<div class="pieGraphHeaderLabel" [id]="getHeaderLabelId()" *ngIf="header != ''" [style.color]="headercolor">{{ header | texttransform }}</div>
<div *ngIf="!dataAvailable" class="pieChart__noData" [ngClass]="{ 'pieGraph__darkMode': darkmode }">
    {{ 'Pie.Label.NoDataAvailable' | texttransform }}
</div>

<div *ngIf="dataAvailable"
    class="pieChart"
    echarts
    [options]="options"
    [merge]="merge"
    [id]="controlid"
    [style.height.px]="height">
</div>

