<div class="emailEditorSync__wrapper">
    <div class="everleadShadowBox everleadShadowBox2">
        <div class="emailEditorSync__header">
            <div class="emailEditorSync__header__receiver">
                <div class="emailEditorSync__headerLine">
                    <label>TO</label>
                    <input [(ngModel)]="to" [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
                </div>    
                <div class="emailEditorSync__headerLine">
                    <label>CC</label>
                    <input [(ngModel)]="cc" [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
                </div>    
                <div class="emailEditorSync__headerLine">
                    <label>BCC</label>
                    <input [(ngModel)]="bcc" [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
                </div>                            
            </div>
            <div class="emailEditorSync__header__buttons">
                <button class="jjButtonStyle jjButtonColor maxHeight" (click)="sendEMail()"
                    [disabled]="cantSendMail()"
                >{{ 'SMTPContent.Label.Send' | texttransform }}</button>
            </div>
        </div>

        <div class="emailEditorSync__topic" *ngIf="cloudConnections.length > 1">
            <div class="emailEditorSync__topic__text">
                <label>{{ 'SMTPSettings.Label.Mailbox' | texttransform }}</label>
                <select [(ngModel)]="selectedCloudConnection" class="maxWidth">
                    <option *ngFor="let cloudConnection of cloudConnections" [ngValue]="cloudConnection.id">{{ cloudConnection.defaultName }}</option>
                </select>    
            </div>
        </div>
    </div>

    <div class="emailEditorSync__topic emailEditorSyncSmallFont" *ngIf="applicationInfoService.campaingnModeId == null">
        <div class="emailEditorSync__topic__text">
            <label>{{ 'SMTPSettings.Label.Campaign' | texttransform }}</label>
            <select [(ngModel)]="selectedCampaign" class="maxWidth">
                <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">{{ campaign.defaultName | texttransform }}</option>
            </select>
        </div>
    </div>

    <div class="emailEditorSync__template emailEditorSyncSmallFont">
        <label>{{ 'SMTPContent.Label.Page1' | texttransform }}</label>
        <select [(ngModel)]="dropdownTemplate" (change)="selectTemplate(dropdownTemplate)">
            <option [ngValue]=null></option>
            <option *ngFor="let template of templates" [ngValue]="template">{{ template.defaultName}}</option>
        </select>
    </div>
    <div *ngIf="doubleOptinMissing && doubleOptinMissingChecked && dropdownTemplate!=null" 
        class="emailEditorSync__topic emailEditorSyncSmallFont everleadCoral">
        {{ 'SMTPSettings.Label.DoubleOptinMissing' | texttransform }}
    </div>
    <div *ngIf="optOutAvailable" class="emailEditorSync__topic emailEditorSyncSmallFont everleadCoral">
        {{ 'SMTPSettings.Label.OptOutDontSendMails' | texttransform }}
    </div>
    <div class="everleadShadowBox everleadShadowBox2 emailEditorSyncMailContent">
        <div class="emailEditorSync__topic  ">
            <div class="emailEditorSync__topic__text">
                <label>{{ 'SMTPContent.Label.Subject' | texttransform }}</label>
                <input [(ngModel)]="subject">
            </div>
        </div>
        <div class="emailEditorSyncAttachmentBar">
            <div class="emailEditorSyncAttachmentBar__files">
                <div class="ellipsisText" *ngIf="attachments.length == 0">{{ 'SMTPContent.Label.NoAttachmentsYet' | texttransform }}</div>
                <div class="ellipsisText" *ngIf="attachments.length > 0">{{ attachments.length }} {{ 'SMTPContent.Label.FilesAttached' | texttransform }}</div>
            </div>
            <div class="emailEditorSyncAttachmentBar__icon">
                <i class="fas fa-paperclip fa-sm fingerCursor" (click)="showAttachments()"></i>
            </div>
        </div>
        <div class="emailSection">
            <div class="maxHeight maxWidth autoOverflowY">
                <app-htmleditor class="maxHeight" (valueStatusChange)="content = $event" [ngClass]="{ hideInDom: selectedTemplate != null }"></app-htmleditor>         
                <div *ngIf="selectedTemplate" class="selectedTemplate">
                    <app-custom-emaileditor [templateId]=selectedTemplate.id [contactId]="this.applicationInfoService.currentContact.id"
                        class="maxHeight"></app-custom-emaileditor>
                </div>            
            </div>        
        </div>
    </div>



    <!-- Attachments -->
    <div id="emailEditorSyncAttachmentPopup" class="emailEditorSyncAttachmentPopup" *ngIf="showAttachmentPopup == true">
        <div class="emailEditorSyncAttachmentHeader">
            <div class="emailEditorSyncAttachmentHeader__label">{{ 'SMTPSettings.Label.Attachments' | texttransform }}</div>
            <div class="emailEditorSyncAttachmentHeader__close">
                <svg-icon src="assets/images/svg/close.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="fingerCursor" (click)="showAttachmentPopup = false"></svg-icon>
            </div>
        </div>
        <div class="emailEditorSyncAttachmentList__wrapper">
            <div class="emailEditorSyncAttachmentList__attachedFiles">
                <div class="emailEditorSyncAttachmentList__alreadyAttached">
                    {{ 'SMTPSettings.Label.AlreadyAttached' | texttransform }}
                </div>    
                <div class="emailEditorSyncAttachmentList" *ngIf="attachments.length == 0">
                    <div class="noAttachmentsYet">{{ 'SMTPSettings.Label.NoAttachmentsYet' | texttransform }}</div>
                </div>
        
                <div class="emailEditorSyncAttachmentList" *ngIf="attachments.length > 0">
                    <div class="emailEditorSyncAttachmentItem everleadShadowBox everleadShadowBox2" *ngFor="let attachment of attachments">
                        <div class="emailEditorSyncAttachmentItem__icon">
                            <i class="fas fa-file" [tippy]="'SMTPSettings.Label.FromUpload' | texttransform" *ngIf="attachment.source == 'upload'"></i>
                            <i class="fas fa-book-open" [tippy]="'SMTPSettings.Label.FromLibrary' | texttransform" *ngIf="attachment.source == 'contentlibrary'"></i>
                            <i class="fas fa-file-code" [tippy]="'SMTPSettings.Label.FromTemplate' | texttransform" *ngIf="attachment.source == 'template'"></i>
                            <!-- <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> -->
                        </div>
                        <div class="emailEditorSyncAttachmentItem__name">
                            {{ attachment.fileName }}
                            {{ attachment.name }}
                        </div>
                        <div class="emailEditorSyncAttachmentItem__size" *ngIf="attachment.size != null">{{ commonService.getAttachmentSizeStringFromAttachmantSize(attachment.size) }}</div>
                        <div class="emailEditorSyncAttachmentItem__delete">                            
                            <i class="fas fa-download fingerCursor" (click)="downloadAttachment(attachment)"></i>
                        </div>
                        <div class="emailEditorSyncAttachmentItem__delete">                            
                            <i class="fas fa-trash fingerCursor"  *ngIf="attachment.source != 'template'" (click)="removeAttachment(attachment)"></i>
                        </div>
                    </div>            
                </div>    
            </div>
            <div class="emailEditorSyncContentLibraryFiles">
                <div class="emailEditorSyncContentLibraryFiles__header">
                    {{ 'SMTPSettings.Label.AvailableContentLibraryFiles' | texttransform }}
                </div>
                <div class="emailEditorSyncContentLibraryFiles__files">
                    <div class="contentLibraryFile everleadShadowBox everleadShadowBox2" *ngFor="let contentLibraryFile of availableContentLibraryFiles">
                        <div class="contentLibraryFile__plus"><i class="fas fa-plus fingerCursor" (click)="addContentLibraryFile(contentLibraryFile)"></i></div>
                        <div class="contentLibraryFile__name" [tippy]="contentLibraryFile.name">{{ contentLibraryFile.name }}</div>
                        <!-- <div class="contentLibraryFile__size">{{ commonService.getAttachmentSizeStringFromAttachmantSize(contentLibraryFile.size) }}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="emailEditorSyncAttachmentUpload">
            <app-externalfiledragcontrol class="contentLibraryItem__dragDrop" (selectedFiles)="onFilesSelected($event)"></app-externalfiledragcontrol>
        </div>
        <!-- <div class="emailEditorSyncAttachmentClose">
            <button (click)="showAttachmentPopup = false" class="everleadCustomButton">
                {{ 'General.Label.Close' | texttransform }}
            </button>
        </div> -->
    </div>
</div>