import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SettingsService } from 'app/shared/service/settings.service';

enum ViewMode {
  Agent = 1,
  Supervisor = 2,
  Projects = 3,
  Notification = 4,
  Contacts = 5,
  Accounts = 6,
  Groups = 7
}

@Component({
  selector: 'app-vip',
  templateUrl: './vip.component.html',
  styleUrls: ['./vip.component.scss']
})

export class VipComponent implements OnInit {
  vipList = [];
  vipListForGroup = [];
  lookupItems = [];
  loadingGroups = false;
  loadingSettings = false;
  acitvatedGroups = [];
  selectedGroup = null;
  vipGroups = [];
  viewMode = ViewMode;
  currentViewMode = ViewMode.Agent;
  projects = [];
  projectsVIP = [];
  projectsDefault = [];
  filterString = '';
  listId = 148543;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private settingsService: SettingsService,
    private loaderService: LoaderService,
    public userService: UserService,    
  ) { }

  ngOnInit(): void {
    this.generateNumberArray(this.projectsVIP, this.settingsService.getUserSettingByString('VipAlertProjectIds', '').value);
    this.generateNumberArray(this.projectsDefault, this.settingsService.getUserSettingByString('NormalAlertProjectIds', '').value);
    this.getVipList();
    this.getLookupItems();
    this.getProjects();
    this.getVipGroups(this.applicationInfoService.projectID);
    this.getSecurityUserVipGroups();
  }

  getLookupItems() {
    this.lookupItems = [
      {
        "id": 4107,
        "defaultName": "url",
        "nameTranslationTokenId": null,
        "nameTranslationToken": null,
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": null
      },{
        "id": 4108,
        "defaultName": "ip",
        "nameTranslationTokenId": null,
        "nameTranslationToken": null,
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": null
      }
    ]
    // this.externaldatasourceService.executeExternalDataSource(387, [69])
    // .then(getLookupItemsResult => {
    //   this.lookupItems = getLookupItemsResult;
    // });
  }

  getProjects() {
    this.externaldatasourceService.executeExternalDataSource(2).then(getProjectsResult => { this.projects = getProjectsResult; })
  }

  getSecurityUserVipGroups() {
    this.externaldatasourceService.executeExternalDataSource(552, [])
    .then(getSecurityUserVipGroupsResult => {
      this.acitvatedGroups = getSecurityUserVipGroupsResult;
    });
  }

  getVipList() {
    this.vipList = [];
    this.loadingSettings = true;
    this.externaldatasourceService.executeExternalDataSource(537, [])
    .then(getVipListResult => {
      getVipListResult.forEach(item => {
        if (item.projectId == this.applicationInfoService.projectID) {
          this.vipList.push(item);          
        }
      });
      this.loadingSettings = false;
    })
    .catch(error => {
      console.error('getVipList', error);
      this.loadingSettings = false;
    });
  }

  getVipListForGroup() {
    this.vipListForGroup = [];
    this.loadingSettings = true;
    this.externaldatasourceService.executeExternalDataSource(556, [this.selectedGroup.id])
    .then(getVipListForGroupResult => {
      this.vipListForGroup = getVipListForGroupResult;
      this.loadingSettings = false;
    })
    .catch(error => {
      console.error('getVipList', error);
      this.loadingSettings = false;
    });
  }

  getVipGroups(projectId = null) {
    this.loadingGroups = true;
    this.externaldatasourceService.executeExternalDataSource(548, [projectId])
    .then(getVipGroupsResult => {  
      this.vipGroups = getVipGroupsResult;
      this.loadingGroups = false;
    })
    .catch(error => {
      this.loadingGroups = false;
      console.error('getVipGroups', error);
    })
  }

  toNumber(value) {
    return Number(value);
  }

  updateVipSetting(setting) {
    this.externaldatasourceService.executeExternalDataSource(539, [
      this.commonService.getModifyArrayBody(setting, [])
    ])
    .then(() => {
      
    })
    .catch(error => { 
      console.error(error);
    });
  }

  createUserVipSetting() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(538, [this.applicationInfoService.userID, null])
    .then(() => {
      this.loaderService.display(false);
      this.getVipList();
    })
    .catch(error => { 
      this.loaderService.display(false);
      console.error(error);
    });
  }

  createGroupVipSetting() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(538, [null, this.selectedGroup.id])
    .then(() => {
      this.loaderService.display(false);
      this.getVipListForGroup();      
    })
    .catch(error => { 
      this.loaderService.display(false);
      console.error(error);
    });
  }

  createVipGroup(projectSpecific = false) {
    let projectId = null;
    if (projectSpecific) {
      projectId = this.applicationInfoService.projectID;
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(549, [projectId])
    .then(() => {
      this.loaderService.display(false);
      this.getVipGroups(projectId);
    })
    .catch(error => { 
      this.loaderService.display(false);
      console.error(error);
    });
  }

  deleteVipSetting(vipId) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(540, [vipId])
    .then(() => {
      if (this.currentViewMode == ViewMode.Agent) {
        this.getVipList();
      }      
      if (this.currentViewMode == ViewMode.Supervisor) {
        this.getVipListForGroup();
      }      
      this.loaderService.display(false);
    })
    .catch(error => { 
      this.loaderService.display(false);
      console.error(error);
    });
  }

  checkIfGroupIsActivated(group) {
    if (this.commonService.isNullOrUndefined(this.acitvatedGroups)) {
      return false;
    }
    const item = this.acitvatedGroups.find(x => x.vipGroupId == group.id);
    if (this.commonService.isNullOrUndefined(item)) {
      return false;
    } else {
      return true;
    }
  }

  toggleGroup(group) {
    let externalDataSourceId = 0;
    let refId = 0;
    if(this.checkIfGroupIsActivated(group)) {
      const item = this.acitvatedGroups.find(x => x.vipGroupId == group.id);
      refId = item.id;
      externalDataSourceId = 555;
    } else {
      externalDataSourceId = 553;
      refId = group.id;
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [refId])
    .then(() => {
      this.loaderService.display(false);
      this.getSecurityUserVipGroups();
    })
    .catch(toggleGroupError => {
      this.loaderService.display(false);
      console.error('toggleGroup', toggleGroupError);
    })
  }

  changeMode(viewMode) {
    this.currentViewMode = viewMode;
  }

  editVipGroup(vipGroup) {
    this.selectedGroup = vipGroup;
    this.getVipListForGroup();
  }

  saveVipGroup(group) {
    this.externaldatasourceService.executeExternalDataSource(550, [
      this.commonService.getModifyArrayBody(group, [])
    ])
    .then(() => {
      
    })
    .catch(error => { 
      console.error(error);
    });
  }

  refreshWhois(setting) {
    this.externaldatasourceService.executeExternalDataSource(648, [setting.id]);
  }

  getGroups(vipGroups, projectSpecific) {
    if (projectSpecific) {
      return vipGroups.filter(groupItem => groupItem.projectId != null );
    } else {
      return vipGroups.filter(groupItem => groupItem.projectId == null );
    }
  }

  toggleArray(array, project, setting) {
    this.commonService.toggleArrayItem(array, project.id);    
    let stringValue = this.commonService.createSeperatedStringFromArray(array, null, ',');
    if (stringValue == '') { stringValue = 'none'; }
    this.settingsService.setUserSetting(setting, stringValue);
  }

  checkIfActive(array, project) {
    return this.commonService.checkIfItemIsInArray(array, project.id);
  }

  generateNumberArray(arraytoAddData, data, seperator = ',') {
    if (data == '' || data == undefined || data == 'none') {
      return [];
    }
    const dataArray = data.split(seperator);
    dataArray.forEach(item => {
      arraytoAddData.push(Number(item));
    });    
  }
  
  isItemFiltered(project) {
    return this.commonService.checkIfStringContainsString(project.projectName, this.filterString);
  }
}
