<div *ngIf="controlDefinition.uiEntityInstanceId == 1" class="calculatedLeadStateAccount">
    <label class="customTextControlWrapper__label">{{ 'CalculatedLeadState.Label.Account' | texttransform }}</label>
    <label class="calculatedLeadStateAccount__valuelabel">{{ getLeadStateName(controlValue) | texttransform }}</label>
</div>

<div *ngIf="controlDefinition.uiEntityInstanceId == 4" class="calculatedLeadStateContact">
    <label class="customTextControlWrapper__label">{{ 'CalculatedLeadState.Label.Contact' | texttransform }}</label>
    <div class="calculatedLeadStateSelect">
        <select [(ngModel)]="controlValue" class="maxWidth customControl__dataLine__dataControl customTextControlWrapper__dropdown" (blur)="updateLeadState()">
            <option *ngFor="let leadState of leadStates" [ngValue]="leadState.lookupTable.id">
                {{ commonService.getTranslationValueFromArray(leadState.lookupTable) | texttransform }}
            </option>
        </select>            
    </div>
</div>
