import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { WizardMode, WizardService } from '../../wizard/wizard.service';
import { DatarightsService } from '../datarights.service';

@Component({
  selector: 'app-datarights-item',
  templateUrl: './datarights-item.component.html',
  styleUrls: ['./datarights-item.component.scss']
})
export class DatarightsItemComponent implements OnInit {
  @Input() dataRightGroup = null;
  @Input() level = 0;
  @Input() availableUsers = [];
  @Input() availableFilters = [];
  @Input() expandAll = false;

  @Input() generalFilterUser = null;
  @Input() generalFilterString = '';
  @Input() generalFilterAccessRights = null;
  
  dataFilterCaller = 'datarights-item';
  isExpanded = false;
  editItem = false;
  assignedUsers = [];
  filterStringAssigned = '';
  filterString = '';
  page = 1;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    public datarightsService: DatarightsService,
    public uiService: UiService,
    private loaderService: LoaderService,
    private datafilterService: DatafilterService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private wizardService: WizardService
  ) { }

  ngOnInit(): void {
  }

  // saveGroup(dataRightGroup) {
  //   this.externaldatasourceService.executeExternalDataSource(574, [
  //     this.commonService.getModifyArrayBody(dataRightGroup, ['groupNumber'])
  //   ]).then(() => {

  //   })
  // }

  toggleExpansion() {
    if (this.datarightsService.isExpanded[this.dataRightGroup.id] != true) {
      this.datarightsService.isExpanded[this.dataRightGroup.id] = true;
    } else {
      this.datarightsService.isExpanded[this.dataRightGroup.id] = false;
    }
  }

  // checkIfAlreadyAssigned(user) {    
  //   return this.dataRightGroup.users.some(userToCheck => userToCheck.user.id == user.id); 
  // }

  // checkIfFilterAlreadyAssigned (filter) {
  //   return this.dataRightGroup.filters.some(filterToCheck => filterToCheck.filter.id == filter.id); 
  // }

  createDataRightsGroup(dataRightGroup) {
    let parentId = dataRightGroup.id;
    let name = this.axivasTranslateService.getTranslationTextForToken('DataRights.Label.NewSubGroupName');
    name = dataRightGroup.defaultName.concat(
      ': ', 
      this.axivasTranslateService.getTranslationTextForToken('DataRights.Label.NewSubGroupName'),
      ' ',
      (dataRightGroup.children.length + 1).toString()
    );
    this.externaldatasourceService.executeExternalDataSource(573, [
      this.commonService.getModifyArrayBody(
        {
          projectId: this.applicationInfoService.projectID,
          defaultName: name,
          parentId: parentId,
          groupLevel: 1,
          isReadOnly: true
        }
        , []
      )
    ]).then(createDataRightsGroupResult => { 
      this.datarightsService.refreshDataRightGroupListEvent.emit();
      this.editDataRightGroup(createDataRightsGroupResult);
      this.datarightsService.isExpanded[this.dataRightGroup.id] = true;
    });
  }

  deleteDataRightsGroup(dataRightGroup) {
    this.externaldatasourceService.executeExternalDataSource(575, [dataRightGroup.id])
    .then(() => {
      this.datarightsService.refreshDataRightGroupListEvent.emit(null);
      this.datarightsService.selectedGroup = null;
    });
  }

  newLevel() {
    return Number(this.level) + 1;
  }

  isFilterActive(checkString, filterString) {
    return !this.commonService.checkIfStringContainsString(checkString, filterString);
  }

  checkIfItemNeedsToBeHidden() {
    let returnValue = false;
    if (!this.commonService.checkIfStringContainsString(this.dataRightGroup.defaultName, this.generalFilterString)) {
      returnValue = true;
    }
    if (this.generalFilterUser != null) {
      const user  = this.dataRightGroup.users.find(user => user.userId == this.generalFilterUser );
      if (!user) {
        returnValue = true;
      }
    }
    if (this.generalFilterAccessRights != null) {
      if (this.dataRightGroup.isReadOnly != this.generalFilterAccessRights) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  getAssignedUserToolTip() {
    let returnValue = this.axivasTranslateService.getTranslationTextForToken('DataRights.Label.AssignedUsers');
    this.dataRightGroup.users.forEach(user => {
      returnValue = returnValue.concat('\n', user.user.userName);
    })
    return returnValue;
  }

  getAssignedFilterToolTip() {
    let returnValue = this.axivasTranslateService.getTranslationTextForToken('DataRights.Label.AssignedFilters');
    this.dataRightGroup.filters.forEach(filter => {
      returnValue = returnValue.concat('\n', filter.filter.defaultName);
    })
    return returnValue;
  }

  editDataRightGroup(group) {
    this.wizardService.showWizard(WizardMode.DataRightGroup, JSON.parse(JSON.stringify(group)), '80vw', '80vh')
  }
}
