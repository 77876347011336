import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-controledit-light',
  templateUrl: './controledit-light.component.html',
  styleUrls: ['./controledit-light.component.scss']
})
export class ControleditLightComponent implements OnInit {
  controlDefinition = null;
  mode = 1;
  displayMode = 'controlSettings';
  containerView = 1;
  fixedTypeId = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private designerService: DesignerService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.controlDefinition = this.applicationInfoService.miscSettings['controlEditLightControl'];
    this.mode = this.applicationInfoService.miscSettings['controlEditLightControlMode'];
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['controlEditLightControlFixedTypeId'])) {
      this.fixedTypeId = this.applicationInfoService.miscSettings['controlEditLightControlFixedTypeId'];
      this.applicationInfoService.miscSettings['controlEditLightControlFixedTypeId'] = null;
    }
    
  }

  editDeveloper() {
    this.designerService.editUiControl(this.controlDefinition);
  }
}
