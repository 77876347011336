<div #PowerButtonContainer class="powerButtonContainer powerButtonWrapper everleadBlueColor">
  <label *ngIf="powerButtons.length == 0 && loadingControlData == false">
    {{ 'PowerButton.Label.NoPowerButtonsAvailable' | texttransform }}
  </label>
  <label *ngIf="loadingControlData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
  </label>
  <label *ngIf="applicationInfoService.accountLocked && loadingControlData == false" class="everleadCoral">
    {{ 'PowerButton.Label.AccountIsLocked' | texttransform }}
  </label>
  <span *ngIf="powerButtons.length > 0 && loadingControlData == false && !applicationInfoService.accountLocked">
    <label *ngFor="let powerButton of powerButtons" (click)="callPowerButton(powerButton.id)"
      class="powerButtonStyle everleadShadowBox everleadCoralHover fingerCursor" [ngClass]="{
        'powerButtonPositiveResult': powerButton.result==1,
        'powerButtonNegativeResult': powerButton.result==0,
        powerButtonNew: applicationInfoService.useNewDesign
      }" [class]="getPowerButtonClasses()"
      title="{{ powerButton.name | texttransform }}">
      {{ powerButton.name | texttransform }}
    </label>
  </span>
</div>