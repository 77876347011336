<div *ngIf="userService.hasPermission('CanReadCampaign')">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem" *ngIf="userService.hasPermission('CanCreateCampaign')">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                (click)="createNewCampaign()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithDefault' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon> 
            <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                (click)="createNewWizardCampaign()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{theme: 'everleadBlue', placement:'bottom'}"></svg-icon>         
            {{ 'CampaignDesign.Label.NewCampaign' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__showOnlyActive">
                <input [(ngModel)]="showOnlyActive" type="checkbox">
                {{ 'CampaignDesign.Label.ShowOnlyActive' | texttransform }}            
            </div>
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>
    <div class="nonExpertHeader" *ngIf="applicationInfoService.expertMode==false && !applicationInfoService.isMobile()" >
        <div class="nonExpertHeader__active">{{ 'CampaignDesign.Label.IsActive' | texttransform }}</div>
        <div class="nonExpertHeader__name">{{ 'CampaignDesign.Label.DefaultName' | texttransform}}</div>
        <div class="nonExpertHeader__edit">{{ 'General._.Edit' | texttransform}}</div>    
    </div>

    <span id="projectSettingsDesignSpan"></span>

    <span *ngIf="loadingData==true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>

    <div class="projectSettings__wrapper" [style.height]="getTableHeight()" *ngIf="applicationInfoService.expertMode==true && loadingData == false">    
        <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
            <ng-container matColumnDef="defaultName" [sticky]="!applicationInfoService.isMobile()">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.DefaultName' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">  
                    <div class="projectSettings__name">
                        <i class="fas fa-edit fingerCursor" [tippy]="'General.Label.ShowWizard' | texttransform" (click)="showWizard(element)"
                            *ngIf="userService.hasPermission('CanUpdateCampaign')"></i>
                        <label *ngIf="applicationInfoService.isDeveloper" class="projectSettings__id everleadCoral">{{ element.id }}</label>
                        <input [(ngModel)]="element.defaultName" type="text" (change)="saveCampaign(element)" class="projectSettings__inputMin">                    
                        <app-wizard-translations [array]="element" updateBaseArrayExternaldatasourceId=137 mode="3"></app-wizard-translations>    
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.IsActive' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <input [(ngModel)]="element.isActive" type="checkbox" (change)="saveCampaign(element)" class="projectSettings__inputFull">                    
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignStart">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CampaignFrom' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">        
                    <input [(ngModel)]=element.startCampaign [owlDateTimeTrigger]="dt7" (dateTimeChange)="saveCampaign(element)" 
                        [owlDateTime]="dt7" readonly appendTo="body" aria-modal="true" class="projectSettings__inputFull"> 
                    <owl-date-time #dt7></owl-date-time>                                   
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignEnd">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CampaignUntil' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <input [(ngModel)]=element.endCampaign [owlDateTimeTrigger]="dt8" (dateTimeChange)="saveCampaign(element)"
                        [owlDateTime]="dt8" readonly appendTo="body" aria-modal="true" class="projectSettings__inputFull">
                    <owl-date-time #dt8></owl-date-time>
                </td>
            </ng-container>

            <ng-container matColumnDef="nettoCallLimit">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.NettCallLimit' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <input [(ngModel)]="element.netcallLimit" type="number" (change)="saveCampaign(element)" class="projectSettings__inputFull">                    
                </td>
            </ng-container>

            <ng-container matColumnDef="ses">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.SesConfigSetId' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">        
                    <select [(ngModel)]="element.sesConfigSetId" (change)="saveCampaign(element)" class="projectSettings__dropdown">
                        <option [ngValue]=null></option>
                        <option *ngFor="let configSet of configSets" [ngValue]="configSet.id">
                            {{ configSet.defaultName }}
                        </option>
                    </select>    
                </td>
            </ng-container>

            <ng-container matColumnDef="followUps">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.AutoCloseNettCallLimit' | texttransform }}</th>
                <td mat-cell *matCellDef="let element"> 
                    <input [(ngModel)]="element.closeTaskAfterXCallAttempts" type="number" (change)="saveCampaign(element)" class="projectSettings__inputFull">                               
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignId">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CustomerCampaignId' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <input [(ngModel)]="element.customerCampaignId" type="text" (change)="saveCampaign(element)" class="projectSettings__inputFull">          
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignName">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CustomerCampaignName' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">  
                    <input [(ngModel)]="element.customerCampaignName" type="text" (change)="saveCampaign(element)" class="projectSettings__inputMin">          
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
                hideInDom: (row.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
                projectSettings__isInactive: row.isActive == false
            }"></tr>
        </table>
    </div>



    <div class="nonExpertView__wrapper" [style.height]="getTableHeight()" *ngIf="applicationInfoService.expertMode==false" >
        <div *ngFor="let campaign of campaigns" class="nonExpertView__main nonExpertView__main__noSubitems"[ngClass]="{             
            hideInDom: (campaign.isActive == false && showOnlyActive)  || itemIsFiltered(campaign) == true,
            projectSettings__isInactive: campaign.isActive == false,
            everleadShadowBox: applicationInfoService.isMobile()
        }">
            <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
                <input [(ngModel)]="campaign.isActive" type="checkbox" (change)="saveCampaign(campaign)" [tippy]="'CampaignDesign.Label.IsActive' | texttransform">
            </div>
            <div class="nonExpertView__name">            
                <input [(ngModel)]="campaign.defaultName" (change)="saveCampaign(campaign)">
                <app-wizard-translations [array]="campaign" updateBaseArrayExternaldatasourceId=137 mode="3"></app-wizard-translations>    
                <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" *ngIf="applicationInfoService.isMobile()" (click)="showWizard(campaign)"></i>
            </div>
            <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
                <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" [tippy]="'General._.Edit' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>
            </div>
        </div>
    </div>
</div>