
<div *ngIf="control" class="entityTableValueControl" [ngClass]="{
    entityTableValueControl__centerContent: control.controlType == 6 || 
                                            control.controlType == 4 ||
                                            control.controlType == 7 ||
                                            control.controlType == 15 ,
    entityTableValueDarkMode:               darkmode==true,
    entityTableValueMobileColumn:           control.controlType == 16,
    entitytableValueBreakLineAfterDescription: control.breakLineAfterDescription == true,
    maxHeight: (subcontrol == false && subcontrols.length == 0)
    }"    
>
    <div class="entityTableValueControlInfo" *ngIf="control.showHeaderAsRowDesription || applicationInfoService?.isMobile()
        || isSmallControlWidth" [ngClass]="{
        entityTableValueBold: control.bold,
        entityTableValueItalic: control.italic
    }">
        <span *ngIf="control.alternateQuickTextForValueControl != '' && 
            commonService.isNullOrUndefined(control.alternateQuickTextForValueControl)==false">
            {{ control.alternateQuickTextForValueControl | texttransform }}
        </span>
        <span *ngIf="control.alternateQuickTextForValueControl == '' || 
            commonService.isNullOrUndefined(control.alternateQuickTextForValueControl)">
            {{ control.displayText | texttransform }}
        </span>        
    </div>


    <div *ngIf="control.controlType == 0" [ngClass]="{
        entityTableValueBold: control.bold,
        entityTableValueItalic: control.italic,
        ellipsisText: control.breakWhitespace != true,
        entityTableValueBreakWhitespace: control.breakWhitespace == true
    }" class="entityTableValueText">
        <!-- Text -->
        <span *ngIf="control.lookupTableId == null && control.alternateLookupTable == null" 
            class="ellipsisText" [tippy]="value" [tippyOptions]="{placement:'bottom'}" >{{ value }}</span>
        <span *ngIf="control.lookupTableId != null || control.alternateLookupTable != null" [tippy]="getTranslatedValue() | texttransform" 
            [tippyOptions]="{ placement: 'left' }" class="ellipsisText">{{ getTranslatedValue() | texttransform }}</span>
    </div>


    <!-- Date -->
    <div *ngIf="control.controlType == 1" class="maxWidth" [tippy]="getDate()" [tippyOptions]="{ placement:'left' }">
        {{ getDate() }}
    </div>


    <!-- Date mit roter Schrift wenn überschritten -->
    <div *ngIf="control.controlType == 2" class="maxWidth" [ngClass]="{ everleadCoral: valueDateIsInPast }" [tippy]="getDate()" [tippyOptions]="{placement:'left'}">
        {{ getDate() }}
    </div>
    

    <!-- Web-Image -->
    <div *ngIf="control.controlType == 3 && commonService.isNullOrUndefined(value)==false && dataTableItem.mainEntityId != 1227" class="maxWidth webImage">        
        <img [src]="getImage()" class="entityTableValueImage" [ngClass]="{ hideInDom: imageName == '' }">
    </div>
    <div *ngIf="control.controlType == 3 && dataTableItem.mainEntityId == 1227" class="maxWidth webImage">
        <app-icon-file [value]="getDocTypeName()" [darkmode]="darkmode" [height]="24" [width]="24"></app-icon-file>
    </div>

    <div *ngIf="control.controlType == 3 && commonService.isNullOrUndefined(value)" class="maxWidth webImage">        
        <svg-icon src="assets/images/svg/account_factory.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==1"></svg-icon>
        <svg-icon src="assets/images/svg/iconset/07icon-termine.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==2 && commonService.isNullOrUndefined(valueline['_2_resultid'])"></svg-icon>        
        <svg-icon src="assets/images/svg/file-signature.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==2 && !commonService.isNullOrUndefined(valueline['_2_resultid'])"></svg-icon>        
        <svg-icon src="assets/images/svg/dashboard_contact.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==4"></svg-icon>
        <svg-icon src="assets/images/svg/dashboard_contact.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg entityTableValueImage__stroke" *ngIf="dataTableItem.mainEntityId==992"></svg-icon>
        <svg-icon src="assets/images/svg/default_conversion.svg" [svgStyle]="{ 'width.px': 60, 'height.px': 60 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==462"></svg-icon>
        <svg-icon src="assets/images/svg/account_factory.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==917"></svg-icon>
        <svg-icon src="assets/images/svg/file-signature.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==20"></svg-icon>
        <svg-icon src="assets/images/svg/default_conversion.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" class="entityTableValueImage__svg" *ngIf="dataTableItem.mainEntityId==99"></svg-icon>
    </div>


    <!-- Thermometer -->
    <div *ngIf="control.controlType == 4" class="maxWidth prospectQuality" [tippy]="'Thermometer.Label.Description' | texttransform">        
        <app-custom-thermometer [value]="value"></app-custom-thermometer>
    </div>      

    <!-- Research -->
    <div *ngIf="control.controlType == 5" class="entityTableValueResearch">
        <div class="entityTableValueResearch__buttons">
            <svg-icon src="assets/images/svg/google.svg" class="entityTableValueIcon fingerCursor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                (click)="commonService.openGoogle(value);$event.stopPropagation()">
            </svg-icon>
            <svg-icon src="assets/images/svg/xing.svg" class="entityTableValueIcon fingerCursor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                (click)="commonService.openXing(value);$event.stopPropagation()">
            </svg-icon>
        </div>
        <div class="ellipsisText" [ngClass]="{
            entityTableValueBold: control.bold,
            entityTableValueItalic: control.italic
        }">
            <span *ngIf="control.lookupTableId == null && control.alternateLookupTable == null" [tippy]="value">{{ value }}</span>
            <span *ngIf="control.lookupTableId != null || control.alternateLookupTable != null" [tippy]="getTranslatedValue() | texttransform">{{ getTranslatedValue() | texttransform }}</span>
        </div>        
    </div>


    <!-- Leadscore -->
    <div *ngIf="control.controlType == 6">
        <app-custom-leadscore [controlValue]="value" *ngIf="featureLeadscoring"></app-custom-leadscore>
    </div>
    <!-- Leadscore Prospect -->
    <div *ngIf="control.controlType == 15">
        <app-custom-leadscore [controlValue]="getLeadScoreProspectValue(value)"></app-custom-leadscore>
    </div>

    <!-- Delete -->
    <div *ngIf="control.controlType == 7" (click)="deleteItem();$event.stopPropagation()" class="entityTableValueDelete">
        <i class="fas fa-trash fa-2x everleadCoral"></i>
    </div>    

    <!-- Visitlist -->
    <div *ngIf="control.controlType == 8" class="maxWidth">        
        <div class="entityTableValueListItem" *ngFor="let item of commonService.splitRows(value); let i = index" [ngClass]="{
            hideInDom: item == '\r' || item == '' || i > 2
        }">
            <i class="fas fa-eye"></i>
            <label [tippy]="removeDemoGuid(item)">{{ removeDemoGuid(item) }}</label>
        </div>        
    </div>  

    <!-- Add to accountgroup -->
    <div *ngIf="control.controlType == 9" class="maxWidth">
        <i class="fas fa-plus fa-lg fingerCursor" (click)="addToAccountGroup()"></i>
    </div>

     <!-- DownloadRecording -->
     <div *ngIf="control.controlType == 10" class="maxWidth entityTableValueRecording">
        <div class="ellipsisText">{{ getTranslatedValue() | texttransform }}</div>
        <div class="entityTableValueRecording__download"><i class="fas fa-download fa-lg fingerCursor" (click)="downloadRecording()"></i></div>
    </div>

    <div *ngIf="control.controlType == 11">
        <div *ngIf="value == '2'">
            <svg-icon src="assets/images/svg/ai/everlead_ai_thumbs_up2.svg" [tippy]="'MatTable.Label.AIHigh' | texttransform"
                [svgStyle]="{ 'width.px': 56, 'height.px': 56 }"></svg-icon>
        </div>
        <div *ngIf="value == '0'">
            <svg-icon src="assets/images/svg/ai/everlead_ai_thumbs_down3.svg" [tippy]="'MatTable.Label.AILow' | texttransform"
                [svgStyle]="{ 'width.px': 56, 'height.px': 56 }"></svg-icon>
        </div>
    </div>

    <!-- AIContact -->
    <div *ngIf="control.controlType == 12" class="maxWidth entityTableValueAIContact" [style.min-height]="getMinHeight()"
        [ngClass]="{ 'entityTableValueAIContact__active': valueline._4_isaisuggestion == true || valueline._4_isaicontact == true }">
        <svg-icon src="assets/images/svg/ai/everlead_ai_thumbs_up2.svg" 
            [svgStyle]="{ 'width.px': 56, 'height.px': 56 }" 
            [tippy]="'MatTable.Label.AIHigh' | texttransform"
            *ngIf="valueline._4_isaisuggestion == true || valueline._4_isaicontact == true">
        </svg-icon>           
    </div>

    <!-- VIP  -->
    <div *ngIf="control.controlType == 13" class="maxWidth entityTableValueVIP" [style.min-height]="getMinHeight()" 
        (click)="changeVIPStatus();$event.stopPropagation()">
        <svg-icon class="everleadCoral" src="assets/images/svg/vipsmall.svg" [svgStyle]="{ 'width.px': 40, 'height.px': 40 }" 
            *ngIf="value != null" [tippy]="'EntityTableValue.Label.VIPControlRemove' | texttransform"></svg-icon>        
        <svg-icon class="noVip" src="assets/images/svg/vipsmall.svg" [svgStyle]="{ 'width.px': 40, 'height.px': 40 }" 
            *ngIf="value == null" [tippy]="'EntityTableValue.Label.VIPControlAdd' | texttransform"></svg-icon>
    </div>

    <!-- VIP OnlyView -->
    <div *ngIf="control.controlType == 21" class="maxWidth entityTableValueVIP" [style.min-height]="getMinHeight()" >
        <svg-icon class="everleadCoral" src="assets/images/svg/vipsmall.svg" [svgStyle]="{ 'width.px': 40, 'height.px': 40 }" 
            *ngIf="value == null"></svg-icon>
    </div>

    <!-- Asstet Link -->
    <div *ngIf="control.controlType == 14" class="maxWidth entityTableValueAssetLink fingerCursor"
        [tippy]="getAssetLinkTitle() | texttransform" (click)="callAsstetLink();$event.stopPropagation()">
        <label>{{ value }}</label>
        <svg-icon src="assets/images/svg/toolbar/toolbar_openurl.svg" class="fingerCursor" 
            [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"></svg-icon>
    </div>

    <div *ngIf="control.controlType == 16" class="maxWidth entityTableValueProspectConnectedAccount"
        [style.min-height]="getMinHeight()" (click)="$event.stopPropagation()">
        <div *ngIf="valueline.accountid == null" class="entityTableValueProspectConnectedAccount__accountNotFound">
            <div class="entityTableValueProspectConnectedAccount__accountNotFound__nowrapline">
                {{ 'EntityTableValue.Label.NoConnectionDesc' | texttransform }}
            </div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__icons">
                <button class="everleadCustomButton" (click)="createAccountFromProspect()">
                    {{ 'Prospect.Label.ConvertToAccount' | texttransform }}
                </button>
            </div>
        </div>
        <div *ngIf="valueline.accountid != null" class="entityTableValueProspectConnectedAccount__accountNotFound">           
            <div class="entityTableValueProspectConnectedAccount__accountFound__line">{{ valueline._1_name1 }}</div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__line">{{ valueline._1_street }}</div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__line">{{ valueline._1_zipcode }} {{ valueline._1_city }}</div>
            <div class="entityTableValueProspectConnectedAccount__accountFound__icons">
                <button class="everleadCustomButton" (click)="loadConnectedAccount()">
                    {{ 'Prospect.Label.LoadConnectedAccount' | texttransform }}
                </button>
            </div>
        </div>
    </div>

    <!-- Prospect blacklisted -->
    <div *ngIf="control.controlType == 17" class="maxWidth entityTableProspectBlacklisted fingerCursor" 
        (click)="changeProspectBlacklistMode();$event.stopPropagation()"
        [ngClass]="{ everleadCoralBackground: value == true }"
    >
        <i class="fas fa-ban fa-2x" [tippy]="'Prospect.Label.RemoveFromBlacklist' | texttransform" *ngIf="value == true"></i>
        <i class="fas fa-ban fa-2x" [tippy]="'Prospect.Label.Blacklist' | texttransform" *ngIf="value == false"></i>
    </div>

    <div *ngIf="control.controlType == 18" class="maxWidth entityTableProspectLeadstateChange fingerCursor" (click)="$event.stopPropagation()">
        <select [(ngModel)]="value" (focusout)="updateLeadstate();$event.stopPropagation()">
            <option *ngFor="let leadstate of getLeadstates()" [ngValue]="leadstate.id">
                {{ leadstate.lookupTable.defaultName }}
            </option>
        </select>
        <!-- <i class="fas fa-exchange-alt fingerCursor" (click)="updateLeadstate()"></i> -->
    </div>
    <div *ngIf="control.controlType == 19" class="maxWidth entityTableEditOptin fingerCursor" (click)="$event.stopPropagation()">
        <i class="fas fa-lg fa-edit fingerCursor" (click)="editOptin(valueLine)" *ngIf="valueline['_20_enddate'] == null"></i>
    </div>

    <!-- New task for contact -->
    <div *ngIf="control.controlType == 20" class="maxWidth entityTableNewTaskForContact" [tippy]="'NewTask.Label.CreateNewTask' | texttransform">
        <i class="fas fa-plus fa-2x" (click)="createTaskForContact();$event.stopPropagation()"></i>
    </div>

    <!-- Download entity file -->
    <div *ngIf="control.controlType == 22" class="maxWidth entityTableNewTaskForContact" 
        [tippy]="'EntityFileUpload.Label.DownloadEntityFile' | texttransform" [tippyOptions]="{placement:'bottom'}">
        <i class="fas fa-download fingerCursor" (click)="downloadEntityFile();$event.stopPropagation()"></i>
    </div>

    <!-- File size -->
    <div *ngIf="control.controlType == 23" class="maxWidth" [tippy]="'EntityFileUpload.Label.EntityFileSize' | texttransform" [tippyOptions]="{placement: 'bottom'}">
        {{ commonService.getAttachmentSizeStringFromAttachmantSize(value) }}
    </div>

    <!-- Delete entity file -->
    <div *ngIf="control.controlType == 24" class="maxWidth entityTableNewTaskForContact" 
        [tippy]="'EntityFileUpload.Label.DeleteEntityFile' | texttransform" [tippyOptions]="{ placement: 'bottom' }">
        <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteEntityFile();$event.stopPropagation()"></i>
    </div>

    <!-- Edit opportunity -->
    <div *ngIf="control.controlType == 25" class="maxWidth entityTableEditOpportunity">
        <i class="fas fa-edit fa-lg fingerCursor" (click)="editOpportunity();$event.stopPropagation()"></i>
    </div>

    <!-- Change opportunity status -->
    <div *ngIf="control.controlType == 26" class="maxWidth entityTableProspectLeadstateChange">
        <select [(ngModel)]="value" (focusout)="changeOpportunityStatus();$event.stopPropagation()">
            <option *ngFor="let opportunityState of oppotunityService.opportunityStates" [ngValue]="opportunityState.id">
                {{ opportunityState.defaultName }}
            </option>
        </select>       
    </div>

    <div *ngIf="control.controlType == 27" class="maxWidth">
        {{ value | currency:'EUR':true }}
    </div>

    <div *ngIf="control.controlType == 28" class="maxWidth">
        {{ value | date: 'dd.MM.yyyy' }}
    </div>

    <!-- Sonderfall SAP Pay per SAL -->
    <div *ngIf="control.controlType == 30" class="maxWidth">
        <div *ngFor="let sapPPSkeyword of sapPPSkeywords" class="entityTableSAPPPSitem">
            <div class="entityTableSAPPPSitem__label">{{ sapPPSkeyword[0] }}</div>
            <div class="entityTableSAPPPSitem__value">{{ sapPPSkeyword[1] }}</div>
        </div>
    </div>
</div>