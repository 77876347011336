<div class="optinOverview__wrapper" [ngClass]="{ optinOverviewDarkmode: darkMode }">
    <ng-container *ngIf="optOutsCollected == false" class="optinOverview__spinner">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
    </ng-container>
    <div class="radioButtonSelector">
        <div class="radioButtonSelector__buttons">
            <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=1>{{ 'OptinOptOut.Label.Overview' | texttransform }}</div>
            <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=2>{{ 'OptinOptOut.Label.Detailview' | texttransform }}</div>        
        </div>
        <div class="radioButtonSelector__new" (click)="newOptinOptOutItem()">
            <i class="fas fa-plus"></i>{{ 'OptinOptOut.Label.NewItem' | texttransform }}
        </div>
    </div>
    <div *ngIf="optOutsCollected && viewMode==1">
        <div class="optinOverviewItem optinOverviewItem__small">
            <div class="optinOverviewItem__name">Medium</div>
            <div class="optinOverviewItem__type">Typ</div>
            <div class="optinOverviewItem__startDate">{{ 'OptinOptOut.Label.LatestEntry' | texttransform }}</div>
            <div class="optinOverviewItem__endDate">Enddatum</div>
            <div class="optinOverviewItem__status">IP</div>
            <div class="optinOverviewItem__status">Benutzer</div>
            <div class="optinOverviewItem__comment"></div>            
        </div>
        <div *ngFor="let availableMediaItem of availableMedia">
            <div class="optinOverviewItem everleadShadowBox everleadShadowBox" [ngClass]="{
                    everleadCoralBackground:  (getStatus(availableMediaItem)?.lookupTypeRefId == 44 && getStatus(availableMediaItem)?.endDateInPast == false),
                    everleadGreenBackground:  (getStatus(availableMediaItem)?.lookupTypeRefId == 43 && getStatus(availableMediaItem)?.endDateInPast == false),
                    goldStatus:               (getStatus(availableMediaItem)?.lookupTypeRefId == 1815 && getStatus(availableMediaItem)?.endDateInPast == false),
                    endDateInPast:            getStatus(availableMediaItem)?.endDateInPast == true
                }">
                <div class="optinOverviewItem__name">{{ availableMediaItem.defaultName }}</div>
                <div class="optinOverviewItem__type">
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 1815">DoubleOptIn</span>
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 44">OptOut</span>
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 43">OptIn</span>
                </div>
                <div class="optinOverviewItem__startDate"><span *ngIf="getStatus(availableMediaItem).startDate != null">{{ getStatus(availableMediaItem).startDate | date:'medium' }}</span></div>
                <div class="optinOverviewItem__endDate">
                    <span *ngIf="getStatus(availableMediaItem).endDate != null" [ngClass]="{
                        everleadCoral: getStatus(availableMediaItem)?.endDateInPast == true
                    }">{{ getStatus(availableMediaItem).endDate | date:'medium' }}</span>
                </div>
                <div class="optinOverviewItem__status">
                    <div *ngIf="getStatus(availableMediaItem).ip != null">{{ getStatus(availableMediaItem).ip }}</div>
                </div>
                <div class="optinOverviewItem__status">
                    <div *ngIf="getStatus(availableMediaItem).userId != null" class="ellipsisText"
                        [tippy]="getUser(getStatus(availableMediaItem).userId)">{{ getUser(getStatus(availableMediaItem).userId) }}</div>
                </div>
                <div class="optinOverviewItem__comment">
                    <i class="fa-lg far fa-sticky-note" *ngIf="getStatus(availableMediaItem).comment != null" [tippy]="getStatus(availableMediaItem).comment"></i>
                </div>
            </div>
        </div>
    </div>    
    <div *ngIf="optOutsCollected && viewMode==2">
        <div [innerHTML]="innerHTML"></div>
    </div>
</div>