import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-mailshot-final',
  templateUrl: './wizard-mailshot-final.component.html',
  styleUrls: ['./wizard-mailshot-final.component.scss']
})
export class WizardMailshotFinalComponent implements OnInit {
  users = [];
  errorMessage = '';
  firstContact = null;

  constructor(
    public wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    public loaderService: LoaderService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private confirmationService: ConfirmationService,
    private emailTemplateService: EmailtemplateService
  ) { }

  ngOnInit(): void {
    // console.warn('wizardArray', this.wizardService.wizardArray);
    if (this.wizardService.wizardArray.newsletter == true) {
      this.externaldatasourceService.executeExternalDataSource(707).then(firstContact => {
        this.firstContact = firstContact;
      });
    }

    this.getUsers();
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.queryEntityId)) {
      this.wizardService.wizardArray.queryEntityId = 4;
    }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.followUpUserId)) {
      this.wizardService.wizardArray.followUpUserId = this.applicationInfoService.userID;
    }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.followUpDate)) {
      this.wizardService.wizardArray.followUpDate = new Date(Date.now());
    }
  }

  getSendDate(date, step) {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step).bookTaskAfterXDays)) {
      return new Date(new Date(date).getTime());
    } else {
      return new Date(new Date(date).getTime() + (1000 * 60 * 60 * 24 * this.applicationInfoService.steps.Item(step).bookTaskAfterXDays));
    }
  }

  createMailShot() {
    this.errorMessage = '';
    let templateId = null;
    const bookedResult = this.applicationInfoService.results.Item(this.wizardService.wizardArray.resultId);
    if (bookedResult) {
      templateId = bookedResult.emailTemplateId;
    } else {
      return;
    }

    if (this.wizardService.wizardArray.newsletter != true) {
      this.externaldatasourceService.executeExternalDataSource(729, [
        bookedResult.emailTemplateId,
        this.wizardService.wizardArray.campaignId
      ]).then(() => {
          this.emailTemplateService.sendTestEmail(
            templateId,
            this.wizardService.wizardArray.campaignId,
            this.wizardService.wizardArray.testContact.id,
            'info@alex-gross.com',
            true
          ).then(result => {           
            if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.filterId)) {
              this.wizardService.wizardArray.filterId = null;
            }
            let taskCreationMessage = this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.MailMessage');
            taskCreationMessage = taskCreationMessage.replace('<0>', this.wizardService.wizardArray.receivers);
            taskCreationMessage = taskCreationMessage.replace('<1>',
              new Date(this.wizardService.wizardArray.followUpDate).toLocaleDateString().concat(' ',
              new Date(this.wizardService.wizardArray.followUpDate).toLocaleTimeString()));
            this.confirmationService.confirm({
              message: taskCreationMessage,
              header: this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.Header'),
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.loaderService.display(true);
                this.updateEMailBatch().then(() => {
                  this.externaldatasourceService.executeExternalDataSource(850, [
                    this.wizardService.wizardArray.templateDefinitionId,
                    this.wizardService.wizardArray.filterId,
                    this.wizardService.wizardArray.columnFilters,
                    this.wizardService.wizardArray.id,
                    this.wizardService.wizardArray.consentTypes,
                    this.wizardService.wizardArray.mediumTypes,
                    this.wizardService.wizardArray.lastXDays,
                  ])
                  .then(() => {
                    // Prepared, starting now
                    this.externaldatasourceService.executeExternalDataSource(851, [this.wizardService.wizardArray.id])
                    .then(() => {
                      this.loaderService.display(false);
                      this.messagingService.showDefaultSuccess('', 'Booking.MailshotMessage.Message');
                      this.eventService.mailshotCreatedEvent.emit(null);
                      this.eventService.closeJjPopup();
                    });
                  }).catch(() => { this.loaderService.display(false); });                 
                }).catch(() => { this.loaderService.display(false); });
              }
            });
        });
      });
    } else {
      // Newsletter
      this.externaldatasourceService.executeExternalDataSource(729, [
        bookedResult.emailTemplateId,
        this.wizardService.wizardArray.campaignId
      ]).then(() => {
          this.emailTemplateService.sendTestEmail(
            templateId,
            this.wizardService.wizardArray.campaignId,
            this.firstContact.id,
            'info@alex-gross.com',
            true
          ).then(result => {           
            if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.filterId)) {
              this.wizardService.wizardArray.filterId = null;
            }
            let taskCreationMessage = this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.NewsletterMessage');
            taskCreationMessage = taskCreationMessage.replace('<0>',
              new Date(this.wizardService.wizardArray.followUpDate).toLocaleDateString().concat(' ',
              new Date(this.wizardService.wizardArray.followUpDate).toLocaleTimeString()));
            this.confirmationService.confirm({
              message: taskCreationMessage,
              header: this.axivasTranslateService.getTranslationTextForToken('TaskCreation.Message.Header'),
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.loaderService.display(true);
                this.updateEMailBatch().then(() => {
                  this.externaldatasourceService.executeExternalDataSource(932, [
                    this.wizardService.wizardArray.id,
                    this.wizardService.wizardArray.newsletterMicroCampaignResultId,
                    new Date(this.wizardService.wizardArray.followUpDate).toUTCString()
                  ]).then(() => {
                    this.loaderService.display(false);
                    this.messagingService.showDefaultSuccess('', 'Booking.MailshotMessage.Message');
                    this.eventService.mailshotCreatedEvent.emit(null);
                    this.eventService.closeJjPopup();
                  }).catch(() => { this.loaderService.display(false); });
                }).catch(() => { this.loaderService.display(false); });
              }
            });
        });
      });
    }

  }

  getUsers() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(14, [])
    .then(result => {
      this.loaderService.display(false);
      this.users = result;
    })
    .catch(() => {
      this.loaderService.display(false);
     });
  }

  updateEMailBatch(): Promise<any> {
    return new Promise((updateEMailBatchResolve, updateEMailBatchReject)=> {
      const array = {
        id: this.wizardService.wizardArray.id,
        batchName: this.wizardService.wizardArray.batchName,
        status: this.wizardService.wizardArray.status,
        wizardValues: JSON.stringify(this.wizardService.wizardArray)
      }

      let externalDataSourceId = 680;
      if (this.wizardService.wizardArray.bookingVersion == 2) {
        externalDataSourceId = 855;
      }
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
        this.commonService.getModifyArrayBody(array, [])])
      .then(() => {
        updateEMailBatchResolve(null);
        this.eventService.customEvent.emit({
          id: this.wizardService.wizardArray.id,
          type: 'updateEMailBatch',
          value: JSON.stringify(this.wizardService.wizardArray)
        });
      }).catch(()=> { updateEMailBatchReject(null); });
    });
  }
}
