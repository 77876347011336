<div class="eventBookingBatchWrapper">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createNewBookingBatch()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
            <span *ngIf="batchTypeLookupId == 7422">{{ 'EventBookingBatch.Label.NewMicroCampaign' | texttransform }}</span>
            <span *ngIf="batchTypeLookupId == 7421">{{ 'EventBookingBatch.Label.NewMailShot' | texttransform }}</span>
        </div>
        <!-- <div class="projectSettings__header__newItem fingerCursor" (click)="createNewBookingBatch(true)" *ngIf="batchTypeLookupId == 7421">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
            {{ 'EventBookingBatch.Label.NewNewsletter' | texttransform }}
        </div> -->
        
        <div class="projectSettings__header__interaction">            
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>






    <div *ngIf="batchTypeLookupId == 7422" class="eventBookingBatchSectionWrapper">
        <!-- MicroCampaigns -->
        <div class="eventBookingBatchList">
            <div *ngFor="let eventBookingBatch of eventBookingBatches" class="eventBookingBatchItem everleadShadowBox everleadShadowBox2"
                [ngClass]="{
                    hideInDom: isFiltered(eventBookingBatch),
                    fingerCursor: eventBookingBatch.startedAt == null
                }" (click)="editMicroCampaign(eventBookingBatch)">
                <div class="eventBookingBatchItem__id everleadCoral">{{ eventBookingBatch.id }}</div>
                <div class="eventBookingBatchItem__name">{{ eventBookingBatch.batchName }}</div>
                <div class="eventBookingBatchItem__buttons">
                    <i class="fas fa-stop fingerCursor" [ngClass]="{
                        eventBookingBatchItemDisabled: eventBookingBatch.startedAt == null || eventBookingBatch.stoppedAt != null
                    }" (click)="eventbookingbatchService.stopEventBookingBatch(eventBookingBatch);$event.stopPropagation()"></i>            
                    <i class="fas fa-play fingerCursor" [ngClass]="{
                        eventBookingBatchItemDisabled: eventBookingBatch.startedAt != null
                    }" (click)="eventbookingbatchService.startEventBookingBatch(eventBookingBatch);$event.stopPropagation()"></i>
                </div>
            </div>    
        </div>
    </div>

    <div *ngIf="batchTypeLookupId == 7421" class="eventBookingBatchSectionWrapper">
        <!-- E-Mail batch -->
        <div class="eventBookingBatchList">
            <div class="eventBookingBatchItem eventBookingBatchItem__small">
                <div class="eventBookingBatchItem__icon"></div>
                <div class="eventBookingBatchItem__name">{{ 'EventBookingBatch.Label.Name' | texttransform }}</div>
                <div class="eventBookingBatchItem__date">{{ 'EventBookingBatch.Label.SendDate' | texttransform }}</div>
                <div class="eventBookingBatchItem__buttons"></div>
            </div>
            <div *ngFor="let eventBookingBatch of eventBookingBatches" class="eventBookingBatchItem everleadShadowBox everleadShadowBox2"
                [ngClass]="{
                    hideInDom: isFiltered(eventBookingBatch),
                    fingerCursor: eventBookingBatch.startedAt == null
                }" (click)="editMailshot(eventBookingBatch)">
                <!-- <div class="eventBookingBatchItem__id everleadCoral">{{ eventBookingBatch.id }}</div> -->
                <div class="eventBookingBatchItem__icon">
                    <i class="fas fa-newspaper" tippy="Newsletter" *ngIf="eventBookingBatch.wizardValueArray.newsletter == true"></i>
                    <i class="fas fa-mail-bulk" tippy="Mailshot" *ngIf="eventBookingBatch.wizardValueArray.newsletter != true"></i>
                </div>
                <div class="eventBookingBatchItem__name">{{ eventBookingBatch.batchName }}</div>
                <div class="eventBookingBatchItem__date" *ngIf="eventBookingBatch.status == 9139">
                    <input
                        [(ngModel)]="eventBookingBatch.wizardValueArray.followUpDate"
                        [owlDateTimeTrigger]="dt7"
                        (dateTimeChange)="changeFollowUpDate(eventBookingBatch)"
                        [owlDateTime]="dt7" readonly class="bookingInput" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>
                </div>
                <div class="eventBookingBatchItem__date" *ngIf="eventBookingBatch.status != 9139">
                    {{ eventBookingBatch.wizardValueArray.followUpDate | date:'medium' }}
                </div>
                <div class="eventBookingBatchItem__buttons">
                    <i class="fas fa-eye fingerCursor" *ngIf="eventBookingBatch.startedAt != null" 
                        (click)="showStats(eventBookingBatch);$event.stopPropagation()"></i>            

                    <i class="fas fa-stop fingerCursor" [ngClass]="{
                        eventBookingBatchItemDisabled: eventBookingBatch.startedAt == null || eventBookingBatch.stoppedAt != null
                    }" (click)="eventbookingbatchService.stopEventBookingBatch(eventBookingBatch);$event.stopPropagation()"></i>            
                    <i class="fas fa-play fingerCursor" [ngClass]="{
                        eventBookingBatchItemDisabled: eventBookingBatch.startedAt != null
                    }" (click)="eventbookingbatchService.finalizeMailshot(eventBookingBatch);$event.stopPropagation()"></i>
                </div>
            </div>    
        </div>
    </div>
</div>