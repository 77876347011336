import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-callednumberhistory',
  templateUrl: './callednumberhistory.component.html',
  styleUrls: ['./callednumberhistory.component.scss']
})
export class CallednumberhistoryComponent implements OnInit {
  recordings = [];
  accountCalls = [];
  lookuptableId = 0;
  
  constructor(
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private sharedAPI: SharedAPI,
    private ringcentralwebphoneService: RingcentralwebphoneService
  ) { }

  ngOnInit(): void {
    this.lookuptableId = Number(this.applicationInfoService.applicationSettings['recordingLookupTableId']);
    this.refreshView();
  }

  refreshView() {
    this.getCallsForAccount();
    this.getRecordings();
  }

  getCallsForAccount() {
    this.externaldatasourceService.executeExternalDataSource(789, [this.applicationInfoService.currentAccount.id]).then(getCallsForAccountResult => { 
      this.accountCalls = getCallsForAccountResult.filter(call => call.accountId == this.applicationInfoService.currentAccount.id); 
    });
  }

  getRecordings() {
    this.externaldatasourceService.executeExternalDataSource(788, []).then(getRecordingsResult => { 
      this.recordings = getRecordingsResult; 
    });
  }

  getRecordingsForCall(accountCall) {
      return this.recordings.filter(record => record.calledNumberHistoryId == accountCall.id);
  }

  downloadFile(recording) {
    let fileName = ''.concat(this.applicationInfoService.currentAccount.name1, '_', this.getRecordingTypeName(recording.recordingLookupId), '_', recording.id, '.mp3');
    fileName = this.commonService.removeIllegalCharactersFromFileName(fileName);
    this.sharedAPI.downloadRecording(recording, null).subscribe(result => {      
      this.commonService.downloadFile(result, 'audio/mpeg', fileName);
    })
  }

  syncRecordings() {
    this.sharedAPI.syncRecordings().subscribe(()=> {});
  }

  getRecordingTypeName(recordingTypeId) {
    const recordingType = this.ringcentralwebphoneService.recordingtypes.find(recordtype => recordtype.id == recordingTypeId);
    if (recordingType) {
      return recordingType.defaultName;
    } else {
      return 'recordTypeId not found';
    }
  }

  downloadAvailable(recording) {
    let returnValue = true;
    let url = recording.partialFileStorageUrl;    
    if (url == undefined || url == null) {
      url = recording.storageUrl;
    }
    if (url == undefined || url == null) { 
      returnValue = false;
    }
    return returnValue;
  }
}
