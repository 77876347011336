import { UiService } from '../../service/ui.service';
import { Injector, Input, OnInit, OnDestroy, Directive } from '@angular/core';
import { MethodService } from '../../service/method.service';
import { ApplicationInfoService, VariableType } from '../../../core/application/application-info.service';
import { EntityService, ValueType } from '../../service/entity.service';
import { Subscription } from 'rxjs';
import { EventService } from '../../service/event.service';
import { ExternaldatasourceService } from '../../service/externaldatasource.service';
import { LoaderService } from '../../../shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ControlService } from 'app/jollyjupiter/service/control.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { isNullOrUndefined } from 'util';

@Directive()
export class CustomControlBaseClass implements OnInit, OnDestroy {
    // Subscriptions
    updateEntityEventSubscription: Subscription = new Subscription();
    updateInformationPanelValue: Subscription = new Subscription;
    setNestedControlStyleSubscription: Subscription = new Subscription;
    raiseShowRequiredFieldEventSubscription: Subscription = new Subscription();
    raiseResetRequiredFieldEventSubscription: Subscription = new Subscription();
    updateEntityDataPropertyEventSubscription: Subscription = new Subscription();
    lockAllControlsOfContainerEventSubscription: Subscription = new Subscription();
    updateControlAttributesEventSubscription: Subscription = new Subscription();

    // Services
    externalDatasourceService: ExternaldatasourceService;
    eventService: EventService;
    uiService: UiService;
    methodService: MethodService;
    applicationInfoService: ApplicationInfoService;
    loaderService: LoaderService;
    entityService: EntityService;
    commonService: CommonService;
    messagingService: MessagingService;
    controlService: ControlService;
    userService: UserService;

    // Control
    controlValue: any = null;
    controlSelection: any = null;
    valueType: ValueType;
    data: any[] = [];
    dataKey: any = null;
    time: any;
    controlGUID = '';
    loadingControlData = false;
    controlGuidPredefine = '';
    settingJSON = null;

    // Style
    public disabledChilds: any[] = [];
    public isEnabled = true;
    public isRequired = false;

    // Documentation
    public documentationPage = null;
    
    public controlSettings = new Object();

    // Inputs
    @Input() controlDefinition: any = null;
    @Input() controlID: any = null;
    @Input() externalDataSource: any = null;
    @Input() entityMemberlookupTable: any = null;
    @Input() entity: any = null;

    constructor(
        private injectorObj: Injector
    ) {
        this.uiService = this.injectorObj.get(UiService);
        this.methodService = this.injectorObj.get(MethodService);
        this.applicationInfoService = this.injectorObj.get(ApplicationInfoService);
        this.entityService = this.injectorObj.get(EntityService);
        this.eventService = this.injectorObj.get(EventService);
        this.commonService = this.injectorObj.get(CommonService);
        this.loaderService = this.injectorObj.get(LoaderService);
        this.messagingService = this.injectorObj.get(MessagingService);
        this.controlService = this.injectorObj.get(ControlService);
        this.externalDatasourceService = this.injectorObj.get(ExternaldatasourceService);
        this.userService = this.injectorObj.get(UserService);

        this.controlGUID = this.commonService.createGuid();
        this.updateEntityDataPropertyEventSubscription = this.eventService.updateEntityDataPropertyEvent.subscribe(event => {
            if (!this.commonService.isNullOrUndefined(this.controlDefinition)) {
                if (Number(event.target) === this.controlDefinition.uiEntityInstanceId) {
                    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities
                        .Item(this.controlDefinition.uiEntityInstanceId).data)) {
                        this.controlValue = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId)
                        .data[this.controlDefinition.value];
                    }
                }
            }
        });

        this.updateEntityEventSubscription = this.eventService.updateEntityEvent.subscribe(event => {
          if (!this.commonService.isNullOrUndefined(this.controlDefinition)) {
            if (Number(event.target) === this.controlDefinition.uiEntityInstanceId) {
                let value = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId)                
                .data[this.controlDefinition.value];
                if (this.controlDefinition.uiEntityInstanceId == 230) {
                    value = this.applicationInfoService.miscSettings[this.controlDefinition.value];
                }
                if (value) {
                    switch (this.valueType) {
                        case ValueType.Date:
                            if (value != null) {
                                value = new Date(value);
                            }
                        break;
                        case ValueType.Boolean:
                        value = value;
                        break;
                    }
                }
                this.controlValue = value;
                // this.controlValue = this.entityService.getEntityValue(
                //     this.controlDefinition.uiEntityInstanceId,
                //     this.controlDefinition.value,
                //     1
                // );
                this.checkAttributes();
            }
        }});

       // SubScriptions
        this.raiseShowRequiredFieldEventSubscription = this.eventService.raiseShowRequiredFieldEvent.subscribe(event => {
            if (!this.commonService.isNullOrUndefined(this.controlDefinition)) {
                if ((Number(event.target) === this.controlDefinition.uiEntityInstanceId) && (event.arguments[0] === this.controlDefinition.value)) {
                    this.isRequired = true;
                }
            }
        });
        this.updateControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
            this.checkAttributes();
        }),
        this.lockAllControlsOfContainerEventSubscription = this.eventService.lockAllControlsOfContainerEvent.subscribe(event => {
            if (!this.commonService.isNullOrUndefined(this.controlDefinition)) {
                if (event.target === this.controlDefinition.parentContainerId) {
                    this.isEnabled = event.arguments[0];
                }
            }
        });
        this.raiseResetRequiredFieldEventSubscription = this.eventService.raiseResetRequiredFieldEvent.subscribe(event => {
            if (!this.commonService.isNullOrUndefined(this.controlDefinition)) {
                if (event.target === this.controlDefinition.uiEntityInstanceId) {
                    this.isRequired = false;
                }        
            }
        });

        this.setNestedControlStyleSubscription = this.eventService.setNestedControlStyleEvent.subscribe(event => {
            if (event.target === this.controlID) {

            }
        });        
    }

    getControlValueAsTitle(controlValue) {
        if (!controlValue) {
            return '';
        } else {
            return controlValue;
        }
    }

    getSettingJSON() {
        if (this.controlDefinition.settings != null && this.controlDefinition.settings != '') {
          this.settingJSON = JSON.parse(this.controlDefinition.settings);
        } else {
          this.settingJSON = {};
        }
    }
    
    callMethod(type: string, sourceControl: any = this.controlDefinition) {
        this.loaderService.display(true);
        if (this.controlSelection !== null) { this.controlValue = this.controlSelection; }
        let updateValue = this.controlValue;
        if (Array.isArray(this.controlValue)) {
            updateValue = this.controlValue[this.dataKey];
        } else {
            updateValue = this.controlValue;
        }

        if (type.toLowerCase() === 'onchange')  {
            if (sourceControl.uiEntityInstanceId != null) {
                if (sourceControl.uiEntityInstanceId == 230) {
                    this.applicationInfoService.miscSettings[sourceControl.value] = updateValue;
                } else {
                this.entityService.updateValuebyType(sourceControl.uiEntityInstanceId,
                    sourceControl.value, updateValue, this.valueType);
                }
            }
        }
        this.uiService.updateControlValueDict(this.controlDefinition.logicalControlId, this.controlValue);
        this.loaderService.display(false);
        this.methodService.callMethod(type, sourceControl, this.controlValue);
    }

    setControlStyle(type: string, modifier: number = 0) {
        this.checkAttributes();
        return(this.uiService.setControlStyle(type, modifier, this.controlDefinition));
    }

    setControlHeight(type: string, modifier: number = 0) {
        return(this.uiService.setControlHeight(this.controlDefinition, modifier));
    }

    setControlWidth(type: string, modifier: number = 0) {
        return(this.uiService.setControlWidth(this.controlDefinition, modifier));
    }

    ngOnInit() {
        if (this.commonService.isNullOrUndefined(this.controlDefinition.settings)) {
            this.controlSettings = new Object();
        } else {
            this.controlSettings = JSON.parse(this.controlDefinition.settings);
        }
    }

    ngOnDestroy() {
        if (this.raiseShowRequiredFieldEventSubscription) { this.raiseShowRequiredFieldEventSubscription.unsubscribe(); }
        if (this.raiseResetRequiredFieldEventSubscription) { this.raiseResetRequiredFieldEventSubscription.unsubscribe(); }
        if (this.updateEntityEventSubscription) { this.updateEntityEventSubscription.unsubscribe(); }
        if (this.setNestedControlStyleSubscription) { this.setNestedControlStyleSubscription.unsubscribe(); }
        if (this.updateInformationPanelValue) { this.updateInformationPanelValue.unsubscribe(); }
        if (this.updateEntityDataPropertyEventSubscription) { this.updateEntityDataPropertyEventSubscription.unsubscribe(); }
        if (this.lockAllControlsOfContainerEventSubscription) { this.lockAllControlsOfContainerEventSubscription.unsubscribe(); }
        if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
    }

    checkColorAttribute(controlPreValue = ''): any {
        const control =  document.getElementById(this.getControlMainId(controlPreValue));
        if (control) {
            return this.uiService.checkAttributeChanges('color', this.controlDefinition, control.style.color, VariableType.String);
        }
    }

    checkBackgroundColorAttribute(controlPreValue = ''): any {
        const control =  document.getElementById(this.getControlMainId(controlPreValue));
        if (control) {
            return this.uiService.checkAttributeChanges('backgroundcolor', this.controlDefinition, control.style.backgroundColor,
                VariableType.String);
        }
    }

    checkAttributes() {
        this.isEnabled = this.checkIfControlIsEnabled();
    }

    checkIfControlIsEnabled(): boolean {
        if (this.commonService.isNullOrUndefined(this.controlDefinition)) {
            return true;
        }
        let isEnabled = this.uiService.checkAttributeChanges('enabled', this.controlDefinition, true, VariableType.Boolean);
         isEnabled = this.uiService.checkAttributeModifierGroups('enabled', this.controlDefinition, isEnabled);
        // isEnabled = this.userService.getControlPermission('CanUpdate', this.controlDefinition.userPermission, isEnabled);
        if (this.controlDefinition.uiControlSecurityPermissions.length > 0) {
            isEnabled = this.userService.getControlPermission('', this.controlDefinition.uiControlSecurityPermissions,
                isEnabled, 'enabled');
        }
        if (this.applicationInfoService.accountLocked && 
            (
                this.controlDefinition.uiEntityInstanceId == 1 || 
                this.controlDefinition.uiEntityInstanceId == 2 ||
                this.controlDefinition.uiEntityInstanceId == 4
            )
        ) {
            isEnabled = false;
        }
        if (this.applicationInfoService.currentAccount) {
            if (this.controlDefinition.uiEntityInstanceId == 1 && this.applicationInfoService.currentAccount.isReadOnly == true) {
                isEnabled = false;
            }    
        }
        if (this.applicationInfoService.currentContact) {
            if (this.controlDefinition.uiEntityInstanceId == 4 && this.applicationInfoService.currentContact.isReadOnly == true) {
                isEnabled = false;
            }        
        }

        if (this.uiService.getControlSettingOrDefault(this.controlDefinition, 'disabled', false) == true) {
            isEnabled = false;
        }
        return isEnabled;
    }

    getControlMainId(additionalPreValue = '') {
        return this.controlGuidPredefine.concat(additionalPreValue, this.controlGUID);
    }

    getSubControlInteractionControlWidth(substractValue = 0) {
        return this.controlService.getSubControlInteractionControlWidth(this.getControlMainId(), substractValue);
        return this.uiService.getDomControlWidth(this.getControlMainId(),
            Number(this.uiService.getControlDimensionWithoutAttribute(this.applicationInfoService.controlInteractionIconWidth)))
             + 'px';
    }

    getDisplayText() {
        return this.uiService.getDisplayText(this.controlDefinition);
    }
}
