<div class="everleadShadowBox externalDataSource__wrapper">
    <div class="externalDataSource__header">
        <div class="externalDataSource__header__id">
            Id: {{ externalDataSource.externalDataSource.id }}
        </div>
        <div class="externalDataSource__header__queryType">
            {{ externalDataSource.externalDataSource.queryType }}
        </div>
        <div class="externalDataSource__header__delete fingerCursor" (click)="deleteUiControlExternalDataSource(externalDataSource)">
            <i class="fas fa-trash"></i>
        </div>
    </div>
    <div class=" externalDataSource__content">
        {{ externalDataSource.externalDataSource.dataQuery }}
    </div>
</div>