<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor leadStateHeaderDiv">
        <label style="padding-left: 3px;"
            class="normalWhiteSpace">{{ 'Supervisor.ProjectSettings.DataDelivery.DataSources' | texttransform }}</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
        <button (click)="updateDataDelivery(currentDataDelivery)" class="jjControlSubmenuButton" style="white-space: normal;"
            [disabled]="currentDataDelivery == null"><i class="fas fa-save" ></i></button>
</div>
<div>
    <label class="dataDeliveryLabel">{{ 'Supervisor.ProjectSettings.DataDelivery.DataSources' | texttransform }}</label>
    <select [(ngModel)]="currentDataDelivery" class="dataDeliveryDropdown">
        <option [ngValue]=null></option>
        <option [ngValue]=dataDelivery *ngFor="let dataDelivery of dataDeliveries">{{ dataDelivery.defaultName }} ({{ dataDelivery.importDate | date: 'medium' }})</option>
    </select>
 
    <br>
    <br>
    <div *ngIf="currentDataDelivery != null">
        <div *ngIf="currentDataDelivery.deliveredRecords.length > 1">
            <label class="dataDeliveryLabel jjBold">{{ 'Supervisor.ProjectSettings.DataDelivery.Accounts' | texttransform }}</label><br>
            <label class="dataDeliveryLabel">{{ 'Supervisor.ProjectSettings.DataDelivery.RecordsDelivered' | texttransform }}</label>
            <input [(ngModel)]="currentDataDelivery.deliveredRecords[0].recordsDelivered" class="dataDeliveryTextbox">
            <br>
            <label class="dataDeliveryLabel">{{ 'Supervisor.ProjectSettings.DataDelivery.RecordsImported' | texttransform }}</label>
            <input [(ngModel)]="currentDataDelivery.deliveredRecords[0].recordsImported" class="dataDeliveryTextbox" disabled=true>
            <br>
            <br>
            <label class="dataDeliveryLabel jjBold">{{ 'Supervisor.ProjectSettings.DataDelivery.Contacts' | texttransform }}</label><br>
            <label class="dataDeliveryLabel">{{ 'Supervisor.ProjectSettings.DataDelivery.RecordsDelivered' | texttransform }}</label>
            <input [(ngModel)]="currentDataDelivery.deliveredRecords[1].recordsDelivered" class="dataDeliveryTextbox">
            <br>
            <label class="dataDeliveryLabel">{{ 'Supervisor.ProjectSettings.DataDelivery.RecordsImported' | texttransform }}</label>
            <input [(ngModel)]="currentDataDelivery.deliveredRecords[1].recordsImported" class="dataDeliveryTextbox" disabled=true>
            <br>
            <br>    
        </div>
        <app-import-details [importGuid]="currentDataDelivery.importGuid"></app-import-details>
    </div>
</div>