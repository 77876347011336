import { Component, OnInit, Injector } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { TextTransformService } from 'app/jollyjupiter/pipe/text-transform.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { isNullOrUndefined } from 'util';
import { VariableType } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-custom-menubar',
  templateUrl: './custom-menubar.component.html',
  styleUrls: ['./custom-menubar.component.scss']
})
export class CustomMenubarComponent extends CustomControlBaseClass  implements OnInit {
  selectedParent = null;

  constructor(
    private injector: Injector,
    public userService: UserService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit() {

  }

  getButtonClass(child: any) {
    let returnValue = 'fingerCursor ';
    if (!this.commonService.isNullOrUndefined(child.additionalCssClasses)) {
      returnValue = returnValue.concat(child.additionalCssClasses);
    }
    if (this.applicationInfoService.useNewDesign) {
      returnValue = returnValue.concat(' fa-2x');
    } else {
      returnValue.concat(' fa-lg');
    }
    return returnValue;
  }

  clickItem(method, child, isSubChild = false) {
    // console.warn('clickItem', child.childs.length)
    if (child.childs.length > 0) {      
      if (this.selectedParent == child.id) {
        this.selectedParent = null;
      } else {
        this.selectedParent = child.id;
      }
    } else {      
      this.callMethod(method, child);
      this.selectedParent = null;
    }    
    if (isSubChild) {
      this.selectedParent = null;
    }
  }

  checkIfControlIsVisible(control) {
    let isVisible = true;
    if (control.isActive == false) {
      return false;
    }
    isVisible = this.uiService.checkAttributeChanges('visible', control, true, VariableType.Boolean);
    return isVisible;
  }
}
