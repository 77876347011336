import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { isNullOrUndefined } from 'util';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-admin-customer',
  templateUrl: './admin-customer.component.html',
  styleUrls: ['./admin-customer.component.scss']
})
export class AdminCustomerComponent implements OnInit {
  customers = [];
  customersBackup = [];
  dataInstances = [];
  filterString = '';
  dataInstanceDetailArray = [];

  constructor(
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getDataInstances();
    this.getCustomerInfo();
  }

  setCustomerInfoArray(array: any) {
    this.customers = array;
    this.customersBackup = JSON.parse(JSON.stringify(array));
  }

  getCustomerInfo(customerId = null) {
    let customerInfoExternalDataSource = 143;
    if (customerId !== null) {
      customerInfoExternalDataSource = 145;
    }
    this.loaderService.display(true, false, 'getCustomerInfo');
    this.externaldatasourceService.executeExternalDataSource(customerInfoExternalDataSource, [customerId])
    .then(getCustomerInfoResult => {
      this.setCustomerInfoArray(getCustomerInfoResult);
      this.loaderService.display(false, false, 'getCustomerInfo');
    })
    .catch(error => {
      this.loaderService.display(false, false, 'getCustomerInfo');
      console.error('getCustomerInfo', error);
    });
  }

  addCustomer() {
    const newCustomer = new Object();
    newCustomer['customerName'] = 'Neuer Kunde';
    this.customers.unshift(newCustomer);
  }

  getDataInstances() {
    this.dataInstanceDetailArray = [];
    this.loaderService.display(true, false, 'getDataInstances');
    this.externaldatasourceService.executeExternalDataSource(146)
    .then(getDataInstancesResult => {
      this.loaderService.display(false, false, 'getDataInstances');
      this.dataInstances = getDataInstancesResult;
      this.dataInstances.forEach(instance => {
        this.dataInstanceDetailArray[instance.id] = instance.dataInstanceDatabaseDetails;
      });
    })
    .catch(error => {
      this.loaderService.display(false, false, 'getDataInstances');
      console.error('getDataInstances', error);
    });
  }

  getBackupIndexOfCustomer(customer) {
    let returnValue = 0;
    this.customersBackup.forEach((backupItem, index) => {
      if (backupItem.id === customer.id) {
        returnValue = index;
      }
    });
    return returnValue;
  }

  saveCustomer() {
    const promiseArray: Promise<any>[] = [];
    let newValue = false;
    let validationError = false;
    this.customers.forEach(customer => {
      if (JSON.stringify(customer) !== JSON.stringify(this.customersBackup[this.getBackupIndexOfCustomer(customer)])) {
        let updateDataSource = 149;
        if (isNullOrUndefined(customer.id)) {
          updateDataSource = 148;
          newValue = true;
        }
        if (isNullOrUndefined(customer.defaultDataInstanceDatabaseDetailId) ||
            isNullOrUndefined(customer.defaultDataInstanceId)) {
              this.messagingService.showDefaultError(customer.customerName, 'Angaben für DataInstance benötigt!');
              validationError = true;
              return;
        }
        promiseArray.push(this.externaldatasourceService.executeExternalDataSource(updateDataSource,
          [
            customer.customerName,
            this.commonService.nullOrUndefinedToDefaultValue(customer.defaultDataInstanceDatabaseDetailId),
            this.commonService.nullOrUndefinedToDefaultValue(customer.defaultDataInstanceId),
            customer.id
          ]
        ));
      }
    });

    if (validationError) { return; }

    this.loaderService.display(true, false, 'saveCustomer');
    Promise.all(promiseArray).then(() => {
      this.loaderService.display(false, false, 'saveCustomer');
      this.messagingService.showDefaultSuccess('saveCustomer', 'Speichern erfolgreich!');
      if (newValue) {
        this.getCustomerInfo();
      }
    })
    .catch(error => {
      this.messagingService.showDefaultError('saveCustomer', error);
      this.loaderService.display(false, false, 'saveCustomer');
      console.error('getDataInstances', error);
    });
  }
}
