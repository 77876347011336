import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { QuestionaireControlService } from '../questionaire-control.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-questionaire-designer-control',
  templateUrl: './questionaire-designer-control.component.html',
  styleUrls: ['./questionaire-designer-control.component.scss']
})
export class QuestionaireDesignerControlComponent implements OnInit {
  showOnlyActive = false;
  filterString = '';

  constructor(
    public questionaireControlService: QuestionaireControlService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.questionaireControlService.getQuestionaires();
  }

  selectQuestionaire(questionaire) {
    this.questionaireControlService.selectedQuestionaire = questionaire;
    this.questionaireControlService.getQuestionsById(this.questionaireControlService.questionaireLookupId, questionaire.id).then(getQuestionsByIdResult => {
      this.questionaireControlService.selectedQuestionaire.questions = getQuestionsByIdResult;      
    });
  }

  addQuestion() {
    this.questionaireControlService.addQuestion().then(addQuestionResult => {       
      // this.questionaireControlService.selectedQuestionaire.questions.push(addQuestionResult);      
      // console.warn(this.questionaireControlService.selectedQuestionaire.questions);
      this.questionaireControlService.getQuestionsById(this.questionaireControlService.questionaireLookupId, 
        this.questionaireControlService.selectedQuestionaire.id).then(getQuestionsByIdResult => {
        this.questionaireControlService.selectedQuestionaire.questions = getQuestionsByIdResult;      
      });  
    });
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    console.warn(event, event.container.data, event.previousIndex, event.currentIndex);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  deleteQuestionnaire(questionnaire) {
    this.questionaireControlService.deleteQuestionaire(questionnaire).then(() => {

    })
  }

  isItemFiltered(questionaire) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(questionaire.defaultName, this.filterString)) {
      returnValue = false;
    }
  }
}
