import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-custom-resultpreview',
  templateUrl: './custom-resultpreview.component.html',
  styleUrls: ['./custom-resultpreview.component.scss']
})
export class CustomResultpreviewComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updateBookingPreviewEventScription: Subscription = new Subscription();
  innerHTML = '';
  previewBookingTask = null;
  selectedResult = null;
  nextStep = null;

  constructor(
    private injector: Injector,
    private externaldatasourceService: ExternaldatasourceService,
    private axivasTranslateService: AxivasTranslateService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit() {
    const entityData = this.entityService.getEntityDataArray(this.controlDefinition.uiEntityInstanceId);
    if (entityData) {
      if (entityData.resultId) {
        this.getCurrentPreview(entityData.resultId, true);
      }
    }
    this.updateBookingPreviewEventScription = this.eventService.updateBookingPreviewEvent.subscribe(event => {
      this.getCurrentPreview(event.arguments[0], true);
    });
  }

  ngOnDestroy() {
    if (this.updateBookingPreviewEventScription) { this.updateBookingPreviewEventScription.unsubscribe(); }
  }

  getCurrentPreview(resultId: any, updateFollowUpDate: boolean = true) {
    let variableIdentifierLeft = '{';
    let variableIdentifierRight = '}';
    this.nextStep = null;
    this.selectedResult = null;

    if (!resultId) {
      this.innerHTML = null;
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(888, [resultId])
    .then(resultValue => {
      const resultJSON = JSON.parse(resultValue);

      this.previewBookingTask = resultJSON;

      this.selectedResult = this.applicationInfoService.results.Item(resultId);
      if (this.selectedResult.nextStepId != null) {
        this.nextStep = this.applicationInfoService.steps.toArray().find(step => step.id == this.selectedResult.nextStepId);
      }
      
      // Followup
      let showFollowUpTask = null;
      let showFollowUpDate = null;
      if (resultJSON.HasFollowUpTask === true) {
        showFollowUpTask = true;
        if ((this.applicationInfoService.results.Item(resultId).workflowStepResultTypeId === 29) ||
          (this.applicationInfoService.results.Item(resultId).workflowStepResultTypeId === 30)) {
          showFollowUpDate = null;
        } else {
          showFollowUpDate = true;
        }
      }
      this.methodService.launchInternalMethod('updateentityvalue', [this.controlDefinition.uiEntityInstanceId,'uiFollowUpTask', showFollowUpTask]);
      this.methodService.launchInternalMethod('updateentityvalue', [this.controlDefinition.uiEntityInstanceId,'followUpUserId', this.userService.getUserId()]);
      this.methodService.launchInternalMethod('updateentityvalue', [this.controlDefinition.uiEntityInstanceId,'uiFollowUpDate', showFollowUpDate]);
      this.methodService.launchInternalMethod('updateentityvalue', [this.controlDefinition.uiEntityInstanceId,'isFollowUpAppointment', this.applicationInfoService.results.Item(resultId).isPersonalFollowup]);

      const resultFromArray = this.applicationInfoService.results.toArray().find(resultItem => resultItem.id == resultId);
      if (resultFromArray) {
        if (resultFromArray.followUpUserId != null) {
          this.methodService.launchInternalMethod('updateentityvalue', [this.controlDefinition.uiEntityInstanceId,'followUpUserId', resultFromArray.followUpUserId]);
        }
      }

      // result.previewText.messageParameters.forEach((messageParameter, index) => {
      //   if (messageParameter !== '') {
      //     this.innerHTML = this.innerHTML.replace(variableIdentifierLeft + index + variableIdentifierRight,
      //       this.axivasTranslateService.getTranslationTextForToken(messageParameter));
      //   } else {
      //     this.innerHTML = this.innerHTML.replace(variableIdentifierLeft + index + variableIdentifierRight, '');
      //   }
      // });

      if (updateFollowUpDate) {
        if (resultJSON.NextFollowUpDate) {
          this.methodService.launchInternalMethod('updateentityvalue', [this.controlDefinition.uiEntityInstanceId, 'uiNextFollowUpDate',
          resultJSON.NextFollowUpDate]);
        }
      }
      this.innerHTML = this.innerHTML.replace(/\n/g, '<br>');      
    })
    .catch(error => {console.error(error); });
  }

  showMail() {
    if (this.selectedResult == null) { return; }
    if (this.selectedResult.emailTemplateId == null) { return; }
    this.applicationInfoService.miscSettings['bookingPreviewEMailTemplateId'] = this.selectedResult.emailTemplateId;
    this.eventService.showJjPopup('BookingPreview.Label.EMailPreview', 'maileditor', '80');
  }
}
