import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-informationpanel',
  templateUrl: './custom-informationpanel.component.html',
  styleUrls: ['./custom-informationpanel.component.scss']
})
export class CustomInformationpanelComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  items: string[] = [];
  severity = '';

  updateInformationPanelValue: Subscription = new Subscription;

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.String;
    this.updateInformationPanelValue = this.eventService.updateInformationPanelEvent.subscribe(event => {
      // console.log('updateInformationPanelValue', event);
      if (event.target === this.controlDefinition.uiEntityInstanceId) {
        this.items = event.arguments;
        this.severity = event.additionalInfo[0];
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.updateInformationPanelValue) { this.updateInformationPanelValue.unsubscribe(); }
  }
}
