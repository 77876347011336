import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { isNullOrUndefined, isNull } from 'util';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-translation-button',
  templateUrl: './translation-button.component.html',
  styleUrls: ['./translation-button.component.scss']
})
export class TranslationButtonComponent implements OnInit, OnDestroy {
  translateTokenPopupConfirmTranslationEventSubscription: Subscription = new Subscription();
  @Input() type = 'nameTranslationToken';
  @Input() source = '';
  @Input() target = null;

  constructor(
    public methodService: MethodService,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    public jjtranslationService: JjtranslationService
  ) { }

  ngOnInit() {
    if (isNullOrUndefined(this.target.id) && isNullOrUndefined(this.target.tempItemId)) {
      this.target.tempItemId = this.applicationInfoService.tempId;
      this.applicationInfoService.tempId ++;
    }
    this.translateTokenPopupConfirmTranslationEventSubscription = this.eventService.
    translateTokenPopupConfirmTranslationEvent.subscribe(event => {
      if (event.callSource === this.source) {
        this.jjtranslationService.addTranslationArrayToItem(this.target, event.callItem,
          event.translations, event.target, event.callType);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.translateTokenPopupConfirmTranslationEventSubscription) { this.translateTokenPopupConfirmTranslationEventSubscription.unsubscribe(); }
  }
}
