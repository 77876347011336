import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ApplicationInfoService } from '../application/application-info.service';

@Component({
  selector: 'app-applicationdown',
  templateUrl: './applicationdown.component.html',
  styleUrls: ['./applicationdown.component.scss']
})
export class ApplicationdownComponent implements OnInit {

  constructor(
    private router: Router,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit() {
    this.applicationInfoService.showLoadProjectSpinner = false;
    this.applicationInfoService.errorOccured = true;
    console.warn('ApplicationdownComponent', this.applicationInfoService.errorOccured);
  }

  returnToMainpage() {
    window.open(environment.serverUrl + environment.baseRef, '_self');
    // this.router.navigate(['../' + this.applicationInfoService.mainFrameName]);
  }
}
