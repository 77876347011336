import { Injectable } from '@angular/core';
import { Dictionary } from '../../core/dictionary';
import { GraphQLService, ApolloMethod } from '../../shared/service/graphql.service';
import { EventService } from './event.service';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { SharedAPI } from '../../shared/service/sharedAPI';
import { LoaderService } from '../../shared/service/loader-service';
import { CommonService } from './common.service';
import { isUndefined, isObject } from 'util';

export enum ExternalDataSource_QueryType {
  Query = 'query',
  Update = 'update',
  Insert = 'insert',
  Delete = 'delete',
  UpdateMulti = 'updatemulti',
  MyLeadsQuery = 'myleadsquery'
}

export enum ExternalDataSource_Type {
  API = 1,
  GraphQL = 2,
  Dictionary = 3
}

@Injectable({
  providedIn: 'root'
})

export class ExternaldatasourceService {
  schemaQuery = `query getSchema {
    __type(name: "<0>") {
      kind
      name
      fields {
        name
        type {
          ofType { name }
          name
          kind
        }
      }
    }
  }`;

  constructor(
    private graphQLService: GraphQLService,
    private eventService: EventService,
    private sharedAPI: SharedAPI,
    private applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private commonService: CommonService
  ) {

  }

  initializeExternalDataSources() {
    this.addSchemeToList('ProjectType', 'main');
    this.eventService.applicationInitialized('externalDataSourceInitialized');
  }


  getScheme(schemeName: string, source: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.applicationInfoService.schemes.ContainsKey(schemeName)) {
        resolve(this.applicationInfoService.schemes.Item(schemeName));
      } else {
        this.addSchemeToList(schemeName, source).then(result => {
          resolve(result);
        });
      }
    });
  }

  getDataLookupTableDefinitions(lookupTableType): Promise<any> {
    return new Promise((getDataLookupTableDefinitionsResolve, getDataLookupTableDefinitionsReject) => {
    this.executeExternalDataSource(193)
    .then(getDataLookupTableDefinitionsResult => {
        getDataLookupTableDefinitionsResolve(getDataLookupTableDefinitionsResult);
      })
      .catch(error => {
        getDataLookupTableDefinitionsReject(error);
      });
    });
  }

  addSchemeToList(schemeName: string, source: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log('addSchemeToList', schemeName, source);
      if (!this.applicationInfoService.schemes.ContainsKey(schemeName)) {
        const schemaInfo: Dictionary<string> = new Dictionary<string>();
        const query: string = this.schemaQuery.replace('<0>', schemeName);
        this.graphQLService.apolloGQLpromise(
          source,
          ApolloMethod.Query,
          query
        ).then(result => {
          // console.warn('addSchemeToList', schemeName, source, result);
          if (result.data.__type) {
            result.data.__type.fields.forEach(element => {
              if (element.type.name != null) {
                schemaInfo.Add(element.name, element.type.name);
              } else {
                schemaInfo.Add(element.name, element.type.ofType.name);
              }
            });
            this.applicationInfoService.schemes.Add(schemeName, schemaInfo);
            resolve(this.applicationInfoService.schemes.Item(schemeName));
          } else {
            console.warn('Scheme query didn´t return __type', schemeName);
            reject('Scheme query didn´t return __type');
          }
        }).catch(error => { reject(error); });
      }
    });
  }

  getLookupTableValues(lookUpTableId: any, lookupTableSource = 1): Promise<any> {
    return new Promise<any>((getLookupTableValuesResolve, getLookupTableValuesReject) => {
      let externalDataSourceId = 0;
      switch (lookupTableSource) {
        case 1:
          externalDataSourceId = 202;
          break;
        case 2:
          externalDataSourceId = 255;
          break;
        }
      this.executeExternalDataSource(externalDataSourceId, [lookUpTableId])
      .then(getLookupTableValuesResult => { getLookupTableValuesResolve(getLookupTableValuesResult); })
      .catch(error => { getLookupTableValuesReject(error); });
    });
  }

  executeExternalDataSource(externalDataSourceId: any, args: any[] = [], entityId = null, refreshCache = false): Promise<any> {    
    return new Promise<any>((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {      
      this.executeQuery(this.applicationInfoService.externalDataSources.Item(externalDataSourceId), args, entityId, false, [], refreshCache)
      .then(executeExternalDataSourceResult => { executeExternalDataSourceResolve(executeExternalDataSourceResult); })
      .catch(error => { executeExternalDataSourceReject(error); });
    });
  }

  executeLookUpTable(lookupTableInfo): Promise<any> {
    return new Promise<any>((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {
      let externalDataSourceId = 202;
      if (lookupTableInfo.lookupTableSource.toUpperCase() === 'MAIN') { externalDataSourceId = 255; }
      this.executeExternalDataSource(externalDataSourceId, [lookupTableInfo.lookupTableId])
      .then(executeExternalDataSourceResult => { executeExternalDataSourceResolve(executeExternalDataSourceResult); })
      .catch(error => { executeExternalDataSourceReject(error); });
    });
  }

  executeLookUpTableForModification(lookupTableInfo): Promise<any> {
    return new Promise<any>((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {
      let externalDataSourceId = 386;
      if (lookupTableInfo.lookupTableSource.toUpperCase() === 'MAIN') { externalDataSourceId = 387; }
      this.executeExternalDataSource(externalDataSourceId, [lookupTableInfo.lookupTableId])
      .then(executeExternalDataSourceResult => { executeExternalDataSourceResolve(executeExternalDataSourceResult); })
      .catch(error => { executeExternalDataSourceReject(error); });
    });
  }

  updateLookupTableEntry(lookupTableInfo, entry): Promise<any> {
    let externalDataSourceId = 216;
    if (lookupTableInfo.lookupTableSource.toUpperCase() === 'MAIN') {
      externalDataSourceId = 215;
    }
    return new Promise<any>((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {
      this.executeExternalDataSource(externalDataSourceId, [entry])
      .then(executeExternalDataSourceResult => { executeExternalDataSourceResolve(executeExternalDataSourceResult); })
      .catch(error => { executeExternalDataSourceReject(error); });
    });
  }

  createLookupTableEntry(lookupTableInfo, entry): Promise<any> {
    let externalDataSourceId = 211;
    if (lookupTableInfo.lookupTableSource.toUpperCase() === 'MAIN') {
      externalDataSourceId = 214;
    }
    return new Promise<any>((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {
      this.executeExternalDataSource(externalDataSourceId, [entry])
      .then(executeExternalDataSourceResult => { executeExternalDataSourceResolve(executeExternalDataSourceResult); })
      .catch(error => { executeExternalDataSourceReject(error); });
    });
  }

  createLookupTable(lookupTableInfo, entry): Promise<any> {
    let externalDataSourceId = 212;
    if (lookupTableInfo.lookupTableSource.toUpperCase() === 'MAIN') {
      externalDataSourceId = 213;
    }
    return new Promise<any>((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {
      this.executeExternalDataSource(externalDataSourceId, [entry])
      .then(executeExternalDataSourceResult => { executeExternalDataSourceResolve(executeExternalDataSourceResult); })
      .catch(error => { executeExternalDataSourceReject(error); });
    });
  }

  executeQuery(externalDataSource: any, args: any[] = [], entityId = 0, showLoader = false, namedParams = [], refreshCache = false): Promise<any> {
    if (showLoader) { this.loaderService.display(true); }
    return new Promise<any>((executeQueryResolve, executeQueryReject) => {
      let argCounter = 0;
      if (this.commonService.isNullOrUndefined(externalDataSource)) {
        if (this.applicationInfoService.applicationSettings['showDebugMessages'] == 'true') {
          console.warn('External datasource not found');
        }        
        return;
      }
      switch (externalDataSource.type) {
        case ExternalDataSource_Type.Dictionary:
          const resultArray: any[] = [];
          const dictionary = this.applicationInfoService[externalDataSource.dataQuery];
          dictionary.GetItemArray().forEach(impArrayItem => {
            resultArray.push(dictionary.Item(impArrayItem));
          });
          if (showLoader) { this.loaderService.display(false); }
          executeQueryResolve(resultArray);
          break;

        case ExternalDataSource_Type.API:
        let urlSource = externalDataSource.source;        
        args.forEach(arg => {
          if (isObject(arg)) {
            arg = JSON.stringify(arg);
          }
          const re = new RegExp('<' + argCounter + '>', 'g');
          urlSource = urlSource.replace(re, arg, 'g');
          argCounter = argCounter + 1;
        });
        if (externalDataSource.queryType == 'blob') {
          this.sharedAPI.getBlob(urlSource).subscribe(result => {
            if (showLoader) { this.loaderService.display(false); }
            executeQueryResolve(result);
          }, (error) => {
            if (showLoader) { this.loaderService.display(false); }
            executeQueryReject(error);
          });
        } else {
          this.sharedAPI.getDataFromAPI(urlSource).subscribe(result => {
            if (showLoader) { this.loaderService.display(false); }
            executeQueryResolve(result);
          }, (error) => {
            if (showLoader) { this.loaderService.display(false); }
            executeQueryReject(error);
          });
        }
        break;


        case ExternalDataSource_Type.GraphQL:
          let finalQuery = externalDataSource.dataQuery;        
          args.forEach(arg => {            
            if (isObject(arg)) {
              arg = JSON.stringify(arg);
            }
            const re = new RegExp('<' + argCounter + '>', 'g');
            finalQuery = finalQuery.replace(re, arg, 'g');
            argCounter = argCounter + 1;
          });
          namedParams.forEach(param => {
            const splitParam = param.split(';');
            finalQuery = finalQuery.replace(splitParam[0], splitParam[1]);
          });
          finalQuery = this.commonService.modifyQueryStringWithApplicationInfos(finalQuery);
          finalQuery = this.modifyQueryWithExternalDataSourceQueryModifiers(finalQuery, externalDataSource);
          finalQuery = this.commonService.modifyQueryWithDynamicEntityMember(finalQuery, entityId);
          if (this.applicationInfoService.applicationSettings['showFinalQuery'] == 'true') {
            console.log('ExternalDataSource: FinalQuery', finalQuery);
          }
          if (refreshCache) {
            this.applicationInfoService.externalDataSourceCache.Remove(finalQuery);
          }
          const cacheResult = this.checkExternalDataSourceCache(finalQuery, externalDataSource.isCacheable);
          if (cacheResult === null) {
            this.graphQLService.apolloGQLpromise(externalDataSource.source, ApolloMethod.Query, finalQuery)
            .then(executeQueryResult => {
              if (showLoader) { this.loaderService.display(false); }
              const identifier: string = externalDataSource.identifier;
              let finalResult = this.deserializeIdentifier(executeQueryResult.data, identifier);
              finalResult = this.sortResult(finalResult, externalDataSource);
              this.addResultToExternalDataSourceCache(finalQuery, externalDataSource.isCacheable, finalResult);
              executeQueryResolve(finalResult);
            })
            .catch(error => {
              if (showLoader) { this.loaderService.display(false); }
              executeQueryReject(error);
            });
          } else {
            executeQueryResolve(cacheResult);
          }
        break;
      }
    });
  }

  addResultToExternalDataSourceCache(query: any, isCacheable: any, result): any {
    if (!isCacheable) { return null; }
    this.applicationInfoService.externalDataSourceCache.Add(query, result);
  }

  checkExternalDataSourceCache(query: any, isCacheable: any): any {
    if (!isCacheable) { return null; }
    if (this.applicationInfoService.externalDataSourceCache.ContainsKey(query)) {
      return this.applicationInfoService.externalDataSourceCache.Item(query);
    } else {
      return null;
    }
  }

  sortResult(finalResult: any, externalDataSource: any): string {
    if (externalDataSource.sortColumn) {
      if (finalResult) {
        if (externalDataSource.sortColumn) {
          if (externalDataSource.sortColumn == 'defaultName') {
            this.commonService.sortArrayWithTranslationToken(finalResult);
          } else {
            if (externalDataSource.isSortedAscending) {
              finalResult.sort((a, b) => a[externalDataSource.sortColumn] < b[externalDataSource.sortColumn] ? -1 : 1);
            } else {
              finalResult.sort((a, b) => a[externalDataSource.sortColumn] > b[externalDataSource.sortColumn] ? -1 : 1);
            }  
          }
        }
      }
    }
    return finalResult;
  }

  modifyQueryWithExternalDataSourceQueryModifiers(query: string, externalDataSource: any): string {
    if (this.commonService.isNullOrUndefined(externalDataSource)) {
      return query;
    }
    let finalData = query;
    if (externalDataSource.externalDataSourceQueryMappingFields) {
      externalDataSource.externalDataSourceQueryMappingFields.forEach(element => {        
        let replacementValue: any;
        const splitValue = element.source.split(';');
        switch (element.type) {
          case 20:
            replacementValue = this.applicationInfoService.entities.Item(splitValue[0]).data[splitValue[1]];
            break;
          case 21:
            replacementValue = this.applicationInfoService.controlValues.Item(splitValue[0])[splitValue[1]];
            break;
          case 22:
            replacementValue = this.applicationInfoService.miscSettings[splitValue[0]];
            break;
        }
        if (replacementValue != undefined) {
          finalData = finalData.replace(element.target, replacementValue);
        } else {
          replacementValue = element.defaultValue;
          if (replacementValue === 'true') { replacementValue = true; }
          if (replacementValue === 'false') { replacementValue = false; }
          finalData = finalData.replace(element.target, replacementValue);
        }
      });
    }
    return finalData;
  }

  deserializeIdentifier(resultData: any, identifier: any): any {
      if (resultData == null) {return null; }
      const argsSplit = identifier.split('.');
      let result: any = resultData;
      for (let i = 0; i <= argsSplit.length - 1; i++) {
        if (result[argsSplit[i]] == null) { return null; }
        result = result[argsSplit[i]];
      }
      return result;
  }

  executeControlData2Query(controlDataInfo: any, externalDataSource, params, subControlString = '', customQuery = '', v2Info = ''): Promise<any> {
    return new Promise((executeControlDataResolve, executeControlDataReject) => {
      let v2Params = v2Info[1];
      v2Params = this.updateQueryWithMiscInformation(v2Params, externalDataSource);;
      // console.warn('executeControlData2Query', controlDataInfo);
      let finalQuery = this.applicationInfoService.applicationSettings['controlData2Query'];
      finalQuery = finalQuery.replace('<0>', controlDataInfo);
      finalQuery = finalQuery.replace('<1>', v2Info[0]);
      finalQuery = finalQuery.replace('<2>', JSON.stringify(v2Params));
      finalQuery = this.updateQueryWithMiscInformation(finalQuery, externalDataSource, null, true);

      let query = '';
      let dataSourceApi = 'data';
      if (customQuery !== '') {
        query = customQuery;
      } else {
        dataSourceApi = externalDataSource.source;
        query = externalDataSource.dataQuery;
        query = this.modifyQueryWithParams(query, params);
        query = this.updateQueryWithMiscInformation(query, externalDataSource);
        query = query.replace(/(?:\r\n|\r|\n)/g, ' ');
        query = query.replace('<compactListSubControls>', subControlString);
        query = query.replace('<subControls>', subControlString)
      }

      finalQuery = finalQuery.replace('<1>', query);
      // console.warn('finalQuery', finalQuery);

      this.graphQLService.apolloGQLpromise(dataSourceApi, ApolloMethod.Query, finalQuery)
      .then(executeControlDataResult => {
        executeControlDataResolve(executeControlDataResult.data.controlDataV2);
      })
      .catch(executeControlDataError => {
        console.error('executeControlData', executeControlDataError);
        executeControlDataReject(executeControlDataError);
      });
    });
  }

  executeControlDataQuery(controlDataInfo: any, externalDataSource, params, subControlString = '', customQuery = ''): Promise<any> {
    return new Promise((executeControlDataResolve, executeControlDataReject) => {

      let finalQuery = this.applicationInfoService.applicationSettings['controlDataQuery'];
      finalQuery = finalQuery.replace('<0>', controlDataInfo);
      finalQuery = this.updateQueryWithMiscInformation(finalQuery, externalDataSource);

      let query = '';
      let dataSourceApi = 'data';
      if (customQuery !== '') {
        query = customQuery;
      } else {
        dataSourceApi = externalDataSource.source;
        query = externalDataSource.dataQuery;
        query = this.modifyQueryWithParams(query, params);
        query = this.updateQueryWithMiscInformation(query, externalDataSource);
        query = query.replace(/(?:\r\n|\r|\n)/g, ' ');
        query = query.replace('<compactListSubControls>', subControlString);
        query = query.replace('<subControls>', subControlString)
      }

      finalQuery = finalQuery.replace('<1>', query);
      // console.warn('finalQuery', finalQuery);

      this.graphQLService.apolloGQLpromise(dataSourceApi, ApolloMethod.Query, finalQuery)
      .then(executeControlDataResult => {
        executeControlDataResolve(executeControlDataResult.data.controlData);
      })
      .catch(executeControlDataError => {
        console.error('executeControlData', executeControlDataError);
        executeControlDataReject(executeControlDataError);
      });
    });
  }

  modifyQueryWithParams(query, params) {
    let argCounter = 0;
    params.forEach(arg => {
      if (arg !== null && typeof arg === 'object') {
        arg = JSON.stringify(arg);
      }
      const re = new RegExp('<' + argCounter + '>', 'g');
      query = query.replace(re, arg, 'g');
      argCounter = argCounter + 1;
    });
    return query;
  }

  updateQueryWithMiscInformation(query, externalDataSource, entityId = null, convertSpecialValues = false) {
    query = this.commonService.modifyQueryStringWithApplicationInfos(query, convertSpecialValues);
    query = this.modifyQueryWithExternalDataSourceQueryModifiers(query, externalDataSource);
    query = this.commonService.modifyQueryWithDynamicEntityMember(query, entityId);
    return query;
  }
}
