<div *ngIf="mode == 1">
    <div class="externalUserHeader">
        {{ 'ExternalUser.Label.Description' | texttransform }}
    </div>
    <div class="everleadShadowBox">
        <div class="externalUserRow">
            <div class="externalUserRow__label">{{ 'ExternalUser.Label.FirstName' | texttransform }}</div>
            <div class="externalUserRow__value">
                <input type="text" [(ngModel)]="newUser.firstName" class="externalUserInput">
            </div>
        </div>
        <div class="externalUserRow">
            <div class="externalUserRow__label">{{ 'ExternalUser.Label.LastName' | texttransform }}</div>
            <div class="externalUserRow__value">
                <input type="text" [(ngModel)]="newUser.lastName" class="externalUserInput">
            </div>
        </div>
        <div class="externalUserRow">
            <div class="externalUserRow__label">{{ 'ExternalUser.Label.EMail' | texttransform }}</div>
            <div class="externalUserRow__value">
                <input type="text" [(ngModel)]="newUser.email" class="externalUserInput">
            </div>
        </div>
    </div>
    <div class="externalUserRow__buttons">        
        <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="createExternalUser()"
            [disabled]="checkIfUserCanBeCreated()">{{ 'ExternalUser.Label.CreateExternalUser' | texttransform }}</button>    
    </div>
</div>

<div *ngIf="mode == 2">
    <div *ngIf="userAlreadyExists" class="everleadShadowBox">
        {{ 'ExternalUser.Label.UserAlreadyExists' | texttransform }}
    </div>
    <div *ngIf="!userAlreadyExists" class="everleadShadowBox">
        <div>{{ 'ExternalUser.Label.UserCreated' | texttransform }}</div>
        <div>{{ 'ExternalUser.Label.UserCreatedDescription' | texttransform }}</div>
        <br>
        <div><b>{{ newUser.givenName }} {{ newUser.familyName }}</b></div>
        <div>{{ resultUser.email }}</div>
        <div>{{ resultUser.password }}</div>
    </div>
    <!-- <div class="externalUserAdditionalInfo">{{ 'ExternalUser.Label.CreateAnotherUser' | texttransform }}</div> -->
    <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="createAnotherUser()">
        {{ 'ExternalUser.Label.CreateAnotherUser' | texttransform }}</button>
</div>