<div class="navigationItem" [title]="getMenuItemText(menuItem) | texttransform" [ngClass]="{ 
    dropDownMenu: menuItem.isExtendable, 
    fingerCursor: menuItem.clickType,
    navigationTopLevel: !menuItem.isChild && menuItem.clickType != 'loadNavigation',
    navigationTopLevelCollapsed: !menuItem.isChild && menuItem.clickType != 'loadNavigation' && applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] != true,
    navigationChildLevel: menuItem.isChild,
    navigationItem__isSelected: isItemSelected(),
    navigationSubmenu: menuItem.clickType == 'loadNavigation'
}" 
    (click)="jsonMenuItemClicked(menuItem)" *ngIf="jsonItemVisible(menuItem)">
    <div class="packageFeatureAvailable" *ngIf="packagefeatureService.navigationItemHasNonActiveFeature(menuItem)">
        <i class="fas fa-star packageFeatureAvailable__icon"></i>
    </div>

    <div class="navigationItem__inner" [ngClass]="{ 
        fingerCursor: menuItem.clickType,
        everleadCoral: !menuItem.isChild && menuItem.clickType != 'loadNavigation'
    }">
        <div class="navigationItem__iconContainer">
            <svg-icon [src]="getJsonImage(menuItem)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
            [ngClass]="{ navigationStroke: menuItem.stroke == true }"
            [title]="getMenuItemText(menuItem) | texttransform" class="navigationItem__redesignIcon" *ngIf="menuItem.iconType == 'svg'"></svg-icon>
        </div>
        <label class="navigationItem__inner__label" [ngClass]="{ fingerCursor: menuItem.clickType, everleadCoral: !menuItem.isChild && menuItem.clickType != 'loadNavigation' }">            
            {{ getMenuItemText(menuItem) | texttransform }}
            <span *ngIf="packagefeatureService.navigationItemHasNonActiveFeature(menuItem)" class="everleadCoral"> (PRO) </span>
        </label>    
        <div class="playIcon" *ngIf="menuItem.clickType == 'loadNavigation'"><i class="fas fa-chevron-circle-right"></i></div>
        <div *ngIf="menuItem.isExtendable" class="playIcon fingerCursor">
            <i class="fas fa-chevron-circle-down " *ngIf="applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] != true"></i>
            <i class="fas fa-chevron-circle-up " *ngIf="applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] == true"></i>
        </div>
    </div>
</div>
