import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { Subscription } from 'rxjs';
import { isArray, isNullOrUndefined } from 'util';
import { ObjectDeserializeService } from 'app/jollyjupiter/pipe/object-deserialize.service';

@Component({
  selector: 'app-custom-radio',
  templateUrl: './custom-radio.component.html',
  styleUrls: ['./custom-radio.component.scss']
})
export class CustomRadioComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updateControlContentEventSubscription: Subscription = new Subscription();
  updateLookupTableEventSubscription: Subscription = new Subscription();
  radioGuid = '';

  constructor(
    private injector: Injector,
    private objectDeserializeService: ObjectDeserializeService
  ) {
    super(injector);
    this.valueType = ValueType.Number;
  }

  clearValue() {
    this.controlValue = null;
    this.callMethod('onchange');
  }

  ngOnDestroy() {
    if (this.updateControlContentEventSubscription) { this.updateControlContentEventSubscription.unsubscribe(); }
    if (this.updateLookupTableEventSubscription) { this.updateLookupTableEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.eventService.updateLookupTableEvent.subscribe(event => {
      if (!isNullOrUndefined(this.entityMemberlookupTable)) {
        if (Number(event.lookupTableId) === Number(this.entityMemberlookupTable.lookupTableId)) {
          this.getRadioData();
        }
      }
    });

    this.updateControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        // console.warn('updateControlContentEventSubscription - valid', this.controlDefinition.id, event);
        this.getRadioData();
        this.onControlValueChanged(null);
      }
    });

    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }

    // ExternalDataSource
    this.getRadioData();
    this.checkAttributes();
  }

  getRadioData() {
    if (this.externalDataSource) {
      this.dataKey = this.externalDataSource.dataKey;
      this.loadingControlData = true;
      // this.externalDatasourceService.executeQuery(this.externalDataSource)
      this.externalDatasourceService.executeExternalDataSource(this.externalDataSource.id)
        .then(data => {
          this.cleanUpResult(data);
        })
        .catch(error => {
          this.loadingControlData = false;
          console.error(error);
        });
    } else {
      this.loadingControlData = true;
      if (!isNullOrUndefined(this.entityMemberlookupTable)) {
        this.externalDatasourceService.executeLookUpTable(this.entityMemberlookupTable)
        .then(result => {
          this.cleanUpResult(result);
        })
        .catch(error => {
          this.loadingControlData = false;
          console.error(error);
        });
      }
    }
  }

  cleanUpResult(data) {
    this.loadingControlData = false;
    this.data = data;
    if (this.data) {
      if (isArray(this.data)) {
        this.data.forEach(dataElement => {
          if (!isNullOrUndefined(this.externalDataSource)) {
            if ((this.externalDataSource.dataValue === 'defaultName') && (dataElement.nameTranslationToken)) {
              dataElement.defaultName = dataElement.nameTranslationToken.tokenFullName;
            }
          } else {
            if (!isNullOrUndefined(dataElement.nameTranslationToken)) {
              dataElement.defaultName = dataElement.nameTranslationToken.tokenFullName;
            }
          }
        });
      } else {
        this.data = [data];
      }
    }
  }

  onControlValueChanged(event) {
    this.controlValue = event.target.value;
    this.callMethod('onchange');
  }

  setDropdownStyle() {
    if (this.applicationInfoService.useNewDesign) {
      return {
        'width': '100%',
        'height': '55px'
      };
    } else {
      return {
        'width': '100%',
        'height': this.controlDefinition.height
      };
    }
  }

  setButtonStyle() {
    return {
      'width': '10%',
      'height': this.controlDefinition.height
    };
  }
}
