<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor leadStateHeaderDiv">
        <label style="padding-left: 3px;" class="normalWhiteSpace">FTP - Verwaltung</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
        <button (click)="saveFTP()" class="jjControlSubmenuButton" style="white-space: normal;"><i
            class="fas fa-save"></i></button>
        <button (click)="newFTP()" class="jjControlSubmenuButton" style="white-space: normal;"><i
                class="fas fa-plus-square"></i></button>
        <input [(ngModel)]="filterString" style="height: 21px; margin-top: 2px; margin-right: 3px;" class="floatRight">
        <i class="fas fa-filter floatRight" style="margin-top: 5px; margin-right: 3px;"></i>
    </div>
</div>
<div style="border-bottom: 1px solid gainsboro; margin-bottom: 3px;">
    <label class="ftpControlId">ID</label>
    <label style="width: 30px;"></label>
    <label class="ftpControl1">Host</label>
    <label class="ftpControl2">Port</label>
    <label class="ftpControl3">User</label>
    <label class="ftpControl4">Passwort</label>
</div>
<div *ngFor="let ftpConnection of ftpConnections" class="connectionPanel">
    <label class="ftpControlId">{{ ftpConnection.id }}</label>
    <i class="fas fa-trash-alt" (click)="deleteFTPConnection(ftpConnection)" style="margin: 0 5px 0 10px; color: red; width: 30px;"></i>

    <input [(ngModel)]="ftpConnection.host" class="ftpControl1">
    <input [(ngModel)]="ftpConnection.port" class="ftpControl2" type="number">
    <input [(ngModel)]="ftpConnection.username" class="ftpControl3">
    <input [(ngModel)]="ftpConnection.password" class="ftpControl4">
</div>