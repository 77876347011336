import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-datafilter-groupitem-member-details',
  templateUrl: './datafilter-groupitem-member-details.component.html',
  styleUrls: ['./datafilter-groupitem-member-details.component.scss']
})
export class DatafilterGroupitemMemberDetailsComponent implements OnInit {
  @Input() filterSegmentMember = null;
  @Input() disabled = false;
  @Input() controlValueFilterOperator = null;
  @Input() filterId = null;
  @Input() additionalEntityMemberValue = null;
  @Input() filterParameter = null;
  @Input() filterParameter2 = null;

  @Input() member;
  @Input() entity;

  @Output() onFilterParameterChanged = new EventEmitter<{
    filterId: any,
    filterSegmentMemberId: any,
    filterParameter1: any ,
    filterParameter2: any
  }>();

  memberDetails = null;
  data = null;
  controlType = null;
  showParameter2 = false;
  lastCheckedMemberId = null;

  constructor(
    private entityService: EntityService,
    private externalDatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit() {
    this.getElementInfo();
    this.getExternalDataSource();
  }

  getExternalDataSource() {

  }

  changeFilter(event: any = '#') {
    if (event !== '#') {
      this.filterParameter = event;
    }
    this.onFilterParameterChanged.emit({
      filterId: this.filterId,
      filterSegmentMemberId: this.filterSegmentMember.id,
      filterParameter1: this.filterParameter,
      filterParameter2: this.filterParameter2} );
  }

  getElementInfo() {
    if (this.member) {
      if (this.applicationInfoService.entities.Item(this.entity)) {
        this.applicationInfoService.entities.Item(this.entity).entityMembers.forEach(member => {
          if (member.defaultName === this.member.defaultName) {
            this.memberDetails = member;
          }
        });
      }
    }
    // console.log('getElementInfo member', this.controlValueFilterOperator, this.entity, this.member, this.memberDetails);
    if (this.controlValueFilterOperator) {
      switch (this.controlValueFilterOperator.id) {
        case 1: // TEXT LIKE
          this.controlType = 4;
          this.showParameter2 = false;
          break;
        case 2: // LIST
          this.controlType = 99;
          this.showParameter2 = false;
          break;
        case 3: // LIST BETWEEN
          this.controlType = 99;
          this.showParameter2 = false;
          break;
        case 4: // DATE BETWEEN
          this.controlType = 5;
          this.showParameter2 = true;
          break;
        case 5: // NUMBER CSV
          this.controlType = 99;
          this.showParameter2 = false;
        break;
        case 6: // NUMBER BETWEEN
          this.controlType = 6;
          this.showParameter2 = true;
          break;
        case 7: // EQUAL
          this.initEqualityFields();
          this.showParameter2 = false;
          break;
        case 9: // NOT EQUAL
          this.initEqualityFields();
          this.showParameter2 = false;
          break;
      }
    }
  }

  initEqualityFields() {
    if (this.memberDetails) {
      switch (this.memberDetails.memberType) {
        case 'BOOL':
          this.controlType = 2;
          break;
        case 'NUMERIC':
          if (this.memberDetails.externalDataSource) {
            this.getMemberDataFromExternalDataSource(this.memberDetails.externalDataSource);
            this.controlType = 3;
          } else {
            this.controlType = 6;
          }
          break;
        case 'TEXT':
            this.controlType = 4;
            break;
      }
    }
  }

  getMemberDataFromExternalDataSource(externalDataSource: any) {
    if (this.lastCheckedMemberId === this.member.id) {
      return;
    }
    this.lastCheckedMemberId = this.member.id;
    this.externalDatasourceService.executeQuery(externalDataSource)
    .then(data => {
      this.data = data;
      if (this.data) {
        if (!externalDataSource.dataValue.includes(';')) {
          this.data.sort((a, b) => a[externalDataSource.dataValue].toLowerCase()
          < b[externalDataSource.dataValue].toLowerCase() ? -1 : 1);
        }
        this.data.forEach(dataElement => {
          if ((externalDataSource.dataValue === 'defaultName') && (dataElement.nameTranslationToken)) {
            dataElement.defaultName = dataElement.nameTranslationToken.tokenFullName;
          }
        });
      }
    })
    .catch(data => {
      console.error(data);
    });
  }
}
