import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService, VariableType } from 'app/core/application/application-info.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { LanguageService } from 'app/shared/translation/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-palette',
  templateUrl: './custom-palette.component.html',
  styleUrls: ['./custom-palette.component.scss']
})
export class CustomPaletteComponent implements OnInit, OnDestroy {
  updateMenuBarSubscription: Subscription = new Subscription();
  loadDashboardSubscription: Subscription = new Subscription();

  customEventSubscription: Subscription = new Subscription();

  @Input() isGeneralPalette = true;
  @Input() controlId = 38320;  
  controlDefinition = null;
  childIsContainer = false;
  selectedChild = null;
  visibleSubControls = [];
  paletteObject = null;
  currentInnerHTML = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public designerService: DesignerService,
    private uiService: UiService,
    private domSanitizer: DomSanitizer,
    private eventService: EventService,
    private commonService: CommonService,
    public languageService: LanguageService,
    public axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {   
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.visibleSubControls = [];
        if (          
          !this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['customPalette']) &&
          this.applicationInfoService.applicationSettings['customPalette'] != ''
        ) {
          this.paletteObject = JSON.parse(this.applicationInfoService.applicationSettings['customPalette']);
          this.getVisibleChilds();
        }        
      }
      if (event.id == 'paletteChanged') {
        this.paletteObject = JSON.parse(this.applicationInfoService.applicationSettings['customPalette']);
        setTimeout(() => { this.getVisibleChilds() }, 300);
      }
      if (event.id == 'pageContentChanged') {
        this.applicationInfoService.paletteCollapsed = true;
        setTimeout(() => { this.getVisibleChilds() }, 300);
      }
      if (event.id == 'applicationPageResized') {
        setTimeout(() => { this.getVisibleChilds() }, 300);
      }
      if (event.id == 'collapsPalette') {
        this.applicationInfoService.paletteCollapsed = true;
      }
    }); 
  }

  ngOnDestroy(): void {
    if(this.loadDashboardSubscription){ this.loadDashboardSubscription.unsubscribe(); }
    if(this.updateMenuBarSubscription){ this.updateMenuBarSubscription.unsubscribe(); }
    if(this.customEventSubscription){ this.customEventSubscription.unsubscribe(); }
  }

  getPaletteTop(): number {
    let returnValue = 150;
    // return returnValue;
    var referenceElement = document.getElementById('routerOutletDiv');
    if (referenceElement) {
      returnValue = referenceElement.getBoundingClientRect().top;
      if (!this.isGeneralPalette) {
        returnValue = returnValue + referenceElement.getBoundingClientRect().height;
      }
    }
    return returnValue;
  }

  togglePanel() {
    this.applicationInfoService.paletteCollapsed = !this.applicationInfoService.paletteCollapsed;
    if (this.applicationInfoService.paletteCollapsed == false) {
      this.eventService.customEvent.emit({ id: 'collapseExtension' });
    }
  }

  editControl() {
    this.eventService.showJjPopup('', 'paletteeditor', '700px;900px');
    if (this.applicationInfoService.designerMode) {
      this.designerService.editUiControl(this.controlDefinition);
    }
  }

  getMaximumHeightOfSubContainer(elementId, substractValue = 10) {        
    if (this.applicationInfoService.isMobile()) {
      return '100%';
    } else {
      return Number(this.uiService.getDesignSpanPosition(elementId, substractValue)) + 'px';
    }    
  }

  getVisibleChilds() {
    this.visibleSubControls = [];
    if (this.paletteObject == null) { return; }

    this.paletteObject.controls.forEach(item => {
      if (this.isSubControlVisible(item)) {
        this.visibleSubControls.push(item);
      }
    });
    if (this.visibleSubControls.length > 0) {
      this.selectedChild = this.visibleSubControls[0];
      this.getSelectedChildsInnerHTML();
    } else {
      this.selectedChild = null;
      this.currentInnerHTML = null;
      this.applicationInfoService.paletteCollapsed = true;
    }
  }

  getSelectedChildsInnerHTML() {
    this.currentInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.selectedChild.controlSettingPaletteWhatToDisplay);
  }

  isSubControlVisible(child) {
    let returnValue = true;
    if (child == null) { return true; }
    if (child.isActive == false) { return false; }

    const locationCheck = child.controlSettingPaletteLocation;
    let locationCheckMinX = child.controlSettingPaletteLocationMinX;
    let locationCheckMaxX = child.controlSettingPaletteLocationMaxX;
    let locationCheckPartOfLocation = child.controlSettingPaletteLocationCheckPart;
    if (this.commonService.isNullOrUndefined(locationCheckPartOfLocation)) {
      locationCheckPartOfLocation = false;
    }
    
    if (locationCheck != null && locationCheck != '') {
      returnValue = false;      
      if (locationCheckPartOfLocation) {
        if (this.commonService.checkIfStringContainsString(document.location.href, locationCheck)) { returnValue = true; }
      } else {
        if (this.commonService.checkIfStringContainsString(document.location.href, '/' + locationCheck + '?')) { returnValue = true; }
      }
    } 

    if (!this.commonService.isNullOrUndefined(locationCheckMinX)) {
      if (this.applicationInfoService.innerWidth < Number(locationCheckMinX)) {
        returnValue = false;
      }
    }
    if (!this.commonService.isNullOrUndefined(locationCheckMaxX)) {
      if (this.applicationInfoService.innerWidth > Number(locationCheckMaxX)) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  getSelectedChildWith() {
    if (this.applicationInfoService.paletteCollapsed) {
      return 0;
    }    
    if (this.selectedChild == null || this.applicationInfoService.paletteCollapsed) {
      return 0;
    }
    if (this.commonService.isNullOrUndefined(this.selectedChild.controlSettingPaletteWidth)) {
      return 400;
    } else {
      if (this.selectedChild.controlSettingPaletteWidth == '') {
        return 400;
      } else {
        return Number(this.selectedChild.controlSettingPaletteWidth);
      }
    }
  }
}
