import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  tooltip = '';
  tooltipHeader = '';
  showTooltipTemplate = false;
  left = 0;
  top = 0;
  delay = 500;
  showItem = false;
  controlTemplate = '<jj-universal controlid="23113"> </jj-universal>';
  innerHTML = null;
  
  constructor(
    private commonService: CommonService,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    setTimeout(() => {              
      this.showItem = true;
    }, this.delay);

    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.controlTemplate);
  }

  isValueEmpty() {
    let returnValue = false;
    if (this.commonService.isNullOrUndefined(this.tooltip)) { returnValue = true; }
    if (this.tooltip == '') { returnValue = true; }
    return returnValue;
  }

  showHeader() {
    let returnValue = true;
    if (this.commonService.isNullOrUndefined(this.tooltipHeader)) { returnValue = false;}
    if (this.tooltipHeader == '') { returnValue = false;}
    return returnValue;
  }
}
