import { Injectable } from '@angular/core';
import { isNullOrUndefined, isNull } from 'util';
import { EventService } from '../event.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from '../externaldatasource.service';
import { CommonService } from '../common.service';
import { ControlType, UiService } from '../ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from '../messaging.service';
import { JjtranslationService } from '../jjtranslation.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { promise } from 'protractor';
import { isIP } from 'net';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DesignerService {
  designMenuEnabledArray = [];
  currentControl = null;

  constructor(
    private eventService: EventService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private uiService: UiService,
    private authService: AuthService,
    private axivasTranslateService: AxivasTranslateService,
    private jjtranslationService: JjtranslationService,
    private messagingService: MessagingService
  ) { }

  editUiControl(control: any) {
    if (!this.commonService.isNullOrUndefined(control)) {
      this.currentControl = control;
      this.eventService.showJjPopup('General.Label.EditContainer', 'uicontrol-design-main', '80');
    }
  }

  saveUiControl(control: any) {
    return new Promise((saveUiControlResolve, saveUiControlReject) => {
      const promiseArray: Promise<any>[] = [];
      this.loaderService.display(true);
      this.saveControl(control).then(() => {
        // Subcontrols
        control.childs.forEach((child, index) => {
          promiseArray.push(this.saveControl(child, control.id).then(() => {
            child.childs.forEach((subChild, subChildIndex) => {
              promiseArray.push(this.saveControl(subChild, child.id));
            });
          }));
        });
        // delete subcontrols?
        if (this.applicationInfoService.controlsBackUp.ContainsKey(control.logicalControlId)) {
          this.applicationInfoService.controlsBackUp.Item(control.logicalControlId).childs.forEach(child => {
            let controlFound = false;
            control.childs.forEach(controlChilds => {
              if (controlChilds.id === child.id) {
                controlFound = true;
              }
            });
            if (!controlFound) {
              promiseArray.push(this.deleteControl(child));
              child.childs.forEach(subChild => {
                promiseArray.push(this.deleteControl(subChild));
              });
            }
          });
        }
        Promise.all(promiseArray)
        .then(() => {
          saveUiControlResolve(null);
          this.applicationInfoService.controlsBackUp.Set(control.logicalControlId, this.commonService.createCopyOfArray(control));
          this.messagingService.showDefaultSuccess('', 'General._.SaveSuccessfull', false);
          this.loaderService.display(false);
        })
        .catch(error => {
          saveUiControlReject(error);
          this.loaderService.display(false);
        });
      }).catch(error => {
        saveUiControlReject(error);
        this.loaderService.display(false);
      });
    });
  }

  saveControl(control, parentControlId = null): Promise<any> {
    return new Promise((saveControlResolve, saveControlReject) => {
      // checkTranslation
      if (this.commonService.isNullOrUndefined(parentControlId)) {
        control.childs.forEach((child, index) => {
          child.order = index;
          child.childs.forEach((subChild, subChildIndex) => {
            subChild.order = subChildIndex;
          });
        });
      }
      const promiseArrayBefore: Promise<any>[] = [];
      this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(promiseArrayBefore, control,
        'descriptionTranslationToken', null, 'descriptionTranslationTokenId',
        control.descriptionTranslationToken, 'uiControl');
      this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(promiseArrayBefore,
        control, 'nameTranslationToken', null, 'nameTranslationTokenId', control.nameTranslationToken,
        'uiControl');

      // Delete stuff
      promiseArrayBefore.push(this.deleteControlRelatedItems(control.removedAttributeModifierGroups, 251));
      promiseArrayBefore.push(this.deleteControlRelatedItems(control.removedUiControlSecurityPermissions, 258));
      promiseArrayBefore.push(this.deleteControlRelatedItems(control.removedAttributeModifier, 254));
      Promise.all(promiseArrayBefore)
      .then(() => {
        control.removedUiControlSecurityPermissions = [];
        control.removedAttributeModifier = [];
        control.removedAttributeModifierGroups = [];
        let newControl = false;
        if (!(parentControlId === null)) { control.parentControlId = parentControlId; }
        let externalDataSourceId = this.applicationInfoService.applicationSettings['updateControlExternalDataSourceId'];
        let queryString = this.commonService.getModifyArrayBody(
          control, [], [],
          this.applicationInfoService.entities.Item(this.applicationInfoService.applicationSettings['uiControlLogicalEntityId'])
        );
        if (this.commonService.isNullOrUndefined(control.id)) {
          newControl = true;
          externalDataSourceId = this.applicationInfoService.applicationSettings['createControlExternalDataSourceId'];
          queryString = this.commonService.getModifyArrayBody(control, ['id'], [],
            this.applicationInfoService.entities.Item(this.applicationInfoService.applicationSettings['uiControlLogicalEntityId']));
        }

        // Saving the control
        this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [queryString])
        .then(result => {
          const promiseArray: Promise<any>[] = [];
          control.id = result.id;
          if (newControl) {
            promiseArray.push(this.addLogicalUiControlToControl(control));
          }
          if (!this.commonService.isNullOrUndefined(control.controlAttributeModifierGroups)) {
            control.controlAttributeModifierGroups.forEach((modifierGroup, index) => {
              modifierGroup.order = index;
              promiseArray.push(this.saveUiControlAttributeModifierGroup(modifierGroup));
            });
          }
          if (!this.commonService.isNullOrUndefined(control.uiControlSecurityPermissions)) {
            control.uiControlSecurityPermissions.forEach(permission => {
              permission['uiControlId'] = control.id;
              promiseArray.push(this.saveUiControlSecurityPermission(permission));
            });
          }
          Promise.all(promiseArray)
          .then(() => { this.updateControlInfoInDictionaries(control); saveControlResolve(result); })
          .catch(error => { saveControlReject(error); });
        })
        .catch(error => {
          saveControlReject(error);
        });
      });
    });
  }

  deleteControl(control): Promise<any> {
    return new Promise((deleteControlResolve, deleteControlReject) => {
      this.externaldatasourceService.executeExternalDataSource(203, [control.id])
      .then(() => {
        deleteControlResolve(null);
      })
      .catch(error => {
        deleteControlReject(error);
      });
    });
  }

  deleteControlRelatedItems(array, externalDataSourceId, useIdFromArray = false): Promise<any> {
    return new Promise((deleteControlRelatedItemsResolve, deleteControlRelatedItemsReject) => {
      if (!this.commonService.isNullOrUndefined(array)) {
        const promiseArray: Promise<any>[] = [];
        array.forEach(arrayItem => {
          let itemId = arrayItem;
          if (useIdFromArray) {
            itemId = arrayItem.id;
          }
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [itemId]));
        });
        Promise.all(promiseArray)
        .then(() => { deleteControlRelatedItemsResolve(null); })
        .catch(error => { deleteControlRelatedItemsReject(error); });
      } else {
        deleteControlRelatedItemsResolve(null);
      }
    });
  }

  saveUiContainer(container, containerControlList, containerControlListBackup) {
    const promiseArray: Promise<any>[] = [];
    promiseArray.push(this.saveControl(container));
    containerControlList.forEach((control, index) => {
      if (JSON.stringify(control) !== JSON.stringify(containerControlListBackup[index])) {
        promiseArray.push(this.saveControl(control));
      }
    });
    this.loaderService.display(true);
    Promise.all(promiseArray)
    .then(() => {
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe(() => {
        this.eventService.refreshContentEvent.emit(container.logicalControlId);  
      });
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  addLogicalUiControlToControl(control): Promise<any> {
    return new Promise((addLogicalUiControlToControlResolve, addLogicalUiControlToControlReject) => {
      control.logicalControlId = control.id;
      this.externaldatasourceService.executeExternalDataSource(210, [control.id, control.id])
      .then(() => {
        addLogicalUiControlToControlResolve(null);
        console.warn('addLogicalUiControlToControl added', control.id);
      })
      .catch(error => {
        addLogicalUiControlToControlReject(error);
      });
    });
  }

  addControlToContainer(container, control, index): Promise<any> {
    return new Promise((addControlToContainerResolve, addControlToContainerReject) => {
      this.externaldatasourceService.executeExternalDataSource(208, [
        this.applicationInfoService.projectID,
        control.logicalControlId,
        container.logicalControlId,
        index
      ])
      .then(() => {
        addControlToContainerResolve(null);
      })
      .catch(error => {
        addControlToContainerReject(error);
      });
    });
  }
  
  updateContainerControlDefinition(container, control, index): Promise<any> {
    return new Promise((addControlToContainerResolve, addControlToContainerReject) => {
      this.externaldatasourceService.executeExternalDataSource(608, [
        this.applicationInfoService.projectID,
        control.logicalControlId,
        container.logicalControlId,
        index
      ])
      .then(() => {
        addControlToContainerResolve(null);
      })
      .catch(error => {
        addControlToContainerReject(error);
      });
    });
  }

  deleteContainerContent(projectId, containerId): Promise<any> {
    return new Promise((deleteContainerContentResolve, deleteContainerContentReject) => {
      this.externaldatasourceService.executeExternalDataSource(209, [projectId, containerId])
      .then(() => {
        deleteContainerContentResolve(null);
      })
      .catch(error => {
        deleteContainerContentReject(error);
      });
    });
  }

  createNewControl(entity = null, member = null): any {
    const newControl = new Object();
    // Control type
    let controlType = 2;

    // default values
    newControl['width'] = '100%';
    newControl['reserverSpaceLeft'] = '0';
    newControl['labelPos'] = 1;
    newControl['isActive'] = true;
    newControl['methods'] = [];
    newControl['childs'] = [];
    newControl['attributeModifiers'] = [];
    newControl['uiControlExternalDataSources'] = [];
    newControl['uiControlSecurityPermissions'] = [];
    newControl['controlAttributeModifierGroups'] = [];
    newControl['inline'] = 0;
    newControl['displayTextWidth'] = '100px';
    newControl['displayTextHeight'] = '25px';

    if (!this.commonService.isNullOrUndefined(member)) {
      switch (member.memberType) {
        case 'TEXT':
          break;
        case 'NUMERIC':
          if (!this.commonService.isNullOrUndefined(member.externalDataSource)
            || !this.commonService.isNullOrUndefined(member.externalDataSource)
            || !this.commonService.isNullOrUndefined(member.lookupTableId)) {
            controlType = ControlType.Dropdown;
          }
          break;
        case 'DATETIME':
          controlType = ControlType.Calendar;
          break;
        case 'BOOL':
          newControl['labelPos'] = 0;
          controlType = ControlType.CheckBox;
          break;
      }
    }

    newControl['controlType'] = controlType;

    if (!this.commonService.isNullOrUndefined(entity)) {
      newControl['uiEntityInstanceId'] = entity.uiEntityInstance;
    }
    if (!this.commonService.isNullOrUndefined(member)) {
      newControl['value'] = member.defaultName;
      if (!this.commonService.isNullOrUndefined(member.nameTranslationToken)) {
        // newControl['displayText'] = this.axivasTranslateService.getTranslationTextForToken(member.nameTranslationToken.tokenFullName);
        newControl['displayText'] = member.nameTranslationToken.tokenFullName;
      } else {
        if (!this.commonService.isNullOrUndefined(member.nameTranslationTokenId)) {
          console.warn(this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId))
          newControl['displayText'] = this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId);
        } else {
          newControl['displayText'] = member.defaultName;
        }        
      }
      newControl['entityMemberId'] = member.id;
    }

    if (!this.commonService.isNullOrUndefined(entity)) {
      if (!this.commonService.isNullOrUndefined(entity.lookupEntityDynamicalType)) {
        newControl['labelPos'] = 2;
        newControl['displayTextWidth'] = '100%';
      }
    }
    return newControl;
  }

  updateControlInfoInDictionaries(control) {
    this.applicationInfoService.controls.Set(control.logicalControlId, control);
    this.applicationInfoService.controlsBackUp.Set(control.logicalControlId, control);
  }

  getAttributeModifierSources(control) {
    let array = [];
    array = this.addEntityToAttributeModifierSources(7, array);
    array = this.addEntityToAttributeModifierSources(8, array);
    array = this.addEntityToAttributeModifierSources(this.applicationInfoService.accountEntityId, array);
    array = this.addEntityToAttributeModifierSources(this.applicationInfoService.contactEntityId, array);
    array = this.addEntityToAttributeModifierSources(this.applicationInfoService.taskEntityId, array);
    return array;
  }

  addEntityToAttributeModifierSources(entityId, array) {
    const entity = this.applicationInfoService.entities.Item(entityId);
    if (!this.commonService.isNullOrUndefined(entity)) {
      entity.entityMembers.forEach(member => {
        array.push({
          defaultName: this.axivasTranslateService.getTranslationTextForObject(entity, 'nameTranslationToken').concat(
            ' - ', this.axivasTranslateService.getTranslationTextForObject(member, 'nameTranslationToken')),
          id: entity.uiEntityInstance.toString().concat(';', member.id),
          member: member
        });
      });
    }
    return array;
  }

  saveUiControlAttributeModifierGroup(uiControlAttributeModifierGroup): Promise<any> {
    let externalDataSourceId = 250;
    let param = this.commonService.getModifyArrayBody(uiControlAttributeModifierGroup, ['controlAttributeModifiers']);
    if (this.commonService.isNullOrUndefined(uiControlAttributeModifierGroup.id)) {
      externalDataSourceId = 249;
      param = this.commonService.getModifyArrayBody(uiControlAttributeModifierGroup, ['controlAttributeModifiers', 'id']);
    }
    return new Promise((saveUiControlAttributeModifierGroupResolve, saveUiControlAttributeModifierGroupReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveUiControlAttributeModifierGroupResult => {
        uiControlAttributeModifierGroup.id = saveUiControlAttributeModifierGroupResult.id;
        const promiseArray: Promise<any>[] = [];
        uiControlAttributeModifierGroup.id = saveUiControlAttributeModifierGroupResult.id;
        uiControlAttributeModifierGroup.controlAttributeModifiers.forEach((uiControlAttributeModifier, index) => {
          uiControlAttributeModifier.order = index;
          uiControlAttributeModifier.uiControlAttributeModifierGroupId = uiControlAttributeModifierGroup.id;
          promiseArray.push(this.saveUiControlAttributeModifier(uiControlAttributeModifier));
        });
        Promise.all(promiseArray)
        .then(() => {
          saveUiControlAttributeModifierGroupResolve(saveUiControlAttributeModifierGroupResult);
        })
        .catch(error => {
          saveUiControlAttributeModifierGroupReject(error);
        });
      })
      .catch(error => {
        // this.messagingService.showDefaultError('', '' + error);
        saveUiControlAttributeModifierGroupReject(error);
      });
    });
  }

  saveUiControlAttributeModifier(uiControlAttributeModifier): Promise<any> {
    let externalDataSourceId = 253;
    let param = this.commonService.getModifyArrayBody(uiControlAttributeModifier, ['attributeModifierSourceMember'], [],
      this.applicationInfoService.entities.Item('86'));
    if (this.commonService.isNullOrUndefined(uiControlAttributeModifier.id)) {
      externalDataSourceId = 252;
      param = this.commonService.getModifyArrayBody(uiControlAttributeModifier, ['attributeModifierSourceMember', 'id'], [],
        this.applicationInfoService.entities.Item('86'));
    }
    return new Promise((saveUiControlAttributeModifierResolve, saveUiControlAttributeModifierReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveUiControlAttributeModifierResult => {
        uiControlAttributeModifier.id = saveUiControlAttributeModifierResult.id;
        saveUiControlAttributeModifierResolve(saveUiControlAttributeModifierResult);
      })
      .catch(error => {
        // this.messagingService.showDefaultError('', '' + error);
        saveUiControlAttributeModifierReject(error);
      });
    });
  }

  saveUiControlSecurityPermission(uiControlSecurityPermission): Promise<any> {
    let externalDataSourceId = 257;
    let param = this.commonService.getModifyArrayBody(uiControlSecurityPermission, []);
    if (this.commonService.isNullOrUndefined(uiControlSecurityPermission.id)) {
      externalDataSourceId = 256;
      param = this.commonService.getModifyArrayBody(uiControlSecurityPermission, ['id']);
    }
    return new Promise((saveUiControlSecurityPermissionResolve, saveUiControlSecurityPermissionReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveUiControlSecurityPermissionResult => {
        uiControlSecurityPermission.id = saveUiControlSecurityPermissionResult.id;
        saveUiControlSecurityPermissionResolve(saveUiControlSecurityPermissionResult);
      })
      .catch(error => {
        saveUiControlSecurityPermissionReject(error);
      });
    });
  }

  getAttributeModifierInfoString(control, returnString = '') {
    // return '';
    control.controlAttributeModifierGroups.forEach(group => {
      switch (group.attribute) {
        case 'visible':
          returnString = returnString.concat(
            this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.Visible'), ' => ');
          break;
        case 'enabled':
          returnString = returnString.concat(
            this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.Enabled'), ' => ');
          break;
      }
      switch (group.newValue) {
        case 'true':
          returnString = returnString.concat(
            this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.True'), '\n');
          break;
        case 'false':
          returnString = returnString.concat(
            this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.False'), '\n');
          break;
      }
      returnString = returnString.concat(
        this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.If'), '\n');
      group.controlAttributeModifiers.forEach(modifier => {
        returnString = returnString.concat('  ');
        if (!this.commonService.isNullOrUndefined(modifier.compareValue)) {
          const data = modifier.compareValue.split(';');
          const entity = this.applicationInfoService.entities.Item(data[0]);
          const member = this.applicationInfoService.entityMember.Item(data[1]);
          returnString = returnString.concat(
            this.axivasTranslateService.getTranslationTextForObject(entity, 'nameTranslationToken'),
            '-',
            this.axivasTranslateService.getTranslationTextForObject(member, 'nameTranslationToken'),
            ' ');
            switch (modifier.compareType) {
              case '=':
                returnString = returnString.concat(
                  this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.SameAs'), ' ');
                break;
              case '!=':
                returnString = returnString.concat(
                  this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.Not'), ' ');
                break;
              case 'LIKE':
                returnString = returnString.concat(
                  this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.Like'), '');
                break;
              case 'STARTSWITH':
                returnString = returnString.concat(
                  this.axivasTranslateService.getTranslationTextForToken('Control.Label.AttributeModifier.StartsWith'), ' ');
                break;
            }
            // RefValue
            if (this.commonService.isNullOrUndefined(member.lookupTableId)) {
              returnString = returnString.concat(modifier.refValue);
            } else {
              if (!this.commonService.isNullOrUndefined(this.applicationInfoService.dataLookupTableDefinitions.Item(modifier.refValue))) {
                returnString = returnString.concat(
                  this.applicationInfoService.dataLookupTableDefinitions.Item(modifier.refValue).defaultName);
              }
          }
        }
      });
    });
    return returnString;
  }

  updateUiContainerUiControl(uiContainerUiControl):Promise<any> {
    return new Promise((resolve, reject) => {
      this.externaldatasourceService.executeExternalDataSource(608,[
        this.commonService.getModifyArrayBody(uiContainerUiControl, ['control', 'orderAdded'])
      ])
      .then(updateUiContainerUiControlResult => { resolve (updateUiContainerUiControlResult); })
      .catch(error => { reject(error); })
    });
  }
}
