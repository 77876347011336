import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-custom-aipromptresult',
  templateUrl: './custom-aipromptresult.component.html',
  styleUrls: ['./custom-aipromptresult.component.scss']
})
export class CustomAipromptresultComponent implements OnInit {
  resultToDisplay = null;
  resultToDisplayPdf = null;
  loadingData = false;
  aiPrompts = [];
  selectedPompt = null;
  forceUpdate = false;
  featureId = null;
  featureNotFound = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private packagefeatureService: PackagefeatureService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_aiBattleCards']);    
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.getAiPrompts();
      this.featureNotFound = false;
    }    
  }

  getAiPrompts() {
    this.externaldatasourceService.executeExternalDataSource(775, []).then(getAiPromptsResult => {
      this.aiPrompts = getAiPromptsResult.filter(result => result.promptLookupId == this.applicationInfoService.miscSettings['AIPromptTypeId']);
      if (this.aiPrompts.length == 1) {
        this.runPrompt(this.aiPrompts[0].id);
      }
    });
  }

  runPrompt(promptId, forceUpdate = false) {
    if (promptId == null) { return; }
    
    this.loadingData = true;
    let contactId = null;
    if (this.applicationInfoService.currentContact) {
      contactId = this.applicationInfoService.currentContact.id;
    }
    this.externaldatasourceService.executeExternalDataSource(871, [
      this.applicationInfoService.currentAccount.id,
      contactId,
      promptId,
      null,
      null,
      forceUpdate
    ])
    .then(result => {
      this.loadingData = false;
      if (result != null) {
        const prompt = this.aiPrompts.find(prompt => prompt.id == this.selectedPompt.id);
        this.resultToDisplay = result.responseText;        
        let resultToDisplayPdfHtml = this.modifyHeaderString(this.applicationInfoService.applicationSettings['battleCardPdfHeader'], prompt, result);
        this.resultToDisplayPdf = resultToDisplayPdfHtml;
      }      
    });
  }

  public exportPDF() {
    const body = {
      html: this.resultToDisplayPdf,
      fileName: 'test.pdf'
    }
    this.sharedAPI.createPDFFromHTML(body).subscribe(pdfToHtmlResult => {
      this.commonService.downloadFile(pdfToHtmlResult, 'application/pdf',
        'BattleCard '.concat(this.applicationInfoService.currentAccount.name1, ' ',
        this.commonService.getDateTimeString(new Date(Date.now())), '.pdf')
      );

    });
    return;   
  }

  modifyHeaderString(headerString, prompt, result) {
    // BattleCard
    headerString = headerString.replace('{{BattleCardName}}', prompt.defaultName);
    
    headerString = this.commonService.replaceStringWithEntityValues(headerString, 1, 'account');
    headerString = this.commonService.replaceStringWithEntityValues(headerString, 4, 'contact');

    // Add the result of the aiPrompt
    if (this.commonService.checkIfStringContainsString(headerString, '{{PromptResult}}')) {
      headerString = headerString.replace('{{PromptResult}}', result.responseText);
    } else {
      headerString = headerString.concat(result.responseText);
    }    

    return headerString;
  }
}
