import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-task-available',
  templateUrl: './no-task-available.component.html',
  styleUrls: ['./no-task-available.component.scss']
})
export class NoTaskAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
