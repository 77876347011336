import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pie-graph',
  templateUrl: './pie-graph.component.html',
  styleUrls: ['./pie-graph.component.scss']
})

export class PieGraphComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  showCanvas = true;
  @Input() controlid = '';
  @Input() data = [];
  @Input() alignLeft = false;
  @Input() size = 400;
  @Input() colors = [
    { background: '#ED5C73', font: 'white' },
    { background: '#F07E26', font: 'white' },
    { background: '#80B800', font: 'white' },
    { background: '#59AA8E', font: 'white' },
    { background: '#AEA7B0', font: 'white' },
    { background: '#3EA4C3', font: 'white' },
    { background: '#1D1645', font: 'white' },
    { background: '#57518E', font: 'white' },
    { background: '#346188', font: 'white' },
    { background: '#1B3A55', font: 'white' }
  ];
  @Input() borderwidth = 2;
  @Input() legend = false;
  @Input() labels = true;
  @Input() header = '';
  @Input() darkmode = false;
  @Input() headercolor = 'white';
  dataAvailable = false;
  totalAmount = 0;

  constructor(
    private commonService: CommonService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updatePie' && event.value == this.controlid) {
        this.createPie();
      }
    });

    if (this.controlid == '') {
      this.controlid = 'pie_'.concat(this.commonService.createGuid());
    }
    this.createPie();

    /* console.log('data', this.data) */
  }

  ngOnDestroy(): void {
      if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getHeaderLabelId() {
    return this.controlid + 'headerLabel';
  }

  getMaxLegendWidth() {
    return 'calc(100% - '.concat(this.size.toString(), 'px)');
  }

  createPie() {
    this.showCanvas = false;
    setTimeout(() => {
      this.showCanvas = true;
      setTimeout(() => {
        var canvas = <HTMLCanvasElement> document.getElementById(this.controlid);
        var ctx = canvas.getContext("2d");
        var lastend = 0;
        this.totalAmount = 0;

        for(var e = 0; e < this.data.length; e++)
        {
          if (this.data[e].isSelected) {
            this.totalAmount += this.data[e].value;
          }
        }

        var off = 10;
        var w = (canvas.width - off) / 2;
        var h = (canvas.height - off) / 2;
        let colorCounter = 0;

        this.dataAvailable = false;
        for (var i = 0; i < this.data.length; i++) {
          if (this.data[i].isSelected && this.data[i].value > 0) {
            this.dataAvailable = true;
            const displayValue = this.data[i].value;
            const displayName = this.data[i].displayName;
            ctx.fillStyle = this.colors[i].background;
            colorCounter ++;
            ctx.strokeStyle ='white';
            ctx.lineWidth = this.borderwidth;
            ctx.beginPath();
            ctx.moveTo(w,h);
            var len =  (displayValue / this.totalAmount) * 2 * Math.PI
            var r = h - off / 2
            ctx.arc(w , h, r, lastend, lastend + len, false);
            ctx.lineTo(w,h);
            ctx.fill();
            ctx.stroke();
            ctx.fillStyle ='white';
            ctx.font = "15px Poppins";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var mid = lastend + len / 2
            if (this.labels) {
              ctx.fillText('  ' + displayName, w + Math.cos(mid) * (r/2), h + Math.sin(mid) * (r/2));
              ctx.fillText('  ' + displayValue, w + Math.cos(mid) * (r/2), 15 + h + Math.sin(mid) * (r/2));
            }
            lastend += Math.PI * 2 * (displayValue / this.totalAmount);
          }
        }
      }, 100);
    }, 100);
  }

  getBackgroundColor(i) {
    return this.colors[i].background;
  }

  getPercentage(dataItem) {
    let returnValue = null;
    if (dataItem.value != 0) {
      returnValue = ((dataItem.value * 100) / this.totalAmount);
      returnValue = (Math.round(returnValue * 100) / 100).toFixed(1)
    } else {
      returnValue = 0;
    }
    return returnValue + '%';
  }

  getMaxValue() {
    return this.size + 'px';
  }

  getLegendColor() {
    if(this.darkmode) {
      return 'white';
    } else {
      return '#1C3A56';
    }
  }
}
