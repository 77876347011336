import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizard-infobutton',
  templateUrl: './wizard-infobutton.component.html',
  styleUrls: ['./wizard-infobutton.component.scss']
})
export class WizardInfobuttonComponent implements OnInit {
  @Input() displayText = '';
  constructor() { }

  ngOnInit(): void {
  }

}
