<div [ngStyle]="setControlStyle('control')">
  <div class="jjTab" style="width: 100%;">
    <button [ngClass]="{ 
            jjActivetab: questionaire.logicalControlId == selectedTabIndex,
            jjControlDefaultColor: questionaire.logicalControlId == selectedTabIndex,
            jjInactivetab: questionaire.logicalControlId != selectedTabIndex
          }" *ngFor="let questionaire of controlDefinition.childs; let currentIndex = index"
      (click)="setCurrentChild(questionaire)">
      {{ questionaire?.displayText | texttransform }}
      <span *ngIf="applicationInfoService.entities.Item(questionaire.uiEntityInstanceId)?.lookupEntityDynamicalType?.id == 25"> ({{ 'Questionaire.Label.AccountLevel' | texttransform }}) </span>      
      <span *ngIf="applicationInfoService.entities.Item(questionaire.uiEntityInstanceId)?.lookupEntityDynamicalType?.id == 26"> ({{ 'Questionaire.Label.ContactLevel' | texttransform }}) </span>      
      <span *ngIf="applicationInfoService.entities.Item(questionaire.uiEntityInstanceId)?.lookupEntityDynamicalType?.id == 27"> ({{ 'Questionaire.Label.TaskLevel' | texttransform }}) </span>      
      <i class="fas fa-edit" (click)="editSubControls(questionaire.logicalControlId)" 
        [ngClass]="{ hideInDom: !userService.hasPermission('CanReadAllUsers') }"></i>
    </button>
  </div>

  <div 
    class="mainContainer noScrollbar"
    stlye="height: 100px"
  >
    <div       
      *ngFor="let questionaire of controlDefinition.childs; let currentIndex = index" 
      [id]="questionaire.logicalControlId" 
    >
      <div *ngIf="questionaire.logicalControlId == selectedTabIndex" style="padding: 10px;">                
        <span *ngIf="applicationInfoService.entities.Item(questionaire.uiEntityInstanceId)?.lookupEntityDynamicalType?.id == 26 
        && (applicationInfoService.currentContact !== null || applicationInfoService.designerMode)">
          <app-custom-container controluiid={{questionaire.logicalControlId}}  additionalClass="contactContainer"></app-custom-container>
        </span>
        <span *ngIf="applicationInfoService.entities.Item(questionaire.uiEntityInstanceId)?.lookupEntityDynamicalType?.id == 25
          && (applicationInfoService.currentAccount !== null || applicationInfoService.designerMode)">
          <app-custom-container controluiid={{questionaire.logicalControlId}} additionalClass="accountContainer"></app-custom-container>
        </span>
      </div>
    </div>
  </div>
</div>