<div class="vipWrapper">
    <div class="vipTabDropdown">
        <select [(ngModel)]="currentViewMode">
            <option [ngValue]="viewMode.Agent">{{'Vip.Label.AvailableVipGroups' | texttransform}}</option>
            <option [ngValue]="viewMode.Groups">{{'Vip.Label.Groups' | texttransform}}</option>
            <option [ngValue]="viewMode.Projects" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'">
                {{'Vip.Label.ManageProjects' | texttransform}}</option>
            <option [ngValue]="viewMode.Contacts">{{'Vip.Label.Contacts' | texttransform}}</option>
            <option [ngValue]="viewMode.Accounts">{{'Vip.Label.Accounts' | texttransform}}</option>
            <option [ngValue]="viewMode.Notification" *ngIf="userService.hasPermission('CanCreateVipGroup')">
                {{'Vip.Label.Notifications' | texttransform}}</option>
        </select>
    </div>
    <div class="jjTab vipTabControl">
        <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Agent }"
            (click)="currentViewMode = viewMode.Agent">
            {{'Vip.Label.AvailableVipGroups' | texttransform}}
        </button>
        <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Groups }"
            (click)="currentViewMode = viewMode.Groups">
            {{'Vip.Label.Groups' | texttransform}}
        </button>
        <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Projects }"
            (click)="currentViewMode = viewMode.Projects" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'">
            {{'Vip.Label.ManageProjects' | texttransform}}
        </button>
        <!-- <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Supervisor }"
            (click)="currentViewMode = viewMode.Supervisor" *ngIf="userService.hasPermission('CanCreateVipGroup')">
            {{'Vip.Label.Edit' | texttransform}}
        </button> -->
        <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Contacts }"
            (click)="currentViewMode = viewMode.Contacts">
            {{'Vip.Label.Contacts' | texttransform}}
        </button>
        <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Accounts }"
            (click)="currentViewMode = viewMode.Accounts">
            {{'Vip.Label.Accounts' | texttransform}}
        </button>
        <button class="tablinks " [ngClass]="{ jjActivetab: currentViewMode == viewMode.Supervisor }"
            (click)="currentViewMode = viewMode.Notification" *ngIf="userService.hasPermission('CanCreateVipGroup')">
            {{ 'Vip.Label.Notifications' | texttransform}}            
        </button>
    </div>

    <div *ngIf="currentViewMode == viewMode.Notification" class="maxHeight autoOverflowY vipSection">
        <label class="everleadSmallHeader">{{ 'Vip.Label.NotificationDesc' | texttransform }}</label>
        <div class="vipNotification">        
            <div class="vipNotification__label">
                {{ 'UserSettings.Label.VipAlertFrequency' | texttransform }}
            </div>
            <div class="vipNotification__interaction" *ngIf="settingsService.userSettings['VipAlertFrequency']">
                <select [(ngModel)]="settingsService.userSettings['VipAlertFrequency'].value"
                    (change)="settingsService.setUserSettingEx(settingsService.userSettings['VipAlertFrequency'])">
                    <option value="none">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.None' | texttransform }}</option>
                    <option value="direct">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Direct' | texttransform }}</option>
                    <option value="daily">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Daily' | texttransform }}</option>
                    <option value="weekly">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly' | texttransform }}</option>                
                </select>
            </div>
        </div>
        <div class="vipNotification">
            <div class="vipNotification__label">
                {{ 'UserSettings.Label.NormalVisitorAlertFrequency' | texttransform }}
            </div>
            <div class="vipNotification__interaction" *ngIf="settingsService.userSettings['NormalVisitorAlertFrequency']">
                <select [(ngModel)]="settingsService.userSettings['NormalVisitorAlertFrequency'].value"
                    (change)="settingsService.setUserSettingEx(settingsService.userSettings['NormalVisitorAlertFrequency'])">
                    <option value="none">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.None' | texttransform }}</option>
                    <option value="direct">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Direct' | texttransform }}</option>
                    <option value="daily">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Daily' | texttransform }}</option>
                    <option value="weekly">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly' | texttransform }}</option>                
                </select>
            </div>
        </div>
    </div>

    <div *ngIf="currentViewMode == viewMode.Groups" class="maxHeight autoOverflowY vipSection">
        <div class="vip__groups">
            <div class="everleadSmallHeader">{{ 'Vip.Label.VipGroupDescription' | texttransform }}</div>
            <ng-container *ngIf="loadingSettings == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                texttransform }}
            </ng-container>

            <span *ngIf="getGroups(vipGroups, false).length > 0 && loadingSettings == false">
                <label class="everleadSmallHeader">{{ 'Vip.Label.GlobalGroups' | texttransform }}</label>
                <div class="groupheader">
                    <div class="groupheader__name everleadSmallHeader">{{ 'Vip.Label.Name' | texttransform }}</div>
                    <div class="groupheader__description everleadSmallHeader">{{ 'Vip.Label.Description' | texttransform }}</div>
                </div>
                <div *ngFor="let vipGroup of getGroups(vipGroups, false)" class="vip__groupItem">
                    <div class="vip__groupItem__name">
                        <label class="ellipsisText" [tippy]="vipGroup.defaultName">{{ vipGroup.defaultName
                            }}</label>
                    </div>
                    <div class="vip__groupItem__comment">
                        <label class="ellipsisText" [tippy]="vipGroup.comment">{{ vipGroup.comment }}</label>
                    </div>

                    <div class="vip__item__groupInfo fingerCursor" (click)="toggleGroup(vipGroup)" [ngClass]="{
                    vip__item__groupInfo__green: checkIfGroupIsActivated(vipGroup)
                }">
                        <i class="fas fa-sm vip__item__delete__icon" [ngClass]="{
                        'fa-check-square': checkIfGroupIsActivated(vipGroup),
                        'fa-square': !checkIfGroupIsActivated(vipGroup)
                    }"></i>
                    </div>
                </div>
            </span>

            <span *ngIf="getGroups(vipGroups, true).length > 0 && loadingSettings == false">
                <label class="everleadSmallHeader">{{ 'Vip.Label.ProjectSpecificGroups' | texttransform }}</label>
                <div class="groupheader">
                    <div class="groupheader__name everleadSmallHeader">{{ 'Vip.Label.Name' | texttransform }}</div>
                    <div class="groupheader__description everleadSmallHeader">{{ 'Vip.Label.Description' | texttransform }}</div>
                </div>
                <div *ngFor="let vipGroup of getGroups(vipGroups, true)" class="vip__groupItem">
                    <div class="vip__groupItem__name">
                        <label class="ellipsisText" [tippy]="vipGroup.defaultName">{{ vipGroup.defaultName
                            }}</label>
                    </div>
                    <div class="vip__groupItem__comment">
                        <label class="ellipsisText" [tippy]="vipGroup.comment">{{ vipGroup.comment }}</label>
                    </div>

                    <div class="vip__item__groupInfo fingerCursor" (click)="toggleGroup(vipGroup)" [ngClass]="{
                    vip__item__groupInfo__green: checkIfGroupIsActivated(vipGroup)
                }">
                        <i class="fas fa-sm vip__item__delete__icon" [ngClass]="{
                        'fa-check-square': checkIfGroupIsActivated(vipGroup),
                        'fa-square': !checkIfGroupIsActivated(vipGroup)
                    }"></i>
                    </div>
                </div>
            </span>
        </div>
    </div>

    <div *ngIf="currentViewMode == viewMode.Contacts" class="maxHeight autoOverflowY">
        <label class="everleadSmallHeader">{{ 'Vip.Label.ContactsDesc' | texttransform }}</label>
        <app-custom-universal [controlid]="217844"></app-custom-universal>
    </div>

    <div *ngIf="currentViewMode == viewMode.Accounts" class="maxHeight autoOverflowY">
        <label class="everleadSmallHeader">{{ 'Vip.Label.AccountsDesc' | texttransform }}</label>
        <app-custom-universal [controlid]="217845"></app-custom-universal>
    </div>

    <div *ngIf="currentViewMode == viewMode.Agent" class="maxHeight autoOverflowY vipSection">
        <div class="vipSection__header">
            <div class="vip__sectionWrapper">
                <label class="vip__headerLabel">{{ 'Vip.Label.MyVipSettings' | texttransform }}</label>
                <button class="vip__headerButton" (click)="createUserVipSetting()"
                    [tippy]="'Vip.Label.CreateNew' | texttransform"><i class="fas fa-plus"></i></button>
            </div>    
            <div class="everleadSmallHeader">{{ 'Vip.Label.VipSettingDescription' | texttransform }}</div>
            <label class="everleadSmallHeader" *ngIf="vipList.length > 0">{{ 'Vip.Label.ProjectSpecificEntries' | axivastranslate }}</label>
        </div>

        <div class="vipSection__content">
            <ng-container *ngIf="loadingSettings == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                texttransform }}
            </ng-container>
            
            <div class="vipPersonalItem everleadShadowBox everleadShadowBox2" *ngFor="let vipItem of vipList" [ngClass]="{
                hideInDom: vipItem.typeRefLookupId == 6640 || vipItem.typeRefLookupId == 8060
            }">
                <div class="vipPersonalItem__line">
                    <div class="vipPersonalItem__line__label">{{ 'Vip.Label.Value' | texttransform }}</div>
                    <div class="vipPersonalItem__line__value">
                        <input [(ngModel)]="vipItem.value" class="vip__item__text__textbox" (change)="updateVipSetting(vipItem)"
                            [placeholder]="'Vip.Label.VipValuePlaceholder' | texttransform">
                    </div>
                </div>

                <div class="vipPersonalItem__line">
                    <div class="vipPersonalItem__line__label">{{ 'Vip.Label.Type' | texttransform }}</div>
                    <div class="vipPersonalItem__line__value">
                        <select [(ngModel)]="vipItem.typeRefLookupId" (change)="updateVipSetting(vipItem)"
                            class="vip__item__type__select">
                            <option *ngFor="let lookupItem of lookupItems" [ngValue]="lookupItem.id">{{ lookupItem.defaultName }}</option>
                        </select>
                    </div>
                </div>

                <div class="vipPersonalItem__line">
                    <div class="vipPersonalItem__line__label">{{ 'Vip.Label.Comment' | texttransform }}</div>
                    <div class="vipPersonalItem__line__value">
                        <input [(ngModel)]="vipItem.comment" class="vip__item__text__textbox" (change)="updateVipSetting(vipItem)">
                    </div>
                </div>
                <div class="vipPersonalItem__delete">
                    <button class="everleadCustomButton" (click)="deleteVipSetting(vipItem.id)">{{ 'Vip.Label.DeletePersonalSetting' | texttransform }}</button>
                </div>               
        </div>
    </div>    

    <div *ngIf="currentViewMode == viewMode.Projects" class="maxHeight autoOverflowY vipProjects">
        <div class="everleadSmallHeader vipProjects__header">{{'Vip.Label.ProjectDescription' | texttransform}}</div>
        <div class="vipProjects__search">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString">
        </div>

        <div class="maxHeight vipProjects__projects">            
            <!-- <app-custom-universal [controlid]=listId></app-custom-universal> -->
            <div *ngFor="let project of projects" class="everleadShadowBox vipProjects__item" [ngClass]="{ hideInDom: !isItemFiltered(project) }">
                <label>{{project.projectName}}</label>
                <div>
                    <i class="fas fa-sticky-note fingerCursor" (click)="toggleArray(projectsDefault, project, 'NormalAlertProjectIds')" [tippy]="'Vip.Label.TitleDefault' | texttransform"
                        [ngClass]="{ everleadCoral: checkIfActive(projectsDefault, project) }"></i>
                    <i class="far fa-star fingerCursor" (click)="toggleArray(projectsVIP, project, 'VipAlertProjectIds')" [tippy]="'Vip.Label.TitleVip' | texttransform"
                        [ngClass]="{ everleadCoral: checkIfActive(projectsVIP, project) }"></i>    
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="currentViewMode == viewMode.Supervisor" class="maxHeight autoOverflowY">
        <div *ngIf="applicationInfoService.isDeveloper">
            <div class="vip__sectionWrapper">
                <label class="vip__headerLabel">{{ 'Vip.Label.AdminVipGroups' | texttransform }}</label>
                <button class="vip__headerButton" (click)="createVipGroup()"
                    [tippy]="'Vip.Label.CreateNew' | texttransform"><i class="fas fa-plus"></i></button>
            </div>

            <div class="vip__groups">
                <div *ngFor="let vipGroup of vipGroups">
                    <div class="vip__groupItem" *ngIf="vipGroup.projectId == null">
                        <div class="vip__groupItem__name">
                            <input type="text" [tippy]="vipGroup.defaultName" [(ngModel)]="vipGroup.defaultName"
                                (change)="saveVipGroup(vipGroup)">
                        </div>
                        <div class="vip__groupItem__comment">
                            <input type="text" [tippy]="vipGroup.defaultName" [(ngModel)]="vipGroup.comment"
                                (change)="saveVipGroup(vipGroup)">
                        </div>
                        <div class="vip__item__groupInfo fingerCursor" (click)="editVipGroup(vipGroup)">
                            <i class="fas fa-edit vip__item__delete__icon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="vip__sectionWrapper">
            <label class="vip__headerLabel">{{ 'Vip.Label.AvailableVipGroups' | texttransform }}</label>
            <button class="vip__headerButton" (click)="createVipGroup(true)"
                [tippy]="'Vip.Label.CreateNew' | texttransform"><i class="fas fa-plus"></i></button>
        </div>

        <ng-container *ngIf="loadingGroups == true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
            texttransform }}
        </ng-container>

        <div class="vip__groups">
            <div *ngFor="let vipGroup of vipGroups">
                <div class="vip__groupItem" *ngIf="vipGroup.projectId == applicationInfoService.projectID">
                    <div class="vip__groupItem__name">
                        <input type="text" [tippy]="vipGroup.defaultName" [(ngModel)]="vipGroup.defaultName"
                            (change)="saveVipGroup(vipGroup)">
                    </div>
                    <div class="vip__groupItem__comment">
                        <input type="text" [tippy]="vipGroup.comment" [(ngModel)]="vipGroup.comment"
                            (change)="saveVipGroup(vipGroup)">
                    </div>
                    <div class="vip__item__groupInfo fingerCursor" (click)="editVipGroup(vipGroup)">
                        <i class="fas fa-edit vip__item__delete__icon"></i>
                    </div>
                </div>
            </div>
        </div>




        <ng-container *ngIf="loadingSettings == true && selectedGroup == null">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
            texttransform }}
        </ng-container>


        <div *ngIf="selectedGroup != null">
            <div class="vip__sectionWrapper">
                <label class="vip__headerLabel">{{ selectedGroup.defaultName }}</label>
                <button class="vip__headerButton" (click)="createGroupVipSetting()"
                    [tippy]="'Vip.Label.CreateNewGroupAlert' | texttransform"><i class="fas fa-plus"></i></button>
            </div>
            <div class="everleadShadowBox">
                {{ 'Vip.Label.VipSettingPerGroupDescription' | texttransform }}
            </div>
            <ng-container *ngIf="loadingSettings == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                texttransform }}
            </ng-container>
            <div class="vip__wrapper">
                <div class="vip__item" *ngFor="let vipItem of vipListForGroup">
                    <div class="vip__item__text">
                        <div class="vip__item__text__label">
                            <label class="vip__label">
                                {{ 'Vip.Label.Value' | texttransform }}
                            </label>
                        </div>
                        <input [(ngModel)]="vipItem.value" class="vip__item__text__textbox"
                            (change)="updateVipSetting(vipItem)"
                            [placeholder]="'Vip.Label.VipValuePlaceholder' | texttransform">
                    </div>
                    <div class="vip__item__type">
                        <div class="vip__item__text__label">
                            <label class="vip__label__type">
                                {{ 'Vip.Label.Type' | texttransform }}
                            </label>
                        </div>
                        <select [(ngModel)]="vipItem.typeRefLookupId" (change)="updateVipSetting(vipItem)"
                            class="vip__item__type__select">
                            <option *ngFor="let lookupItem of lookupItems" [ngValue]="lookupItem.id">{{
                                lookupItem.defaultName
                                }}
                            </option>
                        </select>
                    </div>
                    <div class="vip__item__type">
                        <div class="vip__item__text__label" [tippy]="'Vip.Label.Comment' | texttransform">
                            <i class="fas fa-comment-alt"></i>
                        </div>
                        <input [(ngModel)]="vipItem.comment" class="vip__item__text__textbox"
                            (change)="updateVipSetting(vipItem)">
                    </div>

                    <div class="fingerCursor" (click)="refreshWhois(vipItem)" *ngIf="applicationInfoService.isDeveloper"
                        style="margin-right: 5px;">
                        <i class="fas fa-sync"></i>
                    </div>

                    <div class="vip__item__delete fingerCursor" (click)="deleteVipSetting(vipItem.id)">
                        <i class="fas fa-trash vip__item__delete__icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>