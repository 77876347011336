import { Component, OnInit, HostListener } from '@angular/core';
import { CdkDragDrop, transferArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { Dictionary } from '../../../../core/dictionary';
import { ApplicationInfoService } from '../../../../core/application/application-info.service';

@Component({
  selector: 'app-custom-dragdrop',
  templateUrl: './custom-dragdrop.component.html',
  styleUrls: ['./custom-dragdrop.component.scss']
})
export class CustomDragdropComponent implements OnInit {
  // Mobile
  innerWidth: number = 0;  
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  content: any[] = [];
  header: any[] = [];
  availableLists: Dictionary<any> = new Dictionary<any>();

  constructor(
    private applicationInfoService: ApplicationInfoService
  ) { 

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    
    this.header.push('Aktuell zugeordnet');
    this.header.push('Verfügbar');
    this.content.push(["Christian Stuck", "Alexander Markau"]);
    this.content.push(["Yaron Zohar", "Ivan Mestrovic", "Sascha Winheim"]);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data, 
        event.previousIndex, 
        event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  setControlStyle(type: string, modifier: number = 0) {
    switch (type) {
      case 'panelwidth':
        if(this.applicationInfoService.isMobile()) {
          return {
            'width': '100%'                        
          }
        } else {
          return {
          'width': 100 / this.header.length + '%'
        }
      }
    }
  }
}
