<div class="consentManagementDashboardItem__wrapper maxHeight">
    <div class="consentManagementDashboardItem__body">
        <div class="consentManagementDashboardItem__selectionHeader">
            <div class="consentManagementDashboardItem__selectionHeader__line">
                <label class="everleadCoral">{{ 'ConsentManagementDashboard.Label.Period' | texttransform }}</label>
                <select [(ngModel)]="lastXDays" (change)="changeSelection()" class="consentManagementDashboardItemDropdown">
                    <option [ngValue]=null>{{ 'ConsentManagementDashboard.Label.FullPeriod' | texttransform }}</option>
                    <option [ngValue]=toNumber(7)>{{ 'ConsentManagementDashboard.Label.Period7' | texttransform }}</option>
                    <option [ngValue]=toNumber(14)>{{ 'ConsentManagementDashboard.Label.Period14' | texttransform }}</option>
                    <option [ngValue]=toNumber(21)>{{ 'ConsentManagementDashboard.Label.Period21' | texttransform }}</option>
                    <option [ngValue]=toNumber(30)>{{ 'ConsentManagementDashboard.Label.Period30' | texttransform }}</option>
                </select>
            </div>
            <label class="ellipsisText consentManagementDashboardItem__selectedItems">
                <span class="everleadCoral">{{ 'ConsentManagementDashboard.Label.SelectedMedia' | texttransform }}:</span>
                {{ getSelectedValueString() }}
            </label>
            <div class="consentManagementDashboardItem__selectionHeader__switch">
                <input type="radio" [(ngModel)]="mode" value="1" (change)="changeSelection()"> OptIn
                <input type="radio" [(ngModel)]="mode" value="2" (change)="changeSelection()"> OptOut
            </div>
            <div class="consentManagementDashboardItem__selectionHeader__icon">
                <i class="fas fa-lg fa-cog fingerCursor" (click)="isMediaselectionExpanded = !isMediaselectionExpanded"></i>
            </div>
        </div>
        <div class="consentManagementDashboardItem__content">
            <div *ngIf="isMediaselectionExpanded" class="consentManagementDashboardItem__mediaListContainer">
                <div *ngFor="let medialistItem of availableMedia" class="consentManagementDashboardItem__mediaListItem"
                    (click)="changeSelection(medialistItem)"
                    [id]="getId(medialistItem)">
                    <i class="fas fingerCursor fa-lg " [ngClass]="{
                        'fa-square': !medialistItem.isSelected,
                        'fa-check-square': medialistItem.isSelected
                    }"></i>
                    {{ medialistItem.defaultName | texttransform }}
                </div>
            </div>
            <div class="consentManagementDashboardItem__detailContainer">
                <div class="consentManagementDashboardItem__canvas maxWidth">
                    <div *ngIf="loadingData" class="consentManagementDashboardItem__spinner maxWidth">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
                    </div>

                    <app-echarts *ngIf="!loadingData" [height]="150"
                        [chart]="'pie'" [controlid]="pieId" [chartData]="chartData" [darkmode]="false" [legend]="false" class="maxWidth" [height]="100">
                    </app-echarts>  

                </div>
                <div [innerHTML]="innerHTML" class="consentManagementDashboardItemContainer"></div>
                <!-- <div class="consentManagementDashboardItemFooter">
                    <button class="everleadCustomButton" (click)="startMailshot()">{{'ConsentManagementDashboard.Label.StartMailShot'|texttransform}}</button>
                    <button class="everleadCustomButton" (click)="startMicroCampaign()">{{'ConsentManagementDashboard.Label.StartMicroCampaign'|texttransform}}</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
