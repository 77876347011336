import { Injectable, EventEmitter } from '@angular/core';
import { isNullOrUndefined, isNull, isUndefined } from 'util';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Injectable({
  providedIn: 'root'
})
export class ImportHelperService {
  public suggestValuesEvent: EventEmitter<any> = new EventEmitter<any>();
  public importDataEvent: EventEmitter<any> = new EventEmitter<any>();

  importDisabled = true;
  sheetSelected = false;
  currentImportFile = null;
  currentImportFileFormData = null;
  importResult = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private sharedAPI: SharedAPI,
    private loaderService: LoaderService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  getAvailableValuesForLookupTable(lookUpTableDefinitionId): Promise<any> {
    return new Promise((getAvailableValuesForLookupTableResolve, getAvailableValuesForLookupTableReject) => {
      const availableValues = [];
      if (this.commonService.isNullOrUndefined(lookUpTableDefinitionId)) {
        console.error('No definitionId!');
        return;
      }
      this.externaldatasourceService.executeExternalDataSource(192, [lookUpTableDefinitionId])
      .then(result => {
        if (!this.commonService.isNullOrUndefined(result)) {
          result.forEach(resultItem => {
            availableValues.push(resultItem.defaultName);
          });
        }
        getAvailableValuesForLookupTableResolve(availableValues);
      })
      .catch(() => {
        getAvailableValuesForLookupTableReject([]);
      });
    });
  }

  getArrayDataForColumn(dataArray, columnIndex) {
    const returnValue = [];
    dataArray.forEach(item => {
      returnValue.push(item[columnIndex]);
    });
    return returnValue;
  }

  getAlreadyImportedLookupItems(lookUpTableDefinitionId, dataArray, sourceField) {
    let returnValue = 0;
    this.getAvailableValuesForLookupTable(lookUpTableDefinitionId)
    .then(result => {
      const availableData = result;
      dataArray.forEach(item => {
        if (this.commonService.checkIfItemIsInArray(availableData, item)) {
          returnValue ++;
        }
      });
      console.warn(returnValue + ' / ' + dataArray.length);
      let status = 0;
      if (returnValue === dataArray.length) {
        status = 1;
      }
      sourceField.helperTableCount = {
        value: returnValue + ' / ' + dataArray.length + ' ' +
          this.axivasTranslateService.getTranslationTextForToken('Import.Label.RecordsFound'),
        status: status
      };
    });
  }

  createLookupTableDefiniton(definitionName): Promise<any> {
    return new Promise((createLookupTableDefinitonResolve, createLookupTableDefinitonReject) => {
      this.externaldatasourceService.executeExternalDataSource(194, [definitionName])
      .then(createLookupTableDefinitonResult => {
        createLookupTableDefinitonResolve(createLookupTableDefinitonResult);
      })
      .catch(error => {
        createLookupTableDefinitonReject(error);
      });
    });
  }

  createLookupTable(tableName, definitionId): Promise<any> {
    return new Promise((createLookupTableResolve, createLookupTableReject) => {
      this.externaldatasourceService.executeExternalDataSource(195, [tableName, definitionId])
      .then(createLookupTableResult => {
        createLookupTableResolve(createLookupTableResult);
      })
      .catch(error => {
        createLookupTableReject(error);
      });
    });
  }

  sendImportDataToBackEnd(importData, fileName, poolId: any, projectId: any, toTempTable: boolean = false, checkData = false, isCheckIfEmailExists = false, isSkipImportOnError = false): Promise<any> {
    return new Promise((sendImportDataToBackEndResolve, sendImportDataToBackEndReject) => {
      const headerValues = [];
      const dataValues = [];
      // creating headers
      importData.data.headers.forEach(headerItem => {
        if (this.commonService.isNullOrUndefined(headerItem.checkForDuplicates)) { headerItem.checkForDuplicates = false; }
        if (this.commonService.isNullOrUndefined(headerItem.skipRecordIfEmpty)) { headerItem.skipRecordIfEmpty = false; }
        if (this.commonService.isNullOrUndefined(headerItem.isKeyMember)) { headerItem.isKeyMember = false; }
        if (this.commonService.isNullOrUndefined(headerItem.lookupTableName)) { headerItem.lookupTableName = null; }
        if (this.commonService.isNullOrUndefined(headerItem.isCreateNewLookupValues)) { headerItem.isCreateNewLookupValues = false; }
        let headerString = `{ "name": "<0>", "type": "<1>", "entityId": <2>, "entityMemberName": "<3>",
          "lookupTableDefinitionName": <4>, "isKeyMember": "<5>", "isNewMember": "<6>", "skipRecordIfEmpty": <7> , "checkForDuplicates": <8>, "isCreateNewLookupValues": <9>, "copy": <10> }`;
        headerString = headerString.replace('<0>', headerItem.name);
        headerString = headerString.replace('<1>', headerItem.type);
        headerString = headerString.replace('<2>', headerItem.entity);
        headerString = headerString.replace('<3>', headerItem.memberName);
        if (headerItem.lookupTableName === null) {
          headerString = headerString.replace('<4>', null);
        } else {
          headerString = headerString.replace('<4>', '"' + headerItem.lookupTableName + '"');
        }
        headerString = headerString.replace('<5>', headerItem.isKeyMember);   
        let newMember = false;    
        if (headerItem.member === '-1' || headerItem.isNewMember == true)  {
          newMember = true;
        }
        headerString = headerString.replace('<6>', newMember.toString());
        headerString = headerString.replace('<7>', headerItem.skipRecordIfEmpty);
        headerString = headerString.replace('<8>', headerItem.checkForDuplicates);
        headerString = headerString.replace('<9>', headerItem.isCreateNewLookupValues );        
        if (this.commonService.isNullOrUndefined(headerItem.copy)) {
          headerString = headerString.replace('<10>', null);   
        } else {
          headerString = headerString.replace('<10>', '"' + headerItem.copy + '"');   
        }
        headerValues.push(headerString);
      });

      // creating data
      // importData.data.data.forEach(dataItem => {
      //   dataValues.push(JSON.stringify(dataItem));
      // });

      // finalizing post body
      const formData: FormData = new FormData();
      formData.append('file', this.currentImportFileFormData);
      formData.append('headers', '[ ' + headerValues.toString() + ' ]');
      formData.append('projectId', projectId);
      formData.append('fileName', fileName);
      formData.append('sheetName', importData.name);
      formData.append('isCheckIfEmailExists', isCheckIfEmailExists.toString());
      formData.append('isSkipImportOnError', isSkipImportOnError.toString());
      if (!this.commonService.isNullOrUndefined(poolId)) {
        formData.append('poolId', poolId);
      }
      formData.append('newPoolName', importData.poolName);

      let url = '/Import/ImportData';
      if (toTempTable) {
        url = '/Import/ImportToTempTable';
      }       
      if (checkData) {
        url = '/Import/CheckData';
        this.sharedAPI.checkImportData(url, formData).subscribe(result => {
          sendImportDataToBackEndResolve(result);
        });
      } else {
        this.sharedAPI.importData(url, formData, toTempTable).subscribe(result => {
          sendImportDataToBackEndResolve(result);
          if (toTempTable) {
            console.warn('ImportToTempTable Name', result);
          } else {
            this.importResult = result;
            this.eventService.importShowPageEvent.emit(3);
          }
        });
      }
    });
  }

  sendBlacklistImportDataToBackEnd(importData, fileName, projectId: any, blacklistType, fieldName): Promise<any> {
    return new Promise((sendImportDataToBackEndResolve, sendImportDataToBackEndReject) => {
      // finalizing post body
      const formData: FormData = new FormData();
      formData.append('file', this.currentImportFileFormData);
      // formData.append('projectId', projectId);
      // formData.append('fileName', fileName);
      // formData.append('blacklistType', blacklistType);      
      // formData.append('sheetName', importData);
      // formData.append('fieldName', fieldName);
      let url = '/Import/ImportBlacklistData';
      this.sharedAPI.importBlacklistData(url, formData, importData, blacklistType, fieldName).subscribe(result => {
          sendImportDataToBackEndResolve(result);
          this.importResult = result;
          this.eventService.closeJjPopup();
      });
    });
  }
}
