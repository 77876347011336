import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-cloudconnection-login',
  templateUrl: './cloudconnection-login.component.html',
  styleUrls: ['./cloudconnection-login.component.scss']
})
export class CloudconnectionLoginComponent implements OnInit {
  cloudConnectionLink = null;

  constructor(
    private applicationInfoServica: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.cloudConnectionLink = this.applicationInfoServica.miscSettings['msoCode'];
  }

}
