import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.scss']
})
export class AdminMainComponent implements OnInit {
  selectedTab = 0;


  constructor(
    public applicationInfoService: ApplicationInfoService,
    public uiService: UiService
  ) { }

  ngOnInit() {
  }


}
