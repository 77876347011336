import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from './common.service';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { EntityService } from './entity.service';
import { isUndefined, isNullOrUndefined } from 'util';
import { MessagingService } from './messaging.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionaireService {
  queryBase = `query { <questionaireName> (projectId:<projectId>, <type> ) {
    id
    <member>
  }}`;
  mutationBase = `mutation { <questionaireMutationType><questionaireMutationName>Mutation (projectId: <projectId>, <recordType>: {
    <mutationTypeIdentifier>
    <memberData>
  }) { id } }`;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private graphQLService: GraphQLService,
    private eventService: EventService,
    private messagingService: MessagingService,
    private entityService: EntityService
  ) { }

  saveQuestionaryData(questionaireId): Promise<any> {
    return new Promise((saveQuestionaryDataResolve, saveQuestionaryDataReject) => {
      const entity = this.applicationInfoService.entities.Item(questionaireId);
      let questionaireMutationType = '';
      let mutationTypeIdentifier = '';
      let memberData = '';
      let recordType = '';
        if (entity.data.id) {
          questionaireMutationType = 'update';
          recordType = 'record';
          mutationTypeIdentifier = 'id: ' + entity.data.id;
        } else {
          questionaireMutationType = 'create';
          recordType = 'newRecord';
          switch (entity.lookupEntityDynamicalType.id) {
            case 25: // Account level
              mutationTypeIdentifier = 'accountId: <accountId>';
            break;
            case 26: // Contact level
              mutationTypeIdentifier = 'contactId: <contactId>';
              break;
            case 27: // Task level
              mutationTypeIdentifier = 'taskId: <taskId>';
              break;
          }
        }
        entity.entityMembers.forEach(entityMember => {
          let valueToReplace = entity.data[entityMember.defaultName];
          if (!isUndefined(valueToReplace)) {
            if (entity.data[entityMember.defaultName] === null) {
              memberData = memberData.concat(' ' + entityMember.defaultName + ': null');
            } else {
              switch (entityMember.memberType) {
                case 'TEXT':
                  valueToReplace = this.commonService.cleanStringValue(valueToReplace);
                  memberData = memberData.concat(' ' + entityMember.defaultName + ': "<value>"');
                  break;
                case 'NUMERIC':
                    // valueToReplace = this.commonService.removeNonNumericsFromInt(valueToReplace);
                  memberData = memberData.concat(' ' + entityMember.defaultName + ': <value>');
                  break;
                case 'DATETIME':
                  valueToReplace = new Date(valueToReplace).toUTCString();
                  memberData = memberData.concat(' ' + entityMember.defaultName + ': "<value>"');
                  break;
                default:
                  memberData = memberData.concat(' ' + entityMember.defaultName + ': <value>');
                  break;
              }
            }
            // console.warn('saveQuestionaryData valueToReplace', valueToReplace);
            memberData = memberData.replace('<value>', valueToReplace);
          }
        });
        let query = this.mutationBase;
        query = query.replace('<questionaireMutationType>', questionaireMutationType);
        query = query.replace('<questionaireMutationName>', this.commonService.toPascalCase(entity.entityName));
        query = query.replace('<mutationTypeIdentifier>', mutationTypeIdentifier);
        query = query.replace('<memberData>', memberData);
        query = query.replace('<recordType>', recordType);
        query = this.commonService.modifyQueryStringWithApplicationInfos(query);
        this.graphQLService.apolloGQLpromiseWithParameter('data', ApolloMethod.Query, query, [])
        .then(saveQuestionaryDataQueryResult => {
          saveQuestionaryDataResolve(saveQuestionaryDataQueryResult.data[this.commonService.toPascalCase(entity.entityName)]);
        })
        .catch(error => {
          saveQuestionaryDataReject(error);
          this.messagingService.showDefaultError('saveQuestionaryData', error, true);
          console.error(error);
      });
    });
    //   .catch(error => {
    //     saveQuestionaryDataReject(error);
    //     this.messagingService.showDefaultError('saveQuestionaryData', error, true);
    //     console.error(error);
    // });
  }

  loadQuestionaireData(questionaireId: any, overwriteData: boolean = true): Promise<any> {
    return new Promise((loadQuestionaireDataResolve, loadQuestionaireDataReject) => {
      const query = this.createQuestionaireQueryString(questionaireId);
      if (query === '') {
        loadQuestionaireDataResolve(null);
        return;
      }

      this.graphQLService.apolloGQLpromiseWithParameter('data', ApolloMethod.Query, query, [])
      .then(loadQuestionaireDataQueryResult => {
        let questionaireData = loadQuestionaireDataQueryResult
          .data[this.getQuestionaireName(this.applicationInfoService.entities.Item(questionaireId))];
        let dataForEntityDict = null;
        if (questionaireData.length > 0) {
          questionaireData = questionaireData[0];
          dataForEntityDict = questionaireData;
        } else {
          questionaireData = null;
          dataForEntityDict = new Object();
        }
        if (overwriteData) {
          this.entityService.overwriteEntityData(questionaireId, dataForEntityDict);
        }
        loadQuestionaireDataResolve(questionaireData);
      })
      .catch(error => {
        this.entityService.overwriteEntityData(questionaireId, new Object());
        loadQuestionaireDataReject(error);
      });
    });
  }

  getQuestionaireName(entity): string {
    if (isNullOrUndefined(entity)) {
      return;
    }
    let questionaireName = this.commonService.toPascalCase(entity.entityName);
    questionaireName = questionaireName.charAt(0).toLowerCase() + questionaireName.slice(1);
    switch (entity.lookupEntityDynamicalType.id) {
      case 25: // Account level
        questionaireName = questionaireName.concat('ByAccountId');
      break;
      case 26: // Contact level
        questionaireName = questionaireName.concat('ByContactId');
        break;
      case 27: // Task level
        questionaireName = questionaireName.concat('ByTaskId');
        break;
    }

    return questionaireName;
  }

  createQuestionaireQueryString(questionaireId: any): string {
    let result = '';
    const entity = this.applicationInfoService.entities.Item(questionaireId);
    if (isNullOrUndefined(entity)) {
      console.log('createQuestionaireQueryString: no questionaire entity');
      return '';
    }
    const questionaireName = this.getQuestionaireName(entity);
    let member = '';
    entity.entityMembers.forEach(entityMember => {
      member = member.concat(entityMember.defaultName, ' ');
    });

    // Type
    let type = '';
    let suffix = '';
    switch (entity.lookupEntityDynamicalType.id) {
      case 25: // Account level
        type = 'accountId: <accountId>';
        suffix = 'ByAccountId';
      break;
      case 26: // Contact level
        type = 'contactId: <contactId>';
        suffix = 'ByContactId';
        break;
      case 27: // Task level
          type = 'taskId: <taskId>';
          suffix = 'ByTaskId';
          break;
      }

    result = this.queryBase.replace('<member>', member);
    result = result.replace('<type>', type);
    result = result.replace('<questionaireSuffix>', suffix);
    result = result.replace('<questionaireName>', questionaireName);
    result = this.commonService.modifyQueryStringWithApplicationInfos(result);
    return result;
  }

  getModifiedQuestionaires(): any[] {
    const result = [];
    this.applicationInfoService.entities.impArray.items.forEach(entityId => {
      const entity = this.applicationInfoService.entities.Item(entityId);
      if (entity.lookupEntityDynamicalType) {
        if (entity.lookupEntityDynamicalType.id >= 25 && entity.lookupEntityDynamicalType.id <= 27) {
          if (JSON.stringify(entity.data) !== JSON.stringify(this.applicationInfoService.entitiesorig.Item(entityId).data)) {
            result.push(entityId);
          }
        }
      }
    });
    return result;
  }
}
