<div *ngIf="paletteObject != null" class="palleteDesignerWrapper">
    <!-- <div class="paletteDesignerHeader">       
        <div>
            <button class="everleadCustomButton" (click)="addControl()">{{ 'Palette.Label.NewPalette' | texttransform }}</button>
        </div>
    </div> -->
    <div class="paletteDesignerControls">
        <div class="palleteControlItemHeader">
            <label>{{ 'Palette.Label.AvailablePalettes' | texttransform }}</label>
            <i class="fas fa-plus fingerCursor" [tippy]="'Palette.Label.NewPalette' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" (click)="addPalette()"></i>
        </div>
        <div *ngFor="let palette of array.controls" class="palleteControlItem everleadShadowBox">
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.IsActive' | texttransform }}</label>
                <div class="palleteItemLine__value">
                    <input [(ngModel)]="palette['isActive']" type="checkbox" (change)="savePalette()">
                </div>
            </div>
            <div *ngFor="let language of languages">
                <div class="palleteItemLine">
                    <label class="palleteItemLine__label">
                        {{ 'Palette.Label.TranslationText' | texttransform }}
                        {{ commonService.getTranslationValueFromArray(language, 'languageName') | texttransform }}
                    </label>
                    <div class="palleteItemLine__value">
                        <input [(ngModel)]="palette['languageText' + language.languageName]" type="text" class="maxWidth" (change)="savePalette()">
                    </div>
                </div>    
            </div>
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocation' | texttransform }}</label>
                <div class="palleteItemLine__value">
                    <select [(ngModel)]="palette['controlSettingPaletteLocation']" class="maxWidth" (change)="savePalette()">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let location of locations" [ngValue]="location.value">{{ location.defaultName | texttransform }}</option>
                    </select>
                </div>
            </div>
            <div class="palleteItemLine isDeveloper" *ngIf="applicationInfoService.isDeveloper">
                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocation' | texttransform }}</label>
                <div class="palleteItemLine__value">
                    <input [(ngModel)]="palette['controlSettingPaletteLocation']" class="maxWidth" (change)="savePalette()">
                </div>
            </div>
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.WhatToDisplay' | texttransform }}</label>
                <div class="palleteItemLine__value">
                    <select [(ngModel)]="palette['controlSettingPaletteWhatToDisplay']" class="maxWidth" (change)="savePalette()">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let target of targets" [ngValue]="target.value">{{ target.defaultName | texttransform }}</option>
                    </select>
                </div>
            </div>
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocationCheckPart' | texttransform }}</label>
                <div class="palleteItemLine__value">
                    <input [(ngModel)]="palette['controlSettingPaletteLocationCheckPart']" type="checkbox" (change)="savePalette()">
                </div>
            </div>
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocationMinX' | texttransform }}
                    <app-wizard-infobutton displayText="Palette.Label.ControlSettingPaletteLocationMinXDesc"></app-wizard-infobutton>
                </label>
                <div class="palleteItemLine__value">
                    <input [(ngModel)]="palette['controlSettingPaletteLocationMinX']" type="number" class="maxWidth" (change)="savePalette()">
                </div>
            </div>
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocationMaxX' | texttransform }}
                    <app-wizard-infobutton displayText="Palette.Label.ControlSettingPaletteLocationMaxXDesc"></app-wizard-infobutton>
                </label>
                <div class="palleteItemLine__value">
                    <input [(ngModel)]="palette['controlSettingPaletteLocationMaxX']" type="number" class="maxWidth" (change)="savePalette()">
                </div>
            </div>
            <div class="palleteItemLine">
                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteWidth' | texttransform }}
                    <app-wizard-infobutton displayText="Palette.Label.ControlSettingPaletteWidthDesc"></app-wizard-infobutton>
                </label>
                <div class="palleteItemLine__value">
                    <input [(ngModel)]="palette['controlSettingPaletteWidth']" type="number" class="maxWidth" (change)="savePalette()">
                </div>
            </div>
            <div class="palleteItemLineFooter">
                <div class="palleteItemLineFooter__button">
                    <!-- <button class="everleadCustomButton" (click)="deleteControl(palette)">{{ 'Palette.Label.RemovePalette' | texttransform }}</button> -->
                </div>                
                <div class="palleteItemLineFooter__options">
                    <i class="fas fa-clone fingerCursor" (click)="clonePalette(palette)" [tippy]="'Palette.Label.ClonePalette' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
                    <i class="fas fa-trash everleadCoral fingerCursor" (click)="removePalette(palette)" [tippy]="'Palette.Label.RemovePalette' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>
                </div>
            </div>
        </div>
    </div>
</div>