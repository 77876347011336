import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from 'primeng/api';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class NotificationsService {
    notificationChange: Subject<Object> = new Subject<Object>();

    message: Message[];

    constructor() {
        this.message = [];
    }

    success(detail: string, summary?: string, isSticky: boolean = false, lifeTime: number = 3000): void {
      this.message.push({
          severity: 'success', summary: summary, detail: detail
      });

      this.notify('success', summary, detail, isSticky , lifeTime);
    }

    info(detail: string, summary?: string, isSticky: boolean = false, lifeTime: number = 3000): void {
        this.message.push({
            severity: 'info', summary: summary, detail: detail
        });

        this.notify('info', summary, detail, isSticky , lifeTime);
    }

    warning(detail: string, summary?: string, isSticky: boolean = false, lifeTime: number = 3000): void {
        this.message.push({
            severity: 'warn', summary: summary, detail: detail
        });

        this.notify('warn', summary, detail, isSticky , lifeTime);
    }

    error(detail: string, summary?: string, isSticky: boolean = false, lifeTime: number = 3000): void {
        this.message.push({
            severity: 'error', summary: summary, detail: detail
        });

        this.notify('error', summary, detail, isSticky , lifeTime);
    }

     notify(severity: Severities, summary: string, detail: string, isSticky: boolean = false, lifeTime: number = 3000) {
       this.notificationChange.next({ severity, summary, detail, isSticky , lifeTime });
    }
}
