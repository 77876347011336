import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-account-group',
  templateUrl: './account-group.component.html',
  styleUrls: ['./account-group.component.scss']
})
export class AccountGroupComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  innerHTMLContent = ` <jj-container controluiid="181378"> </jj-container>`;
  innerHTML = null;
  showParents = false;
  accountGroup = [];
  searchEngine = '1';
  loading = true;
  filterString = '';

  @Input() parentid = null;
  @Input() subsidaryid = null;
  @Input() showparents = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private applicationInfoService: ApplicationInfoService,
    private commonSerice: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private methodService: MethodService,
    private ringcentralwebphoneService: RingcentralwebphoneService,
    private messagingService: MessagingService,
    private commonService: CommonService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.type == 'addtoeventgroup') {
        this.createAccountGroupMember(event.data.id, event.data);
      }
    });
    if (this.showparents == null) {
      this.parentid = this.applicationInfoService.miscSettings['accountGroupParentLookupId'];
      this.subsidaryid = this.applicationInfoService.miscSettings['accountGroupSubsidaryLookupId'];
      this.showparents = this.applicationInfoService.miscSettings['accountGroupShowParents'];
    } else {
      this.applicationInfoService.miscSettings['accountGroupParentLookupId'] = this.parentid;
      this.applicationInfoService.miscSettings['accountGroupSubsidaryLookupId'] = this.subsidaryid;
      this.applicationInfoService.miscSettings['accountGroupShowParents'] = this.showparents;
    }
    if (this.showparents == 'true') {
      this.showParents = true;
    }
    this.getAccountGroups();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    this.applicationInfoService.miscSettings['accountGroupParentLookupId'] = null;
    this.applicationInfoService.miscSettings['accountGroupSubsidaryLookupId'] = null;
    this.applicationInfoService.miscSettings['accountGroupShowParents'] = null;
  }

  createAccountGroupMember(subsidaryId, dataRow = null) {      
    let createAccountGroupMemberArray = {
      parentId: this.showParents ? subsidaryId :  this.applicationInfoService.currentAccount.id,
      parentLookupId: Number(this.parentid),
      subsidaryId: this.showParents ? this.applicationInfoService.currentAccount.id :  subsidaryId,
      subsidaryLookupId: Number(this.subsidaryid),
      percent: "100",
      comment: "No comment", 
    }

    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(781, [
      this.commonSerice.getModifyArrayBody(createAccountGroupMemberArray, [])
    ]).then(result => {
      if (dataRow) {
        result['email'] = dataRow['_1_email'];
        result['phone'] = dataRow['_1_phone'];
      }  
      this.accountGroup.push(result);
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); })
  }

  updateAccountGroup(accountGroup) {    
    this.externaldatasourceService.executeExternalDataSource(785, [
      this.commonSerice.getModifyArrayBody(accountGroup, ['subsidary', 'parent'])
    ]).then(result => {
      this.accountGroup.push(result);
    })
  }

  getAccountGroups() {
    this.loading = true;
    let externalDataSourceId = 784;
    if (this.showParents) {
      externalDataSourceId = 783;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
      this.applicationInfoService.currentAccount.id,
      Number(this.parentid),
      Number(this.subsidaryid),
    ]).then(result => {
      this.loading = false;
      this.accountGroup = result;
    }).catch(() => { this.loading = false; });
  }

  deleteAccountGroup(accountGroupItem) {
    this.externaldatasourceService.executeExternalDataSource(782, [accountGroupItem.id]).then(() => {
      this.commonSerice.removeItemFromArray(this.accountGroup, accountGroupItem);
    });
  }

  loadAccount(accountGroupItem) {
    let accountId = accountGroupItem.parent.id;
    if (!this.showParents) {
      accountId = accountGroupItem.subsidary.id;
    }
    this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [accountId]);
  }

  callNumber(account) {
    if (this.targetHasOptout(account)) {
      this.messagingService.showDefaultWarning('OptOut', 'Für den Eigentümer/ das Objekt wurde ein OptOut hinterlegt, der Anruf kann daher nicht gestartet werden!');
      return;
    }
    this.ringcentralwebphoneService.makeCall(account.phone);
  }

  targetHasOptout(account) {
    let returnValue = false;
    if (account.currentOptStatus == 2) {
      returnValue = true;
    }
    return returnValue;
  }

  updatePhone(account) {
    this.externaldatasourceService.executeExternalDataSource(800, [
      this.commonSerice.getModifyArrayBody({ 
        id: account.id, 
        phone: account.phone 
      }, [])
    ]);
  }

  updateAccount(account) {
    this.externaldatasourceService.executeExternalDataSource(800, [
      this.commonSerice.getModifyArrayBody({ 
        id: account.id, 
        name2: account.name2, 
        name3: account.name3,
        comments: account.comments
      }, [])
    ]).then(() => {
      this.eventService.customEvent.emit('ShowAccountData');
    });
  }

  
  updateMail(account) {
    this.externaldatasourceService.executeExternalDataSource(800, [
      this.commonSerice.getModifyArrayBody({ 
        id: account.id, 
        email: account.email 
      }, [])
    ]);
  }

  searchPhoneNumber(account) {
    switch (this.searchEngine) {
      case '1':
        this.commonSerice.searchPhoneNumberInWebPhonebook(account);
        break;
      case '2':
        this.commonSerice.searchPhoneNumberInWebPhonebook(account, 'https://www.dasoertliche.de/?zvo_ok=&plz=&quarter=&district=&ciid=&kw=<0>&ci=<2><1>&kgs=&buab=&zbuab=&form_name=search_nat');
        break;
      case '3':
        break;
    }    
  }

  searchPhoneNumberOertlich(account) {    
    this.commonSerice.searchPhoneNumberInWebPhonebook(account, 'https://www.dasoertliche.de/?zvo_ok=&plz=&quarter=&district=&ciid=&kw=<0>&ci=<2><1>&kgs=&buab=&zbuab=&form_name=search_nat');
  }

  isItemFiltered(group) {    
    let item = null;
    if (this.showParents) {
      item = group.parent;
    } else {
      item = group.subsidary;
    }
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(item.street, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.zipcode, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.name1, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.name2, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.name3, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.email, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.phone, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.id3w, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.hausnummer, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.hausnrzusatz, this.filterString)) { returnValue = false; }
    if (this.commonService.checkIfStringContainsString(item.comments, this.filterString)) { returnValue = false; }    
    return returnValue;
  }
}
