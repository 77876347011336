import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-entityfileupload',
  templateUrl: './custom-entityfileupload.component.html',
  styleUrls: ['./custom-entityfileupload.component.scss']
})
export class CustomEntityfileuploadComponent extends CustomControlBaseClass implements OnInit, OnDestroy, AfterViewInit {
  customEventSubscription: Subscription = new Subscription();
  updateControlAttributesEventSubscriptionFileUpload: Subscription = new Subscription();
  entityFiles = [];
  entityId = null;
  refId = null;
  overwrite = false;
  showFileList = false;
  darkMode = false;
  showFiluUploadPopup = false;
  filesToUpload = null;
  entityfileUploadContentTypes = [];
  showUpload = true;
  entityIdSelection = null;
  showEntitySelection = true;

  constructor(
    private injector: Injector,
    private sharedApi: SharedAPI,
    private axivasTranslateService: AxivasTranslateService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngAfterViewInit(): void {
    const popupControl = document.getElementById(this.getEntityFileUploadPopupId());
    const poupBackGroundControl = document.getElementById(this.getEntityFileUploadPopupId('Background'));
    if (popupControl) { document.body.append(popupControl); }
    if (poupBackGroundControl) { document.body.append(poupBackGroundControl); }
  } 

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'refreshEntityFileUpload') {
        this.initializeControl();
      }
    });
    this.updateControlAttributesEventSubscriptionFileUpload = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.initializeControl();
    });
    this.showFileList = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'entityFileUploadShowFileList', false);    
    this.darkMode = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'controlSettingGeneralDarkMode', false);    
    this.initializeControl();
    this.getEntityfileUploadContentTypes();
  }

  initializeControl() {
    this.entityId = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'entityFileUploadEntity', 4);    
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId))) {
      this.refId = this.applicationInfoService.entities.Item(this.entityId).data.id;
      this.getEntityFiles();  
    }
    this.entityIdSelection = Number(this.entityId);
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscriptionFileUpload) { this.updateControlAttributesEventSubscriptionFileUpload.unsubscribe(); }
  }

  public onFilesSelected(files: File[]): void {    
    this.filesToUpload = [];
    for ( let i = 0; i < files.length; i++) {
      this.filesToUpload.push({ 
        file: files[i],
        name: files[i].name,
        type: Number(this.applicationInfoService.applicationSettings['fileUploadDefaultTypeLookupId']),
        comment: ''
      });
    }
    this.showFiluUploadPopup = true;
    return;
  }

  getEntityFiles() {
    if (
      this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['entityFileUploadSourceId']) ||
      this.checkVisibility() == false ||
      this.refId == null
    ) {
      return;
    }

    this.externalDatasourceService.executeExternalDataSource(899, [      
      this.applicationInfoService.applicationSettings['entityFileUploadSourceId'],
      this.entityId, 
      this.refId])
    .then(getEntityFilesResult => {
      // console.log('getEntityFiles', getEntityFilesResult);
      this.entityFiles = [];
      const alreadyFound = [];
      getEntityFilesResult.forEach(item => {
        if (!this.commonService.checkIfItemIsInArray(alreadyFound, item.name)) {
          alreadyFound.push(item.name);
          this.entityFiles.push(item);  
        }
      });
      // this.entityFiles = getEntityFilesResult;
    });
  }

  deleteEntityFiles(entityFile) {
    this.loaderService.display(true);
    this.sharedApi.contentLibraryDeleteFile(
      entityFile.id, 
      this.applicationInfoService.applicationSettings['entityFileUploadSourceId']
    ).subscribe(() => {
      this.loaderService.display(false);
      this.eventService.customEvent.emit({ eventType: 'updateEntityTableView' });
      this.getEntityFiles();
    });  
  }

  uploadFiles() {
      this.filesToUpload.forEach(file => {
      //console.log('file', file);
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('fileType', file.file.type)
      formData.append('displayName', file.name);  
      formData.append('fileTypeId', file.type);  
      formData.append('comment', file.comment);  
      formData.append('projectId', this.applicationInfoService.projectID.toString());
      formData.append('libraryId', this.applicationInfoService.applicationSettings['entityFileUploadSourceId']);
      formData.append('parentFolderId', this.applicationInfoService.applicationSettings['entityFileUploadSourceFolderId']);
      formData.append('contentTypeId', '6880');  
      formData.append('entityId', this.entityId); 
      formData.append('refId', this.refId); 
      formData.append('overwrite', this.overwrite.toString()); 
      this.loaderService.display(true);
      this.sharedApi.contentLibraryUploadFile(formData).subscribe(
        (file) => {
          //console.log('fileresponse', file) 
          this.getEntityFiles(); 
          this.eventService.customEvent.emit({ eventType: 'updateEntityTableView' });
          this.loaderService.display(false);
        },
        error => { 
          const errorMessage = JSON.parse(error._body);
          this.loaderService.display(false);
          if (errorMessage) {
            if (this.commonService.checkIfStringContainsString(errorMessage.errors.errors[0].description, 'already exist')) {
              let errorMessage = this.axivasTranslateService.getTranslationTextForToken('EntityFileUpload.Label.FileUploadErrorAlreadyExists')
                .replace('<0>', file.name);
              this.messagingService.showDefaultWarning('Upload', errorMessage);
            }
          }
        }
      );  
    });
    this.showFiluUploadPopup = false;
  }

  checkVisibility() {
    let returnValue = true;
    if (this.entityId == '4' && this.applicationInfoService.currentContact == null) {
      returnValue = false;
    }
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['entityFileUploadSourceId'])) {
      returnValue = false;
    }
    return returnValue;
  }

  downloadEntityFiles(entityFile) {
    this.sharedApi.contentLibraryDownloadFile(
      entityFile.id,
      this.applicationInfoService.applicationSettings['entityFileUploadSourceId']
    ).subscribe(result => {
      let fileDetails = entityFile.name.split('.');
      this.commonService.downloadFile(result, 'application/'.concat(fileDetails[fileDetails.length-1]), entityFile.name);
    });  
  }

  getEntityFileUploadPopupId(addition = '') {
    return 'entityFileUploadPopupId'.concat(addition, this.controlDefinition.id);
  }

  getEntityfileUploadContentTypes() {
    this.entityfileUploadContentTypes = this.commonService.getProjectLookupTableValues(103, 'central'); // main or data
  }

  updateUploadEntityValue() {
    this.entityId = this.entityIdSelection;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.entityId))) {
      this.refId = this.applicationInfoService.entities.Item(this.entityId).data.id;
      this.getEntityFiles();  
      this.eventService.customEvent.emit({
        id: 'setEntityTableEntityIdForUploadedFiles',
        value: this.entityId
      });
    }
  }
}
