import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-custom-subresult',
  templateUrl: './custom-subresult.component.html',
  styleUrls: ['./custom-subresult.component.scss']
})
export class CustomSubresultComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updateControlDataEventScription: Subscription = new Subscription();
  updateBookingPreviewEventScription: Subscription = new Subscription();
  checkTimerSubscription: Subscription = new Subscription();
  lastResultId = null;

  constructor(
    private injector: Injector
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    this.getSubResultsFromArray();

    this.updateBookingPreviewEventScription = this.eventService.updateBookingPreviewEvent.subscribe(() => {
      this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data[this.controlDefinition.value] = [];
      this.getSubResultsFromArray();
    });

    this.updateControlDataEventScription = this.eventService.updateControlDataEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.data = event.arguments[0][0];
      }
    });

    const responseTimer = timer(1000, 1000);
    this.checkTimerSubscription = responseTimer.subscribe(() => {
      this.getSubResultsFromArray();
    });

  }

  ngOnDestroy() {
    if (this.updateControlDataEventScription) { this.updateControlDataEventScription.unsubscribe(); }
    if (this.updateBookingPreviewEventScription) { this.updateBookingPreviewEventScription.unsubscribe(); }
    if (this.checkTimerSubscription) { this.checkTimerSubscription.unsubscribe(); }
  }

  getSubResultsFromArray() {
    let resultId = this.entityService.getEntityValue(this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId), 'tempResultId', 1);
    if (this.commonService.isNullOrUndefined(resultId)) {
      resultId = this.entityService.getEntityValue(this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId), 'resultId', 1);
    }    
    if (resultId) {
      if (resultId !== this.lastResultId) {
        this.lastResultId = resultId;
        this.data = this.applicationInfoService.subResults.Item(resultId);
      }
    } else {
      this.data = null;
    }
  }
}
