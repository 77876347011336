// import { ThrowStmt } from '@angular/compiler/';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

enum optState {
  optIn = 43,
  optOut = 44,
  doubleOptIn = 1815
}
@Component({
  selector: 'app-custom-optinoptout',
  templateUrl: './custom-optinoptout.component.html',
  styleUrls: ['./custom-optinoptout.component.scss']
})

export class CustomOptinoptoutComponent extends CustomControlBaseClass implements OnInit {
  @Input() mode = 'account'
  mediumInfo = [];
  typeInfo = [];
  optInOptOutText = 'OptInOptOut.Label.NoInfoAvailable';
  public optState = optState;
  public currentOptState: optState;
  optArrayString = '';

  constructor(
    private injector: Injector,
    private externaldatasourceService: ExternaldatasourceService
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit(): void {
    this.getOpts();
  }

  getTypeInfo(): Promise<any> {
    return new Promise((getTypeInfoResolve, getTypeInfoReject) => {
      this.externalDatasourceService.getLookupTableValues(17, 2)
      .then(result => {
        result.forEach(resultItem => {
          this.typeInfo[resultItem.id] = resultItem.defaultName;
        });
        this.typeInfo[-1] = 'Keine Angabe';
        getTypeInfoResolve(null);
      }).catch(error => { getTypeInfoReject(error); });
    });
  }

  getMediumInfo(): Promise<any> {
    return new Promise((getTypeInfoResolve, getTypeInfoReject) => {
      this.externalDatasourceService.getLookupTableValues(16, 2)
      .then(result => {
        result.forEach(resultItem => {
          this.mediumInfo[resultItem.id] = resultItem.defaultName;
        });
        this.mediumInfo[-1] = 'Keine Angabe';
        getTypeInfoResolve(null);
      }).catch(error => { getTypeInfoReject(error); });
    });
  }

  getOpts() {
    this.optArrayString = '';
    const promiseArray: Promise<any>[] = [];
    promiseArray.push(this.getTypeInfo());
    promiseArray.push(this.getMediumInfo());
    Promise.all(promiseArray)
    .then(() => {
      this.externaldatasourceService.executeExternalDataSource(62, []).then(result => {
        result.forEach(resultItem => {
          let addItem = false;
          if (this.controlDefinition.additionalSetting1 === 'account') {
            if (resultItem.accountId !== null && resultItem.accountId === this.applicationInfoService.currentAccount.id) {
              addItem = true;
            }
          } else {
            if (this.applicationInfoService.currentContact) {
              if (resultItem.contactId !== null && resultItem.contactId === this.applicationInfoService.currentContact.id) {
                addItem = true;
              }
            }
          }


          if (resultItem.endDate !== null) {
            if (new Date(resultItem.endDate) < new Date(Date.now())) {
              addItem = false;
            }
          }

          if (addItem) {
            this.optArrayString = this.optArrayString.concat(new Date(resultItem.startDate).toLocaleString());
            if (resultItem.endDate !== null) {
              this.optArrayString = this.optArrayString.concat(' - ', new Date(resultItem.endDate).toLocaleString());
            }
              this.optArrayString = this.optArrayString.concat(' : ',
              this.typeInfo[resultItem.lookupTypeRefId],
              '-',
              this.mediumInfo[resultItem.lookupMediumRefId],
              '\n');
            if (resultItem.lookupTypeRefId == optState.optOut) {
              this.currentOptState = optState.optOut;
              this.optInOptOutText = 'OptInOptOut.Label.OptOutAvailable';
            } else {
              if (this.currentOptState !== optState.optOut) {
                this.currentOptState = optState.optIn;
                this.optInOptOutText = 'OptInOptOut.Label.OptInAvailable';
              }
            }
          }
        })
      });
    })
  }

  itemClicked() {
    this.callMethod('onclick');
  }
}
