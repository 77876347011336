import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="24809"> </jj-container>`;
  innerHTML = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService
  ) { }

  ngOnInit() {
    this.trackingService.trackPageView();
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
  }

}
