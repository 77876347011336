import { Injectable, EventEmitter } from '@angular/core';

export class JJEvent {
  public target = '';
  public arguments: any[] = [];
  public additionalInfo: string[] = [];

  constructor(event: string, eventargs: any[] = [], additionalInfo: string[] = []) {
    this.target = event;
    this.arguments = eventargs;
    this.additionalInfo = additionalInfo;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  // Entity
  public updateEntityEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateEntityDataPropertyEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public entitySavedEvent: EventEmitter<any> = new EventEmitter<any>();
  
  // Popups
  public designerPopupEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public showPopupEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public showJjPopupEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public softPhonePopupEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public closePopupEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public closeJjPopupEvent: EventEmitter<any> = new EventEmitter<any>();
  public closeSoftPhonePopupEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();

  // Misc  
  public navigateToContent: EventEmitter<any> = new EventEmitter<any>();
  public navigateToContainerId: EventEmitter<any> = new EventEmitter<any>();
  public updateMicroCampaignViewEvent: EventEmitter<any> = new EventEmitter<any>();
  public showSettingPageEvent: EventEmitter<any> = new EventEmitter<any>();
  public entityKeyDownEvent: EventEmitter<any> = new EventEmitter<any>();
  public loadNewContainerContentEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public changeEntityValuesEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public raiseErrorEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public applicationInitializedEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public showNewContentEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateHeaderEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateManuBarEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateInformationPanelEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public changeControlStyleEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public setNestedControlStyleEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateDropDownListEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public closeMegaMenuEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public navigationInitializedEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public lockAllControlsOfContainerEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateControlAttributesEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public loadContactEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public activateTabEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateControlContentEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updatePowerButtonsEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateBookingPreviewEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateControlContentByControlTypeEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateQuestionaireDataEvent: EventEmitter<any> = new EventEmitter<any>();
  public callInternalMethodEvent: EventEmitter<any> = new EventEmitter<any>();
  public loadDashboardEvent: EventEmitter<any> = new EventEmitter<any>();
  public updateContactQuestionaireEvent: EventEmitter<any> = new EventEmitter<any>();
  public externaldDataQueryEvent: EventEmitter<any> = new EventEmitter<any>();
  public changeContainerViewContent: EventEmitter<any> = new EventEmitter<any>();
  public updateCombinedActivityList: EventEmitter<any> = new EventEmitter<any>();
  public showBookingPreviewEventForResultIdEvent: EventEmitter<any> = new EventEmitter<any>();
  public updateSubResultsEvent: EventEmitter<any> = new EventEmitter<any>();
  
  // Designer
  public campaignListUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  public leadStateListUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  public procssListUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  public saveQuestionAnswersEvent: EventEmitter<any> = new EventEmitter<any>();
  public updateControlDefinition: EventEmitter<any> = new EventEmitter<any>();
  public refreshContentEvent: EventEmitter<any> = new EventEmitter<any>();
  public newLookupTableCreatedEvent: EventEmitter<any> = new EventEmitter<any>();
  public updateLookupTableEvent: EventEmitter<any> = new EventEmitter<any>();
  public refreshControlInfo: EventEmitter<any> = new EventEmitter<any>();
  public onUiControlCacheUpdateEvent: EventEmitter<any> = new EventEmitter<any>();
  
  // CompactList  
  public showListControlDataEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public callCompactListExternalPaginationEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public compactListShowOnlyDataEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public compactListRunQueryWithSpecificFilterEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();  
  public updateControlDataEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateControlDataPagingEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public sendCompactListInformationEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public compactListSelectAllEvent: EventEmitter<number> = new EventEmitter<number>();
  public compactListUpdateDataContentEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public compactListRemoveItemFromArray: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public exportCompactListDataEvent: EventEmitter<any> = new EventEmitter<any>();
  public compactListUpdateExternalDataSourcesEvent: EventEmitter<any> = new EventEmitter<any>();
  public updateControlContentWithCustomParameter: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public matTableUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  public matTableHeaderItemsUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();

  // ChatBot
  public enableChatBotEvent: EventEmitter<any> = new EventEmitter<any>();
  
  // Import
  public importFileUploadedEvent: EventEmitter<any> = new EventEmitter<any>();
  public importUpdateLookupTablesEvent: EventEmitter<any> = new EventEmitter<any>();
  public importShowPageEvent: EventEmitter<any> = new EventEmitter<any>();
  public dataImportFinishedEvent: EventEmitter<any> = new EventEmitter<any>();
  
  // MyLeads
  public updateMyLeadsRecordsetsEvent: EventEmitter<any> = new EventEmitter<any>();
  public updateAndBackToMyLeadsEvent: EventEmitter<any> = new EventEmitter<any>();
  public newLogicalEntityCreatedEvent: EventEmitter<any> = new EventEmitter<any>();
  public changeMyLeadsSubLevelEvent: EventEmitter<any> = new EventEmitter<any>();
  
  // Account form
  public showNewTaskEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public removeQMItemFromAccountFormArrayEvent: EventEmitter<any> = new EventEmitter<any>();

  // Language / Translation
  public languageUpdateEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public translateTokenPopupConfirmTranslationEvent: EventEmitter<any> = new EventEmitter<any>();

  // User related
  public updateAvailableActivitiesEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public externalUserCreatedEvent: EventEmitter<any> = new EventEmitter<any>();

  // Required field events
  public raiseShowRequiredFieldEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public raiseResetRequiredFieldEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();

  // Data filter
  public dataFilterUpdateFilterSegmentMemberMutationEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateSegmentInformationEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public selectCurrentDataFilterEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public cancelUpdateFilterSegmentMemberEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public updateDataFilterSegmentsEvent: EventEmitter<JJEvent> = new EventEmitter<JJEvent>();
  public activitiesUpdatedEvent: EventEmitter<any> = new EventEmitter();

  // Webphone
  public callNumberEvent: EventEmitter<any> = new EventEmitter<any>();
  public initPhoneSystemEvent: EventEmitter<any> = new EventEmitter<any>();
  public ringCentralOnPhoneSessionEvent: EventEmitter<any> = new EventEmitter<any>();
  public openIncomingCallEvent: EventEmitter<any> = new EventEmitter<any>();

  // Video and banner
  public showStartupVideoEvent: EventEmitter<any> = new EventEmitter<any>();
  public showCustomVideoEvent: EventEmitter<any> = new EventEmitter<any>();  
  public showCustomVideoWithCheckEvent: EventEmitter<any> = new EventEmitter<any>();  
  public showStartupBannerEvent: EventEmitter<any> = new EventEmitter<any>();
  public showBannerEvent: EventEmitter<any> = new EventEmitter<any>();  

  // Email Template & Mailshot
  public showEmailTemplatePreview: EventEmitter<any> = new EventEmitter<any>();  
  public addTextToHTMLText: EventEmitter<any> = new EventEmitter<any>();  
  public addImageArrayToHTMLText: EventEmitter<any> = new EventEmitter<any>();  
  public mailshotCreatedEvent: EventEmitter<any> = new EventEmitter<any>();  
  
  // Customized event
  public customEvent: EventEmitter<any> = new EventEmitter<any>();  

  constructor(
    ) {

  }

  exportCompactListData(controlId, fileNameAddition = '', onlyIfCheckBoxesActive = false) {
    this.exportCompactListDataEvent.emit({ controlId: controlId, onlyIfCheckBoxesActive: onlyIfCheckBoxesActive,
      fileNameAddition: fileNameAddition });
  }

  callCompactListExternalPagination(controlId: any, data: any) {
    this.callCompactListExternalPaginationEvent.emit(new JJEvent(controlId, data));
  }

  updateControlData(controlId: any, data: any) {
    this.updateControlDataEvent.emit(new JJEvent(controlId, [data]));
  }

  showListControlData(controlId: any, data: any) {
    this.showListControlDataEvent.emit(new JJEvent(controlId, [data]));
  }
  

  updateControlDataPaging(controlId: any, data: any) {
    this.updateControlDataPagingEvent.emit(new JJEvent(controlId, [data]));
  }

  updateControlContentByControlType(controlType: any) {
    this.updateControlContentByControlTypeEvent.emit(new JJEvent(controlType, []));
  }
  public updateBookingPreview(params: any) {
    this.updateBookingPreviewEvent.emit(new JJEvent('', [params]));
  }

  public updatePowerButtons() {
    this.updatePowerButtonsEvent.emit(new JJEvent('', []));
  }
  public updateControlContent(controlId: any) {
    this.updateControlContentEvent.emit(new JJEvent(controlId, []));
  }
  public navigationInitialized(controlId: string) {
    this.navigationInitializedEvent.emit(new JJEvent(controlId, []));
  }

  public closeMegaMenu() {
    this.closeMegaMenuEvent.emit(new JJEvent('', []));
  }

  public updateEntityDataProperty(entityId: any) {
    this.updateEntityDataPropertyEvent.emit(new JJEvent(entityId, []));
  }

  public languageUpdated() {
    this.languageUpdateEvent.emit(new JJEvent('', []));
  }

  public updateAvailableActivities(projectId: number) {
    this.updateAvailableActivitiesEvent.emit(new JJEvent('updateAvailableActivities', [projectId]));
  }

  public showNewTask(taskId: any)  {
    this.showNewTaskEvent.emit(new JJEvent('', [taskId]));
  }

  public updateDropDownList(dropDownID: string) {
    this.updateDropDownListEvent.emit(new JJEvent(dropDownID, []));
  }

  public closePopup() {
    this.closePopupEvent.emit(new JJEvent('', []));
  }

  public closeJjPopup(popupId: any = null) {
    this.closeJjPopupEvent.emit(popupId);
  }

  public designerPopup(visible: boolean, designTargetID: string, designType: string) {
    this.designerPopupEvent.emit(new JJEvent('designerPopup', [visible, designTargetID, designType]));
  }

  public softphonePopup(mode: any) {
    this.softPhonePopupEvent.emit(new JJEvent('SoftPhone', [mode]));
  }

  public closeSoftPhonePopup() {
    this.closeSoftPhonePopupEvent.emit(new JJEvent('softphone', []));
  }

  public showPopup(popupHeader: string, popupContentId: string, popupId: string, cancelEnabled: boolean = true) {
    this.loadNewContainerContent('popup', popupContentId);
    this.showPopupEvent.emit(new JJEvent(popupHeader, [popupContentId, popupId, cancelEnabled]));
  }

  public showJjPopup(popupHeader: string, popupContentId: string, sizePercentage: any = 80, cancelEnabled: boolean = true) {
    this.showJjPopupEvent.emit(new JJEvent(popupHeader, [popupContentId, sizePercentage, cancelEnabled]));
  }

  public lockAllControlsOfContainer(containerId: any, lock: boolean = true) {
    this.lockAllControlsOfContainerEvent.emit(new JJEvent(containerId, [lock]));
  }

  public setNestedControlStyle(controlID: string, member: string,  value: string) {
    this.setNestedControlStyleEvent.emit(new JJEvent(controlID, [member], [value]));
  }

  public changeControlStyle(entityID: string, member: string,  modifier: string[]) {
    // console.log('changeControlStyle', entityID, member, modifier);
    this.changeControlStyleEvent.emit(new JJEvent(entityID, [member], modifier));
  }

  public updateInformationPanel(panelID: string, content: string[], severity: string) {
    // console.log('updateInformationPanel', panelID, content, severity);
    this.updateInformationPanelEvent.emit(new JJEvent(panelID, content, [severity]));
  }

  public loadContact(contact: any) {
    this.loadContactEvent.emit(new JJEvent('LoadContact', [contact]));
  }

  public activateTab(controlId: any, tabId: any) {
    this.activateTabEvent.emit(new JJEvent(controlId, [tabId]));
  }

  public updateContactQuestionaire() {
    this.updateContactQuestionaireEvent.emit();
  }

  public updateControlAttributes() {
    this.updateControlAttributesEvent.emit(new JJEvent(''));
  }

  public updateManuBar(sourceControl: string, controlID: string) {
    this.updateManuBarEvent.emit(new JJEvent(sourceControl, [controlID]));
  }

  public applicationInitialized(initializedPart: string) {
    this.applicationInitializedEvent.emit(new JJEvent(initializedPart, []));
  }

  public raiseResetRequiredField(entityID) {
    this.raiseResetRequiredFieldEvent.emit(new JJEvent(entityID, []));
  }

  public callInternalMethod(params) {
    this.callInternalMethodEvent.emit(params);
  }

  public raiseShowRequiredField(entityID: string, settingName: string) {
    this.raiseShowRequiredFieldEvent.emit(new JJEvent(entityID, [settingName]));
  }

  public raiseError(errorTitle: string, errorMessage: string) {
    this.raiseErrorEvent.emit(new JJEvent('error', [errorTitle, errorMessage]));
  }

  public changeEntityValues(entityID: string, contentID: string) {
    this.changeEntityValuesEvent.emit(new JJEvent(entityID, [contentID]));
  }

  public loadNewContainerContent(containerName: string, contentID: string) {
    // console.log('loadNewContainerContent', containerName, contentID);
    this.loadNewContainerContentEvent.emit(new JJEvent(containerName, [contentID]));
  }

  public updateEntity(entityID: string, dataID: string) {
    this.updateEntityEvent.emit(new JJEvent(entityID, [dataID]));
  }

  public showNewContent(containerID: any, contentString: string, initalID: string) {
    this.showNewContentEvent.emit(new JJEvent(containerID, [contentString, initalID]));
  }
}

