<div  *ngIf="applicationInfoService.projectLoaded" class="phoneRingCentral" [ngClass]="{
    phoneRingCentralHighZIndex: applicationInfoService.applicationSettings['phoneRingCentralHighZIndex'] == 'true'
}">

    <div class="phone" [ngClass]="{ 
        active: openMenu == true, 
        notActive: openMenu == false,
        connected: ringcentralwebphoneService.extension != null
    }">

        <div class="phone__navRingCentral" *ngIf="ringcentralwebphoneService.extension == null">
            <button (click)="logInRingCentral()">
                <svg  class="logSvg" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#59aa8e" viewBox="0 0 256 256"><path d="M141.66,133.66l-40,40a8,8,0,0,1-11.32-11.32L116.69,136H24a8,8,0,0,1,0-16h92.69L90.34,93.66a8,8,0,0,1,11.32-11.32l40,40A8,8,0,0,1,141.66,133.66ZM192,32H136a8,8,0,0,0,0,16h56V208H136a8,8,0,0,0,0,16h56a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Z"></path></svg>
            </button>
            <hr>
            <label class="unifyLabel">Unify Office</label>
        </div>

        <div class="phone__nav" *ngIf="ringcentralwebphoneService.extension != null">
            <!-- <button (click)="logoutRingCentralRequest()">
                <svg class="logSvg" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#ff1049" viewBox="0 0 256 256"><path d="M112,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h56a8,8,0,0,1,0,16H48V208h56A8,8,0,0,1,112,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L196.69,120H104a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,221.66,122.34Z"></path></svg>
            </button>
            <hr> -->

            <button *ngIf="ringcentralwebphoneService.session && applicationInfoService.applicationSettings['isRecordingAllowed'] == 'true' && 
                ringcentralwebphoneService.established && ringcentralwebphoneService.makeCallObject.accountId != null"
                (click)="ringcentralwebphoneService.toggleRecord()">
                <svg *ngIf="!ringcentralwebphoneService.sessionState?.record" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1f1e1e" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z"></path><circle cx="128" cy="128" r="72"></circle></svg>
                <svg *ngIf="ringcentralwebphoneService.sessionState?.record" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff0000" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z"></path><circle cx="128" cy="128" r="72"></circle></svg>
            </button>

            <button (click)="openPhoneMenu()">
                <svg *ngIf="openMenu == true" xmlns="http://www.w3.org/2000/svg" width="9.867" height="16" viewBox="0 0 9.867 16">
                    <path id="chevron_right_FILL1_wght400_GRAD0_opsz24" d="M326.133-712,320-718.133,321.867-720l8,8-8,8L320-705.867Z" transform="translate(-320 720)" fill="#162c43"/>
                  </svg>
                  
                <svg *ngIf="openMenu == false" xmlns="http://www.w3.org/2000/svg" width="9.867" height="16" viewBox="0 0 9.867 16">
                    <path id="chevron_right_FILL1_wght400_GRAD0_opsz24" d="M326.133-712,320-718.133,321.867-720l8,8-8,8L320-705.867Z" transform="translate(329.867 -704) rotate(180)" fill="#162c43"/>
                  </svg>   
            </button>
            <hr>

            <button (click)="ringcentralwebphoneService.toggleHold()" [class.disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established" [disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established">
                <svg *ngIf="!ringcentralwebphoneService.session?.held" height="16" viewBox="0 0 13.714 16" width="13.714" xmlns="http://www.w3.org/2000/svg"><path d="m249.143-744v-16h4.571v16zm-9.143 0v-16h4.571v16z" fill="#011b34" transform="translate(-240 760)"/></svg>
                <svg *ngIf="ringcentralwebphoneService.session?.held" height="16" viewBox="0 0 13.714 16" width="13.714" xmlns="http://www.w3.org/2000/svg"><path d="m249.143-744v-16h4.571v16zm-9.143 0v-16h4.571v16z" fill="#ff1049" transform="translate(-240 760)"/></svg>
            </button>

            <!-- microphone -->
            <button (click)="ringcentralwebphoneService.toggleMute()" [class.disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established" [disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established" >
                <svg *ngIf="!ringcentralwebphoneService?.session?.muted" height="16" viewBox="0 0 15.379 16" width="15.379" xmlns="http://www.w3.org/2000/svg"><path d="m68.7-869.942-1.126-1.126a3.71 3.71 0 0 0 .408-.932 3.727 3.727 0 0 0 .136-1.01h1.552a5.153 5.153 0 0 1 -.252 1.621 5.694 5.694 0 0 1 -.718 1.447zm-2.291-2.33-4.5-4.5v-.893a2.247 2.247 0 0 1 .68-1.65 2.247 2.247 0 0 1 1.65-.68 2.247 2.247 0 0 1 1.65.68 2.247 2.247 0 0 1 .68 1.65v4.66a1.45 1.45 0 0 1 -.049.388q-.054.17-.112.345zm-2.951 7.029v-2.408a5.193 5.193 0 0 1 -3.34-1.8 5.272 5.272 0 0 1 -1.32-3.563h1.552a3.743 3.743 0 0 0 1.136 2.748 3.743 3.743 0 0 0 2.748 1.136 3.819 3.819 0 0 0 1.252-.2 3.825 3.825 0 0 0 1.078-.573l1.106 1.103a5.8 5.8 0 0 1 -1.233.748 5.025 5.025 0 0 1 -1.427.4v2.408zm6.833 1.243-14.291-14.291 1.087-1.087 14.291 14.291z" fill="#011b34" transform="translate(-56 880)"/></svg>
                <svg *ngIf="ringcentralwebphoneService?.session?.muted" height="16" viewBox="0 0 15.379 16" width="15.379" xmlns="http://www.w3.org/2000/svg"><path d="m68.7-869.942-1.126-1.126a3.71 3.71 0 0 0 .408-.932 3.727 3.727 0 0 0 .136-1.01h1.552a5.153 5.153 0 0 1 -.252 1.621 5.694 5.694 0 0 1 -.718 1.447zm-2.291-2.33-4.5-4.5v-.893a2.247 2.247 0 0 1 .68-1.65 2.247 2.247 0 0 1 1.65-.68 2.247 2.247 0 0 1 1.65.68 2.247 2.247 0 0 1 .68 1.65v4.66a1.45 1.45 0 0 1 -.049.388q-.054.17-.112.345zm-2.951 7.029v-2.408a5.193 5.193 0 0 1 -3.34-1.8 5.272 5.272 0 0 1 -1.32-3.563h1.552a3.743 3.743 0 0 0 1.136 2.748 3.743 3.743 0 0 0 2.748 1.136 3.819 3.819 0 0 0 1.252-.2 3.825 3.825 0 0 0 1.078-.573l1.106 1.103a5.8 5.8 0 0 1 -1.233.748 5.025 5.025 0 0 1 -1.427.4v2.408zm6.833 1.243-14.291-14.291 1.087-1.087 14.291 14.291z" fill="#ff1049" transform="translate(-56 880)"/></svg>
            </button>

            <!-- volume -->
            <button (click)="ringcentralwebphoneService.volumeDown()" [class.disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established" [disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established">
                <svg height="16" viewBox="0 0 13.5 16" width="13.5" xmlns="http://www.w3.org/2000/svg"><path d="m200-789v-6h4l5-5v16l-5-5zm11 1v-8.05a4.2 4.2 0 0 1 1.813 1.625 4.485 4.485 0 0 1 .687 2.425 4.361 4.361 0 0 1 -.687 2.4 4.251 4.251 0 0 1 -1.813 1.6z" fill="#011b34" transform="translate(-200 800)"/></svg>
            </button>

            <button (click)="ringcentralwebphoneService.volumeUp()" [class.disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established" [disabled]="!ringcentralwebphoneService.session  || !ringcentralwebphoneService.established" >
                <svg height="16" viewBox="0 0 16.457 16" width="16.457" xmlns="http://www.w3.org/2000/svg"><path d="m130.057-815v-1.874a6.14 6.14 0 0 0 3.314-2.286 6.272 6.272 0 0 0 1.257-3.84 6.273 6.273 0 0 0 -1.257-3.84 6.14 6.14 0 0 0 -3.314-2.286v-1.874a8 8 0 0 1 4.617 2.869 7.982 7.982 0 0 1 1.783 5.131 7.982 7.982 0 0 1 -1.783 5.131 8 8 0 0 1 -4.617 2.869zm-10.057-5.234v-5.486h3.657l4.571-4.571v14.629l-4.571-4.571zm10.057.914v-7.36a3.8 3.8 0 0 1 1.68 1.509 4.175 4.175 0 0 1 .606 2.194 4.076 4.076 0 0 1 -.606 2.16 3.817 3.817 0 0 1 -1.68 1.497z" fill="#011b34" transform="translate(-120 831)"/></svg>
            </button>

            <!-- transfer  call -->
            <button (click)="ringcentralwebphoneService.startTransferProcess()" [class.disabled]="ringcentralwebphoneService.sessionState.transferCallDisabled" [disabled]="ringcentralwebphoneService.sessionState.transferCallDisabled">
                <svg *ngIf="!ringcentralwebphoneService?.sessionState?.startTransfer" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m134.274-864a12.641 12.641 0 0 1 -5.2-1.147 15.559 15.559 0 0 1 -4.674-3.253 15.557 15.557 0 0 1 -3.253-4.674 12.64 12.64 0 0 1 -1.147-5.2.857.857 0 0 1 .253-.632.857.857 0 0 1 .632-.253h3.411a.783.783 0 0 1 .526.2.769.769 0 0 1 .274.474l.547 2.947a1.484 1.484 0 0 1 -.021.568.886.886 0 0 1 -.232.4l-2.042 2.063a10.187 10.187 0 0 0 1 1.505 14.831 14.831 0 0 0 1.274 1.4 14.816 14.816 0 0 0 1.368 1.21 13.317 13.317 0 0 0 1.516 1.021l1.979-1.979a1.178 1.178 0 0 1 .495-.284 1.355 1.355 0 0 1 .6-.053l2.905.589a.975.975 0 0 1 .484.305.741.741 0 0 1 .189.495v3.411a.857.857 0 0 1 -.253.632.857.857 0 0 1 -.631.255zm-1.642-9.263-1.221-1.179 1.347-1.347h-4.337v-1.684h4.337l-1.305-1.305 1.179-1.222 3.368 3.411z" fill="#011b34" transform="translate(-120 880)"/></svg>
                <svg *ngIf="ringcentralwebphoneService?.sessionState?.startTransfer" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m134.274-864a12.641 12.641 0 0 1 -5.2-1.147 15.559 15.559 0 0 1 -4.674-3.253 15.557 15.557 0 0 1 -3.253-4.674 12.64 12.64 0 0 1 -1.147-5.2.857.857 0 0 1 .253-.632.857.857 0 0 1 .632-.253h3.411a.783.783 0 0 1 .526.2.769.769 0 0 1 .274.474l.547 2.947a1.484 1.484 0 0 1 -.021.568.886.886 0 0 1 -.232.4l-2.042 2.063a10.187 10.187 0 0 0 1 1.505 14.831 14.831 0 0 0 1.274 1.4 14.816 14.816 0 0 0 1.368 1.21 13.317 13.317 0 0 0 1.516 1.021l1.979-1.979a1.178 1.178 0 0 1 .495-.284 1.355 1.355 0 0 1 .6-.053l2.905.589a.975.975 0 0 1 .484.305.741.741 0 0 1 .189.495v3.411a.857.857 0 0 1 -.253.632.857.857 0 0 1 -.631.255zm-1.642-9.263-1.221-1.179 1.347-1.347h-4.337v-1.684h4.337l-1.305-1.305 1.179-1.222 3.368 3.411z" fill="#59aa8e" transform="translate(-120 880)"/></svg>
            </button>
            <!--  call -->
            <button *ngIf="ringcentralwebphoneService.session  && ringcentralwebphoneService.established" (click)="closeCall()" ><svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m70.869-828-4.485-4.485a14.752 14.752 0 0 1 -3.909 2.283 11.768 11.768 0 0 1 -4.333.828.787.787 0 0 1 -.667-.242.982.982 0 0 1 -.182-.606v-3.273a.737.737 0 0 1 .182-.495.821.821 0 0 1 .465-.273l2.788-.566a1.217 1.217 0 0 1 .556.061 1.174 1.174 0 0 1 .495.263l1.9 1.9q.364-.222.788-.505t.747-.546l-9.214-9.213 1.131-1.131 14.869 14.869zm-2.2-6.768-1.152-1.151q.283-.344.6-.788a7.613 7.613 0 0 0 .515-.828l-1.96-1.98a.877.877 0 0 1 -.222-.455 1.169 1.169 0 0 1 -.02-.475l.525-2.828a.821.821 0 0 1 .273-.465.737.737 0 0 1 .495-.182h3.267a.822.822 0 0 1 .606.242.822.822 0 0 1 .242.606 11.721 11.721 0 0 1 -.838 4.345 14.979 14.979 0 0 1 -2.333 3.959z" fill="#ff1049" transform="translate(-56 844)"/></svg></button>
            
            <button *ngIf="showIncomingCall && !ringcentralwebphoneService.established" (click)="rejectCall()" ><svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m70.869-828-4.485-4.485a14.752 14.752 0 0 1 -3.909 2.283 11.768 11.768 0 0 1 -4.333.828.787.787 0 0 1 -.667-.242.982.982 0 0 1 -.182-.606v-3.273a.737.737 0 0 1 .182-.495.821.821 0 0 1 .465-.273l2.788-.566a1.217 1.217 0 0 1 .556.061 1.174 1.174 0 0 1 .495.263l1.9 1.9q.364-.222.788-.505t.747-.546l-9.214-9.213 1.131-1.131 14.869 14.869zm-2.2-6.768-1.152-1.151q.283-.344.6-.788a7.613 7.613 0 0 0 .515-.828l-1.96-1.98a.877.877 0 0 1 -.222-.455 1.169 1.169 0 0 1 -.02-.475l.525-2.828a.821.821 0 0 1 .273-.465.737.737 0 0 1 .495-.182h3.267a.822.822 0 0 1 .606.242.822.822 0 0 1 .242.606 11.721 11.721 0 0 1 -.838 4.345 14.979 14.979 0 0 1 -2.333 3.959z" fill="#ff1049" transform="translate(-56 844)"/></svg></button>
            <button *ngIf="showIncomingCall && !ringcentralwebphoneService.established" (click)="acceptCall()"><svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg></button>
            <!-- <button *ngIf="incomingCall"(click)=""><svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg></button> -->

            <!-- call groups -->
            <hr>
            <button (click)="toggleCallGroupAvailability()" [tippy]="'RingCentral.Label.ToggleCallGroups' | texttransform" [ngClass]="{
                everleadCoral: ringcentralwebphoneService.availabilityMode == 'inbound',
                groupsDisabled: ringcentralwebphoneService.availabilityMode == 'notavailable'
            }">
                <i class="fas fa-users"></i>
            </button>
            
        </div>

        <app-phone-dashboard *ngIf="ringcentralwebphoneService.extension != null"></app-phone-dashboard>

    </div>

</div>

<div *ngIf="checkAuthKey()"></div>

