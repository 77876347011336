import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';

interface Data {
  title?: string;
  color?: string[];
  series?: any;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() colors = [
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#1D1645', '#57518E', '#346188', '#1B3A55',
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#1D1645', '#57518E', '#346188', '#1B3A55'
  ];


  constructor(
    private commonService: CommonService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService
  ) { }


  customEventSubscription: Subscription = new Subscription();
  @Input() controlid: string = "";
  @Input() options: EChartsOption = {}
  @Input() merge : any;
  @Input() header: string = '';
  @Input() headercolor = 'white';
  @Input() title: string =''
  @Input() legend: boolean = false;
  @Input() chartData: Data;
  @Input() darkmode: true;
  @Input() height: number = 200;
  @Input() color: ['#ED5C73', '#F07E26', '#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#1D1645', '#57518E', '#346188', '#1B3A55']
  @Input() piePosition: string[]
  @Input() startAngle: number;
  @Input() clockwise: boolean;
  @Input() legendPosition;
  @Input() pieSize: number;
  @Input() dashboard: string = "";
  @Input() tooltipFormatter: string = "";
  @Input() transparent: boolean = true;


  dataAvailable = false;
  totalAmount: number = 0
  backgroundColor= this.transparent ? 'transparent' : 'white';
  darkBackgroundColor= '#1c3a56';
  fontColor = '#1c3a56';
  darkFontColor: 'white';

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updatePieChart' && event.value == this.controlid) {
        this.chartData = event.chartData;
        this.createChart();
      }
    });

    if (this.controlid == '') {
      this.controlid = 'pie_'.concat(this.commonService.createGuid());
    }
    this.createChart();

    //console.log('thisChartDATA-> pi', this.chartData);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && !changes['chartData'].firstChange) {
      this.createChart();
    }
  }

  createChart() {
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }
    if (this.chartData.series.length == 0) {
      this.dataAvailable = false;
      return;
    }

    let backgroundColor= 'transparent';
    let fontColor = '#1c3a56';
    if (this.darkmode) {
      fontColor = 'white';
      backgroundColor = '#1c3a56';
    }

    if (this.chartData.color.length == 0) {
      this.chartData.color = this.colors;
    }
    this.dataAvailable = true;
    const dataArray = this.chartData.series
    let data =[]
    let legendData =[]

    for (let i = 0; i < dataArray.length; i++){
      this.totalAmount += dataArray[i].value;
      if (dataArray[i].value > 0) {
        data = dataArray.map((item): any => {

          return {
            name: this.axivasTranslateService.getTranslationTextForToken(item.displayName).concat(` (${ item.value }) : ${this.getPercentage(item.value)}%`),
            value: this.getPercentage(item.value)
          }
        })
      }
    }

    this.options = {
      textStyle: {
        fontFamily:'Poppins',
        color: fontColor
      },
      color: this.chartData.color,
      label: { color: fontColor },
      tooltip: {
        trigger: 'item',
        formatter: this.tooltipFormatter || '{b}',
        textStyle: {
          fontFamily: 'Poppins',
          fontWeight: 'bold',
        },
        axisPointer: {
          axis: 'auto',
          crossStyle: {
            textStyle: {
              fontFamily: 'Poppins',
            }
          }
        }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      }

    }

    this.options.backgroundColor = backgroundColor;
    if (this.title) {
      this.options.title = {
        left: 'center',
        text: this.axivasTranslateService.getTranslationTextForToken(this.title),
        textStyle: {
          fontFamily: 'Poppins',
          fontSize: 17,
          color: fontColor
        }
      }
    }

    if (this.legend) {
      this.options.legend= {
        textStyle: {
          fontFamily: 'Poppins',
          fontSize: 12,
          color: fontColor
        },
        formatter:'{name}',
        ...this.legendPosition,
        data: data.map((item)=> item.name)
      }
    }


    if(this.dashboard === 'Kpis' && this.chartData.series){
      this.options.series = [{
        name: '',
        label: { color: fontColor },
        type: 'pie',
        radius: this.pieSize ? `${this.pieSize}%` :'50%',
        center: this.piePosition ? this.piePosition : ['50%','55%'],
        data: data,
        startAngle: this.startAngle ? this.startAngle : 90,
        clockwise: this.clockwise ? this.clockwise : false ,
        tooltip:{ position:function (pos, params, dom, rect, size) {
          let obj = {top: 10};
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;}}
        }]
    }


    if (this.chartData.series && !this.dashboard) {
      if(this.checkIfValuesareSame0(data)){
        this.dataAvailable = false;
        return
      } else {
        this.options.series = [{
          name: '',
          label: { color: fontColor },
          type: 'pie',
          radius: this.pieSize ? `${this.pieSize}%` :'50%',
          center: this.piePosition ? this.piePosition : ['50%','55%'],
          data: data,
          startAngle: this.startAngle ? this.startAngle : 90,
          clockwise: this.clockwise ? this.clockwise : false ,
          }]
      }
    }
  }

  checkIfValuesareSame0(data){
    //console.log('all 0', data.every((el) => el.value === 0 ))
    return data.every((el) => el.value === 0 )
  }


  getPercentage(value: any) {
    let returnValue = null;
    if (value != 0) {
      returnValue = ((value * 100) / this.totalAmount);
      returnValue = (Math.round(returnValue * 100) / 100).toFixed(1)
    } else {
      returnValue = 0;
    }
    return returnValue;
  }

  getHeaderLabelId() {
    return this.controlid + 'headerLabel';
  }

  ngOnDestroy() {
    if (this.customEventSubscription) {
      this.customEventSubscription.unsubscribe();
    }
  }

}
