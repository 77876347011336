import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-admin-ftp',
  templateUrl: './admin-ftp.component.html',
  styleUrls: ['./admin-ftp.component.scss']
})
export class AdminFtpComponent implements OnInit {
  ftpConnections = [];
  filterString = '';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getFtpConnections();
  }

  getFtpConnections() {
    this.externaldatasourceService.executeExternalDataSource(259, []).then(result => { this.ftpConnections = result; });
  }

  saveFTP() {
    this.ftpConnections.forEach(connection => {
      this.saveConnection(connection);
    });
  }

  saveConnection(connection) {
    let externalDataSourceId = 266;
    let param = this.commonService.getModifyArrayBody(connection, []);
    if (isNullOrUndefined(connection.id)) {
      externalDataSourceId = 265;
      param = this.commonService.getModifyArrayBody(connection, ['id']);
    }

    return new Promise((saveConnectionResolve, saveConnectionReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveConnectionResult => {
        connection.id = saveConnectionResult.id;
        saveConnectionResolve(saveConnectionResult);
      })
      .catch(error => {
        saveConnectionReject(error);
      });
    });
  }

  newFTP() {
    const fptConnection = new Object();
    fptConnection['host'] = '';
    fptConnection['port'] = 0;
    fptConnection['username'] = '';
    fptConnection['password'] = '';
    this.ftpConnections.unshift(fptConnection);
  }

  deleteFTPConnection(connection) {
    if (!isNullOrUndefined(connection.id)) {
      this.externaldatasourceService.executeExternalDataSource(267, [connection.id])
      .then(() => { this.commonService.removeItemFromArray(this.ftpConnections, connection); });
    } else {
      this.commonService.removeItemFromArray(this.ftpConnections, connection);
    }
  }
}
