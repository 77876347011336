import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { isNullOrUndefined, isUndefined, isNull } from 'util';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UiService, DragDropelements } from 'app/jollyjupiter/service/ui.service';
import { ImportHelperService } from '../import-helper.service';
import { Subscription } from 'rxjs';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';

@Component({
  selector: 'app-import-assignement',
  templateUrl: './import-assignement.component.html',
  styleUrls: ['./import-assignement.component.scss']
})
export class ImportAssignementComponent implements OnInit, OnDestroy {
  importUpdateLookupTablesEventSubscription: Subscription = new Subscription();
  suggestValuesEventSubscription: Subscription = new Subscription();
  importDataEventSubscription: Subscription = new Subscription();

  availableItems = [];
  contactItems = [];
  accountItems = [];

  @Input()fileSheets = [];
  requiredValues = [];
  requiredValuesString = '';
  fileSheetSourceFieldStringValue = 'string';
  fileSheetSourceFieldIntValue = 'int';
  fileSheetSourceFieldDateValue = 'date';
  fileSheetSourceFieldBoolValue = 'bool';
  editAccountVerificationMode = false;
  entityMemberForAccountVerification = [];
  selectedSheet = null;
  selectedProject = null;
  selectedPool = -1;
  pools = [];
  projects = [];
  availableEntities = [];
  lookupTableDefinitions = [];
  expandedItems = [];
  maxRows = 6;

  automaticAssignmentArray = null;
 
  constructor(
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private dragdropService: DragdropService,
    public eventService: EventService,
    public uiService: UiService,
    private axivasTranslateService: AxivasTranslateService,
    private importHelperService: ImportHelperService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private refreshValueService: RefreshValueService
  ) { }

  getAutoAssignments() {
    this.externaldatasourceService.executeExternalDataSource(276).then(result => {
      this.automaticAssignmentArray = result;
    });
  }

  ngOnInit() {
    this.selectedProject = this.applicationInfoService.projectID;
    this.getAutoAssignments();
    this.getPools(this.selectedProject);
    this.commonService.toggleItemExpanded(this.expandedItems, 3);
    this.availableEntities.push(this.applicationInfoService.entities.Item(this.applicationInfoService.accountEntityId));
    this.availableEntities.push(this.applicationInfoService.entities.Item(this.applicationInfoService.contactEntityId));
    this.entityMemberForAccountVerification = this.applicationInfoService.applicationSettings
      ['defaultEntityMemberForAccountVerification'].split(';');
    this.requiredValues = this.applicationInfoService.applicationSettings['importRequiredMembers'].split(';');
    this.requiredValues.forEach(item => {
      if (this.requiredValuesString !== '') {
        this.requiredValuesString = this.requiredValuesString.concat(', ');
      }
      this.requiredValuesString = this.requiredValuesString.concat(item);
    });

    this.getLookupTableDefinitions();
    // this.selectedProject = this.applicationInfoService.projectID;
    this.getProjects();

    this.importUpdateLookupTablesEventSubscription = this.eventService.importUpdateLookupTablesEvent.subscribe(() => {
      this.getLookupTableDefinitions();
    });
    this.suggestValuesEventSubscription = this.importHelperService.suggestValuesEvent.subscribe(() => {
      this.suggestValues();
    });
    this.importDataEventSubscription = this.importHelperService.importDataEvent.subscribe(value => {
      this.importSheet(value);
    });


    // if (this.applicationInfoService.projectInfo.pools.length > 0) {
    //   this.selectedPool = this.applicationInfoService.projectInfo.pools[0].id;
    // }
  }

  ngOnDestroy() {
    if (this.importUpdateLookupTablesEventSubscription) { this.importUpdateLookupTablesEventSubscription.unsubscribe(); }
    if (this.suggestValuesEventSubscription) { this.suggestValuesEventSubscription.unsubscribe(); }
    if (this.importDataEventSubscription) { this.importDataEventSubscription.unsubscribe(); }
  }

  newColumn(source, value) {
    if (value) {
      source.member = null;
    }
    source.newColumn = value;
  }

  isSourceFieldIgnored(sourceField) {
    if (isUndefined(sourceField)) {
      return false;
    }
    if (isNull(sourceField.entity)) {
      return true;
    } else {
      return false;
    }
  }

  poolChanged(pool) {
    this.selectedSheet.poolName = '';
  }

  getMinimumFieldsDescription() {
    let baseString = this.axivasTranslateService.getTranslationTextForToken('Import.Label.MinimumFieldsInfoDescription');
    baseString = baseString.replace('<0>', this.requiredValuesString);
    return baseString;
  }

  isSourceFieldDefined(sourceField) {
    let returnValue = false;
    if (sourceField.memberName != null) {
      returnValue = true;
    }
    sourceField.isDefined = returnValue;
    return returnValue;
  }

  isSourceFieldLookupTableDoubled(source, array) {
    let returnValue = false;
    array.forEach(sourceField => {
      if (!isNullOrUndefined(sourceField['lookupTableName']) && !isNullOrUndefined(source.lookupTableName)) {
        if ((sourceField['lookupTableName'].toUpperCase() === source.lookupTableName.toUpperCase())
          && sourceField !== source) {
          returnValue = true;
        }
      }
    });
    return returnValue;
  }

  isSourceFieldDirty(source, array) {
    let returnValue = false;

    // Mininmallänge
    if (!isNullOrUndefined(source.lookupTableName)) {
      if (source.lookupTableName.length < 4) {
        source.lookupTableNameIsToShort = true;
        returnValue = true;
      } else {
        source.lookupTableNameIsToShort = false;
      }
    }

    if (!isNullOrUndefined(source.memberName)) {
      if (source.memberName.length < 2) {
        returnValue = true;
        source.memberNameIsToShort = true;
      } else {
        source.memberNameIsToShort = false;
      }
    }

    // Doublettencheck & fehldender Type
    array.forEach(sourceField => {
      if (!isNullOrUndefined(sourceField['memberName']) && !isNullOrUndefined(source.memberName)) {
        if ((sourceField['memberName'].toUpperCase() === source.memberName.toUpperCase())
          && sourceField !== source) {
          returnValue = true;
        }
      }
    });

    if (source.type == null) {
      returnValue = true; 
    }

    source.isDirty = returnValue;
    return returnValue;
  }

  checkArray(array) {
    let returnValue = true;
    if (isNullOrUndefined(this.selectedSheet)) {
      return false;
    }
    array.forEach(sourceField => {
      if ((sourceField['isDirty'] === true) || (sourceField['isDefined'] === false)) {
        returnValue =  false;
      }
    });
    return returnValue;
  }

  checkImportFileState() {
    let returnValue = false;
    if (this.checkIfAccountKeysAreSet() === false) {
      returnValue = true;
    }
    if (this.checkIfContactKeysAreSet() === false) {
      returnValue = true;
    }

    
    if (this.checkIfDataPoolIsSelected() === false) {
      returnValue = true;
    }
    if (this.checkArray(this.accountItems) === false) {
      returnValue = true;
    }
    if (this.checkArray(this.contactItems) === false) {
      returnValue = true;
    }
    if (this.checkRequiredMembers() === false) {
      returnValue = true;
    }
    this.importHelperService.importDisabled = returnValue;
    return returnValue;
  }

  isTargetMemberInvalidForSourceFieldValue(sourceField, member) {
    let returnValue = false;
    if (!(isNullOrUndefined(sourceField.lookupTableDefinition) || sourceField.lookupTableDefinition === 'null')) {
      return(this.commonService.checkIfItemIsInArray(['BOOL'], member.memberType));
    } else {
      switch (sourceField.type) {
        case this.fileSheetSourceFieldStringValue:
          returnValue = this.commonService.checkIfItemIsInArray(['NUMERIC', 'BOOL'], member.memberType);
          break;
        case this.fileSheetSourceFieldIntValue:
          returnValue = this.commonService.checkIfItemIsInArray(['BOOL'], member.memberType);
          break;
      }
    }
    return returnValue;
  }

  getLookupTableDefinitions() {
    this.externaldatasourceService.executeExternalDataSource(193).then(result => { this.lookupTableDefinitions = result; });
  }

  showHelpTablePopup(lookupDefinitionId, index) {
    const dataArray = [];
    this.selectedSheet.data.data.forEach(dataRow => {
      if (!this.commonService.checkIfItemIsInArray(dataArray, dataRow[index])) {
        dataArray.push(dataRow[index]);
      }
    });
    this.applicationInfoService.miscSettings['importHelpTable'] = lookupDefinitionId;
    this.applicationInfoService.miscSettings['importHelpTableSourceValues'] = dataArray;
    this.eventService.showJjPopup('HelpTable', 'import-helptable-view', 80);
  }

  showHelpTablePopupReadOnly(header) {
    const dataArray = [];
    this.selectedSheet.data.data.forEach(dataRow => {
        dataArray.push(dataRow[header.dataIndex]);
    });
    this.applicationInfoService.miscSettings['importHelpTableReadOnlyMode'] = true;
    this.applicationInfoService.miscSettings['importHelpTable'] = null;
    this.applicationInfoService.miscSettings['importHelpTableSourceValues'] = dataArray;
    this.eventService.showJjPopup('HelpTable', 'import-helptable-view', 80);
  }

  previewValues(index, sourceField) {
    sourceField.member = null;
    if (sourceField.helpTableId === 'null') {
      sourceField.helperTableCount = null;
    } else {
      this.importHelperService.getAlreadyImportedLookupItems(
        sourceField.helpTableId,
        this.importHelperService.getArrayDataForColumn(this.selectedSheet.data.data, index),
        sourceField
      );
    }
  }

  suggestValues() {
    const removeHeaders = [];
    this.selectedSheet.data.headers.forEach((header, index) => {
      let headerFound = false;
      this.automaticAssignmentArray.forEach(automaticAssignmentArrayItem => {
        if (!headerFound) {
          if (automaticAssignmentArrayItem.sourceName.toUpperCase() === header.name.toUpperCase()) {
            if (automaticAssignmentArrayItem.lookupTableDefinitionName !== null) {
              header.lookupTableDefinition = this.commonService.getLookupTableItemByDefaultName(
                this.lookupTableDefinitions, 
                automaticAssignmentArrayItem.lookupTableDefinitionName
              );
              this.helpTableChanged(header);
            }
  
            header.entity = automaticAssignmentArrayItem.entityId;
            if (automaticAssignmentArrayItem.isKeyMember === true) {
              if (this.checkDataValuesForSourceField(header, false)) {
                header.isKeyMember = automaticAssignmentArrayItem.isKeyMember;
              }
            }
            header.memberName = automaticAssignmentArrayItem.entityMemberName;
            header.member = '-2';
            // this.applicationInfoService.entities.Item(automaticAssignmentArrayItem.entityId.toString())
            // .entityMembers.forEach(member => {
            //   if (member.id === automaticAssignmentArrayItem.member) {
            //     header.member = member;
            //     header.memberName = member.defaultName;
            //   }
            // });
            if (header.entity === 1) {
              this.accountItems.push(header);
              removeHeaders.push(header);
              headerFound = true;
            }
            if (header.entity === 4) {
              this.contactItems.push(header);
              removeHeaders.push(header);
              headerFound = true;
            }
          }
        }
      });
      if (!headerFound) {
        header.member = '-1';
        this.accountItems.push(header);
        this.entityMemberChanged(header);
        removeHeaders.push(header);
      }
    });
    removeHeaders.forEach(header => {
      this.commonService.removeItemFromArray(this.selectedSheet.data.headers, header);
      this.commonService.removeItemFromArray(this.availableItems, header);
    });
  }

  importSheet(toTempTable: boolean = false) {
    this.selectedSheet.data.headers = [];
    this.availableItems.forEach(header => {
      header.entity = null;
    });
    this.accountItems.forEach(header => {
      this.selectedSheet.data.headers.push(header);
    });
    this.contactItems.forEach(header => {
      this.selectedSheet.data.headers.push(header);
    });
    this.availableItems.forEach(header => {
      this.selectedSheet.data.headers.push(header);
    });
    this.selectedSheet.data.headers.sort((a, b) => a['dataIndex'] < b['dataIndex'] ? -1 : 1);
    this.loaderService.display(true);
    this.importHelperService.sendImportDataToBackEnd(
      this.selectedSheet,
      this.importHelperService.currentImportFile,
      this.selectedPool,
      this.selectedProject,
      toTempTable
    ).then(() => {
      this.refreshValueService.getProjectEntities(true);
      this.refreshValueService.getLookupTableDefinitions();
      this.refreshValueService.refreshTranslations();
      this.loaderService.display(false);
    }).catch(error => {
      this.loaderService.display(false);
    });
  }

  checkRequiredMembers() {
    let returnValue = true;
    this.requiredValues = this.applicationInfoService.applicationSettings['importRequiredMembers'].split(';');
    if (this.requiredValues.length > 0) {
      let counter = 0;
      this.requiredValues.forEach(value => {
        this.accountItems.forEach(header => {
          if ((value === header.memberName)) {
            counter ++;
          }
        });
      });
      if (counter !== this.requiredValues.length) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  checkIfDataPoolIsSelected() {
    let returnValue = false;
    if (!isNull(this.selectedPool) && this.selectedPool !== -1) {
      returnValue = true;
    } else {
      if (!isNullOrUndefined(this.selectedSheet.poolName)) {
        if (this.selectedSheet.poolName.length > 3) {
          return true;
        }
      }
    }
    return returnValue;
  }

  checkIfAccountKeysAreSet() {
    let returnValue = false;
    this.accountItems.forEach(header => {
      if (header.entity === 1 && header.isKeyMember) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  checkIfContactKeysAreSet() {
    let returnValue = false;
    if (this.contactItems.length == 0) {
      return true; // Kontakte werden nicht importiert
    }
    this.contactItems.forEach(header => {
      if (header.entity === 4 && header.isKeyMember) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  helpTableChanged(sourceField) {
    sourceField.lookupTableName = null;
    if (sourceField.lookupTableDefinition === '-1') {
      sourceField.lookupTableName = this.createSourceFieldNameForImport(sourceField.name);
    } else {
      if (!this.commonService.isNullOrUndefined(sourceField.lookupTableDefinition)) {
        sourceField.lookupTableName = sourceField.lookupTableDefinition.defaultName;
      }
    }
    if (!this.commonService.isNullOrUndefined(sourceField.lookupTableDefinition)) {
      sourceField.type = 'string';
    }
  }

  entityMemberChanged(sourceField) {
    if (sourceField.member === null) {
      sourceField.memberName = '';
    } else if (sourceField.member === '-1') {
      sourceField.memberName = this.createSourceFieldNameForImport(sourceField.name);
    } else {
      sourceField.memberName = sourceField.member.defaultName;
    }
  }

  createSourceFieldNameForImport(sourceFieldName) {
    let tempSourceField = sourceFieldName.toLowerCase();
      tempSourceField = tempSourceField.replace(/ /g, '');
      tempSourceField = tempSourceField.replace(/ä/g, 'ae');
      tempSourceField = tempSourceField.replace(/ö/g, 'oe');
      tempSourceField = tempSourceField.replace(/ü/g, 'ue');
      tempSourceField = tempSourceField.replace(/ß/g, 'ss');
      tempSourceField = tempSourceField.replace(/ *\([^)]*\) */g, '');
      tempSourceField = tempSourceField.replace(/-/g, '');
      tempSourceField = tempSourceField.replace(/_/g, '');
    return tempSourceField;
  }

  entityChanged(sourceField) {
    if (sourceField.entity === null) {
      sourceField.member = null;
      sourceField.memberName = null;
    }
  }

  toggleKeyMember(sourceField, index) {
    if (isNullOrUndefined(sourceField.isKeyMember) || (sourceField.isKeyMember === false)) {
      if (this.checkDataValuesForSourceField(sourceField)) {
        sourceField.isKeyMember = true;
      }
    } else {
      sourceField.isKeyMember = false;
    }
  }

  checkDataValuesForSourceField(header, showMessage = true) {
    let returnValue = true;
    this.selectedSheet.data.data.forEach(dataItem => {
      if (isNull(dataItem[header.dataIndex]) || dataItem[header.dataIndex] === '') {
        returnValue = false;
      }
    });
    if (!returnValue && showMessage) {
      this.messagingService.showDefaultWarning('', 'Import.Label.CantUseKeyBecauseOfEmptyValues');
    }
    return returnValue;
  }

  toggleSkipMember(sourceField) {
    if (isNullOrUndefined(sourceField.skipRecordIfEmpty)) {
      sourceField.skipRecordIfEmpty = true;
    } else {
      sourceField.skipRecordIfEmpty = !sourceField.skipRecordIfEmpty;
    }
  }

  getColumnWidth() {
    return (100 / this.selectedSheet.data.headers.length);
  }

  maxRowsReached(index) {
    return (index + 1) > this.maxRows ? true : false;
  }

  selectedSheetChange() {
    this.importHelperService.sheetSelected = true;
    this.availableItems = this.selectedSheet.data.headers;
    this.availableItems.forEach((header, index) => {
      header.dataIndex = index;
      header.itemMouseOver = this.getItemMouseover(header);
    });
  }

  getProjects(): Promise<any> {
    return new Promise((getProjectsResolve, getProjectsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(1, [])
      .then(getProjectsResult => {
        this.loaderService.display(false);
        this.projects = getProjectsResult;
        getProjectsResolve(null);
      })
      .catch(error => {
        this.loaderService.display(false);
        getProjectsReject(error);
      });
    });
  }

  getPools(projectID: any): Promise<any> {
    return new Promise((getPoolsResolve, getPoolsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(207, [projectID])
      .then(getPoolsResult => {
        this.loaderService.display(false);
        this.pools = getPoolsResult;
        if (getPoolsResult.length > 0) {
          this.selectedPool = getPoolsResult[0].id;
        } else {
          this.selectedPool = null;
        }
        getPoolsResolve(null);
      })
      .catch(error => {
        this.loaderService.display(false);
        getPoolsReject(error);
      });
    });
  }

  drop(event: CdkDragDrop<string[]>) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
      }
  }

  setControlStyle() {
    return {
      'width': '100%',
    };
  }

  setEntity(sourceField, entityId) {
    sourceField.entity = entityId;
  }

  getItemMouseover(sourceField) {
    let returnString = sourceField.name;
    returnString = returnString.concat('\n');
    let counter = 0;
    for (const dataItem of this.selectedSheet.data.data) {
      if (returnString !== '') {
        returnString = returnString.concat('\n');
      }
      returnString = returnString.concat(dataItem[sourceField.dataIndex]);
      if (counter > 5) {
        break;
      }
      counter ++;
    }
    return returnString;
  }
}

