<div class="incomingCall__wrapper" *ngIf="showIncomingCall != false">
    <div class="incomingCall__icon__wrapper">
        <div class="incomingCall__icon">
            <i class="fas fa-phone fa-2x"></i>
        </div>    
    </div>
    <div class="incomingCall__inner">
        <div class="incomingCall__inner__header">
            <label *ngIf="ringcentralService?.phoneConnectionStatus == 'Proceeding' 
                && ringcentralService?.direction == 'Inbound'">
                {{ 'IncomingCall.Label.Header' | texttransform }}: {{ callerNumber }}
            </label>
            <label *ngIf="ringcentralService?.phoneConnectionStatus != 'Proceeding'">
                {{ 'IncomingCall.Label.HeaderNonCall' | texttransform }}: {{ callerNumber }}
            </label>

            <div class="incomingCall__inner__header__close fingerCursor">
                <i class="fas fa-close" (click)="showIncomingCall = false"></i>
            </div>
        </div>

        <div *ngIf="loadingData == true" class="incomingCall__inner__wait">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
            texttransform }}    
        </div>

        <!-- <label class="incomingCall__inner__caller__callerNumber">{{ callerNumber }}</label> -->
        <label class="incomingCall__inner__caller__foundContacts" *ngIf="currentCallerList?.length > 0">
            {{ 'IncomingCall.Label.FoundContacts' | texttransform  }}:
        </label>

        <div class="incomingCall__inner__caller__wrapper" *ngIf="loadingData == false ">
            <div class="incomingCall__inner__caller" *ngFor="let caller of currentCallerList" (click)="loadContact(caller)" [title]="'IncomingCall.Label.Enter' | texttransform">
                <label class="incomingCall__inner__caller__contactlabel">{{ caller?.firstName }} {{ caller?.lastName }}</label>
                <label class="incomingCall__inner__caller__accountlabel" *ngIf="caller?.account?.name1">{{ caller?.account?.name1 }}</label>
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.account?.phone">
                    {{ 'IncomingCall.Label.Phone' | texttransform }}: {{ caller?.account?.phone }}
                </label>
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.phone">
                    {{ 'IncomingCall.Label.Phone' | texttransform }}: {{ caller?.phone }}
                </label>
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.mobile">
                    {{ 'IncomingCall.Label.Mobile' | texttransform }}: {{ caller?.mobile }}
                </label>                
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.extension">
                    {{ 'IncomingCall.Label.Extension' | texttransform }}: {{ caller?.extension }}
                </label>                     
            </div>
        </div>

        <div class="incomingCall__inner__accept" *ngIf="ringcentralService?.phoneConnectionStatus == 'Proceeding' && 
            ringcentralService?.direction == 'Inbound'">
            <div class="incomingCall__inner__accept__acceptRow" (click)="acceptCall()">
                <div class="incomingCall__inner__accept__acceptRow__acceptText">
                    <svg-icon src="assets/images/svg/iconset/icon-phone.svg" 
                        class="incomingCall__inner__accept__acceptRow__acceptButton fingerCursor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                    {{ 'IncomingCall.Label.Accept' | texttransform  }}
                </div>
            </div>
        </div>
        <div class="incomingCall__inner__accept" *ngIf="ringcentralwebphoneService.session != null">
            <div class="incomingCall__inner__accept__acceptRow" (click)="acceptWebRTCCall()">
                <div class="incomingCall__inner__accept__acceptRow__acceptText fingerCursor">
                    <svg-icon src="assets/images/svg/iconset/icon-phone.svg" 
                        class="incomingCall__inner__accept__acceptRow__acceptButton" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                    {{ 'IncomingCall.Label.Accept' | texttransform  }}
                </div>
            </div>
        </div>
    </div>    
</div>