import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardMode, WizardService } from '../wizard.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wizard-workflow',
  templateUrl: './wizard-workflow.component.html',
  styleUrls: ['./wizard-workflow.component.scss']
})
export class WizardWorkflowComponent implements OnInit, OnDestroy {
  customEventSubscription : Subscription = new Subscription();
  newStepName = '';
  newStepBatch = '';
  steps = [];
  results = [];
  types = [];

  flowChartId;
  template;
  isTemplate: boolean;


  constructor(
    public eventService : EventService,
    public applicationInfoService: ApplicationInfoService,
    public wizardService: WizardService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private refreshValueService: RefreshValueService,
  ) { }

  ngOnInit(): void {
    this.isTemplate = true;
    this.flowChartId = 'templateUpdated';

    this.getWorkflowSteps();
    this.getWorkflowTypes();
    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray.notRelevantStepResult)) {
      this.wizardService.helperId = this.wizardService.wizardArray.notRelevantStepResult.stepId;
    }
    this.getWorkflowStepResults(this.wizardService.helperId);
    console.warn(this.wizardService.helperId, this.wizardService.wizardArray);

  }

  getTemplate(){
    this.template = this.applicationInfoService.workflows.toArray().find((workflow) => workflow.id === this.wizardService.wizardArray.selectedTemplateWorkflow);
    if(this.template){      
      this.wizardService.wizardArray.selectedTemplateWorkflow = this.template.id
      this.wizardService.wizardArray =  {
        ...this.wizardService.wizardArray,
        defaultName: this.template.defaultName,
        duplicateTemplateId : this.template.id,
        typeLookupId: this.template.typeLookupId,
      }
        
        this.eventService.customEvent.emit({
          id: 'updateTemplateFlowChart',
          value: this.flowChartId,
          chartData: this.template,
          editor: false,
        }); 

    } else {
      this.wizardService.wizardArray = {
        ...this.wizardService.wizardArray,
        defaultName:'New Workflow',
        duplicateTemplateId : null,
        typeLookupId: null,
      }
    }
    
  }


  getWorkflowSteps() {
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.id)) { return; }

    this.steps = [];
    this.externaldatasourceService.executeExternalDataSource(104, [this.wizardService.wizardArray.id])
    .then(executeExternalDataSourceResult => {
      this.steps = executeExternalDataSourceResult;
    })
    .catch(error => { console.error(error); });

    //console.log('THIS STEPS', this.steps);
  }

  getWorkflowStepResults(workflowStepId) {
    console.warn('getWorkflowStepResults', workflowStepId)
    if (this.commonService.isNullOrUndefined(workflowStepId)) { return; }
    this.results = [];
    this.externaldatasourceService.executeExternalDataSource(498, [workflowStepId])
    .then(executeExternalDataSourceResult => {
      this.results = executeExternalDataSourceResult;
    })
    .catch(error => { console.error(error); });
  }

  createWorkflowSteps(batchString) {
    const batchArray = batchString.split(';');
    batchArray.forEach(batchArrayItem => {
      this.createWorkflowStep(batchArrayItem);
    });
  }

  createWorkflowStep(stepName) {
    this.wizardService.createWorkflowStep(this.wizardService.wizardArray, stepName)
    .then(createWorkflowStepResult => {
      this.newStepName = '';
      this.wizardService.wizardArray.workflowSteps.push(createWorkflowStepResult)
    });
  }

  getWorkflowTypes() {
    this.types = [];
    this.externaldatasourceService.executeExternalDataSource(387, [65])
    .then(getWorkflowStepResultTypesResult => {
      this.types = getWorkflowStepResultTypesResult;
    })
    .catch(error => {
      console.error('getWorkflowStepTypes', error);
    });
  }

  getTypeTranslationToken() {
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.typeLookupId)) {
      return '';
    } else {
      return 'ProcessDesign.Label.Type' + this.wizardService.wizardArray.typeLookupId + 'Desc';
    }    
  }  

  ngOnDestroy():void{
    this.applicationInfoService.isTemplateRequired = null;
    this.wizardService.wizardArray.selectedTemplateWorkflow = null;
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
}
