import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

@Component({
  selector: 'app-custom-multilist',
  templateUrl: './custom-multilist.component.html',
  styleUrls: ['./custom-multilist.component.scss']
})
export class CustomMultilistComponent extends CustomControlBaseClass  implements OnInit, OnDestroy {
  updateLookupTableEventSubscription: Subscription = new Subscription();
  availableValues = [];
  selectedValue = null;
  addedDataValues = [];
  isDarkMode = false;

  constructor(
    private injector: Injector,
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
    this.documentationPage = 'https://axivas.sharepoint.com/sites/DA/_layouts/OneNote.aspx?id=%2Fsites%2FDA%2FSiteAssets%2FEasyportal&wd=target%28Developer%20documentation.one%7C843C1E95-43A2-44D8-99E7-ED582DFB721E%2FMultiListControl%7C8178384B-3573-4616-BA8C-B0A344CA5E9E%2F%29';
  }

  ngOnInit(): void {
    this.getAvailableValues();
    this.getAddedDataValues();

    this.eventService.updateLookupTableEvent.subscribe(event => {
      if(Number(this.controlDefinition.additionalSetting1) == event.lookupTableId) {
        this.getAvailableValues();
      }
    });
    
    this.getSettingJSON();
  }

  ngOnDestroy(): void {
    if (this.updateLookupTableEventSubscription) { this.updateLookupTableEventSubscription.unsubscribe(); }
  }

  getAvailableValues() {
    if (this.controlDefinition.additionalInfo3 != '' && this.controlDefinition.additionalInfo3 != null) {
      const lookupTableInfo = this.controlDefinition.additionalInfo3.split(';');
      const tableData = this.applicationInfoService.projectLookupTables.find(
        projectLookupTable => projectLookupTable.definitionId == Number(lookupTableInfo[1]) && projectLookupTable.source == lookupTableInfo[0]
      );
      if (tableData) {
        this.availableValues = tableData.lookups;
        if (this.availableValues.length > 0) {
          this.selectedValue = this.availableValues[0]
        }
      }    
      return;  
    }

    this.externalDatasourceService.executeExternalDataSource(650, [this.controlDefinition.additionalSetting1])
    .then(getAvailableValuesResult => {
      this.availableValues = getAvailableValuesResult;
    });
  }

  getAddedDataValues() {    
    if ( this.controlDefinition.additionalSetting3 == null
      || this.controlDefinition.additionalSetting2 == null
      || this.controlDefinition.uiEntityInstanceId == null
      || this.controlDefinition.value == null
    ) {
      return;
    }
    const refId = this.entityService.getEntityValue(
      this.controlDefinition.uiEntityInstanceId,
      this.controlDefinition.value,
      1
    );
    if (this.commonService.isNullOrUndefined(refId))  {
      console.warn('refId null or undefined', this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value);
      return;
    }

    this.externalDatasourceService.executeExternalDataSource(306, [
      this.controlDefinition.uiEntityInstanceId,
      this.controlDefinition.additionalSetting2,
      refId,
      this.controlDefinition.additionalSetting3
    ])
    .then(getAddedDataValuesResult => {
      this.addedDataValues = getAddedDataValuesResult;
    })
    .catch(error => {
      console.error('getAddedDataValues', error);
    });
  }

  addItemToMultilist() {
    if (this.selectedValue == null) {
      return;
    }

    const object = new Object();
    object['entityId'] = this.controlDefinition.uiEntityInstanceId;
    object['lookupEntityMultiListTypeId'] = Number(this.controlDefinition.additionalSetting2);
    object['refId'] = this.entityService.getEntityValue(this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value, 1);
    object[this.controlDefinition.additionalSetting3] = this.selectedValue.id;
    this.loaderService.display(true);
    this.externalDatasourceService.executeExternalDataSource(308, [
      this.commonService.getModifyArrayBody(object, [])
    ])
    .then(() => {
      this.loaderService.display(false);
      this.getAddedDataValues();      
    })
    .catch(error => {
      this.loaderService.display(false);
      console.error('addItemToMultilist', error);
    });
  }

  deleteItem(item) {
    this.externalDatasourceService.executeExternalDataSource(309, [item.id])
    .then(() => {
      this.getAddedDataValues();
    })
    .catch(error => {
      console.error('deleteItem', error);
    });
  }

  getValueName(value) {
    const valueItem = this.availableValues.find(x => x.id == value[this.controlDefinition.additionalSetting3]);
    if (valueItem) {
      return valueItem.defaultName;
    } else {
      return 'item not found';
    }
  }

  editLookupTable () {
    this.applicationInfoService.miscSettings['lookupTable'] = {
      lookupTableId: Number(this.controlDefinition.additionalSetting1),
      lookupTableSource: "data"
    };
    this.eventService.showJjPopup('', 'uicontrol-design-lookuptable', 80, true);
  }
}
