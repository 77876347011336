
<div class="emailEditorWrapper" *ngIf="controlValue != null">    
    <div class="emailEditorEditableContent__wrapper" *ngIf="showPreviewOnly == false">
        <div class="emailEditorEditableContent" [style.min-width.px]="editableContentMinWidth">
            <div class="emailEditorHeader">{{ 'EMailEditor.Label.EditableContent' | texttransform }}</div>        
            <div *ngIf="selectedContent" class="emailEditorMaxHeight emailEditorContentList">
                <textarea *ngFor="let content of contents" 
                    class="emailEditorTextArea everleadShadowBox2" 
                    [(ngModel)]="content.content" 
                    (keyup)="renderContent(content)" 
                    [ngClass]="{ hideInDom: content.isEditable == false }" 
                    [disabled]="content.isEditable == false">
                </textarea>
            </div>            
        </div>
        <div class="grabber" id="grabber"></div>
    </div>

    <div class="emailEditorSection">
        <div class="emailEditorHeader">{{ 'EMailEditor.Label.Preview' | texttransform }}</div>
        <div id="emailRenderedContent" class="emailEditorRenderedContent emailEditorMaxHeight">            
            <div [innerHTML]="innerHTML"></div>
        </div>
    </div>
</div>