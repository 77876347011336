<div class="qmQuestionAnswer__wrapper">
    <div class="qmQuestionAnswer__list">
        <div class="qmQuestionAnswer__item" *ngFor="let question of questions">
            <label class="qmQuestionAnswer__questionlabel">{{ commonService.getTranslationValueFromArray(question) | texttransform }}</label>

            <div *ngIf="question.isMultiselect">
                <div class="qmQuestionAnswer__item__answer fingerCursor" *ngFor="let answer of question.answers" (click)="toggleCheck(answer.id)">
                    <i class="far fa-check-square" *ngIf="checkedItemsContainId(answer.id)"></i>
                    <i class="far fa-square" *ngIf="!checkedItemsContainId(answer.id)"></i>
                    {{ commonService.getTranslationValueFromArray(answer) | texttransform }}
                </div>    
            </div>

            <div *ngIf="!question.isMultiselect" class="qmQuestionAnswer__multiselect">
                <select [(ngModel)]="selectedAnswers[question.id]" (change)="updateAnswerList()">
                    <option *ngFor="let answer of question.answers" [ngValue]="answer.id">
                        {{ commonService.getTranslationValueFromArray(answer) | texttransform }}
                    </option>
                </select>
                <div *ngIf="checkIfSelectedAnswerIsType(selectedAnswers[question.id], 1907)" class="qmQuestionAnswer__subText">
                    <label class="qmQuestionAnswer__subText__label"><i class="fas fa-sticky-note"></i>{{ 'QualityManagement.Label.SubText' | texttransform }}</label>
                    <input [(ngModel)]="answerTexts[selectedAnswers[question.id]]"  (change)="updateAnswerTextList()"
                        class="qmQuestionAnswer__subText__value">
                </div>
            </div>
        </div>
    </div>
</div>