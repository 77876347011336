<div class="reportingWrapper">
    <div class="reportingSelection">
        <div class="reportingSelection__item">
            <input [(ngModel)]="mode" [value]="1" type="radio"><div>{{ 'Reporting.Label.ReportName' | texttransform }}</div>
        </div>
        <div class="reportingSelection__item">
            <input [(ngModel)]="mode" [value]="2"  type="radio" (click)="getDownloadContent()"><div>{{ 'Reporting.Label.ReportToDownload' | texttransform }}</div>
        </div>
    </div>

    <div *ngIf="mode==1" class="modeSection">
        <div class="everleadShadowBox reportingHeader">
            <label>{{ 'Reporting.Label.ReportName' | texttransform }}</label>
            <select [(ngModel)]="selectedTemplate" (change)="initReportTemplate()">
                <option [ngValue]="template" *ngFor="let template of templates">
                    <span *ngIf="!template.nameTranslationToken">{{ template.defaultName | texttransform }}</span>
                    <span *ngIf="template.nameTranslationToken">{{ template.nameTranslationToken.tokenFullName | texttransform
                        }}</span>
                </option>
            </select>
        </div>

        <label class="everleadSmallHeader">
            {{ 'Reporting.Label.ParameterHeader' | texttransform }}
        </label>

        <div *ngFor="let parameter of selectedTemplate?.reportParameters">
            <div *ngIf="parameter.isUserParameter" class="everleadShadowBox reportingParameter">
                <label [id]="getSubControlId(parameter, 'label')" *ngIf="parameter?.nameTranslationToken != null" [tippy]="commonService.getTooltip(parameter)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                    {{ parameter?.nameTranslationToken.tokenFullName | texttransform }}</label>
                <label [id]="getSubControlId(parameter, 'label')" class="jjReportingDynamicControlLabel"
                    *ngIf="parameter?.nameTranslationToken == null" [tippy]="commonService.getTooltip(parameter)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                    {{ parameter?.defaultName | texttransform }}</label>
                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource == null" type="number">
                <select [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]"
                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource != null"
                    type="number" class="jjReportingDynamicControl">
                    <option [ngValue]="item[parameter.externalDataSource.dataKey]"
                        *ngFor="let item of paramterExternalDataSourceResults[parameter.externalDataSource.id]">
                        {{ item[parameter.externalDataSource.dataValue] }}
                    </option>
                </select>
                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'boolean'" type="checkbox" style="width: 10px">
                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'string'" type="text">
                <span *ngIf="parameter.parameterTypeLookup?.defaultName == 'date'" class="reportingParameter__dateSpan">
                    <input [(ngModel)]="ngValues[parameter.id]" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" 
                        readonly [id]="getSubControlId(parameter)" appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>    
                </span>
                <i class="fas fa-times-circle jjReportingLeftSpacer"
                    *ngIf="parameter.parameterTypeLookup?.defaultName != 'boolean'"
                    (click)=" clearSelectedValue(parameter.id)"></i>
            </div>
        </div>

        <br>
        <app-extended-button (buttonClickEvent)="executeReporting()" buttonText="Reporting.Label.RequestReport"
            iconCss="fas fa-file-signature" style="width: 250px;">
        </app-extended-button>
    </div>


    <div *ngIf="mode==2" class="modeSection">
        <div class="downloadContentItem">
            <div class="downloadContentItem__name">{{ 'Reporting.Label.DownloadName' | texttransform}}</div>        
            <div class="downloadContentItem__date">{{ 'Reporting.Label.DownloadDate' | texttransform}}</div>
            <div class="downloadContentItem__downloaded"></div>
            <div class="downloadContentItem__download"></div>
        </div>
        <div *ngFor="let downloadContentItem of downloadContent" class="downloadContentItem everleadShadowBox" [ngClass]="{
            hideInDom: downloadContentItem.contentLibraryFileId == null
        }">
            <div class="downloadContentItem__name ellipsisText">{{ downloadContentItem?.contentLibraryFile?.name }}</div>        
            <div class="downloadContentItem__date">{{ downloadContentItem.finishedAt | date:'medium' }}</div>
            <div class="downloadContentItem__downloaded"><span *ngIf="downloadContentItem.isDownloaded">{{ 'Reporting.Label.AlreadyDownloaded' | texttransform}}</span></div>
            <div class="downloadContentItem__download"><i class="fas fa-download fingerCursor" (click)="downloadFile(downloadContentItem)"></i></div>
        </div>
    </div>
</div>
<!-- 

<div *ngIf="selectedTemplate == null" class="reportListWrapper">
    <label class="reportSectionHeader">{{ 'Reporting.Label.ReportName' | texttransform }}</label>    
    <div class="reportListList">
        <div class="everleadShadowBox reportBox fingerCursor" *ngFor="let template of templates" (click)="initReportTemplate(template)">
            <div class="reportBox__icon">
                <svg-icon src="assets/images/svg/file-signature.svg" [svgStyle]="{ 'width.px': 50, 'height.px': 50 }"></svg-icon>        
            </div>
            <div class="reportBox__label">
                <div class="reportBox__label__name">
                    {{ commonService.getTranslationValueFromArray(template, 'defaultName') | texttransform }}
                </div>
                <div class="reportBox__label__description" *ngIf="template.descriptionTranslationTokenId != null">
                    {{ axivasTranslateService.getTranslationTextForId(template.descriptionTranslationTokenId) | texttransform }}
                </div>                
            </div>
        </div>
    </div>
</div>

<div class="reportingDetails" *ngIf="selectedTemplate != null">
    <div>
        <button class="everleadCustomButton" (click)="selectedTemplate = null">{{ 'General.Label.Back' | texttransform }}</button>
    </div>    
    <div class="reportListParameterHeader">
        {{ commonService.getTranslationValueFromArray(selectedTemplate, 'defaultName') | texttransform }}
    </div>
    <label class="reportSectionHeader">
        {{ 'Reporting.Label.ParameterHeader' | texttransform }}
    </label>
    <div class="everleadShadowBox" *ngIf="selectedTemplate?.reportParameters.length == 0">
        {{ 'Reporting.Label.ThisReportHasNoParameters' | texttransform }}
    </div>
    <div class="everleadShadowBox" *ngIf="selectedTemplate?.reportParameters.length > 0">
        <div *ngFor="let parameter of selectedTemplate?.reportParameters">
            <div *ngIf="parameter.isUserParameter" class="reportingParameter">
                <label [id]="getSubControlId(parameter, 'label')" *ngIf="parameter?.nameTranslationToken != null" [tippy]="commonService.getTooltip(parameter)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                    {{ parameter?.nameTranslationToken.tokenFullName | texttransform }}</label>
                <label [id]="getSubControlId(parameter, 'label')" class="jjReportingDynamicControlLabel"
                    *ngIf="parameter?.nameTranslationToken == null" [tippy]="commonService.getTooltip(parameter)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                    {{ parameter?.defaultName | texttransform }}</label>
                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource == null" type="number">
                <select [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]"
                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource != null"
                    type="number" class="jjReportingDynamicControl">
                    <option [ngValue]="item[parameter.externalDataSource.dataKey]"
                        *ngFor="let item of paramterExternalDataSourceResults[parameter.externalDataSource.id]">
                        {{ item[parameter.externalDataSource.dataValue] }}
                    </option>
                </select>
                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'boolean'" type="checkbox" style="width: 10px">
                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'string'" type="text">
                <span *ngIf="parameter.parameterTypeLookup?.defaultName == 'date'" class="reportingParameter__dateSpan">
                    <input [(ngModel)]="ngValues[parameter.id]" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" 
                        readonly [id]="getSubControlId(parameter)" appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>    
                </span>
                <i class="fas fa-times-circle jjReportingLeftSpacer"
                    *ngIf="parameter.parameterTypeLookup?.defaultName != 'boolean'"
                    (click)=" clearSelectedValue(parameter.id)"></i>
            </div>
        </div>
    </div>
    <br>
    <app-extended-button (buttonClickEvent)="executeReporting()" buttonText="Reporting.Label.RequestReport"
        iconCss="fas fa-file-signature">
    </app-extended-button>
</div> -->