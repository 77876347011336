<div class="manageRights__type everleadShadowBox everleadHorizontalFlex">
    <label>ModuleType</label>
    <select [(ngModel)]="selectedModuleType" (change)="getModulesForType(selectedModuleType)">
        <option [ngValue]=null>Ohne spezifischen ModulTyp</option>
        <option *ngFor="let moduleType of moduleTypes" [ngValue]="moduleType.id">
            {{ moduleType.defaultName | texttransform }}
        </option>
    </select>    
</div>

<div class="everleadShadowBox">
    <div class="infoLabel">Neues Modul mit diesem Modultyp anlegen</div>
    <div class="manageRights__newRightsName everleadHorizontalFlex">
        <label>Modulname</label>
        <input [(ngModel)]="newRightName">
        <button (click)="addModuleToType(newRightName)" [disabled]="newRightName.length < 3">Modul anlegen</button>
    </div>  
</div>

<div class="everleadShadowBox">
    <div>Vorhandene Module</div>

    <div>
        <div class="manageRights__permissions__header">
            <label>
                <i class="fas fa-search"></i>
                <input [(ngModel)]="filterString" style="width: 100%" class="filterInput">
            </label>
            <div>
                <div *ngFor="let permissionRight of permissionRights">
                    <div>
                        {{ permissionRight.defaultName | texttransform }}
                    </div>
                </div>
            </div>
        </div>    
    </div>
    <div id="userPermissionDesign"></div>
    <div [style.max-height.px]="getMaxHeight()" class="autoOverflowY">
        <div *ngFor="let module of moduleTypeModules" class="manageRights__permissions__wrapper" [ngClass]="{
            hideInDom: itemIsFiltered(module)
        }">
            <label [tippy]="commonService.getNameTranslationOrDefaultName(module) | texttransform">
                {{ module.defaultName | texttransform }}
            </label>
            <div class="manageRights__permissions">
                <div *ngFor="let permissionRight of permissionRights" [ngClass]="{                 
                    everleadCoral: moduleHasPermission(module, permissionRight),
                    fingerCursor: !moduleHasPermission(module, permissionRight)
                }" (click)="addPermissionToModule(module, permissionRight)">                
                    <i class="far fa-check-square" *ngIf="moduleHasPermission(module, permissionRight)"></i>                
                    <i class="far fa-square" *ngIf="!moduleHasPermission(module, permissionRight)"></i>                
                </div>
            </div>
        </div>            
    </div>
</div>

