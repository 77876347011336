<div class="everleadShadowBox everleadShadowBox2"
    (click)="commonService.toggleItemExpanded(expandedReportParameters, 1);$event.stopPropagation()">
    <i class="fas fa-plus-square" style="margin-right: 5px; margin-right: 3px"
        (click)="newReportParameter();$event.stopPropagation()"></i>
    <label class="adminReportLabel">ReportParameters</label>
    <i class="floatRight fingerCursor adminReportExpanderIcon fas" [ngClass]="{
    'fa-chevron-down': commonService.isItemExpanded(expandedReportParameters, 1) != true,
    'fa-chevron-up': commonService.isItemExpanded(expandedReportParameters, 1) == true
    }"></i>
    <div class="adminReportQueryDiv" cdkDropList [cdkDropListData]="selectedReport.reportParameters"
        (cdkDropListDropped)="dragdropService.moveInList($event, '0')"
        [ngClass]="{'hideInDom': (commonService.isItemExpanded(expandedReportParameters, 1) != true)}" style="background-color: white;">
        <div *ngFor="let reportParameter of selectedReport.reportParameters" class="adminReportQueryDetailDiv"
            [cdkDragData]="reportParameter" cdkDrag data-html="true">
            <div
                (click)="commonService.toggleItemExpanded(reportParameterExpanded, reportParameter.id);$event.stopPropagation()">
                <i class="floatLeft fingerCursor adminReportExpanderIcon fas fa-trash everleadCoral "
                    (click)="removeReportParameter(reportParameter);$event.stopPropagation()"></i>
                <label class="adminReportIdLabel">({{reportParameter.id}})</label>
                <input type="text" [(ngModel)]="reportParameter.defaultName" style="width: 30%; margin-left: 10px"
                    class="jjDesignBorder" (click)="$event.stopPropagation()" (mousedown)="$event.stopPropagation()">
                <app-translation-button type='nameTranslationToken' source='reportParameter' [target]=reportParameter></app-translation-button>
                <app-translation-button type='descriptionTranslationToken' source='reportParameter' [target]=reportParameter></app-translation-button>
            
                <input type="text" [(ngModel)]="reportParameter.queryEditorDebugValue" style="width: 30%; margin-left: 10px" *ngIf="queryBuilderMode == 1"
                    class="jjDesignBorder" (click)="$event.stopPropagation()" placeholder="Debug-Abfrage-Wert" (mousedown)="$event.stopPropagation()">

                <i class="fas fa-grip-lines floatRight adminReportExpanderIcon"></i>
                <i class="floatRight fingerCursor adminReportExpanderIcon fas" [ngClass]="{
                'fa-chevron-down': commonService.isItemExpanded(reportParameterExpanded, reportParameter.id) != true,
                'fa-chevron-up': commonService.isItemExpanded(reportParameterExpanded, reportParameter.id) == true
            }"></i>
            </div>
            <span *ngIf="commonService.isItemExpanded(reportParameterExpanded, reportParameter.id) == true"
                (click)="$event.stopPropagation()">
                <div>
                    <label class="adminReportQueryLabel">Standard-Wert</label>
                    <input type="text" [(ngModel)]="reportParameter.defaultValue"
                        class="adminReportQueryData jjDesignBorder" (mousedown)="$event.stopPropagation()">
                </div>
                <div>
                    <label class="adminReportQueryLabel">Value</label>
                    <input type="text" [(ngModel)]="reportParameter.value" class="adminReportQueryData jjDesignBorder"
                        (mousedown)="$event.stopPropagation()">
                </div>
                <div>
                    <label class="adminReportQueryLabel">isMandatory</label>
                    <input type="checkbox" [(ngModel)]="reportParameter.isMandatory">
                </div>
                <div>
                    <label class="adminReportQueryLabel">isUserParameter</label>
                    <input type="checkbox" [(ngModel)]="reportParameter.isUserParameter">
                </div>
                <div>
                    <label class="adminReportQueryLabel">parameterTypeLookupId</label>
                    <select [(ngModel)]="reportParameter.parameterTypeLookupId">
                        <option [ngValue]="parameterTypes.id" *ngFor="let parameterTypes of parameterTypes">{{ parameterTypes.defaultName }}</option>
                    </select>            
                </div>
            </span>
        </div>
    </div>
</div>