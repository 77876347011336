import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-vipactivity',
  templateUrl: './vipactivity.component.html',
  styleUrls: ['./vipactivity.component.scss']
})
export class VipactivityComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="189735"> </jj-container>`;
  innerHTML = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.trackingService.trackPageView();
  }

  openVipSettings() {
    this.eventService.showJjPopup('Supervisor.Label.ManageVip', 'vip', '80');
  }
}
