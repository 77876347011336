import { Component, OnInit, OnDestroy } from '@angular/core';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { LoaderService } from 'app/shared/service/loader-service';
import { LanguageService } from 'app/shared/translation/language.service';
import { FilterDto } from 'app/jollyjupiter/dto/filter-dto';
import { FilterParameterDto } from 'app/jollyjupiter/dto/filter-parameter-dto';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-export-form',
  templateUrl: './export-form.component.html',
  styleUrls: ['./export-form.component.scss']
})
export class ExportFormComponent implements OnInit, OnDestroy {
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  filterSegments = [];
  dataFilter = null;
  dataFilterCaller = 'exportform';
  exportTemplateCollapsed = false;
  filterCollapsed = false;
  exportTemplates = [];
  campaigns = [];
  languages = [];
  selectedExportTemplate: any;
  selectedLanguage: any;
  selectedCampaign: any;
  filters = {};
  isCompleteExport = false;

  public currentExport = 0;

  constructor(
    private graphQLService: GraphQLService,
    public applicationInfoService: ApplicationInfoService,
    private graphqlqueryService: GraphqlqueryService,
    private sharedAPI: SharedAPI,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private languageService: LanguageService,
    private datafilterService: DatafilterService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnDestroy() {
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.getExportTemplates();
    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(event => {
      if (event.target === this.dataFilterCaller) {
        this.datafilterService.getDataFilterInformation(event.arguments[0].id)
        .then(getDataFilterInformationResult => {
          this.filterSegments = [];
          getDataFilterInformationResult.filterSegments.forEach(filterSegment => {
            this.filterSegments.push(filterSegment);
          });
          this.dataFilter = event.arguments[0].id;
        });
      }
    });

    this.languageService.getLanguages()
    .then(data => {
      this.languages = data;
      this.selectedLanguage = this.commonService.getFirstItemFromArrayIfAvailable(this.languages);
    })
    .catch(error => {
      console.error(error);
    });
    this.applicationInfoService.campaigns.impArray.items.forEach(campaign => {
      const campaignItem = JSON.parse(JSON.stringify(this.applicationInfoService.campaigns.Item(campaign)));
      if (campaignItem.nameTranslationToken) {
        campaignItem.defaultName = this.axivasTranslateService.getTranslationTextForToken(campaignItem.nameTranslationToken);
      }
      this.campaigns.push(campaignItem);
    });
    this.selectedCampaign = this.commonService.getFirstItemFromArrayIfAvailable(this.campaigns);
  }

  getExportTemplates() {
    this.exportTemplates = [];
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query, this.graphqlqueryService.getQuery('reportingBaseReports'),
    [this.applicationInfoService.projectID])
    .then(result => {
      console.warn('getExportTemplates', result);
      result.data.baseReports.forEach(exportTemplate => {
        this.exportTemplates.push(exportTemplate);
      });
      this.selectedExportTemplate = this.commonService.getFirstItemFromArrayIfAvailable(this.exportTemplates);
    })
    .catch(error => {
      console.error(error);
    });
  }

  executeExportTemplatePost(): Promise<any> {
    return new Promise<any>((executeExportTemplateResolve, executeExportTemplateReject) => {
      this.loaderService.display(true);
      const route = '/Export/get/'.concat(this.selectedExportTemplate.id, '/report?projectId=',
        this.applicationInfoService.projectID.toString());
      const body = [];

      body.push({id: 9, value: this.applicationInfoService.projectID.toString() });
      body.push({id: 10, value: this.selectedLanguage.id.toString() });
      body.push({id: 11, value: this.selectedCampaign.id.toString() });
      body.push({id: 12, value: this.isCompleteExport ? '0' : '1' });
      this.sharedAPI.executeExportTemplatePost(route, body)
      .subscribe(executeExportTemplatePostResult => {
        this.loaderService.display(false);
        this.commonService.downloadFile(executeExportTemplatePostResult, 'application/xlsx',
          'Export '.concat(this.applicationInfoService.projectName, ' ',
          this.commonService.getDateTimeString(new Date(Date.now())), '.xlsx'));
        executeExportTemplateResolve(null);
      }, (error) => {
        this.loaderService.display(false);
        executeExportTemplateReject(error);
      });
    });
  }

  executeExportTemplate(): Promise<any> {
    return new Promise<any>((executeExportTemplateResolve, executeExportTemplateReject) => {
      this.loaderService.display(true);

      let exportFilterAccount = '[]';

      if (this.isCompleteExport) {
         exportFilterAccount = this.datafilterService.buildJsonDataFilter(this.getFilter(false));
      }

      const map = new Map([
        [ 'accounts' , exportFilterAccount]
        ]
      );

      this.sharedAPI.executeExportTemplate(this.selectedExportTemplate.id, 'Export.xlsx' , 'Export', this.selectedLanguage.id, map)
      .subscribe(executeExportTemplateResult => {
        console.log(executeExportTemplateResult);
        this.loaderService.display(false);
        this.commonService.downloadFile(executeExportTemplateResult, 'application/xlsx', 'Export.xlsx');
        executeExportTemplateResolve(executeExportTemplateResult);
      }, (error) => {
        this.loaderService.display(false);
        executeExportTemplateReject(error);
      });
    });
  }

  getFilter(closedData: boolean): FilterDto[] {
     const filter = new FilterDto();

     filter.filterId = 3;
     filter.filterParameter = [new FilterParameterDto(7, String(closedData) , null)];

     return [filter];
  }

  toggleClosedData(e: { target: { checked: boolean; }; }) {
    this.isCompleteExport = e.target.checked;
  }

  editDataFilter() {
    this.datafilterService.setFilterEnvironment(
      this.dataFilter,
      1,
      this.dataFilterCaller,
      null,
      152
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80');
  }

  toggleItem(toggleName: string) {
    console.warn(toggleName, this[toggleName], this['exportTemplateCollapsed']);
    this[toggleName] = !this[toggleName];
  }
}
