<div class="accordeon__wrapper">
    <div *ngFor="let control of controlDefinition.childs" [ngClass]="{ hideInDom: !isVisible(control) }">
        <div *ngIf="isVisible(control)" class="accordeon__line">
            <div class="accordeon__lineHeader fingerCursor" (click)="selectChild(control.id)">
                <label>{{ commonService.getTranslationValueFromArray(control, 'displayText') | texttransform }}</label>
                <i class="fas " [ngClass]="{
                    'fa-chevron-down': selectedControlId != control.id,
                    'fa-chevron-up': selectedControlId == control.id
                }"></i>
            </div>
            <div class="accordeon__lineContent" *ngIf="selectedControlId == control.id || applicationInfoService.designerMode == true">
                <app-custom-container controluiid={{control?.logicalControlId}}></app-custom-container>
            </div>    
        </div>
    </div>
</div>