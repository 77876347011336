import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { isNullOrUndefined } from 'util';
import { ImportHelperService } from '../import-helper.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-import-helptable-view',
  templateUrl: './import-helptable-view.component.html',
  styleUrls: ['./import-helptable-view.component.scss']
})
export class ImportHelptableViewComponent implements OnInit {
  sourceValues = [];
  availableValues = [];
  lookUpTableDefinitionId = null;
  newDefinitionName = null;
  readOnlyMode = false;

  constructor(
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private importHelperService: ImportHelperService,
    private eventService: EventService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit() {
    this.readOnlyMode = this.applicationInfoService.miscSettings['importHelpTableReadOnlyMode'];
    this.applicationInfoService.miscSettings['importHelpTableReadOnlyMode'] = null;
    this.sourceValues = this.applicationInfoService.miscSettings['importHelpTableSourceValues'];
    this.lookUpTableDefinitionId = this.applicationInfoService.miscSettings['importHelpTable'];
    this.importHelperService.getAvailableValuesForLookupTable(this.lookUpTableDefinitionId)
    .then(result => {
      this.availableValues = result;
    });
  }

  addMissingValues() {
    const promiseArray: Promise<any>[] = [];
    this.sourceValues.forEach(sourceValue => {
      if (!this.sourceIsAvailable(sourceValue)) {
        promiseArray.push(this.importHelperService.createLookupTable(sourceValue, this.lookUpTableDefinitionId));
      }
    });
    Promise.all(promiseArray)
    .then(() => {
      this.importHelperService.getAvailableValuesForLookupTable(this.lookUpTableDefinitionId)
      .then(result => {
        this.availableValues = result;
        this.messagingService.showDefaultSuccess('',
          this.axivasTranslateService.getTranslationTextForToken('Import.Label.NewEntriesAddes'),
          false);
      });
    });
  }

  sourceIsAvailable(sourceValue) {
    return this.commonService.checkIfItemIsInArray(this.availableValues, sourceValue);
  }

  createNewDefinition() {
    this.importHelperService.createLookupTableDefiniton(this.newDefinitionName)
    .then(result => {
      this.lookUpTableDefinitionId = result.id;
      this.eventService.importUpdateLookupTablesEvent.emit();
    });
  }
}
