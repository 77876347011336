import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventService } from '../../../service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-errormessage',
  templateUrl: './errormessage.component.html',
  styleUrls: ['./errormessage.component.scss']
})
export class ErrormessageComponent implements OnInit, OnDestroy {
  errorMessage: string = '';  
  errorTitle: string = '';  
  errorVisible: boolean = false;

  errorSubscription: Subscription = new Subscription;

  constructor(
    private eventService: EventService
  ) { 
    this.errorSubscription = this.eventService.raiseErrorEvent.subscribe(error => {
      this.errorTitle = error.arguments[0];
      this.errorMessage = error.arguments[1];
      this.errorVisible = true;
    });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    if (this.errorSubscription) { this.errorSubscription.unsubscribe(); }
  }

  hideErrorMessage() {
    this.errorVisible = false;
  }

  showError(errorMessage: string) {
    this.errorMessage = errorMessage;
    this.errorVisible = true;
  }
}
