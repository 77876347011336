import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-startpagedashboard',
  templateUrl: './startpagedashboard.component.html',
  styleUrls: ['./startpagedashboard.component.scss']
})
export class StartpagedashboardComponent implements OnInit {
  constructor(
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {    
    this.trackingService.trackPageView();
  } 
}
