import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CustomControlBaseClass } from '../../custom/customcontrolbaseclass';

@Component({
  selector: 'app-extended-button',
  templateUrl: './extended-button.component.html',
  styleUrls: ['./extended-button.component.scss']
})
export class ExtendedButtonComponent extends CustomControlBaseClass implements OnInit {
  @Input() buttonText = '';
  @Input() iconCss = '';
  @Input() iconUrl = null;
  @Input() enabled = true;

  @Output() buttonClickEvent = new EventEmitter<any>();
  buttonClickBlocker = false;
  
  constructor(
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  getIconClass() {
    return ''.concat(this.iconCss, '  extendedButton__icon');
  }

  buttonClicked() {
    if (this.buttonClickBlocker) { return; }
    this.buttonClickBlocker = true;
    setTimeout(() => { 
      this.buttonClickBlocker = false; 
    }, 2000);
    if (this.enabled) {
      this.buttonClickEvent.emit();
    }
  }
}
