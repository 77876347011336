<div class="maxHeight">
<div *ngIf="applicationInfoService?.isMobile() && visibleSubTabs.length > 0">
    <select class="superVisorMainMobileSelect" [(ngModel)]="selectedSubTabIndex"  (change)="setCurrentSubChild(selectedSubTabIndex)">
        <option [ngValue]="child.id" *ngFor="let child of visibleSubTabs">
            {{ child.displayText | texttransform }}
        </option>
    </select>
    <br>

</div>

<div class="jjTab" *ngIf="!applicationInfoService?.isMobile() && selectedSubTabIndex != null">
    <button [ngClass]="{ 
            jjActivetab: child.id == selectedSubTabIndex,
            jjControlDefaultColor: child.id == selectedSubTabIndex,
            jjInactivetab: child.id != selectedSubTabIndex
        }" *ngFor="let child of visibleSubTabs; let currentIndex = index" class="tablinks"
        (click)="setCurrentSubChild(child.id)">
        {{ child.displayText | texttransform }}
    </button>
</div>

<!-- Tab content -->
<span id="contentSpan"></span>
<div class="mainContainer noScrollbar" *ngIf="getContent()" [style.height.px]="getContentHeight('contentSpan', 40)">
   
    <!-- General Settings-->
    <div *ngIf="selectedTabIndex == 0" class="everleadMaxHeightOverflow">
        <app-custom-container controluiid="generalsettings">
        </app-custom-container>
    </div>

    <!-- Blacklist-->
    <div *ngIf="selectedTabIndex == 1" class="everleadMaxHeightOverflow">
        <app-blacklist></app-blacklist>
    </div>

    <!-- Data deliviers-->
    <div *ngIf="selectedTabIndex == 2" class="everleadMaxHeightOverflow">
        <app-datadelivery></app-datadelivery>
    </div>

     <!-- USER-->
    <div *ngIf="selectedSubTabIndex == 300" class="everleadMaxHeightOverflow"><app-usermanagement></app-usermanagement></div>
    <div *ngIf="selectedSubTabIndex == 301" class="everleadMaxHeightOverflow"><app-projectusers></app-projectusers></div>
    <div *ngIf="selectedSubTabIndex == 302" class="everleadMaxHeightOverflow"><app-usergroups></app-usergroups></div>
    <div *ngIf="selectedSubTabIndex == 303" class="everleadMaxHeightOverflow"><app-automatic-record-assignment></app-automatic-record-assignment></div>

     <!-- Project-->
    <div *ngIf="selectedSubTabIndex == 401" class="everleadMaxHeightOverflow"><app-custom-container controluiid="campaign"></app-custom-container></div>
    <div *ngIf="selectedSubTabIndex == 402" class="everleadMaxHeightOverflow"><app-custom-container controluiid="process"></app-custom-container></div>
    <div *ngIf="selectedSubTabIndex == 403" class="everleadMaxHeightOverflow"><app-custom-container controluiid="leadstate"></app-custom-container></div>
    <div *ngIf="selectedSubTabIndex == 404" class="everleadMaxHeightOverflow"><app-audit onlySettings=true></app-audit></div>
    <div *ngIf="selectedSubTabIndex == 405" class="everleadMaxHeightOverflow"><app-pools onlySettings=true></app-pools></div>
    <div *ngIf="selectedSubTabIndex == 406" class="everleadMaxHeightOverflow"><app-import-main mode=2></app-import-main></div>
    <div *ngIf="selectedSubTabIndex == 407" class="everleadMaxHeightOverflow"><app-ajtracker></app-ajtracker></div>
    <div *ngIf="selectedSubTabIndex == 408" class="everleadMaxHeightOverflow"><app-eventlist></app-eventlist></div>
    <div *ngIf="selectedSubTabIndex == 409" class="everleadMaxHeightOverflow"><app-admin-entity></app-admin-entity></div>
    <div *ngIf="selectedSubTabIndex == 410" class="everleadMaxHeightOverflow"><app-customerapi></app-customerapi></div>
    <div *ngIf="selectedSubTabIndex == 412" class="everleadMaxHeightOverflow"><app-consent-management></app-consent-management></div>
    <div *ngIf="selectedSubTabIndex == 801" class="everleadMaxHeightOverflow"><app-datarights></app-datarights></div>
    
    <!-- AI Prompt -->
    <div *ngIf="selectedSubTabIndex == 820" class="everleadMaxHeightOverflow"><app-admin-aiprompts></app-admin-aiprompts></div>

    <!-- Supervisor (Alex & Gross intern)-->
    <div *ngIf="selectedSubTabIndex == 501" class="everleadMaxHeightOverflow"><app-custom-container controluiid="leadreportsettings"></app-custom-container></div>
    <div *ngIf="selectedSubTabIndex == 502" class="everleadMaxHeightOverflow"><app-mocoprojectassignment></app-mocoprojectassignment></div>

    <!-- Follow Up -->
    <div *ngIf="selectedTabIndex == 6" class="everleadMaxHeightOverflow">
       
    </div>

    <!-- E-Mail -->
    <div *ngIf="selectedSubTabIndex == 701" class="everleadMaxHeightOverflow"><app-emailtemplates></app-emailtemplates></div>
    <div *ngIf="selectedSubTabIndex == 702" class="everleadMaxHeightOverflow"><app-emailcontent></app-emailcontent></div>


    <!-- ADMIN-->
    <div *ngIf="applicationInfoService.isDeveloper" class="maxHeight">                
        <div *ngIf="selectedSubTabIndex == 802" class="everleadMaxHeightOverflow"><app-sendmailitems></app-sendmailitems></div>
        <div *ngIf="selectedSubTabIndex == 803" class="everleadMaxHeightOverflow"><app-custom-projectoverview></app-custom-projectoverview></div>
        <div *ngIf="selectedSubTabIndex == 806" class="everleadMaxHeightOverflow"><app-ses></app-ses></div>
        <div *ngIf="selectedSubTabIndex == 805" class="everleadMaxHeightOverflow"><app-uistyle></app-uistyle></div>        
        <div *ngIf="selectedSubTabIndex == 804" class="everleadMaxHeightOverflow"><app-custom-container controluiid="70927"></app-custom-container></div>
        <div *ngIf="selectedSubTabIndex == 807" class="everleadMaxHeightOverflow"><app-logical-entities></app-logical-entities></div>
    </div>
</div>
</div>