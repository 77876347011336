import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { OpportunityService } from 'app/jollyjupiter/service/opportunity.service';

@Component({
  selector: 'app-wizard-stepresult',
  templateUrl: './wizard-stepresult.component.html',
  styleUrls: ['./wizard-stepresult.component.scss']
})
export class WizardStepresultComponent implements OnInit {
  emailTemplates = [];
  qmUserGroups = [];
  users = [];
  results = [];
  innerHTML = null;
  innerHTMLFollowUp = null;
  emailTemplatePreview = null;
  workflows = [];
  campaigns = [];
  leadStates = [];
  workflowStepResultTypes = [];
  resultTypes = [];
  opportunityStates = [];

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    private commonService: CommonService,
    private packagefeatureService: PackagefeatureService,
    public opportunityService: OpportunityService
  ) { }

  ngOnInit(): void {
    this.getEMailTemplates();
    this.getUsers();
    this.getQmUserGroups();
    this.getWorkflows();
    this.getStepResults();
    this.getTemplatePreview();
    this.getTemplateFollowupPreview();
    this.getCampaigns();
    this.getLeadStates();
    this.getResultTypes();
    this.getWorkflowStepResultTypes();
  }


  // ! maybe to be used  later
/*   checkFeatureisActive(featureName){
    const featureId = Number(this.applicationInfoService.applicationSettings[featureName]);
    return false
    return this.packagefeatureService.selectedPackageHasFeature(featureId)

  } */



  getResultTypes() {
    this.externaldatasourceService.getLookupTableValues(56, 2)
    .then(getResultTypesResult => {
      this.resultTypes = getResultTypesResult;
    });
  }

  checkStepResult() {
    
  }

  getWorkflowStepResultTypes() {
    this.externaldatasourceService.getLookupTableValues(10, 2)
    .then(getWorkflowStepResultTypesResult => {
      this.workflowStepResultTypes = getWorkflowStepResultTypesResult;
    });
  }

  getTemplateFollowupPreview() {
    if (this.wizardService.wizardArray.followupEmailTemplateId == null) {
      this.innerHTMLFollowUp = '';
      return;
    }

    this.emailtemplateService.getEMailTemplate(this.wizardService.wizardArray.followupEmailTemplateId, 2, true)
    .then(getEMailTemplateResult => {
      this.emailTemplatePreview = getEMailTemplateResult;     
      this.innerHTMLFollowUp = this.domSanitizer.bypassSecurityTrustHtml(
        this.emailtemplateService.renderEMail(this.emailTemplatePreview.bodyHtml, [], false)
      );
    });  
  }
  
  getTemplatePreview() {
    if (this.wizardService.wizardArray.emailTemplateId == null) {
      this.innerHTML = '';
      return;
    }

    this.emailtemplateService.getEMailTemplate(this.wizardService.wizardArray.emailTemplateId, 2, true)
    .then(getEMailTemplateResult => {
      this.emailTemplatePreview = getEMailTemplateResult;     
      this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(
        this.emailtemplateService.renderEMail(this.emailTemplatePreview.bodyHtml, [], false)
      );
    });  
  }

  getStepResults() {
    this.externaldatasourceService.executeExternalDataSource(498, [this.wizardService.wizardArray.stepId]).then(getStepResultsResult => { this.results = getStepResultsResult; });
  }
  
  getEMailTemplates() {
    this.externaldatasourceService.executeExternalDataSource(719, []).then(getEMailTemplatesResult => { this.emailTemplates = getEMailTemplatesResult; });
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(139, []).then(getUsersResult => { this.users = getUsersResult; });
  }

  getQmUserGroups() {
    this.externaldatasourceService.executeExternalDataSource(95, [295]).then(getQmUserGroupsResult => { this.qmUserGroups = getQmUserGroupsResult; });
  }

  getWorkflowSteps(workflowId) {
    const workflow = this.workflows.find(x => x.id == workflowId);
    if (workflow) {
      return workflow.workflowSteps;
    } else {
      return [];
    }
  }

  getWorkflows() {
    this.workflows = [];
    this.externaldatasourceService.executeExternalDataSource(571, [])
    .then(executeExternalDataSourceResult => {
      executeExternalDataSourceResult.forEach(workflow => {
        workflow.workflowSteps.forEach(step => {
          step.results.forEach(result => {
            if (result.nextStep != null) {
              result.nextStepWorkflowId = result.nextStep.workflowId;
            }  
          })
        })
      })
      this.workflows = executeExternalDataSourceResult;
    });
  }

  getCampaigns() {
    this.campaigns = [];
    this.externaldatasourceService.executeExternalDataSource(695)
    .then(getCampaignsResult => {
      this.campaigns = getCampaignsResult;
    });
  }

  getLeadStates() {
    this.externaldatasourceService.executeExternalDataSource(926)
    .then(getLeadStatesResult => {
      this.leadStates = getLeadStatesResult;
    })
    .catch(error => {
    });
  }

  showEMailSettings() {
    localStorage.setItem('oneTimeShowSettingPage', '7');
    this.commonService.openUrl(document.location.href, '_new')
  }
}
