import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { ConfirmationService } from 'primeng/api';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-accountowner',
  templateUrl: './custom-accountowner.component.html',
  styleUrls: ['./custom-accountowner.component.scss']
})
export class CustomAccountownerComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  updateControlAttributesEventSubscription: Subscription = new Subscription();
  accountOwners = [];
  mode = 1;
  vCheckResult = null;
  extendEverleadResult = null;

  constructor(
    private injector: Injector,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private sharedAPI: SharedAPI,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService
  ) {
    super(injector);
    this.valueType = ValueType.Number;
  }

  ngOnInit() {
    this.mode = Number(this.controlDefinition.additionalSetting3);
    this.applicationInfoService.miscSettings['accountOwnerName'] = null;
    switch (this.mode) {
      case 1:
        this.getAccountOwners();
        break;
      case 2:
        break;
      case 7:
        this.checkEverleadDemo();
        break;
    }

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'ShowAccountData' && this.mode == 1) {
        this.getAccountOwners();
      }
    });

    this.updateControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      switch (this.mode) {
        case 7:
          this.checkEverleadDemo();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
  }

  getAccountOwners() {
    let externalDataSourceId = 783;
    this.externalDatasourceService.executeExternalDataSource(externalDataSourceId, [
      this.applicationInfoService.currentAccount.id,
      Number(this.controlDefinition.additionalSetting1),
      Number(this.controlDefinition.additionalSetting2),
    ]).then(getAccountOwnersResult => {
      this.accountOwners = getAccountOwnersResult;
      if (getAccountOwnersResult.length == 1) {
        this.controlValue = getAccountOwnersResult[0].parent.id;
        this.changeOwner(this.controlValue);
        this.callMethod('onchange');
      } else {
        this.controlValue = null;
        this.changeOwner(this.controlValue);
        this.callMethod('onchange');
      }
    });
  }

  assignCallToAccount() {
    this.ringcentralwebphoneService.makeCallObject.accountId = this.applicationInfoService.currentAccount.id;
    this.ringcentralwebphoneService.updateCallLog(this.ringcentralwebphoneService.makeCallObject);
    this.messagingService.showDefaultInfo('', 'CustomAccountOwner.Message.CallAssignedToAccount', false)
  }

  getEONVCheck(accountEntityId = null) {
    console.warn('getEONVCheck', accountEntityId);
    let street = '';
    let postalCode = '';
    let city = '';
    let housenumber = '';
    if (accountEntityId == null) {
      street = this.applicationInfoService.currentAccount.street
      postalCode = this.applicationInfoService.currentAccount.zipcode;
      city = this.applicationInfoService.currentAccount.city;
      housenumber = this.applicationInfoService.currentAccount.hausnummer;
    } else {
      street = this.applicationInfoService.entities.Item(accountEntityId.toString()).data.street
      postalCode = this.applicationInfoService.entities.Item(accountEntityId.toString()).data.zipcode
      city = this.applicationInfoService.entities.Item(accountEntityId.toString()).data.city
      housenumber = this.applicationInfoService.entities.Item(accountEntityId.toString()).data.hausnummer
      if (!this.commonService.isNullOrUndefined(housenumber)) {
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(accountEntityId.toString()).data.hausnrzusatz)) {
          housenumber = housenumber.concat(this.applicationInfoService.entities.Item(accountEntityId.toString()).data.hausnrzusatz);
        }
      }
    }
    if (this.commonService.isNullOrUndefined(street)) { street = '-'; }
    if (this.commonService.isNullOrUndefined(postalCode)) { postalCode = '-'; }
    if (this.commonService.isNullOrUndefined(city)) { city = '-'; }
    if (this.commonService.isNullOrUndefined(housenumber)) { housenumber = '-'; }

    this.loaderService.display(true);

    this.sharedAPI.getEonVCheck(this.controlDefinition.value, {
      resellerId: 69,
      givenName: '-',
      salutation: '-',
      surName: '-',
      housenumber: housenumber,
      street: street,
      postalCode: postalCode,
      city: city
    }).subscribe(
      result => {
      this.vCheckResult = result;
      this.loaderService.display(false);
      if (accountEntityId != null) {
        if (!this.commonService.isNullOrUndefined(result.result)) {
          if (!this.commonService.isNullOrUndefined(result.result.adresseID)) {
            this.entityService.updateValuebyType(accountEntityId.toString(), 'name1', result.result.adresseID, ValueType.String);
            this.entityService.updateValuebyType(accountEntityId.toString(), 'adressid', result.result.adresseID, ValueType.String);
            this.messagingService.showDefaultSuccess('VCheck', 'Adresse gefunden!');
          }
        } else {
          this.messagingService.showDefaultWarning('VCheck', `Für dieses Objekt ist kein Glasfaser verfügbar`);
        }
      }
    }, err => {
      this.loaderService.display(false);
      this.messagingService.showDefaultWarning('VCheck', `Für dieses Objekt ist kein Glasfaser verfügbar`);
    });
  }

  createTask() {
    this.externalDatasourceService.executeExternalDataSource(13)
    .then(CreateNewTaskForContactTaskCheckResult => {
      if (CreateNewTaskForContactTaskCheckResult.length > 0) {
        this.confirmationService.confirm({
          message: this.axivasTranslateService.getTranslationTextForToken('CreateNewTaskForContact.TasksFound.EonAccountMessage'),
          header: this.axivasTranslateService.getTranslationTextForToken('CreateNewTaskForContact.TasksFound.Header'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.createTaskForObject();
          },
        });
      } else {
        this.createTaskForObject();
      }
    });
  }

  createTaskForObject() {
    this.loaderService.display(true);
    this.externalDatasourceService.executeExternalDataSource(798, [
      Number(this.controlDefinition.value),
      this.applicationInfoService.userID
    ]).then(result => {
      this.methodService.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [result.id]);
      this.loaderService.display(false);
    }).catch(error => { this.loaderService.display(false); });
  }

  searchAdress() {
    this.commonService.searchPhoneNumberInWebPhonebook(this.applicationInfoService.currentAccount);
  }

  changeOwner(controlValue) {
    const owner = this.accountOwners.find(owner => owner.parent.id == controlValue);
    if (owner) {
      let ownerString = '';
      if (owner.parent.name3) { ownerString = ownerString.concat(owner.parent.name3, ' '); }
      if (owner.parent.name2) { ownerString = ownerString.concat(owner.parent.name2, ' '); }
      if (owner.parent.name1) { ownerString = ownerString.concat(owner.parent.name1, ' '); }
      this.applicationInfoService.miscSettings['accountOwnerName'] = ownerString;
    }
    this.callMethod('onchange');
  }

  checkAvailability() {
    let stringToEncode = '{"zipcode":"'.concat(
      this.commonService.convertStringToUnicode(this.commonService.checkForNullAndUndefinedAndReturnDefault(this.applicationInfoService.currentAccount.zipcode, '')),
      '","city":"',
      this.commonService.convertStringToUnicode(this.commonService.checkForNullAndUndefinedAndReturnDefault(this.applicationInfoService.currentAccount.city, '')),
      '","street":"',
      this.commonService.convertStringToUnicode(this.commonService.checkForNullAndUndefinedAndReturnDefault(this.applicationInfoService.currentAccount.street, '')),
      '","houseno":"',
      this.commonService.convertStringToUnicode(this.commonService.checkForNullAndUndefinedAndReturnDefault(this.applicationInfoService.currentAccount.hausnummer, '')),
      '"}'
    );

    stringToEncode = this.applicationInfoService.applicationSettings['eonWebservice'].replace('<0>', btoa(stringToEncode.replace(/u00/g, '\\u00')));
    this.commonService.openUrl(stringToEncode, '_blank')
    return;
  }

  checkEverleadDemo() {
    if (this.applicationInfoService.currentContact.isDemoUser) {
      this.externalDatasourceService.executeExternalDataSource(834, [this.applicationInfoService.currentContact.id]).then(extendEverleadDemoResult => {
        this.extendEverleadResult = extendEverleadDemoResult;
      });
    }
  }

  extendEverleadDemo() {
    this.sharedAPI.extendTrial(this.extendEverleadResult.trialExtensionGuid, false).subscribe(extendEverleadDemoReult => {
      this.messagingService.showDefaultSuccess(
        'CustomAccountOwner.Label.ExtendEverleadHeader',
        'CustomAccountOwner.Label.ExtendEverleadMessage');
      this.checkEverleadDemo();
    });
  }
}
