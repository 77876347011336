import { Component, Injector, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

@Component({
  selector: 'app-custom-leadscore',
  templateUrl: './custom-leadscore.component.html',
  styleUrls: ['./custom-leadscore.component.scss']
})
export class CustomLeadscoreComponent extends CustomControlBaseClass implements OnInit {
  @Input() controlValue = 0;
  @Input() size = 75;

  constructor(
    private injector: Injector,
  ) {
    super(injector);
    this.valueType = ValueType.Number;
  }

  ngOnInit() {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.checkAttributes();
  }

  roundUp(value) {
    if (value !== null) {
      return Math.floor(value);
    } else {
      return 0;
    }
  }
}
