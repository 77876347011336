import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from './common.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {
  opportunityStates = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService
  ) { 
    this.getOpportunityStates();
  }

  getTempOpportunity() {
    return this.applicationInfoService.entities.Item('99').data;
  }

  createTempOpportunity(contactId) {
    this.applicationInfoService.entities.Item('99').data = {
      accountId: this.applicationInfoService.currentAccount.id,
      contactId: contactId,
      statusId: this.opportunityStates.length > 0 ? this.opportunityStates[0].id : null
    }
  }

  getOpportunityStates(): Promise<any> {
    return new Promise((getOpportunityStatesResolve, getOpportunityStatesReject) => {
      this.externaldatasourceService.executeExternalDataSource(326, [])
      .then(getOpportunityStatesResult => {
        this.opportunityStates = getOpportunityStatesResult;
        this.opportunityStates.sort((a, b) => a.factor > b.factor ? 1 : 1);
        getOpportunityStatesResolve(getOpportunityStatesResult);
      }).catch(error => {
        getOpportunityStatesReject(error);
      });
    });
  }

  createOpportunity(opportunity): Promise<any> {
    opportunity['createdAt'] = new Date(Date.now()).toUTCString();
    opportunity['createdBy'] = this.applicationInfoService.user.id;
    return new Promise((createOpportunityResolve, createOpportunityReject) => {
      this.externaldatasourceService.executeExternalDataSource(324, [
        this.commonService.getModifyArrayBody(opportunity, ['id'])
      ]).then(createOpportunityResult => {
        createOpportunityResolve(createOpportunityResult);
      }).catch(error => {
        createOpportunityReject(error);
      });
    });
  }

  updateOpportunity(opportunity): Promise<any> {
    return new Promise((updateOpportunityResolve, updateOpportunityReject) => {
      this.externaldatasourceService.executeExternalDataSource(325, [
        this.commonService.getModifyArrayBody(opportunity, [])
      ]).then(updateOpportunityResult => {
        this.eventService.customEvent.emit({ id: 'updateOpportunityEvent', eventType: 'updateEntityTableView' });
        updateOpportunityResolve(updateOpportunityResult);
      }).catch(error => {
        updateOpportunityReject(error);
      });
    });
  }

  checkIfTempOpportunityIsValid() {
    let returnValue = true;
    const opportunity = this.getTempOpportunity();
    if (opportunity) {
      if (
        this.commonService.isNullOrUndefined(opportunity.value) ||
        this.commonService.isNullOrUndefined(opportunity.statusId) 
      ) {
        returnValue = false;
      }  
    } else {
      returnValue = false;
    }
    return returnValue;
  }

  clearTempOpportunity() {
    this.applicationInfoService.entities.Item('99').data = null;
  }

  checkIfResultRequiresOpportunity(resultId) {
    const selectedResult = this.applicationInfoService.results.toArray().find(result => result.id == resultId);
    if (selectedResult) {
      return selectedResult.isOpportunityCreation;
    } else {
      return false;
    }    
  }

  showOpportunityForm(editExistingOpportunity = false) {
    if (editExistingOpportunity) {

    } else {

    };
    this.applicationInfoService.miscSettings['opportunityEditExistingOpportunity'] = editExistingOpportunity;
    this.eventService.showJjPopup('Task.Label.EditOpportunity', 'opportunity', this.applicationInfoService.applicationSettings['opportunityFormSize']);
  }
}
