<div class="projectUser__wrapper autoHeight">

  <div class="projectUser__header">
    <div class="projectUser__header__item" *ngIf="applicationInfoService.isDeveloper">
      <div class="projectUser__header__label">
        <label>{{ 'General._.Project' | texttransform }}</label>
      </div>
      <div class="projectUser__header__dropdown">
        <select [(ngModel)]="dropDownProjectSelection" (change)="changeProjectDropdownSelectionValue()">
          <option *ngFor="let data of projectData; let i = index" [(ngValue)]="projectData[i]">{{ data.projectName }}</option>
        </select>
      </div>
    </div>
    <div class="projectUser__header__item">
      <div class="projectUser__header__label">
        <label>{{ 'General.User.UserRole' | texttransform }}</label>
      </div>
      <div class="projectUser__header__dropdown">
        <select [(ngModel)]="dropDownRoleSelection" (change)="changeUserTypeDropdownSelectionValue()">
          <option *ngFor="let role of roleData" [(ngValue)]="role.id" [ngClass]="{ hideInDom: isRoleHidden(role) }">
            {{ commonService.getNameTranslationOrDefaultName(role) | texttransform }}
          </option>
        </select>
      </div>
    </div>
    <div class="projectUser__header__item" *ngIf="dropDownRoleSelection">
      <div class="projectUser__header__label">
        <label>{{ 'General.User.UserRoleSection' | texttransform }}</label>
      </div>
      <div class="projectUser__header__dropdown">
        <select [(ngModel)]="userRoleSection" (change)="getSubitemInfoBySection()">
          <option ngValue="1">{{ 'General.User.UserRoleSectionUser' | texttransform }}</option>
          <option ngValue="2">{{ 'General.User.UserRoleSectionRecordAssignment' | texttransform }}</option>
          <option ngValue="3">{{ 'General.User.UserRoleSectionRights' | texttransform }}</option>
          <option ngValue="4">{{ 'General.User.UserRoleSectionDashboards' | texttransform }}</option>
          <option ngValue="5">{{ 'General.User.UserRoleSectionDataRights' | texttransform }}</option>
        </select>
      </div>
    </div>
  </div>

  <!-- User assignment -->
  <label class="roleSectionHeaderLabel" *ngIf="userRoleSection == '1' && dropDownRoleSelection">{{ 'General.User.UserRoleSectionUser' | texttransform }}</label>
  <div class="userManagement__wrapper" *ngIf="userRoleSection == '1' && dropDownRoleSelection">
    <div class="userManagement__left">
      <div class="userManagement__sectionHeader">
        <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}</div>
          <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
          <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
            [(ngModel)]="filterString">
      </div>
      <div id="userList"></div>
      <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('userList', 40, 300)">
        <div *ngFor="let user of availableUsers" [ngClass]="{ hideInDom: isFilterActive(user.userName, filterString) || checkIfAlreadyAssigned(user) }">
          <div class="userManagement__item__container">
            <div class="userManagement__item__id">{{ user.id }}</div>
            <div class="userManagement__item__name ellipsisText">{{ user.userName }}</div>
            <div class="userManagement__item__move" (click)="addUser(user)">
              <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon" *ngIf="dropDownRoleSelection != null" (click)="addUser(user)"
                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="userManagement__left">
      <div class="userManagement__sectionHeader">
        <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' | texttransform }}</div>
        <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
          [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
        <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
          [(ngModel)]="filterStringAssigned">
      </div>
      <div id="assignedList"></div>
      <div class="projectUser__header__userList"
        [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
        <div *ngFor="let assignedUser of assignedUsers" [ngClass]="{ hideInDom: isFilterActive(assignedUser.user.userName, filterStringAssigned) }">
          <div class="userManagement__item__container userManagement__item__containerreverse">
            <div class="userManagement__item__id">{{ assignedUser.user.id }}</div>
            <div class="userManagement__item__name ellipsisText">{{ assignedUser.user.userName }}</div>
            <div class="userManagement__item__move" (click)="removeUser(assignedUser)">
              <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Automatic record assignment -->
  <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '2' && dropDownRoleSelection">
    <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionRecordAssignment' | texttransform }}</label>
    <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionRecordAssignmentDescription' | texttransform }}</label>
    <div class="d-flex">
      <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
          <div class="userManagement__sectionHeader__label">{{ 'General.Label.Available' | texttransform }}</div>
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input" [(ngModel)]="filterString">
        </div>
        <div id="availableList"></div>
        <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('availableList', 40, 300)">
          <div *ngFor="let automaticRecordAssignment of automaticRecordAssignments" [ngClass]="{ hideInDom: isFilterActive(automaticRecordAssignment.defaultName, filterString) || isActiveAssignment(automaticRecordAssignment) }">
            <div class="userManagement__item__container">
              <div class="userManagement__item__name ellipsisText">{{ automaticRecordAssignment.defaultName }}</div>
              <div class="userManagement__item__move" (click)="toggleAssignment(automaticRecordAssignment)">
              <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon" *ngIf="dropDownRoleSelection != null"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
          <div class="userManagement__sectionHeader__label">{{ 'General.Label.Assigned' | texttransform }}</div>
          <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
          <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
            [(ngModel)]="filterStringAssigned">
        </div>
        <div id="assignedList"></div>
        <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
          <div *ngFor="let automaticRecordAssignment of automaticRecordAssignments" [ngClass]="{ hideInDom: isFilterActive(automaticRecordAssignment.defaultName, filterStringAssigned) || isActiveAssignment(automaticRecordAssignment) == false }">
            <div class="userManagement__item__container userManagement__item__containerreverse">
              <div class="userManagement__item__name ellipsisText">{{ automaticRecordAssignment.defaultName }}</div>
              <div class="userManagement__item__move" (click)="toggleAssignment(automaticRecordAssignment)">
                <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- <span id="projectUserSectionData"></span>
    <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">
      <div *ngFor="let automaticRecordAssignment of automaticRecordAssignments" class="projectUserSectionAssignment"
        (click)="toggleAssignment(automaticRecordAssignment)" [ngClass]="{ 'projectUserSectionAssignment__isActive': isActiveAssignment(automaticRecordAssignment) }">
        {{ automaticRecordAssignment.defaultName }}
      </div>
    </div> -->
  </div>

  <!-- Rights -->
  <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '3' && dropDownRoleSelection">
    <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionRights' | texttransform }}</label>
    <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionRightsDescription' | texttransform }}</label>
    <span id="projectUserSectionData"></span>
    <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">

    </div>
  </div>

  <!-- Dashboards -->
  <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '4' && dropDownRoleSelection">
    <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionDashboards' | texttransform }}</label>
    <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionDashboardsDescription' | texttransform }}</label>
    <div class="d-flex">
      <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
          <div class="userManagement__sectionHeader__label">{{ 'General.Label.Available' | texttransform }}</div>
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input" [(ngModel)]="filterString">
        </div>
        <div id="availableList"></div>
        <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('availableList', 40, 300)">
          <div *ngFor="let dashboard of dashboards" [ngClass]="{ hideInDom: isFilterActive(dashboard.name, filterString) || isActiveDashboard(dashboard) }">
            <div class="userManagement__item__container">
              <div class="userManagement__item__name ellipsisText">{{ dashboard.name }}</div>
              <div class="userManagement__item__move" (click)="toggleDashboard(dashboard)">
              <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon" *ngIf="dropDownRoleSelection != null"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
          <div class="userManagement__sectionHeader__label">{{ 'General.Label.Assigned' | texttransform }}</div>
          <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
          <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
            [(ngModel)]="filterStringAssigned">
        </div>
        <div id="assignedList"></div>
        <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
          <div *ngFor="let dashboard of dashboards" [ngClass]="{ hideInDom: isFilterActive(dashboard.name, filterStringAssigned) || isActiveDashboard(dashboard) == false }">
            <div class="userManagement__item__container userManagement__item__containerreverse">
              <div class="userManagement__item__name ellipsisText">{{ dashboard.name }}</div>
              <div class="userManagement__item__move" (click)="toggleDashboard(dashboard)">
                <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <span id="projectUserSectionData"></span>
    <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">
      <div *ngFor="let dashboard of dashboards" class="projectUserSectionAssignment"
        (click)="toggleDashboard(dashboard)" [ngClass]="{ 'projectUserSectionAssignment__isActive': isActiveDashboard(dashboard) }">
        {{ dashboard.name }}
      </div>
    </div> -->
  </div>

    <!-- Datarights -->
    <div class="projectUserSection fingerCursor" *ngIf="userRoleSection == '5' && dropDownRoleSelection">
      <label class="roleSectionHeaderLabel">{{ 'General.User.UserRoleSectionDataRights' | texttransform }}</label>
      <label class="roleSectionHeaderLabelDesc">{{ 'General.User.UserRoleSectionDataRightsDescription' | texttransform }}</label>
      <div class="d-flex">
        <div class="userManagement__left">
          <div class="userManagement__sectionHeader">
            <div class="userManagement__sectionHeader__label">{{ 'General.Label.Available' | texttransform }}</div>
              <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
              <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input" [(ngModel)]="filterString">
          </div>
          <div id="availableList"></div>
          <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('availableList', 40, 300)">
            <div *ngFor="let userRight of userRights" [ngClass]="{ hideInDom: isFilterActive(userRight.defaultName, filterString) || isActiveUserRight(userRight) }">
              <div class="userManagement__item__container">
                <div class="userManagement__item__name ellipsisText">{{ userRight.defaultName }}</div>
                <div class="userManagement__item__move" (click)="toggleUserRight(userRight)">
                <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon" *ngIf="dropDownRoleSelection != null"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="userManagement__left">
          <div class="userManagement__sectionHeader">
            <div class="userManagement__sectionHeader__label">{{ 'General.Label.Assigned' | texttransform }}</div>
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
              [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
              [(ngModel)]="filterStringAssigned">
          </div>
          <div id="assignedList"></div>
          <div class="projectUser__header__userList" [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
            <div *ngFor="let userRight of userRights" [ngClass]="{ hideInDom: isFilterActive(userRight.defaultName, filterStringAssigned) || isActiveUserRight(userRight) == false }">
              <div class="userManagement__item__container userManagement__item__containerreverse">
                <div class="userManagement__item__name ellipsisText">{{ userRight.defaultName }}</div>
                <div class="userManagement__item__move" (click)="toggleUserRight(userRight)">
                  <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- <span id="projectUserSectionData"></span>
      <div class="projectUserSectionData" [style.height.px]="uiService.getContainerHeightEx('projectUserSectionData', 40, 300)">
        <div *ngFor="let userRight of userRights" class="projectUserSectionAssignment"
        (click)="toggleUserRight(userRight)" [ngClass]="{ 'projectUserSectionAssignment__isActive': isActiveUserRight(userRight) }">
        {{ userRight.defaultName }}
      </div>
      </div> -->
    </div>

</div>
