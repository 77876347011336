<div class="adminJobCheckResult" *ngIf="errorObject != null">
    <div class="adminJobCheckResultBlock adminJobCheckResultBlock__errors" *ngIf="errorObject.errors.length > 0">
        <div class="adminJobCheckResultHeader">{{ 'AdminJobCheckResult.Label.Errors' | texttransform }}</div>
        <div class="adminJobCheckResultBlock__content">
            <div class="adminJobCheckResultBlockItem" *ngFor="let item of errorObject.errors">
                <div class="adminJobCheckResultBlockItem__type">                    
                    {{ item.type }}
                </div>
                <div class="adminJobCheckResultBlockItem__value">
                    <div class="adminJobCheckResultBlockItem__value__param">{{ item.displayValue }} </div>
                    <div class="adminJobCheckResultBlockItem__value__step" *ngIf="item.step != undefined && item.step != null">
                        <i class="fas fa-shoe-prints"></i><div class="stepId">{{ item.step.id }}</div> {{ item.step.defaultName }}
                    </div>
                </div>
            </div>
        </div>
    </div>    

    <div class="adminJobCheckResultBlock adminJobCheckResultBlock__warnings" *ngIf="errorObject.warnings.length > 0">
        <div class="adminJobCheckResultHeader">{{ 'AdminJobCheckResult.Label.Warnings' | texttransform }}</div>
        <div class="adminJobCheckResultBlock__content">
            <div class="adminJobCheckResultBlockItem" *ngFor="let item of errorObject.warnings">
                <div class="adminJobCheckResultBlockItem__type">
                    {{ item.type }}
                </div>
                <div class="adminJobCheckResultBlockItem__value">
                    <div class="adminJobCheckResultBlockItem__value__param">{{ item.displayValue }} </div>
                    <div class="adminJobCheckResultBlockItem__value__step" *ngIf="item.step != undefined && item.step != null">
                        <i class="fas fa-shoe-prints"></i><div class="stepId">{{ item.step.id }}</div> {{ item.step.defaultName }}
                    </div>
                </div>
            </div>
        </div>
    </div> 

    <div class="adminJobCheckResultAllFine" *ngIf="errorObject.warnings.length == 0 && errorObject.errors.length == 0">
        <i class="far fa-laugh fa-8x everleadGreen"></i>
        <div class="adminJobCheckResultHeaderAllFine">{{ 'AdminJobCheckResult.Label.AllFine' | texttransform }}</div>
    </div>

    <div class="adminJobCheckResultAddMissingParameter" *ngIf="errorObject.stepsWithMissingParameter.length > 0">
        <button class="everleadCustomButton" (click)="addMissingParameter()" [disabled]="missingParameterFired == true">
            {{ 'AdminJobCheckResult.Label.AddMissingParameter' | texttransform }}
        </button>
    </div>
</div>