<br>
<div *ngIf="uploadingFile==false">
  <div class="col-md-3">
    <input type="file" #file accept=".xlsx" placeholder="Choose file" (change)="uploadFile($event)"
      style="display:none;">
    <button type="button" class="jjButtonColor jjButtonStyle fingerCursor"
      (click)="file.click()">{{ 'Import.Label.UploadFile' | texttransform }}</button>
  </div>
</div>

<div *ngIf="uploadingFile">
  {{ 'Import.Label.UploadingFilePleaseWait' | texttransform }}
</div>