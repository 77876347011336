import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-table-filter',
  templateUrl: './custom-table-filter.component.html',
  styleUrls: ['./custom-table-filter.component.scss']
})
export class CustomTableFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
