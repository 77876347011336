import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { LanguageService } from 'app/shared/translation/language.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-emailsettings',
  templateUrl: './wizard-emailsettings.component.html',
  styleUrls: ['./wizard-emailsettings.component.scss']
})
export class WizardEmailsettingsComponent implements OnInit {

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public emailtemplateService: EmailtemplateService,
    public commonService: CommonService,
    private messagingService: MessagingService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    
  }

  checkEMailSender() {
    let array = {
      serverSender: this.wizardService.wizardArray.serverSender,
      portSender: this.wizardService.wizardArray.portSender,
      userNameSender: this.wizardService.wizardArray.userNameSender,
      useSslSender: this.wizardService.wizardArray.useSslSender,
      passwordSender: this.wizardService.wizardArray.passwordSender,
      projectId: this.wizardService.wizardArray.projectId,
      fromEmailSender: this.wizardService.wizardArray.fromEmailSender
    }

    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(664, [
      this.wizardService.wizardArray.serverSender,
      this.wizardService.wizardArray.portSender,
      this.wizardService.wizardArray.userNameSender,
      this.wizardService.wizardArray.useSslSender,
      this.wizardService.wizardArray.passwordSender     
    ]).then(() => {
      this.saveData(array);
      this.loaderService.display(false);
    }).catch(error => {
      console.error(error);
      this.loaderService.display(false);
    });
  }

  checkEMailReader() {
    let array = {
      serverReader: this.wizardService.wizardArray.serverReader,
      portReader: this.wizardService.wizardArray.portReader,
      userNameReader: this.wizardService.wizardArray.userNameReader,
      useSslReader: this.wizardService.wizardArray.useSslReader,
      passwordReader: this.wizardService.wizardArray.passwordReader,
      projectId: this.wizardService.wizardArray.projectId
    }

    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(665, [
      this.wizardService.wizardArray.serverReader,
      this.wizardService.wizardArray.portReader,
      this.wizardService.wizardArray.userNameReader,
      this.wizardService.wizardArray.useSslReader,
      this.wizardService.wizardArray.passwordReader
    ]).then(() => {
      this.saveData(array);
      this.loaderService.display(false);
    }).catch(error => {
      console.error(error);
      this.loaderService.display(false);
    });
  }

  saveData(array) {
    let externalDataSourceId = 667;
    array['displayName'] = this.wizardService.wizardArray.displayName;
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.id)) {      
      externalDataSourceId = 666;
    } else {
      array['id'] = this.wizardService.wizardArray.id;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
      this.commonService.getModifyArrayBody(array, [])
    ]).then(checkEMailSenderCreateResult => {
      this.wizardService.wizardArray.id = checkEMailSenderCreateResult.id;
      this.messagingService.showDefaultSuccess('', 'SMTPSettings.Label.TestedAndSaved');
    });
  }
}
