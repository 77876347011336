<span id="consentDesignSpan"></span>
<div *ngIf="userService.hasPermission('CanReadSecurity.Module.SettingPageConsent')">    
    <div class="consent__wrapper" [style.height]="getControlHeight(0)">
        <div *ngIf="applicationInfoService?.isDeveloper" class="isDeveloper" style="padding: 10px;">Bitte darauf achten, dass der ConsentUser dem Projekt hinzugefügt wurde</div>
        <div class="consent__item everleadShadowBox">
            <label class="">{{ 'Consent.Label.HeaderPicture' | texttransform }}</label>
            <label class="everleadSmallHeader consentSpacer">{{ 'Consent.Label.HeaderPictureDescription' | texttransform
                }}</label>
            <input type="file" #file accept="image/*" placeholder="Choose file" (change)="uploadPicture($event)"
                style="display:none;">

            <div class="consent__headerpicture__wrapper">
                <div class="consent__headerpicture__picture">
                    <img [src]="consentSettings.headerPicture" class="headerPicture"
                        *ngIf="consentSettings.headerPicture != null">
                    <div *ngIf="consentSettings.headerPicture == null">
                        <i class="far fa-image fa-10x fingerCursor" (click)="file.click()"></i>
                        <div>
                            {{ 'Consent.Label.NotUploadedYet' | texttransform }}
                        </div>
                    </div>
                </div>
                <div class="consent__headerpicture__buttons">
                    <div>
                        <div class="consent__item__line">
                            <label class="">{{ 'Consent.Label.PictureType' | texttransform }}</label>
                            <select [(ngModel)]="consentSettings.pictureType" (change)="saveConsentSettings()">
                                <option ngValue="banner">{{ 'Consent.Label.Banner' | texttransform }}</option>
                                <option ngValue="logo">{{ 'Consent.Label.Logo' | texttransform }}</option>
                            </select>
                        </div>
                        <div class="consent__item__line" *ngIf="consentSettings?.pictureType == 'logo'">
                            <label class="">{{ 'Consent.Label.PicturePosition' | texttransform }}</label>
                            <select [(ngModel)]="consentSettings.picturePosition" (change)="saveConsentSettings()">
                                <option ngValue="flex-start">{{ 'Consent.Label.Left' | texttransform }}</option>
                                <option ngValue="center">{{ 'Consent.Label.Center' | texttransform }}</option>
                                <option ngValue="flex-end">{{ 'Consent.Label.Right' | texttransform }}</option>
                            </select>
                        </div>
                        <div class="consent__item__line">
                            <label class="">{{ 'Consent.Label.PictureHeight' | texttransform }}</label>
                            <input [(ngModel)]="consentSettings.pictureHeight" (change)="saveConsentSettings()">
                        </div>
                        <div class="consent__item__line" *ngIf="consentSettings?.pictureType == 'logo'">
                            <label class="">{{ 'Consent.Label.PictureWidth' | texttransform }}</label>
                            <input [(ngModel)]="consentSettings.pictureWidth" (change)="saveConsentSettings()">
                        </div>
                    </div>
                    <div>
                        <button class="consent__button" (click)="removePicture()"
                            *ngIf="consentSettings.headerPicture != null">{{ 'Consent.Label.RemovePicture' |
                            texttransform }}</button>
                        <button class="consent__button" (click)="file.click()">{{ 'Consent.Label.UploadPicture' |
                            texttransform }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="consent__item everleadShadowBox">
            <label class="">{{ 'Consent.Label.Header' | texttransform }}</label>
            <label class="everleadSmallHeader consentSpacer">{{ 'Consent.Label.HeaderDescription' | texttransform
                }}</label>
            <br>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.BackgroundColor' | texttransform }}</label>
                <input type="color" class="consent__color" [(ngModel)]="consentSettings.headerBackgroundColor"
                    (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.HeaderHeight' | texttransform }}</label>
                <input [(ngModel)]="consentSettings.headerHeight" (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.HeaderHorizontalPadding' | texttransform }}</label>
                <input [(ngModel)]="consentSettings.headerHorizontalPadding" (change)="saveConsentSettings()">
            </div>
        </div>

        <div class="consent__item everleadShadowBox">
            <label class="">{{ 'Consent.Label.Body' | texttransform }}</label>
            <label class="everleadSmallHeader consentSpacer">{{ 'Consent.Label.BodyDescription' | texttransform
                }}</label>

            <br>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.BodyWith' | texttransform }}</label>
                <input type="text" [(ngModel)]="consentSettings.bodyWidth" (change)="saveConsentSettings()">
            </div>

            <div class="consent__item__line">
                <label>{{ 'Consent.Label.FontFamily' | texttransform }}</label>
                <input type="text" [(ngModel)]="consentSettings.fontFamily" (change)="saveConsentSettings()">        
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.FontSize' | texttransform }}</label>
                <input type="text" [(ngModel)]="consentSettings.fontSize" (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.BackgroundColor' | texttransform }}</label>
                <input type="color" class="consent__color" [(ngModel)]="consentSettings.bodyBackgroundColor"
                    (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.TextColor' | texttransform }}</label>
                <input type="color" class="consent__color" [(ngModel)]="consentSettings.bodyColor"
                    (change)="saveConsentSettings()">
            </div>
        </div>

        <div class="consent__item everleadShadowBox">
            <label class="">{{ 'Consent.Label.Footer' | texttransform }}</label>
            <label class="everleadSmallHeader consentSpacer">{{ 'Consent.Label.FooterDescription' | texttransform
                }}</label>

            <br>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.FooterEnabled' | texttransform }}</label>
                <input [(ngModel)]="consentSettings.footerEnabled" (change)="saveConsentSettings()" type="checkbox"
                    class="checkBox">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.BackgroundColor' | texttransform }}</label>
                <input type="color" class="consent__color" [(ngModel)]="consentSettings.footerBackgroundColor"
                    [value]="consentSettings.footerBackgroundColor" (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.TextColor' | texttransform }}</label>
                <input type="color" class="consent__color" [(ngModel)]="consentSettings.footerColor"
                    [value]="consentSettings.footerColor" (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.Impressum' | texttransform }}</label>
                <input [(ngModel)]="consentSettings.impressumLink" (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.DataProtection' | texttransform }}</label>
                <input [(ngModel)]="consentSettings.dataProtectionLink" (change)="saveConsentSettings()">
            </div>
            <div class="consent__item__line">
                <label>{{ 'Consent.Label.FooterHeight' | texttransform }}</label>
                <input [(ngModel)]="consentSettings.footerHeight" (change)="saveConsentSettings()">
            </div>
        </div>

        <div class="consent__item everleadShadowBox">
            <div class="translationHeader consentSpacer">
                <div class="translationHeader__description">
                    <label class="maxWidth">{{ 'Consent.Label.Texte' | texttransform }}</label>
                    <label class="everleadSmallHeader">{{ 'Consent.Label.TexteDescription' | texttransform }}</label>
                </div>
                <div *ngIf="consentSettings" class="languageSelection">
                    <select [(ngModel)]="selectedLanguage">
                        <option [ngValue]="language" *ngFor="let language of consentSettings.languages">{{ language.name
                            }}</option>
                    </select>
                </div>
            </div>

            <br>
            <div *ngIf="selectedLanguage">
                <div class="consent__item__line">
                    <label class="everleadSmallHeader">{{ 'Consent.Label.Active' | texttransform }}</label>
                    <input type="checkbox" style="width: 30px;" [(ngModel)]="selectedLanguage.isActive"
                        (change)="saveConsentSettings()">
                </div>
                <div *ngFor="let translation of selectedLanguage.translations" class="consent__item__line textColumn">
                    <label class="everleadSmallHeader textAreaLabel everleadCoral">{{ translation.description |
                        texttransform }}</label>
                    <textarea *ngIf="translation.type == 'textarea'" [(ngModel)]="translation.value"
                        (change)="saveConsentSettings()"></textarea>
                    <input *ngIf="translation.type == 'input'" [(ngModel)]="translation.value"
                        (change)="saveConsentSettings()">
                </div>
            </div>
        </div>


        <div class="consent__item everleadShadowBox">
            <label class="consentSpacer">{{ 'Consent.Label.Preview' | texttransform }}</label>
            <iframe [src]=iframeSource *ngIf="iFrameLoaded == true"></iframe>
        </div>
    </div>
</div>