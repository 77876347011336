import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-custom-contactleadstateoverview-details',
  templateUrl: './custom-contactleadstateoverview-details.component.html',
  styleUrls: ['./custom-contactleadstateoverview-details.component.scss']
})
export class CustomContactleadstateoverviewDetailsComponent implements OnInit {
  contactLeadStatusCampaign = null;
  target = 'contact';

  constructor(
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.contactLeadStatusCampaign = this.applicationInfoService.miscSettings['contactLeadStatusCampaign'];
    this.target = this.applicationInfoService.miscSettings['contactLeadStatusCampaignTarget'];
  }

}
