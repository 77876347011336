import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from '../../../core/application/application-info.service';
import { EventService } from '../../../jollyjupiter/service/event.service';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { MiddlewareSignalrService } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { ExternalContentService } from 'app/jollyjupiter/service/external-content.service';
import { LoaderService } from 'app/shared/service/loader-service';


@Component({
  selector: 'app-toplevel-header',
  templateUrl: './toplevel-header.component.html',
  styleUrls: ['./toplevel-header.component.scss']
})
export class ToplevelHeaderComponent implements OnInit, OnDestroy {
  languageUpdateEventSubscription: Subscription = new Subscription();
  languageCheckTimerSubscription: Subscription = new Subscription;

  public userDropdownMenuItems: any = null;

  constructor(
      private router: Router
    , private eventService: EventService
    , public applicationInfoService: ApplicationInfoService
    , private axivasTranslateService: AxivasTranslateService
    , private methodService: MethodService
    , private middlewareSignalrService: MiddlewareSignalrService
    , private confirmationService: ConfirmationService
    , private messagingService: MessagingService
    , public userService: UserService
    , private externalContentService: ExternalContentService
  ) { }


  ngOnInit() {
    this.languageUpdateEventSubscription = this.eventService.languageUpdateEvent.subscribe(() => {
      this.updateUserMenu();
    });
    this.launchLanguageCheckTimer();
  }

  ngOnDestroy() {
    if (this.languageUpdateEventSubscription) { this.languageUpdateEventSubscription.unsubscribe(); }
    if (this.languageCheckTimerSubscription) { this.languageCheckTimerSubscription.unsubscribe(); }
  }

  onClickAxivasLogo() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'dashboard']);
  }

  showProjectSelection() {
      this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '80');
  }

  showDebug() {
    if (this.applicationInfoService.isDeveloper) {
      this.applicationInfoService.isDebugMode = !this.applicationInfoService.isDebugMode;
    }
  }

  launchLanguageCheckTimer() {
    // required because this ngp control is shitty...
    // console.log('launchLanguageCheckTimer tick');
    const responseTimer = timer(1000, 1000);
    this.languageCheckTimerSubscription = responseTimer.subscribe(() => {
      if (this.axivasTranslateService.getTranslationTextForToken('General._.Language') !== 'General._.Language') {
        this.updateUserMenu();
        this.languageCheckTimerSubscription.unsubscribe();
      }
    });
  }

  updateUserMenu() {
    this.userDropdownMenuItems = [{
      label: this.axivasTranslateService.getTranslationTextForToken('General._.Language'),
      items: [{
          label: 'Deutsch',
          command: () => { this.methodService.launchInternalMethod('changelanguage', ['de']); }
        }, {
          label: 'English',
          command: () => { this.methodService.launchInternalMethod('changelanguage', ['en']); }
        }
      ]
    },
    {

      label: this.axivasTranslateService.getTranslationTextForToken('General.Menu.Account'),
      items: [{
          label: this.axivasTranslateService.getTranslationTextForToken('General.Menu.Settings'),
          command: (event) => { this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/usersettings']); }
        }, {
          label: this.axivasTranslateService.getTranslationTextForToken('General.Menu.Logout'),
          command: (event) => { this.logoutApplication(); }
      }]
    }];
    this.messagingService.showDebugMessageInConsole('updateUserMenu', [this.userDropdownMenuItems]);
  }

  changeUserGroup() {
    if (this.applicationInfoService.changeUserGroup()) {
      this.middlewareSignalrService.hubConnection.invoke('TimeRecordingChangeStatus', '2',
      this.applicationInfoService.getTimeRecordingProjectId().toString());
    }
  }

  logoutApplication() {
    this.methodService.launchInternalMethod('logout', []);
    return; // TODO MIDDLEWARE

    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('Logout.AskForTRLogout.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('Logout.AskForTRLogout.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.middlewareSignalrService.logout();
        this.methodService.launchInternalMethod('logout', []);
      },
      reject: () => {
        this.methodService.launchInternalMethod('logout', []);
      }
    });
  }

  changeView() {
    if (this.applicationInfoService.isDeveloper) {
      this.applicationInfoService.useNewDesign = !this.applicationInfoService.useNewDesign;
      this.externalContentService.setNewDesignCSS(true);
    }
  }
}
