import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { switchMapTo } from 'rxjs-compat/operator/switchMapTo';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { RingcentralService } from 'app/jollyjupiter/service/ringcentral.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { LoaderService } from 'app/shared/service/loader-service';
import { MicrocampaignService } from 'app/jollyjupiter/service/microcampaign.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';

@Component({
  selector: 'app-custom-micro-campaigns',
  templateUrl: './custom-micro-campaigns.component.html',
  styleUrls: ['./custom-micro-campaigns.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('50ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],
})

export class CustomMicroCampaignsComponent implements OnInit, OnDestroy {
  updateMicroCampaignViewEventSubscription: Subscription = new Subscription();
  externalDataSourceResult = null;
  campaignDetails = [];
  displayedColumns = ['type','details','notes','status'];
  timeline = null;
  page = 0;
  currentPage = 0;
  pageSize = 20;
  resultsLength = 0;
  dataArray: any;
  filterString = '';
  filterType = ['conversion','task','event'];
  loadingData = false;
  campaignFilter = [];
  // filterType_timeline = ['all', 'agent','contact','robot'];
  numRecordsTask = 0;
  numRecordsEvent = 0;
  numRecordsConversion = 0;
  microCampaignPDF = null;
  expandedElement = null;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedMicroCampaign = null;
  doubleOptinMissing = false;
  doubleOptinMissingChecked = false;
  // ! ivo
/*   workflow
  customEventSubscription:Subscription = new Subscription(); */
  selectedCampaignOverview
  selectedInput
  microcampaignState = [];
  viewMode = 1;
  
 constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private entityService: EntityService,
    private ringcentralService: RingcentralService,
    private axivasTranslateService: AxivasTranslateService,
    private paginatorIntl: MatPaginatorIntl,
    private loaderService: LoaderService,
    public microcampaignService: MicrocampaignService,
    private emailTemplateService: EmailtemplateService
  ) { }

  ngOnInit(): void {
    this.paginatorIntl.itemsPerPageLabel = this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator')
    Promise.all([
      this.getDataList().then((message) => { })
    ]).then();

    this.microCampaignPDF = this.applicationInfoService.applicationSettings['microCampaignPDF'];

    this.updateMicroCampaignViewEventSubscription = this.eventService.updateMicroCampaignViewEvent.subscribe(() => {
      this.getDataList();
      this.getMicroCampaignInfo();
    });

    this.getMicroCampaignInfo();

    //console.log('viewMode MicroCampaigns', this.viewMode)
  }

  ngOnDestroy() {
    if (this.updateMicroCampaignViewEventSubscription) { this.updateMicroCampaignViewEventSubscription.unsubscribe(); };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getDataList() {
    this.loadingData = true;
    let filterString = '';
    this.filterType.forEach(element => {
      if (filterString != '') { filterString = filterString.concat(', '); }
      filterString = filterString.concat('\"', element, '\"')
    });
    if (filterString == '') {
      this.dataSource = new MatTableDataSource(null);
      this.loadingData = false;
      return;
    }

    return new Promise((resolve, reject ) => {
      this.externaldatasourceService.executeExternalDataSource(426, [])
      .then(result => {
        result.sort((a, b) => a.firstTaskDate > b.firstTaskDate ? -1 : 1);
        result.forEach(resultItem => {
          resultItem.results = [];
          resultItem.moreThanTwoResult = false;
          resultItem.resultTypes.forEach(resultType => {
            resultType.results.forEach(resultTypeResult => {
              if(resultItem.results.length < 2) {
                resultTypeResult.resultType = resultType.resultType;
                resultItem.results.push(resultTypeResult);
              } else if (resultItem.moreThanTwoResult == false) {
                resultItem.moreThanTwoResult = true;
                resultItem.results.push({ name: '...' });
              }
            })
          });


          // this.campaignFilter[resultItem.taskChainGuid] = ['all', 'agent','contact','system'];
          // this.getCampaignResults(resultItem, this.campaignFilter[resultItem.taskChainGuid]);
        })
        this.dataArray = result;
        this.externalDataSourceResult = result;
        this.dataSource = new MatTableDataSource(result);
        this.numRecordsTask = result.numRecordsTask;
        this.numRecordsEvent = result.numRecordsEvent;
        this.numRecordsConversion = result.numRecordsConversion;
        this.dataSource.sort = this.sort;
        this.resultsLength = result.numRecords;
        this.loadingData = false;

        this.selectedCampaignOverview = this.dataArray[0]
        resolve(null);
        }).catch(error => reject(error));
    });

  }

  isValidStep(step) {
    let returnValue = true;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step))) {
      if (this.applicationInfoService.steps.Item(step).typeLookupId == 3842) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  getTypeIcon(baseClasses, taskType) {
    switch(taskType) {
      case 'conversion':
        baseClasses = baseClasses.concat(' ', 'fa-robot');
      break;
      case 'call':
        baseClasses = baseClasses.concat(' ', 'fa-phone');
      break;
      case 'pending':
        baseClasses = baseClasses.concat(' ', 'fa-clock');
      break;
      case 'StepType.Label.Call':
        baseClasses = baseClasses.concat(' ', 'fa-phone');
        if (this.applicationInfoService.webPhoneConnected == true) {
            baseClasses = baseClasses.concat(' ', 'fingerCursor');
        }
      break;
      case 'followup':
        baseClasses = baseClasses.concat(' ', 'fa-phone');
      break;
      case 'mail':
        baseClasses = baseClasses.concat(' ', 'fa-envelope');
      break;
      case 'mailsend':
        baseClasses = baseClasses.concat(' ', 'fa-envelope');
      break;
      case 'mailopen':
        baseClasses = baseClasses.concat(' ', 'fa-envelope');
      break;
    }

    return baseClasses;
  }

  toggleDetails(expandedElement,row){
    return (expandedElement != row) ? row : null;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  filterList(e, type, value) {
    this.commonService.toggleArrayItem(this.filterType, value);
    this.getDataList();
  }

  isItemFiltered(element) {
    return this.commonService.checkIfStringContainsString(element.campaignName, this.filterString)
  }

  filterTimeline(campaign, value){
    this.commonService.toggleArrayItem(this.campaignFilter[campaign.taskChainGuid], value);
    this.getCampaignResults(campaign, this.campaignFilter[campaign.taskChainGuid]);
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.page = e.pageIndex;
    this.getDataList();
  }

  transformKeyToText(key) { //"Leadscoring.Event.Type."
   return this.axivasTranslateService.getTranslationTextForToken( key);
  }

  bookResult(element, resultType = null, resultId = null) {
    if (element.currentTaskResultId != null) {
      return;
    }

    if (resultType != null) {
      if (resultType.isAuto) {
        return;
      }
    }
    if (this.commonService.isNullOrUndefined(resultId)) {
      resultId = null;
    }
    this.applicationInfoService.miscSettings['createTaskMode'] = 'booktask';
    this.applicationInfoService.entities.Item('15').data = [];
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'workflowId', element.workflowId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'contactId', this.applicationInfoService.currentContact.id]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'campaignId', element.campaignId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'stepId', element.stepId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'resultId', resultId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'eventId', element.currentTaskEventId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'id', element.currentTaskId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'followUpUserId', element.followUpUserId]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'uiBlockStepChange', true]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, ['15', 'uiBlockCampaignChange', true]);
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['', 'booking', '80']);
  }

  getDifferenceInDays(date1, date2, stringContent, multiplicator) {
    if (date1 == null) {
      return '';
    } else {
      return this.commonService.getDifferenceInDays(date1, date2, stringContent, multiplicator);
      return this.commonService.getTimeDifferenceString(date1, date2, stringContent, multiplicator);
    }
  }

  getCampaignResults(campaign, searchString) {
    let endData = campaign.campaignEnd;
    if (endData == 'null' || endData == null) {
      endData = new Date(Date.now());
    } else {
      endData = new Date(campaign.campaignEnd);
    }

    let startDate = new Date(campaign.firstTaskDate);
    startDate.setHours(0, 0, 0);

    this.externaldatasourceService.executeExternalDataSource(427, [
      campaign.campaignId,
      searchString,
      startDate,
      endData,
      campaign.taskChainGuid
    ])
    .then(campaignResult => {
      campaignResult.result.sort((a, b) => a.followUpDate > b.followUpDate ? -1 : 1);

      this.campaignDetails[campaign.taskChainGuid] = campaignResult;
    })
  }

  callContact(member, taskType) {
    if (taskType != 'call') {
      return;
    }
    let phoneNumber = null;
    phoneNumber = this.entityService.getEntityValue('4', member, 0);
    console.log('callContact', phoneNumber)
    if (phoneNumber != null && phoneNumber != '') {
      this.ringcentralService.makeCall(phoneNumber)
    }
  }

  startCampaign(selectedResult) {

    const promiseArray: Promise<any>[] = [];
    let editableContent = false;
    if (selectedResult.emailTemplateId != null) {
      promiseArray.push(
        this.emailTemplateService.getEMailTemplate(selectedResult.emailTemplateId, this.applicationInfoService.currentContact.languageId)
        .then(getEMailTemplateResult => {
          getEMailTemplateResult.usedEmailTemplateContents.forEach(content => {
            if (content.isEditable) {
              editableContent = true;
            }
          });
      }));
    }
    
    Promise.all(promiseArray).then(() => {
      if (editableContent) {
        if (this.applicationInfoService.projectID == 164) {
          this.applicationInfoService.miscSettings['isStartingMicroCampaign'] = selectedResult;
        }
        this.applicationInfoService.miscSettings['bookingWorkflowTypeId'] = 2851;
        this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1'])
        .then(() => {
          this.applicationInfoService.miscSettings['bookingMode'] = 4;
          this.eventService.showJjPopup('MicroCampaign.Label.StartCampaign', 'booking', 80);
        });
      } else {
        this.loaderService.display(true);
        let campaignId = selectedResult.nextStepCampaignId;
        if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {
          campaignId = this.applicationInfoService.campaingnModeId;
        }
        this.externaldatasourceService.executeExternalDataSource(888, [selectedResult.id]).then(preview => {
          const nextFolloupDetails = JSON.parse(preview);
          this.externaldatasourceService.executeExternalDataSource(270, [this.commonService.getModifyArrayBody({
            resultId: selectedResult.id,
            followUpUserId: 6,
            stepId: selectedResult.stepId,
            campaignId: campaignId,
            // resultUserId: this.applicationInfoService.userID,
            resultUserId: this.applicationInfoService.userID,
            followUpDate: nextFolloupDetails.NextFollowUpDate,
            resultDate: new Date(Date.now()),
            accountId: this.applicationInfoService.currentAccount.id,
            contactId: this.applicationInfoService.currentContact.id,
            isFollowUpAppointment: true
          }, [])]).then(() => {
            this.getMicroCampaignInfo();
            this.loaderService.display(false);
          }).catch(() => {
            this.loaderService.display(false);
          });      
        }).catch(() => {
          this.loaderService.display(false);
        });
      }      
    })    
  }

  isDoubleOptinMissing() {
    this.doubleOptinMissingChecked = false;
    if (this.selectedMicroCampaign.emailTemplateId == null) {
      this.doubleOptinMissing = false;
      this.doubleOptinMissingChecked = true;
      return;
    }

    if (this.applicationInfoService.currentContact == null) {
      this.doubleOptinMissing = false;
      this.doubleOptinMissingChecked = true;
      return
    }

    this.doubleOptinMissing = true;
    this.externaldatasourceService.executeExternalDataSource(878, [this.selectedMicroCampaign.emailTemplateId]).then(templateInfo => {
      if (templateInfo.isRequireDoubleOptIn == true) {
        this.externaldatasourceService.executeExternalDataSource(879, [
          this.applicationInfoService.currentAccount.id,
          this.applicationInfoService.currentContact.id
        ]).then(getOptStatesResult => {
          const emailOptin = this.commonService.getOptinStateForMedia({ id: 47 }, getOptStatesResult, this.applicationInfoService.currentContact);
          const allMediaOptin = this.commonService.getOptinStateForMedia({ id: 51 }, getOptStatesResult, this.applicationInfoService.currentContact);

          if (emailOptin.lookupTypeRefId == 1815 && emailOptin.endDateInPast == false) { this.doubleOptinMissing = false; };
          if (allMediaOptin.lookupTypeRefId == 1815 && allMediaOptin.endDateInPast == false) { this.doubleOptinMissing = false; }
          if (emailOptin.lookupTypeRefId == 44 && emailOptin.endDateInPast == false) { this.doubleOptinMissing = true; }
          if (allMediaOptin.lookupTypeRefId == 44 && allMediaOptin.endDateInPast == false) { this.doubleOptinMissing = true; }

          this.doubleOptinMissingChecked = true;
        });
      } else {
        this.doubleOptinMissing = false;
        this.doubleOptinMissingChecked = true;
      }
    });
  }

  isCampaignAvailable() {
    let returnValue = false;
    if (this.applicationInfoService.applicationSettings['showCampaignPdf'] == 'true') {
      returnValue = true;
    }
    return returnValue;
  }

  showCampaignPdf() {
    let url = this.applicationInfoService.applicationSettings['campaignPdfDe'];
    if (this.axivasTranslateService.currentLanguage == 'de') {
      url = this.applicationInfoService.applicationSettings['campaignPdfEn']
    }
    this.commonService.openUrl(url, '_new');
  }

  isTutorialAvailable() {
    let returnValue = false;
    if (this.applicationInfoService.applicationSettings['showCampaignTutorialVideo'] == 'true') {
      returnValue = true;
    }
    return returnValue;
  }

  showTutorialVideo() {
    this.eventService.showCustomVideoEvent.emit('campaignTutorialVideo');
  }


  cancelMicroCampaign(campaignId) {
    this.applicationInfoService.miscSettings['microCampaignToCancel'] = campaignId;
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['MicroCampaign.Label.CancelCampaign', 'microcampaigncancel', '80']);
  }

  showCampaignOverviewDetails(){
    console.log('this.selectedInput', this.applicationInfoService.selectedCampaignOverview)
    //this.dataArray.find((campaign) => campaign.id === campaignoverview.id)
  }

  getMicroCampaignInfo() {
    this.microcampaignState = [];
    const campaignIds = [];
    this.microcampaignService.getMicroCampaignStarter();    
    this.microcampaignService.microcampaigns.forEach(campaign => { campaignIds.push(campaign.id); });
    if (campaignIds.length == 0) { return; }

    if (this.applicationInfoService.currentContact) {
      this.externaldatasourceService.executeExternalDataSource(929, [this.applicationInfoService.currentContact.id, campaignIds  ])
      .then(getMicroCampaignInfoResult => {
        if (getMicroCampaignInfoResult) {
          getMicroCampaignInfoResult.forEach(resultItem => {
            this.microcampaignState[resultItem.resultId] = resultItem;
          });                  
        }
      });      
    }      
  }

  getStepName(stepId) {
    const step = this.applicationInfoService.steps.toArray().find(step => step.id == stepId);
    if (step) {
      return this.commonService.getTranslationValueFromArray(step);
    } else {
      return '';
    }
  }
}
