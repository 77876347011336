<div class="inline" style="width: 100%;">
    <div *ngFor="let language of translateLanguages; index as i">
        <label
            style="width: 100%; margin: 0px; padding-left: 5px; background-color: whitesmoke; border-top: 1px solid gainsboro">{{ language.languageName }}</label>
        <input [tabindex]=i style="width: 100%;" [(ngModel)]="translations[language.id]" *ngIf="translations !== null">
        <br><br>
    </div>    
    <br>
    <button class="jjButtonColor jjButtonStyle"
        (click)="confirmTranslation()">{{ 'TranslateTokenPopup.Label.Confirm' | texttransform }}</button>
</div>