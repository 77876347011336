import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EntityService, ValueType } from 'app/jollyjupiter/service/entity.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { isNull } from 'util';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-newaccount',
  templateUrl: './newaccount.component.html',
  styleUrls: ['./newaccount.component.scss']
})
export class NewaccountComponent implements OnInit, OnDestroy {
  updateEventSubscription: Subscription = new Subscription();
  entityKeyDownEventSubscription: Subscription = new Subscription();
  newAccountContainerInnerHtmlBase = '<jj-container controluiid=<0>></jj-container>';
  newAccountContainerListInnerHtmlBase = '<jj-container controluiid=53126></jj-container>';  
  newAccountContainerInnerHtml = null;
  newAccountContainerListInnerHtml = null;
  newAccountEntityId = 46;
  poolId = null;
  assignContactAfterCreation = false;
  useNewAccountCreationForm = false;
  accountsSearched = false;
  useNewAccountCreationFormMatTableId = null;
  keyWords = '';
  poolSelectionBlocked = false;
  newAccountText = 'NewAccount.Label.CreateNewAccountAnyway';
  newAccountEnterText = 'NewAccount.Label.CreateNewAccountAnywayAndEnter';
  newAccountSearchText = 'NewAccount.Label.SearchAccounts';
  searchOption = true;

  constructor(
    private domSanitizer: DomSanitizer,
    private uiService: UiService,
    private messagingService: MessagingService,
    private methodService: MethodService,
    private eventService: EventService,
    private commonService: CommonService,
    private projectService: ProjectService,
    private entityService: EntityService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnDestroy() {
    if (this.updateEventSubscription) { this.updateEventSubscription.unsubscribe(); }
    if (this.entityKeyDownEventSubscription) { this.entityKeyDownEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.updateEventSubscription = this.eventService.updateEntityEvent.subscribe(event => {
      if (Number(event.target) === 46) {
        this.accountsSearched = false;
      }
    });
    this.entityKeyDownEventSubscription = this.eventService.entityKeyDownEvent.subscribe(event => {
      if (event === 46) {
        this.accountsSearched = false;
      }
    });

    this.newAccountContainerInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(
      this.newAccountContainerInnerHtmlBase.replace('<0>', this.applicationInfoService.applicationSettings['createNewAccountContainerId'])
    );
    this.newAccountContainerListInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(this.newAccountContainerListInnerHtmlBase);

    if (this.applicationInfoService.applicationSettings['useNewAccountCreationForm'] == 'true') {
      this.useNewAccountCreationForm = true;
    }
    this.useNewAccountCreationFormMatTableId = this.applicationInfoService.applicationSettings['useNewAccountCreationFormMatTableId']    
    this.poolId = this.applicationInfoService.poolId;

    if (this.applicationInfoService.blockSettingChange == true) {
      this.poolId = this.applicationInfoService.poolId;
      this.poolSelectionBlocked = true;
    }
  
    if (this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] == true) {
      this.assignContactAfterCreation = true;
      this.applicationInfoService.miscSettings['convertProspectAndAssignContactToIt'] = false;
    }

    // Label & Co
    if (this.applicationInfoService.applicationSettings['newAccountHidePreview'] == 'true') {
      this.searchOption =false;
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newAccountLabel'])) {
      this.newAccountText = this.applicationInfoService.applicationSettings['newAccountLabel'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newAccountSearchLabel'])) {
      this.newAccountSearchText = this.applicationInfoService.applicationSettings['newAccountSearchLabel'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['newAccountAndEnterLabel'])) {
      this.newAccountEnterText = this.applicationInfoService.applicationSettings['newAccountAndEnterLabel'];
    }    
    this.setPoolId();
  }

  setPoolId() {
    this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.poolId = Number(this.poolId);    
    console.warn(this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data)
  }

  getMaximumHeightOfSubContainer(containerName) {
    return this.uiService.getDesignSpanPosition(containerName, 50);
  }

  createNewAccount(enterAccount: boolean = false) {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.name1) ||
      this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()).data.name1 == '') {
        this.messagingService.showDefaultWarning('', 'NewAccount.Message.EnterName')
        return;
    }
    this.loaderService.display(true);    
    this.externaldatasourceService.executeExternalDataSource(
      this.applicationInfoService.applicationSettings['createNewAccountExternalDataSourceId'],
      [
        this.commonService.getModifyArrayBody(this.applicationInfoService.entities.Item(
        this.newAccountEntityId.toString()).data, ['id', 'keywords', 'allkeywords'], [],
        this.applicationInfoService.entities.Item(this.newAccountEntityId.toString()))
      ])
    .then(createNewAccountResult => {
      if (this.applicationInfoService.campaingnModeId != null && this.applicationInfoService.useNewDesign == true) {
        console.log('Campaign mode - creating ')
        this.externaldatasourceService.executeExternalDataSource(425, [createNewAccountResult.id]);
      }
      this.messagingService.showDefaultSuccess('', 'NewAccount.Label.CreateNewAccountSuccess', false);
      this.entityService.overwriteEntityData(this.newAccountEntityId.toString(), {});
      this.setPoolId();
      if (enterAccount) {
        this.applicationInfoService.currentContact = null;
        this.applicationInfoService.currentTask = null;
        this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
        this.entityService.clearEntityData(this.applicationInfoService.taskEntityId);
        this.projectService.getCurrentAccount(createNewAccountResult.id)
        .then(() => {
          this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'account'])
          .then(() => {
            this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
          });
        });
      }
      if (this.assignContactAfterCreation) {
        this.methodService.launchInternalMethod(InternalMethodType.AssignAccountToContact, [createNewAccountResult.id, 'loadContact'])
        .then(() => {
          this.loaderService.display(false);
          this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
        });
      } else {
        this.loaderService.display(false);
      }      
    })
    .catch(error => {
      this.loaderService.display(false);
    });
  }

  createSearchOrNewAccount() {   
    this.entityService.checkValuesForAllRequiredControls(46, true) 
    .then(() => {
      let finalRequestQuery = 'query { accountDuplicates(projectId: <projectId>, account: { <0> }, keywords: <1> mustMatchEveryKeyword: <3>) { id <2> }}';
      finalRequestQuery = this.commonService.modifyQueryStringWithApplicationInfos(finalRequestQuery);
      finalRequestQuery = finalRequestQuery.replace('<0>', this.commonService.getModifyArrayBody(this.applicationInfoService.entities.Item(
        this.newAccountEntityId.toString()).data, ['id', 'keywords', 'allkeywords', 'owner'], [], this.applicationInfoService.entities.Item(this.newAccountEntityId.toString())));

      this.keyWords = this.entityService.getEntityValue(46, 'keywords', 1);
      if (this.keyWords != '' && !this.commonService.isNullOrUndefined(this.keyWords)) {
        let keyWords = this.keyWords.split(' ');
        finalRequestQuery = finalRequestQuery.replace('<1>', JSON.stringify(keyWords));
      } else {
        finalRequestQuery = finalRequestQuery.replace('<1>', '[]');      
      }
      const controlFields = this.uiService.getQueryFieldsFromListControl(
        this.applicationInfoService.controls.Item(this.useNewAccountCreationFormMatTableId)
      );
      finalRequestQuery = finalRequestQuery.replace('<2>', controlFields);
      let allKeyWords = this.keyWords = this.entityService.getEntityValue(46, 'allkeywords', 1);
      if (allKeyWords == undefined) {
        allKeyWords = false;
      }
      finalRequestQuery = finalRequestQuery.replace('<3>', allKeyWords);
      this.eventService.externaldDataQueryEvent.emit(new JJEvent(
        this.useNewAccountCreationFormMatTableId, [finalRequestQuery]));
        this.eventService.customEvent.emit({ eventType: 'updateEntityTableView' });
      this.accountsSearched = true;
    });
  }
}
