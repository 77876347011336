<div *ngIf="applicationInfoService.entities.Item(entityId).data != null" class="booking__wrapper">
    <app-booking-image [result]="microCampaignToStart" class="booking__page"
        *ngIf="microCampaignToStart != null && page == 1"></app-booking-image>
    <div class="booking__row booking__page" [ngClass]="{ hideInDom: page != 1 || microCampaignToStart != null }" >
        <div class="booking__row__general">
            <div *ngIf="createTaskList?.length > 0" class="newTaskTaskCountContainer">
                <div class="sectionDecorator">{{ createTaskCount }} {{ 'CreateTask.Label.TaskWillBeCreated' | texttransform }}</div>
                <label class="bookingEntityMemberLabel">{{ 'Task.Label.CreationGuidDescription' | texttransform }}</label>
                <input class="everleadCoral" [(ngModel)]="taskCreationGuid" class="maxWidth">
            </div>

            <div class=" Maxwidth bookingRow">
                <div class=" bookingRowFollowUpCampaignId">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-laptop fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Task.Label.Campaign' | texttransform }}
                    </label>
                    <div class="customControlValueWrapper">
                        <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.campaignId"
                            [disabled]="!canEdit('campaign')" class="bookingDropdown maxWidth">
                            <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id" [ngClass]="{ hideInDom: checkIfCampaignNeedsToBeHidden(campaign) }">
                                <span *ngIf="campaign.nameTranslationToken == null">{{ campaign.defaultName |
                                    axivastranslate
                                    }}</span>
                                <span *ngIf="campaign.nameTranslationToken != null">{{
                                    campaign.nameTranslationToken.tokenFullName | texttransform }}</span>
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class=" Maxwidth bookingRow" *ngIf="controlMode != 2">
                <div class="">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-user fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Contact.Label.Header' | texttransform }}
                    </label>
                    <div class="customControlValueWrapper">
                        <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.contactId"
                            class="bookingDropdown maxWidth" [disabled]="!canEdit('contact')">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let contact of contacts" [ngValue]="contact.id">
                                {{ contact.firstName }} {{ contact.lastName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- workflows -->
            <div class=" Maxwidth bookingRow" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] !='true'">
                <div class="">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-list fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Task.Label.Process' | texttransform }}
                    </label>
                    <div class="customControlValueWrapper">
                      <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.workflowId"
                          class="bookingDropdown maxWidth" [disabled]="!canEdit('workflow')"
                          (change)="getWorkflowSteps(applicationInfoService.entities.Item(entityId).data.workflowId, true)">
                          <option *ngFor="let workflow of workflows" [ngValue]="workflow.id" [ngClass]="{
                              hideInDom: checkIfWorkflowNeedsToBeHidden(workflow)
                          }">
                              <span *ngIf="workflow.nameTranslationToken == null">{{ workflow.defaultName | axivastranslate }}</span>
                              <span *ngIf="workflow.nameTranslationToken != null">{{ workflow.nameTranslationToken.tokenFullName | texttransform }}</span>
                          </option>
                      </select>
                    </div>
                </div>
            </div>

            <div *ngIf="(steps?.length == 0 || workflows?.length == 0) && applicationInfoService.entities.Item(entityId).data.workflowId" class="noStepAvailableDiv">
                <label class="noStepAvailableLabel">
                    <i class="fas fa-info-circle"></i>
                    {{ 'Task.Label.NoStepsAvailable' | texttransform }}
                </label>
            </div>

            <!-- steps -->
            <span *ngIf="steps?.length > 0 && workflows?.length > 0">
                <div class=" Maxwidth bookingRow">
                    <div class="">
                        <label class="bookingEntityMemberLabel">
                            <i class="fas fa-shoe-prints fa-everleadSmall" style="margin-right: 4px;"></i>                            
                            {{ 'Task.Label.Step' | texttransform }}
                        </label>
                        <div class="customControlValueWrapper">
                            <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.stepId"
                                class="bookingDropdown maxWidth" [disabled]="!canEdit('step')"
                                (change)="getWorkflowStepResults(applicationInfoService.entities.Item(entityId).data.stepId)">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let step of steps" [ngValue]="step.id" [ngClass]="{
                                    hideInDom: checkIfStepNeedsToBeHidden(step)
                                }">
                                    <span *ngIf="step.nameTranslationToken == null">{{ step.defaultName | texttransform
                                        }}</span>
                                    <span *ngIf="step.nameTranslationToken != null">{{
                                        step.nameTranslationToken.tokenFullName |
                                        axivastranslate }}</span>
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class=" Maxwidth bookingRow" *ngIf="controlMode != 2">
                    <div class="">
                        <label class="bookingEntityMemberLabel">
                            <i class="fas fa-check-square fa-everleadSmall" style="margin-right: 4px;"></i>
                            {{ 'Task.Label.Result' | texttransform }}
                        </label>
                        <div class="customControlValueWrapper">
                          <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.resultId"
                              class="bookingDropdown maxWidth" [disabled]="!canEdit('result')"
                              (change)="resultChanged(applicationInfoService.entities.Item(entityId).data.resultId)">
                              <option [ngValue]="null"></option>
                              <option *ngFor="let result of results" [ngValue]="result.id" [ngClass]="{
                                  hideInDom: checkIfResultNeedsToBeHidden(result)
                              }">
                                  <span *ngIf="result.nameTranslationToken == null">{{ result.defaultName | texttransform }}</span>
                                  <span *ngIf="result.nameTranslationToken != null">{{
                                      result.nameTranslationToken.tokenFullName
                                      | texttransform }}</span>
                              </option>
                          </select>
                        </div>
                    </div>
                </div>
                <div id="opportunitySelector" *ngIf="opportunityService.checkIfResultRequiresOpportunity(applicationInfoService.entities.Item(entityId).data.resultId)">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-percentage fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Task.Label.Opportunity' | texttransform }}
                    </label>
                    <div class="everleadShadowBox opportunityBox">
                        <div class="opportunityBox__description">
                            {{ 'Task.Label.OpportunityDescription' | texttransform }}
                        </div>
                        <div class="opportunityBox__createOpportunity">
                            <button class="everleadCustomButton" (click)="editOpportunity()">{{ 'Task.Label.EditOpportunity' | texttransform }}</button>
                        </div>
                    </div>
                </div>
                <div id="bookingFormBookingPreview" class="bookingFormBookingPreview  everleadShadowBox everleadShadowBox2" 
                    *ngIf="bookingPreview != null && applicationInfoService.applicationSettings['bookingFormShowBookingPreview'] == 'true'">
                    <label class="bookingFormBookingPreview__header">{{ 'TaskBooking.Preview.Header' | texttransform }}:</label>
                    <div *ngIf="selectedResult?.emailTemplateId != null" class="bookingFormBookingPreview__email">
                        <i class="fas fa-envelope fingerCursor" [tippy]="'Timeline.Label.ShowEmail' | texttransform" 
                            (click)="showMail()"></i>{{ 'BookingPreview.Label.SendingEMail' | texttransform }}
                    </div>
                    <div *ngIf="bookingPreview.HasFollowUpTask == true">
                        {{ 'BookingPreview.Label.FollowupTaskWillbeCreated' | texttransform }}: 
                        <span class="bookingFormBookingPreview__nextStep">{{ nextStep?.defaultName }}</span>
                    </div>
                    <div *ngIf="bookingPreview.HasFollowUpTask == false">
                        {{ 'BookingPreview.Label.ChainEndsHere' | texttransform }}
                    </div>
                    <div *ngIf="bookingPreview.LeadStatus != null">
                        {{ 'BookingPreview.Label.Leadstatus' | texttransform }}: <span class="bookingFormBookingPreview__leadstate">{{ bookingPreview.LeadStatus }}</span>
                    </div>
                    <div *ngIf="selectedResult?.qmUserGroupId != null">
                        {{ 'BookingPreview.Label.QMRequired' | texttransform }}
                    </div>
                    <div *ngIf="applicationInfoService.entities.Item(entityId).data.isNotifyFollowUpOwner == true">
                        {{ 'BookingPreview.Label.NotifyOwnerByEMail' | texttransform }}
                    </div>
                </div>
                <div class="bookingRow" *ngIf="applicationInfoService.entities.Item(entityId).data.uiNextFollowUpDate != null
                        && applicationInfoService.entities.Item(entityId).data.uiNextFollowUpDate != undefined
                        && !additionalTaskInformation?.resultId
                ">
                    <div class="bookingRowUiNextFollowUpDate__label">
                        <label class="bookingEntityMemberLabel">{{ 'Task.Label.FollowUpDate' | texttransform }}</label>
                    </div>
                    <div class="bookingRowUiNextFollowUpDate__input">
                        <input [disabled]="!canEdit('resultdate')"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.uiNextFollowUpDate"
                            [owlDateTimeTrigger]="dt7"
                            (dateTimeChange)="uiNextFollowUpDateChanged(applicationInfoService.entities.Item(entityId).data.uiNextFollowUpDate)"
                            [owlDateTime]="dt7" readonly class="bookingInput" aria-modal="true">
                        <owl-date-time #dt7></owl-date-time>
                    </div>
                </div>
            </span>
            <app-custom-subresult [controlDefinition]="controlDefinition" [externalDataSource]="externalDataSource">
            </app-custom-subresult>
            <div [innerHTML]="innerHTMLAdd2" class=""></div>

            <div class=" Maxwidth bookingRow bookingRowResultDate" *ngIf="additionalTaskInformation?.resultId">
                <div class="">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-calendar fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Task.Label.ResultDate' | texttransform }}
                    </label>
                    <div class="customControlValueWrapper">
                        <input [disabled]="!canEdit('resultdate')"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.resultDate"
                            [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly class="bookingInput" aria-modal="true">
                    </div>
                </div>
            </div>

            <!-- new task -------------------------------------------------------------------------------------------->
            <div *ngIf="controlMode == 2">
                <div class=" bookingRowFollowUpUserId">
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.Owner' | texttransform }}</label>
                    <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.followUpUserId"
                        class="bookingDropdown maxWidth">
                        <option *ngFor="let user of users" [ngValue]="user.id">
                            {{ user.userName }}
                        </option>
                    </select>
                </div>

                <div class=" bookingFollowUpDate">
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.FollowUpDate' | texttransform }}</label>
                    <input (dateTimeChange)="checkFollowUpDate()"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.followUpDate"
                        [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly class="bookingInput" aria-modal="true">
                </div>
            </div>

            <div class=" Maxwidth bookingRow" *ngIf="bookingPreview?.HasFollowUpTask == true">
                <div class=" bookingRowFollowUpUserId">
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.Owner' | texttransform }}</label>
                    <select [(ngModel)]="applicationInfoService.entities.Item(entityId).data.followUpUserId"
                        class="bookingDropdown maxWidth" [disabled]="!canEdit('owner')">
                        <option *ngFor="let user of users" [ngValue]="user.id">
                            {{ user.userName }}
                        </option>
                    </select>
                </div>

                <div class=" bookingRowInformFollowUpUser">
                    <input type="checkbox"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.isNotifyFollowUpOwner"
                        class="bookingCheckBox"><label class="bookingEntityMemberLabelLabel">
                    </label>
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.Inform' | texttransform }}</label>
                </div>
            </div>

            <div class=" Maxwidth bookingRow">
                <div class="bookingFormIsPersonalFollowup" *ngIf="checkResultAttribute('isPersonalFollowup', true)">
                    <input type="checkbox" [(ngModel)]="applicationInfoService.entities.Item(entityId).data.isPersonalFollowup"
                        class="bookingCheckBox">
                    <label class="bookingEntityMemberLabelLabel">
                        {{ 'Task.Label.PersonalContact' | texttransform }}
                    </label>
                </div>
            </div>
            <div class=" Maxwidth bookingRow">
                <div class=" bookingRowSubject" *ngIf="controlMode != 2">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-copy fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Task.Label.Subject' | texttransform }}
                    </label>
                    <div class="customControlValueWrapper">
                        <input [(ngModel)]="applicationInfoService.entities.Item(entityId).data.subject"
                            class="bookingInput">
                    </div>
                </div>                
                <div class=" bookingRowNotes" *ngIf="controlMode != 2">
                    <label class="bookingEntityMemberLabel">
                        <i class="fas fa-sticky-note fa-everleadSmall" style="margin-right: 4px;"></i>
                        {{ 'Task.Label.Notes' | texttransform }}
                    </label>
                    <textarea *ngIf="aiReplacementText == null" [(ngModel)]="applicationInfoService.entities.Item(entityId).data.notes"
                        class="bookingTextArea">
                    </textarea>
                    <textarea *ngIf="aiReplacementText != null" [(ngModel)]="aiReplacementText"
                        class="bookingTextArea"
                    ></textarea>
                    <div class="aiTextarea" *ngIf="applicationInfoService.applicationSettings['showBookingAITextTransform']=='true'">
                        <button class="everleadCustomButton aiTextarea__button" (click)="aiTransformText('notes')"
                            [disabled]="applicationInfoService.entities.Item(entityId).data.notes?.length < 10"
                        >
                            {{ 'TextArea.Label.AITransform' | texttransform }}<i class="fas fa-info-circle" [title]="'TextArea.Label.AICalculatingDescription' | texttransform"></i>
                        </button>
                        <div *ngIf="aiGenerating == true" class="aiTextarea__icons">{{ 'TextArea.Label.AICalculating' | texttransform }}</div>
                        <div *ngIf="aiReplacementText != null && aiGenerating == false" class="aiTextarea__icons">
                            <i class="fas fa-check everleadGreen fingerCursor" (click)="acceptAiText('notes')"></i>
                            <i class="fas fa-times everleadCoral fingerCursor" (click)="cancelAiText('notes')"></i>
                        </div>
                    </div>                    
                </div>
            </div>
            <div [innerHTML]="innerHTMLAdd3" class=""></div>
            <div class="Maxwidth bookingRow bookingPreview everleadShadowBox everleadShadowBox2" *ngIf="bookingPreview != null">
                <div *ngIf="bookingPreview.HasFollowUpTask == true">
                    {{ 'BookingPreview.Label.FollowupTaskWillbeCreated' | texttransform }}
                </div>
                <div *ngIf="bookingPreview.HasFollowUpTask == false">
                    {{ 'BookingPreview.Label.ChainEndsHere' | texttransform }}
                </div>
                <div *ngIf="bookingPreview.LeadStatus != null">
                    {{ 'BookingPreview.Label.Leadstatus' | texttransform }}: {{ bookingPreview.LeadStatus }}
                </div>              
            </div>

            <div [innerHTML]="innerHTMLAdd4" class=""></div>
        </div>

        <div class="booking__row__additional">
            <div *ngIf="controlMode == 2" class="bookingPreviewContainer__wrapper">
                <div *ngIf="innerHTMLEMailPreview != null" class="sectionDecorator">
                    {{ 'TaskBooking.EMailPreview.Header' | texttransform }}
                </div>
                <div class="bookingPreviewContainer" [innerHTML]="innerHTMLEMailPreview"></div>
            </div>

            <app-booking-preview></app-booking-preview>
            <div [innerHTML]="innerHTMLAdd1" class=""></div>
        </div>
    </div>

    <div *ngIf="page == 2" class="booking__page">
        <app-custom-emaileditor [templateId]=selectedResult.emailTemplateId [taskEntityId]=entityId [contactId]="applicationInfoService.entities.Item(entityId).data.contactId"></app-custom-emaileditor>
    </div>


    <div id="bookingFooterRow" class="bookingFooterRow">
            <div class="bookingFooterRow__left">

                <button id="booking1" class="everleadCustomButton bookingButton" (click)="bookTask(true)"
                    *ngIf="(controlMode == 1 || controlMode == 4) && page == 1" [disabled]="!canBookTask()">
                    <span *ngIf="checkResultAttribute('isEditableEmail', true) == true">{{ 'CreateTask.Label.EditEMail'
                        | texttransform }}</span>
                    <span *ngIf="checkResultAttribute('isEditableEmail', true) == false">{{ 'CreateTask.Label.BookTask'
                        | texttransform }}</span>
                </button>

                <button id="booking2"  class="everleadCustomButton bookingButton" (click)="createTask()"
                    *ngIf="controlMode == 2 && bookingMailMode != 2 && bookingMailMode != 3" [disabled]="!canCreateTask()">
                    {{ 'CreateTask.Label.CreateTask' | texttransform }}
                </button>

                <button id="booking2"  class="everleadCustomButton bookingButton" (click)="createMailShot()"
                    *ngIf="controlMode == 2 && (bookingMailMode == 2 || bookingMailMode == 3)" [disabled]="!canCreateTask() || taskCreationGuid.length < 3">
                    {{ 'CreateTask.Label.CreateMailshot' | texttransform }}
                </button>

                <button id="booking3"  class="everleadCustomButton bookingButton" (click)="updateTask()"
                    *ngIf="controlMode == 3 && page == 1" [disabled]="!canBookTask()">{{
                    'CreateTask.Label.UpdateTask' | texttransform }}</button>

                <button id="booking4"  class="everleadCustomButton bookingButton" (click)="changePage(1)"
                    *ngIf="(controlMode == 1 || controlMode == 4)  && page == 2">{{
                    'CreateTask.Label.Back' | texttransform }}</button>

                <button id="booking5"  class="everleadCustomButton bookingButton" (click)="bookTask()"
                    *ngIf="(controlMode == 1 || controlMode == 4)  && page == 2" [disabled]="!canBookTask()">
                        <span *ngIf="microCampaignToStart != null">{{ 'MicroCampaign.Label.StartCampaign' | texttransform }}</span>
                        <span *ngIf="microCampaignToStart == null">{{ 'CreateTask.Label.BookTask' | texttransform }}</span>
                </button>
                <button id="booking6"  class="everleadCustomButton bookingButton" (click)="showSendMailContainer = true"
                    *ngIf="bookingMailMode == 2 && selectedTemplate != null">{{
                    'CreateTask.Label.TestEMail' | texttransform }}</button>

            </div>
            <div class="bookingFooterRow__right">
                <button class="everleadCustomButton everleadCoralBackground" (click)="deleteTask()"
                    *ngIf="canDeleteTask() && userService.hasPermission(deleteTaskUserRightName)">{{
                    'CreateTask.Label.DeleteTask' | texttransform }}</button>
            </div>
    </div>

    <!-- Testmailshot -->
    <div *ngIf="showSendMailContainer == true">
        <div class="sendMailContainer__background"></div>
        <div id="sendMailContainer" class="sendMailContainer">
            <div class="sendMailContainer__header">
                <ng-container>{{ 'CreateTask.Label.TestEMail' | texttransform }}</ng-container>
                <i class="fas fa-close fingerCursor" (click)="showSendMailContainer = false"></i>
            </div>
            <div class="sendMailContainer__wrapper">
                <div class="">
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.TestEMailReceiver' | texttransform }}</label>
                    <select [(ngModel)]="testMailUser" (change)="testMailAdress = testMailUser.email"
                        class="bookingDropdown maxWidth">
                        <option *ngFor="let user of users" [ngValue]="user">
                            {{ user.userName }}
                        </option>
                    </select>
                </div>
                <div class="sendMailContainer__adress">
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.TestEMailReceiverAdress' | texttransform }}</label>
                    <input type="text" [(ngModel)]="testMailAdress">
                </div>
                <div class="sendMailContainer__infoContainer">
                    <label class="sendMailContainer__infoLabel">{{ 'Task.Label.FirstContactWillBePickedForTestMail' | texttransform }}:</label>
                    <br>
                    <label class="sendMailContainer__infoLabel everleadCoral">{{ testMailContact?.firstName }} {{ testMailContact?.lastName }}</label>
                </div>
                <button class="everleadCustomButton bookingButton" (click)="sendEmail()"
                    *ngIf="(bookingMailMode == 2 || bookingMailMode == 3) && selectedTemplate != null">{{
                    'CreateTask.Label.TestEMail' | texttransform }}</button>
            </div>
        </div>
    </div>
</div>

<owl-date-time #dt8></owl-date-time>
