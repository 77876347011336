import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-preview',
  templateUrl: './booking-preview.component.html',
  styleUrls: ['./booking-preview.component.scss']
})
export class BookingPreviewComponent implements OnInit, OnDestroy {
  showBookingPreviewEventForResultIdEventSubscription: Subscription = new Subscription();
  @Input() resultId = null;
  @Input() layerNumber = 0;  
  @Input() showEmailPreview = false;
  result = null;
  step = null;
  followUpResultId = null;

  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.showBookingPreviewEventForResultIdEventSubscription = this.eventService.showBookingPreviewEventForResultIdEvent.subscribe(event => {
      if (this.resultId != null) {
        return;
      }
      this.result = this.applicationInfoService.results.Item(event);
      if (this.result.nextStepId != null) {
        this.step = this.getFollowUpStep(this.result.nextStepId)
      } else {
        this.step = null;
      } 
    });
    if (this.resultId != null) {
      this.result = this.applicationInfoService.results.Item(this.resultId);
      if (this.result.nextStepId != null) {
        this.step = this.getFollowUpStep(this.result.nextStepId)
      }  
    }
  }

  ngOnDestroy(): void {
    if(this.showBookingPreviewEventForResultIdEventSubscription) { this.showBookingPreviewEventForResultIdEventSubscription.unsubscribe(); }
  }

  getFollowUpStep(resultId) {
    const step = this.applicationInfoService.steps.Item(resultId);
    if (step) {
      return step;
    } else {
      return null;
    }
  }

  getFollowUpStepName(result) {
    const step = this.applicationInfoService.steps.Item(result.nextStepId);
    if (step) {
      return this.axivasTranslateService.getTranslationTextForToken('Task.Label.Step').concat(
        ': ', 
        this.axivasTranslateService.getTranslationTextForToken(step.defaultName)
      );
    } else {
      return 'MicroCampaign.Label.NoMoreAction';
    }
  }

  async showFollowUpStep(result) {
    this.followUpResultId = null;
    setTimeout(() => {
      this.followUpResultId = result.id;
    }, 100);
  }

  getStepHeader(token) {
    if (!this.step) {
      return;
    }
    let returnValue = this.axivasTranslateService.getTranslationTextForToken(token);
    returnValue = returnValue.replace('<0>', this.axivasTranslateService.getTranslationTextForToken(this.step.defaultName));
    return returnValue;
  }

  showPreview(result) {
    this.eventService.showEmailTemplatePreview.emit(result);
  }
}
