import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { ConfirmationService } from 'primeng/api';
import { WizardMode, WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-emailtemplates',
  templateUrl: './emailtemplates.component.html',
  styleUrls: ['./emailtemplates.component.scss']
})
export class EmailtemplatesComponent implements OnInit {
  eMailTemplates = [];
  selectedTemplate = null;
  filterString = '';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private commonService: CommonService,
    public uiService: UiService,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    private emailtemplateService: EmailtemplateService,
    public userService: UserService,
    private wizardService: WizardService
  ) { }

  ngOnInit(): void {
    this.getEMailTemplates();
  }

  getEMailTemplates(templateId = null) {
    if (this.userService.hasPermission('CanReadEmailTemplate')) {
      this.externaldatasourceService.executeExternalDataSource(429, [])
      .then(eMailTemplateResult => {
        this.eMailTemplates = eMailTemplateResult;
        if (eMailTemplateResult.length > 0) {
          if (templateId != null) {
            const emailTemplate = this.eMailTemplates.find(template => template.id == templateId);
            if (emailTemplate) {
              this.selectedTemplate = emailTemplate;
              this.showWizard(emailTemplate);
            }
          }
        }
      });
    }
  }

  createNewTemplate() {
    this.externaldatasourceService.executeExternalDataSource(430, [])
    .then(createNewTemplate => {
      const promiseArray: Promise<any>[] = [];
      promiseArray.push(this.emailtemplateService.createEMailTemplateLanguage(createNewTemplate.id, 1));
      promiseArray.push(this.emailtemplateService.createEMailTemplateLanguage(createNewTemplate.id, 2));
      Promise.all(promiseArray).then(() => {
        this.getEMailTemplates(createNewTemplate.id);
      });
    })
    .catch(error => { console.error('createNewTemplate', error); });
  }

  getMyHeight() {
    return Number(this.uiService.getDesignSpanPosition('emailContentEditContainer', 40));
  }

  showWizard(emailTemplate) {
    if (this.userService.hasPermission('CanUpdateEmailTemplate')) {
      this.wizardService.showWizard(WizardMode.EMailTemplate, emailTemplate, '95vh', '95vh');
    }
  }

  checkIfItemIsFiltered(emailContent) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(emailContent.defaultName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }
}
