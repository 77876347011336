import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AdminJobsService } from '../admin-jobs.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-admin-jobs',
  templateUrl: './admin-jobs.component.html',
  styleUrls: ['./admin-jobs.component.scss']
})
export class AdminJobsComponent implements OnInit {
  selectedTabIndex = 0;
  visibleTabs = [{ id: 0, displayText: 'Jobs' }, { id: 1, displayText: 'Schedules' }];
  filterString = '';
  jobsExpanded = [];
  jobStepsExpanded = [];
  jobRemovedSteps = [];
  jobRemovedJobs = [];
  mode = 1;
  jobToModify = null;
  newStepType = null;
  showOnlyActive = true;  

  constructor(
    public commonService: CommonService,
    public dragdropService: DragdropService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private sharedAPI: SharedAPI,
    public adminJobsService: AdminJobsService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    if (this.adminJobsService.jobs.length == 0) { this.adminJobsService.getJobs(); }    
    if (this.adminJobsService.jobStepTypes.length == 0) { this.adminJobsService.getJobStepTypes(); }    
    if (this.adminJobsService.jobSchedules.length == 0) { this.adminJobsService.getSchedules(); }    

    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'addMissingJobStepParameter') {
        event.value.forEach(step => {
          this.addMissingParameter(step);
        })        
      }
    });
  }  

  itemIsFiltered(jobName, filterString = this.filterString) {
    const returnValue = this.commonService.checkIfStringContainsString(jobName, filterString) === true ? false :  true;
    return returnValue;
  }

  addNewStep(job) {
    const newStep = new Object();
    newStep['defaultName'] = 'NEUER STEP';
    newStep['isActive'] = true;
    newStep['jobId'] = job.id;
    newStep['order'] = job.jobSteps.length + 1;    
    newStep['stepTypeId']= this.newStepType.id;
    this.adminJobsService.createJobStep(newStep).then(createJobStepResult => {
      createJobStepResult['parameterValues']= [];
      createJobStepResult['stepType']= this.newStepType;
      job.jobSteps.push(createJobStepResult);
      // Stepparameters
      this.addMissingParameter(createJobStepResult);
    });
  }

  addParameter(step, parameter) {
    const newParam = new Object();
    newParam['jobStepId'] = step.id;
    newParam['value'] = null;
    newParam['stepTypeParameterId'] = parameter.id;    
    this.adminJobsService.createJobStepParameter(newParam).then(addParameterResult => {
      addParameterResult['stepTypeParameter'] = parameter;
      step.parameterValues.push(addParameterResult);
    });
  }




  changeStepType(step) {
    if (this.commonService.isItemExpanded(this.jobStepsExpanded, step.id) !== true) {
      this.commonService.toggleItemExpanded(this.jobStepsExpanded, step.id);
    }
    this.getParameter(step);
  }

  getParameter(step: any) {
    this.externaldatasourceService.executeExternalDataSource(198, [step.stepTypeId])
    .then(executeExternalDataSourceResult => {
      this.getStepParameterValues(step)
      .then(getStepParameterValuesResult => {
        executeExternalDataSourceResult.forEach(parameter => {
          getStepParameterValuesResult.forEach(result => {

          });
        });
      });
      step.parameter = executeExternalDataSourceResult;
    })
    .catch(error => {
      console.error('changeStepType', error);
    });
  }

  createJob() {
    const job = new Object();
    job['defaultName'] = 'Neuer Job';
    job['isActive'] = true;
    this.adminJobsService.createJob(job).then(createJobResult => {
      createJobResult['descriptionTranslationTokenId'] = null;
      createJobResult['descriptionTranslationToken'] = null;
      createJobResult['nameTranslationToken'] = null;
      createJobResult['nameTranslationTokenId'] = null;
      createJobResult['lastExecuted'] = null;
      createJobResult['lastModified'] = null;
      createJobResult['jobSteps'] = [];            
      this.adminJobsService.jobs.unshift(createJobResult);
    });    
  }  

  getStepParameterValues(step): Promise<any> {
    return new Promise((getStepParameterValuesResolve, getStepParameterValuesReject) => {
      this.externaldatasourceService.executeExternalDataSource(199, [step.id, step.stepTypeId])
      .then(executeExternalDataSourceResult => {
        getStepParameterValuesResolve(executeExternalDataSourceResult);
      })
      .catch(error => {
        getStepParameterValuesReject(error);
      });
    });
  }

  setMode(mode) {
    this.mode = mode;
  }

  setCurrentChild(id) {
    this.selectedTabIndex = Number(id);
  }

  addMissingParameter(step) {
    const baseType = this.adminJobsService.jobStepTypes.find(type => type.id == step.stepType.id);
    console.warn('addMissingParameter', step, baseType);
    if (baseType) {
      baseType.jobStepTypeParameters.forEach(jobStepTypeParameter => {        
        const availableParameter = step.parameterValues.find(value => value.stepTypeParameterId == jobStepTypeParameter.id);
        console.warn('addMissingParameter', step, baseType, jobStepTypeParameter, availableParameter);
        if(!availableParameter) {
          console.log('Adding', jobStepTypeParameter);
          this.addParameter(step, jobStepTypeParameter);
        }
      });
    }
  }
}
