import { improvedArray } from './improvedarray';

export interface IKeyedCollection<T> {
    Add(key: string, value: T);
    ContainsKey(key: string): boolean;
    Count(): number;
    Item(key: string): T;
    Keys(): string[];
    Remove(key: string): T;
    Values(): T[];
    toArray(): any[];
    getIdArray(): any[];
    createDictionaryBackup(dict: Dictionary<any>): Dictionary<any>;
  }

  export class Dictionary<T> implements IKeyedCollection<T> {
    public items: { [index: string]: T } = {};
    public valueArray = [];
    impArray: improvedArray = new improvedArray;
    private count = 0;

    public ContainsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    public Count(): number {
        return this.count;
    }

    public GetItemArray(): any[] {
        return this.impArray.list();
    }

    public Set(key: string, value: T) {
        this.items[key] = value;
    }

    public Add(key: string, value: T) {
        this.valueArray.push(value);
        this.impArray.add(key);
        if (!this.items.hasOwnProperty(key)) {
             this.count++;
        }
        this.items[key] = value;
    }

    public Remove(key: string): T {
        const val = this.items[key];
        if (this.ContainsKey(key)) {
            this.impArray.remove(key);
            delete this.items[key];
            this.count--;
        }
        return val;
    }

    public Item(key: string): T {
        return this.items[key];
    }

    public Keys(): string[] {
        const keySet: string[] = [];

        for (const prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }

        return keySet;
    }

    public Values(): T[] {
        const values: T[] = [];

        for (const prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }

        return values;
    }

    public toArray(): any[] {
        const returnValue = [];
        this.getIdArray().forEach(item => {
            if (item !== undefined && item !== null) {
                returnValue.push(this.Item(item));
            }
          });
        return returnValue;
    }

    public getIdArray(): any [] {
        return this.impArray.items;
    }

    public createDictionaryBackup(): Dictionary<any> {
        const returnDictionary = new Dictionary();
        this.getIdArray().forEach(item => {
          if (item !== undefined && item !== null) {
            returnDictionary.Add(item, this.Item(item));
          }
        });
        return returnDictionary;
    }
  }
