import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { LoaderService } from '../service/loader-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    // this.loaderService.display(false);
  }

}
