import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
@Component({
  selector: 'app-custom-activity-list',
  templateUrl: './custom-activity-list.component.html',
  styleUrls: ['./custom-activity-list.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('50ms cubic-bezier(0.4, 0.0, 0.2, 1)')),

  ]),
],
})
export class CustomActivityListComponent implements OnInit, OnDestroy {
  updateControlAttributesEventSubscription: Subscription = new Subscription;
  updateCombinedActivityListSubscription: Subscription = new Subscription;
  externalDataSourceResult = null;
  timelineResult = null;
  displayedColumns = ['details'];
  page = 0;
  currentPage = 0;
  pageSize = 20;
  resultsLength = 0;
  filterType = ['conversion','task','event', 'mailevent'];
  loadingData = false;
  useTimeline = false;
  onlyContactAvtivitys = true;
  newMode = true;
  numRecordsTask = 0;
  numRecordsEvent = 0;
  numRecordsConversion = 0;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


 constructor( private externaldatasourceService: ExternaldatasourceService,
  public commonService: CommonService,
  private eventService: EventService,
  private methodService: MethodService,
  private messagingService: MessagingService,
  private userService: UserService,
  public applicationInfoService: ApplicationInfoService,
  public axivasTranslateService: AxivasTranslateService,
  private paginatorIntl: MatPaginatorIntl,
) { }

  ngOnDestroy() {
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }
    if (this.updateCombinedActivityListSubscription) { this.updateCombinedActivityListSubscription.unsubscribe(); }
  }

  ngOnInit(): void {

    this.paginatorIntl.itemsPerPageLabel = this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator');
    this.updateCombinedActivityListSubscription  = this.eventService.updateCombinedActivityList.subscribe(() => {
      this.getDataList();
    });

    this.updateControlAttributesEventSubscription  = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getDataList();
    });

    Promise.all([this.getDataList().then((message) => { })]).then();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  canEditNotes(element) {
    let returnValue = false;
    if(this.userService.hasPermission(this.applicationInfoService.applicationSettings['editMyTaskRight'])
       && element.taskResultUserId == this.userService.getUserId()) {
       returnValue = true;
    }

    if (this.userService.hasPermission(this.applicationInfoService.applicationSettings['editAllTasksRight'])) {
        returnValue = true;
    }
    return returnValue;
  }

  editActivity(element) {
    if (!this.canEditNotes(element)) {
      return;
    }
    let entityId = '15';
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, [entityId, '-1'])
    .then(() => {
      this.applicationInfoService.miscSettings['bookingMode'] = 3;
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'resultId', element.taskResultId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'stepId', element.taskStepId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'followUpUserId', element.taskResultUserId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'subject', element.taskSubject]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'notes', element.taskNotes]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'resultDate', element.date]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'id', element.id]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'campaignId', element.taskCampaignId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'contactId', element.contactId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'subResults', [element.taskAnswerId]]);
      let textArray = [];
      textArray[element.taskAnswerId] = element.taskAnswerText;
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'subResultsadditionalText', textArray]);
      // this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [entityId, 'subResultsadditionalText',
      //   [{ element.taskAnswerId }]   ]);

      this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['', 'booking', '80'])
    });

  }

  copyToClipBoard(text) {
    this.commonService.copyTextToClipboard(text);
      this.messagingService.showDefaultSuccess(
        'General.CopyToClipBoard.Header',
        'General.CopyToClipBoard.Message',
        false
      );
  }

  getDataList() {
    this.loadingData = true;
    let filterString = '';
    this.filterType.forEach(element => {
      if (filterString != '') { filterString = filterString.concat(', '); }
      filterString = filterString.concat('\"', element, '\"')
    });
    if (filterString == '') {
      this.dataSource = new MatTableDataSource(null);
      this.loadingData = false;
      return;
    }

    return new Promise((resolve, reject ) => {
      if (this.newMode == true) {
        resolve(null);
        return;
      }
      this.externaldatasourceService.executeExternalDataSource(399, [this.pageSize, this.page +1, filterString])
      .then(result => {
        this.externalDataSourceResult = result;
        this.timelineResult = result.result;
        this.dataSource = new MatTableDataSource(result.result);
        this.numRecordsTask = result.numRecordsTask;
        this.numRecordsEvent = result.numRecordsEvent;
        this.numRecordsConversion = result.numRecordsConversion;
        this.dataSource.sort = this.sort;
        this.resultsLength = result.numRecords;
        this.loadingData = false;
        resolve(null);
        }).catch(error => reject(error));
    });
  }

  changeActivityLayer() {
    this.onlyContactAvtivitys = !this.onlyContactAvtivitys;
    this.getDataList();
  }

  toggleDetails(expandedElement,row){
    return (expandedElement != row) ? row : "undefined";

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  filterList(e, type, value) {
    this.commonService.toggleArrayItem(this.filterType, value);
    this.getDataList();
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.page = e.pageIndex;
    this.getDataList();
  }

  transformKeyToText(key) { //"Leadscoring.Event.Type."
   return this.axivasTranslateService.getTranslationTextForToken( key);
  }

  getEventTranslation(maileventType) {
    return this.axivasTranslateService.getTranslationTextForToken('ActivityList.Label.' + maileventType);
  }

  editConversion(element) {
    this.applicationInfoService.miscSettings['conversion-editor-conversion'] = element;
    this.eventService.showJjPopup('ConversionEditor.Label.Header', 'conversion-editor', '80');
  }
}
