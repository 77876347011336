import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { isNull } from 'util';
import { WizardService } from '../wizard/wizard.service';

@Component({
  selector: 'app-jj-popup',
  templateUrl: './jj-popup.component.html',
  styleUrls: ['./jj-popup.component.scss']
})
export class JjPopupComponent implements OnInit, OnDestroy {
  closeJjPopupEventSubscription: Subscription = new Subscription;
  showJjPopupEventSubscription: Subscription = new Subscription;
  lastId = 1;
  popups = [] ; // [{id: 1, controlId: 'dashboard'}];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
  ) { }

  ngOnInit() {
    this.closeJjPopupEventSubscription = this.eventService.closeJjPopupEvent.subscribe(event => {
      if (this.popups.length > 0) {        
        if (isNull(event)) {
          this.removePopupFromList(this.popups[this.popups.length - 1].id);
        } else {
          this.removePopupFromList(event);
        }
      }
    });

    this.showJjPopupEventSubscription = this.eventService.showJjPopupEvent.subscribe(event => {      
      this.popups.push({
        id: this.lastId,
        headerText: event.target,
        controlId: event.arguments[0],
        popupSize: event.arguments[1],
        isClosable: event.arguments[2] });
      this.lastId++;
    });
  }

  ngOnDestroy() {
    if (this.closeJjPopupEventSubscription) { this.closeJjPopupEventSubscription.unsubscribe(); }
    if (this.showJjPopupEventSubscription) { this.showJjPopupEventSubscription.unsubscribe(); }
  }

  removePopupFromList(popupId: any) {
    this.popups.forEach((popup, index) => {
      if (Number(popup.id) === popupId) {
        this.popups.splice(index, 1);
      }
    });
  }
}
