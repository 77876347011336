<div class="myTopContactsDashboard__wrapper">
    <div class="myTopContactsDashboard__header">
        <div class="myTopContactsDashboard__headerLabel">{{ 'WebsiteDashboard.Label.MyTopContacts' | texttransform }}</div>
    </div>
    <div class="myTopContactsDashboard__content">
        <div class="myTopContactsDashboardItems__header">
            <div class="myTopContactsDashboardItems__header__contact">
                {{ 'WebsiteDashboard.Label.ContactPerson' | texttransform }}
            </div>
            <div class="myTopContactsDashboardItems__header__leadscore">
                {{ 'WebsiteDashboard.Label.Leadscore' | texttransform }}
            </div>
        </div>
        <div class="myTopContactsDashboardItemOnlyAI">
            <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleSearchMode()" 
                *ngIf="applicationInfoService.myBestContactsOnlyAI == true"></svg-icon>
            <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleSearchMode()" 
                *ngIf="applicationInfoService.myBestContactsOnlyAI != true"></svg-icon>                    

            <!-- <input [(ngModel)]="applicationInfoService.myBestContactsOnlyAI" class="myTopContactsDashboardItemOnlyAI__input" type="checkbox" (change)="getDatashboardData()"> -->
            <div class="myTopContactsDashboardItemOnlyAI__label">{{'WebsiteDashboard.Label.OnlyAI' | texttransform}}</div>
        </div>
        <div *ngIf="loadingData == true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
        </div>
        <div class="myTopContactsDashboardItems" *ngIf="loadingData == false">           
            <div *ngFor="let contact of contacts" class="myTopContactsDashboardItem fingerCursor" (click)="loadContact(contact.contactid)">
                <div class="myTopContactsDashboardItem__icon">
                    <svg-icon src="assets/images/svg/dashboard_contact.svg" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
                </div>
                <div class="myTopContactsDashboardItem__content">
                    <div class="myTopContactsDashboardItem__content__item">
                        <div class="myTopContactsDashboardItem__content__item__text">{{ 'WebsiteDashboard.Label.AccountName' | texttransform }}</div>
                        <div class="myTopContactsDashboardItem__content__item__value">{{ contact.accountname }}</div>
                    </div>
                    <div class="myTopContactsDashboardItem__content__item">
                        <div class="myTopContactsDashboardItem__content__item__text">{{ 'WebsiteDashboard.Label.FirstName' | texttransform }}</div>
                        <div class="myTopContactsDashboardItem__content__item__value">{{ contact.firstname }}</div>
                    </div>
                    <div class="myTopContactsDashboardItem__content__item">
                        <div class="myTopContactsDashboardItem__content__item__text">{{ 'WebsiteDashboard.Label.LastName' | texttransform }}</div>
                        <div class="myTopContactsDashboardItem__content__item__value">{{ contact.lastname }}</div>
                    </div>
                    <div class="myTopContactsDashboardItem__content__item">
                        <div class="myTopContactsDashboardItem__content__item__text">{{ 'WebsiteDashboard.Label.EMail' | texttransform }}</div>
                        <div class="myTopContactsDashboardItem__content__item__value">{{ contact.email }}</div>
                    </div>
                    <div class="myTopContactsDashboardItem__content__item">
                        <div class="myTopContactsDashboardItem__content__item__text">{{ 'DashboardComponent.Label.Leadstate' | texttransform }}</div>
                        <div class="myTopContactsDashboardItem__content__item__value everleadCoral">{{ getLeadStateName(contact.leadstateid) }}</div>
                    </div>
                </div>
                <div class="myTopContactsDashboardItem__score">
                    <div class="myTopContactsDashboardItem__score__ai">                                                                     
                        <!-- <div *ngIf="contact.leadscore > getMinPepperoniValue()" class="myTopContactsDashboardItem__score__ai__available">
                            <svg-icon src="assets/images/svg/everlead_pepperoni.svg" [tippy]="'WebsiteDashboard.Label.EverleadLeadstatePepperoni' | texttransform" 
                                 [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
                        </div> -->
                        <div *ngIf="contact.aisuggestion == true || contact.aicontact == true || contact.leadscore > getMinPepperoniValue()" 
                            class="myTopContactsDashboardItem__score__ai__available"
                            [ngClass]="{ myTopContactsDashboardItem__aiAndPepperoni: contact.leadscore > getMinPepperoniValue() }">
                            <svg-icon src="assets/images/svg/ai/everlead_ai_thumbs_up2.svg" [tippy]="getAITitle(contact)"  
                                [svgStyle]="{ 'width.px': 60, 'height.px': 60 }"></svg-icon>
                        </div>
                    </div>
                    <div class="myTopContactsDashboardItem__score__leadscore">
                        <app-custom-leadscore [controlValue]="contact.leadscore"></app-custom-leadscore>
                    </div>
                </div>                
            </div>        
        </div>
    </div>
</div> 