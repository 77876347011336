<div [id]="dropdownGuid" style="width: calc(100% - 5px); max-width: 100%" class="customControl__dataLine" [ngClass]="{
    heightMax: !applicationInfoService.useNewDesign,
    heightNew: applicationInfoService.useNewDesign
}">    
    <select (change)="emitValueChange()" [disabled]="disabled" [(ngModel)]="controlValue" 
        class="customControl__dataLine__dataControl customTextControlWrapper__dropdown"
        [ngClass]="{ extendedDropdownPlaceholder: controlValue == null }"
    >
        <option [ngValue]=null>-</option>
        <option [ngValue]="selectItem[dataKey]" *ngFor="let selectItem of getDatalist(data)"
            [ngClass]="{ hideInDom: selectItem.isActive == false }">
            <span *ngFor="let arrayItem of valueArray">
                <div *ngIf="valueArray.length == 1">
                    <span *ngIf="selectItem.nameTranslationTokenId">
                        {{ getTranslationById(selectItem.nameTranslationTokenId, selectItem | objectdeserialize:arrayItem) | texttransform }}
                    </span>
                    <span *ngIf="!selectItem.nameTranslationTokenId">{{ selectItem | objectdeserialize:arrayItem | texttransform }} </span>
                </div>
                <div *ngIf="valueArray.length > 1">
                    <span *ngIf="commonService.isDate(selectItem[arrayItem])">{{ selectItem[arrayItem] | date: medium }} </span>
                    <span *ngIf="!commonService.isDate(selectItem[arrayItem])">{{ selectItem | objectdeserialize:arrayItem | texttransform }}</span>    
                </div>
            </span>            
        </option>
    </select>

    <div *ngIf="entityMemberlookupTable !== null && userService.hasPermission('CanUpdateUIControl')" 
        class="plusButton__wrapper" [title]="'Dropdown.Label.AddLookupTableMember' | texttransform"
        (click)="addValueToLookupTable()">
        <i class="fas fa-plus fingerCursor" [ngClass]="{ 
            darkMode: darkMode,
            'customTextControlWrapper__button': !darkMode
        }"></i>
    </div>
</div>