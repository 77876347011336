<div class="ringcentralWebRTCExtension__wrapper">
    <div class="ringcentralWebRTCExtension__toolbar">
        <select class="maxWidth ringcentralWebRTCExtension__toolbar__dropdown" [(ngModel)]="mode" (change)="modeChange()">
            <option [ngValue]=1>{{ 'RingCentralExtension.Label.Keyboard' | texttransform }}</option>
            <option [ngValue]=2>{{ 'RingCentralExtension.Label.CallHistory' | texttransform }}</option>
            <option [ngValue]=3>{{ 'RingCentralExtension.Label.Availability' | texttransform }}</option>
            <option [ngValue]=4 *ngIf="applicationInfoService.applicationSettings['isRecordingAllowed'] == 'true'">{{ 'RingCentralExtension.Label.RecordSettings' | texttransform }}</option>
        </select>
    </div>
    <div *ngIf="mode==1" class="ringcentralWebRTCExtension__session">        
        <div *ngIf="!ringcentralwebphoneService.session" class="ringcentralWebRTCExtension__keyPad">
            {{ 'RingCentralExtension.Label.KeypadRequiresActiveCall' | texttransform }}
        </div>
        <div *ngIf="ringcentralwebphoneService.session" class="ringcentralWebRTCExtension__keyPad">
            <div class="ringcentralWebRTCExtension__keyPad__inner">
                <button (click)="ringcentralwebphoneService.sendDTMF(1)" class="ringcentralWebRTCExtension__dtmfButton">1</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(2)" class="ringcentralWebRTCExtension__dtmfButton">2</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(3)" class="ringcentralWebRTCExtension__dtmfButton">3</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(4)" class="ringcentralWebRTCExtension__dtmfButton">4</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(5)" class="ringcentralWebRTCExtension__dtmfButton">5</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(6)" class="ringcentralWebRTCExtension__dtmfButton">6</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(7)" class="ringcentralWebRTCExtension__dtmfButton">7</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(8)" class="ringcentralWebRTCExtension__dtmfButton">8</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(9)" class="ringcentralWebRTCExtension__dtmfButton">9</button>
                <button (click)="ringcentralwebphoneService.sendDTMF('*')" class="ringcentralWebRTCExtension__dtmfButton">*</button>
                <button (click)="ringcentralwebphoneService.sendDTMF(0)" class="ringcentralWebRTCExtension__dtmfButton">0</button>
                <button (click)="ringcentralwebphoneService.sendDTMF('#')" class="ringcentralWebRTCExtension__dtmfButton">#</button>
            </div>
        </div>
    </div>

    <div *ngIf="mode==2" class="ringcentralWebRTCExtension__recentCalls">
        <div *ngIf="ringcentralwebphoneService.recentCalls" class="ringcentralWebRTCExtension__recentCalls__container">
            <div *ngFor="let recentCall of ringcentralwebphoneService.recentCalls.records" class="ringcentralWebRTCExtension__recentCalls__call">
                <div class="ringcentralWebRTCExtension__recentCalls__call__direction">
                    <ng-container *ngIf="recentCall.direction == 'Inbound'"><i class="fas fa-chevron-circle-right everleadGreen"></i></ng-container>
                    <ng-container *ngIf="recentCall.direction == 'Outbound'"><i class="fas fa-chevron-circle-left everleadCoral"></i></ng-container>
                </div>
                <div class="ringcentralWebRTCExtension__recentCalls__call__otherParty ellipsisText">
                    <ng-container *ngIf="recentCall.direction == 'Inbound'">
                        <ng-container *ngIf="recentCall.from.name">{{ recentCall.from.name }}</ng-container>
                        <ng-container *ngIf="!recentCall.from.name">{{ recentCall.from.phoneNumber }}</ng-container>                        
                    </ng-container>
                    <ng-container *ngIf="recentCall.direction == 'Outbound'">
                        <ng-container *ngIf="recentCall.to.name">{{ recentCall.to.name }}</ng-container>
                        <ng-container *ngIf="!recentCall.to.name">{{ recentCall.to.phoneNumber }}</ng-container>                                                
                    </ng-container>
                </div>
                <div class="ringcentralWebRTCExtension__recentCalls__call__date">{{ recentCall.startTime | date:'medium' }}</div>
                <div class="ringcentralWebRTCExtension__recentCalls__call__button">
                    <i class="fas fa-play fingerCursor" (click)="makeCall(recentCall)"></i>                    
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode==3" class="ringcentralWebRTCExtension__recentCalls">
        <div class="everleadCoral">
            {{ 'RingCentralExtension.Label.UserStatus' | texttransform }}
        </div>
        <div class="ringcentralWebRTCExtension__userStatus">
            <select [(ngModel)]="ringcentralwebphoneService.userState.userStatus" class="maxWidth ringcentralWebRTCExtension__toolbar__dropdown" (change)="ringcentralwebphoneService.updateUserState()">
                <option ngValue="Available">{{ 'RingCentralExtension.Label.StatusAvailable' | texttransform }}</option>
                <option ngValue="Busy">{{ 'RingCentralExtension.Label.StatusBusy' | texttransform }}</option>
                <option ngValue="Offline">{{ 'RingCentralExtension.Label.StatusOffline' | texttransform }}</option>
            </select>
        </div>
        <div class="everleadCoral">
            {{ 'RingCentralExtension.Label.DndStatus' | texttransform }}
        </div>
        <div>
            <select [(ngModel)]="ringcentralwebphoneService.userState.dndStatus" class="maxWidth ringcentralWebRTCExtension__toolbar__dropdown" (change)="ringcentralwebphoneService.updateUserState()">
                <option ngValue="TakeAllCalls">{{ 'RingCentralExtension.Label.DndStatusTakeAllCalls' | texttransform }}</option>
                <option ngValue="DoNotAcceptDepartmentCalls">{{ 'RingCentralExtension.Label.DndStatusDoNotAcceptDepartmentCalls' | texttransform }}</option>
                <option ngValue="TakeDepartmentCallsOnly">{{ 'RingCentralExtension.Label.DndStatusTakeDepartmentCallsOnly' | texttransform }}</option>
                <option ngValue="DoNotAcceptAnyCalls">{{ 'RingCentralExtension.Label.DndStatusDoNotAcceptAnyCalls' | texttransform }}</option>
                <option ngValue="Unknown">{{ 'RingCentralExtension.Label.DndStatusUnknown' | texttransform }}</option>
            </select>
        </div>
        <div class="everleadCoral ringcentralWebRTCExtension__callQueue__wrapper">
            {{ 'RingCentralExtension.Label.CallQueues' | texttransform }}
        </div>
        <div>
            <div *ngFor="let callQueue of ringcentralwebphoneService.callQueues" class="ringcentralWebRTCExtension__callQueue">
                <div class="everleadCoral"  class="ringcentralWebRTCExtension__callQueue__name ellipsisText">
                    {{ callQueue.callQueue.name}}
                </div>
                <!-- <div>
                    <select [(ngModel)]="callQueue.acceptCalls" class="maxWidth ringcentralWebRTCExtension__toolbar__dropdown" (change)="ringcentralwebphoneService.updateUserQueueState(callQueue)">
                        <option [ngValue]=true>{{ 'RingCentralExtension.Label.QueueStatusAccept' | texttransform }}</option>
                        <option [ngValue]=false>{{ 'RingCentralExtension.Label.QueueStatusDecline' | texttransform }}</option>
                    </select>
                </div> -->
            </div>
        </div>
    </div>

    <div *ngIf="mode==4" class="ringcentralWebRTCExtension__session">        
        <div *ngIf="!ringcentralwebphoneService.session" class="ringcentralWebRTCExtension__recordSettings">
            {{ 'RingCentralExtension.Label.RecordSettings' | texttransform }}
        </div>
        <div>
            <div  class="ringcentralWebRTCExtension__recordSettings__desc">
                {{ 'RingCentralExtension.Label.RecordSettingsDescription' | texttransform }}
            </div>
            <div class="ringcentralWebRTCExtension__recordSettings__buttons">
                <button class="everleadCustomButton maxWidth"  *ngFor="let recordingtype of ringcentralwebphoneService.recordingtypes" 
                    (click)="startRecord(recordingtype)">{{ recordingtype.defaultName }}</button>
            </div>    
        </div>
    </div>
</div>