<div class="aiPrompt__wrapper" *ngIf="userService.hasPermission('CanCreateAiPrompt')">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createAiPrompt()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>               
            {{ 'AIPrompts.Label.NewPrompt' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">            
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="aiPromptList">
        <div *ngFor="let aiPrompt of aiPrompts; let promptIndex = index" class="everleadShadowBox aiPrompt__item">
            <div class="aiPrompt__item__header">
                <div class="everleadCoral aiPrompt__item__id">{{ aiPrompt.id }}</div>
                <input [(ngModel)]="aiPrompt.defaultName" class="maxWidth">
                <div class="aiPrompt__item__header__delete">
                    <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteAiPrompt(aiPrompt)"  *ngIf="userService.hasPermission('CanDeleteAiPrompt')"></i>
                    <i class="fas fingerCursor" (click)="toggleItem(promptIndex)" [ngClass]="{
                        'fa-chevron-down': toggledItems[promptIndex] != true,
                        'fa-chevron-up': toggledItems[promptIndex] == true
                    }"></i>     
                </div>
            </div>    
            <div *ngIf="toggledItems[promptIndex] == true" class="aiPrompt__item__details">
                <div class="aiPrompt__item__row">
                    <input type="checkbox" [(ngModel)]="aiPrompt.isDefault" (change)="saveAiPrompt(aiPrompt)"><label>{{ 'AIPrompts.Label.IsDefault' | texttransform }}</label>            
                </div>
                <div class="aiPrompt__item__row">
                    <input type="checkbox" [(ngModel)]="aiPrompt.isActive" (change)="saveAiPrompt(aiPrompt)"><label>{{ 'AIPrompts.Label.IsActive' | texttransform }}</label>            
                </div>
                <div class="aiPrompt__item__row">
                    <label>{{ 'AIPrompts.Label.Model' | texttransform }}</label>
                    <select [(ngModel)]="aiPrompt.modelLookupId" (change)="saveAiPrompt(aiPrompt)">
                        <option *ngFor="let promptModel of promptModels" [ngValue]="promptModel.modelLookupId">{{ promptModel.defaultName }}</option>
                    </select>
                </div>
                <div class="aiPrompt__item__row">
                    <label>{{ 'AIPrompts.Label.Response' | texttransform }}</label>
                    <select [(ngModel)]="aiPrompt.responseLookupId" (change)="saveAiPrompt(aiPrompt)">
                        <option *ngFor="let lookupItem of commonService.getProjectLookupTableValues(87, 'central')" [ngValue]="lookupItem.id">{{ lookupItem.defaultName }}</option>
                    </select>
                </div>
                <div class="aiPrompt__item__row">
                    <label>{{ 'AIPrompts.Label.Prompt' | texttransform }}</label>
                    <select [(ngModel)]="aiPrompt.promptLookupId" (change)="saveAiPrompt(aiPrompt)">
                        <option *ngFor="let lookupItem of this.commonService.getProjectLookupTableValues(85, 'central')" [ngValue]="lookupItem.id">{{ lookupItem.defaultName }}</option>
                    </select>
                </div>
                <div class="aiPrompt__item__row">
                    <label>{{ 'AIPrompts.Label.Language' | texttransform }}</label>
                    <select [(ngModel)]="aiPrompt.languageId" (change)="saveAiPrompt(aiPrompt)">
                        <option *ngFor="let lookupItem of languages" [ngValue]="lookupItem.id">{{ lookupItem.languageName }}</option>
                    </select>
                </div>
                <div class="aiPrompt__item__promptRow">
                    <label>{{ 'AIPrompts.Label.PromptHeader' | texttransform }}</label>
                    <div class="aiPrompt__item__promptRow__text">
                        <div class="aiPrompt__item__promptRow__text__prompt">
                            <textarea [(ngModel)]="aiPrompt.prompt" (change)="saveAiPrompt(aiPrompt)"></textarea>
                        </div>
                        <div class="aiPrompt__item__promptRow__text__placeholder">
                            <div class="aiPrompt__item__promptRow__text__placeholder__list">                    
                                <div class="wizardEMail__placeholder" *ngFor="let placeholder of placeholders">
                                    <i class="fas fa-chevron-circle-left fingerCursor" (click)="addMemberToPrompt(placeholder, aiPrompt)"></i>
                                    {{ getPlaceHolderInfo(placeholder) }}
                                </div>    
                            </div>   
                        </div>
                    </div>
                </div>
                <div class="aiPrompt__item__settings">
                    <div class="aiPrompt__item__settings__header">{{ 'AIPrompts.Label.Settings' | texttransform }}</div>
                    <div class="aiPrompt__item__settings__line">
                        <div class="aiPrompt__item__settings__line__label">{{ 'AIPrompts.Label.Temperature' | texttransform }}</div>
                        <div class="aiPrompt__item__settings__line__value">
                            <input [(ngModel)]="aiPrompt.settingsArray.Temperature" (change)="saveAiPrompt(aiPrompt)" onkeydown="return false" step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)" type="number">
                        </div>
                    </div>
                    <div class="aiPrompt__item__settings__line">
                        <div class="aiPrompt__item__settings__line__label">{{ 'AIPrompts.Label.TopP' | texttransform }}</div>
                        <div class="aiPrompt__item__settings__line__value">
                            <input [(ngModel)]="aiPrompt.settingsArray.TopP" (change)="saveAiPrompt(aiPrompt)" onkeydown="return false" step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)" type="number">
                        </div>
                    </div>
                    <div class="aiPrompt__item__settings__line">
                        <div class="aiPrompt__item__settings__line__label">{{ 'AIPrompts.Label.MaxToken' | texttransform }}</div>
                        <div class="aiPrompt__item__settings__line__value">
                            <input [(ngModel)]="aiPrompt.settingsArray.MaxTokens" (change)="saveAiPrompt(aiPrompt)" type="number">
                        </div>
                    </div>
                    <div class="aiPrompt__item__settings__line">
                        <div class="aiPrompt__item__settings__line__label">{{ 'AIPrompts.Label.PresencePenalty' | texttransform }}</div>
                        <div class="aiPrompt__item__settings__line__value">
                            <input [(ngModel)]="aiPrompt.settingsArray.PresencePenalty" (change)="saveAiPrompt(aiPrompt)" onkeydown="return false" step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)" type="number">
                        </div>
                    </div>
                    <div class="aiPrompt__item__settings__line">
                        <div class="aiPrompt__item__settings__line__label">{{ 'AIPrompts.Label.FrequencyPenalty' | texttransform }}</div>
                        <div class="aiPrompt__item__settings__line__value">
                            <input [(ngModel)]="aiPrompt.settingsArray.FrequencyPenalty" (change)="saveAiPrompt(aiPrompt)" onkeydown="return false" step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)" type="number">
                        </div>
                    </div>
                    <div class="aiPrompt__item__settings__line">
                        <div class="aiPrompt__item__settings__line__label">{{ 'AIPrompts.Label.UseTemperature' | texttransform }}</div>
                        <div class="aiPrompt__item__settings__line__value">
                            <input [(ngModel)]="aiPrompt.settingsArray.UseTemperature" type="checkbox" (change)="saveAiPrompt(aiPrompt)">
                        </div>
                    </div>
                    <br>
                    <div class="aiPrompt__item__settings__header">{{ 'AIPrompts.Label.Image' | texttransform }}</div>
                    <div class="aiPrompt__item__settings__headersmall">{{ 'AIPrompts.Label.ImageDesc' | texttransform }}</div>
                    <div class="aiPrompt__item__settings__line">                        
                        <div class="aiPromptImage everleadShadowBox">
                            <div class="aiPromptImage__left">
                                <img [src]="aiPrompt.pictureData" class="aiPromptImagePreview" *ngIf="aiPrompt.pictureData != '' && aiPrompt.pictureData != undefined">
                                <i class="fa-4x far fa-image" *ngIf="aiPrompt.pictureData == '' || aiPrompt.pictureData == undefined"></i>
                            </div>
                            <div class="aiPromptImage__right">
                                <input type="file" #file accept="image/*" placeholder="Choose file" (change)="uploadLoadingImage($event, aiPrompt)" style="display:none;">
                                <div class="aiPromptImage__buttons">
                                    <button class="everleadCustomButton" (click)="removeLoadingImage(aiPrompt)"
                                        *ngIf="this.applicationInfoService.userAvatar != ''">{{ 'AIPrompts.Label.RemoveLoadingImage' | texttransform }}</button>
                                    <button class="everleadCustomButton" (click)="file.click()">{{ 'AIPrompts.Label.UploadLoadingImage' | texttransform }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    
                    <div>
                        <button class="everleadCustomButton" (click)="saveSettings(aiPrompt)">{{ 'AIPrompts.Label.SaveChanges' | texttransform }}</button>
                    </div>
                </div>        
            </div>
        </div>
    </div>
</div>