<div *ngIf="viewMode == 0">
    <div class="contactprotection" *ngIf="type !== 'delete'">
        <div class="contactprotection__inner">
        <div class="row">
            <div class="col-12">
                <div class="contactprotection__field" >
                    <label>{{'ContactProtection.Label.Contact' | texttransform}}</label>
                    <select [(ngModel)]="newContact.contactId">
                        <option [ngValue]="null" disabled>{{'ContactProtection.Label.SelectContact' | texttransform }}</option>
                        <option *ngFor="let contact of accountContactsArray" [ngValue]="contact.id">{{contact.firstName}} {{contact.lastName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="contactprotection__field">
                <label>{{'ContactProtection.Label.Medium' | texttransform}}</label>
                <select [(ngModel)]="newContact.mediumTypeId">
                    <option [ngValue]="null" disabled>{{'ContactProtection.Label.SelectMedium' | texttransform }}</option>
                    <option *ngFor="let definition of mediumDefinition" [ngValue]="definition.id">{{ !definition?.nameTranslationToken?.tokenFullName ? definition?.defaultName : definition.nameTranslationToken.tokenFullName | texttransform }}</option>
                </select>
                </div>
            </div>

            <div class="col-12">
                <div class="contactprotection__field">
                    <label>{{'ContactProtection.Label.Frequency' | texttransform}}</label>
                    <select [(ngModel)]="newContact.frequencyLookupId">
                        <option [ngValue]="null" disabled>{{'ContactProtection.Label.SelectFrequency' | texttransform }}</option>
                        <option *ngFor="let definition of intervalDefinition" [ngValue]="definition.id">{{definition.defaultName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="contactprotection__field">
                    <label>{{'ContactProtection.Label.Quantity' | texttransform}}</label>
                    <input type="number" [(ngModel)]="newContact.quantity">
                </div>
            </div>
            <div class="col-12" >
                <div class="contactprotection__field">
                    <label>{{'ContactProtection.Label.Comments' | texttransform}}</label>
                    <textarea [(ngModel)]="newContact.comment"></textarea>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="buttonsContainer">
                <button *ngIf="type==='add'" class="everleadCustomButton" (click)="createNewContact()">{{'ContactProtection.Label.AddContact' | texttransform}}</button>
                <button *ngIf="type==='update'" class="everleadCustomButton" (click)="updateContact()">{{'ContactProtection.Label.UpdateContact' | texttransform}}</button>
            </div>
        </div>
    </div>
    </div>

    <div class="contactprotection" *ngIf="type === 'delete'">
        <div class="deleteContainer">
            <span>{{'ContactProtection.Label.DeleteContactConfirm' | texttransform }}</span>
            <span>Please confirm you want to delete this entry</span>
            <!-- when query change -->
        <!--   <h3 *ngIf="deleteContact.contact.firstName || deleteContact.contact.lastName">{{ deleteContact.contact.firstName }} {{deleteContact.contact.lastName}}</h3>
            <h3 *ngIf="(!deleteContact.contact.firstName && !deleteContact.contact.lastName) && deleteContact.account.name1">{{ deleteContact.contact.firstName }} {{deleteContact.contact.lastName}}</h3> -->
            <div class="buttonsContainer">
                <button class="everleadCustomButton" (click)="deleteConfirm()">{{'ContactProtection.Label.Confirm' | texttransform}}</button>
            </div>
        </div>
        
    </div>
</div>


<div *ngIf="viewMode == 1">
    <div class="contactprotection" *ngIf="type !== 'delete'">
        <div class="contactprotection__inner">
        <div class="row">
            <div class="col-12">
                <div class="contactprotection__field">
                <label>{{'ContactProtection.Label.Medium' | texttransform}}</label>
                <select [(ngModel)]="project.mediumTypeId" [disabled]="type === 'update'">
                    <option [ngValue]="null" disabled>{{'ContactProtection.Label.Medium' | texttransform }}</option>
                    <option *ngFor="let definition of mediumDefinition" [ngValue]="definition.id">{{ !definition?.nameTranslationToken?.tokenFullName ? definition?.defaultName : definition.nameTranslationToken.tokenFullName | texttransform }}</option>
                </select>
                </div>
            </div>

            <div class="col-12">
                <div class="contactprotection__field">
                    <label>{{'ContactProtection.Label.Frequency' | texttransform}}</label>
                    <select [(ngModel)]="project.frequencyTypeId" [disabled]="type === 'update'">
                        <option [ngValue]="null" disabled>{{'ContactProtection.Label.Frequency' | texttransform }}</option>
                        <option *ngFor="let definition of intervalDefinition" [ngValue]="definition.id">{{definition.defaultName}}</option>
                    </select>
                </div>
            </div>
  
            <div class="col-12">
                <div class="contactprotection__field">
                    <label>{{'ContactProtection.Label.Quantity' | texttransform}}</label>
                    <input type="number" [(ngModel)]="project.quantity">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="buttonsContainer">
                <button *ngIf="type === 'add'" class="everleadCustomButton" (click)="createNewProjectProtection()">{{'ContactProtection.Label.AddProjectEntry' | texttransform}}</button>
                <button *ngIf="type === 'update'" class="everleadCustomButton" (click)="updateProjectProtection()">{{'ContactProtection.Label.UpdateProjectEntry' | texttransform}}</button>

            </div>
        </div>
    </div>
    </div>
    <div class="contactprotection" *ngIf="type === 'delete'">
        <div class="deleteContainer">
            <span>{{'ContactProtection.Label.DeleteContactConfirm' | texttransform }}</span>
            <!-- <span>Please confirm you want to delete this entry</span> -->
            <div class="buttonsContainer">
                <button class="everleadCustomButton" (click)="deleteProjectProtection()">{{'ContactProtection.Label.Confirm' | texttransform}}</button>
            </div>
        </div>
    </div>
</div>
