<div class="everleadMaxHeightOverflow">
<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">Debug</div>
    <div>
        <app-debugpanel></app-debugpanel>
    </div>
    <div>
        <br>
        Version: {{ applicationInfoService.versionNumber }}<br>
        ApplicationName: {{ applicationName }}
    </div>
</div>

<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">Verschiedenes</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Editiermodus aktivieren
        </div>
        <div class="userSettings__item__interaction">
            <button class="everleadCustomButton" (click)="changeDesignerMode()"
                [ngClass]="{ userSettingRed: applicationInfoService.designerMode }">Editiermodus</button>
        </div>
    </div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Neues Design aktivieren
        </div>
        <div class="userSettings__item__interaction">
            <button class="everleadCustomButton" (click)="changeNewDesignMode()"
                [ngClass]="{ jjButtonStyle__dark: applicationInfoService.useNewDesign }">Neues Design</button>
        </div>
    </div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Testumgebung anzeigen
        </div>
        <div class="userSettings__item__interaction">
            <button class="everleadCustomButton" (click)="changeTestEnvironmentMode()"
                [ngClass]="{ jjButtonStyle__dark: applicationInfoService.testEnvironment }">Testumgebung</button>
        </div>
    </div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            E-Mail Queue aktualisieren
        </div>
        <div class="userSettings__item__interaction">
            <button class="everleadCustomButton" (click)="updateEMailQueue()">Aktualisieren</button>
        </div>
    </div>
</div>

<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">Schema reload</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Central
        </div>
        <div class="userSettings__item__interaction">
            <button (click)="updateCentralSchema()" class="everleadCustomButton">Update Central Schema</button>
        </div>
    </div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Data
            <select [(ngModel)]="schemaReloadProjectId">
                <option *ngFor="let project of projects" [ngValue]="project.id">{{ project.projectName }}</option>
            </select>
        </div>
        <div class="userSettings__item__interaction">
            <button (click)="updateSchema()" class="everleadCustomButton">Update Data Schema</button>
        </div>
    </div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Data
            <select [(ngModel)]="deleteProjectId">
                <option *ngFor="let project of projects" [ngValue]="project.id">{{ project.projectName }}</option>
            </select>
        </div>
        <div class="userSettings__item__interaction">
            <button (click)="deleteProject()" class="everleadCustomButton">Delete project</button>
        </div>
    </div>
</div>

<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">Central</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Cache leeren (Custom)
            <select [(ngModel)]="cacheCentral">
                <!-- <option value="Entity">Entity</option>
                <option value="ExternalDataSource">ExternalDataSource</option>                
                <option value="LookupTable">LookupTable</option>
                <option value="Translation">Translation</option>
                <option value="UiControl">UiControl</option>                
                <option value="Project">Project</option>
                <option value="User">User</option>
                <option value="Workflow">Workflow</option> -->
                <option *ngFor="let centralCacheResetItem of centralCacheResetItems" [ngValue]="centralCacheResetItem">{{ centralCacheResetItem }}</option>          
            </select>
        </div>
        <div class="userSettings__custom">
            <input [(ngModel)]="cacheCentral" class="userSettings__custom__input">
            <button class="everleadCustomButton userSettings__button"
                (click)="updateCache(cacheCentral)">Aktualisieren</button>
        </div>
    </div>
</div>

<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">Data</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Cache leeren (Custom)
            <select [(ngModel)]="cacheData">
                <!-- <option value="Entity">Entity (GraphQL Data-Entity)</option>
                <option value="Report">Report</option>                
                <option value="LookupTable">LookupTable</option>       -->
                <option *ngFor="let dataCacheResetItem of dataCacheResetItems" [ngValue]="dataCacheResetItem">{{ dataCacheResetItem }}</option>          
            </select>
        </div>
        <div class="userSettings__custom">
            <input [(ngModel)]="cacheData" class="userSettings__custom__input">
            <button class="everleadCustomButton userSettings__button"
                (click)="updateDataCache(cacheData)">Aktualisieren</button>
        </div>
    </div>
    
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Data
            <select [(ngModel)]="schemaUnloadProjectId">
                <option *ngFor="let project of projects" [ngValue]="project.id">{{ project.projectName }}</option>
            </select>
        </div>
        <div class="userSettings__item__interaction">
            <button (click)="unloadSchema()" class="everleadCustomButton">Unload Data Schema</button>
        </div>
    </div>
</div>

<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">API - CENTRAL</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ apiUrl }}
        </div>
        <div class="userSettings__item__interaction">
            <button class="everleadCustomButton" (click)="resetCacheCentral()">CentralAPI - Repo Cache löschen</button>
            <button class="everleadCustomButton" (click)="openExternalSite('hasuraCentral')">Hasura</button>
            <button class="everleadCustomButton" (click)="openExternalSite('playgroundCentral')">Playground</button>
            <button class="everleadCustomButton" (click)="openExternalSite('portainer')">Portainer</button>
        </div>
    </div>
</div>

<div class="userSettings__wrapper isDeveloper" *ngIf="applicationInfoService.isDeveloper">
    <div class="userSettings__header">API - BACKEND</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ apiBackend }}
        </div>
        <div class="userSettings__item__interaction">
            <button class="everleadCustomButton" (click)="openExternalSite('hasuraData')">Hasura</button>
            <button class="everleadCustomButton" (click)="openExternalSite('playgroundData')">Playground</button>
            <button class="everleadCustomButton" (click)="reloadHasura()">HasuraReload</button>
        </div>
    </div>    
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            DoNotUntrack
        </div>
        <div class="userSettings__custom">
            <input [(ngModel)]="doNotUntrackValue" class="userSettings__custom__input">
            <button class="userSettings__custom__button everleadCustomButton"
                (click)="doNotUntrack()">Ausführen</button>
        </div>
    </div>    
</div>

<div class="userSettings__wrapper " *ngIf="userService.hasPermission('CanUpdateExpertMode')">
    <div class="userSettings__header">{{ 'UserSettings.Label.ExpertMode' | texttransform }}</div>
    <div class="userSettings__info">{{ 'UserSettings.Label.ExpertModeDescription' | texttransform }}</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ 'UserSettings.Label.ExpertModeActive' | texttransform }}
        </div>
        <div class="userSettings__item__interaction">
            <select [(ngModel)]="settingsService.userSettings['ExpertMode'].value"
                (change)="changeExpertMode()">
                <option value="true">{{ 'UserSettings.Label.ExpertModeOn' | texttransform }}</option>
                <option value="false">{{ 'UserSettings.Label.ExpertModeOff' | texttransform }}</option>
            </select>
        </div>
    </div>
</div>

<div class="userSettings__wrapper " *ngIf="userService.hasPermission('CanFakeUser')">
    <div class="userSettings__header">User faken</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            Zu fakender User:
        </div>
        <div class="userSettings__item__interaction">
            <select [(ngModel)]="applicationInfoService.user.fakeUserId"
                (change)="fakeUser(applicationInfoService.user.fakeUserId)" class="userSettings__dropdown">
                <option [ngValue]="null"></option>
                <option [ngValue]=user.id *ngFor="let user of userList">{{ user.userName }}</option>
            </select>
        </div>
    </div>
</div>

<div class="userSettings__wrapper">
    <div class="userSettings__header">{{ 'UserSettings.Label.GeneralSettings' | texttransform }}</div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ 'General._.Language' | texttransform }}
        </div>
        <div class="userSettings__item__interaction">
            <select [(ngModel)]="axivasTranslateService.currentLanguage" (change)="changeLanguage()">
                <option ngValue="de">Deutsch</option>
                <option ngValue="en">English</option>
            </select>
        </div>
    </div>

    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ 'RingCentral.Label.Extension' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" *ngIf="settingsService.userSettings['Extension3CX']">
            <input [(ngModel)]="settingsService.userSettings['Extension3CX'].value"
                (change)="settingsService.setUserSettingEx(settingsService.userSettings['Extension3CX'])">
        </div>
    </div>

    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ 'RingCentral.Label.UseWebRtc' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" >
            <input 
                [(ngModel)]="applicationInfoService.userSettings['useRingCentralWebRTC']" 
                (change)="settingsService.updateSecurityUserSettingsJson()"
                type="checkbox"
            >
        </div>    
    </div>
    <div class="userSettings__item" *ngIf="applicationInfoService.applicationSettings['showPhoneSelection'] == 'true'">
        <div class="userSettings__item__label">
            {{ 'RingCentral.Label.UseHardphone' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" >
            <input type="checkbox" [(ngModel)]="applicationInfoService.userSettings['ringCentralUseHardphone']" (change)="changeRingCentralHardphone()">
        </div>
    </div>
    
    <div class="userSettings__item" *ngIf="applicationInfoService.applicationSettings['showPhoneSelection'] == 'true'">
        <div class="userSettings__item__label">
            {{ 'RingCentral.Label.OwnNumber' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" >
            <select [(ngModel)]="applicationInfoService.userSettings[ringCentralOwnNumberSetting]"
                (change)="changeRingCentralNumber()">
                <option [ngValue]=null></option>
                <option *ngFor="let ownNumber of ringcentralwebphoneService.phoneNumbers" [ngValue]="ownNumber.phoneNumber">{{ ownNumber.phoneNumber }}</option>
            </select>
        </div>
    </div>
  

    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ 'UserSettings.Label.PageSize' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" *ngIf="settingsService.userSettings['MatTablePagesize']">
            <select [(ngModel)]="settingsService.userSettings['MatTablePagesize'].value"
                (change)="settingsService.setUserSettingEx(settingsService.userSettings['MatTablePagesize'])">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>
        </div>
    </div>
</div>

<div class="userSettings__wrapper userAvatar__wrapper">
    <div class="userSettings__header">{{ 'UserSettings.Label.Avatar' | texttransform }}</div>
    <div class="userSettings__item maxHeight">
        <div class="userSettings__item__label">
            <img [src]="applicationInfoService.userAvatar" class="userAvatar" *ngIf="applicationInfoService.userAvatar != 'none'">
            <div class="userAvatar__notFound" *ngIf="applicationInfoService.userAvatar == 'none'">
                <i class="fas fa-user-alt fa-10x"></i>
                <div>
                    {{ 'UserSettings.Label.NotUploadedYet' | texttransform }}
                </div>
            </div>            
        </div>
        <div class="userSettings__item__interaction maxHeight">
            <input type="file" #file accept="image/*" placeholder="Choose file" (change)="uploadAvatar($event)" style="display:none;">
            <div class="userAvatar__buttonWrapper">
                <button class="everleadCustomButton" (click)="removeAvatar()"
                    *ngIf="this.applicationInfoService.userAvatar != 'none'">{{ 'UserSettings.Label.RemoveAvatar' | texttransform }}</button>
                <button class="everleadCustomButton" (click)="file.click()">{{ 'UserSettings.Label.UploadAvatar' | texttransform }}</button>
            </div>
        </div>
    </div>
</div>

<div class="userSettings__wrapper" *ngIf="applicationInfoService.applicationSettings['showVipSettings'] == 'true'">
    <div class="userSettings__header">{{ 'Supervisor.Label.ManageVip' | texttransform }}</div>
    <div class="userSettings__info">{{ 'Vip.Label.VipDescription' | texttransform }}</div>
    <div class="userSettings__item">        
        <div class="userSettings__item__label">
            {{ 'UserSettings.Label.VipAlertFrequency' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" *ngIf="settingsService.userSettings['VipAlertFrequency']">
            <select [(ngModel)]="settingsService.userSettings['VipAlertFrequency'].value"
                (change)="settingsService.setUserSettingEx(settingsService.userSettings['VipAlertFrequency'])">
                <option value="none">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.None' | texttransform }}</option>
                <option value="direct">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Direct' | texttransform }}</option>
                <option value="daily">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Daily' | texttransform }}</option>
                <option value="weekly">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly' | texttransform }}</option>                
            </select>
        </div>
    </div>
    <div class="userSettings__item">
        <div class="userSettings__item__label">
            {{ 'UserSettings.Label.NormalVisitorAlertFrequency' | texttransform }}
        </div>
        <div class="userSettings__item__interaction" *ngIf="settingsService.userSettings['NormalVisitorAlertFrequency']">
            <select [(ngModel)]="settingsService.userSettings['NormalVisitorAlertFrequency'].value"
                (change)="settingsService.setUserSettingEx(settingsService.userSettings['NormalVisitorAlertFrequency'])">
                <option value="none">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.None' | texttransform }}</option>
                <option value="direct">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Direct' | texttransform }}</option>
                <option value="daily">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Daily' | texttransform }}</option>
                <option value="weekly">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly' | texttransform }}</option>                
            </select>
        </div>
    </div>

    <div class="userSettings__item">
        <div class="userSettings__item__label">
            
        </div>
        <div class="userSettings__item__interaction" *ngIf="settingsService.userSettings['Extension3CX']">
            <button class="everleadCustomButton" (click)="openVipSettings()">{{ 'UserSettings.Label.OpenVipSettings' | texttransform }}</button>
        </div>
    </div>
</div>
    <div class="applicationIds">{{ applicationInfoService.poolId }} / {{ applicationInfoService.campaingnModeId }} </div>
</div>