import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-required',
  templateUrl: './setting-required.component.html',
  styleUrls: ['./setting-required.component.scss']
})
export class SettingRequiredComponent implements OnInit {
  @Input() displayText: string = '*';
  
  constructor() { }

  ngOnInit(): void {
  }

}
