<div [ngStyle]="setControlStyle('control')">
    <button 
        (click)="copyRelatedItemsToClipboard()" 
        class="jjButtonColor jjButtonStyle"
        [disabled]="!isEnabled" 
        (focus)="callMethod('onfocus')" 
        [ngStyle]="setControlStyle('control')" 
        style="width:100%; white-space: normal; padding-top: 0px; margin-top: 0px"
    >
        Clipboard<i class="far fa-clipboard"></i>
    </button>
</div>