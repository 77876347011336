<div class="jobScheduleWrapper">
    <div class="projectSettings__header jobScheduleHeader">
        <div class="projectSettings__header__newItem">        
            <button (click)="createSchedule()" class="jjControlSubmenuButton" style="white-space: normal;"><i
                class="fas fa-plus-square"></i></button>
        </div>   
        <div class="projectSettings__header__interaction">  
            <div class="projectSettings__header__showOnlyActive">
                <input [(ngModel)]="showOnlyActive" type="checkbox">
                {{ 'CampaignDesign.Label.ShowOnlyActive' | texttransform }}            
            </div>           
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="jobScheduleSchedules">
        <div *ngFor="let schedule of adminJobsService.jobSchedules" class="everleadShadowBox adminJobScheduleContainer"
            [ngClass]="{ hideInDom: isFiltered(schedule)  || (schedule.isActive == false && showOnlyActive == true) }">
            <input type="checkbox" [(ngModel)]="schedule.isActive" class="jjDesignBorder" style="margin-left: 5px;"
                (click)="adminJobsService.saveSchedule(schedule);$event.stopPropagation()">
            <label class="adminJobScheduleLabel everleadCoral">{{ schedule.id }}</label>
            <input type="text" [(ngModel)]="schedule.defaultName" class="adminJobScheduleTextBox jjDesignBorder"
                (click)="$event.stopPropagation()" (change)="adminJobsService.saveSchedule(schedule)">

            <select [(ngModel)]="schedule.jobId" class="adminJobScheduleCombo" (change)="adminJobsService.saveSchedule(schedule)">
                <option [ngValue]=job.id *ngFor="let job of adminJobsService.jobs">{{ job.defaultName }} ({{job.id}})</option>
            </select>
            <i class="fas fa-trash everleadCoral adminJobScheduleDelete" (click)="removeItem(schedule)"></i>
        </div>
    </div>
</div>