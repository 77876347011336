<div [ngClass]="{ darkMode: darkMode }" class="maxHeight">
  <div class="component-container"
    [class.hovering]="isDragging"
    (click)="fileSelector.click()"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="stopDrag($event)"
    (dragend)="stopDrag($event)"  
  >  
    <div class="icon"><i class="fas fa-cloud-upload-alt"></i>{{ dragdroplabel | texttransform }}</div>
    <input #fileSelector type="file" multiple (change)="onFilesSelected($event.target.files)" hidden>
  </div>
</div>