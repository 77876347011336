<div class="errorMessage" *ngIf="errorVisible">
  <div class="upperError">
    <div class="errorInline errorLeft">
      <h4>{{ errorTitle | texttransform }}</h4>  
    </div>
    <div class="errorInline errorRight">      
      <button class="btn btn-danger" (click)="hideErrorMessage()">{{ 'FAQ' | texttransform }}</button>
      <button class="btn btn-danger" (click)="hideErrorMessage()">{{ 'X' | texttransform }}</button>
    </div>    
        
  </div>
  <div class="lowerError">
    {{ errorMessage | texttransform }}
  </div>
</div>