import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newactivity',
  templateUrl: './newactivity.component.html',
  styleUrls: ['./newactivity.component.scss']
})
export class NewactivityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
