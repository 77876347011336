<div class="container">
  <div class="row">
      <div class="col-md-12">
          <div class="error-template">
              <div class="error-details">
                Sie haben nicht die benötigten Berechtigungen um diesen Bereich zu sehen. Dies kann folgende Ursachen haben:<br>
                <br>
                <ul>
                  <li>Sie sind nicht für die Anwendung freigeschaltet.</li>
                  <li>Ihr Account ist gesperrt.</li>
                  <li>Das Passwort Ihres Accounts ist abgelaufen.</li>
                  <li>Ihre lokale Systemzeit ist nicht korrekt.</li>
                </ul>
                Bitte wenden Sie sich an IT-Help (ithelp&#64;axivas.com)!
                <hr>
                You do not have the required permissions to view this section. This can have the following causes:<br>
                <br>
                <ul>
                  <li>You are not unlocked for the application.</li>
                  <li>Your account is locked.</li>
                  <li>The password for your account has expired.</li>
                  <li>Your local system time is not correct.</li>
                </ul>
                Please contact IT-Help (ithelp&#64;axivas.com)!
                <hr>
                <small>Error 401</small>
              </div>
          </div>
      </div>
  </div>
</div>
