<div class="projectSettings__header">
    <div class="projectSettings__header__newItem fingerCursor" (click)="createNewMocoAssignment()">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
        {{ 'MocoDesign.Label.NewAssignment' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction">
        <div class="projectSettings__header__filter">
        </div>
    </div>
</div>

<div class="projectSettings__wrapper">    
    <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
        <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef>{{ 'MocoDesign.Label.Project' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">  
                <select [(ngModel)]="element.mocoProjectTaskId" class="mocoAssignmentDropdown" (change)="updateAssignment(element)">
                    <option *ngFor="let mocoProjectTask of getTaskstOfSpecificProject(element.mocoProjectId)" [ngValue]="mocoProjectTask.id">
                        {{ mocoProjectTask.name }}
                    </option>
                </select>    
            </td>
        </ng-container>

        <ng-container matColumnDef="project">
            <th mat-header-cell *matHeaderCellDef>{{ 'MocoDesign.Label.Activity' | texttransform }}</th>
            <td mat-cell *matCellDef="let element" class="mocoProject">            
                <select [(ngModel)]="element.mocoProjectId" (change)="changeProject(element)" class="mocoAssignmentDropdown">
                    <option *ngFor="let mocoProject of mocoProjects" [ngValue]="mocoProject.id">
                        {{ mocoProject.name }}
                    </option>
                </select> 
            </td>
        </ng-container>

        <ng-container matColumnDef="lookup">
            <th mat-header-cell *matHeaderCellDef>{{ 'MocoDesign.Label.Lookup' | texttransform }}</th>
            <td mat-cell *matCellDef="let element" class="poolIsActive">            
                <select [(ngModel)]="element.typeLookupId" class="mocoAssignmentDropdown" (change)="updateAssignment(element)">
                    <option *ngFor="let lookupValue of lookupValues" [ngValue]="lookupValue.id">
                        {{ lookupValue.defaultName }}
                    </option>
                </select>                     
            </td>
        </ng-container>
    
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="poolIsActive">            
                <i class="fas fa-trash fingerCursor floatRight" (click)="deleteAssignment(element)"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="row mocoAssignmentRow">
    <div class="col-md-8" *ngIf="loadingData == true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    <!-- <div class="col-md-8" *ngIf="loadingData == false">
        <h5>Bestehende Zuordnungen</h5>
        <div *ngFor="let assignment of assignments">
            <div  class="mocoAssignmentAssignedDiv row mocoAssignmentRow" *ngIf="assignment.campaignId == campaignId">
                <div class="col-md-4">
                    <select [(ngModel)]="assignment.mocoProjectId" (change)="getTasksOfMocoProject(assignment.mocoProjectId, assignment, true)" class="mocoAssignmentDropdown">
                        <option *ngFor="let mocoProject of mocoProjects" [ngValue]="mocoProject.id">
                            {{ mocoProject.name }}
                        </option>
                    </select>            
                </div>
                <div class="col-md-4">
                    <select [(ngModel)]="assignment.mocoProjectTaskId" class="mocoAssignmentDropdown" (change)="updateAssignment(assignment)">
                        <option *ngFor="let mocoProjectTask of assignmentTasks[assignment.id]" [ngValue]="mocoProjectTask.id">
                            {{ mocoProjectTask.name }}
                        </option>
                    </select>            
                </div>
                <div class="col-md-3">
                    <select [(ngModel)]="assignment.typeLookupId" class="mocoAssignmentDropdown" (change)="updateAssignment(assignment)">
                        <option *ngFor="let lookupValue of lookupValues" [ngValue]="lookupValue.id">
                            {{ lookupValue.defaultName }}
                        </option>
                    </select>                  
                </div>
                <div class="col-md-1">
                    <i class="fas fa-trash" (click)="deleteAssignment(assignment)"></i>
                </div>
            </div>
        </div>        
    </div> -->
    <!-- <div class="col-md-4">
        <h5>Neue Zuordnung anlegen</h5>
        <br>
        <br>
        Projektname
        <select [(ngModel)]="newTask.mocoProjectId" (change)="getTasksOfMocoProject(newTask.mocoProjectId)" class="mocoAssignmentDropdown">
            <option *ngFor="let mocoProject of mocoProjects" [ngValue]="mocoProject.id">
                {{ mocoProject.name }}
            </option>
        </select>
        <br>
        Task
        <select [(ngModel)]="newTask.mocoProjectTaskId" class="mocoAssignmentDropdown">
            <option *ngFor="let mocoProjectTask of mocoProjectTasks" [ngValue]="mocoProjectTask.id">
                {{ mocoProjectTask.name }}
            </option>
        </select>
        <br>
        Lookup
        <select [(ngModel)]="newTask.typeLookupId" class="mocoAssignmentDropdown">
            <option *ngFor="let lookupValue of lookupValues" [ngValue]="lookupValue.id">
                {{ lookupValue.defaultName }}
            </option>
        </select>      
        <br>
        <br>
        <button [disabled]="newTask.mocoProjectTaskId == null" class="jjButtonColor jjButtonStyle fingerCursor" 
            (click)="addAssignment()">Hinzufügen</button>
    </div> -->
</div>