<div class="paletteWrapper" [style.top.px]="getPaletteTop()" [ngClass]="{ 
    extended: applicationInfoService.paletteCollapsed == false,
    fullWidth: applicationInfoService.isMobile()
}">
    <div class="expander fingerCursor" (click)="togglePanel()" *ngIf="visibleSubControls.length > 0" [ngClass]="{
        hideInDom: applicationInfoService.isMobile() && applicationInfoService.paletteCollapsed == false
    }">
        <svg-icon src="assets/images/svg/toolbar/palette_expander.svg" class="fingerCursor paletteSvgExpanded" 
            [svgStyle]="{ 'width.px': 24, 'height.px': 20 }"></svg-icon>
    </div>
    <div id="paletteWrapperInner" class="paletteCurrentContent" [style.width.px]="getSelectedChildWith()">        
        <div class="paletteContentHeaderRow">
            <label class="paletteContentHeaderRow__label" *ngIf="selectedChild">
                {{ selectedChild['languageText' + axivasTranslateService.currentLanguage] | texttransform  }}                
            </label>     
            <i class="fas fa-edit fingerCursor" *ngIf="applicationInfoService.isDeveloper" (click)="editControl()"></i>
            <i class="fas fa-close fingercursor" *ngIf="applicationInfoService.isMobile()" (click)="togglePanel()"></i>
        </div>
        <div *ngIf="visibleSubControls.length > 1" class="paletteBackground">
            <select [(ngModel)]="selectedChild" (change)="getSelectedChildsInnerHTML()" class="maxWidth">
                <option *ngFor="let visibleSubControl of visibleSubControls" [ngValue]="visibleSubControl">
                    {{ visibleSubControl['languageText' + axivasTranslateService.currentLanguage] | texttransform }} 
                </option>
            </select>
        </div>
        <span id="paletteContentSpan"></span>
        <div class="paletteBackground" 
            [style.height]="getMaximumHeightOfSubContainer('paletteContentSpan')"
            [style.max-height]="getMaximumHeightOfSubContainer('paletteContentSpan')"
        >
            <div [innerHTML]="currentInnerHTML" class="paletteWrapper__contentContainer"></div>
        </div>
        
    </div>
</div>