import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-conversiondetail',
  templateUrl: './conversiondetail.component.html',
  styleUrls: ['./conversiondetail.component.scss']
})
export class ConversiondetailComponent implements OnInit {
  @Input() eventId = null;
  @Input() darkMode = false;
  eventData = [];

  constructor(
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getEventInfo();
  }

  getEventInfo() {
    if (this.eventId == null) {
      return;
    }

    this.loaderService.display(true);
    this.eventData = [];
    this.externaldatasourceService.executeExternalDataSource(520, [this.eventId])
    .then(result => {
      let jsonValue = result.transformedData.toString();
      jsonValue = jsonValue.replace(/\'/g, '"');
      jsonValue = JSON.parse(jsonValue);
      const keys = Object.keys(jsonValue);
      keys.forEach(key => {
        this.eventData.push({ name: key, value: jsonValue[key] });
      });
      this.loaderService.display(false);
    })
    .catch(error => {
      this.loaderService.display(false);
    });
  }
}
