
<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor">
        <label style="padding-left: 3px;" class="normalWhiteSpace" style="margin: 0 0 0 5px; padding-bottom: 0px;"
        [tippy]="'Import.Label.ImportStep1Description' | texttransform">{{ 'Import.Label.ImportedRecordsets' | texttransform }} ({{ resultImportedCount }})</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px"> 
        <button (click)="exportData(resultImported)" class="jjControlSubmenuButton" style="white-space: normal;"
            [tippy]="'Import.Label.ExportData' | texttransform"
        ><i class="fas fa-file-excel"></i></button>  

        <button (click)="pageUp(1)" class="jjControlSubmenuButton floatRight" style="white-space: normal;"
        [tippy]="'Import.Label.ExportData' | texttransform" [disabled]="checkPage(1, 'up')"
        ><i class="fas fa-caret-right"></i></button>  

        <span class="floatRight">{{ resultImportedPage }} / {{ resultImportedMaxPage }}</span>

        <button (click)="pageDown(1)" class="jjControlSubmenuButton floatRight" style="white-space: normal;"
        [tippy]="'Import.Label.ExportData' | texttransform" [disabled]="checkPage(1, 'down')"
        ><i class="fas fa-caret-left"></i></button>  
    </div>
</div>
<div *ngIf="result != null" style="overflow-x: auto">
    <table style="table-layout: fixed;" class="scroll">
        <thead>
        <tr>
            <th *ngFor="let header of result.headers; let i = index"
                class="importAssignmentTableDefDesign" [style.max-width.px]=200 [style.width.px]=200><label class="ellipsisText"
                    [tippy]=header.name style="margin: 0px;">{{ header.name }}</label></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dataItem of resultImportedPagination">
            <td *ngFor="let value of dataItem" [style.max-width.px]=200 [style.width.px]=200
                class="importAssignmentTableDefDesign">                
                <label class="ellipsisText" style="margin: 0px;" [tippy]=value>{{ value }}</label>
            </td>
        </tr>
    </tbody>
    </table>    
</div>
<br>
<br>    
<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor">
    <label style="padding-left: 3px;" class="normalWhiteSpace" style="margin: 0 0 0 5px; padding-bottom: 0px;"
            [tippy]="'Import.Label.ImportStep1Description' | texttransform">{{ 'Import.Label.NotImportedRecordsets' | texttransform }} ({{ resultNotImportedCount }})</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px"> 
        <button (click)="exportData(resultNotImported)" class="jjControlSubmenuButton" style="white-space: normal;"
            [tippy]="'Import.Label.ExportData' | texttransform"
        ><i class="fas fa-file-excel"></i></button>  
        <button (click)="pageUp(2)" class="jjControlSubmenuButton floatRight" style="white-space: normal;"
        [tippy]="'Import.Label.ExportData' | texttransform" [disabled]="checkPage(2, 'up')"
        ><i class="fas fa-caret-right"></i></button>  

        <span class="floatRight">{{ resultNotImportedPage }} / {{ resultNotImportedMaxPage }}</span>

        <button (click)="pageDown(2)" class="jjControlSubmenuButton floatRight" style="white-space: normal;"
        [tippy]="'Import.Label.ExportData' | texttransform" [disabled]="checkPage(2, 'down')"
        ><i class="fas fa-caret-left"></i></button>  

    </div>
</div>
<div *ngIf="result != null" style="overflow-x: auto">
    <table style="table-layout: fixed;" class="scroll">
        <thead>
        <tr>
            <th *ngFor="let header of result.headers; let i = index"
                class="importAssignmentTableDefDesign" [style.max-width.px]=200 [style.width.px]=200><label class="ellipsisText"
                    [tippy]=header.name style="margin: 0px;">{{ header.name }}</label></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dataItem of resultNotImportedPagination; i as index">
            <td *ngFor="let value of dataItem" [style.max-width.px]=200 [style.width.px]=200
                class="importAssignmentTableDefDesign">                
                <label  class="ellipsisText" style="margin: 0px;" [tippy]=value>{{ value }}</label>
            </td>
        </tr>
    </tbody>
    </table>    
</div>
