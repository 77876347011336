<div *ngIf="project">        
    <label  style="margin-right: 5px;">{{ 'GeneralSettings.Setting.IsUsingDataRights' | texttransform }}</label>
    <input type="checkbox" [(ngModel)]="project.isUsingDataRights" (change)="saveProjectInfo()" />
</div>

    <div class="dataRights__toolbar">        
        <div class="dataRights__toolbar__item everleadShadowBox">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="generalFilterString">
        </div>
        <div class="dataRights__toolbar__item everleadShadowBox">
            <i class="fas fa-user" [tippy]="'DataRights.Label.User' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
            <select [(ngModel)]="generalFilterUser">
                <option [ngValue]=null>{{'DataRights.Label.AllUsers' | texttransform }}</option>
                <option *ngFor="let user of users" [ngValue]="user.id">{{ user.userName }}</option>
            </select>
        </div>
        <div class="dataRights__toolbar__item everleadShadowBox">            
            <i class="fas fa-hand-paper" *ngIf="generalFilterAccessRights == null"[tippy]="'DataRights.Label.AccessLevel' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
            <i class="fas fa-eye" *ngIf="generalFilterAccessRights == true" [tippy]="'DataRights.Label.AccessLevel' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
            <i class="fas fa-feather" *ngIf="generalFilterAccessRights == false" [tippy]="'DataRights.Label.AccessLevel' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
            <select [(ngModel)]="generalFilterAccessRights">
                <option [ngValue]=null>{{'DataRights.Label.AllAccessLevels' | texttransform }}</option>
                <option [ngValue]=true>{{'DataRights.Label.AccessLevelOnlyRead' | texttransform }}</option>
                <option [ngValue]=false>{{'DataRights.Label.AccessLevelOnlyFullAccess' | texttransform }}</option>
            </select>
        </div>
        <div class="dataRights__toolbar__item everleadShadowBox fingerCursor" (click)="expandAll = !expandAll">           
            <i class="fas fa-expand-arrows-alt"></i>
            {{'DataRights.Label.ExpandAll' | texttransform }}
        </div>
    </div>

    <div class="dataRightsReminder" *ngIf="applicationInfoService.isDeveloper">
        {{'DataRights.Label.AdminReminder' | texttransform }}
    </div>

<div *ngIf="getDataRightGroupsForParent().length != 0" class="dataRights__listHeader">
    <div class="dataRights__listHeader__item">
        {{'DataRights.Label.DataGroup' | texttransform }}
    </div>
    <div class="dataRights__listHeader__item dataRights__listHeader__item__right">
        {{'DataRights.Label.AdditionalInfo' | texttransform }}
    </div>
</div>

<div class="dataRights__toolbar dataRights__toolbar__sublevel__item" *ngIf="generalFilterUser == null && generalFilterString == ''">
    <div class="dataRights__toolbar__newGroup fingerCursor" (click)="createDataRightsGroup()">
        <i class="fas fa-plus"></i>             
        {{'DataRights.Label.CreateNewGroup' | texttransform }}
    </div>    
</div>

<div class="dataRights__toolbar dataRights__toolbar__sublevel__item"  *ngIf="generalFilterUser != null || generalFilterString != ''">
    <div class="dataRights__toolbar__newGroup">
        {{'DataRights.Label.CantCreateNewGroup' | texttransform }}
    </div>    
</div>

<div class="dataRights__wrapper">
    <div *ngIf="getDataRightGroupsForParent().length == 0">    
        {{'DataRights.Label.NoGroupsYet' | texttransform }}
    </div>

    <div class="dataRights__body">
        <div *ngFor="let dataRightGroup of getDataRightGroupsForParent()" class="dataRights__body__spacer">
            <app-datarights-item
                [dataRightGroup]="dataRightGroup"
                level=0
                [availableUsers]="users"
                [availableFilters]="availableFilters"
                [expandAll]="expandAll"
                [generalFilterString]="generalFilterString"
                [generalFilterUser]="generalFilterUser"
                [generalFilterAccessRights]="generalFilterAccessRights"
            ></app-datarights-item>
        </div>
    </div>
</div>