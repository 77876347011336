import { Component, OnInit } from '@angular/core';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-breadcrumbandmenu',
  templateUrl: './breadcrumbandmenu.component.html',
  styleUrls: ['./breadcrumbandmenu.component.scss']
})
export class BreadcrumbandmenuComponent implements OnInit {
  searchActive = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    private settingsService: SettingsService,
    private awsCognitoService: AwsCognitoService,
    private eventService: EventService,
    public commonService: CommonService,
    private methodService: MethodService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
  }

  checkIfTippIsAvailable() {
    return this.commonService.getApplicationSectionContentTipp(window.location.href.toString())
  }

  getUserNameShortCut(user) {
    let userShortCut = '';
    if (user == null) { return ''; }
    if (user.firstName != null) {
      userShortCut = userShortCut.concat(user.firstName.substring(0,1))
    }
    if (user.lastName != null) {
      userShortCut = userShortCut.concat(user.lastName.substring(0,1))
    }    
    return userShortCut;
  }

  logout() {
    this.awsCognitoService.logoutUserFromCognito();
  }

  changeExpertMode() {
    if (this.applicationInfoService.expertMode == true) {
      this.applicationInfoService.expertMode = false;
      this.settingsService.userSettings['ExpertMode'].value = 'false'
    } else {
      this.applicationInfoService.expertMode = true;
      this.settingsService.userSettings['ExpertMode'].value = 'true'
    }
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['ExpertMode'])
  }

  showMailbox() {
    this.eventService.showJjPopup('UserSettings.Label.Mailbox', 'mailbox', 90);
  }

  showHelpContent() {
    this.eventService.customEvent.emit('showHelp')
  }

  getModeText(){
    return (this.applicationInfoService.designerMode ? "Designermodus deaktivieren" : "Designermodus aktivieren" )
  }
  
  changeDesignerMode() {
    this.applicationInfoService.changeDesignerMode();
  }

  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }    
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }

  showUserSettings() {
    this.applicationInfoService.routerHeaderLabelText = 'General.Menu.Settings';
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'usersettings']);
    // this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/usersettings']);
    if (this.applicationInfoService.isMobile()) {
      this.applicationInfoService.navigationExpandedMobile = false;
    }
  }

    handleQuickSearchKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.searchForValue();
    }
  }

  searchForValue() {
    if (this.applicationInfoService.quickSearchValue === '' || this.searchActive) {
      return;
    }
    // console.warn('searchForValue', this.applicationInfoService.quickSearchValue);
    this.searchActive = true;
    this.eventService.showJjPopup(this.axivasTranslateService
      .getTranslationTextForToken('General._.QuickSearch'), 'quicksearch', '80', true);
    setTimeout(() => this.searchActive = false, 1000);
  }
}
