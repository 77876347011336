import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationInfoService } from '../../../../core/application/application-info.service';
import { Table } from 'primeng/table';
import { GraphQLService, ApolloMethod } from '../../../../shared/service/graphql.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { SettingsService } from 'app/shared/service/settings.service';

@Component({
  selector: 'app-projectselection',
  templateUrl: './projectselection.component.html',
  styleUrls: ['./projectselection.component.scss']
})
export class ProjectselectionComponent implements OnInit {
  controlSelection: any = null;
  customerDropDownSelection: any = null;
  headerText = '';
  currentMode = '';
  campaigns = null;
  allCampaigns = null;
  showOnlySettings = false;
  specialRole = null;

  @ViewChild(Table) dt: Table;

  private popUpWidth = 0;

  projectData: any[] = [];
  customerData: any[] = [];
  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    public graphQLService: GraphQLService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private projectService: ProjectService,
    public userService: UserService,
    private commonService: CommonService,
    private settingService: SettingsService
  ) { }

  ngOnInit() {
    this.headerText = '';
    const showProjectSettings = this.applicationInfoService.miscSettings['showProjectSettings'];
    if (showProjectSettings == true) {
      this.applicationInfoService.miscSettings['showProjectSettings'] = null;
      this.showOnlySettings = true;
    }

    if (this.showOnlySettings) {
      if (this.applicationInfoService.projectSelected) {
        this.externaldatasourceService.executeExternalDataSource(269, [])
        .then(result => {
          this.campaigns = result;
        });
        this.externaldatasourceService.executeExternalDataSource(123, [])
        .then(result => {
          this.allCampaigns = result;
        });
      }  
    }    
  }

  closePopup() {
    if (this.applicationInfoService.useNewDesign) {
      this.eventService.closeJjPopup();
    } else {
      this.eventService.closeJjPopup();
    }
  }
  
  setSessionItem(setting, value) {
    this.eventService.updateControlAttributesEvent.emit(null);    
    this.commonService.setSessionStorageItem(setting, value);
    if (setting == 'lastSelectedPool') {
      this.eventService.customEvent.emit({ id: 'selectedPoolChanged' });
      this.applicationInfoService.userSettings['lastSelectedPool'.concat(this.applicationInfoService.projectID.toString())] = value;
    }        
    if (setting == 'lastSelectedCampaignId') {
      this.applicationInfoService.userSettings['lastSelectedCampaignId'.concat(this.applicationInfoService.projectID.toString())] = value;
    }    
    this.settingService.updateSecurityUserSettingsJson();
  }

  changeSpecialRole() {
    this.applicationInfoService.currentUserRoleId = this.specialRole.roleId;
    this.applicationInfoService.campaingnModeId = this.specialRole.roleId;
    this.applicationInfoService.poolId = this.specialRole.poolId;
    this.userService.getUserPermissions();
    this.eventService.updateControlAttributesEvent;
  }

  changeUserRole() {
    this.userService.getUserPermissions().then(() => {
      this.eventService.customEvent.emit({ id: 'userRoleChanged' });
      this.eventService.customEvent.emit({ id: 'loadJsonNavigationAfterRoleChange' });  
    });    
  }

  changeUserGroup() {
    const userGroup = this.applicationInfoService.availableUserGroups.find(group => group.id == this.applicationInfoService.currentUserGroup);
    if (userGroup && this.applicationInfoService.applicationSettings['useCampaignModeFilter'] == 'true') {
      this.applicationInfoService.campaingnModeId = userGroup.campaignId;
    }
  }

  userGroupHasCampaignId() {
    if (this.applicationInfoService.applicationSettings['useCampaignModeFilter'] != 'true') {
      return false;
    }
    
    const userGroup = this.applicationInfoService.availableUserGroups.find(group => group.id == this.applicationInfoService.currentUserGroup);
    if (userGroup) {
      if (userGroup.campaignId == null) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
