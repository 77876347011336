<svg-icon src="assets/images/svg/account_factory.svg"  style="display:  none;"
[svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>

<div class="dashboardcomponentMyDay__wrapper">
    <div class="websiteDashboardHeaderLabel ">{{ 'WebsiteDashboard.Label.MyDay' | texttransform }}</div>
    <div class="websiteDashboard__myday__content everleadShadowBox">
        <div class="websiteDashboard__myday__contentheader">
            <div class="websiteDashboard__myday__contentheader__item fingerCursor" (click)="showMyFollowUps()">
                {{ 'Agent.Menu.MyFollowUps' | texttransform }}
            </div>
            <div class="websiteDashboard__myday__contentheader__item">
                <select [(ngModel)]="myDayContentFilter"(change)="myDayContentFilterChanged()" class="maxWidth">
                    <option [ngValue]="1">{{ 'WebsiteDashboard.Label.All' | texttransform }}</option>
                    <option [ngValue]="2">{{ 'WebsiteDashboard.Label.OnlyToday' | texttransform }}</option>
                    <option [ngValue]="3">{{ 'WebsiteDashboard.Label.OnlyFuture' | texttransform }}</option>
                    <option [ngValue]="4">{{ 'WebsiteDashboard.Label.OnlyInthePast' | texttransform }}</option>
                </select>
            </div>
        </div>
        <div class="websiteDashboard__myday__contentlist">
            <app-mydayappointment [followUp]="followUp" *ngFor="let followUp of myFollowUpsToday"  [ngClass]="{ hideInDom: myDayContentFilter != '2' && myDayContentFilter != '1' }"
            class="fingerCursor" (click)="loadFollowUp(followUp)"></app-mydayappointment>
            <app-mydayappointment [followUp]="followUp" *ngFor="let followUp of myFollowUpsPast" [ngClass]="{ hideInDom: myDayContentFilter != '4' && myDayContentFilter != '1' }"
            class="fingerCursor" (click)="loadFollowUp(followUp)"></app-mydayappointment>
            <app-mydayappointment [followUp]="followUp" *ngFor="let followUp of myFollowUpsFuture" [ngClass]="{ hideInDom: myDayContentFilter != '3' && myDayContentFilter != '1' }"
            class="fingerCursor" (click)="loadFollowUp(followUp)"></app-mydayappointment>
        </div>        

        <div class="websiteDashboard__myday__contentbottom">
            <div class="websiteDashboard__myday__contentbottom__item">
                <button class="everleadCustomButton" (click)="startWorking()">{{ 'Button.WelcomePage.StartWork' |
                    texttransform }}</button>
            </div>
        </div>
    </div>
</div>
