<div [ngStyle]="setControlStyle('control')" class="compactListWrap compactListWrapNew noScrollbar"
  style="border-style: solid; border-width: 0px 1px 1px 1px;" [id]="getMatName(controlDefinition.id, 'compactList')">
  <div [ngStyle]="setCompactListInnerContainerHeight()" class="compactListWrapNew noScrollbar" style="overflow-x: hidden;">
    <div class=" jjCompactListHeader" style="padding: 1px; white-space: nowrap;"
      *ngIf="filterEnabled" [id]="getMatName(controlDefinition.id, 'headerDiv')">

      <!-- Header -->
      <label style="font-weight: bold; width: 60%; padding-left: 2px;" *ngIf="controlDefinition.displayText!=null"
        class="ellipsisText" title="{{ controlDefinition.displayText | texttransform }}" (click)="showData()">
        {{ controlDefinition.displayText | texttransform }}</label>        
        <!-- General filter -->
      <input [id]="getFilterInputId()" type="text" [(ngModel)]="generalFilter" (change)="filterData(generalFilter)"
        [ngClass]="{ hideInDom: showFilterInput==false}" style="width: 25%;" class="floatRight"
        style="border: 0px; height: 100%">
      <button class=" floatRight" style="border-width: 0px; cursor: default; height: 100%; background-color: transparent;"
        (click)="showFilter()">
        <i class="fas fa-filter" [ngClass]="{'fa-2x': applicationInfoService.useNewDesign }"></i>
      </button>

    </div>
    <div class=" jjCompactListHeader" style="padding: 1px; white-space: nowrap;"
      *ngIf="paginationEnabled" [id]="getMatName(controlDefinition.id, 'paginationDiv')">
      <span><i class="fas fa-database" title="{{ 'CompactList.Label.Recordsets' | texttransform }}" [ngClass]="{'fa-2x': applicationInfoService.useNewDesign }"></i>
        {{ dataCount }}</span>
      <span style="margin-left: 3px;" *ngIf="filteredDataCount != dataCount">
        <i class="fas fa-filter" style="color:red;" title="{{ 'CompactList.Label.FilteredRecordsets' | texttransform }}" [ngClass]="{'fa-2x': applicationInfoService.useNewDesign }"></i>
        {{ filteredDataCount }}</span>
      <span class="floatRight">
        <i class="fas fa-file-excel" style="margin-right: 5px;" (click)="compactListDataService.exportData(controlDefinition, filteredItemsDisplay)"
        title="{{ 'CompactList.Label.Export' | texttransform }}" [ngClass]="{'fa-2x': applicationInfoService.useNewDesign }"></i>
        {{ 'CompactList.Label.EntriesPerPage' | texttransform }}
        <select [(ngModel)]="paginationItemsPerPage" class="everleadCoral" style="height: 100%; margin-right: 5px; border: 0px solid"
          (change)="paginationResetStartPage()">
          <option [ngValue]=1>1</option>
          <option [ngValue]=10>10</option>
          <option [ngValue]=20>20</option>
          <option [ngValue]=50>50</option>
          <option [ngValue]=100>100</option>
          <option [ngValue]=250>250</option>
          <option [ngValue]=500>500</option>
          <option [ngValue]=1000>1.000</option>
          <option [ngValue]=3000>3.000</option>
        </select>
        <i class="fas fa-caret-left" style="margin-right: 5px;"
          [ngClass]="{'isDisabled': isPaginationMoveLeftDisabled()==true, 'fa-lg': applicationInfoService.useNewDesign }" (click)="paginationMoveLeft()"></i>
        {{ paginationCurrentPage }} / {{ paginationGetMaxPages() }}
        <i class="fas fa-caret-right" style="margin-left: 5px; margin-right: 5px;" (click)="paginationMoveRight()"
          [ngClass]="{'isDisabled': isPaginationMoveRightDisabled()==true, 'fa-lg': applicationInfoService.useNewDesign }"></i>
      </span>
    </div>
    <span *ngIf="loadingControlData==true">
      <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
    <span *ngIf="loadingControlData==false">
      <div [id]="getMatName(controlDefinition.id)" style="overflow-x: auto; overflow-y: hidden; width: 100%" [style.height.px]="getTableHeightInPixel(1, 'table')">
        <table class="fixed_header" [style.height.px]="getTableHeightInPixel(9, 'table')"
          [style.width.px]="getMaxTableWidth()">

          <thead [id]="getMatName(controlDefinition.id, 'thead')">
            <tr class="jjControlSubmenuColor">
              <th *ngIf="selectCheckbox" [style.width.px]="getColumnWidth('15px')">
                <input [(ngModel)]="allItemsSelected" type="checkbox" (click)="selectAllRows()">
              </th>
              <th *ngFor="let child of controlDefinition.childs; index as i" class="controlInlineBlock"
                [style.width.px]="getColumnWidth(child.width, 0 , i)" (click)="sortList(i)"
                [ngClass]="{ hideInDom: child.isActive == false}"
              >
                <label [ngStyle]="getLabelDimension(child)" [style.width.px]="getColumnWidth(child.width, 15)"
                  class="noOverflow ellipsis" [tippy]="child.displayText | texttransform">
                  <span *ngIf="child.nameTranslationToken == null">{{ child.displayText | texttransform }}</span>
                  <span *ngIf="child.nameTranslationToken != null">{{ child.nameTranslationToken?.tokenFullName | texttransform }}</span>
                  <i style="width: 10px; margin-left: 3px;" class="fas" [ngClass]="{
                    'fa-sort': sortingArray[i] == null,
                    'fa-sort-up': sortingArray[i] == false,
                    'fa-sort-down': sortingArray[i] == true
                  }"></i>
                </label>
              </th>
            </tr>

            <tr>
              <th [ngClass]="{ hideInDom: showFilterInput==false}" *ngIf="selectCheckbox"
                [style.width.px]="getColumnWidth('15px')">
              </th>
              <th [ngClass]="{ hideInDom: showFilterInput==false || child.isActive == false}"
                *ngFor="let child of controlDefinition.childs; index as i"
                [style.width.px]="getColumnWidth(child.width)">
                <input [ngStyle]="getLabelDimension(child)" [style.width.px]="getColumnWidth(child.width, 2)"
                  [id]="getMatName(i, 'filterInput')" [(ngModel)]="filterValueArray[i]"
                  (change)="filterListWidthColumnHeaderFilter()" style="margin: 0px" [disabled]="!child.isFieldFilterable">
              </th>
            </tr>
          </thead>
          <tbody [style.height.px]="getBodyHeightInPixel()" style="overflow-y: auto;" class="firefoxScrollbar">
            <tr *ngFor="let dataItem of filteredItemsDisplay; index as dataItemIndex"
              [ngClass]="{ 'fingerCursor': controlDefinition.methods.length > 0,
                hideInDom: (dataItemIndex >= pageStartItem && dataItemIndex < pageStartItem + paginationItemsPerPage || externalDatasourcePagination) == false
              }">
              <span *ngIf="dataItemIndex >= pageStartItem && dataItemIndex < pageStartItem + paginationItemsPerPage || externalDatasourcePagination">                
                <td *ngIf="selectCheckbox" [style.width.px]="getColumnWidth('15px')">
                  <input [ngModel]="checkIfItemIsSelected(dataItem)" type="checkbox" (click)="selectRow(dataItem)">
                </td>
                <td *ngFor="let child of controlDefinition.childs; index as i" [style.height.px]="getTdHeight()" [style.width.px]="tdWidthArray[i]"
                  [style.max-width.px]="tdWidthArray[i]" [ngClass]="{ hideInDom: child.isActive == false}" 
                  valign="top" (click)="cellClickedEvent(dataItem)" (dblclick)="cellDblClickedEvent(dataItem)">
                  <span *ngIf="userService.getControlPermissionByType(child.childs[0].uiControlSecurityPermissions, true, 'visible')==true">
                    <span id="1" *ngIf="child.childs[0]?.controlType==10" style="text-align: center; display: block;"
                      [style.max-width.px]="tdWidthArray[i]">
                      <button (click)="callChildClick(child.childs[0], dataItem, dataItemIndex);$event.stopPropagation()" class="fingerCursor" 
                        style="border: 0px; background: transparent;" [tippy]="child.childs[0].tooltip | texttransform"
                        [disabled]="userService.getControlPermissionByType(child.childs[0].uiControlSecurityPermissions, true, 'enabled')==false">
                        <i [class]="child.childs[0].additionalCssClasses"></i>
                      </button>
                    </span>
                    <span id="2" *ngIf="child.childs[0]?.controlType==23" style="text-align: center; display: block;"
                      [style.width.px]="getColumnWidth(child.width)" [style.max-width.px]="tdWidthArray[i]">
                      <i [class]="dataItem[child.childs[0].value]"
                        (click)="callChildClick(child.childs[0], dataItem, dataItemIndex);$event.stopPropagation()"></i>
                    </span>
                    <span id="3" *ngIf="child.childs[0]?.controlType==null || child.childs[0]?.controlType==99">
                      <label [ngClass]="{ 'fingerCursor': controlDefinition.methods.length > 0}" class="noScrollbar ellipsis"
                        [tippy]="dataItem[i]"
                        [style.max-width.px]="tdWidthArray[i]" [innerHTML]="dataItem[i]"
                        style="padding-left: 2px; margin: 0px; width: 100%; overflow-y: hidden;"></label></span>
                        <span id="4" *ngIf="child.childs[0]?.controlType==1">
                          <label [ngClass]="{ 'fingerCursor': controlDefinition.methods.length > 0}" class="noScrollbar ellipsis"
                            [tippy]="dataItem[i]"
                            [style.max-width.px]="tdWidthArray[i]" 
                            [innerHTML]="compactListDataService.formatText(1, dataItem[i])"
                            style="padding-left: 2px; margin: 0px; width: 100%; overflow-y: hidden;"></label></span>
                  </span>
                </td>
              </span>
            </tr>
          </tbody>
        </table>
      </div>
     
    </span>
  </div>

  