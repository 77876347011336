<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="createData()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>         
        Neuer Wert
    </div>
    <div class="projectSettings__header__interaction">
    </div>
</div>

<table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
    <ng-container matColumnDef="id" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>id</th>
        <td mat-cell *matCellDef="let element">  
            <label>{{ element.id }}</label>
        </td>
    </ng-container>
    <ng-container matColumnDef="method" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>method</th>
        <td mat-cell *matCellDef="let element">  
            <select [(ngModel)]="element.method" (change)="updateData(element)">
                <option *ngFor="let methodType of methodTypesArray" [ngValue]="methodType.name">{{ methodType.id }}</option>
            </select>
        </td>
    </ng-container>
    <ng-container matColumnDef="index" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>index</th>
        <td mat-cell *matCellDef="let element">  
            <input [(ngModel)]="element.index" (change)="updateData(element)" type="number">
        </td>
    </ng-container>
    <ng-container matColumnDef="informationtext" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>informationText</th>
        <td mat-cell *matCellDef="let element">  
            <input [(ngModel)]="element.informationText" (change)="updateData(element)" class="maxWidth">
        </td>
    </ng-container>
    <ng-container matColumnDef="optional" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>optional</th>
        <td mat-cell *matCellDef="let element">  
            <input [(ngModel)]="element.optional" (change)="updateData(element)" type="checkbox" style="margin-left: 5px">
        </td>
    </ng-container>
    <ng-container matColumnDef="delete" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="scoringParameter__delete">  
            <i class="fas fa-trash fingerCursor" (click)="deleteData(element)"></i>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
