
<div class="multilist__wrapper" [ngClass]="{ darkMode: settingJSON?.controlSettingGeneralDarkMode == true }">
    <app-documentation-link [infoLink]="documentationPage"></app-documentation-link>
    <div class="multilist__description">
        {{ controlDefinition.displayText | texttransform }}
    </div>
    <div class="multilist__header">
        <div class="multilist__header__label">
            <i class="fas fa-edit multilist__icon" (click)="editLookupTable()"></i>{{ 'MultiList.Label.AvailableValues' | texttransform }}
        </div>
        <div class="multilist__header__addline">
            <div class="multilist__header__select">
                <select [(ngModel)]="selectedValue" class="multilist__selection">
                    <option *ngFor="let availableValue of availableValues" [ngValue]="availableValue">
                        {{ availableValue.defaultName }}
                    </option>
                </select>    
            </div>
            <div class="multilist__header__plus" (click)="addItemToMultilist()">
                <i class="fas fa-plus-square fa-2x fingerCursor"></i>
            </div>    
        </div>
    </div>

    <div class="multilist__content" 
        *ngIf="addedDataValues.length > 0">
        <div class="multilist__header__label">
            {{ 'MultiList.Label.AddedValues' | texttransform }}
        </div>
        <div class="multilist__content__item" *ngFor="let addedDataValue of addedDataValues">         
            <div class="multilist__content__item__delete" (click)="deleteItem(addedDataValue)">
                <i class="fas fa-trash fa-lg fingerCursor multilist__content__item__delete__icon"></i>
            </div>

            <div class="multilist__content__item__name">
                {{ getValueName(addedDataValue) }}
            </div>
        </div>
    </div>
</div>