<div *ngIf="loadingControlData==true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</div>
<div *ngIf="loadingControlData==false">
    <div class="everleadShadowBox leadReportRow" *ngIf="applicationInfoService.currentAccount?.isDummyAccount != true">
        <label>{{ 'Leadreport.Label.Level' | texttransform }}</label>
        <select [(ngModel)]="leadReportLevel">
            <option [ngValue]="level" *ngFor="let level of leadReportLevels">{{ level?.defaultName | texttransform }}
            </option>
        </select>
    </div>

    <span *ngIf="leadReportLevel?.id == 107 && applicationInfoService.currentAccount?.isDummyAccount != true">
        <div class="everleadShadowBox leadReportRow">
            <label [ngClass]="{ 'redStyle': contactRequired }">{{ 'Contact.Label.Header' | texttransform }}</label>
            <select [(ngModel)]="leadReportContact">
                <option [ngValue]="contact.id" *ngFor="let contact of contacts">{{ contact?.firstName | texttransform }}
                    {{ contact?.lastName | texttransform }}
                </option>
            </select>
        </div>
    </span>

    <div class="everleadShadowBox leadReportRow">
        <label>{{ 'General._.Language' | texttransform }}</label>
        <select [(ngModel)]="selectedLanguage">
            <option [ngValue]="language" *ngFor="let language of languages">{{ language?.languageName | texttransform }}
            </option>
        </select>
    </div>

    <div class="everleadShadowBox leadReportRow">
        <label>{{ 'Leadreport.Label.Template' | texttransform }}</label>
        <select [(ngModel)]="leadReportTemplate">
            <option [ngValue]="template" *ngFor="let template of templates">{{ template?.defaultName | texttransform }}
            </option>
        </select>
    </div>

    <div class="everleadShadowBox leadReportRow">
        <label>{{ 'Leadreport.Label.FileType' | texttransform }}</label>
        <select [(ngModel)]="leadReportFileType">
            <option ngValue="docx">{{ 'Leadreport.Label.FileTypeWord' | texttransform }}</option>
            <option ngValue="pdf">{{ 'Leadreport.Label.FileTypePdf' | texttransform }}</option>
        </select>
    </div>

    <div class="everleadShadowBox leadReportRow">
        <label>{{ 'Leadreport.Label.OnlyNetResults' | texttransform }} 
            <app-wizard-infobutton displayText="Leadreport.Label.OnlyNetResultsDescription"></app-wizard-infobutton></label>        
        <input type="checkbox" [(ngModel)]="onlyNetResults">
    </div>
    <br>
    <button (click)="executeLeadReport()" class="jjButtonColor jjButtonStyle">{{ 'Leadreport.Label.StartExport' |
        texttransform }}</button>
</div>