import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { isNullOrUndefined } from 'util';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-admin-report-parameter',
  templateUrl: './admin-report-parameter.component.html',
  styleUrls: ['./admin-report-parameter.component.scss']
})
export class AdminReportParameterComponent implements OnInit {
  @Input() selectedReport = null;
  @Input() queryBuilderMode = 0;
  parameterTypes = null;
  expandedReportParameters = [];
  reportParameterExpanded = [];

  constructor(
    public commonService: CommonService,
    public dragdropService: DragdropService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit() {
    if (Number(this.queryBuilderMode) === 1) {
      this.commonService.toggleItemExpanded(this.expandedReportParameters, 1);
    }
    this.selectedReport.reportParameters.forEach(parameter => {
      parameter.queryEditorDebugValue = parameter.value;
    });
    this.getParameterTypes();
  }

  getParameterTypes() {
    this.externaldatasourceService.getLookupTableValues(6, 2)
    .then(result => {
      this.parameterTypes = result;
    });
  }

  removeReportParameter(parameter) {
    this.commonService.removeItemFromArray(this.selectedReport.reportParameters, parameter);
    if (!isNullOrUndefined(parameter.id)) {
      if (isNullOrUndefined(this.selectedReport.removedParameters)) {
        this.selectedReport.removedParameters = [];
      }
      this.selectedReport.removedParameters.push(parameter.id);
    }
  }

  newReportParameter() {
    const parameter = new Object();
    parameter['isMandatory'] = false;
    parameter['isUserParameter'] = false;
    parameter['value'] = '';
    parameter['defaultName'] = 'Neuer Parameter';
    this.selectedReport.reportParameters.push(parameter);
  }
}
