import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCamelcase'
})
export class SplitCamelcasePipe implements PipeTransform {

  transform(input: any, divider:string): any {


    if (typeof input !== "string") {
      return input;
    }

    return input.split(/(?=[A-Z])/).join(divider);

  }

}
