import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-ui-control-design-security-permission',
  templateUrl: './ui-control-design-security-permission.component.html',
  styleUrls: ['./ui-control-design-security-permission.component.scss']
})
export class UiControlDesignSecurityPermissionComponent implements OnInit {
  @Input() control = null;

  constructor(
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
  }

  addUiControlSecurityPermissions() {
    const permission = new Object();
    permission['permissionType'] = 'visible';
    permission['securityPermission'] = 'CanReadPermission';
    permission['uiControlId'] = this.control.id;
    this.externaldatasourceService.executeExternalDataSource(256, [
      this.commonService.getModifyArrayBody(permission, [])
    ]).then(addUiControlSecurityPermissionResult => { 
      permission['id'] = addUiControlSecurityPermissionResult.id;
      this.control.uiControlSecurityPermissions.push(permission);
    });
  }

  updatePermission(permission) {
    this.externaldatasourceService.executeExternalDataSource(257, [
      this.commonService.getModifyArrayBody(permission, [])
    ]);
  }

  removeUiControlSecurityPermissions(permission) {
    this.externaldatasourceService.executeExternalDataSource(258, [permission.id]).then(() => { 
      this.commonService.removeItemFromArray(this.control.uiControlSecurityPermissions, permission);
    });    
  }

}
