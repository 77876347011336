<div class="userMessagesContainer">
    <div class="userMessagesContainer__header maxWidth">
        <div class="maxWidth everleadCoral jjBold">
            <span *ngIf="mode==1">{{ 'UserMailBox.Label.Inbox' | texttransform }}</span>
            <span *ngIf="mode==2">{{ 'UserMailBox.Label.NewMail' | texttransform }}</span>
        </div>
        <div class="userMessagesContainer__header__item fingerCursor" (click)="mode=1"
            [tippy]="'UserMailBox.Label.Inbox' | texttransform"><i class="fas fa-envelope"></i></div>
        <div class="userMessagesContainer__header__item fingerCursor" (click)="mode=2"
            [tippy]="'UserMailBox.Label.NewMail' | texttransform"><i class="fas fa-pencil-alt"></i></div>
    </div>
    <div class="userMessages__wrapper maxWidth" *ngIf="mode == 1">
        <div *ngIf="applicationInfoService.userMessages.length == 0" class="maxWidth">
            {{ 'UserMailBox.Label.NoMessagesAvailable' | texttransform }}
        </div>        

        <div class="userMessages__navigation" *ngIf="selectedMessage != null">
            <div class="everleadShadowBox fingerCursor" *ngFor="let userMessage of applicationInfoService.userMessages" 
                (click)="renderContent(userMessage)" [ngClass]="{ selected: userMessage.id == selectedMessage?.id }">
                <div>
                    <div class="everleadSmallHeader maxWidth userMessages__header">
                        <div class="userMessages__header__from ellipsisText">
                            {{ userMessage?.fromUser.firstName }} {{ userMessage?.fromUser.lastName }}
                        </div>
                        <div class="userMessages__header__new flex-justify-flexend d-flex everleadCoral" *ngIf="userMessage?.userMessages[0]?.readDateAt == null">
                            {{ 'UserMailBox.Label.New' | texttransform }}
                        </div>                        
                    </div>
                    <div class="userMessages__navigation__time everleadCoral">{{ userMessage?.createdAt | date: 'medium' }}</div>
                </div>                
                <div class="jjBold everleadBlueColor ellipsisText" [tippy]="userMessage?.subject">{{ userMessage?.subject }}</div>            
            </div>
        </div>
        <div class="userMessages__content everleadShadowBox" *ngIf="selectedMessage != null">
            <div class="userMessages__content__subject">
                <div class="jjBold">{{ selectedMessage?.subject }}</div>
                <div class="d-flex flex-row">
                    <div class="maxWidth smallText">
                        {{ selectedMessage?.fromUser.firstName }} {{ selectedMessage?.fromUser.lastName }}
                    </div>
                    <div class="maxWidth smallText flex-justify-flexend d-flex">
                        {{ selectedMessage?.createdAt | date: 'medium' }}
                    </div>
                </div>                
            </div>
            <div class="userMessages__content__body">
                <div [innerHTML]="innerHTML"></div>
            </div>
        </div>
    </div>

    <div class="userMessages__wrapper maxWidth" *ngIf="mode == 2">
        <div class="d-flex maxWidth">
            <div class="newMessage__wrapper">
                <div class="newMessage__subject">
                    <label class=" everleadCoral">{{ 'EMailTemplate.Label.Subject' | texttransform }}</label>
                    <input class="maxWidth" [(ngModel)]="subject">
                </div>                
                <app-htmleditor 
                    class="maxHeight maxWidth" 
                    [htmlText]="newMailContent" 
                    (valueStatusChange)="getHTMLData($event)"
                >
                </app-htmleditor>
            </div>            
            <div class="newMessage__menu">
                <div class="maxHeight newMessage__menu__content">
                    <div class="newMessage__menu__header">{{ 'UserMailBox.Label.Receiver' | texttransform }}</div>
                    <div class="newMessage__menu__item">
                        <label>{{ 'UserMailBox.Label.Project' | texttransform }}</label>
                        <select [(ngModel)]="messageProjectId" (change)="changeProject()">
                            <option [ngValue]="project.id" *ngFor="let project of projects">{{ project.projectName }}</option>
                        </select>
                    </div>
                    <div class="newMessage__menu__item">
                        <label>{{ 'UserMailBox.Label.Role' | texttransform }}</label>
                        <select [(ngModel)]="messageRoleId" (change)="getAssignedUsers()">
                            <option [ngValue]=1>{{ 'Security.Role.Name.Agent' | texttransform }}</option>
                            <option [ngValue]=4>{{ 'Security.Role.Name.Supervisor' | texttransform }}</option>                            
                        </select>
                    </div>
                    <div class="newMessage__menu__item">
                        <label>{{ 'UserMailBox.Label.Users' | texttransform }}</label>
                    </div>
                    <div class="">
                        <div class="fingerCursor everleadSmallHeader selectAll" (click)="selectAllUsers()">
                            <i class="fas fa-check"></i>{{ 'UserMailBox.Label.SelectAll' | texttransform }}
                        </div>
                        <div *ngFor="let user of users" class="everleadSmallShadowBox"
                            (click)="selectUser(user)">
                            <label class="maxWidth" [ngClass]="{ everleadCoral: userIsSelected(user) }">{{ user.user.userName }}</label>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="jjButtonStyle jjButtonColor maxWidth" (click)="sendMessage()"
                        [disabled]="subject == '' || htmlText == '' || receiverList.length == 0"
                    >
                        {{ 'UserMailBox.Label.SendMessage' | texttransform }}
                    </button>
                </div>                
            </div>
        </div>        
    </div>
</div>
