import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-follow-up-task-chart',
  templateUrl: './follow-up-task-chart.component.html',
  styleUrl: './follow-up-task-chart.component.scss'
})
export class FollowUpTaskChartComponent implements OnInit, OnChanges{
  @Input() colors: string[]=[];
  @Input() chartData: any;
  @Input() height: any;
  @Input() width: any;
  @Input() toolboxInfo: boolean = false;

  options:EChartsOption={};
  dataAvailable 
  showInfo 

  constructor(private commonService: CommonService, private axivasTransateService: AxivasTranslateService){}
  
  ngOnInit(): void {

    if( this.chartData && this.chartData.length > 0 ){
      this.createChart();
    }
      
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && !changes['chartData'].firstChange) {
      this.createChart();
    }
  }


  createChart(){

    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }
    if (this.chartData.length == 0) {
      this.dataAvailable = false;
      return;
    }

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      xAxis:{
        show:true, 
        type:'category',
        data:this.chartData.map((item:any) => this.axivasTransateService.getTranslationTextForToken(item.name)),
      },
      yAxis:{ show:false },
      grid:{
        left:0,
        right:5,
        bottom:20,
      },
      series:[{
        data: this.chartData.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar.name),
          type:'bar',
          value: bar.value,
          itemStyle:{
            color: this.colors.length > 0 ? this.colors[index] : '#559ac0'
          }
         }
        }),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',}
       ]
      
    }
  }


  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  }

  getWidth() {
    if (isNaN(this.width)) {
      return this.width;
    } else {
      return this.width.toString().concat('px');
    }
  }

}
