import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-telekomcreatetake',
  templateUrl: './telekomcreatetake.component.html',
  styleUrls: ['./telekomcreatetake.component.scss']
})
export class TelekomcreatetakeComponent implements OnInit {
  account = {
    name1: '',
    systeminfo: null,
    product: null,
    takestate: 121,
    poolId: 590
  };
  regEx = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
  idList = [29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47];
  lookups = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private methodService: MethodService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.getLookups();
  }

  getTarifSublist() {
    switch(this.account.systeminfo) {
      case 123: // FMC
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 39);      
      case 124: // MTV
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 41);
      case 125: // Magenta Mobil Upgrade
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 40);
      case 126: // Pluskarte
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 42);
      case 127: // GK Highspeed
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 43);
      case 128: // Speed Up
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 44);
      case 129: // Cross
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 45);
    }
  }

  getDealcloserSublist() {
    switch(this.account.systeminfo) {
      case 123: // FMC
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 32);      
      case 124: // MTV
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 34);
      case 125: // Magenta Mobil Upgrade
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 33);
      case 126: // Pluskarte
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 35);
      case 127: // GK Highspeed
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 36);
      case 128: // Speed Up
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 37);
      case 129: // Cross
        return this.lookups.filter(lookupitem => lookupitem.definitionId == 38);
    }
  }

  getLookups() {
    this.externaldatasourceService.executeExternalDataSource(650, [this.idList])
    .then(result => {
      this.lookups = result;      
    })
  }

  getLookupList(lookupId) {
    return this.lookups.filter(lookupitem => lookupitem.definitionId == lookupId);
  }

  checkIfTakeIdIsValid() {
    let returnValue = true;
    return this.commonService.checkRegEx(this.regEx, this.account.name1);
  }

  createAccount(loadAndEnter = true) {
    if (this.checkIfTakeIdIsValid()) {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(204, [
        this.commonService.getModifyArrayBody(this.account, [])
      ]).then(createResult => {
        this.loaderService.display(false);
        if (loadAndEnter) {
          this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [createResult.id]).then(() => {
            this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
          });
        }
      }).catch(() => { 
        this.loaderService.display(false); 
      });  
    }
  }
}
