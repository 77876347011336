<label class="importAssignmentItemLabel">{{ 'Import.Label.DataSheet' | texttransform }}</label>
<div class="importAssignmentItemDropdownDiv inline">
    <select [(ngModel)]="selectedSheet" class="importAssignmentItemDropdown" (change)="selectedSheetChange()">
        <option [ngValue]="fileSheet" *ngFor="let fileSheet of fileSheets">{{ fileSheet.name }}</option>
    </select>
</div>

<input [disabled]="checkImportFileState()" class="hideInDom" *ngIf="selectedSheet != null">
<div *ngIf="selectedSheet != null && selectedProject != null">
    <label class="importAssignmentItemLabel">{{ 'Import.Label.DataPool' | texttransform }}</label>
    <div class="importAssignmentItemDropdownDiv inline">
        <select [(ngModel)]="selectedPool" class="importAssignmentItemDropdown" (change)="poolChanged(selectedPool)">
            <option [ngValue]=null>{{ 'Import.Label.NewPool' | texttransform }}</option>
            <option [ngValue]="pool.id" *ngFor="let pool of pools">
                {{ pool.defaultName }}</option>
        </select>
    </div>
    <input type="text" [(ngModel)]="selectedSheet.poolName" class="importAssignmentTextBox"
        *ngIf="selectedPool == null">
</div>

<div style="margin-top: 10px;" *ngIf="selectedSheet != null"
    class="importAssignmentAssignHeader importAssignmentAssignHeader2"
    (click)="commonService.toggleItemExpanded(expandedItems, 1);$event.stopPropagation()">
    <div class="inline importAssignmentItemLabel">
        <b>{{ 'Import.Label.PreviewValues' | texttransform }}</b>
    </div>
    <i class="floatRight fingerCursor fas" style="margin: 3px 12px 3px 3px;" [ngClass]="{
        'fa-chevron-down': commonService.isItemExpanded(expandedItems, 1) != true,
        'fa-chevron-up': commonService.isItemExpanded(expandedItems, 1) == true
    }"></i>
    <input [(ngModel)]="maxRows" class="floatRight importAssignmentMaxRowTextBox" (click)="$event.stopPropagation()"
        type="number">
    <div class="inline floatRight" style="margin-right: 5px;">
        {{ 'Import.Label.MaxValues' | texttransform }}
    </div>
</div>
<div [ngClass]="{'hideInDom': commonService.isItemExpanded(expandedItems, 1) !=true }" *ngIf="selectedSheet != null"
    style="overflow-x: auto;">
    <table style="table-layout: fixed;" class="scroll">
        <thead>
            <tr>
                <th *ngFor="let sourceField of selectedSheet?.data?.headers; let i = index"
                    class="importAssignmentTableDefDesign" [style.max-width.px]=200 [style.width.px]=200><label
                        class="ellipsisText" [tippy]=sourceField.name
                        style="margin: 0px;">{{ sourceField.name }}</label></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dataRow of selectedSheet?.data?.data; let i = index"
                [ngClass]="{'hideInDom': maxRowsReached(i) }">
                <td *ngFor="let dataItem of dataRow" [style.max-width.px]=200 [style.width.px]=200
                    class="importAssignmentTableDefDesign">
                    <label class="ellipsisText" style="margin: 0px;" [tippy]=dataItem>{{ dataItem }}</label>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div style="margin-top: 10px;" *ngIf="selectedSheet != null"
    (click)="commonService.toggleItemExpanded(expandedItems, 2);$event.stopPropagation()"
    class="importAssignmentAssignHeader importAssignmentAssignHeader2">
    <div class="inline importAssignmentItemLabel">
        <b>{{ 'Import.Label.Progress' | texttransform }}</b>
    </div>
    <i class="floatRight fingerCursor fas" style="margin: 3px 12px 3px 3px;" [ngClass]="{
        'fa-chevron-down': commonService.isItemExpanded(expandedItems, 2) != true,
        'fa-chevron-up': commonService.isItemExpanded(expandedItems, 2) == true
    }"></i>
    <i class="importAssignmentOverviewIcon fas fa-times-circle" style="color: red"
        *ngIf="checkIfDataPoolIsSelected() == false && commonService.isItemExpanded(expandedItems, 2) != true"
        [tippy]="'Import.Label.DataPoolSelectedDescription' | texttransform"></i>
    <i class="importAssignmentOverviewIcon fas fa-check" style="color: green;"
        *ngIf="checkIfDataPoolIsSelected() == true && commonService.isItemExpanded(expandedItems, 2) != true"
        [tippy]="'Import.Label.DataPoolSelectedDescription' | texttransform"></i>
    
    <i class="importAssignmentOverviewIcon fas fa-times-circle" style="color: red"
        *ngIf="checkIfContactKeysAreSet() == false && commonService.isItemExpanded(expandedItems, 2) != true"
        tippy="Ansprechpartner benötigt ggf. KeyValue (wenn Elemente dem Ansprechpartner zugeordnet wurden)"></i>
    <i class="importAssignmentOverviewIcon fas fa-check" style="color: green;"
        *ngIf="checkIfContactKeysAreSet() == true && commonService.isItemExpanded(expandedItems, 2) != true"
        tippy="Ansprechpartner benötigt ggf. KeyValue (wenn Elemente dem Ansprechpartner zugeordnet wurden)"></i>

    <i class="importAssignmentOverviewIcon fas fa-times-circle" style="color: red"
        *ngIf="checkIfAccountKeysAreSet() == false && commonService.isItemExpanded(expandedItems, 2) != true"
        [tippy]="'Import.Label.AccountKeyInfoDescription' | texttransform"></i>
    <i class="importAssignmentOverviewIcon fas fa-check" style="color: green;"
        *ngIf="checkIfAccountKeysAreSet() == true && commonService.isItemExpanded(expandedItems, 2) != true"
        [tippy]="'Import.Label.AccountKeyInfoDescription' | texttransform"></i>

    <i class="importAssignmentOverviewIcon fas fa-times-circle" style="color: red"
        *ngIf="checkRequiredMembers() == false && commonService.isItemExpanded(expandedItems, 2) != true"
        [tippy]="getMinimumFieldsDescription()"></i>
    <i class="importAssignmentOverviewIcon fas fa-check" style="color: green;"
        *ngIf="checkRequiredMembers() == true && commonService.isItemExpanded(expandedItems, 2) != true"
        [tippy]="getMinimumFieldsDescription()"></i>

</div>

<div [ngClass]="{'hideInDom': commonService.isItemExpanded(expandedItems, 2) !=true }">
    <div *ngIf="selectedSheet != null">
        <i class="fas fa-times-circle" style="color: red" *ngIf="checkIfDataPoolIsSelected() == false"></i>
        <i class="fas fa-check" style="color: green;" *ngIf="checkIfDataPoolIsSelected() == true"></i>
        <label class="importAssignmentInfoLabel"
            [tippy]="'Import.Label.DataPoolSelectedDescription' | texttransform">{{ 'Import.Label.DataPoolSelected' | texttransform }}</label>
    </div>
    <div *ngIf="selectedSheet != null">
        <i class="fas fa-times-circle" style="color: red" *ngIf="checkIfAccountKeysAreSet() == false"></i>
        <i class="fas fa-check" style="color: green;" *ngIf="checkIfAccountKeysAreSet() == true"></i>
        <label class="importAssignmentInfoLabel"
            [tippy]="'Import.Label.AccountKeyInfoDescription' | texttransform">{{ 'Import.Label.AccountKeyInfo' | texttransform }}</label>
    </div>
    <div *ngIf="selectedSheet != null">
        <i class="fas fa-times-circle" style="color: red" *ngIf="checkRequiredMembers() == false"></i>
        <i class="fas fa-check" style="color: green;" *ngIf="checkRequiredMembers() == true"></i>
        <label class="importAssignmentInfoLabel"
            [tippy]="getMinimumFieldsDescription()">{{ 'Import.Label.MinimumFieldsInfo' | texttransform }}</label>
    </div>
 
</div>

<hr>
<!-- DRAG DROP Section -->
<div cdkDropListGroup id="dropListGroup" *ngIf="selectedSheet != null">
    <p-panel header="{{ 'Import.Label.AvailableItems' | texttransform }}" class="inline" cdkDropList
        [ngStyle]="setControlStyle()" [cdkDropListData]="availableItems" (cdkDropListDropped)="drop($event, '0')">
        <div class="dragDropitem-noflexbox maxHeigh inline importAssignmentDragDropItem" *ngFor="let item of availableItems" cdkDrag data-html="true"
            [cdkDragData]="item" style="width: 200px; max-width: 200px; margin-right: 5px; white-space: pre-wrap;" [tippy]="getItemMouseover(item)">
            <div><label class="importAssignmentDragDropItemLabel jjBold" title=item.name>{{ item.name }}</label><i class="fas fa-grip-lines floatRight importAssignmentDragDropItemBurger"></i></div>
        </div>
    </p-panel>
    <br>
    <br>
    <p-panel header="Adresse" class="inline" cdkDropList [cdkDropListData]="accountItems"
        (cdkDropListDropped)="drop($event, '1')" [ngStyle]="setControlStyle()">
        <div class="dragDropitem-noflexbox maxHeight overflowy importAssignmentDragDropItem" *ngFor="let sourceField of accountItems" cdkDrag
            [cdkDragData]="sourceField" [tippy]="getItemMouseover(sourceField)"
            [ngClass]="{ 
                importAssignmentItemPositive: isSourceFieldDefined(sourceField),
                importAssignmentItemNegative: isSourceFieldDirty(sourceField, accountItems),
                importAssignmentItemLookupTableDoubled: isSourceFieldLookupTableDoubled(sourceField, accountItems),
                importAssignmentItemNotUsed: isSourceFieldIgnored(sourceField)
            }">
            <i class="fas fa-grip-lines floatRight importAssignmentDragDropItemBurger"></i>
            <label class="importAssignmentItemLabel jjBold">
                <i class="fas fa-info-circle" style="margin-right: 4px;" (click)="showHelpTablePopupReadOnly(sourceField)"
                    title="Vorschau"></i>
                <i class="fas fa-key importAssignmentDefaultBulb"
                    [ngClass]="{ importAssignmentItemPositiveFontColor: sourceField.isKeyMember == true }"
                    (click)="toggleKeyMember(sourceField, i)"
                    [tippy]="'Import.Label.KeyValueForImport' | texttransform"></i>
                <i class="fas fa-ban" *ngIf="sourceField.isKeyMember != true" (click)="toggleSkipMember(sourceField)"
                    [ngClass]="{ importAssignmentItemNegativeSignColor: sourceField.skipRecordIfEmpty == true }"
                    [tippy]="'Import.Label.SkipRecordIfEmpty' | texttransform"></i>
                {{ sourceField.name }}</label>

            <span [ngStyle]="setEntity(sourceField, 1)"></span>
            <select [(ngModel)]="sourceField.member" class="importAssignmentItemDropdownSmall"
                *ngIf="sourceField.entity !== null" (change)="entityMemberChanged(sourceField)"
                [ngClass]="{ hideInDom: sourceField.newColumn == true || sourceField.entity == null }">
                <option disabled ngValue=-2>Auto-Assign</option>
                <option [ngValue]=null></option>
                <option ngValue=-1>{{ 'Import.Label.NewField' | texttransform }}</option>
                <option [ngValue]="entityMember"
                    *ngFor="let entityMember of applicationInfoService.entities.Item(sourceField.entity).entityMembers"
                    [ngClass]="{ hideInDom: isTargetMemberInvalidForSourceFieldValue(sourceField, entityMember) }">
                    <span
                        *ngIf="entityMember.nameTranslationToken != null">{{ entityMember.nameTranslationToken.tokenFullName | texttransform }}</span>
                    <span
                        *ngIf="entityMember.nameTranslationToken == null">{{ entityMember.defaultName | texttransform }}</span>
                </option>
            </select>
            <input [disabled]="sourceField.member != -1" [(ngModel)]="sourceField.memberName"
                class="importAssignmentTextBoxSmall" *ngIf="sourceField.entity != null">

            <label class="importAssignmentItemLabelSmall">{{ 'Import.Label.Dropdown' | texttransform }}</label>
            <select [(ngModel)]="sourceField.lookupTableDefinition" class="importAssignmentItemDropdownSmall"
                (change)="helpTableChanged(sourceField)">
                <option [ngValue]=null></option>
                <option ngValue=-1>{{ 'Import.Label.NewDropdown' | texttransform }}</option>
                <option [ngValue]="lookupTableDefinition" *ngFor="let lookupTableDefinition of lookupTableDefinitions">
                    {{ lookupTableDefinition.defaultName }}
                </option>
            </select>
            <input *ngIf="sourceField.lookupTableDefinition == -1" [(ngModel)]="sourceField.lookupTableName"
                class="importAssignmentTextBoxSmall">            
                <label class="importAssignmentItemLabelSmall" style="width: 80px;">Type</label>
                <select [(ngModel)]="sourceField.type" class="importAssignmentItemDropdownSmall"
                    [disabled]="!(sourceField.lookupTableDefinition == null || sourceField.lookupTableDefinition == 'null')">
                    <!-- <option [ngValue]=sourceField.type>{{ sourceField.type }}</option>
                    <option ngValue="string" *ngIf="sourceField.type != 'string'">string</option> -->
                    <option ngValue="string">string</option>
                    <option ngValue="int">int</option>    
                    <option ngValue="date">date</option>    
                </select>
        </div>
    </p-panel>
    <br>
    <br>
    <p-panel header="Ansprechpartner" class="inline" cdkDropList [cdkDropListData]="contactItems"
        (cdkDropListDropped)="drop($event, '1')" [ngStyle]="setControlStyle()">
        <div class="dragDropitem-noflexbox maxHeight overflowy importAssignmentDragDropItem" *ngFor="let sourceField of contactItems" cdkDrag
        [cdkDragData]="sourceField" [tippy]="getItemMouseover(sourceField)" [ngClass]="{ 
            importAssignmentItemPositive: isSourceFieldDefined(sourceField),
            importAssignmentItemNegative: isSourceFieldDirty(sourceField, contactItems),
            importAssignmentItemLookupTableDoubled: isSourceFieldLookupTableDoubled(sourceField, contactItems),
            importAssignmentItemNotUsed: isSourceFieldIgnored(sourceField)
        }">
        <i class="fas fa-grip-lines floatRight importAssignmentDragDropItemBurger"></i>
        <label class="importAssignmentItemLabel jjBold">
            <i class="fas fa-info-circle" style="margin-right: 4px;" (click)="showHelpTablePopupReadOnly(sourceField)"
                title="Vorschau"></i>
            <i class="fas fa-key importAssignmentDefaultBulb"
                [ngClass]="{ importAssignmentItemPositiveFontColor: sourceField.isKeyMember == true }"
                (click)="toggleKeyMember(sourceField, i)"
                [tippy]="'Import.Label.KeyValueForImport' | texttransform"></i>
            <i class="fas fa-ban" *ngIf="sourceField.isKeyMember != true" (click)="toggleSkipMember(sourceField)"
                [ngClass]="{ importAssignmentItemNegativeSignColor: sourceField.skipRecordIfEmpty == true }"
                [tippy]="'Import.Label.SkipRecordIfEmpty' | texttransform"></i>
            {{ sourceField.name }}</label>

        <span [ngStyle]="setEntity(sourceField, 4)"></span>
        <select [(ngModel)]="sourceField.member" class="importAssignmentItemDropdownSmall"
            *ngIf="sourceField.entity !== null" (change)="entityMemberChanged(sourceField)"
            [ngClass]="{ hideInDom: sourceField.newColumn == true || sourceField.entity == null }">
            <option disabled ngValue=-2>Auto-Assign</option>
            <option [ngValue]=null></option>
            <option ngValue=-1>{{ 'Import.Label.NewField' | texttransform }}</option>
            <option [ngValue]="entityMember"
                *ngFor="let entityMember of applicationInfoService.entities.Item(sourceField.entity).entityMembers"
                [ngClass]="{ hideInDom: isTargetMemberInvalidForSourceFieldValue(sourceField, entityMember) }">
                <span
                    *ngIf="entityMember.nameTranslationToken != null">{{ entityMember.nameTranslationToken.tokenFullName | texttransform }}</span>
                <span
                    *ngIf="entityMember.nameTranslationToken == null">{{ entityMember.defaultName | texttransform }}</span>
            </option>
        </select>
        <input [disabled]="sourceField.member != -1" [(ngModel)]="sourceField.memberName"
            class="importAssignmentTextBoxSmall" *ngIf="sourceField.entity != null">

        <label class="importAssignmentItemLabelSmall">{{ 'Import.Label.Dropdown' | texttransform }}</label>
        <select [(ngModel)]="sourceField.lookupTableDefinition" class="importAssignmentItemDropdownSmall"
            (change)="helpTableChanged(sourceField)">
            <option [ngValue]=null></option>
            <option ngValue=-1>{{ 'Import.Label.NewDropdown' | texttransform }}</option>
            <option [ngValue]="lookupTableDefinition" *ngFor="let lookupTableDefinition of lookupTableDefinitions">
                {{ lookupTableDefinition.defaultName }}
            </option>
        </select>
        <input *ngIf="sourceField.lookupTableDefinition == -1" [(ngModel)]="sourceField.lookupTableName"
            class="importAssignmentTextBoxSmall">            
            <label class="importAssignmentItemLabelSmall" style="width: 80px;">Type</label>
            <select [(ngModel)]="sourceField.type" class="importAssignmentItemDropdownSmall"
                [disabled]="!(sourceField.lookupTableDefinition == null || sourceField.lookupTableDefinition == 'null')">
                <!-- <option [ngValue]=sourceField.type>{{ sourceField.type }}</option> -->
                <!-- <option ngValue="string" *ngIf="sourceField.type != 'string'">string</option> -->
                <option ngValue="string">string</option>
                <option ngValue="int">int</option>
                <option ngValue="date">date</option>    
            </select>
    </div>
    </p-panel>
</div>