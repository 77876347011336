import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { RingcentralService } from 'app/jollyjupiter/service/ringcentral.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { MiddlewareSignalrService, PopupMode } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { environment } from 'environments/environment';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-softphone',
  templateUrl: './softphone.component.html',
  styleUrls: ['./softphone.component.scss']
})
export class SoftphoneComponent implements OnInit, OnDestroy {
  connectionStateSubsciption: Subscription = new Subscription;
  timerSubscription: Subscription = new Subscription;
  useRingCentral = 'false';
  connectionTime = null;
  softphoneExpanded = true;
  currentlyReconnecting = false;
  @Input() expanded = false;
  numberToCall = '';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public middlewareSignalrService: MiddlewareSignalrService,
    private methodService: MethodService,
    public ringcentralService: RingcentralService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
  ) { }

  ngOnDestroy() {
    if (this.timerSubscription) { this.timerSubscription.unsubscribe(); }
    if (this.connectionStateSubsciption) { this.connectionStateSubsciption.unsubscribe(); }
  }

  ngOnInit() {
    this.connectionStateSubsciption = this.middlewareSignalrService.connectionStateEvent.subscribe(data => {
      console.log('connectionStateSubsciption', data);
    });
    this.getConnectionTime();
    if (this.applicationInfoService.phoneConnection == this.applicationInfoService.phoneRingCentral) {
      this.useRingCentral = 'true'
    }
    this. initSoftphone(window.innerWidth)
  }
  @HostListener('window:resize', ['$event'])
  onResize(event){
    this. initSoftphone(event.target.innerWidth)
  }

  initSoftphone(screenWidth){
    const desktopBreakPoint = 1024;
    this.softphoneExpanded = screenWidth > desktopBreakPoint;

  }
  getInfos() {
    console.warn(this.ringcentralService.phoneConnectionStatus);
  }

  isExternalUser() {
    if (this.applicationInfoService.user != null) {
      return this.applicationInfoService.user.isExternalUser;
    } else {
      return false;
    }
  }

  visitWebsite() {
    this.applicationInfoService.showBanner = true;
    // this.externaldatasourceService.executeExternalDataSource(493, []).then(result => {
    //   if (result.length > 0) {
    //     this.commonService.openUrl(result[0].domainFilter, '_blank');
    //   }
    // })
  }

  callDialing() {
    if ((this.middlewareSignalrService.connectionStatus.connectionStatus === 'Connected') ||
      (this.middlewareSignalrService.connectionStatus.connectionStatus === 'Ringing')) {
      this.middlewareSignalrService.hangup();
    } else {
      this.methodService.launchInternalMethod(InternalMethodType.ShowSoftphonePopup, [1]);
    }
  }

  callRingCentralPopup() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['RingCentral', 'ringcentralpopup', 80]);
  }

  toggle() {
    this.expanded = !this.expanded;
  }
  togglesoftPhone (){
    this.softphoneExpanded = !this.softphoneExpanded;
  }


  callNumber(number) {
    this.middlewareSignalrService.call(number, true);
  }

  softphoneEvent(event: string, property: string) {
    switch (event) {
      case 'hold':
        this.hold();
        break;
      case 'hangup':
        this.hangup();
        break;
      case 'consult':
        this.consult();
        break;
      case 'toggleheadset':
          this.toggleHeadset();
          break;
      case 'togglespeaker':
          this.toggleSpeaker();
          break;
      case 'startrecord':
          this.startRecord();
          break;
      case 'stoprecord':
          this.stopRecord();
          break;
      case 'transfer':
          this.transfer();
          break;
      case 'resume':
          this.resume();
          break;
      case 'discardrecord':
          this.discardRecord();
          break;
      case 'conference':
          this.conference();
          break;
      case 'answer':
          this.pickupcall();
          break;
      }
}

transfer() {
  this.middlewareSignalrService.transfer();
}

discardRecord() {
  this.middlewareSignalrService.discardRecord();
}

pickupcall() {
  this.middlewareSignalrService.pickupcall();
}

resume() {
  this.middlewareSignalrService.resume();
}

toggleSpeaker() {
  this.middlewareSignalrService.toggleSpeaker();
}

toggleHeadset() {
  this.middlewareSignalrService.toggleHeadset();
}

consult() {
  this.methodService.launchInternalMethod('showsoftphonepopup', [PopupMode.Consult]);
  // this.middlewareSignalrServiceService.consult();
}

conference() {
  this.middlewareSignalrService.conference();
}

startRecord() {
  this.middlewareSignalrService.startRecord();
}

stopRecord() {
  this.middlewareSignalrService.stopRecord();
}

hold() {
  this.middlewareSignalrService.hold();
}

hangup() {
  this.middlewareSignalrService.hangup();
}

  reinitializeConnection() {
    this.currentlyReconnecting = true;
    this.middlewareSignalrService.InitService()
    .then(() => {
      console.log('reinitializeConnection success');
      this.currentlyReconnecting = false;
    })
    .catch(error => {
      this.currentlyReconnecting = false;
      console.error('reinitializeConnection error', error);
    });
  }

  getConnectionTime() {
    const activityTimer = timer(1000, 1000);

    this.timerSubscription = activityTimer.subscribe(() => {
      if (environment.useRingCentral == 'true') {
        const date1 = new Date().getTime() / 1000;
        const date2 = new Date(this.ringcentralService.eventTime).getTime() / 1000;
        const difference = Math.floor(date1 - date2);
        const minutes: number = Math.floor(difference / 60);
        this.connectionTime  = minutes.toString().padStart(2, '0') + ':' + (difference - minutes * 60).toString().padStart(2, '0');
      } else {
        if (this.middlewareSignalrService.onTelStatusTime !== null) {
          const date1 = new Date().getTime() / 1000;
          const date2 = new Date(this.middlewareSignalrService.onTelStatusTime).getTime() / 1000;
          const difference = Math.floor(date1 - date2);
          const minutes: number = Math.floor(difference / 60);
          this.connectionTime  = minutes.toString().padStart(2, '0') + ':' + (difference - minutes * 60).toString().padStart(2, '0');
        }
      }
    });
  }

  ringCentralAuthorized() {
    let returnValue = false;
    if (localStorage.getItem('ringCentralAuthCode') != null) {
      if (this.ringcentralService.ringCentralAuthWindow != null) {
        this.applicationInfoService.webPhoneConnected = false;
        this.ringcentralService.closeRingCentralAhtentification();
        this.ringcentralService.ringCentralAuthWindow = null;
        this.ringcentralService.initConnection(true).catch(() => {});;
      }
      returnValue = true;
    }
    return returnValue;
  }

  openIncomingCall() {
    this.eventService.openIncomingCallEvent.emit(null);
  }
}
