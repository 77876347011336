import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EntityService, ValueType } from 'app/jollyjupiter/service/entity.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { OpportunityService } from 'app/jollyjupiter/service/opportunity.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss']
})
export class OpportunityComponent implements OnInit {
  innerHTMLContent = '';
  innerHTMLContentEdit = '';
  innerHTML = null;
  innerHTMLEdit = null;
  createNew = true;
  controlIdNew = 247201;
  controlIdEdit = 247202;
  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private methodService: MethodService,
    private externaldatasourceService: ExternaldatasourceService,
    private opportunityService: OpportunityService
  ) { }

  ngOnInit() {
    this.showOpportunityControl();
  }

  showOpportunityControl() {
    this.createNew = !this.applicationInfoService.miscSettings['opportunityEditExistingOpportunity'];
    this.innerHTMLContent = '<jj-container controluiid="'.concat(this.controlIdNew.toString(), '"> </jj-container>');
    this.innerHTMLContentEdit = '<jj-container controluiid="'.concat(this.controlIdEdit.toString(), '"> </jj-container>');
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.innerHTMLEdit = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentEdit);
  }

  applyOpportunity() {
    this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
  }

  canUseOpportunity() {
    return this.opportunityService.checkIfTempOpportunityIsValid();
  }
  
  saveExistingOpportunity () {
    this.opportunityService.updateOpportunity(this.opportunityService.getTempOpportunity()).then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
    });
  }
}
