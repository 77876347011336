<!-- style="border-top: 1px solid RGB(167, 167, 167); border-bottom: 1px solid RGB(167, 167, 167)" -->
<div #MainDiv [ngStyle]="setControlStyle('control')" class="jjControlSubmenuColor menubarWrapper" 
    
>
    <span *ngFor=" let child of controlDefinition.childs" (click)="clickItem('onclick', child)">
        <div style="height: 100%; border: 0px;" class=" "
            [ngClass]="{ 'hideInDom': checkIfControlIsVisible(child) == false, 'menubarButton': child.childs.length == 0, 'menubarButtonShort': child.childs.length > 0 }"
            *ngIf="userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')==true && child.isActive"
            title="{{ child.displayText | texttransform }}"
            [ngClass]="{ 'hideInDom': userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')==false}"
            [class]="getButtonClass(child) "
            [ngStyle]="{ fingerCursor: child.methods.length > 0 }">
            <div class="subChildList fingerCursor" *ngIf="selectedParent==child.id">
                <div *ngFor="let subchild of child.childs" (click)="clickItem('onclick', subchild);$event.stopPropagation()">
                    <div class="subChildList__listItem" [ngClass]="{ 'hideInDom': !checkIfControlIsEnabled() }"
                    title="{{ subchild.displayText | texttransform }}"
                    [ngClass]="{ 'hideInDom': userService.getControlPermissionByType(subchild.uiControlSecurityPermissions, true, 'visible')==false}"
                    [class]="getButtonClass(subchild) "
                    [ngStyle]="{ fingerCursor: subchild.methods.length > 0 }">
                    </div>
                    <label class="subChildList__label fingerCursor">{{ subchild.displayText | texttransform }}</label>
                </div> 
            </div>
        </div>
        <div *ngIf="child.childs.length > 0" class="fas " [ngClass]="{
            'fa-chevron-down': selectedParent!=child.id,
            'fa-chevron-up': selectedParent==child.id
        }"></div>
    </span>
    <span style="margin-left: 10px" *ngIf="!applicationInfoService.useNewDesign">
        <label style="font-size: 16px;">
            <span *ngIf="applicationInfoService.currentAccount != null">
                {{ applicationInfoService.entities.Item(applicationInfoService.accountEntityId).data['name1'] }}
            </span>
            <span *ngIf="applicationInfoService.currentContact != null">
                - {{ applicationInfoService.entities.Item(applicationInfoService.contactEntityId).data['firstName'] }} 
                {{ applicationInfoService.entities.Item(applicationInfoService.contactEntityId).data['lastName'] }}
            </span>    
        </label>
    </span>
</div>