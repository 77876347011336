<label class="jjSectionHeaderLabel">
  {{ 'DeveloperProjectManagement.Label.Header' | texttransform }}
</label>

  <!-- Source -->
  <div style="padding-bottom: 10px;" class="controlInlineBlock">
    <label class="jjBold" style="width:100%">Quelle</label>
  </div>

  <div class=everleadShadowBox>
  <div class="copyProject__item">
    <label class="copyProject__label">Kunde</label>
    <select (change)="changeCustomerSource()" [(ngModel)]="selectedSourceCustomer" class="copyProject__dropdown">
      <option [ngValue]="customer" *ngFor="let customer of sourceCustomers">{{ customer.customerName  }}</option>
    </select>  
  </div>

  <div class="copyProject__item">
    <label class="copyProject__label">Projekt</label>
    <select (change)="changeProjectSource()" [(ngModel)]="selectedSourceProject" class="copyProject__dropdown">
      <option [ngValue]="project" *ngFor="let project of filterProjectsSourceByCustomerId()">{{ project.projectName  }}
      </option>
    </select>  
  </div>

  <!-- Data schema -->
  <div class="copyProject__item">
    <label class="copyProject__label">Schema</label>
    <select class="copyProject__dropdown" [(ngModel)]="selectedDataSourceSchema">
      <option [ngValue]="schema" *ngFor="let schema of sourceSchemas">{{ schema.schema  }}
      </option>
    </select>      
  </div>
</div>


<div class="everleadShadowBox">
  <!-- Destination -->
  <div style="padding-bottom: 10px;" class="controlInlineBlock">
    <label class="jjBold" style="width:100%">Ziel</label>
  </div>

  <div class="copyProject__item">
    <label class="copyProject__label">{{ 'DeveloperProjectManagement.Label.NameOfCustomerDestination' | texttransform }}</label>
    <select  class="copyProject__dropdown" (change)="changeCustomerDestination()" [(ngModel)]="selectedDestinationCustomer">
      <option [ngValue]="customer" *ngFor="let customer of sourceCustomers">
        {{ customer.customerName  }}
      </option>
    </select>
  </div>

    
  <div class="copyProject__item" *ngIf=isNewCustomer>
    <label class="copyProject__label">Kundenname</label>
    <input type="text" class="copyProject__textbox" maxlength="50" on-focusout="checkIfCustomerAlreadyExists()"
      [ngClass]="{
        'colorRed': isCustomerAlreadyExists
      }" [(ngModel)]="newCustomerName">
  </div>

  <div *ngIf=isCustomerAlreadyExists class="copyProject__item">
    <label style="color:red">
      {{ 'DeveloperProjectManagement.Label.CustomerAlreadyExists' | texttransform }}
    </label>
  </div>


  <div class="copyProject__item">
    <label class="copyProject__label">Projektname</label>
    <input type="text" class="copyProject__textbox" maxlength="50" on-focusout="checkIfProjectAlreadyExists()" class="copyProject__textbox"
        [ngClass]="{
        'colorRed': isProjectAlreadyExists
      }"[(ngModel)]="newProjectName">
  </div>
 
  <div class="copyProject__label" *ngIf=isProjectAlreadyExists class="copyProject__item">
    <label style="color:red">{{ 'DeveloperProjectManagement.Label.ProjectAlreadyExists' | texttransform }}</label>
  </div>
  
  <!-- <div class="copyProject__item">
    <label class="copyProject__label">Data - Instance</label>
    <select  class="copyProject__dropdown" (change)="changeCustomerDestination()" [(ngModel)]="selectedDataInstance">
      <option [ngValue]="datainstance.id" *ngFor="let datainstance of datainstances">
        <span class="everleadCoral">{{ datainstance.id }}</span> {{ datainstance.alias }} ({{ datainstance.urlRessource.urlLive }})
      </option>
    </select>
  </div> -->

  <!-- Data schema -->
  <div class="copyProject__item">
    <label class="copyProject__label">Schema</label>
    <select  class="copyProject__dropdown" (change)="changeSchemaDestination()" [(ngModel)]="selectedDataDestinationSchema">
      <option [ngValue]="schema" *ngFor="let schema of destinationSchemas">{{ schema.schema  }}
      </option>
    </select>
  </div>

  <div *ngIf=isNewSchema class="copyProject__item">
    <label class="copyProject__label">Name des Schemas</label>
    <input type="text" class="copyProject__textbox" maxlength="25"
      [(ngModel)]="newCustomerSchema" on-focusout="checkIfSchemaAlreadyExists()" [ngClass]="{
      'colorRed': isSchemaAlreadyExists
    }">
  </div>
  <div *ngIf=isSchemaAlreadyExists class="copyProject__item">
    <label style="color:red">
      {{ 'DeveloperProjectManagement.Label.SchemaAlreadyExists' | texttransform }}
    </label>
  </div>
</div>

<button (click)="preCheckExecution()" class="copyProject__createProjectButton jjButtonStyle jjButtonColor">Projekt anlegen</button>
