<div class="featuressettingsWrapper">
  <div class="projectSettings__header">
    <div class="projectSettings__header__newItem fingerCursor" (click)="addNewFeature()">
        <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            [tippy]="'General.Label.CreateNewWithDefaultValues' | texttransform" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"></svg-icon>
            {{ 'Feature.Label.NewFeature' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction">
        <div class="projectSettings__header__showOnlyActive" *ngIf="applicationInfoService.expertMode">
            <input [(ngModel)]="showOnlyActive" type="checkbox">
            {{ 'ProcessDesign.Label.ShowOnlyActive' | texttransform }}
        </div>
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
            </svg-icon>
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
  </div>

  <div class="featuressettings">
    @for (feature of packagefeatureService.allFeatures; track feature.id; let i = $index) {
    <div class="everleadShadowBox" (click)="selectFeature(feature)" *ngIf="selectedFeature == null" [ngClass]="{
      hideInDom: !isItemFiltered(feature)
    }">
      <div class="featuressettings__header fingerCursor">
        {{feature.defaultName}}        
      </div>
    </div>
    }  

    <div class="featuressettings__body" *ngIf="selectedFeature != null">
      <div>
        <button class="everleadCustomButton" (click)="selectedFeature = null">{{ 'General.Label.Back' | texttransform }}</button>
      </div>
      <div class="featuressettings__field">
        <label for="feature-name-{{i}}">{{ 'Feature.Label.DefaultName' | texttransform }}</label>
        <input [(ngModel)]="selectedFeature.defaultName" id="feature-name-{{i}}" type="text" (change)="updateFeature(feature.id)">
      </div>

      <div class="featuressettings__field">
        <label for="feature-active-{{i}}">{{ 'Feature.Label.IsActive' | texttransform }}</label>
        <input [(ngModel)]="selectedFeature.isActive" id="feature-active-{{i}}" type="checkbox" (change)="updateFeature(feature.id)">
      </div>

      <div class="featuressettings__field" *ngIf="applicationInfoService.isDeveloper">
        <label for="feature-notes-{{i}}">Notes</label>
        <textarea [(ngModel)]="selectedFeature.notes" id="feature-notes-{{i}}" (change)="updateFeature(feature.id)"></textarea>
      </div>

      <div class="featuressettings__field" *ngIf="applicationInfoService.isDeveloper">
        <label for="feature-settings-{{i}}">Settings</label>
        <textarea [(ngModel)]="selectedFeature.settings" id="feature-settings-{{i}}" rows="4"
          (change)="updateFeature(selectedFeature.id)"></textarea>
      </div>

      <app-featuressettings-slider [featureId]="selectedFeature.id" />
    </div>
  </div>
</div>

<!-- @if (!packagefeatureService.allFeatures.length) {
  <div style="height: 22px;">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
  </div>
} -->