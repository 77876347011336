import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class MicrocampaignService {
  microcampaigns = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ) {
    this.getMicroCampaignStarter();
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'workflowUpdated') {        
        this.getMicroCampaignStarter();
      }
    });
   }

  getMicroCampaignStarter() {
    this.microcampaigns = [];
    this.applicationInfoService.results.toArray().forEach(result => {

      if (result.typeLookupId == 6216) {    
        this.microcampaigns.push(result);        
      }
    });  
    this.microcampaigns.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);     
  }
}
