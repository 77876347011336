import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CustomControlBaseClass } from 'app/jollyjupiter/controls/custom/customcontrolbaseclass';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { SettingsService, UserSetting } from 'app/shared/service/settings.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { environment } from 'environments/environment';
import { ReplaySubject, Subscription, timer } from 'rxjs';
import { BoldbiServiceService } from '../boldbi-service.service';

declare var BoldBI: any;
declare function emdbedDashboardActionComplete(any): any;

@Component({
  selector: 'app-boldbi-dashboard',
  templateUrl: './boldbi-dashboard.component.html',
  styleUrls: ['./boldbi-dashboard.component.scss']
})

export class BoldbiDashboardComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  dropdownElement = null;
  dropdownElements = [];
  dropdownOpenElement = null;

  loadDashboardEventSubscription: Subscription = new Subscription();
  dataShow = false;
  dropDownFound = false;
  public dashboards;
  public dashboardId
  public dashboardsList: Item[];
  result: any;
  dashboard: any;
  loadDashboards = false;
  userAuthenticated = true;
  selectedDashboard: any;
  BoldBiTranslationBase = '.e-title-value';
  BoldBiTranslationMaxDashboards = 0;
  BoldBiTranslationMaxAxisItems = 0;
  intervalSubscription: Subscription = new Subscription();;

  emailBatchId = null;

  constructor(
    private _app: BoldbiServiceService, 
    private injector: Injector,
    private settingService: SettingsService,
    private externaldatasourceService: ExternaldatasourceService,
    private axivasTranslateService: AxivasTranslateService,
    public applicationInfoService: ApplicationInfoService,
    private sharedAPI: SharedAPI
  ) {
    super(injector);
  }

  ngOnDestroy() {    
    if (this.loadDashboardEventSubscription) { this.loadDashboardEventSubscription.unsubscribe(); }
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  ngOnInit() { 
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['emailbatch'])) {
      this.emailBatchId = this.applicationInfoService.miscSettings['emailbatch'].id;
      this.applicationInfoService.miscSettings['emailbatch'] = null;
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['eventBookingBatch'])) {
      this.emailBatchId = this.applicationInfoService.miscSettings['eventBookingBatch'].id;
      this.applicationInfoService.miscSettings['eventBookingBatch'] = null;
    }

    this.BoldBiTranslationBase = this.applicationInfoService.applicationSettings['BoldBiTranslationBase'];
    this.BoldBiTranslationMaxDashboards = Number(this.applicationInfoService.applicationSettings['BoldBiTranslationMaxDashboards']);
    this.BoldBiTranslationMaxAxisItems = Number(this.applicationInfoService.applicationSettings['BoldBiTranslationMaxAxisItems']);
    
    for (let counter = 0; counter < this.BoldBiTranslationMaxDashboards; counter ++) {
      for (let axisItemCounter = 0; axisItemCounter < this.BoldBiTranslationMaxAxisItems; axisItemCounter ++) {
        this.BoldBiTranslationBase = this.BoldBiTranslationBase + ', #dashboard_embeddedbichartItemChart' + counter + '_svg_XAxisTitle_' + axisItemCounter;
        this.BoldBiTranslationBase = this.BoldBiTranslationBase + ', #dashboard_embeddedbichartItemChart' + counter + '_svg_YAxisTitle_' + axisItemCounter;
      }
    }

    this.loadDashboardEventSubscription = this.eventService.loadDashboardEvent.subscribe(event => {
      this.renderDashboard(event);
    });

    if (environment.boldBiEnvironment == "enterprise") {
      environment.boldBiBaseUrl = environment.boldBiRootUrl + "/" + environment.boldBiSiteIdentifier;
        environment.boldBiDashboardServerApiUrl = environment.boldBiRootUrl + "/api/" + environment.boldBiSiteIdentifier;
    } else {
      environment.boldBiBaseUrl = environment.boldBiRootUrl;
      environment.boldBiDashboardServerApiUrl = environment.boldBiRootUrl + "/api";
    }
    
    let lastDashboard = this.dashboardIdLastSelection(false, this.dashboardId);
    if (this.commonService.isNullOrUndefined(lastDashboard)) {
      lastDashboard = null;
    }
    setTimeout(() => { this.renderDashboard(null, lastDashboard); }, 500);
  }

  dashboardIdLastSelection(setSelection = false, dashboardId = null) {
    let returnValue = null;
    const checkId = 'boldBI?pid:'.concat(
      this.applicationInfoService.projectID.toString(),
      '?cid:',
      this.controlDefinition.id.toString()
    );    
    let lastDashboard = this.settingService.userSettings[checkId];
    if (setSelection) {
      if (this.commonService.isNullOrUndefined(lastDashboard)) {
        lastDashboard = new UserSetting(null, checkId, dashboardId, this.applicationInfoService.userID);
      } else {
        lastDashboard['value'] = dashboardId;
        returnValue = dashboardId;
      }
      this.settingService.setUserSettingEx(lastDashboard)
    } else {
      if (!this.commonService.isNullOrUndefined(lastDashboard)) {
        returnValue = lastDashboard['value'];
      }
    }
    return returnValue;
  }

  renderDashboard(dashboard: Item, userSettingDashboardId = null) {
    this.dropdownElements = [];
    if (this.controlDefinition.isActive == false) {
      return;
    }
    if (dashboard === null) {
      this.dashboardId = this.controlDefinition.value;      
    } else {
      this.dashboardId = dashboard.Id;
    }
    if (userSettingDashboardId != null) {
      this.dashboardId = userSettingDashboardId;
    }
    if (userSettingDashboardId == null) {
      this.dashboardIdLastSelection(true, this.dashboardId);
    }    

    let filterParameterString = this.commonService.modifyQueryStringWithApplicationInfos(
      'project_id=<projectId>&&translation_id=<languageId>&&contact_id=<contactId>&&account_id=<accountId>&&pool_id=<poolId>&&campaign_id=<campaignId>&&session_isadmin=<isadmin>&&session_userid=<userId>&&session_groups=1&&batch_id=<emailBatchId>'
    );
    this.externaldatasourceService.executeExternalDataSource(603)
    .then(dataRightsInt => {
      if (this.applicationInfoService.campaingnModeId == null) {
        filterParameterString = filterParameterString.replace('<campaignId>', '0');        
      } else {
        filterParameterString = filterParameterString.replace('<campaignId>', this.applicationInfoService.campaingnModeId.toString());        
      }
      if (this.emailBatchId) {
        filterParameterString = filterParameterString.replace('<emailBatchId>', this.emailBatchId.toString());
      }      
      filterParameterString = filterParameterString.replace('<groups>', dataRightsInt.toString());
      if (this.applicationInfoService.isDeveloper) {
        filterParameterString = filterParameterString.replace('<isadmin>', 'true');
      } else {
        filterParameterString = filterParameterString.replace('<isadmin>', 'false');
      }
      this.dashboard= BoldBI.create({          
          serverUrl: environment.boldBiBaseUrl,
          dashboardId: this.dashboardId,
          embedContainerId: "dashboard",
          embedType: BoldBI.EmbedType.Component,
          environment: environment.boldBiEnvironment=="enterprise"? BoldBI.Environment.Enterprise:BoldBI.Environment.Cloud,
          width:"100%",
          height: "100%",
          expirationTime: 100000,
          filterParameters: filterParameterString,
          authorizationServer: {
              url: environment.boldBiApiHost + environment.boldBiSiteAuthorizationUrl,
              headers: {
                "Authorization": "Bearer " + localStorage.getItem('awstoken')
              }
          },
          dashboardSettings: { 
            showHeader: false,
          },
          autoRefreshSettings: {
              enabled: false,
              hourlySchedule: {
                  hours: 24,
                  minutes: 0,
                  seconds: 0
              }

          },
          actionBegin:"emdbedDashboardActionBegin",
          actionComplete:"emdbedDashboardActionComplete"
      });
      this.applicationInfoService.currentDashboardId = this.dashboardId;
      this.dashboard.loadDashboard();
      this.replaceTranslationTokens();
    });
  }   

  getDashboarHeight() {
    let tableSize = Number(this.uiService.getDesignSpanPosition(this.controlDefinition.id, 40));
      if (this.applicationInfoService.designerMode) {
        tableSize = tableSize - 20;
      }
      return  tableSize; 
  }

  emdbedDashboardActionComplete(arg) {
    console.warn('emdbedDashboardActionComplete', arg);
  }

  getHeightBoldBiDashoardHeight() {
    // Non-popup
    let value = this.uiService.getDesignSpanPosition('designSpanBoldBI', 45);
    if (value == undefined) {
      value = this.applicationInfoService.innerHeight - 135;
    }
    return value + 'px';    
  }  

  replaceTranslationTokens() {
    const responseTimer = timer(1000, 1000);
    this.intervalSubscription = responseTimer.subscribe(() => {
      const elementList = document.querySelectorAll(this.BoldBiTranslationBase)
      elementList.forEach(item => {
        this.checkItemForTranslations(item); 
      });  
    });
  }

  checkItemForTranslations(item) {
    const token = item.getAttribute('data-tooltip');
    if(this.commonService.checkIfStringContainsString(token, 'BoldBiTranslation')) {
      item.setAttribute(
        'data-tooltip', 
        this.axivasTranslateService.getTranslationTextForToken(token.trim())
      );
    }
    const translationToken = item.innerHTML.match(/(\bBoldBiTranslation\S+\b)/ig);
    if (translationToken != null) {    
      let translationTokenDetail = translationToken[0];      
      translationTokenDetail = translationTokenDetail.split('<')[0];
      item.innerHTML = item.innerHTML.replace(translationTokenDetail, this.axivasTranslateService.getTranslationTextForToken(translationTokenDetail));  
    }    
  }

  refreshBoldBiData() {
    this.sharedAPI.refreshBoldBi().subscribe(result => {
      this.applicationInfoService.lastBoldBiSync = result.result.lastSyncTime;
      this.dashboard.loadDashboard();
    });
  }
}

export class Item {
  Name: string;
  Description: string;
  Id: string;
  Version: string;
  IsPublic: boolean;
  ItemLocation: string;
  CategoryName: string;
}