import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { MiddlewareSignalrService } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { RingcentralService } from 'app/jollyjupiter/service/ringcentral.service';
import { environment } from 'environments/environment';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';

@Component({
  selector: 'app-custom-dial',
  templateUrl: './custom-dial.component.html',
  styleUrls: ['./custom-dial.component.scss']
})
export class CustomDialComponent extends CustomControlBaseClass implements OnInit {

  constructor(
    private injector: Injector,
    private middlewareSignalrService: MiddlewareSignalrService,
    private ringcentralService: RingcentralService,
    private ringcentralwebphoneService: RingcentralwebphoneService
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.controlGuidPredefine = 'dial';
    this.checkAttributes();
  }

  callNumber() {
    let numberToDial = this.controlValue;
    if (this.commonService.isNullOrUndefined(numberToDial)) {
      numberToDial = '';
    }

    if (this.controlDefinition.parentContainerId !== null &&
      this.applicationInfoService.controls.ContainsKey(this.controlDefinition.parentContainerId)) {
        const control = this.applicationInfoService.controls.Item(this.controlDefinition.parentContainerId);
        const pre = this.applicationInfoService.entities.Item(control.uiEntityInstanceId).data[control.value];
        if (!this.commonService.isNullOrUndefined(pre))  {
          numberToDial = pre.concat(numberToDial);
        }
    }

    let memberId = null;
    if (!this.commonService.isNullOrUndefined(this.controlDefinition.uiEntityInstanceId)) {
      memberId = this.entityService.getEntityMember(
        this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId),
        this.controlDefinition.value
      );
    }

    switch(this.applicationInfoService.phoneConnection) {
      case this.applicationInfoService.phoneRingCentral:
        this.ringcentralService.makeCall(numberToDial);
        break;
      case this.applicationInfoService.phoneRingCentralWebRTC:
        this.ringcentralwebphoneService.makeCall(numberToDial, 1);
        break;  
      case this.applicationInfoService.phone3cx:
        this.middlewareSignalrService.call(numberToDial, true, this.controlDefinition.uiEntityInstanceId, memberId.id);
        break;
    }
  }

  keyDown() {
    this.eventService.entityKeyDownEvent.emit(this.controlDefinition.uiEntityInstanceId)
  }

  checkIfCallControlIsEnabled() {
    let returnValue = this.checkIfControlIsEnabled();
    if (this.ringcentralService.callInitialized) {
      returnValue = false;
    }
    return returnValue;
  }

  copyToClipboard() {
    if (this.commonService.isNullOrUndefined(this.controlValue)) {
      return;
    }

    this.commonService.copyTextToClipboard(this.controlValue);
    this.messagingService.showDefaultSuccess(
      'General.CopyToClipBoard.Header',
      'General.CopyToClipBoard.Message',
      false
    );

    let userId = null;
    if (!this.commonService.isNullOrUndefined(this.ringcentralService.userInfo)) {
      if (!this.commonService.isNullOrUndefined(this.ringcentralService.userInfo.extensionId)) {
        userId = this.ringcentralService.userInfo.extensionId;
      }
    }
    this.ringcentralService.logCall(
      userId, 
      this.controlValue, 
      this.applicationInfoService.projectID, 
      this.applicationInfoService.currentAccount.id
    );
  }
}
