import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-aileadscoring',
  templateUrl: './aileadscoring.component.html',
  styleUrls: ['./aileadscoring.component.scss']
})
export class AileadscoringComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="192998"> </jj-container>`;
  innerHTML = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.trackingService.trackPageView();
  }

}
