import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { ImportHelperService } from '../import-helper.service';

@Component({
  selector: 'app-import-uploadfile',
  templateUrl: './import-uploadfile.component.html',
  styleUrls: ['./import-uploadfile.component.scss']
})
export class ImportUploadfileComponent implements OnInit {
  public progress: number;
  public message: string;
  uploadingFile = false;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(
    private importHelperService: ImportHelperService,
    private eventService: EventService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit() {
  }

  uploadFile(event) {
    this.uploadingFile = true;
    const files: FileList = event.target.files;
    const formData = new FormData();
    for ( let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    this.sharedAPI.uploadFile(formData).subscribe(
      (uploadFileResult) => {
      console.warn('uploadFileResult', uploadFileResult);
      this.importHelperService.currentImportFile = files[0].name;
      this.importHelperService.currentImportFileFormData = files[0];
      this.eventService.importFileUploadedEvent.emit(uploadFileResult);
    }, 
      (err) => { console.warn(err); this.uploadingFile = false; }
    );
  }

}
