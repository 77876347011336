import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SharedAPI } from '../service/sharedAPI';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Injectable({
  providedIn: 'root'
})
export class AxivasTranslateService {
  public translationTextArray: any;
  public translationTextArrayGlobal = null;
  public translationTextArrayProject = null;
  public translationTextIdArray: any;
  public currentLanguage = 'de';
  
  translatedValues = [];

  constructor(
    private sharedAPI: SharedAPI,
    private authService: AuthService,
    private applicationInfoService: ApplicationInfoService  
  ) { }

  isNullOrUndefined(value) {
    let returnValue = false;
    if (value === undefined || value === null) {
      returnValue = true;
    }
    return returnValue;
  }

  public getTranslationTextForId(translationTokenId, defaultValue = null) {    
    let translationToken = null;
    
    if (this.translationTextArrayProject != null) {
      translationToken = this.translationTextArrayProject.find(e => e.translationToken.id == translationTokenId);
    }
    // this.translationTextArray.find(translationItem => translationItem.id == translationTokenId);
    if (translationToken == undefined) {
      if (this.translationTextArrayGlobal != null) {
        translationToken = this.translationTextArrayGlobal.find(e => e.translationToken.id == translationTokenId);
      }        
    }    
    
    if (translationToken) {
      return translationToken.translationToken.tokenFullName
    } else {
      if (defaultValue != null) {
        return defaultValue;
      } else {
        return translationTokenId.toString();
      }      
    }
  }

  public getTranslationTextForObject(object, token) {
    if (this.isNullOrUndefined(object[token])) {
      if (this.isNullOrUndefined(object.entityName)) {
        return object.defaultName;
      } else {
        return object.entityName;
      }
    } else {
      return this.getTranslationTextForToken(object[token].tokenFullName);
    }
  }

  public getTranslationTextForToken(tokenName: any, showMessage = false): string {
    let returnValue: any;
    if (this.isNullOrUndefined(tokenName)) {
      return '';
    }

    if (tokenName == 'CURRENT_RECORDSET_HEADER') {      
      if (this.applicationInfoService.currentContact == null || this.applicationInfoService.currentContact == undefined) {
        tokenName = 'CurrentRecordset.Account.Header';        
      } else {
        tokenName = 'CurrentRecordset.Contact.Header';
      }
    }

    if (!tokenName.includes('.')) {
      return tokenName;
    }

    // if (this.isNullOrUndefined(this.translationTextArray)) {
    //   return tokenName;
    // }

    let value = undefined;
    if (this.translationTextArrayProject != null) {
      value = this.translationTextArrayProject.find(e => e.translationToken.tokenFullName == tokenName);
    }
    if (value == undefined) {
      if (this.translationTextArrayGlobal != null) {
        value = this.translationTextArrayGlobal.find(e => e.translationToken.tokenFullName == tokenName);
      }        
    }    
    if (value == undefined) {
      if (this.translationTextArray != null) {
        value = this.translationTextArray.find(e => e.token === tokenName);
      }
    }
    
    if (value == undefined) {
      return tokenName;
    } else {
      return value.translation;
    }    
  }

  updateTranslationCache(): Promise<any> {
    return new Promise((updateTranslationCacheResolve, updateTranslationCacheReject) => {
      this.authService.AuthGet(environment.apiUrl +
        '/AdminRefreshRepositoryCache/refresh/entity/Translation/0').subscribe(response => {
          updateTranslationCacheResolve(null);
        },
        error => {
          updateTranslationCacheReject(error);
        });
    });
  }


  public getTranslationIds(lang: string = this.currentLanguage): Promise<any> {
    return new Promise((getTranslationResolve, getTranslationReject) => {
      this.currentLanguage = lang;
      this.sharedAPI.getTranslationIdsForLanguage(lang).subscribe(
        (res: Response) => {                    
        this.translationTextArray = res;        
        getTranslationResolve(res);
      },
      error => {
        getTranslationReject(error);
        this.applicationInfoService.showLoadProjectSpinner = true;
        this.applicationInfoService.errorOccured = true;
      });
    });
  }
}

@Pipe({
  name: 'axivastranslate',
  pure: false
})

@Injectable({
  providedIn: 'root'
})
export class AxivasTranslateServicePipe implements PipeTransform {
  constructor(
    private axivasTranslateService: AxivasTranslateService    
  ) {}

  transform(originalText: any) {
    switch (originalText) {
      default:
        return this.axivasTranslateService.getTranslationTextForToken(originalText);        
    }    
  }
}
