import { Component, OnDestroy, OnInit} from '@angular/core';

import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';


import { Subscription } from 'rxjs';


@Component({
  selector: 'app-phone-ringcentral',
  templateUrl: './phone-ringcentral.component.html',
  styleUrls: ['./phone-ringcentral.component.scss']
})
export class PhoneRingcentralComponent implements OnInit , OnDestroy{


  constructor(
    public commonService: CommonService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,

    ) { }

  ringCentralEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();

  openMenu: boolean = false;
  isAuthenticated: boolean;
  outgoingCall = false;

  pauseCall = false
  recordingCall = false
  outgoingCallUser= {};
  user={}
  showIncomingCall:boolean=false;
  callAccepted





  ngOnInit(): void {

    this.ringCentralEventSubscription = this.ringcentralwebphoneService.ringCentralWebPhoneEvent.subscribe( event =>{
      if(event.id === 'expandExtension'){
        this.openMenu= true;
        this.applicationInfoService.paletteCollapsed = true;
      }

      if(event.id === 'collapseExtension'){
        this.openMenu= false
      }
      
      if(event.id == 'callTerminated'){
        this.showIncomingCall=false
      }

      if(event.id == 'startTransferCall'){
        this.openMenu = true;
        this.applicationInfoService.paletteCollapsed = true;
      }

      if(event.id === 'callRejected'){
        this.user={}
        this.showIncomingCall=false
      }

      if(event.id == 'hangUp'){
        this.user = {}
      }
    })

    this.customEventSubscription = this.eventService.customEvent.subscribe((event)=>{
      if (event.id == 'dashboardCallAccepted'){
        this.user = event.user;
      }

      if(event.id == 'dashboardIncomingCallUser'){
        this.user= event.user;
        this.showIncomingCall = true;
      }

      if(event.id === 'collapseExtension') {
        this.openMenu= false
      }
    })
  }

  openPhoneMenu():void{
    if(this.ringcentralwebphoneService.extension != null)
    this.openMenu = !this.openMenu;
    if (this.openMenu == true) {
      this.applicationInfoService.paletteCollapsed = true;
    }
    this.eventService.customEvent.emit({id: 'widgetMenu', menuState: this.openMenu})
  }

  logInRingCentral() {
    this.ringcentralwebphoneService.login();
  }

  logoutRingCentralRequest(){
    this.openMenu = true;
    this.applicationInfoService.paletteCollapsed = true;
    this.eventService.customEvent.emit({id: 'widgetLogoutRequest'})
  }

  checkAuthKey() {
    const code = localStorage.getItem("ringCentralWebRTCAuthCode");
    if (code && !this.ringcentralwebphoneService.loginProcessRunning && this.ringcentralwebphoneService.extension==null) {
      this.ringcentralwebphoneService.login(false);
      return true
    }
  }

  closeCall(){
    this.ringcentralwebphoneService.hangUp()
    this.eventService.customEvent.emit({ id: 'widgetCallClosed' });
    this.user={}
    this.showIncomingCall=false;
    this.callAccepted=false;

  }

  acceptCall(){
    this.eventService.customEvent.emit({id: 'widgetAcceptCall'})
    this.ringcentralwebphoneService.accept()
    //console.log('click accept')
    this.callAccepted = true

  }

  rejectCall(){
    //console.log('click reject call')
    this.ringcentralwebphoneService.reject();
    this.showIncomingCall= false;
    this.user={};
    this.callAccepted=false;
  }


  ngOnDestroy():void{
   if (this.customEventSubscription) {this.customEventSubscription.unsubscribe();}
   if(this.ringCentralEventSubscription){ this.ringCentralEventSubscription.unsubscribe()}
  }

  toggleCallGroupAvailability() {
    if (this.ringcentralwebphoneService.availabilityMode == 'inbound') {
      this.ringcentralwebphoneService.availabilityMode = 'backoffice';
      this.ringcentralwebphoneService.changeAvailabilityMode();
    } else if (this.ringcentralwebphoneService.availabilityMode == 'backoffice') {
      this.ringcentralwebphoneService.availabilityMode = 'inbound';
      this.ringcentralwebphoneService.changeAvailabilityMode();
    }    
  }
}
