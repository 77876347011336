import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-eventtrigger',
  templateUrl: './eventtrigger.component.html',
  styleUrls: ['./eventtrigger.component.scss']
})
export class EventtriggerComponent implements OnInit {
  workflowStepResultEvents = [];
  eventTrigger = [];
  eventSources = [];
  steps = [];
  campaigns = [];
  loadingData = true;
  eventTriggerTypes = [];
  result = { id: 2313 };
  eventTriggerGroups = [{ name: 'EventTrigger.Label.Connected', data: [] }, { name: 'EventTrigger.Label.NotConnected', data: [] }];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['eventtriggerresult'])) {
      this.result = this.applicationInfoService.miscSettings['eventtriggerresult'];
      this.applicationInfoService.miscSettings['eventtriggerresult'] = null;
    }

    this.eventTriggerTypes = this.applicationInfoService.applicationSettings['eventTriggerTypes'].split(';');
    this.getWorkflowSteps();
    this.getCampaigns();
    this.getEventTypes();    
    this.getWorkflowStepResultEvents();
  }

  getEventTypes() {
    this.externaldatasourceService.executeExternalDataSource(387, [54]).then(getEventTypesResult => this.eventSources = getEventTypesResult );
  }

  getWorkflowStepResultEvents() {
    if (this.result == null) { 
      this.getEventTrigger();    
      return; 
    }
    
    this.externaldatasourceService.executeExternalDataSource(489, [this.result.id])
    .then(getWorkflowStepResultEventsResult => {
      this.workflowStepResultEvents = getWorkflowStepResultEventsResult;
      this.getEventTrigger();    
    });
  }

  getEventTrigger() {
    this.eventTriggerGroups = [{ name: 'EventTrigger.Label.Connected', data: [] }, { name: 'EventTrigger.Label.NotConnected', data: [] }];
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(478, []).then(getEventTriggerResult => {
      this.eventTrigger = getEventTriggerResult;
      getEventTriggerResult.forEach(element => {
        if (this.isActivated(element, true)) {
          this.eventTriggerGroups[0].data.push(element);
        } else {
          this.eventTriggerGroups[1].data.push(element);
        }
      });
      this.loadingData = false;;
    });
  }

  createNewTrigger() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(475, [])
    .then(() => { 
      this.getEventTrigger(); 
      this.loaderService.display(false);
    }).catch(error => {
      this.loaderService.display(false);
    });
  }

  deleteTrigger(trigger) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(477, [trigger.id])
    .then(() => {
      this.getEventTrigger() ;
      this.loaderService.display(false);
    }).catch(error => {
      this.loaderService.display(false);
    });
  }

  getWorkflowSteps() {
    this.externaldatasourceService.executeExternalDataSource(350, []).then(result => { this.steps = result; })
  }

  getCampaigns() {
    this.externaldatasourceService.executeExternalDataSource(123, []).then(result => { this.campaigns = result; })
  }

  changeRow(event) {
    this.externaldatasourceService.executeExternalDataSource(476, [
      this.commonService.getModifyArrayBody(event, [])
    ])
    .then(() => {});
  }

  getResults(event) {
    if (event.newTaskWorkflowStepId == null) {
      return [];
    } else {
      return this.applicationInfoService.steps.Item(event.newTaskWorkflowStepId.toString()).results;
    }
  }

  stepChanged(event) {
    event.newTaskWorkflowResultId = null;
  }

  toggleEvent(event) {
    let recordId = null;
    if (this.workflowStepResultEvents != null) {
      this.workflowStepResultEvents.forEach(workflowStepResultEvent => {
        if (event.id == workflowStepResultEvent.eventTriggerId && workflowStepResultEvent.workflowStepResultId == this.result.id) {
          recordId = workflowStepResultEvent.id;
        }
      });
    }
    if (recordId == null) {      
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(486, [this.result.id, event.id]).then(() => { 
        this.loaderService.display(false);
        this.getWorkflowStepResultEvents();
      })
      .catch(() => { this.loaderService.display(false); });
    } else {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(488, [recordId]).then(() => { 
        this.loaderService.display(false);
        this.getWorkflowStepResultEvents();
      })
      .catch(() => { this.loaderService.display(false); });
    }
  }

  isActivated(dataItem, showInfo = false) {
    let returnValue = false;
    if (this.result == null) {
      return false;
    }

    if (this.workflowStepResultEvents != null) {
      this.workflowStepResultEvents.forEach(workflowStepResultEvent => {        
        if (dataItem.id == workflowStepResultEvent.eventTriggerId && workflowStepResultEvent.workflowStepResultId == this.result.id) {
          returnValue = true;
        }
      });
      if (showInfo) {
      }
    }
    return returnValue;
  }
}
