import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-projectpackages',
  templateUrl: './projectpackages.component.html',
  styleUrl: './projectpackages.component.scss'
})

export class ProjectpackagesComponent implements OnInit {
  packages = [];
  expandedItems = [];

   constructor(
    public applicationInfoService: ApplicationInfoService,
    public packagefeatureService: PackagefeatureService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit(): void {
    this.packages = JSON.parse(JSON.stringify(this.packagefeatureService.allPackages));     
    this.packages.forEach(projectpackage => {
      projectpackage.featuresRelations.forEach(feature => {
        feature.defaultName = this.getFeatureName(feature.featureId);
      });
      projectpackage.featuresRelations.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
    });
  }

  selectPackageForProject(selectedPackage) {
    if (!this.applicationInfoService.isDeveloper) {
      return;
    }
    this.applicationInfoService.projectInfo.packageId = selectedPackage.id;
    this.packagefeatureService.getProjectFeatures();
    let dataSourceParam = this.commonService.getModifyArrayBody({
      id: this.applicationInfoService.projectID,
      packageId: this.applicationInfoService.projectInfo.packageId
    }, []);
    this.externaldatasourceService.executeExternalDataSource(260, [dataSourceParam])
    .then(()  => { })
    .catch(() => { });
  }

  getFeatureName(featureId) {
    const feature = this.packagefeatureService.getFeatureById(featureId);
    if (feature) {
      return feature.defaultName;
    } else {
      return '';
    }
  }

  packageExpanded(packageToCheck) {
    return this.commonService.checkIfItemIsInArray(this.expandedItems, packageToCheck.id);
  }

  toggleExpansion(packageToToggle) {
    if (this.commonService.checkIfItemIsInArray(this.expandedItems, packageToToggle.id)) {
      this.commonService.removeItemFromArray(this.expandedItems, packageToToggle.id);
    } else {
      this.expandedItems.push(packageToToggle.id);
    }
  }
}
