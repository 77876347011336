<div class="everleadTooltip"
     [style.left]="left + 'px'" 
     [style.top]="top + 'px'"
     [ngClass]="{
          hideInDom: isValueEmpty(),
          showItem: showItem
     }"
>    <div class="everleadTooltip__header" *ngIf="showHeader()">
          <label [innerHTML]=tooltipHeader></label>
     </div>
     <div *ngIf="showTooltipTemplate==true">
          <div [innerHTML]="innerHTML"></div>
         
     </div>
     <div class="everleadTooltip__value">{{ tooltip }}</div>
</div>

