<div class="wizardDataImportList__wrapper">    
    <table class="ExcelTable2007">
        <tr>
            <th align="left" valign="bottom" class="heading">&nbsp;</th>
            <th align="left" valign="bottom" *ngFor="let header of getValidHeaders(wizardService.wizardArray.selectedSheet.data.headers)">{{ header.name }}</th>
        </tr>
        <tr *ngFor="let data of wizardService.wizardArray.selectedSheet.data.data; let rowNumber = index">
            <td align="left" valign="bottom"  class="heading">{{ rowNumber }}</td>
            <td align="left" valign="bottom" *ngFor="let header of wizardService.wizardArray.selectedSheet.data.headers; let i = index">
                {{ data[i] }}
            </td>
        </tr>
    </table>
</div>