import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApplicationInfoService } from '../../../core/application/application-info.service';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { MiddlewareSignalrService } from '../../../jollyjupiter/signalr/middleware-signalr-service.service';
import { Subscription, timer } from 'rxjs';
import { EventService } from '../../../jollyjupiter/service/event.service';
import * as moment from 'moment';
import { SettingsService } from 'app/shared/service/settings.service';
import { Dictionary } from 'app/core/dictionary';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { Router } from '@angular/router';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  updateAvailableActivitiesSubscriptions: Subscription = new Subscription();
  languageUpdateEventSubscription: Subscription = new Subscription();
  languageCheckTimerSubscription: Subscription = new Subscription;
  activityTimerSubscription: Subscription;

  maxNumberOfFavorites = 5;
  currentFavoritNumber = 1;
  lastFavItemName = 'favorite';
  favoritesInitialized = false;

  items: MenuItem[];
  activityItems: MenuItem[] = [];
  interuptItems: MenuItem[] = [];
  useNewSoftphone = '';

  // Hours
  public shortBreakTime: any;
  public longBreakTime: any;
  public paidHours: any;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public middlewareSignalrService: MiddlewareSignalrService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private projectService: ProjectService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit() {
    this.useNewSoftphone = environment.newSoftPhone;
    this.updateAvailableActivitiesSubscriptions = this.eventService.updateAvailableActivitiesEvent.subscribe(data => {
      // this.getAvailableActivities();
    });

    this.languageUpdateEventSubscription = this.eventService.languageUpdateEvent.subscribe(() => {
      this.getAvailableActivities();
    });
    if (this.applicationInfoService.phoneConnection == this.applicationInfoService.phone3cx) {
      this.launchLanguageCheckTimer();
    }
  }


  launchLanguageCheckTimer() {
    const responseTimer = timer(10000, 1000);
    this.languageCheckTimerSubscription = responseTimer.subscribe(() => {
      if (this.axivasTranslateService.getTranslationTextForToken('General._.Language') !== 'General._.Language') {
        this.getAvailableActivities();
        this.languageCheckTimerSubscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
      if (this.updateAvailableActivitiesSubscriptions) { this.updateAvailableActivitiesSubscriptions.unsubscribe(); }
      if (this.activityTimerSubscription) { this.activityTimerSubscription.unsubscribe(); }
      if (this.languageUpdateEventSubscription) { this.languageUpdateEventSubscription.unsubscribe(); }
      if (this.languageCheckTimerSubscription) { this.languageCheckTimerSubscription.unsubscribe(); }      
  }

  getAvailableActivities() {
    if (this.middlewareSignalrService.connectionState !== 0) {
      // console.warn('connectionState', this.middlewareSignalrService.connectionState);
      this.middlewareSignalrService.getActivities().then(result => {
        this.activityItems = [];
        this.interuptItems = [];
        if (result != null) {
          result.forEach(element => {
            // console.log('Element info', element, element.activityId);
            let item: MenuItem;
            if (this.useNewSoftphone === 'true') {
              item = {
                styleClass: 'globalFontSize',
                label: this.axivasTranslateService.getTranslationTextForToken(element.translationToken),
                command: () => { this.setCurrentActivity(element.activityId, false, element); }
              };
            } else {
              item = {
                styleClass: 'globalFontSize',
                label: this.axivasTranslateService.getTranslationTextForToken(element.translationTokenFullName),
                command: () => { this.setCurrentActivity(element.activityId); }
              };
            }
            if (element.isBreak) {
              this.interuptItems.push(item);
            } else {
              this.activityItems.push(item);
            }

          });
          let logOutItem: MenuItem;
          logOutItem = {
            label: this.axivasTranslateService.getTranslationTextForToken('Activity.Menu.Logout'),
            command: (event) => {
              this.logout();
            }
          };
          this.interuptItems.push(logOutItem);

          this.items = [{
            label: this.axivasTranslateService.getTranslationTextForToken('Activity.Menu.Available'),
            items: this.activityItems
          },
          {
              label:  this.axivasTranslateService.getTranslationTextForToken('Activity.Menu.Interrupt'),
              items: this.interuptItems
          }];
        }
        this.startActivityCounter();
        this.getCurrentFavorits(true);
        this.middlewareSignalrService.getCurrentTimeRecordingStatus();
      })
      .catch(error => {
        console.error('getAvailableActivities', error);
      });
    }
  }

  setCurrentActivity(activityID: number, saveFavorit: boolean = true, activity = null) {
    const activityInfo = this.applicationInfoService.availableActivities.Item(activityID.toString());
    if (activityInfo.isBreak && this.applicationInfoService.currentTask != null) {
        this.confirmationService.confirm({
          message: this.axivasTranslateService.
            getTranslationTextForToken(this.axivasTranslateService.getTranslationTextForToken('Activity.Break.ConfirmUnlocking')),
          header: this.axivasTranslateService.
            getTranslationTextForToken(this.axivasTranslateService.getTranslationTextForToken('Activity.Break.ConfirmUnlockingHeader')),
          icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // Unlocking account
          this.projectService.saveCurrentTask()
          .then(() => {
            // this.bookActivity(activityID, saveFavorit);
            this.projectService.unlockAccount();
            this.applicationInfoService.currentTask = null;
            this.bookActivity(activityID, saveFavorit, activity);
            this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/dashboard']);
          });
        },
        reject: () => {

        }
        });
    } else {
      this.bookActivity(activityID, saveFavorit, activity);
    }
  }

  bookActivity(activityID: any, saveFavorit: boolean = true, activity = null) {
    let bookPromise;
    if (this.useNewSoftphone === 'true') {
      bookPromise = this.middlewareSignalrService.hubConnection.invoke('TimeRecordingChangeStatus' , activity.isUserAvailability
      , activity.isQueueAvailability);
    } else {
      bookPromise = this.middlewareSignalrService.hubConnection.invoke('TimeRecordingChangeStatus', activityID.toString(),
      this.applicationInfoService.getTimeRecordingProjectId().toString());
    }
    bookPromise.then(() => {
      if (saveFavorit) {
        this.saveFavorit(this.applicationInfoService.availableActivities.Item(activityID.toString()));
      }
    })
    .catch(error => {
      console.error('setCurrentActivity error', error);
    });
  }

  startActivityCounter() {
    // return; // TODO MIDDLEWARE
    if (this.useNewSoftphone === 'true') {
      return;
    }
    if (this.activityTimerSubscription) {
      console.log('already running');
      return;
    }
    const activityTimer = timer(1000, 1000);

    this.activityTimerSubscription = activityTimer.subscribe(() => {
      if (this.applicationInfoService.currentTimeRecordingStatus) {
        let dateDiff: number;
        const difference =
          Math.floor(new Date().getTime() / 1000) -
          new Date(this.applicationInfoService.currentTimeRecordingStatus.userStateChangeDate.seconds).getTime();
        dateDiff = difference / 3600;
        // console.warn(
        //   Math.floor(new Date().getTime() / 1000),
        //   new Date(this.applicationInfoService.currentTimeRecordingStatus.userStateChangeDate.seconds).getTime(),
        //   dateDiff);
        this.longBreakTime = this.applicationInfoService.currentTimeRecordingStatus.unpaidBreaks;
        this.shortBreakTime = this.applicationInfoService.currentTimeRecordingStatus.paidBreaks;
        this.paidHours = this.applicationInfoService.currentTimeRecordingStatus.paidHours;
        if (this.applicationInfoService.currentTimeRecordingStatus.currentActivity) {
          if (this.applicationInfoService.currentTimeRecordingStatus.currentActivity.isPaid) {
            this.paidHours = (this.paidHours + dateDiff);
          }
          if (this.applicationInfoService.currentTimeRecordingStatus.currentActivity.isBreak) {
            if (this.applicationInfoService.currentTimeRecordingStatus.currentActivity.isPaid) {
              this.shortBreakTime = (this.shortBreakTime + dateDiff);
            } else {
              this.longBreakTime = (this.longBreakTime + dateDiff);
            }
          }
        }
      }
    });
  }

  logout() {
    this.middlewareSignalrService.logout();
  }


  // Favorites
  getCurrentFavorits(initial: boolean) {
    if (initial && this.favoritesInitialized) {
      return;
    }
    this.favoritesInitialized = true;
    if (this.applicationInfoService.availableActivities == null) { return; }

    this.applicationInfoService.availableFavoritActivities = [];
    this.applicationInfoService.availableFavoritActivitiesDic = new Dictionary();
    this.currentFavoritNumber = Number(this.settingsService.getUserSettingByString('lastfav', '1').value);
    for (let i = 1; i <= this.maxNumberOfFavorites; i++) {
      let favorit: any = this.settingsService.getUserSettingByString(this.lastFavItemName + i, null);
      if (favorit.value != null) {
        if (this.applicationInfoService.availableActivities.ContainsKey(favorit.value)) {
          favorit = this.applicationInfoService.availableActivities.Item(favorit.value);
          this.applicationInfoService.availableFavoritActivities.push(favorit);
          this.applicationInfoService.availableFavoritActivitiesDic.Add(favorit.activityId, '');
        }
      }
    }
  }

  saveFavorit(favorit: any) {
    if (favorit.isBreak) { return; }
    if (this.applicationInfoService.availableFavoritActivitiesDic.ContainsKey(favorit.activityId)) { return; }

    this.currentFavoritNumber = this.currentFavoritNumber + 1;
    if (this.currentFavoritNumber > this.maxNumberOfFavorites) {
      this.currentFavoritNumber = 1;
    }
    const userSettingValue = this.currentFavoritNumber.toString();
    this.settingsService.setUserSetting('lastfav', userSettingValue);
    this.settingsService.setUserSetting(this.lastFavItemName + this.currentFavoritNumber.toString(), favorit.activityId)
    .then(() => {
      this.getCurrentFavorits(false);
    });
  }


  trLogout() {
    this.middlewareSignalrService.timeRecordingChangeStatus();
  }
}
