<ng-container *ngIf="loadingData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</ng-container>

<div *ngIf="loadingData == false" [ngClass]="{ compactTaskListWrapperDark: darkMode == true }">
    <div class="compactTaskListHeader">
        <div class="row maxWidth compactTaskListRow"
            *ngIf="controlDefinition.displayText != null && controlDefinition.displayText != ''">
            <div class="col-md-12">
                <label class="compactTaskListHeader__label">{{ controlDefinition.displayText | texttransform
                    }}</label>
            </div>
        </div>
        <div class="maxWidth compactTaskListRow__header">
            <div class="fingerCursor compactTaskListHeader__selectRow" *ngIf="this.createDataSource != null">
                <i class="fas fa-plus compactTaskListHeader__radio" (click)="addNew()"></i>
            </div>
            <div class="fingerCursor compactTaskListHeader__selectRow__wrapper">                
                <div class="compactTaskListHeader__selectRow__item">
                    <label class="ellipsisText">{{ 'CompactTaskList.Label.ContactLevel' | texttransform }}:</label>    
                </div>
                <div class="compactTaskListHeader__selectRow__item" [ngClass]="{ compactTaskListHeader__active: listMode == 1 }">
                    <input name="options" type="radio" [value]=toNumber(1) [(ngModel)]="listMode" (change)="changeListMode(1)" class="compactTaskListHeader__radio" >
                    <label (click)="changeListMode(1)">{{ 'CompactTaskList.Label.Account' | texttransform }}</label>                        
                </div>
                <div class="compactTaskListHeader__selectRow__item" [ngClass]="{
                    compactTaskListHeader__active: listMode == 2,
                    fingerCursor: contactAvailable(),
                    compactTaskListHeader__disabled: !contactAvailable()
                }">
                    <input name="options" type="radio" class="compactTaskListHeader__radio" [value]=toNumber(2) [(ngModel)]="listMode" (change)="changeListMode(2)">
                    <label (click)="changeListMode(2)">{{ 'CompactTaskList.Label.Contact' | texttransform }}</label>                            
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="controlView == 1" class="compactTaskListItem__wrapper compactTaskListItem__wrapperSmall">
        <div *ngFor="let dataItem of data" class="compactTaskListItem" [ngClass]="{ 
            hideInDom: resultCount(dataItem.stepId) == 0 || isValidStep(dataItem.stepId) == false || dataItem.followUpUserId == 6,
            'compactTaskListItem__highlight': dataItem.id == applicationInfoService.lastLogicalItemFromMyFollowups 
        }">
            <ng-container *ngIf="resultCount(dataItem.stepId) > 0 && isValidStep(dataItem.stepId)">
            <div class="maxWidth compactTaskListHeaderRow compactTaskListStepHeaderRow"> 
                <div class="compactTaskListHeaderRow__step">
                    <label class="compactTaskListItem__stepLabel">{{ getStepname(dataItem.stepId) | texttransform}}</label>
                </div>
                <div>
                    <i class="far fa-clock" style="margin: 0 5px"></i>
                    <input (dateTimeChange)="changeRow(dataItem)" [(ngModel)]="dataItem.followUpDate"
                        [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" class="taskListDateInput customTextControlWrapper__datetime" readonly
                        (click)="$event.stopPropagation()" appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>
                </div>
                <div *ngIf="!taskIsInfoOnly(dataItem)">
                    <i class="far fa-edit fa-lg compactTaskListItem__topIcon fingerCursor" (click)="editTask(dataItem)"
                        *ngIf="dataItem.stepLookupTypeId != 4190"></i>
                    <div *ngIf="dataItem.stepLookupTypeId == 4190" class="fingerCursor compactTaskListItem__donebox">
                        <label>{{ 'CompactTaskList.Label.Done' | texttransform }}</label>
                        <input type="checkbox" class="compactTaskListItem__checkbox customTextControlWrapper__textbox fingerCursor" (change)="bookSingleResult(dataItem)">
                    </div>                                  
                </div>
            </div>
            <div class="maxWidth compactTaskListHeaderRow">
                <div class="compactTaskListHeaderRow__infoLabel">
                    <i class="fas fa-user-tie compactTaskListItem__icon" [tippy]="'CompactTaskList.Label.User' | texttransform"></i>
                    <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.User' | texttransform}}</label>
                </div>
                <div class="maxWidth">
                    <select class="maxWidth compactTaskListHeaderRow__white" [(ngModel)]="dataItem.followUpUserId" [tippy]="'CompactTaskList.Label.User' | texttransform" (change)="changeRow(dataItem)">
                        <option *ngFor="let projectUser of projectUsers" [ngValue]="projectUser.id">{{projectUser.userName }}</option>
                    </select>
                </div>
            </div>

            <div class="maxWidth compactTaskListHeaderRow">
                <div class="compactTaskListHeaderRow__infoLabel">
                    <i class="fas fa-user-tie compactTaskListItem__icon" [tippy]="'CompactTaskList.Label.User' | texttransform"></i>
                    <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.TaskContact' | texttransform}}</label>
                </div>
                <div class="maxWidth">
                    <select class="maxWidth compactTaskListHeaderRow__white" [(ngModel)]="dataItem.contactId" (change)="changeRow(dataItem)" [tippy]="'CompactTaskList.Label.TaskContact' | texttransform">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let contact of contacts" [ngValue]="contact.id">
                            {{ contact.firstName }} {{ contact.lastName }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="maxWidth compactTaskListHeaderRow">
                <div class="compactTaskListHeaderRow__infoLabel">
                    <i class="far fa-file-alt compactTaskListItem__icon" [tippy]="'CompactTaskList.Label.Notes' | texttransform"></i>
                    <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.Subject' | texttransform}}</label>
                </div>
                <div class="maxWidth">
                    <input type="text" [(ngModel)]="dataItem.subject" class="maxWidth compactTaskListHeaderRow__white"
                        (change)="changeRow(dataItem)" [tippy]="'CompactTaskList.Label.Subject' | texttransform" >
                </div>
            </div>           
            <div class="maxWidth compactTaskListHeaderRow">
                <div class="compactTaskListHeaderRow__infoLabel">
                    <i class="far fa-sticky-note compactTaskListItem__icon" [tippy]="'CompactTaskList.Label.Notes' | texttransform"></i>
                    <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.Notes' | texttransform}}</label>
                </div>
                <div class="maxWidth">
                    <input type="text" [(ngModel)]="dataItem.notes" class="maxWidth compactTaskListHeaderRow__white"
                        (change)="changeRow(dataItem)" [tippy]="'CompactTaskList.Label.Notes' | texttransform" >
                </div>
            </div>           
        </ng-container>
        </div>
    </div>












    <div *ngIf="controlView == 2" class="compactTaskListItem__wrapperlarge">
 
        <div *ngFor="let dataItem of data" class="compactTaskListItem">
            <div class="row maxWidth compactTaskListRow controlView2container">
                <div class="col-md-12 compactTaskListItem__lineOne compactTaskListItem__col">
                    <label class="compactTaskListItem__stepLabel"><span class="compactTaskListItem__id">{{ dataItem.id }} </span> {{ getStepname(dataItem.stepId) | texttransform
                        }}</label>
                </div>
            </div>
            <div class="row maxWidth compactTaskListRow">                
                <div class="col-md-5">
                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="far fa-clock compactTaskListItem__icon"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.FollowUpDate' |
                                axivastranslate }}</label>
                            <input (dateTimeChange)="changeRow(dataItem)" [(ngModel)]="dataItem.followUpDate"
                                [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" class="compactTaskListItem__select customTextControlWrapper__datetime"
                                readonly (click)="$event.stopPropagation()" appendTo="body" aria-modal="true">
                            <owl-date-time #dt7></owl-date-time>
                        </div>
                    </div>

                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="fas fa-info-circle compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.Campaign' | texttransform"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.Campaign' |
                                axivastranslate }}</label>
                            <select class="compactTaskListItem__select" [(ngModel)]="dataItem.campaignId" disabled
                                (change)="changeRow(dataItem)"
                                [tippy]="'CompactTaskList.Label.Campaign' | texttransform">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">
                                    {{ campaign.defaultName | texttransform }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="fas fa-info-circle compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.Workflow' | texttransform"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.Workflow' |
                                axivastranslate }}</label>
                            <select class="compactTaskListItem__select customTextControlWrapper__dropdown" [(ngModel)]="dataItem.stepId" disabled
                                (change)="changeRow(dataItem)"
                                [tippy]="'CompactTaskList.Label.Workflow' | texttransform">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let workflow of workflows" [ngValue]="workflow.id">
                                    {{ workflow.workflowName | texttransform }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="fas fa-user-tie compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.User' | texttransform"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.User' |
                                axivastranslate
                                }}</label>
                            <select class="compactTaskListItem__select customTextControlWrapper__dropdown" [(ngModel)]="dataItem.followUpUserId"
                                [tippy]="'CompactTaskList.Label.User' | texttransform" (change)="changeRow(dataItem)">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let projectUser of projectUsers" [ngValue]="projectUser.id">{{
                                    projectUser.userName }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="fas fa-user compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.TaskContact' | texttransform"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.TaskContact' |
                                axivastranslate }}</label>
                            <select class="compactTaskListItem__select customTextControlWrapper__dropdown" [(ngModel)]="dataItem.contactId"
                                (change)="changeRow(dataItem)"
                                [tippy]="'CompactTaskList.Label.TaskContact' | texttransform">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let contact of contacts" [ngValue]="contact.id">
                                    {{ contact.firstName }} {{ contact.lastName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="fas fa-calendar-check compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.TaskContact' | texttransform"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.PersonalResubmission' | axivastranslate }}</label>
                            <input type="checkbox" [(ngModel)]="dataItem.isFollowUpAppointment" (change)="changeRow(dataItem)">
                        </div>
                    </div>
                </div>

                <div class="col-md-5">
                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col">
                            <i class="far fa-file-alt compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.Subject' | texttransform"></i>
                            <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.Subject' |
                                axivastranslate }}</label>
    
                            <input type="text" [(ngModel)]="dataItem.subject" class="compactTaskListItem__subject customTextControlWrapper__textbox"
                                (change)="changeRow(dataItem)"
                                [tippy]="'CompactTaskList.Label.Subject' | texttransform">
                        </div>
                    </div>                    
                    <div class="row maxWidth compactTaskListRow">
                        <div class="col-md-12 compactTaskListItem__col compactTaskListColumn">
                            <div class="compactTaskListColumn__item">
                                <i class="far fa-sticky-note compactTaskListItem__icon"
                                [tippy]="'CompactTaskList.Label.Notes' | texttransform"></i>
                                <label class="compactTaskListItem__detailLabel">{{ 'CompactTaskList.Label.Notes' |
                                    axivastranslate }}</label>
                            </div>
                            <div class="compactTaskListColumn__item">
                                <textarea type="text" [(ngModel)]="dataItem.notes" class="compactTaskListItem__largenotes customTextControlWrapper__textbox"
                                (change)="changeRow(dataItem)" 
                                [tippy]="'CompactTaskList.Label.Notes' | texttransform"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 compactTaskListItem__infoOnly" *ngIf="taskIsInfoOnly(dataItem)">
                    {{ 'CompactTaskList.Label.InfoOnly' | texttransform }}
                    <div>
                        <button *ngIf="applicationInfoService.isDeveloper" (click)="loadTask(dataItem)" class="maxWidth everleadCustomButton">
                            {{ 'CompactTaskList.Label.LoadTask' | texttransform }} <i class="far fa-caret-square-right"></i>
                        </button>
                    </div>
                </div>
                <div class="col-md-2 compactTaskListItem__buttons" *ngIf="!taskIsInfoOnly(dataItem)">
                    <button *ngIf="applicationInfoService.projectInfo.isUsingWorkflow" (click)="loadTask(dataItem)" class="maxWidth everleadCustomButton">
                        {{ 'CompactTaskList.Label.LoadTask' | texttransform }} <i class="far fa-caret-square-right"></i>
                    </button>
                    <button *ngIf="userService.hasPermission(deleteTaskUserRightName)" (click)="deleteTask(dataItem)" class="maxWidth everleadCustomButton" [title]="'CompactTaskList.Label.Delete' | texttransform">
                        {{ 'CompactTaskList.Label.Delete' | texttransform }} <i class="fas fa-trash"></i>
                    </button>
                </div>                
            </div>    
            <div class="compactTaskListRow compactTaskListRow__dynamicItem__wrapper">
                <label class="compactTaskListRow__dynamicItem__headerLabel" *ngIf="additionalItemsFound">{{ 'CompactTaskList.Label.AdditionalItemList' |
                    axivastranslate }}</label>
                <div *ngFor="let child of controlDefinition.childs" class="maxWidth">                   
                    <div *ngIf="child.controlType=='20' && child.isActive" class="compactTaskListRow__dynamicItem">
                        <label class="compactTaskListRow__dynamicItem__label">{{ child.displayText | texttransform }}</label>
                        <input *ngIf="getMemberInfo(child) == 'TEXT'" [(ngModel)]="dataItem[child.value]" (change)="changeRow(dataItem)"
                            [disabled]="!child.isEnabled" class="compactTaskListRow__dynamicItem__dataItem">
                        <select *ngIf="getMemberInfo(child) == 'NUMERIC'" [(ngModel)]="dataItem[child.value]" (change)="changeRow(dataItem)"
                            [disabled]="!child.isEnabled" class="compactTaskListRow__dynamicItem__dataItem">
                            <option *ngFor="let subDataItem of subDataLists[child.id]" [ngValue]="subDataItem.id">{{ subDataItem.defaultName }}</option>
                        </select>
                    </div>
                </div>
            </div>        
        </div>
        
    </div>
</div>