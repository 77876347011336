<div class="contactHeader">
    <div class="contactHeader__leadScoreWrapper">
            <div class="contactHeader__leadScoreWrapper__text contactHeader__leadScoreWrapper__center">
                <label class="contactHeader__leadScoreWrapper__text__topLabel">LEAD</label>
                <label class="contactHeader__leadScoreWrapper__label">{{ controlDefinition.displayText | texttransform }}</label>    
            </div>
            <div class="contactHeader__leadScoreWrapper__icon">
                <app-icon-leadscore [value]="roundUp(controlValue)" style="margin-left: 10px;"></app-icon-leadscore>
            </div>        
    </div>
    <div class="contactHeader__optinWrapper">
            <div class="contactHeader__optinWrapper__text">
                <label class="contactHeader__optinWrapper__text__topLabel">OPTIN</label>
                <label class="contactHeader__optinWrapper__text__bottomLabel">{{ optInOptOutText | texttransform}}</label>
            </div>
            <div class="contactHeader__optinWrapper__icon" [tippy]=optArrayString>
                <i class="fas fa-thumbs-up fa-2x optInOptOutIcon" *ngIf="currentOptState == optState.optIn"></i>
                <i class="fas fa-thumbs-down  fa-2x optInOptOutIcon optInOptOutIcon__red" *ngIf="currentOptState == optState.optOut"></i>
            </div>
    </div>
    <div class="contactHeader__vipWrapper" *ngIf="applicationInfoService.applicationSettings['showVipSettings'] == 'true'">
        <i class="fa-2x fingerCursor fa-star" [ngClass]="{ 
            'far isNoVip': !isVip,
            'fas isVip': isVip     
          }"
            [tippy]="'Vip.Notification.AddRemove' | texttransform" (click)="toggleVip()"></i>
    </div>
</div>    
<div class="blacklisted everleadCoralBackground" *ngIf="applicationInfoService.currentContact?.isBlacklisted == true">
    {{ 'ContactHeader.Label.ContactBlacklisted'}}
</div>
<div class="blacklisted everleadCoralBackground" *ngIf="applicationInfoService.currentAccount?.isBlacklisted == true">
    {{ 'ContactHeader.Label.AccountBlacklisted'}}
</div>
