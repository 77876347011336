import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-admin-dashboards',
  templateUrl: './admin-dashboards.component.html',
  styleUrls: ['./admin-dashboards.component.scss']
})
export class AdminDashboardsComponent implements OnInit {
  dashboards = [];
  showMode = 0;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getDashboards();
  }

  getDashboards() {
    this.externaldatasourceService.executeExternalDataSource(737).then(getDashboardsResult => { this.dashboards = getDashboardsResult; })
  }

  isDashboardVisible(dashboard) {
    let returnValue = true;
    if (this.showMode == 1 && dashboard.projectId != null) {
      returnValue = false;
    }
    if (this.showMode == 2 && dashboard.projectId != this.applicationInfoService.projectID) {
      returnValue = false;
    }
    return returnValue;
  }

  createDashboard(dashboard = null) {
    let array = {
      name: "new Dashboard",
      projectId: null,
      guid: "guid",
      isActive: true
    };

    if (dashboard) {
      array.guid = dashboard.guid;
      array.name = dashboard.name;
      array.projectId = this.applicationInfoService.projectID;
    }

    this.externaldatasourceService.executeExternalDataSource(744, [
      this.commonService.getModifyArrayBody(array, [])
    ]).then(() => { this.getDashboards()  });
  }

  updateDashboard(dashboard) {
    this.externaldatasourceService.executeExternalDataSource(745, [
      this.commonService.getModifyArrayBody(dashboard, [])
    ]).then(() => {});
  }

  deleteDashboard(dashboard) {
    this.externaldatasourceService.executeExternalDataSource(746, [dashboard.id]).then(() => {
      this.commonService.removeItemFromArray(this.dashboards, dashboard);
    });
  }
}
