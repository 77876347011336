import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-softphone-ringcentralwebrtc',
  templateUrl: './softphone-ringcentralwebrtc.component.html',
  styleUrls: ['./softphone-ringcentralwebrtc.component.scss']
})
export class SoftphoneRingcentralwebrtcComponent implements OnInit, OnDestroy {
  collapseExtensionEventSubscription: Subscription = new Subscription();
  expandExtensionEventSubscription: Subscription = new Subscription();
  expanded = false;
  lookuptableId = 0;
  recordingtypes = [];

  constructor(
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,    
    private commonService: CommonService,    
  ) { }

  ngOnInit(): void {
    this.collapseExtensionEventSubscription = this.ringcentralwebphoneService.collapseExtensionEvent.subscribe(() => {
      this.expanded = false;
    });
    this.expandExtensionEventSubscription = this.ringcentralwebphoneService.expandExtensionEvent.subscribe(() => {
      this.expanded = true;
    });
  }

  ngOnDestroy(): void {
    if (this.collapseExtensionEventSubscription) { this.collapseExtensionEventSubscription.unsubscribe(); }
    if (this.expandExtensionEventSubscription) { this.expandExtensionEventSubscription.unsubscribe(); }
  }

  login() {
    this.ringcentralwebphoneService.login();
  }

  checkAuthKey() {
    const code = localStorage.getItem("ringCentralWebRTCAuthCode");
    if (code && !this.ringcentralwebphoneService.loginProcessRunning && this.ringcentralwebphoneService.extension==null) {
      this.ringcentralwebphoneService.login(false);
    }
  }

  callRingCentralPopup() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['RingCentral', 'ringcentralpopup', 80]);
  }

  toggleRecord() {
    if (this.ringcentralwebphoneService.recordingtypes == null || this.ringcentralwebphoneService.recordingtypes.length < 2) {
      this.ringcentralwebphoneService.toggleRecord();
    } else {  
      if (this.ringcentralwebphoneService.sessionState.record) {
        this.ringcentralwebphoneService.toggleRecord();
      } else {
        this.expanded = true;      
        setTimeout(() => { 
          this.ringcentralwebphoneService.showRecordOptionsEvent.emit(null);          
        }, 200);  
      }
    }
  }
}

