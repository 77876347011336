<div class="customTextControlWrapper">
  <label class="customTextControlWrapper__label">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
  <div>
    <textarea *ngIf="aiReplacementText == null"
      class="customTextControlWrapper__textbox customControlValueWrapperTextArea"
      [disabled]="!checkIfControlIsEnabled()"      
      [placeholder]="controlDefinition.displayText | texttransform" 
      (change)="callMethod('onchange')"
      [(ngModel)]="controlValue"
      [tippy]="controlValue"
      [style.font-size.px]="getFontSize()"
    ></textarea>
    <textarea *ngIf="aiReplacementText != null"
      class="customTextControlWrapper__textbox customControlValueWrapperTextArea"
      [disabled]="!checkIfControlIsEnabled()"      
      [placeholder]="controlDefinition.displayText | texttransform" 
      [(ngModel)]="aiReplacementText"
      [tippy]="controlValue"
      [style.font-size]="getFontSize()"
    ></textarea>
  </div>  
  <div *ngIf="controlDefinition.controlType == '47' || 
              controlDefinition.controlType == '48' || 
              controlDefinition.controlType == '49'" class="aiTextarea">
    <button class="everleadCustomButton" (click)="getContactAiText(12)" *ngIf="controlDefinition.controlType == '48'">{{ 'TextArea.Label.AITransform' | texttransform }}</button>
    <button class="everleadCustomButton" (click)="getContactAiText(13)" *ngIf="controlDefinition.controlType == '47'">{{ 'TextArea.Label.AITransform' | texttransform }}</button>
    <button class="everleadCustomButton" (click)="getContactAiText(14)" *ngIf="controlDefinition.controlType == '49'">{{ 'TextArea.Label.AITransform' | texttransform }}</button>
    <div *ngIf="aiGenerating == true" class="aiTextarea__icons">
      {{ 'TextArea.Label.AICalculating' | texttransform }}
    </div>
    <div *ngIf="aiReplacementText != null && aiGenerating == false" class="aiTextarea__icons">
      <i class="fas fa-check everleadGreen fingerCursor" (click)="acceptAiText()"></i>
      <i class="fas fa-times everleadCoral fingerCursor" (click)="cancelAiText()"></i>        
    </div>
  </div>
</div>