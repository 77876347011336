import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternalContentService } from 'app/jollyjupiter/service/external-content.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-uistyle',
  templateUrl: './uistyle.component.html',
  styleUrls: ['./uistyle.component.scss']
})
export class UistyleComponent implements OnInit {
  projectCSS = '';
  saveCSSexternalDataSourceId = 218;
  getCSSexternalDataSourceId = 217;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private externalContentService: ExternalContentService,
    private applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getProjectCSS();
  }

  saveProjectCSS() {
    this.externaldatasourceService.executeExternalDataSource(this.saveCSSexternalDataSourceId, [
      this.applicationInfoService.projectInfo.uiStyle.id,
      this.commonService.cleanStringValue(this.projectCSS)
    ])
    .then(() => {
      this.messagingService.showDefaultSuccess('', 'General._.SuccessfullySaved', false);
      this.externalContentService.setProjectCSS(this.projectCSS);
    })
    .catch(error => {
      console.warn('saveProjectCSS', error);
      this.messagingService.showDefaultError('saveProjectCSS', '' + error, false);
    });
  }

  getProjectCSS() {
    this.externaldatasourceService.executeExternalDataSource(this.getCSSexternalDataSourceId, [
      this.applicationInfoService.projectInfo.uiStyle.id
    ])
    .then(getProjectCSSResult => { this.projectCSS = getProjectCSSResult.style; })
    .catch(error => { this.messagingService.showDefaultError('saveProjectCSS', error, false); });
  }
}
