import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-optinoptouteditor',
  templateUrl: './optinoptouteditor.component.html',
  styleUrls: ['./optinoptouteditor.component.scss']
})
export class OptinoptouteditorComponent implements OnInit {
  optInoptOutObject = null;
  lookupMediaTypes = [];
  lookupTypeTypes = [];
  contacts = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    const optinObject = this.applicationInfoService.miscSettings['optinoptouteditorvalue'];
    if (this.commonService.isNullOrUndefined(optinObject)) {
      this.optInoptOutObject = {
        id: null,
        accountId: this.applicationInfoService.currentAccount.id,
        startDate: new Date(Date.now()),
        endDate: null,
        comment: '',
        lookupTypeRefId: null,
        lookupMediumRefId: null
      }
    } else {
      let endDate = null;
      console.warn(optinObject['_20_enddate'])
      if (optinObject['_20_enddate'] != null) {
        endDate = new Date(optinObject['_20_enddate']);
      }
      this.optInoptOutObject = {
        id: optinObject['id'],
        accountId: optinObject['_20_accountid'],
        startDate: new Date(optinObject['_20_startdate']),
        endDate: endDate,
        comment: optinObject['_20_comment'],
        lookupTypeRefId: optinObject['_20_lookuptyperefid'],
        lookupMediumRefId: optinObject['_20_lookupmediumrefid']
      }
    }

    this.lookupMediaTypes = this.applicationInfoService.projectLookupTables.find(projectLookupTable => projectLookupTable.definitionId == 16 && projectLookupTable.source == 'central').lookups;
    this.lookupTypeTypes = this.applicationInfoService.projectLookupTables.find(projectLookupTable => projectLookupTable.definitionId == 17 && projectLookupTable.source == 'central').lookups;
    this.getContacts();
  }

  getContacts() {
    this.externaldatasourceService.executeExternalDataSource(310, []).then(getContactsResult => {
      this.contacts = getContactsResult;
    })
  }

  updateOptInOptOutSetting() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(402, [
      this.commonService.getModifyArrayBody(this.optInoptOutObject, ['modifiedDate'])
    ]).then(() => {
      this.eventService.customEvent.emit({ eventType: 'updateEntityTableView' });
      this.eventService.closeJjPopup();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  createOptInOptOutSetting() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(858, [
      this.commonService.getModifyArrayBody(this.optInoptOutObject, ['modifiedDate', 'id'])
    ]).then(() => {
      this.eventService.customEvent.emit({ eventType: 'updateEntityTableView' });
      this.eventService.closeJjPopup();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }
}
