<div class="maxHeight autoOverflowY">
<div class="wizardSubheader__wrapper" *ngIf="project != null">
    <div class="">
        <label class="sectionHeader">{{ 'GeneralSettings.Label.ManageGeneralSettings' | texttransform }}</label>
        <div class="wizardPageContent everleadShadowBox">
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ProjectName' | texttransform }}</label>
                <input [(ngModel)]="project.projectName" type="text" (change)="saveSettings()" class="maxWidth">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.Customer' | texttransform }}</label>
                <select [(ngModel)]="project.customerId" class="maxWidth" (change)="saveSettings()">
                    <option *ngFor="let customer of customers" [ngValue]="customer.id">
                        {{ customer.customerName }}
                    </option>
                </select>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ProjectBanner' | texttransform
                    }}</label>
                <input [(ngModel)]="project.projectBanner" type="text" (change)="saveSettings()" class="maxWidth">
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ProjectStart' | texttransform }}</label>
                <input [(ngModel)]=project.projectStart [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly
                    class="maxWidth" appendTo="body" aria-modal="true" (dateTimeChange)="saveSettings()">
                <owl-date-time #dt7></owl-date-time>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ProjectEnd' | texttransform }}</label>
                <input [(ngModel)]=project.projectEnd [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly
                    class="maxWidth" appendTo="body" aria-modal="true" (dateTimeChange)="saveSettings()">
                <i class="fas fa-trash everleadCoral fa-lg fingerCursor" (click)="clearProjectEnd()"></i>
                <owl-date-time #dt8></owl-date-time>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.Comments' | texttransform
                    }}</label>
                <textarea [(ngModel)]="project.comments" (change)="saveSettings()" class="projectSettingsComments"></textarea>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ClassicAccountView' | texttransform
                    }}</label>
                <input [(ngModel)]="project.isClassicView" type="checkbox" (change)="saveSettings()">
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.IsUsingDataRights' | texttransform
                    }}</label>
                <input [(ngModel)]="project.isUsingDataRights" type="checkbox" (change)="saveSettings()">
            </div>


            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.IsLoadingTaskOnLoginForAgent' |
                    texttransform }}</label>
                <input [(ngModel)]="project.isLoadingTaskOnLoginForAgent" type="checkbox" (change)="saveSettings()">
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.IsCheckEvents' | texttransform
                    }}</label>
                <input [(ngModel)]="project.isCheckEvents" type="checkbox" (change)="saveSettings()">
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.SelectOnlyContactOnTaskCreation' |
                    texttransform }}</label>
                <input [(ngModel)]="project.selectOnlyContactOnTaskCreation" type="checkbox" (change)="saveSettings()">
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.IsUsingReportRights' |
                    texttransform }}</label>
                <input [(ngModel)]="project.isUsingReportRights" type="checkbox" (change)="saveSettings()">
            </div>
        </div>

        <label class="sectionHeader">{{ 'GeneralSettings.Setting.EMailSettings' | texttransform }}</label>
        <div class="everleadShadowBox">            
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.MailConfigTypeLookupId' | texttransform}}</label>
                <select [(ngModel)]="project.mailConfigTypeLookupId" (change)="saveSettings()" class="maxWidth">
                    <option [ngValue]=null></option>
                    <option *ngFor="let mailConfigType of mailConfigTypes" [ngValue]="mailConfigType.id">
                        {{ commonService.getTranslationValueFromArray(mailConfigType) | texttransform }}
                    </option>
                </select>
            </div>
            <div class="wizardDetailItem d-flex" *ngIf="project.mailConfigTypeLookupId == 5936">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.MailConfigSMTPRefId' | texttransform }}</label>
                <select [(ngModel)]="project.mailConfigRefId" (change)="saveSettings()" class="maxWidth">
                    <option [ngValue]=null></option>
                    <option *ngFor="let mailConfigRefId of mailConfigSmtpRefIds" [ngValue]="mailConfigRefId.id">
                        {{ commonService.getTranslationValueFromArray(mailConfigRefId, 'displayName') | texttransform }}
                    </option>
                </select>
            </div>    
            <div class="wizardDetailItem d-flex" *ngIf="project.mailConfigTypeLookupId == 5935">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.MailConfigSesRefId' | texttransform
                    }}</label>
                <select [(ngModel)]="project.mailConfigRefId" (change)="saveSettings()" class="maxWidth">
                    <option [ngValue]=null></option>
                    <option *ngFor="let mailConfigRefId of mailConfigSesRefIds" [ngValue]="mailConfigRefId.sesConfigSetId">
                        {{ commonService.getTranslationValueFromArray(mailConfigRefId) | texttransform }}
                    </option>
                </select>
            </div>
        </div>

        <label class="sectionHeader">{{ 'GeneralSettings.Setting.TeleMarketing' | texttransform }}</label>
        <div class="everleadShadowBox">
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.TeleMarketingStart' | texttransform }}</label>
                <input [(ngModel)]=project.telemarketingStart [owlDateTimeTrigger]="dt9" [owlDateTime]="dt9" readonly
                    class="maxWidth" appendTo="body" aria-modal="true" (dateTimeChange)="saveSettings()">
                <i class="fas fa-trash everleadCoral fa-lg fingerCursor" (click)="clearData('telemarketingStart')"></i>
                <owl-date-time #dt9></owl-date-time>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.TeleMarketingEnd' | texttransform }}</label>
                <input [(ngModel)]=project.telemarketingEnd [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" readonly
                    class="maxWidth" appendTo="body" aria-modal="true" (dateTimeChange)="saveSettings()">
                <i class="fas fa-trash everleadCoral fa-lg fingerCursor" (click)="clearData('telemarketingEnd')"></i>
                <owl-date-time #dt10></owl-date-time>
            </div>
        </div>
        
        <label class="sectionHeader">{{ 'GeneralSettings.Setting.ContactFrequency' | texttransform }}</label>
        <div class="everleadShadowBox"> 
            <app-contact-protection [viewMode]="1"></app-contact-protection>           
          <!--   <div class="wizardDetailItem d-flex">

                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ContactFrequencyDaily' | texttransform
                    }}</label> 
                     <input [(ngModel)]="project.contactFrequencyDaily" type="number" (change)="saveSettings()" class="maxWidth">
                     <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ContactMediumDefinition' | texttransform
                    }}</label>
                    <select [(ngModel)]="project.contactMediumTypeId" class="maxWidth" (change)="saveSettings()">
                        <option [ngValue]="null" disabled>{{'ContactProetction.Label.SelectMedium' | texttransform}}</option>
                        <option *ngFor="let medium of contactMediumDefinition" [ngValue]="medium.id">
                            {{ !medium?.nameTranslationToken?.tokenFullName ? medium?.defaultName : medium.nameTranslationToken.tokenFullName | texttransform }}
                        </option>
                    </select>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ContactFrequencyWeekly' | texttransform
                    }}</label> 
                     <input [(ngModel)]="project.contactFrequencyWeekly" type="number" (change)="saveSettings()"
                    class="maxWidth">
                          <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ContactFrequency' | texttransform
                    }}</label>

                    <select [(ngModel)]="project.contactFrequencyId" class="maxWidth" (change)="saveSettings()">
                        <option [ngValue]="null" disabled>{{'ContactProtection.Label.SelectMedium' | texttransform}}</option>
                        <option *ngFor="let interval of contactIntervalDefinition" [ngValue]="interval.id">
                            {{ !interval?.nameTranslationToken?.tokenFullName ? interval?.defaultName : interval.nameTranslationToken.tokenFullName | texttransform }}
                        </option>
                    </select>
            </div>

            <div class="wizardDetailItem d-flex">
           <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ContactFrequencyMonthly' | texttransform
                    }}</label>
                <input [(ngModel)]="project.contactFrequencyMonthly" type="number" (change)="saveSettings()"
                    class="maxWidth"> 
                    <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.Quantity' | texttransform
                    }}</label>
                <input [(ngModel)]="project.contactAmount" type="number" (change)="saveSettings()"
                    class="maxWidth">
            </div> -->
        </div>

        <label class="sectionHeader">{{ 'GeneralSettings.Setting.CallSettings' | texttransform }}</label>
        <div class="everleadShadowBox">
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountOfCallsPerHour' | texttransform
                    }}</label>
                <input [(ngModel)]="project.countOfCallsPerHour" type="number" (change)="saveSettings()" class="maxWidth" [title]="'GeneralSettings.Label.ReportingValue' | texttransform">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountOfNetcallsPerHour' | texttransform
                    }}</label>
                <input [(ngModel)]="project.countOfNetcallsPerHour" type="number" (change)="saveSettings()"
                    class="maxWidth" [title]="'GeneralSettings.Label.ReportingValue' | texttransform">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.NetCallTarget' | texttransform
                    }}</label>
                <input [(ngModel)]="project.netCallTarget" type="number" (change)="saveSettings()" [title]="'GeneralSettings.Label.ReportingValue' | texttransform"
                    class="maxWidth">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.LeadQuota' | texttransform }}</label>
                <input [(ngModel)]="project.leadQuota" type="number" (change)="saveSettings()" class="maxWidth" [title]="'GeneralSettings.Label.ReportingValue' | texttransform">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountFteOperational' | texttransform }}</label>
                <input [(ngModel)]="project.countFteOperational" type="number" (change)="saveSettings()" class="maxWidth">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountProjectHours' | texttransform }}</label>
                <input [(ngModel)]="project.countProjectHours" type="number" (change)="saveSettings()" class="maxWidth">
            </div>
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ReportTypeLookup' | texttransform
                    }}</label>
                <select [(ngModel)]="project.reportTypeLookupId" (change)="saveSettings()" class="maxWidth">
                    <option [ngValue]=null></option>
                    <option *ngFor="let reportTypeLookup of reportTypeLookups" [ngValue]="reportTypeLookup.id">
                        {{ reportTypeLookup.defaultName }}
                    </option>
                </select>
            </div>
        </div>
                

        <label class="sectionHeader">{{ 'GeneralSettings.Setting.CallMasking' | texttransform }}</label>
        <div class="everleadShadowBox">
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.AgentMaskingType' | texttransform
                    }}</label>
                <select [(ngModel)]="project.agentMaskingType" (change)="changeMaskingType()" class="maxWidth">
                    <option *ngFor="let masktype of maskTypes" [ngValue]="masktype.id">
                        {{ masktype.defaultName }}
                    </option>
                </select>
            </div>

            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.AgentMaskingSpecialNumber' | texttransform
                    }}</label>
                <input [(ngModel)]="project.agentMaskingSpecialNumber" [disabled]="project.agentMaskingType != 297"
                    type="text" (change)="saveSettings()" class="maxWidth">
            </div>
        </div>        

        <label class="sectionHeader">{{ 'GeneralSettings.Setting.AISettings' | texttransform }}</label>
        <div class="everleadShadowBox">
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.AIKey' | texttransform
                    }}</label>
                <input [(ngModel)]="aiKey.value" (change)="saveApplicationSetting(aiKey)" class="maxWidth">
            </div>
        </div>

        <label class="sectionHeader">{{ 'GeneralSettings.Setting.ProjectTargets' | texttransform }}</label>
        <div>            
            <div *ngFor="let projectTarget of projectTargets">
                <app-generalsettingsprojecttarget [data]="projectTarget" [reportTypeLookups]="reportTypeLookups"></app-generalsettingsprojecttarget>
            </div>
            <button class="everleadCustomButton" (click)="createProjectTarget()">{{ 'GeneralSettings.Setting.NewProjectTarget' | texttransform }}</button>
        </div>


        <span *ngIf="applicationInfoService.isDeveloper">
            <br><br><br><br><br>
            <label class="sectionHeader">{{ 'GeneralSettings.Setting.Sync' | texttransform }}</label>
            <div class="everleadShadowBox">
                <div class="wizardDetailItem d-flex">
                    <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ActiveSync' | texttransform }}</label>
                    <input [(ngModel)]="project.activeSync" type="checkbox" (change)="saveSettings()">
                </div>
                <div class="wizardDetailItem d-flex">
                    <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.DmcAccountId' | texttransform
                        }}</label>
                    <input [(ngModel)]="project.dmcAccountId" type="text" (change)="saveSettings()" class="maxWidth">
                </div>
                <div class="wizardDetailItem d-flex">
                    <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.TrackingSchema' | texttransform
                        }}</label>
                    <input [(ngModel)]="project.trackingSchema" type="text" (change)="saveSettings()" class="maxWidth">
                </div>
            </div>

            <label class="sectionHeader">{{ 'GeneralSettings.Setting.RingCentralConnection' | texttransform }}</label>
            <div class="everleadShadowBox">
                <div class="wizardDetailItem d-flex">
                    <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.RingCentralConnectionId' | texttransform }}</label>
                    <input [(ngModel)]="ringCentralConnectionId" type="password" class="maxWidth">
                </div>

                <div class="wizardDetailItem d-flex">
                    <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.RingCentralConnectionSecret' | texttransform }}</label>
                    <input [(ngModel)]="ringCentralConnectionSecret" type="password" class="maxWidth">
                </div>
                <div>
                    <button class="everleadCustomButton" (click)="saveRingCentralSettings()">{{ 'GeneralSettings.Setting.RingCentralConnectionSave' | texttransform }}</button>
                </div>
            </div>            
        </span>        
    </div>
</div>
</div>