/* const followUpdata = [
    { id:1, name: 'agent1', campaign: 'Outbound', pool:'High' ,overdueTasks: '10', openTasksToday:'15' , futureTask: '150', futureTask1:'1', futureTask2:'2', futureTask3:'4', futureTask4:'5', futureTask5:'9', futureTask6:'23', futureTask7: '2', futureTask8:'9',futureTask9:'10', futureTask10:'10', futureTasks:'108' },
    { id:2, name: 'agent2', campaign: 'Outbound', pool:'Low' ,overdueTasks: '5', openTasksToday:'15' , futureTask: '150', futureTask1:'2', futureTask2:'5', futureTask3:'3', futureTask4:'5', futureTask5:'9', futureTask6:'23', futureTask7: '2', futureTask8:'9',futureTask9:'10', futureTask10:'10', futureTasks:'108' },
    { id:3, name: 'agent3', campaign: 'Inbound', pool:'High' ,overdueTasks: '1', openTasksToday:'5' , futureTask: '350', futureTask1:'2', futureTask2:'5', futureTask3:'3', futureTask4:'5', futureTask5:'9', futureTask6:'23', futureTask7: '2', futureTask8:'9',futureTask9:'10', futureTask10:'10', futureTasks:'108' },
    { id:4, name : 'allAgents', campaign: 'Outbound', pool:'', overdueTasks:'125', openTasksToday: '250', futureTask:'1105'},
    { id:5, name: 'allAgents',  campaign:'Inbound', pool:'', overdueTasks:'325', openTasksToday: '450', futureTask:'107'},
    { id:6, name: 'overAll', campaign: 'Outbound', pool:'', overdueTasks:'140', openTasksToday:'302', futureTask:'1759'},
    { id:7, name: 'overAll', campaign: 'Inbound', pool:'', overdueTasks:'10', openTasksToday:'30', futureTask:'2559'},
    { id:8, name: 'system', campaign: 'Outbound', pool:'', overdueTasks:'0', openTasksToday:'25', futureTask:'254'},
    { id:9, name: 'system', campaign: 'Inbound', pool:'', overdueTasks:'50', openTasksToday:'5', futureTask:'454'},
] */


const followUpdata = [
    {
        "campaign_name": "1. Call",
        "pool_name": "Standardpool",
        "user_name": "Bla",
        "follow_up_user_id": 5000,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "defaultpool",
        "user_name": "Bla",
        "follow_up_user_id": 5000,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "highpool",
        "user_name": "Bli Bli",
        "follow_up_user_id": 5002,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "highpool",
        "user_name": "Blo Blr",
        "follow_up_user_id": 5003,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "Standardpool",
        "user_name": "Bleb ble",
        "follow_up_user_id": 5004,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "Standardpool",
        "user_name": "blub bulb",
        "follow_up_user_id": 5005,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "Standardpool",
        "user_name": "blob",
        "follow_up_user_id": 5006,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "Standardpool",
        "user_name": "STick st",
        "follow_up_user_id": 5007,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    },
    {
        "campaign_name": "1. Call",
        "pool_name": "Standardpool",
        "user_name": "Chris",
        "follow_up_user_id": 5008,
        "overdue": 1,
        "overdue_today": 0,
        "current": 0,
        "total_today": 0,
        "ft1": 0,
        "ft2": 0,
        "ft3": 0,
        "ft4": 0,
        "ft5": 0,
        "ft6": 0,
        "ft7": 0,
        "ft8": 0,
        "ft9": 0,
        "ft10": 0,
        "future": 0
    }
]

export { followUpdata }