import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';

import { Subscription } from 'rxjs';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnDestroy {

  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,
  ) { }

  customEventSubscription: Subscription = new Subscription();
  @Input() header: string = '';
  @Input() controlid: string = "";
  @Input() options: EChartsOption;
  @Input() chartData: any;
  @Input() darkmode: boolean;
  @Input() barWidth: number
  @Input() legend: boolean;
  @Input() legendPosition;
  @Input() tooltip: boolean;
  @Input() barMaxWidth: number;
  @Input() barGap: number = 0;
  @Input() height: number = 400;
  @Input() barLabel;

  // * Xaxis
  @Input() showXaxis:boolean = true;
  @Input() xAxisType: string = 'category' || 'value';
  @Input() xAxisLabel = {};

  // * Yaxis
  @Input() showYaxis:boolean = true;
  @Input() yAxisType:string= 'category' || 'value';
  @Input() yAxisLabel= {};



  @Input() colors = [
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#1D1645', '#57518E', '#346188', '#1B3A55',
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#1D1645', '#57518E', '#346188', '#1B3A55'
  ];

  dataAvailable = false;
  totalAmount: number = 0
  backgroundColor= 'white';
  darkBackgroundColor= '#1c3a56';
  fontColor = '#1c3a56';
  darkFontColor: 'white';

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updateBarChart' && event.value == this.controlid) {
        this.chartData = event.chartData;
        this.chartData.data = this.adjustChartData()
        this.createChart()
      }
    });
    if (this.controlid == '') {
      this.controlid = 'barChart_'.concat(this.commonService.createGuid());
    }
    this.chartData.data = this.adjustChartData()
    this.createChart();

   // console.log('this chart Data', this.chartData)
  }

  adjustChartData() {
    let data = this.chartData.data

    switch (this.chartData.type) {
      case 'microCampaign':
        return data.map((item, index) => {
          return {
            ...item,
            stepId: this.getStepName(item.stepId),
          }
        })

      case 'mailCampaignDetails':
         return data.map((item) => {
          return {
            ...item,
            name: this.axivasTranslateService.getTranslationTextForToken(item.displayName)
          }
        })

      default:
        return data;
    }

  }

  getHeaderLabelId() {
    return this.controlid + 'headerLabel';
  }

  createChart() {
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    let dataChart;
    if (this.chartData.categories) {
      dataChart = this.createDataSetSeries()
    } else {
      dataChart = this.createSeries()
    }

    this.options = {
      ...dataChart,
      color: this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      xAxis: {
        type: this.xAxisType ? this.xAxisType : 'category',
        show: this.showXaxis,
        axisLabel: {
          ...this.xAxisLabel,
          color: this.darkmode ? '#FFFFFF' : '',
        },
        axisLine: {
          ...this.yAxisLabel,
          lineStyle: { color: this.darkmode ? '#FFFFFF' : '' }
        },
      },
      yAxis: {
        type: this.yAxisType ? this.yAxisType : 'value',
        show: this.showYaxis,
        axisLabel: {
          ...this.yAxisLabel,
          color: this.darkmode ? '#FFFFFF' : ''
        },
      },
    }

    if (this.legend) {
      this.options = {
        ...this.options,
        legend: {
          ...this.legendPosition,
          textStyle: {
            fontFamily: 'Poppins',
            fontSize: 12,
            color: this.darkmode ? '#FFFFFF' : ''
          }
        }
      }
    }

    if (this.tooltip) {
      this.options.tooltip={}
    }

    console.log('options', this.options);

  }

  createDataSetSeries() {

    let source = [['product']]

    let series = this.chartData.categories.map((item, i) => {
      source[0].push(this.axivasTranslateService.getTranslationTextForToken(item))
      return {
        name: this.axivasTranslateService.getTranslationTextForToken(item),
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        radius:'',
        barWidth: this.barWidth ? `${this.barWidth}%` : '10%',
        barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,
        label: {
          show: true,
          position: 'insideBottom',
          distance: 15,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          fontSize: 15,
          color: this.darkmode ? '#FFFFFF' : '',
          ...this.barLabel,
          rich: {
            name: {}
          }
        }
      }
    })

     this.chartData.data.map((item) => {
      source.push(Object.values(item))
    })

    return { series: series, dataset: { source: source } }
  }

  createSeries() {
    let series = this.chartData.data.map((item, i) => {
      return {
        name: item.name,
        data: [item.value],
        type: 'bar',
        barWidth: this.barWidth ? `${this.barWidth}%` : '5%',
        barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,
        barGap: this.barGap,
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'top',
          distance: 15,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 0,
          fontSize: 15,
          color: this.darkmode ? '#FFFFFF' : '',
          ...this.barLabel,
          rich: {
            name: {}
          }
        }
      }

    })

    return { series: series }
  }


  getStepName(stepId) {
    const step = this.applicationInfoService.steps.toArray().find(step => step.id == stepId);
    if (step) {
      return this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(step));
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

}
