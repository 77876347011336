export const fakecontactProtectionDATA = [
    {
        id:0,
        name: 'lu',
        factor:2,
        interval_look_up_id:1,
        medium_ref:50, 
    },
    {
        id:1,
        name: 'leo',
        factor:1,
        interval_look_up_id:0,
        medium_ref:51 
    },
    {
        id:2,
        name: 'ibu',
        factor:5,
        interval_look_up_id:2 ,
        medium_ref:2312
    },
    {
        id:3,
        name: 'ivo',
        factor:3,
        interval_look_up_id:1,
        medium_ref: 51 
    },
    {
        id:4,
        name: 'manuek',
        factor:2,
        interval_look_up_id:2,
        medium_ref:50 
    },
    {
        id:5,
        name: 'serra',
        factor:2,
        interval_look_up_id:1,
        medium_ref:51 
    },
    {
        id:6,
        name: 'silva',
        factor:1,
        interval_look_up_id:0,
        medium_ref:2312 
    },
    {
        id:7,
        name: 'agagag',
        factor:5,
        interval_look_up_id:2,
        medium_ref:50 
    },
    {
        id:8,
        name: 'sgsfards',
        factor:3,
        interval_look_up_id:1,
        medium_ref:2312 
    },
    {
        id:9,
        name: 'fgefgefge',
        factor:2,
        interval_look_up_id:2,
        medium_ref:50 
    },
    {
        id:10,
        name: 'contactuuau',
        factor:2,
        interval_look_up_id:1,
        medium_ref:51 
    },
    {
        id:11,
        name: 'zazaz',
        factor:1,
        interval_look_up_id:0,
        medium_ref:2312 
    },
    {
        id:12,
        name: 'cijsgsf',
        factor:5,
        interval_look_up_id:2,
        medium_ref:2312 
    },
    {
        id:13,
        name: 'contactposkj',
        factor:3,
        interval_look_up_id:1,
        medium_ref:47 
    },
    {
        id:14,
        name: 'ülkshgt',
        factor:2,
        interval_look_up_id:2,
        medium_ref:51 
    },
    {
        id:15,
        name: 'contactrdsea',
        factor:2,
        interval_look_up_id:1,
        medium_ref:2312 
    },
    {
        id:16,
        name: 'coiuztsg',
        factor:1,
        interval_look_up_id:0 ,
        medium_ref:50
    },
    {
        id:17,
        name: 'coposjshg',
        factor:5,
        interval_look_up_id:2 ,
        medium_ref:51
    },
    {
        id:18,
        name: 'consss',
        factor:3,
        interval_look_up_id:1,
        medium_ref:47 
    },
    {
        id:19,
        name: 'ossuhagsde',
        factor:2,
        interval_look_up_id:2,
        medium_ref:47 
    },
    {
        id:20,
        name: 'psosjs',
        factor:2,
        interval_look_up_id:1,
        medium_ref:50 
    },
    {
        id:21,
        name: 'ckjhgtz',
        factor:1,
        interval_look_up_id:0,
        medium_ref:51 
    },
    {
        id:22,
        name: 'ciuhgtf',
        factor:5,
        interval_look_up_id:2,
        medium_ref: 48
    },
    {
        id:23,
        name: 'wsadre',
        factor:3,
        interval_look_up_id:1,
        medium_ref: 47
    },
    {
        id:24,
        name: 'olokjhb',
        factor:2,
        interval_look_up_id:2,
        medium_ref:46 
    },
]



export const mediumRefGuard=[
    {
        "id": 47,
        "isActive": true,
        "nameTranslationTokenId": 2043,
        "nameTranslationToken": {
            "id": 2043,
            "tokenFullName": "LookupTable.OptInMedium.Email_32"
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "Email"
    },
    {
        "id": 51,
        "isActive": true,
        "nameTranslationTokenId": 2065,
        "nameTranslationToken": {
            "id": 2065,
            "tokenFullName": "LookupTable.OptInMedium.AllMedia_32"
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "AllMedia"
    },
    {
        "id": 45,
        "isActive": true,
        "nameTranslationTokenId": 2033,
        "nameTranslationToken": {
            "id": 2033,
            "tokenFullName": "LookupTable.OptInMedium.Phone_32"
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "Phone"
    },
    {
        "id": 48,
        "isActive": true,
        "nameTranslationTokenId": 2050,
        "nameTranslationToken": {
            "id": 2050,
            "tokenFullName": "LookupTable.OptInMedium.SocialMedia_32"
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "SocialMedia"
    },
    {
        "id": 50,
        "isActive": true,
        "nameTranslationTokenId": 2061,
        "nameTranslationToken": {
            "id": 2061,
            "tokenFullName": "LookupTable.OptInMedium.SMS_32"
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "SMS"
    },
    {
        "id": 46,
        "isActive": true,
        "nameTranslationTokenId": 2038,
        "nameTranslationToken": {
            "id": 2038,
            "tokenFullName": "LookupTable.OptInMedium.Fax_32"
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "Fax"
    },
    {
        "id": 4276,
        "isActive": true,
        "nameTranslationTokenId": null,
        "nameTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "Umfragen"
    },
    {
        "id": 6766,
        "isActive": true,
        "nameTranslationTokenId": null,
        "nameTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "Brief"
    },
    {
        "id": 2312,
        "isActive": true,
        "nameTranslationTokenId": null,
        "nameTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "descriptionTranslationTokenId": null,
        "descriptionTranslationToken": {
            "id": 0,
            "tokenFullName": ""
        },
        "defaultName": "News"
    }
]