import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

import { PermissionGuard } from './permission.guard';

// const ActivityModuleId = 2;
// const ReadPermissionId = 1;

@Injectable()
export class ActivityReadPermissionGuard extends PermissionGuard   {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(): boolean {
    // const userCanActivate = this.userService.hasPermission(ActivityModuleId, ReadPermissionId);
    const userCanActivate = this.userService.hasPermission('CanReadActivity');
    if (!userCanActivate) {
      this.redirectUnauthorized();
    }
    return userCanActivate;
  }
}
