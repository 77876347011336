<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>Oops!</h1>
                <h2>404</h2>
                <div class="error-details">
                    Die gesuchte Seite wurde nicht gefunden | Requested page not found!
                </div>
                <div class="error-actions">
                    <a href="/{{baseRef}}" class="btn btn-primary btn-lg">
                      <span class="fa fa-home"></span>
                      Home
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
