<div class="callProjectBooking">
    <div *ngIf="resultSendsEmail == true" class="callProjectBookingSendingEmail">
        <div class="callProjectBookingSendingEmail__header">{{ 'General.Label.SendingEmail' | texttransform }}</div>
        <div class="callProjectBookingSendingEmail__infoline">{{ 'CallProjectBooking.Label.CheckEMail' | texttransform }}</div>
        <div class="callProjectBookingSendingEmail__checkEmail">
            <div class="callProjectBookingSendingEmail__checkEmail__label" [ngClass]="{
                everleadCoral: emailValidationResult?.isValidEmail == false,
                everleadGreen: emailValidationResult?.isValidEmail == true
              }" [tippy]="applicationInfoService.entities.Item('4').data.email">
                {{ applicationInfoService.entities.Item('4').data.email }}
            </div>
            <div class="callProjectBookingSendingEmail__checkEmail__check">
                <i class="far fa-file-alt" [tippy]="generateTippy()" *ngIf="emailValidationResult != null" 
                    [tippyOptions]="{ allowHTML: true, placement:'top' }"></i>
                <svg-icon src="assets/images/svg/validate_email.svg" [svgStyle]="{ 'width.px': 21, 'height.px': 20 }" class="fingerCursor"
                    (click)="validateEmailViaAPI()" [tippy]="'CustomEMail.Label.VerifyEmail' | texttransform"></svg-icon>
            </div>
        </div>
    </div>
    <div class="callProjectBookingButtons" *ngIf="canBook">
        <button class="maxWidth everleadCustomButton" (click)="bookTask()">{{ 'Task.Label.Button.SaveAndNext' | texttransform }}</button>
        <button class="maxWidth everleadCustomButton" (click)="bookTaskAndStayInAccount()">{{ 'Task.Label.Button.Save' | texttransform }}</button>    
    </div>
    <div class="callProjectBookingCantBook everleadShadowBox everleadCoral" *ngIf="!canBook">
        {{ 'Task.Label.BookingThisResultRequiresDoubleOptin' | texttransform }}
    </div>
</div>