<div [style.background-image]=getProjectBackgroundImage() class="logoBackground" *ngIf="!applicationInfoService.isMobile()"></div>
<div class="row ajandoHeaderWrapper" [ngClass]="{ 
    testEnvironment: applicationInfoService.testEnvironment == true,
    ajandoHeaderWrapperNavigationExpandedFixed: applicationInfoService.navigationExpandedFixed
}">
    <div class="col-md-12 mobile-visible ajandoHeaderWrapper__mobile__row1">
        <div class="row">
            <div class="col-2">
                <div class="ajandoHeaderContainer__logo">
                    <!-- <img src="assets/images/alexgross.png" /> -->
                    <svg-icon src="assets/images/svg/EVERLEAD-unendlich-symbol.svg" [svgStyle]="{ 'height.px': 15 }"></svg-icon>
                </div>        
            </div>
            <div class="col-8">
                <div class="ellipsisText">
                    <span (click)="showProjectSelection()" role="button">{{ applicationInfoService.projectName }}</span>
                </div>                            
            </div>
            <div class="col-2">
                <div class="ajandoHeaderContainer__burger">
                    <i class="fas fa-bars" *ngIf="!this.applicationInfoService.navigationExpandedMobile" (click)="toggleNavigation($event)" role="button" id="navToggleButton"></i>
                    <i class="fas fa-times" *ngIf="this.applicationInfoService.navigationExpandedMobile" (click)="toggleNavigation($event)" role="button" id="navToggleButton"></i>
                </div>                        
            </div>
        </div>
    </div>

    <div class="col-md-12 mobile-visible">
        <app-phone-ringcentral-widget></app-phone-ringcentral-widget>
    </div>
    <div class="col-md-12 mobile-visible ajandoHeaderWrapper__mobile__row2">
        <app-toolbar class="maxWidth" *ngIf="applicationInfoService.isMobile()"></app-toolbar>
        <!-- <app-custom-universal *ngIf="applicationInfoService?.additionalMenuBarControlId!=0"
            [controlid]=applicationInfoService?.additionalMenuBarControlId class="breadCrumbAndMenu__menu">
        </app-custom-universal> -->
    </div>
    <div class="col-xl-6 col-md-6 desktop-visible nopadding" [ngClass]="{
        'col-xl-8' : applicationInfoService.navigationExpandedFixed,
        'col-md-8' : applicationInfoService.navigationExpandedFixed
    }">

        <div class="headerItemInner headerItemProject d-flex align-items-center">
            <div [id]=applicationInfoService.projectID class="headerItemSettings ajandoHeaderProjectName fingerCursor" role="button" 
                [title]="applicationInfoService.projectName" (click)="showProjectSelection()">
                <label class="ajandoHeaderProjectName__label fingerCursor">
                    <i class="fas fa-exclamation-triangle " *ngIf="applicationInfoService.testEnvironment == true"
                        title="Testumgebung - Änderungen in diesem System werden täglich überschrieben. <br>BITTE NICHT PRODUKTIV NUTZEN!"></i>
                    {{ applicationInfoService.projectName }}
                </label>
                <div class="ajandoHeaderProjectName__button fingerCursor">
                    <svg-icon src="assets/images/svg/header_arrowdown.svg" ></svg-icon> {{ 'Toolbar.Label.ChangeProject' | texttransform}}
                </div>
            </div>
            <div (click)="showProjectSettings()" role="button"
                *ngIf="!applicationInfoService.blockSettingChange"
                class="headerItemInner__settingBox">
                <div class="headerItemSettings second-color"
                    *ngIf="applicationInfoService?.availableUserGroups?.length > 1">
                    <svg-icon src="assets/images/svg/contact-groups.svg" class="ajandoHeaderIcon "></svg-icon>
                    <span class="navigaionInfoSpan">{{ getUserFollowupGroup() | texttransform }}</span>
                </div>
                <div class="headerItemSettings second-color">
                    <span class="navigaionInfoSpan">{{ getPoolName() }}</span>
                    <div class="ajandoHeaderProjectName__button fingerCursor">
                        <svg-icon src="assets/images/svg/header_arrowdown.svg" ></svg-icon> {{ 'Toolbar.Label.ChangeProject' | texttransform}}
                    </div>    
                </div>
                <div class="headerItemSettings second-color"
                    *ngIf="applicationInfoService?.campaingnModeId != null ">
                    <span class="navigaionInfoSpan everleadCoral">Kampagne </span>
                    <span class="navigaionInfoSpan">{{ getCampaignModeCampaign() | texttransform }}</span>
                </div>

            </div>            
        </div>
    </div>

    <div class="col-xl-6 col-md-6 personalNav desktop-visible order-first order-md-last headerPhonePanel">
        <div class="headerPhonePanel__visitWebSite" 
                *ngIf="
                    applicationInfoService.applicationSettings['showFirstSteps'] == 'true' &&
                    ringcentralwebphoneService.session == null
                ">
            <button (click)="applicationInfoService.showBanner = true" class="everleadCustomButton">
                {{ 'DemoBanner.Label.VisitWebsite' | texttransform }}</button>
        </div>
        <app-phone-ringcentral-widget></app-phone-ringcentral-widget>    
    </div>
</div>