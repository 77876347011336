import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { WizardMode, WizardService } from '../wizard/wizard.service';

@Component({
  selector: 'app-mailshots',
  templateUrl: './mailshots.component.html',
  styleUrls: ['./mailshots.component.scss']
})
export class MailshotsComponent implements OnInit, OnDestroy {
  mailshotCreatedEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  mailshots = [];
  filterString = '';
  loadingData = true;

  constructor(
    private wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    public userService: UserService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.getMailShots();
    this.mailshotCreatedEventSubscription = this.eventService.mailshotCreatedEvent.subscribe(() => { this.getMailShots(); });
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.type == 'updateEMailBatch') {
        const element = this.mailshots.find(mailshot => mailshot.id == event.id);
        if (element) {
          element.batchName = JSON.parse(event.value).batchName;
          element.wizardValues = event.value;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mailshotCreatedEventSubscription) { this.mailshotCreatedEventSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getMailShots() {
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(656).then(getMailShotsResult => {
      this.mailshots = getMailShotsResult;
      this.loadingData = false;
    }).catch(() => { this.loadingData = false; });
  }

  createNewMailshot() {
    if (!this.userService.hasPermission('CanCreateMailshotList')) { return; }
    const array = {
      projectId: this.applicationInfoService.projectID,
      stepId: null,
      batchName: 'New batch',
      campaignId: null,
      campaignName: '',
      receivers: null,
      followUpUserId: this.applicationInfoService.userID,
      followUpDate: new Date(Date.now()),
      sender: '',
      subject: '',
      templateName: '',
      templateHTML: '',
      templateSource: 1
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(693, [
      this.commonService.getModifyArrayBody({
        batchName: array.batchName,
        projectId: array.projectId,
        countSent: 0,
        countOpen: 0,
        countCanceled: 0,
        countBounced: 0,
        wizardValues: JSON.stringify(array)
      }, [])
    ]).then(createNewMailshotResult => {
      this.getMailShots();
      array['id'] = createNewMailshotResult.id;
      array['status'] = 0;
      array['countSent'] = 0;
      array['countOpen'] = 0;
      array['countCanceled'] = 0;
      array['countBounced'] = 0;
      array['receivers'] = null;
      this.loaderService.display(false);
      this.wizardService.showWizard(WizardMode.Mailshot, array, '90', '90');
    }).catch(()=> { this.loaderService.display(false); });
  }

  cloneMailshot(mailshot) {
    if (!this.userService.hasPermission('CanCreateMailshotList')) { return; }

    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('Mailshots.CloneMessage.Message').replace('<0>', mailshot.batchName),
      header: this.axivasTranslateService.getTranslationTextForToken('Mailshots.CloneMessage.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        this.externaldatasourceService.executeExternalDataSource(693, [
          this.commonService.getModifyArrayBody({
            batchName: mailshot.batchName.concat(' clone'),
            projectId: mailshot.projectId,
            wizardValues: mailshot.wizardValues
          }, [])
        ]).then(cloneResult => {
          this.loaderService.display(false);
          this.messagingService.showDefaultSuccess('Mailshots.Label.CloneMessageHeader', 'Mailshots.Label.CloneMessageMessage');
          this.getMailShots();
        }).catch(() => { this.loaderService.display(false); });
      }
    });


  }

  itemIsFiltered(mailshot) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(mailshot.batchName, this.filterString)) {
     returnValue = false
    }
    return returnValue;
  }

  showMailshotDetails(mailshot) {
    this.applicationInfoService.miscSettings['emailbatch'] = mailshot;
    this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
  }

  editMailshot(mailshot) {
    const array = JSON.parse(mailshot.wizardValues);
    if (this.commonService.isNullOrUndefined(mailshot.startedAt)) {
      array['id'] = mailshot.id;
      array['status'] = mailshot.status;
      this.wizardService.showWizard(WizardMode.Mailshot, array, '90', '90');
    } else {
      this.applicationInfoService.miscSettings['emailbatch'] = mailshot;
      this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
    }
  }

  startMailshot(mailshot) {
    const array = JSON.parse(mailshot.wizardValues);
    array['id'] = mailshot.id;
    array['status'] = mailshot.status;
    this.wizardService.showWizard(WizardMode.MailshotFinal, array, '90', '90');
  }

  stopMailshot(mailshot) {
    this.externaldatasourceService.executeExternalDataSource(694, [mailshot.id]).
    then(() => {
      this.getMailShots();
    });
  }

  deleteMailshot(mailshot) {
    this.externaldatasourceService.executeExternalDataSource(706, [mailshot.id]).
    then(() => {
      this.getMailShots();
    });
  }

  getMailShotPool(mailshot) {
    let returnValue = '';
    const array = JSON.parse(mailshot.wizardValues);
    if (array) {
      if (this.applicationInfoService.pools.ContainsKey(array.poolId)) {
        returnValue = this.applicationInfoService.pools.Item(array.poolId).defaultName;
      }
    }
    return returnValue;
  }

  mailshotPoolIsCurrentPool(mailshot) {
    const array = JSON.parse(mailshot.wizardValues);
    if(array) {
      return array.poolId == this.applicationInfoService.poolId;
    } else {
      return false;
    }
  }

  checkEmailBatchQueue() {
    this.sharedAPI.checkEmailBatchQueue().subscribe(() => {
      console.log('checkEmailBatchQueue', 'done');
    });
  }
}
