import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-projectstartpage',
  templateUrl: './projectstartpage.component.html',
  styleUrls: ['./projectstartpage.component.scss']
})
export class ProjectstartpageComponent implements OnInit {

  constructor(
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
  }

}
