<div class="myNextFollowUp__wrapper">
    <div class="myNextFollowUp__displayText">{{ controlDefinition.displayText | texttransform }}</div>
    <!-- <div class="myNextFollowUp__haderLabel">{{ 'MyNextFollowUp.Label.StartDate' | texttransform }}</div> -->
    <div class="myNextFollowUp__inner">
        <div class="myNextFollowUp__dateSelect">
            <input type="datetime-local" [(ngModel)]="startDate" class="maxWidth">
            <!-- <input [(ngModel)]=startDate [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly [placeholder]="'MyNextFollowUp.Label.StartDate' | texttransform"
              (dateTimeChange)="clearLastLogicalTask()" appendTo="body" aria-modal="true" class="myNextFollowUp__dateSelect__input"> -->
            <div class="myNextFollowUp__dateSelect__clear customTextControlWrapper__baseBackgroundHover" 
                (click)="clearDate()" [tippy]="'MyNextFollowUp.Label.ClearDate' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}">
                <i class="fas fa-times-circle"></i>
            </div>
        </div>
        <div class="myNextFollowUp__buttons">
            <div class="myNextFollowUp__button myNextFollowUp__button__toList customTextControlWrapper__baseBackgroundHover everleadCustomButton" 
                (click)="showList()">
                {{ 'MyNextFollowUp.Label.ToList' | texttransform }}
            </div>    
            <div class="myNextFollowUp__button customTextControlWrapper__baseBackgroundHover everleadCustomButton" (click)="getNextFollowup()">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" 
                    [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                    class="myNextFollowUp__searchIcon"
                ></svg-icon> 
                <ng-container *ngIf="startDate != null">{{ 'MyNextFollowUp.Label.Search' | texttransform }}</ng-container>
                <ng-container *ngIf="startDate == null">{{ 'MyNextFollowUp.Label.NextAppointment' | texttransform }}</ng-container>
            </div>    
        </div>    
    </div>
</div>

<owl-date-time #dt7></owl-date-time>