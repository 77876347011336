import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-uicontrol-design-methods',
  templateUrl: './uicontrol-design-methods.component.html',
  styleUrls: ['./uicontrol-design-methods.component.scss']
})
export class UicontrolDesignMethodsComponent implements OnInit {
  @Input() currentControlToModify = null;
  methodTypes = InternalMethodType;
  methodTypesArray = [];
  parameterInfoArray = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.methodTypesArray = this.commonService.enumToArray(this.methodTypes);
    this.methodTypesArray.sort((a, b) => a.id < b.id ? -1 : 1);
    this.createParameterInfoArray();
  }

  addNewMethod() {
    const method = new Object();
    method['parameters'] = [];
    method['order'] = 0;
    method['method'] = 'askyesno';
    method['type'] = 'onClick';
    method['controlId'] = this.currentControlToModify.id;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(312, [
      this.commonService.getModifyArrayBody(method, [])
    ])
    .then(result => {
      method['id'] = result.id;
      this.currentControlToModify.methods.push(method);
      this.adjustMethodParameters(method);
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  addNewParameter(method, order = null) {
    const parameter = new Object();
    if (order != null) {
      parameter['order'] = order;
    } else {
      parameter['order'] = method.parameters.length + 1;
    }    
    parameter['type'] = 1;
    parameter['value'] = 'value';
    parameter['controlMethodId'] = method.id;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(315, [
      this.commonService.getModifyArrayBody(parameter, [])
    ])
    .then(result => {
      parameter['id'] = result.id;
      method.parameters.push(parameter);      
      method.parameters.sort((a, b) => a.order < b.order ? -1 : 1)
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe();
      this.loaderService.display(false);      
    }).catch(() => { this.loaderService.display(false); });
  }

  saveMethodType(method) {
    this.adjustMethodParameters(method);
    this.saveMethod(method);
  }

  adjustMethodParameters(method) {
    const parametersForThisMethod = this.getParameterCount(method).length;
    let currentParameters = method.parameters.length;
    let counter = 1;
    while(currentParameters > parametersForThisMethod) {
      this.deleteParameter(method, method.parameters[method.parameters.length - counter]);
      currentParameters --;
      counter ++;      
    }
    for (let i = method.parameters.length; i < parametersForThisMethod; i++) {        
      this.addNewParameter(method, i);
    }      

  }
  saveMethod(method) {
    this.externaldatasourceService.executeExternalDataSource(314, [this.commonService.getModifyArrayBody(method, [])]);
  }

  deleteMethod(method) {
    this.externaldatasourceService.executeExternalDataSource(313, [method.id])
    .then(() => { 
      this.commonService.removeItemFromArray(this.currentControlToModify.methods, method);       
    });
    this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe();
  }

  saveParameter(parameter) {
    this.externaldatasourceService.executeExternalDataSource(316, [this.commonService.getModifyArrayBody(parameter, [])])
    .then(() => { this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe(); });
  }

  deleteParameter(method, parameter, refreshApi = true) {
    this.externaldatasourceService.executeExternalDataSource(317, [parameter.id])
    .then(() => { 
      this.commonService.removeItemFromArray(method.parameters, parameter); 
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe();
    });
  }

  toNumber(value) {
    return Number(value);
  }


  // "InApp Dokumentation "
  getParameterInformation(method, currentIndex) {
    if (method.method == null) {
      return 'Bitte eine Methode auswählen'
    }
    let returnString = 'Nicht verwendet!';
    this.parameterInfoArray.forEach(arrayItem => {
      if (arrayItem.method == method.method && arrayItem.index == currentIndex) {
        returnString = arrayItem.informationText;
      }
    })
    return returnString;
  }

  getParameterCount(method) {
    return this.parameterInfoArray.filter(parameter => parameter.method == method.method);
  }

  createParameterInfoArray() {
    this.externaldatasourceService.executeExternalDataSource(641)
    .then(createParameterInfoArrayResult => {
      this.parameterInfoArray = createParameterInfoArrayResult;
    });    
    return;
    // TODO In eine Tabelle auslagern
    this.parameterInfoArray.push({ method: InternalMethodType.ShowJJPopup, index: 0, information: 'Überschrift' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowJJPopup, index: 1, information: 'Was soll angezeigt werden (AngularElement oder id des Containers)' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowJJPopup, index: 2, information: 'Größe des Fensters in %' });
    this.parameterInfoArray.push({ method: InternalMethodType.AskYesNo, index: 0, information: 'Überschrift' });
    this.parameterInfoArray.push({ method: InternalMethodType.AskYesNo, index: 1, information: 'Frage' });
    this.parameterInfoArray.push({ method: InternalMethodType.SetAccountPopupData, index: 0, information: 'Das DatenArray' });
    this.parameterInfoArray.push({ method: InternalMethodType.SetAccountPopupData, index: 1, information: 'Der Typ des Popups' });
    this.parameterInfoArray.push({ method: InternalMethodType.ActivateTab, index: 0, information: '' });
    this.parameterInfoArray.push({ method: InternalMethodType.ActivateTab, index: 1, information: '' });
    this.parameterInfoArray.push({ method: InternalMethodType.ExecuteExternalDataSource, index: 0, information: 'Die id der ExternalDatasource' });
    this.parameterInfoArray.push({ method: InternalMethodType.AssignAccountToContact, index: 0, information: 'Die id des Kontakts. Er wird dann dem aktuell selektieren Account zugewiesen' });
    this.parameterInfoArray.push({ method: InternalMethodType.CreateNewTaskForContact, index: 0, information: 'Die id des Ansprechpartners' });
    this.parameterInfoArray.push({ method: InternalMethodType.SaveEntityToServer, index: 0, information: 'Die uiEntityId der Entität (z.B. 1 für account, 2 für task, 4 für contact usw.)' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadProject, index: 0, information: 'Die id des Projekts welches geladen werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateDropdownControl, index: 0, information: 'Die logicalControlId des Dropdowns welches aktualisiert werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.CloseJJPopup, index: 0, information: 'Optional: Die id des Popups, diesen Parameter löschen wenn das zuletzt geöffnete Popup geschlossen werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadFollowUp, index: 0, information: 'Der Array des Followups. Diese Methode wird eigentlich nur aus dem Quellcode selbst aufgerufen' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowAccountData, index: 0, information: 'Die id des Accounts' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowAccountDataPopup, index: 0, information: 'Die id des Accounts' });
    this.parameterInfoArray.push({ method: InternalMethodType.ScrollIntoView, index: 0, information: 'Die id des Controls zu dem gescrollt werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadEntity, index: 0, information: 'Nur intern!' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateEntity, index: 0, information: 'Nur intern!' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateEntityValue, index: 0, information: 'uiEntityId' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateEntityValue, index: 1, information: 'Der Name des Members' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateEntityValue, index: 2, information: 'Der Wert der zugewiesen werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.CallNumber, index: 0, information: 'Die anzurufende Nummer' });
    this.parameterInfoArray.push({ method: InternalMethodType.BookNotRelevantCp, index: 0, information: 'Die id des "richtigen" Ansprechpartners' });
    this.parameterInfoArray.push({ method: InternalMethodType.Message, index: 0, information: 'Die Nachricht' });
    this.parameterInfoArray.push({ method: InternalMethodType.Logout, index: 0, information: '' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowUrl, index: 0, information: 'Die URL die aufgerufen werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowUrl, index: 0, information: '' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadContact, index: 0, information: 'Die id des Ansprechpartners' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadContactAndAssignToTask, index: 0, information: 'Die id des Ansprechpartners' });
    this.parameterInfoArray.push({ method: InternalMethodType.CreateSingleAction, index: 0, information: 'Die uiEntity id, aus der die singleaction erstellt werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadConversionContact, index: 0, information: 'Die id des Ansprechpartners, der zu der Conversion gehört' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateBookingPreview, index: 0, information: 'Die id des Results' });
    this.parameterInfoArray.push({ method: InternalMethodType.CallInternalMethod, index: 0, information: 'Nur intern' });
    this.parameterInfoArray.push({ method: InternalMethodType.CheckIfTaskCanBeLoaded, index: 0, information: 'Der Task der geladen werden soll.' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateMiscSettings, index: 0, information: 'Der Name des Settings' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateMiscSettings, index: 1, information: 'Der Wert der eingetragen werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.SetDeleteFlag, index: 0, information: 'Intern!' });
    this.parameterInfoArray.push({ method: InternalMethodType.RollbackAuditValue, index: 0, information: 'Audit Zeile welche zurückgesetzt werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.BookTaskAndStayInAccount, index: 0, information: '' });
    this.parameterInfoArray.push({ method: InternalMethodType.UpdateControlContent, index: 0, information: 'Die logicalControlId des Controls, welches daraufhin seinen Inhalt neu lädt (Dropdowns, Tables ect.)' });
    this.parameterInfoArray.push({ method: InternalMethodType.CreateContactAndAddToTask, index: 0, information: 'Die Ansprechpartner-Entity' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowTaskByTaskId, index: 0, information: 'Die id des Tasks' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowTask, index: 0, information: 'Das Task-Objekt' });
    this.parameterInfoArray.push({ method: InternalMethodType.ChangeLanguage, index: 0, information: 'Die Sprache, z.B. "DE"' });
    this.parameterInfoArray.push({ method: InternalMethodType.NavigateTo, index: 0, information: 'Der Bereich in den Navigiert werden soll, z.B. Account' });
    this.parameterInfoArray.push({ method: InternalMethodType.ChangePageContent, index: 0, information: 'Dieser Parameter wird mittlerweile nicht mehr verwendet' });
    this.parameterInfoArray.push({ method: InternalMethodType.ChangePageContent, index: 0, information: 'Der Bereich in den navigiert werden soll z.B. Account' });
    this.parameterInfoArray.push({ method: InternalMethodType.ChangePageContentToContainer, index: 0, information: 'Die id des Containers' });
    this.parameterInfoArray.push({ method: InternalMethodType.ChangeContainerContent, index: 0, information: 'Die initiale Id des Containers' });
    this.parameterInfoArray.push({ method: InternalMethodType.ChangeContainerContent, index: 1, information: 'Die id des Containers der in dem alten Container angezeigt werden soll.' });
    this.parameterInfoArray.push({ method: InternalMethodType.SetAccountPopupData, index: 0, information: '' });
    this.parameterInfoArray.push({ method: InternalMethodType.SetAccountPopupData, index: 0, information: '' });

    this.parameterInfoArray.push({ method: InternalMethodType.ShowPopup, index: 0, information: 'Methode ist obsolete, bitte ShowJJPopup verwenden' });
    this.parameterInfoArray.push({ method: InternalMethodType.ClosePopup, index: 0, information: 'Methode obsolete, bitte nicht mehr verwenden' });
    this.parameterInfoArray.push({ method: InternalMethodType.ShowSoftphonePopup, index: 0, information: 'Methode obsolete, bitte nicht mehr verwenden' });
    this.parameterInfoArray.push({ method: InternalMethodType.CopyValuesFromEntityToEntity, index: 0, information: 'Methode obsolete, bitte nicht mehr verwenden' });
    this.parameterInfoArray.push({ method: InternalMethodType.CloseSoftphonePopup, index: 0, information: 'Methode obsolete, bitte nicht mehr verwenden' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadFirstTaskOfContact, index: 0, information: 'Der erste Parameter wird nicht mehr verwendet' });
    this.parameterInfoArray.push({ method: InternalMethodType.LoadFirstTaskOfContact, index: 1, information: 'Die id des Ansprechpartners' });
    this.parameterInfoArray.push({ method: InternalMethodType.DownloadExternalDataSourceResult, index: 0, information: 'Die id der externalDatasource' });
    this.parameterInfoArray.push({ method: InternalMethodType.DownloadExternalDataSourceResult, index: 1, information: 'Der Parameter der an die externalDatasurce übergeben werden soll' });
    this.parameterInfoArray.push({ method: InternalMethodType.DownloadExternalDataSourceResult, index: 2, information: 'Die Dateiart (pdf, word...)' });
    this.parameterInfoArray.push({ method: InternalMethodType.DownloadExternalDataSourceResult, index: 3, information: 'Der Dateiname' });
  
    this.parameterInfoArray.push({ method: InternalMethodType.SetAccountPopupData, index: 0, information: '' });
    

  }
}
