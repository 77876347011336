import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashbdashboardcomponent-mytoppageviewers',
  templateUrl: './dashbdashboardcomponent-mytoppageviewers.component.html',
  styleUrls: ['./dashbdashboardcomponent-mytoppageviewers.component.scss']
})
export class DashbdashboardcomponentMytoppageviewersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
