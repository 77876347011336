import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener, EventEmitter } from '@angular/core';
import { ApplicationUser } from '../../core/authentication/models/application-user';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../translation/language.service';
import { AuthService } from '../../core/authentication/services/auth.service';
import { SharedAPI } from '../service/sharedAPI';
import { Observable } from 'rxjs';
import { UiService } from '../../jollyjupiter/service/ui.service';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { EventService } from '../../jollyjupiter/service/event.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MethodService, InternalMethodType } from '../../jollyjupiter/service/method.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MiddlewareSignalrService } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { AxivasTranslateService } from '../translation/axivas-translate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  public innerHTML = null;
  appApplicationGUIDMaster = 'JJApplicationGUIDMaster';
  serverInfoFile: Observable<any>;
  public user: ApplicationUser;
  public userName: any;
  public userID: number;
  public userSubscription: Subscription;
  public userPermissionSubscription: Subscription;
  public newsCheckSubscription: Subscription;
  public applicationString: string;
  updateMenuBarSubscription: Subscription = new Subscription;
  closeMegaMenuSubscription: Subscription = new Subscription;
  translationLanguageChangedSubscription: Subscription = new Subscription;

  isSupervisor = false;
  smallWindowSize = 1500;
  confirmText = '';
  confirmHeader = '';
  isDirty = false;
  isSubmitted = false;
  axivasLogoClickBlocked = false;

  latestNews = 0;
  lastNewsCheck = 0;

  showLargeMenu = false;

  // mainMenuItems: any[];
  megaMenuItems: MenuItem[];

  public SilentRenewFailed: Boolean = false;

  constructor(private languageService: LanguageService
            , private authService: AuthService
            , private sharedAPI: SharedAPI
            , private uiService: UiService
            , public middlewareSignalrServiceService: MiddlewareSignalrService
            , public userService: UserService
            , private router: Router
            , private axivasTranslateService: AxivasTranslateService
            , private methodService: MethodService
            , private eventService: EventService
            , public applicationInfoService: ApplicationInfoService
            ) {

    this.translationLanguageChangedSubscription = this.languageService.translationLanguageChanged$.subscribe(translationLanguage => {
        // this.translateService.use(translationLanguage.identifier);
    });

    this.closeMegaMenuSubscription = this.eventService.closeMegaMenuEvent.subscribe(() => {
      this.showLargeMenu = false;
    });

    this.updateMenuBarSubscription = this.eventService.updateManuBarEvent.subscribe(event => {
      if (event.target === 'mainmenu') {
        this.uiService.getControls(event.arguments[0]).then(result => {
          result.forEach(item => {
            item.childs.sort((a, b) => a.order < b.order ? -1 : 1);
          });
          this.applicationInfoService.menuItems = result;
        });
      }
    });
  }

  ngOnInit() {
    this.sharedAPI.eventSilentRenewError.subscribe(
      errorRaised => {
        this.SilentRenewFailed = true;
    });
  }

  onClickAxivasLogo() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'dashboard']);
    // this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/dashboard']);
  }

  changeDesignerMode() {
    if (this.applicationInfoService.isDeveloper === true) {
      this.applicationInfoService.designerMode = !this.applicationInfoService.designerMode;
      this.applicationInfoService.isTimeRecordingUser = !this.applicationInfoService.isTimeRecordingUser;
      this.applicationInfoService.isDebugMode = !this.applicationInfoService.isDebugMode;
      this.applicationInfoService.isWebPhoneUser = !this.applicationInfoService.isWebPhoneUser;
    }
  }

  onRenewAccessTokenClicked() {

  }

  ngOnDestroy(): void {
    if (this.updateMenuBarSubscription) { this.updateMenuBarSubscription.unsubscribe(); }
    if (this.translationLanguageChangedSubscription) { this.translationLanguageChangedSubscription.unsubscribe(); }
    if (this.closeMegaMenuSubscription) { this.closeMegaMenuSubscription.unsubscribe(); }

    localStorage.setItem(this.appApplicationGUIDMaster, '');
    if (this.userSubscription) { this.userSubscription.unsubscribe(); };
    if (this.newsCheckSubscription) { this.newsCheckSubscription.unsubscribe(); }
    if (this.userPermissionSubscription) { this.userPermissionSubscription.unsubscribe(); }
    if (this.closeMegaMenuSubscription) { this.closeMegaMenuSubscription.unsubscribe(); }
  }

  getMegaMenuItems() {
    this.showLargeMenu = !this.showLargeMenu;
    return;
  }

  callMethod(control: any) {
    if (control.methods.length > 0) {
      this.applicationInfoService.highlightedMenuItem = control;
      this.methodService.launchInternalMethods(control);
    }
  }

  getMegaMenuSubItems(sourceItem: any, base: number = 0): any {
    if (sourceItem === undefined) { return; }
    const tempMenuItems: MenuItem[] = [];
    sourceItem.forEach(menuItem => {
      let singleItem: MenuItem;

      let subItems = null;
      if (menuItem.childs.length > 0) {
        subItems = this.getMegaMenuSubItems(menuItem.childs);
      }

      console.log('sourceItem', menuItem, subItems);
      if (subItems == null) {
        singleItem = {
          label: this.axivasTranslateService.getTranslationTextForToken(menuItem.displayText)
        };
      } else {
        singleItem = {
          label: this.axivasTranslateService.getTranslationTextForToken(menuItem.displayText),
          items:  subItems
        };
      }
      tempMenuItems.push(singleItem);
      console.log('tempMenuItems', base, tempMenuItems);
    });
    return tempMenuItems;
  }

  callExternalSite(type: number) {
    switch (type) {
      case 1:
        this.methodService.launchInternalMethod('url', ['https://tr.axivas.com']);
        break;
      case 2:
        this.methodService.launchInternalMethod('url', ['https://intranet.axivas.com/category/departments/it/jollyjupiter/']);
        break;
      case 3:
        this.methodService.launchInternalMethod('url', [this.axivasTranslateService.getTranslationTextForToken('General._.ManualLink')]);
        break;
    }
  }

  searchForValue() {
    if (this.applicationInfoService.quickSearchValue === '') {
      return;
    }
    // console.warn('searchForValue', this.applicationInfoService.quickSearchValue);
    this.eventService.showJjPopup(this.axivasTranslateService
      .getTranslationTextForToken('General._.QuickSearch'), 'quicksearch', '80', true);
  }

  handleQuickSearchKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.searchForValue();
    }
  }

  showDesignerMode() {
    this.applicationInfoService.designerMode = !this.applicationInfoService.designerMode;
  }
}
