
<div class="followup-dashboard">
    <div class="followup-dashboard__inner">

        <div class="followup-dashboard__nav">
            <div class="input">
                <label> {{'FollowUp.Label.SelectInputCampaignLabel' | texttransform }} </label>
                <select [(ngModel)]="selectedCampaign" (change)="setSelectedCampaignValue($event,selectedCampaign)" [disabled]="loading">
                    <option disabled [value]=0>{{ 'FollowUp.Label.SelectInputCampaignLabel' | texttransform }}</option>
                    <option *ngFor="let campaign of campaigns" [value]="campaign.id">{{ campaign.defaultName }}</option>
                </select>
            </div>
             <div class="input">
                <label>Pool Name</label>
                <select [(ngModel)]="selectedPool" (change)="setSelectedPoolValue(selectedPool)" [disabled]="loading">
                    <option disabled [value]=0>{{ 'FollowUp.Label.SelectInputPoolLabel' | texttransform }}</option>
                    <option *ngFor="let pool of campaignsPool" [value]="pool.id">{{ pool.defaultName }}</option>
                </select>
            </div>
        </div>

        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="globalFontSize apiResponseContainer">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{
                        'General.Label.LoadingData' | texttransform }}
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!loading && noDataAvailable && ( selectedCampaign ||  selectedPool )">
            <div class="col-12">
                <div class="apiResponseContainer globalFontSize">
                   {{'FollowUp.Label.NoDataAvailable' | texttransform }}
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!loading && !noDataAvailable && !selectedCampaign">
            <div class="col-12">
                <div class="apiResponseContainer globalFontSize">
                   {{'FollowUp.Label.SelectCampaign' | texttransform }}
                </div>
            </div>
        </div>

        <div class="tabContainer" *ngIf="!loading && !noDataAvailable">
            <div class="tabsection">
                    <button class="tabLinks" *ngFor="let tab of dashboardTabs; let currentIndex = index"
                    [ngClass]="{ tabActive: selectedTab === tab.id, tabInactive: selectedTab !== tab.id}" (click)="setCurrentTab(tab)">
                    {{ tab?.name | texttransform }}
                </button>
            </div>
            <div class="tabContainer__inner">
                    <div *ngIf="selectedTab === 0">
                        <div class="row" >
                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                    <div class="pie-container">
                                        <label>
                                            {{'FollowUp.Label.OverallTasks' | texttransform }}
                                        </label>
                                        <app-pie-chart [chartData]="overAllPieChartData" [pieSize]="60" [height]="197"></app-pie-chart>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                    <div class="chart-container">
                                        <label>
                                            {{'FollowUp.Label.TodayTaskStatus' | texttransform }}
                                        </label>
                                        <app-follow-up-task-chart [chartData]="overAllTodayTaskChart" [height]="200" [colors]="colors"></app-follow-up-task-chart>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <div class="chart-container">
                                        <label>
                                            {{'FollowUp.Label.OverallFutureTasks' | texttransform }}
                                            <svg tippy="{{'FollowUp.Label.FutureChartDescription'| texttransform }}" [tippyOptions]="{placement: 'bottom', theme:'everleadBlack' }" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg>
                                        </label>
                                        <app-follow-up-task-chart [chartData]="overAllTaskChart" [height]="200"></app-follow-up-task-chart>
                                    </div>
                                </div>
                     
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="overAllRowList">
                                        <app-followuplist [data]="general"></app-followuplist>  
                                    </div>
                                </div>
                            </div>
                        </div>

                    
                    <div  *ngIf="selectedTab === 1">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <app-followuplist [data]="personal" [listType]="'personal'"></app-followuplist>
                            </div>
                            
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="personal-pie-container">
                                    <label>
                                        {{'FollowUp.Label.OverallTasks' | texttransform }}
                                    </label>
                                    <app-pie-chart [chartData]="personalPieChartData" [pieSize]="60" [height]="297"></app-pie-chart>
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="chart-container">
                                    <label>
                                        {{'FollowUp.Label.TodayTaskStatus' | texttransform }}
                                      
                                    </label>
                                    <app-follow-up-task-chart [chartData]="personalTodayTaskChart" [height]="300" [colors]="colors"></app-follow-up-task-chart>
                                </div>  
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <div class="chart-container">
                                    <label>
                                        {{'FollowUp.Label.OverallFutureTasks' | texttransform }}
                                        <span tippy="{{'FollowUp.Label.FutureChartDescription'| texttransform }}" [tippyOptions]="{placement: 'bottom', theme:'everleadBlack' }">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg>
                                        </span>
                                    </label>
                                    <app-follow-up-task-chart [chartData]="personalTaskChart" [height]="300"></app-follow-up-task-chart>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
