import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { OpportunityService } from 'app/jollyjupiter/service/opportunity.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { VipLookup, VipService } from 'app/jollyjupiter/service/vip.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-entitytable-valuecontrol',
  templateUrl: './entitytable-valuecontrol.component.html',
  styleUrls: ['./entitytable-valuecontrol.component.scss']
})
export class EntitytableValuecontrolComponent implements OnInit {
  @Input() value = null;
  @Input() valueline = null;
  @Input() control = null;
  @Input() subcontrol = false;
  @Input() dataTableItem = null;
  @Input() darkmode = false;
  @Input() subcontrols = [];
  @Input() isSmallControlWidth = false;

  imageName = '';
  translatedValue = null;
  valueDate = null;
  valueDateIsInPast = false;
  leadstates = null;
  valueSave = null;
  docType = null;
  featureLeadscoring = false;

  constructor(
    public commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externalDatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private sharedAPI: SharedAPI,
    private methodService: MethodService,
    private messagingService: MessagingService,
    private vipService: VipService,
    private sharedApi: SharedAPI,
    private packagefeatureService: PackagefeatureService,
    public oppotunityService: OpportunityService
  ) { }

  ngOnInit(): void {
    this.backupValue();
    this.featureLeadscoring = this.packagefeatureService.selectedPackageHasFeature(Number(this.applicationInfoService.applicationSettings['featureId_leadscoring']));
  }

  getDocTypeName(){
    // ! ask christian for this condition of _1227_
    if(this.valueline._1227_name){
    }
    return this.valueline._1227_name.split('.')[1].toLowerCase()
  }

  backupValue() {
    if (!this.commonService.isNullOrUndefined(this.value)) {
      this.valueSave = JSON.parse(JSON.stringify(this.value));
    }
  }

  getTranslatedValue() {
    if (this.control.alternateLookupTable != null) {
      this.translatedValue = this.commonService.getAlternateTableValue(this.control.alternateLookupTable, this.value);
    } else {
      const value = this.getLookupValue(this.value, this.control);
      if (this.translatedValue == null) {
        if (value) {
          this.translatedValue = this.commonService.getTranslationValueFromArray(value, 'defaultName');
        }
      }
    }
    return this.translatedValue;
  }

  getLeadstates() {
    if (this.leadstates == null) {
      const tempStates = this.commonService.getAlternateTable('projectleadstate');
      if (tempStates.table != null) {
        this.leadstates = tempStates.table;
      }
    }
    return this.leadstates;
  }

  getLookupValue(value, tableLayoutItem) {
    if (this.commonService.isNullOrUndefined(value)) {
      return '';
    }
    const tableData = this.applicationInfoService.projectLookupTables.find(
      projectLookupTable => projectLookupTable.definitionId == Number(tableLayoutItem.lookupTableId) &&
                            projectLookupTable.source == tableLayoutItem.lookupTableSource
    );

    if (tableData) {
      const tableDataItem = tableData.lookups.find(lookupItem => lookupItem.id == Number(value));
      if (tableDataItem) {
        return tableDataItem;
      }
    }
  }

  getImage() {
    if (this.commonService.isNullOrUndefined(this.value)) {
      return;
    }

    if (!isNaN(this.value)) {
      return;
    }

    let image = this.value;
    if (this.value !== '') {
      var pattern = /^((http|https):\/\/)/;

      if(!pattern.test(image)) {
       image =  image.replace(image, 'https://'+ image);
      }
      this.imageName = image;
      return image;
    } else {
      // return 'assets/images/svg/'.concat(subChild.additionalSetting1);
    }
  }

  getDate() {
    if (this.value == null) { return ''; }
    if (this.valueDate == null) {
      this.valueDate = new Date(this.value).toLocaleString();
      const dateTest = new Date(this.value);
      const dateNow = new Date(Date.now());
      if (dateTest < dateNow) {
        this.valueDateIsInPast = true;
      }
    }
    return this.valueDate;
  }

  deleteItem() {
    let externalDataSourceId = null;
    switch(this.dataTableItem.mainEntityId) {
      case 462:
        externalDataSourceId = 587;
        break;
    }
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('MatTable.DeleteItem.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('MatTable.DeleteItem.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true, false, 'matTable - deleteRow');
        this.externalDatasourceService.executeExternalDataSource(externalDataSourceId, [this.valueline['id']])
        .then(() => {
          this.eventService.selectCurrentDataFilterEvent.emit();
          this.loaderService.display(false, false, 'matTable - deleteRow');
        })
        .catch(error => {
          this.loaderService.display(false, false, 'matTable - deleteRow');
          console.error(error);
        });
      },
      reject: () => {
        return;
      }
    });
  }

  addToAccountGroup() {
    this.eventService.customEvent.emit({
      type: 'addtoeventgroup',
      data: this.valueline
    });
  }

  downloadRecording() {
    let name = this.valueline['_1_name1'];
    if (this.commonService.isNullOrUndefined(name)) { name = ''; }
    let lookupName = this.getTranslatedValue();
    let fileName = ''.concat(
      this.valueline.id, '_', name, '_', lookupName, '.mp3'
    );
    fileName = this.commonService.removeIllegalCharactersFromFileName(fileName);
    this.sharedAPI.downloadRecording({
      partialFileStorageUrl: this.valueline['_934_partialfilestorageurl'],
      storageUrl: this.valueline['_934_storageurl']
    }, null).subscribe(result => {
      this.commonService.downloadFile(result, 'audio/mpeg', fileName);
    })

  }

  getMinHeight() {
    let returnValue = '100%';
    if (!this.commonService.isNullOrUndefined(this.control.minHeight) && this.control.minHeight != '') {
      returnValue = this.control.minHeight;
    }
    if (this.control.minHeight == '') {
      returnValue = '100%'
    }
    return returnValue;
  }

  getAITitle() {

  }

  callAsstetLink() {
    this.commonService.openUrl(this.valueline['_462_url'], '_new');
  }

  getAssetLinkTitle() {
    let returnValue = '';
    returnValue = returnValue.concat(
      this.axivasTranslateService.getTranslationTextForToken('EntityTableValue.Label.AssetLink'),
      ': ',
      '\n',
      this.valueline['_462_url']
    )
    return returnValue;
  }

  removeDemoGuid(item) {
    if (this.applicationInfoService.demoLandingPage != null) {
      item = item.replace(this.applicationInfoService.demoLandingPage, this.applicationInfoService.applicationSettings['demoLandingPageReplaceString']);
    }
    return item;
  }

  getLeadScoreProspectValue(value) {
    if (!this.commonService.isNullOrUndefined(value)) {
      return value * 100;
    } else {
      return null;
    }
  }

  changeProspectBlackList() {
    let isblacklisted = !this.valueline.isblacklisted;
    this.loaderService.display(true);
    this.externalDatasourceService.executeExternalDataSource(291, [
      this.commonService.getModifyArrayBody({
        id: this.valueline.id,
        isblacklisted: isblacklisted,
        projectId: this.applicationInfoService.projectID
      }, [], [])
    ])
    .then(()  => {
      this.loaderService.display(false);
      this.valueline.isblacklisted = isblacklisted;
    })
    .catch(() => { this.loaderService.display(false); })
  }

  loadConnectedAccount() {
    this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [this.valueline.accountid]);
  }

  createAccountFromProspect() {
    this.loaderService.display(true);
    this.externalDatasourceService.executeExternalDataSource(292, [this.valueline.id])
    .then(createAccountFromProspectResult => {
      this.loaderService.display(false);
      this.valueline.accountid = createAccountFromProspectResult.id;
      this.valueline['_1_street'] = createAccountFromProspectResult.street;
      this.valueline['_1_zipcode'] = createAccountFromProspectResult.zipcode;
      this.valueline['_1_city'] = createAccountFromProspectResult.city;
      this.valueline['_1_name1'] = createAccountFromProspectResult.name1;
      this.messagingService.showDefaultSuccess('', 'Prospect.Message.AccountCreationSuccessfull', false);
    })
    .catch(error => { console.error(error); })
    .finally(() => { this.loaderService.display(false); });

  }

  changeProspectBlacklistMode() {
    let isBlacklisted = false;
    if (this.value == false) {
      isBlacklisted = true;
    }

    return new Promise((resolve, reject) => {
      this.loaderService.display(true);
      this.externalDatasourceService.executeExternalDataSource(291, [
        this.commonService.getModifyArrayBody({
          id: this.valueline.id,
          isblacklisted: isBlacklisted,
          projectId: this.applicationInfoService.projectID
        }, [], [])
      ])
      .then(() => {
        this.value = isBlacklisted;
        this.loaderService.display(false);
        this.messagingService.showDefaultSuccess('', 'Prospect.Message.Saved', false)
      }).catch(() => { this.loaderService.display(false); });
    });
  }

  changeVIPStatus() {
    	let value = null;
      let typeLookupId = null;
      let displayName = '';

      if (this.value == null) {
        this.loaderService.display(true);
        switch(this.dataTableItem.mainEntityId) {
          case 1:
            typeLookupId = VipLookup.Account;
            displayName = this.valueline['_1_name1'];
            value = this.valueline['id'];
            break;
          case 4:
            typeLookupId = VipLookup.Contact;
            displayName = this.valueline['_4_firstname'].concat(' ', this.valueline['_4_lastname']);
            value = this.valueline['id'];
            break;
          case 917:
            typeLookupId = VipLookup.Prospect;
            value = this.valueline['_917_url'];
            displayName =this.valueline['_917_companyName'];
            break;
        }
        this.vipService.addItemToVipList(displayName, value, typeLookupId).then(createVipSettingResult => {
          this.value = createVipSettingResult.id;
          this.valueline['vip_setting_id'] = createVipSettingResult.id;
          this.loaderService.display(false);
        }).catch(() => { this.loaderService.display(false); });
      } else {
        let vipId = this.valueline['vip_setting_id'];
        if (this.commonService.isNullOrUndefined(vipId)) {
          vipId = this.valueline['id'];
        }
        this.vipService.removeItemFromVipList(vipId).then(() => {
          this.loaderService.display(false);
          this.value = null;
        }).catch(() => { this.loaderService.display(false); });
      }
  }

  updateLeadstate() {
    if (this.value == this.valueSave) { return; }
    this.externalDatasourceService.executeExternalDataSource(843, [
      this.commonService.getModifyArrayBody({
        id: this.valueline['id'],
        leadStateId: this.value
      }, [])
    ]).then(() => {
      this.backupValue();
      let leadStateForBooking = this.leadstates.find(leadstate => leadstate.id == this.value);
      this.methodService.bookLeadstatusChange(this.valueline['accountid'], this.valueline['id'], leadStateForBooking.lookupTable.id);
    });
  }

  editOptin(valueLine) {
    this.applicationInfoService.miscSettings['optinoptouteditorvalue'] = this.valueline;
    this.eventService.showJjPopup('OptinOptOutEditor.Label.EditOptInOptOut', 'optinoptouteditor', '500px;500px')
  }

  createTaskForContact() {
    this.methodService.launchInternalMethod(InternalMethodType.CreateNewTaskForContact, [this.valueline['id']]);
  }

  downloadEntityFile() {
    this.sharedApi.contentLibraryDownloadFile(
      this.valueline.id,
      this.applicationInfoService.applicationSettings['entityFileUploadSourceId']
    ).subscribe(result => {
      let fileDetails = this.valueline._1227_name.split('.');
      this.commonService.downloadFile(result, 'application/'.concat(fileDetails[fileDetails.length-1]), this.valueline._1227_name);
    });
  }

  deleteEntityFile() {
    this.loaderService.display(true);
    this.sharedApi.contentLibraryDeleteFile(
      this.valueline.id, 
      this.applicationInfoService.applicationSettings['entityFileUploadSourceId']
    ).subscribe(
      () => {
        this.loaderService.display(false);    
        this.eventService.customEvent.emit({ eventType: 'updateEntityTableView' });
      },
      (error) => {
        console.error(error);
        this.loaderService.display(false);    
      }
    );  
  }

  editOpportunity() {
    // this.applicationInfoService.entities.Item('99').data = {
    //   id: this.valueline.id,
    //   comment: this.valueline._99_comment,
    //   value: this.valueline._99_value,
    //   statusId: this.valueline._99_statusid
    // };
    this.externalDatasourceService.executeExternalDataSource(955, [this.valueline.id]).then(editOpportunityResult => {
      this.applicationInfoService.entities.Item('99').data = editOpportunityResult;
      this.oppotunityService.showOpportunityForm(true);
    });    
  }

  changeOpportunityStatus() {
    const opportunityStatus = this.oppotunityService.opportunityStates.find(opstate => opstate.id == this.value);
    let bookingNotes = '';
    this.externalDatasourceService.executeExternalDataSource(325, [
      this.commonService.getModifyArrayBody({
        id: this.valueline.id,
        statusId: opportunityStatus.id
      } , [])
    ])
    bookingNotes = 'Status auf <0> geändert';
    bookingNotes = bookingNotes.replace('<0>', opportunityStatus.defaultName);
    this.methodService.bookOpportunitystatusChange(this.valueline['_99_accountid'], this.valueline['_99_contactid'], bookingNotes);
  }
}
