<div class="followUpItem" [ngClass]="{ aiBorder: isAi }">
    <div class="followUpItem__logo " [ngClass]="{ everleadBlueColor: !isAi, aiBox: isAi}">
        <div *ngIf="!isAi">
            <svg-icon src="assets/images/svg/account_factory.svg" *ngIf="followUp.contactId == null" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
            <svg-icon src="assets/images/svg/dashboard_contact.svg" *ngIf="followUp.contactId != null" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
        </div>
        <div *ngIf="isAi">
            <svg-icon src="assets/images/svg/ai/everlead_ai_thumbs_up2.svg" class="aiIcon" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
        </div>
    </div>
    <div class="followUpItem__item">
        <div class="ellipsisText followUpItem__item__text followUpItem__item__border">{{
            getAccountName(followUp) }}</div>
        <div class="followUpItem__item__subtext followUpItem__item__border ellipsisText"
            *ngIf="followUp.contactId != null">
            <span class="everleadCoral">AP:</span>
            {{ getContactName(followUp) }}
        </div>
    </div>

    <div class="followUpItem__item">
        <div class="ellipsisText followUpItem__item__date" *ngIf="isToday && !isInPast">{{ 'WebsiteDashboard.Label.Today' | texttransform }}</div>
        <div class="ellipsisText followUpItem__item__date" *ngIf="isSoon">{{ 'WebsiteDashboard.Label.Soon' | texttransform }}</div>
        <div class="ellipsisText followUpItem__item__date dateTomorrow" *ngIf="isTomorrow">{{ 'WebsiteDashboard.Label.Tomorrow' | texttransform }}</div>

        <div class="ellipsisText followUpItem__item__date" *ngIf="isInPast">{{ 'WebsiteDashboard.Label.InThePast' | texttransform }}</div>
        <div class="followUpDate" [ngClass]="{ everleadCoral: isInPast }">{{ followUp.followUpDate | date: 'medium' }}</div>
    </div>

    <div class="followUpItem__type">
        <div class="followUpItem__type__icon"><svg-icon src="assets/images/svg/dashboard_phone.svg"></svg-icon></div>
        <div class="followUpItem__type__text everleadCoral">Call</div>
    </div>
</div>