<label class="dataRightsHeaderLabel__top"> {{ 'DataRights.Label.WhyDoISeeThisItem' | texttransform }}</label>

<label class="dataRightsHeaderLabel"> {{ 'DataRights.Label.MyGroups' | texttransform }}</label>
<div *ngFor="let myGroup of infoResult.myGroups">
    <div class="nonExpertView__main">
        <div class="nonExpertView__name">            
            <label>{{ myGroup.defaultName }}</label>
        </div>
        <div class="">
            <div class="nonExpertView__bonus">
                <i class="fas fa-eye" *ngIf="myGroup.isReadOnly" [title]="'DataRights.Label.AccessLevelOnlyRead' | texttransform"></i>
                <i class="fas fa-feather" *ngIf="!myGroup.isReadOnly" [title]="'DataRights.Label.AccessLevelFullAccess' | texttransform"></i>
            </div>
        </div>
    </div>
</div>

<label class="dataRightsHeaderLabel" *ngIf="infoResult?.otherGroups?.length > 0"> {{ 'DataRights.Label.OtherGroups' | texttransform }}</label>
<div *ngFor="let myGroup of infoResult.otherGroups">
    <div class="nonExpertView__main">
        <div class="nonExpertView__name">            
            <label>{{ myGroup.defaultName }}</label>
        </div>
        <div class="">
            <div class="nonExpertView__bonus">
                <i class="fas fa-eye" *ngIf="myGroup.isReadOnly" [title]="'DataRights.Label.AccessLevelOnlyRead' | texttransform"></i>
                <i class="fas fa-feather" *ngIf="!myGroup.isReadOnly" [title]="'DataRights.Label.AccessLevelFullAccess' | texttransform"></i>
            </div>
        </div>
    </div>
</div>
