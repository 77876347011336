<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="createNewLogicalEntity()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
        Neue Abfrage
    </div>
    <div class="projectSettings__header__interaction">
    </div>
</div>
<div class="nonExpertHeader">
    <div class="nonExpertHeader__name">Name</div>
    <div class="nonExpertView__smallText">ListId</div>
    <div class="nonExpertView__smallText">ContainerId</div>    
    <div class="nonExpertView__smallText">Projekt</div>    
    <div class="nonExpertView__smallText">Entity</div>    
    <div class="nonExpertView__smallText">UIEntityInstance</div>    
</div>

<div class="nonExpertView__wrapper">
    <div *ngFor="let logicalEntity of logicalEntities" class="nonExpertView__main nonExpertView__main__noSubitems">
        <div class="nonExpertView__name">            
            <input [(ngModel)]="logicalEntity.defaultName" (change)="saveLogicalEntity(logicalEntity)">
            <app-wizard-translations [projectSpecific]=false [array]="logicalEntity" updateBaseArrayExternaldatasourceId=606 mode="3"></app-wizard-translations>    
        </div>        
        <div class="nonExpertView__smallText">
            <input [(ngModel)]="logicalEntity.listContainerLogicalControlId" type="number" (change)="saveLogicalEntity(logicalEntity)">            
        </div>
        <div class="nonExpertView__smallText">
            <input [(ngModel)]="logicalEntity.editContainerLogicalControlId" type="number" (change)="saveLogicalEntity(logicalEntity)">
        </div>
        <div class="nonExpertView__smallText">
            <select [(ngModel)]="logicalEntity.projectId" (change)="saveLogicalEntity(logicalEntity)">
                <option [ngValue]=null>Alle Projekte</option>
                <option *ngFor="let project of projects" [ngValue]="project.id">{{ project.projectName }}</option>
            </select>
        </div>
        <div class="nonExpertView__smallText">
            <select [(ngModel)]="logicalEntity.entityId" (change)="saveLogicalEntity(logicalEntity)">
                <option *ngFor="let entity of entities" [ngValue]="entity.id">({{ entity.id }}) {{ entity.entityName }}</option>
            </select>
        </div>
        <div class="nonExpertView__smallText">
            <input [(ngModel)]="logicalEntity.uiEntityInstanceId" type="number" (change)="saveLogicalEntity(logicalEntity)">
        </div>
    </div>
</div>
