<div class="radioButtonSelector">
    <div class="radioButtonSelector__buttons">
        <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=1>{{ 'MicroCampaign.Label.Overview' | texttransform }}</div>
        <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=2>{{ 'MicroCampaign.Label.Detailview' | texttransform }}</div>        
    </div>   
</div>

<div class="microCampaignWrapper" *ngIf="viewMode == 1 && applicationInfoService.currentContact !== null">
    <div class="microCampaignList" *ngIf="microcampaignService.microcampaigns?.length != 0">
        <div class="microCampaignListItem microCampaignListItem__small">            
            <div class="microCampaignListItem__name">{{ 'MicroCampaign.Label.Name' | texttransform }}</div>
            <div class="microCampaignListItem__startDate">{{ 'MicroCampaign.Label.StartDate' | texttransform }}</div>
            <div class="microCampaignListItem__currentStep">{{ 'MicroCampaign.Label.CurrentStep' | texttransform }}</div>
            <div class="microCampaignListItem__status">{{ 'MicroCampaign.Label.Status' | texttransform }}</div>
        </div>
        <div class="microCampaignListItem" *ngFor="let microcampaign of microcampaignService.microcampaigns" [ngClass]="{
            microCampaignListItem__active: microcampaignState[microcampaign.id],
            hideInDom: microcampaign.nextStepCampaignId == null && applicationInfoService.applicationSettings['isDemoproject'] != 'true'
        }">
            <div class="microCampaignListItem__name">{{ microcampaign.defaultName | texttransform }}</div>
            <div class="microCampaignListItem__startDate" *ngIf="microcampaignState[microcampaign.id]">
                {{ microcampaignState[microcampaign.id]?.startDate | date: 'medium' }}
            </div>
            <div class="microCampaignListItem__currentStep" *ngIf="microcampaignState[microcampaign.id]">
                {{ getStepName(microcampaignState[microcampaign.id]?.currentStepId) | texttransform }}
            </div>
            <div class="microCampaignListItem__status">
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                    (click)="cancelMicroCampaign(microcampaignState[microcampaign.id])"
                    *ngIf="microcampaignState[microcampaign.id] != null && microcampaignState[microcampaign.id] != undefined"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                    (click)="startCampaign(microcampaign)"
                    *ngIf="microcampaignState[microcampaign.id] == null || microcampaignState[microcampaign.id] == undefined"></svg-icon> 
            </div>
        </div>
    </div>
    
    <div class="microCampaignList" *ngIf="microcampaignService.microcampaigns?.length == 0" class="microCampaignEmpty">
        {{ 'MicroCampaign.Label.NoMicroCampaignAvailable' | texttransform }}
    </div>
</div>

<div class="microCampaignList" *ngIf="viewMode == 1 && applicationInfoService.currentContact == null" class="microCampaignEmpty">
    {{ 'MicroCampaign.Label.StartCampaignRequiresSelectedContact' | texttransform }}
</div>


<div class="microCampaignWrapper" *ngIf="viewMode==2">
    <div class="row microCampaignWrapper">
        <div class="microCampaignCancelPopup"></div>
        <div class="col-12 darkMode" style="padding: 0">
            <div class="microCampaignHeader">
                <div class="microCampaignHeader__buttons">
                    <div class="microCampaignHeader__button microCampaignHeader__button__search">
                        <div class="microCampaignHeader__svg">
                            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
                        </div>
                        <input class="microCampaignHeader__filter" [placeholder]="'General.Label.Search' | texttransform" [(ngModel)]="filterString">
                    </div>
                </div>

            </div>
            <div *ngIf="doubleOptinMissing == true && doubleOptinMissingChecked == true" class="everleadCoral">
                {{ 'MailToContact.Label.MicroCampaignRequiresDoubleOptin' | texttransform }}<br><br>
            </div>
            <div *ngIf="(dataArray == null || dataArray.length == 0) && loadingData == false" class="microCampaignHeader__infoLabel">
                {{ 'General.Label.NoCampaignFound' | texttransform }}<br>
                <br>
            </div>
            <div *ngIf="applicationInfoService.currentContact == null" class="microCampaignHeader__infoLabel">
                {{ 'MicroCampaign.Label.StartCampaignRequiresSelectedContact' | texttransform }}
            </div>


            <span *ngIf="loadingData" class="loadingDataSpinner">
                <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </span>

            <div class="matTableWrapper tableMargin" *ngIf="loadingData == false">
                <table mat-table #table [dataSource]="dataSource" multiTemplateDataRows matSort
                    class="matTabl microCampaignsTable">
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="" [ngClass]="{ hideInDom: !isItemFiltered(element) }">
                            <div class="microCampaignsTable__campaign">
                                <div class="microCampaignsTable__campaign__metalinehead">
                                <div class="microCampaignsTable__campaign__metaline1">
                                    <div class="microCampaignsTable__campaign__meta">
                                        <div class="microCampaignsTable__campaign__meta__information">
                                            <div class="microCampaignsTable__campaign__meta__information__campaign">
                                            {{element.campaignName | texttransform }}
                                            </div>
                                                <div class="microCampaignsTable__campaign__meta__leadstate" *ngIf="element.leadStatus != null"
                                                    [ngClass]="{
                                                    positive: element.taskLeadPositivityValue  == 1,
                                                    neutral: element.taskLeadPositivityValue  == 0.5,
                                                    negative: element.taskLeadPositivityValue  == 0
                                                }"><label class="ellipsisText">{{element.leadStatus | texttransform }}</label></div>
                                        </div>

                                        <div class="microCampaignsTable__campaign__meta__trigger">
                                            <button
                                                *ngIf="element.currentTaskResultDate == null && isValidStep(element.currentTaskStepId)"
                                                class="microCampaignsTable__campaign__meta__trigger__button"
                                                (click)="bookResult(element)">{{ 'MicroCampaign.Label.CreateTask' |
                                                axivastranslate }}</button>
                                        </div>                                    
                                        <div class="microCampaignsRefreshButton fingerCursor" (click)="cancelMicroCampaign(element.currentTaskId)" [tippyOptions]="{theme: 'everleadRed'}"
                                            *ngIf="element.currentTaskResultId == null" [tippy]="'MicroCampaign.Label.CancelCampaign' | texttransform">
                                            <i class="fas fa-ban"></i>
                                        </div>

                                        <div class="microCampaignsRefreshButton fingerCursor" (click)="getDataList()">
                                            <i class="fas fa-refresh"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="microCampaignsTable__campaign__metaline2">
                                    <div class="microCampaignsTable__campaign__metaline2__item">
                                        <div class="value topLevelIcon"><i
                                                [class]="getTypeIcon('fas circle-icon microCampaignsTable__campaign__metaline2__item__icon', element.firstTaskType)"></i>
                                        </div>
                                        <label>{{ 'MicroCampaign.Label.StartActivity' | texttransform }}: </label>
                                        <div>{{element.firstTaskStep | texttransform }}</div>
                                    </div>

                                    <div class="microCampaignsTable__campaign__metaline2__item">
                                        <label>{{ 'MicroCampaign.Label.Start' | texttransform }}:</label>
                                        <span class="value">{{element.firstTaskDate | date: 'medium'}}</span>
                                    </div>

                                    <div class="microCampaignsTable__campaign__metaline2__item">
                                        <label *ngIf="element.currentTaskResultDate != null">{{
                                            'MicroCampaign.Label.End' | texttransform }}:</label>
                                        <span *ngIf="element.currentTaskResultDate != null"
                                            class="value">{{element.currentTaskResultDate | date: 'medium'}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="microCampaignsTable__campaign__body__wrapper">
                                <div class="microCampaignsTable__campaign__body">
                                    <app-flowchart
                                    [chartData]="element"
                                    [controlid]="'campaignOverview'"
                                    [height]="'25vh'"
                                    [treeHeight]="'80%'"
                                    [width]="'100%'"
                                    [top]="'25%'"
                                    [initialTreeDepth]="element.previousTaskStep ? 2 : 1"
                                    [toolboxInfo]="false"
                                    [toolboxExpand]="true"
                                    [dataToolbox]="element"
                                    ></app-flowchart>
                                </div>
                                <app-custom-timeline [newMode]=true type=2 [microCampaign]="element" [id]="i"></app-custom-timeline>
                            </div>                   
                        </div>
                        </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: [ 'type'];"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
