import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CustomControlBaseClass } from '../../custom/customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';

@Component({
  selector: 'app-callprojectbooking',
  templateUrl: './callprojectbooking.component.html',
  styleUrls: ['./callprojectbooking.component.scss']
})
export class CallprojectbookingComponent extends CustomControlBaseClass  implements OnInit, OnDestroy {
  updateBookingPreviewEventScription: Subscription = new Subscription();
  doubleOptinMissing = false;
  optStatesResult = [];
  canBook = true;
  lastCheckedResultId = null;
  resultSendsEmail = false;
  emailValidationResult = null;

  constructor(
    private injector: Injector ,
    private emailtemplateService: EmailtemplateService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit(): void {
    this.checkIfResultNeedsOptin();

    this.updateBookingPreviewEventScription = this.eventService.updateBookingPreviewEvent.subscribe(event => {
      console.warn('updateBookingPreviewEvent')
      this.checkIfResultNeedsOptin();
    });

  }

  ngOnDestroy(): void {
    if (this.updateBookingPreviewEventScription) { this.updateBookingPreviewEventScription.unsubscribe(); }
  }

  bookTask() {
    this.methodService.launchInternalMethod(InternalMethodType.BookTask, []);
  }

  bookTaskAndStayInAccount() {
    this.methodService.launchInternalMethod(InternalMethodType.BookTaskAndStayInAccount, []);    
  }

  checkIfResultNeedsOptin() {
    this.canBook = false;
    this.resultSendsEmail = false;
    const resultId = this.applicationInfoService.entities.Item('2').data.resultId;
    if (resultId == this.lastCheckedResultId) { return; }
    const selectedResult = this.applicationInfoService.results.toArray().find(result => result.id == resultId);
    if (selectedResult) {
      this.lastCheckedResultId = resultId;
      if (selectedResult.emailTemplateId != null) {
        this.resultSendsEmail = true;
        this.externalDatasourceService.executeExternalDataSource(878, [selectedResult.emailTemplateId]).then(templateInfo => {
          if (templateInfo.isRequireDoubleOptIn == true) {
            this.getOptinStates();
          } else {
            this.canBook = true;
          }
        });
      } else {
        this.canBook = true;
      }      
    }
  }

  getOptinStates() {
    this.externalDatasourceService.executeExternalDataSource(879, [
      this.applicationInfoService.currentAccount.id,
      this.applicationInfoService.currentContact.id
    ]).then(getOptStatesResult => { 
      this.optStatesResult = getOptStatesResult;
      this.checkDoubleOptin();
    });
  }

  checkDoubleOptin(): Promise<any> {
    return new Promise((checkDoubleOptinResolve, checkDoubleOptinReject) => {            
      const emailTypeLookup = this.commonService.getOptinStateForMedia({ id: 47 }, this.optStatesResult, this.applicationInfoService.currentContact);
      const allMediaTypeLookup = this.commonService.getOptinStateForMedia({ id: 51 }, this.optStatesResult, this.applicationInfoService.currentContact);
      if (emailTypeLookup.lookupTypeRefId == 1815 && emailTypeLookup.endDateInPast == false) {  this.canBook = true; }
      if (allMediaTypeLookup.lookupTypeRefId == 1815 && allMediaTypeLookup.endDateInPast == false) { this.canBook = true; }

      checkDoubleOptinResolve(null);
    });
  }

  validateEmailViaAPI() {
    const eMail = this.applicationInfoService.entities.Item('4').data.email;
    if (this.commonService.isNullOrUndefined(eMail) || eMail == '') {
      return;
    }
    this.externalDatasourceService.executeExternalDataSource(898, [eMail]).then(validationResult => {
      this.emailValidationResult = validationResult;
    });
  }

  generateTippy() {
    return this.emailtemplateService.generateTippyForValidation(this.emailValidationResult);
  }
}
