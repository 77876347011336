import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-uicontrol-valueitem',
  templateUrl: './uicontrol-valueitem.component.html',
  styleUrls: ['./uicontrol-valueitem.component.scss']
})
export class UicontrolValueitemComponent implements OnInit {
  @Input() valueItemName = '';
  @Input() valueItemNameValue = '';
  @Input() valueType = 0;
  @Input() control = null;
  @Input() member = null;  
  @Output() valueStatusChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {  
    if (this.control != null) {
      this.valueItemNameValue = this.control[this.member];
    }
  }

  changeValueEmitter() {
    this.valueStatusChange.emit({ 
      value: this.valueItemNameValue,
      member: this.member
    });
  }
}
