<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <div *ngIf="wizardService.wizardPage == 1">
            <label class="wizardSubheader__headerLabel">{{ 'LanguageDesign.Label.German' | texttransform }}</label>
        </div>
        <div *ngIf="wizardService.wizardPage == 2">
            <label class="wizardSubheader__headerLabel">{{ 'LanguageDesign.Label.English' | texttransform }}</label>            
        </div>        
        <label class="wizardSubheader__descriptionLabel">{{ 'LanguageDesign.PageInfo.GeneralHeader' | texttransform }}</label>
        <div *ngIf="wizardService.translationArrayBase[wizardService.translationDefaultValue] != null">
            <label class="wizardSubheader__descriptionLabel">
                <b>{{ 'LanguageDesign.Label.TranslationBaseDefaultName' | texttransform }}:</b> {{ wizardService.translationArrayBase.defaultName }}
            </label>
        </div>        
    </div>


    <div *ngIf="wizardService.wizardPage == 3">
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">{{ 'Wizard.Translation.Name' | texttransform }}
                <app-wizard-infobutton displayText="Wizard.Info.TranslationName"></app-wizard-infobutton>
            </label>
            <div *ngFor="let language of languages">
                <div class="languageLine">
                    <div class="languageLine__name">{{ commonService.getTranslationValueFromArray(language, 'languageName') | texttransform }}</div>
                    <div class="languageLine__value">
                        <input [(ngModel)]="wizardService.translationArray['name' + language.id]"
                            [placeholder]="wizardService.translationArrayBase[wizardService.translationDefaultValue] | texttransform"
                            (change)="changeTranslation('name', language.id)"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">{{ 'Wizard.Translation.Description' | texttransform }}
                <app-wizard-infobutton displayText="Wizard.Info.TranslationDescription"></app-wizard-infobutton>
            </label>
            <div *ngFor="let language of languages">
                <div class="languageLine">
                    <div class="languageLine__name">{{ commonService.getTranslationValueFromArray(language, 'languageName') | texttransform }}</div>
                    <div class="languageLine__value"><input [(ngModel)]="wizardService.translationArray['desc' + language.id]"
                        [placeholder]="wizardService.translationArrayBase[wizardService.translationDefaultValue] | texttransform"
                            (change)="changeTranslation('desc', language.id)"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="wizardService.wizardPage == 1">
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">{{ 'Wizard.Translation.Name' | texttransform }}<app-wizard-infobutton displayText="Wizard.Info.TranslationName"></app-wizard-infobutton></label>
            <input  [placeholder]="wizardService.translationArrayBase[wizardService.translationDefaultValue] | texttransform" 
            [(ngModel)]="wizardService.translationArray['name' + 2]" class="wizardInput" (change)="changeTranslation('name', 2)">            
        </div>
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'Wizard.Translation.Description' | texttransform }}
                <app-wizard-infobutton displayText="Wizard.Info.TranslationDescription"></app-wizard-infobutton>    
            </label>
            <input [placeholder]="wizardService.translationArrayBase[wizardService.translationDefaultValue] | texttransform" 
            [(ngModel)]="wizardService.translationArray['desc' + 2]" class="wizardInput" (change)="changeTranslation('desc', 2)">             
        </div>
    </div>

    <div *ngIf="wizardService.wizardPage == 2">
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'Wizard.Translation.Name' | texttransform }}
                <app-wizard-infobutton displayText="Wizard.Info.TranslationName"></app-wizard-infobutton>    
            </label>
            <input [placeholder]="wizardService.translationArrayBase[wizardService.translationDefaultValue] | texttransform" 
            [(ngModel)]="wizardService.translationArray['name' + 1]" class="wizardInput" (change)="changeTranslation('name', 1)">          
        </div>
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'Wizard.Translation.Description' | texttransform }}
                <app-wizard-infobutton displayText="Wizard.Info.TranslationDescription"></app-wizard-infobutton>    
            </label>
            <input [placeholder]="wizardService.translationArrayBase[wizardService.translationDefaultValue] | texttransform" 
            [(ngModel)]="wizardService.translationArray['desc' + 1]" class="wizardInput" (change)="changeTranslation('desc', 1)">            
        </div>
    </div>
</div>
