<div class="emailDistributor__wrapper">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createNewDistributionList()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>               
            {{ 'DistributionList.Label.NewList' | texttransform }}
        </div>
        <!-- <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div> -->
    </div>
    <div class="everleadShadowBox" *ngIf="distributionLists.length > 0">
        <select [(ngModel)]="selectedDistributionList" class="maxWidth">
            <option *ngFor="let distributionList of distributionLists" [ngValue]="distributionList">
                {{ distributionList.defaultName }}
            </option>
        </select>
    </div>
        
    <div *ngIf="selectedDistributionList != null">
        <div class="everleadShadowBox">    
            <div class="d-flex">
                <input [(ngModel)]="selectedDistributionList.defaultName" class="emailDistributor__input" (change)="updateDistributionList(selectedDistributionList)">
                <app-wizard-translations [array]="selectedDistributionList" updateBaseArrayExternaldatasourceId=709 mode="3"></app-wizard-translations>    
            </div>    
            <span id="emailDistibutorDesignSpan"></span>    
            <div class="distributions autoOverflowY" [style.height]="getDivHeight()">
                <div class="everleadSmallHeader">{{ 'DistributionList.Label.Distributions' | texttransform }}</div>
                <div *ngFor="let distribution of selectedDistributionList.distributions" class="distributionItem everleadShadowBox">            
                    <div class="d-flex emailDistributor__header">
                        <div class="maxWidth">
                            <div class="everleadSmallHeader">{{ 'DistributionList.Label.EMailReceipiant' | texttransform }}</div>
                            <select class="emailDistributor__receipiant" [(ngModel)]="distribution.distributionEmailId" (change)="saveDistribution(distribution)">
                                <option [ngValue]=null></option>
                                <option [ngValue]="distributionEmail.id" *ngFor="let distributionEmail of distributionEmails">
                                    {{ distributionEmail.email }}
                                </option>
                            </select>
                            <i class="fas fa-plus fingerCursor" (click)="setNewMailMode(distribution)"></i>
                        </div>
                        <div>
                            <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteDistribution(distribution, selectedDistributionList)"></i>
                        </div>
                    </div>
                    <div class="d-flex smallerFontSize">
                        <div class="distributionGeneralInfo maxWidth">
                            <div class="everleadSmallHeader">{{ 'DistributionList.Label.DistributionGeneralInfo' | texttransform }}</div>
                            <div><input type="checkbox" (change)="saveDistribution(distribution)" [(ngModel)]="distribution.isActive"><label>{{ 'DistributionList.Label.IsActive' | texttransform }}</label></div>
                            <div><input type="checkbox" (change)="saveDistribution(distribution)" [(ngModel)]="distribution.isMailTo"><label>{{ 'DistributionList.Label.IsMailTo' | texttransform }}</label></div>
                            <div><input type="checkbox" (change)="saveDistribution(distribution)" [(ngModel)]="distribution.isMailCc"><label>{{ 'DistributionList.Label.IsMailCc' | texttransform }}</label></div>
                            <div><input type="checkbox" (change)="saveDistribution(distribution)" [(ngModel)]="distribution.isMailBcc"><label>{{ 'DistributionList.Label.IsMailBcc' | texttransform }}</label></div>
                            <div><input type="checkbox" (change)="saveDistribution(distribution)" [(ngModel)]="distribution.isReceiveEmailsDuringAbsence"><label>{{ 'DistributionList.Label.IsReceiveEmailsDuringAbsence' | texttransform }}</label></div>
                        </div>        
                        <div class="maxWidth">
                            <div class="everleadSmallHeader">{{ 'DistributionList.Label.DistributionSubstituteTimeframe' | texttransform }}</div>
                            <div class="distributionTimeframe">
                                <div class="distributionTimeframe__item">
                                    <label>{{ 'DistributionList.Label.SubstituteFrom' | texttransform }}</label>
                                    <input [(ngModel)]="distribution.from" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly appendTo="body" aria-modal="true"
                                        (dateTimeChange)="saveDistribution(distribution)">
                                    <owl-date-time #dt7></owl-date-time>
                                </div>
                                <div class="distributionTimeframe__item">
                                    <label>{{ 'DistributionList.Label.SubstituteUntil' | texttransform }}</label>
                                    <input [(ngModel)]="distribution.until" [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly appendTo="body" aria-modal="true"
                                        (dateTimeChange)="saveDistribution(distribution)">
                                    <owl-date-time #dt8></owl-date-time>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="newItem fingerCursor" (click)="createNewDistibution(selectedDistributionList)">
                    <i class="fas fa-plus"></i>
                    {{ 'DistributionList.Label.CreateNewDistribution' | texttransform }}
                </div>
            </div>
        </div>
    </div>

    <div class="emailDistributor__popup__background" *ngIf="createNewMailMode == true"></div>
    <div class="emailDistributor__popup" *ngIf="createNewMailMode == true">
        <div>
            <div class="emailDistributor__popup__header">
                <label>{{ 'DistributionList.Label.NewEmailAdress' | texttransform }}</label>
                <i class="fas fa-close fingerCursor" (click)="createNewMailMode = false"></i>
            </div>            
            <input [(ngModel)]="newEmailAdress" class="maxWidth">
            <br>
            <br>
            <button class="jjButtonStyle jjButtonColor" (click)="createNewDistributionEmail()" [disabled]="!checkIfValidEmail()">
                {{ 'DistributionList.Label.CreateNewDistribionEmail' | texttransform }}
            </button>
        </div>
    </div>
</div>