<span *ngIf="lookUpTableDefinitionId == -1">
    <label class="jjBold">
        {{ 'Import.Label.CreateNewDefinitionHeader' | texttransform }}
    </label><br>
    <label class="">
        {{ 'Import.Label.HelpTableCreateDescription' | texttransform }}
    </label><br>
    <label style="width: 200px;">{{ 'Import.Label.NameOfNewHelpTable' | texttransform }}</label>
    <input [(ngModel)]="newDefinitionName" style="width: 300px;">
    <button [disabled]="newDefinitionName?.length < 4" (click)="createNewDefinition()">{{ 'Import.Label.Create' | texttransform }}</button>
</span>

<span *ngIf="(lookUpTableDefinitionId != null && lookUpTableDefinitionId != -1) || readOnlyMode">
    <div>
        <label>{{ sourceValues.length }} {{ 'Import.Label.AvailableRecordsets' | texttransform }}</label>
        <button class="jjButtonColor jjButtonStyle fingerCursor floatRight" (click)="addMissingValues()"
            *ngIf="!readOnlyMode">{{ 'Import.Label.AddMissingLookupTables' | texttransform }}</button>
    </div>
    
    <div *ngFor="let sourceValue of sourceValues" class="importHelpTableSourceValueDiv" [ngClass]="{
        importHelpTablePositive: sourceIsAvailable(sourceValue)
        }">
        <label style="margin: 0 0 0 3px;">{{ sourceValue }}</label>
    </div>
    
</span>