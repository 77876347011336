import { Component, OnInit } from '@angular/core';
import { MiddlewareSignalrService } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-brakepopup',
  templateUrl: './brakepopup.component.html',
  styleUrls: ['./brakepopup.component.scss']
})
export class BrakepopupComponent implements OnInit {
  data: any[] = [];
  controlSelection: any;
  isTimeRecordingProjectChange = null;
  isLoadingData = true;

  constructor(
    private middlewareSignalrService: MiddlewareSignalrService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private methodService: MethodService
  ) { }

  ngOnInit() {
    this.isTimeRecordingProjectChange = this.applicationInfoService.isTimeRecordingProjectChange;
    this.applicationInfoService.isTimeRecordingProjectChange = null;
    this.getActivities();

    this.eventService.activitiesUpdatedEvent.subscribe(() => {
      this.getActivities();
    });
  }

  getActivities() {
    this.data = [];
    if (this.applicationInfoService.availableUserActivities) {
      this.applicationInfoService.availableUserActivities.forEach(element => {
        if (element.isBreak === false) {
          this.data.push({ value: element.activityId, label: element.translationTokenFullName });
        }
      });
      if (this.data.length !== 0) {
        this.isLoadingData = false;
      }
    }
  }

  getDropdownWidth() {
    if (this.applicationInfoService.isMobile()) {
      return {
        'width': '100%'
      };
    } else {
      return {
        'width': '50%'
      };
    }
  }

  backToWork() {
    this.middlewareSignalrService.hubConnection.invoke(
      'TimeRecordingChangeStatus',
      this.controlSelection,
      this.applicationInfoService.getTimeRecordingProjectId().toString())
    .then(result => {
      this.eventService.closePopup();
    })
    .catch(error => { console.error('backToWork', error); });
  }
}
