import { Component, OnInit, Input } from '@angular/core';
import { MethodService } from '../../../../jollyjupiter/service/method.service';

@Component({
  selector: 'app-custom-menu-item',
  templateUrl: './custom-menu-item.component.html',
  styleUrls: ['./custom-menu-item.component.scss']
})
export class CustomMenuItemComponent implements OnInit {
  @Input() menuItemControl: any = null;

  constructor(
    private methodService: MethodService
  ) { }

  ngOnInit() {
    
  }

  callMethod(control: any) {
    if(control.methods.length > 0) { 
      this.methodService.launchInternalMethods(control); 
    }
  }
}
