import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { Dictionary } from 'app/core/dictionary';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { DragDropelements } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-moco-assignment',
  templateUrl: './moco-assignment.component.html',
  styleUrls: ['./moco-assignment.component.scss']
})
export class MocoAssignmentComponent implements OnInit {
  @Input() campaignId = null;
  dragdropElements: DragDropelements[] = [];
  projects: Dictionary<any> = new Dictionary<any>();

  constructor(
    public dragdropService: DragdropService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.dragdropElements.push(new DragDropelements());
    this.dragdropElements.push(new DragDropelements());
    this.getCompleteList();
  }

  getCompleteList() {
    this.dragdropElements[0] = new DragDropelements();
    this.externaldatasourceService.executeExternalDataSource(6)
    .then(result => {
      result.forEach(item => {
        this.projects.Add(item.projectId, item);
        let dragDropItem: Object = [];
        dragDropItem = item;
        dragDropItem['userName'] = item.projectName;
        dragDropItem['workID'] = item.projectId;
        dragDropItem['id'] = item.projectId;
        this.dragdropService.pushItemToDragDropDicts(this.dragdropElements[0], dragDropItem);
      });
      this.getAssignedItems();
    })
    .catch(error => { console.error(error); });
  }

  setControlStyle(type: string, modifier: number = 0) {
    switch (type) {
      case 'dragpanel':
        if (this.applicationInfoService.isMobile()) {
          return {
            'width': '50%',
          };
        } else {
          return {
            'width': '50%',
        };
      }
    }
  }

  getAssignedItems() {
    this.externaldatasourceService.executeExternalDataSource(295, [this.campaignId])
    .then(result => {
      result.forEach(item => {
        let dragDropItem: Object = [];
        dragDropItem = item;
        dragDropItem['userName'] = this.projects.Item(item.csProjectId).projectName;
        dragDropItem['workID'] = item.id;
        dragDropItem['id'] = item.csProjectId;
        this.dragdropService.pushItemToDragDropDicts(this.dragdropElements[1], dragDropItem);
        this.removeItemFromList(this.dragdropElements[0], item);
      });
      this.removeAllAssignedItems();
    })
    .catch(error => { console.error(error); });
  }

  removeAllAssignedItems() {
    this.externaldatasourceService.executeExternalDataSource(318, [this.campaignId])
    .then(result => {
      result.forEach(item => {
        this.removeItemFromList(this.dragdropElements[0], item);
      });
    })
    .catch(error => { console.error(error); });
  }

  removeItemFromList(element, item) {
    // console.warn('removeItemFromList 1', element, item.projectId, item.csProjectId);
    element.itemDictInitial.Remove(item);
    element.itemsUI.forEach(uiItem => {
      if (uiItem.projectId === item.projectId || uiItem.sourceProjectId === item.csProjectId) {
        // console.warn('removeItemFromList 3', uiItem.projectId, item.projectId);
        this.commonService.removeItemFromArray(element.itemsUI, uiItem);
        this.commonService.removeItemFromArray(element.items, uiItem);
      }
    });
  }

  drop($event, value, dragdropElements) {
    this.dragdropService.drop($event, value, dragdropElements);
    if (value.toString() === '1') {
      this.addItem(value);
    } else {
      this.removeItem(value);
    }
  }

  addItem(value) {
    let counterPart = 0;
    if (value === 0) {
      counterPart = 1;
    }
    for (const item in this.dragdropElements[value].itemDictAdded.items) {
      if (item !== null) {
        const itemtoModify = this.dragdropElements[value].itemDictAdded.Item(item);
        this.externaldatasourceService.executeExternalDataSource(296, [
          this.campaignId,
          this.dragdropElements[value].itemDictAdded.Item(item).projectId
        ])
        .then(addResult => {
          this.addValueToItem(this.dragdropElements[value], item, addResult.id, 'workID');
          this.addValueToItem(this.dragdropElements[value], item, this.campaignId, 'campaignId');
          this.dragdropElements[counterPart].itemDictInitial.Remove(item);
          this.dragdropElements[counterPart].itemDictRemoved.Remove(item);

          this.dragdropElements[value].itemDictAdded.Remove(item);
          this.dragdropElements[value].itemDictInitial.Add(itemtoModify.projectId, itemtoModify);
        })
        .catch(error => { });
      }
    }
  }

  addValueToItem(element, itemToCheck, id, value) {
    element.items.forEach(item => {
      console.warn('addIdToItem', item, itemToCheck);
      if (Number(itemToCheck) === item.projectId) {
        item[value] = id;
      }
    });
  }

  removeItem(value) {
    let counterPart = 0;
    if (value.toString() === '0') {
      counterPart = 1;
    }
    for (const item in this.dragdropElements[counterPart].itemDictRemoved.items) {
      if (item !== null) {
        const itemtoModify = this.dragdropElements[counterPart].itemDictRemoved.Item(item);
        this.externaldatasourceService.executeExternalDataSource(297, [
          this.dragdropElements[counterPart].itemDictRemoved.Item(item).workID])

        .then(() => {
          this.dragdropElements[value].itemDictInitial.Remove(item);
          this.dragdropElements[value].itemDictAdded.Remove(item);

          this.dragdropElements[counterPart].itemDictRemoved.Remove(item);
          this.dragdropElements[counterPart].itemDictInitial.Add(itemtoModify.projectId, itemtoModify);
        })
        .catch(error => { });
      }
    }
  }
}
