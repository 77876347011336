import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { isNullOrUndefined } from 'util';
import { LoaderService } from 'app/shared/service/loader-service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ObjectDeserializeService } from 'app/jollyjupiter/pipe/object-deserialize.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';

@Component({
  selector: 'app-translate-token-popup',
  templateUrl: './translate-token-popup.component.html',
  styleUrls: ['./translate-token-popup.component.scss']
})
export class TranslateTokenPopupComponent implements OnInit {
  translateLanguages = [];
  translations = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private eventService: EventService,
    private objectDeserializeService: ObjectDeserializeService,
    private jjtranslationService: JjtranslationService
  ) { }

  ngOnInit() {
    this.loaderService.display(true, false, 'TranslateTokenPopupComponent init');
    this.getTranslationLanguages()
    .then(() => {
      if (!isNullOrUndefined(this.objectDeserializeService.transform(this.applicationInfoService.translationPopupCallingItem,
        this.applicationInfoService.translationPopupTarget + '.' + this.jjtranslationService.tempTranslationName, null))) {
          this.translations = this.objectDeserializeService.transform(this.applicationInfoService.translationPopupCallingItem,
            this.applicationInfoService.translationPopupTarget)[this.jjtranslationService.tempTranslationName];
        this.loaderService.display(false, false, 'TranslateTokenPopupComponent init');
      } else {
        if (!isNullOrUndefined(this.applicationInfoService.translationPopupToken)) {
          this.externaldatasourceService.executeExternalDataSource(116, [this.applicationInfoService.translationPopupToken.id])
          .then(translationTokenInfo => {
            translationTokenInfo.translations.forEach(translation => {
              this.translations[translation.language.id] = translation.translation;
            });
            this.loaderService.display(false, false, 'TranslateTokenPopupComponent init');
          })
          .catch(error => {
            this.loaderService.display(false, false, 'TranslateTokenPopupComponent init');
          });
        } else {
          this.loaderService.display(false, false, 'TranslateTokenPopupComponent init');
        }
      }
    })
    .catch(error => {
      this.loaderService.display(false, false, 'TranslateTokenPopupComponent init');
    });
  }

  getTranslationLanguages(): Promise<any> {
    return new Promise((getTranslationLanguagesResolve: (value?: any) => void, getTranslationLanguagesReject) => {
      this.externaldatasourceService.executeExternalDataSource(89)
      .then(languageResult => {
          this.translateLanguages = languageResult;
          getTranslationLanguagesResolve();
      })
      .catch(error => {
        getTranslationLanguagesReject(error);
      });
    });
  }

  confirmTranslation() {
    let callId = this.applicationInfoService.translationPopupCallingItem.id;
    let callType = 'original';

    if (isNullOrUndefined(this.applicationInfoService.translationPopupCallingItem.id)) {
      callId = this.applicationInfoService.translationPopupCallingItem.tempItemId;
      callType = 'temp';
    }
    this.eventService.translateTokenPopupConfirmTranslationEvent.emit({
      'callType': callType,
      'callId': callId,
      'callSource': this.applicationInfoService.translationPopupCallingSource,
      'callItem': this.applicationInfoService.translationPopupCallingItem,
      'target': this.applicationInfoService.translationPopupTarget,
      'translations': this.translations,
    });
    this.eventService.closeJjPopup(null);
  }
}
