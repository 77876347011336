import {  Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-usermailbox',
  templateUrl: './usermailbox.component.html',
  styleUrls: ['./usermailbox.component.scss'],
})
export class UsermailboxComponent implements OnInit {  
  selectedMessage = null;
  innerHTML = null;
  mode = 1;
  newMailContent = 'Test';
  subject = '';
  projects = null;
  messageProjectId = null;
  roles = null;
  messageRoleId = 1;
  users = null;
  validFrom = null;
  validUntil = null;
  receiverList = [];
  isImageSaved: boolean = false;
  cardImageBase64: string = '';
  htmlEditorObject = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private emailtemplateService: EmailtemplateService,
    private refreshValueService: RefreshValueService,
    private domSanitizer: DomSanitizer,
    private messagingService: MessagingService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    if (this.applicationInfoService.userMessages.length > 0) {
      this.selectMessage(this.applicationInfoService.userMessages[0])
    }
    this.getAvailableProjects();    
    this.getAssignedUsers();
  }

  selectMessage(userMessage) {
    this.renderContent(userMessage);
  }

  renderContent(userMessage) {        
    this.selectedMessage = JSON.parse(JSON.stringify(userMessage));    
    if (userMessage.userMessages[0].readDateAt == null) {
      const readDate = new Date(Date.now());
      this.externaldatasourceService.executeExternalDataSource(678, [
        this.commonService.getModifyArrayBody({
          id: userMessage.userMessages[0].id,
          readDateAt: readDate
        }, [])
      ]);
      userMessage.userMessages[0].readDateAt = readDate;
    }    
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.emailtemplateService.renderEMail(userMessage.content, []));    
    return;
  }

  getAssignedUsers() {
    this.messageProjectId = this.applicationInfoService.projectID;
    this.externaldatasourceService.executeExternalDataSource(679, [this.messageRoleId, this.messageProjectId])
    .then(getAssignedUsersResult => { this.users = getAssignedUsersResult; })
  }

  getAvailableProjects() {
    this.externaldatasourceService.executeExternalDataSource(2)
    .then(getAvailableProjectsResult => { this.projects = getAvailableProjectsResult; })
  }

  getRoles() {
    this.externaldatasourceService.executeExternalDataSource(184)
    .then(getRolesResult => { this.roles = getRolesResult; })
  }

  sendMessage() {
    if (this.htmlEditorObject == null) { return; }    
    if (this.validFrom == null) { this.validFrom = new Date(Date.now()); }
    if (this.validUntil == null) { this.validUntil = new Date(2099, 1, 1); }
    this.loaderService.display(true);
    const array =  {
      subject: this.subject,
      content: this.htmlEditorObject.renderedData,
      isActive: true,
      projectId: this.messageProjectId,
      validFrom: this.validFrom,
      validUntil: this.validUntil,
      fromUserId: this.applicationInfoService.userID,
      defaultName: "mailSend"
    }
    this.externaldatasourceService.executeExternalDataSource(676, [
      this.commonService.getModifyArrayBody(array, []),
      this.receiverList
    ]).then(() => {
      this.messagingService.showDefaultSuccess('', 'Nachricht wurde verschickt');
      this.loaderService.display(false);
    }).catch(()=> { this.loaderService.display(false); });
  }

  selectUser(user) {
    this.commonService.toggleArrayItem(this.receiverList, user.userId);
  }

  userIsSelected(user) {
    return this.commonService.checkIfItemIsInArray(this.receiverList, user.userId);
  }

  changeProject() {
    this.messageRoleId = null;
    this.receiverList = [];
    this.users = [];
  }

  getHTMLData(event) {
    this.htmlEditorObject = event;
    this.newMailContent = event.textData;
  }

  selectAllUsers() {
    console.warn(this.users, this.receiverList);
    this.users.forEach(user => {
      if (!this.commonService.checkIfItemIsInArray(this.receiverList, user.userId)) {
        this.commonService.toggleArrayItem(this.receiverList, user.userId);
      }
    });
  }
}
