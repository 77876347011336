<div *ngIf="question != null" class="everleadShadowBox" [ngClass]="{
    hideInDom: itemIsFiltered()
}">
    <div class="questionHeader">
        <div class="questionAnswerItemBox">
            <label class="questionAnswerAnswerLabel normalWhiteSpace">{{ 'QuestsionAnswers.Label.QuestionDefaultName' |
                texttransform }}</label>
            <input [(ngModel)]="question.defaultName" class="questionAnswerAnswerTextBox" (change)="saveQuestion()">
            <app-wizard-translations [array]="question" updateBaseArrayExternaldatasourceId=155 mode="3"></app-wizard-translations>

            <button (click)="removeQuestion(question);$event.stopPropagation()" class="nudeButton autoMarginLeft"
                [ngClass]="{ hideInDom: question.answers?.length > 0 }" style="white-space: normal;"><i
                    class="fas fa-trash-alt"></i></button>
        </div>

        <div style="width: 100%">
            <div class="questionAnswerItemBox">
                <input type="checkbox" [(ngModel)]="question.isMandatory" (change)="saveQuestion()">
                <label class="questionAnswerAnswerLabel normalWhiteSpace">{{ 'QuestsionAnswers.Label.IsMandatory' |
                    texttransform }}</label>
            </div>
            <div class="questionAnswerItemBox">
                <input type="checkbox" [(ngModel)]="question.isMultiselect" (change)="saveQuestion()">
                <label class="questionAnswerAnswerLabel normalWhiteSpace">{{ 'QuestsionAnswers.Label.IsMultiselect' |
                    texttransform }}</label>
            </div>
        </div>
    </div>

    <!-- Answers of this question -->
    <div>
        <label class="questionAnswerLabel ellipsisText">{{ 'QuestsionAnswers.Label.Answers' | texttransform }}</label>

        <div *ngFor="let answer of question?.answers" class="questionAnswerAnswerBox" [ngClass]="{
            hideInDom: answer.isDeleted == true
        }">
            <div class="questionAnswerItemBox">
                <label class="questionAnswerNoPaddingAndMargin questionAnswerAnswerLabel normalWhiteSpace">{{
                    'QuestsionAnswers.Label.AnswerDefaultName' | texttransform }}</label>
                <input [(ngModel)]="answer.defaultName" class="questionAnswerAnswerTextBox" (change)="saveAnswer(answer)">
                <div>
                    <app-wizard-translations [array]="answer" updateBaseArrayExternaldatasourceId=158 mode="3"></app-wizard-translations>
                </div>
                <i class="fas fa-trash-alt autoMarginLeft" (click)="removeItem(question, answer)"
                    [ngClass]="{ hideInDom: answer.childQuestions?.length > 0 }"></i>
            </div>

            <!-- <div class="questionAnswerItemBox">
                    <app-translation-button type='nameTranslationToken' source='answerTranslation'
                        [target]=answer></app-translation-button>
                    <app-translation-button type='descriptionTranslationToken' source='answerTranslation'
                        [target]=answer></app-translation-button>        
                </div> -->
            <div *ngIf="answer.id != null" style="width: 100%; margin-top: 5px;">
                <div class="questionAnswerSubquestionHeaderDiv" style="margin-bottom: 3px">
                    <button (click)="addSubQuestion(answer)" class="jjControlSubmenuButton" style="white-space: normal;"><i
                                class="fas fa-plus"></i></button>
                    <label class="questionAnswerSectionHeader">
                        {{ 'QuestsionAnswers.Label.Subquestions' | texttransform }}
                    </label>
                </div>
                <div *ngFor="let subquestion of answer.childQuestions">
                    <app-questionanswers-item [question]=subquestion [level]=getChildLevel() [parentId]=answer.id>
                    </app-questionanswers-item>
                </div>
            </div>
        </div>
        <div class="bottomBar fingerCursor" (click)="addAnswer()">
            <i class="fas fa-plus"></i>{{ 'QuestsionAnswers.Label.CreateNewAnswer' | texttransform }}
        </div>
    </div>
    <!-- </span> -->
</div>