<div *ngIf="isTimeRecordingProjectChange">
  <img src="assets/images/axivas.png" style="height: 130px" /><br>
  <br>
  {{ 'Message.Activity.SelectActivity' | texttransform }}<br>
  <br>
</div>
<div *ngIf="!isTimeRecordingProjectChange">
  <img src="assets/images/break/jj_break_schwetzingen.jpg"
    style="width:100%" /><br>
  <br>
  {{ 'Message.Break.BreakPopupLine1' | texttransform }}<br>
  <br>
  {{ 'Message.Break.BreakPopupLine2' | texttransform }}<br>
  <br>
</div>

<div *ngIf="!isLoadingData">
  <p-dropdown (onChange)="backToWork()" [options]="data" filter="true" resetFilterOnHide="true" showClear="true"
    [(ngModel)]="controlSelection" emptyFilterMessage="{{ 'Control.Dropdown.EmptyFilterMessage' | texttransform }}"
    placeholder="{{ 'Control.Dropdown.Placeholder' | texttransform }}" [style]="getDropdownWidth()">
    <ng-template let-item pTemplate="selectedItem">
      <span style="vertical-align:middle">{{item.label | texttransform }}</span>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <span style="vertical-align:middle">{{item.label | texttransform }}</span>
    </ng-template>
  </p-dropdown>
</div>

<div *ngIf="isLoadingData">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
    {{ 'Message.Break.LoadingData' | texttransform }}
</div>
