import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { CommonService } from './common.service';
import { RefreshValueService } from '../signalr/refresh-value.service';
import { MessagingService } from './messaging.service';
import { EventService } from './event.service';
import { Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebpushService {
  responseTimerSubscription: Subscription = new Subscription();
  public hubConnection: HubConnection | undefined;
  public clientConnectionId: string;
  public activities = [];
  currentlyReconnecting = false;
  userInfo = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private refreshValueService: RefreshValueService,
    private messagingService: MessagingService,
    private eventService: EventService
  ) { }

  public initConnection(force = false): Promise<any> {
    return new Promise((initConnectionResolve, initConnectionReject) => {
      if (this.hubConnection != undefined) {
        if (this.hubConnection.state == 1 && force == false) {
          console.warn('WebPush initConnection', " - already connected") 
          return;
        }
      }

      const i = Date.now();
      this.currentlyReconnecting = true;
      this.applicationInfoService.webPhoneConnected = false;
      let url = environment.webPushUrl;
      url = url + '/?l=' + this.commonService.encryptSignalRText(localStorage.getItem('awstoken'), i.toString()) + '&i=' + i ;
      this.hubConnection = new HubConnectionBuilder().withUrl(url).configureLogging(signalR.LogLevel.Error).build();
      this.hubConnection.start().then(startResult => {
        this.currentlyReconnecting = false;
        this.startPingTimer();
        this.addHubConnectionMethods();
        console.log('signalR messages', 'connected');
        initConnectionResolve(this.hubConnection);          
      })
      .catch(error => {
        this.currentlyReconnecting = false;
        console.error('WebPush HubConnection init error', error);
        initConnectionReject(error);
      });
    });
  }
  
  startPingTimer() {
    const responseTimer = timer(5000, 30000);
    this.responseTimerSubscription = responseTimer.subscribe(restime => {
      this.hubConnection.invoke('Ping', [this.applicationInfoService.userID])
      .then(result => {
        console.warn('webPush ping', result)
      });
    });
  }

  addHubConnectionMethods() {    
    this.hubConnection.on('OnCacheUpdate', (data: any) => {
      console.warn('OnCacheUpdate', data);
      if (data.projectId != this.applicationInfoService.projectID) { return; }
      console.warn('OnCacheUpdate project matches', data);

      switch(data.entityName) {
        case 'UiControl':
          this.refreshValueService.updateUiControl(data.entityId);
          break;
      }
    });

    this.hubConnection.on('OnWebPushMessage', (data: any) => {
      console.warn('OnWebPushMessage', data);
      this.refreshValueService.getUserMessages();
      // this.messagingService.showDefaultInfo('Nachricht', 
      // 'Sie haben eine neue Nachricht von ' + data.fromUser.firstName + ' ' + data.fromUser.lastName + ' in Ihrem Posteingang', 
      // true);
    });

    this.hubConnection.on('OnPostgraphileRestart', (data: any) => {
      if (data.additionalData == 'Restart') {
        this.applicationInfoService.postGrahphileRestart = true;
      } else {
        setTimeout(() => this.applicationInfoService.postGrahphileRestart = false, 15000);        
      }
    });
  }
}
