import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-createdemoaccount',
  templateUrl: './createdemoaccount.component.html',
  styleUrls: ['./createdemoaccount.component.scss']
})
export class CreatedemoaccountComponent implements OnInit {
  firstName = '';
  lastName = '';
  campaignId = 140;
  email = '';
  phone = '';
  languageId = '2';

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private sharedAPI: SharedAPI,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
  }

  emailValid() {
    let returnValue = true;
    if (!this.commonService.checkIfStringContainsString(this.email, '@')) {
      return false;
    } else {
      const emailDetails = this.email.split('@');
      if (!this.commonService.checkIfStringContainsString(emailDetails[1], '.')) {
        returnValue = false;
      }
    }
    if (!this.email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(this.email, 'alex-gross.com')) {
      returnValue = false;
    }
    return returnValue;
  }

  checkIfEMailAlreadyExists(): Promise<any> {
    return new Promise((checkIfEMailAlreadyExistsResolve, checkIfEMailAlreadyExistsReject) => {
      this.sharedAPI.checkDemoAccount(this.email).subscribe(() => {
        checkIfEMailAlreadyExistsResolve(null);
      }, (error) => { 
        checkIfEMailAlreadyExistsResolve(error);
       });  
    });
  }

  createDemoAccount() {
    const demoUser = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email.trim(),
      phone: this.phone.trim(),
      campaignId: this.campaignId,
      languageId: Number(this.languageId),
      projectId: this.applicationInfoService.projectID      
    }
    this.messagingService.showDefaultInfo('Toolbar.Label.CreateDemoUser', 'CreateDemoAccount.Label.CreateDemoUserStarted');
    this.sharedAPI.createDemoAccount(demoUser).subscribe(() => {
      this.messagingService.showDefaultSuccess('Toolbar.Label.CreateDemoUser', 'CreateDemoAccount.Label.DemoUserCreated');
    }, (error) => { 
      const errorMessage =  error._body.replace(/"/g, '');
      this.messagingService.showDefaultError('Toolbar.Label.CreateDemoUser', errorMessage);
     });
  }

  cleanEMail(email) {
    email = email.replace(/|/g, '');
    email = email.replace(/^/g, '');
    this.email = email;
  }
}
