<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor automaticRecordAssignmentHeaderDiv">
        <label style="padding-left: 3px;"
            class="normalWhiteSpace">{{ 'DesignTranslation.Label.ManageTranslations' | texttransform }}</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
        <button (click)="saveToken(true)" class="jjControlSubmenuButton" [disabled]="!checkIfTokenIsDirty()"
            [tippy]="'DesignTranslation.Tooltip.SaveAndNext' | texttransform" [tippyOptions]="{placement:'bottom'}" style="white-space: normal;"><i
                class="far fa-save"></i></button>
        <button (click)="saveToken()" class="jjControlSubmenuButton" [disabled]="!checkIfTokenIsDirty()"
            [tippy]="'DesignTranslation.Tooltip.Save' | texttransform" [tippyOptions]="{placement:'bottom'}" style="white-space: normal;"><i
                class="fas fa-save"></i></button>
        <button (click)="newToken()" class="jjControlSubmenuButton" [disabled]="checkIfTokenIsDirty()"
            [tippy]="'DesignTranslation.Tooltip.NewToken' | texttransform" [tippyOptions]="{placement:'bottom'}" style="white-space: normal;"><i
                class="fas fa-plus-square"></i></button>
        <button (click)="undoChanges()" class="jjControlSubmenuButton" [disabled]="!checkIfTokenIsDirty()"
            [tippy]="'DesignTranslation.Tooltip.UndoChanges' | texttransform" [tippyOptions]="{placement:'bottom'}" style="white-space: normal;"><i
                class="fas fa-undo"></i></button>

    </div>
</div>
<!-- <div [innerHTML]="translationViewInnerHTML"></div>
<br> -->
<div *ngIf="selectedTranslationToken != null">
    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.ID' | texttransform }}</label>
    <label class="designTranslationInput floatRight">{{ selectedTranslationToken.id }}</label>

    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.ExistingGroupItems' | texttransform }}</label>
    <select class="designTranslationDropdown floatRight" (change)="changeGroup(selectedGroup)" [(ngModel)]="selectedGroup">
        <option *ngFor="let groupName of groupNames">{{ groupName }}</option>
    </select>

    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.Group' | texttransform }}</label>
    <input type="text" class="designTranslationInput floatRight" [(ngModel)]="selectedTranslationToken.group">

    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.SubGroup' | texttransform }}</label>
    <input type="text" class="designTranslationInput floatRight" [(ngModel)]="selectedTranslationToken.subgroup">

    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.Field' | texttransform }}</label>
    <input type="text" class="designTranslationInput floatRight" [(ngModel)]="selectedTranslationToken.field">

    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.TokenName' | texttransform }}</label>
    <input id="designTranslationTokenNameLabel" type="text" class="designTranslationInput floatRight"
        [(ngModel)]="selectedTranslationToken.tokenName">
    
    <label class="designTranslationLabel">{{ 'DesignTranslation.Label.Project' | texttransform }}</label>
    <select class="designTranslationInput floatRight" [(ngModel)]="selectedTranslationToken.projectId">
        <option [ngValue]="null"></option>
        <option *ngFor="let project of projects" [ngValue]="project.id">
            {{ project.projectName }}
        </option>
    </select>
</div>
<br>
<div *ngIf="selectedTranslationToken != null">
    <label
        class="designTranslationLabel designTranslationLabelBold">{{ 'DesignTranslation.Label.Translations' | texttransform }}</label>
    <div *ngFor="let language of translationLanguages">
        <label class="designTranslationLabel">{{ language.languageName }}</label>
        <input type="text" class="designTranslationInput floatRight" [(ngModel)]="translationValues[language.id]">
    </div>
</div>
<br>

