import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Guid } from 'guid-typescript';
import { SessionStorageService } from 'ngx-webstorage';
import { timer, Subscription } from 'rxjs';
import { AuthService } from '../../core/authentication/services/auth.service';
import { environment } from 'environments/environment';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';

export enum PageType {
  MasterPage,
  SlavePage
}

@Injectable()
export class MasterPageService {
  appLocalGUID = 'JJLocalGUID';
  appPageType = 'JJPageType';
  appApplicationGUIDMasterTime = 'JJApplicationGUIDMasterTime';
  appApplicationGUIDMaster = 'JJApplicationGUIDMaster';
  appUserInformation = 'JJUserInformation';
  appUserInformationType = 'JJUserInformationType';

  SecondsToClaimMaster = 7;
  pageTickTimer = 3000;
  SecSlaveTicks = 3;
  firstCheck = true;
  renewDisabled = false;
  public applicationGUID: Guid;
  public timerSubscription = new Subscription();
  public pageType: PageType = PageType.MasterPage;
  externalActivityWindow: Window;

  constructor(
    private sessionStorage: SessionStorageService,
    private authService: AuthService,
    private awsCognitoService: AwsCognitoService
  ) {
    this.applicationGUID = Guid.create();
    if (this.sessionStorage.retrieve(this.appLocalGUID)) {
      this.applicationGUID = this.sessionStorage.retrieve(this.appLocalGUID);
    } else {
      this.sessionStorage.store(this.appLocalGUID, this.applicationGUID.toString());
    }
  }

  addMasterInfo() {
    this.sessionStorage.store(this.appPageType, 'master');
    localStorage.setItem(this.appApplicationGUIDMasterTime, Date.now().toString());
    localStorage.setItem(this.appApplicationGUIDMaster, this.applicationGUID.toString());
    localStorage.setItem(this.appUserInformation, localStorage.getItem('awstoken'));
    localStorage.setItem(this.appUserInformationType, 'bearer');
    this.sessionStorage.store(this.appApplicationGUIDMaster, this.applicationGUID.toString());
    this.pageType = PageType.MasterPage;
    this.renewDisabled = false;
    this.authService.master = true;
  }

  addSlaveInfo() {
    this.sessionStorage.store(this.appPageType, 'slave');
    this.pageType = PageType.SlavePage;
    if (!this.renewDisabled) {
      this.renewDisabled = true;
    }
    this.authService.master = false;
  }

  getPageType() {
    const trApplicationGUIDMaster = localStorage.getItem(this.appApplicationGUIDMaster);
    if (!trApplicationGUIDMaster) {
      this.addMasterInfo();
    } else {
      if (trApplicationGUIDMaster === this.applicationGUID.toString()) {
        this.addMasterInfo();
     } else { // checking if old master still alive
       const trApplicationGUIDMasterTime = localStorage.getItem(this.appApplicationGUIDMasterTime);
       const dateDiff = moment(Date.now()).diff(Number(trApplicationGUIDMasterTime), 'second');
       if (dateDiff > this.SecondsToClaimMaster) { // Master seems dead, im new master
         console.log('establishing me as new guidmaster...');
         this.addMasterInfo();
       } else {
         this.addSlaveInfo();
         this.firstCheck = false;
       }
     }
    }
  }

  checkPageType() {
    const authMasterTimer = timer(0, this.pageTickTimer);
    authMasterTimer.subscribe(time => {
      this.getPageType();
    });
  }
}
