import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-htmleditor',
  templateUrl: './htmleditor.component.html',
  styleUrls: ['./htmleditor.component.scss']
})
export class HtmleditorComponent implements OnInit, OnDestroy {
  addTextToHTMLTextSubscription: Subscription = new Subscription();
  addImageArrayToHTMLTextSubscription: Subscription = new Subscription();
  @Input() content = null;
  @Input() htmlText = '';
  @Output() valueStatusChange = new EventEmitter<any>();
  emailContents = null;
  innerHTML = null;  
  mode = 1;
  caretPos = 0;
  oField = null;
  @Input() imageArray = [];
  imageCounter = 0;
  imageData = null;
  isTemplate = false;
  smtpSetting = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    private eventService: EventService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.addTextToHTMLTextSubscription = this.eventService.addTextToHTMLText.subscribe(event => {
      this.addTextToHTML(event);
    });
    this.addImageArrayToHTMLTextSubscription = this.eventService.addImageArrayToHTMLText.subscribe(event => {
      this.addImageArrayToHTMLText(event);
    });

    this.getEMailContents();

    
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['smtpSetting'])) {
      this.smtpSetting = this.applicationInfoService.miscSettings['smtpSetting'];
    }
  }

  ngOnDestroy(): void {
    if (this.addImageArrayToHTMLTextSubscription) { this.addImageArrayToHTMLTextSubscription.unsubscribe()}
    if (this.addTextToHTMLTextSubscription) { this.addTextToHTMLTextSubscription.unsubscribe()}
  }

  getEMailContents() {
    this.emailtemplateService.getEMailContents().then(getEMailContentsResult => { this.emailContents = getEMailContentsResult; })
  }

  renderContent() {
    this.mode = 2;
    const contentToRender = this.getRenderedContent();
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.emailtemplateService.renderEMail(contentToRender, []));
  }

  getRenderedContent(renderPictures = true) {
    let contentToRender = this.htmlText;
    if (renderPictures) {
      contentToRender = this.replaceImagesInTemplate();
    }    
    if (!this.isTemplate) {
      contentToRender = contentToRender.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
    return contentToRender;
  }

  changeValueEmitter() {
    this.valueStatusChange.emit({ 
      textData: this.htmlText, 
      renderedData: this.getRenderedContent(),
      renderedDataWithPictureTags: this.getRenderedContent(false),
      imageArray: this.imageArray
    });
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
       this.caretPos = oField.selectionStart;
       this.oField = oField;
    }
  }

  addTextToHTML(textToInsert, position = this.caretPos) {
    if (this.commonService.isNullOrUndefined(this.htmlText)) {
      this.htmlText = '';
    }
    this.htmlText = ''.concat(
      this.htmlText.substring(0, position),
      textToInsert,
      this.htmlText.substring(position, this.htmlText.length),
      ' '
    );
    this.caretPos = position + textToInsert.length + 1;
    this.changeValueEmitter();
  }

  wrapTextInTag(tag) {
    if (this.oField == null) { return; }
    if (this.oField.selectionStart == this.oField.selectionEnd) { return; }

    const startTag = { text: '<' + tag + '>', position: this.oField.selectionStart };
    const endTag = { text: '</' + tag + '>', position: this.oField.selectionEnd };
    this.addTextToHTML(endTag.text, endTag.position);
    this.addTextToHTML(startTag.text, startTag.position);   
  }

  createBase64String(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const imgBase64Path = e.target.result;
          this.imageData = imgBase64Path;
          this.imageArray[this.imageCounter] = { 
            data: imgBase64Path, 
            index: this.imageCounter,
            filename: fileInput.target.files[0].name
          };
          this.addTextToHTML('<img src="cid:' + fileInput.target.files[0].name + '" style="width: auto; height: auto;" />');
          this.imageCounter ++;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  replaceImagesInTemplate() {
    if (this.commonService.isNullOrUndefined(this.imageArray)) {
      this.imageArray = [];
    }
    let returnValue = this.htmlText;
    this.imageArray.forEach(image => {
      returnValue = returnValue.replace('cid:' + image.filename, image.data);
    });
    return returnValue;
  }

  sendTestMail() {
    this.loaderService.display(true);
    let renderData = this.getRenderedContent(false);
    renderData = renderData.replace(/"/g, '\\"');
    console.warn(renderData);
    this.externaldatasourceService.executeExternalDataSource(658, [
      this.applicationInfoService.user.email,
      '',
      '',
      'Everlead Mailtest',
      renderData,
      'Everlead Mailtest',
      this.smtpSetting.id,
      null
    ]).then(sendEMailResult => {
      this.loaderService.display(false);
      this.messagingService.showDefaultSuccess('', 'EmailEditorSync.Label.MailSent');
    }).catch (() => { this.loaderService.display(false); });
  }

  addImageArrayToHTMLText(event) {
    if (event != null) {
      const array = JSON.parse(event);
      array.forEach(arrayItem => {
        this.imageArray.push(arrayItem);
      })
    }
    this.changeValueEmitter();
  }
}
