import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-customerapi',
  templateUrl: './customerapi.component.html',
  styleUrls: ['./customerapi.component.scss']
})
export class CustomerapiComponent implements OnInit {
  secretKeyCreated = false;
  secretKey = '';
  section = 1;
  apiUser = {
    firstName: '',
    lastName: '',
    email: '',
    projectId: 0
  }

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private messagingService: MessagingService,
    private sharedAPI: SharedAPI,
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
  }

  createSecretKey() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(657)
    .then(createSecretKeyResult => {
      this.secretKey = createSecretKeyResult;
      this.secretKeyCreated = true;
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  copyToClipBoard() {
    this.commonService.copyTextToClipboard(this.secretKey);
    this.messagingService.showDefaultSuccess(
      'General.CopyToClipBoard.Header',
      'General.CopyToClipBoard.Message',
      false
    );
  }

  createApiUser() {
    this.apiUser.projectId = this.applicationInfoService.projectID
    this.sharedAPI.createAPIAccount(this.apiUser).subscribe(() => {
      this.messagingService.showDefaultSuccess('API User', 'Der User wurde angelegt');
    });
  }

  checkApiUserReqs() {
    let returnValue = false;
    if (this.apiUser.firstName.length < 3) { returnValue = true; }
    if (this.apiUser.lastName.length < 3) { returnValue = true; }
    if (!this.commonService.checkIfStringContainsString(this.apiUser.email, '@')) { returnValue = true; }
    if (!this.commonService.checkIfStringContainsString(this.apiUser.email, '.')) { returnValue = true; }
    return returnValue;
  }
}
