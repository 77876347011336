<div *ngIf="newMode">
    <app-custom-timeline [element]="timelineResult" [newMode]=true></app-custom-timeline>
</div>













































<div *ngIf="!newMode">
<div>
    <div class="row">
        <div class="col-12 tableFilter border-bottom">
            <div [ngClass]="{ active: commonService.checkIfItemIsInArray(filterType, 'conversion') == true}"
                class="tableFilter__category" (click)="filterList($event,'category','conversion')">{{
                'ActivityList.Label.Conversions' | texttransform }} <span class="tableFilter__category__count">{{
                    externalDataSourceResult?.numRecordsConversion }}</span></div>
            <div [ngClass]="{ active: commonService.checkIfItemIsInArray(filterType, 'task') == true}"
                class="tableFilter__category" (click)="filterList($event,'category','task')">{{
                'ActivityList.Label.SalesActivities' | texttransform }}<span class="tableFilter__category__count">{{
                    externalDataSourceResult?.numRecordsTask }}</span></div>
            <div [ngClass]="{ active: commonService.checkIfItemIsInArray(filterType, 'event') == true}"
                class="tableFilter__category" (click)="filterList($event,'category','event')">{{
                'ActivityList.Label.WebActivities' | texttransform }} <span class="tableFilter__category__count">{{
                    externalDataSourceResult?.numRecordsEvent }}</span></div>
            <div [ngClass]="{ active: commonService.checkIfItemIsInArray(filterType, 'mailevent') == true}"
                class="tableFilter__category" (click)="filterList($event,'category','mailevent')">{{
                'ActivityList.Label.MailEvents' | texttransform }} <span class="tableFilter__category__count">{{
                    externalDataSourceResult?.numRecordsMailevent }}</span></div>
        </div>
    </div>


    <div class="row paginationWrapper">
        <div class="col-12">
            <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
            <mat-paginator [length]=resultsLength [pageSize]=pageSize [pageSizeOptions]="[5, 10, 20, 50, 100]"
                [pageIndex]=currentPage [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
            </mat-paginator>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="useTimeline" class="timeline">
                <ng-container *ngFor="let item of timelineResult">
                    <app-custom-timeline [element]="item" [filter]=null></app-custom-timeline>
                </ng-container>
            </div>
            <div class="row matTableWrapper__descRow" *ngIf="!applicationInfoService.isMobile()">
                <div class="col-md-6 matTableWrapper__descRow__left">
                    {{ 'ActivityList.Label.YourActions' | texttransform }}
                </div>
                <div class="col-md-6 matTableWrapper__descRow__right">
                    {{ 'ActivityList.Label.OtherActions' | texttransform }}
                </div>
            </div>
            <div class="matTableWrapper matTableWrapperActivityList tableMargin"
                *ngIf="loadingData == false && useTimeline == false">
                <table mat-table #table [dataSource]="dataSource" multiTemplateDataRows matSort
                    class="matTable activitylistTable">                   
                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="activitylistTable__details">
                            <div class="activitylistTable__details__icon">
                                <i class="fas circle-icon icon-event icon-event__{{element.eventType}}{{element.conversionAssetType}}
                                icon-event__{{element.maileventType}}"
                                    [ngClass]="element.type=='task' ? 'type_task' : ''"></i>
                            </div>
                            <div class="activitylistTable__details__meta">
                                <h5>{{element.taskStep  | texttransform }}</h5>
                                <div class="date">{{element.date | date: 'medium' }}</div>
                                <div class="icon-text"><i class="fas fa-user"></i>
                                    <span class="label">{{ 'ActivityList.Label.Contact' | texttransform }}:</span>
                                    <span class="value">{{element.contact?.firstName}}
                                        {{element.contact?.lastName}}</span>
                                </div>

                            </div>

                            <div class="activitylistTable__details__notes"
                                [ngClass]="{ 'activitylistTable__notes__edit' : canEditNotes(element) == true }">
                                <div (click)="editActivity(element);$event.stopPropagation()" class="label">
                                    <i class="fas " [ngClass]="{ 
                                'fa-sticky-note' : canEditNotes(element) == false, 
                                'fa-edit' : canEditNotes(element) == true
                            }"></i><label class="label">Notes</label>
                                </div>


                                <div class="text" [matTooltip]="element.taskNotes" matTooltipPosition='above'
                                    matTooltipClass="toolTipClass"
                                    (click)="copyToClipBoard(element.taskNotes);$event.stopPropagation()">
                                    <b>{{element.taskSubject | slice:0:250}}</b><br *ngIf="element.taskSubject != null">
                                    {{element.taskNotes | slice:0:250}}
                                </div>
                            </div>
                            <div class="activitylistTable__details__status">
                                <div class="status-badge" *ngIf="element.taskResult" [ngClass]="{ 
                                positive: element.taskLeadPositivityValue  == 1,
                                neutral: element.taskLeadPositivityValue  == 0.5,
                                negative: element.taskLeadPositivityValue  == 0
                            }">
                                    {{element.taskResult}}
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell [attr.colspan]="displayedColumns.length + 1"
                            *matCellDef="let element; let i = index" class="expandedDetail">
                            <div class="selectedRow element-detail" id="element-detail-{{element.id}}"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="selectedRow__item icon-text">
                                    <i class="fas fa-user"></i><span class="label">{{ 'ActivityList.Label.User' |
                                        axivastranslate }}:</span>
                                    <span class="value">{{element.taskResultUser?.firstName }}
                                        {{element.taskResultUser?.lastName }}</span>
                                </div>
                                <div class="selectedRow__item icon-text"
                                    *ngIf="element.taskIsFollowUpAppointment != false">
                                    <i class="fas fa-calendar"></i><span class="label">{{
                                        'ActivityList.Label.PersonalResubmission' | texttransform }}</span>
                                </div>
                                <div class="selectedRow__item icon-text">
                                    <i class="fas fa-cogs"></i><span class="label">{{ 'ActivityList.Label.Process' |
                                        axivastranslate }}:</span>
                                    <span class="value">{{ element.taskWorkflow }}</span>
                                </div>
                                <div class="selectedRow__item icon-text">
                                    <i class="fas fa-bullhorn"></i><span class="label">{{ 'ActivityList.Label.Campaign'
                                        | texttransform }}:</span>
                                    <span class="value">{{element.taskCampaign}}</span>
                                </div>

                                <div class="selectedRow__item icon-text">
                                    <i class="fas fa-comment-dots"></i><span class="label">{{
                                        'ActivityList.Label.Reason' | texttransform }}:</span>
                                    <span class="value">{{element.taskAnswer}} <span
                                            *ngIf="element.taskAnswerText != null">-
                                            {{element.taskAnswerText}}</span></span>
                                </div>
                            </div>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="conversion">
                        <th mat-header-cell *matHeaderCellDef></th>

                        <td mat-cell [attr.colspan]="displayedColumns.length + 1" *matCellDef="let element"
                            class="activitylistTable__conversion">
                            <div class="grid-wrapper">
                                <div class="standard-container">
                                    <h5>{{element.conversionAssetType | texttransform }}</h5>
                                    <div class="date">{{element.date | date: 'medium'}}</div>
                                    <div><a href="{{element.conversionUrl}}" target="_blank"
                                            [tippy]="element.conversionUrl">{{element.conversionUrl | slice:0:150}}</a>
                                    </div>
                                    <div><i class="fas fa-file-alt"
                                            [tippy]="element.conversionAssetName"></i>{{element.conversionAssetName |
                                        slice:0:150}} <i class="fas fa-edit" (click)="editConversion(element)"></i></div>
                                </div>

                                <div class="activitylistTable__conversion__icon">
                                    <i class="fas circle-icon icon-event icon-event__{{element.eventType}}{{element.conversionAssetType}}
                                    icon-event__{{element.maileventType}}"
                                        [ngClass]="element.type=='task' ? 'type_task' : ''"></i>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="event">
                        <th mat-header-cell *matHeaderCellDef></th>

                        <td mat-cell [attr.colspan]="displayedColumns.length + 1" *matCellDef="let element"
                            class="activitylistTable__event">
                            <div class="grid-wrapper">
                                <div class="standard-container">
                                    <h5>{{element.eventType}}</h5>
                                    <div class="date">{{element.date | date: 'medium'}}</div>
                                    <div><a href="{{element.eventUrl}}" target="_blank"
                                            [tippy]="element.eventUrl">{{element.eventUrl | slice:0:150}}</a></div>
                                    <div [tippy]="element.conversionAssetName">{{element.conversionAssetName |
                                        slice:0:150}}</div>
                                </div>
                                <div class="activitylistTable__event__icon">
                                    <i class="fas circle-icon icon-event icon-event__{{element.eventType}}{{element.conversionAssetType}}
                                    icon-event__{{element.maileventType}}"
                                        [ngClass]="element.type=='task' ? 'type_task' : ''"></i>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="mailevent">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell [attr.colspan]="displayedColumns.length + 1" *matCellDef="let element"
                            class="activitylistTable__mailevent">
                            <div class="grid-wrapper">
                                <div class="standard-container">
                                    <h5>{{getEventTranslation(element.maileventType)}}</h5>
                                    <div><a href="{{element.maileventUrl}}" target="_blank"
                                            [tippy]="element.maileventUrl">{{element.maileventUrl | slice:0:150}}</a>
                                    </div>
                                    <div>{{element.maileventTemplateName}}</div>
                                    <div class="date">{{element.date | date: 'medium'}}</div>
                                </div>
                                <div class="activitylistTable__event__icon">
                                    <i class="fas circle-icon icon-event icon-event__{{element.eventType}}{{element.conversionAssetType}}
                                    icon-event__{{element.maileventType}}"
                                        [ngClass]="element.type=='task' ? 'type_task' : ''"></i>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr [ngClass]="row.type=='task' ? '' : 'hidden'" mat-row
                        *matRowDef="let row; columns: displayedColumns;" class="element-row"
                        [class.expanded-row]="expandedElement === row"
                        (click)="expandedElement=toggleDetails(expandedElement,row)"></tr>
                    <tr [ngClass]="row.type=='task' ? '' : 'hidden'" mat-row
                        *matRowDef="let row; columns: ['expandedDetail'];" class="detail-row"></tr>
                    <tr mat-row *matRowDef="let row; columns: [ 'event'];" class="standard-row"
                        [ngClass]="row.type=='event' ? '' : 'hidden'"></tr>
                    <tr mat-row *matRowDef="let row; columns: [ 'conversion'];" class="standard-row"
                        [ngClass]="row.type=='conversion' ? '' : 'hidden'"></tr>
                    <tr mat-row *matRowDef="let row; columns: [ 'mailevent'];" class="standard-row"
                        [ngClass]="row.type=='mailevent' ? '' : 'hidden'"></tr>

                </table>
                <ng-container *ngIf="loadingData == true">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
                </ng-container>
            </div>
        </div>
    </div>
</div>
</div>