<button (click)="createExternalDataSource()" class="jjButtonStyle jjButtonColor">Neue externalDataSource</button>
<input placeholder="filter" [(ngModel)]="filterString" class="floatRight" style="margin-right:10px;">
<br>
<br>
<br>

<div class="container-fluid externalDataSourceDesign__containerDiv">
    <div *ngFor="let externalDataSource of externalDataSources"
        class="container-fluid externalDataSourceDesign__wrapperDiv" [ngClass]="{
    hideInDom: itemIsFiltered(externalDataSource)
}">
        <div class="row" style="width: 100%; background-color: whitesmoke;">
            <div class="col-1">
                <i class="fas fa-play-circle" style="color: red;"
                    (click)="testExternalDataSource(externalDataSource)"></i>
                <b> Id</b>
            </div>
            <div class="col-1"><b>Source</b></div>
            <div class="col-2"><b>Identifier</b></div>
            <div class="col-2"><b>Type</b></div>
            <div class="col-2"><b>DataKey</b></div>
            <div class="col-2"><b>DataValue</b></div>
            <div class="col-2"><b>Schema</b></div>
        </div>
        <div class="row" style=" width: 100%">
            <div class="col-1">
                {{ externalDataSource.id }}
            </div>
            <div class="col-1">
                <select [(ngModel)]="externalDataSource.source" (change)="saveExternalDataSource(externalDataSource)">
                    <option [ngValue]=null></option>
                    <option [ngValue]='data'>Data</option>
                    <option [ngValue]='main'>Main</option>
                </select>
            </div>
            <div class="col-2">
                <input [(ngModel)]="externalDataSource.identifier"
                    (change)="saveExternalDataSource(externalDataSource)">
            </div>
            <div class="col-2">
                <select [(ngModel)]="externalDataSource.type" (change)="saveExternalDataSource(externalDataSource)">
                    <option [ngValue]=null></option>
                    <option [ngValue]='1'>HTTP</option>
                    <option [ngValue]='2'>GraphQL</option>
                    <option [ngValue]='3'>Internal</option>
                </select>
            </div>
            <div class="col-2">
                <input [(ngModel)]="externalDataSource.dataKey" (change)="saveExternalDataSource(externalDataSource)">
            </div>
            <div class="col-2">
                <input [(ngModel)]="externalDataSource.dataValue" (change)="saveExternalDataSource(externalDataSource)">
            </div>
            <div class="col-2">
                <input [(ngModel)]="externalDataSource.schema" (change)="saveExternalDataSource(externalDataSource)">
            </div>
        </div>
        <div class="row" style=" width: 100%">
            <div class="col-12">
                <b>Query</b>
            </div>
            <div class="col-12">
                <textarea [(ngModel)]="externalDataSource.dataQuery"
                    (change)="saveExternalDataSource(externalDataSource)" style="height: 90px; width:100%"></textarea>
            </div>
        </div>
    </div>
</div>
<br>
<div>
    Parameter <input [(ngModel)]="param"> <hr>
    <b>{{ queryTest }}</b><br>
    <br>
    {{ queryResult | json }}
</div>