import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { LoggerService } from './logger.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    // constructor(@Inject(NotificationsService) private notificationService: NotificationsService
    // , private logger: LoggerService) {
    // }

    constructor(@Inject(LoggerService)  private logger: LoggerService) {
    }
    handleError(error: any): void {
      this.logger.Debug(error);
    }

    private showErrorInConsole(error: any): void {
        if (console && console.group && console.error) {
            console.group('Error Log');
            console.error(error);
            // console.error(error.message);
            // console.error(error.stack);
            console.groupEnd();
        }
    }
}
