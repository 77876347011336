
<div *ngIf="lookupTable?.lookupTableId != null" class="lookupTable__wrapper">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem">
            <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                (click)="createNewLookupTableEntry()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithWizard' | texttransform"></svg-icon>               
            {{ 'UiControlDesign.Label.CreateNewEntry' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction"></div>
    </div>
    <div class="lookupTable__info">
        Bestehende Elemente
    </div>
    <div class="uiDesignLookupTableList" class="lookupTable__content">
        <div *ngFor="let lookupTableEntry of lookupTableEntries" class="nonExpertView__main nonExpertView__main__noSubitems">
            <div class="nonExpertView__bonus" [tippy]="'CampaignDesign.Label.IsActive' | texttransform">
                <input [(ngModel)]="lookupTableEntry.isActive" type="checkbox" (change)="updateLookupTableEntry(lookupTableEntry)">
            </div>
            <div class="nonExpertView__name">            
                <input [(ngModel)]="lookupTableEntry.defaultName" (change)="updateLookupTableEntry(lookupTableEntry)">
                <app-wizard-translations *ngIf="updateExternalDataSourceId != null" [array]="lookupTableEntry" [updateBaseArrayExternaldatasourceId]=updateExternalDataSourceId mode="3"></app-wizard-translations>    
            </div>
        </div>
    </div>
</div>
