<div class="projectTarget everleadShadowBox">
    <div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ProjectName' | texttransform
                }}</label>
            <input [(ngModel)]="data.projectName" (change)="saveSettings()" class="maxWidth">
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountOfNetcallsPerHour' | texttransform
                }}</label>
            <input [(ngModel)]="data.countOfNetcallsPerHour" type="number" (change)="saveSettings()"
                class="maxWidth">
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.NetCallTarget' | texttransform
                }}</label>
            <input [(ngModel)]="data.netCallTarget" type="number" (change)="saveSettings()"
                class="maxWidth">
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.LeadQuota' | texttransform }}</label>
            <input [(ngModel)]="data.leadQuota" type="number" (change)="saveSettings()" class="maxWidth">
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountFteOperational' | texttransform }}</label>
            <input [(ngModel)]="data.countFteOperational" type="text" (change)="saveSettings()" class="maxWidth">
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.CountProjectHours' | texttransform }}</label>
            <input [(ngModel)]="data.countProjectHours" type="text" (change)="saveSettings()" class="maxWidth">
        </div>

        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.TargetStart' | texttransform }}</label>
            <input [(ngModel)]=data.targetStart [owlDateTimeTrigger]="dt9" [owlDateTime]="dt9" readonly
                class="maxWidth" appendTo="body" aria-modal="true" (dateTimeChange)="saveSettings()">
            <owl-date-time #dt9></owl-date-time>
        </div>

        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.TargetEnd' | texttransform }}</label>
            <input [(ngModel)]=data.targetEnd [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" readonly
                class="maxWidth" appendTo="body" aria-modal="true" (dateTimeChange)="saveSettings()">            
            <owl-date-time #dt10></owl-date-time>
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.ReportTypeLookup' | texttransform
                }}</label>
            <select [(ngModel)]="data.reportTypeLookupId" (change)="saveSettings()" class="maxWidth">
                <option [ngValue]=null></option>
                <option *ngFor="let reportTypeLookup of reportTypeLookups" [ngValue]="reportTypeLookup.id">
                    {{ reportTypeLookup.defaultName }}
                </option>
            </select>
        </div>
        <div class="projectTargetItem">
            <label class="noPadding wizardLabel">{{ 'GeneralSettings.Setting.Comments' | texttransform
                }}</label>
            <textarea [(ngModel)]="data.comments" class="targetTextArea" (change)="saveSettings()"></textarea>
        </div>
        <button class="everleadCustomButton" (click)="deleteTarget(data)">
            {{ 'GeneralSettings.Setting.DeleteTarget' | texttransform }}
        </button>
    </div>
</div>
