<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <div *ngIf="wizardService.wizardPage == 1">
            <label class="wizardSubheader__headerLabel">{{ 'Leadreport.Label.Information' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'LeadReportSettings.PageInfo.Page1' | texttransform
                }}</label>
        </div>
        <div *ngIf="wizardService.wizardPage == 2">
            <label class="wizardSubheader__headerLabel">{{ 'Leadreport.Label.Upload' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'LeadReportSettings.PageInfo.Page2' | texttransform
                }}</label>
        </div>
    </div>

    <div *ngIf="wizardService.wizardPage == 1" class="maxHeight">
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'LeadReportSettings.Label.LeadreportLevel' | texttransform }}
                <app-wizard-infobutton displayText="LeadReportSettings.Info.Layer"></app-wizard-infobutton>
            </label>
            <select [(ngModel)]="wizardService.wizardArray.leadreportLevel" class="wizardInput">
                <option [ngValue]=null></option>
                <option [ngValue]="level.id" *ngFor="let level of levels">{{ level.defaultName }}</option>
            </select>
        </div>

        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'LeadReportSettings.Label.Language' | texttransform }}
                <app-wizard-infobutton displayText="LeadReportSettings.Info.Language"></app-wizard-infobutton>
            </label>
            <select [(ngModel)]="wizardService.wizardArray.leadreportLanguageId" class="wizardInput">
                <option [ngValue]="language.id" *ngFor="let language of languages">{{ language.languageName }}</option>
            </select>
        </div>

        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'LeadReportSettings.Label.LeadreportTemplateDefinitionId' | texttransform }}
                <app-wizard-infobutton displayText="LeadReportSettings.Info.TemplateDefinitionId"></app-wizard-infobutton>
            </label>
            <select [(ngModel)]="wizardService.wizardArray.leadreportTemplateDefinitionId" class="wizardInput">
                <option [ngValue]=null></option>
                <option [ngValue]="templateDefinition.id" *ngFor="let templateDefinition of templateDefinitions">{{ templateDefinition.defaultName }}</option>
            </select>
        </div>

        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'LeadReportSettings.Label.FileType' | texttransform }}                
            </label>
            <select [(ngModel)]="wizardService.wizardArray.leadreportFiletype" class="wizardInput">
                <option [ngValue]=null></option>
                <option ngValue="docx">{{ 'Leadreport.Label.FileTypeWord' | texttransform }}</option>
                <option ngValue="pdf">{{ 'Leadreport.Label.FileTypePdf' | texttransform }}</option>
            </select>
        </div>

        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'LeadReportSettings.Label.OnlyNetResults' | texttransform }}
                <app-wizard-infobutton displayText="LeadReportSettings.Info.OnlyNetResults"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.leadreportOnlyNetResults" type="checkbox">
        </div>
    </div>

    <div *ngIf="wizardService.wizardPage == 2" class="maxHeight">
        <div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'LeadReportSettings.Label.LeadreportBoxPath' | texttransform }}               
                </label>
                <input [(ngModel)]="wizardService.wizardArray.leadreportBoxPath" type="text" class="wizardInput" (change)="checkLeadreportPath(wizardService.wizardArray.leadreportBoxPath, true)">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'LeadReportSettings.Label.LeadreportBoxPathEnabled' | texttransform }}               
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isAutomaticLeadreportUploadEnabled" type="checkbox" class="wizardInput">
            </div>
        </div>
    </div>
</div>

