import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-ses',
  templateUrl: './ses.component.html',
  styleUrls: ['./ses.component.scss']
})
export class SesComponent implements OnInit {
  sesList = [];
  newSesName = '';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.getSesList();    
  }

  getSesList() {
    this.externaldatasourceService.executeExternalDataSource(532)
    .then(getSesListResult => {
      this.sesList = getSesListResult;
    });
  }

  createSesEntry(isBatch = false) {
    let externalDataSourceId = 533;
    if (isBatch) { externalDataSourceId = 875; }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [this.newSesName])
    .then(() => {
      this.getSesList();
    });
  }
}
