<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="createAttributeModifier()" class="fingerCursor" [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
        {{ 'Designer.Label.NewAttributeModifier' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction"></div>
</div>

<div *ngFor="let modifier of currentControlToModify.attributeModifiers" class="everleadShadowBox">
    <div class="attributeModifierLine__header">
        <div>
            {{ modifier.id }}
        </div>
        <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteAttributeModifier(modifier)"></i>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.Attribute' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <select [(ngModel)]="modifier.attribute" (change)="updateAttributeModifier(modifier)">
                <option ngValue="enabled">Enabled</option>
                <option ngValue="visible">Visible</option>
            </select>
        </div>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.HowToCheck' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <select [(ngModel)]="modifier.compareValueType" (change)="updateAttributeModifier(modifier)">
                <option [ngValue]=toNumber(1)> {{ 'Designer.AttributeModifier.HowToCheck1' | texttransform }}</option>
                <option [ngValue]=toNumber(2)> {{ 'Designer.AttributeModifier.HowToCheck2' | texttransform }}</option>
                <option [ngValue]=toNumber(3) *ngIf="applicationInfoService.isDeveloper"> {{ 'Designer.AttributeModifier.HowToCheck3' | texttransform }}</option>
                <option [ngValue]=toNumber(4) *ngIf="applicationInfoService.isDeveloper"> {{ 'Designer.AttributeModifier.HowToCheck4' | texttransform }}</option>
                <option [ngValue]=toNumber(5)> {{ 'Designer.AttributeModifier.HowToCheck5' | texttransform }}</option>
                <option [ngValue]=toNumber(6)> {{ 'Designer.AttributeModifier.HowToCheck6' | texttransform }}</option>
                <option [ngValue]=toNumber(7)> {{ 'Designer.AttributeModifier.HowToCheck7' | texttransform }}</option>
            </select>
        </div>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.CompareMode' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <select [(ngModel)]="modifier.compareType" (change)="updateAttributeModifier(modifier)">
                <option ngValue='='>Gleich</option>
                <option ngValue='!='>Ungleich</option>
                <option ngValue='LIKE'>Ähnlich</option>
                <option ngValue='STARTSWITH'>Beginnt mit</option>         
            </select>
        </div>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.ValueToCheck' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <input [(ngModel)]="modifier.compareValue" (change)="updateAttributeModifier(modifier)">
        </div>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.RefValue' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <input [(ngModel)]="modifier.refValue" (change)="updateAttributeModifier(modifier)">
            <select *ngIf="modifier.compareValueType == 6" [(ngModel)]="modifier.refValue" (change)="updateAttributeModifier(modifier)">
                <option *ngFor="let pool of applicationInfoService.pools.toArray()" [ngValue]="pool.id.toString()">{{ pool.defaultName }}</option>
            </select>
        </div>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.ValueToInsert' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <input [(ngModel)]="modifier.newValue" (change)="updateAttributeModifier(modifier)">
        </div>
    </div>
    <div class="attributeModifierLine">
        <div class="attributeModifierLine__label">
            {{ 'Designer.AttributeModifier.Order' | texttransform }}
        </div>
        <div class="attributeModifierLine__value">
            <input [(ngModel)]="modifier.order" (change)="updateAttributeModifier(modifier)">
        </div>
    </div>


    <div *ngIf="modifier.compareValueType == 1" class="everleadCoral">
        {{ 'Designer.AttributeModifier.ValueToCheck' | texttransform }} muss so eingetragen werden:<br>
        <br>
        Entität;Membername
    </div>
</div>

<!-- <div *ngFor="let modifier of currentControlToModify.attributeModifiers" class="row" style ="width: 100%;">
    <div class="col-1">
        <i class="fas fa-trash" style="color: red; margin-right: 5px;" (click)="deleteAttributeModifier(modifier)"></i>
        {{ modifier.id }}
    </div>
    <div class="col-2">
        <select [(ngModel)]="modifier.attribute" (change)="updateAttributeModifier(modifier)">
            <option ngValue="enabled">Enabled</option>
            <option ngValue="visible">Visible</option>
        </select>
    </div>
    <div class="col-1">
        <select [(ngModel)]="modifier.compareType" (change)="updateAttributeModifier(modifier)" class="modifierInput">
            <option ngValue='='>Gleich</option>
                        <option ngValue='!='>Ungleich</option>
                        <option ngValue='LIKE'>Ähnlich</option>
                        <option ngValue='STARTSWITH'>Beginnt mit</option>         
        </select>
    </div>
    <div class="col-1">
        <input [(ngModel)]="modifier.compareValue" class="modifierInput" (change)="updateAttributeModifier(modifier)">
    </div>
    <div class="col-1">
        <select [(ngModel)]="modifier.compareValueType" (change)="updateAttributeModifier(modifier)" class="modifierInput">
            <option [ngValue]=toNumber(1)>1</option>
            <option [ngValue]=toNumber(2)>2</option>
            <option [ngValue]=toNumber(3)>3</option>
            <option [ngValue]=toNumber(4)>4</option>
            <option [ngValue]=toNumber(5)>5</option>
        </select>
    </div>
    <div class="col-2">
        <input [(ngModel)]="modifier.refValue" class="modifierInput" (change)="updateAttributeModifier(modifier)">        
    </div>
    <div class="col-2">
        <input [(ngModel)]="modifier.newValue" class="modifierInput" (change)="updateAttributeModifier(modifier)">        
    </div>
    <div class="col-1">
        <input [(ngModel)]="modifier.order" class="modifierInput" (change)="updateAttributeModifier(modifier)">        
    </div>
</div> -->