<div class="row">
    &nbsp;
</div>
<footer class="footer fixed-bottom">
  <div class="row">
      &nbsp;
  </div>
  <div class="row">
      <div class="col-auto">
          <div class="col-auto">
              <ul class="list-unstyled">
                  <li>Axivas GmbH</li>
                  <li>Carl-Benz-Straße 9-11</li>
                  <li>68723 Schwetzingen</li>
              </ul>
          </div>
          <div class="col-auto">
              <ul class="list-unstyled">
                  <li><a href="mailto:info@axivas.com">info&#64;axivas.com</a></li>
                  <li>+49 6202 927-0</li>
              </ul>
          </div>
      </div>
      <div class="col-auto">
          <div class="col-auto">
              <ul class="list-unstyled">
                  <li><a href="http://axivas.com/imprint/" target="_blank">LEGAL INFORMATION</a></li>
              </ul>
          </div>
          <div class="col-auto">
              <ul class="list-unstyled">
                  <li><a href="http://axivas.com/privacy/" target="_blank">PRIVACY</a></li>
              </ul>
          </div>
      </div>
  </div>
</footer>
