<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <div *ngIf="wizardService.wizardPage == 1">
            <label class="wizardSubheader__headerLabel">{{ 'LeadstateDesign.Label.General' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'LeadStateDesign.PageInfo.Page1' | texttransform
                }}</label>
        </div>
        <div *ngIf="wizardService.wizardPage == 2">
            <label class="wizardSubheader__headerLabel">{{ 'LeadstateDesign.Label.Upload' | texttransform
                }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'LeadStateDesign.PageInfo.Page2' | texttransform
                }}</label>
        </div>        
    </div>

    <div class="row">
        <div class="col-md-12">
            <div *ngIf="wizardService.wizardPage == 1">
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.DefaultName' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.DefaultName"></app-wizard-infobutton>                            
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.lookupTable.defaultName" type="text"
                        class="wizardInput">
                </div>
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.IsActive' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.IsActive"></app-wizard-infobutton>    
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.lookupTable.isActive" type="checkbox"
                        class="wizardCheckBox">
                </div>
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.IsNet' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.IsNet"></app-wizard-infobutton>    
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.isNet" type="checkbox" class="wizardCheckBox">
                </div>
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.Type' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.Type"></app-wizard-infobutton>    
                    </label>
                    <select [(ngModel)]="wizardService.wizardArray.statusLookupId" class="wizardInput">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let leadStateType of leadStateTypes" [ngValue]="leadStateType.id">{{
                            leadStateType.defaultName }}
                        </option>
                    </select>
                </div>

                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.IsLead' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.IsLead"></app-wizard-infobutton>                            
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.isLead" type="checkbox" class="wizardCheckBox">
                </div>
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.IsStatistic' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.IsStatistic"></app-wizard-infobutton>                            
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.isStatistic" type="checkbox" class="wizardCheckBox">
                </div>
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.IsLeadquote' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.IsLeadquote"></app-wizard-infobutton>    
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.isLeadquote" type="checkbox" class="wizardCheckBox">
                </div>
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.ValidXDays' | texttransform }}
                        <app-wizard-infobutton displayText="Leadstate.Info.ValidXDaysDesc"></app-wizard-infobutton>    
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.validXDays" type="number" class="wizardCheckBox">
                </div>
            </div>

            <div *ngIf="wizardService.wizardPage == 2">
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.IsRequiredLeadreport' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.IsRequiredLeadreport"></app-wizard-infobutton>    
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.isRequiredLeadreport" type="checkbox"
                        class="wizardCheckBox">
                </div>
            </div>
            <div *ngIf="wizardService.wizardPage == 2">
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'Leadstate.Label.RequiredLeadreportStart' | texttransform }}
                        <app-wizard-infobutton displayText="LeadReportSettings.Info.RequiredLeadreportStart"></app-wizard-infobutton>    
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.requiredLeadreportStart" type="text"
                        class="wizardInput" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly>
                    <owl-date-time #dt7></owl-date-time>
                </div>
            </div>
        </div>
    </div>
</div>