<ng-container *ngIf="eventData.length > 0">
    <div class="eventInfoHeader">{{ 'ConversionEditor.Label.DataArray' | texttransform }}</div>
    <div *ngFor="let eventDataItem of eventData" class="eventDataItem">
        <div class="eventDataItem__name">
            {{ eventDataItem.name | texttransform }}
        </div>
        <div class="eventDataItem__value" [innerHTML]=eventDataItem.value>
        </div>
    </div>
</ng-container>
