<div *ngIf="userService.hasPermission('CanRefreshCache')" class="maxHeight">
    <div class="refreshCacheWrapper">
        <div class="refresCacheDescription">
            {{ 'CacheReset.Label.Description' | texttransform }}
        </div>
        <div class="refresCacheContent">
            <div class="refresCacheLine everleadShadowBox2">
                <div class="refresCacheLine__label">{{ 'CacheReset.Label.Translation' | texttransform }}</div>
                <div class="refresCacheLine__button">
                    <button class="everleadCustomButton" (click)="refreshTranslation()">
                        {{ 'General.Label.ClearCache' | texttransform }}
                    </button>
                </div>
            </div>    
        </div>
    </div>
</div>