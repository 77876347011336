// https://stackoverflow.com/questions/37591076/how-to-convert-seconds-to-time-string-in-angular2

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToTime'
})
export class DecimalToTimePipe implements PipeTransform {
  times = {
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  };

  transform(secondsToConvert: number, args: any[]) {

    let timePräfix: string;
    let hours: number;
    let minutes: number;
    let seconds: number;

    timePräfix = '';
    hours = 0;
    minutes = 0;
    seconds = 0;
    secondsToConvert = secondsToConvert * 3600;

    if ( secondsToConvert < 0) {
      secondsToConvert = secondsToConvert * -1;
      timePräfix = '-';
    }

    for (const key in this.times) {
        if (Math.floor(secondsToConvert / this.times[key]) > 0) {

            if (key === 'day' ) {
              hours += Math.floor(secondsToConvert / this.times[key]) * 24;
            } else if (key === 'hour' ) {
              hours += Math.floor(secondsToConvert / this.times[key]);
            }  else if (key === 'minute' ) {
              minutes = Math.floor(secondsToConvert / this.times[key]);
            }  else if (key === 'second' ) {
              seconds = Math.floor(secondsToConvert / this.times[key]);
            }
            secondsToConvert = secondsToConvert - this.times[key] * Math.floor(secondsToConvert / this.times[key]);
        }
    }

    if (args &&  args[0] === '1') {
      return timePräfix + (hours < 10 ? '0' + hours : hours) +  ':' + (minutes < 10 ? '0' + minutes : minutes)
      +  ':' + (seconds < 10 ? '0' + seconds : seconds);
    } else {
      minutes = seconds > 29 ? minutes + 1 : minutes;
      hours = minutes > 59 ? hours + 1 : hours;
      minutes = minutes > 59 ? minutes - 60 : minutes;
      return timePräfix + (hours < 10 ? '0' + hours : hours) +  ':' + (minutes < 10 ? '0' + minutes : minutes);
    }
  }

}
