//! LOOKS LIKE THIS COMPONENT ISNT USED ANYWHERE

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { NotificationsService } from '../notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  subscription: Subscription;

  isSticky: Boolean = true;
  lifeTime: Number = 3000;

  subscribeToNotifications() {
    this.subscription = this.notificationsService.notificationChange
    .subscribe(notification => {
        const notificationObject = <NotificationsComponent> notification;
        this.isSticky = notificationObject.isSticky;
        this.lifeTime = notificationObject.lifeTime;
        this.msgs = [];
        this.msgs.push(notification);
      });
    }

  constructor(private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.subscribeToNotifications();
  }

  ngOnDestroy(): void {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }
}
