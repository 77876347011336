<div class="projectPackageWrapper">
    <div *ngFor="let package of packages" class="everleadShadowBox" [ngClass]="{ 
        fingerCursor: applicationInfoService.isDeveloper
    }" (click)="selectPackageForProject(package)">
        <div class="projectPackageFeaturesHeader">
            <div class="projectPackageHeader"[ngClass]="{ 
                everleadCoral: package.id == applicationInfoService.projectInfo.packageId
            }">{{ package.defaultName | texttransform }}</div>
            <div class="projectPackageFeaturesHeader__icon">
                <i class="fas fingerCursor" (click)="toggleExpansion(package);$event.stopPropagation()" [ngClass]="{
                    'fa-chevron-down': !packageExpanded(package),
                    'fa-chevron-up': packageExpanded(package)
                }"></i>    
            </div>
        </div>        
        <div class="projectPackageFeaturesAdditionalInfo" *ngIf="packageExpanded(package)">
            <div class="projectPackageListHeader">Features</div>
            <div class="projectPackageList">
                <div *ngFor="let featuresRelation of package.featuresRelations" class="projectPackageListFeature">
                    <div class="projectPackageListFeatureView">
                        <i class="fas fa-eye fingerCursor" (click)="packagefeatureService.showFeatureInfoPopup(featuresRelation.featureId)"></i>
                    </div>                
                    <div class="projectPackageListFeatureName">{{ featuresRelation.defaultName }}</div>
                </div>
            </div>            
        </div>
    </div>
</div>

