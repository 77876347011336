<div class="uiSubchild">
    <div class="uiSubchild__header">
        ControlId: {{ childControl.id }} / LogicalControlId: {{ childControl.logicalControlId }}
    </div>
    <div class="uiSubchild__wrapper">
        <div class="uiSubchild__left">
            <div class="uiSubchild__left__container">  
                <div>
                    <i class="fab fa-jedi-order"></i>
                    <input [(ngModel)]="childControl.order" (change)="designerService.saveControl(childControl, parentControl.id)" class="uiSubchild__left__container__orderbox">
                </div>          
                <div>
                    <input type="checkbox" [(ngModel)]="childControl.isActive" (change)="designerService.saveControl(childControl, parentControl.id)">
                    isActive                    
                </div>    
                <div>
                    <input type="checkbox" [(ngModel)]="childControl.isEnabled" (change)="designerService.saveControl(childControl, parentControl.id)">
                    isEnabled                    
                </div>
                <div>
                    <input type="checkbox" [(ngModel)]="childControl.isVisible" (change)="designerService.saveControl(childControl, parentControl.id)">
                    isVisible    
                </div>
                
            </div>
        </div>

        <div class="uiSubchild__center">
            <div class="uiSubchild__center__infoLine">
                <div class="uiSubchild__center__infoLine__label">
                    ControlType
                </div>
                <div class="uiSubchild__center__infoLine__value">
                    <select [(ngModel)]="childControl.controlType" *ngIf="getControlTypeList() != null"
                        (change)="designerService.saveControl(childControl, parentControl.id)" style="width: 100%">
                        <option [ngValue]=toNumber(230)>Misc Settings</option>
                        <option [ngValue]="null"></option>
                        <option [ngValue]="entityItem.uiEntityInstance" *ngFor="let entityItem of entityList">
                            ({{ entityItem.uiEntityInstance }}) {{ entityItem.entityName }}
                        </option>
                    </select>
                    <input [(ngModel)]="childControl.controlType" (change)="designerService.saveControl(childControl, parentControl.id)" *ngIf="getControlTypeList() == null">
                </div>
            </div>
            <div class="uiSubchild__center__infoLine">
                <div class="uiSubchild__center__infoLine__label">
                    UiEntityInstance
                </div>
                <div class="uiSubchild__center__infoLine__value">
                    <select [(ngModel)]="childControl.uiEntityInstanceId"
                        (change)="designerService.saveControl(childControl, parentControl.id)" style="width: 100%">
                        <option [ngValue]=toNumber(230)>Misc Settings</option>
                        <option [ngValue]="null"></option>
                        <option [ngValue]="entityItem.uiEntityInstance" *ngFor="let entityItem of entityList">
                            ({{ entityItem.uiEntityInstance }}) {{ entityItem.entityName }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="uiSubchild__center__infoLine">
                <div class="uiSubchild__center__infoLine__label">
                    Value
                </div>
                <div class="uiSubchild__center__infoLine__value">
                    <input [(ngModel)]="childControl.value" (change)="designerService.saveControl(childControl, parentControl.id)">
                </div>
            </div>
            <div class="uiSubchild__center__infoLine">
                <div class="uiSubchild__center__infoLine__label">
                    Anzeigetext
                </div>
                <div class="uiSubchild__center__infoLine__value">
                    <input [(ngModel)]="childControl.displayText" (change)="designerService.saveControl(childControl, parentControl.id)">
                </div>
            </div>
          
        </div>

        <div class="uiSubchild__right">
            <i class="fas fa-edit" (click)="designerService.editUiControl(childControl)" title="Element bearbeiten"></i>
            <i class="fas fa-trash" style="color: red;" (click)="deleteControl()" title="Element löschen"></i>
        </div>
    </div>
    <div class="uiSubchild__additionalinfo" *ngIf="getAdditionalInfoAboutControl() != ''">
        {{ getAdditionalInfoAboutControl() }}
    </div>
</div>