<div class="jjHeaderVisibility">

  <div *ngIf="SilentRenewFailed" class="promoteMe">
    <div class="SilentRenewFailedMessage">
      <table>
        <tr>
          <td><button class="btn btn-warning seperateMe"
              (click)="onRenewAccessTokenClicked()">{{ 'General.SilentRenew.ConnectButton' | texttransform }}</button>
          </td>
          <td>{{ 'General.SilentRenew.FailedMessage' | texttransform }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="applicationInfoService.useNewHeader">
    <app-toplevel-header></app-toplevel-header>
  </div>

  <!-- Small menu that shows the current selected item and other stuff like manual -->
  <div class="subMenuControl jjSubMenuControl"
    *ngIf="applicationInfoService.useNewHeader && applicationInfoService.projectSelected">
    <span *ngIf="applicationInfoService.selectedMenuItem !== null">
      <div class="subMenuTopLevelControl jjSubMenuTopLevelControl fingerCursor inline" (click)="getMegaMenuItems()">
        <span *ngIf="applicationInfoService.selectedMenuItem != null">
          <label
            style="padding-left: 5px">{{ applicationInfoService.selectedMenuItem.displayText | texttransform }}</label>
        </span>
        <i class="fas fa-caret-down seperaterIcon" style="padding-left: 5px"></i>
      </div>
      <span class="scrollXWithoutScrollBars" *ngIf="!applicationInfoService?.isMobile()">
        <div *ngFor="let item of applicationInfoService.selectedMenuItem?.childs"
          [ngClass]="{ 'hideInDom': applicationInfoService.isMobile() }" class="subMenuTopLevelChildControls inline">
          <span (click)="callMethod(item)" class="jjHeaderMenuLabel" [ngClass]="{ 
          fingerCursor: item.methods.length > 0,
          highlightMenuItem: item?.id == applicationInfoService.highlightedMenuItem?.id 
        }"
            *ngIf="userService.getControlPermissionByType(item.uiControlSecurityPermissions, true, 'visible')==true && item.isActive">
            <i class="{{ item.icon }} picturePadding"></i>
            {{ item?.displayText | texttransform }}
          </span>
        </div>
      </span>
    </span>
    <span id="generalInformationSpan" style="max-height: 25px; margin-right: 5px; padding-top: 3px" class="floatRight">
      <span (click)="showDesignerMode()" *ngIf="userService.hasPermission('CanUpdateUIControl')">
        <i class="fas fa-edit fa-lg menuBarIcon fingerCursor jjHeaderMenuLabel" style="margin-left: 5px;"></i>
        <span class="fingerCursor jjHeaderMenuLabel" *ngIf="applicationInfoService.isMobile() == false">
          {{ 'General._.EditMode' | texttransform }}
        </span>
      </span>

      <span (click)="callExternalSite(1)">
        <i class="fas fa-clock fa-lg menuBarIcon fingerCursor jjHeaderMenuLabel" style="margin-left: 5px;"></i>
        <span class="fingerCursor jjHeaderMenuLabel" *ngIf="applicationInfoService.isMobile() == false">
          {{ 'General._.TimeRecording' | texttransform }}
        </span>
      </span>

      <span (click)="callExternalSite(2)">
        <i class="fas fa-newspaper fa-lg menuBarIcon fingerCursor jjHeaderMenuLabel"
          style="margin-left: 5px; margin-right: 3px"></i>
        <span class="fingerCursor jjHeaderMenuLabel" *ngIf="applicationInfoService.isMobile() == false">
          {{ 'General._.News' | texttransform}}
        </span>
      </span>

      <span (click)="callExternalSite(3)">
        <i class="fas fa-book fa-lg menuBarIcon fingerCursor jjHeaderMenuLabel" style="margin-left: 5px;"></i>
        <span class="fingerCursor jjHeaderMenuLabel" *ngIf="applicationInfoService.isMobile() == false">
          {{ 'General._.Manual' | texttransform}}
        </span>
      </span>

      <span id="headerQuickSearchSpan" *ngIf="applicationInfoService.projectMode == 1">
        <input type="text" style="height: 21px; width: 150px; margin-left: 5px; margin-bottom: 2px;"
          [(ngModel)]="applicationInfoService.quickSearchValue"
          placeholder="{{ 'General._.QuickSearch' | texttransform }}" (keydown)="handleQuickSearchKeyDown($event)">
        <button style="height: 21px; width: 24px; margin-bottom: 2px;" class="jjButtonColor jjButtonStyle fingerCursor"
          (click)="searchForValue()"><i class="fas fa-search"></i></button>
      </span>
    </span>
  </div>

  <div id="menuPanel" [ngClass]="{ 'hideInDom': !showLargeMenu }" class="largeMenuPanel">
    <div class="flexContainer-start">
      <div *ngFor="let item of applicationInfoService.menuItems">
        <app-large-menu-item [menuitem]=item></app-large-menu-item>
      </div>
    </div>
  </div>

  <span id="dockMenuToMe"></span>

  <div class="activityControl inline noOverflow jjActivityControl"
    *ngIf="applicationInfoService.isTimeRecordingUser==true && applicationInfoService.projectSelected"
    [ngClass]="{ 
      'jjActivityControlAway' : middlewareSignalrServiceService?.availStatus?.status == 2 ||
       middlewareSignalrServiceService?.availStatus?.status == 3 }"
    >
    <app-activity></app-activity>
  </div>

  <div class="softPhoneControl jjSoftPhoneControl inline" *ngIf="applicationInfoService.isWebPhoneUser==true && 
              applicationInfoService.projectSelected && 
              applicationInfoService.isMobile()==false"
              [ngClass]="{ 
                webPhoneActiveCallPanelDimension: middlewareSignalrServiceService?.isActiveCall(),
                webPhoneInactiveCallPanelDimension: !middlewareSignalrServiceService?.isActiveCall()
              }"
  ><app-webphone></app-webphone></div>  

  <app-errormessage></app-errormessage>
</div>
<div class="navigationDebugPanel" *ngIf="applicationInfoService.isDebugMode==true && !applicationInfoService.isMobile()"><app-debugpanel></app-debugpanel></div>

<app-custom-universal *ngIf="applicationInfoService?.additionalMenuBarControlId!=0"
  [controlid]=applicationInfoService?.additionalMenuBarControlId></app-custom-universal>
<!-- <app-custom-popup controluiid="designer"></app-custom-popup> -->
<!-- <p-toast key="topCenter" position="top-center" [baseZIndex]="100"></p-toast>
<p-toast key="topRight" position="top-right" [baseZIndex]="100"></p-toast>
<p-toast key="topLeft" position="top-left" [baseZIndex]="100"></p-toast>
<p-toast key="center" position="center" [baseZIndex]="100"></p-toast>
<p-toast key="bottomCenter" position="bottom-center" [baseZIndex]="100"></p-toast>
<p-toast key="bottomLeft" position="bottom-left" [baseZIndex]="100"></p-toast>
<p-toast key="bottomRight" position="bottom-right" [baseZIndex]="100"></p-toast> -->


<!-- <p-confirmDialog acceptLabel="{{ 'Message.General.Yes' | texttransform }}"
  rejectLabel="{{ 'Message.General.No' | texttransform }}"></p-confirmDialog> -->