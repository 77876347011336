
<div class="uiControlEditor__wrapper">
    <div class="uiControlEditor__item">
        <div class="uiControlEditor__headerLabel">Allgemeines</div>
        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>Id</label></div>
            <div class="uiControlEditor__subItem__interaction">{{controlToModify.id}}</div>
        </div>
        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>Logische Id</label></div>
            <div class="uiControlEditor__subItem__interaction">{{controlToModify.logicalControlId}}</div>
        </div>

        <!-- Label -->
        <br>
        <div>
            <app-uicontrol-valueitem valueType="1" member='displayText' valueItemName="Beschriftung" class="maxWidth"
                (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>            
        </div>
        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>Übersetzung</label></div>
            <div class="uiControlEditor__subItem__interaction uiControlEditor__subItem__translation">
                <app-wizard-translations defaultValue="displayText" [array]="controlToModify" updateBaseArrayExternaldatasourceId=161 mode="3"></app-wizard-translations>        
                <i class="fas fa-trash everleadCoral" (click)="removeTranslation()"></i>    
            </div>            
        </div>
        <br>
        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>Project</label></div>
            <div class="uiControlEditor__subItem__interaction">
                <select [(ngModel)]="controlToModify.projectId" (change)="designerService.saveControl(controlToModify)">
                    <option [ngValue]=null>Für alle Projekte</option>
                    <option [ngValue]=applicationInfoService.projectID>Aktuelles Projekt</option>
                </select>
            </div>
        </div>
        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>ControlType</label></div>
            <div class="uiControlEditor__subItem__interaction">
                <select [(ngModel)]="controlToModify.controlType" (change)="designerService.saveControl(controlToModify)"
                    *ngIf="controlToModify.parentControlId == null">
                    <option *ngFor="let option of controlTypeOptions" [ngValue]=toNumber(option.id)>{{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <app-uicontrol-valueitem valueType="1" member='width'  valueItemName="Breite" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='height'  valueItemName="Höhe" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='icon'  valueItemName="Icon" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='value'  valueItemName="Value" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        

        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>uiEntityInstanceId</label></div>
            <div class="uiControlEditor__subItem__interaction">
                <select [(ngModel)]="controlToModify.uiEntityInstanceId" (change)="designerService.saveControl(controlToModify)">
                    <option [ngValue]=toNumber(230)>Misc Settings</option>
                    <option [ngValue]="null"></option>
                    <option [ngValue]="entityItem.uiEntityInstance" *ngFor="let entityItem of entityList">
                        ({{ entityItem.uiEntityInstance }}) {{ entityItem.entityName }}
                    </option>
                </select>
            </div>
        </div>
        <app-uicontrol-valueitem valueType="1" member='parentControlId' valueItemName="ParentControlId" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='parentContainerId' valueItemName="ParentContainerId" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='inline' valueItemName="Inline-Wert" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='reserverSpaceLeft' valueItemName="Spacer links" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='order' valueItemName="Order-Wert" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="2" member='tooltip' valueItemName="Tooltip" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
    </div>

    <div class="uiControlEditor__item">
        <div class="uiControlEditor__headerLabel">Visibility</div>
        <div class="uiControlEditor__subItem">
            <div class="uiControlEditor__subItem__label"><label>Viewmode</label></div>
            <div class="uiControlEditor__subItem__interaction">
                <select [(ngModel)]="controlToModify.lookupVisibilityId" (change)="designerService.saveControl(controlToModify)">
                    <option [ngValue]=null></option>
                    <option *ngFor="let visibilityMode of visibilityModes" [ngValue]="visibilityMode.id">
                        {{ visibilityMode.defaultName | texttransform }}
                    </option>
                </select>
            </div>
        </div>
        <app-uicontrol-valueitem valueType="3" member='minXResolution' valueItemName="X-MinResolution" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="3" member='maxXResolution' valueItemName="X-MaxResolution" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="3" member='minYResolution' valueItemName="Y-MinResolution" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="3" member='maxYResolution' valueItemName="Y-MaxResolution" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='minWidth' valueItemName="minWidth" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>
        <app-uicontrol-valueitem valueType="1" member='maxWidth' valueItemName="maxWidth" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
    </div>

    <div class="uiControlEditor__item">
        <div class="uiControlEditor__headerLabel">Additional settings</div>
        <app-uicontrol-valueitem valueType="2" member='additionalSetting1' valueItemName="Setting1" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="2" member='additionalSetting2' valueItemName="Setting2" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="2" member='additionalSetting3' valueItemName="Setting3" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
    </div>
    
    <div class="uiControlEditor__item">
        <div class="uiControlEditor__headerLabel">Mobile settings</div>
        <app-uicontrol-valueitem valueType="3" member='mobileRow' valueItemName="Mobile Row" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
    </div>

    <div class="uiControlEditor__item">
        <div class="uiControlEditor__headerLabel">CSS</div>
        <app-uicontrol-valueitem valueType="1" member='additionalCssClasses' valueItemName="Sonstiges CSS" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="1" member='additionalCssClassesMainLabel' valueItemName="Main label"  (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="1" member='additionalCssClassesMainControl' valueItemName="Main control"  (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
    </div>

    <div class="uiControlEditor__item">
        <div class="uiControlEditor__headerLabel">Bools</div>
        <app-uicontrol-valueitem valueType="4" member='isActive' valueItemName="isActive" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isDeveloperOnly' valueItemName="isDeveloperOnly" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isEnabled' valueItemName="isEnabled" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isVisible' valueItemName="isVisible" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isRequired' valueItemName="isRequired" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isResetVisible' valueItemName="isResetVisible" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isFieldActivatable' valueItemName="isFieldActivatable" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
        <app-uicontrol-valueitem valueType="4" member='isFieldFilterable' valueItemName="isFieldFilterable" (valueStatusChange)="callbackEvent($event)" [control]="controlToModify"></app-uicontrol-valueitem>        
    </div>    
    <!-- <app-uicontrol-design-attributemodifier [currentControlToModify]=controlToModify></app-uicontrol-design-attributemodifier>
    <app-ui-control-design-security-permission [control]=controlToModify></app-ui-control-design-security-permission> -->
</div>