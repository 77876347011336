<div class="userDashboard__wrapper" *ngIf="selectionmode == 1">
    <div class="userDashboardSelect fingerCursor" (click)="applicationInfoService.userDashboardMode = 1" [ngClass]="{ everleadCoral: applicationInfoService.userDashboardMode == 1 }">
        <label>{{ 'UserDashboard.Label.Marketing' | texttransform }}</label>
    </div>
    <div class="userDashboardSelect fingerCursor" (click)="applicationInfoService.userDashboardMode = 2" [ngClass]="{ everleadCoral: applicationInfoService.userDashboardMode == 2 }">
        {{ 'UserDashboard.Label.Sales' | texttransform }}
    </div>
</div>

<div class="userDashboard__wrapper" *ngIf="selectionmode == 2">
    <!-- Marketing -->
    <div *ngIf="applicationInfoService.userDashboardMode == 1" class="maxHeight maxWidth userDashboard__container">
        <div id="dashboardMarketingPersonal" class="dashboardPanelGeneral dashboardPanelSmall dashboardMarketingPersonal">
            <div class="userDashboard__headerLabel">{{ 'UserDashboard.Label.YourDashboard' | texttransform }}</div>
            <div class="dashboardMarketingPersonal__header" [innerHTML]="'Label.Welcomepage.Header' | texttransform"></div>
            <div class="dashboardMarketingPersonal__tipp">
                <app-contentinfo tippoftheday=true></app-contentinfo>
                <!-- <label class="dashboardMarketingPersonal__tipp__label">{{ 'UserDashboard.Label.TippOfTheDay' | texttransform }}</label>
                <div>
                    <label [innerHTML]="axivasTranslateService.getTranslationTextForId(selectedTipp.teaserTranslationTokenId) | texttransform"
                        *ngIf="selectedTipp != null"></label>
                </div> -->
            </div>
            <div class="dashboardMarketingPersonal__reminder">
                Reminder
            </div>
        </div>
        <div id="dashboardMarketingCampaign" class="dashboardPanelGeneral dashboardPanelSmall">
            <div class="dashboardMarketingCampaign">
                <div class="userDashboard__headerLabel">{{ 'UserDashboard.Label.YourCampaigns' | texttransform }}</div>
                <div class="dashboardMarketingCampaign__myCampaigns">{{ 'UserDashboard.Label.MicroCampaigns' | texttransform }}</div>
                <div class="dashboardMarketingCampaign__body">                    
                    <div *ngFor="let campaignOverviewItem of campaignOverview.result" class="campaignOverviewItem">
                        <div class="campaignOverviewItem__campaignName everleadCoral">
                            <span *ngIf="!campaignOverviewItem.resultNameTranslationTokenId">{{ campaignOverviewItem.resultName | texttransform }}</span>
                            <span *ngIf="campaignOverviewItem.resultNameTranslationTokenId">{{ axivasTranslateService.getTranslationTextForId(campaignOverviewItem.resultNameTranslationTokenId) | texttransform }}</span>
                        </div>       
                        <div class="campaignOverviewItem__campaignInfo">
                            <div class="campaignOverviewItem__campaignInfo__item" [tippy]="'UserDashboard.Label.YourCampaignsTasks' | texttransform"><i class="fas fa-tasks"></i>{{ campaignOverviewItem.taskCount }}</div>
                            <div class="campaignOverviewItem__campaignInfo__item" [tippy]="'UserDashboard.Label.YourCampaignsContacts' | texttransform"><i class="fas fa-user"></i>{{ campaignOverviewItem.contactCount }}</div>
                            <div class="campaignOverviewItem__campaignInfo__item" [tippy]="'UserDashboard.Label.YourCampaignsWins' | texttransform"><i class="fas fa-trophy"></i>{{ campaignOverviewItem.positiveLeadCount }}</div>
                            <div class="campaignOverviewItem__campaignInfo__item fingerCursor" (click)="showCampaignVisual(campaignOverviewItem)"><i class="fas fa-cog everleadCoral"></i></div>
                            <div class="campaignOverviewItem__campaignInfo__deco"></div>
                        </div>             
                    </div>
                </div>
                <div class="dashboardMarketingCampaign__footer">
                    <div class="dashboardMarketingCampaign__footer__info">{{ 'UserDashboard.Label.CampaignBoard' | texttransform }}</div>
                    <div class="dashboardMarketingCampaign__footer__page">
                        <select [(ngModel)]="dashboardInfo.microCampaignItemsPerPage" (change)="getCampaignOverview()">
                            <option [ngValue]=5>5</option>
                            <option [ngValue]=10>10</option>
                            <option [ngValue]=20>20</option>
                        </select>
                    </div>                        
                </div>
            </div>
        </div>    
        <div id="dashboardMarketingTrendbarometer" class="dashboardPanelGeneral dashboardPanelBig dashboardMarketingTrendbarometer dashboardPanelDark">
            dashboardMarketingTrendbarometer
        </div>    

    </div>

    <!-- Sales -->
    <div *ngIf="applicationInfoService.userDashboardMode == 2" class="maxHeight maxWidth userDashboard__container">
        <div class="dashboardPanelGeneral dashboardPanelSmall">x</div>
        <div class="dashboardPanelGeneral dashboardPanelSmall">x</div>
        <div class="dashboardPanelGeneral dashboardPanelSmall">x</div>
        <div class="dashboardPanelGeneral dashboardPanelSmall dashboardPanelDark">x</div>
    </div>    
</div>