<div class="dashboardcomponentKpis__wrapper">
    <div class="websiteDashboardHeaderLabel" *ngIf="showKPIs">{{ 'WebsiteDashboard.Label.Leads' | texttransform }}</div>
    <div class="websiteDashboardHeaderLabel" *ngIf="!showKPIs">{{ 'WebsiteDashboard.Label.ProgressState' | texttransform }}</div>
    <span *ngIf="loading">
        <br>
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
    <div class="websiteDashboard__generalcontent__inner everleadShadowBox" *ngIf="!loading">
        <div class="websiteDashboard__generalcontent" *ngIf="showKPIs">
            <div class="websiteDashboard__generalcontent__item">
                <div class="ellipsisText websiteDashboard__generalcontent__item__text">{{ 'WebsiteDashboard.Label.Accounts' |
                    texttransform }}</div>
                <div class="websiteDashboard__generalcontent__item__number">{{ kpis?.accounts }}</div>
            </div>
            <div class="websiteDashboard__generalcontent__item">
                <div class="ellipsisText websiteDashboard__generalcontent__item__text">{{ 'WebsiteDashboard.Label.Contacts' |
                    texttransform }}</div>
                <div class="websiteDashboard__generalcontent__item__number">{{ kpis?.contacts }}</div>
            </div>
            <div class="websiteDashboard__generalcontent__item">
                <div class="ellipsisText websiteDashboard__generalcontent__item__text">{{ 'WebsiteDashboard.Label.Conversions' |
                    texttransform }}</div>
                <div class="websiteDashboard__generalcontent__item__number">{{ kpis?.conversions }}</div>
            </div>
            <div class="websiteDashboard__generalcontent__item">
                <div class="ellipsisText websiteDashboard__generalcontent__item__text">{{ 'WebsiteDashboard.Label.Prospects' |
                    texttransform }}</div>
                <div class="websiteDashboard__generalcontent__item__number">{{ kpis?.prospects }}</div>
            </div>
        </div>
        <div class="chartContainer">
            <div class="row">
                <div class="col-sm-12 col-md-6" *ngIf="chartOptionsAccounts.data?.length > 0">
                    <app-echarts
                        [id]="pieId"
                        [chart]="'pie'"
                        [title]="'WebsiteDashboard.Label.Accounts' | texttransform"
                        [chartData]="chartOptionsAccounts"
                        [darkmode]="false"
                        [legend]="false"
                        [height]="175"
                        tooltipPosition="right"
                    ></app-echarts>
                </div>
                <div class="col-sm-12 col-md-6" *ngIf="chartOptionsContacts.data?.length > 0" >
                    <app-echarts
                        [id]="pieId"
                        [chart]="'pie'"
                        [title]="'WebsiteDashboard.Label.Contacts' | texttransform"
                        [chartData]="chartOptionsContacts"
                        [darkmode]="false"
                        [legend]="false"
                        tooltipPosition="left"
                        [height]="175"
                    ></app-echarts>
                </div>
            </div>
        </div>
    </div>
</div>
