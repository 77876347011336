    <div cdkDropListGroup>
      <p-panel  
        [ngStyle]="setControlStyle('panelwidth')"
        header="{{ header[i] | texttransform }}"
        *ngFor="let dropList of content; let i = index" 
        class="inline dragColumnDiv"
        cdkDropList 
        [cdkDropListData]="content[i]" 
        (cdkDropListDropped)="drop($event)"
        >
        <div class="example-list">
          <div class="example-box" *ngFor="let item of content[i]" cdkDrag [cdkDragData]="item">{{item}}</div>
        </div>
      </p-panel>        
    </div>
    