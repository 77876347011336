import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LanguageService } from 'app/shared/translation/language.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-leadreportsettings',
  templateUrl: './wizard-leadreportsettings.component.html',
  styleUrls: ['./wizard-leadreportsettings.component.scss']
})
export class WizardLeadreportsettingsComponent implements OnInit {
  languages = [];
  lookuptables = [];
  levels = [];
  templateDefinitions = [];
  leadreportExportSettings = [];
  uploadType = 'box';

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private languageService: LanguageService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.languageService.getLanguages()
    .then(getAvailableLanguagesResult => {
      this.languages = getAvailableLanguagesResult;
    });
    this.getLevels();
    this.getExportSettings();
    this.getTemplateDefinitions();
  }

  getLevels() {
    this.externaldatasourceService.getLookupTableValues(24, 2).then(result => {this.levels = result; });
  }

  getTemplateDefinitions() {
    this.externaldatasourceService.executeExternalDataSource(261, []).then(result => { this.templateDefinitions = result; });
  }

  getExportSettings() {
    this.externaldatasourceService.getLookupTableValues(36, 2).then(result => {this.leadreportExportSettings = result; });
  }

  checkLeadreportPath(leadreportBoxPath, resetValue = false) {
    this.externaldatasourceService.executeExternalDataSource(260, [
      this.commonService.getModifyArrayBody({ 
        id: this.applicationInfoService.projectID,  
        leadreportBoxPath: leadreportBoxPath
      },[])
    ])
    .then(() => {
      if (resetValue && leadreportBoxPath != '') {
        this.checkLeadreportPath(this.applicationInfoService.projectInfo.leadreportBoxPath);
      }      
    })
    .catch(error => {
      console.warn(error);
    });
  }
}
