<div class="awsLoginWrapper">
    <div class="awsLoginLogo">
        <svg-icon src="assets/images/everleadtheme/EVERLEAD_logo.svg" class="awsLoginLogo__svg"
        [svgStyle]="{ 'width.px': 370, 'height.px': 80 }"></svg-icon>
    
    </div>

    <div class="awsLoginData">
        <div class="awsLoginLine">
            <div class="awsLoginLine__label">Username</div>
            <div class="awsLoginLine__value">
                <input class="maxWidth" [(ngModel)]="applicationInfoService.awsUserName" placeholder="firstname.lastname@domain.com" (change)="checkUserName()">
            </div>
        </div>
    
        <div class="awsLoginLine" *ngIf="isInternalUser == false && userChecked == true && pageMode == 2">
            <div class="awsLoginLine__label">Password</div>
            <div class="awsLoginLine__value">
                <input class="maxWidth" [(ngModel)]="applicationInfoService.awsPassword" type="password">
            </div>
        </div>   
        <div class="awsLoginLine" *ngIf="isInternalUser == false && userChecked == true && pageMode == 2">
            <div class="awsLoginLine__label"></div>
            <div class="awsLoginLine__value">
                <div class="awsLoginForgotPassword" (click)="openPasswordReset()">Forgot your password?</div>
            </div>            
        </div> 
    </div>
    <div class="awsLoginButton" *ngIf="pageMode == 1">
        <button class="everleadCustomButton awsLoginButton__button" (click)="nextPage()" [disabled]="applicationInfoService.awsUserName?.length < 5">
            <span *ngIf="isInternalUser == true">Login</span>
            <span *ngIf="isInternalUser == false">Next</span>
        </button>
    </div>

    <div class="awsLoginButton" *ngIf="userChecked == true && isInternalUser == false && pageMode != 1">
        <button class="everleadCustomButton awsLoginButton__button" (click)="loginUser()">Login</button>
    </div>

    <!-- <div class="awsLoginAGMember" *ngIf="isInternalUser == true">
        <div class="awsLoginAGMember__header">Hello {{ internalUserName }}</div>
        <div>
            As a member of ALEX & GROSS, please use the member login
        </div>
    </div>

    <div class="awsLoginButton" *ngIf="isInternalUser == true">
        <button class="everleadCustomButton awsLoginButton__buttoninternal" (click)="loginAG()">ALEX & GROSS member login</button>
    </div> -->
</div>