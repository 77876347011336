<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<div class="aiPromptResult__wrapper" *ngIf="featureNotFound == false">
    <div class="aiPromptResult__selection" *ngIf="aiPrompts.length > 1">
        <label class="aiPromptResult__selection__label">{{ 'AIPrompt.Label.BattleCards' | texttransform }}</label>
        <select (change)="runPrompt(selectedPompt.id)" [(ngModel)]="selectedPompt" class="aiPromptResult__select">
            <option disabled="true" [ngValue]=null>{{ 'AIPrompt.Label.SelectBattleCard' | texttransform }}</option>
            <option *ngFor="let aiPrompt of aiPrompts" [ngValue]="aiPrompt">{{ aiPrompt.defaultName }}</option>
        </select> 
    </div>
    <div class="aiPromptResult__result" *ngIf="!loadingData">
        <label id="promptResultVisual" [innerHTML]="resultToDisplay" *ngIf="resultToDisplay != null"></label>
        <label class="promptResultVisualPdf" id="promptResultVisualPdf" [innerHTML]="resultToDisplayPdf" *ngIf="resultToDisplay != null"></label>
    </div>    
    <label class="aiPromptResult__result aiPromptResult__wait" *ngIf="loadingData">        
        <svg-icon src="assets/images/svg/battlecard/everlead_battlecard_wait.svg" class="aiPromptSVG" [stretch]="true" *ngIf="selectedPompt.pictureData == null">            </svg-icon>
        <img [src]="selectedPompt.pictureData" class="aiPromptPicture" *ngIf="selectedPompt.pictureData != null">
        <div><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'AIPrompt.Label.GeneratingData' | texttransform }}</div>
    </label>
    <div clasS="aiPromptResult__runOptions">
        <div class="aiPromptResult__runOptions__force">
            <button class="everleadCustomButton" (click)="runPrompt(selectedPompt, true)"
                [disabled]="selectedPompt == null">{{ 'AIPrompt.Label.ForceUpdate' | texttransform }}</button>
            <!-- <input [(ngModel)]="forceUpdate" type="checkbox">{{ 'AIPrompt.Label.ForceUpdate' | texttransform }} -->
        </div>
        <label class="ellipsisText noMargin">({{ 'AIPrompt.Label.GeneratingBattleCard' | texttransform }})</label>
        <div class="aiPromptResult__runOptions__export">
            <button class="everleadCustomButton" (click)="exportPDF()" [disabled]="resultToDisplay == null"
                *ngIf="applicationInfoService.applicationSettings['showAIPromptExportToPDFButton'] == 'true'">
                {{ 'AIPrompt.Label.ExportToPdf' | texttransform }}
            </button>
        </div>
    </div>
</div>
