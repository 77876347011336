import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-generalsettingsprojecttarget',
  templateUrl: './generalsettingsprojecttarget.component.html',
  styleUrls: ['./generalsettingsprojecttarget.component.scss']
})
export class GeneralsettingsprojecttargetComponent implements OnInit {
  @Input() data;
  @Input() reportTypeLookups = [];
  constructor(
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
  }

  saveSettings() {    
    let dataSourceParam = this.commonService.getModifyArrayBody(this.data, []);
    this.externaldatasourceService.executeExternalDataSource(803, [dataSourceParam]);
  }

  deleteTarget(data) {
    this.externaldatasourceService.executeExternalDataSource(804, [data.id]);
    this.eventService.customEvent.emit({ id: 'removeProjectTarget', value: data })
  }
}
