<div class="userGroup__wrapper">
  <div class="projectUser__header">
    <div class="projectUser__header__item">
      <div class="projectUser__header__label">
        <label>{{ 'UserGroups.Label.UserGroupType' | texttransform }}</label>
      </div>
      <div class="projectUser__header__dropdown">
        <select [(ngModel)]="selectedUserGroupType" (change)="getAvailableUserGroups();">
          <option *ngFor="let userGroupType of userGroupTypes" [ngValue]="userGroupType.id">{{ userGroupType.defaultName
            |
            texttransform }}</option>
        </select>
      </div>
    </div>
    <div class="projectUser__header__item">
      <div class="projectUser__header__label">
        <label>{{ 'UserGroups.Label.SelectedUserGroup' | texttransform }}</label>
      </div>
      <div class="projectUser__header__dropdown">
        <select [(ngModel)]="selectedUserGroup" (change)="getAssignedUsers()">
          <option *ngFor="let userGroup of userGroups" [ngValue]="userGroup" (change)="getUserGroupMember();">
            <label *ngIf="userGroup.nameTranslationToken == null">{{ userGroup.defaultName | texttransform }}</label>
            <label *ngIf="userGroup.nameTranslationToken != null">{{ userGroup.nameTranslationToken?.tokenFullName |
              texttransform }}</label>
          </option>
        </select>
        <i class="fas fa-plus fingerCursor" *ngIf="userService.hasPermission('CanCreateUserGroup')" (click)="createNewUserGroupMode = true"></i>
      </div>
    </div>
    <div class="projectUser__header__item" *ngIf="selectedUserGroupType == userGroupMailType">
      <div class="projectUser__header__label">
        <label>{{ 'UserGroups.Label.Assignment' | texttransform }}</label>
      </div>
      <div class="projectUser__header__dropdown">
        <select [(ngModel)]="assignmentType">
          <option ngValue="user">{{ 'UserGroups.Label.AssignmentUser' | texttransform }}</option>
          <option ngValue="mail">{{ 'UserGroups.Label.AssignmentEmail' | texttransform }}</option>
        </select>
      </div>
    </div>

  </div>

  <div *ngIf="assignmentType == 'mail'">
    <div>
      {{ 'UserGroups.Label.AssignmentDescription' | texttransform }}
    </div>
    <label class="everleadSmallHeader">{{ 'UserGroups.Label.MailSes' | texttransform }}</label>
    <div class="everleadShadowBox">
      <div *ngFor="let mailConfigSesRefId of mailConfigSesRefIds" (click)="toggleMailConfigSes(mailConfigSesRefId)"
        [ngClass]="{ everleadCoral: checkIfSesConfigIsActive(mailConfigSesRefId) != null }" class="fingerCursor">
        {{ commonService.getTranslationValueFromArray(mailConfigSesRefId) | texttransform }}
      </div>
    </div>

    <label class="everleadSmallHeader">{{ 'UserGroups.Label.MailSmtp' | texttransform }}</label>
    <div class="everleadShadowBox">
      <div *ngFor="let mailConfigSmtpRefId of mailConfigSmtpRefIds" (click)="toggleMailConfigSmtp(mailConfigSmtpRefId)"
        [ngClass]="{ everleadCoral: checkIfSmptSettingIsActive(mailConfigSmtpRefId) != null }" class="fingerCursor">
        {{ commonService.getTranslationValueFromArray(mailConfigSmtpRefId, 'displayName') | texttransform }}
      </div>
    </div>
  </div>


  <div class="userManagement__wrapper" *ngIf="assignmentType == 'user'">
    <div class="userManagement__left">
      <div class="userManagement__sectionHeader">
        <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}</div>
        <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
          [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
        <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
          [(ngModel)]="filterString">
      </div>
      <div id="userList"></div>
      <div class="projectUser__header__userList"
        [style.height.px]="uiService.getContainerHeightEx('userList', 40, 300)">
        <div *ngFor="let user of availableUsers"
          [ngClass]="{ hideInDom: isFilterActive(user.userName, filterString) || checkIfAlreadyAssigned(user) }">
          <div class="userManagement__item__container">
            <div class="userManagement__item__id">{{ user.id }}</div>
            <div class="userManagement__item__name ellipsisText">{{ user.userName }}</div>
            <div class="userManagement__item__move" (click)="addUser(user)">
              <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon"
                *ngIf="selectedUserGroup != null" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="userManagement__left">
      <div class="userManagement__sectionHeader">
        <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' | texttransform }}</div>
        <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
          [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
        <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
          [(ngModel)]="filterStringAssigned">
      </div>
      <div id="assignedList"></div>
      <div class="projectUser__header__userList"
        [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
        <div *ngFor="let assignedUser of assignedUsers"
          [ngClass]="{ hideInDom: isFilterActive(assignedUser.user.userName, filterStringAssigned) }">
          <div class="userManagement__item__container userManagement__item__containerreverse">
            <div class="userManagement__item__id">{{ assignedUser.user.id }}</div>
            <div class="userManagement__item__name ellipsisText">{{ assignedUser.user.userName }}</div>
            <div class="userManagement__item__move" (click)="removeUser(assignedUser)">
              <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="userGroup__popup__background" *ngIf="createNewUserGroupMode == true"></div>
  <div class="userGroup__popup" *ngIf="createNewUserGroupMode == true">
    <div>
      <div class="userGroup__popup__header">
        <label>{{ 'UserGroups.Label.UserGroupName' | texttransform }}</label>
        <i class="fas fa-close fingerCursor" (click)="createNewUserGroupMode = false"></i>
      </div>
      <input [(ngModel)]="newGroupName" class="maxWidth">
      <br>
      <br>
      <button class="jjButtonStyle jjButtonColor" (click)="createNewUserGroup()"
        [disabled]="newGroupName.length < 4">
          {{ 'UserGroups.Label.CreateNewUserGroup' | texttransform }}
      </button>
    </div>
  </div>
</div>