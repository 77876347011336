import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-dashboardcontrol-userviplist',
  templateUrl: './custom-dashboardcontrol-userviplist.component.html',
  styleUrls: ['./custom-dashboardcontrol-userviplist.component.scss']
})
export class CustomDashboardcontrolUserviplistComponent implements OnInit {
  userVIPList = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    this.getUserVIPList();
  }

  getUserVIPList() {
    this.externaldatasourceService.executeExternalDataSource(773, [])
    .then(getUserVIPListResult => {
      getUserVIPListResult.sort((a, b) => a.lastVisited > b.lastVisited ? -1 : 1);
      this.userVIPList = getUserVIPListResult;
    });
  }
}
