import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-leadscoring-detail-potential',
  templateUrl: './leadscoring-detail-potential.component.html',
  styleUrls: ['./leadscoring-detail-potential.component.scss']
})
export class LeadscoringDetailPotentialComponent implements OnInit {
  leadPotentials = [];

  constructor(
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private methodService: MethodService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.getPotentials();
  }

  getPotentials() {
    this.externaldatasourceService.executeExternalDataSource(400, [])
    .then(result => {
      this.leadPotentials = result;
    });
  }

  selectItem(leadPotential) {
    this.applicationInfoService.entities.Item('4').data['leadPotentialId'] = leadPotential.id;
    this.applicationInfoService.currentContact.leadPotentialId = leadPotential.id;
    this.methodService.launchInternalMethod(InternalMethodType.SaveEntityToServer, [4]).then(() => {
      this.eventService.updateControlData('leadscoring-detail', []);
      this.eventService.closeJjPopup();
    });
  }
}
