import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-externalusers',
  templateUrl: './externalusers.component.html',
  styleUrls: ['./externalusers.component.scss']
})
export class ExternalusersComponent implements OnInit {
  newUser = null;
  mode = 1;
  userGroup = null;
  resultUser = null;
  userAlreadyExists = false;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.createNewUser();
  }

  createNewUser() {
    this.newUser = new Object();
    this.newUser['projectId'] = this.applicationInfoService.projectID;
    this.newUser['sendEmailToUser'] = false;
    this.newUser['roleId'] = 12;
    this.userGroup = this.applicationInfoService.miscSettings['newExternalUSerGroup'];
  }

  createExternalUser() {        
    this.loaderService.display(true);
    this.sharedAPI.createExternalAccount(this.newUser).subscribe(createExternalUserResult => {
      this.mode = 2;
      this.resultUser = createExternalUserResult;
      this.eventService.externalUserCreatedEvent.emit(createExternalUserResult);
      this.loaderService.display(false);
    });    
  }

  createAnotherUser() {
    this.newUser = new Object();
    this.userAlreadyExists = false;
    this.mode = 1;
  }

  checkIfUserCanBeCreated() {
    let returnValue = false;
    if(
      this.commonService.isNullOrUndefined(this.newUser.firstName) ||
      this.commonService.isNullOrUndefined(this.newUser.lastName) || 
      this.commonService.isNullOrUndefined(this.newUser.email)
    ) {
      returnValue = true;
    }
    if (!this.commonService.isNullOrUndefined(this.newUser.email)) {
      if (
        !this.commonService.checkIfStringContainsString(this.newUser.email, '@') || 
        !this.commonService.checkIfStringContainsString(this.newUser.email, '.')
      ) {
        returnValue = true;
      }
    }
    return returnValue;
  }
}
