import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[onlynumber]'
})
export class OnlynumberDirective {

  constructor(private _el: ElementRef) {}
  @HostListener("keydown", ["$event"])  
  onKeyDown(e: KeyboardEvent) {
    if (
      (e.key.toLowerCase() === 'a' && e.ctrlKey === true) || 
      (e.key.toLowerCase() === 'c' && e.ctrlKey === true) || 
      (e.key.toLowerCase() === 'x' && e.ctrlKey === true) || 
      (e.key.toLowerCase() === 'a' && e.metaKey === true) || 
      (e.key.toLowerCase() === 'c' && e.metaKey === true) || 
      (e.key.toLowerCase() === 'x' && e.metaKey === true) || 

      e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' ||
      e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' ||
      e.key === '9' ||e.key === '0' ||

      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'Home' ||
      e.key === 'Tab' ||
      e.key === 'End' 
    ) {
      return;
    } else {
      e.preventDefault();
    }
  }
}
