import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { ImportHelperService } from '../../supervisor/import/import-helper.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-blacklistimport',
  templateUrl: './wizard-blacklistimport.component.html',
  styleUrls: ['./wizard-blacklistimport.component.scss']
})
export class WizardBlacklistimportComponent implements OnInit {
  uploadingFile = false;
  sheets = [];
  pools = [];
  lookupTables = [];
  savedTemplates = [];
  dataPopupOpen = false;
  templateMode = 0;
  newTemplateName = '';
  newLookupTableName = '';
  newPoolName = '';
  suggestionValues = [];
  headerToModify = null;
  importCurrentlyRunning = false;
  importResult = null;
  automaticAssignmentArray = [];
  isSkipImportOnError = false;
  isCheckIfEmailExists = true;

  constructor(
    private sharedAPI: SharedAPI,
    private importHelperService: ImportHelperService,
    public wizardService: WizardService,
    public commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.wizardService.wizardArray.selectedHeader = null;
    this.wizardService.wizardArray.blacklistTypeLookupId = null;    
  }


  uploadFile(event) {
    this.uploadingFile = true;
    const files: FileList = event.target.files;
    const formData = new FormData();
    this.wizardService.wizardArray.selectedSheet = null;
    for ( let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    this.sharedAPI.uploadFile(formData).subscribe(uploadFileResult => { 
      uploadFileResult.forEach(result => { this.orderSheet(result) })     
      if (uploadFileResult.length > 0) {
        this.wizardService.wizardArray.selectedSheet = uploadFileResult[0];
      }          
      this.sheets = uploadFileResult;
      this.importHelperService.currentImportFile = files[0].name;
      this.importHelperService.currentImportFileFormData = files[0];
      this.wizardService.wizardArray['uploadFile'] = files[0].name;
      this.wizardService.wizardArray['uploadFileFormData'] = files[0];
      this.wizardService.wizardPage = 2;
      this.uploadingFile = false;
    });
  }

  orderSheet(sheet, init = true) {
    if (init) {
      let order = 100;
      let dataOrder = 0;
      sheet.data.headers.forEach(header => {
        header.orderValue = order;
        header.dataOrder = dataOrder;
        order = order + 100;
        dataOrder ++;        
      });  
    }
    sheet.data.headers.sort((a, b) => a.orderValue < b.orderValue ? -1 : 1);
  }

  importSheet(checkData = false) {   
    this.wizardService.wizardArray.selectedSheet.data.headers.forEach(header => {
      if (header.type == null) { header.type = 'string' }
    });
    this.importCurrentlyRunning = true;
    this.messagingService.showDefaultInfo('', 'Import.Label.ImportedStarted')
    this.importHelperService.sendBlacklistImportDataToBackEnd(
      this.wizardService.wizardArray.selectedSheet.name,
      this.importHelperService.currentImportFile,
      this.applicationInfoService.projectID,
      this.wizardService.wizardArray.blacklistTypeLookupId,
      this.wizardService.wizardArray.selectedHeader.name
    ).then(importResult => {
      this.importResult = importResult;
      this.templateMode = 5;
      this.dataPopupOpen = true;
      this.importCurrentlyRunning = false;
      this.eventService.customEvent.emit('blacklistimported');
    })
    .catch(error => {
      console.error(error);
      this.importCurrentlyRunning = false;
    });
  }
}
