import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';


@Component({
  selector: 'app-wizard-campaign',
  templateUrl: './wizard-campaign.component.html',
  styleUrls: ['./wizard-campaign.component.scss']
})
export class WizardCampaignComponent implements OnInit {
  configSets = [];
  
  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
    this.getConfigSets();
  }

  checkCampaign() {
    this.wizardService.nextDisabled = false;
    if (this.wizardService.wizardPage == 1 && this.wizardService.wizardArray.defaultName == '') {
      this.wizardService.nextDisabled = true;
    }
    return false;
  }

  getConfigSets() {
    this.externaldatasourceService.executeExternalDataSource(532, [])
    .then(result => { this.configSets = result; });
  }
}
