<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<div class="consentManagementDashboard__wrapper" *ngIf="featureNotFound == false">
    <div class="consentManagementDashboard__30days">
        <div class="everleadShadowBox maxWidth consentManagementDashboardItem" *ngIf="applicationInfoService.projectLookupTables.length != 0">
            <app-consentmanagementdashboard-item></app-consentmanagementdashboard-item>
        </div>
    </div>
</div>