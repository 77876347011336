<div class="featureWrapper">
  <div class="featureHeader">
    {{ commonService.getTranslationValueFromArray(packagefeatureService.getFeatureById(featureId)) | texttransform }}
  </div>
  <div class="featureSubHeader">
    {{ packagefeatureService.getFeatureSubHeaderById(featureId) | texttransform }}
  </div>

  <div class="featureSpinner">
    <div class="featureSpinner__spinnArrow" *ngIf="slider?.length > 1">
      <i class="fas fa-angle-double-left fingerCursor" (click)="changeSlide(false)"></i>
    </div>
    <div class="featureBody">
      <div class="featureBody__picture">
        <img class="featureBody__picture__inner"
            [src]="getFeatureSliderImage(currentSlider)"
            [alt]="commonService.getTranslationValueFromArray(currentSlider)">
      </div>
      <div class="featureBody__text">
        <div [innerHTML]="getFeatureSliderInnerHtml(currentSlider)"></div>
      </div>
    </div>
    <div class="featureSpinner__spinnArrow" *ngIf="slider?.length > 1">
      <i class="fas fa-angle-double-right fingerCursor" (click)="changeSlide(true)"></i>
    </div>
  </div>
</div>
