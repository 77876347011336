import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { WizardMode, WizardService } from '../wizard/wizard.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';

@Component({
  selector: 'app-voicescriptcontrol',
  templateUrl: './voicescriptcontrol.component.html',
  styleUrls: ['./voicescriptcontrol.component.scss']
})
export class VoicescriptcontrolComponent implements OnInit {
  voiceScript = null;
  voiceScriptTemplates = [];
  renderedValue = null;
  mode = 2;
  filterString = '';
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    public uiService: UiService,
    private wizardService: WizardService,
    public ringcentralwebphoneService: RingcentralwebphoneService
  ) { }

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['voiceScriptId'])) {
      this.mode = 1;
    }

    switch (this.mode) {
      case 1:
        this.getVoiceScriptContent();
        break;
      case 2:
        this.getTemplates();
        break;
    }    
  }

  createNewTemplate() {
    this.externaldatasourceService.executeExternalDataSource(795)
    .then(createNewTemplateResult => {
      this.showWizard(createNewTemplateResult);
      this.getTemplates();
    });
  }

  getVoiceScriptContent() {
    const contentId = this.applicationInfoService.miscSettings['voiceScriptId'];
    if(contentId) {
      this.externaldatasourceService.executeExternalDataSource(793, [Number(contentId)])
      .then(getVoiceScriptContentResult => {
        let hausnummer = '';
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount.hausnummer)) {
          hausnummer = this.applicationInfoService.currentAccount.hausnummer;
        }
        let hausnrzusatz = '';
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount.hausnrzusatz)) {
          hausnrzusatz = this.applicationInfoService.currentAccount.hausnrzusatz;
        }
        let city = '';
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount.city)) {
          city = this.applicationInfoService.currentAccount.city;
        }
        let zipcode = '';
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount.zipcode)) {
          zipcode = this.applicationInfoService.currentAccount.zipcode;
        }
        let street = '';
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount.street)) {
          street = this.applicationInfoService.currentAccount.street;
        }

        getVoiceScriptContentResult = getVoiceScriptContentResult.replace('<owner>', this.applicationInfoService.miscSettings['accountOwnerName']);
        getVoiceScriptContentResult = getVoiceScriptContentResult.replace('<hausnummer>', hausnummer);
        getVoiceScriptContentResult = getVoiceScriptContentResult.replace('<zusatz>', hausnrzusatz);
        getVoiceScriptContentResult = getVoiceScriptContentResult.replace('<city>', city);
        getVoiceScriptContentResult = getVoiceScriptContentResult.replace('<zipcode>', zipcode);
        getVoiceScriptContentResult = getVoiceScriptContentResult.replace('<street>', street);
        this.renderedValue = getVoiceScriptContentResult;
      });
    }      
  }

  getTemplates() {
    this.externaldatasourceService.executeExternalDataSource(794)
    .then(getTemplatesResult => {
      this.voiceScriptTemplates = getTemplatesResult;
    });
  }

  showWizard(voiceScriptTemplate) {
    this.wizardService.showWizard(WizardMode.VoiceTemplate, voiceScriptTemplate, '95vh', '95vh');
  }

  checkIfItemIsFiltered(voiceScriptTemplate) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(voiceScriptTemplate.defaultName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  toggleRecord() {
    if (this.ringcentralwebphoneService.recordingtypes == null || this.ringcentralwebphoneService.recordingtypes.length < 2) {
      this.ringcentralwebphoneService.toggleRecord();
    } else {  
      if (this.ringcentralwebphoneService.sessionState.record) {
        this.ringcentralwebphoneService.toggleRecord();
      } else {
        this.ringcentralwebphoneService.expandExtensionEvent.emit(null);
        setTimeout(() => {           
          this.ringcentralwebphoneService.showRecordOptionsEvent.emit(null);                    
        }, 200);  
      }
    }
  }
}
