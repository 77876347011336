<!-- <label class="adminEntity__header">{{ 'AdminEntity.Label.ManageEntities' | texttransform }}</label> -->
<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<div class="maxHeight" *ngIf="featureNotFound == false" >
    <div class="wizardDetailItem d-flex">
        <label class="noPadding wizardLabel">{{ 'AdminEntity.Label.Layer' | texttransform }}</label>
        <select [(ngModel)]="selectedEntity" class="visibleSelect" (change)="changeEntity()">
            <option *ngFor="let entity of entities" [ngValue]=entity>
                {{ commonService.getNameTranslationOrDefaultName(entity, 'entityName') | texttransform }}</option>
        </select>
        <app-wizard-translations *ngIf="selectedEntity && applicationInfoService.isDeveloper" 
            [exclude]="excludeEntity" 
            [array]="selectedEntity" 
            updateBaseArrayExternaldatasourceId=168 
            mode="3"
            projectSpecific=false
        ></app-wizard-translations>   
        <select [(ngModel)]="viewMode" class="visibleSelect" *ngIf="selectedEntity">
            <option ngValue="All">{{ 'AdminEntity.Label.ViewModeAll' | texttransform }}</option>
            <option ngValue="Specific">{{ 'AdminEntity.Label.ViewModeProjectSpecific' | texttransform }}</option>
            <option ngValue="Universal">{{ 'AdminEntity.Label.ViewModeUniversal' | texttransform }}</option>
        </select>
        <div class="adminEntity__header__search">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString">
        </div> 
    </div>
    <div class="everleadShadowBox entityMemberManagement">
        <div class="entityMemberManagement__create fingerCursor">
            <i class="fas fa-plus fingerCursor" (click)="createNewMember()" *ngIf="selectedEntity != null"></i>
            <span *ngIf="selectedEntity != null" class="fingerCursor" (click)="createNewMember()">{{ 'AdminEntity.Label.CreateNewMember' | texttransform }}</span>
            <span *ngIf="applicationInfoService.user.isExternalUser == false && selectedEntity != null" class="maxMemberInfo">
            ({{ getMaxEntityMemberText() }})
            </span>
        </div>    
        <div class="entityMemberManagement__refresh fingerCursor" (click)="refreshEntityCache()">
            <i class="fas fa-sync "></i>
            {{ 'AdminEntity.Label.RefreshDataEntityCache' | texttransform }}
        </div>
    </div>
    <div *ngIf="selectedEntity">    
        <label class="adminEntity__member__header">{{ 'AdminEntity.Label.Member' | texttransform }}</label>
        <span id="entityDesignSpan"></span>
        <div [style.max-height.px]="getControlHeight('entityDesignSpan', 30)" class="adminEntity__member__body">
            <div *ngFor="let member of selectedEntity.entityMembers" class="everleadShadowBox" [ngClass]="{
                hideInDom: checkIfMemberNeedsToBeHidden(member)
            }">
                <div class="adminEntity__member__row__column">
                    <div class="adminEntity__member__row__name">
                        <div class="maxWidth">
                            <span *ngIf="member.nameTranslationTokenId != null">
                                {{ axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId) | texttransform }} 
                            (</span>
                            <span>{{ member.defaultName }}</span>
                            <span *ngIf="member.nameTranslationTokenId != null">)</span>
                        </div>
                        <!-- <label class="adminEntity__member__sectionLabel">{{ 'EntityMemberDesign.Label.DefaultName' | texttransform }}</label> -->
                        <!-- <input type="text" disabled class="adminEntityDetailTextbox" [(ngModel)]="member.defaultName"> -->
                        <div class="everleadFlexCenter adminEntityIcon">
                            <i class="fas fa-font" *ngIf="member.memberType == 'TEXT'"></i>
                            <i class="fas fa-sort-numeric-up-alt" *ngIf="member.memberType == 'NUMERIC'"></i>
                            <i class="fas fa-calendar-alt" *ngIf="member.memberType == 'DATETIME'"></i>
                            <i class="fas fa-check-square" *ngIf="member.memberType == 'BOOL'"></i>
                        </div>
                        <div class="everleadFlexCenter adminEntityIcon">
                            <app-wizard-translations [array]="member"  [exclude]="translationExcludes" updateBaseArrayExternaldatasourceId=170 mode="3"></app-wizard-translations>    
                        </div>                    
                        <div class="everleadFlexCenter adminEntityIcon" *ngIf="checkIfMemberIsProjectSpecific(member) || 
                            applicationInfoService.isDeveloper">
                            <i class="fas fa-edit fa-lg fingerCursor" (click)="editMember(member)" ></i>
                        </div>
                    </div>
                    <!-- <div class="adminEntity__member__row__type" *ngIf="checkIfMemberIsProjectSpecific(member) || 
                        applicationInfoService.isDeveloper">
                        <label class="adminEntity__member__sectionLabel">{{ 'AdminEntity.Label.FieldType' | texttransform }}</label>
                        <select [(ngModel)]="member.memberType" disabled>
                            <option ngValue="TEXT">TEXT</option>
                            <option ngValue="NUMERIC">NUMERIC</option>
                            <option ngValue="DATETIME">DATETIME</option>
                            <option ngValue="BOOL">BOOL</option>
                        </select>
                    </div> -->
                    <div *ngIf="!checkIfMemberIsProjectSpecific(member)" class="adminEntity__member__isProjectSpecificInfo">
                        {{ 'AdminEntity.Label.ProjectSpecificMember' | texttransform }}
                    </div>
                </div>          
                
                <!-- <div class="adminEntity__member__row adminEntity__member__row__spacer" *ngIf="member.lookupTableSource != null">
                    <div class="adminEntity__member__row__datasource__left">
                        <label class="adminEntity__member__datasourcelabel">{{ 'AdminEntity.Label.DataSource' | texttransform }}</label>
                    </div>
                    <div class="adminEntity__member__row__datasource maxWidth">
                        <div class="adminEntity__member__row__type maxWidth">
                            <label class="adminEntity__member__sectionLabel">{{ 'AdminEntity.Label.LookupTableSource' | texttransform }}</label>
                            <select [(ngModel)]="member.lookupTableSource" class="adminEntityDetailTextbox" disabled
                                (change)="changeLookupTableSource(finalMember)">
                                <option ngValue=null></option>
                                <option ngValue="main">MAIN</option>
                                <option ngValue="data">DATA</option>
                            </select>    
                        </div>
                        <div class="adminEntity__member__row__type maxWidth">
                            <label class="adminEntity__member__sectionLabel" *ngIf="member.lookupTableSource != null">{{ 'AdminEntity.Label.LookupTableId' | texttransform }}</label>
                            <select [(ngModel)]="member.lookupTableId" class="adminEntityDetailTextbox" disabled
                                *ngIf="member.lookupTableSource != null" (change)="checkChangesLookupTable(member)">
                                <option ngValue=null></option>
                                <option ngValue=-1>{{ 'AdminEntity.Label.CreateNewTable' | texttransform }}</option>
                                <option [ngValue]="lookupTable.id" *ngFor="let lookupTable of lookupTableDefinitions[member.lookupTableSource]">
                                    {{ lookupTable.defaultName }}
                                </option>
                            </select>        
                        </div>    
                    </div>
                </div> -->

                <!-- <div class="adminEntity__member__row isDeveloper" *ngIf="applicationInfoService.isDeveloper">
                    <div class="adminEntity__member__row__name">
                        <label class="adminEntityDetailLabel">{{ 'AdminEntity.Label.ExternalDataSourceId' | texttransform }}</label>
                        <input type="number" [(ngModel)]="member.externalDataSourceId">    
                    </div>
                </div>     -->
            </div>    
        </div>

        <div class="adminEntity__footer fingerCursor" (click)="createNewMember()">
            {{ 'AdminEntity.Label.CreateNewMember' | texttransform }}
        </div>
    </div>
</div>