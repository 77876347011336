import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-datarights-info',
  templateUrl: './datarights-info.component.html',
  styleUrls: ['./datarights-info.component.scss']
})
export class DatarightsInfoComponent implements OnInit {
  infoResult = null;
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
    this.externaldatasourceService.executeExternalDataSource(602, [1, this.applicationInfoService.currentAccount.id])
    .then(getInfoResult => {
      this.infoResult = getInfoResult;
    });
  }
}
