import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qualitymanagement',
  templateUrl: './qualitymanagement.component.html',
  styleUrls: ['./qualitymanagement.component.scss']
})
export class QualitymanagementComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  currentQMTask = null;
  campaignText = '';
  stepText = '';
  userText = '';
  availableResults = [];
  approvalStates = [];
  questions = [];
  users = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private methodService: MethodService,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getCurrentQMTask();
    this.getResults();
    this.getApprovalStates();
    this.getQuestions();
    this.getUsers();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'qmLoaded') {
        this.getCurrentQMTask();
        this.getResults();
        this.getQuestions();
      }
    });
  }

  ngOnDestroy() {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getApprovalStates() {
    this.approvalStates = this.commonService.getProjectLookupTableValues(14, 'central');
  }

  getUsers() {
    this.users = this.applicationInfoService.projectUsers.toArray();
  }

  getResults() {
    this.availableResults = [];
    const step = this.applicationInfoService.steps.Item(this.currentQMTask.stepId);
    if (step) {
      this.availableResults = JSON.parse(JSON.stringify(step.results.filter(result => result.isActive == true)));
      this.availableResults.sort((a, b) => a.resultOrder < b.resultOrder ? -1 : 1);
    }
  }

  getQuestions(): Promise<any> {
    return new Promise((getQuestionsResolve, getQuestionsReject) => {
      this.questions = [];
      this.externaldatasourceService.executeExternalDataSource(152, [this.currentQMTask.tempResultId])
      .then(getQuestionsResult => {
        this.questions = getQuestionsResult;
        getQuestionsResolve(null);
      }).catch(error => { getQuestionsReject(error); });  
    });
  }

  resultChanged() {
    this.getQuestions().then(() => {
      this.eventService.customEvent.emit({ id: 'qmResultChanged' });
    });
  }

  getCurrentQMTask() {
    this.applicationInfoService.miscSettings['qmAnswers'] = null;
    this.applicationInfoService.miscSettings['qmAnswersText'] = [];
    this.applicationInfoService.miscSettings['qmCheckedAnswers'] = [];
    this.currentQMTask = this.applicationInfoService.entities.Item('21').data;
    const campaign = this.applicationInfoService.campaigns.Item(this.currentQMTask.campaignId);
    if (campaign) {
      this.campaignText = campaign.defaultName;
    }

    const step = this.applicationInfoService.steps.Item(this.currentQMTask.stepId);
    if (step) {
      this.stepText = step.defaultName;
    }

    const user = this.applicationInfoService.projectUsers.Item(this.currentQMTask.tempResultUserId);
    if (user) {
      this.userText = user.userName;
    }
  }

  isConfirmDisabled() {
    let returnValue = true;
    if (this.currentQMTask.qmStateLookupId == 39) { returnValue = false; }
    if (this.currentQMTask.qmStateLookupId == 40 && this.currentQMTask.qmRejectedText != null && this.currentQMTask.qmRejectedText != '') { 
      returnValue = false; 
    }
    return returnValue;
  }

  saveTask(): Promise<any> {
    return new Promise((saveTaskResolve, saveTaskReject) => {
      this.externaldatasourceService.executeExternalDataSource(418, [
        this.commonService.getModifyArrayBody(this.currentQMTask, ['questionAnswers', 'account', 'contact'])
      ]).then(() => { saveTaskResolve(null);
      }).catch(error => { saveTaskReject(error); })     
    });
  }

  qmTask(): Promise<any> {
    let questionAnswers = null;
    if (this.currentQMTask.qmStateLookupId == 39) {      
        questionAnswers = this.applicationInfoService.miscSettings['qmAnswers'];
        let answerString = '[';
        const qmTexts = this.applicationInfoService.miscSettings['qmAnswersText'];

        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['qmAnswers'])) {
          this.applicationInfoService.miscSettings['qmAnswers'].forEach(answer => {
            if (answerString != '[') { answerString = answerString.concat(', '); }
            if (this.commonService.isNullOrUndefined(qmTexts[answer.answerId])) {
              answerString = answerString.concat('{ answerId: <0> }'.replace('<0>', answer.answerId));
            } else {
              answerString = answerString.concat('{ answerId: <0>, answerText: "<1>" }'
                .replace('<0>', answer.answerId)
                .replace('<1>', qmTexts[answer.answerId])
              );
            }          
          });    
        }

        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['qmCheckedAnswers'])) {
          this.applicationInfoService.miscSettings['qmCheckedAnswers'].forEach(answer => {
            if (answerString != '[') { answerString = answerString.concat(', '); }
            answerString = answerString.concat('{ answerId: <0> }'.replace('<0>', answer));
          });
        }

        answerString = answerString.concat(']');
        questionAnswers = answerString;        
    }


    const qmObject = {
      taskId: this.currentQMTask.id,
      qmStateLookupId: this.currentQMTask.qmStateLookupId,
      rejectedText: this.commonService.cleanStringValue(this.currentQMTask.qmRejectedText),
      followUpDate: this.currentQMTask.followUpDate
    }
    const qmId = this.currentQMTask.id;
    return new Promise((bookTaskResolve, bookTaskReject) => {
      this.externaldatasourceService.executeExternalDataSource(832, 
        [this.commonService.getModifyArrayBody(qmObject , []), questionAnswers]
      ).then(() => { 
        bookTaskResolve(null);
        this.eventService.compactListRemoveItemFromArray.emit(new JJEvent('6312', [qmId]));
        this.eventService.removeQMItemFromAccountFormArrayEvent.emit(qmId);        
      }).catch(error => { bookTaskReject(error); });
    });
  }

  confirmQM() {
    this.loaderService.display(true);
    this.saveTask().then(() => {
      this.qmTask().then(() => { this.loaderService.display(false); 
      }).catch(() => { this.loaderService.display(false); });
    }).catch(() => { this.loaderService.display(false); });
  }
}
