import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { ConfirmationService } from 'primeng/api';
import { WizardMode, WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-emailcontent',
  templateUrl: './emailcontent.component.html',
  styleUrls: ['./emailcontent.component.scss']
})
export class EmailcontentComponent implements OnInit {
  viewMode = 1;
  emailContents;
  viewModeToolbar = 1;
  innerHTML = null;
  selectedContent = null;
  selectedEntity = null;
  entities = [];
  caretPos = 0;
  placeholders = [];
  filterString = '';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    public uiService: UiService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private emailtemplateService: EmailtemplateService,
    public userService: UserService,
    private wizardService: WizardService
  ) { }

  ngOnInit(): void {
    this.selectedContent = new Object();
    this.getEMailContents();
    this.getEntities();
    this.getPlaceHolders();

    this.wizardService.wizardFinishedEvent.subscribe(() => {
      this.getEMailContents();
    });
  }

  getEntities() {
    this.entities = [];
    this.entities.push(this.applicationInfoService.entities.items[1]);
    this.entities.push(this.applicationInfoService.entities.items[4]);
    this.selectedEntity = this.applicationInfoService.entities.items[1];
  }

  getEMailContents(templateId = null) {
    if (this.userService.hasPermission('CanReadEmailTemplateContent')) {
      this.emailtemplateService.getEMailContents()
      .then(getEMailContentsResult => {
        this.emailContents = getEMailContentsResult;
        if (this.emailContents.length > 0) {
          if (templateId == null) {
            this.selectedContent = this.emailContents[0];
          } else {
            this.emailContents.forEach(item => {
              if (item.id == templateId) {
                this.selectedContent = item;
              }
            });
          }
        }
      });
    }
  }

  saveTemplateContent(selectedContent = null) {
    if (selectedContent != null) {
      this.selectedContent = selectedContent;
    }
    this.externaldatasourceService.executeExternalDataSource(439, [
      this.commonService.getModifyArrayBody(this.selectedContent, ['lastModifiedDate'])
    ])
    .then(saveTemplateContentResult => {
      this.selectedContent.lastModifiedDate = saveTemplateContentResult.lastModifiedDate;
      this.messagingService.showDefaultSuccess('', 'EMailContent.SaveSuccess.Message');

    }).catch(() => {  });
  }

  renderContent() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.emailtemplateService.renderEMail(this.selectedContent.content, this.emailContents));
  }

  setViewMode(value) {
    this.renderContent();
    this.viewMode = Number(value);
  }

  setViewModeToolbar(value) {
    this.viewModeToolbar = Number(value);
  }

  addMemberToTemplate(placeholder) {
    // const memberName = ''.concat('{{', this.selectedEntity.entityName, '.', member.defaultName, '}}');
    this.selectedContent.content = ''.concat(
      this.selectedContent.content.substring(0, this.caretPos),
      placeholder,
      this.selectedContent.content.substring(this.caretPos, this.selectedContent.content.length),
      ' '
    );
    this.caretPos = this.caretPos + placeholder.length + 1;
  }

  getMyHeight() {
    return Number(this.uiService.getDesignSpanPosition('emailContentEditContainer', 40));
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
       this.caretPos = oField.selectionStart;
    }
  }

  publishTemplateContent() {
    this.confirmationService.confirm({
      message: 'EMailContent.Publish.Message',
      header: 'Publish',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedContent.isPublished = true;
        this.saveTemplateContent();
      }
    })
  }

  createNewContent() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(438, [])
    .then(result => {
      this.wizardService.showWizard(WizardMode.EMailContent, result)
      this.getEMailContents(result.id);
      this.loaderService.display(false);
    })
    .catch(() => { this.loaderService.display(false); });
  }

  cloneContent() {
    this.confirmationService.confirm({
      message: 'EMailContent.CloneQuestion.Message',
      header: '',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        this.externaldatasourceService.executeExternalDataSource(444, [this.selectedContent.id])
        .then(result => {
          this.messagingService.showDefaultSuccess('', 'EMailContent.CloneSuccess.Message')
          this.getEMailContents(result.id);
          this.loaderService.display(false);
        })
        .catch(() => { this.loaderService.display(false); });
      }
    })
  }


  getPlaceHolders() {
    this.externaldatasourceService.executeExternalDataSource(446, [])
    .then(getPlaceHoldersResult => { this.placeholders = getPlaceHoldersResult; })
  }

  showWizard(emailContent) {
    if (this.userService.hasPermission('CanUpdateEmailTemplateContent')) {
      this.wizardService.showWizard(WizardMode.EMailContent, emailContent);
    }
  }

  checkIfItemIsFiltered(emailContent) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(emailContent.defaultName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }
}
