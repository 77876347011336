// import { GrowlModule, ConfirmDialogModule } from 'primeng/primeng';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule} from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { LoggerService } from './logger.service';
import { HttpErrorInterceptor } from './http-error-interceptor';

import { AuthService } from './authentication/services/auth.service';
import { UserService } from './authentication/services/user.service';

import { AuthGuard } from './authentication/guards/auth.guard';
import { ActivityReadPermissionGuard } from './authentication/guards/ActivityReadPermission.guard';

import { CustomErrorHandler } from './custom-error-handler';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ApplicationdownComponent } from './applicationdown/applicationdown.component';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    ToastModule,
  ],
  exports: [
    PageNotFoundComponent,
    UnauthorizedComponent,
    NotificationsComponent
  ],
  declarations: [
    PageNotFoundComponent,
    UnauthorizedComponent,
    NotificationsComponent,
    ApplicationdownComponent,
    BrowserNotSupportedComponent,
],
  providers: [
    MessageService,
    LoggerService,
    AuthService,
    UserService,
    AuthGuard,
    ActivityReadPermissionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ]
})
export class CoreModule { }
