<div  class="infoPanel maxWidth" 
      [ngClass]="{ 
        showError: severity == 'error', 
        showWarning: severity == 'warning',
        showInfo: severity == 'info'
      }"
      [ngStyle]="setControlStyle('control')" 
  >
  <div class="maxWidth" *ngFor="let item of items">
      <i class="fas fa-info-circle"></i>{{ item }}
  </div>      
</div>