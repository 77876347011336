<div class="uiControlEditor__onlycontainer" *ngIf="!applicationInfoService.isDeveloper">
    <app-uicontainer-design [currentControlToModify]=currentControlToModify 
        *ngIf="currentControlToModify.controlType == 0 || currentControlToModify.controlType == null">
    </app-uicontainer-design>
</div>

<div class="designMain__wrapper" *ngIf="applicationInfoService.isDeveloper">
    <div class="jjTab designMain__tabs" *ngIf="!applicationInfoService?.isMobile()">
        <button [ngClass]="{ 
            jjActivetab: child.id == selectedTabIndex,
            jjControlDefaultColor: child.id == selectedTabIndex,
            jjInactivetab: child.id != selectedTabIndex
          }" *ngFor="let child of visibleTabs; let currentIndex = index" class="tablinks"
            (click)="setCurrentChild(child.id)">
            {{ child.displayText | texttransform }}
            <span *ngIf="child.id == 2"> ({{ currentControlToModify.childs?.length }})</span>
            <span *ngIf="child.id == 3"> ({{ currentControlToModify.methods?.length }})</span>
            <span *ngIf="child.id == 4"> ({{ currentControlToModify.uiControlExternalDataSources?.length }})</span>
            <span *ngIf="child.id == 5"> ({{ currentControlToModify.attributeModifiers?.length }})</span>
            <span *ngIf="child.id == 6"> ({{ currentControlToModify.uiControlSecurityPermissions?.length }})</span>
        </button>
    </div>

    <div *ngIf="applicationInfoService?.isMobile()">
        <select style="width: 100%;" [(ngModel)]="selectedTabIndex">
            <option [ngValue]="child.id" *ngFor="let child of visibleTabs">
                {{ child.displayText | texttransform }}
            </option>
        </select>
    </div>

    <div style="overflow-y: auto; overflow-x: hidden; height: 100%;">
        <!-- Tab content -->
        <div class="mainContainer noScrollbar maxHeight">
            <div *ngIf="selectedTabIndex == 0">
                <app-control-design-admin [controlToModify]=currentControlToModify></app-control-design-admin>
            </div>

            <div *ngIf="selectedTabIndex == 1" class="maxHeight">
                <app-uicontrol-design [currentControlToModify]=currentControlToModify [lookupTable]=lookupTable
                    *ngIf="currentControlToModify.controlType != 0">
                </app-uicontrol-design>
                <app-uicontainer-design [currentControlToModify]=currentControlToModify
                    *ngIf="currentControlToModify.controlType == 0 || currentControlToModify.controlType == null">
                </app-uicontainer-design>
            </div>

            <div *ngIf="selectedTabIndex == 2">
                <div class="projectSettings__header">
                    <div class="projectSettings__header__newItem">
                        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                            (click)="addNewSubControl()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
                        {{ 'Designer.SubControls.CreateNew' | texttransform }}
                    </div>
                    <div class="projectSettings__header__interaction"></div>
                </div>                
                <app-uicontrol-subcontrol [entityList]="entityList" [childControl]="child"
                    [parentControl]="currentControlToModify" *ngFor="let child of currentControlToModify.childs">
                </app-uicontrol-subcontrol>
            </div>

            <div *ngIf="selectedTabIndex == 3">
                <app-uicontrol-design-methods [currentControlToModify]=currentControlToModify>
                </app-uicontrol-design-methods>
            </div>

            <div *ngIf="selectedTabIndex == 4">
                <div class="projectSettings__header">
                    <div class="projectSettings__header__newItem">
                        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                            (click)="addNewExternalDataSource(selectedExternalDataSource)" class="fingerCursor" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
                        {{ 'Designer.Label.NewAttributeModifier' | texttransform }}
                    </div>
                    <div class="projectSettings__header__interaction">
                        <select [(ngModel)]="selectedExternalDataSource" class="maxWidth">
                            <option *ngFor="let externalDataSource of externalDatasourcesList" [tippy]="externalDataSource.dataQuery"
                                [ngValue]="externalDataSource.id" class="externalDataSourceOption">
                                <label class="ellipsisText" style="max-width: 200px;">{{
                                    externalDataSource.id }} - {{ getShortenedQuery(externalDataSource.dataQuery) }}</label>
                            </option>
                        </select>
                    </div>
                </div>
            
                <app-control-design-externaldatasource [externalDataSource]="externalDataSource" [parent]="currentControlToModify"
                    *ngFor="let externalDataSource of currentControlToModify.uiControlExternalDataSources">
                </app-control-design-externaldatasource>
            </div>

            <div *ngIf="selectedTabIndex == 5">
                <app-uicontrol-design-attributemodifier [currentControlToModify]="currentControlToModify"></app-uicontrol-design-attributemodifier>
            </div>

            <div *ngIf="selectedTabIndex == 6">
                <app-ui-control-design-security-permission [control]=currentControlToModify></app-ui-control-design-security-permission>
            </div>

            <div *ngIf="selectedTabIndex == 7">
                <app-contols-design-settings [control]=currentControlToModify></app-contols-design-settings>
            </div>
            
            <div *ngIf="selectedTabIndex == 99" class="everleadShadowBox">
                {{ currentControlToModify | json }}
            </div>
        </div>
    </div>
</div>