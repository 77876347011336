<div>
  <div class="dataFilter__member__infoLine" [ngClass]="{ dateRow: filterSegmentMember.lookupFilterOperatorId == 21 }">
    <div title="{{ filterSegmentMember?.entity?.entityName | texttransform }} - {{ filterSegmentMember?.entityMember?.defaultName | texttransform }}"
      class="dataFilter__member__infoLine__largeInfo dataFilter__member__infoLine__largeInfo__memberSection" *ngIf="filterSegmentMember">
      <div class="dataFilter__member__infoLine__largeInfo__subItem">
        <!-- Entity -->
        <select class="" [(ngModel)]="filterSegmentMember.entityId" (change)="changeEntity()" [disabled]="disabled">
          <option *ngFor="let entity of memberEntities" [ngValue]="entity.uiEntityInstance">
            <ng-container *ngIf="!entity.nameTranslationToken">{{ entity.entityName | texttransform }}</ng-container>
            <ng-container *ngIf="entity.nameTranslationToken">{{ entity.nameTranslationToken.tokenFullName | texttransform }}</ng-container>
          </option>
        </select>
        <i class="fas fa-arrow-right"></i>
        <!-- Member -->
        <select class="" [(ngModel)]="filterSegmentMember.memberId" (change)="memberChanged(filterSegmentMember)" [disabled]="disabled">
          <option *ngFor="let member of getMembers()" [ngValue]="member.id">
            <ng-container *ngIf="!member.nameTranslationToken">{{ member.defaultName | texttransform }}</ng-container>
            <ng-container *ngIf="member.nameTranslationToken">{{ member.nameTranslationToken.tokenFullName | texttransform }}</ng-container>
          </option>
        </select>
      </div>   
    </div>

    <!-- Operator -->
    <div class="dataFilter__member__infoLine__operator" title="{{ filterSegmentMember?.lookupFilterOperator?.lookupTable.defaultName | texttransform }}">
      <select [(ngModel)]="filterSegmentMember.lookupFilterOperatorId" (change)="changeOperator()" [disabled]="disabled" class="everleadCoral">
        <option *ngFor="let operator of operators" [ngClass]="{ hideInDom: invalidOperatorForMember(filterSegmentMember, operator) }"
          [ngValue]="operator.lookupTable.id">{{ operator.lookupTable.defaultName }}</option>
      </select>      
    </div>

    <!-- lookUpTable -->
    <div *ngIf="applicationInfoService.entityMember.Item(filterSegmentMember.memberId)?.lookupTableId != null"                 
      class="dataFilter__member__infoLine__largeInfo">
      <div class="dataFilter__member__infoLine__largeInfo__segment1">
        <select [(ngModel)]="filterSegmentMember.filterParameter" class="maxWidth" (change)="saveSegmemtMember()">
          <option [ngValue]="null"></option>
          <option *ngFor="let lookupTableItem of getLookupTableContent(filterSegmentMember)" [ngValue]="toString(lookupTableItem.id)">
              {{ commonService.getNameTranslationOrDefaultName(lookupTableItem) | texttransform }}
          </option>
        </select>  
      </div>
    </div>

    <!-- TEXT / NUMERIC -->
    <div *ngIf="(applicationInfoService.entityMember.Item(filterSegmentMember.memberId)?.memberType == 'TEXT' || 
                 applicationInfoService.entityMember.Item(filterSegmentMember.memberId)?.memberType == 'NUMERIC')
              && applicationInfoService.entityMember.Item(filterSegmentMember.memberId)?.lookupTableId == null" 
      class="dataFilter__member__infoLine__largeInfo">
      <div class="dataFilter__member__infoLine__largeInfo__segment1">
        <input [(ngModel)]="filterSegmentMember.filterParameter" *ngIf="
          filterSegmentMember.lookupFilterOperatorId == 18 ||
          filterSegmentMember.lookupFilterOperatorId == 32 ||
          filterSegmentMember.lookupFilterOperatorId == 22 ||
          filterSegmentMember.lookupFilterOperatorId == 23 ||
          filterSegmentMember.lookupFilterOperatorId == 105
        " (change)="saveSegmemtMember()">
      </div>
      
      <div class="dataFilter__member__infoLine__largeInfo__segment2" *ngIf="filterSegmentMember.lookupFilterOperatorId == 23">
        <input [(ngModel)]="filterSegmentMember.filterParameter2" (change)="saveSegmemtMember()">
      </div>  
    </div>

    <div *ngIf="applicationInfoService.entityMember.Item(filterSegmentMember.memberId)?.memberType == 'DATETIME'" class="dateControlSection__wrapper">
      <div *ngIf="filterSegmentMember.lookupFilterOperatorId == 21" class="dateControlSection">
        <input [id]="getMyId('dateTo', filterSegmentMember)" [(ngModel)]="filterSegmentMember.filterParameter2" [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly (dateTimeChange)="saveSegmemtMember()" class="noSize">
        <input [id]="getMyId('dateFrom', filterSegmentMember)" [(ngModel)]="filterSegmentMember.filterParameter" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly (dateTimeChange)="saveSegmemtMember()" class="noSize">        
          <div>
            <label>{{ filterSegmentMember.filterParameter | date:'medium' }}</label> 
            <i class="far fa-clock fingerCursor" (click)="callClickElement('dateFrom')"></i>  
          </div>
          <div>
            <label>{{ filterSegmentMember.filterParameter2 | date:'medium' }}</label>
            <i class="far fa-clock fingerCursor" (click)="callClickElement('dateTo')"></i>  
          </div>
      </div>
    </div>

    <div *ngIf="applicationInfoService.entityMember.Item(filterSegmentMember.memberId)?.memberType == 'BOOL' " class="dataFilter__member__infoLine__largeInfo">
      <div *ngIf="filterSegmentMember.lookupFilterOperatorId == 32 || filterSegmentMember.lookupFilterOperatorId == 105" 
        (click)="changeBoolValue(filterSegmentMember, 'filterParameter')">
          <i class="far fa-check-square" *ngIf="filterSegmentMember.filterParameter == 'true'"></i>
          <i class="far fa-square" *ngIf="filterSegmentMember.filterParameter == 'false'"></i>
      </div>
    </div>
    
    <div *ngIf="filterSegmentMember.memberId == mailshotFakeMember.id" class="maxWidth">
      <div class="dataFilter__member__infoLine__largeInfo mailShotOption">
        <label>Mailshot:</label>
        <select [(ngModel)]="filterSegmentMember.filterParameter" (change)="saveSegmemtMember()">
          <option *ngFor="let emailBatch of emailBatches" [ngValue]="emailBatch.id.toString()">{{ emailBatch.batchName | texttransform }}</option>
        </select>
      </div>
      <div class="dataFilter__member__infoLine__largeInfo mailShotOption">
        <label>Status:</label>
        <select [(ngModel)]="filterSegmentMember.filterParameter2" (change)="saveSegmemtMember()">
          <option *ngFor="let mailShotState of mailShotStates" [ngValue]="mailShotState.id">{{ mailShotState.displayName | texttransform }}</option>
        </select>
      </div>
    </div>

    <div class="dataFilter__member__infoLine__buttons" *ngIf="!disabled">
      <button class="dataFilter__member__infoLine__button" (click)="deleteMember()"><i class="fas fa-trash everleadCoral"></i></button>  
    </div>
  </div>  
</div>

<owl-date-time #dt7></owl-date-time>
<owl-date-time #dt8></owl-date-time>

