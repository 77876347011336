<!-- <app-custom-container [controluiid]=127046></app-custom-container> -->
<div *ngIf="userService.hasPermission( 'CanReadEvent')" class="eventWrapper">
    <div class="eventHeader">
        {{ 'Eventlist.Label.Header' | texttransform }}
    </div>
    <div class="eventList">
        <div class="everleadShadowBox" *ngFor="let event of events">
            <div class="event">
                <div class="event__type">{{ event.type }}</div>
                <div class="event__url">{{ event.url }}</div>
                <div class="event__count">{{ event.count }}</div>
                <div class="event__edit">
                    <i class="fas fa-edit fingerCursor" (click)="showTriggerWizard(event)" *ngIf="eventTriggerAvailable(event)"></i>
                    <i class="fas fa-plus fingerCursor" (click)="showTriggerWizard(event)" *ngIf="!eventTriggerAvailable(event)"></i>
                </div>
            </div>
        </div>
    </div>
</div>