<!-- RingCentral -->
<div *ngIf="applicationInfoService.applicationSettings['showFirstStep'] == 'true'" class="bannerInner" [ngClass]="{
        bannerInner__maximized: applicationInfoService.mainViewMaximixed
    }">
    <button (click)="applicationInfoService.showBanner = true;" class="everleadCustomButton">{{ 'DemoBanner.Label.VisitWebsite' | texttransform }}</button>
</div>

<div *ngIf="(!isExternalUser() && applicationInfoService.applicationSettings['showSoftphone'] == 'true') || applicationInfoService.isDeveloper">
<div class="softPhoneWrapper" *ngIf="applicationInfoService.phoneConnection == applicationInfoService.phoneRingCentral">
    <div *ngIf="!ringCentralAuthorized()" (click)="ringcentralService.callRingCentralAhtentification();"
        class="fingerCursor">
        <i class="fas fa-sign-in-alt ringCentralLoginButton"></i>
        <label> Unify Office: {{ 'RingCentral.Label.LoginRequired' | texttransform }}</label>
    </div>
    <div *ngIf="ringCentralAuthorized()">
        <div class="softPhoneHeader">
                <div (click)="getInfos()" class="softPhoneHeader__topLine">
                    <i class="fas fa-external-link-alt webPhoneStatusIcon fingerCursor" (click)="callRingCentralPopup()"></i>
                    <i class="fas fa-info-circle webPhoneStatusIcon" [title]="ringcentralService?.userInfo?.directNumber"></i>
                    <select [(ngModel)]="applicationInfoService.currentTimeRecordingStatus"
                        (change)="ringcentralService.bookActivity()" class="softPhoneStatusDropdown" style="border: 0;"
                        title="Status">
               
                        <option *ngFor="let activity of ringcentralService.activities" [ngValue]="activity"
                            class="softPhoneStatusDropdown__option">
                            <span>{{ activity.translationToken | texttransform }}</span>
                            {{ activity.translationTokenName | texttransform }}
                        </option>
                    </select>
          
                    <i class="fas fa-phone softPhoneDropdownIcon ringCentralConnectIcon fingerCursor" (click)="ringcentralService.answerCall()"
                        *ngIf="ringcentralService?.phoneConnectionStatus == 'Proceeding' && ringcentralService?.direction == 'Inbound'"></i>
                    
                    <i class="fas fa-phone-slash softPhoneDropdownIcon everleadCoral fingerCursor" (click)="ringcentralService.dropCall()"
                        *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'"></i>
                    
                    <!-- <i class="fas fa-cog softPhoneDropdownIcon fingerCursor" (click)="openIncomingCall()"
                        *ngIf="applicationInfoService.applicationSettings['showIncomingCall']== 'true'"></i> -->
                </div>
         
        </div>
        <div>
            <span *ngIf="applicationInfoService.webPhoneConnected == false && ringcentralService.currentlyReconnecting == false 
                || applicationInfoService.webPhoneConnected && ringcentralService?.hubConnection?.state != 1">
                <i class="ajandoHeaderPicture fas fa-phone-slash ajandoHeaderBox__smallImage everleadCoral" ></i>
                <label class="webPhoneCallLabel" (click)="ringcentralService.initConnection(true)">{{ 'RingCentral.Label.ResetConnection' | texttransform }}</label>
            </span>
            <span *ngIf="applicationInfoService.webPhoneConnected == false && ringcentralService.currentlyReconnecting == true ||
            ringcentralService.currentlyReconnecting == true && ringcentralService?.hubConnection?.state != 1">
                <label class="webPhoneCallLabel">{{ 'RingCentral.Label.Reconnect' | texttransform }}</label>
            </span>
        </div>
  
        <div class="softPhoneCallButtons"
            *ngIf="this.applicationInfoService.webPhoneConnected == true && ringcentralService?.hubConnection?.state == 1">
     
            <div class="row" style="width: 100%; height: 20px;">
                <div class="col-6">
                    <label class="softPhoneCallLabel" *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'">{{ 'SoftPhone.Label.OtherPartner' | texttransform }}</label>
                </div>
                <div class="col-6">
                    <label class="softPhoneCallLabel" [title]="ringcentralService?.numberTo"><span
                            *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'">
                            {{ connectionTime }} {{ ringcentralService?.numberTo }}</span>
                    </label>
                </div>
            </div>

            <div *ngIf="expanded" class="softPhoneAdditionalBar">
                <div class="row" style="width: 100%;">
                    <div class="col-12">
                        <div class="row" style="width: 100%;">
                            <div class="col-md-2">
                                <button class="softPhoneButton"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected' && ringcentralService?.telephonyPartyStatus != 'Hold'"
                                    title="{{ 'Webphone.Tooltip.Hold' | texttransform }}"
                                    (click)="ringcentralService.holdCall()"><i class="fas fa-pause"></i></button>
                                <button class="softPhoneButton webPhoneShowActive"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected' && ringcentralService?.telephonyPartyStatus == 'Hold'"
                                    title="{{ 'Webphone.Tooltip.Resume' | texttransform }}"
                                    (click)="ringcentralService.unholdCall()"><i
                                        class="fas fa-pause activeWebphoneButton"></i></button>
                            </div>
                            <div class="col-md-2">
                                <button class="softPhoneButton"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected' && ringcentralService?.recordingId == null"
                                    title="{{ 'Webphone.Tooltip.Record' | texttransform }}"
                                    (click)="ringcentralService.startRecording()"><i class="fas fa-circle"></i></button>

                                <button class="softPhoneButton webPhoneShowActive"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected' && ringcentralService?.recordingId != null"
                                    title="{{ 'Webphone.Tooltip.StopRecord' | texttransform }}"
                                    (click)="ringcentralService.toggleRecording()"><i class="fas fa-circle" [ngClass]=" { 
                                    activeWebphoneButton: ringcentralService?.resumeRecording == false
                                }"></i></button>
                            </div>

                            <div class="col-md-2">
                                <!-- Consult -->
                                <button class="softPhoneButton"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'"
                                    title="{{ 'Webphone.Tooltip.Consult' | texttransform }}"
                                    (click)="ringcentralService.initConsult()"><i class="fas fa-user-plus"></i></button>
                            </div>
                            <div class="col-md-2">
                                <!-- Transfer -->
                                <button class="softPhoneButton"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'"
                                    title="{{ 'Webphone.Tooltip.Transfer' | texttransform }}"
                                    (click)="ringcentralService?.initTransfer()"><i
                                        class="fas fa-user-plus"></i></button>
                            </div>
                            <div class="col-md-2">
                                <!-- Conference -->
                                <button class="softPhoneButton"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'"
                                    title="{{ 'Webphone.Tooltip.Conference' | texttransform }}"
                                    (click)="ringcentralService?.initConference()"><i class="fas fa-users"></i></button>
                            </div>
                            <div class="col-md-2">
                                <!-- Mute -->
                                <button class="softPhoneButton"
                                    *ngIf="ringcentralService?.phoneConnectionStatus != 'Disconnected'"
                                    title="{{ 'Webphone.Tooltip.Mute' | texttransform }}"
                                    (click)="ringcentralService.muteCall()"><i
                                        class="fas fa-microphone-slash"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>















<!-- Old softphone -->
<div class="softPhoneWrapper" *ngIf="applicationInfoService.phoneConnection == applicationInfoService.phone3cx">
    <div class="softPhoneHeader">
        <h2>
            <i class="fas fa-phone-alt webPhoneStatusIcon" (click)="callDialing()"></i>
            {{ 'Task.Label.Softphone' | texttransform }}
        </h2>
        <div class="softPhoneHeader__Icons">
            <i class="fas fa-phone softPhoneHeader__Icons__dropdownIcon softphoneConnectIcon fingerCursor"
                (click)="pickupcall()" *ngIf="middlewareSignalrService?.connectionStatus?.canAnswer"></i>
            <i class="fas fa-phone-slash softPhoneHeader__Icons__dropdownIcon softphoneHangupIcon fingerCursor"
                (click)="hangup()" *ngIf="middlewareSignalrService?.connectionStatus?.canDisconnect"></i>
            <!-- <i class="fas fa-phone-slash softPhoneHeader__Icons__dropdownIcon softphoneDialIcon" (click)="hangup()" style="padding: 0 35px 0 0;"></i> -->
            <i class="softPhoneHeader__Icons__dropdownIcon fingerCursor fas" [ngClass]="{
                'fa-chevron-down': expanded != true,
                'fa-chevron-up': expanded == true
            }" (click)="toggle()"></i>
        </div>
    </div>
    <div>
        <span
            *ngIf="applicationInfoService.webPhoneConnected == false && middlewareSignalrService.currentlyReconnecting == false">
            <i class="ajandoHeaderPicture fas fa-phone-slash ajandoHeaderBox__smallImage everleadCoral"></i>
            <label class="webPhoneCallLabel" (click)="middlewareSignalrService.reconnectSoftphoneApi()">Verbindung
                wiederherstellen</label>
        </span>
        <span
            *ngIf="applicationInfoService.webPhoneConnected == false && middlewareSignalrService.currentlyReconnecting == true">
            <label class="webPhoneCallLabel">Reconnecting...</label>
        </span>
        <span *ngIf="this.applicationInfoService.webPhoneConnected == true">
            <select [(ngModel)]="applicationInfoService.currentTimeRecordingStatus"
                (change)="middlewareSignalrService.bookActivity()" class="softPhoneStatusDropdown" style="border: 0;"
                title="Status">
                <!-- <option ngValue=null class="softPhoneStatusDropdown__option">No status</option> -->
                <option *ngFor="let activity of middlewareSignalrService.activities" [ngValue]="activity"
                    class="softPhoneStatusDropdown__option">
                    <span>{{ activity.translationToken | texttransform }}</span>
                    {{ activity.translationTokenName | texttransform }}
                </option>
            </select>
        </span>
        <div class="softPhoneCallButtons" *ngIf="this.applicationInfoService.webPhoneConnected == true">
            <div class="row">
                <div class="col-7">
                    <label class="softPhoneCallLabel">{{ 'SoftPhone.Label.OwnNumber' | texttransform }}</label>
                </div>
                <div class="col-5">
                    <label class="softPhoneCallLabel">{{ middlewareSignalrService?.connectionStatus?.ownDisplayedNumber
                        }}
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <label class="softPhoneCallLabel">{{ 'SoftPhone.Label.OtherPartner' | texttransform }}</label>
                </div>
                <div class="col-5">
                    <label class="softPhoneCallLabel"><span *ngIf="
                        middlewareSignalrService?.connectionStatus?.connectionStatus == 'Dialing'
                        || middlewareSignalrService?.connectionStatus?.connectionStatus == 'RingingOutbound'
                        || middlewareSignalrService?.connectionStatus?.connectionStatus == 'Connected'">{{
                            connectionTime }} - </span>{{ middlewareSignalrService?.connectionStatus?.otherParty }} {{
                        middlewareSignalrService?.connectionStatus?.otherPartyName }}
                    </label>
                </div>
            </div>

            <!-- Dial Buttons -->
            <div *ngIf="expanded" class="softPhoneAdditionalBar">
                <div class="row" style="width: 100%;">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('1')">1</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('2')">2</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('3')">3</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('4')">4</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('5')">5</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('6')">6</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('7')">7</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('8')">8</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('9')">9</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('*')">*</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('0')">0</button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    (click)="middlewareSignalrService.sendKey('#')">#</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row" style="width: 100%;">
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canHold"
                                    title="{{ 'Webphone.Tooltip.Hold' | texttransform }}"
                                    (click)="softphoneEvent('hold', 'canHold')"
                                    [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i class="fas fa-pause"></i></button>
                                <button class="softPhoneButton webPhoneShowActive"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canResume"
                                    title="{{ 'Webphone.Tooltip.Resume' | texttransform }}"
                                    (click)="softphoneEvent('resume', 'canResume')"
                                    [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i class="fas fa-pause"></i></button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canRecord"
                                    title="{{ 'Webphone.Tooltip.Record' | texttransform }}"
                                    (click)="softphoneEvent('startrecord', 'canRecord')" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                    webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-circle"></i></button>

                                <button class="softPhoneButton webPhoneShowActive"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canStopRecord"
                                    title="{{ 'Webphone.Tooltip.StopRecord' | texttransform }}"
                                    (click)="softphoneEvent('stoprecord', 'canStopRecord')" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                    webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-circle"></i></button>


                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canStopRecord"
                                    title="{{ 'Webphone.Tooltip.DiscardRecord' | texttransform }}"
                                    (click)="softphoneEvent('discardrecord', 'canStopRecord')" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                    webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-trash"></i></button>
                            </div>
                            <div class="col-md-4">
                                <!-- Consult -->
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canInitConsult"
                                    title="{{ 'Webphone.Tooltip.Consult' | texttransform }}"
                                    (click)="softphoneEvent('consult', 'canInitConsult')"><i
                                        class="fas fa-user-plus"></i></button>
                            </div>
                        </div>

                        <div class="row" style="width: 100%;">
                            <div class="col-md-4">
                                <!-- Transfer -->
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canTransferCall"
                                    title="{{ 'Webphone.Tooltip.Transfer' | texttransform }}"
                                    (click)="middlewareSignalrService.transfer()" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                    webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-user-plus"></i></button>
                            </div>
                            <div class="col-md-4">
                                <!-- Conference -->
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canInitConference"
                                    title="{{ 'Webphone.Tooltip.Conference' | texttransform }}"
                                    (click)="softphoneEvent('conference', 'canInitConference')" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                    webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-users"></i></button>
                            </div>
                            <div class="col-md-4">

                            </div>
                        </div>

                        <!-- Hardphone Settings -->
                        <div class="row" style="width: 100%;">
                            <!-- Mute -->
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canMute || middlewareSignalrService?.connectionStatus?.isMute"
                                    title="{{ 'Webphone.Tooltip.Mute' | texttransform }}"
                                    (click)="middlewareSignalrService.mute()" [ngClass]="{ 
                                  webPhoneShowActive: middlewareSignalrService?.connectionStatus?.isMute,
                                  webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                  webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()
                                }"><i class="fas fa-microphone-slash"></i></button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canVolumeUp"
                                    title="{{ 'Webphone.Tooltip.VolumeUp' | texttransform }}"
                                    (click)="middlewareSignalrService.volumeUp()" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                  webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-volume-up"></i></button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canVolumeDown"
                                    title="{{ 'Webphone.Tooltip.VolumeDown' | texttransform }}"
                                    (click)="middlewareSignalrService.volumeDown()" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                  webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-volume-down"></i></button>
                            </div>
                        </div>

                        <div class="row" style="width: 100%;">
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canVolumeDown"
                                    title="{{ 'Webphone.Tooltip.Headphone' | texttransform }}"
                                    (click)="softphoneEvent('toggleheadset', 'canToggleHeadset')" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                  webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-headphones"></i></button>
                            </div>
                            <div class="col-md-4">
                                <button class="softPhoneButton"
                                    *ngIf="middlewareSignalrService?.connectionStatus?.canToggleSpeaker"
                                    title="{{ 'Webphone.Tooltip.Speaker' | texttransform }}"
                                    (click)="softphoneEvent('togglespeaker', 'canToggleSpeaker')" [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
                                  webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"><i
                                        class="fas fa-bullhorn"></i></button>
                            </div>
                            <div class="col-md-4">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>