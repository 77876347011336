<span *ngIf="checkStepResult()"></span>
<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 11" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.IsNetResult' | texttransform }}
                    <app-wizard-infobutton displayText="StepResultDesign.Label.IsNetResultDescription">
                    </app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isNetResult" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.IsCallAttempt' | texttransform }}
                    <app-wizard-infobutton displayText="StepResultDesign.Label.IsCallAttemptDescription">
                    </app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isCallAttempt" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.IsNotesRequired' | texttransform }}
                    <app-wizard-infobutton displayText="StepResultDesign.Label.IsNotesRequiredDescription">
                    </app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isNotesRequired" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.IsConsentRequired' | texttransform }}
                    <app-wizard-infobutton displayText="StepResultDesign.Label.IsConsentRequiredDescription">
                    </app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isConsentRequired" type="checkbox">
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.DefaultName' | texttransform }}
                    <app-wizard-infobutton displayText="CampaignDesign.Info.DefaultName"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.defaultName" class="wizardInput">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.Active' | texttransform }}
                    <app-wizard-infobutton displayText="StepResultDesign.Label.Active"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isActive" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.PersonalFollowUp' | texttransform }}
                    <app-wizard-infobutton displayText="StepResultDesign.Label.PersonalFollowUpDescription">
                    </app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isPersonalFollowup" type="checkbox">
            </div>   
            <div class="wizardDetailItem" *ngIf="applicationInfoService.expertMode">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.WorkflowStepResultTypeId' | texttransform }}
                </label>
                <select [(ngModel)]="wizardService.wizardArray.workflowStepResultTypeId" class="processWorkflowStepresultDetailDropdown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let workflowStepResultType of workflowStepResultTypes" [ngValue]="workflowStepResultType.id">
                        {{ workflowStepResultType.defaultName }}
                    </option>
                </select>
            </div>         
            <div class="wizardDetailItem" *ngIf="applicationInfoService.expertMode">
                <label class="noPadding wizardLabel">
                    {{ 'StepResultDesign.Label.TypeLookupId' | texttransform }}
                </label>
                <select [(ngModel)]="wizardService.wizardArray.typeLookupId" class="processWorkflowStepresultDetailDropdown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let resultType of resultTypes" [ngValue]="resultType.id">
                        {{ resultType.defaultName }}
                    </option>
                </select>
            </div>         
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="wizardDetailItem d-flex">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.EmailTemplateId' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.EmailTemplateIdDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.emailTemplateId" class="processWorkflowStepresultDetailDropdown" (change)="getTemplatePreview()">
                    <option [ngValue]=null></option>
                    <option *ngFor="let emailtemplate of emailTemplates" [ngValue]="emailtemplate.id">{{ emailtemplate.defaultName }}</option>
                </select>
                <div class="stepResult__emailButtons">
                    <i class="fas fa-sync fingerCursor" [tippy]="'StepResultDesign.Label.RefreshTemplates' | texttransform" 
                        (click)="getEMailTemplates()"></i>
                    <i class="fas fa-edit fingerCursor" [tippy]="'StepResultDesign.Label.EditTemplates' | texttransform" 
                        (click)="showEMailSettings()"></i>
                </div>                
            </div>

            <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.emailTemplateId">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.IsEditableEmail' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.IsEditableEmailDescription"></app-wizard-infobutton></label>
                <input [(ngModel)]="wizardService.wizardArray.isEditableEmail" type="checkbox">
            </div>
            <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.emailTemplateId != null">
                <div [innerHTML]="innerHTML"></div>
            </div>
        </div>


        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.IsSendEmailToFollowUpUser' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.IsSendEmailToFollowUpUserDescription"></app-wizard-infobutton></label>
                <input [(ngModel)]="wizardService.wizardArray.isSendEmailToFollowUpUser" type="checkbox">
            </div>
            <div *ngIf="wizardService.wizardArray.isSendEmailToFollowUpUser">
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.FollowUpUserId' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.FollowUpUserIdDescription"></app-wizard-infobutton></label>
                    <select [(ngModel)]="wizardService.wizardArray.followUpUserId"
                        class="processWorkflowStepresultDetailDropdown">
                        <option [ngValue]=null></option>
                        <option *ngFor="let user of users" [ngValue]="user.id">{{
                            user.userName }}</option>
                    </select>
                </div>

                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.FollowupEmailTemplateId' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.FollowupEmailTemplateIdDescription"></app-wizard-infobutton></label>
                    <select [(ngModel)]="wizardService.wizardArray.followupEmailTemplateId"
                        class="processWorkflowStepresultDetailDropdown" (change)="getTemplateFollowupPreview()">
                        <option [ngValue]=null></option>
                        <option *ngFor="let emailtemplate of emailTemplates" [ngValue]="emailtemplate.id">{{
                            emailtemplate.defaultName }}</option>
                    </select>
                </div>

                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">CC<app-wizard-infobutton displayText="StepResultDesign.Label.CCDescription"></app-wizard-infobutton></label>
                    <input [(ngModel)]="wizardService.wizardArray.followupEmailCc" class="wizardInput">
                </div>
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">BCC<app-wizard-infobutton displayText="StepResultDesign.Label.CCDescription"></app-wizard-infobutton></label>
                    <input [(ngModel)]="wizardService.wizardArray.followupEmailBcc" class="wizardInput">
                </div>

                <div class="wizardItemBlock">
                    <div [innerHTML]="innerHTMLFollowUp"></div>
                </div>      
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.CloseTaskAfterXResults' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.CloseTaskAfterXResultsDescription"></app-wizard-infobutton></label>
                <input [(ngModel)]="wizardService.wizardArray.closeTaskAfterXResults" type="number">
            </div>

            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.ClosingResultIdAfterXResults' | texttransform }}</label>
                <select [(ngModel)]="wizardService.wizardArray.closingResultIdAfterXResults"
                        class="processWorkflowStepresultDetailDropdown">
                        <option [ngValue]=null></option>
                        <option *ngFor="let result of results" [ngValue]="result.id">{{
                            result.defaultName }}</option>
                    </select>
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 5">
           
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.IsOpportunityCreation' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.IsOpportunityCreationDescription"></app-wizard-infobutton></label>
                <input [(ngModel)]="wizardService.wizardArray.isOpportunityCreation" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.OpportunityStatusId' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.OpportunityStatusIdDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.opportunityStatusId"
                    class="processWorkflowStepresultDetailDropdown">
                    <option [ngValue]=null></option> 
                    <option *ngFor="let opportunityState of opportunityService.opportunityStates" [ngValue]="opportunityState.id">{{
                        opportunityState.defaultName }}</option>
                </select>
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 9">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.NextStepWorkflow' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.NextStepWorkflowDescription"></app-wizard-infobutton></label>
                <select class="processWorkflowStepresultDetailDropdown"
                    [(ngModel)]="wizardService.wizardArray.nextStepWorkflowId" (change)="wizardService.wizardArray.nextStepId = null">
                    <option [ngValue]=null></option>
                    <option *ngFor="let workflow of workflows" [ngValue]="workflow.id">{{ workflow.defaultName }}</option>
                </select>            
            </div>

            <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.nextStepWorkflowId">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.NextStep' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.NextStepDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.nextStepId" class="processWorkflowStepresultDetailDropdown">
                    <option *ngFor="let step of getWorkflowSteps(wizardService.wizardArray.nextStepWorkflowId)" [ngValue]="step.id">{{ step.defaultName }}</option>
                </select>
            </div>
            <div *ngIf="wizardService.wizardArray.nextStepId">
                <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.nextStepWorkflowId">
                    <label class="noPadding wizardLabel">{{ 'ProcessDesign.Label.NextStepCampaign' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.NextStepCampaignDescription"></app-wizard-infobutton></label>
                    <select [(ngModel)]="wizardService.wizardArray.nextStepCampaignId" class="processWorkflowStepresultDetailDropdown">
                        <option [ngValue]=null></option>
                        <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">{{ campaign.defaultName }}</option>
                    </select>
                </div>
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'ProcessDesign.Label.DueInDays' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.DueInDaysDescription"></app-wizard-infobutton></label>
                    <input [(ngModel)]="wizardService.wizardArray.defaultDueInDays" type="number">
                </div>
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'ProcessDesign.Label.DueInDaysMax' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.DueInDaysMaxDescription"></app-wizard-infobutton></label>
                    <input [(ngModel)]="wizardService.wizardArray.dueInDaysMax" type="number">
                </div>        
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 8" class="isDeveloper">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">Event-Ergebnis</label>
                <input [(ngModel)]="wizardService.wizardArray.isEventResult" type="checkbox">
            </div>
            <div class="wizardItemBlock">
                <div class="campaignDetailItem wizardSectionSpacer">
                    <label class="noPadding wizardLabel">{{ 'PreExecuteSql' | texttransform
                        }}</label>
                    <textarea [(ngModel)]="wizardService.wizardArray.preExecuteSql" style="width: 100%; height: 100px;"></textarea>
                </div>
                <div class="campaignDetailItem wizardSectionSpacer">
                    <label class="noPadding wizardLabel">{{'PostExecuteSql' | texttransform}}</label>
                    <textarea [(ngModel)]="wizardService.wizardArray.postExecuteSql" style="width: 100%; height: 100px;"></textarea>
                </div>
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 10">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.QM' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.QMDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.qmUserGroupId" class="processWorkflowStepresultDetailDropdown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let qmUserGroup of qmUserGroups" [ngValue]="qmUserGroup.id">{{ qmUserGroup.defaultName }}</option>
                </select>
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 6">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepResultDesign.Label.LeadState' | texttransform }}<app-wizard-infobutton displayText="StepResultDesign.Label.LeadStateDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.leadStatusId" class="processWorkflowStepresultDetailDropdown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let leadstate of leadStates" [ngValue]="leadstate.lookupTable.id">{{ leadstate.lookupTable.defaultName }}</option>
                </select>
            </div>
        </div>     
        
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 7">
            <app-questionanswers [result]="wizardService.wizardArray" *ngIf="wizardService.wizardArray.id"></app-questionanswers>
        </div>                    
    </div>
</div>
