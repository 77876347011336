import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-subresult-item',
  templateUrl: './custom-subresult-item.component.html',
  styleUrls: ['./custom-subresult-item.component.scss']
})
export class CustomSubresultItemComponent implements OnInit, OnDestroy {
  updateSubResultsEventSubscription: Subscription = new Subscription();
  @Input() data: any;
  @Input() parentId: any;
  @Input() subResultLevel: any;
  @Input() subResultArray: any;
  @Input() mainControl: any;  
  dropdownSelection = [];
  fitleredResultsForSelectBox = [];
  answerText = [];
  
  constructor(
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private entityService: EntityService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.data.forEach(element => {
      if (element.parentSubResultId === this.parentId) {
        this.fitleredResultsForSelectBox.push(element);
      }
    });
    this.updateSubResultsEventSubscription = this.eventService.updateSubResultsEvent.subscribe(() => {
      this.getSelectedSubResult();
    })
    this.getSelectedSubResult();
    this.applicationInfoService.subResultChanges = [];
  }

ngOnDestroy(): void {
  if (this.updateSubResultsEventSubscription) { this.updateSubResultsEventSubscription.unsubscribe(); }
}

  parentSelected(parentId: any) {
    return this.itemInList(parentId);
  }

  getCheckboxLabelWidth(subResultLevel) {
    return { 'padding-left': this.getLevelPaddingRawValue(subResultLevel) + 'px' };
  }

  getLevelPaddingRawValue(subResultLevel) {
    return subResultLevel * 10;
  }

  getLevelPadding(subResultLevel: number = this.subResultLevel) {
    return { 'padding-left': this.getLevelPaddingRawValue(subResultLevel) + 'px' };
  }

  toggleSubResultInArray(item: any) {
    let selectedResultsArray = this.entityService.getEntityValue(
      this.applicationInfoService.entities.Item(this.mainControl.uiEntityInstanceId), this.mainControl.value, 1);
    const toggleResult = this.commonService.toggleArrayItem(selectedResultsArray, item);
    selectedResultsArray = toggleResult.resultArray;
    this.applicationInfoService.entities.Item(this.mainControl.uiEntityInstanceId).data[this.mainControl.value] = selectedResultsArray;
    if (toggleResult.itemRemoved) {
      this.uncheckChildsOfRemovedItem(item);
    }
  }

  selectDropDownItem(selectedItemId: any, dropdownDataItem: any) {
    dropdownDataItem.answers.forEach(answer => {
      if (this.itemInList(answer.id)) {
        this.toggleSubResultInArray(answer.id);
      }
    });
    const existingItem = this.applicationInfoService.subResultChanges.find(change => change.questionId == dropdownDataItem.id);
    if (existingItem) {
      this.commonService.removeItemFromArray(this.applicationInfoService.subResultChanges, existingItem);
    }
    this.applicationInfoService.subResultChanges.push({ questionId: dropdownDataItem.id, answerId: selectedItemId })
    this.toggleSubResultInArray(Number(selectedItemId));
  }

  getSelectedSubResult() {    
    const selectedSubResults = this.entityService.getEntityValue(
      this.applicationInfoService.entities.Item(this.mainControl.uiEntityInstanceId), 'questionAnswers', 1);
    if (selectedSubResults) {     
      if (selectedSubResults.length > 0) {
        this.data.forEach(dataItem => {
          dataItem.answers.forEach(answer => {
            const answerFound = selectedSubResults.find(resultItem => resultItem.answerId == answer.id);
            if (answerFound) {              
              this.dropdownSelection[dataItem.id] = answer.id;
              this.answerText[answer.id] = answerFound.answerText;
            }
          });            
        });
      }
    }
  }

  uncheckChildsOfRemovedItem(removedParentItem) {
    this.data.forEach(dataItem => {
      if (dataItem.parentAnswerId === removedParentItem) {
        dataItem.answers.forEach(answer => {
          if (this.itemInList(answer.id)) {
            this.toggleSubResultInArray(answer.id);
          }
        });
      }
    });
  }

  itemInList(item: any): boolean {
    const selectedResultsArray = this.entityService.getEntityValue(
      this.applicationInfoService.entities.Item(this.mainControl.uiEntityInstanceId), this.mainControl.value, 1);
    if (!selectedResultsArray) {
      return false;
    }
    return this.commonService.checkIfItemIsInArray(selectedResultsArray, item);
  }

  addAnswerTextArrayToEntity() {
    this.applicationInfoService.entities.Item(this.mainControl.uiEntityInstanceId).data[this.mainControl.value.concat('additionalText')] = this.answerText;
  }

  checkIfSubquestionAvailable(dataItem, dropdownSelection, typeLookupId) {
    let returnValue = false;    
    dataItem.answers.forEach(answer => {
      if (answer.id == dropdownSelection && answer.typeLookupId == typeLookupId) {
        returnValue =  true;
      }
    });
    return returnValue;
  }
}
