import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-booking-image',
  templateUrl: './booking-image.component.html',
  styleUrls: ['./booking-image.component.scss']
})
export class BookingImageComponent implements OnInit {
  @Input() result = null;
  image = null;
  type = null;
  isFlowchart = false;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService
  ) { }
  
  ngOnInit(): void {
    this.isFlowchart = true
    return;

    this.image = this.applicationInfoService.applicationSettings['bookingImage'.concat(this.result.id, this.axivasTranslateService.currentLanguage)]
    if (this.image != null) {
      const imageDetails = this.image.split('.');
      this.type = imageDetails[imageDetails.length -1].toLowerCase();
    } else {
      this.isFlowchart = true
    };
  }
}
