import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalToTimePipe } from './pipes/decimal-to-time.pipe';
import { FormsModule } from '@angular/forms';
import { AutoLogoutService } from './service/autologout.service';
import { SharedAPI } from './service/sharedAPI';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { AxivasTranslateServicePipe } from './translation/axivas-translate.service';



@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    // TranslateModule,
    DecimalToTimePipe,
    AxivasTranslateServicePipe
  ],
  declarations: [
    DecimalToTimePipe,
    AxivasTranslateServicePipe,
    LandingpageComponent
  ],
  providers: [
    AutoLogoutService,
    SharedAPI,
    DecimalToTimePipe,
    AxivasTranslateServicePipe
  ]
})
export class SharedModule { }
