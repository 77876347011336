<span id="userManagementUserList"></span>
<div class="userManagement__wrapper">
    <div class="userManagement__left">
        <div class="userManagement__sectionHeader">
            <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}</div>
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input"
                [(ngModel)]="filterString">
            <svg-icon src="assets/images/svg/account-plus.svg" class="customTextControlWrapper__button userManagement__sectionHeader__button fingerCursor" (click)="newUser()"
                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
        </div>
        <div id="userManagementUserList" class="userManagement__itemContainer"
            [style.height.px]="uiService.getContainerHeightEx('userManagementUserList', 110, 300)">
            <div id="userWrapper" *ngFor="let user of users" [ngClass]="{ hideInDom: isFilterActive(user) }" (click)="selectUser(user)">
                <div [id]="getUserControlId(user)" class="userManagement__item__container fingerCursor">
                    <div class="userManagement__item__name ellipsisText">{{ user.userName }} ({{ user.email }})</div>                
                </div>
            </div>
        </div>
    </div>






    <div class="userManagement__right">        
        <div class="userManagement__details" *ngIf="selectedUser">
            <div class="userManagement__details__header">
                <div class="userManagement__details__header__left">
                    <svg-icon src="assets/images/svg/default_contact.svg" class="customTextControlWrapper__baseColor"
                        [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                </div>

                <div class="userManagement__details__header__right">
                    {{ selectedUser?.userName }}
                </div>
                <div class="userManagement__details__header__left fingerCursor">                    
                    <svg-icon src="assets/images/svg/iconset/icon-save.svg" [svgStyle]="{ 'height.px': 24 }" (click)="saveUser()"></svg-icon>
                </div>
            </div>
            <div  id="userManagementUserDetails"></div>
            <div [style.height.px]="uiService.getContainerHeightEx('userManagementUserDetails', 40)" style="overflow-y: auto;">
            <div class="userManagement__blockwrapper">
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        ID
                    </div>
                    <div class="userManagement__details__item__input">
                        {{ selectedUser?.id }}
                    </div>
                </div>

                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.UserName' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">
                        <input [(ngModel)]="selectedUser.userName" type="text">
                    </div>
                </div>
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.FirstName' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">
                        <input [(ngModel)]="selectedUser.firstName" type="text">
                    </div>
                </div>
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.LastName' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">
                        <input [(ngModel)]="selectedUser.lastName" type="text">
                    </div>
                </div>
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.EMail' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">
                        <input [(ngModel)]="selectedUser.email" type="text">
                    </div>
                </div>
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.IsExternalUser' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">
                        <input [(ngModel)]="selectedUser.isExternalUser" type="checkbox">
                    </div>
                </div>
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.IsSentWelcomeMail' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">
                        <input [(ngModel)]="selectedUser.isSentWelcomeEmail" type="checkbox">
                    </div>
                </div>
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__label">
                        {{ 'UserManagement.Label.TrialEndsAt' | texttransform }}
                    </div>
                    <div class="userManagement__details__item__input">                        
                    <input [(ngModel)]="selectedUser.trialEndsAt" type="text" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>

                    </div>
                </div>
            </div>

            <div class="userManagement__fullInfoMode isDeveloper" *ngIf="applicationInfoService.isDeveloper">
                <div class="userManagement__fullInfoMode__button">
                    <i class="fas fa-eye"(click)="changeUserInfoMode()" [ngClass]="{ isActive: isFullUserInfo }"></i>
                </div>                
                <div class="userManagement__fullInfoMode__text">
                    <label class="ellipsisText">{{ 'UserManagement.Label.FullProjectInfo' | texttransform }}</label>
                </div>
                <div class="userManagement__fullInfoMode__button">
                    <i class="fas fa-trash" *ngIf="applicationInfoService.isDeveloper && fullInfoModeProjectId != null" (click)="deleteAllGroupsOfUser()"></i>
                </div>                
                <div class="userManagement__fullInfoMode__dropdown">
                    <select [(ngModel)]="fullInfoModeProjectId">
                        <option [ngValue]=null></option>
                        <option *ngFor="let project of projects" [ngValue]=project.id>{{ project.projectName }}</option>
                    </select>
                </div>
            </div>

            <div class="userManagement__details__options">
                <div class="ellipsisText">
                    {{ 'UserManagement.Label.DataRightGroups' | texttransform }}
                </div>                
                <div class="userManagement__details__header__left fingerCursor">
                    <svg-icon src="assets/images/svg/settings.svg" [svgStyle]="{ 'height.px': 24 }" (click)="openDataRightsPanel()"></svg-icon>
                </div>    
            </div>
            <div class="userManagement__blockwrapper">
                <div *ngFor="let userDataRight of userProjectInfo?.userDataRightsGroups" [ngClass]="{ 
                    specialWrapper: isFullUserInfo,
                    hideInDom: checkIfItemShouldBeHidden( userDataRight.group.project)
                }">
                    <div class="userManagement__item__container">
                        <div class="userManagement__item__name ellipsisText">{{ userDataRight.group.defaultName }}</div>
                        <div class="userManagement__item__move" (click)="deleteDataRightGroupOfUser(userDataRight, userProjectInfo.userDataRightsGroups)">
                            <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                        </div>            
                    </div>
                    <div class="userManagement__item__projectInfo">                        
                        <label *ngIf="isFullUserInfo" class="userManagement__item__projectInfo__inner" [tippy]="userDataRight.group.project.projectName">
                            {{ userDataRight.group.project.projectName }}
                        </label>
                    </div>  
                </div>
                <div *ngIf="userProjectInfo?.userDataRightsGroups.length == 0">
                    {{ 'UserManagement.Label.DataRightGroupsNoGroups' | texttransform }}
                </div>
            </div>


            <div class="userManagement__details__options">
                <div class="ellipsisText">
                    {{ 'Supervisor.Label.UserRoles' | texttransform }}
                </div>                
                <div class="userManagement__details__header__left fingerCursor">
                    <svg-icon src="assets/images/svg/settings.svg" [svgStyle]="{ 'height.px': 24 }" (click)="openPopup('projectusers')"></svg-icon>
                </div>    
            </div>
            <div class="userManagement__blockwrapper">
                <div *ngFor="let userProject of userProjectInfo?.userProjects" [ngClass]="{ 
                    specialWrapper: isFullUserInfo,
                    hideInDom: checkIfItemShouldBeHidden(userProject.project)
                }">
                    <div class="userManagement__item__container">
                        <div class="userManagement__item__name ellipsisText">{{ userProject.role.nameTranslationToken.tokenFullName | texttransform }}</div>
                        <div class="userManagement__item__move" (click)="deleteUserRoleAssignment(userProject, userProjectInfo.userProjects)">
                            <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                        </div>            
                    </div>
                    <div class="userManagement__item__projectInfo">                        
                        <label *ngIf="isFullUserInfo" class="userManagement__item__projectInfo__inner" [tippy]="userProject.project.projectName">
                            {{ userProject.project.projectName }}
                        </label>
                    </div>  
                </div>
                <div *ngIf="userProjectInfo?.userProjects.length == 0">
                    {{ 'UserManagement.Label.UserRolesNoGroups' | texttransform }}
                </div>
            </div>


            <div class="userManagement__details__options">
                <div class="ellipsisText">
                    {{ 'Supervisor.Label.UserGroups' | texttransform }}
                </div>                
                <div class="userManagement__details__header__left fingerCursor">
                    <svg-icon src="assets/images/svg/settings.svg" [svgStyle]="{ 'height.px': 24 }" (click)="openPopup('usergroups')"></svg-icon>
                </div>    
            </div>            

            <div class="userManagement__blockwrapper">
                <div *ngFor="let userGroupUser of userProjectInfo?.userGroupUsers" [ngClass]="{ 
                    specialWrapper: isFullUserInfo,
                    hideInDom: checkIfItemShouldBeHidden(userGroupUser.userGroup.project)
                }">
                    <div class="userManagement__item__container">
                        <div class="userManagement__item__name ellipsisText">{{ userGroupUser.userGroup.defaultName }}</div>
                        <div class="userManagement__item__move" (click)="deleteUserGroupAssignment(userGroupUser, userProjectInfo.userGroupUsers)">
                            <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                        </div>                         
                    </div>
                    <div class="userManagement__item__projectInfo">                        
                        <label *ngIf="isFullUserInfo" class="userManagement__item__projectInfo__inner" [tippy]="userGroupUser.userGroup.project.projectName">
                            {{ userGroupUser.userGroup.project.projectName }}
                        </label>
                    </div>           
                </div>
                <div *ngIf="userProjectInfo?.userGroupUsers.length == 0">
                    {{ 'UserManagement.Label.UserGroupsNoGroups' | texttransform }}
                </div>
            </div>


            <div class="userManagement__details__options">
                <div class="ellipsisText">
                    {{ 'Supervisor.Label.AutomaticRecordAssignment' | texttransform }}
                </div>     
                <div class="userManagement__details__header__left fingerCursor">
                    <svg-icon src="assets/images/svg/settings.svg" [svgStyle]="{ 'height.px': 24 }" (click)="openPopup('automaticrecordassignment')"></svg-icon>
                </div>               
            </div>
            <div class="userManagement__blockwrapper">
                <div *ngFor="let followupUserGroupUser of userProjectInfo?.followupUserGroupUsers" [ngClass]="{ 
                    specialWrapper: isFullUserInfo,
                    hideInDom: checkIfItemShouldBeHidden(followupUserGroupUser.followupUserGroup.project)
                }">
                    <div class="userManagement__item__container">
                        <div class="userManagement__item__name ellipsisText">{{ followupUserGroupUser.followupUserGroup.defaultName }}</div>
                        <div class="userManagement__item__move" (click)="deleteFollowUpUserAssignment(followupUserGroupUser, userProjectInfo.followupUserGroupUsers)">
                            <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                        </div>            
                    </div>
                    <div class="userManagement__item__projectInfo">                        
                        <label *ngIf="isFullUserInfo" class="userManagement__item__projectInfo__inner" [tippy]="followupUserGroupUser.followupUserGroup.project.projectName">
                            {{ followupUserGroupUser.followupUserGroup.project.projectName }}
                        </label>
                    </div>   
                </div>
                <div *ngIf="userProjectInfo?.followupUserGroupUsers.length == 0">
                    {{ 'UserManagement.Label.AutomaticRecordAssignmentNoGroups' | texttransform }}
                </div>
            </div>

            <div class="userManagement__details__options">
                {{ 'UserManagement.Label.AdditionalFunctions' | texttransform }}
            </div>
            <div class="userManagement__blockwrapper">
                <div class="userManagement__details__item">
                    <div class="userManagement__details__item__button">
                        <button class="jjButtonStyle jjButtonColor maxWidth" (click)="activateUser()" [disabled]="!selectedUser.isExternalUser">{{
                            'ActivateUser.Message.Header' | texttransform }}</button>
                    </div>
                    <div class="userManagement__details__item__description">
                        {{ 'UserManagement.Label.SendActivationMailDescription' | texttransform }}
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>