import { Component, Input, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-questionanswers',
  templateUrl: './questionanswers.component.html',
  styleUrls: ['./questionanswers.component.scss']
})
export class QuestionanswersComponent implements OnInit {
  @Input() result = null;
  questions = [];
  tempItemId = 0;
  filterString = '';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getResultQuestions();
  }

  getResultQuestions(): Promise<any> {    
    return new Promise((getResultQuestionsResolve, getResultQuestionsReject) => {
      if (this.result == null) {
        this.result = this.applicationInfoService.miscSettings['subQuestionFormCall'];
      }
      this.externaldatasourceService.executeExternalDataSource(152, [this.result.id])
      .then(getResultQuestionsResult => {
        this.questions = getResultQuestionsResult;
        getResultQuestionsResolve(null);
      })
      .catch(error => {
        getResultQuestionsReject(error);
      });
    });
  }

  addQuestion() {
    const question = new Object();
    question['refId'] = this.result.id;
    question['nameTranslationTokenId'] = [];
    question['nameTranslationToken'] = [];
    question['answers'] = [];
    question['order'] = this.questions.length + 1;
    question['lookupRefTypeId'] = 41;
    question['isMandatory'] = false;
    question['isMultiselect'] = false;
    question['descriptionTranslationId'] = [];
    question['descriptionTranslation'] = [];
    question['defaultName'] = this.axivasTranslateService.getTranslationTextForToken('QuestsionAnswers.Label.NewQuestionDefaultName');
    this.externaldatasourceService.executeExternalDataSource(156, [
      this.commonService.getModifyArrayBody(question, [])
    ]).then(result => {
      question['id'] = result.id;
      this.questions.push(question);
    });    
  }
}
