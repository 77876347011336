<h6>
    <i class="fas fa-plus-square" style="margin: 0 3px 0 3px;" (click)="addAttributeModifierGroup()"></i>
    {{ 'UiControlDesign.Label.AttributeModifyerSupervisor' | texttransform }}
</h6>
<div cdkDropList [cdkDropListData]="currentControlToModify.controlAttributeModifierGroups" (cdkDropListDropped)="dragdropService.moveInList($event, '0')">
    <div *ngFor="let attributeModifierGroup of currentControlToModify.controlAttributeModifierGroups" class="uiControlAttributeModifierGroup"
        cdkDrag [cdkDragData]="attributeModifierGroup" data-html="true">
        <div class="uiControlAttributeModifierGroupHeader">
            <i class="fas fa-trash fingerCursor" style="margin: 5px; color: red; float: left"
            (click)="removeAttributeModifierGroup(currentControlToModify.controlAttributeModifierGroups, attributeModifierGroup)"></i>

            <label class="uiControlAttributeModifierLabel">{{ 'UiControlDesign.Label.Modifying' | texttransform }}</label>
            <select class="uiControlAttributeModifierSelect" [(ngModel)]="attributeModifierGroup.attribute">
                <option ngValue='visible'>Unsichtbar</option>
                <option ngValue='enabled'>Nicht editierbar</option>
            </select>
            <i class="fas fa-grip-lines floatRight" style="margin: 5px;"></i>
        </div>
        <div style="width: 100%" class="nav-content-wrapper">
            <div style="padding: 0 5px 0 5px;" class="left-nav">
                <label class="uiControlAttributeModifierLabel">{{ 'UiControlDesign.Label.Connection' | texttransform }}</label>
                <select style="width: 100%" [(ngModel)]="attributeModifierGroup.connection">
                    <option ngValue="and">Und</option>
                    <option ngValue="or">Oder</option>
                </select>
            </div>
            <div class="content" style="border-left: 3px solid gainsboro; padding-left: 5px;">
                <label class="uiControlAttributeModifierLabel">
                    <i class="fas fa-plus-square" style="margin: 0 3px 0 3px;" (click)="addAttributeModifier(attributeModifierGroup)"></i>
                    {{ 'UiControlDesign.Label.Conditions' | texttransform }}
                </label>
                <div *ngFor="let attributeModifier of attributeModifierGroup.controlAttributeModifiers" class="uiControlAttributeModifierGroupItem">
                    <i class="fas fa-trash fingerCursor" style="margin: 5px; color: red; float: left" (click)="removeAttributeModifier(attributeModifierGroup.controlAttributeModifiers, attributeModifier)"
                    ></i>            
                    <select [(ngModel)]="attributeModifier.compareValue" class="uiControlAttributeModifierSelect" (change)="compareValueChanged(attributeModifier)">
                        <option *ngFor="let attributeModifierSource of attributeModifierSources" [ngValue]="attributeModifierSource.id">
                            {{ attributeModifierSource.defaultName }}
                        </option>                
                    </select>
            
                    <select [(ngModel)]="attributeModifier.compareType" class="uiControlAttributeModifierSelect">
                        <option ngValue='='>Gleich</option>
                        <option ngValue='!='>Ungleich</option>
                        <option ngValue='LIKE'>Ähnlich</option>
                        <option ngValue='STARTSWITH'>Beginnt mit</option>                    
                    </select>
                    <span *ngIf="getSelectedAttributeModifierSource(attributeModifier) != null">
                        {{ attributeModifier.attributeModifierSourceMember?.memberType }}
                        <span *ngIf="attributeModifierSourceMember[attributeModifier.compareValue]?.memberType == 'TEXT'">
                            <input [(ngModel)]="attributeModifier.refValue" style="height: 23px;" class="jjDesignBorder">
                        </span>
                        <span *ngIf="attributeModifierSourceMember[attributeModifier.compareValue]?.memberType == 'BOOL'">
                            <input type="checkbox" [(ngModel)]="attributeModifier.refValue" style="height: 23px;" class="jjDesignBorder">
                        </span>
                        <span *ngIf="attributeModifierSourceMember[attributeModifier.compareValue]?.memberType == 'NUMERIC'">
                            <span *ngIf="attributeModifierSourceMember[attributeModifier.compareValue].lookupTableId != null">
                                <select [(ngModel)]="attributeModifier.refValue" class="uiControlAttributeModifierSelect">
                                    <option [ngValue]=null>Leer</option>
                                    <option *ngFor="let lookupTableItem of attributeModifierSourceDropdownItems[attributeModifier.compareValue]" [ngValue]=lookupTableItem.id>
                                        {{ lookupTableItem.defaultName }}
                                    </option>
                                </select>
                            </span>
                            <span *ngIf="attributeModifierSourceMember[attributeModifier.compareValue].lookupTableId == null">
                                <input type="text" [(ngModel)]="attributeModifier.refValue" style="height: 23px;" class="jjDesignBorder">
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>    
</div>