<span *ngIf="checkStep()"></span>
<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'ProcessDesign.Label.DefaultName' | texttransform }}
                    <app-wizard-infobutton displayText="CampaignDesign.Info.DefaultName"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.defaultName" class="wizardInput">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'ProcessDesign.Label.IsActive' | texttransform }}
                    <app-wizard-infobutton displayText="ProcessDesign.Label.IsActive"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isActive" type="checkbox">
            </div>

            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepDesign.Label.IsSingleAction' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.IsSingleActionDescription"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isSingleAction" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'StepDesign.Label.IsProcessStarter' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.IsProcessStarterDescription"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isProcessStarter" type="checkbox">
            </div>
            <div class="wizardDetailItem" *ngIf="applicationInfoService.expertMode">
                <label class="noPadding wizardLabel">
                    {{ 'StepDesign.Label.IsFollowUp' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.IsFollowUpDescription"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isFollowUp" type="checkbox">
            </div>    
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.StepType' | texttransform }}</label>
                <select [(ngModel)]="wizardService.wizardArray.typeLookupId" class="wizardDropDown">
                    <option *ngFor="let stepType of stepTypes" [ngValue]="stepType.id">
                        {{ stepType?.descriptionTranslationToken?.tokenFullName | texttransform }}
                    </option>
                </select>
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3" class="maxHeight">
            <div>
                <div class="wizardDetailItem d-flex">
                    <input [placeholder]="'StepDesign.Label.CreateResultToolTip' | texttransform" [(ngModel)]="newResultName" class="wizardDetailItem__input">
                    <button class="wizardDetailItem__button" (click)="createResult(newResultName)" [disabled]="newResultName.length < 3">{{ 'StepDesign.Label.CreateResult' | texttransform }}</button>
                </div>
                <div class="wizardDetailItem d-flex" *ngIf="applicationInfoService.expertMode">
                    <input [placeholder]="'StepDesign.Label.CreateResultsToolTip' | texttransform" [(ngModel)]="newResultBatch" class="wizardDetailItem__input">
                    <button class="wizardDetailItem__button" (click)="createResults(newResultBatch)" [disabled]="newResultBatch.length < 3">{{ 'StepDesign.Label.CreateResults' | texttransform }}</button>
                </div>    
            </div>              
            <div [ngClass]="{
                stepDefault: !applicationInfoService.expertMode,
                stepExpert: applicationInfoService.expertMode
            }">
                <label class="wizardSubheader__descriptionLabel">{{ 'StepDesign.Label.ExistingResults' | texttransform }}</label>
                <div class="wizardDetailItem" *ngFor="let result of wizardService.wizardArray.results">
                    <label class="noPadding wizardLabel maxWidth" [tippy]="result.defaultName">
                        {{ result.defaultName | texttransform }}                
                    </label>            
                </div>    
            </div>
                      
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.BookTaskAfterXDays' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.bookTaskAfterXDays" type="number">
            </div>

            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.BookTaskAfterXDaysResult' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.BookTaskAfterXDaysDescription"></app-wizard-infobutton>
                </label>
                <select [(ngModel)]="wizardService.wizardArray.bookTaskAfterXDaysResultId" class="wizardDropDown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let result of wizardService.wizardArray.results" [ngValue]="result.id">{{ result.defaultName }}</option>
                </select>
            </div>

            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.CloseTasksAfterXCallAttempts' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.closeTaskAfterXCallAttempts" type="number">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.CloseTasksAfterXCallAttemptsResult' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.CloseTasksAfterXCallAttemptsDescription"></app-wizard-infobutton>
                </label>
                <select [(ngModel)]="wizardService.wizardArray.closeTaskAfterXCallAttemptsResultId" class="wizardDropDown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let result of wizardService.wizardArray.results" [ngValue]="result.id">{{ result.defaultName }}</option>
                </select>
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 5" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.FollowUpResultId' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.FollowUpResultIdDescription"></app-wizard-infobutton>
                </label>
                <select [(ngModel)]="wizardService.wizardArray.followUpResultId" class="wizardDropDown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let result of wizardService.wizardArray.results" [ngValue]="result.id">{{ result.defaultName }}</option>
                </select>
            </div>
            
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.NotRelevantStepResultId' | texttransform }}
                    <app-wizard-infobutton displayText="StepDesign.Label.NotRelevantStepResultIdDescription"></app-wizard-infobutton>
                </label>
                <select [(ngModel)]="wizardService.wizardArray.notRelevantStepResultId" class="wizardDropDown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let result of wizardService.wizardArray.results" [ngValue]="result.id">{{ result.defaultName }}</option>
                </select>
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="row">
    <div class="col-md-12">
    

        <div *ngIf="wizardService.wizardPage == 3">
           
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.CloseTasksAfterXCallAttempts' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.closeTaskAfterXCallAttempts" type="number">
            </div>
            <div class="wizardSectionSpacer">
                <label class="noPadding wizardLabel">{{ 'StepDesign.Label.CloseTasksAfterXCallAttemptsResult' | texttransform }}</label>
                <select [(ngModel)]="wizardService.wizardArray.closeTaskAfterXCallAttemptsResultId" class="wizardDropDown">
                    <option [ngValue]=null></option>
                    <option *ngFor="let result of results" [ngValue]="result.id">{{ result.defaultName }}</option>
                </select>
            </div>
            <div class="wizardDetailItem">
                {{ 'StepDesign.Label.CloseTasksAfterXCallAttemptsDescription' | texttransform }}
            </div>
        </div>
    </div>
</div> -->