import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'objectdeserialize'
})

@Injectable({
  providedIn: 'root'
})
export class ObjectDeserializeService implements PipeTransform {

  constructor() { }

  transform(item: any, args: string, returnValueIfNull = '') {
    if (item == null) {return null; }
    if (args == null) {return null; }
    const argsSplit = args.split('.');
    let result: any = item;
    for (let i = 0; i <= argsSplit.length - 1; i++) {
      if (result[argsSplit[i]] == null) { return returnValueIfNull; }
      result = result[argsSplit[i]];
    }
    return result;
  }
}
