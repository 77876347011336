import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-opportunitylist',
  templateUrl: './opportunitylist.component.html',
  styleUrl: './opportunitylist.component.scss'
})

export class OpportunitylistComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="247203"> </jj-container>`;
  innerHTML = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService
  ) { }

  ngOnInit() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.trackingService.trackPageView();
  }
}
