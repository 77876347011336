<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>

<div class="processvisual" *ngIf="featureNotFound == false">
    <div class="processvisual__wrapper">
        {{ applicationInfoService.miscSettings['processvisual'] | json }}

    <div class="processvisual__header">
        <div class="processvisual__info">
            <h1 *ngIf="workflowObject">{{workflowObject.defaultName}}</h1>
        </div>
        <div class="processvisual__nav"> 
            <div *ngIf="!isDuplicated" class="processvisual__nav__input">
                <select [(ngModel)]="applicationInfoService.selectedProcessVisualWorkflow" 
                    (change)="showWorkflowDetails()"
                    >
                    <option disabled [ngValue]=0 >{{ 'ProcessVisual.Label.SelectWorkflow' | texttransform }}</option>
                    <option *ngFor="let workflow of applicationInfoService.workflows.toArray()" [ngValue]="workflow.id">{{ workflow.defaultName }}</option>
                </select>
            </div>

        </div>

    </div>

        <div [ngClass]="{hideInDom: (applicationInfoService.selectedProcessVisualWorkflow != 0) }" class="processvisual__selectCampaign">

            <!-- {{ 'ProcessVisual.Label.SelectWorkflow' | texttransform }} -->

            <span *ngIf="loading == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </span>
            <div *ngIf="loading == false" class="processvisual__image_placeholder">
            <!--   {{ 'ProcessVisual.Label.SelectWorkflow' | texttransform }} -->
                <img src="assets/images/flowchart/shadowFlowChart.png"/>
                <!-- <div class="placeholder"></div> -->
            </div>

        </div>
        <div [ngClass]="{ hideInDom: applicationInfoService.selectedProcessVisualWorkflow == 0 }" class="processvisual__flowchartContainer">
            <app-flowchart 
            [chartData]="workflowObject" 
            [controlid]="flowChartId"
            [initialTreeDepth]="2"
            [treeWidth]="'65%'"
            [treeHeight]="'70%'"
            [height]="'100%'"
            [width]="'100%'"
            [top]="'10%'"
            [toolboxInfo]="true"
            [toolboxExpand]="false"
            ></app-flowchart>       
        </div>
    </div>
</div>

