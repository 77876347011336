import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-cognitocallback',
  templateUrl: './cognitocallback.component.html',
  styleUrls: ['./cognitocallback.component.scss']
})
export class CognitocallbackComponent implements OnInit {

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: string = urlParams.get('code');
  }
}
