import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from '../../../core/application/application-info.service';
import { Subscription, timer } from 'rxjs';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { MiddlewareSignalrService, PopupMode } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';

@Component({
  selector: 'app-webphone',
  templateUrl: './webphone.component.html',
  styleUrls: ['./webphone.component.scss']
})
export class WebphoneComponent implements OnInit, OnDestroy {
  connectionStateSubsciption: Subscription = new Subscription;
  timerSubscription: Subscription = new Subscription;
  connectionTime = null;
  currentlyReconnecting = false;

  public popupMode: PopupMode;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public middlewareSignalrService: MiddlewareSignalrService,
    private methodService: MethodService
  ) { }

  ngOnInit() {
    this.connectionStateSubsciption = this.middlewareSignalrService.connectionStateEvent.subscribe(data => {
      console.log('connectionStateSubsciption', data);
    });
    this.getConnectionTime();
  }

  ngOnDestroy() {
    if (this.connectionStateSubsciption) { this.connectionStateSubsciption.unsubscribe(); }
    if (this.timerSubscription) { this.timerSubscription.unsubscribe(); }
  }

  softphoneEvent(event: string, property: string) {
      switch (event) {
        case 'hold':
          this.hold();
          break;
        case 'hangup':
          this.hangup();
          break;
        case 'consult':
          this.consult();
          break;
        case 'toggleheadset':
            this.toggleHeadset();
            break;
        case 'togglespeaker':
            this.toggleSpeaker();
            break;
        case 'startrecord':
            this.startRecord();
            break;
        case 'stoprecord':
            this.stopRecord();
            break;
        case 'transfer':
            this.transfer();
            break;
        case 'resume':
            this.resume();
            break;
        case 'discardrecord':
            this.discardRecord();
            break;
        case 'conference':
            this.conference();
            break;
        case 'answer':
            this.pickupcall();
            break;
        }
  }

  getConnectionTime() {
    const activityTimer = timer(1000, 1000);

    this.timerSubscription = activityTimer.subscribe(() => {
      if (this.middlewareSignalrService.onTelStatusTime !== null) {
        const date1 = new Date().getTime() / 1000;
        const date2 = new Date(this.middlewareSignalrService.onTelStatusTime).getTime() / 1000;
        const difference = Math.floor(date1 - date2);
        const minutes: number = Math.floor(difference / 60);
        this.connectionTime  = minutes.toString().padStart(2, '0') + ':' + (difference - minutes * 60).toString().padStart(2, '0');
      }
    });
  }

  transfer() {
    this.middlewareSignalrService.transfer();
  }

  discardRecord() {
    this.middlewareSignalrService.discardRecord();
  }

  pickupcall() {
    this.middlewareSignalrService.pickupcall();
  }

  resume() {
    this.middlewareSignalrService.resume();
  }

  toggleSpeaker() {
    this.middlewareSignalrService.toggleSpeaker();
  }

  toggleHeadset() {
    this.middlewareSignalrService.toggleHeadset();
  }

  consult() {
    this.methodService.launchInternalMethod('showsoftphonepopup', [PopupMode.Consult]);
    // this.middlewareSignalrServiceService.consult();
  }

  conference() {
    this.middlewareSignalrService.conference();
  }

  startRecord() {
    this.middlewareSignalrService.startRecord();
  }

  stopRecord() {
    this.middlewareSignalrService.stopRecord();
  }

  hold() {
    this.middlewareSignalrService.hold();
  }

  hangup() {
    this.middlewareSignalrService.hangup();
  }

  reinitializeConnection() {
    this.currentlyReconnecting = true;
    this.middlewareSignalrService.InitService()
    .then(() => {
      console.log('reinitializeConnection success');
      this.currentlyReconnecting = false;
    })
    .catch(error => {
      this.currentlyReconnecting = false;
      console.error('reinitializeConnection error', error);
    });
  }
}
