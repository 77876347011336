import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-thermometer',
  templateUrl: './custom-thermometer.component2.svg',
  styleUrls: ['./custom-thermometer.component.scss']
})
export class CustomThermometerComponent implements OnInit {
  valueNumber: any;

  @Input() value = 0.3;
  @Input() width = 65;
  @Input() height = 65;
  controlValue = 0;
  constructor() { }

  ngOnInit() {

   this.valueNumber = this.value * 100;

  }

 
}
