import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-wizard-voicetemplates',
  templateUrl: './wizard-voicetemplates.component.html',
  styleUrls: ['./wizard-voicetemplates.component.scss']
})
export class WizardVoicetemplatesComponent implements OnInit {
  caretPos = 0;
  viewModeToolbar = 1;
  viewMode = 1;  
  innerHTML = null;
  placeholders = [];

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    private commonService: CommonService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    this.getPlaceHolders();
  }

  getPlaceHolderInfo(placeholder) {
    let returnString = '';
    let entityId = null;
    placeholder = placeholder.replace(/{/g, '');
    placeholder = placeholder.replace(/}/g, '');
    const placeHolderDetails = placeholder.split('.');
    switch (placeHolderDetails[0]) {
      case 'account':
        entityId = 1;
        break;

      case 'contact':
        entityId = 4;
        break;

      case 'lastActivity':
        entityId = 2;
        break;  
    }
    if (entityId == null) {
      returnString = placeholder;
    } else {
      const member = this.applicationInfoService.entities.Item(entityId).entityMembers
        .find(member => member.defaultName == placeHolderDetails[1]);
      if (member) {
        returnString = ''.concat(
          this.axivasTranslateService.getTranslationTextForObject(this.applicationInfoService.entities.Item(entityId), 'nameTranslationToken'),
          ' - ',
          this.axivasTranslateService.getTranslationTextForObject(member, 'nameTranslationToken')
        );
      } else {
        returnString = placeholder;
      }
    }
    return returnString;
  }

  getPlaceHolders() {
    this.externaldatasourceService.executeExternalDataSource(446, [])
    .then(getPlaceHoldersResult => { this.placeholders = getPlaceHoldersResult; })
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
       this.caretPos = oField.selectionStart;
    }
  }

  addMemberToTemplate(placeholder) {
    this.wizardService.wizardArray.content = ''.concat(
      this.wizardService.wizardArray.content.substring(0, this.caretPos),
      placeholder,
      this.wizardService.wizardArray.content.substring(this.caretPos, this.wizardService.wizardArray.content.length),
      ' '
    );
    this.caretPos = this.caretPos + placeholder.length + 1;
  }
}
