import { Component, OnInit, Injector } from '@angular/core';

import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-textbox',
  templateUrl: './custom-textbox.component.html',
  styleUrls: ['./custom-textbox.component.scss']
})
export class CustomTextboxComponent extends CustomControlBaseClass implements OnInit {
  inputType = 'text';

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    if (this.entity != null) {
        this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
        switch (this.entityService.getEntityMemberType(this.entity.entityMembers, this.controlDefinition.value)) {
          case 'NUMERIC':
            this.inputType = 'number';
            break;
      }
    }
    if (this.controlDefinition.uiEntityInstanceId == this.applicationInfoService.miscSettingsEntityId) { this.controlValue = this.applicationInfoService.miscSettings[this.controlDefinition.value]; }
    this.checkAttributes();
  }

  getPlaceholder() {
    if (this.controlDefinition.tooltip != '' && this.controlDefinition.tooltip != null) {
      return this.controlDefinition.tooltip; 
    } else {
      return this.controlDefinition.displayText;
    }
  }

  keyDown() {    
    this.eventService.entityKeyDownEvent.emit(this.controlDefinition.uiEntityInstanceId);
  }

  checkInputValue() {   
    this.callMethod('onchange');
  }
}