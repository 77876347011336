<div *ngIf="userService.hasPermission('CanReadLookupLeadStatus')">
<div class="projectSettings__header">
    <div class="projectSettings__header__newItem fingerCursor" (click)="createNewWizardLeadState()" *ngIf="userService.hasPermission('CanCreateLookupLeadStatus')">
        <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
        {{ 'Leadstate.Label.NewLeadState' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction">
        <div class="projectSettings__header__showOnlyActive">
            <input [(ngModel)]="showOnlyActive" type="checkbox">
            {{ 'ProcessDesign.Label.ShowOnlyActive' | texttransform }}            
        </div>
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>

<div class="nonExpertHeader" *ngIf="applicationInfoService.expertMode==false && !applicationInfoService.isMobile()">
    <div class="nonExpertHeader__active">{{ 'Leadstate.Label.IsActive' | texttransform }}</div>
    <div class="nonExpertHeader__name">{{ 'Leadstate.Label.DefaultName' | texttransform}}</div>
    <div class="nonExpertHeader__edit">{{ 'Leadstate.Label.Order' | texttransform}}</div>    
    <div class="nonExpertHeader__netto">{{ 'Leadstate.Label.IsNet' | texttransform }}</div>
    <div class="nonExpertHeader__edit">{{ 'General._.Edit' | texttransform}}</div>        
</div>

<span *ngIf="loadingData==true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</span>

<span id="projectSettingsDesignSpan"></span>

<div class="projectSettings__wrapper" [style.height]="getTableHeight()" *ngIf="applicationInfoService.expertMode==true && loadingData == false">    
    <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
        <ng-container matColumnDef="defaultName" [sticky]="!applicationInfoService.isMobile()">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.DefaultName' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">  
                <div class="projectSettings__name">                    
                    <label *ngIf="applicationInfoService.isDeveloper" class="everleadCoral leadState__id">{{ element.lookupTable.id }} </label>
                    <input [(ngModel)]="element.lookupTable.defaultName" type="text" (change)="saveLeadState(element)" class="projectSettings__inputMin maxWidth">                    
                    <i class="fas fa-edit fingerCursor" [tippy]="'General.Label.ShowWizard' | texttransform" (click)="showWizard(element)" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></i>
                    <app-wizard-translations [array]="element.lookupTable" updateBaseArrayExternaldatasourceId=215 mode="3"></app-wizard-translations>    
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.Order' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.lookupTable.order" type="number" (change)="saveLeadState(element)" class="projectSettings__inputFull orderInput">                    
            </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsActive' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.lookupTable.isActive" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">                    
            </td>
        </ng-container>

    
        <ng-container matColumnDef="isNetto">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsNet' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.isNet" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">                    
            </td>
        </ng-container>
        
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.Type' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <select [(ngModel)]="element.statusLookupId" class="projectSettings__dropdown projectSettings__inputMin" (change)="saveLeadState(element)">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let leadStateType of leadStateTypes" [ngValue]="leadStateType.id">{{ leadStateType.defaultName }}
                    </option>
                </select>
    
            </td>
        </ng-container>

        <ng-container matColumnDef="isLead">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsLead' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.isLead" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">                    
            </td>
        </ng-container>

        <ng-container matColumnDef="isTableCreation">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsStatistic' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.isStatistic" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">                    
            </td>
        </ng-container>

        <ng-container matColumnDef="isLeadQuote">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsLeadquote' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.isLeadquote" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">                    
            </td>
        </ng-container>

        <ng-container matColumnDef="isAutomatic">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsRequiredLeadreport' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">            
                <input [(ngModel)]="element.isRequiredLeadreport" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">                    
            </td>
        </ng-container>

        <ng-container matColumnDef="isAutomaticStart">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.RequiredLeadreportStart' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">  
                <input [(ngModel)]=element.requiredLeadreportStart [owlDateTimeTrigger]="dt7" (dateTimeChange)="saveLeadState(element)" 
                    [owlDateTime]="dt7" readonly appendTo="body" aria-modal="true" class="projectSettings__inputFull maxWidth"> 
                <owl-date-time #dt7></owl-date-time>                                   
            </td>
        </ng-container>

        <ng-container matColumnDef="validXDays">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.ValidXDays' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">  
                <input [(ngModel)]=element.validXDays class="projectSettings__inputFull maxWidth" type="number" (change)="saveLeadState(element)"> 
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
            hideInDom: (row.lookupTable.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
            projectSettings__isInactive: row.isActive == false
        }"></tr>
    </table>
</div>

<div class="nonExpertView__wrapper" [style.height]="getTableHeight()" *ngIf="applicationInfoService.expertMode==false" >
    <div *ngFor="let leadstate of leadstates" class="nonExpertView__main nonExpertView__main__noSubitems" [ngClass]="{             
        hideInDom: (leadstate.isActive == false && showOnlyActive)  || itemIsFiltered(leadstate) == true,
        projectSettings__isInactive: leadstate.isActive == false
    }">
        <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
            <input [(ngModel)]="leadstate.lookupTable.isActive" type="checkbox" (change)="saveLeadState(leadstate)" [tippy]="'CampaignDesign.Label.IsActive' | texttransform" [tippyOptions]="{placement:'bottom'}">
        </div>
        <div class="nonExpertView__name">            
            <input [(ngModel)]="leadstate.lookupTable.defaultName" (change)="saveLeadState(leadstate)">
            <app-wizard-translations [array]="leadstate.lookupTable" updateBaseArrayExternaldatasourceId=215 mode="3"></app-wizard-translations>    
            <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" *ngIf="applicationInfoService.isMobile()" (click)="showWizard(leadstate)"></i>
        </div>
        <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
            <input [(ngModel)]="leadstate.lookupTable.order" (change)="saveLeadState(leadstate)" type="number" style="width: 50px" [tippy]="'Leadstate.Label.Order' | texttransform" [tippyOptions]="{placement:'bottom'}">
        </div>
        <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
            <input [(ngModel)]="leadstate.isNet" type="checkbox" (change)="saveLeadState(leadstate)" [tippy]="'CampaignDesign.Label.IsNet' | texttransform" [tippyOptions]="{placement:'bottom'}">
        </div>
        <div class="nonExpertView__bonus" (click)="showWizard(leadstate)" *ngIf="!applicationInfoService.isMobile()">
            <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" [tippy]="'General._.Edit' | texttransform" [tippyOptions]="{ theme:'everleadRed', placement:'bottom'}" ></i>
        </div>
    </div>
</div>




</div>