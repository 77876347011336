<video id="remoteVideo" hidden="hidden"></video>
<video id="localVideo" hidden="hidden" muted></video>

<span *ngIf="setLocation()"></span>
<p-toast key="topRight" position="top-right" [baseZIndex]="999999"></p-toast>
<div [id]="applicationInfoService?.versionNumber"></div>
<app-banner [ngClass]="{'hideInDom': checkAppVisibilityRequirements() }"></app-banner>
<app-incomingcall> </app-incomingcall>
<app-newversionavailable *ngIf="showNewVersionControl"></app-newversionavailable> 
<app-wizard-translations mode=2></app-wizard-translations>
<app-videoplayer *ngIf="applicationInfoService.useNewDesign && applicationInfoService.isMobile() == false"></app-videoplayer>
<app-awslogin *ngIf="applicationInfoService.showAWsLoginPage"></app-awslogin>
<p-confirmDialog acceptLabel="{{ 'Message.General.Yes' | texttransform }}" rejectLabel="{{ 'Message.General.No' | texttransform }}"></p-confirmDialog>      

<!-- <div class="mainView" (window:resize)="onResize()" [ngClass]="{ 'hideInDom': checkAppVisibilityRequirements() }">
  <div class="mainViewNavigation">
    <app-navigation class="appNavigation" *ngIf="applicationInfoService.projectLoaded"></app-navigation>
  </div>
  <div class="mainViewMain">
    <div class="mainViewHeader">
      <app-ajando-header *ngIf="applicationInfoService.projectSelected" [ngClass]="{ headerMobile: applicationInfoService.isMobile() }"></app-ajando-header>
      <app-breadcrumbandmenu *ngIf="applicationInfoService.noProject == false"></app-breadcrumbandmenu>
    </div>
    <div class="mainViewBody">
      <div class="mainViewContent">
        <router-outlet *ngIf="applicationInfoService.projectLoaded" [ngClass]="{ hideInDom: !checkAppVisibilityRequirements() }"></router-outlet>
      </div>
      <div class="mainViewSidebar">
        <app-custom-webphone-popup controluiid="softphone"></app-custom-webphone-popup>
        <app-custom-palette></app-custom-palette>    
      </div>  
    </div>  
  </div>
</div>

<div class="maxHeight">
  <app-contentinfo class="componentInfoContainer"></app-contentinfo>
  <app-custom-popup controluiid="popup"></app-custom-popup>
  <app-jj-popup></app-jj-popup>
  <app-toolbar-popup></app-toolbar-popup>
  <app-custom-palette></app-custom-palette> 
  <app-splashscreen *ngIf="applicationInfoService.showLoadProjectSpinner && !applicationInfoService?.showAWsLoginPage"></app-splashscreen>
</div> -->

<div class="bodyNewDesign" (window:resize)="onResize()">
  <app-contentinfo class="componentInfoContainer" *ngIf="applicationInfoService.projectLoaded"></app-contentinfo>
  <app-custom-popup controluiid="popup"></app-custom-popup>
  <app-jj-popup></app-jj-popup>
  <app-toolbar-popup *ngIf="applicationInfoService.projectLoaded"></app-toolbar-popup>
  <app-custom-palette *ngIf="applicationInfoService.projectLoaded"></app-custom-palette>
  <app-navigation class="appNavigation" *ngIf="applicationInfoService.projectLoaded"></app-navigation>

  <span [ngClass]="{ 'hideInDom': checkAppVisibilityRequirements()
}">
    <app-custom-webphone-popup controluiid="softphone"></app-custom-webphone-popup>    
    <app-ajando-header *ngIf="applicationInfoService.projectSelected" [ngClass]="{ headerMobile: applicationInfoService.isMobile() }"></app-ajando-header>
  </span>

  <span id="appComponentDesignSpan"></span>
  <div [ngClass]="{ nopadding: applicationInfoService.showLoadProjectSpinner }">

    <div class="row" [ngClass]="{ appMobileBackground: applicationInfoService.isMobile() }">

      <div class="col" [ngClass]="{ 
      appComponentMobileView: applicationInfoService.isMobile() == true,
      hideInDom: checkAppVisibilityRequirements() 
    }">
        <div id="naviDiv" id="appComponentNonMobile">
        <div id="navigationBar" class="navigationMain" [ngClass]="{
        navigationExpanded: applicationInfoService.navigationExpanded == true,
        navigationCollapsed: applicationInfoService.navigationExpanded == false
      }">

          </div>
          <span id="appComponentDesignSpan2"></span>
          <div id="contentDiv" [style.width.px]="calcOutletWidth('navigationBar')" class="contentDiv"
            [style.height.px]="getMaximumHeightOfSubContainer('appComponentDesignSpanRedesign', 0)"
            [style.max-height.px]="getMaximumHeightOfSubContainer('appComponentDesignSpanRedesign', 0)">
            <app-breadcrumbandmenu *ngIf="applicationInfoService.noProject == false"></app-breadcrumbandmenu>
            
            <span id="appComponentDesignSpanRedesign" class="routerHeaderDesignDiv"></span>
            <div id="routerOutletDiv" [ngClass]="{ 
              routerOutletDiv: !applicationInfoService.isMobile(),
              routerOutletDivNavigationExpandedFixed: !applicationInfoService.isMobile() && applicationInfoService.navigationExpandedFixed,
              routerOutletDivMobile: applicationInfoService.isMobile()
            }"
              [style.height.px]="getMaximumHeightOfSubContainer('appComponentDesignSpanRedesign', 70)"
              [style.max-height.px]="getMaximumHeightOfSubContainer('appComponentDesignSpanRedesign', 70)">
              <router-outlet *ngIf="applicationInfoService.projectLoaded" 
                [ngClass]="{ hideInDom: !checkAppVisibilityRequirements() }"></router-outlet>
            </div>           
          </div>
        </div>
        
        <span *ngIf="showLoader" class="loading"></span>
      </div>
      
      <div class="col nopadding showOnlyOnLargeScreen ringCentralSpacer" [ngClass]="{ hideInDom: checkAppVisibilityRequirements() }"
        *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'"></div>
      <app-phone-ringcentral class="appPhoneRingCentral" [ngClass]="{ hideInDom: checkAppVisibilityRequirements() }"
        *ngIf="applicationInfoService.applicationSettings['showSoftphoneWidget'] == 'true'"></app-phone-ringcentral>
    </div>

    <app-splashscreen *ngIf="applicationInfoService.showLoadProjectSpinner && !applicationInfoService?.showAWsLoginPage"></app-splashscreen>

    <div class="errorScreenBackgroundLayer" *ngIf="applicationInfoService.postGrahphileRestart"></div>
    <div *ngIf="
      applicationInfoService.errorOccured || 
      applicationInfoService.postGrahphileRestart ||
      applicationInfoService.noProjectsForUser" class="errorScreen">
      <div class="maxHeight maxWidth" class="errorScreen__inner maxHeight">
        <div class="errorScreen__inner__header everleadCoral jjBold">
          EVERLEAD
        </div>
        <div class="errorScreen__inner__body everleadBlueColor">
          <div class="d-flex">
            <div class="errorScreen__inner__body__left">
              <i class="fas fa-sad-tear fa-3x" *ngIf="applicationInfoService.errorOccured"></i>
              <i class="fas fa-exclamation-triangle fa-3x" *ngIf="applicationInfoService.postGrahphileRestart || applicationInfoService.noProjectsForUser"></i>
            </div>
            <div class="errorScreen__inner__body__right" *ngIf="applicationInfoService.errorOccured">
              <div>Derzeit besteht ein Problem mit der Anwendung, bitte versuchen Sie es später erneut.</div>
              <div>There is currently a problem with the application, please try again later.</div>    
            </div>
            <div class="errorScreen__inner__body__right" *ngIf="applicationInfoService.noProjectsForUser">
              <div>Ihrem Benutzer sind noch keine Projekte zugeordnet. Bitte wenden Sie sich an ihren Supervisor.</div>
              <div>No projects have been assigned to your user yet. Please contact your supervisor.</div>    
            </div>
            <div class="errorScreen__inner__body__right" *ngIf="applicationInfoService.postGrahphileRestart">
              {{ 'PostGraphile.Label.Restarting' | texttransform }}
            </div>
          </div>
        </div>
        <div *ngIf="applicationInfoService.errorOccured">
          <button class="errorScreen__inner__body__button everleadBlueColor" (click)="reloadPage()">Seite neu laden / Reload page</button>
        </div>
        <div class="errorScreen__inner__footer">
          v.{{ applicationInfoService.versionNumber }}
        </div>
      </div>
    </div>
  </div>
</div>

<div id="everleadFooter" class="everleadFooter" *ngIf="applicationInfoService?.showApplicationFooter"></div>