import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor() { }

  mailTo(emailReceiver: string, emailSubject: string, emailMessage: string ) {
    const mail = document.createElement('a');
    const mailString = 'mailto:'.concat(emailReceiver, '?subject=', emailSubject,
      '&body=', encodeURIComponent(emailMessage));
    mail.href = mailString;
    mail.click();
  }
}
