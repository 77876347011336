<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="addQuestion()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithWizard' | texttransform"></svg-icon>               
        {{ 'QuestsionAnswers.Label.NewQuestion' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction">       
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>


<div *ngFor="let question of questions">
    <app-questionanswers-item [filterString]=filterString [question]=question [level]=0 *ngIf="question.parentAnswerId==null"></app-questionanswers-item>
</div>