import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslationLanguage } from './translationLanguage';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { Language } from './language.model';
import { CustomTranslateLoader } from './translationCustomLoader';

@Injectable()
export class LanguageService {
  private currentLanguage: TranslationLanguage;
  public translationLanguages = null;

  constructor(
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private customTranslateLoader: CustomTranslateLoader,
   ) { }

  // Observable string sources
  private translationLanguageChangedSource = new Subject<TranslationLanguage>();

  // Observable string streams
  translationLanguageChanged$ = this.translationLanguageChangedSource.asObservable();

  // Service message commands
  emitLanguageChange(change: TranslationLanguage): Promise<any> {
    return new Promise((resolve) => {
      this.translationLanguageChangedSource.next(change);
      this.currentLanguage = change;
    });
  }

  public getCurrentLanguage(defaultLanguage: string = 'de'): TranslationLanguage {
    if (!this.currentLanguage) {
          this.currentLanguage = new TranslationLanguage({'identifier': defaultLanguage});
          return this.currentLanguage;
    } else {
    return this.currentLanguage;
    }
  }

  public getLanguageJson(language: any) {
    this.customTranslateLoader.getTranslation(language).subscribe(() => { });
  }

  public getLanguages(): Promise<Language[]> {
    return new Promise<Language[]>((getJollyJupiterLanguagesResolve, getJollyJupiterLanguagesReject) => {
      if (this.translationLanguages != null) {
        getJollyJupiterLanguagesResolve(this.translationLanguages);
        return;
      }
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.languages)
      .then(getLanguagesResult => {
        this.translationLanguages = getLanguagesResult.data.translationLanguages;
        getJollyJupiterLanguagesResolve(getLanguagesResult.data.translationLanguages);        
      })
      .catch(error => {
        console.error(error);
        getJollyJupiterLanguagesReject(error);
      });
    });
  }

}

