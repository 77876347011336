
<div *ngIf="!applicationInfoService.isMobile()"
  [ngClass]="{
    scrollInfo__active: !checkIfScrolled()
    && showAccountInformation() == true
    && !applicationInfoService.isMobile()
    && applicationInfoService.currentAccount != null,
    scrollInfo__inactive: checkIfScrolled(),
    scrollInfo__stickyNav: applicationInfoService.navigationExpandedFixed,
    hideInDom: applicationInfoService.currentAccount == null && checkIfScrolled()
  }" class="scrollInfo">

  <div class="ellipsisText noPadding uppercase scrollInfo__line1" [tippy]="getAdressLine(true)">
    {{ getAdressLine() }}
  </div>
  <div class="ellipsisText noPadding scrollInfo__line2" [tippy]="getContactLine()">
    {{ getContactLine() }}
  </div>
</div>
<ng-container *ngIf="applicationInfoService.projectLoaded">
  <ng-container *ngIf="!applicationInfoService.currentTask && !applicationInfoService.currentAccount && !applicationInfoService.currentContact
    && applicationInfoService.designerMode == false">
    <div *ngIf="!accountInit" class="globalFontSize">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    <ng-container *ngIf="accountInit">
      <span *ngIf="applicationInfoService.currentProject?.isUsingWorkflow">{{ "Task.Label.NoTaskAvailable" | texttransform }}<br /></span>
      <br />
      <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="startWorking()"
        *ngIf="applicationInfoService.currentProject?.isUsingWorkflow">
        {{ "Button.WelcomePage.StartWork" | texttransform }}
      </button>
      <span *ngIf="!applicationInfoService.currentProject?.isUsingWorkflow">
        {{ "Task.Label.NoContactSelected" | texttransform }}<br /></span>
      <br />
    </ng-container>
  </ng-container>


  <ng-container *ngIf="applicationInfoService.currentAccount">
    <span id="accountDesignSpan"></span>
    <div [ngClass]="{
        designerMode: applicationInfoService.designerMode,
        hideInDom:
          !applicationInfoService.currentTask &&
          !applicationInfoService.currentAccount &&
          !applicationInfoService.currentContact &&
          !applicationInfoService.designerMode
      }" class="maxWidth everleadMaxHeightOverflow noHorizontalScrolling" (scroll)="onScroll($event)"
    >
      <span id="accountMenuChecker" [class]="checkIfMenuIsVisible()"></span>
      <div *ngIf="applicationInfoService.accountLocked == true" class="everleadShadowBox accountLocked">
        <div class="accountLocked__header">
          <i class="fas fa-lock"></i>
          {{ 'Account.Locked.MessageHeader' | texttransform }}
        </div>
        <div class="accountLocked__body">
          {{ 'Account.Locked.Message' | texttransform }}
        </div>
        <div class="accountLocked__info">
          <div class="accountLocked__info__line">
            <div class="accountLocked__info__line__label">{{ 'Account.Locked.LockUser' | texttransform }}</div>
            <div class="accountLocked__info__line__value">{{ applicationInfoService.lockUser }}</div>
          </div>
          <div class="accountLocked__info__line">
            <div class="accountLocked__info__line__label">{{ 'Account.Locked.LockUntil' | texttransform }}</div>
            <div class="accountLocked__info__line__value">{{ applicationInfoService.accountLockInformation?.lockTill | date: 'medium' }}</div>
          </div>
        </div>
      </div>

      <div class="accountForm__wrapper">
        <div class="accountFormContactAccount" [ngClass]="{
          accountFormContactAccount__classic: applicationInfoService?.projectInfo?.isClassicView
        }">
          <div class="accountForm__section accountForm__contact everleadShadowBox2" [ngClass]="{
            largeMode: !applicationInfoService.currentTask && applicationInfoService.currentProject?.isUsingWorkflow == true,
            largeModeNonClassic: !applicationInfoService.isAccountExpanded && !applicationInfoService?.projectInfo?.isClassicView
          }">
            <div class="accountForm__section__designBorder"></div>
            <div [innerHTML]="innerHTMLBonusPanel1"></div>
            <div *ngIf="!applicationInfoService.currentContact" class="accountForm__contact__info">
              <div *ngIf="getAccountContacts(applicationInfoService.currentAccount.contacts).length > 0">
                <div>{{ "Account.Label.NoContactSelected" | texttransform }}</div>
                <div class="accountContact__select">{{ "Account.Label.PleaseSelectContact" | texttransform }}</div>
                <div class="accountContact__wrapper">
                  <div *ngFor="let accountContact of getAccountContacts(applicationInfoService.currentAccount.contacts)" class="accountContact">
                    <div class="accountContact__infoLineHead">
                      <label class="ellipsisText">{{getContactSalutation(accountContact.salutationId)}} {{accountContact.firstName}} {{accountContact.lastName}}</label>
                      <div *ngIf="accountContact.position" class="accountContact__infoLineHead__position everleadCoral ellipsisText">{{accountContact.position}}</div>
                      <div *ngIf="accountContact.positionfreetext" class="accountContact__infoLineHead__position everleadCoral ellipsisText">{{accountContact.positionfreetext}}</div>
                    </div>
                    <button class="everleadCustomButton" (click)="loadContact(accountContact)" [tippy]="'Contact.Label.LoadContact' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"><i class="fas fa-external-link-alt"></i></button>
                  </div>
                </div>
              </div>

              <div *ngIf="getAccountContacts(applicationInfoService.currentAccount.contacts).length == 0" class="accountForm__contact__info__nocontactavailable">
                <div>{{ "Account.Label.NoContactAvailable" | texttransform }}</div>
                <div class="accountForm__contact__info__createcontact">
                  <button class="everleadCustomButton" (click)="createContact()">{{ 'Contact.Label.NewContact' | texttransform }}</button>
                </div>
              </div>
            </div>
            <div [innerHTML]="innerHTMLContact"
                *ngIf="applicationInfoService.currentContact"></div>
            <div class="accountForm__contact__header"><i class="fas fa-user" style="margin-top: -2px"></i></div>
            <div class="accountForm__contact__expander fingerCursor" (click)="applicationInfoService.isAccountExpanded = true"
              [tippy]="'Account.Label.ExpandAccount' | texttransform"
              *ngIf="!applicationInfoService.isAccountExpanded && applicationInfoService.currentContact && !applicationInfoService.isMobile() && !applicationInfoService?.projectInfo?.isClassicView">
                <div><i class="fas fa-chevron-right"></i></div>
                <div><i class="fas fa-home"></i></div>
            </div>
          </div>

          <div class="mobileAccountToggle" *ngIf="!applicationInfoService?.projectInfo?.isClassicView"
            (click)="applicationInfoService.isAccountExpanded = !applicationInfoService.isAccountExpanded"></div>

          <div class="accountForm__account accountForm__section everleadShadowBox2"
            [ngClass]="{ largeMode: !applicationInfoService.currentTask && applicationInfoService.currentProject?.isUsingWorkflow == true }"
            *ngIf="(applicationInfoService.isAccountExpanded || applicationInfoService.currentContact == null || applicationInfoService.isMobile()) || applicationInfoService?.projectInfo?.isClassicView">
            <div class="accountForm__section__designBorder"></div>
            <div [innerHTML]="innerHTMLAccountBonusPanel1"></div>
            <div [innerHTML]="innerHTMLAccount"></div>
            <div class="accountForm__account__header"><svg-icon src="assets/images/svg/iconset/05icon-adressen.svg" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }" style="margin-top: -4px"></svg-icon></div>
            <div class="accountForm__account__expander fingerCursor" (click)="applicationInfoService.isAccountExpanded = false"
              [title]="'Account.Label.CollapseAccount' | texttransform"
              *ngIf="applicationInfoService.isAccountExpanded && applicationInfoService.currentContact != null && !applicationInfoService.isMobile() && !applicationInfoService?.projectInfo?.isClassicView">
                <div><i class="fas fa-chevron-left"></i></div>
                <div><i class="fas fa-home"></i></div>
            </div>
          </div>
        </div>

        <div id="accountTaskSection" class="accountForm__task accountForm__section everleadShadowBox2"
          *ngIf="applicationInfoService.currentProject?.isUsingWorkflow == false" [ngClass]="{
            hideInDom: !checkTaskMaxWidthWidth(),
            largeModeNonClassic: !applicationInfoService.isAccountExpanded && !applicationInfoService?.projectInfo?.isClassicView
          }">
            <div *ngIf="applicationInfoService.applicationSettings['showQuestionaireTab'] == 'true'" class="accountQuestionnaire">
              <div class="accountQuestionnaire__button" (click)="selectedQuestionnaireId=0" [ngClass]="{ 
                everleadCoralBackground: selectedQuestionnaireId==0 }">{{ "Account.Label.General" | texttransform }}
              </div>
              <div class="accountQuestionnaire__button" (click)="showQuestionnaire(questionnaire)" *ngFor="let questionnaire of questionnaires"
                [ngClass]="{ everleadCoralBackground: selectedQuestionnaireId==questionnaire.id }">
                {{ questionnaire.defaultName | texttransform }}
              </div>
            </div>
            <app-singleaction *ngIf="selectedQuestionnaireId==0"></app-singleaction> 
            <div *ngIf="applicationInfoService.applicationSettings['showQuestionaireTab'] == 'true'">
              <div *ngIf="selectedQuestionnaireId!=0" [innerHTML]="innerHTMLQuestionaire"></div>
            </div>            
        </div>

        <div class="accountForm__task accountForm__section"
          *ngIf="applicationInfoService.currentTask &&
                 applicationInfoService.currentProject?.isUsingWorkflow == true"
        >
          <div [innerHTML]="innerHTMLTask" *ngIf="!applicationInfoService.accountPopupQMMode"></div>
          <div *ngIf="applicationInfoService.accountPopupQMMode">
            <app-qualitymanagement></app-qualitymanagement>
          </div>
          <!-- <div [innerHTML]="innerHTMLQM" *ngIf="applicationInfoService.accountPopupQMMode"></div> -->
        </div>
      </div>
      <div class="account__footer">
          <div [innerHTML]="innerHTMLFooter"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
