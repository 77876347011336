<div *ngIf="hideIdList" class="newTaskTaskCountContainer">
    <b>{{ newTask?.targetArray?.length }} Task(s) werden angelegt.</b>
    <br>
</div>

<label class="newTaskLabel">{{ 'NewTask.Label.Campaign' | texttransform }}</label>
<select [(ngModel)]="newTask.campaignId" class="newTaskDropdown">
    <option [ngValue]="campaign.id" *ngFor="let campaign of campaigns">{{ campaign.defaultName }}</option>
</select>
<br>

<span *ngIf="hideStep == false">
    <label class="newTaskLabel">{{ 'NewTask.Label.Workflow' | texttransform }}</label>
    <select [(ngModel)]="newTask.workflowId" (change)="workflowChanged()" class="newTaskDropdown">
        <option [ngValue]="workflow.id" *ngFor="let workflow of workflows">{{ workflow.defaultName }}</option>
    </select>
    
    <br>
    <label class="newTaskLabel">{{ 'NewTask.Label.Step' | texttransform }}</label>
    <select [(ngModel)]="newTask.stepId" class="newTaskDropdown">
        <option [ngValue]="workflowstep.id" *ngFor="let workflowstep of workflowsteps">{{ workflowstep.defaultName }}</option>
    </select>
    <br>    
</span>

<label class="newTaskLabel">{{ 'NewTask.Label.FollowUpUserId' | texttransform }}</label>
<select [(ngModel)]="newTask.followUpUserId" class="newTaskDropdown">
    <option [ngValue]="user.id" *ngFor="let user of users">{{ user.userName }}</option>
</select>
<br>

<span *ngIf="createMode=='multi' && hideStep == false">
    <label class="newTaskLabel">{{ 'NewTask.Label.Level' | texttransform }}</label>
    <select [(ngModel)]="newTask.level" (change)="levelChanged()" class="newTaskDropdown">
        <option ngValue=273>{{ 'NewTask.Label.AccountLevel' | texttransform }}</option>
        <option ngValue=274>{{ 'NewTask.Label.ContactLevel' | texttransform }}</option>
    </select>
    <br>
</span>

<label class="newTaskLabel">{{ 'NewTask.Label.FollowUpAppointment' | texttransform }}</label>
<input type="checkbox" [(ngModel)]="newTask.isFollowUpAppointment">
<br>

<label class="newTaskLabel">{{ 'NewTask.Label.FollowUpDate' | texttransform }}</label>
<input 
    [(ngModel)]="newTask.followUpDate"
    [owlDateTimeTrigger]="dt7" 
    [owlDateTime]="dt7" 
    readonly            
    class="newTaskDate"
    appendTo="body"
    aria-modal="true"
>
<!-- (dateTimeChange)="callMethod('onchange')"-->

<owl-date-time 
    #dt7
></owl-date-time>

<br>


<span *ngIf="createMode=='multi'">
    <label class="newTaskLabel">{{ 'NewTask.Label.AssignCp' | texttransform }}</label>
    <input type="checkbox" [(ngModel)]="newTask.assignCp" [disabled]="newTask.level == 274">
    <br>
    <span *ngIf="hideIdList == false">
        <label class="newTaskLabel">{{ 'NewTask.Label.IdList' | texttransform }}</label>
        <textarea (change)="targetChanged()" type="text" [(ngModel)]="newTask.target"></textarea>
        <br>    
    </span>
    <br>
</span>

<span *ngIf="createMode!='multi'">
    <button (click)="methodService.createNewTask(newTask)" [disabled]="newTask.stepId==null || newTask.followUpUserId == null" 
        class="jjButtonColor jjButtonStyle">{{ 'NewTask.Label.CreateNewTask' | texttransform }}</button>
</span>
<span *ngIf="createMode=='multi'">
    <button (click)="methodService.createNewTasks(newTask)" [disabled]="newTask.stepId==null || newTask.followUpUserId == null" 
        class="jjButtonColor jjButtonStyle">{{ 'NewTask.Label.CreateNewTasks' | texttransform }}</button>
</span>

<br>
