<!-- Default view -->
<div id="dataFilterMainPanel" *ngIf="mainPanelMode == 0">
    <!-- <div class="dataFilter__mainPanel__header">
      <button (click)="selectCurrentDataFilter(currentDataFilter)" [disabled]="currentDataFilter == null"
        title="{{ 'DataFilterMainPanel.Tooltip.SelectThisFilter' | texttransform }}"
        class="dataFilter__mainPanel__header__button"><i class="fas fa-check"></i></button>
      <button (click)="newDataFilter()" class="dataFilter__mainPanel__header__button"
        title="{{ 'DataFilterMainPanel.Tooltip.CreateNewFilter' | texttransform }}"><i class="fas fa-plus-square"></i></button>
      <label>{{ 'DataFilterMainPanel.Label.FilterNameHeader' | texttransform }}</label>
    </div> -->
    <div class="everleadSmallHeader">{{ 'DataFilterMainPanel.Label.FilterNameHeader' | texttransform }}</div>
    <div *ngIf="loadingdata == true" class="everleadShadowBox everleadSmallHeader">
      <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
    </div>
    <div class="dataFilter__infoLine__wrapper everleadShadowBox" *ngIf="loadingdata == false">
      <div class="dataFilter__infoLine">
        <label class="dataFilter__infoLine__item">{{ 'DataFilterMainPanel.Label.Filter' | texttransform }}</label>
        <div class="dataFilter__infoLine__item__wrapper">
          <select class="dataFilter__infoLine__item" (change)="changeDataFilter()" [(ngModel)]="dataFilterId">
            <option [ngValue]="-1" disabled selected hidden>{{ 'Control.Dropdown.Placeholder' | texttransform }}</option>
            <option *ngFor="let dataFilter of dataFilters" [ngValue]="dataFilter.id">
              {{ dataFilter.defaultName | texttransform }}</option>
          </select>
          <i class="fas fa-plus fingerCursor" (click)="newDataFilter()" [title]="'DataFilterMainPanel.Label.CreateNewDataFilter' | texttransform"></i>
        </div>
      </div>

      <div class="dataFilter__infoLine" *ngIf="currentDataFilter">
        <label class="dataFilter__infoLine__item">{{ 'DataFilterMainPanel.Label.FilterName' | texttransform }}</label>
        <input class="dataFilter__infoLine__item" [(ngModel)]="currentDataFilter.defaultName" (change)="saveCurrentDataFilter()">
      </div>

      <div class="dataFilter__infoLine" *ngIf="currentDataFilter">
        <label class="dataFilter__infoLine__item">{{ 'DataFilterMainPanel.Label.Entity' | texttransform }}</label>
        <select class="dataFilter__infoLine__item" (change)="changeMainEntityId()" [(ngModel)]="currentDataFilter.mainEntityId" disabled>
          <option *ngFor="let validEntity of validEntities" [ngValue]="validEntity.id">
            <ng-container *ngIf="!validEntity.nameTranslationToken">{{ validEntity.entityName | texttransform }}</ng-container>
            <ng-container *ngIf="validEntity.nameTranslationToken">{{ validEntity.nameTranslationToken.tokenFullName | texttransform }}</ng-container>
          </option>
        </select>
      </div>

      <div class="dataFilter__infoLine" *ngIf="currentDataFilter">
        <label class="dataFilter__infoLine__item">{{ 'DataFilterMainPanel.Label.Operator' | texttransform }}
          <app-wizard-infobutton displayText="DataFilterMainPanel.Label.OperatorDescription"></app-wizard-infobutton>
        </label>
        <select class="dataFilter__infoLine__item" (change)="changeOperator(currentOperator)" [(ngModel)]="currentOperator">
          <option *ngFor="let filterOperator of filterOperators" [ngValue]="filterOperator.id">{{ filterOperator.defaultName | texttransform }}</option>
        </select>
      </div>
      <div class="applyButton" *ngIf="currentDataFilter">
        <button class="jjButtonColor jjButtonStyle" (click)="selectCurrentDataFilter(currentDataFilter)">
          {{ 'DataFilterMainPanel.Label.ApplyFilter' | texttransform }}
        </button>
      </div>
    </div>

    <div *ngIf="currentDataFilter != null">
      <!-- Kriteriengruppen -->
      <label class="everleadSmallHeader noMargin">
        {{ 'DataFilterMainPanel.Label.FilterSegments' | texttransform }}
      </label>
      <div *ngFor="let filterSegment of filterSegments; let i = index">
        <div *ngIf="i > 0" class="dataFilterGroupOperator">
          <div class="everleadShadowBox dataFilter__mainPanel__criteriaGroupSpacer dataFilterGroupOperator__inner">
            {{ getCurrentOperator()?.defaultName | texttransform }}
          </div>
        </div>
        <div class="everleadShadowBox" style="position: relative">
          <app-datafilter-groupitem
            [filterSegment]="filterSegment"
            [filter]="currentDataFilter"
            [emailBatches]="emailBatches"
            [filterSegmentOperators]="filterSegmentOperators"
            [memberEntities]="validEntities"
            [operators]="filterSegmentMemberOperators"
          ></app-datafilter-groupitem>
        </div>
      </div>
      <div class="nonExpertView__newSubGroup fingerCursor" (click)="addNewFilterSegment()">
        <i class="fas fa-plus fingerCursor"></i>
        <label class="fingerCursor">{{ 'DataFilterMainPanel.Label.NewFilterSegment' | texttransform }}</label>
      </div>

      <label class="everleadSmallHeader noMargin">
        {{ 'DataFilterMainPanel.Label.Preview' | texttransform }}
        <app-wizard-infobutton displayText="DataFilterMainPanel.Label.PreviewDescription"></app-wizard-infobutton>
      </label>
      <div [innerHTML]="previewInnerHTML"></div>
    </div>
</div>

<!-- Create new dataFilter view -->
<div id="dataFilterCreateNewGroup" *ngIf="mainPanelMode == 1">
  <label class="newFilterHeader">{{ 'DataFilterMainPanel.Label.CreateNewDataFilterHeader' | texttransform }}</label>

  <div class="dataFilter__infoLine__wrapper everleadShadowBox">
    <div class="dataFilter__infoLine">
      <label class="dataFilter__infoLine__item">{{ 'DataFilterMainPanel.Label.CreateNewDataFilterName' | texttransform }}</label>
      <input class="dataFilter__infoLine__item" type="text" [(ngModel)]="newDataFilterName" class="maxWidth" minlength="3">
    </div>
    <div class="dataFilter__infoLine">
      <label class="dataFilter__infoLine__item">{{ 'DataFilterMainPanel.Label.CreateNewDataFilterOperator' | texttransform }}</label>
      <select class="dataFilter__infoLine__item" (change)="changeOperator(currentOperator)" [(ngModel)]="newDataFilterOperator">
        <option *ngFor="let filterOperator of filterOperators" [ngValue]="filterOperator.id">
          {{ filterOperator.defaultName | texttransform }}</option>
      </select>
    </div>
  </div>

  <div class="dataFilter__infoLine everleadShadowBox">
    <div [innerHTML]="'DataFilterMainPanel.Label.FilterOperatorDescription' | texttransform "
      class="maxWidth"></div>
  </div>
  <div>
    <button class="jjButtonColor jjButtonStyle" (click)="createNewDataFilter()" [disabled]="newDataFilterName.length < 2">
      {{ 'DataFilterMainPanel.Label.CreateNewDataFilter' | texttransform }}
    </button>
    <button class="jjButtonColor jjButtonStyle" (click)="cancelNewDataFilter()">
      {{ 'DataFilterMainPanel.Label.CancelNewDataFilter' | texttransform }}
    </button>
  </div>
</div>
