<div>
  <img src="assets/images/EVERLEAD_HeaderC-240x1920-flames55.jpg" alt="Everlead" title="Everlead" style="height: 200px;">    
</div>
<br>
<div class="applicationNameLabel jjTopHeaderFont centerVertically">
  <h4>Everlead</h4>
</div>
<br>
Derzeit besteht ein Problem mit der Anwendung, bitte versuchen Sie es später erneut.<br>
There is currently a problem with the application, please try again later.<br>
<br>
<button class="jjButtonStyle fingerCursor"
  (click)="returnToMainpage()">
  Startseite / Start page
</button>