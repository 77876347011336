<ng-container *ngIf="loadingData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</ng-container>
<div *ngIf="loadingData == false" class="eventTrigger__wrapper">
    <i class="fas fa-plus fingerCursor" style="margin-right: 15px" (click)="createNewTrigger()"></i>
    <label class="headerLabel"><span *ngIf="result != null">{{ result.defaultName }}: </span> {{ 'EventTrigger.Label.EventTrigger' | texttransform }}</label>
    <div *ngFor="let eventTriggerGroup of eventTriggerGroups">

    <label class="sectionLabel">{{ eventTriggerGroup.name | texttransform }}</label><hr>
    <div *ngFor="let event of eventTriggerGroup.data" class="eventTriggerItem everleadShadowBox">
        <div class="row maxWidth">
            <div class="col-md-6">
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.SubUrl' | texttransform }}</label>
                <input [(ngModel)]="event.subUrl" class="eventTriggerItem__textBox" (change)="changeRow(event)"><br>
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.RegexUrl' | texttransform }}</label>
                <input [(ngModel)]="event.regexUrl" class="eventTriggerItem__textBox" (change)="changeRow(event)"><br>
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.RegExData' | texttransform }}</label>
                <input [(ngModel)]="event.regexData" class="eventTriggerItem__textBox" (change)="changeRow(event)">
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.EventSourceId' | texttransform }}</label>
                <select class="eventTriggerItem__select" [(ngModel)]="event.eventSourceId" (change)="changeRow(event)"
                    [tippy]="'EventTrigger.Label.EventSourceId' | texttransform">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let eventSource of eventSources" [ngValue]="eventSource.id">
                        {{ eventSource.defaultName | texttransform }}
                    </option>
                </select><br>
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.EventType' | texttransform }}</label>
                <select class="eventTriggerItem__select" [(ngModel)]="event.eventType" (change)="changeRow(event)"
                    [tippy]="'EventTrigger.Label.EventType' | texttransform">
                    <option *ngFor="let eventTriggerType of eventTriggerTypes" [ngValue]="eventTriggerType">{{ eventTriggerType }}</option>
                </select>
            </div>

            <div class="col-md-5">
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.IsCreateNewTask' | texttransform }}</label>
                <input [(ngModel)]="event.isCreateNewTask" type="checkbox" (change)="changeRow(event)"><br>
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.NewTaskWorkflowStepId' | texttransform }}</label>
                <select class="eventTriggerItem__select" [(ngModel)]="event.newTaskWorkflowStepId"
                    (change)="stepChanged(event)" [tippy]="'EventTrigger.Label.NewTaskWorkflowStepId' | texttransform">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let step of steps" [ngValue]="step.id">
                        {{ step.defaultName | texttransform }}
                    </option>
                </select><br>
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.NewTaskWorkflowResultId' | texttransform }}</label>
                <select class="eventTriggerItem__select" [(ngModel)]="event.newTaskWorkflowResultId"
                    (change)="changeRow(event)"
                    [tippy]="'EventTrigger.Label.NewTaskWorkflowResultId' | texttransform">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let result of getResults(event)" [ngValue]="result.id">
                        {{ result.defaultName | texttransform }}
                    </option>
                </select><br>
                <label class="eventTriggerItem__label">{{ 'EventTrigger.Label.NewTaskCampaignId' | texttransform }}</label>
                <select class="eventTriggerItem__select" [(ngModel)]="event.newTaskCampaignId" 
                    (change)="changeRow(event)" [tippy]="'EventTrigger.Label.NewTaskCampaignId' | texttransform">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">
                        {{ campaign.defaultName | texttransform }}
                    </option>
                </select>
            </div>

            <div class="col-md-1 eventTriggerItem__icons">
                <i class="far fa-lightbulb fingerCursor eventTriggerItem__icon" (click)="toggleEvent(event)" [ngClass]="{
                    eventTriggerItem__bulp__active: isActivated(event)
                }"></i>
                <i class="fas fa-trash fingerCursor eventTriggerItem__icon" (click)="deleteTrigger(event)"></i>
            </div>
        </div>
        <div class="row maxWidth">
            <div class="col-md-6">
                <label class="eventTriggerItem__label">PreSQL</label>
                <textarea [(ngModel)]="event.handlebarsjsPreExecute" class="eventTriggerItem__textBox" (change)="changeRow(event)"></textarea>
            </div>

            <div class="col-md-6">
                <label class="eventTriggerItem__label">PostSQL</label>
                <textarea [(ngModel)]="event.handlebarsjsPostExecute" class="eventTriggerItem__textBox" (change)="changeRow(event)"></textarea>
            </div>
        </div>
    </div>
</div>
</div>

