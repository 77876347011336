<span id="emailtemplatesDesignSpan"></span>
<div [style.height.px]="uiService.getDesignSpanPosition('emailtemplatesDesignSpan', 40)" class="emailTemplates__wrapper">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createNewTemplate()" *ngIf="userService.hasPermission('CanCreateEmailTemplate')">
            <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }" 
            *ngIf="userService.hasPermission('CanCreateEmailTemplateContent')" [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{ theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
            {{ 'EMailTemplate.Label.NewTemplate' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">       
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="emailTemplates__content">
        <div class="nonExpertView__main nonExpertView__main__noSubitems everleadShadowBox" *ngFor="let eMailTemplate of eMailTemplates" [ngClass]="{
            hideInDom: checkIfItemIsFiltered(eMailTemplate),
            fingerCursor: userService.hasPermission('CanUpdateEmailTemplate')
        }" (click)="showWizard(eMailTemplate)">
            <div class="nonExpertView__name">  
                <div class="everleadCoral emailTemplates__content__id">{{ eMailTemplate.id }}</div>
                <label>{{ eMailTemplate.defaultName }}</label>
            </div>
            <!-- <div class="nonExpertView__bonus" [title]="'General._.Edit' | texttransform" (click)="showWizard(eMailTemplate)">            
                <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button"></i>
            </div> -->
        </div>
    </div>
</div>