<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
            texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
            texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="everleadSmallHeader">
                {{ 'Task.Label.CreationGuidDescription' | texttransform }}
                <app-setting-required></app-setting-required>
            </div>
            <div class="wizardDetailItem">
                <input [(ngModel)]="wizardService.wizardArray.batchName" type="text" class="maxWidth" (change)="updateEMailBatch()">
            </div>
            <div class="everleadSmallHeader" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'">
                {{ 'Task.Label.Campaign' | texttransform }}
                <app-setting-required></app-setting-required>
            </div>
            <div class="wizardDetailItem" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'">
                <select [(ngModel)]="wizardService.wizardArray.campaignId" class="maxWidth" (change)="updateEMailBatch()">
                    <option [ngValue]=-1>{{ 'Task.Label.NewCampaign' | texttransform }}</option>
                    <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">{{ campaign.defaultName }}
                    </option>
                </select>
            </div>
            <div *ngIf="wizardService.wizardArray.campaignId == -1">
                <div class="everleadSmallHeader">{{ 'Task.Label.CampaignName' | texttransform }}</div>
                <div class="wizardDetailItem">
                    <input [(ngModel)]="wizardService.wizardArray.campaignName" type="text" class="maxWidth">
                </div>
                <button class="jjButtonStyle jjButtonColor" (click)="createNewCampaign()" [disabled]="">
                    {{ 'Task.Label.CreateCampaign' | texttransform }}
                </button>
            </div>
        </div>


        <!-- <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2 && wizardService.wizardArray.newsletter == true" 
            class="maxHeight wizardNewsletterWrapper">
            <div class="wizardNesletterHeader">{{'Newsletter.Label.MicroCampaignBaseDescription'|texttransform}}</div>
            <div class="wizardNesletterCampaignList">
                <div *ngFor="let microcampaign of microcampaignService.microcampaigns" class="everleadShadowBox wizardNesletterCampaignListItem" 
                [ngClass]="{ wizardNesletterCampaignListItem__selected: wizardService.wizardArray.newsletterMicroCampaignResultId == microcampaign.id }"
                (click)="setMicroCampaignIdForNewsletter(microcampaign.id)">
                    {{ microcampaign.defaultName}}
                </div>
            </div>
        </div> -->

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="myLeadsFilterHeader__wrapper">
                <div *ngIf="applicationInfoService.myLeadsSelectedFilter != null" class="myLeadsFilterHeader">
                    <i class="fas fa-filter"></i>
                    <label>{{ applicationInfoService.myLeadsSelectedFilter.defaultName }}</label>
                    <i class="fas fa-trash fingerCursor" (click)="clearDataFilter();$event.stopPropagation()"></i>
                    <i class="fas fingerCursor" (click)="filterExpanded = !filterExpanded" [ngClass]="{
                'fa-chevron-down': !filterExpanded,
                'fa-chevron-up': filterExpanded
              }"></i>
                </div>

                <div *ngIf="applicationInfoService.myLeadsSelectedFilter == null" class="myLeadsFilterHeader">
                    <i class="fas fa-filter"></i>
                    <label class="fingerCursor">{{ 'MyLeads.Label.NoFilterSelected' | texttransform }}</label>
                    <i class="fas fa-edit customTextControlWrapper__button fingerCursor"
                        (click)="editDataFilter();$event.stopPropagation()"></i>
                </div>
            </div>
            <div>
                <div *ngIf="filterExpanded">
                    <div *ngFor="let filterSegment of applicationInfoService?.myLeadsSelectedFilter?.filterSegments; let i = index">
                        <div *ngIf="i > 0" class="everleadShadowBox dataFilter__mainPanel__criteriaGroupSpacer">AND</div>
                        <div>
                            <app-datafilter-groupitem [filterSegment]="filterSegment" [filter]="selectedFilter"
                                [filterSegmentOperators]="filterSegmentOperators" [memberEntities]="validEntities"
                                disabled=true [operators]="filterSegmentMemberOperators"></app-datafilter-groupitem>
                        </div>
                    </div>
                </div>
            </div>
            <app-custom-universal controlid=201178></app-custom-universal>
        </div>

        <div [ngClass]="{ hideInDom: wizardService.pages[wizardService.wizardPage - 1]?.pageId != 3 }" class="maxHeight columnFlex">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{'EMailTemplate.Label.Template'|texttransform}}</label>
                <select class="wizardDropDown" [(ngModel)]="wizardService.wizardArray.templateSource" (change)="templateSourceChanged()">
                    <option value="1">{{'Task.Label.ProcessBased'|texttransform}}</option>
                    <option value="2">{{'Task.Label.NewTemplate'|texttransform}}</option>
                </select>    
            </div>
            <div class="maxHeight noOverflow">
                <div [ngClass]="{ hideInDom: wizardService.wizardArray.templateSource != 1 }" class="columnFlex maxHeight noOverflow">
                    <div class="wizardDetailItem everleadHorizontalFlex" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'">
                        <label class="noPadding wizardLabel">{{'Task.Label.Process'|texttransform}}</label>
                        <select [(ngModel)]="wizardService.wizardArray.workflowId" (change)="changeWorkflow(wizardService.wizardArray.workflowId, true)" 
                            class="wizardDropDown">
                            <option *ngFor="let workflow of workflows" [ngValue]="workflow.id"
                                [ngClass]="{ hideInDom: checkIfWorkflowNeedsToBeHidden(workflow) }">
                                {{ commonService.getNameTranslationOrDefaultName(workflow) }}
                            </option>
                        </select>    
                        <i class="fas fa-sync fingerCursor" (click)="getWorkflows()"></i>
                        <i class="fas fa-edit fingerCursor" (click)="openProjectSettingsInNewTab()"></i>                        
                    </div>
                    <div class="wizardDetailItem everleadHorizontalFlex">
                        <label class="noPadding wizardLabel">
                            {{'Task.Label.Step'|texttransform}}
                            <app-setting-required></app-setting-required>
                        </label>
                        <select [(ngModel)]="wizardService.wizardArray.stepId" class="wizardDropDown"
                            (change)="changeStep()">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let step of steps" [ngValue]="step.id" [ngClass]="{ hideInDom: checkIfStepNeedsToBeHidden(step) }">
                               {{ commonService.getNameTranslationOrDefaultName(step) }}
                            </option>
                        </select>
                        <i class="fas fa-envelope fingerCursor" (click)="getPreview(wizardService.wizardArray.stepId, false);$event.stopPropagation()"
                            *ngIf="wizardService.wizardArray.stepId != null" 
                            [tippy]="'BookingPreview.Label.ShowPreview' | texttransform"></i>
                    </div>                

                    <div class="d-flex previewContainer" [ngClass]="{ hideInDom: wizardService.wizardArray.stepId == null }">
                        <div class="autoOverflowY maxWidth maxHeight infoPadding">
                            <app-booking-preview showEmailPreview="true"></app-booking-preview>
                        </div>
                        <div class="autoOverflowY maxWidth maxHeight infoPadding">                        
                            <div class="bookingPreviewContainer" [innerHTML]="innerHTMLEMailPreview"></div>
                        </div>    
                    </div>
                </div>

                <div class="maxHeight" [ngClass]="{ hideInDom: wizardService.wizardArray.templateSource != 2 }">
                    <div class="editorWrapper">                        
                        <div class="editorWrapper__left">
                            <div class="textWrapperRow">
                                <label>{{ 'EMailTemplate.Label.Subject' | texttransform }}</label>
                                <input [(ngModel)]="wizardService.wizardArray.subject" (change)="updateEMailBatch()">
                            </div>   
                            <app-htmleditor class="maxHeight" [htmlText]="wizardService.wizardArray.templateText" 
                                (valueStatusChange)="htmlEdit($event)"></app-htmleditor>
                        </div>                 
                        <div class="editorWrapper__right">
                            <div class="maxHeight noOverflow editorWrapper__navigation">
                                <div class="textWrapperColumn">
                                    <label>{{ 'EMailTemplate.Label.TemplateName' | texttransform }}</label>
                                    <input [(ngModel)]="wizardService.wizardArray.templateName" class="maxWidth" (change)="updateEMailBatch()">
                                    <label>{{ 'EMailTemplate.Label.Language' | texttransform }}</label>
                                    <select [(ngModel)]="wizardService.wizardArray.templateLanguageId" class="maxWidth" 
                                        (change)="getLanguageContent()" [disabled]="wizardService.wizardArray.createdTemplate == null">
                                        <option [ngValue]=2>Deutsch</option>
                                        <option [ngValue]=1>English</option>
                                    </select> 
                                </div>    
                                <div class="textWrapperColumn">
                                    <label>{{ 'EMailTemplate.Label.Sender' | texttransform }}</label>
                                    <input [(ngModel)]="wizardService.wizardArray.sender" class="maxWidth"
                                        [placeholder]="'EMailTemplate.Label.SenderTooltip' | texttransform"
                                        (change)="checkDomain()" [disabled]="applicationInfoService?.projectID == 164"
                                    >
                                </div>    
                                <div *ngIf="wizardService.wizardArray.senderDomainValid == false" class="everleadSmallHeader">
                                    {{ 'EMailTemplate.Label.SenderDomainInvalid' | texttransform }}
                                </div>
                                <div class="textWrapperColumn boilerPlateWrapper">
                                    <label>{{ 'EMailTemplate.Label.AddBoilerplate' | texttransform }}</label>
                                    <div class="maxHeight autoOverflowY maxWidth">
                                        <div class="emailToolbar__item__emailContent fingerCursor" [tippy]="emailContent.content"
                                            *ngFor="let emailContent of emailContents" (click)="addMemberToTemplate(emailContent)">
                                            <!-- <i class="fas fa-plus fingerCursor" ></i> -->
                                            <div class="emailToolbar__item__emailContent__name">{{ emailContent.defaultName }}</div>                                            
                                        </div>                      
                                    </div>
                                </div>
                            </div>
                            <div class="createTemplateButton">
                                <div *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true'" class="everleadCoral everleadSmallFontSize">
                                    {{ 'EMailTemplate.Label.CantCreateTemplateInDemo' | texttransform }}
                                </div>

                                <button *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true' && 
                                    wizardService.wizardArray.templateSaved == true" class="everleadCustomButton maxWidth"
                                    (click)="updateTemplate()">
                                    {{ 'EMailTemplate.Label.UpdateTemplate' | texttransform }}
                                </button>
                                <button (click)="saveTemplate()" class="jjButtonStyle jjButtonColor maxWidth" 
                                    [disabled]="
                                        wizardService.wizardArray.sender == '' || 
                                        wizardService.wizardArray.templateName == '' ||
                                        wizardService.wizardArray.subject == '' ||
                                        this.wizardService.wizardArray.senderDomainValid != true"
                                        *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true' && 
                                            !wizardService.wizardArray.templateSaved">
                                        {{ 'EMailTemplate.Label.CreateTemplate' | texttransform }}
                                </button>    
                            </div>
                        </div>       
                    </div>                    
                </div>                
            </div>
        </div>



        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4 && wizardService.wizardArray.stepId == null" class="maxHeight">
            <div class="columnFlex">
                <label class="bookingEntityMemberLabel" *ngIf="wizardService.wizardArray.templateSource==1">{{ 'Task.Label.PleaseSelectStep' | texttransform }}</label>
                <label class="bookingEntityMemberLabel" *ngIf="wizardService.wizardArray.templateSource==2">{{ 'Task.Label.PleasePublishTemplate' | texttransform }}</label>
            </div>
        </div>            

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4 && wizardService.wizardArray.stepId != null" class="maxHeight">
            <div class="columnFlex">
                <div>
                    <label class="bookingEntityMemberLabel">{{ 'Task.Label.TestEMailReceiver' | texttransform
                        }}</label>
                    <select [(ngModel)]="testMailUser" (change)="testMailAdress = testMailUser.email"
                        class="bookingDropdown maxWidth">
                        <option *ngFor="let user of users" [ngValue]="user">
                            {{ user.userName }}
                        </option>
                    </select>
                </div>
                <div class="sendMailContainer__adress">
                    <label class="bookingEntityMemberLabel">{{'Task.Label.TestEMailReceiverAdress'|axivastranslate}}</label>
                    <input type="text" [(ngModel)]="testMailAdress">
                </div>
                <div class="sendMailContainer__infoContainer">
                    <label class="sendMailContainer__infoLabel">{{'Task.Label.FirstContactWillBePickedForTestMail'|axivastranslate}}:</label>
                    <br>
                    <label class="sendMailContainer__infoLabel everleadCoral"
                        *ngIf="applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters']?.length > 0">
                        {{ applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'][0]['_4_firstname'] }} 
                        {{ applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'][0]['_4_lastname'] }}
                    </label>
                </div>
            </div>
            
            <button class="jjButtonStyle jjButtonColor bookingButton" (click)="testMail()"
                [disabled]="
                this.wizardService.wizardArray.testContact == null || 
                this.wizardService.wizardArray.testContact == undefined || 
                testMailAdress == '' ||
                wizardService.wizardArray.campaignId == null ||
                wizardService.wizardArray.campaignId == undefined"
            >{{'CreateTask.Label.TestEMail'| texttransform }}</button>                
        </div>

    </div>
</div>