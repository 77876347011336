import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApplicationInfoService } from '../application/application-info.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  public baseRef: string;

  constructor(
    private applicationInfoService: ApplicationInfoService
  ) {
    this.baseRef = environment.baseRef;
  }

  ngOnInit() {
    this.applicationInfoService.showLoadProjectSpinner = false;
    this.applicationInfoService.errorOccured = true;
  }

}
