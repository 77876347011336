import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { QuestionaireService } from 'app/jollyjupiter/service/questionaire.service';
import { Subscription } from 'rxjs';
import { JJEvent } from 'app/jollyjupiter/service/event.service';
import { Dictionary } from 'app/core/dictionary';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-custom-questionnaire',
  templateUrl: './custom-questionnaire.component.html',
  styleUrls: ['./custom-questionnaire.component.scss']
})
export class CustomQuestionnaireComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updateQuestionaireDataEventSubscription: Subscription = new Subscription();
  updateContactQuestionaireEventSubscription: Subscription = new Subscription();

  public questionaires = [];
  public selectedTabIndex = 0;
  questionaireIdList: Dictionary<any> = new Dictionary<any>();

  constructor(
    private injector: Injector,
    private questionaireService: QuestionaireService,
    private designerService: DesignerService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnDestroy() {
    if (this.updateQuestionaireDataEventSubscription) { this.updateQuestionaireDataEventSubscription.unsubscribe(); }
    if (this.updateContactQuestionaireEventSubscription) { this.updateContactQuestionaireEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.controlDefinition.childs.forEach(element => {
      if (this.selectedTabIndex === 0) {
        this.selectedTabIndex = element.logicalControlId;
      }
    });
    this.showQuestionairData();

    this.updateQuestionaireDataEventSubscription = this.eventService.updateQuestionaireDataEvent.subscribe(() => {
      this.showQuestionairData();
    });
    this.updateContactQuestionaireEventSubscription = this.eventService.updateContactQuestionaireEvent.subscribe(() => {
      this.showQuestionairData(true);
    });
  }

  showQuestionairData(onlyContact = false) {
    this.questionaireIdList = new Dictionary<any>();
    this.addQuestionaryId(this.controlDefinition);
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentAccount)) {
      this.questionaireIdList.impArray.items.forEach(element => {
        let loadQuestionaireData = true;
        if (onlyContact && this.applicationInfoService.entities.Item(element).lookupEntityDynamicalType.id !== 26) {
          loadQuestionaireData = false;
        }
        if (loadQuestionaireData) {
          this.questionaireService.loadQuestionaireData(element).then(() => {})
          .catch(error => { console.error('showQuestionairData', error); });
        }
      });
    }
  }

  addQuestionaryId(controlChilds: any) {
    controlChilds.childs.forEach(child => {
      if (child.uiEntityInstanceId) {
        if (!this.questionaireIdList.ContainsKey(child.uiEntityInstanceId)) {
          this.questionaireIdList.Add(child.uiEntityInstanceId, '');
        }
      }
      if (child.childs.length > 0) {
        this.addQuestionaryId(child);
      }
    });
  }

  setCurrentChild(selectedQuestionaire) {
    this.selectedTabIndex = selectedQuestionaire.logicalControlId;
  }

  editSubControls(logicalControlId) {
    this.externalDatasourceService.executeExternalDataSource(160, [logicalControlId])
    .then(editSubControlsQueryResult => {
      editSubControlsQueryResult.forEach(child => {
        this.commonService.toggleItemExpanded(this.designerService.designMenuEnabledArray, child.id);
      });
    });
  }
}
