import { Component, OnInit, Injector, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { Subscription } from 'rxjs';
import { Dictionary } from 'app/core/dictionary';
import { JJEvent } from 'app/jollyjupiter/service/event.service';
import { ClickContext } from 'app/jollyjupiter/service/common.service';
import { isNullOrUndefined } from 'util';
import { CompactListDataService } from './compact-list-data.service';
import { CompactListVisualsService } from './compact-list-visuals.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-custom-compactlist',
  templateUrl: './custom-compactlist.component.html',
  styleUrls: ['./custom-compactlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class CustomCompactlistComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  @Input() additionalValues = '';
  clickContext = new ClickContext();
  cellClickedEvent = this.clickContext.clickHandler((param) => { this.cellClicked(param); });
  cellDblClickedEvent = this.clickContext.dblClickHandler((param) => { this.cellDblClicked(param); });
  externalDataSourceResults: Dictionary<any> = new Dictionary();
  dataDict: Dictionary<any> = new Dictionary();
  dataTextDict: Dictionary<any> = new Dictionary();
  dataTextDictControlsUpdated = [];
  exportCompactListDataSubscription: Subscription = new Subscription();
  updateCompactListDataPagingEventSubscription: Subscription = new Subscription();
  updateSubControlAttributesEventSubscription: Subscription = new Subscription();
  compactListRemoveItemFromArraySubscription: Subscription = new Subscription();
  updateCompactListDataEventSubscription: Subscription = new Subscription();
  updateControlContentEventSubscription: Subscription = new Subscription();
  updateControlContentByControlTypeEventSubscription: Subscription = new Subscription();
  compactListSelectAllEventSubscription: Subscription = new Subscription();
  compactListShowOnlyDataEventSubscription: Subscription = new Subscription();
  compactListUpdateDataContentEventSubscription: Subscription = new Subscription();
  compactListUpdateExternalDataSourcesEventSubscription: Subscription = new Subscription();
  calculatingData = false;
  dataCount = 0;
  selectCheckbox = false;
  allItemsSelected = false;
  // externalDataSourceResults: Dictionary<any> = new Dictionary();
  selectedRows: any[];

  // Filter
  filteredDataCount = 0;
  filterValueArray: any[] = [];
  filteredItemsDisplay = [];
  filterEnabled = true;
  showFilterInput = false;
  generalFilter = '';

  // Sorting
  sortingArray: any[] = [];

  // Click & Doubleclick
  clickTimer: any;
  clickDelay = 300;
  preventClick = false;

  // Style
  containerClientHeight = 0;
  styleSpanClientHeight = 0;
  tdWidthArray: any[] = [];

  // Pagination
  externalDatasourcePagination = false;
  paginationEnabled = true;
  paginationCurrentPage = 1;
  paginationMaxPages = 10;
  paginationItemsPerPage = 50;
  pageStartItem = 0;

  showRowSelectCheckBox = false;

  displayedColumns: string[] = [];

    constructor(
    private injector: Injector,
    private compactListDataService: CompactListDataService,
    private compactListVisuals: CompactListVisualsService,
  ) {
    super(injector);
    this.valueType = ValueType.List;
  }

  // TODO: Refactor sobald Zeit da ist

  ngOnInit() {
    this.getDisplayedColumns();
    this.compactListDataService.fillExternalDataSourceDictionary(this.controlDefinition).then(fillExternalDataSourceDictionaryResult => {
      this.externalDataSourceResults = fillExternalDataSourceDictionaryResult;
      this.updateContent();
    });
    this.checkAttributes();

    this.getAdditionalAttribues();

    this.updateSubControlAttributesEventSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getDisplayedColumns();
    });

    this.compactListUpdateExternalDataSourcesEventSubscription =
      this.eventService.compactListUpdateExternalDataSourcesEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event)) {
        this.compactListDataService.fillExternalDataSourceDictionary(this.controlDefinition)
        .then(fillExternalDataSourceDictionaryResult => {
          this.externalDataSourceResults = fillExternalDataSourceDictionaryResult;
        });
      }
    });

    this.compactListSelectAllEventSubscription = this.eventService.compactListSelectAllEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event)) {
        this.selectAllRows();
      }
    });

    this.exportCompactListDataSubscription = this.eventService.exportCompactListDataEvent.subscribe(event => {
      if (event.controlId === this.controlDefinition.logicalControlId) {
        if (event.onlyIfCheckBoxesActive) {
          if (this.selectCheckbox) {
            this.compactListDataService.exportData(this.controlDefinition, this.filteredItemsDisplay, event.fileNameAddition);
          }
        } else {
          this.compactListDataService.exportData(this.controlDefinition, this.filteredItemsDisplay, event.fileNameAddition);
        }
      }
    });

    this.compactListRemoveItemFromArraySubscription = this.eventService.compactListRemoveItemFromArray.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.removeItemFromArrayById(event.arguments[0]);
      }
    });

    this.compactListUpdateDataContentEventSubscription = this.eventService.compactListUpdateDataContentEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.updateDataContent(event.arguments[0]);
      }
    });

    this.updateControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.updateContent();
      }
    });

    this.compactListShowOnlyDataEventSubscription = this.eventService.compactListShowOnlyDataEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.data = event.arguments[0];
        this.setFilteredItems(event.arguments[0]);
      }
    });

    this.updateCompactListDataEventSubscription = this.eventService.updateControlDataEvent.subscribe(event => {
      // console.warn('updateCompactListDataEventSubscription', this.controlDefinition.logicalControlId, event.arguments[0][0]);
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.createDataArray(event.arguments[0][0]).then(() => {
          this.loadingControlData = false;
          this.addFinalDataLayerToData(this.data);
          this.setFilteredItems(JSON.parse(JSON.stringify(this.data)));
          this.loadingControlData = false;
        });
      }
    });

    this.eventService.showListControlDataEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.filteredItemsDisplay = event.arguments[0][0].data;
        this.data = event.arguments[0][0].data;
        this.dataCount = event.arguments[0][0].data.length;
      }
    });

    this.updateCompactListDataPagingEventSubscription = this.eventService.updateControlDataPagingEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.externalDatasourcePagination = true;
        this.paginationCurrentPage = event.arguments[0][1].pageNumber;
        this.paginationItemsPerPage = event.arguments[0][1].pageSize;
        this.createDataArray(event.arguments[0][0], event.arguments[0][1].dataCount).then(() => {
          this.loadingControlData = false;
          this.addFinalDataLayerToData(this.data);
          this.setFilteredItems(JSON.parse(JSON.stringify(this.data)));
          this.loadingControlData = false;
          this.filteredDataCount = event.arguments[0][1].numRecords;
        });
      }
    });

    this.updateControlContentByControlTypeEventSubscription = this.eventService.updateControlContentByControlTypeEvent.subscribe(event => {
      if (this.controlDefinition.controlType === event.target) {
        this.updateContent();
      }
    });
  }

  getAdditionalAttribues() {
    if (this.controlDefinition.additionalCssClasses) {
      if (this.controlDefinition.additionalCssClasses.includes('jjCompactListSelectBox') > 0) { this.selectCheckbox = true; }
      // if (this.controlDefinition.additionalCssClasses.includes('jjCompactListPagination') > 0) { this.paginationEnabled = true; }
    }
    if (this.additionalValues) {
      if (this.additionalValues.includes('jjCompactListSelectBox')) { this.selectCheckbox = true; }
      // if (this.additionalValues.includes('jjCompactListPagination')) { this.paginationEnabled = true; }
    }
  }

  removeItemFromArrayById(itemId: any) {
    this.filteredItemsDisplay.forEach((item, index) => {
      if (Number(item.id) === Number(itemId)) {
          this.filteredItemsDisplay.splice(index, 1);
      }
    });
    this.data.forEach((item, index) => {
      if (Number(item.id) === Number(itemId)) {
        this.data.splice(index, 1);
      }
    });
    this.dataCount = this.data.length;
    this.filteredDataCount = this.filteredItemsDisplay.length;
  }

  toggleItem(toggleName: string) {
    this[toggleName] = !this[toggleName];
  }

  selectAllRows() {
    if (this.allItemsSelected) {
      this.selectedRows = [];
    } else {
      this.selectedRows = [];
      this.filteredItemsDisplay.forEach(dataItem => {
        this.selectedRows.push(dataItem);
      });
    }
    this.allItemsSelected = !this.allItemsSelected;
    this.sendListInformation();
  }

  ngOnDestroy() {
    if (this.compactListUpdateDataContentEventSubscription) { this.compactListUpdateDataContentEventSubscription.unsubscribe(); }
    if (this.updateSubControlAttributesEventSubscription) { this.updateSubControlAttributesEventSubscription.unsubscribe(); }
    if (this.updateControlContentEventSubscription) { this.updateControlContentEventSubscription.unsubscribe(); }
    if (this.updateControlContentByControlTypeEventSubscription) { this.updateControlContentByControlTypeEventSubscription.unsubscribe(); }
    if (this.updateCompactListDataEventSubscription) { this.updateCompactListDataEventSubscription.unsubscribe(); }
    if (this.compactListSelectAllEventSubscription) { this.compactListSelectAllEventSubscription.unsubscribe(); }
    if (this.compactListShowOnlyDataEventSubscription) { this.compactListShowOnlyDataEventSubscription.unsubscribe(); }
    if (this.compactListRemoveItemFromArraySubscription) { this.compactListRemoveItemFromArraySubscription.unsubscribe(); }
    if (this.exportCompactListDataSubscription) { this.exportCompactListDataSubscription.unsubscribe(); }
    if (this.updateCompactListDataPagingEventSubscription) { this.updateCompactListDataPagingEventSubscription.unsubscribe(); }
    if (this.compactListUpdateExternalDataSourcesEventSubscription) {
      this.compactListUpdateExternalDataSourcesEventSubscription.unsubscribe(); }
  }

  getMatName(childId: any, prefix: any = 'mat') {
    return prefix + this.controlGUID + childId;
  }

  getFilterInputId() {
    return 'compactListFilter' + this.controlDefinition.id;
  }

  updateDataContent(newContent: any) {
    this.setFilteredItems(newContent);
  }

  updateContent() {
    if (!this.controlDefinition.isActive ) {
      return;
    }

    this.selectedRows = [];
    if (this.externalDataSource) {
      this.dataKey = this.externalDataSource.dataKey;
      this.loadingControlData = true;
      const namedArray = [];
      namedArray.push('<compactListSubControls>;' + this.uiService.getQueryFieldsFromListControl(this.controlDefinition));
      this.externalDatasourceService.executeQuery(this.externalDataSource, [], 0, false, namedArray)
        .then(data => {
          this.loadingControlData = false;
          this.createDataArray(data)
          .then(() => {
            this.addFinalDataLayerToData(this.data);
            this.setFilteredItems(JSON.parse(JSON.stringify(this.data)));
          })
          .catch(() => { this.loadingControlData = false; });
        })
        .catch(error => {
          this.loadingControlData = false;
          this.messagingService.showDebugMessageInConsole('updateContent error: ' + error + ' controlId: ' + this.controlDefinition.id);
        });
    }
  }

  getTdHeight() {
    if (this.applicationInfoService.useNewDesign) {
      return 40;
    } else {
      return 25;
    }
  }

  showFilter() {
    this.showFilterInput = !this.showFilterInput;
    if (this.showFilterInput) {
      const filter = this.getFilterInputId();
      if (filter) {
        setTimeout(() => {
          document.getElementById(filter).focus();
        }, 100);
      }
    }
  }


  getBodyHeightInPixel(): any {
    const thead = document.getElementById(this.getMatName(this.controlDefinition.id, 'thead'));
    if (thead) {
      return this.getTableHeightInPixel(0, 'getBodyHeightInPixel') - thead.offsetHeight - 4;
    } else {
      return 0;
    }
  }

  getTableHeightInPixel(modifier: any = 0, source = ''): number {
    let tableHeightInPixel = 0;
    const container = document.getElementById(this.getMatName(this.controlDefinition.id, 'compactList'));
    const styleSpan = document.getElementById(this.getMatName(this.controlDefinition.id, 'headerDiv'));
    const paginationSpan = document.getElementById(this.getMatName(this.controlDefinition.id, 'paginationDiv'));
    if (container && styleSpan && paginationSpan) {
      let containerClientHeight = container.clientHeight;
      if (containerClientHeight === 0) {
        containerClientHeight = this.containerClientHeight;
      } else {
        this.containerClientHeight = containerClientHeight;
      }
      let styleSpanClientHeight = styleSpan.clientHeight;
      if (styleSpanClientHeight === 0) {
        styleSpanClientHeight = this.styleSpanClientHeight;
      } else {
        this.styleSpanClientHeight = styleSpanClientHeight;
      }

      tableHeightInPixel = Math.floor(containerClientHeight - styleSpanClientHeight);
      if (this.paginationEnabled) {
        tableHeightInPixel = tableHeightInPixel - paginationSpan.clientHeight;
      }
    }
    const result = tableHeightInPixel - modifier;
    return result;
  }

  getTableHeight(): any {
    const tableHeight = this.getTableHeightInPixel() + 'px';
    return { 'height': tableHeight };
  }

  selectRow(dataValue: any) {
    dataValue.id = dataValue[0];
    if (!this.checkIfItemIsSelected(dataValue)) {
      this.selectedRows.push(dataValue);
    } else {
      this.selectedRows = this.commonService.removeItemFromArray(this.selectedRows, dataValue);
    }
    this.sendListInformation();
  }

  sendListInformation() {
    this.eventService.sendCompactListInformationEvent.emit(new JJEvent(this.controlDefinition.logicalControlId, [
      this.dataCount,
      this.selectedRows.length,
      this.selectedRows
    ]));
    // console.warn('sendListInformation', this.data.length, this.selectedRows.length, this.selectedRows);
  }

  createDataArray(data: any, dataCount = null): Promise<any> {
    return new Promise((createDataArrayResolve, createDataArrayReject) => {
      if (data === null) {
        createDataArrayResolve(null);
      } else {
        this.calculatingData = true;
        this.dataDict = new Dictionary();
        this.dataTextDict = new Dictionary();
        this.selectedRows = [];
        this.dataTextDictControlsUpdated = [];
        this.data = data;
        if (data) {
          data.forEach((createDataArrayElement, index) => {
            this.dataDict.Add(index, createDataArrayElement);
          });
          this.sendListInformation();
        }
        if (isNullOrUndefined(dataCount)) {
          this.dataCount = this.data.length;
        } else {
          this.dataCount = dataCount;
        }
        this.calculatingData = false;
        createDataArrayResolve(null);
      }
    });
  }

  getDisplayedColumns() {
    this.displayedColumns = [];
    if (this.controlDefinition.childs) {
      this.controlDefinition.childs.forEach((getDisplayedColumnsElement) => {
        this.displayedColumns.push('mat' + getDisplayedColumnsElement.id);
      });
    }
  }

  getTableWidth() {
    return {
      width: this.controlDefinition.width
    };
  }

  cellDblClicked(cellSelectedElement) {
    this.controlValue = cellSelectedElement;
    this.callClick('ondblclick');
}
  cellClicked(cellSelectedElement: any) {
    this.controlValue = cellSelectedElement;
    this.callClick('onclick');
  }

  callChildClick(child: any, callChildClickElement: any) {
    this.controlValue = callChildClickElement;
    this.callMethod('onclick', child);
  }

  callClick(method: any) {
    this.callMethod(method);
  }

  getMaxTableWidth() {
    let tableWidth = 0;
    this.controlDefinition.childs.forEach((child) => {
      if (child.isActive) {
        tableWidth = tableWidth + this.getColumnWidth(child.width);
      }
    });
    if (this.selectCheckbox) {
      tableWidth = tableWidth + 16;
    }
    return tableWidth;
  }

  getColumnWidth(childWidth: any, subsctractValue = 0, index = -1) {
    let tempValue = 0;
    if (childWidth.includes('%')) {
      const percentage = Number(this.uiService.getControlDimensionWithoutAttribute(childWidth));
      const table = document.getElementById(this.getMatName(this.controlDefinition.id));
      if (table) {
        let tableWidth = table.offsetWidth;
        if (this.selectCheckbox) {
          tableWidth = tableWidth - 15;
        }
        const finalWidthInPixel = (percentage * tableWidth / 100);
        tempValue = Number(Math.floor(finalWidthInPixel).toString());
      }
    } else {
      tempValue = Number(this.uiService.getControlDimensionWithoutAttribute(childWidth));
    }
    if (index !== -1) {
      this.tdWidthArray[index] = Number(tempValue) - subsctractValue;
    }
    return Number(tempValue) - subsctractValue;
  }

  setCompactListInnerContainerHeight() {
    const mainControl = document.getElementById(this.getMatName(this.controlDefinition.id, 'compactList'));
    if (mainControl) {
      const innerContainerHeight = mainControl.offsetHeight - 1;
      return { height: innerContainerHeight };
    }
  }

  getLabelDimension(child: any) {
    return {
      'height': '100%',
      'padding': '0px 0px 0px 2px',
      'margin': '0px 0px 0px 0px',
    };
  }

  getRowHeight(childHeight: string) {
    return { 'height': childHeight + ' !important' };
  }

  addSubChildTextToTextDictionary(text: string, dataItemIndex: any, subChild: any) {
    let textItem: any;
    if (!this.dataTextDictControlsUpdated.includes(dataItemIndex + '-' + subChild.id)) {
      if (this.dataTextDict.ContainsKey(dataItemIndex)) {
        textItem = this.dataTextDict.Item(dataItemIndex);
        textItem = textItem.concat(text);
        this.dataTextDict.Set(dataItemIndex, textItem);
      } else {
        this.dataTextDict.Add(dataItemIndex, text);
      }
      this.dataTextDictControlsUpdated.push(dataItemIndex + '-' + subChild.id);
    }
  }

  getLabelClass(control: any) {
    return 'fingerCursor' + ' ' + control.additionalCssClasses;
  }


  checkIfItemIsSelected(dataValue: any) {
    return JSON.stringify(this.selectedRows).includes(JSON.stringify(dataValue));
  }

  getDataRowId(data): string {
    const result = data.id;
    return result;
  }

  //////////////////////////////////////////// New
  addFinalDataLayerToData(data: any) {
    // this.loaderService.display(true, false, 'addFinalDataLayerToData');
    if (!isNullOrUndefined(data)) {
      data.forEach(dataItem => {
        this.controlDefinition.childs.forEach((child, index) => {
          dataItem[index] = '';
          child.childs.forEach(subChild => {
            const textToDisplay = this.compactListDataService.getSubChildValue(this.externalDataSourceResults, subChild, dataItem,
              true, child.childs);
            if (textToDisplay !== '') {
              if (dataItem[index] !== '') {
                dataItem[index] = dataItem[index].concat('<br>');
              }
              dataItem[index] = dataItem[index].concat(textToDisplay, ' ');
            }
          });
        });
      });
    }
    // this.loaderService.display(false);
  }

  setFilteredItems(value: any) {
    this.applicationInfoService.dataFilterFilteredItemArray[this.controlDefinition.logicalControlId] = value;
    this.filteredItemsDisplay = value;
    this.filteredDataCount = this.filteredItemsDisplay.length;
  }

  filterData(filterString) {
    this.calculatingData = true;
    if (this.externalDatasourcePagination) {
      this.loadExternalPagination();
    } else {
      this.setFilteredItems([]);
      filterString = filterString.toUpperCase();
      this.data.forEach(dataItem => {
        let found = false;
        this.controlDefinition.childs.forEach((child, index) => {
          if (dataItem[index].toUpperCase().indexOf(filterString) > -1) {
            found = true;
          }
        });
        if (found) {
          this.filteredItemsDisplay.push(dataItem);
        }
      });
      this.filteredDataCount = this.filteredItemsDisplay.length;
      this.paginationResetStartPage();
    }
    this.calculatingData = false;
  }

  sortList(index: any) {
      let descending = this.sortingArray[index];
      if (descending === false || descending === true) {
        descending = !this.sortingArray[index];
        this.sortingArray[index] = descending;
      } else {
        this.sortingArray = [];
        this.sortingArray[index] = false;
      }
      if (this.externalDatasourcePagination) {
        this.loadExternalPagination();
      } else {
        if (descending) {
          this.filteredItemsDisplay.sort((a, b) => a[index].toLowerCase() > b[index].toLowerCase() ? -1 : 1);
        } else {
          this.filteredItemsDisplay.sort((a, b) => a[index].toLowerCase() < b[index].toLowerCase() ? -1 : 1);
        }
    }
  }

  filterListWidthColumnHeaderFilter() {
    if (this.externalDatasourcePagination) {
      this.loadExternalPagination();
    } else {
      this.setFilteredItems([]);
      this.data.forEach(dataItem => {
        let found = true;
        this.controlDefinition.childs.forEach((child, childIndex) => {
          let filterArrayItemText = '';
          if (this.filterValueArray[childIndex]) {
            filterArrayItemText = this.filterValueArray[childIndex].toUpperCase();
          }
          if (!(dataItem[childIndex].toUpperCase().indexOf(filterArrayItemText) > -1)) {
            found = false;
          }
        });
        if (found) {
          this.filteredItemsDisplay.push(dataItem);
        }
      });
      this.filteredDataCount = this.filteredItemsDisplay.length;
      this.paginationResetStartPage();
    }
  }

  paginationMoveRight() {
    if (!this.isPaginationMoveRightDisabled()) {
      this.paginationCurrentPage ++;
      this.pageStartItem = this.pageStartItem + this.paginationItemsPerPage;
    }
    this.loadExternalPagination();
  }

  loadExternalPagination() {
    if (this.externalDatasourcePagination) {
      const paginationInfo: any = [];
      paginationInfo.pageNumber = this.commonService.nullOrUndefinedToDefaultValue(this.paginationCurrentPage, 1);
      paginationInfo.pageSize = this.commonService.nullOrUndefinedToDefaultValue(this.paginationItemsPerPage, 1);
      paginationInfo.crossColumnFilter = this.commonService.nullOrUndefinedToDefaultValue(this.generalFilter, '');
      paginationInfo.filterInformation = this.commonService.nullOrUndefinedToDefaultValue(paginationInfo.filterInformation, '');
      paginationInfo.additionalColumnsSettings = this.compactListDataService.getAdditionalColumnString(this.controlDefinition,
        this.sortingArray, this.filterValueArray);
      this.eventService.callCompactListExternalPagination(this.controlDefinition.id, [paginationInfo]);
    }
  }

  paginationMoveLeft() {
    if (!this.isPaginationMoveLeftDisabled()) {
      this.paginationCurrentPage --;
      this.pageStartItem = this.pageStartItem - this.paginationItemsPerPage;
    }
    if (this.pageStartItem < 0) { this.pageStartItem = 0; }
    this.loadExternalPagination();
  }

  paginationResetStartPage() {
    this.pageStartItem = 0;
    this.paginationCurrentPage = 1;
    this.loadExternalPagination();
  }

  isPaginationMoveLeftDisabled() {
    let returnValue = false;
    if (this.pageStartItem < 1) {
      returnValue = true;
    }
    return returnValue;
  }

  paginationGetMaxPages() {
    this.paginationMaxPages = Math.ceil(this.filteredDataCount / this.paginationItemsPerPage);
    // this.paginationCurrentPage = this.paginationItemsPerPage
    if (this.paginationMaxPages === 0) { this.paginationMaxPages = 1; }
    return this.paginationMaxPages;
  }

  isPaginationMoveRightDisabled() {
    let returnValue = false;
    if ((this.paginationItemsPerPage + this.pageStartItem) >= this.filteredDataCount) {
      returnValue = true;
    }
    return returnValue;
  }
}
