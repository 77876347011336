<label class="jjSectionHeaderLabel">
  {{ 'DataExport.Label.Header' | texttransform }}
</label>

<br>
<div id="myLeadsResults" [ngClass]="{
      'jjControlDefaultColor': exportTemplateCollapsed==false,
      'jjControlNotSelectedColor': exportTemplateCollapsed==true,
      'jjControlMenuDivSpacer': exportTemplateCollapsed==true
      }" class="jjControlMenuDiv" (click)="toggleItem('exportTemplateCollapsed')">
  <label class="" style="width: 60%;">{{ 'DataExport.Label.Header' | texttransform }}</label>
  <button (click)="toggleItem('exportTemplateCollapsed');$event.stopPropagation()"
    class="floatRight jjControlMenuButton" style="white-space: normal;">
    <i *ngIf="exportTemplateCollapsed==true" class="fas fa-chevron-down"></i>
    <i *ngIf="exportTemplateCollapsed==false" class="fas fa-chevron-up"></i>
  </button>
</div>
<div class="jjControlMenuDivSpacer" *ngIf="exportTemplateCollapsed==false">

  <!-- Filter -->
  <!-- <div id="myLeadsFilter" [ngClass]="{
      'jjControlDefaultColor': filterCollapsed==false,
      'jjControlNotSelectedColor': filterCollapsed==true,
      'jjControlMenuDivSpacer': filterCollapsed==true
  }" class="jjControlMenuDiv fingerCursor" (click)="toggleItem('filterCollapsed')" style="margin-top: 2px;">
    <label class=" fingerCursor" style="width: 60%;">{{ 'MyLeads.Label.FilterHeader' | texttransform }}</label>
    <button (click)="toggleItem('filterCollapsed');$event.stopPropagation()" class="floatRight jjControlMenuButton"
      style="white-space: normal;">
      <i class="fas fa-chevron-down fingerCursor" *ngIf="filterCollapsed==false"></i>
      <i class="fas fa-chevron-up fingerCursor" *ngIf="filterCollapsed==true"></i>
    </button>
  </div>

  <div class="jjControlMenuDivSpacer" [ngClass]="{ hideInDom: filterCollapsed==true }">
    <div class="jjControlSubmenuColor contentDivSpacer">
      <button (click)="editDataFilter();$event.stopPropagation()" class="jjControlSubmenuButton"><i
          class="fas fa-edit"></i></button>
    </div>


  </div>
   -->
 
  <div style="margin-top: 10px;">
    <label style="width:45%;">
      {{ 'ExportForm.Label.AvailableExportTemplates' | texttransform }}
    </label>
    <select style="width: 50%" [(ngModel)]="selectedExportTemplate" class="floatRight">
      <option width="19%" [ngValue]="exportTemplate" *ngFor="let exportTemplate of exportTemplates">
        {{ exportTemplate.defaultName  }}
      </option>
    </select>
    <br>
    <label style="width:45%">
      {{ 'ExportForm.Label.AvailableLanguages' | texttransform }}
    </label>
    <select style="width: 50%" [(ngModel)]="selectedLanguage" class="floatRight">
      <option width="19%" [ngValue]="language" *ngFor="let language of languages">{{ language.languageName  }}
      </option>
    </select>
    <br>
    <label style="width:45%">
      {{ 'ExportForm.Label.AvailableCampaigns' | texttransform }}
    </label>
    <select style="width: 50%" [(ngModel)]="selectedCampaign" class="floatRight">
      <option width="19%" [ngValue]="campaign" *ngFor="let campaign of campaigns">{{ campaign.defaultName  }}
      </option>
    </select>

    <br>
    <label style="width:45%;">
      {{ 'ExportForm.Label.CompleteExport' | texttransform }}
    </label>
    <span class="floatRight" style="width: 50%">
      <input type="checkbox" [(ngModel)]="isCompleteExport" data-md-icheck (change)="toggleClosedData($event)" />
    </span>
    <br>
    <app-extended-button width="200px" (buttonClickEvent)="executeExportTemplatePost()"
      buttonText="ExportForm.Label.StartExport" iconCss="fas fa-file-excel">
    </app-extended-button>
  </div>
</div>