<div class="contentLibraryItem__wrapper">
    <div class="contentLibraryItem__wrapper__inner everleadShadowBox">
        <div *ngIf="currentFolder != null" class="currentFolder everleadCoral"><i class="fas fa-arrow-alt-circle-left fingerCursor" (click)="navigateBack()"></i>
            <div class="maxWidth">{{ contentLibrary.defaultName }} > {{ currentFolder.name }}</div>
            <i class="fas fa-share fingerCursor" (click)="shareFolder()" [tippy]="'ContentLibrary.Label.ShareFolder' | texttransform"></i>
        </div>
        <div *ngIf="currentFolder == null" class="currentFolder everleadCoral"><i class="fas fa-arrow-alt-circle-left fingerCursor" (click)="navigateBack()"></i>
            <div class="maxWidth">{{ contentLibrary.defaultName }}</div>
        </div>

        <div class="projectSettings__header">
            <div class="projectSettings__header__interaction">
                <div class="projectSettings__header__filter">            
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                    <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
                </div>
            </div>
        </div>
        <div *ngIf="folderContent.length == 0" class="maxHeight">
            {{ 'ContentLibrary.Label.NoContentYet' | texttransform }}
        </div>   
        <div class="contentLibraryItem__files__header" *ngIf="folderContent.length != 0">
            <div class="ellipsisText">{{ 'ContentLibrary.Label.Name' | texttransform }}</div>
            <div class="contentLibraryItem__isFile__date">{{ 'ContentLibrary.Label.Date' | texttransform }}</div>
            <div class="contentLibraryItem__isFile__user ellipsisText">{{ 'ContentLibrary.Label.User' | texttransform }}</div>
            <div class="contentLibraryItem__isFile__buttons"></div>
        </div>         
        <div class="contentLibraryItem__files">            
            <div *ngFor="let subChild of getFolderContent(folderContent, true)" class="contentLibraryItem__isDirectory fingerCursor contentLibraryItem__name" 
                (click)="showContent(subChild.id)" [ngClass]="{ hideInDom: isFiltered(subChild) }"
            >
                <i class="fas fa-folder"></i>
                <div class="ellipsisText">{{ subChild.name }}</div>
                <div class="contentLibraryItem__isFile__date">{{ subChild.createdAt | date:'medium' }}</div>
                <div class="contentLibraryItem__isFile__user ellipsisText">{{ getUser(subChild.createdBy) }}</div>
                <div class="contentLibraryItem__isFile__buttons">
                    <i class="fas fa-edit fingerCursor" (click)="editFilename(subChild)"></i>
                    <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteContentLibraryFile(subChild);$event.stopPropagation()"></i>
                </div>                
                <!-- <input [(ngModel)]="subChild.name" (change)="saveContrnetLibraryItem(subChild)" (click)="$event.stopPropagation()"> -->
            </div>

            <div *ngFor="let subChild of getFolderContent(folderContent, false)" class="contentLibraryItem__isFile" [ngClass]="{ hideInDom: isFiltered(subChild) }">
                <i class="fas fa-file"></i>
                <div class="ellipsisText" [tippy]="subChild.name">{{ subChild.name }}</div>
                <div class="contentLibraryItem__isFile__date">{{ subChild.createdAt | date:'medium' }}</div>
                <div class="contentLibraryItem__isFile__user ellipsisText">{{ getUser(subChild.createdBy) }}</div>
                <div class="contentLibraryItem__isFile__buttons">
                    <i class="fas fa-file-download fingerCursor" (click)="downloadFile(subChild)"></i>
                    <i class="fas fa-edit fingerCursor" (click)="editFilename(subChild)"></i>
                    <i class="fas fa-trash everleadCoral fingerCursor" *ngIf="userService.hasPermission('CanDeleteContentLibraryFile')"
                    (click)="deleteContentLibraryFile(subChild);$event.stopPropagation()"></i>
                </div>                
            </div>            
        </div> 
        <app-externalfiledragcontrol class="contentLibraryItem__dragDrop" *ngIf="currentFolder != null"
            (selectedFiles)="onFilesSelected($event)"></app-externalfiledragcontrol>     
    </div>    

    <div class="contentFolderSettings everleadShadowBox" [ngClass]="{ 'contentFolderSettings__extended': settingsExtended }">
        <div class="contentLibraryItem__headerLine" *ngIf="!settingsExtended">
            <i class="fas fa-lg fa-arrow-alt-circle-left everleadCoral fingerCursor" (click)="settingsExtended = true"></i>
        </div>
        <div class="contentLibraryItem__headerLine" *ngIf="settingsExtended">
            <i class="fas fa-lg fa-arrow-alt-circle-right everleadCoral fingerCursor" (click)="settingsExtended = false"></i>
            <div class="contentLibraryItem__content" *ngIf="currentFolder != null">
                <label class="ellipsisText everleadCoral">{{ 'ContentLibrary.Label.FolderName' | texttransform }}</label>
                <input [(ngModel)]="currentFolder.name" class="maxWidth" (change)="saveContentLibraryItem(currentFolder)">
            </div>
            <div class="contentLibraryItem__upload">
                <input type="file" #file accept="*.*" placeholder="Choose file" (change)="uploadFile($event)" style="display:none;" multiple="true">        
                <label *ngIf="currentFolder == null">{{ 'ContentLibrary.Label.IsRootDirectory' | texttransform }}</label>
                <button class="everleadCustomButton maxWidth" (click)="newFolder()">{{ 'ContentLibrary.Label.CreateFolder' | texttransform }}</button>
                <button class="everleadCustomButton maxWidth" (click)="file.click()" *ngIf="currentFolder != null">{{ 'ContentLibrary.Label.UploadFile' | texttransform }}</button>                    
            </div>
            <div class="contentLibraryItem__security" *ngIf="currentFolder != null">
                <label class="ellipsisText everleadCoral">{{ 'ContentLibrary.Label.SecuritySettings' | texttransform }}</label>
                <div *ngFor="let userRole of userRoles">
                    <div *ngIf="userRoleAvailable(userRole) == true" class="contentLibraryItem__security__item">
                        <input type="checkbox" [(ngModel)]="userRole.isChecked" (click)="changeSecuritySetting(userRole)" 
                            [disabled]="userRole.id == applicationInfoService.currentUserRoleId" class="contentFolderSettings__checkbox">
                        <label class="ellipsisText">{{ userRole?.nameTranslationToken?.tokenFullName | texttransform }}</label>
                    </div>
                </div>
            </div>
        </div>             
    </div>

    <div class="contentLibraryItemPopup" *ngIf="createNewFolder || editFile">
        <div class="contentLibraryItemPopup__folderName" *ngIf="createNewFolder">
            <label class="ellipsisText">{{ 'ContentLibrary.Label.FolderName' | texttransform }}</label>
            <input id="newFolderNameInput" class="maxWidth" [(ngModel)]="newFolderName">            
        </div>
        <div class="contentLibraryItemPopup__folderName" *ngIf="editFile">
            <label class="ellipsisText">{{ 'ContentLibrary.Label.FileName' | texttransform }}</label>
            <input id="newFolderNameInput" class="maxWidth" [(ngModel)]="fileToEdit.name">            
            
        </div>
        <div class="contentLibraryItemPopup__buttons">
            <button class="everleadCustomButton" *ngIf="createNewFolder" (click)="createNewDirectory()">{{ 'ContentLibrary.Label.CreateFolder' | texttransform }}</button>
            <button class="everleadCustomButton" *ngIf="editFile" (click)="updateDirectory()">{{ 'ContentLibrary.Label.UpdateFolder' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="closeEditWindow()">{{ 'ContentLibrary.Label.Cancel' | texttransform }}</button>
        </div>
    </div>

    <div class="contentLibraryItemPopup" *ngIf="sharePage">
        <div class="contentLibraryItemPopup__folderName">
            <label class="ellipsisText">{{ 'ContentLibrary.Label.ShareFolder' | texttransform }}</label>
            <div class="everleadShadowBox">
                {{ 'ContentLibrary.Label.ShareFolderInfo' | texttransform }}                
            </div>
            <br>
            <input id="newFolderNameInput" class="maxWidth" [(ngModel)]="currentPageLink">
        </div>
        <br>
        <div class="contentLibraryItemPopup__buttons">            
            <button class="everleadCustomButton" (click)="copyToClipboard(currentPageLink)">{{ 'ContentLibrary.Label.CopyToClipboard' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="sharePage = false">{{ 'ContentLibrary.Label.Cancel' | texttransform }}</button>
        </div>
    </div>
</div>
