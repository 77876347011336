

<div class="phoneDashboard">
    <div class="phoneDashboard__wrapper">
        <div class="phoneDashboard__header">
            <div class="phoneDashboard__header__ringCentralLabel">
                <label class="label">Unify Office</label>
                <span class="callDuration" *ngIf="ringcentralwebphoneService.established && userCall">{{'Webphone.Label.Time' | texttransform }} {{ringcentralwebphoneService.getTimeDifferenceString() }}</span>

            </div>
            <div class="phoneSelectWrapper" *ngIf="!ringcentralwebphoneService.session || ringcentralwebphoneService.established && selectedTab != 2" >
                <label class="phoneSelectLabel">{{ 'General.Label.State' | texttransform }}:</label>
                <select class="phoneSelect" 
                [(ngModel)]="ringcentralwebphoneService.availabilityMode"
                (change)="ringcentralwebphoneService.changeAvailabilityMode()">
                    <option [ngValue]=null hidden>{{ 'Activity.Name.Unknown' | texttransform }}</option>
                    <option ngValue="inbound">{{ 'Activity.Name.Inbound_Outbound' | texttransform }}</option>
                    <option ngValue="backoffice">{{ 'Activity.Name.Backoffice' | texttransform }}</option>
                    <option ngValue="notavailable">{{ 'Activity.Name.Not_Available' | texttransform }}</option>
                </select>
            </div>

            <div class="phoneDashboard__logout" *ngIf="!ringcentralwebphoneService.session || ringcentralwebphoneService.established && selectedTab != 2" 
                (click)="logout()" [tippy]="'General._.Logout' | texttransform">
                <svg class="logSvg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff1049" viewBox="0 0 256 256"><path d="M112,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h56a8,8,0,0,1,0,16H48V208h56A8,8,0,0,1,112,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L196.69,120H104a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,221.66,122.34Z"></path></svg>
            </div>
            <div *ngIf="ringcentralwebphoneService.session && userCall && selectedTab == 2" class="phoneDashboard__header__userInfo">
                <span *ngIf="userCall?.adressBook">{{userCall?.firstName}} {{userCall?.lastName}}</span>
                <span *ngIf="!userCall?.adressBook">{{userCall?.user}}</span>
                <span>{{userCall?.selectedNumber}}</span>
            </div>
        </div>
        <div class="phoneDashboard__body">
            <div class="phoneDashboard__body__wrapper" *ngIf="!logoutRequest">
                <div class="phoneTab">
                    <button class="tabLinks" [ngClass]="{ phoneActiveTab: selectedTab == tab.id }"
                        *ngFor="let tab of visibleTabs; let currentIndex = index" (click)="setCurrentTab(tab)">
                        {{ tab?.name | texttransform }}
                    </button>
                </div>

                <div *ngIf="selectedTab == 0" class="phoneDashboardTab">
                  <div class="phoneCallTab">
                    <div *ngIf="userCall" class="phoneCallTab__userwrapper">
                        <div class="phoneCallTab__callInfo" *ngIf="userCall.adressBook">
                            <span class="phoneCallTab__profile">{{userCall?.firstName[0]}}{{userCall?.lastName[0]}}</span>
                            <span class="phoneCallTab__info">
                            <p class="phoneCallTab__info__name">{{userCall?.firstName}} {{userCall?.lastName}}</p>
                            <p class="phoneCallTab__info__jobTitle">{{userCall?.jobTitle}}</p>
                            </span>
                            <span class="phoneCallTab__outgoingNumber">{{userCall.selectedNumber}}</span>
                        </div>
                        <div class="phoneCallTab__callInfo" *ngIf="!userCall.adressBook">
                            <span class="phoneCallTab__profile unknownProfile">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#262626" viewBox="0 0 256 256"><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26ZM71.44,198a66,66,0,0,1,113.12,0,89.8,89.8,0,0,1-113.12,0ZM94,120a34,34,0,1,1,34,34A34,34,0,0,1,94,120Zm99.51,69.64a77.53,77.53,0,0,0-40-31.38,46,46,0,1,0-51,0,77.53,77.53,0,0,0-40,31.38,90,90,0,1,1,131,0Z"></path></svg> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
                                    <g id="Gruppe_2176" data-name="Gruppe 2176" transform="translate(-1471 -324)">
                                      <circle id="Ellipse_66" data-name="Ellipse 66" cx="45" cy="45" r="45" transform="translate(1471 324)" fill="#d9dee3"/>
                                      <path id="account_circle_FILL1_wght400_GRAD0_opsz24" d="M95.4-819.6a41.74,41.74,0,0,1,11.4-6.15A38.95,38.95,0,0,1,120-828a38.95,38.95,0,0,1,13.2,2.25,41.74,41.74,0,0,1,11.4,6.15,30.918,30.918,0,0,0,5.45-9.3A31.352,31.352,0,0,0,152-840a30.848,30.848,0,0,0-9.35-22.65A30.848,30.848,0,0,0,120-872a30.848,30.848,0,0,0-22.65,9.35A30.848,30.848,0,0,0,88-840a31.352,31.352,0,0,0,1.95,11.1A30.918,30.918,0,0,0,95.4-819.6ZM120-836a13.526,13.526,0,0,1-9.95-4.05A13.526,13.526,0,0,1,106-850a13.526,13.526,0,0,1,4.05-9.95A13.526,13.526,0,0,1,120-864a13.526,13.526,0,0,1,9.95,4.05A13.526,13.526,0,0,1,134-850a13.526,13.526,0,0,1-4.05,9.95A13.526,13.526,0,0,1,120-836Zm0,36a38.951,38.951,0,0,1-15.6-3.15,40.4,40.4,0,0,1-12.7-8.55,40.4,40.4,0,0,1-8.55-12.7A38.95,38.95,0,0,1,80-840a38.95,38.95,0,0,1,3.15-15.6,40.394,40.394,0,0,1,8.55-12.7,40.393,40.393,0,0,1,12.7-8.55A38.95,38.95,0,0,1,120-880a38.95,38.95,0,0,1,15.6,3.15,40.393,40.393,0,0,1,12.7,8.55,40.394,40.394,0,0,1,8.55,12.7A38.95,38.95,0,0,1,160-840a38.95,38.95,0,0,1-3.15,15.6,40.4,40.4,0,0,1-8.55,12.7,40.4,40.4,0,0,1-12.7,8.55A38.951,38.951,0,0,1,120-800Z" transform="translate(1396 1209)" fill="#f1eff2"/>
                                    </g>
                                  </svg>
                                  
                            </span>
                            <span class="phoneCallTab__info">
                            <p class="phoneCallTab__info__name">{{userCall.user}}</p>
                            <p class="phoneCallTab__info__jobTitle">{{userCall?.jobTitle}}</p>
                            </span>
                            <span class="phoneCallTab__outgoingNumber">{{userCall.selectedNumber}}</span>
                        </div>
                    

                    <!-- incoming call -->

                     <div class="phoneCallTab__onCallActions" *ngIf="ringcentralwebphoneService.session || ringcentralwebphoneService.established">
                       <!--  <button class="record"
                        *ngIf="ringcentralwebphoneService.session && applicationInfoService.applicationSettings['isRecordingAllowed'] == 'true' && ringcentralwebphoneService.established && ringcentralwebphoneService.makeCallObject.accountId != null" 
                        (click)="ringcentralwebphoneService.toggleRecord()">
                            <svg *ngIf="!ringcentralwebphoneService.sessionState?.record" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1f1e1e" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z"></path><circle cx="128" cy="128" r="72"></circle></svg>
                            <svg *ngIf="ringcentralwebphoneService.sessionState?.record" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff0000" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z"></path><circle cx="128" cy="128" r="72"></circle></svg>
                        </button> -->

                        <button (click)="ringcentralwebphoneService.toggleHold()">
                            <svg *ngIf="!ringcentralwebphoneService.session?.held" height="24" viewBox="0 0 20.571 24" width="20.571" xmlns="http://www.w3.org/2000/svg"><path d="m253.714-736v-24h6.857v24zm-13.714 0v-24h6.857v24z" fill="#011b34" transform="translate(-240 760)"/></svg>
                            <svg *ngIf="ringcentralwebphoneService.session?.held" height="24" viewBox="0 0 20.571 24" width="20.571" xmlns="http://www.w3.org/2000/svg"><path d="m253.714-736v-24h6.857v24zm-13.714 0v-24h6.857v24z" fill="#ff1049" transform="translate(-240 760)"/></svg>
                        </button>

                        <button (click)="ringcentralwebphoneService.toggleMute()">
                            <svg *ngIf="!ringcentralwebphoneService.session?.muted" height="24" viewBox="0 0 23.068 24" width="23.068" xmlns="http://www.w3.org/2000/svg"><path d="m75.049-864.913-1.69-1.687a5.564 5.564 0 0 0 .612-1.4 5.59 5.59 0 0 0 .2-1.514h2.329a7.729 7.729 0 0 1 -.379 2.432 8.541 8.541 0 0 1 -1.072 2.169zm-3.437-3.5-6.757-6.757v-1.34a3.37 3.37 0 0 1 1.019-2.476 3.37 3.37 0 0 1 2.476-1.014 3.37 3.37 0 0 1 2.476 1.02 3.37 3.37 0 0 1 1.019 2.476v6.99a2.176 2.176 0 0 1 -.073.583q-.072.261-.16.523zm-4.427 10.544v-3.612a7.789 7.789 0 0 1 -5.01-2.694 7.909 7.909 0 0 1 -1.981-5.345h2.33a5.614 5.614 0 0 0 1.7 4.121 5.614 5.614 0 0 0 4.121 1.7 5.725 5.725 0 0 0 1.883-.301 5.734 5.734 0 0 0 1.617-.859l1.66 1.66a8.7 8.7 0 0 1 -1.85 1.121 7.534 7.534 0 0 1 -2.141.6v3.612zm10.252 1.869-21.437-21.437 1.631-1.631 21.437 21.437z" fill="#011b34" transform="translate(-56 880)"/></svg>
                            <svg *ngIf="ringcentralwebphoneService.session?.muted" height="24" viewBox="0 0 23.068 24" width="23.068" xmlns="http://www.w3.org/2000/svg"><path d="m75.049-864.913-1.69-1.687a5.564 5.564 0 0 0 .612-1.4 5.59 5.59 0 0 0 .2-1.514h2.329a7.729 7.729 0 0 1 -.379 2.432 8.541 8.541 0 0 1 -1.072 2.169zm-3.437-3.5-6.757-6.757v-1.34a3.37 3.37 0 0 1 1.019-2.476 3.37 3.37 0 0 1 2.476-1.014 3.37 3.37 0 0 1 2.476 1.02 3.37 3.37 0 0 1 1.019 2.476v6.99a2.176 2.176 0 0 1 -.073.583q-.072.261-.16.523zm-4.427 10.544v-3.612a7.789 7.789 0 0 1 -5.01-2.694 7.909 7.909 0 0 1 -1.981-5.345h2.33a5.614 5.614 0 0 0 1.7 4.121 5.614 5.614 0 0 0 4.121 1.7 5.725 5.725 0 0 0 1.883-.301 5.734 5.734 0 0 0 1.617-.859l1.66 1.66a8.7 8.7 0 0 1 -1.85 1.121 7.534 7.534 0 0 1 -2.141.6v3.612zm10.252 1.869-21.437-21.437 1.631-1.631 21.437 21.437z" fill="#ff1049" transform="translate(-56 880)"/></svg>
                        </button>

                        <button (click)="ringcentralwebphoneService.volumeDown()">
                            <svg height="24" viewBox="0 0 20.25 24" width="20.25" xmlns="http://www.w3.org/2000/svg"><path d="m200-783.5v-9h6l7.5-7.5v24l-7.5-7.5zm16.5 1.5v-12.075a6.305 6.305 0 0 1 2.719 2.437 6.728 6.728 0 0 1 1.031 3.638 6.542 6.542 0 0 1 -1.031 3.6 6.377 6.377 0 0 1 -2.719 2.4z" fill="#011b34" transform="translate(-200 800)"/></svg>
                        </button>

                        <button (click)="ringcentralwebphoneService.volumeUp()">
                            <svg height="24" viewBox="0 0 16.457 16" width="16.457" xmlns="http://www.w3.org/2000/svg"><path d="m130.057-815v-1.874a6.14 6.14 0 0 0 3.314-2.286 6.272 6.272 0 0 0 1.257-3.84 6.273 6.273 0 0 0 -1.257-3.84 6.14 6.14 0 0 0 -3.314-2.286v-1.874a8 8 0 0 1 4.617 2.869 7.982 7.982 0 0 1 1.783 5.131 7.982 7.982 0 0 1 -1.783 5.131 8 8 0 0 1 -4.617 2.869zm-10.057-5.234v-5.486h3.657l4.571-4.571v14.629l-4.571-4.571zm10.057.914v-7.36a3.8 3.8 0 0 1 1.68 1.509 4.175 4.175 0 0 1 .606 2.194 4.076 4.076 0 0 1 -.606 2.16 3.817 3.817 0 0 1 -1.68 1.497z" fill="#011b34" transform="translate(-120 831)"/>
                            </svg>
                        </button>

                        <button (click)="ringcentralwebphoneService.startTransferProcess()" [class.disabled]="ringcentralwebphoneService.sessionState.transferCallDisabled" [disabled]="ringcentralwebphoneService.sessionState.transferCallDisabled">
                            <svg *ngIf="!ringcentralwebphoneService.sessionState?.startTransfer" height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m134.274-864a12.641 12.641 0 0 1 -5.2-1.147 15.559 15.559 0 0 1 -4.674-3.253 15.557 15.557 0 0 1 -3.253-4.674 12.64 12.64 0 0 1 -1.147-5.2.857.857 0 0 1 .253-.632.857.857 0 0 1 .632-.253h3.411a.783.783 0 0 1 .526.2.769.769 0 0 1 .274.474l.547 2.947a1.484 1.484 0 0 1 -.021.568.886.886 0 0 1 -.232.4l-2.042 2.063a10.187 10.187 0 0 0 1 1.505 14.831 14.831 0 0 0 1.274 1.4 14.816 14.816 0 0 0 1.368 1.21 13.317 13.317 0 0 0 1.516 1.021l1.979-1.979a1.178 1.178 0 0 1 .495-.284 1.355 1.355 0 0 1 .6-.053l2.905.589a.975.975 0 0 1 .484.305.741.741 0 0 1 .189.495v3.411a.857.857 0 0 1 -.253.632.857.857 0 0 1 -.631.255zm-1.642-9.263-1.221-1.179 1.347-1.347h-4.337v-1.684h4.337l-1.305-1.305 1.179-1.222 3.368 3.411z" fill="#011b34" transform="translate(-120 880)"/></svg>
                            <svg *ngIf="ringcentralwebphoneService.sessionState?.startTransfer" height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m134.274-864a12.641 12.641 0 0 1 -5.2-1.147 15.559 15.559 0 0 1 -4.674-3.253 15.557 15.557 0 0 1 -3.253-4.674 12.64 12.64 0 0 1 -1.147-5.2.857.857 0 0 1 .253-.632.857.857 0 0 1 .632-.253h3.411a.783.783 0 0 1 .526.2.769.769 0 0 1 .274.474l.547 2.947a1.484 1.484 0 0 1 -.021.568.886.886 0 0 1 -.232.4l-2.042 2.063a10.187 10.187 0 0 0 1 1.505 14.831 14.831 0 0 0 1.274 1.4 14.816 14.816 0 0 0 1.368 1.21 13.317 13.317 0 0 0 1.516 1.021l1.979-1.979a1.178 1.178 0 0 1 .495-.284 1.355 1.355 0 0 1 .6-.053l2.905.589a.975.975 0 0 1 .484.305.741.741 0 0 1 .189.495v3.411a.857.857 0 0 1 -.253.632.857.857 0 0 1 -.631.255zm-1.642-9.263-1.221-1.179 1.347-1.347h-4.337v-1.684h4.337l-1.305-1.305 1.179-1.222 3.368 3.411z" fill="#59aa8e" transform="translate(-120 880)"/></svg>
                        </button>

                        <button (click)="closeCall()">
                            <svg height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m70.869-828-4.485-4.485a14.752 14.752 0 0 1 -3.909 2.283 11.768 11.768 0 0 1 -4.333.828.787.787 0 0 1 -.667-.242.982.982 0 0 1 -.182-.606v-3.273a.737.737 0 0 1 .182-.495.821.821 0 0 1 .465-.273l2.788-.566a1.217 1.217 0 0 1 .556.061 1.174 1.174 0 0 1 .495.263l1.9 1.9q.364-.222.788-.505t.747-.546l-9.214-9.213 1.131-1.131 14.869 14.869zm-2.2-6.768-1.152-1.151q.283-.344.6-.788a7.613 7.613 0 0 0 .515-.828l-1.96-1.98a.877.877 0 0 1 -.222-.455 1.169 1.169 0 0 1 -.02-.475l.525-2.828a.821.821 0 0 1 .273-.465.737.737 0 0 1 .495-.182h3.267a.822.822 0 0 1 .606.242.822.822 0 0 1 .242.606 11.721 11.721 0 0 1 -.838 4.345 14.979 14.979 0 0 1 -2.333 3.959z" fill="#ff1049" transform="translate(-56 844)"/></svg>
                        </button>
                    </div> 
                </div>

                    <div class="phoneCallTab__mainActions incomingCallActions" *ngIf="showIncomingCall && !ringcentralwebphoneService.established">
                        <button class="phoneButtons" (click)="rejectCall(incomingNumber)">
                            <svg height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m70.869-828-4.485-4.485a14.752 14.752 0 0 1 -3.909 2.283 11.768 11.768 0 0 1 -4.333.828.787.787 0 0 1 -.667-.242.982.982 0 0 1 -.182-.606v-3.273a.737.737 0 0 1 .182-.495.821.821 0 0 1 .465-.273l2.788-.566a1.217 1.217 0 0 1 .556.061 1.174 1.174 0 0 1 .495.263l1.9 1.9q.364-.222.788-.505t.747-.546l-9.214-9.213 1.131-1.131 14.869 14.869zm-2.2-6.768-1.152-1.151q.283-.344.6-.788a7.613 7.613 0 0 0 .515-.828l-1.96-1.98a.877.877 0 0 1 -.222-.455 1.169 1.169 0 0 1 -.02-.475l.525-2.828a.821.821 0 0 1 .273-.465.737.737 0 0 1 .495-.182h3.267a.822.822 0 0 1 .606.242.822.822 0 0 1 .242.606 11.721 11.721 0 0 1 -.838 4.345 14.979 14.979 0 0 1 -2.333 3.959z" fill="#ff1049" transform="translate(-56 844)"/></svg>
                            {{ 'Webphone.Label.CancelCall' | texttransform }}
                        </button>

                        <button class="phoneButtons" (click)="acceptCall(incomingNumber)">
                            <svg height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg>
                                {{'Webphone.Label.Accept' | texttransform }}
                        </button>
                    </div>

                    <div class="phoneCallTab__mainActions" *ngIf="!showIncomingCall">
                        <button class="togglePadButton"(click)="toggleOnCallKeyPad()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#fafafa" viewBox="0 0 256 256"><path d="M76,60A16,16,0,1,1,60,44,16,16,0,0,1,76,60Zm52-16a16,16,0,1,0,16,16A16,16,0,0,0,128,44Zm68,32a16,16,0,1,0-16-16A16,16,0,0,0,196,76ZM60,112a16,16,0,1,0,16,16A16,16,0,0,0,60,112Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,128,112Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,196,112ZM60,180a16,16,0,1,0,16,16A16,16,0,0,0,60,180Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,128,180Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,196,180Z"></path></svg>
                        </button>
                    </div>
                    
                    <div class="toggleKeypad" [class.active]="onCallKeyPad">
                        <span class="callNumberMessage" *ngIf="callingNumber">{{ callingNumber }}</span>

                        <div class="toggleKeypad__pad">
                            <button class="toggleKeypad__pad__key" *ngFor="let key of keypad" (click)="keypadOnCall(key.value)">
                                {{key.value}}
                            </button>
                        </div>
                    </div>
                </div>   
            </div>
                
                <div *ngIf="selectedTab == 1" class="phoneDashboardTab">
                    <div class="phoneDirectCallTab">
                       <!--  <input *ngIf="!outgoingCall" [(ngModel)]="callingNumber" (keyup)="validateNumber(callingNumber)" class="numberInput" [placeholder]="'Toolbar.Label.NumberToCall' | texttransform"> -->
                        <div class="dropdown" #dropdownList>
                            <div class="dropdown__wrapper">
                            <input 
                            class="dropdown__input"
                            [(ngModel)]="callingNumber" 
                            (keyup)="filterInput($event, callingNumber)" 
                            (click)="filterInput($event, callingNumber)"
                            (keydown)="validateNumber(callingNumber)" 
                            [placeholder]="'Webphone.Label.NumberToCall' | texttransform">
                                <button *ngIf="callingNumber" (click)="deleteKeyNumber()" class="phoneButtonsExtraKeys">
                                    <svg class="deleteNumber" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#011b34" viewBox="0 0 256 256"><path d="M216,40H68.53a16.08,16.08,0,0,0-13.72,7.77L9.14,123.88a8,8,0,0,0,0,8.24l45.67,76.11A16.08,16.08,0,0,0,68.53,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM61.67,204.12,68.53,200h0ZM216,200H68.53l-43.2-72,43.2-72H216ZM106.34,146.34,124.69,128l-18.35-18.34a8,8,0,0,1,11.32-11.32L136,116.69l18.34-18.35a8,8,0,0,1,11.32,11.32L147.31,128l18.35,18.34a8,8,0,0,1-11.32,11.32L136,139.31l-18.34,18.35a8,8,0,0,1-11.32-11.32Z"></path></svg>
                                </button>
                            </div>
                            <ul *ngIf="callingNumber && dropdownInputData.length > 0" class="dropdown__list">
                                <li *ngFor="let contact of dropdownInputData let i = index" (mouseenter)="onMouseEnter()" (click)="setSelectedContact(contact, callingNumber)" class="dropdown__row" [class.active]="i === selectedIndex">
                                    <div class="dropdown__profile">
                                        <span class="dropdown__profile__shortName">{{contact?.firstName[0]}}{{contact?.lastName[0]}}</span>
                                        <p class=" dropdown__profile__name">{{contact?.firstName}} {{contact?.lastName}}</p>
                                    </div>
<!--                                     <span class="dropdown__contact" *ngIf="contact?.extension"> {{contact?.extension}}</span>
                                    <span class="dropdown_contact" *ngIf="!contact?.extension && contact?.phoneNumber">{{contact?.phoneNumber}}</span> -->
                                    <span class="dropdown__contact" *ngIf="this.commonService.checkIfStringContainsString(contact.extension, callingNumber) && this.commonService.checkIfStringContainsString(contact.phoneNumberNF, callingNumber)">{{contact.extension}}</span>
                                    <span class="dropdown__contact" *ngIf="this.commonService.checkIfStringContainsString(contact.firstName, callingNumber) || this.commonService.checkIfStringContainsString(contact.lastName, callingNumber)">{{contact.extension}}</span>
                                    <span class="dropdown__contact" *ngIf="this.commonService.checkIfStringContainsString(contact.extension, callingNumber) && !this.commonService.checkIfStringContainsString(contact.phoneNumberNF, callingNumber)">{{contact.extension}}</span>
                                    <span class="dropdown__contactPhoneNumber" *ngIf="this.commonService.checkIfStringContainsString(contact.phoneNumberNF, callingNumber) && !this.commonService.checkIfStringContainsString(contact.extension, callingNumber)">{{contact.phoneNumber}}</span>
                                    <!-- <span class="dropdown__contactPhoneNumber" *ngIf="this.commonService.checkIfStringContainsString(contact.phoneNumber, callingNumber)">{{contact.phoneNumber}}</span> -->
                                </li>
                            </ul>
                        </div>
                        <div class="phoneDirectCallTab__keypad">
                            <div class="phoneDirectCallTab__keypad__pad">
                                <button class="phoneDirectCallTab__keypad__key" *ngFor="let key of keypad" (click)="keyPadValue(selectedTab, key.value)">{{key.value}}</button>
                            </div>

                            <div class="phoneDirectCallTab__keypad__actionCalling" *ngIf="ringcentralwebphoneService.extension != null">
                                <button *ngIf="callingNumber" class="phoneButtons" (click)="makeCall(callingNumber)" [disabled]="!isNumberInvalid" [class.disabled]="!isNumberInvalid">
                                    <svg height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg>
                                        {{'Webphone.Label.Call' | texttransform }}
                                </button>
                              <!--   <button *ngIf="callingNumber" (click)="deleteKeyNumber()" class="phoneButtonsExtraKeys">
                                    <svg class="deleteNumber" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#011b34" viewBox="0 0 256 256"><path d="M216,40H68.53a16.08,16.08,0,0,0-13.72,7.77L9.14,123.88a8,8,0,0,0,0,8.24l45.67,76.11A16.08,16.08,0,0,0,68.53,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM61.67,204.12,68.53,200h0ZM216,200H68.53l-43.2-72,43.2-72H216ZM106.34,146.34,124.69,128l-18.35-18.34a8,8,0,0,1,11.32-11.32L136,116.69l18.34-18.35a8,8,0,0,1,11.32,11.32L147.31,128l18.35,18.34a8,8,0,0,1-11.32,11.32L136,139.31l-18.34,18.35a8,8,0,0,1-11.32-11.32Z"></path></svg>
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedTab == 2" class="phoneDashboardTab">
                    <div class="phoneDirectCallTab">
                        <div class="dropdown" #dropdownList *ngIf="!blockTransferInput">
                            <input class="dropdown__input"
                            [(ngModel)]="transferNumber" 
                            (keyup)="filterInput($event, transferNumber)" 
                            (click)="filterInput($event, transferNumber)"
                            (keydown)="validateNumber(transferNumber)" 
                            [placeholder]="'Webphone.Label.NumberToTransfer' | texttransform">
                            <ul *ngIf="transferNumber && dropdownInputData.length > 0 && !ringcentralwebphoneService.transferReady" class="dropdown__list">
                                <li *ngFor="let contact of dropdownInputData let i = index" (mouseenter)="onMouseEnter()" (click)="setSelectedContact(contact, transferNumber)" class="dropdown__row" [class.active]="i === selectedIndex">
                                    <div class="dropdown__profile">
                                        <span class="dropdown__profile__shortName">{{contact?.firstName[0]}}{{contact?.lastName[0]}}</span>
                                        <p class=" dropdown__profile__name">{{contact?.firstName}} {{contact?.lastName}}</p>
                                    </div>
                                    <span class="dropdown__contact" *ngIf="this.commonService.checkIfStringContainsString(contact.extension, transferNumber) && this.commonService.checkIfStringContainsString(contact.phoneNumberNF, transferNumber)">{{contact.extension}}</span>
                                    <span class="dropdown__contact" *ngIf="this.commonService.checkIfStringContainsString(contact.firstName, transferNumber) || this.commonService.checkIfStringContainsString(contact.lastName, transferNumber)">{{contact.extension}}</span>
                                    <span class="dropdown__contact" *ngIf="this.commonService.checkIfStringContainsString(contact.extension, transferNumber) && !this.commonService.checkIfStringContainsString(contact.phoneNumberNF, transferNumber)">{{contact.extension}}</span>
                                    <span class="dropdown__contactPhoneNumber" *ngIf="this.commonService.checkIfStringContainsString(contact.phoneNumberNF, transferNumber) && !this.commonService.checkIfStringContainsString(contact.extension, transferNumber)">{{contact.phoneNumberNF}}</span>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="blockTransferInput" class="blockDropdown">
                            <span class="blockDropdown__input">{{transferNumber}}</span> 
                        </div>
                        <span *ngIf="ringcentralwebphoneService.transferReady" class="transferNumberMessage">{{'Webphone.Label.WeConnectYouWith' | texttransform}}<strong>{{transferNumber}}</strong></span>

                        <div class="phoneDirectCallTab__keypad">
                            <div class="phoneDirectCallTab__keypad__pad">
                                <button class="phoneDirectCallTab__keypad__key" *ngFor="let key of keypad" (click)="keyPadValue(selectedTab, key.value)">{{key.value}}</button>
                            </div>
                            <div class="phoneDirectCallTab__keypad__actionTransfer">
                                <button *ngIf="ringcentralwebphoneService.session" class="phoneButtons" (click)="cancelTransferCall(transferNumber)" [class.disabled]="!ringcentralwebphoneService.transferSession">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff1049" viewBox="0 0 256 256"><path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path></svg>
                                    {{'Webphone.Label.CancelCall' | texttransform }}
                                </button>
                                <button *ngIf="ringcentralwebphoneService.sessionState?.startTransfer && !ringcentralwebphoneService.transferReady" class="phoneButtons" (click)="makeTransferCall(transferNumber)" [disabled]="!isNumberInvalid" [class.disabled]="!isNumberInvalid">
                                    <svg height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg>
                                    {{'Webphone.Label.Call' | texttransform }}
                                </button>
                                <button *ngIf="ringcentralwebphoneService.sessionState?.startTransfer && ringcentralwebphoneService.transferReady" class="phoneButtons" (click)="ringcentralwebphoneService.warmTransfer()" [disabled]="!isNumberInvalid" [class.disabled]="!isNumberInvalid">
                                    <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m148.547-848a25.282 25.282 0 0 1 -10.4-2.295 31.118 31.118 0 0 1 -9.347-6.505 31.114 31.114 0 0 1 -6.505-9.348 25.281 25.281 0 0 1 -2.295-10.4 1.714 1.714 0 0 1 .505-1.263 1.713 1.713 0 0 1 1.263-.505h6.821a1.565 1.565 0 0 1 1.053.4 1.538 1.538 0 0 1 .547.947l1.095 5.895a2.969 2.969 0 0 1 -.042 1.137 1.771 1.771 0 0 1 -.463.8l-4.084 4.126a20.369 20.369 0 0 0 2 3.01 29.663 29.663 0 0 0 2.547 2.8 29.636 29.636 0 0 0 2.737 2.421 26.634 26.634 0 0 0 3.032 2.042l3.958-3.958a2.355 2.355 0 0 1 .989-.568 2.71 2.71 0 0 1 1.2-.105l5.811 1.179a1.95 1.95 0 0 1 .968.61 1.483 1.483 0 0 1 .379.99v6.821a1.714 1.714 0 0 1 -.505 1.263 1.714 1.714 0 0 1 -1.264.506zm-3.284-18.526-2.442-2.358 2.695-2.695h-8.674v-3.368h8.674l-2.611-2.61 2.358-2.443 6.737 6.821z" fill="#59aa8e" transform="translate(-120 880)"/></svg>
                                    {{'Webphone.Label.TransferCall' | texttransform }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedTab == 3" class="phoneDashboardTab">

                    <span *ngIf="loading == true">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                    </span> 
                    <ul class="phoneCallHistoryTab" *ngIf="callHistory?.length > 0">
                        <li *ngFor="let callItem of callHistory" class="phoneCallHistoryTab__row">
                            <div class="toNumberWrapper">
                                <span class="toNumberName ellipsisText" [tippyOptions]="{theme: 'callHistory', placement: 'right'}" [tippy]="callItem?.to?.extensionNumber ? callItem?.to?.extensionNumber : callItem?.to?.phoneNumber" *ngIf="callItem?.to?.name">{{callItem?.to?.name}}</span>
                                <span class="toNumber" *ngIf="!callItem?.to?.name && callItem?.to?.phoneNumber && !callItem.to.extensionNumber">{{callItem?.to?.phoneNumber}}</span>
                                <span class="toNumber" *ngIf="!callItem?.to?.name && callItem?.to?.extensionNumber">{{callItem?.to?.extensionNumber}}</span>
                            </div>
                            <span class="callStart">{{callItem?.start | date: 'medium' }}</span>
                            <span class="callDuration">{{callItem?.durationFormatted}}</span>
                            <button class="phoneCallHistoryTab__call" (click)="callItem?.to?.extensionNumber ? makeCallHistoryContact(callItem?.to?.extensionNumber) : makeCallHistoryContact(callItem?.to?.phoneNumber)" [class.disabled]="ringcentralwebphoneService.session" [disabled]="ringcentralwebphoneService.session">
                                <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg>
                            </button>
                        </li>
                    </ul>
                </div>

                <div *ngIf="selectedTab == 4" class="phoneDashboardTab">
                    <div class="phoneDashboardTab__filterWrapper">
                        <input [(ngModel)]="searchString" class="filterInput" [placeholder]="'Webphone.Label.SearchNameOrNumber'| texttransform" (keyup)="applyFilter()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#262626" viewBox="0 0 256 256"><path d="M228.24,219.76l-51.38-51.38a86.15,86.15,0,1,0-8.48,8.48l51.38,51.38a6,6,0,0,0,8.48-8.48ZM38,112a74,74,0,1,1,74,74A74.09,74.09,0,0,1,38,112Z"></path></svg>
                    </div>

                    <span *ngIf="loading == true">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
                    </span>

                    <ul *ngIf="adressBook?.length > 0" class="phoneAdressBookTab">
                        <li *ngFor="let adressItem of adressBookDisplay" class="phoneAdressBookTab__row">
                            <div class="adressCard">
                                <span class="adressCard__profile">{{adressItem?.firstName[0]}}{{adressItem?.lastName[0]}}</span>
                                <span class="adressCard__info">
                                    <p class="adressCard__info__name">{{adressItem?.firstName}} {{adressItem?.lastName}}</p>
                                    <p class="adressCard__info__jobTitle ellipsisText" *ngIf="adressItem?.jobTitle">{{adressItem?.jobTitle}}</p>
                                    <!-- <p class="adressCard__info__phoneNumber ellipsisText">{{adressItem?.phoneNumber}}</p> -->
                                </span>
                            </div>

                            <div class="adressCard__phone">
                                <button class="adressCard__call" *ngIf="!ringcentralwebphoneService.sessionState.startTransfer" 
                                    (click)="makeCallAdressBookContact(adressItem, 'phoneNumber')" [class.disabled]="ringcentralwebphoneService.session"
                                    [tippy]="adressItem?.phoneNumber">
                                    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m135.067-824a13.342 13.342 0 0 1 -5.489-1.211 16.42 16.42 0 0 1 -4.933-3.433 16.42 16.42 0 0 1 -3.433-4.933 13.343 13.343 0 0 1 -1.212-5.49.9.9 0 0 1 .267-.667.9.9 0 0 1 .667-.267h3.6a.827.827 0 0 1 .556.211.812.812 0 0 1 .289.5l.578 3.111a1.567 1.567 0 0 1 -.022.6.935.935 0 0 1 -.244.422l-2.156 2.178a10.749 10.749 0 0 0 1.056 1.589 15.638 15.638 0 0 0 1.344 1.478 15.635 15.635 0 0 0 1.444 1.278 14.053 14.053 0 0 0 1.6 1.078l2.089-2.089a1.244 1.244 0 0 1 .522-.3 1.431 1.431 0 0 1 .633-.056l3.067.622a1.028 1.028 0 0 1 .511.322.782.782 0 0 1 .2.522v3.6a.9.9 0 0 1 -.267.667.9.9 0 0 1 -.667.268z" fill="#59aa8e" transform="translate(-120 840)"/></svg> 
                                </button>
                                <button *ngIf="ringcentralwebphoneService.sessionState.startTransfer" class="adressCard__call" 
                                    (click)="transferAdressBookContact(adressItem, 'phoneNumber')" [class.disabled]="transferCallStarted"
                                    [tippy]="adressItem?.phoneNumber">
                                    <svg class="svgPlusTransfer"xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#59aa8e" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.13,104.13,0,0,0,128,24Zm40,112H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z"></path></svg>
                                </button>
                            </div>
                            <!-- <button *ngIf="adressItem.extensionNumber && ringcentralwebphoneService.sessionState.startTransfer" class="adressCard__call"  (click)="transferAdressBookContact(adressItem, 'extension')" [class.disabled]="transferCallStarted">
                                {{adressItem?.extensionNumber}}
                                <svg class="svgPlusTransfer"xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#59aa8e" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.13,104.13,0,0,0,128,24Zm40,112H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z"></path></svg>
                            </button> -->
                        </li>
                    </ul>
                </div>
            </div>

            <div class="phoneDashboard__body__wrapper" *ngIf="logoutRequest">
                <div class="phoneLogout">
                    <div class="phoneLogout__wrapper">
                        <label class="label">Unify Office</label>
                        <span>{{'AWS.Logout.Message'| texttransform }}</span>
                        <div class="phoneLogout__actions">
                            <button class="teamCustomButton__cancel" (click)="cancelLogoutRequest()">{{'AWS.Logout.Cancel' | texttransform }}</button>
                            <button class="teamCustomButton__logout" (click)="ringcentralwebphoneService.logout()">{{'AWS.Logout.Header' | texttransform }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>