import { Component, OnInit } from '@angular/core';
import { DashboardcoreService } from '../../dashboardcore.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-dashbdashboardcomponent-mytopcontacts',
  templateUrl: './dashbdashboardcomponent-mytopcontacts.component.html',
  styleUrls: ['./dashbdashboardcomponent-mytopcontacts.component.scss']
})
export class DashbdashboardcomponentMytopcontactsComponent implements OnInit {
  contacts = [];
  loadingData = false;
  

  constructor(
    private dashboardcoreService: DashboardcoreService,
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    this.getDatashboardData();
  }

  getDatashboardData() {
    const params = [];
    let dashboardId = 2;
    params.push({ key: '_max_items', value: 20 });
    if (this.applicationInfoService.myBestContactsOnlyAI) {
      params.push({ key: '_min_leadscore', value: this.getMinPepperoniValue() });
      dashboardId = 6;
    }    
    
    this.loadingData = true;
    this.dashboardcoreService.getDashboardData(dashboardId, params).then(getDatashboardDataResult => {
      this.contacts = getDatashboardDataResult[0];
      this.loadingData = false;
    }).catch(() => {
      this.loadingData = false;
    });
  }

  loadContact(contactId) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [contactId]);
  }

  getMinPepperoniValue() {
    return Number(this.applicationInfoService.applicationSettings['aiLeadscoreMinvalue']);
  }

  getAITitle(contact) {
    let returnValue = '';
    if (contact.aisuggestion) {
      returnValue = returnValue.concat(
        this.axivasTranslateService.getTranslationTextForToken('AIMessage.Label.AIWeblead'),
        '\n',
        '\n'
      );
    }
    if (contact.aicontact) {
      returnValue = returnValue.concat(
        this.axivasTranslateService.getTranslationTextForToken('AIMessage.Label.AIContact')
      );
    }
    return returnValue;
  }

  getLeadStateName(leadStateId) {    
    const leadStateItem = this.applicationInfoService.leadstates.toArray().find(leadState => leadState.id == leadStateId);
    if (leadStateItem) {
      return this.axivasTranslateService.getTranslationTextForObject(leadStateItem.lookupTable, 'nameTranslationToken');
    } else {
      return '';
    }
  }

  toggleSearchMode() {
    this.applicationInfoService.myBestContactsOnlyAI = !this.applicationInfoService.myBestContactsOnlyAI;
    this.getDatashboardData();
  }
}
