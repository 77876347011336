import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatarightsService {
  isExpanded = [];
  selectedGroup = null;
  public refreshDataRightGroupListEvent: EventEmitter<any> = new EventEmitter<any>();
  public refreshDataRightFiltersEvent: EventEmitter<any> = new EventEmitter<any>(); 

  constructor() { }
}
