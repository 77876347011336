<span id="dashboardContainerDesignSpan"></span>
<div class="dashboardContainerWrapper" 
    [style.max-height.px]="getControlHeight('dashboardContainerDesignSpan', 50)"
    [style.height.px]="getControlHeight('dashboardContainerDesignSpan', 50)"
>
    <div *ngIf="applicationInfoService.isDeveloper"
        class="customDashboard__headerDev everleadShadowBox designMode__header">
        <i class="fas fa-edit fingerCursor" (click)="showDesignMode()"></i>
        <i class="fas fa-sync fingerCursor" (click)="refreshView()"></i>
        <i class="fas fa-plus" *ngIf="designMode" (click)="addItem()"></i>
        <i class="fas fa-save" *ngIf="designMode" (click)="saveDashboard()"></i>
    </div>



    <div *ngIf="!designMode" class="dashboardItems">
        <!-- Dashboards -->
        <div *ngFor="let jsonLayoutItem of jsonLayout; let jsonLayoutItemIndex = index" class="dashboardDesignItem"
            [style.width]="getDashboardWidth(jsonLayoutItem)"
        >
            <app-custom-dashboardcontrol-dashboard class="d-flex maxHeight" *ngIf="dataLoaded" 
                [dashboard]="jsonLayoutItem"
                [dashboarddata]="dashboardData" 
                [dataid]="jsonLayoutItemIndex"                
            ></app-custom-dashboardcontrol-dashboard>
        </div>
    </div>




    <div *ngIf="designMode" class="dashboardItems">
        <!-- Designer -->
        <div cdkDropList [cdkDropListData]="jsonLayout" (cdkDropListDropped)="moveInList($event, '0')" class="dashboardDesignItem__wrapper">
            <div *ngFor="let jsonLayoutItem of jsonLayout" class="everleadShadowBox" cdkDrag [cdkDragData]="item" data-html="true" >
                <div class="dashboardDesignItemHeader">
                    <input [(ngModel)]="jsonLayoutItem.dasbboardTitle" class="maxWidth">
                    <i class="fas fingerCursor" (click)="toggleItem(jsonLayoutItem)" [ngClass]="{
                        'fa-chevron-down': toggledItems[jsonLayoutItem.guid] != true,
                        'fa-chevron-up': toggledItems[jsonLayoutItem.guid] == true
                    }"></i>
                    <i class="fas fa-grip-lines"></i>
                </div>
                <div class="dashboardDesignItem" *ngIf="toggledItems[jsonLayoutItem.guid] == true">
                    <div class="dashboardDesignItem__type dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.DasbboardTitle' | texttransform }}</label>
                        <input [(ngModel)]="jsonLayoutItem.dasbboardTitle" class="maxWidth">
                    </div>
                    <div class="dashboardDesignItem__type dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.DasbboardXTitle' | texttransform }}</label>
                        <input [(ngModel)]="jsonLayoutItem.dasbboardXTitle" class="maxWidth">
                    </div>
                    <div class="dashboardDesignItem__type dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.DasbboardYTitle' | texttransform }}</label>
                        <input [(ngModel)]="jsonLayoutItem.dasbboardYTitle" class="maxWidth">
                    </div>
                    <div class="dashboardDesignItem__type dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.Type' | texttransform }}</label>
                        <select [(ngModel)]="jsonLayoutItem.dashboardType">
                            <option [ngValue]=0>{{ 'DashboardControl.Label.Table' | texttransform }}</option>
                            <option [ngValue]=1>{{ 'DashboardControl.Label.BarChart1' | texttransform }}</option>
                            <option [ngValue]=2>{{ 'DashboardControl.Label.CakeChart1' | texttransform }}</option>

                            <option [ngValue]=50>{{ 'DashboardControl.Label.FixedMicroCampaigns' | texttransform }}</option>
                            <option [ngValue]=51>{{ 'DashboardControl.Label.FixedVipStuff' | texttransform }}</option>
                            <option [ngValue]=52>{{ 'DashboardControl.Label.BestNextContacts' | texttransform }}</option>
                            <option [ngValue]=53>{{ 'DashboardControl.Label.OptInOptOut' | texttransform }}</option>
                        </select>
                    </div>
                    <div class="dashboardDesignItem__type dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.Theme' | texttransform }}</label>
                        <select [(ngModel)]="jsonLayoutItem.theme">
                            <option [ngValue]=0>{{ 'DashboardControl.Label.BackgroundWhite' | texttransform }}</option>
                            <option [ngValue]=1>{{ 'DashboardControl.Label.BackgroundBlue' | texttransform }}</option>
                            <option [ngValue]=2>{{ 'DashboardControl.Label.BackgroundCoral' | texttransform }}</option>
                        </select>
                    </div>
                    <div class="dashboardDesignItem__query dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.SQL' | texttransform }}</label>
                        <textarea [(ngModel)]="jsonLayoutItem.sql"></textarea>
                    </div>
                    <div class="dashboardDesignItem__query dashboardDesignItem__line">
                        <label>{{ 'DashboardControl.Label.Width' | texttransform }}</label>
                        <input [(ngModel)]="jsonLayoutItem.width" class="maxWidth">
                    </div>
                    <div class="dashboardDesignItem__settings dashboardDesignItem__line">
                        <i class="fas fa-trash fingerCursor everleadCoral" (click)="remoteDashboard(jsonLayoutItem)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>