import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'app/shared/service/loader-service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { DatarightsService } from '../../datarights/datarights.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-datafilter-mainpanel',
  templateUrl: './datafilter-mainpanel.component.html',
  styleUrls: ['./datafilter-mainpanel.component.scss']
})
export class DatafilterMainpanelComponent implements OnInit, OnDestroy {
  updateDataFilterSegmentsEventSubscription: Subscription;
  public mainPanelMode = 0;
  public dataFilters = [];
  public filterSegments = [];
  public filterOperators = [];
  public filterEntities = [];
  public dataFilterId = -1;
  public lookupFilterTypeId = 11;
  public currentDataFilter = null;
  public currentOperator = null;
  public resultPreviewControl = null;
  public newDataFilterName = '';
  public newDataFilterOperator = null;
  public dataFilterMainEntity = 1;
  public dataFilterLogicalId = 1;
  public filterIsInUse = false;
  loadingdata = true;

  filterSegmentOperators = [];
  filterSegmentMemberOperators = [];
  validEntities = [];
  previewInnerHTMLBase = '<jj-universal controlid=<0>></jj-universal>';
  previewInnerHTML = null;
  emailBatches = [];

  constructor(
    private domSanitizer: DomSanitizer,
    private datafilterService: DatafilterService,
    private eventService: EventService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private uiService: UiService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private datarightsService: DatarightsService
  ) { }

  ngOnDestroy() {
    this.applicationInfoService.matTableTempFilter = null;
    if (this.updateDataFilterSegmentsEventSubscription) { this.updateDataFilterSegmentsEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.getDataFilters();
    this.getDataEMailBatches();
    this.datafilterService.getDataFilterOperators()
    .then(getDataFilterOperatorsResult => {
      getDataFilterOperatorsResult.sort((a, b) => a['defaultName'].toLowerCase() < b['defaultName'].toLowerCase() ? -1 : 1);
      const firstItem = this.commonService.getFirstItemFromArrayIfAvailable(getDataFilterOperatorsResult);
      if (firstItem) {
        this.newDataFilterOperator = firstItem.id;
      }
      this.filterOperators = getDataFilterOperatorsResult;
    });
    this.datafilterService.getProjectEntities()
    .then(getProjectEntitiesResult => {
      this.filterEntities = getProjectEntitiesResult.data.entities;
    });

    this.updateDataFilterSegmentsEventSubscription = this.eventService.updateDataFilterSegmentsEvent.subscribe(event => {
      this.getDataFilterInformation(this.currentDataFilter.id);
    });

    if (this.applicationInfoService.baseLogicalEntityIdForFilter) {
      this.dataFilterLogicalId = this.applicationInfoService.baseLogicalEntityIdForFilter;
      this.resultPreviewControl = this.applicationInfoService.dataFilterPreviewControl;
      this.dataFilterMainEntity = this.applicationInfoService.baseLogicalEntityIdForFilter;
      this.applicationInfoService.baseLogicalEntityIdForFilter = null;
    }
    if (this.applicationInfoService.loadFilterIdAtPopup) {
      this.dataFilterId = this.applicationInfoService.loadFilterIdAtPopup;
      this.applicationInfoService.loadFilterIdAtPopup = null;
      this.getDataFilterInformation(this.dataFilterId);
    }
    this.getSegmentOperators();
    this.getFilterSegmentMemberOperators()
    this.getValidEntities();
  }

  getFilterSegmentMemberOperators() {
    this.datafilterService.getSegmentMemberOperators()
    .then(getFilterSegmentMemberOperatorsResult => {
      this.filterSegmentMemberOperators = getFilterSegmentMemberOperatorsResult;
    });
  }
  
  setDataFilter(filter: any) {
    this.dataFilters = filter;
    this.currentDataFilter = this.commonService.getFirstItemFromArrayIfAvailable(this.dataFilters);
    if (this.currentDataFilter) {
      this.dataFilterId = this.currentDataFilter.id;
      this.getDataFilterInformation(this.dataFilterId);
    }
  }

  getDataEMailBatches() {
    this.externaldatasourceService.executeExternalDataSource(656)
    .then(getDataEMailBatchesResult => {
      this.emailBatches = getDataEMailBatchesResult;
    })
  }

  getDataFilters(showFilter = true): Promise<any> {
    return new Promise((getDataFiltersResolve, getDataFiltersReject) => {
      this.loadingdata = true;
      if (this.applicationInfoService.dataFilterFilterTemplate) {
        this.setDataFilter(this.applicationInfoService.dataFilterFilterTemplate);
        getDataFiltersResolve(this.applicationInfoService.dataFilterFilterTemplate);
        this.applicationInfoService.dataFilterFilterTemplate = null;
      } else {
        this.datafilterService.getDataFilters()
        .then(getDataFiltersResult => {
          if (showFilter) {
            this.setDataFilter(getDataFiltersResult); 
          } else {
            this.dataFilters = getDataFiltersResult;
          }
          this.loadingdata = false;
          getDataFiltersResolve(getDataFiltersResult);
        })
        .catch(error => { 
          this.loadingdata = false;
          getDataFiltersReject(error); 
        });
      }
    });
  }
  newDataFilter() {
    this.mainPanelMode = 1;
  }

  changeDataFilter() {
    this.getDataFilterInformation(this.dataFilterId);
  }

  getDataFilterInformation(dataFilterId: any) {    
    if (dataFilterId == null) {
      return;
    }
    this.datafilterService.getDataFilterInformation(dataFilterId)
    .then(getDataFilterInformationResult => {
      this.currentDataFilter = getDataFilterInformationResult;
      this.currentOperator = this.currentDataFilter.lookupSegmentOperatorId;
      this.filterSegments = [];
      getDataFilterInformationResult.filterSegments.forEach(filterSegment => {
        this.filterSegments.push(filterSegment);
      });
      this.loadPreviewControl();
    });
    this.applicationInfoService.matTableTempFilter = dataFilterId;    
  }

  loadPreviewControl() {
    this.resultPreviewControl = null;
    switch(this.currentDataFilter.mainEntityId) {
      case 1:
        this.resultPreviewControl = '113084';
        break;
      case 4:
        this.resultPreviewControl = '113085';
        break;  
    }
    if (this.resultPreviewControl) {
      this.previewInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.previewInnerHTMLBase.replace('<0>', this.resultPreviewControl));  
    }
  }

  undoChanges() {
    this.getDataFilterInformation(this.currentDataFilter.id);
  }

  cancelNewDataFilter() {
    this.mainPanelMode = 0;
  }

  checkIfFilterIsInUse(filterId) {
    this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.CentralAPI, ApolloMethod.Query,
      this.graphqlqueryService.dataFilterFollowUpUserGroupsByFilterId, [filterId])
    .then(dataFilterCreateFilterDuplicateMutationResult => {
      if (dataFilterCreateFilterDuplicateMutationResult.data.followUpUserGroupsByFilter.length > 0) {
        this.filterIsInUse = true;
      } else {
        this.filterIsInUse = false;
      }
    });
  }

  copyDataFilter() {
    this.datafilterService.copyDataFilter(this.currentDataFilter.id, this.newDataFilterName)
    .then(copyDataFilterResult => {
      this.newDataFilterName = '';
      this.getDataFilters().then(() => {
        this.getDataFilterInformation(copyDataFilterResult.data.createFilterDuplicateMutation.id);
        this.dataFilterId = copyDataFilterResult.data.createFilterDuplicateMutation.id;
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
        this.axivasTranslateService.getTranslationTextForToken('DataFilterMainPanel.Label.CreateNewDataFilterSuccess'), false);
      });
      this.mainPanelMode = 0;
    });
  }

  createNewDataFilter() {
    if (this.newDataFilterName.length < 2) {
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, '',
        this.axivasTranslateService.getTranslationTextForToken('DataFilterMainPanel.Label.CreateNewFilterNameLengthError'), false);
      return;
    }
    this.loaderService.display(true);
    this.datafilterService.createNewFilter(this.dataFilterMainEntity, this.newDataFilterName, this.newDataFilterOperator
      , this.applicationInfoService.dataFilterLookupFilterTypeId)
    .then(createNewDataFilterResult => {
      this.mainPanelMode = 0;
      this.datafilterService.addNewFilterSegment(createNewDataFilterResult.data.createFilterMutation, true)
      .then(() => {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
          this.axivasTranslateService.getTranslationTextForToken('DataFilterMainPanel.Label.CreateNewDataFilterSuccess'), false);
          this.newDataFilterName = '';          
          this.getDataFilters(false).then(() => {
            this.currentDataFilter = null;
            this.filterSegments = null;
            this.dataFilterId = createNewDataFilterResult.data.createFilterMutation.id;
            this.changeDataFilter();
            this.loaderService.display(false);
        })
        .catch(() => { this.loaderService.display(false); });
      })
      .catch(() => {this.loaderService.display(false); });
    })
    .catch(error => { console.error(error); this.loaderService.display(false); });
  }

  changeOperator(currentOperator: any) {
    this.currentDataFilter.lookupSegmentOperatorId = currentOperator;
    this.saveCurrentDataFilter();
  }

  addNewFilterSegment() {
    this.datafilterService.addNewFilterSegment(this.currentDataFilter)
    .then(() => {
      this.getDataFilterInformation(this.currentDataFilter.id);
    });
  }

  saveCurrentDataFilter() {
    this.externaldatasourceService.executeExternalDataSource(592, [
      this.commonService.getModifyArrayBody(this.currentDataFilter, [])
    ]);
  }

  deleteFilter() {
    // this.datafilterService.deleteFilter(this.currentDataFilter);
  }

  selectCurrentDataFilter(dataFilter) {
    this.eventService.selectCurrentDataFilterEvent.emit(new JJEvent(this.applicationInfoService.dataFilterCaller, [dataFilter]));
    this.eventService.closeJjPopup();
  }

  showPreview() {
    this.eventService.compactListRunQueryWithSpecificFilterEvent.emit(new JJEvent(this.resultPreviewControl, [this.currentDataFilter.id]));
  }

  changeMainEntityId() {
    this.loadPreviewControl();
    this.saveCurrentDataFilter();
  }

  getValidEntities() {
    this.validEntities = this.datafilterService.getValidEntities(this.applicationInfoService.dataFilterLookupFilterTypeId);    
  }

  getSegmentOperators() {
    return new Promise<void>((getSegmentOperatorsResolve, getSegmentOperatorsReject) => {
      this.datafilterService.getDataFilterOperators()
      .then(getDataFilterOperatorsResult => {
        this.filterSegmentOperators = getDataFilterOperatorsResult;
        getSegmentOperatorsResolve();
      })
      .catch(error => { getSegmentOperatorsReject(error); });
    });
  }

  getCurrentOperator() {
    return this.filterOperators.find(operator => operator.id == this.currentOperator);
  }
}
