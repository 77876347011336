import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EventService } from './event.service';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalContentService {

  cssList: string[] = [];
  jsList: string[] = [];

  constructor(
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  public GetCSSForCustomer(customerID: string): string[] {
    this.cssList = [];
    if (customerID === '000001') {
      this.cssList.push('../../../assets/customer/000001/style.css');
    }
    if (customerID === '000002') {
      this.cssList.push('../../../assets/customer/000002/style.css');
    }
    return this.cssList;
  }
  
  public GetJSForCustomer(customerID: string): string[] {
    this.jsList = [];
    if (customerID === '000001') {
      this.jsList.push('../../../assets/customer/000001/adress.js');
    }
    if (customerID === '000002') {
      this.jsList.push('../../../assets/customer/000002/adress.js');
    }
    return this.jsList;
  }

  public unloadCustomer() {
    this.modifyCustomerFiles('', false);
  }

  public loadCustomer(customer: string) {
    this.modifyCustomerFiles(customer, true);
  }

  modifyCustomerFiles(customer: string, load: boolean = true) {
    if (load) {
      this.GetCSSForCustomer(customer);
      this.GetJSForCustomer(customer);
    }

    let cssID = 1;
    for (const css of this.cssList) {
      this.loadDocument(css, 'custCSS' + cssID, load);
      cssID ++;
    }
    let jsID = 1;
    for (const js of this.jsList) {
      this.loadDocument(js, 'custJS' + jsID, load);
      jsID ++;
    }
  }

  loadDocument(url, id: string, add: boolean) {
    let element;
    if (add) {
      if (url.endsWith('.js')) {
        element = document.createElement('script');
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;
        element.id = id;
      }
      if (url.endsWith('.css')) {
        element = document.createElement('link');
        element.rel = 'stylesheet';
        element.href = url;
        element.id = id;
      }
      document.getElementsByTagName('head')[0].appendChild(element);
    } else {
      element = document.getElementById(id);
      document.getElementsByTagName('head')[0].removeChild(element);
    }
  }

  public runMethodFromScriptEx(methodName: string, param: any = ''): Observable<any> {
    let returnValue = new Observable<any>();
    const fn = window[methodName];
    if (typeof fn === 'function') {
      returnValue = fn(param);
    }
    return of (returnValue);
  }

  public runMethodFromScript(methodName: string) {
    this.runMethodFromScriptEx(methodName).subscribe(retEvent => {
      if (retEvent) {
        switch (retEvent) {
        }
      }
    });
  }

  public removeDynamicCSS(styleId: any) {
    const node = document.getElementById(styleId);
    if (node != null) {
      document.getElementsByTagName('head')[0].removeChild(node);
    }
  }

  public createDynamicCSS(styleId: any, contentOfFile: any) {
    this.removeDynamicCSS(styleId);
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = styleId;
    style.innerHTML = contentOfFile;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  public addStyleFromDataBase(styleIds: any[]): Promise<any> {
    return new Promise<any> ((addStyleFromDataBaseResolve, addStyleFromDataBaseReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.externalContentServiceUiStyle, [styleIds])
      .then(externalContentServiceUiStyleResult => {
        externalContentServiceUiStyleResult.data.uiStyles.forEach(uiStyle => {
          this.createDynamicCSS('jollyJupiterStyle' + uiStyle.id, uiStyle.style);
          addStyleFromDataBaseResolve(externalContentServiceUiStyleResult);
        });
        if (this.applicationInfoService.projectInfo.uiStyle) {
          this.removeDynamicCSS('projectCSS');
          this.createDynamicCSS('projectCSS', this.applicationInfoService.projectInfo.uiStyle.style);
        }
      })
      .catch(error => { addStyleFromDataBaseReject(error); });
    });
  }

  setProjectCSS(projectStyle) {
    this.removeDynamicCSS('projectCSS');
    if (projectStyle) {
      this.createDynamicCSS('projectCSS', projectStyle);
    }
  }

  setNewDesignCSS(loadDesign: boolean) {
    this.removeDynamicCSS('newDesignCSS');
    if (loadDesign === true) {
      if (this.applicationInfoService.useNewDesign) {
        this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.externalContentServiceUiStyle, [42])
        .then(uiStyle => {
          uiStyle = uiStyle.data.uiStyles;
          this.createDynamicCSS('newDesignCSS', uiStyle[0].style);
        });
      }
    }
  }
}
