<div class="websiteDashboard__wrapper">
    <div class="websiteDashboard__topRow">
        <div class="websiteDashboard__topRow__item websiteDashboard__topRow__item__left">
            <app-dashbdashboardcomponent-myvisitors class="maxHeight"></app-dashbdashboardcomponent-myvisitors>
        </div>
        <div class="websiteDashboard__topRow__item websiteDashboard__topRow__item__right">
            <app-dashboardcomponent-myconversions class="maxHeight"></app-dashboardcomponent-myconversions>
        </div>
    </div>
    <div class="websiteDashboard__bottomRow everleadShadowBox">
        <div class="everleadContentContainer" class="maxHeight">
            <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
        </div>
    </div>
</div>

