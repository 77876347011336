<div [ngStyle]="setControlStyle('control')" style="position: relative;" [id]="getMyId()" class="registerMinHeight registerLayout">
  <div class="jjTabWrapper">
    <button class="scroll-button" *ngIf="list.isOverflow" [class.disable]="!list.canScrollStart" [ngClass]="{ hideInDom: !list.canScrollStart }" 
      (click)="list.scroll(-1)" id="scroll-left">
      <svg-icon src="assets/images/svg/arrow_left.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
    </button>
    <div id="jjTab" class="jjTab desktop-visible" appScrollable #list="appScrollable" [scrollUnit]="150">    
      <button [ngClass]="{ 
          jjActivetab: child.logicalControlId == selectedTabIndex,
          jjControlDefaultColor: child.logicalControlId == selectedTabIndex,
          jjInactivetab: child.logicalControlId != selectedTabIndex
        }" *ngFor="let child of visibleTabs; let currentIndex = index" class="tablinks"
        (click)="setCurrentChild(child.logicalControlId)"
        >
        {{ commonService.getTranslationValueFromArray(child, 'displayText') | texttransform }}
      </button>    
    </div>  
    <button class="scroll-button" *ngIf="list.isOverflow"  [class.disable]="!list.canScrollEnd" [ngClass]="{ hideInDom: !list.canScrollEnd }"  (click)="list.scroll(1)">
      <svg-icon src="assets/images/svg/arrow_right.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }" ></svg-icon> 
    </button>  
  </div>

  <select class="tabSelectWrapper" [(ngModel)]="selectedTabIndex"> 
    <option [value]="child.logicalControlId" *ngFor="let child of visibleTabs">{{ commonService.getTranslationValueFromArray(child, 'displayText') | texttransform }}</option>
  </select>

  <div class="mainContainer noScrollbar">
    <div class="mainContainer__tab">
      <div *ngFor="let child of visibleTabs; let currentIndex = index" [id]="child.logicalControlId">
        <div *ngIf="child.logicalControlId == selectedTabIndex">
          <app-custom-container controluiid={{child.logicalControlId}} [ngStyle]="getSubContainerWidth(child)"></app-custom-container>
          <span *ngIf="child.value != null && child.value != ''">
            <div [innerHTML]="innerHTMLs[child.id]" class="account_footer__item"></div>        
          </span>
        </div>
      </div>
    </div>
  </div>
  
  <span [id]="getRegisterId()" class="bottomRegister"></span>
</div>
