<div class="customTextControlWrapper">
    <label class="customTextControlWrapper__label">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
    <div class="d-flex">
        <input class="customTextControlWrapper__textbox customTextControlWrapper__oneIcon"
            [disabled]="!checkIfControlIsEnabled()" (change)="callMethod('onchange')" [(ngModel)]="controlValue"
            [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly
            appendTo="body" aria-modal="true"
            (dateTimeChange)="callMethod('onchange')" name="custom-calendar-{{getControlMainId()}}">
        <owl-date-time #dt7></owl-date-time>
        <!-- <input type="datetime"> -->
        <svg-icon src="assets/images/svg/calendar.svg" class="fingerCursor customTextControlWrapper__button"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="clearControlValue()"></svg-icon>
    </div>
</div>