import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { VipLookup, VipService } from 'app/jollyjupiter/service/vip.service';

@Component({
  selector: 'app-custom-website',
  templateUrl: './custom-website.component.html',
  styleUrls: ['./custom-website.component.scss']
})
export class CustomWebsiteComponent extends CustomControlBaseClass implements OnInit {
  isVip = false;

  constructor(
    private injector: Injector,
    private vipService: VipService
  ) {
    super(injector);
    this.valueType = ValueType.String;
    this.controlGuidPredefine = 'website';
  }

  showURL() {
    if (this.controlValue == null || this.controlValue === '') {
      return;
    }
    let url: string =  this.controlValue;
    if (url.indexOf('http') === -1) {
      url = 'http://' + url;
    }
    window.open(url);
  }

  ngOnInit() {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.checkAttributes();
    this.checkIfIsVip();
  }

  keyDown() {
    this.eventService.entityKeyDownEvent.emit(this.controlDefinition.uiEntityInstanceId)
  }

  checkIfIsVip() {
    if (this.applicationInfoService.applicationSettings['showVipSettings'] != 'true') {
      return;
    }
    
    if (this.controlValue != null && this.controlValue != '') {
      this.vipService.checkIfProspectIsVip({ url: this.controlValue })
      .then(checkIfProspectIsVipResult => {
        this.isVip = checkIfProspectIsVipResult;
      })
      .catch(error => {
        console.error(error);
      });
    } else {
      this.isVip = false;
    }
  }

  toggleVip() {
    if (this.isVip) {
      this.vipService.deleteProspectFromList({ url: this.controlValue }).then(() => { this.isVip = false; });
    } else { 
      this.vipService.addItemToVipList(this.applicationInfoService.currentAccount.name1, this.controlValue, VipLookup.AccountUrl)
      .then(() => { this.isVip = true; });;
    }
  }

  urlChanged() {
    this.checkIfIsVip();
    this.callMethod('onchange');
  }
}
