<div class="customAccountOwner customAccountOwnerMode1" *ngIf="mode==1">
    <label class="customTextControlWrapper__label">    
        {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
    </label>
    <select [(ngModel)]="controlValue" [disabled]="!checkIfControlIsEnabled()" (change)="changeOwner(controlValue)">
        <option *ngFor="let accountOwner of accountOwners" [ngValue]="accountOwner.parent.id">
            {{ accountOwner.parent.name3 }} {{ accountOwner.parent.name2 }} {{ accountOwner.parent.name1 }}
        </option>
    </select>
</div>

<div class="customAccountOwner" *ngIf="mode==2 && ringcentralwebphoneService.session != null" class="customAccountOwnerMode2">
    <div *ngIf="ringcentralwebphoneService.makeCallObject?.accountId == null" class="customAccountOwner__bindCall everleadCoral">
        {{ 'CustomAccountOwner.Label.AccountNotBoundYet' | texttransform }}
    </div>
    <button class="everleadCustomButton" (click)="assignCallToAccount()">{{ 'CustomAccountOwner.Label.AssignCall' | texttransform }}</button>
</div>

<div *ngIf="mode==3" class="customAccountOwnerMode3">
    <button (click)="getEONVCheck()" class="everleadCustomButton">VCheck</button>
</div>

<div *ngIf="mode==4" class="customAccountOwnerMode4">
    <button (click)="createTask()" class="everleadCustomButton">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</button>
</div>

<div *ngIf="mode==5" class="customAccountOwnerMode5">
    <button class="everleadCustomButton" (click)="searchAdress()">Suche</button>
</div>

<div *ngIf="mode==6" class="customAccountOwnerMode6">
    <button class="everleadCustomButton" (click)="checkAvailability()">Verfügbarkeit</button>
</div>

<div *ngIf="mode==7" class="customAccountOwnerMode7">
    <div class="extendEverleadDemo__header">
        {{ 'CustomAccountOwner.Label.EverleadTrial' | texttransform }}
    </div>
    <button class="everleadCustomButton" (click)="checkEverleadDemo()" *ngIf="extendEverleadResult == null">
        {{ 'CustomAccountOwner.Label.CheckEverleadDemoExtension' | texttransform }}
    </button>
    <div *ngIf="extendEverleadResult != null" class="extendEverleadDemo__details">
        <div class="extendEverleadDemo__details__line">
            <div class="extendEverleadDemo__details__line__label">{{ 'CustomAccountOwner.Label.ExtendEverleadDemoUser' | texttransform }}</div>
            <div class="extendEverleadDemo__details__line__value">{{ extendEverleadResult.userName }}</div>
        </div>
        <div class="extendEverleadDemo__details__line">
            <div class="extendEverleadDemo__details__line__label">{{ 'CustomAccountOwner.Label.TrialEndsAt' | texttransform }}</div>
            <div class="extendEverleadDemo__details__line__value">{{ extendEverleadResult.trialEndsAt | date: 'medium' }}</div>
        </div>
        <div class="extendEverleadDemo__details__line">
            <div class="extendEverleadDemo__details__line__label">{{ 'CustomAccountOwner.Label.TrialExtensibleUntil' | texttransform }}</div>
            <div class="extendEverleadDemo__details__line__value">{{ extendEverleadResult.trialExtensibleUntil | date: 'medium' }}</div>
        </div>
        <div class="everleadCoral" *ngIf="extendEverleadResult.trialExtensionGuid == null">
            {{ 'CustomAccountOwner.Label.EverleadDemoCantBeExtended' | texttransform }}
        </div>
        <div *ngIf="extendEverleadResult.trialExtensionGuid != null">
            <button class="everleadCustomButton" (click)="extendEverleadDemo()">{{ 'CustomAccountOwner.Label.ExtendEverleadDemo' | texttransform }}</button>
        </div>
    </div>
</div>

<div *ngIf="mode==8" class="customAccountOwnerMode3">
    <button (click)="getEONVCheck(46)" class="everleadCustomButton" [disabled]="
        commonService.isNullOrUndefined(applicationInfoService.entities.Item('46').data.street) ||
        commonService.isNullOrUndefined(applicationInfoService.entities.Item('46').data.zipcode) ||
        commonService.isNullOrUndefined(applicationInfoService.entities.Item('46').data.city) ||
        commonService.isNullOrUndefined(applicationInfoService.entities.Item('46').data.hausnummer)
    ">HA AdressId ermitteln</button>    
</div>
