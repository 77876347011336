import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LanguageService } from 'app/shared/translation/language.service';

@Component({
  selector: 'app-wizard-translation-popup',
  templateUrl: './wizard-translation-popup.component.html',
  styleUrls: ['./wizard-translation-popup.component.scss']
})
export class WizardTranslationPopupComponent implements OnInit {
  languages = [];

  constructor(
    public wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private refreshValueService: RefreshValueService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.getLanguages();
  }

  getLanguages() {
    this.languageService.getLanguages().then(getLanguageResult => {
      this.languages = getLanguageResult;
      console.warn(this.languages);
    });    
  }

  getTranslations() {    
    if(this.wizardService.translationArrayBase.nameTranslationTokenId != null) {
      this.externaldatasourceService.executeExternalDataSource(116, [this.wizardService.translationArrayBase.nameTranslationTokenId])
      .then(result => {
        result.translations.forEach(translation => {
          this.wizardService.translationArray['name' + translation.language.id] = translation.translation;
          this.wizardService.translationIds['name' + translation.language.id] = translation.id;
          // if (translation.language.id == 1) { 
          //   this.wizardService.translationArray['nameen'] = translation.translation; 
          //   this.wizardService.translationIds['nameen'] = translation.id;
          // }
          // if (translation.language.id == 2) { 
          //   this.wizardService.translationArray['namede'] = translation.translation; 
          //   this.wizardService.translationIds['namede'] = translation.id;
          // }
        });
      });
    }

    if(this.wizardService.translationArrayBase.descriptionTranslationTokenId != null) {
      this.externaldatasourceService.executeExternalDataSource(116, [this.wizardService.translationArrayBase.descriptionTranslationTokenId])
      .then(result => {
        result.translations.forEach(translation => {
          this.wizardService.translationArray['desc' + translation.language.id] = translation.translation;
          this.wizardService.translationIds['desc' + translation.language.id] = translation.id;
          // if (translation.language.id == 1) { 
          //   this.wizardService.translationArray['descriptionen'] = translation.translation; 
          //   this.wizardService.translationIds['descriptionen'] = translation.id;
          // }
          // if (translation.language.id == 2) { 
          //   this.wizardService.translationArray['descriptionde'] = translation.translation; 
          //   this.wizardService.translationIds['descriptionde'] = translation.id;
          // }
        });
      });
    }
    this.wizardService.showTranslationEditor = true;
  }

  changeTranslation(section, languageId) {
    let sectionText = '';    
    switch (section) {
      case 'name':
        sectionText = 'nameTranslationTokenId';        
        break;
      case 'desc': 
        sectionText = 'descriptionTranslationTokenId';
        break;
    }
    
    console.warn(section, languageId, this.wizardService.translationArrayBase[sectionText]);

    if (this.wizardService.translationArrayBase[sectionText]) {
      this.upsertTranslation(languageId, this.wizardService.translationArrayBase[sectionText], section);
    } else {
      // Create Token first, update basearray
      this.createNewToken(sectionText)
      .then(() => {
        this.upsertTranslation(languageId, this.wizardService.translationArrayBase[sectionText], section);
      });
    }
  }

  upsertTranslation(languageId, translationTokenId, section) {
    const translationId = this.wizardService.translationIds[section + languageId];
    const translationText =  this.wizardService.translationArray[section + languageId];
    if (this.commonService.isNullOrUndefined(translationId)) {
      this.externaldatasourceService.executeExternalDataSource(113, [languageId, translationTokenId, translationText]).
      then(createTranslationResult => {  this.wizardService.translationIds[section + languageId] = createTranslationResult.id; });
    } else {
      this.externaldatasourceService.executeExternalDataSource(112, [translationText, translationId]).then(() => { });
    }    
  }

  createNewToken(sectionText): Promise<any> {
    return new Promise((createNewTokenResolve, createNewTokenReject) => {
      const token = new Object();
      this.loaderService.display(true);
      token['group'] = 'DesignerCreated';
      token['subgroup'] = 'DesignerCreated_' + this.commonService.createGuid();
      token['tokenName'] = this.commonService.createGuid();      
      if (this.wizardService.translationProjectSpecific == true) {
        token['projectId'] = this.applicationInfoService.projectID;
      } else {
        token['projectId'] = null;
      }
      this.externaldatasourceService.executeExternalDataSource(109, [
        this.commonService.getModifyArrayBody(token, [])
      ])
      .then(result => {
        this.wizardService.translationArrayBase[sectionText] = result.id;
        this.externaldatasourceService.executeExternalDataSource(this.wizardService.translationArrayBaseUpdateExternalDataSourceId, [
          this.commonService.getModifyArrayBody(this.wizardService.translationArrayBase, this.wizardService.exclude)
        ]).then(() => {
          this.loaderService.display(false);
          createNewTokenResolve(null);
          if (this.wizardService.translationArrayBaseUpdateExternalDataSourceId == 161) {
            this.refreshValueService.updateUiControl(this.wizardService.wizardArray.id);
          }          
        })
        .catch(error => {
          createNewTokenReject(error);
          this.loaderService.display(false);
        });
      });  
    });
  }
}
