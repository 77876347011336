<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{'VoiceScript.Label.IsActive' | texttransform }}
                    <!-- <app-wizard-infobutton displayText="StepResultDesign.Label.IsNetResultDescription">
                    </app-wizard-infobutton> -->
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isActive" type="checkbox">
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{'VoiceScript.Label.DefaultName' | texttransform }}
                    <!-- <app-wizard-infobutton displayText="StepResultDesign.Label.IsNetResultDescription">
                    </app-wizard-infobutton> -->
                </label>
                <input [(ngModel)]="wizardService.wizardArray.defaultName">
            </div>
        </div>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="wizardPage">
        <div class="wizardEMail__wrapper">            
            <div class="wizardDetailItem wizardEMail__emailFlex">            
                <!-- <div class="wizardEMail__emailEditBar__item">
                    <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(1)"
                        [ngClass]="{ activeColor: viewMode == 1 }">{{ 'EMailContent.Label.Editor' | texttransform }}</label>
                    <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(2)"
                        [ngClass]="{ activeColor: viewMode == 2 }">{{ 'EMailContent.Label.Preview' | texttransform
                        }}</label>
                </div> -->
                <div class="maxHeight" *ngIf="viewMode == 1">
                    <textarea #templateContentEditor class="wizardEMail__wizardEmailEditor"
                        [(ngModel)]="wizardService.wizardArray.content" (click)="getCaretPos(templateContentEditor)"
                        (keyup)="getCaretPos(templateContentEditor)">
                </textarea>
                </div>
                <div class="maxHeight" *ngIf="viewMode == 2">
                    <div [innerHTML]="innerHTML">

                    </div>
                </div>
            </div>            
            <div class="wizardDetailItem wizardEMail__addons" *ngIf="viewMode == 1">
                <div class="wizardEMail__emailEditBar__item">
                    {{ 'EMailContent.Label.Placeholders' | texttransform }}
                </div>       
                    
                    <div class="wizardEMail__addons__content">                    
                        <div class="wizardEMail__placeholder" *ngFor="let placeholder of placeholders">
                            <i class="fas fa-chevron-circle-left fingerCursor" (click)="addMemberToTemplate(placeholder)"></i>
                            {{ getPlaceHolderInfo(placeholder) }}
                        </div>    
                    </div>             
            </div>
        </div>
    </div>
</div>    