
<div *ngIf="action == 1">
    <div *ngIf="jobAccepted == false">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>Approving...
    </div>
    <div *ngIf="jobAccepted == true">
        <label>{{ requestAnswerText }}</label>
    </div>
</div>

<div *ngIf="action == 2">
    <div *ngIf="jobStopped == false">
        <label class="acceptLabel">Kommentar</label>
        <textarea [(ngModel)]="reportingDeclineText" class="acceptTextArea"></textarea>
        <br>
        <button (click)="stopJob()">Reporting stoppen</button><br>
        <br>
        <br>
        <label>Sie können alternativ einfach eine Mail an <a href= "mailto:ithelp@alex-gross.com">ithelp&#64;alex-gross.com</a> verschicken.</label>
    </div>

    <div *ngIf="jobStopped != false">
        <label>{{ requestAnswerText }}</label>
    </div>
</div>

<div *ngIf="applicationInfoService.isDeveloper">
    Action {{ action }} - AssignmentId {{ aid }} - ReportId {{ rid }} - Date {{ date }} - ProjectId {{ pid }}
    <br>
    <br>
    {{ applicationInfoService.applicationInitialized }}
</div>
