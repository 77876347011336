<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
            texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
            texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div *ngIf="wizardService.wizardArray._1130_contactid == null" class="maxHeight">
                <div class="everleadSmallHeader">{{ 'MailToContact.Label.QuickSearch' | texttransform }}</div>            
                <div class="quickSearch">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                    <input [(ngModel)]="searchStringQuickSearch" (keydown)="handleQuickSearchKeyDown($event)" (change)="quickSearchContacts()">
                </div>
                <div>
                    <div class="everleadShadowBox fingerCursor" (click)="noContactFound()">
                        {{ 'MailToContact.Label.ContactNotInList' | texttransform }}
                    </div>
                    <div class="everleadSmallHeader" *ngIf="foundItems.length > 0">{{ 'MailToContact.Label.SearchResult' | texttransform }}</div>
                    <div *ngFor="let foundItem of foundItems" class="everleadShadowBox fingerCursor" (click)="selectExistingContact(foundItem)">
                        <div class="everleadSmallHeader">{{ foundItem.accountText }}</div>
                        <div>{{ foundItem.first_name }} {{ foundItem.last_name  }} ({{ foundItem['account.name1'] }})</div>
                    </div>
                    <div class="everleadSmallHeader" *ngIf="foundItems.length == 0">{{ 'MailToContact.Label.NoSearchResult' | texttransform }}</div>            
                </div>             
            </div>
            <div *ngIf="wizardService.wizardArray._1130_contactid != null" class="mailtoContactInfoPanel">
                {{ 'MailToContact.Label.AlreadyAssigned' | texttransform }}
            </div>   
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div *ngIf="wizardService.wizardArray._1130_contactid == null" class="maxHeight">
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'MailToContact.Label.FirstName' | texttransform }}</label>
                    <input [(ngModel)]="wizardService.wizardArray.firstName" class="wizardInput">
                </div>
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'MailToContact.Label.LastName' | texttransform }}</label>
                    <input [(ngModel)]="wizardService.wizardArray.lastName" class="wizardInput">
                </div>
                <!-- <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'MailToContact.Label.CreateConversion' | texttransform }}</label>
                    <input [(ngModel)]="wizardService.wizardArray.createConversion" type="checkbox">
                </div> -->
                <br>
                <div class="wizardButtonDiv">
                    <button class="jjButtonColor jjButtonStyle" (click)="createContact()" [disabled]="wizardService.wizardArray.lastName == ''">
                        {{ 'MailToContact.Label.Create' | texttransform }}</button>
                </div>
                <!-- <div class="wizardButtonDiv">
                    <button class="jjButtonColor jjButtonStyle" (click)="createContact(true)"[disabled]="wizardService.wizardArray.lastName == ''">
                        {{ 'MailToContact.Label.CreateAndOpen' | texttransform }}</button>
                </div> -->
            </div>
            <div *ngIf="wizardService.wizardArray._1130_contactid != null" class="mailtoContactInfoPanel">
                {{ 'MailToContact.Label.AlreadyAssigned' | texttransform }}
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3" class="maxHeight">
            <div *ngIf="wizardService.wizardArray._1130_contactid == null" class="mailtoContactInfoPanel">
                {{ 'MailToContact.Label.NotAssignedToContact' | texttransform }}
            </div>
            <div *ngIf="wizardService.wizardArray._1130_contactid != null && this.wizardService.wizardArray._1130_conversionid == null">
                <div class="wizardDetailItem">
                    <button class="everleadCustomButton" (click)="createConversion()">{{ 'MailToContact.Label.CreateConversion' | texttransform }}</button>
                </div>
            </div>
            <div *ngIf="wizardService.wizardArray._1130_contactid != null && this.wizardService.wizardArray._1130_conversionid != null">
                {{ 'MailToContact.Label.ConversionAlreadyCreated' | texttransform }}
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4" class="maxHeight">
            <div *ngIf="wizardService.wizardArray._1130_contactid != null">
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{ 'MailToContact.Label.Page4' | texttransform }}</label>
                    <select class="maxWidth" [(ngModel)]="selectedMicroCampaign" (change)="startCampaign()">
                        <option *ngFor="let microcampaign of microcampaigns" [ngValue]="microcampaign">{{ microcampaign.defaultName | texttransform }}</option>
                    </select>                  
                </div>
            </div>
            <div *ngIf="wizardService.wizardArray._1130_contactid != null && campaignSelected && selectedMicroCampaign != null" class="mailToContactMicroCampaign">
                <app-booking *ngIf="optinMissing==false && optinCheckPending==false"></app-booking>
                <div *ngIf="optinMissing==true && optinCheckPending==false" class="optinMissingInfo">
                    {{ 'MailToContact.Label.MicroCampaignRequiresDoubleOptin' | texttransform }}
                </div>
            </div>
            <div *ngIf="wizardService.wizardArray._1130_contactid == null" class="mailtoContactInfoPanel">{{ 'MailToContact.Label.NotAssignedToContact' | texttransform }}</div>
        </div>
    </div>
</div>


