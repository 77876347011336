import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lookuptables',
  templateUrl: './lookuptables.component.html',
  styleUrls: ['./lookuptables.component.scss']
})
export class LookuptablesComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  lookupTableDefinitions = [];
  lookupTableSource = 'data'
  filterString = '';
  selectedLookupTableDefinition = null;
  lookups = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getLookupsBySource();

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getLookupsBySource();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
  
  getLookupsBySource() {
    this.lookupTableDefinitions = this.applicationInfoService.projectLookupTables.filter(lookupDefinition => lookupDefinition.source == this.lookupTableSource);
    // console.warn(this.lookupTableDefinitions, this.applicationInfoService.projectLookupTables, this.lookupTableSource)
  }

  selecteLookupTableDefinition(lookupTableDefinition) {
    this.lookups = lookupTableDefinition.lookups;
    this.selectedLookupTableDefinition = lookupTableDefinition;
    // console.warn(lookupTableDefinition)
  }

  saveLookupTable(lookupTable) {
    let externalDatasourceId = 215;
    if (this.lookupTableSource == 'data') {
      externalDatasourceId = 859;
    }
    // console.warn(externalDatasourceId, lookupTable)
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.commonService.getModifyArrayBody(lookupTable, [])]);
  }

  createLookupTableDefinition() {
    const lookupTableDefinition = {
      defaultName: 'Neue Definition',
      nameTranslationToken: null,
      descriptionTranslationToken: null
    }
    let externalDatasourceId = 213;
    if (this.lookupTableSource == 'data') {
      externalDatasourceId = 860;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.commonService.getModifyArrayBody(lookupTableDefinition, [])]).then(createLookupTableDefinitionResult => {
      lookupTableDefinition['id'] = createLookupTableDefinitionResult.id;
      lookupTableDefinition['definitionLogicalId'] = createLookupTableDefinitionResult.id;
      this.updateLookupTableDefinition(lookupTableDefinition);
    });
  }

  createLookupTable() {
    const lookupTable = {
      defaultName: 'Neue Definition',
      nameTranslationToken: null,
      descriptionTranslationToken: null,
      definitionId: this.selectedLookupTableDefinition.definitionId,      
      order: this.lookups.length + 1,      
    }
    let externalDatasourceId = 214;
    if (this.lookupTableSource == 'data') {
      lookupTable['projectId'] = this.applicationInfoService.projectID;
      externalDatasourceId = 861;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.commonService.getModifyArrayBody(lookupTable, [])]).then(createLookupTableDefinitionResult => {
      lookupTable['id'] = createLookupTableDefinitionResult.id;
      lookupTable['isActive'] = true;
      this.lookups.push(lookupTable);
      this.saveLookupTable(lookupTable);
    });
  }

  updateLookupTableDefinition(lookupTableDefinition) {

  }
}
