<div class="dashboardMarketingCampaign">
    <!-- <div class="userDashboard__headerLabel">{{ 'UserDashboard.Label.YourCampaigns' | texttransform }}</div> -->
    <div class="dashboardMarketingCampaign__myCampaigns">{{ 'UserDashboard.Label.MicroCampaigns' | texttransform }}</div>
    <div class="dashboardMarketingCampaign__body" *ngIf="campaignOverview != null">                    
        <div *ngFor="let campaignOverviewItem of campaignOverview.result" class="campaignOverviewItem">
            <div class="campaignOverviewItem__campaignName everleadCoral">
                <span *ngIf="!campaignOverviewItem.resultNameTranslationTokenId">{{ campaignOverviewItem.resultName | texttransform }}</span>
                <span *ngIf="campaignOverviewItem.resultNameTranslationTokenId">{{ axivasTranslateService.getTranslationTextForId(campaignOverviewItem.resultNameTranslationTokenId) | texttransform }}</span>
            </div>       
            <div class="campaignOverviewItem__campaignInfo">
                <div class="campaignOverviewItem__campaignInfo__item" [tippy]="'UserDashboard.Label.YourCampaignsTasks' | texttransform"><i class="fas fa-tasks"></i>{{ campaignOverviewItem.taskCount }}</div>
                <div class="campaignOverviewItem__campaignInfo__item" [tippy]="'UserDashboard.Label.YourCampaignsContacts' | texttransform"><i class="fas fa-user"></i>{{ campaignOverviewItem.contactCount }}</div>
                <div class="campaignOverviewItem__campaignInfo__item" [tippy]="'UserDashboard.Label.YourCampaignsWins' | texttransform"><i class="fas fa-trophy"></i>{{ campaignOverviewItem.positiveLeadCount }}</div>
                <div class="campaignOverviewItem__campaignInfo__item fingerCursor" (click)="showCampaignVisual(campaignOverviewItem)"><i class="fas fa-cog everleadCoral"></i></div>
                <div class="campaignOverviewItem__campaignInfo__deco"></div>
            </div>             
        </div>
    </div>
    <div class="dashboardMarketingCampaign__footer">
        <div class="dashboardMarketingCampaign__footer__info">{{ 'UserDashboard.Label.CampaignBoard' | texttransform }}</div>
        <div class="dashboardMarketingCampaign__footer__page">
            <select [(ngModel)]="dashboardInfo.microCampaignItemsPerPage" (change)="getCampaignOverview()">
                <option [ngValue]=5>5</option>
                <option [ngValue]=10>10</option>
                <option [ngValue]=20>20</option>
            </select>
        </div>                        
    </div>
</div>