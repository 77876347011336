<div class="microCampaignBatchStarterWrapper">
    <div class="microCampaignBatchStarterGeneralSettings everleadShadowBox everleadShadowBox2" *ngIf="mode == 1">
        <div class="microCampaignBatchStarterBox">
            <div class="microCampaignBatchStarterGeneralSettings__line">
                <div class="microCampaignBatchStarterGeneralSettings__line__label">
                    {{ 'MicroCampaignBatchStarter.Label.CampaignName' | texttransform }}
                </div>
                <div class="microCampaignBatchStarterGeneralSettings__line__value">
                    <input [(ngModel)]="campaignName">
                 </div>
            </div>
            <div class="microCampaignBatchStarterGeneralSettings__line">
                <div class="microCampaignBatchStarterGeneralSettings__line__label">
                    {{ 'MicroCampaignBatchStarter.Label.StartDate' | texttransform }}
                </div>
                <div class="microCampaignBatchStarterGeneralSettings__line__value">
                    <input [(ngModel)]="bookingDate" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>            
                 </div>
            </div>
            <div class="microCampaignBatchStarterGeneralSettings__line">
                <div class="microCampaignBatchStarterGeneralSettings__line__label">
                    {{ 'Task.Label.Campaign' | texttransform }}
                </div>
                <div class="microCampaignBatchStarterGeneralSettings__line__value">
                    <select [(ngModel)]="selectedCampaign" [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
                        <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">
                            {{ campaign.defaultName | texttransform }}
                        </option>
                    </select>        
                </div>
            </div>
            <div class="microCampaignBatchStarterGeneralSettings__line">
                <div class="microCampaignBatchStarterGeneralSettings__line__label">
                    {{ 'Task.Label.Owner' | texttransform }}
                </div>
                <div class="microCampaignBatchStarterGeneralSettings__line__value">
                    <select [(ngModel)]="selectedUser" [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
                        <option *ngFor="let user of users" [ngValue]="user.id">{{ user.userName }}</option>
                    </select>        
                </div>             
            </div>
            <!-- Demo - Info -->
            <!-- <div *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true' && mode == 1" class="everleadCoral microCampaignBatchStarterDemoInfo">
                {{ 'MicroCampaignBatchStarter.Label.DemoprojectValuesCantBeChanged' | texttransform }}
            </div> -->
        </div>
    </div>

    <div class="microCampaignBatchStarterBookingImage everleadShadowBox everleadShadowBox2"  *ngIf="bookingResult != null && mode == 1">
        <app-booking-image [result]="bookingResult"></app-booking-image>
    </div>


    <div class="microCampaignBatchStarterEMail" *ngIf="bookingResult != null && mode == 2">
        <app-custom-emaileditor [dontReplaceTokens]="dontReplaceTokens" [templateId]=bookingResult.emailTemplateId [taskEntityId]=15></app-custom-emaileditor>
    </div>
    <div class="microCampaignBatchStarterButtons">
        <button class="everleadCustomButton" (click)="changeMode(1)" *ngIf="mode == 2">{{ 'CreateTask.Label.Back' | texttransform }}</button>

        <button class="everleadCustomButton" (click)="changeMode(2)" *ngIf="mode == 1 && bookingResult.isEditableEmail == true">
            {{ 'CreateTask.Label.EditEMail' | texttransform }}
        </button>
       
        <button class="everleadCustomButton" (click)="startMicroCampaignBatch()" *ngIf="mode == 2 || bookingResult.isEditableEmail == false">
            {{ 'MicroCampaignBatchStarter.Label.StartCampaign' | texttransform }}
        </button>
        
    </div>
</div>