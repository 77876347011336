<div class="newContact__wrapper">
    <div class="newContact__account everleadShadowBox everleadShadowBox2">
        <div class="newContact__account__left">
            <div *ngIf="currentAccount == null || currentAccount?.isDummyAccount == true">
                <div class="noAccount">{{ 'NewContact.Label.NoAccount' | texttransform }}</div>
                <div class="noAccountDesc">{{ 'NewContact.Label.NoAccountDesc' | texttransform }}</div>
            </div>
            <div *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true" class="newContact__account__selected">
                <div class="newContact__account__selected__header">{{ 'Account.Label.Header' | texttransform }}</div>
                <div class="newContact__account__selected__value">{{ currentAccount.name1 }}</div>
                <div class="newContact__account__selected__value">{{ currentAccount.zipcode }} {{ currentAccount.city }}</div>
                <div class="newContact__account__selected__value">{{ currentAccount.street }}</div>
            </div>    
        </div>
        <div class="newContact__account__right">
            <button id="newAccountCreate" class="everleadCustomButton" (click)="changeAccount('NewContact.Label.ChangeAccount')" 
                *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true">{{ 'NewContact.Label.ChangeAccount' | texttransform }}</button>    
            <button id="newAccountCreate" class="everleadCustomButton" (click)="changeAccount('NewContact.Label.SelectAccount')" 
                *ngIf="currentAccount == null">{{ 'NewContact.Label.SelectAccount' | texttransform }}</button>    
            <button id="newAccountRemove" class="everleadCustomButton" (click)="removeAccount()" 
                *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true">{{ 'NewContact.Label.RemoveAccount' | texttransform }}</button>    
        </div>
    </div>
    <div class="newContact__data everleadShadowBox everleadShadowBox2">
        <app-custom-container controluiid="6134"></app-custom-container>        
    </div>    
    <div class="newContact__footer everleadShadowBox everleadShadowBox2">
        <button class="newContact__footer__button everleadCustomButton" [disabled]="!checkIfContactCanBeSaved()" (click)="createContact()">{{ newContactLabel | texttransform }}</button>
        <button class="newContact__footer__button everleadCustomButton" [disabled]="!checkIfContactCanBeSaved()" (click)="createContact(true)">{{ newContactAndLoadLabel | texttransform }}</button>
    </div>
</div>
