import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-admin-awsidentities',
  templateUrl: './admin-awsidentities.component.html',
  styleUrls: ['./admin-awsidentities.component.scss']
})
export class AdminAwsidentitiesComponent implements OnInit {
  awsIdentities = [];
  activeAWSIdentities = [];
  applicationSettings = [];
  filterString = '';
  settingId = null;
  settingName = 'activeAWSIdentities';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private projectService: ProjectService,
    public uiService: UiService
  ) { }

  ngOnInit(): void {
    this.getIdentities();
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings[this.settingName])) {
      this.activeAWSIdentities = this.applicationInfoService.applicationSettings[this.settingName].split(';');
    }    
    this.projectService.getApplicationSettings().then(applicationSettings => {      
      const applicationSetting = applicationSettings.find(setting => setting.setting == this.settingName)
      if (applicationSetting) {
        this.settingId = applicationSetting.id;
      }      
    });
  }

  getIdentities() {
    this.externaldatasourceService.executeExternalDataSource(672)
    .then(getIdentitiesResult => {
      this.awsIdentities = getIdentitiesResult;
    })
  }

  toggleAWSIdentity(awsIdentity) {    
    let identityString = '';
    this.commonService.toggleArrayItem(this.activeAWSIdentities, awsIdentity.identity);
    this.activeAWSIdentities.forEach(identity => {
      if (identityString != '') {
        identityString = identityString.concat(';');
      }
      identityString = identityString.concat(identity);
    });        
    if (this.settingId == null) {
      this.externaldatasourceService.executeExternalDataSource(674, [this.commonService.getModifyArrayBody(
        { projectId: this.applicationInfoService.projectID, setting: this.settingName, value: identityString }
      , [])]).then(createResult => { 
        this.settingId = createResult.id; 
      })      
    } else {
      this.externaldatasourceService.executeExternalDataSource(673, [this.commonService.getModifyArrayBody(
        { id: this.settingId, projectId: this.applicationInfoService.projectID, setting: this.settingName, value: identityString }
      , [])])
    }
    this.applicationInfoService.applicationSettings[this.settingName] = identityString;
  }

  isFiltered(awsIdentity) {
    return !this.commonService.checkIfStringContainsString(awsIdentity.identity, this.filterString);
  }
}
