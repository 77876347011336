<div id="breadCrumbAndMenu" class="breadCrumbAndMenu" *ngIf="applicationInfoService.noProject == false">
    <div class="row breadCrumbAndMenu__wrapper" [ngClass]="{ breadCrumbAndMenuNavigationExpandedFixed: applicationInfoService?.navigationExpandedFixed }">
        <div class="breadCrumbAndMenu__left everleadBlueColor" *ngIf="!applicationInfoService?.isMobile()">
            <label class="breadCrumbAndMenu__label ellipsisText"><span
                    class="everleadCoral breadCrumbAndMenu__label__section">{{
                    applicationInfoService.breadCrumbTextSection |
                    axivastranslate }}</span><span *ngIf="applicationInfoService.breadCrumbTextSection != null"> >
                </span> {{ applicationInfoService.breadCrumbText |
                axivastranslate }}</label>
        </div>

        <div class="breadCrumbAndMenu__center searchBox__wrapper" [ngClass]="{ maxWidth: applicationInfoService?.isMobile() }">
            <div class="searchBox__container" *ngIf="userService.hasPermission('CanReadQuickSearch')">
                <input type="text" class="searchBox__search" [(ngModel)]="applicationInfoService.quickSearchValue"
                    placeholder="{{ 'General.Label.Search' | texttransform }}"
                    (keydown)="handleQuickSearchKeyDown($event)">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="searchForValue()"></svg-icon>
            </div>
        </div>

        <div class="breadCrumbAndMenu__right" *ngIf="!applicationInfoService?.isMobile()">
            <app-toolbar class="maxWidth"></app-toolbar>
            <!-- <div class="row maxWidth">
                <div class="col-md-12">
                    <div class="headerItemInner d-flex align-items-center everleadBlueColor">
                        <app-custom-universal
                            *ngIf="applicationInfoService?.additionalMenuBarControlId!=0 && !applicationInfoService?.isMobile()"
                            [controlid]=applicationInfoService?.additionalMenuBarControlId
                            class="breadCrumbAndMenu__menu">
                        </app-custom-universal>
                        <div *ngIf="isConsentLibrary">

                        </div>
                        <div *ngIf="checkIfTippIsAvailable()!=null" class="helpActivator fingerCursor" (click)="showHelpContent()">
                            <i class="fas fa-lg fa-question"></i>
                        </div>
                        <div class="headerItemSettings dropdown">
                            <div class="dropdown-toggle parent-item" id="personalDropdown" role="button"
                                data-toggle="dropdown">
                                <div class="userLogo customTextControlWrapper__baseBackgroundHover"
                                    *ngIf="applicationInfoService.userAvatar == 'none'">
                                    <div class="userLogo__newMessages"
                                        *ngIf="getNewUserMessages() != 0 && userService.hasPermission('CanReadUserMessage')">
                                        {{ getNewUserMessages() }}</div>
                                    {{ getUserNameShortCut(applicationInfoService?.user) }}
                                </div>

                                <div class="userLogo customTextControlWrapper__baseBackgroundHover"
                                    *ngIf="applicationInfoService.userAvatar != 'none'">
                                    <img [src]="applicationInfoService.userAvatar" class="userAvatar">
                                    <div class="userLogo__newMessages"
                                        *ngIf="getNewUserMessages() != 0 && userService.hasPermission('CanReadUserMessage')">
                                        {{ getNewUserMessages() }}</div>
                                </div>

                            </div>
                            <div class="dropdown-menu everleadBlueColor dropdownWrapper">
                                <div class="dropDownUserInfo">
                                    <img [src]="applicationInfoService.userAvatar" class="userAvatar"
                                        *ngIf="applicationInfoService.userAvatar != 'none'">

                                    <label *ngIf="applicationInfoService?.user?.fakeUser == null"
                                        class="customTextControlWrapper__coralColor userNameLabel uppercase bold ellipsisText">
                                        {{ applicationInfoService?.user?.userName }}
                                    </label>
                                </div>
                                <label *ngIf="applicationInfoService?.user?.fakeUser != null"
                                    class="customTextControlWrapper__coralColor userNameLabel uppercase bold ellipsisText">
                                    {{ applicationInfoService?.user?.fakeUser.userName }}
                                </label>
                                <li class="dropdown-item" (click)="changeExpertMode()" role="button"
                                    *ngIf="userService.hasPermission('CanUpdateExpertMode')">
                                        <i class="fas " [ngClass]="{ 
                                        'fa-toggle-on': applicationInfoService.expertMode == true,
                                        'fa-toggle-off': applicationInfoService.expertMode == false
                                        }"></i>
                                    <span class="navigaionInfoSpan">{{ 'UserSettings.Label.ExpertMode' | texttransform
                                        }}</span>
                                </li>
                                <li class="dropdown-item" (click)="changeDesignerMode()" role="button"
                                    *ngIf="userService.hasPermission('CanReadUiDesigner')">
                                    <i class="fas fa-external-link-alt"></i>
                                    <span class="navigaionInfoSpan">{{getModeText()}}</span>
                                </li>
                                <li class="dropdown-item" (click)="showMailbox()" role="button"
                                    *ngIf="userService.hasPermission('CanReadUserMessage')">
                                    <i class="fas fa-envelope" style="position: relative;">
                                        <div class="userLogo__newMessages" *ngIf="getNewUserMessages() != 0">
                                        </div>
                                    </i>
                                    <span class="navigaionInfoSpan">{{ 'UserSettings.Label.Mailbox' | texttransform
                                        }}</span>
                                </li>
                                <li class="dropdown-item" (click)="showUserSettings()" role="button">
                                    <svg-icon src="assets/images/svg/settings.svg"></svg-icon>
                                    <span class="navigaionInfoSpan">{{'General.Menu.Settings' | texttransform }}</span>
                                </li>
                                <li class="dropdown-item" (click)="logout()" role="button">
                                    <svg-icon src="assets/images/svg/logout.svg"></svg-icon>
                                    <span class="navigaionInfoSpan">{{'General.Menu.Logout' | texttransform }}</span>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>