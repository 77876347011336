import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { textChangeRangeIsUnchanged } from 'typescript';

@Component({
  selector: 'app-contentlibrary-item',
  templateUrl: './contentlibrary-item.component.html',
  styleUrls: ['./contentlibrary-item.component.scss']
})
export class ContentlibraryItemComponent implements OnInit {
  @Input() start = null;
  @Input() startiid = null;
  currentFolder = null;
  folderContent = [];
  securityArray = [];
  userRoles = [];
  contentLibrary = null;
  createNewFolder = false;
  newFolderName = '';
  filterString = '';
  settingsExtended = true;
  currentPageLink = '';
  sharePage = false;

  editFile = false;
  fileToEdit = null;

  constructor(
    private sharedAPI: SharedAPI,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    public userService: UserService
  ) { }


  ngOnInit(): void {
    this.folderContent = this.start;
    this.contentLibrary = this.applicationInfoService.miscSettings['currentContentLibary'];
    this.externaldatasourceService.executeExternalDataSource(819).then(roleResult => {
      this.userRoles = roleResult;
    });
    if (this.startiid != null) {
      this.showContent(this.startiid);
      this.startiid = null;
    }
  }

  newFolder() {
    this.newFolderName = '';
    this.createNewFolder = true;
    setTimeout(() => {
      const input = document.getElementById('newFolderNameInput');
      if (input) {
        input.focus();
      }
    }, 200);
  }

  createNewDirectory() {
    let parentFolderId = null;
    if (this.currentFolder != null) {
      parentFolderId = this.currentFolder.id;
    }
    const contentLibraryItem = {
      projectId: this.applicationInfoService.projectID,
      libraryId: this.contentLibrary.id,
      folderName: 'Documents/Everlead/Libraries/'.concat(
        this.applicationInfoService.projectID.toString(),
        '/',
        this.contentLibrary.id,
        '/',
        this.commonService.createGuid()
      ),
      displayName: this.newFolderName,
      parentFolderId: parentFolderId,
    }
    if (parentFolderId != null) {
      contentLibraryItem.folderName = this.commonService.createGuid();
    }

    this.loaderService.display(true);
    this.sharedAPI.contentLibraryCreatFolder(contentLibraryItem).subscribe(
      newFolderResult => {
      contentLibraryItem['id'] = newFolderResult.id;
      this.createNewFolder = false;
      this.loaderService.display(false);
      if (this.currentFolder == null) {
        this.eventService.customEvent.emit({ id: 'loadContentLibrary', value: this.contentLibrary });
      } else {
        this.getContent(this.currentFolder.id);
      }
    }, error => {
      this.loaderService.display(false);
      console.error('newFolder', error);
    });
  }

  deleteContentLibraryFile(subChild) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('ContentLibrary.Label.DeleteFileMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('ContentLibrary.Label.DeleteFileHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sharedAPI.contentLibraryDeleteFile(
          subChild.id,
          subChild.contentLibraryId)
        .subscribe(() => {
          if (this.currentFolder)   {
            this.getContent(this.currentFolder.id);
          } else {
            this.eventService.customEvent.emit({ id: 'loadContentLibrary', value: this.contentLibrary });
          }
        });
      }
    });
  }

  closeEditWindow() {
    this.createNewFolder = false;
    this.editFile = false;
  }

  editFilename(subChild) {
    this.fileToEdit = subChild;
    this.editFile = true;
  }

  updateDirectory() {
    this.saveContentLibraryItem(this.fileToEdit);
    this.editFile = false;
  }

  getFolderContent(folderContent, isDirectory) {
    return folderContent.filter(contentItem => contentItem.isDirectory == isDirectory);
  }

  saveContentLibraryItem(contentLibraryItem) {
    this.externaldatasourceService.executeExternalDataSource(812, [
      this.commonService.getModifyArrayBody(contentLibraryItem, ['editMode', 'createdAt', 'createdBy'])
    ]);
  }

  isFiltered(subChild) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(subChild.name, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  uploadFile(event) {
    const files: FileList = event.target.files;
    this.uploadFilesToLibrary(files);
  }

  uploadFilesToLibrary(files) {
    let parent = this.currentFolder.id;
    if (parent == null) {
      parent = 0;
    }
    for ( let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i]);
      formData.append('displayName', files[i].name);
      formData.append('projectId', this.applicationInfoService.projectID.toString());
      formData.append('libraryId', this.currentFolder.contentLibraryId);
      formData.append('parentFolderId', parent);
      formData.append('contentTypeId', '6880');
      this.loaderService.display(true);
      this.sharedAPI.contentLibraryUploadFile(formData).subscribe((uploadFileResult) => {
        this.getContent(this.currentFolder.id);
        this.loaderService.display(false);
      }, (err) => {
        console.warn(err);
        this.loaderService.display(false);
      });
    }
  }

  getUser(userId) {
    const userList = this.applicationInfoService.projectUsers.toArray();
    const user = userList.find(projectuser => projectuser.id == userId );
    if (user) {
      return user.userName;
    } else {
      return 'user not found';
    }
  }

  downloadFile(subChild) {
    this.sharedAPI.contentLibraryDownloadFile(subChild.id, subChild.contentLibraryId).subscribe(result => {
      let fileDetails = subChild.name.split('.');
      this.commonService.downloadFile(result, 'application/'.concat(fileDetails[fileDetails.length-1]), subChild.name);
    });
  }

  getContent(libraryFileId) {
    this.externaldatasourceService.executeExternalDataSource(814, [this.contentLibrary.id, libraryFileId]).then(result => { this.folderContent = result; })
  }

  showContent(libraryFileId) {
    const promiseArray: Promise<any>[] = [];
    if (libraryFileId == null) {
      this.currentFolder = null;
      this.eventService.customEvent.emit({ id: 'loadContentLibrary', value: this.contentLibrary });
      return;
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(818, [libraryFileId]).then(folderResult => {
      this.currentFolder = folderResult;
      this.externaldatasourceService.executeExternalDataSource(814, [this.contentLibrary.id, libraryFileId]).then(contentResult => {
        this.folderContent = contentResult;
        this.externaldatasourceService.executeExternalDataSource(817, [this.contentLibrary.id]).then(securityResult => {
          this.securityArray = securityResult;
          this.applicationInfoService.miscSettings['currentContentLibraryFolder'] = libraryFileId;
          this.loaderService.display(false);
          this.setUserroleChecks();
        }).catch(() => { this.loaderService.display(false); });
      }).catch(() => { this.loaderService.display(false); });
    }).catch(() => { this.loaderService.display(false); });
  }

  setUserroleChecks() {
    this.userRoles.forEach(role => {
      role.isChecked = false;
      if (!this.commonService.isNullOrUndefined(this.securityArray)) {
        const roleCheck = this.securityArray.find(item => item.roleId == role.id && item.contentLibraryFileId == this.currentFolder.id);
        if (roleCheck) {
          role.isChecked = true;
        }
      }
    });
  }

  navigateBack() {
    if (this.currentFolder == null) {
      this.eventService.customEvent.emit({ id: 'resetLibrary' });
    } else {
      this.showContent(this.currentFolder.parentId);
    }
  }

  userRoleAvailable(userRole) {
    let returnValue = false;
    const myUserRole = this.userRoles.find(role => role.id == this.applicationInfoService.currentUserRoleId);
    if (myUserRole.permissionLevel > userRole.permissionLevel) {
      returnValue = true;
    }
    return returnValue;
  }

  changeSecuritySetting(userRole) {
    setTimeout(() => {
      if (userRole.isChecked == true) {
        this.externaldatasourceService.executeExternalDataSource(815, [userRole.id, this.currentFolder.id]).then(createResult => {
          this.securityArray.push({
            id: createResult.id,
            contentLibraryFileId: this.currentFolder.id,
            roleId: userRole.id
          })
        });
      } else {
        const roleToDelete = this.securityArray.find(arrayItem => arrayItem.roleId == userRole.id && arrayItem.contentLibraryFileId == this.currentFolder.id );
        if (roleToDelete) {
          this.externaldatasourceService.executeExternalDataSource(816, [roleToDelete.id]).then(() => {
            this.commonService.removeItemFromArray(this.securityArray, roleToDelete);
          });
        }
      }
    }, 200);
  }

  shareFolder() {
    this.currentPageLink = document.location.href;
    this.sharePage = true;
  }

  copyToClipboard(currentPageLink) {
    this.commonService.copyTextToClipboard(currentPageLink);
    this.messagingService.showDefaultSuccess(
      'General.CopyToClipBoard.Header',
      'General.CopyToClipBoard.Message',
      false
    );
    this.sharePage = false;
  }

  public onFilesSelected(files: File[]): void {
    this.uploadFilesToLibrary(files);
  }
}
