import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sendmailitems',
  templateUrl: './sendmailitems.component.html',
  styleUrls: ['./sendmailitems.component.scss']
})
export class SendmailitemsComponent implements OnInit {
  sendMailItemsControl = 12;
  constructor() { }

  ngOnInit(): void {
  }

}
