import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-dashboardcontrol-dashboard',
  templateUrl: './custom-dashboardcontrol-dashboard.component.html',
  styleUrls: ['./custom-dashboardcontrol-dashboard.component.scss']
})
export class CustomDasboardcontrolDashboardComponent implements OnInit {
  
  @Input() dashboard = null;
  @Input() dashboarddata = null;
  @Input() dataid = 0;
  sectionData = null;
  headers = null;
  maxValue = 0;

  constructor() { }

  ngOnInit(): void {
    if (this.dashboarddata[this.dataid] != undefined) {
      if (this.dashboarddata[this.dataid].length > 0) {
        this.headers = Object.keys(this.dashboarddata[this.dataid][0]);
      }
    }
  }

  getBar1Width(value) {
    if (this.maxValue == 0) {
      this.dashboarddata[this.dataid].forEach(dataitem => {
        if (this.maxValue < dataitem[this.headers[1]]) {
          this.maxValue = dataitem[this.headers[1]];
        }
      });
    }
    if (this.maxValue == 0) {
      return 0;
    }

    return Number((value / this.maxValue) * 100) + '%';
  }
}
