<div class="callednumberhistory__wrapper">
    <button class="everleadCustomButton" (click)="syncRecordings()" *ngIf="applicationInfoService.isDeveloper">sync</button>
    <button class="everleadCustomButton" (click)="refreshView()" *ngIf="applicationInfoService.isDeveloper">refresh</button>
    
    <div *ngFor="let accountCall of accountCalls" class="callednumberhistory__item">
        <div class="callednumberhistory__item__date">{{ accountCall.callStartedAt | date:'medium' }}</div>
        <div class="callednumberhistory__item__user">{{ accountCall.user?.userName }}</div>
        <div class="callednumberhistory__item__number">{{ accountCall.calledNumber }}</div>
        <div class="callednumberhistory__item__recording">
            <div *ngFor="let recording of getRecordingsForCall(accountCall)" class="callednumberhistory__item__recording__item">
                <div *ngIf="downloadAvailable(recording)">{{ getRecordingTypeName(recording.recordingLookupId) }}</div>
                <div *ngIf="!downloadAvailable(recording)">
                    Aufzeichnung noch nicht verfügbar
                </div>
                <i class="fas fa-lg fa-download fingerCursor" (click)="downloadFile(recording)" *ngIf="downloadAvailable(recording)"></i>
                <!-- <i class="fas fa-lg fa-play fingerCursor"></i> -->
            </div>
        </div>
    </div>
</div>