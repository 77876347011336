<span *ngIf="checkCampaign()"></span>
<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1">
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'CampaignDesign.Label.DefaultName' | texttransform }}
                <app-wizard-infobutton displayText="CampaignDesign.Info.DefaultName"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.defaultName" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'CampaignDesign.Label.IsActive' | texttransform }}
                <app-wizard-infobutton displayText="CampaignDesign.Label.IsActive"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.isActive" type="checkbox">

        </div>
    </div>
    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2">
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">{{ 'CampaignDesign.Label.CampaignFrom' | texttransform
                }}</label>
            <input [(ngModel)]="wizardService.wizardArray.startCampaign" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7"
                readonly class="datePickerTimePopup wizardInput" aria-modal="true">
            <owl-date-time #dt7></owl-date-time>
        </div>
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">{{ 'CampaignDesign.Label.CampaignUntil' | texttransform
                }} </label>
            <input [(ngModel)]="wizardService.wizardArray.endCampaign" [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8"
                readonly class="datePickerTimePopup wizardInput" aria-modal="true">
            <owl-date-time #dt8></owl-date-time>
        </div>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3">
        <div class="campaignDetailItem">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'CampaignDesign.Label.NettCallLimit' | texttransform }}
                    <app-wizard-infobutton displayText="CampaignDesign.Info.NettCallLimitDescription">
                    </app-wizard-infobutton>
                </label>
                <input type="number" class="wizardInput" [(ngModel)]="wizardService.wizardArray.netcallLimit">
            </div>
        </div>

        <!-- ses configset -->
        <div class="campaignDetailItem">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'CampaignDesign.Label.SesConfigSetId' | texttransform }}
                    <app-wizard-infobutton displayText="CampaignDesign.Info.SesConfigSetDescription">
                    </app-wizard-infobutton>
                </label>
                <select [(ngModel)]="wizardService.wizardArray.sesConfigSetId" class="wizardInput">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let configSet of configSets" [ngValue]="configSet.id">{{ configSet.defaultName
                        }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 4">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{
                'CampaignDesign.Label.AutoCloseNettCallLimit' | texttransform }}
                <app-wizard-infobutton displayText="CampaignDesign.Info.CloseTaskAfterXCallAttemptsDescription">
                </app-wizard-infobutton>
            </label>
            <input type="number" class="wizardInput"
                [(ngModel)]="wizardService.wizardArray.closeTaskAfterXCallAttempts">
        </div>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 5">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{
                'CampaignDesign.Label.CustomerCampaignId' | texttransform }}
                <app-wizard-infobutton displayText="CampaignDesign.Info.CustomerCampaignDescription">
                </app-wizard-infobutton>
            </label>
            <input type="text" class="wizardInput " [(ngModel)]="wizardService.wizardArray.customerCampaignId">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{
                'CampaignDesign.Label.CustomerCampaignName' | texttransform }}
                <app-wizard-infobutton displayText="CampaignDesign.Info.CustomerCampaignDescription">
                </app-wizard-infobutton>
            </label>
            <input type="text" class="wizardInput " [(ngModel)]="wizardService.wizardArray.customerCampaignName">
        </div>
    </div>

</div>