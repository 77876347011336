<div class="mainscreenWrapper">
    <div class="mainscreenMiscControls">

    </div>
    <div class="mainscreenNavigation">        

    </div>
    <div class="mainscreenCore">
        <div class="mainscreenHeader"></div>
        <div class="mainscreenBody">
            <div class="mainscreenBodyContent">
                <router-outlet *ngIf="applicationInfoService.projectLoaded"></router-outlet>
            </div>
            <div class="mainscreenSidebar">
                <app-custom-webphone-popup controluiid="softphone"></app-custom-webphone-popup>
            </div>
        </div>
        <div class="mainscreenFooter"></div>    
    </div>
</div>