<!-- <div #svgContainer></div> -->
<div class="row" style="width: 100%;" *ngIf="controlDefinition != null">
    <div class="col-7">
        <label>{{ controlDefinition.displayText | texttransform }}</label>
        <app-icon-leadscore [value]="roundUp(controlValue)" style="margin-left: 10px;"></app-icon-leadscore>
    </div>
    <!-- <div class="col-5">
        
      
    </div> -->
</div>

<div *ngIf="controlDefinition == null" class="customLeadScore">
    <div class="customLeadScore__noControl">
       <app-icon-leadscore [value]="roundUp(controlValue)"></app-icon-leadscore>
    </div>
</div>
