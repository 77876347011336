<span id="emailcontentDesignSpan"></span>
<div [style.height.px]="uiService.getDesignSpanPosition('emailcontentDesignSpan', 40)" class="emailContents__wrapper">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem fingerCursor" *ngIf="userService.hasPermission('CanCreateEmailTemplateContent')"
            (click)="createNewContent()">
            <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }" *ngIf="userService.hasPermission('CanCreateEmailTemplateContent')"
                class="fingerCursor" [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>
            {{ 'EMailContent.Label.New' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="emailContents__content">
        <div class="nonExpertView__main nonExpertView__main__noSubitems everleadShadowBox" *ngFor="let emailContent of emailContents" [ngClass]="{
            hideInDom: checkIfItemIsFiltered(emailContent),
            fingerCursor: userService.hasPermission('CanUpdateEmailTemplateContent')
        }" (click)="showWizard(emailContent)">
            <div class="nonExpertView__name">
                <div class="everleadCoral emailContents__content__id">{{ emailContent.id }}</div>
                <label class="ellipsisText">{{ emailContent.defaultName }}</label>
            </div>
            <!-- <div class="nonExpertView__bonus" [title]="'General._.Edit' | texttransform">
                <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button"></i>
            </div> -->
        </div>
    </div>
</div>
