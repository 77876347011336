import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { environment } from 'environments/environment';
import { Item } from '../boldbi-dashboard/boldbi-dashboard.component';
import { BoldbiServiceService } from '../boldbi-service.service';

@Component({
  selector: 'app-boldbi-dashboardlist',
  templateUrl: './boldbi-dashboardlist.component.html',
  styleUrls: ['./boldbi-dashboardlist.component.scss']
})
export class BoldbiDashboardlistComponent implements OnInit {
  public dashboards;
  public dashboardId
  public dashboardsList: Item[];
  public filterValue = '';
  public blockedIds = [];

  constructor(
    private _app: BoldbiServiceService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getDashboardList();
    const blockSettings = this.commonService.checkForNullAndUndefinedAndReturnDefault(
      this.applicationInfoService.applicationSettings['blockedBoldBiDashboards' + this.applicationInfoService.userID]
      , ''
    );
    if (blockSettings != '') {
      this.blockedIds = blockSettings.split(';');
    }
  }

  getDashboardList() {
    this._app.GetDashboards(environment.boldBiApiHost + environment.boldBiGetDashboardsUrl).subscribe(data => {      
      this.dashboards = <any>data;
      this.dashboards.sort((a, b) => a.Name < b.Name ? -1 : 1);
      this.dashboardsList = this.dashboards;      
    });
  }

  loadDashboard(dashboard) {
    this.eventService.loadDashboardEvent.emit(dashboard);
  }

  isItemBlocked(dashboard) {
    let returnValue = false;
    if (this.commonService.checkIfItemIsInArray(this.blockedIds, dashboard.Id)) {
      returnValue = true;
    }
    return returnValue;
  }

  isItemFiltered(dashboard) {
    if (this.filterValue == '') return false;
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(dashboard.Name, this.filterValue)) {
      returnValue = false;
    }
    return returnValue;
  }
}
