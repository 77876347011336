import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { isNullOrUndefined } from 'util';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ControlService } from 'app/jollyjupiter/service/control.service';

@Component({
  selector: 'app-uicontrol-design',
  templateUrl: './uicontrol-design.component.html',
  styleUrls: ['./uicontrol-design.component.scss']
})
export class UicontrolDesignComponent implements OnInit, OnDestroy {
  @Input() currentControlToModify = null;
  @Input() lookupTable = null;
  translationSourceSubControl = 'uiSubControl';
  translationSource = 'uiControl';
  specialArray = [];
  attributeModifierSources = null;
  selectedEntity = null;
  tempIdCounter = 0;
  positions = [];
  compactListSubControlTypes = [
    { id: null, name: 'Standard (String)'},
    { id: 1, name: 'Datum'},
  ];

  constructor(
    public designerService: DesignerService,
    public controlService: ControlService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    public uiService: UiService,
    public methodService: MethodService,
    private entityService: EntityService,
    private commonService: CommonService,
    public dragdropService: DragdropService
  ) { }

  ngOnInit() {
    this.fillSpecialArray(0, this.selectedEntity);
    this.positions.push({ id: null, name: 'Keine Label' });
    this.positions.push({ id: 1, name: 'Links' });
    this.positions.push({ id: 2, name: 'Oben' });
  }

  ngOnDestroy() {
    this.eventService.updateControlContent(this.currentControlToModify.logicalControlId);
    this.eventService.compactListUpdateExternalDataSourcesEvent.emit(this.currentControlToModify.logicalControlId);
  }

  getMaxHeight() {
    return this.uiService.getDesignSpanPosition('uiDesignSpan', 20);
  }

  fillSpecialArray(position: any, variable: any) {
    if (!isNullOrUndefined(this.currentControlToModify.uiEntityInstanceId)) {
      this.specialArray[position] = [];
      this.specialArray[position].push({ entity:
        this.applicationInfoService.entities.Item(this.currentControlToModify.uiEntityInstanceId), prefix: ''});

      if (!isNullOrUndefined(this.currentControlToModify.entity)) {
        const additionalEntities = this.currentControlToModify.entity.split(';');
        additionalEntities.forEach(entity => {
          const entityDetails = entity.split('-');
          if (entityDetails.length > 1) {
            this.specialArray[position].push({
              entity: this.applicationInfoService.entities.Item(entityDetails[1]), prefix: entityDetails[0] });
          } else {
            this.specialArray[position].push({ entity: this.applicationInfoService.entities.Item(entity), prefix: '' });
          }
        });
      }
      if (this.specialArray[position].length > 0) {
        variable = this.specialArray[position][0];
      }
    }
    console.warn(this.specialArray);
  }

  checkIfMemberAlreadyInList(member) {
    let memberValue = '';
    let returnValue = false;
    if (Number(this.selectedEntity.entity.uiEntityInstance) === Number(this.currentControlToModify.uiEntityInstanceId)) {
      memberValue = ''.concat(member.defaultName);
    } else {
      memberValue = this.getSubEntityMemberName(member);
    }
    this.currentControlToModify.childs.forEach(child => {
      if (child.childs[0].value === memberValue) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  getSubEntityMemberName(item) {
    let returnValue = '';
    if (this.selectedEntity.prefix === '') {
      returnValue = ''.concat(this.selectedEntity.entity.entityName, '.', item.defaultName);
    } else {
      returnValue = ''.concat(this.selectedEntity.prefix, '.',
        this.selectedEntity.entity.entityName, '.', item.defaultName);
    }
    return returnValue;
  }

  addEmptyItemToCompactListSubControls() {
    const control = new Object();
    control['childs'] = [];
    control['isActive'] = true;
    control['width'] = '10%';
    control['isFieldFilterable'] = false;
    const subControl = new Object();
    subControl['uiControlExternalDataSources'] = [];
    control['childs'].push(subControl);
    this.currentControlToModify.childs.push(control);
  }

  addToCompactListSubControls(item) {
    const control = new Object();
    control['childs'] = [];
    control['isActive'] = true;
    if (!isNullOrUndefined(item.nameTranslationToken)) {
      control['displayText'] = this.axivasTranslateService.getTranslationTextForToken(item.nameTranslationToken.tokenFullName);
    } else {
      control['displayText'] = item.defaultName;
    }
    control['width'] = '10%';
    control['isFieldFilterable'] = true;

    const subControl = new Object();
    subControl['uiControlExternalDataSources'] = [];
    if (Number(this.selectedEntity.entity.uiEntityInstance) === Number(this.currentControlToModify.uiEntityInstanceId)) {
      subControl['value'] = ''.concat(item.defaultName);
    } else {
      subControl['value'] = this.getSubEntityMemberName(item);
    }
    subControl['uiEntityInstanceId'] = this.selectedEntity.entity.uiEntityInstance;
    control['childs'].push(subControl);
    this.currentControlToModify.childs.push(control);
  }

  removeFromCompactListSubControls(item) {
    this.commonService.removeItemFromArray(this.currentControlToModify.childs, item);
  }

  getSubChildLabel(child, subChild, defaultValue) {
    let entityMemberValue = subChild.value;
    if (!isNullOrUndefined(subChild.value)) {
      const subChildValueLevel = subChild.value.split('.');
      if (subChildValueLevel.length > 1) {
        entityMemberValue = subChildValueLevel[subChildValueLevel.length - 1];
      }
      const entity = this.entityService.getEntityMember(child.uiEntityInstanceId, entityMemberValue);
      if (!isNullOrUndefined(entity)) {
        if (isNullOrUndefined(entity.nameTranslationToken)) {
          defaultValue = entity.defaultName;
        } else {
          defaultValue = entity.entity.nameTranslationToken.tokenFullName;
        }
      }
    }
    return defaultValue;
  }

  setOrder(item, i) {
    item.order = i;
  }

  checkIfControlIsModified() {
    if (JSON.stringify(this.currentControlToModify) ===
      JSON.stringify(this.applicationInfoService.controlsBackUp.Item(this.currentControlToModify.logicalControlId))) {
        return false;
      } else {
        return true;
      }
  }
}
