<div [id]="getEntityTableId()" class="entityTable__wrapper" *ngIf="contentFound && contentSearched" [ngClass]="{
    entityTableDarkMode: tableSettingsJson['darkMode']==true,
    entityTableSmallMode: getEntityTableWidth() < 700
}">
    <div class="entityTable">
        <div class="entityTableMenu" [style.background-color]="getTableMenuBackgroundColor()">
            <div *ngIf="reducedFeatures">
                {{ 'MatTable.Label.ReducedContentForFeatureDemo' | texttransform }}
            </div>
            <div class="entityTableGlobalFilter__wrapper" *ngIf="!reducedFeatures">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                <input (change)="setFilter()" [placeholder]="'General.Label.Search' | texttransform" #input [(ngModel)]="tableSettings.globalFilter" class="entityTableGlobalFilter">
            </div>
            <div class="entityTableGlobalFilter__select entityTableHideInMobile" *ngIf="!reducedFeatures">
                <select [(ngModel)]="tableSettings.pageSize" (change)="setFilter()" class="filterWrapper__dropdown">
                    <option [ngValue]=5>5</option>
                    <option [ngValue]=20>20</option>
                    <option [ngValue]=50>50</option>
                    <option [ngValue]=100>100</option>
                    <option [ngValue]=250>250</option>
                </select>
                <span class="filterWrapper__itemsPerPage">{{ 'MatTable.Label.ItemsPerPage' | texttransform }}</span>
            </div>
            <div class="filterWrapper__paging" *ngIf="!reducedFeatures">
                <span *ngIf="controlData.totalCount > 0">{{ getResultString() }}</span>
            </div>
            <div class="filterWrapper__paging" *ngIf="!reducedFeatures">
                <div *ngIf="controlData.pageCount > 1" class="d-flex">
                    <div class="filterWrapper__paging__box" [ngClass]="{ 'filterWrapper__paging__box__active': tableSettings.currentPage > 1 }" (click)="firstPage()">
                        <i class="fas fa-step-backward"></i>
                    </div>
                    <div class="filterWrapper__paging__box" [ngClass]="{ 'filterWrapper__paging__box__active': tableSettings.currentPage > 1 }" (click)="previousPage()">
                        <i class="fas fa-angle-left"></i>
                    </div>
                    <div class="filterWrapper__paging__center">
                        <input [(ngModel)]="tableSettings.currentPage" (change)="pageSizeChangedManualy()" type="number"
                            class="filterWrapper__paging__center__pageSelect">&nbsp;/ {{ controlData.pageCount }}
                    </div>
                    <div class="filterWrapper__paging__box" (click)="nextPage()" [ngClass]="{ 'filterWrapper__paging__box__active': tableSettings.currentPage != controlData.pageCount  }">
                        <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="filterWrapper__paging__box" (click)="lastPage()" [ngClass]="{ 'filterWrapper__paging__box__active': tableSettings.currentPage != controlData.pageCount }">
                        <i class="fas fa-step-forward"></i>
                    </div>
                </div>
                <div class="entityTableGlobalFilter__select entityTableHideInNonMobile" *ngIf="!reducedFeatures">
                    <select [(ngModel)]="tableSettings.pageSize" (change)="setFilter()" class="filterWrapper__dropdown">
                        <option [ngValue]=5>5</option>
                        <option [ngValue]=20>20</option>
                        <option [ngValue]=50>50</option>
                        <option [ngValue]=100>100</option>
                        <option [ngValue]=250>250</option>
                    </select>
                    <span class="filterWrapper__itemsPerPage">{{ 'MatTable.Label.ItemsPerPage' | texttransform }}</span>
                </div>
            </div>
            <div class="filterWrapper__paging__center entityTableHideInMobile">
                <i class="fas fa-file-excel fa-lg fingerCursor" style="width: 25px; margin-left: 10px" (click)="entityTableExport()"
                    *ngIf="tableSettingsJson['canBeExported'] == true && userService.hasPermission('CanCreateUiDataExport')"></i>
                <i class="fas fa-plus fa-lg fingerCursor" style="width: 25px; margin-left: 10px" (click)="createNewOptin()"
                    [tippy]="'OptinOptOutEditor.Label.CreateOptInOptOut' | texttransform" *ngIf="dataTableItem.mainEntityId == 20"></i>
                <i class="fas fa-edit fa-lg fingerCursor" style="width: 25px; margin-left: 10px" (click)="showEntityTableDesigner()" *ngIf="canEditControl"></i>
                <i class="fas fa-sync-alt fa-lg fingerCursor" style="width: 25px; margin-left: 10px" (click)="getData()"></i>                
            </div>
        </div>

        <!-- Zusatzleiste -->
        <div class="entityTableAdditionalFilter" *ngIf="tableSettingsJson['additionalToolbar'] == true && !reducedFeatures" [ngClass]="{
            'entityTableAdditionalFilter__spaceBetween' : tableSettingsJson['vip'] == true
        }">
            <!-- Optins -->
            <div *ngIf="tableSettingsJson['onlyOptinsForContactSwitch'] == true && applicationInfoService?.currentAccount?.isDummyAccount == false" class="entityTableAdditionalFilter__item">
                {{ 'EntityTableDesigner.Label.OnlyOptinsForContact' | texttransform }}
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('onlyOptinsForContact')"
                    *ngIf="tableSettingsJson['onlyOptinsForContact'] == true"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('onlyOptinsForContact')"
                    *ngIf="tableSettingsJson['onlyOptinsForContact'] != true"></svg-icon>
            </div>

            <!-- MicroCampaigns -->
            <div *ngIf="tableSettingsJson['useMicroCampaignContacts'] == true" class="entityTableAdditionalFilterCampaign maxWidth">
                <div class="entityTableAdditionalFilterCampaign__campaign">
                    <select [(ngModel)]="useMicroCampaignContactsCampaign" (change)="changeMicroCampaign()">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let microcampaign of microcampaignService.microcampaigns" [ngValue]="microcampaign.id">
                            <!-- {{ commonService.getNameTranslationFromIdOrDefaultName(microcampaign) }} -->
                            {{ microcampaign.defaultName }}
                        </option>
                    </select>
                </div>
                <div class="entityTableAdditionalFilterCampaign__spacer"></div>
                <div class="entityTableAdditionalFilterCampaign__stopCampaign" *ngIf="tableSettingsJson['microCampaignNewslettermode'] != true">
                    <button class="everleadCustomButton" (click)="batchStopMicroCampaign()">
                    {{ 'MicroCampaign.Label.CancelCampaign' | texttransform }}</button>                    
                </div>
                <div class="entityTableAdditionalFilter__newsletteritem entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['microCampaignNewslettermode'] == true">
                    {{ 'EntityTableDesigner.Label.DoubleOptInRequired' | texttransform }}
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('doubleOptInRequired')"
                        *ngIf="tableSettingsJson['doubleOptInRequired'] == true"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('doubleOptInRequired')"
                        *ngIf="tableSettingsJson['doubleOptInRequired'] != true"></svg-icon>
                </div>
            </div>
            <div *ngIf="tableSettingsJson['useMicroCampaignContacts'] == true && 
                        tableSettingsJson['microCampaignNewslettermode'] == true &&
                        useMicroCampaignContactsCampaign != null && 
                        useMicroCampaignContactsCampaign != undefined"
            class="microCampaignNewslettermodeDescription">
                {{ 'MicroCampaign.Label.CampaignSelectedForNewsletter' | texttransform }}
            </div>
            <div *ngIf="tableSettingsJson['showMicroCampaignCreationSwitch'] == true" class="entityTableAdditionalFilter__item">
                <button class="everleadCustomButton" (click)="toggleValue('showMicroCampaignCreation')"
                    *ngIf="tableSettingsJson['showMicroCampaignCreation'] != true">
                    {{ 'MicroCampaign.Label.StartCampaign' | texttransform }}</button>
            </div>            

            <!-- Account && Contact -->
            <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showOnlyAccountVipsSettingVisible'] == true">
                {{ 'EntityTableDesigner.Label.OnlyVip' | texttransform }}
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('showOnlyAccountVips')"
                    *ngIf="tableSettingsJson['showOnlyAccountVips'] == true"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('showOnlyAccountVips')"
                    *ngIf="tableSettingsJson['showOnlyAccountVips'] != true"></svg-icon>
            </div>
            <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['showOnlyContactVipsSettingVisible']">
                {{ 'EntityTableDesigner.Label.OnlyVip' | texttransform }}
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('showOnlyContactVips')"
                    *ngIf="tableSettingsJson['showOnlyContactVips'] == true"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('showOnlyContactVips')"
                    *ngIf="tableSettingsJson['showOnlyContactVips'] != true"></svg-icon>
            </div>

            <!-- Optin -->
            <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['doubleOptinOptionVisivle'] == true && tableSettingsJson['microCampaignNewslettermode'] != true">
                {{ 'EntityTableDesigner.Label.DoubleOptInRequired' | texttransform }}
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('doubleOptInRequired')"
                    *ngIf="tableSettingsJson['doubleOptInRequired'] == true"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('doubleOptInRequired')"
                    *ngIf="tableSettingsJson['doubleOptInRequired'] != true"></svg-icon>
            </div>

            <!-- VIP / Prospect -->
                <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['vip'] == true">
                    {{ 'EntityTableDesigner.Label.OnlyVip' | texttransform}}
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('vipActive')"
                        *ngIf="tableSettingsJson['vipActive'] == true"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('vipActive')"
                        *ngIf="tableSettingsJson['vipActive'] != true"></svg-icon>
                </div>
                <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['blacklistedSwitch'] == true">
                    {{ 'EntityTableDesigner.Label.FilterBlacklisted' | texttransform}}
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                        (click)="toggleValue('filterBlacklisted')"
                        *ngIf="tableSettingsJson['filterBlacklisted'] == true"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                        (click)="toggleValue('filterBlacklisted')"
                        *ngIf="tableSettingsJson['filterBlacklisted'] != true"></svg-icon>
                </div>
                <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['alreadyInEverleadSwitch'] == true">
                    {{ 'EntityTableDesigner.Label.AlreadyInEverlead' | texttransform}}
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                        (click)="toggleValue('alreadyInEverlead')"
                        *ngIf="tableSettingsJson['alreadyInEverlead'] == true"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                        (click)="toggleValue('alreadyInEverlead')"
                        *ngIf="tableSettingsJson['alreadyInEverlead'] != true"></svg-icon>
                </div>
                <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['notInEverleadSwitch'] == true">
                    {{ 'EntityTableDesigner.Label.NotInEverlead' | texttransform}}
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                        (click)="toggleValue('notInEverlead')"
                        *ngIf="tableSettingsJson['notInEverlead'] == true"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                        (click)="toggleValue('notInEverlead')"
                        *ngIf="tableSettingsJson['notInEverlead'] != true"></svg-icon>
                </div>

            <!-- AI -->
            <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['aicontacts'] == true" [tippy]="'EntityTableValue.Label.AISContatcsDescription' | texttransform">
                {{ 'EntityTableDesigner.Label.OnlyAIContacts' | texttransform}}
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('aicontactsActive')"
                    *ngIf="tableSettingsJson['aicontactsActive'] == true"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('aicontactsActive')"
                    *ngIf="tableSettingsJson['aicontactsActive'] != true"></svg-icon>
            </div>
            <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['aicontacts'] == true" [tippy]="'EntityTableValue.Label.AISuggestionDescription' | texttransform">
                {{ 'EntityTableDesigner.Label.OnlyAIHighPotential' | texttransform}}
                <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('aicontactsHighPotentialActive')"
                    *ngIf="tableSettingsJson['aicontactsHighPotentialActive'] == true"></svg-icon>
                <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue('aicontactsHighPotentialActive')"
                    *ngIf="tableSettingsJson['aicontactsHighPotentialActive'] != true"></svg-icon>
            </div>
            <div class="entityTableAdditionalFilter__item" *ngIf="tableSettingsJson['aicontacts'] == true">
                {{ 'EntityTableDesigner.Label.MinLeadscore' | texttransform}}
                <input [(ngModel)]="minLeadScore" (change)="getData()" type="number" class="entityTableAdditionalFilter__leadscore">
            </div>

            <!-- VIP Part 2 -->
            <div *ngIf="tableSettingsJson['vip'] == true">
                <button class="everleadCustomButton" (click)="showVIP()">{{ 'General.Menu.VipAlert' | texttransform}}</button>
            </div>
        </div>

        <!-- Hier beginnt der Header -->
        <div class="entityTableHeader" [style.max-width]="getMaxHeaderWidth()"
            [ngClass]="{ hideInDom: tableSettingsJson['showMicroCampaignCreation'] == true }">
            <div *ngFor="let tableLayoutItem of headerColumns; let i = index" class="entityTableHeaderItem"
                [style.max-width]="getMaxWidth(tableLayoutItem)"
                [style.min-width]="getMinWidth(tableLayoutItem)"
            >
                <div class="entityTableHeaderItem__wrapper">
                    <div class="entityTableHeaderItem__label">
                        <div *ngIf="tableLayoutItem.filterable && tableLayoutItem.lookupTableId == null &&
                                    tableLayoutItem.alternateLookupTable == null &&
                                    (tableLayoutItem.controlType != 1 && tableLayoutItem.controlType != 2) &&
                                    tableLayoutItem.hidefilterbox != true
                        ">
                            <input [(ngModel)]="columnFilter[tableLayoutItem.memberName]" (change)="setFilter()" class="entityTableHeaderItem__input"
                            [placeholder]="tableLayoutItem.displayText | texttransform">
                        </div>

                        <!-- Filter -->
                        <div *ngIf="tableLayoutItem.filterable && tableLayoutItem.lookupTableId != null && (tableLayoutItem.controlType != 1 && tableLayoutItem.controlType != 2)"
                            class="entityTableHeaderItemFilterHeader">
                            <label>{{ tableLayoutItem.displayText | texttransform }}</label>
                            <i class="fas fa-filter fingerCursor" (click)="editMultifilter(tableLayoutItem)" [ngClass]="{
                                everleadCoral: columnFilter[tableLayoutItem.memberName] != '' && columnFilter[tableLayoutItem.memberName] != undefined
                            }"></i>
                        </div>

                        <!-- Datumsfilter -->
                        <!-- ALTERNATIVE MULTI FILTER -->
                        <div *ngIf="tableLayoutItem.filterable && tableLayoutItem.alternateLookupTable != null && (tableLayoutItem.controlType != 1 && tableLayoutItem.controlType != 2)"
                            class="entityTableHeaderItemFilterHeader">
                            <label>{{ tableLayoutItem.displayText | texttransform }}</label>
                            <i class="fas fa-filter fingerCursor" (click)="editAlternatefilter(tableLayoutItem)" [ngClass]="{
                                everleadCoral: columnFilter[tableLayoutItem.memberName] != '' && columnFilter[tableLayoutItem.memberName] != undefined
                            }"></i>

                        </div>

                       <!--  DATE FILTER -->
                        <div *ngIf="tableLayoutItem.filterable && (tableLayoutItem.controlType == 1 || tableLayoutItem.controlType == 2)" class="entityTableHeaderItemFilterHeader">
                            <label>{{ tableLayoutItem.displayText | texttransform }}</label>
                            <i class="fas fa-filter fingerCursor" (click)="editDateFilter(tableLayoutItem)" [ngClass]="{
                                everleadCoral: columnFilter[tableLayoutItem.memberName] != '' && columnFilter[tableLayoutItem.memberName] != undefined
                            }"></i>
                        </div>
                        <div *ngIf="!tableLayoutItem.filterable">{{ tableLayoutItem.displayText | texttransform }}</div>
                    </div>
                    <div class="entityTableHeaderItem__sort fingerCursor" *ngIf="tableLayoutItem.sortable" (click)="sortDataByColIndex(tableLayoutItem, true)">
                        <i class="fas fa-arrow-up entityTableHeaderItem__sort__hover" *ngIf="selectedSortCol.columnName != tableLayoutItem.memberName"></i>
                        <i class="fas fingerCursor" [ngClass]="{
                            'fa-arrow-up': selectedSortCol.id == tableLayoutItem.memberName + 'ASC',
                            'fa-arrow-down': selectedSortCol.id == tableLayoutItem.memberName + 'DESC'
                        }"></i>
                    </div>
                </div>
            </div>
        </div>

        <!-- Hier beginnen die Daten! -->
        <span id="getMaxHeightSpan"></span>
        
        <!-- MicroCampaign -->
        <div [id]="getControlId('entityTableDataContainer')" class="entityTableDataContainer entityTableMicroCampaignContainer" [style.max-height.px]="getMaxHeight()" 
            *ngIf="tableSettingsJson['showMicroCampaignCreation'] == true">
            
            <div class="entityTableMicroCampaignHeader">{{ 'MicroCampaign.Label.EntityTableHeader' | texttransform }}</div>
            <div class="entityTableMicroCampaignSubHeader">{{ getMicroCampaignSubHeader() | texttransform }}</div> 
            <div class="entityTableMicroCampaignStarterList">
                <div class="entityTableMicroCampaignStarterListItem everleadShadowBox" *ngFor="let microcampaign of microcampaignService.microcampaigns" [ngClass]="{
                    hideInDom: microcampaign.nextStepCampaignId == null && applicationInfoService.applicationSettings['isDemoproject'] != 'true'
                }">
                    <div class="entityTableMicroCampaignStarterListItem__name">
                        <span *ngIf="applicationInfoService.isDeveloper" class="entityTableMicroCampaignStarterListItem__id everleadCoral">{{ microcampaign.id }}</span>
                        {{ microcampaign.defaultName | texttransform }}</div>
                    <div class="entityTableMicroCampaignStarterListItem__status">
                        <button class="everleadCustomButton maxWidth" (click)="smartStartMicroCampaign(microcampaign)">
                            {{ 'MicroCampaign.Label.StartCampaign' | texttransform }}
                        </button>                                               
                    </div>
                </div>
            </div>
            <div class="entityTableMicroCampaignReturn">
                <button class="everleadCustomButton" (click)="
                    tableSettingsJson['showMicroCampaignCreation'] = false;
                    tableSettingsJson['additionalToolbar'] = true"
                >
                    {{ 'MicroCampaign.Label.BackToList' | texttransform }}</button>
            </div>
        </div>

        <!-- Normal data -->
        <div [id]="getControlId('entityTableDataContainer')" class="entityTableDataContainer" [style.max-height.px]="getMaxHeight()"
            [ngClass]="{ hideInDom: tableSettingsJson['showMicroCampaignCreation'] == true }">
            <div *ngIf="loadingData" class="loadingDataSpinner">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div *ngFor="let dataItem of controlDataValues"
                class="entityTableData"
                (click)="cellClickedEvent(dataItem)"
                (dblclick)="cellDblClickedEvent(dataItem)"
                [ngClass]="{
                    hideInDom: loadingData,
                    fingerCursor: controlDefinition?.methods?.length > 0
                }"
                [style.height]="getDataRowHeight()"
                [style.background-color]="getDataRowBackgroundColor()"
            >
                <div *ngFor="let tableLayoutItem of headerColumns"
                    class="entityTableDataItem maxHeight"
                    [style.min-width]="getMinWidth(tableLayoutItem)"
                    [style.max-width]="getMaxWidth(tableLayoutItem)"
                    [ngClass]="{  'd-flex': tableLayoutItem.subControlsInRow,
                                  noPadding: tableLayoutItem.controlType == 12 ||
                                             tableLayoutItem.controlType == 13 ||
                                             tableLayoutItem.controlType == 16 ||
                                             tableLayoutItem.controlType == 17
                                }">
                    <app-entitytable-valuecontrol
                        [value]="dataItem[getTableLayoutItemDataFieldName(tableLayoutItem)]"
                        [valueline]="dataItem"
                        [dataTableItem]="dataTableItem"
                        [darkmode]="tableSettingsJson['darkMode']"
                        [control]="tableLayoutItem"
                        [isSmallControlWidth]="getEntityTableWidth() < 700"
                        [subcontrols]="subControlArray(subControls[tableLayoutItem.entityId + tableLayoutItem.memberName])"
                    >
                    </app-entitytable-valuecontrol>
                    <app-entitytable-valuecontrol
                        *ngFor="let tableLayoutItemSubControl of subControls[tableLayoutItem.entityId + tableLayoutItem.memberName]"
                        [value]="dataItem[getTableLayoutItemDataFieldName(tableLayoutItemSubControl)]"
                        [valueline]="dataItem"
                        [control]="tableLayoutItemSubControl"
                        [dataTableItem]="dataTableItem"
                        [darkmode]="tableSettingsJson['darkMode']"
                        [isSmallControlWidth]="getEntityTableWidth() < 700"
                        subcontrol=true
                    >
                    </app-entitytable-valuecontrol>
             
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="tableSettingsJson['canStartMicroCampaign'] == true || tableSettingsJson['canStartMailshot'] == true"
        class="entityTablebottomToolbar everleadShadowBox2" [style.background-color]="getTableMenuBackgroundColor()">
        <div>
            <select class="microCampaignList" [(ngModel)]="selectedMicroCampaign">
                <option *ngFor="let microcampaign of microcampaigns" [ngValue]="microcampaign">{{ microcampaign.defaultName | texttransform }}</option>
            </select>
            <button class="everleadCustomButton" *ngIf="tableSettingsJson['canStartMicroCampaign'] == true" (click)="startMicroCampaign()">
                {{'ConsentManagementDashboard.Label.StartMicroCampaign'|texttransform}}
            </button>
        </div>
        <button class="everleadCustomButton" *ngIf="tableSettingsJson['canStartMailshot'] == true">
            {{'ConsentManagementDashboard.Label.StartMailShot'|texttransform}}
        </button>
    </div>
</div>

<!-- Spinner während projectData abgeholt wird -->
<span *ngIf="applicationInfoService.getCompleteProjectInformatonLoaded==false">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</span>

<!-- Wenn keine Definition gefunden wurde -->
<div class="entityTable__wrapper" *ngIf="!contentFound && contentSearched && applicationInfoService.getCompleteProjectInformatonLoaded">
    <div class="everleadShadowBox entityTableNewDefinition">
        <label>{{ 'EntityTableDesigner.Label.NoDefinitionFound' | texttransform }}</label>
        <button class="everleadCustomButton" (click)="showEntityTableDesigner()">{{ 'EntityTableDesigner.Label.CreateDefinition' | texttransform }}</button>
    </div>
</div>


<!-- Filterbox (Lookups)-->
<!-- <div *ngIf="lookupFilter" class="entityTableMultiFilterBoxBackground"></div> -->
<div [id]="getFilterBoxId()" [ngClass]="{ hideInDom: lookupFilter==false }" class="entityTableMultiFilterBox">
    <div class="entityTableMultiFilterBox__header">
        <div class="entityTableMultiFilterTitle">
            <label>{{ 'EntityTableDesigner.Label.SelectValuesForFilter' | texttransform }}</label>
            <div>
                <svg-icon src="assets/images/svg/close.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="fingerCursor" (click)="lookupFilter = false"></svg-icon>
            </div>
        </div>
    </div>
    <div class="everleadShadowBox entityTableMultiFilterBox__filterFilter">
        <i class="fas fa-filter fa-lg"></i>
        <input [(ngModel)]="filterString">
    </div>
    <div class="everleadShadowBox entityTableMultiFilterBox__inner__wrapper">
        <div class="entityTableMultiFilterBox__inner">
            <div *ngFor="let multiListFilterItemsItem of multiListFilterItems" class="entityTableMultiFilterBox__inner__item" [ngClass]="{
                hideInDom: isItemFiltered(multiListFilterItemsItem) || checkIfFilterItemIsDoublicate(multiListFilterItemsItem)
            }">
                <input type="checkbox" [(ngModel)]="filterLookupItem.filter[multiListFilterItemsItem.id]" (change)="checkEqualItems(multiListFilterItemsItem)">
                <label class="ellipsisText" *ngIf="filterLookupItem['filterListDisplayValue'] == null">{{ commonService.getNameTranslationOrDefaultName(multiListFilterItemsItem) | texttransform }}</label>
                <label class="ellipsisText" *ngIf="filterLookupItem['filterListDisplayValue'] != null">{{ multiListFilterItemsItem[filterLookupItem['filterListDisplayValue']] | texttransform }}</label>
            </div>
        </div>
    </div>
    <div class="entityTableDateFilterBox__buttons">
        <button class="everleadCustomButton" (click)="removeMultiFilter()">{{ 'EntityTableDesigner.Label.RemoveSelection' | texttransform }}</button>
        <button class="everleadCustomButton" (click)="setMultiFilter()">{{ 'EntityTableDesigner.Label.SetFilter' | texttransform }}</button>
    </div>
</div>

<!-- <div *ngIf="lookupFilter" class="entityTableMultiFilterBox">
    <div class="entityTableFilter__wrapper">
        <div class="entityTableFilter__input">
            <i class="fas fa-filter fa-sm"></i>
            <input [(ngModel)]="filterString"[placeholder]="'filter'">
        </div>
        <div class="entityTableFilter__selection">
            <div *ngFor="let multiListFilterItemsItem of multiListFilterItems" class="entityTableFilter__checkBox" [ngClass]="{
                hideInDom: isItemFiltered(multiListFilterItemsItem) || checkIfFilterItemIsDoublicate(multiListFilterItemsItem)
            }">
                <input type="checkbox" [(ngModel)]="filterLookupItem.filter[multiListFilterItemsItem.id]" (change)="checkEqualItems(multiListFilterItemsItem)">
                 <label class="ellipsisText" *ngIf="filterLookupItem['filterListDisplayValue'] == null">{{ commonService.getNameTranslationOrDefaultName(multiListFilterItemsItem) | texttransform }}</label>
                <label class="ellipsisText" *ngIf="filterLookupItem['filterListDisplayValue'] != null">{{ multiListFilterItemsItem[filterLookupItem['filterListDisplayValue']] | texttransform }}</label>
            </div>
        </div>
        <div class="">
            <button class="everleadCustomButton" (click)="removeMultiFilter()">{{ 'EntityTableDesigner.Label.RemoveSelection' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="setMultiFilter()">{{ 'EntityTableDesigner.Label.SetFilter' | texttransform }}</button>
        </div>
    </div>
</div> -->

<!-- Filterbox (Daterange)-->
<!-- <div *ngIf="dateFilter" class="entityTableMultiFilterBoxBackground"></div> -->
<div *ngIf="dateFilter" class="entityTableMultiFilterBox entityTableDateFilterBox">
    <div class="entityTableMultiFilterBox__header">
        <div class="entityTableMultiFilterTitle">
            <label class="ellipsisText">{{ 'EntityTableDesigner.Label.SelectDateRange' | texttransform }}</label>
            <div>
                <svg-icon src="assets/images/svg/close.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="fingerCursor" (click)="dateFilter=false"></svg-icon>
            </div>
        </div>
    </div>
    <div class="entityTableMultiFilterBox__inner">
        <div class="everleadShadowBox">
            <div class="entityTableDateFilterBox__line">
                <label>{{ 'EntityTableDesigner.Label.DateFilterFrom' | texttransform }}</label>
                <input [(ngModel)]=dateRangeFilterItem.dateFilter.dateFrom [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly  appendTo="body" aria-modal="true">
                <owl-date-time #dt7></owl-date-time>
            </div>
            <div class="entityTableDateFilterBox__line">
                <label>{{ 'EntityTableDesigner.Label.DateFilterUntil' | texttransform }}</label>
                <input [(ngModel)]=dateRangeFilterItem.dateFilter.dateUntil [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly  appendTo="body" aria-modal="true">
                <owl-date-time #dt8></owl-date-time>
            </div>
        </div>
        <div class="entityTableDateFilterBox__buttons">
            <button class="everleadCustomButton" (click)="removeDateFilter()">{{ 'EntityTableDesigner.Label.RemoveSelection' | texttransform }}</button>
            <button class="everleadCustomButton" [disabled]="dateRangeFilterItem.dateFilter.dateFrom == null || dateRangeFilterItem.dateFilter.dateUntil == null"
                (click)="setDateFilter()">{{ 'EntityTableDesigner.Label.SetFilter' | texttransform }}</button>
        </div>
    </div>
</div>
