<div class="projectSettings__header">
    <div class="blackListNaviLeft">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createBlacklistItem()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>               
                {{ 'Blacklist.Label.NewEntry' | texttransform }}
        </div>
        <div class="projectSettings__header__newItem fingerCursor" (click)="importBlacklist()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [title]="'General.Label.ImportBlacklist' | texttransform"></svg-icon>               
                {{ 'Blacklist.Label.ImportBlacklist' | texttransform }}
        </div>    
    </div>
    <div class="projectSettings__header__interaction">        
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>

<div>
    <div class="everleadMaxHeightOverflow">
    <div class="blackListItem__line__section__item">
        <label class="everleadSmallHeader">{{ 'Blacklist.Label.Customer' | texttransform }}</label>
        <select [(ngModel)]="selectedCustomer" (change)="getBlacklistsForCustomer()">
            <option [ngValue]=null></option>
            <option [ngValue]="customer.id" *ngFor="let customer of customers">{{ customer.customerName }}</option>
        </select>    
    </div>
    <div class="everleadSmallHeader blackListItem__spacer">{{ 'Blacklist.Label.BlacklistEntriesForCustomer' | texttransform }}</div>
    <div *ngFor="let blacklist of blacklists" class="everleadShadowBox blackListItem" [ngClass]="{ hideInDom: itemIsFiltered(blacklist) }">
        <div class="blackListItem__line">
            <div class="maxWidth">
                <input [(ngModel)]="blacklist.value" (change)="saveItem(blacklist)" class="maxWidth">
            </div>
            <div class="blackListItem__line__type">
                <select [(ngModel)]="blacklist.blacklistTypeLookupId" (change)="saveItem(blacklist)">
                    <option *ngFor="let type of types" [ngValue]="type.id">{{ type.defaultName }}</option>
                </select>
            </div>
            
            <div><i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteBlacklistItem(blacklist)"></i></div>
        </div>
        <div class="blackListItem__line">
            <div class="blackListItem__line__section">
                <div class="blackListItem__line__section__item">
                    <label class="everleadSmallHeader">{{ 'Blacklist.Label.Customer' | texttransform }}</label>
                    <select [(ngModel)]="blacklist.customerId" (change)="changeCustomer(blacklist)">
                        <option [ngValue]=null></option>
                        <option [ngValue]="customer.id" *ngFor="let customer of customers">{{ customer.customerName }}</option>
                    </select>    
                </div>
                <div class="blackListItem__line__section__item">
                    <label class="everleadSmallHeader">{{ 'Blacklist.Label.Project' | texttransform }}</label>
                    <select [(ngModel)]="blacklist.projectId" (change)="saveItem(blacklist)" [disabled]="blacklist.customerId == null">
                        <option [ngValue]=null></option>
                        <option [ngValue]="project.id" *ngFor="let project of getValidProjects(blacklist.customerId)">{{ project.projectName }}</option>
                    </select>    
                </div>
            </div>
            <div class="blackListItem__line__section">
                <div class="blackListItem__line__section__item">
                    <label class="everleadSmallHeader">{{ 'Blacklist.Label.ValidFrom' | texttransform }}</label>
                    <input [(ngModel)]="blacklist.startTime" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" 
                        (dateTimeChange)="saveItem(blacklist)" readonly appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>            
                </div>
                <div class="blackListItem__line__section__item">
                    <label class="everleadSmallHeader">{{ 'Blacklist.Label.ValidUntil' | texttransform }}</label>
                    <input [(ngModel)]="blacklist.endDate" [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" 
                        (dateTimeChange)="saveItem(blacklist)" readonly appendTo="body" aria-modal="true">
                    <owl-date-time #dt8></owl-date-time>        
                </div>
            </div>
        </div>
        <div>
            <div class="everleadSmallHeader">{{ 'Blacklist.Label.RegEx' | texttransform }}</div>
            <textarea [(ngModel)]="blacklist.regex" class="maxWidth" class="blackListItem__line__textarea" (change)="saveItem(blacklist)"></textarea>
        </div>
        <div *ngIf="applicationInfoService.isDeveloper" class="blackListItem__line__ag">
            <input [(ngModel)]="blacklist.isBlockedForAG" (change)="saveItem(blacklist)" type="checkbox">
            <label class="everleadSmallHeader">isBlockedForAG</label>
        </div>
    </div></div>
</div>