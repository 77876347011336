import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-dashboardcomponent-leadstates',
  templateUrl: './dashboardcomponent-leadstates.component.html',
  styleUrls: ['./dashboardcomponent-leadstates.component.scss']
})
export class DashboardcomponentLeadstatesComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  funnelChartId = '';
  funnelData = {
    data: [],
    type: 'funnel',
  };
  maxHeight = '100%';
  loadingData = true;
  maxValue = 0;
  chartDataBackground = {
    data: [],
    type: 'funnel',
  }
  mode= '1';
  colors = [
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#57518E', '#ffbf00',
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#57518E', '#346188', '#1B3A55',
  ];

  constructor(
    private dashboardcoreService: DashboardcoreService,
    private commonService: CommonService,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    if (this.applicationInfoService.getCompleteProjectInformatonLoaded) {
      this.getDashboardData();
    }    
    this.funnelChartId = this.commonService.createGuid();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'leadStatesLoaded') { 
        this.getDashboardData(); 
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getDashboardData() {
    const params = [];
    let dashboardId = 3;
    if (this.mode == '2') { dashboardId = 4; }
    this.loadingData = true;
    this.dashboardcoreService.getDashboardData(dashboardId, params).then(getDatashboardDataResult => {
      this.generateFunnelData(getDatashboardDataResult[0]);
    });
  }

  generateFunnelData(data) {   
    this.funnelData.data = [];
    this.maxValue = 0;
    data.forEach(dataItem => {      
      console.warn(this.applicationInfoService.leadstates.toArray())
      const leadState = this.applicationInfoService.leadstates.toArray().find(leadstateItem => leadstateItem.lookupTable.id == dataItem.leadstateid);
      if (leadState) {
        dataItem.leadState = leadState;
      } else {
        let tableName = 'FunnelData.Label.Unknown';
        if (dataItem.leadstateid == null) {
          tableName = 'FunnelData.Label.NotSetYet';
        }
        dataItem.leadState = {
          lookupTable: {
            defaultName: tableName,
            order: 200000  
          }
        }
      }
    });
    data.sort((a, b) => a.leadState.lookupTable.order > b.leadState.lookupTable.order ? -1 : 1);
    data.forEach(dataItem => {
      this.funnelData.data.push({        
        name: this.axivasTranslateService.getTranslationTextForObject(dataItem.leadState.lookupTable, 'nameTranslationToken'),
        value: dataItem.leadstatecount
      }); 
    });
    data.forEach(dataItem => {
      this.chartDataBackground.data.push({        
        name: this.axivasTranslateService.getTranslationTextForObject(dataItem.leadState.lookupTable, 'nameTranslationToken'),
        value: dataItem.leadstatecount
      }); 
      if (dataItem.leadstatecount > this.maxValue)        {
        this.maxValue = dataItem.leadstatecount;
      }
    });

    this.eventService.customEvent.emit({ 
      id: 'updateFunnelChart', 
      value: this.funnelChartId, 
      chartData: this.funnelData
    });
    this.loadingData = false;
  }
}
