import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { Dictionary } from 'app/core/dictionary';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-communication-guideline',
  templateUrl: './custom-communication-guideline.component.html',
  styleUrls: ['./custom-communication-guideline.component.scss']
})
export class CustomCommunicationGuidelineComponent extends CustomControlBaseClass implements OnInit {
  loadingControlData = false;
  calculatedText = '';
  externalDataSourceResults: Dictionary<string> = new Dictionary();
  isViewExtended = false;
  labelHeight = '100';

  constructor(
    private injector: Injector,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private axivasTranslateService: AxivasTranslateService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit() {
    this.getControlDataFromExternalDataSource();
    this.calculateText();
  }

  toggleView() {
    this.isViewExtended = !this.isViewExtended;
    if (this.isViewExtended) {
      this.labelHeight = '300';
    } else {
      this.labelHeight = '100';
    }
  }

  calculateText(): string {
    this.calculatedText = '';
    let newText = this.getDisplayText();
    if (!newText) {
      newText = '';
    }
    newText = newText.replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.data.forEach(communicationGuidelineValuesResultItem => {
      let replacementString = '';
      const entityValue = this.entityService.getEntityValue(
      communicationGuidelineValuesResultItem.uiEntityInstanceId,
        this.entityService.getMemberDefaultName(
          communicationGuidelineValuesResultItem.uiEntityInstanceId,
          communicationGuidelineValuesResultItem.memberId
        ), 1
      );

      if (entityValue) {
        const externalDataSource = this.entityService.getExternalDataSourceOfEntityMember(
          communicationGuidelineValuesResultItem.uiEntityInstanceId,
          communicationGuidelineValuesResultItem.memberId
        );
        if (externalDataSource) {
          if (this.externalDataSourceResults.ContainsKey(communicationGuidelineValuesResultItem.memberId)) {
            const dictData: any = this.externalDataSourceResults.Item(communicationGuidelineValuesResultItem.memberId);
            if (dictData) {
              dictData.forEach(item => {
                if (item.id === entityValue) {
                  if (item.nameTranslationToken) {
                    replacementString = this.axivasTranslateService.getTranslationTextForToken(item.nameTranslationToken.tokenFullName);
                  } else {
                    replacementString = item.defaultName;
                  }
                }
              });
            }
          } else {
            this.externalDataSourceResults.Add(communicationGuidelineValuesResultItem.memberId, null);
            this.loadingControlData = true;
            this.externalDatasourceService.executeQuery(externalDataSource)
            .then(result => {
              this.loadingControlData = false;
              this.externalDataSourceResults.Add(communicationGuidelineValuesResultItem.memberId, result);
            })
            .catch(() => {
              this.loadingControlData = false;
            });
          }
        } else {
          replacementString = entityValue;
        }
      } else {
        if (communicationGuidelineValuesResultItem.defaultTranslationToken) {
          replacementString = this.axivasTranslateService.getTranslationTextForToken(
            communicationGuidelineValuesResultItem.defaultTranslationToken.tokenFullName);
        } else {
          replacementString = '';
        }
      }
      newText = newText.replace(communicationGuidelineValuesResultItem.parameter, replacementString);
      this.calculatedText = newText;
    });
    return newText;
  }

  getControlDataFromExternalDataSource() {
    if (this.externalDataSource) {
    this.dataKey = this.externalDataSource.dataKey;
    this.loadingControlData = true;
    this.externalDatasourceService.executeQuery(this.externalDataSource)
      .then(data => {
        this.loadingControlData = false;
        this.data = data;
      })
      .catch(data => {
        this.loadingControlData = false;
        console.error(data);
      });
    }
  }

  getMainClasses() {
    return ' ' + this.controlDefinition.additionalCssClasses;
  }

  getClasses() {
    let returnValue = 'communicationGuidelineLabelWrapping';
    if (this.controlDefinition.additionalCssClassesMainLabel) {
      returnValue = returnValue.concat(' ', this.controlDefinition.additionalCssClassesMainLabel);
    }
    return returnValue;
  }
}
