import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { isNullOrUndefined } from 'util';

export enum AuthHTTPMethods {
  Get = 1,
  Post = 2
}

const settings: any = {
  authority: environment.authorityServerUrl,
  client_id: environment.clientId,
  redirect_uri: `${environment.serverUrl}${environment.baseRef}/signin-callback.html`,
  post_logout_redirect_uri: `${environment.serverUrl}${environment.baseRef}`,
  response_type: 'id_token token',
  scope: 'openid profile api1',

  silent_redirect_uri:  `${environment.serverUrl}${environment.baseRef}/silent-renew.html`,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 40,
  filterProtocolClaims: true,
  loadUserInfo: true
};

@Injectable()
export class AuthService {
  public eventSilentRenewError: EventEmitter<boolean> = new EventEmitter();
  public master = true;

  loggedIn = false;
  authHeaders: HttpHeaders;

  appUserInformationType = 'JJUserInformationType';
  appUserInformation = 'JJUserInformation';

  constructor(
    private http: HttpClient,
    location: Location,
    private applicationInfoService: ApplicationInfoService,
  ) {
    let url: string;

    url =  location.prepareExternalUrl(location.path());

    if (url.startsWith(`/${environment.baseRef}` )) {
      url = url.replace(`/${environment.baseRef}`, '');
    }

    if (!this.loggedIn && url.length > 1) {
      localStorage.setItem('redirectUrl' , url);
    }
  }

  Get(url: string): Observable<any> {
    return this.http.get(url);
  }


  GetAssetsPathUrl(filePath: string): Observable<Blob> {
    return this.http.get(filePath, { responseType: 'blob' });
  }

  /**
   * Example of how you can make auth request using angulars http methods.
   * @param options if options are not supplied the default content type is application/json
   */
  AuthGet(url: string, options?): Observable<any> {
    if (options) {
      options = this._setRequestOptions(options);
    } else {
      options = this._setRequestOptions();
    }
    return this.http.get(url, options);
  }

  AuthGetBlob(url: string, type: AuthHTTPMethods = AuthHTTPMethods.Get, body: any = []): Observable<Blob> {
    this._setAuthHeaders();
    switch (type) {
      case AuthHTTPMethods.Get:
        return this.http.get(url, { headers: this.authHeaders, responseType: 'blob' });
      case AuthHTTPMethods.Post:
        return this.http.post(url, body, { headers: this.authHeaders, responseType: 'blob' });
    }
  }

  AuthGetBlobResponse(url: string): Observable<HttpResponse<any>> {
    this._setAuthHeaders();
    return this.http.get(url, { headers: this.authHeaders, observe: 'response', responseType: 'blob' });
  }

  AuthGetPostBody(url: string, body: any = []): Observable<any> {
    this._setAuthHeaders();
    return this.http.post(url, body, { headers: this.authHeaders, responseType: 'json' });
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPut(url: string, data: any, options?): Observable<any> {
    const body = JSON.stringify(data);

    if (options) {
      options = this._setRequestOptions(options);
    } else {
      options = this._setRequestOptions();
    }
    options.body = body;
    return this.http.put(url, body, options);
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthDelete(url: string, options?): Observable<any> {
    if (options) {
      options = this._setRequestOptions(options);
    } else {
      options = this._setRequestOptions();
    }
    return this.http.delete(url, options);
  }

  UploadFile(url: string, data: any, options?): Observable<any> {
    if (options) {
      options = this._setRequestOptions(options, false);
    } else {
      options = this._setRequestOptions(null, false);
    }
    options.body = data;
    return this.http.post(url, data, options);
  }

  UploadBlacklistFile(url: string, data: any, importData, blacklistType, fieldName): Observable<any> {
    const options = this._setRequestOptions(null, false);
    options.body = data;
    return this.http.post(url, data, options);
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPost(url: string, data: any, options?): Observable<any> {
    const body = JSON.stringify(data);
    if (options) {
      options = this._setRequestOptions(options);
    } else {
      options = this._setRequestOptions();
    }
    options.body = body;
    return this.http.post(url, body, options);
  }

  private _setAuthHeaders(setContentType = true): void {
    const timeOffSet = (-1 * new Date(Date.now()).getTimezoneOffset()).toString();
    this.authHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0',
      'projectId': this.applicationInfoService.projectID.toString(),
      'Authorization': 'bearer' + ' ' + localStorage.getItem('awstoken'),
      'RoleId': this.applicationInfoService.currentUserRoleId,
      'X-Timezone-Offset': timeOffSet,
    });

    if (this.applicationInfoService.user != null) {
      if (!isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
        this.authHeaders = this.authHeaders.append('userId', this.applicationInfoService.user.fakeUserId.toString());
      } else {
        this.authHeaders = this.authHeaders.append('userId', this.applicationInfoService.userID.toString());
      }
    }

    if (setContentType && !this.authHeaders.get('Content-Type')) {
      this.authHeaders = this.authHeaders.append('Content-Type', 'application/json');
    }
  }

  private _setRequestOptions(options?, setContentType = true) {
    this._setAuthHeaders(setContentType);

    if (this.loggedIn) {
      this._setAuthHeaders(setContentType);
    }

    if (options) {
      options.headers = this.authHeaders;
    } else {
      options = { headers: this.authHeaders, body: '' };
    }

    return options;
  }
}
