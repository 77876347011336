import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { RingcentralService } from 'app/jollyjupiter/service/ringcentral.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';

@Component({
  selector: 'app-incomingcall',
  templateUrl: './incomingcall.component.html',
  styleUrls: ['./incomingcall.component.scss']
})
export class IncomingcallComponent implements OnInit {  
  showIncomingCall = false;
  currentCallerList = null;
  callerList = [];
  loadingData = false;
  callerNumber = '';
  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private externalDataSourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private ringcentralService: RingcentralService,
    private methodService: MethodService,
    public ringcentralwebphoneService: RingcentralwebphoneService
  ) { }

  ngOnInit(): void {    
    this.eventService.openIncomingCallEvent.subscribe(event => {
      if (this.applicationInfoService.isMobile()) {
        return;
      }
      if (event == null) {
        this.showIncomingCall = !this.showIncomingCall;
      } else {
        this.loadCallerNumber(event);
      }      
    });
  }

  loadCallerNumber(callerNumber) {
    this.showIncomingCall = true;
    this.callerNumber = callerNumber;
    this.currentCallerList = [];
    this.loadingData = true;
    this.externalDataSourceService.executeExternalDataSource(565, [callerNumber])
    .then(getCallerInfoResult => {
      this.loadingData = false;
      this.currentCallerList = getCallerInfoResult;
      this.externalDataSourceService.executeExternalDataSource(791, [callerNumber])
      .then(getCallerInfoResultAccount => {
        if (this.currentCallerList == null) { this.currentCallerList = []; }
        if (getCallerInfoResultAccount != null) {
          getCallerInfoResultAccount.forEach(getCallerInfoResultAccountItem => {
            this.currentCallerList.push({ lastName: getCallerInfoResultAccountItem.name1, phone: getCallerInfoResultAccountItem.phone, singleAccountId: getCallerInfoResultAccountItem.id });
          });  
        }
      });
    });
  }

  loadContact(contact) {
    if (contact.singleAccountId != null) {
      this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [contact.singleAccountId]);
      // this.showIncomingCall = false;
    } else {
      this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [contact.id]);
    }
  }

  acceptCall() {
    this.ringcentralService.answerCall();
    // this.showIncomingCall = false;
  }

  acceptWebRTCCall() {
    this.ringcentralwebphoneService.accept();
    // this.showIncomingCall = false;
  }
}
