<div [innerHTML]="appointmentViewInnerHTML"></div>
<br>
<br>

<app-extended-button width="200px" (buttonClickEvent)="saveChangesToAppointment()"
    [ngClass]="{ hideInDom: !userService.hasPermission('CanUpdateActivityNote') }"
    style="margin-right: 5px;"
    buttonText="AppointmentView.Label.SaveChanges" iconCss="fas fa-save" class="inline">
</app-extended-button>

<app-extended-button width="200px" (buttonClickEvent)="reopenActivity()"
    *ngIf="canChangeResult"
    [ngClass]="{ hideInDom: !userService.hasPermission('CanReadAllUsers') }"
    buttonText="AppointmentView.Label.ReopenActivity" iconCss="fas fa-lock-open" class="inline">
</app-extended-button><br>


<br>