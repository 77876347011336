<div class="ringcentralWebRTC">
    <div class="ringcentralWebRTC__brand everleadCoral">
        Unify Office
    </div>
    <div *ngIf="ringcentralwebphoneService.extension != null" class="ringcentralWebRTC__headerLine">
        <div class="ringcentralWebRTC__headerLine__name">
            <i class="fas fa-external-link-alt fingerCursor" (click)="callRingCentralPopup()"></i>
            <select [(ngModel)]="ringcentralwebphoneService.availabilityMode" 
                    (change)="ringcentralwebphoneService.changeAvailabilityMode()"
                    class="ringcentralWebRTC__dropdown maxWidth"
            >
                <option [ngValue]=null hidden>{{ 'Activity.Name.Unknown' | texttransform }}</option>
                <option ngValue="inbound">{{ 'Activity.Name.Inbound_Outbound' | texttransform }}</option>
                <option ngValue="backoffice">{{ 'Activity.Name.Backoffice' | texttransform }}</option>
                <option ngValue="notavailable">{{ 'Activity.Name.Not_Available' | texttransform }}</option>
            </select>            
        </div>
    </div>
    <div class="ringcentralWebRTC__currentSession" *ngIf="ringcentralwebphoneService.session">        
        <div class="ringcentralWebRTC__otherPartyInfo ellipsisText">   
            <ng-container>
                <span class="everleadCoral">{{ ringcentralwebphoneService.getTimeDifferenceString() }}</span>
            </ng-container>
            <ng-container *ngIf="ringcentralwebphoneService.outboundCall">                
                {{ ringcentralwebphoneService.getFriendlyName('to') }}
            </ng-container>         
            <ng-container *ngIf="!ringcentralwebphoneService.outboundCall">
                {{ ringcentralwebphoneService.session?.request?.from?.uri?.raw?.user}}
            </ng-container>
        </div>        

        <div class="ringcentralWebRTC__quickButtons">
            <span *ngIf="!ringcentralwebphoneService.incomingCall">
                <i class="fas fa-circle everleadCoral webRTCButton fingerCursor" (click)="toggleRecord()" [ngClass]="{
                    everleadCoral: ringcentralwebphoneService.sessionState.record
                }" *ngIf="applicationInfoService.applicationSettings['isRecordingAllowed'] == 'true' && 
                    ringcentralwebphoneService.established && 
                    ringcentralwebphoneService.makeCallObject.accountId != null">
                </i>
                <i class="fas fa-pause everleadCoral webRTCButton fingerCursor" (click)="ringcentralwebphoneService.toggleHold()" [ngClass]="{
                    everleadCoral: ringcentralwebphoneService.session.held
                }"></i>
                <i class="fas fa-microphone-slash  fingerCursor webRTCButton" (click)="ringcentralwebphoneService.toggleMute()" [ngClass]="{
                    everleadCoral: ringcentralwebphoneService.session.muted
                }"></i>
            </span>
            <span *ngIf="ringcentralwebphoneService.incomingCall">
                <i class="fas fa-phone everleadGreen fingerCursor webRTCButton" (click)="ringcentralwebphoneService.accept()"></i>
            </span>
            
            <i class="fas fa-volume-down fingerCursor webRTCButton" (click)="ringcentralwebphoneService.volumeDown()"></i>
            <i class="fas fa-volume-up fingerCursor webRTCButton" (click)="ringcentralwebphoneService.volumeUp()"></i>

            <i class="fas fa-phone-slash everleadCoral fingerCursor webRTCButton webRTCButton__hangUp" 
                *ngIf="ringcentralwebphoneService.incomingCall" (click)="ringcentralwebphoneService.reject()"></i>
            <i class="fas fa-phone-slash everleadCoral fingerCursor webRTCButton webRTCButton__hangUp" 
                *ngIf="!ringcentralwebphoneService.incomingCall" (click)="ringcentralwebphoneService.hangUp()"></i>
            <div class="ringcentralWebRTC__headerLine_extend">
                <i class="fas fingerCursor webRTCButton" (click)="expanded = !expanded" [ngClass]="{
                    'fa-chevron-down': !expanded,
                    'fa-chevron-up': expanded
                }"></i>    
            </div>
        </div>        
    </div>           
    <div *ngIf="expanded && ringcentralwebphoneService.session" class="ringcentralWebRTC__currentSession__expanded">
        <app-softphone-ringcentralwebrtc-extension></app-softphone-ringcentralwebrtc-extension>
    </div>         

    <!-- Login -->
    <div *ngIf="ringcentralwebphoneService.extension == null">
        <button  class="everleadCustomButton" (click)="login()">login</button>        
    </div>
    <!-- <button class="everleadCustomButton" (click)="makeCall()">makeCall</button> -->


</div>
<div *ngIf="checkAuthKey()"></div>