<div *ngFor="let setting of settings" class="nonExpertView__main nonExpertView__main__noSubitems everleadShadowBox">        
    <div class="nonExpertView__name">            
        <label>{{ setting.projectName }}</label>        
    </div>  
    <div class="nonExpertView__bonus" [tippy]="'General._.Edit' | texttransform" (click)="showWizard(setting)">
        <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button"></i>
    </div>
</div>
























<div *ngIf="newMode == false">

<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor leadStateHeaderDiv">
        <label style="padding-left: 3px;"
            class="normalWhiteSpace"><b>{{ 'LeadReportSettings.Label.ManageLeadReportSettings' | texttransform }}</b></label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
        <button (click)="saveSettings()" class="jjControlSubmenuButton" style="white-space: normal;"><i
                class="fas fa-save"></i></button>
    </div>
</div>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.LeadreportLevel' | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.leadreportLevel" class="leadReportSettingItem">
    <option [ngValue]=null></option>
    <option [ngValue]="level.id" *ngFor="let level of levels">{{ level.defaultName }}</option>
</select>
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.Language' | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.leadreportLanguageId" class="leadReportSettingItem">
    <option [ngValue]="language.id" *ngFor="let language of languages">{{ language.languageName }}</option>
</select>
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.LeadreportTemplateDefinitionId' | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.leadreportTemplateDefinitionId" class="leadReportSettingItem">
    <option [ngValue]=null></option>
    <option [ngValue]="templateDefinition.id" *ngFor="let templateDefinition of templateDefinitions">{{ templateDefinition.defaultName }}</option>
</select>
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.FileType' | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.leadreportFiletype" class="leadReportSettingItem">
    <option [ngValue]=null></option>
    <option ngValue="docx">{{ 'Leadreport.Label.FileTypeWord' | texttransform }}</option>
    <option ngValue="pdf">{{ 'Leadreport.Label.FileTypePdf' | texttransform }}</option>
</select>
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.OnlyNetResults' | texttransform }}</label>
<input [(ngModel)]="applicationInfoService.projectInfo.leadreportOnlyNetResults" type="checkbox">
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.IsAutomaticLeadreportUploadEnabled' | texttransform }}</label>
<input [(ngModel)]="applicationInfoService.projectInfo.isAutomaticLeadreportUploadEnabled" type="checkbox">
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.LeadreportFtpPath' | texttransform }}</label>
<input [(ngModel)]="applicationInfoService.projectInfo.leadreportFtpPath" class="leadReportSettingItem">
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.LeadreportZipFilePassword' | texttransform }}</label>
<input [(ngModel)]="applicationInfoService.projectInfo.leadreportZipFilePassword" type="password" class="leadReportSettingItem">
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.LeadreportExportSettingLookupId'  | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.leadreportExportSettingLookupId" class="leadReportSettingItem">
    <option [ngValue]="leadreportExportSetting.id" *ngFor="let leadreportExportSetting of leadreportExportSettings">{{ leadreportExportSetting.defaultName }}</option>
</select>
<br>

<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.FTPConnectionId' | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.ftpConnectionId" class="leadReportSettingItem">
    <option [ngValue]=null></option>
    <option [ngValue]="ftpConnection.id" *ngFor="let ftpConnection of ftpConnections">{{ ftpConnection.host }}:{{ ftpConnection.port }}</option>
</select>
<br>
<br>
<label><b>BOX - Support</b></label>
<br>
<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.LeadreportBoxPath' | texttransform }}</label>
<input [(ngModel)]="applicationInfoService.projectInfo.leadreportBoxPath" type="text" class="leadReportSettingItem">
<br>
<label class="leadReportSettingLabel">{{ 'LeadReportSettings.Label.BoxConnectionId' | texttransform }}</label>
<select [(ngModel)]="applicationInfoService.projectInfo.boxConnectionId" class="leadReportSettingItem">
    <option [ngValue]=null></option>    
</select>

</div>