import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-consent-management',
  templateUrl: './consent-management.component.html',
  styleUrls: ['./consent-management.component.scss']
})
export class ConsentManagementComponent implements OnInit {
  medias = [];
  selectedMedias = [];
  iframeSource = null;
  iFrameLoaded = false;
  selectedLanguage = null;

  consentSettings = {
    bodyBackgroundColor: '#F5F5F5',    
    bodyColor: '#F5F5F5',    
    headerPicture: null,
    headerBackgroundColor: '#F5F5F5',    
    mediumTypes: '',
    footerBackgroundColor: '#F5F5F5',
    footerColor: '#000000',
    impressumLink: '',
    dataProtectionLink: '',
    pictureType: 'banner',
    pictureWidth: '100%',
    pictureHeight: '50px',
    picturePosition: 'flex-start',
    footerEnabled: true,
    footerHeight: '50px',
    bodyWidth: '100%',
    fontSize: '16px',
    fontFamily: 'Poppins',
    headerHorizontalPadding: '0px',
    headerHeight: '50px',    
    languages: [
      // { 
      //   id:  'de',
      //   countryIcon: '',
      //   name: 'Deutsch',
      //   rtl: false,
      //   isActive: true,
      //   translations: [
      //     { id: 'mediaText1', description: 'Consent.Label.TextMediaLine1', type: 'textarea', value: 'Ich bin damit einverstanden, dass die von mir angegebenen Te in einer von der ALEX & GROSS GmbH verwalteten, zentralen Datenbank gespeichert werden.' },
      //     { id: 'mediaText2', description: 'Consent.Label.TextMediaLine2', type: 'textarea', value: 'Ich bin damit einverstanden, dass die ALEX & GROSS GmbH meine Daten zur individuellen Kunden- und Interessentenbetreuung und zur Bewerbung von Produkten/Lösungen der ALEX & GROSS GmbH nutzen und mich zu diesen Zwecken unter der/den von mir genannten E-Mail-Adresse(n) und Telefonnummer(n) kontaktieren kann.' },
      //     { id: 'consentButton', description: 'Consent.Label.TextButton', type: 'input', value: 'Jetzt absenden' },
      //     { id: 'requiredFields', description: 'Consent.Label.TextRequiredFields', type: 'input', value: 'Mit einem Sternchen (*) markierte Felder sind Pflichtfelder und müssen ausgefüllt werden.' },
      //     { id: 'thankYou', description: 'Consent.Label.ThankYou', type: 'textarea', value: 'Ich.' },
      //   ]
      // },
      // { 
      //   id:  'en',
      //   name: 'English',
      //   rtl: false,
      //   countryIcon: '',
      //   isActive: true,
      //   translations: [
      //     { id: 'mediaText1', description: 'Consent.Label.TextMediaLine1', type: 'textarea', value: 'I consent to the data I have provided being stored in a central database managed by ALEX & GROSS GmbH.' },
      //     { id: 'mediaText2', description: 'Consent.Label.TextMediaLine2', type: 'textarea', value: 'I agree that ALEX & GROSS GmbH may use my data for individual customer and prospective customer care and to advertise products/solutions from ALEX & GROSS GmbH and that I may be contacted for these purposes at the e-mail address(s) and telephone number(s) I have given.' },
      //     { id: 'consentButton', description: 'Consent.Label.TextButton', type: 'input', value: 'Send now' },
      //     { id: 'requiredFields', description: 'Consent.Label.TextRequiredFields', type: 'input', value: 'Fields marked with an asterisk (*) are mandatory and must be filled out.' },
      //     { id: 'thankYou', description: 'Consent.Label.ThankYou', type: 'textarea', value: '' },
      //   ]
      // }
    ],
  };


  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private uiService: UiService,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer,
    private executeExternalDataSource: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    let foundNewTranslations = false;

    if (this.applicationInfoService.projectInfo.mediaIds != null) {
      const tempMedia = this.applicationInfoService.projectInfo.mediaIds.split(';');
      tempMedia.forEach(media => {
        this.selectedMedias.push(Number(media));
      })
    }    
    const defaultLanguages = this.applicationInfoService.applicationSettings['consentLanguages'].split(';');

    if (this.applicationInfoService.projectInfo.consentUi != null) {
      this.consentSettings = JSON.parse(this.applicationInfoService.projectInfo.consentUi);            
      defaultLanguages.forEach(languageId => {
        const languageBaseValues = this.applicationInfoService.applicationSettings['consentLanguage' + languageId];
        const languageBaseValuesJson = JSON.parse(languageBaseValues);
        const foundLanguage = this.consentSettings.languages.find(language => language.id == languageId);
        if (!foundLanguage) {
          this.consentSettings.languages.push(JSON.parse(languageBaseValues));  
        } else {
          // Adding missing translations
          languageBaseValuesJson.translations.forEach(translation => {
            const foundTranslation = foundLanguage.translations.find(existingTranslation => existingTranslation.id == translation.id);
            if (!foundTranslation) {
              foundNewTranslations = true;
              console.warn('Adding missing translation', translation.id);
              foundLanguage.translations.push(translation);
            }
          });
        }
      });
    }     
    
    if (this.consentSettings.languages.length == 0 || this.commonService.isNullOrUndefined(this.consentSettings)) {      
      defaultLanguages.forEach(languageId => {
        this.consentSettings.languages.push(JSON.parse(this.applicationInfoService.applicationSettings['consentLanguage' + languageId]));
      }); 
    }

    this.selectedLanguage = this.consentSettings.languages[0];

    if (foundNewTranslations) {
      this.saveConsentSettings();
    }

    this.getMedia();
    this.loadIFrame();
  }

  getMedia() {
    this.externaldatasourceService.getLookupTableValues(16, 2).then(getMediaResult => { this.medias = getMediaResult; })
  }

  removePicture() {
    this.consentSettings['headerPicture'] = null;
    this.saveConsentSettings();
  }

  addMissingTranslations() {

  }

  saveConsentSettings() {
    let mediaIds = this.commonService.createSeperatedStringFromArray(this.selectedMedias, null, ';');
    this.executeExternalDataSource.executeExternalDataSource(260, [this.commonService.getModifyArrayBody(
      { 
        id: this.applicationInfoService.projectID,
        consentUi: JSON.stringify(this.consentSettings),
        mediaIds: mediaIds
      }
      , [])
    ]).then(() => { 
      this.applicationInfoService.projectInfo.consentUi = JSON.stringify(this.applicationInfoService.projectInfo.consentUi);
      this.loadIFrame(); 
    });
    return null;
  }

  uploadPicture(event) {
    this.createBase64String(event);
  }

  createBase64String(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          this.consentSettings['headerPicture'] = e.target.result;
          this.saveConsentSettings();
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);      
    }
  }

  getControlHeight(substractValue = 0) {
    let controlHeight = Number(this.uiService.getDesignSpanPosition('consentDesignSpan', 40));
    return (controlHeight - substractValue) + 'px'; 
  }

  checkIfMediaIsActivated(media) {
    return this.commonService.checkIfItemIsInArray(this.selectedMedias, media.id);
  }

  toggleMedia(media) {
    this.commonService.toggleArrayItem(this.selectedMedias, media.id);
    this.saveConsentSettings();
  }

  loadIFrame() {
    let url = environment.consentPagePreviewUrl.replace('<0>', this.applicationInfoService.projectInfo.projectGuid);
    console.warn(this.applicationInfoService.projectInfo.projectGuid)
    this.iframeSource = this.loadIframeURL(url);
    this.iFrameLoaded = true;
  }  

  loadIframeURL(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);    
  }
}
