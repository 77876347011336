<div class="contentLibraryPage">
    <div class="projectSettings__header" *ngIf="selectedContentLibrary == null">
        <div class="projectSettings__header__newItem fingerCursor" *ngIf="selectedContentLibrary == null &&
            userService.hasPermission('CanCreateContentLibrary')" (click)="prepareCreation()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
            {{ 'ContentLibrary.Label.NewLibrary' | texttransform }}
        </div>

        <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__filter">            
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>
    
    <div class="contentLibrary__wrapper" *ngIf="selectedContentLibrary == null && 
        userService.hasPermission('CanReadSecurityRoleContentLibraryFile') &&
        userService.hasPermission('CanReadContentLibrary')
    ">
        <label class="contentLibraryPage__selectedTopFolderHeader everleadCoral"> {{ 'ContentLibrary.Label.AvailableLibraries' | texttransform }}</label>
        <div *ngFor="let contentLibrary of contentLibraries" (click)="loadContentLibrary(contentLibrary)" 
            [ngClass]="{ 
                fingerCursor: !editMode,
                hideInDom: isItemFiltered(contentLibrary)
        }">
            <div class="everleadShadowBox contentLibrary__item fingerCursor">
                <i class="fas fa-folder"></i>
                <input class="maxWidth fingerCursor" [(ngModel)]="contentLibrary.defaultName" (change)="updateContentLibrary(contentLibrary)" *ngIf="editMode">
                <label class="ellipsisText fingerCursor" *ngIf="!editMode">{{ contentLibrary.defaultName }}</label>
                <!-- <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteContentLibrary(contentLibrary);$event.stopPropagation()"></i> -->
                <i class="fas fa-edit fingerCursor" (click)="editContentLibrary(contentLibrary);$event.stopPropagation()"></i>
            </div>
        </div>
    </div>

    <div class="contentLibrary__wrapper__items" *ngIf="libraryItems != null">
        <!-- <label class="contentLibraryPage__selectedTopFolderHeader everleadCoral" *ngIf="selectedTopFolder != null"> {{ selectedTopFolder.name }}</label> -->
        <app-contentlibrary-item class="maxWidth" [start]="libraryItems" [startiid]="startIId"></app-contentlibrary-item>
    </div>

    <div class="contentLibraryItemPopup" *ngIf="createLibrary || editLibrary">
        <div class="contentLibraryItemPopup__folderName">
            <label class="ellipsisText">{{ 'ContentLibrary.Label.LibraryName' | texttransform }}</label>
            <input id="newFolderNameInput" class="maxWidth" [(ngModel)]="libraryObject.defaultName">            
        </div>
        <div class="contentLibraryItemPopup__buttons">
            <button class="everleadCustomButton" *ngIf="createLibrary" [disabled]="libraryObject.defaultName.length < 3"
                (click)="createContentLibrary()">{{ 'ContentLibrary.Label.CreateLibrary' | texttransform }}</button>
            <button class="everleadCustomButton" *ngIf="editLibrary" (click)="updateContentLibrary(libraryObject)">{{ 'ContentLibrary.Label.UpdateLibrary' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="closeEditWindow()">{{ 'ContentLibrary.Label.Cancel' | texttransform }}</button>
        </div>
    </div>
</div>