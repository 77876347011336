import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { Subscription } from 'rxjs';
import { Dictionary } from 'app/core/dictionary';

@Component({
  selector: 'app-user-rights',
  templateUrl: './user-rights.component.html',
  styleUrls: ['./user-rights.component.scss']
})
export class UserRightsComponent implements OnInit, OnDestroy {
  permissionInfoDictionary = new Dictionary();
  translateTokenPopupConfirmTranslationEventSubscription = new Subscription();
  modules = null;
  users = null;
  user = null;
  userRoles = null;
  userRole = null;
  permissionSets = null;
  permissionSet = null;
  filterString = '';
  permissionSection = null;
  activePermissionArray = null;
  activePermissionArrayInit = null;
  showIds = false;
  createNewPermissionSet = false;
  permissionSetToCreate = null;
  translationSource = 'userRights';
  moduleTypes = [];
  moduleTypeIds = [];
  moduleTypesExpanded = [];
  newTokenProjectId = null;
  projects = [];
  permissionRights = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private uiService: UiService,
    public methodService: MethodService,
    public jjtranslationService: JjtranslationService,
    private commonService: CommonService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loadModules();
    this.loadPermissionSets();
    this.loadUserRoles();
    this.loadUsers();
    this.getProjects();
    this.getPermissionRights();
    

    this.translateTokenPopupConfirmTranslationEventSubscription = this.eventService.
    translateTokenPopupConfirmTranslationEvent.subscribe(event => {
      if (event.callSource === this.translationSource) {
        this.jjtranslationService.addTranslationArrayToItemFromArray(this.permissionSetToCreate, event.callItem,
          event.translations, event.target, event.callType);
      }
    });
  }

  ngOnDestroy() {
    if (this.translateTokenPopupConfirmTranslationEventSubscription) {
      this.translateTokenPopupConfirmTranslationEventSubscription.unsubscribe(); }
  }

  getPermissionRights() { 
    this.externaldatasourceService.executeExternalDataSource(914).then(getPermissionRightsResult => { this.permissionRights = getPermissionRightsResult; })
  }

  loadModules(): Promise<any> {
    return new Promise((loadModulesResolve, loadModulesReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(915)
      .then(loadModulesResult => {
        loadModulesResult.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
        this.moduleTypes = [];
        this.moduleTypeIds = [];
        this.moduleTypes.push({ id: null, value: null });
        this.moduleTypeIds.push(null);
        loadModulesResult.forEach(module => {
          if (!this.commonService.isNullOrUndefined(module.moduleType)) {
            if (!this.commonService.checkIfItemIsInArray(this.moduleTypeIds, module.moduleType.id)) {
              this.moduleTypeIds.push(module.moduleType.id);
              this.moduleTypes.push(module.moduleType);
            }
          }
        });
        this.loaderService.display(false);
        this.modules = loadModulesResult;
        loadModulesResolve(loadModulesResult);
      })
      .catch(error => {
        this.loaderService.display(false);
        loadModulesReject(error);
      });
    });
  }

  getProjects() {
    this.externaldatasourceService.executeExternalDataSource(1, []).then(result => { this.projects = result; });
  }

  loadPermissionSets(): Promise<any> {
    return new Promise((loadPermissionSetsResolve, loadPermissionSetsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(916)
      .then(loadPermissionSetsResult => {
        this.permissionSets = loadPermissionSetsResult;
        loadPermissionSetsResolve(loadPermissionSetsResult);
        this.loaderService.display(false);
      })
      .catch(error => {
        this.loaderService.display(false);
        loadPermissionSetsReject(error);
      });
    });
  }

  loadUserRoles() {
    return new Promise((loadUserRolesResolve, loadUserRolesReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(
        this.applicationInfoService.applicationSettings['roles'])
      .then(loadUserRolesResult => {
        this.userRoles = loadUserRolesResult;
        loadUserRolesResolve(loadUserRolesResult);
        this.loaderService.display(false);
      })
      .catch(error => {
        this.loaderService.display(false);
        loadUserRolesReject(error);
      });
    });
  }

  loadUserRolePermissionSets(userRole) {
    return new Promise((loadUserRolePermissionSetsResolve, loadUserRolePermissionSetsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(
        this.applicationInfoService.applicationSettings['securityRolePermissionSets'], [userRole.id])
      .then(loadUserRolePermissionSetsResult => {
        this.activePermissionArray = [];
        loadUserRolePermissionSetsResult.forEach(permission => {
          if (permission.roleId === userRole.id) {
            this.activePermissionArray.push(permission.permissionSetId);
          }
        });
        this.activePermissionArrayInit = JSON.parse(JSON.stringify(this.activePermissionArray));
        loadUserRolePermissionSetsResolve(loadUserRolePermissionSetsResult);
        this.loaderService.display(false);
      })
      .catch(error => {
        this.loaderService.display(false);
        loadUserRolePermissionSetsReject(error);
      });
    });
  }

  loadUsers() {
    return new Promise((loadUsersResolve, loadUsersReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(7)
      .then(loadUsersResult => {
        this.users = loadUsersResult;
        loadUsersResolve(loadUsersResult);
        this.loaderService.display(false);
      })
      .catch(error => {
        this.loaderService.display(false);
        loadUsersReject(error);
      });
    });
  }

  loadUserPermissionSets(user): Promise<any> {
    return new Promise((loadUserPermissionSetsResolve, loadUserPermissionSetsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(
        this.applicationInfoService.applicationSettings['securityUserPermissionSets'], [user.id])
      .then(loadUserPermissionSetsResult => {
        this.activePermissionArray = [];
        loadUserPermissionSetsResult.forEach(permission => {
          if (permission.userId === user.id) {
            this.activePermissionArray.push(permission.permissionSetId);
          }
        });
        this.activePermissionArrayInit = JSON.parse(JSON.stringify(this.activePermissionArray));
        loadUserPermissionSetsResolve(loadUserPermissionSetsResult);
        this.loaderService.display(false);
      })
      .catch(error => {
        this.loaderService.display(false);
        loadUserPermissionSetsReject(error);
      });
    });
  }

  clearSelections() {
    this.userRole = null;
    this.permissionSet = null;
    this.user = null;
    this.activePermissionArray = null;
    this.activePermissionArrayInit = null;
    if (this.permissionSection == 1) { this.loadModules(); }
  }

  getMaxHeight() {
    return this.uiService.getDesignSpanPosition('userPermissionDesign', 60);
  }

  setSelectedPermissions(set) {
    this.loadModules().then(() => {
      this.activePermissionArray = [];
      set.permissionSetPermissions.forEach(permission => {
        this.activePermissionArray.push(permission.permissionId);
      });
      this.activePermissionArrayInit = JSON.parse(JSON.stringify(this.activePermissionArray));  
    });
  }

  permissionIdIsActivated(permission) {
    return this.commonService.checkIfItemIsInArray(this.activePermissionArray, permission);
  }

  changeActivatedPermission(permission) {
    this.commonService.toggleArrayItem(this.activePermissionArray, permission);
    let targetId = null;
    let insertExternalDataSourceId = null;
    let deleteExternalDataSourceId = null;

    switch (this.permissionSection) {
      case '1':
        insertExternalDataSourceId = this.applicationInfoService.applicationSettings['userPermissionSetInsertPermission'];
        deleteExternalDataSourceId = this.applicationInfoService.applicationSettings['userPermissionSetDeletePermission'];
        targetId = this.permissionSet.id;
      break;
      case '2':
        insertExternalDataSourceId = this.applicationInfoService.applicationSettings['createSecurityRolePermissionSetMutation'];
        deleteExternalDataSourceId = this.applicationInfoService.applicationSettings['deleteSecurityRolePermissionSetMutation'];
        targetId = this.userRole.id;
      break;
      case '3':
        insertExternalDataSourceId = this.applicationInfoService.applicationSettings['createSecurityUserPermissionSetMutation'];
        deleteExternalDataSourceId = this.applicationInfoService.applicationSettings['deleteSecurityUserPermissionSetMutation'];
        targetId = this.user.id;
      break;      
    }
    if (this.commonService.checkIfItemIsInArray(this.activePermissionArray, permission)) {
      this.externaldatasourceService.executeExternalDataSource(insertExternalDataSourceId, [targetId, permission]);
    } else {
      this.externaldatasourceService.executeExternalDataSource(deleteExternalDataSourceId, [targetId, permission]);
    }
  }

  itemIsFiltered(module) {
    let returnValue = true;
    const moduleName = this.axivasTranslateService.getTranslationTextForToken(this.commonService.getNameTranslationOrDefaultName(module));
    if (this.commonService.checkIfStringContainsString(moduleName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  newPermissionSet() {
    this.permissionSetToCreate = new Object();
    this.permissionSetToCreate['nameTranslation'] = [];
    this.createNewPermissionSet = true;
  }

  saveNewPermissionSet() {
    const newLeadStateTranslationPromiseArray: Promise<any>[] = [];
    this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(newLeadStateTranslationPromiseArray, this.permissionSetToCreate,
        'nameTranslationToken', null, 'nameTranslationTokenId', this.permissionSetToCreate.nameTranslationToken, 'permissionSet');
    Promise.all(newLeadStateTranslationPromiseArray)
    .then(() => {
      this.externaldatasourceService.executeExternalDataSource(
        this.applicationInfoService.applicationSettings['createSecurityPermissionSetMutation'],
          [ this.permissionSetToCreate.nameTranslationTokenId, this.newTokenProjectId])
        .then(() => {
          this.axivasTranslateService.updateTranslationCache().then(() => {
            this.axivasTranslateService.getTranslationIds();
          });
          this.messagingService.showDefaultSuccess('', 'PermissionSet erstellt', false);
          this.loadPermissionSets();
          this.createNewPermissionSet = false;
        });
    });
  }

  getModulePermission(module, permissionRight) {
    let permission = module.permissions.find(permission => permission.permissionRightId == permissionRight.id)
    if (permission) {
      return permission;
    } else {
      return null;
    }
  }
}
