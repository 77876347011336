import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-admin-entity-validity',
  templateUrl: './admin-entity-validity.component.html',
  styleUrls: ['./admin-entity-validity.component.scss']
})
export class AdminEntityValidityComponent implements OnInit {
  @Input() member;
  validity = null;
  loading = true;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {    
    this.member = this.applicationInfoService.miscSettings['memberValidityMember'];
    this.getValidity();
  }

  getValidity() {
    if (this.member.id != null || this.member.id != null) {
      this.externaldatasourceService.executeExternalDataSource(514, [this.member.id])
      .then(result => {
        if (result != null) {
          this.validity = result[0];
        }             
        this.loading = false;
      });
    }
  }

  createEntityValidity() {
    this.externaldatasourceService.executeExternalDataSource(511, [this.member.defaultName, this.member.id])
    .then(result => {
      
        this.validity = result;
         
    });
  }

  saveValidity() {
    this.externaldatasourceService.executeExternalDataSource(512, [
      this.commonService.getModifyArrayBody(this.validity, [])
    ])
    .then(result => {
      this.validity = result;
    });
  }
}
