import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-websitedashboard',
  templateUrl: './websitedashboard.component.html',
  styleUrls: ['./websitedashboard.component.scss']
})
export class WebsitedashboardComponent implements OnInit {
  events = [];
  myFollowUps = [];
  innerHTMLContent = ` <jj-container controluiid="189739"> </jj-container>`;
  innerHTML = null;

  constructor(
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private methodService: MethodService,
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService

  ) { }

  ngOnInit(): void {    
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.trackingService.trackPageView();
  }
}
