<div [ngStyle]="setControlStyle('control')" class="resultPreviewContainer">
  <div class="resultPreviewInnerHTML">
    <div *ngIf="previewBookingTask != null" class="bookingFormBookingPreview  everleadShadowBox everleadShadowBox2">
      <label class="bookingFormBookingPreview__header">{{ 'TaskBooking.Preview.Header' | texttransform }}:</label>
      <div *ngIf="selectedResult?.emailTemplateId != null" class="bookingFormBookingPreview__email">
        <i class="fas fa-envelope fingerCursor" [tippy]="'Timeline.Label.ShowEmail' | texttransform" 
          (click)="showMail()"></i>
          <span>{{ 'BookingPreview.Label.SendingEMail' | texttransform }}</span>
      </div>
      <div *ngIf="previewBookingTask.HasFollowUpTask == true">
        <span>{{'BookingPreview.Label.FollowupTaskWillbeCreated' | texttransform}}: </span>
        <span class="bookingFormBookingPreview__nextStep">{{ nextStep?.defaultName }}</span>
      </div>
      <div *ngIf="previewBookingTask.HasFollowUpTask == false">
        <span>{{ 'BookingPreview.Label.ChainEndsHere' | texttransform }}</span>
      </div>
      <div *ngIf="previewBookingTask.LeadStatus != null">
        <span>{{ 'BookingPreview.Label.Leadstatus' | texttransform }}: </span>
        <span class="bookingFormBookingPreview__leadstate">{{ previewBookingTask.LeadStatus }}</span>
      </div>  
      <div *ngIf="selectedResult?.qmUserGroupId != null">
        <span>{{ 'BookingPreview.Label.QMRequired' | texttransform }}</span>
      </div>
      <div *ngIf="applicationInfoService.entities.Item(controlDefinition.uiEntityInstanceId).data.isNotifyFollowUpOwner == true">
        <span>{{ 'BookingPreview.Label.NotifyOwnerByEMail' | texttransform }}</span>
      </div>
    </div>
  </div>
</div>
