import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { WizardMode, WizardService } from './wizard.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';


@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy {

  constructor(
    public wizardService: WizardService,
    public domSanitizer: DomSanitizer,
    private uiService: UiService,
    public applicationInfoService: ApplicationInfoService,
  ) { }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.wizardService.wizardActive = false;
  }
  
  previousPage() {
    this.wizardService.additionalInformation = '';
    this.changeToPage(this.wizardService.wizardPage - 1);
  }

  nextPage() {
    if (this.wizardService.nextDisabled) {
      return;
    }
    this.changeToPage(this.wizardService.wizardPage + 1);
  }

  changeToPage(pageNumber, fromToolbar = false) {
   
    if (this.wizardService.nextDisabled || (fromToolbar && this.wizardService.toolbarDisabled)) {
      return;
    }
    this.wizardService.wizardPage = pageNumber;
    this.wizardService.addCurrentPageToVisitedPages();
  }


  isPageAvailable(pageId) {
    let returnValue = false;

    if (pageId < (this.wizardService.wizardPage - 1) || pageId > (this.wizardService.wizardPage + 1)) {
      returnValue = false;
    } else {
      returnValue = true;
    }

    if (this.wizardService.wizardPage == 1 && pageId == 3) {
      return true;
    }

    if (pageId == this.getRealPageAmount() -2 && this.wizardService.wizardPage >= this.getRealPageAmount() - 1) {
      return true;
    }

    return returnValue;
  }

  checkForInvisibleTabsBeforeCurrentTabs() {
    if (this.wizardService.wizardPage > 2) {
      return true;
    } else {
      return false;
    }
  }

  isPagePassed(pageId) {
    if (pageId < this.wizardService.wizardPage) {
      return true;
    } else {
      return false;
    }
  }

  isPageComing(pageId) {
    if (pageId > this.wizardService.wizardPage + 1) {
      return true;
    } else {
      return false;
    }
  }

  getRealPageAmount() {
    let realPageAmount = 0;
    this.wizardService.pages.forEach(page => {
      if (page.optional == false) {
        realPageAmount ++;
      } else {
        if (this.wizardService.showOptionalPages) {
          realPageAmount ++;
        }
      }
    });
    return realPageAmount;
  }

  getPageWidth(pageId) {
    const defaultControlWidth = 90;
    if (this.isPageAvailable(pageId)) {
      let realPageAmount = this.getRealPageAmount();
      switch (realPageAmount) {
        case 1:
          return '100%';
        case 2:
          return '50%';
        case 3:
          return '33%';
        default:
          let calcWidth = (realPageAmount - 3) * defaultControlWidth;
          calcWidth = calcWidth / 3;
          calcWidth = (this.uiService.getDomControlWidth('wizardCore__header__container', 0) / 3) - (calcWidth / 2);
          return calcWidth + 'px';
      }
    }    
  }

  checkIfPageIsOptional(page) {

    if(this.wizardService.wizardArray.duplicateTemplateId){
      return true
    }

    if (this.wizardService.pages[page].optional == false) {
      return false;
    } else {
      if (this.wizardService.showOptionalPages) {
        return false;
      } else {
        return true;
      }
    }

  }

  showSaveButton() {
    let returnValue = true;
    const mode = this.wizardService.modeInfo.find(mode => mode.modeId == this.wizardService.wizardMode);
    if (mode.showSave == false) {
      returnValue = false;
    }
    return returnValue;
  }

  checkIfNextIsBlocked() {
    const currentPage = this.wizardService.pages[this.wizardService.wizardPage - 1];
    let returnValue = false;    
    switch(this.wizardService.wizardMode) {
      case WizardMode.Mailshot:
        if (
          this.wizardService.wizardArray.campaignId == null || 
          currentPage.pageId == 1 && this.wizardService.wizardArray.taskCreationGuid == '' ||
          currentPage.pageId == 1 && this.wizardService.wizardArray.campaignId == null ||
          currentPage.pageId == 1 && this.wizardService.wizardArray.campaignId == -1 ||
          currentPage.pageId == 3 && this.wizardService.wizardArray.stepId == null          
        ) { returnValue = true; }
        break;
      case WizardMode.EventAssignment:
        returnValue = true;
    }        
    return returnValue;
  }

  checkIfFooterIsHidden() {
    let returnValue = false;
    if (this.wizardService.wizardPage == 4 && this.wizardService.wizardMode == WizardMode.MailToContact) { returnValue = true; }
    return returnValue;
  }
}
