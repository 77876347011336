import { Injectable, Injector, OnInit, EventEmitter } from '@angular/core';
import { SignalRBaseClass } from './signalrbaseclass';
import { environment } from '../../../environments/environment';
import { Dictionary } from 'app/core/dictionary';
import { MessagingService, MessageSeverity, MessagePosition } from '../service/messaging.service';
import { EventService } from '../service/event.service';
import { GraphqlqueryService } from '../service/graphqlquery.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { timer, Subscription } from 'rxjs';
import { ExternaldatasourceService } from '../service/externaldatasource.service';

export enum PopupMode {
  Call = 1,
  Consult = 2
}


@Injectable({
  providedIn: 'root'
})
export class MiddlewareSignalrService extends SignalRBaseClass implements OnInit {
  responseTimerSubscription: Subscription = new Subscription;
  public OnInitialInfoEvent: EventEmitter<any> = new EventEmitter();
  public connectionStateEvent: EventEmitter<any> = new EventEmitter<any>();
  public timeRecordingConnectionError = false;
  public connectionStatus: any = null;
  public availStatus: any = null;
  public activities = null;
  public currentActivity = null;
  public currentlyReconnecting = false;
  public onTelStatusTime = null;

  constructor(
    private injector: Injector,
    private messagingService: MessagingService ,
    private eventService: EventService,
    private graphqlqueryService: GraphqlqueryService,
    private graphQLService: GraphQLService,
    private axivasTranslateService: AxivasTranslateService,
    private externaldatasourceService: ExternaldatasourceService
  ) {
    super(injector);
    this.signalRUrl = environment.signalRUrlMiddleware;
    this.signalRName = 'Middleware';
  }



  InitService(): Promise<any> {
    return new Promise((intiServiceResolve, intiServiceReject) => {
      this.InitializeDefaultConnection().then(data => {
        console.warn('InitService:', this.signalRName, '=> Init success', data);
        this.AddHubConnectionMethods();
        this.InvokeLogin().then(invokeResult => {
          this.startPingTimer();
          intiServiceResolve(invokeResult);
          this.getActivities();
        })
        .catch(error => {
          intiServiceReject('invoke ' + error);
        });        
      })
      .catch(error => {
        console.log('InitService:', this.signalRName, '=> Init error', error);
        intiServiceReject('init ' + error);
      });
    });
  }

  AddHubConnectionMethods() {
    this.hubConnection.on('OnTelStatus', (data: any) => {
      this.onTelStatusTime = Date.now();
      this.connectionStatus = data;
      this.applicationInfoService.webPhoneConnected = true;
      this.applicationInfoService.showReconnectOption = false;
      console.warn('OnTelStatus', data);
    });

    this.hubConnection.on('OnAvailStatus', (data: any) => {
      console.warn('OnAvailStatus', data);
    });

    this.hubConnection.on('OnStatusUpdate', (data: any) => {
      console.warn('OnStatusUpdate', data);
    });

    this.hubConnection.on('OnMakeCallError', (data: any) => {
      console.warn('OnMakeCallError', data);
    });

    this.hubConnection.on('OnAlert', (data: any) => {
      console.warn('OnAlert', data);
    });

    this.hubConnection.on('OnMiddlewareReconnect', (data: any) => {
      this.applicationInfoService.timeRecordingConnected = true;
      this.getCurrentTimeRecordingStatus();
      this.timeRecordingConnectionError = false;
      console.warn('OnMiddlewareReconnect', data);
    });

    this.hubConnection.on('OnCurrentAvailStatus', (data: any) => {
      this.availStatus = data;
      this.applicationInfoService.timeRecordingConnected = true;
      this.getCurrentTimeRecordingStatus();
      console.warn('OnCurrentAvailStatus', data);
    });

    this.hubConnection.on('OnActivities', (data: any) => {
      console.warn('OnActivities', data);
    });

    this.hubConnection.on('OnInitialInfo', (data: any) => {
      console.warn('OnInitialInfo', data);
    });

    this.hubConnection.on('OnPong', (data: any) => {
      if (data.description === 'errorCode: away_mode') {
        console.error(data.description);
      } else {
        console.warn('OnPong', data);
      }
    });

    this.hubConnection.on('OnLogon', (data: any) => {
      console.warn('OnLogon', data);
    });

    this.hubConnection.on('OnLogonError', (data: any) => {
      this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error, '', data.descriptionTokenName, true);
      console.warn('OnLogonError', data);
    });

    this.hubConnection.on('OnLogout', (data: any) => {
      this.applicationInfoService.timeRecordingConnected = false;
      console.warn('OnLogout', data);
    });

    this.hubConnection.on('OnTelConnectionError', (data: any) => {
      console.warn('OnTelConnectionError', data);
    });

    this.hubConnection.on('OnError', (data: any) => {
      if (data.type === 1) {
        this.messagingService.showDefaultWarning('Dialing',
          this.axivasTranslateService.getTranslationTextForToken(data.descriptionTokenName), false);
      }
      if (data.type === 5) {
        this.timeRecordingConnectionError = true;
      }
      if (data.type === 4) {
        this.connectionStatus = null;
        this.timeRecordingConnectionError = true;
        this.applicationInfoService.timeRecordingConnected = false;
      }
      console.warn('OnError', data);
    });

    this.hubConnection.on('OnTelReconnect', (data: any) => {
      console.warn('OnTelReconnect', data);
    });

    this.hubConnection.on('OnConnectionEvent', (data: any) => {
      console.log('OnConnectionEvent', data);
    });

    this.hubConnection.on('OnOtherClientLoggedIn', (data: any) => {
      this.closeConnection();
      console.warn('OnOtherClientLoggedIn', data);
    });

    this.hubConnection.on('OnStatusChanged', (data: any) => {
      this.getCurrentTimeRecordingStatus();
      console.warn('OnStatusChanged', data);
    });

    this.hubConnection.on('OnStatusChange', (data: any) => {
      if (!this.applicationInfoService.useNewDesign) {
        this.applicationInfoService.currentTimeRecordingStatus = data;
      } else {
        this.getCurrentTimeRecordingStatus();
      }
      // this.getCurrentTimeRecordingStatus();
      console.warn('OnStatusChange', data);
    });

    this.hubConnection.onclose(onCloseData => {
      console.warn('onclose', onCloseData);
      this.reconnectSoftphoneApi();
      // this.closeConnection();
    });

    console.log('AddHubConnectionMethods done');
  }

  closeConnection() {
    this.applicationInfoService.showReconnectOption = true;
    this.applicationInfoService.timeRecordingConnected = false;
    this.applicationInfoService.webPhoneConnected = false;
    this.stopPingTimer();
  }

  ngOnInit() {
  }

  sendKey(key: string) {
    this.hubConnection.invoke('SendKey', key).catch(err => console.error(err.toString()));
  }

  ping() {
    this.hubConnection.invoke('sendPing').catch(err => console.error(err.toString()));
  }

  volumeUp() {
    this.hubConnection.invoke('VolumeUp').catch(err => console.error(err.toString()));
  }

  invoke(invokeString: string) {
    this.hubConnection.invoke(invokeString).catch(err => console.error(err.toString()));
  }

  timeRecordingChangeStatus() {
    this.hubConnection.invoke('TimeRecordingChangeStatus', 2, 243).catch(err => console.error(err.toString()));
  }

  consult (targetNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hubConnection.invoke('Consult', targetNumber)
      .then(() => { resolve(null); })
      .catch(() => reject());
    });
  }

  isActiveCall(): boolean {
    if (isNullOrUndefined(this.connectionStatus)) {
      return false;
    }
    if (isNullOrUndefined(this.connectionStatus.connectionStatus)) {
      return false;
    }
    if (this.connectionStatus.connectionStatus !== 'Disconnected') {
       return true;
    } else {
      return false;
    }
  }

  pickupcall() {
    return new Promise((resolve, reject) => {
      this.hubConnection.invoke('PickUpCall')
      .then(success => { console.log(success); resolve(null); })
      .catch(error => { console.error(error); reject(); });
    });
  }

  conference() {
    return new Promise((resolve, reject) => {
      this.hubConnection.invoke('Conference')
      .then(success => { console.log(success); resolve(null); })
      .catch(error => { console.error(error); reject(); });
    });
  }

  startRecord() {
    this.hubConnection.invoke('Record')
    .then(result => { console.warn('Record', result); })
    .catch(err => console.error(err.toString()));
  }

  stopRecord() {
    this.hubConnection.invoke('StopRecord').catch(err => console.error(err.toString()));
  }

  toggleHeadset() {
    this.hubConnection.invoke('ToggleHeadset').catch(err => console.error(err.toString()));
  }

  toggleSpeaker() {
    this.hubConnection.invoke('ToggleSpeaker').catch(err => console.error(err.toString()));
  }

  discardRecord() {
    this.hubConnection.invoke('DiscardRecord').catch(err => console.error(err.toString()));
  }

  resume() {
    this.hubConnection.invoke('ResumeCall').catch(err => console.error(err.toString()));
  }

  transfer() {
    console.warn('transfer');
    this.hubConnection.invoke('TransferCall')
    .then(data => console.warn(data))
    .catch(err => console.error(err.toString()));
  }

  volumeDown() {
    this.hubConnection.invoke('VolumeDown').catch(err => console.error(err.toString()));
  }

  call(targetNumber: string, checkIfOptOutExists = false, entityId = null, memberId = null): Promise<any> {
    return new Promise((callResolve, callReject) => {
      const poolId = this.applicationInfoService.projectID;
      const projectId = this.applicationInfoService.projectID;
      const timeRecordingProjectId = this.applicationInfoService.getTimeRecordingProjectId();

      let accountId = 0;
      let contactId = 0;
      let taskId = 0;
      let campaignId = this.applicationInfoService.projectInfo.defaultCampaignId;
      let agentMaskingSpecialNumber = '';


      if (!isNullOrUndefined(this.applicationInfoService.currentTask)) {
        accountId = this.applicationInfoService.currentTask.account != null ? this.applicationInfoService.currentTask.account.id : 0;
        contactId = this.applicationInfoService.currentTask.contact != null ? this.applicationInfoService.currentTask.contact.id : 0;
        campaignId = this.applicationInfoService.currentTask.campaignId;
        taskId = this.applicationInfoService.currentTask.id;
      }
      if (this.applicationInfoService.projectInfo.agentMaskingType === 2) {
        agentMaskingSpecialNumber = this.applicationInfoService.projectInfo.agentMaskingSpecialNumber;
      }

      if (checkIfOptOutExists) {
        console.warn('checkIfOptOutExists');
        this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Query,
          this.graphqlqueryService.getQuery('makeCallIsContactAllowedDueOptOut'), [
            accountId = 0 ? null : accountId,
            contactId = 0 ? null : contactId
          ])
        .then(makeCallIsContactAllowedDueOptOutResult => {
          if (makeCallIsContactAllowedDueOptOutResult.data.isContactAllowedDueOptOut) {
            this.invokeDial(targetNumber, poolId, projectId, accountId, contactId, taskId
              , agentMaskingSpecialNumber, campaignId, timeRecordingProjectId)
            .then(() => {
              this.logCall(entityId, memberId, targetNumber, campaignId);
              callResolve(null);
            })
            .catch(err => {
              console.error(err.toString());
              callReject();
            });
          } else {
            this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Error,
              'MakeCall',
              this.axivasTranslateService.getTranslationTextForToken('MakeCall.Message.CantMakeCallDueToOptOut'),
              false
            );
          }
        });
      } else {
        this.invokeDial(targetNumber, poolId, projectId, accountId, contactId, taskId
          , agentMaskingSpecialNumber, campaignId, timeRecordingProjectId)
        .then(() => {
          this.logCall(entityId, memberId, targetNumber, campaignId);
          callResolve(null);
        })
        .catch(err => {
          console.error(err.toString());
          callReject();
        });
      }
    });
  }

  logCall(entityId, memberId, callNumber, campaignId) {
    if (entityId === null || memberId === null) {
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(290, [entityId, memberId, callNumber, new Date().toUTCString(), campaignId])
    .then(() => {
      console.log('logCall done');
    });
  }

  invokeDial(targetNumber, poolId, projectId, accountId, contactId, taskId
    , agentMaskingSpecialNumber, campaignId: any, timeRecordingProjectId): Promise<any> {
    return new Promise((invokeDialResolve, invokeDialReject) => {
      console.warn('Calling number => ', targetNumber, poolId, projectId, accountId, contactId,
       taskId, campaignId, ' - ',  timeRecordingProjectId, ' - ', agentMaskingSpecialNumber);
      this.hubConnection.invoke('Dial', targetNumber, poolId, projectId, accountId, contactId,
        taskId, campaignId, timeRecordingProjectId, agentMaskingSpecialNumber)
      .then(() => invokeDialResolve(null))
      .catch(err => {
        console.error(err.toString());
        invokeDialReject();
      });
    });
  }

  hangup() {
    this.hubConnection.invoke('Hangup').then(() => {
      console.log('...hangup successfull');
    })
    .catch(error => {
      console.error('...hangup failed', error);
    });
  }

  hold() {
    this.hubConnection.invoke('HoldCall').then(() => {
      console.log('...hold successfull');
    })
    .catch(error => {
      console.error('...hold failed', error);
    });
  }

  getHistory(): Promise<any> {
    return new Promise((getHistoryResolve, getHistoryReject) => {
      this.hubConnection.invoke('GetCallHistory').then(callHistory => {
        getHistoryResolve(callHistory);
      })
      .catch(error => {
        console.error('...get phonebook', error);
        getHistoryReject(error);
      });
    });
  }

  getPhonebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hubConnection.invoke('GetPhonebook').then(book => {
        resolve(book);
      })
      .catch(error => {
        console.error('...get phonebook', error);
        reject(error);
      });
    });
  }

  mute() {
    return new Promise((resolve, reject) => {
      this.hubConnection.invoke('ToggleMute')
      .catch(error => { reject(error); });
    });
  }

  getActivities(): Promise<any> {
    // console.warn('getActivities start');
    this.applicationInfoService.availableUserActivities = [];
    return new Promise((getActivitiesResolve, getActivitiesReject) => {
      if (isNullOrUndefined(this.applicationInfoService.getTimeRecordingProjectId())) {
        getActivitiesReject();
        return;
      }
      this.activities = [];
      this.hubConnection.invoke('GetActivities', this.applicationInfoService.getTimeRecordingProjectId())
      .then(result => {
        // console.warn('getActivities result', result, this.applicationInfoService.projectInfo);
        if (result != null) {
          this.applicationInfoService.availableActivities = new Dictionary();
          result.forEach(element => {
            element.translationTokenFullName = this.getElementFullTranslationToken(element);
            this.applicationInfoService.availableActivities.Add(element.activityId.toString(), element);
          });
          this.applicationInfoService.availableUserActivities = result;
          this.eventService.activitiesUpdatedEvent.emit();
          this.activities = result;
          getActivitiesResolve(result);
        } else {
          getActivitiesReject('no activites found');
        }
      })
      .catch(error => {
        this.applicationInfoService.timeRecordingConnected = false;
        // console.log('getActivities error: ', error);
        getActivitiesReject(error);
      });
    });
  }

  getElementFullTranslationToken(element: any): string {
    if (element != null) {
      if (!isNullOrUndefined(element.translationToken)) {
        return element.translationToken.group +
        (element.translationToken.subgroup != null ? '.' + element.translationToken.subgroup : '') +
        (element.translationToken.field != null ? '.' + element.translationToken.field : '') +
        (element.translationToken.tokenName != null ? '.' + element.translationToken.tokenName : '');
      }
    } else {
      console.warn('getElementFullTranslationToken - no element');
    }
  }

  getCurrentTimeRecordingStatus() {
      this.hubConnection.invoke('GetCurrentStatus').then((data => {
        console.warn('getCurrentTimeRecordingStatus', data);
        this.applicationInfoService.timeRecordingConnected = true;
        this.timeRecordingConnectionError = true;
        if (data != null) {
          if (data.currentActivity != null) {
            if (!this.applicationInfoService.useNewDesign) {
              data.currentActivity.translationTokenFullName = this.getElementFullTranslationToken(data.currentActivity);
              this.applicationInfoService.currentTimeRecordingStatus = data;
            }
          } else {
            if (this.activities !== null) {
              this.activities.forEach(activity => {
                if (activity.activityId === data.activityId) {
                  this.applicationInfoService.currentTimeRecordingStatus = activity;
                }
              });
            }
          }
        } else {
          this.applicationInfoService.currentTimeRecordingStatus = null;
        }
      }))
      .catch(error => {
        console.error('getCurrentActivity catch', error);
        this.applicationInfoService.currentTimeRecordingStatus = null;
      });
  }

  logout() {
    this.hubConnection.invoke('Logout')
    .then(returnValue => {
      console.warn('logout returnValue', returnValue);
      // this.hubConnection.stop();
      this.connectionStatus = null;
      this.applicationInfoService.timeRecordingConnected = false;
      console.warn('Logged out');
    })
    .catch(error => {
      console.error('Timerecording logout', error);
    });
  }

  startPingTimer() {
    const responseTimer = timer(1000, 30000);
      this.responseTimerSubscription = responseTimer.subscribe(() => {
        this.ping();
    });
  }

  stopPingTimer() {
    if (this.responseTimerSubscription) {
      this.responseTimerSubscription.unsubscribe();
    }
  }

  bookActivity() {
    const activity = this.applicationInfoService.currentTimeRecordingStatus;
    this.hubConnection.invoke(
      'TimeRecordingChangeStatus',
      activity.isUserAvailability,
      activity.isQueueAvailability
    );
  }

  reconnectSoftphoneApi() {
    this.currentlyReconnecting = true;
    this.InitService()
    .then(() => {
      console.log('reconnectSoftphoneApi success');
      this.getActivities();
      this.getCurrentTimeRecordingStatus();
      this.currentlyReconnecting = false;
    })
    .catch(error => {
      this.currentlyReconnecting = false;
      console.error('reconnectSoftphoneApi error', error);
    });
  }
}
