import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-emailbatchdetails',
  templateUrl: './emailbatchdetails.component.html',
  styleUrls: ['./emailbatchdetails.component.scss']
})
export class EmailbatchdetailsComponent implements OnInit {
  loaded = false;
  eventBookingBatch = null;
  mailShotDetailsContainerId = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['eventBookingBatch'])) {
      this.eventBookingBatch = this.applicationInfoService.miscSettings['eventBookingBatch'];
      this.mailShotDetailsContainerId = this.applicationInfoService.applicationSettings['mailShotDetailsContainerId']
    }
    this.loaded = true;
  }

}
