import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AdminJobsService } from '../admin-jobs.service';

@Component({
  selector: 'app-admin-jobs-schedule',
  templateUrl: './admin-jobs-schedule.component.html',
  styleUrls: ['./admin-jobs-schedule.component.scss']
})
export class AdminJobsScheduleComponent implements OnInit {
  filterString = '';
  showOnlyActive = true;

  constructor(
    private commonService: CommonService,
    public adminJobsService: AdminJobsService
  ) { }

  ngOnInit() {
    if (this.adminJobsService.jobs.length == 0) { this.adminJobsService.getJobs(); }    
    if (this.adminJobsService.jobSchedules.length == 0) { this.adminJobsService.getSchedules(); }    
  }

  createSchedule() {
    const newSchedule = new Object();
    newSchedule['jobId'] = this.adminJobsService.jobs[0].id;
    newSchedule['isActive'] = true;
    newSchedule['defaultName'] = 'NEW SCHEDULE';
    this.adminJobsService.createSchedule(newSchedule).then(createScheduleResult => {
      this.adminJobsService.jobSchedules.unshift(createScheduleResult);
    })    
  }

  isFiltered(schedule) {
    return !this.commonService.checkIfStringContainsString(schedule.defaultName, this.filterString);
  }
}
