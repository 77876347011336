import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-custom-dashoardcontrol',
  templateUrl: './custom-dashoardcontrol.component.html',
  styleUrls: ['./custom-dashoardcontrol.component.scss']
})
export class CustomDashoardcontrolComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  jsonLayout = null;
  jsonSettings = null;
  dashboard = null;
  designMode = false;
  dashboardData = null;
  dataLoaded = false;
  toggledItems = [];
  userGroupInt = null;

  constructor(private injector: Injector ,
    ) {
      super(injector);
      this.valueType = ValueType.Nothing;
    }
  
    ngOnDestroy(): void {
      if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    }

    ngOnInit() {
      this.getDashboardInfo();  
      this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
        if (event == 'getCompleteProjectInformaton') {
          this.getDashboardInfo();
        }        
      });
    }
  
    getDashboardInfo() {
      this.dashboard = this.applicationInfoService.customDashboards.find(dashboard => dashboard.logicalUiControlId == this.controlDefinition.logicalControlId);
      if (this.dashboard) {
        if (this.commonService.isNullOrUndefined(this.dashboard.jsonLayout)) {
          this.jsonLayout = [];
        } else {
          this.jsonLayout = JSON.parse(this.dashboard.jsonLayout);
        }

        if (this.commonService.isNullOrUndefined(this.dashboard.jsonSettings)) {

        } else {
          this.jsonSettings = JSON.parse(this.dashboard.jsonSettings);
        }
        this.getData();
      } else {
        this.dashboard = {
          jsonLayout: [],
          jsonSettings: {}
        };
        this.jsonSettings = {};
        this.jsonLayout = [];
      }
    }

    addItem() {
      const item = {
        guid: this.commonService.createGuid(),
        sql: '',
        dasbboardTitle: '',
        dashboardType: 0,
        dasbboardXTitle: '',
        dasbboardYTitle: ''        
      }
      this.jsonLayout.push(item);
    }

    showDesignMode() {
      this.designMode = !this.designMode;
    }

    createDashboard() {
      this.dashboard.jsonLayout = JSON.stringify(this.jsonLayout);
      this.dashboard.jsonSettings = JSON.stringify(this.jsonSettings);
      this.dashboard.isActive = true;
      this.dashboard.projectId = this.applicationInfoService.projectID;
      this.dashboard.guid = this.commonService.createGuid();
      this.dashboard.name = 'Harald';
      this.dashboard.logicalUiControlId = this.controlDefinition.logicalControlId;
      this.externalDatasourceService.executeExternalDataSource(744, [
        this.commonService.getModifyArrayBody(this.dashboard, [])
      ]).then(createDashboardResult => {
        this.dashboard.id = createDashboardResult.id;
      });
    }

    saveDashboard() {
      if (this.commonService.isNullOrUndefined(this.dashboard.id)) {
        this.createDashboard();
      } else {
        this.dashboard.jsonLayout = JSON.stringify(this.jsonLayout);
        this.dashboard.jsonSettings = JSON.stringify(this.jsonSettings);
        this.externalDatasourceService.executeExternalDataSource(745, [
          this.commonService.getModifyArrayBody(this.dashboard, [])
        ]).then(() => {});  
      }
    }

    getUserGroupInt(): Promise<any> {
      return new Promise((getUserGroupIntResolve, getUserGroupIntReject) => {
        if (this.userGroupInt != null) {
          getUserGroupIntResolve(this.userGroupInt);
        } else {
          this.externalDatasourceService.executeExternalDataSource(603).then(getUserGroupIntResult => {
            this.userGroupInt = getUserGroupIntResult;
            getUserGroupIntResolve(getUserGroupIntResult);
          });    
        }
      })
    }

    createParams() {
      const params = new Object();
      params['_project_id'] = this.applicationInfoService.projectID;
      params['_user_email'] = this.applicationInfoService.user.email;
      params['_language_id'] = 2;
      params['_session_group_id'] = this.userGroupInt;
      params['_pool_id'] = this.applicationInfoService.poolId;
      params['_user_id'] = this.applicationInfoService.userID;
      if (this.applicationInfoService.campaingnModeId == null) {
        params['_campaign_id'] = 0;
      } else {
        params['_campaign_id'] = this.applicationInfoService.campaingnModeId;
      }      
      return params;        
    }

    remoteDashboard(jsonLayoutItem) {
      this.commonService.removeItemFromArray(this.jsonLayout, jsonLayoutItem);
    }

    getData() {
      this.dashboardData = [];
      this.dataLoaded = false;
      this.getUserGroupInt().then(() => {
        const queryData = {
          id: this.dashboard.id,
          params: JSON.stringify(this.createParams())
        }      
        this.externalDatasourceService.executeExternalDataSource(772, [
          this.commonService.getModifyArrayBody(queryData, [])
        ]).then(getDataResult => {
          let dataSet = 0;
          getDataResult.forEach(getDataResultItem => {          
            this.dashboardData[dataSet] = [];
            getDataResultItem.data.forEach(dataItem => {
              this.dashboardData[dataSet].push(JSON.parse(dataItem));
            });
            dataSet ++;
          })
          this.dataLoaded = true;
        }).catch(error => console.error(error));  
      })
    }

    refreshView() {
      this.dataLoaded = false;
      setTimeout(() => { 
        this.getData(); 
      }, 50);
    }

    getDashboardWidth(jsonLayoutItem) {
      if (  jsonLayoutItem.width == '' || 
            this.commonService.isNullOrUndefined(jsonLayoutItem.width) || 
            this.applicationInfoService.isMobile()) {
        return '100%';
      } else {
        return jsonLayoutItem.width;
      }
    }

    moveInList(event: CdkDragDrop<string[]>, item: any) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
    }  

    toggleItem(jsonLayoutItem) {      
      if (this.toggledItems[jsonLayoutItem.guid] == true)  {
        this.toggledItems[jsonLayoutItem.guid] = false;
      } else {
        this.toggledItems[jsonLayoutItem.guid] = true;
      }  
    }

    getControlHeight(control, substractValue = 40) {
      let tableSize = Number(this.uiService.getDesignSpanPosition(control, substractValue));
      return tableSize; 
    }
  }
  