import { Injectable } from '@angular/core';
import { WizardService } from '../controls/static/wizard/wizard.service';
import { CommonService } from './common.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class SmtpService {

  constructor(
    private wizardService: WizardService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  createEvent(mail): Promise<any> { 
    let mailSource = mail.from;
    if (mail.type == 'Outgoing') {
      mailSource = mail.to;
    }

    return new Promise((resolve, reject) => {
      const event = {
        type: 'mailConversion',
        url: 'https://app.everlead.ai',       
        ip: '127.0.0.1',
        utm: '0',
        platform: 'EVERLEAD',
        email: mailSource,
        prospectId: null,
        uniqueId: this.commonService.createGuid(),
        timestamp: new Date(Date.now()).getTime().toString()
      }
      this.externaldatasourceService.executeExternalDataSource(692, [
        this.commonService.getModifyArrayBody(event, [])])
      .then(creteEventResult => {
        resolve(creteEventResult);
      }).catch(error => { reject(error); });  
    });
  }

  createConversion(contactId, event, mail, campaignId): Promise<any> {
    return new Promise((createConversionResolve, createConversionReject) => {    
      let uuid = 'mailId:'.concat(mail.id);
      while (uuid.length < 32) { uuid = uuid.concat('_'); }
      const conversion = {
        eventUniqueId: uuidv4(),
        eventId: event.id,
        date: mail.time,
        assetType: 'mail-conversion',
        assetId: 0,
        assetName: 'mail',
        contactId: contactId,
        companyName: '',
        lookupTypeId: 8069,
        campaignId: campaignId
      }
      this.externaldatasourceService.executeExternalDataSource(681, [
        this.commonService.getModifyArrayBody(conversion, [])])
      .then(createConversionResult => {
        mail.conversionId = createConversionResult.id;
        this.updateEmail(mail);
        createConversionResolve(createConversionResult);        
      });
    });
  }

  updateEmail(mail) {
    const mailItem = {
      id: mail.id,
      conversionId: mail.conversionId
    }
    this.externaldatasourceService.executeExternalDataSource(705, [
      this.commonService.getModifyArrayBody(mailItem, [])])
    .then(() => {});
  }
}
