<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
            texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
            texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="everleadShadowBox fingerCursor" (click)="newTemplate()">
                <i class="fas fa-plus"></i>{{ 'SMTPContent.Label.CreateNewContent' | texttransform }}
            </div>

            <div class="contentList autoOverflowY">
                <div *ngFor="let emailContent of eMailContents" class="everleadShadowBox fingerCursor" (click)="selectContent(emailContent)" 
                [ngClass]="{ hideInDom: !emailtemplateService.isValidSMTPContent(emailContent) }">
                    {{ emailContent.defaultName }}
                </div>
            </div>
        </div>


        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="maxHeight contentEditor">
                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'SMTPContent.Label.Name' | texttransform }}</label>
                    <input [(ngModel)]="wizardService.wizardArray.defaultName" type="text" class="wizardInput">
                </div>

                <div class="wizardDetailItem">
                    <label class="wizardLabel">{{ 'SMTPContent.Label.Subject' | texttransform }}
                        <app-wizard-infobutton displayText="SMTPContent.Info.Subject"></app-wizard-infobutton>
                    </label>
                    <input [(ngModel)]="wizardService.wizardArray.subject" type="text" class="wizardInput">
                </div>

                <div class="maxHeight contentEditor__inner">
                    <app-htmleditor [htmlText]="wizardService.wizardArray.content" [imageArray]="wizardService.wizardArray.imageArray" 
                        class="maxHeight maxWidth" (valueStatusChange)="getContent($event)"></app-htmleditor>         
                    <div class="contentEditor__toolbar">
                        <label class="everleadSmallHeader headerLabel">{{ 'SMTPContent.Label.Availability' | texttransform }}</label>
                        <select [(ngModel)]="wizardService.wizardArray.userId" (change)="changedUserId()">
                            <option [ngValue]=null *ngIf="userService.hasPermission('CanReadSMTPContentDropdownItemAllUsers')">{{ 'SMTPContent.Label.AllUsersOfProject' | texttransform }}</option>
                            <option [ngValue]="applicationInfoService.userID">{{ 'SMTPContent.Label.PersonalTemplate' | texttransform }}</option>
                        </select>
                        <select *ngIf="wizardService.wizardArray.userId != null" [(ngModel)]="wizardService.wizardArray.projectId">
                            <option [ngValue]=null>{{ 'SMTPContent.Label.AllProjectsOfUser' | texttransform }}</option>
                            <option [ngValue]="applicationInfoService.projectID">{{ 'SMTPContent.Label.ThisProject' | texttransform }}</option>
                        </select>
                        <label class="everleadSmallHeader headerLabel">{{ 'SMTPContent.Label.Placeholder' | texttransform }}</label>
                        <div class="contentEditor__inner__placeholder">                    
                            <div class="wizardEMail__placeholder" *ngFor="let placeholder of placeholders">
                                <i class="fas fa-chevron-circle-left fingerCursor" (click)="addMemberToTemplate(placeholder)"></i>
                                {{ emailtemplateService.getPlaceHolderInfo(placeholder) }}
                            </div>    
                        </div>            
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>