import { Component, OnInit, Injector, Input, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-accordeon',
  templateUrl: './custom-accordeon.component.html',
  styleUrls: ['./custom-accordeon.component.scss']
})
export class CustomAccordeonComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  @Input() controlDefinition: any = null;
  selectedControlId = null;

  constructor(
    public injector: Injector,
  ) {
    super(injector);
    this.valueType = null;
  }

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'updateRegisterControl' && event.value.id == this.controlDefinition.id) {        
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  isVisible(control) {
    let returnValue = true;
    if (!control.isActive) {
      returnValue = false;
    }
    return returnValue;
  }

  selectChild(childId) {
    if (this.selectedControlId == childId) {
      this.selectedControlId = null;
    } else {
      this.selectedControlId = childId;
    }    
  }
}

