

<div class="jjTab">
    <button class="tablinks " [ngClass]="{ jjActivetab: section == 1 }" (click)="section = 1">{{
        'ProjectAPI.Label.General' | texttransform }}</button>    
    <button class="tablinks " [ngClass]="{ jjActivetab: section == 2 }" (click)="section = 2">{{
        'ProjectAPI.Label.APIUser' | texttransform }}</button>
    <button class="tablinks " [ngClass]="{ jjActivetab: section == 3 }" (click)="section = 3">{{
        'ProjectAPI.Label.Downloads' | texttransform }}</button>
    <button class="tablinks " *ngIf="applicationInfoService.isDeveloper" 
        [ngClass]="{ jjActivetab: section == 4 }" (click)="section = 4">
        Devs: ApiUser anlegen
    </button>
</div>

<div *ngIf="section == 1" class="projectAPI__container">
    <button (click)="createSecretKey()" class="jjButtonStyle jjButtonColor">
        {{ 'ProjectAPI.Label.CreateSecretKey' | texttransform }}
    </button>
    
    <div class="everleadShadowBox projectAPI__secretKey projectAPI__container" *ngIf="secretKeyCreated">
        <label>{{ 'ProjectAPI.Label.SecretKeyInfo' | texttransform }}</label>
        <div class="projectAPI__container fingerCursor">
            <label class="jjBold">{{ secretKey }}</label>
            <div (click)="copyToClipBoard()" class="projectAPI__footer">
                <i class="fas fa-copy"></i>{{ 'ProjectAPI.Label.CopyToClipBoard' | texttransform }}
            </div>
        </div>                
    </div>    
</div>

<div *ngIf="section == 4" class="projectAPI__container">
    <div class="projectAPI__container__item">
        <label>Vorname</label>
        <input [(ngModel)]="apiUser.firstName">
    </div>
    <div class="projectAPI__container__item">
        <label>Nachname</label>
        <input [(ngModel)]="apiUser.lastName">
    </div>
    <div class="projectAPI__container__item">
        <label>E-Mail</label>
        <input [(ngModel)]="apiUser.email">
    </div>
    <button (click)="createApiUser()" class="jjButtonStyle jjButtonColor" style="margin-top: 15px;" [disabled]="checkApiUserReqs()">
        API-USer anlegen
    </button>
</div>
