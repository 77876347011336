<!-- <button (click)="openDesigner()">Designer</button> -->

<div class="questionaires" *ngIf="selectAvailable">
    <div class="questionaires__select">
        <select [(ngModel)]="selectedQuestionaire" (change)="changeQuestionaire(selectedQuestionaire)" class="maxWidth">            
            <option [ngValue]="questionaire" *ngFor="let questionaire of questionaires">{{ questionaire.defaultName }}</option>
        </select>
    </div>    
</div>

<div class="questionaireControl" 
    *ngIf=" questionsRequested &&
           (
                (selectedQuestionaire.entityId == 1 && applicationInfoService.currentAccount != null) ||
                (selectedQuestionaire.entityId == 2 && applicationInfoService.currentTask != null) ||
                (selectedQuestionaire.entityId == 4 && applicationInfoService.currentContact != null) 
           )
">
    <div class="everleadShadowBox everleadShadowBox2" *ngIf="!selectAvailable">{{ selectedQuestionaire?.defaultName }}</div>
    <div *ngFor="let question of questions" [ngClass]="{ hideInDom: question.isDeleted }">
        <app-questionaire-userquestion [questionnaire]="selectedQuestionaire" [question]="question"></app-questionaire-userquestion>
    </div>
</div>

<div *ngIf="selectedQuestionaire">
    <div *ngIf="selectedQuestionaire.entityId == 4 && applicationInfoService.currentContact == null">
        {{ 'Questionaire.Label.PleaseSelectContact' | texttransform }}
    </div>
</div>