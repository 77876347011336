import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-sectionheader',
  templateUrl: './custom-sectionheader.component.html',
  styleUrls: ['./custom-sectionheader.component.scss']
})
export class CustomSectionheaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
