import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class BoldbiServiceService {
  private authUrl: string;
  private getDashboardsUrl: string;
  private header: HttpHeaders;

  constructor(
      private http: HttpClient,
      private applicationInfoService: ApplicationInfoService) {
  }

  public Gettoken(dashboardServerApiUrl: string,userId: string, userPassword: string) {
      this.header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

      let body = new HttpParams();
      body = body.set('UserId', userId);
      body = body.set('Password', userPassword);

      return this.http.post(dashboardServerApiUrl + '/get-user-key', body, {
          headers: this.header,
      }).pipe(res => {
          return <any>res;
      });
  }

  public GetDashboards(getDashboardsUrl: string) {
      this.header = new HttpHeaders();
      this.header = this.header.append('Access-Control-Allow-Origin', '*');
      this.header = this.header.append('projectId', this.applicationInfoService.projectID.toString());
      this.header = this.header.append('Authorization', 'bearer ' + localStorage.getItem('awstoken'));

      return this.http.get(getDashboardsUrl, {
          headers: this.header
      }).pipe(res => {
          return <any>res;
      });
  }
}
