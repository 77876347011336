import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-dashboardcomponent-myconversions',
  templateUrl: './dashboardcomponent-myconversions.component.html',
  styleUrls: ['./dashboardcomponent-myconversions.component.scss']
})
export class DashboardcomponentMyconversionsComponent implements OnInit {
  loading = true;
  kpis = null;
  lastXDays = 30;
  maxValue = 0;
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private methodService: MethodService
  ) { }

  ngOnInit(): void {
    this.getKPIs()
  }

  getKPIs() {
    this.externaldatasourceService.executeExternalDataSource(831, [this.lastXDays])
    .then(getKPIsResult => {
      this.loading = false;
      if (getKPIsResult != null) {
        this.maxValue = 0;
        this.kpis = getKPIsResult[0];
        this.kpis.trend = this.kpis.trend.toFixed(2);
        this.kpis.eventSources.sort((a, b) => a.count < b.count ? -1 : 1);
        this.kpis.eventSources.forEach(source => {
          if (source.count > this.maxValue) {
            this.maxValue = source.count;
          }
        });
      }      
    }).catch(() => { this.loading = false; });
  }
  
  getPercentHeight(source) {
    if (this.maxValue == 0) {
      return '0%';
    } else {
      return ((source.count * 100) / this.maxValue ) + '%';
    }    
  }

  showConversions() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['conversions','conversions']);
  }
}
