import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-account-footer',
  templateUrl: './account-footer.component.html',
  styleUrls: ['./account-footer.component.scss']
})
export class AccountFooterComponent implements OnInit {
  innerHTMLContentQuestionaire = `<jj-container controluiid="22506" isinline="1"> </jj-container>`;
  public innerHTMLQuestionaire = null;
  innerHTMLContentTasks = `<jj-container controluiid="22507" isinline="1"> </jj-container>`;
  public innerHTMLTasks = null;
  innerHTMLContentActivites = `<jj-container controluiid="22508" isinline="1"> </jj-container>`;
  public innerHTMLActivities = null;
  innerHTMLContentAllContacts = `<jj-container controluiid="22509" isinline="1"> </jj-container>`;
  public innerHTMLAllContacts = null;
  innerHTMLContentOpportunity = `<jj-container controluiid="26225" isinline="1"> </jj-container>`;
  public innerHTMLOpportunity = null;

  innerHTMLContent = `<jj-container controluiid="27465" isinline="1"> </jj-container>`;
  public innerHTML = null;

  selectedTabIndex =  1;
  visibleTabs = [
    { displayText: 'General.AccountFooter.Questionaire', id: 1 },
    { displayText: 'General.AccountFooter.Tasks', id: 2 },
    { displayText: 'General.AccountFooter.Activities', id: 3 },
    { displayText: 'General.AccountFooter.AllContacts', id: 4 }
  ];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getVisibleTabs();
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.innerHTMLQuestionaire = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentQuestionaire);
    this.innerHTMLTasks = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentTasks);
    this.innerHTMLActivities = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentActivites);
    this.innerHTMLAllContacts = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAllContacts);
    this.innerHTMLOpportunity = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentOpportunity);

  }

  getVisibleTabs() {
    this.visibleTabs = [];
    this.visibleTabs.push({ displayText: 'General.AccountFooter.Questionaire', id: 1 });
    if (this.applicationInfoService.projectInfo.isUsingWorkflow) {
      this.visibleTabs.push({ displayText: 'General.AccountFooter.Tasks', id: 2 });
      this.visibleTabs.push({ displayText: 'General.AccountFooter.Opportunity', id: 8 });
    }
    this.visibleTabs.push({ displayText: 'General.AccountFooter.Activities', id: 3 });
    this.visibleTabs.push({ displayText: 'General.AccountFooter.AllContacts', id: 4 });


    if (!this.applicationInfoService.projectInfo.isUsingWorkflow) {
      // this.visibleTabs.push({ displayText: 'General.AccountFooter.SingleAction', id: 5 });
      this.visibleTabs.push({ displayText: 'General.AccountFooter.FollowUps', id: 6 });
      this.visibleTabs.push({ displayText: 'General.AccountFooter.ConversionList', id: 7 });
      this.visibleTabs.push({ displayText: 'General.AccountFooter.LeadScoringDetail', id: 9 });
    }
  }

  setCurrentChild(selectedTabIndex: any) {
    this.selectedTabIndex = selectedTabIndex;
  }
}
