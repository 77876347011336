<div class="campaignDashboard__wrapper">
    <div class="campaignDashboard__wrapper__left">

        <div class="campaignDashboard__wrapper__left__item">
            <div class="campaignDashboard__wrapper__left__item__wrapper">
                <div class="campaignDashboardHeaderLabel ellipsisText">{{ 'CampaignDashboard.Label.MyMailCampaigns' | texttransform }}</div>
                <div class="dashboardMarketingCampaign__myCampaignsFilter">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
                    <input [(ngModel)]="mailCampaignSearchString" placeholder="{{ 'General._.Filter' | texttransform }}">
                </div>
            </div>

            <div class="everleadShadowBox campaignDashboard__item__left">
                <div class="dashboardMarketingCampaign">
                    <div class="progressHeader">
                        <div class="progressHeader__name">{{ 'CampaignDashboard.Label.Name' | texttransform }}</div>
                        <div class="progressHeader__progress">{{ 'CampaignDashboard.Label.Progress' | texttransform }}</div>
                    </div>
                    <div class="dashboardMarketingCampaign__body">
                        <span *ngIf="loadingDataEMailBatches==true">
                            <i class="fas fa-spinner fa-spin" style="margin-right: 5px; font-size: small;"></i>{{ 'General.Label.LoadingData' | texttransform }}
                        </span>

                        <div *ngFor="let campaignOverviewItem of mailCampaignOverview" class="campaignOverviewItem"
                            [ngClass]="{
                                hideInDom: isFiltered(campaignOverviewItem.batchName, mailCampaignSearchString),
                                'campaignOverviewItem__selected': campaignOverviewItem == selectedMailCampaign
                            }">
                            <div class="campaignOverviewItem__campaignName" [ngClass]="{ everleadCoral: campaignOverviewItem == selectedMailCampaign }">
                                <div>{{ campaignOverviewItem.batchName | texttransform }}</div>
                            </div>
                            <div class="campaignOverviewItem__campaignInfo">
                                <div class="campaignOverviewItem__campaignInfo__progress__wrapper">
                                    <label class="campaignOverviewItem__campaignInfo__progress__label">{{
                                        getCampaignProgressPercentage(campaignOverviewItem) }}</label>
                                    <div class="campaignOverviewItem__campaignInfo__progress__progress"
                                        [style.width]="getCampaignProgressPercentage(campaignOverviewItem)"></div>
                                </div>
                                <div class="campaignOverviewItem__campaignInfo__item fingerCursor"
                                    (click)="showMailCampaignDetails(campaignOverviewItem)"
                                    [id]="getCampaignIconId(campaignOverviewItem)">
                                    <div *ngIf="campaignOverviewItem == selectedMailCampaign" class="selectedCampaign">
                                        <svg-icon src="assets/images/svg/icon-campaignselected.svg"  [svgStyle]="{ 'width.px': 52, 'height.px': 50 }"
                                            class="selectedCampaign__svg"></svg-icon>
                                    </div>
                                    <svg-icon src="assets/images/svg/DashboardArrowRight.svg" *ngIf="campaignOverviewItem != selectedMailCampaign"
                                        [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboardMarketingCampaign__footer">
                        <button class=" fingerCursor everleadCustomButton" (click)="showMailCampaigns()">
                            {{ 'UserDashboard.Label.CampaignBoard' |texttransform }}
                        </button>
                        <button class=" fingerCursor everleadCustomButton" (click)="createMailshot()">
                            {{ 'UserDashboard.Label.NewCampaign' |texttransform }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="campaignDashboard__wrapper__left__item">
            <div class="campaignDashboard__wrapper__left__item__wrapper">
                <div class="campaignDashboardHeaderLabel ellipsisText">{{ 'CampaignDashboard.Label.MyMicroCampaigns' | texttransform }}</div>
                <div class="dashboardMarketingCampaign__myCampaignsFilter">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
                    <input [(ngModel)]="microCampaignSearchString" placeholder="{{ 'General._.Filter' | texttransform }}">
                </div>
            </div>
            <div class="everleadShadowBox campaignDashboard__item__left">
                <div class="dashboardMarketingCampaign__body">
                    <span *ngIf="loadingMicroCampaignData==true">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px; font-size: small;"></i>{{ 'General.Label.LoadingData' | texttransform }}
                    </span>
                    <div *ngFor="let campaignOverviewItem of microCampaignOverview"
                        [ngClass]="{
                            hideInDom: isFiltered(campaignOverviewItem.campaignName, microCampaignSearchString),
                            'campaignOverviewItem__selected': campaignOverviewItem == selectedMicroCampaign
                        }"
                        class="campaignOverviewItem">
                        <div class="campaignOverviewItem__campaignName" [ngClass]="{ everleadCoral: campaignOverviewItem == selectedMicroCampaign }">
                            <div *ngIf="!campaignOverviewItem.nameTranslationTokenId">{{
                                campaignOverviewItem.campaignName | texttransform }}</div>
                            <div *ngIf="campaignOverviewItem.nameTranslationTokenId">{{
                                axivasTranslateService.getTranslationTextForId(campaignOverviewItem.nameTranslationTokenId)
                                | texttransform }}</div>
                        </div>
                        <div class="campaignOverviewItem__campaignInfo" [ngClass]="{ everleadCoral: campaignOverviewItem == selectedMicroCampaign }">
                            <div class="campaignOverviewItem__campaignInfo__item"
                                [title]="'UserDashboard.Label.YourCampaignsTasks' | texttransform">
                                    <svg-icon src="assets/images/svg/dashboard_campaign_task.svg"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                    {{ campaignOverviewItem.taskCount }}</div>
                            <div class="campaignOverviewItem__campaignInfo__item"
                                [title]="'UserDashboard.Label.YourCampaignsContacts' | texttransform">
                                    <svg-icon src="assets/images/svg/dashboard_campaign_user.svg"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                    {{ campaignOverviewItem.contactCount }}</div>
                            <div class="campaignOverviewItem__campaignInfo__item"
                                [title]="'UserDashboard.Label.YourCampaignsWins' | texttransform">
                                <svg-icon src="assets/images/svg/dashboard_campaign_cup.svg"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                {{ campaignOverviewItem.positiveResultCount }}
                            </div>
                            <div class="campaignOverviewItem__campaignInfo__item fingerCursor"
                                (click)="showMicroCampaignDetails(campaignOverviewItem)">
                                <div *ngIf="campaignOverviewItem == selectedMicroCampaign" class="selectedCampaign">
                                    <svg-icon src="assets/images/svg/icon-campaignselected.svg"  [svgStyle]="{ 'width.px': 52, 'height.px': 50 }"
                                        class="selectedCampaign__svg"></svg-icon>
                                </div>
                                <div *ngIf="campaignOverviewItem != selectedMicroCampaign">
                                    <svg-icon src="assets/images/svg/DashboardArrowRight.svg" [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
    </div>


    <div class="campaignDashboard__wrapper__right">
        <div class="campaignDashboard__item__right__inner" *ngIf="selectedMailCampaign != null">
            <div class="campaignDashboard__item__right__header">
                <div class="ellipsisText campaignDashboard__item__right__header__item">
                    {{ selectedMailCampaign.batchName | texttransform }}
                </div>
                <div class="campaignDashboard__item__right__header__item campaignDashboard__item__right__header__item__details"
                    *ngIf="selectedMailCampaign.startedAt != null">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
                        class="fingerCursor" (click)="showMailshotDetails(selectedMailCampaign)"
                        [tippy]="'CampaignDashboard.Label.ShowMailshotDetails' | texttransform"></svg-icon>
                </div>
            </div>
            <div class="campaignDashboard__item__right__content">
                <div class="mailCampaignStartInfo__wrapper" *ngIf="selectedMailCampaign.startedAt != null">
                    <div class="mailCampaignStartInfo">
                        <div class="mailCampaignStartInfo__text">{{ 'CampaignDashboard.Label.CampaignStartedAt' | texttransform }}</div>
                        <div class="mailCampaignStartInfo__value">{{ selectedMailCampaign.startedAt | date: 'medium' }}</div>
                    </div>
                    <div *ngIf="selectedMailCampaign.startedAt != null && selectedMailCampaign.countSent == 0" class="mailCampaignStartInfo mailCampaignStartInfo">
                        <div class="mailCampaignStartInfo__text">{{ 'CampaignDashboard.Label.PlannedSendDate' | texttransform }}</div>
                        <div class="mailCampaignStartInfo__value">{{ getSendDate(selectedMailCampaign) | date: 'medium' }}</div>
                    </div>
                </div>
                <div class="campaignDashBoardHeaderLabel" *ngIf="selectedMailCampaign.startedAt == null">
                    {{ 'Pie.Label.NoDataAvailable' | texttransform }}
                </div>

                <div *ngIf="selectedMailCampaign.startedAt != null"
                    class="campaignDashBoardHeaderLabel__mailshotOverview__wrapper">
                    <div class="campaignDashBoardHeaderLabel campaignDashBoardHeaderLabel__mailshotOverview">
                        {{ 'CampaignDashboard.Label.MailshotOverview' | texttransform }}
                    </div>

                    <app-echarts
                        *ngIf="chartDataMailDetailsCampaign.data.length > 0"
                        [chart]="'bar'"
                        [chartData]="chartDataMailDetailsCampaign"
                        [darkmode]="true"
                        [controlid]="barChartMailId"
                        [height]="300"
                        [legend]="true"
                        [legendPosition]="{'bottom': 5 }"
                        [showXaxis]="false"
                        [xAxisType]="'category'"
                        [yAxisType]="'value'"
                        [barWidth]="5"
                        [barMaxWidth]="5"
                        [barGap]="3"
                        [barLabel]="{
                          'show':true,
                          'position':'top',
                          'distance': 15,
                          'align':'center',
                          'verticalAlign':'middle',
                          'rotate': 0
                        }"
                    >
                    </app-echarts>

                    <div class="campaignDashBoardHeaderLabel campaignDashBoardHeaderLabel__mailshotOverviewDetails"
                        *ngIf="chartDataMailCampaignSentInfo.data.length > 0 && selectedMailCampaign.countSent > 0">
                        {{ 'CampaignDashboard.Label.MailshotOverviewDetails' | texttransform }}
                    </div>

                    <div class="chartMailSentDetails" *ngIf="selectedMailCampaign.countSent > 0">
                        <app-echarts
                            *ngIf="chartDataMailCampaignSentInfo.data.length > 0"
                            [chart]="'funnel'"
                            [chartData]="chartDataMailCampaignSentInfo"
                            [controlid]="sentInfoPieId"
                            [legend]="false"
                            [darkmode]="true"
                            [legendPosition]="{'bottom': 0}"
                            [height]="funnelMaxHeight"
                            [sort]="'none'"
                            [orient]="'vertical'"
                            [gap]="1"
                            [funnelAlign]="'center'"
                            [funnelLabelPosition]="{'position': 'center', 'show': true }"
                        ></app-echarts>
                    </div>
                </div>

                <div *ngIf="selectedMailCampaign.startedAt != null && selectedMailCampaign.countSent == 0">
                    <!-- Cancel mailshot -->
                </div>
                <div *ngIf="(selectedMailCampaign.startedAt == null)" class="campaignDashboard__item__right__body__edit">
                    <button class="everleadCustomButton" (click)="editMailCampaign(selectedMailCampaign)">
                        {{ 'UserDashboard.Label.Edit' | texttransform }}
                    </button>
                </div>
            </div>
        </div>


        <div class="campaignDashboard__item__right__inner" *ngIf="selectedMicroCampaign != null">
            <div class="campaignDashboard__item__right__header">
                <div class="ellipsisText campaignDashboard__item__right__header__item">
                    <span *ngIf="!selectedMicroCampaign.nameTranslationTokenId">{{ selectedMicroCampaign.campaignName | texttransform }}</span>
                    <span *ngIf="selectedMicroCampaign.nameTranslationTokenId">
                        {{ axivasTranslateService.getTranslationTextForId(selectedMicroCampaign.nameTranslationTokenId) | texttransform }}
                    </span>
                </div>
                <div class="ellipsisText campaignDashboard__item__right__header__item campaignDashboard__item__right__header__item__details">
                    <!-- <i class="fas fa-cog" (click)="showMailshotDetails()"></i> -->
                </div>
            </div>
            <div class="selectedMicroCampaignDetail__headerline__wrapper">
                <div class="selectedMicroCampaignDetail__headerline">
                    <div class="selectedMicroCampaignDetail__headerline__svg">
                        <svg-icon src="assets/images/svg/dashboard_campaign_task.svg"  [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="selectedMicroCampaignDetail__headerline__value everleadCoral">{{ selectedMicroCampaign.taskCount }}</div>
                    <div class="selectedMicroCampaignDetail__headerline__label">{{ 'UserDashboard.Label.YourCampaignsTasks' | texttransform }}</div>
                </div>
                <div class="selectedMicroCampaignDetail__headerline">
                    <div class="selectedMicroCampaignDetail__headerline__svg" >
                        <svg-icon src="assets/images/svg/dashboard_campaign_user.svg"  [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="selectedMicroCampaignDetail__headerline__value everleadCoral">{{ selectedMicroCampaign.contactCount }}</div>
                    <div class="selectedMicroCampaignDetail__headerline__label">{{ 'UserDashboard.Label.YourCampaignsContacts' | texttransform }}</div>
                    <div class="selectedMicroCampaignDetail__headerline__svg" >
                        <button class="everleadCustomButton" (click)="showCampaignUser(selectedMicroCampaign)">
                            {{ 'UserDashboard.Label.ShowYourCampaignsContacts' | texttransform }}
                        </button>
                    </div>
                </div>
                <div class="selectedMicroCampaignDetail__headerline">
                    <div class="selectedMicroCampaignDetail__headerline__svg">
                        <svg-icon src="assets/images/svg/dashboard_campaign_cup.svg"  [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="selectedMicroCampaignDetail__headerline__value everleadCoral">{{ selectedMicroCampaign.positiveResultCount }}</div>
                    <div class="selectedMicroCampaignDetail__headerline__label">{{ 'UserDashboard.Label.YourCampaignsWins' | texttransform }}</div>
                </div>
            </div>
            <div class="campaignDashboard__item__right__content">
                <div class="campaignDashboardPieChar__header jjBold">
                    {{ 'CampaignDashboard.Label.BarChartHeader' | texttransform }}
                </div>
                <app-echarts
                    *ngIf="chartDataMicroCampaign.data.length > 0"
                    [chart]="'bar'"
                    [height]=400
                    [controlid]="barChartId"
                    [chartData]="chartDataMicroCampaign"
                    [barWidth]="20"
                    [barMaxWidth]="20"
                    [darkmode]="true"
                    [legend]="true"
                    [legendPosition]="{'top': 10 }"
                    [showXaxis]="true"
                    [xAxisType]="'category'"
                    [xAxisLabel]="{ 'interval': 0, 'rotate': 10, 'margin': 20 }"
                    [yAxisType]="'value'"
                    [barLabel]="{
                      'show': true,
                      'position': 'insideBottom',
                      'distance': 15,
                      'align':'left',
                      'verticalAlign': 'middle',
                      'rotate': 90
                }">
                </app-echarts>



            </div>
        </div>
    </div>
</div>
