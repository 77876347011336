<div class="chart-wrapper" *ngIf="barChartData"   [ngStyle]="stylesObj" >
    <h5 *ngIf="title != ''">{{title}}</h5>
       <canvas baseChart
  
       [attr.id]="chartId"
       [datasets]="barChartData"
       [labels]="labels"
       [options]="options"
       [plugins]="barChartPlugins"
       [legend]="barChartLegend"
       [chartType]="barChartType"
      >
     </canvas>
  
</div>