<div class="accountPopupMenu controlInlineBlock">
    <div style="width: 30%" class="inline">
        <app-custom-universal *ngIf="applicationInfoService?.additionalMenuBarControlId!=0 && applicationInfoService.useNewDesign == false"
        [controlid]=applicationInfoService?.additionalMenuBarControlId class="accountPopupMenuLeft"></app-custom-universal>    
    </div>
    <div class="inline" style="max-width: 40%; float: right; margin-right: 7px;" *ngIf="applicationInfoService?.accountPopupDataArray.length > 0">
        <button class=" accountPopupButton everleadCustomButton"
            *ngIf="applicationInfoService.accountPopupDataType === PopupDataType.Account && assignMode"
            title="{{ 'AccountPopup.Label.AssignAccount' | texttransform }}"
            (click)="assignAccount()">
            {{ 'AccountPopup.Label.AssignAccount' | texttransform }}
        </button>  

        <button class=" accountPopupButton everleadCustomButton"
            *ngIf="applicationInfoService.accountPopupDataType === PopupDataType.Account"
            title="{{ 'AccountPopup.Label.LoadAccount' | texttransform }}"
            (click)="loadAccount()">
            {{ 'AccountPopup.Label.LoadAccount' | texttransform }}
        </button>  

        <button class=" accountPopupButton everleadCustomButton"
            *ngIf="applicationInfoService.accountPopupDataType === PopupDataType.Account && applicationInfoService.useNewDesign == false" 
            title="{{ 'AccountPopup.Label.BackAndUpdate' | texttransform }}"
            (click)="updateAndBackToMyLeads()">
            Update and back
        </button>        

        <button class="jjControlSubmenuButton accountPopupButton"
            [disabled]="currentDataIndex < 1"
            title="{{ 'AccountPopup.Label.FirstData' | texttransform }}"
            (click)="showFirstData()">
            <i class="fas fa-step-backward"></i>
        </button>        
        <button class="jjControlSubmenuButton accountPopupButton"
            [disabled]="currentDataIndex < 1"
            title="{{ 'AccountPopup.Label.PreviousData' | texttransform }}"
            (click)="showPreviousData()">
            <i class="fas fa-caret-left"></i>
        </button>
        <label class="accountPopupLabel">{{ 'AccountPopup.Label.Recordset' | texttransform }} 
                {{ currentDataIndex + 1 }} / {{ applicationInfoService?.accountPopupDataArray?.length }}
        </label>
        <button class="jjControlSubmenuButton accountPopupButton"
            title="{{ 'AccountPopup.Label.NextData' | texttransform }}"
            [disabled]="currentDataIndex > applicationInfoService?.accountPopupDataArray?.length - 2"
            (click)="showNextData()">
            <i class="fas fa-caret-right"></i>
        </button>
        <button class="jjControlSubmenuButton accountPopupButton"
            title="{{ 'AccountPopup.Label.LastData' | texttransform }}"
            [disabled]="currentDataIndex > applicationInfoService?.accountPopupDataArray?.length - 2"
            (click)="showLastData()">
            <i class="fas fa-step-forward"></i>
        </button>
    </div>
</div>
<div class="accountPopupAccountInfo">
    <app-account></app-account>
</div>
