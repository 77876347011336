import { Component, OnInit, Input } from '@angular/core';
import { QuestionaireControlService } from '../questionaire-control.service';

@Component({
  selector: 'app-questionaire-userquestion',
  templateUrl: './questionaire-userquestion.component.html',
  styleUrls: ['./questionaire-userquestion.component.scss']
})
export class QuestionaireUserquestionComponent implements OnInit {
  @Input() question = null;
  @Input() questionnaire = null;

  answers = null;
  dropdownAnswers = [];
  questionAnswers = [];
  constructor(
    public questionaireControlService: QuestionaireControlService
  ) { }

  ngOnInit(): void {
    this.getQuestionAnswers();
  }

  getQuestionAnswers() {
    this.questionaireControlService.getAnswersByQuestion(this.question).then(answers => {
      this.answers = answers;
      this.dropdownAnswers[this.question.id] = { 
        id: null,
        answerId: null, 
        answerText: '', 
        answerInt: null, 
        questionId: this.question.id,
      }
      answers.forEach(answer => {
        if (this.questionAnswers[this.question.id] == undefined) {
          this.questionAnswers[this.question.id] = [];
        }                
        const existingAnswer = this.questionaireControlService.questionaireAnswers[this.questionnaire.id].find(listAnswer => 
          listAnswer.questionId == this.question.id && 
          listAnswer.answerId == answer.id
        );        
        this.questionAnswers[this.question.id][answer.id] = { 
          id: null,
          answerId: answer.id, 
          answerText: '', 
          answerInt: null, 
          questionId: this.question.id,
          isSelected: false,
          typeLookupId: answer.typeLookupId
        }
        if (existingAnswer) {
          this.questionAnswers[this.question.id][answer.id].id = existingAnswer.id;
          this.questionAnswers[this.question.id][answer.id].answerText = existingAnswer.answerText;
          this.questionAnswers[this.question.id][answer.id].answerInt = existingAnswer.answerInt;
          this.questionAnswers[this.question.id][answer.id].isSelected = true;
          this.dropdownAnswers[this.question.id].answerText = existingAnswer.answerText;
          this.dropdownAnswers[this.question.id].answerInt = existingAnswer.answerInt;
          this.dropdownAnswers[this.question.id].answerId = answer.id;          
          this.dropdownAnswers[this.question.id].id = existingAnswer.id;
        }
      });      
    })
  }

  toggleValue(answer) {
    answer.isSelected = !answer.isSelected;
    this.questionaireControlService.loaderService.display(true);
    if (answer.isSelected) {
      this.questionaireControlService.createQuestionAnswer(this.question, answer, this.questionaireControlService.getRefId(this.questionnaire)).then(createQuestionAnswerResult => {
        answer.id = createQuestionAnswerResult.id;
        this.questionaireControlService.loaderService.display(false);
      }).catch(() => { 
        answer.isSelected = false; 
        this.questionaireControlService.loaderService.display(false);
      });
    } else {
      this.questionaireControlService.deleteQuestionAnswer(answer).then(() => {
        this.questionaireControlService.loaderService.display(false);
      }).catch(() => { 
        this.questionaireControlService.loaderService.display(false);
        answer.isSelected = true; 
      });
    }  
  }

  changeDropdownAnswerChanged(answer) {
    if (answer.id==null) {
      this.questionaireControlService.createQuestionAnswer(this.question, answer, this.questionaireControlService.getRefId(this.questionnaire))
      .then(changeDropdownAnswerChangedResult => { answer.id = changeDropdownAnswerChangedResult.id; });
    } else {
      this.questionaireControlService.updateQuestionAnswer(answer);
    }
  } 

  isTextControl(answerId) {    
    // getAnswer(dropdownAnswers[question.id].answerId).typeLookupId == 1907
    let returnValue = false;
    const foundAnswer = this.answers.find(answer => answer.id == answerId);
    if (foundAnswer) {
      if (foundAnswer.typeLookupId == 1907) {
        returnValue = true;
      }      
    }
    return returnValue;
  }
}
