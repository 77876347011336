import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-conversion',
  templateUrl: './conversion.component.html',
  styleUrls: ['./conversion.component.scss']
})

export class ConversionComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="24804"> </jj-container>`;
  innerHTML = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService
  ) { }

  ngOnInit() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.trackingService.trackPageView();
  }
}
