import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';
import { Table } from 'primeng/table';
import { Dictionary } from '../../../../core/dictionary';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-custom-listview',
  templateUrl: './custom-listview.component.html',
  styleUrls: ['./custom-listview.component.scss']
})
export class CustomListviewComponent extends CustomControlBaseClass implements OnInit {
  @ViewChild(Table) dt: Table;
  selectedItem: any;
  headerList: any[] = [];
  public scheme: Dictionary<string> = new Dictionary<string>();

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.List;
  }

  ngOnInit() {
    this.controlDefinition.childs.forEach(child => {
      const obj: Object = new Object();
      obj['field'] = child.value;
      obj['header'] = child.displayText;
      obj['width'] = child.width;
      obj['height'] = child.height;
      this.headerList.push(obj);
    });
    // console.log('headerList', this.headerList);

    // ExternalDataSource
    if (this.externalDataSource) {
      this.messagingService.showDebugMessageInConsole('CustomListviewComponent this.externalDataSource', [this.externalDataSource]);
      this.externalDatasourceService.getScheme(this.externalDataSource.schema, this.externalDataSource.source)
      .then(result => {
        this.messagingService.showDebugMessageInConsole(' CustomListviewComponentgetScheme result',
        [result, this.applicationInfoService.schemes.Item(this.externalDataSource.schema)]);
        this.scheme = result;
        this.externalDatasourceService.executeQuery(this.externalDataSource).then(data => {
          this.data = data;
        }).catch (data => {
          console.error(data);
        });
      })
      .catch(error => { console.error('CustomListView', error); });
    }
  }

  getRowCount() {
    if (this.applicationInfoService.isMobile()) {
      return 5;
    } else { return 10; }
  }
}
