import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-ringcentral-redirect',
  templateUrl: './ringcentral-redirect.component.html',
  styleUrls: ['./ringcentral-redirect.component.scss']
})
export class RingcentralRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.applicationInfoService.noAuthKilling = true;
    console.warn('RingcentralRedirectComponent');
    this.route.queryParamMap.subscribe((params: any) => {
      const code = params['params']['code'];
      console.warn('RingcentralRedirectComponent', code);
      localStorage.setItem("ringCentralAuthCode", code);
    });
  }

}
