import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { Subscription, timer } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import * as moment from 'moment';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { SessionStorageService } from 'ngx-webstorage';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { QuestionaireControlService } from '../questionaire-control/questionaire-control.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {
  lockAccountTimerSubscription: Subscription = new Subscription();
  loadContactEventSubscription: Subscription = new Subscription();
  showNewTaskEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  blockTaskUpdate = false;
  accountLockError = false;
  menubarState = null;
  innerHTMLContentMobile = ` <jj-container controluiid="1250" isinline="1"> </jj-container>`;
  innerHTMLContentTask = `<jj-container controluiid="6217" isinline="1"></jj-container>`;
  innerHTMLContentNoTask = `<jj-container controluiid="8762" isinline="1"></jj-container>`;
  innerHTMLContentAdditional = `<jj-container controluiid="1250" isinline="1"></jj-container>`;
  innerHTMLContentQM = `<jj-container controluiid="appointment-view" isinline="1"></jj-container>`;
  innerHTMLContentAccount = ` <jj-container controluiid="1300" isinline="1"> </jj-container>`;
  innerHTMLContentContact = ` <jj-container controluiid="1400" isinline="1"> </jj-container>`;
  innerHTMLContentFooter = ` <jj-account-footer></jj-account-footer>`;
  innerHTMLContenBonusPanel1 = `<jj-container controluiid="27429" isinline="1"> </jj-container>`;
  innerHTMLContenAccountBonusPanel1 = `<jj-container controluiid="34038" isinline="1"> </jj-container>`;
  innerHTMLContentQuestionaire = ` <jj-container controluiid="217848"> </jj-container>`;
  // isAccountExpanded = false;
  
  public innerHTMLQM = null;
  public innerHTMLTask = null;
  public innerHTMLNoTask = null;
  public innerHTMLAdditional = null;
  public innerHTMLMobile = null;
  public innerHTMLAccount = null;
  public innerHTMLContact = null;
  public innerHTMLFooter = null;
  public innerHTMLBonusPanel1 = null;
  public innerHTMLAccountBonusPanel1 = null;
  public innerHTMLQuestionaire = null;

  accountInit = false;
  loaderServiceSubscription: Subscription;
  loaderVisible: boolean = true;
  projectLoaded: boolean = false;
  questionnaires = [];
  selectedQuestionnaireId = 0;

  constructor(
    private domSanitizer: DomSanitizer,
    public applicationInfoService: ApplicationInfoService,
    public entityService: EntityService,
    private sessionStorage: SessionStorageService,
    private eventService: EventService,
    private methodService: MethodService,
    private projectService: ProjectService,
    private uiService: UiService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService, 
    private commonService: CommonService,
    private loaderService: LoaderService,
    private questionaireControlService: QuestionaireControlService
  ) { }

  ngOnInit() {
    setTimeout(() => { 
      this.accountInit = true;
    }, 2000);
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.projectLoaded = false;
        this.loaderService.showSpinner(true);
        const startPath = localStorage.getItem('locationStartInfo');        
        if (!this.commonService.isNullOrUndefined(startPath)) {
          const startData = JSON.parse(startPath);
          if (startData.tid != null) {
            this.methodService.launchInternalMethod(InternalMethodType.ShowTaskByTaskId, [startData.tid]);
          } else {
            const promiseArray: Promise<any>[] = [];
            if (startData.aid != null) { 
              promiseArray.push(this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [startData.aid]));
            }
            if (startData.cid != null) {
              promiseArray.push(this.methodService.launchInternalMethod(InternalMethodType.LoadContact, [startData.cid]));
            }            
            Promise.all(promiseArray).then(() => {
              // console.log('Refresh done', startData, this.applicationInfoService.currentAccount, this.applicationInfoService.currentContact);
            })
          }
        }    
        this.loaderService.showSpinner(false);
        this.projectLoaded = true;
      }
    });

    this.loaderServiceSubscription = this.loaderService.status.subscribe((val: boolean) => {
      this.loaderVisible = val;
    });

    if (this.applicationInfoService.applicationSettings['showQuestionaireTab'] == 'true') {
      this.questionaireControlService.getQuestionaires().then(getQuestionairesResult => this.questionnaires = getQuestionairesResult);
    }    
  }

  ngAfterViewInit(): void {
    this.applicationInfoService.miscSettings['accountformactive'] = true;
    this.applicationInfoService.additionalMenuBarControlId = 6293;

    setTimeout(() => { 
      this.innerHTMLTask = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentTask);
      this.innerHTMLNoTask = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentNoTask);
      this.innerHTMLAdditional = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAdditional);
      this.innerHTMLMobile = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentMobile);
      this.innerHTMLQM = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentQM);
      this.innerHTMLAccount = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentAccount);
      this.innerHTMLContact = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentContact);
      this.innerHTMLFooter = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentFooter);
      this.innerHTMLBonusPanel1 = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContenBonusPanel1);
      this.innerHTMLAccountBonusPanel1 = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContenAccountBonusPanel1);  
      this.innerHTMLQuestionaire = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentQuestionaire);  
      this.eventService.updateControlAttributes();
    }, 20);

    // Subscriptions
    this.loadContactEventSubscription = this.eventService.loadContactEvent.subscribe(event => {
      this.projectService.getCurrentContact(event.arguments[0]);
    });

    // this.startLockAccountTimer();
    this.showNewTaskEventSubscription = this.eventService.showNewTaskEvent.subscribe(event => {
      console.warn('showNewTaskEventSubscription', event, this.applicationInfoService.currentTask, event.arguments[0]);
      if (this.applicationInfoService.currentTask) {
        this.applicationInfoService.currentTask.id = event.arguments[0];
        if (!this.blockTaskUpdate) {
          this.projectService.getCurrentTask();
        } else {
          this.blockTaskUpdate = false;
        }
      }
    });

    if (this.sessionStorage.retrieve('redirectValuesCreated') === 'true') {
      setTimeout(() => {
        console.warn('found redirect information',
          this.sessionStorage.retrieve('redirectTaskId'),
          this.sessionStorage.retrieve('redirectAccountId'),
          this.sessionStorage.retrieve('redirectContactId'),
        );
        this.commonService.setSessionStorageItem('LastNavigation', 'account');
        if (this.sessionStorage.retrieve('redirectTaskId') !== '') {
          // Show the redirected task
          this.methodService.launchInternalMethod(InternalMethodType.ShowTaskByTaskId,
            [this.sessionStorage.retrieve('redirectTaskId')])
          .then(loadTaskResult => {
            this.projectService.clearRedirectValues();
            if (loadTaskResult.account.projectId !== Number(this.applicationInfoService.projectID)) {
              this.projectService.clearAccountForm();
              this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, '',
                this.axivasTranslateService.getTranslationTextForToken('Task.Label.LoadTaskError'), false);
            }
          })
          .catch(error => {
            this.projectService.clearAccountForm();
            this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, '',
              this.axivasTranslateService.getTranslationTextForToken('Task.Label.LoadTaskError'), false);
            this.projectService.clearRedirectValues();
          });
        } else {
          // show the redirected account
          if (this.sessionStorage.retrieve('redirectAccountId') !== '') {
            this.projectService.getCurrentAccount(this.sessionStorage.retrieve('redirectAccountId'))
            .then(getCurrentAccountResult => {              
              if (this.sessionStorage.retrieve('redirectContactId') !== '') {
                this.projectService.getCurrentContact(this.sessionStorage.retrieve('redirectContactId'));
              }
              if (getCurrentAccountResult.projectId !== Number(this.applicationInfoService.projectID)) {                
                this.projectService.clearAccountForm();
                this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning, '',
                  this.axivasTranslateService.getTranslationTextForToken('Account.Label.LoadAccountError'), false);  
              }
              this.projectService.clearRedirectValues();
            })
            .catch(error => { console.error('getCurrentAccountResult', error); });
          } else {
            this.projectService.clearRedirectValues();
          }
        }
      }, 1000);
    }
  }

  checkTaskMaxWidthWidth() {    
    if (this.applicationInfoService.innerWidth > 1400) {
      return true;
    } else {
      return false;
    }
  }

  checkIfMenuIsVisible() {
    if (this.applicationInfoService.miscSettings['navigatedSection'] == 'account' && this.applicationInfoService.currentAccount != null) {
      this.applicationInfoService.additionalMenuBarControlId = 6293;
    } else {
      this.applicationInfoService.additionalMenuBarControlId = 0;
    }
  }

  startWorking() {
    this.blockTaskUpdate = true;
    this.projectService.getNextTask().
    then(getNextTaskResult => {
      // console.log('InternalMethodType.NextTask result', getNextTaskResult);
      if (getNextTaskResult.data !== undefined && getNextTaskResult.data !== null && getNextTaskResult.data.nextFollowUp) {
        this.methodService.launchInternalMethod('showtaskbytaskid', [getNextTaskResult.data.nextFollowUp.id]);
      }
    });
  }

  ngOnDestroy() {
    if (this.lockAccountTimerSubscription) { this.lockAccountTimerSubscription.unsubscribe(); }
    if (this.loadContactEventSubscription) { this.loadContactEventSubscription.unsubscribe(); }
    if (this.showNewTaskEventSubscription) { this.showNewTaskEventSubscription.unsubscribe(); }
    if (this.loaderServiceSubscription) { this.loaderServiceSubscription.unsubscribe(); }    
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    this.applicationInfoService.miscSettings['accountformactive'] = false;
  }

  getMaximumHeightOfSubContainer(diff = 20) {
    return { height: this.uiService.getDesignSpanPosition('accountDesignSpan', diff) + 'px' };
  }

  showAccountInformation() {
    if (this.applicationInfoService.miscSettings['navigatedSection'] == 'account') {
      return true;
    } else {
      return false;
    }
  }

  updateControlAttributes() {
    this.eventService.updateControlAttributes();
  }

  assignAccount() {
    console.log('assignAccount - coming soon');
  }

  checkIfScrolled() {
    let ifScrolled = false;
    const element = document.getElementById('accountMenuChecker');
    if (element == null || element == undefined) {
      ifScrolled = true;    
    } else {
      ifScrolled = this.commonService.isInViewport('accountMenuChecker', 100, 0);
    }    
    return ifScrolled;
  }

  getAdressLine(fullInfo = false) {
    if (fullInfo) {
      return ''.concat(
        this.getEntityInfo(1, 'name1'), '\n',
        this.getEntityInfo(1, 'street'), '\n',
        this.getEntityInfo(1, 'zipcode'), ' ',
        this.getEntityInfo(1, 'city')
      );
    } else {
      return this.getEntityInfo(1, 'name1');
    }
  }

  getContactLine() {
    return ''.concat(
      this.getEntityInfo(4, 'firstName'), ' ',
      this.getEntityInfo(4, 'lastName'), ' ',
      // this.entityService.getEntityValue(4, 'zipcode', 1), ' ',
      // this.entityService.getEntityValue(4, 'city', 1)
    );
  }

  getContactSalutation(salutationId) {
    let returnValue = '';
    if (salutationId != null) {
      const tableData = this.applicationInfoService.projectLookupTables.find(projectLookupTable => projectLookupTable.definitionId == Number(1) && projectLookupTable.source == 'data');
      if (tableData) {
        const lookUpValue = tableData.lookups.find(lookupItem => lookupItem.id == salutationId);
        if (lookUpValue) {
          returnValue = lookUpValue.defaultName;
        }
      }
    }
    return returnValue;
  }

  getEntityInfo(entityId, memberName) {
    let returnValue = this.entityService.getEntityValue(entityId, memberName, 1);
    if (returnValue == null || returnValue == undefined) {
      returnValue = '';
    }
    return returnValue;
  }

  onScroll(event) {
    let referenceHeight = event.target.scrollHeight - event.target.offsetHeight;
    referenceHeight = referenceHeight - 60;
    if( event.target.scrollTop >= (referenceHeight)) {
      this.eventService.customEvent.emit({ id: 'scrolledToBottom' });
    }
  }

  loadContact(accountContact) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [accountContact.id]);
  }
  
  createContact() {
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ["6", "-1"]).then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['Contact.Label.NewContact', 'newcontact', '80']);
    });
  }

  getAccountContacts(contactList) {
    if (this.commonService.isNullOrUndefined(contactList)) {
      return [];
    }
    if (this.applicationInfoService.currentAccount.isDummyAccount) {
      return [];
    }
    return contactList.filter(contactListItem => 
      contactListItem.isBlacklisted == false &&
      contactListItem.isDeleted == false
    );
  }

  showQuestionnaire(questionnaire) {
    this.selectedQuestionnaireId = questionnaire.id;
    this.applicationInfoService.miscSettings['showQuestionnaire'] = questionnaire;
    this.eventService.customEvent.emit({ id: 'showQuestionnaire', value: questionnaire});
  }
}
