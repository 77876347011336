import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SessionStorageService } from 'ngx-webstorage';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService
  ) {
    const taskId =  this.activatedRoute.snapshot.queryParams['tid'];
    const projectId =  this.activatedRoute.snapshot.queryParams['pid'];
    const accountId =  this.activatedRoute.snapshot.queryParams['aid'];
    const contactId =  this.activatedRoute.snapshot.queryParams['cid'];
    console.warn('redirect values',
      this.activatedRoute.snapshot.queryParams['tid'],
      this.activatedRoute.snapshot.queryParams['pid'],
      this.activatedRoute.snapshot.queryParams['cid'],
      this.activatedRoute.snapshot.queryParams['aid'],
      this.sessionStorage.retrieve('redirectValuesCreated') 
    );
    if (this.sessionStorage.retrieve('redirectValuesCreated') !== 'true') {
      this.commonService.setSessionStorageItem('redirectValuesCreated', 'true');
      this.commonService.setSessionStorageItem('redirectTaskId', taskId);
      this.commonService.setSessionStorageItem('redirectAccountId', accountId);
      this.commonService.setSessionStorageItem('redirectContactId', contactId);
      this.commonService.setSessionStorageItem('redirectProjectId', projectId);
    }
    this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/account']);
  }

  ngOnInit() {}


}
