import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loaderCounter = 0;

    display(value: boolean, forcedDisable: boolean = false, message: string = '') {
        if (this.loaderCounter < 0) {
            this.loaderCounter = 0;
        }
        value ? this.loaderCounter = this.loaderCounter + 1 : this.loaderCounter = this.loaderCounter - 1;        
        if (forcedDisable) {
            this.loaderCounter = 0;
        }
        this.loaderCounter > 0 ? this.status.next(true) : this.status.next(false);
    }

    showSpinner(value: boolean, forcedDisable: boolean = false, message: string = '') {
        value ? this.loaderCounter = this.loaderCounter + 1 : this.loaderCounter = this.loaderCounter - 1;        
        this.loaderCounter > 0 ? this.status.next(true) : this.status.next(false);
    }

}
