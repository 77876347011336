<div *ngIf="mode==1" class="voiceScript__wrapper">
    <div class="voiceScript__header" *ngIf="ringcentralwebphoneService.sessionState.record">
        <div class="everleadCoralBackground voiceScript__header__recording">
            {{'VoiceScript.Label.RecordingActive' | texttransform }}
        </div>
    </div>
    <div *ngIf="renderedValue" [innerHTML]="renderedValue" class="voiceScript__content"></div>
    <div class="voiceScript__footer">
        <button class="everleadCustomButton" *ngIf="!ringcentralwebphoneService.sessionState.record &&
            applicationInfoService.applicationSettings['isRecordingAllowed'] == 'true' && 
            ringcentralwebphoneService.established &&
            ringcentralwebphoneService.makeCallObject.accountId != null"
        (click)="toggleRecord()">
            {{'VoiceScript.Label.StartRecording' | texttransform }}
        </button>

        <button class="everleadCustomButton" *ngIf="ringcentralwebphoneService.sessionState.record"
            (click)="ringcentralwebphoneService.toggleRecord()">
            {{'VoiceScript.Label.StopRecording' | texttransform }}
        </button>
    </div>
</div>








<div *ngIf="mode==2" class="maxHeight maxHeight">
    <span id="voiceScriptDesign"></span>
    <div [style.height.px]="uiService.getDesignSpanPosition('voiceScriptDesign', 60)" class="emailTemplates__wrapper">
        <div class="projectSettings__header">
            <div class="projectSettings__header__newItem fingerCursor" (click)="createNewTemplate()" >
                <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }" 
                    [tippy]="'General.Label.CreateNewWithWizard' | texttransform"></svg-icon>               
                    {{'VoiceScript.Label.NewVoiceScript' | texttransform }}
            </div>
            <div class="projectSettings__header__interaction">       
                <div class="projectSettings__header__filter">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                    <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
                </div>
            </div>
        </div>

        <div class="emailTemplates__content">
            <div class="nonExpertView__main nonExpertView__main__noSubitems everleadShadowBox" *ngFor="let voiceScriptTemplate of voiceScriptTemplates" [ngClass]="{
                hideInDom: checkIfItemIsFiltered(voiceScriptTemplate)
            }">
                <div class="nonExpertView__name">            
                    <label>{{ voiceScriptTemplate.defaultName }}</label>
                </div>
                <div class="nonExpertView__bonus" [tippy]="'General._.Edit' | texttransform" (click)="showWizard(voiceScriptTemplate)">            
                    <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button"></i>
                </div>
            </div>
        </div>
    </div>
</div>