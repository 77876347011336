import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ProcessvisualService } from '../processvisual/processvisual.service';

@Component({
  selector: 'app-processvisualitem',
  templateUrl: './processvisualitem.component.html',
  styleUrls: ['./processvisualitem.component.scss']
})
export class ProcessvisualitemComponent implements OnInit {
  @Input() resultid = null;
  @Input() stepid = null;
  @Input() base = false;
  @Input() showstep = true;
  @Input() level = 0;

  step = null;
  result = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public processvisualService: ProcessvisualService
  ) { }

  ngOnInit(): void {
    this.getStepAndResult();
    this.level ++;
  }

  getStepAndResult() {
    if (this.resultid) {
      this.result = this.applicationInfoService.results.Item(this.resultid.toString());
    }
    if (this.stepid) {
      this.step = this.applicationInfoService.steps.Item(this.stepid.toString());
    }
    
    console.warn('Result', this.resultid, this.result);
    console.warn('Step', this.stepid, this.step);
  }

  getResultInfo(resultId) {
    const result = this.applicationInfoService.results.Item(resultId.toString());
    return result;
  }

  getStepInfo(stepId) {
    const step = this.applicationInfoService.steps.Item(stepId.toString());
    return step;
  }
}
