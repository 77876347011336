<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <div *ngIf="wizardService.wizardPage == 1">
            <label class="wizardSubheader__headerLabel">{{ 'EMailContent.Label.Information' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'EMailContent.PageInfo.Page1' | texttransform
                }}</label>
        </div>
        <div *ngIf="wizardService.wizardPage == 2">
            <label class="wizardSubheader__headerLabel">{{ 'EMailContent.Label.Editor' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'EMailContent.PageInfo.Page2' | texttransform
                }}</label>
        </div>
        <div *ngIf="wizardService.wizardPage == 3">
            <label class="wizardSubheader__headerLabel">{{ 'EMailContent.Label.Publish' | texttransform }}</label>
            <label class="wizardSubheader__descriptionLabel">{{ 'EMailContent.PageInfo.Page3' | texttransform
                }}</label>
        </div>
    </div>

    <div *ngIf="wizardService.wizardPage == 1" class="maxHeight">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailContent.Label.DefaultName' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.DefaultName"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.defaultName" type="text" class="wizardInput" (change)="checkDefaultName()">
        </div>
        <div class="wizardDetailItem">
            <label class="noPadding wizardLabel">
                {{ 'EMailContent.Label.IsEditable' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.IsEditable"></app-wizard-infobutton>
            </label>
            <input [(ngModel)]="wizardService.wizardArray.isEditable" type="checkbox">
        </div>
    </div>

    <div *ngIf="wizardService.wizardPage == 2" class="wizardPage">
        <div class="wizardEMail__wrapper">            
            <div class="wizardDetailItem wizardEMail__emailFlex">            
                <div class="wizardEMail__emailEditBar__item">
                    <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(1)"
                        [ngClass]="{ activeColor: viewMode == 1 }">{{ 'EMailContent.Label.Editor' | texttransform }}</label>
                    <label class="wizardEMail__emailEditBar__headerLabel fingerCursor" (click)="setViewMode(2)"
                        [ngClass]="{ activeColor: viewMode == 2 }">{{ 'EMailContent.Label.Preview' | texttransform
                        }}</label>
                </div>
                <div class="maxHeight" *ngIf="viewMode == 1">
                    <textarea #templateContentEditor class="wizardEMail__wizardEmailEditor"
                        [(ngModel)]="wizardService.wizardArray.content" (click)="getCaretPos(templateContentEditor)"
                        (keyup)="getCaretPos(templateContentEditor)">
                </textarea>
                </div>
                <div class="maxHeight" *ngIf="viewMode == 2">
                    <div [innerHTML]="innerHTML">

                    </div>
                </div>
            </div>            
            <div class="wizardDetailItem wizardEMail__addons" *ngIf="viewMode == 1">
                <div class="wizardEMail__emailEditBar__item">
                    {{ 'EMailContent.Label.Placeholders' | texttransform }}
                </div>       
                    
                    <div class="wizardEMail__addons__content">                    
                        <div class="wizardEMail__placeholder" *ngFor="let placeholder of placeholders">
                            <i class="fas fa-chevron-circle-left fingerCursor" (click)="addMemberToTemplate(placeholder)"></i>
                            {{ getPlaceHolderInfo(placeholder) }}
                        </div>    
                    </div>             
            </div>
        </div>
    </div>
    <div *ngIf="wizardService.wizardPage == 3" class="maxHeight">
        <div class="wizardDetailItem">
            <label class="wizardLabel">
                {{ 'EMailContent.Label.Publish' | texttransform }}
                <app-wizard-infobutton displayText="EMailContent.Info.Publish"></app-wizard-infobutton>
            </label>
            <label class="wizardLabel">
                <input [(ngModel)]="wizardService.wizardArray.isPublished" type="checkbox">
            </label>
        </div>        
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'EMailContent.Label.LastUpdate' | texttransform }}</label>
            <label class="wizardLabel">{{ wizardService.wizardArray.lastModifiedDate | date:'medium' }}</label>
        </div>        
    </div>
</div>