import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-mat-table-supervisor',
  templateUrl: './mat-table-supervisor.component.html',
  styleUrls: ['./mat-table-supervisor.component.scss']
})
export class MatTableSupervisorComponent implements OnInit {  
  controlDefinition = null;
  selectedEntity = null;
  specialArray = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private designerService: DesignerService,
    private loaderService: LoaderService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.controlDefinition = this.applicationInfoService.miscSettings['matTableSupervisorControl'];
    this.fillSpecialArray(0);
  }

  fillSpecialArray(position: any) {
    if (!this.commonService.isNullOrUndefined(this.controlDefinition.uiEntityInstanceId)) {
      this.specialArray[position] = [];
      this.specialArray[position].push({ entity:
        this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId), prefix: ''});

      if (!this.commonService.isNullOrUndefined(this.controlDefinition.entity)) {
        const additionalEntities = this.controlDefinition.entity.split(';');
        additionalEntities.forEach(entity => {
          const entityDetails = entity.split('-');
          if (entityDetails.length > 1) {
            this.specialArray[position].push({
              entity: this.applicationInfoService.entities.Item(entityDetails[1]), prefix: entityDetails[0] });
          } else {
            this.specialArray[position].push({ entity: this.applicationInfoService.entities.Item(entity), prefix: '' });
          }
        });
      }
      if (this.specialArray[position].length > 0) {
        this.selectedEntity = this.specialArray[position][0];
      }
    }
  }

  addToContainedControls(entity, item) {
    let order = 0;
    this.controlDefinition.childs.forEach(child => {
      if (order < child.order) { order = child.order; }
    });
    order ++;
    let subControl = this.designerService.createNewControl();
    subControl['displayText'] = this.commonService.getNameTranslationOrDefaultName(item);
    subControl['parentControlId'] = this.controlDefinition.id;
    subControl['controlType'] = null;    
    subControl['order'] = order;
    subControl['isFieldFilterable'] = true;
    subControl['isResetVisible'] = true;        
    subControl['isActive'] = true;
    subControl['isVisible'] = true;
    subControl['isEnabled'] = true;
    this.loaderService.display(true);
    this.designerService.saveControl(subControl)
    .then(saveSubControlResult => {
      let subControlChild = this.designerService.createNewControl();    
      subControlChild['value'] = item.defaultName;
      subControlChild['controlType'] = null;
      subControlChild['parentControlId'] = saveSubControlResult.id;
      subControlChild['isActive'] = true;
      subControlChild['isVisible'] = true;
      subControlChild['uiEntityInstanceId'] = this.controlDefinition.uiEntityInstanceId;
      this.designerService.saveControl(subControlChild)
      .then(() => {
        this.loaderService.display(false);
        subControl['projectId'] = this.applicationInfoService.projectID;
        subControlChild['projectId'] = this.applicationInfoService.projectID;  
        subControl.childs.push(subControlChild);
        this.controlDefinition.childs.push(subControl);
        this.eventService.matTableHeaderItemsUpdatedEvent.emit();
      }).catch(()=> { this.loaderService.display(false); });
    }).catch(()=> { this.loaderService.display(false); });
  }

  checkIfMemberShouldBeHidden() {
    return false;
  }

  deleteControl(child) {
    const promiseArray: Promise<any>[] = [];
    this.loaderService.display(true);
    child.childs.forEach(subchild => {
      promiseArray.push(this.designerService.deleteControl(subchild));
    });    
    Promise.all(promiseArray)
    .then(() => {      
      const controlToRemove = this.controlDefinition.childs.find(arrayItem => arrayItem.id == child.id);
      this.commonService.removeItemFromArray(this.controlDefinition.childs, controlToRemove);      
      this.designerService.deleteControl(child).then(()=> { 
        this.loaderService.display(false);
        this.eventService.matTableHeaderItemsUpdatedEvent.emit();         
      });
    }).catch(()=> { this.loaderService.display(false); });    
  }

  isControlDeleteable(child) {
    let returnValue = true;
    if (child.childs) {
      if (child.childs[0].controlType == 1) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  checkSpecialText(child) {
    let returnValue = '';
    if (child.childs) {
      if (child.childs[0].controlType == 1) {
        returnValue = 'Icon';
      }
    }
    return returnValue;
  }
}
