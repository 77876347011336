<div class="conversionEditorTop">
    <div class="conversionEditorItem">
        <div class="conversionEditorItem__label">
            {{ 'ConversionEditor.Label.Contact' | texttransform }}
        </div>
        <div class="conversionEditorItem__value ellipsisText">
            {{ conversion?.contact.firstName }} {{ conversion?.contact.lastName }}
        </div>
    </div>

    <div class="conversionEditorItem">
        <div class="conversionEditorItem__label">
            {{ 'ConversionEditor.Label.Asset' | texttransform }}
        </div>
        <div class="conversionEditorItem__value ellipsisText">
            {{ conversion.conversionAssetName }} ({{ conversion.conversionAssetType}})
        </div>
    </div>

    <div class="conversionEditorItem">
        <div class="conversionEditorItem__label">
            {{ 'ConversionEditor.Label.Url' | texttransform }}
        </div>
        <div class="conversionEditorItem__value ellipsisText">
            {{ conversion.conversionUrl }}
        </div>
    </div>
    <div class="conversionEditorItem">
        <div class="conversionEditorItem__label">
            {{ 'ConversionEditor.Label.Date' | texttransform }}
        </div>
        <div class="conversionEditorItem__value ellipsisText">
            {{ conversion.date | date: 'medium' }}
        </div>
    </div>
    <app-conversiondetail [eventId]="conversion.eventId"></app-conversiondetail>
    <div class="conversionEditorHeader" *ngIf="isEditVisible()">
        {{ 'ConversionEditor.Label.EditableValues' | texttransform }}    
    </div>
    <div>
        <app-custom-container *ngIf="conversionLoaded" controluiid="85640" class="ConversionEditor__customizing"></app-custom-container>    
    </div>    
</div>

<div class="conversionEditorBottom" *ngIf="isEditVisible()">
    <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="saveConversion()">
        {{ 'General._.Save' | texttransform }}
    </button>    
</div>
