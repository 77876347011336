import { Component, OnInit } from "@angular/core";
import { ApplicationInfoService } from "app/core/application/application-info.service";
import { CommonService } from "app/jollyjupiter/service/common.service";
import { PackagefeatureService } from "app/jollyjupiter/service/packagefeature.service";

@Component({
  selector: "app-featuressettings",
  templateUrl: "./featuressettings.component.html",
  styleUrl: "./featuressettings.component.scss",
})
export class FeaturessettingsComponent {
  expandedIds: number[] = [];
  selectedFeature = null;
  filterString = '';
  showOnlyActive = true;

  constructor(
    public packagefeatureService: PackagefeatureService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService
  ) { }

  isExpanded(id) {
    return this.expandedIds.includes(id);
  }

  toggleExpanded(id) {
    this.isExpanded(id)
    ? this.expandedIds = this.expandedIds.filter(featureId => id !== featureId)
    : this.expandedIds.push(id);
  }

  updateFeature(featureId) {
    this.packagefeatureService.updateFeature(featureId);
  }

  addNewFeature() {
    this.packagefeatureService.createFeature().then((feature) => {
      this.expandedIds.push(feature.id);
      this.packagefeatureService.getAllFeatures();
    })
  }

  isItemFiltered(feature) {    
    return this.commonService.checkIfStringContainsString(feature.defaultName, this.filterString);    
  }

  selectFeature(feature) {
    this.selectedFeature = feature;
  }
}
