<div class="qualityManagement__wrapper">
    <div class="qualityManagement__header">
        {{ 'QualityManagement.Label.Header' | texttransform }} 
        <span class="everleadCoral" style="padding-left: 5px;" *ngIf="applicationInfoService.isDeveloper"> ( Id: {{ currentQMTask.id }} )</span>
    </div>
    <div class="qualityManagement__fixedInfo">
        <!-- <div class="qualityManagement__fixedInfo__line">
            <div class="qualityManagement__fixedInfo__line__label">{{ 'Contact.Label.FirstName' | texttransform }}</div>
            <div class="qualityManagement__fixedInfo__line__value">{{ currentQMTask.contact?.firstName }}</div>
        </div>
        <div class="qualityManagement__fixedInfo__line">
            <div class="qualityManagement__fixedInfo__line__label">{{ 'Contact.Label.LastName' | texttransform }}</div>
            <div class="qualityManagement__fixedInfo__line__value">{{ currentQMTask.contact?.lastName }}</div>
        </div> -->
        <div class="qualityManagement__fixedInfo__line">
            <div class="qualityManagement__fixedInfo__line__label">{{ 'QualityManagement.Label.Booked' | texttransform }}</div>
            <div class="qualityManagement__fixedInfo__line__value">{{ currentQMTask.tempResultDate | date: 'medium' }}</div>
        </div>
        <div class="qualityManagement__fixedInfo__line">
            <div class="qualityManagement__fixedInfo__line__label">{{ 'QualityManagement.Label.Step' | texttransform }}</div>
            <div class="qualityManagement__fixedInfo__line__value">{{ stepText }}</div>
        </div>
        <div class="qualityManagement__fixedInfo__line">
            <div class="qualityManagement__fixedInfo__line__label">{{ 'QualityManagement.Label.Campaign' | texttransform }}</div>
            <div class="qualityManagement__fixedInfo__line__value">{{ campaignText }}</div>
        </div>

        <!-- <div class="qualityManagement__fixedInfo__line">
            <div class="qualityManagement__fixedInfo__line__label">{{ 'QualityManagement.Label.User' | texttransform }}</div>
            <div class="qualityManagement__fixedInfo__line__value">{{ userText }}</div>
        </div> -->
    </div>

    <div class="qualityManagement__dynamicInfo__line">
        <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.FollowUpTo' | texttransform }}</div>
        <div class="qualityManagement__dynamicInfo__line__value">
            <select [(ngModel)]="currentQMTask.tempFollowUpUserId">
                <option *ngFor="let user of users" [ngValue]="user.id">
                    {{ user.userName }}
                </option>
            </select>
        </div>
    </div>
    <div class="qualityManagement__dynamicInfo">
        <div class="qualityManagement__dynamicInfo__line">
            <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.Subject' | texttransform }}</div>
            <div class="qualityManagement__dynamicInfo__line__value">
                <input [(ngModel)]="currentQMTask.subject" class="qualityManagement__dynamicInfo__text">
            </div>
        </div>
        <div class="qualityManagement__dynamicInfo__line">
            <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.Comment' | texttransform }}</div>
            <div class="qualityManagement__dynamicInfo__line__value">
                <textarea [(ngModel)]="currentQMTask.notes" class="qualityManagement__dynamicInfo__comment"></textarea>
            </div>
        </div>
        <div class="qualityManagement__dynamicInfo__line">
            <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.Result' | texttransform }}</div>
            <div class="qualityManagement__dynamicInfo__line__value">
                <select [(ngModel)]="currentQMTask.tempResultId" [disabled]="currentQMTask.qmStateLookupId == 40" (change)="resultChanged()">
                    <option *ngFor="let result of availableResults" [ngValue]="result.id">
                        {{ commonService.getTranslationValueFromArray(result) | texttransform }}
                    </option>
                </select>
            </div>
        </div>

        <div class="qualityManagement__dynamicInfo__line" *ngIf="questions.length > 0">
            <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.QuestionAnswers' | texttransform }}</div>
            <app-qm-questionanswers [questions]="questions" [selecteditems]="currentQMTask.questionAnswers"></app-qm-questionanswers>
        </div>
        
        <div class="qualityManagement__dynamicInfo__line">
            <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.ApprovalState' | texttransform }}</div>
            <div class="qualityManagement__dynamicInfo__line__value">
                <select [(ngModel)]="currentQMTask.qmStateLookupId">
                    <option *ngFor="let approvalState of approvalStates" [ngValue]="approvalState.id">
                        {{ commonService.getTranslationValueFromArray(approvalState) | texttransform }}
                    </option>
                </select>
            </div>
        </div>
        
        <div *ngIf="currentQMTask.qmStateLookupId == 40">
            <label class="everleadCoral qualityManagement__declinedMessage">
                {{ 'QualityManagement.Label.DeclineMessage' | texttransform }}
            </label>
            <div class="qualityManagement__dynamicInfo__line">
                <div class="qualityManagement__dynamicInfo__line__label qualityManagement__dynamicInfo__line__resubmission">
                    <input [(ngModel)]="currentQMTask.isFollowUpAppointment" type="checkbox" 
                        class="qualityManagement__dynamicInfo__checkbox">
                    {{ 'QualityManagement.Label.PersonalResubmission' | texttransform }}
                </div>
                
            </div>
            <div class="qualityManagement__dynamicInfo__line">
                <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.PersonalResubmissionTime' | texttransform }}</div>
                <div class="qualityManagement__dynamicInfo__line__value">
                    <input [(ngModel)]="currentQMTask.followUpDate" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" 
                        class="qualityManagement__dynamicInfo__text" appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>     
                </div>
            </div>                                    
            
            <div class="qualityManagement__dynamicInfo__line"l>
                <div class="qualityManagement__dynamicInfo__line__label">{{ 'QualityManagement.Label.RejectText' | texttransform }}</div>
                <div class="qualityManagement__dynamicInfo__line__value">
                    <textarea [(ngModel)]="currentQMTask.qmRejectedText" class="qualityManagement__dynamicInfo__comment"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="qualityManagement__buttons">
        <button class="everleadCustomButton" [disabled]="isConfirmDisabled()" (click)="confirmQM()">
            {{ 'QualityManagement.Label.ConfirmQM' | texttransform }}
        </button>
    </div>
</div>