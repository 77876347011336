import { Component, OnInit, Injector } from '@angular/core';

import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';
import { ExternalDataSource_QueryType } from 'app/jollyjupiter/service/externaldatasource.service';
import { ObjectDeserializeService } from 'app/jollyjupiter/pipe/object-deserialize.service';
import { Dictionary } from 'app/core/dictionary';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-custom-label',
  templateUrl: './custom-label.component.html',
  styleUrls: ['./custom-label.component.scss']
})
export class CustomLabelComponent extends CustomControlBaseClass implements OnInit {
  externalDataSourceResults: Dictionary<any> = new Dictionary();

  constructor(
    private injector: Injector,
    private objectDeserializeService: ObjectDeserializeService
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  getEntityLabelText() {
    if (this.entity != null) {
      const baseValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
      if (this.controlDefinition.uiControlExternalDataSources.length > 0) {
        this.controlValue = this.externalDataSourceResults.Item(baseValue);
      } else {
        let entityValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
        if (!isNullOrUndefined(entityValue)) {
          entityValue = entityValue.toString();
        }
        this.controlValue = entityValue;
      }
    }
    return this.controlValue;
  }

  fillExternalDataSourceDictionary(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.controlDefinition.uiControlExternalDataSources.length > 0) {
        let extDataSource = null;
        this.controlDefinition.uiControlExternalDataSources.forEach(uiControlExternalDataSource => {
          if (uiControlExternalDataSource.externalDataSource.queryType.toLowerCase() === ExternalDataSource_QueryType.Query) {
            extDataSource = uiControlExternalDataSource.externalDataSource;
            this.externalDatasourceService.executeQuery(extDataSource)
            .then(result => {
              result.forEach(resultElement => {
                this.externalDataSourceResults.Add(resultElement[extDataSource.dataKey], resultElement[extDataSource.dataValue]);
              });
            })
            .catch(error => { reject(error); });
          }
        });
        resolve(null);
      } else {
        resolve(null);
      }
    });
  }

  ngOnInit() {
    this.updateEntityEventSubscription = this.eventService.updateEntityEvent.subscribe(event => {
        this.getEntityLabelText();
    });

    this.updateEntityDataPropertyEventSubscription = this.eventService.updateEntityDataPropertyEvent.subscribe(event => {
      this.getEntityLabelText();
    });

    this.fillExternalDataSourceDictionary()
      .then(() => { this.getEntityLabelText(); })
      .catch(error => { console.error('CustomLabel', error); });
    this.checkAttributes();
  }

  getClasses() {
    let returnValue = 'everleadBlueColor labelWrapping' + ' ' + this.controlDefinition.additionalCssClasses;
    if (this.applicationInfoService.useNewDesign) {
      returnValue = returnValue.concat(' labelFont');
    }
    return returnValue;
  }
}
