<div (click)="itemClicked()" [tippy]="optArrayString" [ngStyle]="setControlStyle('control')">
    <div class="row optInOptOutRow">
        <div class="col-md-12">
            <div class="icon-text">
                <div class="label">
                    {{ optInOptOutText | texttransform}}
                    <i class="fas fa-thumbs-up fa-2x optInOptOutIcon" *ngIf="currentOptState == optState.optIn"></i>
                    <i class="fas fa-thumbs-down  fa-2x optInOptOutIcon optInOptOutIcon__red" *ngIf="currentOptState == optState.optOut"></i>
                </div>
            </div>
        </div>
    </div>
</div>