import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public confirmAcceptText = ' ';
  public confirmRejectText = ' ';

  private confirmationService: ConfirmationService;

  @Input() dialogId = '';
  @Input() header: string;
  @Input() message: string;
  @Input() acceptLabel = '';
  @Input() rejectLabel = '';
  @Input() textIcon = '';
  @Input() acceptIcon = 'pi pi-check';
  @Input() rejectIcon = 'pi pi-times';

  constructor(confirmationService: ConfirmationService) {
    this.confirmationService = confirmationService;
    // this.translateService.onLangChange.subscribe(
    //   (event: LangChangeEvent) => {
    //     this.setLabelTexts();
    //   }
    // );
  }

  setLabelTexts() {
    if (!this.acceptLabel || this.acceptLabel === '') {
      // this.translateService.get('general.button.Yes')
      // .subscribe(
      //   (label: string) => {
      //     this.confirmAcceptText = label;
      // });
    } else {
      this.confirmAcceptText = this.acceptLabel;
    }

    if (!this.rejectLabel || this.rejectLabel === '') {
      // this.translateService.get('general.button.No')
      //   .subscribe(
      //     (label: string) => {
      //       this.confirmRejectText = label;
      // });
    } else {
      this.confirmRejectText = this.rejectLabel;
    }

    if (!this.header || this.header === '') {
      // this.translateService.get('general.headline.ConfirmationModalHeader')
      //   .subscribe(
      //     (label: string) => {
      //       this.header = label;
      // });
    }
  }

  ngOnInit() {
    this.setLabelTexts();
  }

}
