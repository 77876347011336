import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-eventassignment',
  templateUrl: './wizard-eventassignment.component.html',
  styleUrls: ['./wizard-eventassignment.component.scss']
})
export class WizardEventassignmentComponent implements OnInit {
  eventTrigger = [];
  eventData = [];
  eventFilter = '';
  workflowStepResultEventTriggers = [];
  selectedTrigger = null;
  campaigns = null;

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private loaderService: LoaderService
  ) { }


  ngOnInit(): void {
    this.getEventData();
    this.getEventTrigger();
    this.getAssignedTriggers();
    this.getCampaigns();
  }

  getCampaigns() {
    this.externaldatasourceService.executeExternalDataSource(269).then(getCampaignsResult => { this.campaigns = getCampaignsResult; });
  }

  getEventTrigger() {
    this.externaldatasourceService.executeExternalDataSource(478)
    .then(getEventTriggerResult => { 
      this.eventTrigger = getEventTriggerResult;
    });
  }

  getAssignedTriggers() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(489)
    .then(getEventTriggerResult => { 
      this.workflowStepResultEventTriggers = getEventTriggerResult;
      console.warn('getAssignedTriggers', getEventTriggerResult);
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });    
  }

  getEventData() {
    this.externaldatasourceService.executeExternalDataSource(645)
    .then(getEventDataResult => { 
      this.eventData = getEventDataResult;
    });
  }

  isItemFiltered(event) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(event.url, this.eventFilter)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(event.type, this.eventFilter)) {
      returnValue = false;
    }
    return returnValue;
  }

  setEventInfo(event) {
    this.wizardService.wizardArray.subUrl = event.url;
    this.wizardService.wizardArray.eventType = event.type;
  }

  newEventTrigger() {
    this.wizardService.wizardPage = 2;
  }

  createNewEventTrigger() {
    this.wizardService.wizardPage = 2;
  }

  isAssignedToResult(eventTriggerItem) {
    const assignedItem = this.workflowStepResultEventTriggers.find(workflowStepResultEventTrigger =>
      workflowStepResultEventTrigger.eventTriggerId == eventTriggerItem.id
    );
    if (assignedItem) {
      return true;
    } else {
      return false;
    }
  }

  addEventTriggerToResultInit(eventTriggerItem) {
    this.selectedTrigger = new Object();
    this.selectedTrigger.workflowStepResultId = this.wizardService.wizardArray.newTaskWorkflowResultId;
    this.selectedTrigger.eventTriggerId = eventTriggerItem.id;
    this.selectedTrigger.newTaskCampaignId = null;
    this.selectedTrigger.subUrl = eventTriggerItem.subUrl;
    this.selectedTrigger.eventType = eventTriggerItem.eventType;
    this.selectedTrigger.handlebarsjsPostExecute = '';
    this.selectedTrigger.handlebarsjsPostExecute = '';
    this.wizardService.wizardPage = 2;    
  }

  addEventTriggerToResult() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(486, [
      this.commonService.getModifyArrayBody(this.selectedTrigger, ['subUrl', 'eventType'])
    ])
    .then(() => { 
      this.getAssignedTriggers();
      this.loaderService.display(false);
      this.wizardService.wizardPage = 1;
    }).catch(() => { this.loaderService.display(false); });
  }

  removeEventTriggerToResult(trigger) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(488, [trigger.id])
    .then(() => { 
      this.getAssignedTriggers();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  updateEventTrigger(eventTrigger) {
    this.externaldatasourceService.executeExternalDataSource(487, [
      this.commonService.getModifyArrayBody(eventTrigger, [])
    ]).then(() => {});
  }

  getTriggerValue(eventTriggerItem, value) {
    const trigger = this.eventTrigger.find(trigger => trigger.id == eventTriggerItem.eventTriggerId);
    if (trigger) {
      return trigger[value];
    } else {
      return '';
    }
  }
}
