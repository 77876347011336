import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-leadscore',
  templateUrl: './icon-leadscore.component2.svg',
  styleUrls: ['./icon-leadscore.component.scss'],
})
export class IconLeadscoreComponent implements OnInit {
  // valueNumber: any;
  @Input () value = 0.3;
  @Input () height = 60;
  @Input () width = 60;
  constructor() { }

  ngOnInit(): void {
    // this.valueNumber = this.value;
  }

}
