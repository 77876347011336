import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EntityService, ValueType } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-singleaction',
  templateUrl: './singleaction.component.html',
  styleUrls: ['./singleaction.component.scss']
})
export class SingleactionComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="24929"> </jj-container>`;
  innerHTML = null;
  singleActionPrepared = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private entitySerivce: EntityService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.prepareSingleAction();
  }

  prepareSingleAction() {
    this.entitySerivce.updateValuebyType('100', 'campaignId', this.applicationInfoService.projectInfo.defaultCampaignId, ValueType.Number);
    this.entitySerivce.updateValuebyType('100', 'processId',  this.applicationInfoService.projectInfo.defaultWorkflowId, ValueType.Number);
    this.singleActionPrepared = true;
  }
}
