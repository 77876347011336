<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="wizardEvent__eventWrapper">      
        <div class="eventTriggerList__dataLine">
            <label>{{ 'EventTrigger.Label.EventType' | texttransform }}</label>
            {{selectedTrigger.eventType}}
        </div>
        <div class="eventTriggerList__dataLine">
            <label>{{ 'EventTrigger.Label.SubUrl' | texttransform }}</label>
            {{selectedTrigger.subUrl}}
        </div>
        <div class="eventTriggerList__dataLine">
            <label>{{ 'Task.Label.Campaign' | texttransform }}</label>
            <select [(ngModel)]="selectedTrigger.newTaskCampaignId">
                <option [ngValue]=null></option>
                <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">{{ campaign.defaultName }}</option>
            </select>
        </div>
        <br>
        <div>
            <button class="jjButtonStyle jjButtonColor" (click)="addEventTriggerToResult()" 
                [disabled]="selectedTrigger.newTaskCampaignId == null || selectedTrigger.defaultName == ''">
                {{ 'EventTrigger.Label.CreateNewTrigger' | texttransform }}
            </button>
        </div>
    </div>

    <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1">
        <div class="everleadSmallHeader jjBold" *ngIf="workflowStepResultEventTriggers.length > 0">{{ 'Task.Label.ConnectedEventTrigger' | texttransform }}</div>
        <div class="eventTriggerList" *ngIf="workflowStepResultEventTriggers.length > 0">
            <div *ngFor="let eventTriggerItem of workflowStepResultEventTriggers" class="everleadShadowBox eventTriggerList__item">
                    <div class="eventTriggerList__dataLine">
                        <label class="everleadSmallHeader">{{ 'EventTrigger.Label.EventType' | texttransform }}</label>
                        <div class="maxWidth everleadCoral jjBold">{{getTriggerValue(eventTriggerItem, 'eventType')}}</div>
                        <i class="fas fa-trash everleadCoral fingerCursor" (click)="removeEventTriggerToResult(eventTriggerItem)"></i>
                    </div>
                    <div class="eventTriggerList__dataLine">
                        <label class="everleadSmallHeader">{{ 'EventTrigger.Label.SubUrl' | texttransform }}</label>
                        {{getTriggerValue(eventTriggerItem, 'subUrl')}}
                    </div>
                    <div class="eventTriggerList__dataLine">
                        <label class="everleadSmallHeader">{{ 'Task.Label.Campaign' | texttransform }}</label>
                        <select [(ngModel)]="eventTriggerItem.newTaskCampaignId" (change)="updateEventTrigger(eventTriggerItem)">
                            <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">{{ campaign.defaultName }}</option>
                        </select>
                    </div>
                    <!-- <div class="eventTriggerList__dataLine" *ngIf="applicationInfoService.isDeveloper">
                        <label class="everleadSmallHeader">handlebarsjsPreExecute</label>
                        <textarea [(ngModel)]="eventTriggerItem.handlebarsjsPreExecute" (change)="updateEventTrigger(eventTriggerItem)"></textarea>
                    </div>
                    <div class="eventTriggerList__dataLine" *ngIf="applicationInfoService.isDeveloper">
                        <label class="everleadSmallHeader">handlebarsjsPostExecute</label>
                        <textarea [(ngModel)]="eventTriggerItem.handlebarsjsPostExecute" (change)="updateEventTrigger(eventTriggerItem)"></textarea>
                    </div> -->
            </div>
        </div>

        <div class="everleadSmallHeader jjBold">{{ 'Task.Label.AvailableEventTrigger' | texttransform }}</div>
        <div class="everleadSmallHeader">{{ 'Task.Label.AvailableEventTriggerDescription' | texttransform }}</div>
        <div *ngIf="eventTrigger.length == 0">
            {{ 'Task.Label.NoTriggersAvailable' | texttransform }}
        </div>

        <div class="eventTriggerList">
            <div *ngFor="let eventTriggerItem of eventTrigger" class="everleadShadowBox eventTriggerList__item fingerCursor" [ngClass]="{
                hideInDom: isAssignedToResult(eventTriggerItem)
            }" (click)="addEventTriggerToResultInit(eventTriggerItem)">
                <div class="jjBold">{{ commonService.getNameTranslationOrDefaultName(eventTriggerItem) | texttransform }}</div>
                <div class="">{{eventTriggerItem.eventType}}</div>
                <div>{{eventTriggerItem.subUrl}}</div>
            </div>
        </div>
    </div>
</div>