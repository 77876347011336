import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-trialextension',
  templateUrl: './trialextension.component.html',
  styleUrls: ['./trialextension.component.scss']
})
export class TrialextensionComponent implements OnInit {
  message = 'loading...';
  constructor(
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.commonService.setSessionStorageItem('trialextensionValuesCreated', 'true');
    this.applicationInfoService.noProject = true;
    setTimeout(() => { this.checkTrial(); }, 3000);
  }

  checkTrial() {
    if (this.applicationInfoService.user.trialExtensionGuid == null) {
      this.showOopsPage();
    } else {
      this.extendTrial();
    }
  }

  extendTrial() {
      this.sharedAPI.extendTrial(this.applicationInfoService.user.trialExtensionGuid)
      .subscribe(
        result => { 
          this.showSuccessPage(); 
        },
        error => { 
          this.commonService.clearSessionStorageItem('trialextensionValuesCreated');
          if (this.applicationInfoService.user.userLanguage.id == 2) {
            // this.message = 'Bei der Verlängerung ist ein Fehler aufgetreten...';
            location.replace(environment.renewalFailedDE);
          } else {
            location.replace(environment.renewalFailedEN);
          }          
        }
      );      
  }

  showSuccessPage() {
    this.commonService.clearSessionStorageItem('trialextensionValuesCreated');
    let referUrl = environment.renewalThankYouEN;
    if (this.applicationInfoService.user.userLanguage.id == 2) {
      referUrl = environment.renewalThankYouDE;
    }
    location.replace(referUrl);
  }

  showOopsPage() {
    this.commonService.clearSessionStorageItem('trialextensionValuesCreated');
    if (this.applicationInfoService.user.userLanguage.id == 2) {
      location.replace(environment.renewalOopsDE);
    } else {
      location.replace(environment.renewalOopsEN);
    }
  }
}

