import { ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, Directive, 
  ElementRef, Injector, Input, OnInit, HostListener, EmbeddedViewRef } 
from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[tooltip]'
})

export class TooltipDirective {
  customEventSubscription: Subscription = new Subscription();
  @Input() tooltip = '';
  @Input() tooltipHeader = '';
  @Input() showTooltipTemplate = false;  
  mouseX = 0;
  mouseY = 0;
  delay = 500;
  showItem = true;  

  private componentRef: ComponentRef<any> = null;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef, 
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private eventService: EventService
  ) {      
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {    
    this.showItem = true;
    if (this.componentRef === null) {
        const componentFactory =
              this.componentFactoryResolver.resolveComponentFactory(
              TooltipComponent);
        this.componentRef = componentFactory.create(this.injector);        
        this.appRef.attachView(this.componentRef.hostView);        
        const domElem = 
              (this.componentRef.hostView as EmbeddedViewRef<any>)
              .rootNodes[0] as HTMLElement;       
        document.body.appendChild(domElem);
        this.setTooltipComponentProperties(event);  
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.eventService.customEvent.emit({ id: 'destroyTooltip' })
    this.destroy();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe();  }
    this.destroy();
  }

  destroy(): void {
    this.showItem = false;
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'destroyTooltip') {
        this.destroy();  
      }
    });
  }

  private setTooltipComponentProperties(event) {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.tooltipHeader = this.tooltipHeader;
      this.componentRef.instance.showTooltipTemplate = this.showTooltipTemplate;
      const {left, right, bottom, top} = 		  	
            this.elementRef.nativeElement.getBoundingClientRect();
      const height = bottom - top;
      this.componentRef.instance.left = event.clientX
      this.componentRef.instance.top = event.clientY
    }
  }
}
