<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <button (click)="saveCustomer()" class="jjControlSubmenuButton" style="white-space: normal;"><i
            class="fas fa-save"></i></button>
        <button (click)="addCustomer()" class="jjControlSubmenuButton" style="white-space: normal;"><i
            class="fas fa-plus-square"></i></button>
    </div>
    <div class="maxWidth">
        
    </div>   
    <div class="projectSettings__header__interaction">             
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>

<!-- <div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor leadStateHeaderDiv">
        <label style="padding-left: 3px;"
            class="normalWhiteSpace">Kundenverwaltung</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
        <button (click)="saveCustomer()" class="jjControlSubmenuButton" style="white-space: normal;"><i
                class="fas fa-save"></i></button>
        <button (click)="addCustomer()" class="jjControlSubmenuButton" style="white-space: normal;"><i
                class="fas fa-plus-square"></i></button>        
        <input [(ngModel)]="filterString" style="height: 21px; margin-top: 2px; margin-right: 3px;" class="floatRight">                
        <i class="fas fa-filter floatRight" style="margin-top: 5px; margin-right: 3px;"></i>
    </div>
</div> -->

<div *ngFor="let customer of customers" class="everleadShadowBox" [ngClass]="{ 
    hideInDom: !commonService.checkIfStringContainsString(customer.customerName, filterString),
    adminCustomerNewItem: customer.id==null
}">
    <div >
        <div class="adminCustomerSectionDiv">
            <label class="adminCustomerAttributeLabel jjBold">Kundenname</label>
            <input type="text" [(ngModel)]="customer.customerName" class="adminCustomerNameInput">
        </div>
        <div class="adminCustomerSectionDiv">
            <label class="adminCustomerAttributeLabel">defaultDataInstanceId</label>
            <select [(ngModel)]="customer.defaultDataInstanceId" class="adminCustomerDropdown">
                <option [ngValue]=null></option>
                <option [ngValue]=dataInstance.id *ngFor="let dataInstance of dataInstances" title="{{ dataInstance.alias }} > {{ dataInstance.postUrl }} > {{ dataInstance.dataEndpoint }}">
                    {{ dataInstance.alias }}
                </option>
            </select>
        </div>
        <div class="adminCustomerSectionDiv">
            <label class="adminCustomerAttributeLabel">defaultDataInstanceDatabaseDetailId</label>
            <select [(ngModel)]="customer.defaultDataInstanceDatabaseDetailId" class="adminCustomerDropdown">
                <option [ngValue]=null></option>
                <option [ngValue]=dataInstanceDetail.id *ngFor="let dataInstanceDetail of dataInstanceDetailArray[customer.defaultDataInstanceId]">
                    {{ dataInstanceDetail.schema }}
                </option>                
            </select>
        </div>
    </div>
</div>