import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-conversion-list',
  templateUrl: './conversion-list.component.html',
  styleUrls: ['./conversion-list.component.scss']
})
export class ConversionListComponent implements OnInit {
  public innerHTML = null;
  innerHTMLContent = `<jj-container controluiid="24849" isinline="1"> </jj-container>`;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
  }

}
