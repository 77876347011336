@if (!packagefeatureService.allPackages.length) {
<div style="height: 22px;">
  <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
</div>
}
<div class="featuresPackagessettings">
  @for (featuresPackage of packagefeatureService.allPackages; track featuresPackage.id; let i = $index) {
  <div class="everleadShadowBox">
    <div class="featuresPackagessettings__header fingerCursor" (click)="toggleExpanded(featuresPackage.id)">
      <h4>{{featuresPackage.defaultName}}</h4>
      <i class="fas fingerCursor fa-chevron-{{isExpanded(featuresPackage.id) ? 'up' : 'down'}}"></i>
    </div>
    <div class="featuresPackagessettings__body" [ngClass]="{'hideInDom': !isExpanded(featuresPackage.id)}">
      <div class="featuresPackagessettings__field">
        <label for="featuresPackage-name-{{i}}">Feature Name</label>
        <input [(ngModel)]="featuresPackage.defaultName" id="featuresPackage-name-{{i}}" type="text" (change)="updateFeaturesPackage(featuresPackage.id)">
      </div>

      <div class="featuresPackagessettings__field">
        <label for="featuresPackage-active-{{i}}">Is Active</label>
        <input [(ngModel)]="featuresPackage.isActive" id="featuresPackage-active-{{i}}" type="checkbox" (change)="updateFeaturesPackage(featuresPackage.id)">
      </div>

      <div class="featuresPackagessettings__field">
        <label for="featuresPackage-notes-{{i}}">Notes</label>
        <textarea [(ngModel)]="featuresPackage.notes" id="featuresPackage-notes-{{i}}" (change)="updateFeaturesPackage(featuresPackage.id)"></textarea>
      </div>

      <div class="featuresPackagessettings__field">
        <label for="featuresPackage-settings-{{i}}">Settings</label>
        <textarea [(ngModel)]="featuresPackage.settings" id="featuresPackage-settings-{{i}}" rows="4"
          (change)="updateFeaturesPackage(featuresPackage.id)"></textarea>
      </div>
      <div class="featuresPackagessettings__field">
        <label>Features</label>
        <div class="featuresPackagessettings__features-list">
          @for (feature of packagefeatureService.allFeatures; track feature.id; let j = $index) {
          <div class="featuresPackagessettings__features-item">
            <input
              id="feature-{{feature.defaultName}}-{{i}}-{{j}}"
              type="checkbox"
              [checked]="isPackageHasFeature(featuresPackage, feature)"
              (change)="updatePackageHasFeature(featuresPackage, featuresPackage.id, feature.id, $event.target.checked)"
            >
            <label for="feature-{{feature.defaultName}}-{{i}}-{{j}}">{{feature.defaultName}}</label>
          </div>
        }
        </div>
      </div>
    </div>

  </div>
  }

  <button class="everleadCustomButton" (click)="addNewFeaturesPackage()">Add Features Package</button>

</div>
