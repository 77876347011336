import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-dashboardcontrol-optinoptout',
  templateUrl: './custom-dashboardcontrol-optinoptout.component.html',
  styleUrls: ['./custom-dashboardcontrol-optinoptout.component.scss']
})
export class CustomDashboardcontrolOptinoptoutComponent implements OnInit {
  optinOptouts = [];
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private methodService: MethodService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getOptinOptouts();
  }

  getOptinOptouts() {
    this.externaldatasourceService.executeExternalDataSource(775, [])
    .then(getOptinOptoutsResults => {
      this.optinOptouts = getOptinOptoutsResults;
    });
  }

}
