<div style="padding-bottom: 10px;" class="controlInlineBlock">
    <div class="jjControlDefaultColor leadStateHeaderDiv">
        <label style="padding-left: 3px;" class="normalWhiteSpace">Query-Builder</label>
    </div>
    <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
        <button (click)="saveQuery()" class="jjControlSubmenuButton" style="white-space: normal;"
            title="Query speichern"><i class="fas fa-save"></i></button>
        <button (click)="testQuery()" class="jjControlSubmenuButton" style="white-space: normal;"
            title="Query testen"><i class="fas fa-play"></i></button>
    </div>
</div>

<div style="width: 60%" class=" inline">
    <textarea [(ngModel)]=query.query.queryTemplate style="width: 100%; height: 600px;"></textarea>
</div>
<div style="width: 38%; margin-left: 15px" class=" inline">
    <app-admin-report-parameter [selectedReport]="report" queryBuilderMode=1></app-admin-report-parameter>
    <!-- <label class="adminReportQueryLabel">Connection</label>
    <select [(ngModel)]="reportQuery.query.queryConnectionGroupId" class="adminReportQueryData">
        <option *ngFor="let connectionGroup of connectionGroups" [ngValue]=connectionGroup.id>
            {{ connectionGroup.defaultName }}
        </option>
    </select> -->
</div>

<ng-container *ngIf="queryResult != null">
    <hr><label class="jjBold">Ergebnis</label><br>
    <table style="table-layout: fixed;" class="scroll">
        <thead>
        <tr>
            <th *ngFor="let header of queryResult.headers; let i = index" style="border-right: 1px solid gainsboro;"
                [style.max-width.px]=200 [style.width.px]=200><label class="ellipsisText"
                    [tippy]=header.name style="margin: 0px; ">{{ header.name }}</label></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dataItem of queryResult.data">
            <td *ngFor="let value of dataItem" [style.max-width.px]=200 [style.width.px]=200
                style="border-right: 1px solid gainsboro;">                
                <label class="ellipsisText" style="margin: 0px;" [tippy]="value">{{ value }}</label>
            </td>
        </tr>
    </tbody>
    </table>    
</ng-container>