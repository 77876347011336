<div class="projectSettings__header">
    <div class="projectSettings__header__newItem fingerCursor" (click)="createNewPool()" *ngIf="userService.hasPermission('CanCreatePool')">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            [tippy]="'General.Label.CreateNewWithDefault' | texttransform"[tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
        {{ 'PoolDesign.Label.NewPool' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction">
        <div class="projectSettings__header__showOnlyActive">
            <input [(ngModel)]="showOnlyActive" type="checkbox">
            {{ 'ProcessDesign.Label.ShowOnlyActive' | texttransform }}            
        </div>
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>

<span *ngIf="loadingData==true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</span>

<span id="projectSettingsDesignSpan"></span>
<div class="projectSettings__wrapper" [style.height]="getTableHeight()" *ngIf="userService.hasPermission('CanReadPool') && loadingData == false">    
    <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
        <ng-container matColumnDef="defaultName" [sticky]="!applicationInfoService.isMobile()">
            <th mat-header-cell *matHeaderCellDef>{{ 'PoolDesign.Label.DefaultName' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">  
                <div class="projectSettings__name">
                    <label *ngIf="applicationInfoService.isDeveloper" style="margin-right: 10px;" class="everleadCoral">{{ element.id }}</label>
                    <input [(ngModel)]="element.defaultName" type="text" (change)="savePool(element)" class="projectSettings__inputMin maxWidth">                    
                    <app-wizard-translations [array]="element" updateBaseArrayExternaldatasourceId=583 mode="3"></app-wizard-translations>    
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>{{ 'PoolDesign.Label.IsActive' | texttransform }}</th>
            <td mat-cell *matCellDef="let element" class="poolIsActive">            
                <input [(ngModel)]="element.isActive" type="checkbox" (change)="savePool(element)" [tippy]="'CampaignDesign.Label.IsActive' | texttransform" [tippyOptions]="{placement:'bottom'}">                    
            </td>
        </ng-container>

    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
            hideInDom: (row.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
            projectSettings__isInactive: row.isActive == false
        }"></tr>
    </table>
</div>