<div *ngIf="ringcentralwebphoneService?.extension != null && ringcentralwebphoneService.session == null" class="ringCentralUserInfo">
    <!-- Angemeldet aber kein Call -->
    <div class="ringCentralWidgetInfoline">
        <div class="ringCentralWidgetInfoline__label everleadCoral">
            {{ ringcentralwebphoneService?.extension.contact?.firstName }} 
            {{ ringcentralwebphoneService?.extension.contact?.lastName }}
        </div>        
    </div>
    <div class="ringCentralWidgetInfoline">
        <div class="ringCentralWidgetInfoline__label">{{ 'RingCentral.Label.PhoneNumber' | texttransform }}:</div>
        <div class="ringCentralWidgetInfoline__value">{{ ringcentralwebphoneService?.ownCallerNumber }}</div>
    </div>
    <!-- <div class="ringCentralWidgetInfoline ringCentralWidgetInfoline__duration">
        <div class="ringCentralWidgetInfoline__label">{{'Webphone.Label.Time' | texttransform }}:</div>
        <div class="ringCentralWidgetInfoline__value">{{ ringcentralwebphoneService.getTimeDifferenceString() }}</div>
    </div>     -->
</div>

<div *ngIf="ringcentralwebphoneService.session || showIncomingCall || user">
    <div *ngIf="user" class="ringCentralWidget" [ngClass]="{ active: ringcentralwebphoneService.session || showIncomingCall && user }"> 
        <div class="ringCentralWidget__contactBox" *ngIf="user?.adressBook">
            <div class="ringCentralWidget__info">
                <span class="ringCentralWidget__info">
                    {{user.firstName}} {{user.lastName}}
                </span>
                <span>{{user.jobTitle}}</span>
                {{ user | json }}
            </div>
        
            <div class="ringCentralWidget__contact">
                <span class="ringCentralWidget__contact__number">{{user.selectedNumber}}</span>
                <span *ngIf="ringcentralwebphoneService.established" class="ringCentralWidget__contact__timeCall">{{'Webphone.Label.Time' | texttransform }} {{ringcentralwebphoneService.getTimeDifferenceString()}}</span>
            </div>
        </div>

         <div class="ringCentralWidget__contactnoAddress" *ngIf="!user?.adressBook">
            <div class="ringCentralWidgetInfoline">
                <div class="ringCentralWidgetInfoline__label">{{ 'RingCentral.Label.PhoneNumber' | texttransform }}:</div>
                <div class="ringCentralWidgetInfoline__value">{{ ringcentralwebphoneService?.ownCallerNumber }}</div>
            </div>
            <div class="ringCentralWidgetInfoline">
                <div class="ringCentralWidgetInfoline__label">{{ 'SoftPhone.Label.OtherPartner' | texttransform }}:</div>
                <div class="ringCentralWidgetInfoline__value">{{ user.selectedNumber }}</div>
            </div>
            <div class="ringCentralWidgetInfoline ringCentralWidgetInfoline__duration">
                <div class="ringCentralWidgetInfoline__label">{{'Webphone.Label.Time' | texttransform }}:</div>
                <div class="ringCentralWidgetInfoline__value">{{ ringcentralwebphoneService.getTimeDifferenceString() }}</div>
            </div>            
        </div>

        <div class="ringCentralWidget__action" *ngIf="!showIncomingCall && ringcentralwebphoneService.session">
            <button (click)="closeCall()">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m78.3-820-6.727-6.727a22.128 22.128 0 0 1 -5.864 3.424 17.654 17.654 0 0 1 -6.5 1.242 1.18 1.18 0 0 1 -1-.364 1.473 1.473 0 0 1 -.273-.909v-4.909a1.106 1.106 0 0 1 .273-.743 1.232 1.232 0 0 1 .7-.409l4.182-.849a1.825 1.825 0 0 1 .833.091 1.762 1.762 0 0 1 .742.394l2.848 2.849q.545-.333 1.182-.757t1.121-.818l-13.817-13.815 1.7-1.7 22.3 22.3zm-3.3-10.152-1.727-1.727q.424-.515.894-1.182a11.412 11.412 0 0 0 .773-1.242l-2.94-2.97a1.315 1.315 0 0 1 -.333-.682 1.753 1.753 0 0 1 -.03-.712l.788-4.242a1.232 1.232 0 0 1 .409-.7 1.106 1.106 0 0 1 .742-.273h4.909a1.233 1.233 0 0 1 .909.364 1.233 1.233 0 0 1 .364.909 17.58 17.58 0 0 1 -1.258 6.515 22.469 22.469 0 0 1 -3.5 5.942z" fill="#ff1049" transform="translate(-56 844)"/></svg>
            </button>
        </div>
        <div class="ringCentralWidget__action" *ngIf="showIncomingCall">
            <button *ngIf="ringcentralwebphoneService.incomingCall" (click)="acceptCall()">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m142.6-816a20.014 20.014 0 0 1 -8.233-1.817 24.63 24.63 0 0 1 -7.4-5.15 24.63 24.63 0 0 1 -5.15-7.4 20.014 20.014 0 0 1 -1.817-8.233 1.357 1.357 0 0 1 .4-1 1.357 1.357 0 0 1 1-.4h5.4a1.24 1.24 0 0 1 .833.317 1.218 1.218 0 0 1 .433.75l.867 4.667a2.351 2.351 0 0 1 -.033.9 1.4 1.4 0 0 1 -.367.633l-3.233 3.267a16.126 16.126 0 0 0 1.583 2.383 23.465 23.465 0 0 0 2.017 2.217 23.453 23.453 0 0 0 2.167 1.917 21.079 21.079 0 0 0 2.4 1.617l3.133-3.133a1.867 1.867 0 0 1 .783-.45 2.147 2.147 0 0 1 .95-.083l4.6.933a1.543 1.543 0 0 1 .767.483 1.174 1.174 0 0 1 .3.783v5.4a1.356 1.356 0 0 1 -.4 1 1.357 1.357 0 0 1 -1 .399z" fill="#59aa8e" transform="translate(-120 840)"/></svg>
            </button>
            <button *ngIf="ringcentralwebphoneService.incomingCall" (click)="rejectCall()">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m78.3-820-6.727-6.727a22.128 22.128 0 0 1 -5.864 3.424 17.654 17.654 0 0 1 -6.5 1.242 1.18 1.18 0 0 1 -1-.364 1.473 1.473 0 0 1 -.273-.909v-4.909a1.106 1.106 0 0 1 .273-.743 1.232 1.232 0 0 1 .7-.409l4.182-.849a1.825 1.825 0 0 1 .833.091 1.762 1.762 0 0 1 .742.394l2.848 2.849q.545-.333 1.182-.757t1.121-.818l-13.817-13.815 1.7-1.7 22.3 22.3zm-3.3-10.152-1.727-1.727q.424-.515.894-1.182a11.412 11.412 0 0 0 .773-1.242l-2.94-2.97a1.315 1.315 0 0 1 -.333-.682 1.753 1.753 0 0 1 -.03-.712l.788-4.242a1.232 1.232 0 0 1 .409-.7 1.106 1.106 0 0 1 .742-.273h4.909a1.233 1.233 0 0 1 .909.364 1.233 1.233 0 0 1 .364.909 17.58 17.58 0 0 1 -1.258 6.515 22.469 22.469 0 0 1 -3.5 5.942z" fill="#ff1049" transform="translate(-56 844)"/></svg>
            </button>
            <button *ngIf="ringcentralwebphoneService.session && ringcentralwebphoneService.established || callAccepted" (click)="closeCall()">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m78.3-820-6.727-6.727a22.128 22.128 0 0 1 -5.864 3.424 17.654 17.654 0 0 1 -6.5 1.242 1.18 1.18 0 0 1 -1-.364 1.473 1.473 0 0 1 -.273-.909v-4.909a1.106 1.106 0 0 1 .273-.743 1.232 1.232 0 0 1 .7-.409l4.182-.849a1.825 1.825 0 0 1 .833.091 1.762 1.762 0 0 1 .742.394l2.848 2.849q.545-.333 1.182-.757t1.121-.818l-13.817-13.815 1.7-1.7 22.3 22.3zm-3.3-10.152-1.727-1.727q.424-.515.894-1.182a11.412 11.412 0 0 0 .773-1.242l-2.94-2.97a1.315 1.315 0 0 1 -.333-.682 1.753 1.753 0 0 1 -.03-.712l.788-4.242a1.232 1.232 0 0 1 .409-.7 1.106 1.106 0 0 1 .742-.273h4.909a1.233 1.233 0 0 1 .909.364 1.233 1.233 0 0 1 .364.909 17.58 17.58 0 0 1 -1.258 6.515 22.469 22.469 0 0 1 -3.5 5.942z" fill="#ff1049" transform="translate(-56 844)"/></svg>
            </button>
        </div>
    </div>
</div>
