<div *ngIf="loading == true">
    Laden...
</div>
<div *ngIf="loading == false">

    <div class="row maxWidth" *ngIf="validity != null">
        <div class="col-md-12">
            regExDotnet<br>
            <input class="validityInput" [(ngModel)]="validity.regExJs" (change)="saveValidity()">
        </div>
        <div class="col-md-12">
            regExDotnet<br>
            <input class="validityInput" [(ngModel)]="validity.regExDotnet" (change)="saveValidity()">
        </div>
        <div class="col-md-12">
            validityApiResult<br>
            <input class="validityInput" [(ngModel)]="validity.validityApiResult" (change)="saveValidity()">
        </div>
        <hr>
         {{ validity | json }}
    </div>

    <button *ngIf="validity == null" (click)="createEntityValidity()">
        Nicht vorhanden, anlegen
    </button>
</div>