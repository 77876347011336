import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { VipService } from 'app/jollyjupiter/service/vip.service';

enum optState {
  optIn = 43,
  optOut = 44,
  doubleOptIn = 1815
}

@Component({
  selector: 'app-custom-contactheader',
  templateUrl: './custom-contactheader.component.html',
  styleUrls: ['./custom-contactheader.component.scss']
})

export class CustomContactheaderComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  matTableUpdatedEventSubscription: Subscription = new Subscription();
  updateControlAttributesSubscription: Subscription = new Subscription();
  public optState = optState;
  public currentOptState: optState;
  mediumInfo = [];
  typeInfo = [];
  optInOptOutText = 'OptInOptOut.Label.NoInfoAvailable';
  optArrayString = '';
  contactBlacklists = [];
  accountBlacklists = [];
  isVip = false;

  constructor(
    private injector: Injector,
    private vipService: VipService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;    
  }


  roundUp(value) {
    if (value !== null) {
      return Math.floor(value);
    } else {
      return 0;
    }
  }

  ngOnInit(): void {
    this.matTableUpdatedEventSubscription = this.eventService.matTableUpdatedEvent.subscribe(() => {
      this.getOpts();
    });

    this.updateControlAttributesSubscription = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getOpts();
      this.checkIfIsVip();  
    });

    this.checkIfIsVip();  
    this.getOpts();

    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
  }

  ngOnDestroy(): void {
    if (this.matTableUpdatedEventSubscription) {this.matTableUpdatedEventSubscription.unsubscribe(); }
    if (this.updateControlAttributesSubscription) {this.updateControlAttributesSubscription.unsubscribe(); }
  }


  getTypeInfo(): Promise<any> {
    return new Promise((getTypeInfoResolve, getTypeInfoReject) => {
      this.externalDatasourceService.getLookupTableValues(17, 2)
      .then(result => {
        result.forEach(resultItem => {
          this.typeInfo[resultItem.id] = resultItem.defaultName;
        });        
        this.typeInfo[-1] = 'Keine Angabe';
        getTypeInfoResolve(null);
      }).catch(error => { getTypeInfoReject(error); });
    });
  }

  getMediumInfo(): Promise<any> {
    return new Promise((getTypeInfoResolve, getTypeInfoReject) => {
      this.externalDatasourceService.getLookupTableValues(16, 2)
      .then(result => {
        result.forEach(resultItem => {
          this.mediumInfo[resultItem.id] = resultItem.defaultName;
        });        
        this.mediumInfo[-1] = 'Keine Angabe';
        getTypeInfoResolve(null);
      }).catch(error => { getTypeInfoReject(error); });
    });
  }
  
  getOpts() {
    this.optArrayString = '';
    const promiseArray: Promise<any>[] = [];
    promiseArray.push(this.getTypeInfo());
    promiseArray.push(this.getMediumInfo());
    Promise.all(promiseArray)
    .then(() => {
      this.externalDatasourceService.executeExternalDataSource(62, []).then(result => {      
        this.currentOptState = null;
        result.forEach(resultItem => {   
          let addItem = false;     
          if (this.controlDefinition.additionalSetting1 === 'account') {
            if (resultItem.accountId !== null && resultItem.accountId === this.applicationInfoService.currentAccount.id) {
              addItem = true;
            }
          } else {
            if (this.applicationInfoService.currentContact) {
              if (resultItem.contactId !== null && resultItem.contactId === this.applicationInfoService.currentContact.id) {
                addItem = true;
              }  
            }
          }


          if (resultItem.endDate !== null) {
            if (new Date(resultItem.endDate) < new Date(Date.now())) {
              addItem = false;
            } 
          }

          //console.log('RESUlT ITEM', resultItem)

          if (addItem) {

            this.optArrayString = this.optArrayString.concat(new Date(resultItem.startDate).toLocaleString()); 
            if (resultItem.endDate !== null) {
              this.optArrayString = this.optArrayString.concat(' - ', new Date(resultItem.endDate).toLocaleString());
            }
              this.optArrayString = this.optArrayString.concat(' : ', 
              this.typeInfo[resultItem.lookupTypeRefId], 
              '-', 
              this.mediumInfo[resultItem.lookupMediumRefId], 
              '\n');
            if (resultItem.lookupTypeRefId == optState.optOut) {
              this.currentOptState = optState.optOut;
              this.optInOptOutText = 'OptInOptOut.Label.OptOutAvailable';
            } else {
              if (this.currentOptState !== optState.optOut) {
                this.currentOptState = optState.optIn;
                this.optInOptOutText = 'OptInOptOut.Label.OptInAvailable';
              }
            }
          } 
          
          //console.log('mediumInfo', this.mediumInfo);
        })
      });
    })
  }

  launchGoogleSearch() {
    let url =  'https://www.google.de/search?source=everlead&q=<0>';
    let replaceString = '';
    const entityData = this.applicationInfoService.entities.Item('4').data;
    if (this.commonService.isNullOrUndefined(this.controlDefinition.additionalSetting1) ||
      this.controlDefinition.additionalSetting1 === '') {
      replaceString = replaceString.concat(
        this.modifySearchString(entityData.name1), '+',
        this.modifySearchString(entityData.name2), '+',
        this.modifySearchString(entityData.street), '+',
        this.modifySearchString(entityData.zipcode), '+',
        this.modifySearchString(entityData.city)
      );
    } else {
      const searchValues = this.controlDefinition.additionalSetting1.split(';');
      searchValues.forEach(value => {
        const valueDetails = value.split('.');
        const valueSearchString = this.applicationInfoService.entities.Item(valueDetails[0])
          .data[valueDetails[1]];
        if (!this.commonService.isNullOrUndefined(valueSearchString)) {
          replaceString = replaceString.concat(this.modifySearchString(valueSearchString), ' ');
        }
      });
    }
    replaceString = replaceString.replace(/&/, '');
    url = url.replace(/<0>/, replaceString);
    window.open(url);
  }

  modifySearchString(baseString: string): string {
    let resultString = '';
    if (baseString == null) {
      return resultString;
    }
    resultString = baseString.trim();
    resultString = resultString.replace(/ /, '+');
    return resultString;
  }

  checkIfIsVip() {
    if (
      this.applicationInfoService.applicationSettings['showVipSettings'] != 'true' ||
      this.applicationInfoService.currentContact == null
    ) {
      this.isVip = false;
      return;
    }
    
    this.vipService.checkIfContactIsVip(this.applicationInfoService.currentContact.id)
    .then(checkIfProspectIsVipResult => {
      this.isVip = checkIfProspectIsVipResult;
    })
    .catch(error => {
      console.error(error);
    });
  }

  toggleVip() {
    if (this.applicationInfoService.currentContact == null) { return; }
    const contactId = this.applicationInfoService.currentContact.id;

    if (this.isVip) {
      this.vipService.deleteContactFromList(contactId).then(() => { this.isVip = false; });
    } else { 
      this.vipService.addContactToVipList(contactId)
      .then(() => { this.isVip = true; });;
    }
  }
}
