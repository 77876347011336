import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-icon-file',
  templateUrl: './icon-file.component.html',
  styleUrl: './icon-file.component.scss',
})
export class IconFileComponent implements OnInit{

  @Input () value;
  @Input () height: number = 32;
  @Input () width: number = 32;
  @Input () color: string = '#FFFFFF';
  @Input () darkmode;
  defaultFile

  ngOnInit(): void {
    this.defaultFile = this.docFileLibrary(this.value);
    this.color = this.darkmode ? '#FFFFFF': '#000000'
  }


  docFileLibrary(value){
    switch(value) {
      case 'xlsx':
        return false;
      case 'pptx':
        return false;
      case 'docx':
        return false;
      case 'doc':
        return false;
      case 'pdf':
        return false;
      case 'txt':
        return false;
        case 'csv':
          return false;
      default:
          return true;
    }
  }

}
