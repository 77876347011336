<div class="wizardSubheader__wrapper">

<div class="wizardSubheader">
    <div *ngIf="wizardService.wizardPage == 1">
        <label class="wizardSubheader__headerLabel">{{ 'DataRightGroup.Label.Page1' | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ 'DataRightGroup.Label.Page1Description' | texttransform }}</label>
    </div>
    <div *ngIf="wizardService.wizardPage == 2">
        <label class="wizardSubheader__headerLabel">{{ 'DataRightGroup.Label.Page2' | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ 'DataRightGroup.Label.Page2Description' | texttransform }}</label>
    </div>
    <div *ngIf="wizardService.wizardPage == 3">
        <label class="wizardSubheader__headerLabel">{{ 'DataRightGroup.Label.Page3' | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ 'DataRightGroup.Label.Page3Description' | texttransform }}</label>
        <button class="wizardSubheader__button" (click)="editFilterPopup()">{{ 'DataRightGroup.Label.Page3EditFilter' | texttransform }}</button>
    </div>
</div>

<div class="row wizardPageContent maxHeight">
    <div class="col-md-12 maxHeight">
        <div *ngIf="wizardService.wizardPage == 1" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    * {{ 'DataRightGroup.Label.DefaultName' | texttransform }} 
                    <app-wizard-infobutton displayText="DataRightGroup.Label.DefaultNameInfo"></app-wizard-infobutton>    
                </label>                
                <input [(ngModel)]="wizardService.wizardArray.defaultName" class="wizardInput">
            </div>            

            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'DataRightGroup.Label.IsReadOnly' | texttransform }}
                    <app-wizard-infobutton displayText="DataRightGroup.Label.IsReadOnlyInfo"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.isReadOnly" type="checkbox">
            </div>
        </div>

        <div *ngIf="wizardService.wizardPage == 2" class="maxHeight">
            <div *ngIf="!applicationInfoService.isMobile()" class="maxHeight">
                <div class="assignRow">
                    <div class="assignRow__item">
                        <div class="assignRow__item__head">
                            <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' |
                                texttransform }}
                            </div>
                            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input" [(ngModel)]="filterString">
                        </div>
                        <div id="userList"></div>
                        <div class="assignRow__item__body">
                            <div *ngFor="let user of users"
                                [ngClass]="{ hideInDom: isFilterActive(user.userName, filterString) || checkIfAlreadyAssigned(user) }">
                                <div class="userManagement__item__container">
                                    <div class="userManagement__item__name ellipsisText">{{ user.userName }} ({{ user.email }})</div>
                                    <div class="userManagement__item__move" (click)="addUser(user)">
                                        <svg-icon src="assets/images/svg/icon-plus+.svg"
                                            class="userManagement__moveIcon"
                                            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="assignRow__item">
                        <div class="assignRow__item__head">
                            <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' |
                                texttransform }}</div>
                            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg"
                                class="customTextControlWrapper__baseColor"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                            <input placeholder="{{ 'General.Label.Search' | texttransform }}"
                                class="userManagement__left__input" [(ngModel)]="filterStringAssigned">
                        </div>
                        <div id="assignedList"></div>
                        <div class="assignRow__item__body"
                            [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
                            <div *ngFor="let assignedUser of wizardService.wizardArray.users"
                                [ngClass]="{ hideInDom: isFilterActive(assignedUser?.user.userName, filterStringAssigned) }">
                                <div class="userManagement__item__container userManagement__item__containerreverse">
                                    <div class="userManagement__item__name ellipsisText">{{ assignedUser.user?.userName
                                        }} ({{ assignedUser.user?.email }})</div>
                                    <div class="userManagement__item__move" (click)="removeUser(assignedUser)">
                                        <svg-icon src="assets/images/svg/icon-minus+.svg"
                                            class="userManagement__moveIcon"
                                            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="applicationInfoService.isMobile()">
                <div *ngFor="let user of users" class="everleadShadowBox fingerCursor" [ngClass]="{ isAssigned: isAssigned(user) }" 
                    (click)="toggleUser(user)">
                    {{ user.userName }} ({{ user.email }})
                </div>    
            </div>    
        </div>


        <div *ngIf="wizardService.wizardPage == 3" class="maxHeight">
            <div *ngIf="!applicationInfoService.isMobile()" class="maxHeight">
                <div class="assignRow">
                    <div class="assignRow__item">
                        <div class="assignRow__item__head">
                            <div class="userManagement__sectionHeader__label">{{ 'General.Filter.AvailableFilter' |
                                texttransform }}
                            </div>
                            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                            <input placeholder="{{ 'General.Label.Search' | texttransform }}" class="userManagement__left__input" [(ngModel)]="filterStringFilter">
                        </div>
                        <div id="userList"></div>
                        <div class="assignRow__item__body">
                            <div *ngFor="let filter of filters"
                                [ngClass]="{ hideInDom: isFilterActive(filter.defaultName, filterStringFilter) || checkIfFilterAlreadyAssigned(filter) }">
                                <div class="userManagement__item__container">
                                    <div class="userManagement__item__name ellipsisText">{{ filter.defaultName }}</div>
                                    <div class="userManagement__item__move" (click)="addFilter(filter)">
                                        <svg-icon src="assets/images/svg/icon-plus+.svg"
                                            class="userManagement__moveIcon"
                                            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="assignRow__item">
                        <div class="assignRow__item__head">
                            <div class="userManagement__sectionHeader__label">{{ 'General.Filter.AssignedFilter' |
                                texttransform }}</div>
                            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg"
                                class="customTextControlWrapper__baseColor"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                            <input placeholder="{{ 'General.Label.Search' | texttransform }}"
                                class="userManagement__left__input" [(ngModel)]="filterStringAssignedFilter">
                        </div>
                        <div id="assignedList"></div>
                        <div class="assignRow__item__body"
                            [style.height.px]="uiService.getContainerHeightEx('assignedList', 40, 300)">
                            <div *ngFor="let assignedFilter of wizardService.wizardArray.filters"
                                [ngClass]="{ hideInDom: isFilterActive(assignedFilter?.filter.defaultName, filterStringAssignedFilter) }">
                                <div class="userManagement__item__container userManagement__item__containerreverse">
                                    <div class="userManagement__item__name ellipsisText">
                                        <i *ngIf="applicationInfoService.isDeveloper" class="fas fa-refresh fingerCursor" style="margin-right: 5px;" (click)="calculateDataRightsForFilter(assignedFilter)"></i>
                                        {{ assignedFilter.filter?.defaultName }}
                                    </div>                                        
                                    <div class="userManagement__item__move" (click)="removeFilter(assignedFilter)">
                                        <svg-icon src="assets/images/svg/icon-minus+.svg"
                                            class="userManagement__moveIcon"
                                            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="applicationInfoService.isMobile()">
                <div *ngFor="let filter of filters" class="everleadShadowBox fingerCursor" [ngClass]="{ isAssigned: isFilterAssigned(filter) }" (click)="toggleFilter(filter)">
                    {{ filter.defaultName }}
                </div>
            </div>
        </div>
    </div>
</div>

</div>