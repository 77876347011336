import { AfterViewInit, Directive , ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { InstancesChanges, NgxTippyService } from 'ngx-tippy-wrapper';
import { Subscription } from 'rxjs';
import tippy,{ Instance } from 'tippy.js';


@Directive({
  selector: '[tippy]'
})
export class TippyDirective implements OnInit , AfterViewInit, OnDestroy, OnChanges{
  
  @Input('tippy') public tippyContent: string;
  @Input('tippyOptions') public tippyOptions: Object;


  private tippyInstance;


  constructor( 
    private el: ElementRef,
    private eventService: EventService
    ) { 


    }

  ngOnInit(): void {

    this.tippyInstance = tippy(this.el.nativeElement,{
      ...this.tippyOptions,
      content:this.tippyContent ? this.tippyContent : null,
      
    })

    if (this.tippyContent === null){
      this.tippyInstance.disable();
    }


       tippy.setDefaultProps({
        placement:'auto',
        theme:'light-border',
        content:this.tippyContent, 
        allowHTML: false,
        touch:true,
        interactive:true,
        appendTo:'parent'
      });
  }

  ngOnChanges(changes: SimpleChanges){
    //console.log('changes first change',changes)

    if(changes.tippyContent.previousValue == null || changes.tippyContent.previousValue == undefined || changes.tippyContent.currentValue == undefined){
      this.updateInstance();
      /* console.log('this tippy', this.tippyInstance) */
      //this.tippyInstance.show();
    }

    if(changes.tippyContent.currentValue == undefined && (changes.tippyContent.previousValue ||  !changes.tippyContent.previousValue) ){
      //console.log('here')
      
      this.updateInstance();
    }

    if(changes.tippyContent){
     this.updateInstance()
    }

  }

  ngAfterViewInit(): void {
    this.tippyInstance.setContent(this.tippyContent)
  }

  ngOnDestroy(): void {
    if(this.tippyInstance){
      this.tippyInstance.destroy();
    }
  }

  updateInstance(){
    if(this.tippyInstance){
/*       console.log('TIPPY UPDATE CONTENT', this.tippyContent); */
      this.tippyInstance.setContent(this.tippyContent)
      if(this.tippyContent === null){
   /*      console.log('disabled') */
        this.tippyInstance.disable()
      }
    }
  }


}
