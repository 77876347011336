import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { isNullOrUndefined } from 'util';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-non-database-project-dashboard',
  templateUrl: './non-database-project-dashboard.component.html',
  styleUrls: ['./non-database-project-dashboard.component.scss']
})
export class NonDatabaseProjectDashboardComponent implements OnInit {
  iframeSource = null;
  maxHeight = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.iframeSource = this.loadIframeURL(this.applicationInfoService.projectInfo.iframeContent);
  }

  loadIframeURL(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getMaxIframeHeight() {
    let returnValue = this.uiService.getDesignSpanPosition('nonDatabaseDashboardDesign', 20);
    if (isNullOrUndefined(returnValue)) {
      returnValue = this.maxHeight;
    } else {
      this.maxHeight = returnValue;
    }
    return returnValue;
  }
}
