import { Component, OnInit, Input } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-controls-design-lookup-table',
  templateUrl: './controls-design-lookup-table.component.html',
  styleUrls: ['./controls-design-lookup-table.component.scss']
})
export class ControlsDesignLookupTableComponent implements OnInit {
  @Input() lookupTable = null;
  @Input() controlDefinition = null;
  lookupTableEntries = null;
  newLookupTableItem = null;
  newLookUpTable = null;
  updateExternalDataSourceId = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private uiService: UiService,
    private eventService: EventService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private jjtranslationService: JjtranslationService
  ) { }

  ngOnInit() {
    this.createLookupTableTemplate();

    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['lookupTable'])) {
      this.lookupTable = this.applicationInfoService.miscSettings['lookupTable'];
      this.applicationInfoService.miscSettings['lookupTable'] = null;
      this.updateExternalDataSourceId = 216;
      if (this.lookupTable.lookupTableSource.toUpperCase() === 'MAIN') {
        this.updateExternalDataSourceId = 215;
      }
    } else {
      if (this.commonService.isNullOrUndefined(this.lookupTable) && !this.commonService.isNullOrUndefined(this.controlDefinition)) {
        this.lookupTable = this.uiService.getEntityMemberLookupTable(this.controlDefinition);
      }
    }
    if (!this.commonService.isNullOrUndefined(this.lookupTable.lookupTableId)) {
      this.getLookupTableValues();
    }
  }

  createNewLookupTableItem() {
    this.newLookupTableItem = new Object();
    this.newLookupTableItem['nameTranslationToken'] = null;
    this.newLookupTableItem['descriptionTranslationToken'] = null;
  }

  getLookupTableValues() {
    this.externaldatasourceService.executeLookUpTableForModification(this.lookupTable)
    .then(getLookupTableValuesResult => {
      this.lookupTableEntries = getLookupTableValuesResult;
    })
    .catch(error => {
      console.error('getLookupTableValues', error);
    });
  }

  updateLookupTableEntry(lookupTable) {
    if (!this.commonService.isNullOrUndefined(lookupTable.id)) {
          const lookupTableEntry = this.commonService.getModifyArrayBody(lookupTable, []);
          this.externaldatasourceService.updateLookupTableEntry(this.lookupTable, lookupTableEntry)
          .then(() => {
            this.eventService.updateLookupTableEvent.emit(this.lookupTable);
          });
    }
  }

  createNewLookupTableEntry() {
    const lookupTableEntry = this.commonService.getModifyArrayBody({
      definitionId: this.lookupTable.lookupTableId,
      defaultName: 'Neu'
    }, []);
    this.externaldatasourceService.createLookupTableEntry(this.lookupTable, lookupTableEntry)
    .then(() => {
      this.eventService.updateLookupTableEvent.emit(this.lookupTable);
      this.createNewLookupTableItem();
      this.getLookupTableValues();
    });
  }

  createLookupTableTemplate() {
    this.newLookUpTable = new Object();
    this.newLookUpTable['nameTranslationToken'] = null;
    this.newLookUpTable['descriptionTranslationToken'] = null;
  }

  editLookupTableEntry(lookupTableEntry) {
    this.newLookupTableItem = lookupTableEntry;
  }

  createNewLookupTable() {
    if (this.commonService.isNullOrUndefined(this.lookupTable.id)) {
      const lookupTable = this.commonService.getModifyArrayBody(this.newLookUpTable, []);
      this.externaldatasourceService.createLookupTable(this.lookupTable, lookupTable)
      .then(createNewLookupTableResult => {
        this.eventService.newLookupTableCreatedEvent.emit(createNewLookupTableResult.id);
        this.lookupTable.lookupTableId = createNewLookupTableResult.id;
      });
    }
  }

  toggleActive(lookupTable) {
    lookupTable.isActive = !lookupTable.isActive;
    this.updateLookupTableEntry(lookupTable);
  }
}
