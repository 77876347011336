import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-appointment-view',
  templateUrl: './appointment-view.component.html',
  styleUrls: ['./appointment-view.component.scss']
})
export class AppointmentViewComponent implements OnInit {
  appointmentViewInnerHTMLBase = '<jj-container controluiid=<0>></jj-container>';
  appointmentViewInnerHTML = null;
  appointmentEntityId = 21; // TODO In Application-Settings Tabelle auslagern
  canChangeResult = false;
  
  constructor(
    private domSanitizer: DomSanitizer,
    private entityService: EntityService,
    private projectService: ProjectService,
    public userService: UserService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private methodService: MethodService,
    private loaderService: LoaderService,
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit() {
    this.initAppointmentControls();
    this.checkIfResultCanBeChanged();
  }

  initAppointmentControls() {
    this.appointmentViewInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.appointmentViewInnerHTMLBase.replace('<0>', this.applicationInfoService.applicationSettings['appointmentViewInnerHTML'])
    );
  }

  checkIfResultCanBeChanged() {
    const taskId = this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1);
    this.externaldatasourceService.executeExternalDataSource(121, [taskId])
    .then(canChangeResultResult => {
      this.methodService.launchInternalMethod('updateentityvalue', [this.appointmentEntityId,
        'uiCanChangeResult', canChangeResultResult]);
      this.canChangeResult = canChangeResultResult;
    })
    .catch(error => {
      console.error('canChangeResult', error);
      this.canChangeResult = false;
    });
  }

  reopenActivity() {
    this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, [
      this.axivasTranslateService.getTranslationTextForToken('AppointmentView.ReopenQuestion.Header'),
      this.axivasTranslateService.getTranslationTextForToken('AppointmentView.ReopenQuestion.Message')
    ])
    .then(() => {
      this.loaderService.display(true);
      this.projectService.reopenActivity(this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1))
      .then(() => {
        this.loaderService.display(false);
        this.updateAndClose();
      })
      .catch(error => {
        this.loaderService.display(false);
        console.error('reopenActivity', error);
      });
    })
    .catch();
  }

  getAppointment(appointmentId) {
    this.entityService.executeExternalDataSource(this.appointmentEntityId, 'query', appointmentId);
  }

  saveChangesToAppointment() {
    // let questionAnswers = this.getQuestionAnswers();
    //   return;
    if (this.entityService.getEntityValue(this.appointmentEntityId, 'qmStateLookupId', 1) == 40) {      
      this.applicationInfoService.entities.Item(this.appointmentEntityId.toString()).data.isFollowUpAppointment = true
    }
    this.projectService.saveTaskInformation(this.appointmentEntityId)
    .then(saveTaskInformationResult => {
      this.loaderService.display(true);
      if (this.commonService.checkIfItemIsInArray(this.commonService.getModifiedEntityValues(this.appointmentEntityId),
      'qmStateLookupId')) {
        let updateResultString = '';
        const tempResultId = this.entityService.getEntityValue(this.appointmentEntityId, 'tempResultId', 1);
        const tempBackupResultId = this.entityService.getEntityValue(
          this.applicationInfoService.entitiesorig.Item(this.appointmentEntityId.toString()), 'tempResultId', 1);
        if (tempResultId !== tempBackupResultId) {
          updateResultString = 'overwriteResultId: ' + tempResultId;
        }
        this.projectService.qmTask(
          this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1),
          this.commonService.cleanStringValue(this.entityService.getEntityValue(this.appointmentEntityId, 'qmRejectedText', 1)),
          this.entityService.getEntityValue(this.appointmentEntityId, 'qmStateLookupId', 1),
          updateResultString
        ).then(() => {
          this.eventService.compactListRemoveItemFromArray.emit(new JJEvent('6312', // TODO
            [this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1)]));
          this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, [this.appointmentEntityId,
            this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1)
          ]).then(() => {
            this.updateAndClose(this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1));
            this.loaderService.display(false, false, 'saveChangesToAppointment1');
          })
          .catch(error => { this.loaderService.display(false, false, 'saveChangesToAppointment2'); console.error(error); });
        })
        .catch(error => { this.loaderService.display(false, false, 'saveChangesToAppointment3'); console.error(error); });
      } else {
        if (this.canChangeResult) {
          this.externaldatasourceService.executeExternalDataSource(122, [
            this.entityService.getEntityValue(this.appointmentEntityId, 'id', 1),
            this.entityService.getEntityValue(this.appointmentEntityId, 'resultId', 1)
          ])
          .then(result => {
            this.loaderService.display(false);
            this.eventService.updateMyLeadsRecordsetsEvent.emit();
            this.updateAndClose();
          })
          .catch(error => {
            this.loaderService.display(false, false, 'saveChangesToAppointment4');
            console.error(error);
          });
        } else {
          this.loaderService.display(false, false, 'saveChangesToAppointment5');
          this.updateAndClose();
        }
      }
    });
  }

  // getQuestionAnswers() {
  //   let returnValue = null;
  //   let subResults = this.applicationInfoService.entities.Item(this.appointmentEntityId.toString()).data.questionAnswers;
  //   console.warn(subResults)
  //   if (subResults) {

  //   } else {

  //   }
  //   console.warn(subResults);
  //   return returnValue;
  // }

  // enrichSubresults(subResults) {
  //   this.applicationInfoService.subResultChanges.forEach(change => {
  //     const foundItem = subResults.find(subresult => subresult)
  //   });
  // }

  updateAndClose(qmItem: any = null) {
    if (!isNullOrUndefined(qmItem)) {
      this.eventService.removeQMItemFromAccountFormArrayEvent.emit(qmItem);
    }
    this.methodService.launchInternalMethod(InternalMethodType.UpdateControlContent, [
      this.applicationInfoService.applicationSettings['appointmentViewUpdateControlContent1']]);
    this.methodService.launchInternalMethod(InternalMethodType.UpdateControlContent, [
      this.applicationInfoService.applicationSettings['appointmentViewUpdateControlContent2']]);
    if (this.applicationInfoService.miscSettings['accountPopupOpen'] !== 'true') {
      this.methodService.launchInternalMethod(InternalMethodType.ClosePopup, []);
    }
  }
}
