import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-cachereset',
  templateUrl: './cachereset.component.html',
  styleUrls: ['./cachereset.component.scss']
})
export class CacheresetComponent implements OnInit {

  constructor(
    public userService: UserService,
    private authService: AuthService,
    private applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    if (this.userService.hasPermission('CanRefreshCache')) {
      console.warn('CanRefreshCache');
    }
  }

  refreshTranslation() {
    this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/Translation/project/'.concat(
      this.applicationInfoService.projectID.toString()
    )).subscribe(() => {
      this.messagingService.showDefaultSuccess('', 'CacheReset.Label.CacheResetSuccessfull');
    });
  }
}
