<a (click)="callMethod(menuItemControl)" 
  class="nav-link linkItemCustom" 
  href="#" 
  [ngClass]="{ 'dropdown-toggle': menuItemControl?.childs?.length > 0 }"
  id="navbarDropdown" 
  role="button" 
  data-toggle="dropdown"  
  aria-haspopup="true" 
  aria-expanded="false"
>
<i class="{{ menuItemControl?.icon }} fa-lg float-{sm,md,lg,xl}-{left,right,none} picturePadding"></i>{{ menuItemControl?.displayText | texttransform }}
</a>   
<ul class="dropdown-menu dropdown-menu-right" 
    aria-labelledby="navbarDropdown"
    [ngClass]="{ invisible: menuItemControl?.childs?.length == 0 }"
  >
    <li *ngFor='let subItem of menuItemControl?.childs' 
      [ngClass]="{ 'pull-left': subItem.childs?.length > 0, 'dropdown-submenu': subItem.childs?.length > 0 }">
      <!-- Subitem Level 1 -->
      <div class="dropdown-item" (click)="callMethod(subItem)">
          <i [ngClass]="{ 'fa-chevron-left': subItem.childs?.length > 0 }" class="fas iconPadding fingerCursor subItem.icon"></i>
        <span class="fingerCursor">{{ subItem.displayText | texttransform }}</span>
      </div>
      <ul class="dropdown-menu" id="subitemLevel2" aria-labelledby="navbarDropdown">
        <li *ngFor='let subItem2 of subItem.childs' class="pull-down dropdown-item">                              
          <!-- Subitem Level 2 -->
          <a class="dropdown-item fingerCursor" (click)="callMethod(subItem2)">{{ subItem2.displayText | texttransform }}</a>
        </li>
      </ul>                             
    </li>
   </ul>
