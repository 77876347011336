import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-email-smtpcontent',
  templateUrl: './wizard-email-smtpcontent.component.html',
  styleUrls: ['./wizard-email-smtpcontent.component.scss']
})
export class WizardEmailSmtpcontentComponent implements OnInit {
  eMailContents = [];
  placeholders = [];

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public emailtemplateService: EmailtemplateService,
    public commonService: CommonService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.getEmailContents();
    this.getPlaceHolders();
  }

  getEmailContents() {
    this.externaldatasourceService.executeExternalDataSource(435).then(getEmailContentsResult => {
      this.eMailContents = getEmailContentsResult;
    });
  }

  addMemberToTemplate(emailContent) {
    this.eventService.addTextToHTMLText.emit(emailContent);
  }

  getContent(event) {
    this.wizardService.wizardArray.content = event.textData;
    this.wizardService.wizardArray.contentPictures = JSON.stringify(event.imageArray);
  }

  getPlaceHolders() {
    this.externaldatasourceService.executeExternalDataSource(446, [])
    .then(getPlaceHoldersResult => { this.placeholders = getPlaceHoldersResult; })
  }

  selectContent(emailContent) {
    if (this.emailtemplateService.isValidSMTPContent(emailContent)) {
      if (!this.commonService.isNullOrUndefined(emailContent.contentPictures)) {
        emailContent.imageArray = JSON.parse(emailContent.contentPictures);
      }      
      this.wizardService.wizardArray = emailContent;
      this.wizardService.wizardPage = 2;  
    }
  }

  newTemplate() {
    this.wizardService.wizardArray = new Object();
    this.wizardService.wizardArray['imageArray'] = [];    
    this.wizardService.wizardArray['subject'] = '';
    this.wizardService.wizardArray['content'] = '';
    this.wizardService.wizardArray['defaultName'] = '';
    this.wizardService.wizardArray['isActive'] = true;
    this.wizardService.wizardArray['isPublished'] = false;
    this.wizardService.wizardArray['version'] = 0;
    this.wizardService.wizardArray['emailTemplateContentTypeLookupId'] = 5780;
    this.wizardService.wizardArray['userId'] = this.applicationInfoService.userID;
    this.wizardService.wizardArray['projectId'] = this.applicationInfoService.projectID;
    this.wizardService.wizardPage = 2;
  }

  changedUserId() {
    this.applicationInfoService.projectID
  }

  
}
