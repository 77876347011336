import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-followuplist',
  templateUrl: './followuplist.component.html',
  styleUrl: './followuplist.component.scss',
})
export class FollowuplistComponent implements OnInit, OnChanges, AfterViewInit{

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    "user_name",
    "pool_name",
    "overdue",
    "overdue_today",
    "current",
    "total_today",
    "ft1",
    "ft2",
    "ft3",
    "ft4",
    "ft5",
    "ft6",
    "ft7",
    "ft8",
    "ft9",
    "ft10",
    "future",
  ];

  highlightRowId: number | string;
  hightlightPoolName: string;
  hightlightName: string;
  searchString = "";
  filteredData=[]


  @Input() data;
  @Input() listType: string="";



  constructor(
    private paginatorIntl: MatPaginatorIntl,
    private commonService: CommonService,
    private axivasTranslateService: AxivasTranslateService
  ){

  }

  ngOnInit(): void {
    this.paginatorIntl.itemsPerPageLabel = this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator');
    if(this.listType === 'personal'){
      this.displayedColumns = this.displayedColumns.filter((item) => item !== "user_name" && item !== "pool_name")
    }
    const isSamepool = this.isAllPoolNamesSame()
    if(isSamepool && this.listType !== 'personal'){
     this.displayedColumns = this.displayedColumns.filter((item) => item !==' pool_name')
    }
  }

  ngAfterViewInit(): void {
    if(this.data){
      this.dataSource = new MatTableDataSource(this.data);
      this.filteredData = JSON.parse(JSON.stringify(this.data));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    } 
  }

  ngOnChanges(changes:SimpleChanges):void {
    //console.log('changes', changes);
    if(changes['data'] && !changes['data'].firstChange){
      this.dataSource = new MatTableDataSource(this.data);
    }
  }


  sortOnChange($event) {
    if (this.dataSource && this.dataSource.sort) {
      this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    }
  }

  isAllPoolNamesSame(){
    const poolName = this.dataSource?.data[0]?.pool_name
    return this.data.every((item) => item.pool_name === poolName)
  }

  highlightRow(row) {
    this.highlightRowId = row.follow_up_user_id;
    this.hightlightPoolName = row.pool_name;
    this.hightlightName = row.user_name;
  }

  styleRow() {
    return {
      color: "#ed5c73",
      backgroundColor:'#ebebeb',
      cursor:'pointer'
    };
  }

  inputChange($event ,searchResult: any) {
    if(this.searchString){
     const filter = this.filteredData.filter(
        (item) =>
          this.commonService.checkIfStringContainsString(item.user_name, this.searchString)
      );

      this.dataSource.data = filter
    } else {
      this.dataSource.data = this.data
    }
  }
}


