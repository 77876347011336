import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-expandflowchart',
  templateUrl: './expandflowchart.component.html',
  styleUrls: ['./expandflowchart.component.scss']
})
export class ExpandflowchartComponent implements OnInit {

  constructor(
    public applicationInfoService: ApplicationInfoService 
    ) { }

  flowChartData: any;
  flowChartOverviewId: any;

  ngOnInit(): void {
    this.showCampaignOverview()
  }

  showCampaignOverview(){
   this.flowChartData = this.applicationInfoService.miscSettings['flowChartOverviewExpanded'];
   this.flowChartOverviewId = this.applicationInfoService.miscSettings['flowChartOverviewExpandedType'];
  }

}
