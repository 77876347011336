<div class="lookupTableDesigner">
    <div class="projectSettings__header" *ngIf="selectedLookupTableDefinition == null">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createLookupTableDefinition()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>               
            {{ 'LookupTableDesign.Label.NewLookupTableDefinition' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__showOnlyActive">
                {{ 'LookupTableDesign.Label.Source' | texttransform }}
                <select [(ngModel)]="lookupTableSource" (change)="getLookupsBySource()">
                    <option ngValue="central">CENTRAL</option>
                    <option ngValue="data">DATA</option>
                </select>                
            </div>
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="projectSettings__header" *ngIf="selectedLookupTableDefinition != null">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createLookupTable()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>               
            {{ 'LookupTableDesign.Label.NewLookupTable' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">            
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>







    <div class="lookupTableDesigner__content" *ngIf="selectedLookupTableDefinition == null">
        <div class="everleadShadowBox everleadShadowBox2 lookupTableDefinitions fingerCursor" *ngFor="let lookupTableDefinition of lookupTableDefinitions" (click)="selecteLookupTableDefinition(lookupTableDefinition)">
            <div class="lookupTableDefinitions__id everleadCoral">{{ lookupTableDefinition.definitionId }}</div>
            <div class="lookupTableDefinitions__defaultName">
                <input [(ngModel)]="lookupTableDefinition.defaultName">
            </div>
        </div>
    </div>
    <div *ngIf="selectedLookupTableDefinition != null">
        <button (click)="selectedLookupTableDefinition = null">{{ 'General.Label.Back' | texttransform }}</button>
    </div>
    <div class="lookupTableDesigner__content" *ngIf="selectedLookupTableDefinition != null">
        <div class="everleadShadowBox everleadShadowBox2 lookupTableDefinitions fingerCursor" *ngFor="let lookupTable of lookups">
            <div class="lookupTableDefinitions__id everleadCoral">{{ lookupTable.id }}</div>
            <div class="lookupTableDefinitions__isActive"><input [(ngModel)]="lookupTable.isActive" type="checkbox" (change)="saveLookupTable(lookupTable)"></div>
            <div class="lookupTableDefinitions__defaultName">
                <input [(ngModel)]="lookupTable.defaultName" (change)="saveLookupTable(lookupTable)">
                <app-wizard-translations *ngIf="lookupTableSource == 'central'" [array]="lookupTable" updateBaseArrayExternaldatasourceId=215 mode="3"></app-wizard-translations>    
                <app-wizard-translations *ngIf="lookupTableSource == 'data'" [array]="lookupTable" updateBaseArrayExternaldatasourceId=859 mode="3"></app-wizard-translations>    
            </div>
            <div class="lookupTableDefinitions__delete">
                <i class="fas fa-trash everleadCoral" (click)="deleteLookupTable(lookupTable)"></i>
            </div>
        </div>        
    </div>
</div>