<div class="featuressettings-slider">
  <h5>Slides</h5>

  @if (!isAddingNew) {
    <i class="fas fa-plus fingerCursor featuressettings-slider__plus" (click)="isAddingNew = true; selectedLanguage = null"><span></span></i>
  
  }

  <div cdkDropList [cdkDropListData]="slider" class="example-list" (cdkDropListDropped)="drop($event)">
  @for (item of slider; track $index) {

    @if (isEditing && editedItem.id === item.id) {

      <div class="featuressettings-slider__item everleadShadowBox">
        <div class="featuressettings-slider__image fingerCursor">
          <input type="file" #file accept="image/*" (change)="uploadImage($event)" hidden>
          @if (editedItem?.base64Img) {
            <img src="{{ editedItem.base64Img }}" (click)="file.click()" />
            <i class="fas fa-close fingerCursor" (click)="editedItem.base64Img = ''"></i>
          } @else {
            <div [ngClass]="editImageRequiredMessage ? 'featuressettings-slider__imageRequired' : 'featuressettings-slider__no-image'" (click)="file.click()">
              Upload Image
              <span class="featuressettings-slider__requiredMessage"*ngIf="editImageRequiredMessage">{{'LanguageDesign.Label.ImageIsRequired' | texttransform }}</span>
            </div>
          }
        </div>

         <div class="featuressettings-slider__textwrapper">
            <div class="featuressettings-slider__selectors">
              <label>{{ 'LanguageDesign.Label.SelectProject' | texttransform }}</label>
              <select [(ngModel)]="editedItem.projectId">
                <option [ngValue]="null"> {{'LanguageDesign.Label.Global' | texttransform }}</option>
                <option *ngFor="let project of selectProjects" [ngValue]="project.projectId">{{project?.project?.projectName}}</option>
              </select>
            
              <select [(ngModel)]="editedLanguage">
                <option [ngValue]="'text_de'">{{'LanguageDesign.Label.German' | texttransform }}</option>
                <option [ngValue]="'text_en'">{{'LanguageDesign.Label.English' | texttransform }}</option>
              </select>
            </div>
            <div class="featuressettings-slider__texts">
              <div class="featuressettings-slider__text-item" *ngIf="editedLanguage === 'text_de'">
                <label>{{ 'LanguageDesign.Label.German' | texttransform }}</label>
                <textarea [(ngModel)]="editedItem.settings.text_de"></textarea>
              </div>
              <div class="featuressettings-slider__text-item" *ngIf="editedLanguage === 'text_en'">
                <label>{{ 'LanguageDesign.Label.English' | texttransform }}</label>
                <textarea [(ngModel)]="editedItem.settings.text_en"></textarea>
              </div>
            </div>
        </div>

        <div class="featuressettings-slider__icons">
          <i class="fas fa-save fingerCursor" (click)="updateItem()"></i>
          <i class="fas fa-close fingerCursor" (click)="isEditing = false; editImageRequiredMessage=false ; editedItem = null"></i>
        </div>
      </div>

    } @else {

      <div class="featuressettings-slider__item everleadShadowBox" cdkDrag [cdkDragData]="item">
        <div class="featuressettings-slider__image">
          @if (item.base64Img) {
            <img src="{{ item.base64Img }}" />
          } @else {
            <div class="featuressettings-slider__no-image">No Image</div>
          }
        </div>
  
        <div class="featuressettings-slider__texts">

          <div class="featuressettings-slider__text-item" *ngIf="item.projectId">
              <!-- <label>{{ 'LanguageDesign.Label.SelectProject' | texttransform }}</label> -->
              <div *ngFor="let project of selectProjects">
                <span *ngIf="project.projectId == item.projectId">{{project?.project?.projectName}}</span>
              </div>
          </div>


            <div class="featuressettings-slider__text-item" *ngIf="item.settings.text_de">
              <label>{{ 'LanguageDesign.Label.German' | texttransform }}</label>
              <span [innerHTML]="item.settings.text_de"></span>
          </div>
          <div class="featuressettings-slider__text-item" *ngIf="item.settings.text_en">
            <label>{{ 'LanguageDesign.Label.English' | texttransform }}</label>
            <span [innerHTML]="item.settings.text_en"></span>
          </div>
        </div>
        <div class="featuressettings-slider__icons">
          <i class="fas fa-edit fingerCursor" (click)="isEditing = true; editImageRequiredMessage=false ;editedItem = getItemDeepCopy(item)"></i>
          <i class="fas fa-eye fingerCursor" [tippy]="'Feature.Label.TestSlide' | texttransform" (click)="packagefeatureService.showFeatureInfoPopup(featureId, item.id)"></i>
          <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteItem(item.id)"></i>
        </div>
      </div>
    }

  }
  </div>

  @if (isAddingNew) {

    <div class="featuressettings-slider__item everleadShadowBox">
      <div class="featuressettings-slider__image fingerCursor">
        <input type="file" #file accept="image/*" (change)="uploadImage($event)" hidden>
        @if (!newItem?.base64Img) {
          <div [ngClass]="createImageRequiredMessage ? 'featuressettings-slider__imageRequired' : 'featuressettings-slider__no-image'" (click)="file.click()">
            Upload Image
            <span class="featuressettings-slider__requiredMessage" *ngIf="createImageRequiredMessage">{{'LanguageDesign.Label.ImageIsRequired' | texttransform }}</span>
          </div>
        } @else {
          <img src="{{ newItem.base64Img }}" (click)="file.click()" />
          <i class="fas fa-close fingerCursor" (click)="newItem.base64Img = ''"></i>
        }
      </div>

      <div class="featuressettings-slider__textwrapper">
        <div class="featuressettings-slider__selectors">
          <label>{{ 'LanguageDesign.Label.SelectProject' | texttransform }}</label>
          <select [(ngModel)]="newItem.projectId">
            <option [ngValue]="null"> {{'LanguageDesign.Label.Global' | texttransform }}</option>
            <option *ngFor="let project of selectProjects" [ngValue]="project.projectId">{{project?.project?.projectName}}</option>
          </select>

          <select [(ngModel)]="selectedLanguage">
            <option [disabled]="true" [ngValue]="null"> {{'LanguageDesign.Label.SelectLanguage' | texttransform }}</option>
            <option [ngValue]="'text_de'">{{'LanguageDesign.Label.German' | texttransform }}</option>
            <option [ngValue]="'text_en'">{{'LanguageDesign.Label.English' | texttransform }}</option>
          </select>
        </div>
      

        <div class="featuressettings-slider__texts">        
          <div class="featuressettings-slider__text-item" *ngIf="selectedLanguage === 'text_de'">
            <label>{{ 'LanguageDesign.Label.German' | texttransform }}</label>
            <textarea [(ngModel)]="newItem.settings.text_de"></textarea>
          </div>
          <div class="featuressettings-slider__text-item" *ngIf="selectedLanguage === 'text_en'">
            <label>{{ 'LanguageDesign.Label.English' | texttransform }}</label>
            <textarea [(ngModel)]="newItem.settings.text_en"></textarea>
          </div>
        </div>
      </div>
      <div class="featuressettings-slider__icons">
        <i class="fas fa-save fingerCursor" (click)="saveNewItem()"></i>
        <i class="fas fa-close fingerCursor" (click)="isAddingNew = false; createImageRequiredMessage=false; newItem = getItemDeepCopy(emptyItem)"></i>
      </div>
    </div>

  }
</div>
