<div *ngIf="applicationInfoService.useNewDesign" class="customTextControlWrapper">
  <label class="customTextControlWrapper__label">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
  <div class="d-flex customControlValueWrapper">
    <input       
      class="customTextControlWrapper__textbox customTextControlWrapper__oneIcon"
      [type]="inputType" 
      [disabled]="!checkIfCallControlIsEnabled()"      
      (change)="callMethod('onchange')"
      [placeholder]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform"
      [(ngModel)]="controlValue"
      [tippy]="controlValue"
      (keydown)="keyDown()"
    >  
    <div class="everleadCustomControlMiniButton" *ngIf="applicationInfoService.applicationSettings['showPhoneIconInControl'] == 'true'">
      <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="customTextControlWrapper__button customDial__nonMobile"  
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber()" [ngClass]="{
        fingerCursor: checkIfCallControlIsEnabled()
      }"></svg-icon>   
    </div>
  </div>  
</div>