import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-admin-filter',
  templateUrl: './admin-filter.component.html',
  styleUrls: ['./admin-filter.component.scss']
})
export class AdminFilterComponent implements OnInit {
  filters = [];
  filterTypeIds = [
    { id: 151, name: 'ReportingFilter'},
    { id: 4523, name: 'DatarightsFilter'},
  ];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
  }

  getDefinitionsForFilterId(filterId) {
    this.externaldatasourceService.checkExternalDataSourceCache(581, [filterId])
    .then(getDefinitionsForFilterId => {
      this.filters = getDefinitionsForFilterId;
    });
  }
}

