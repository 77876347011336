import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MethodService } from '../../../../jollyjupiter/service/method.service';
import { ApplicationInfoService } from '../../../../core/application/application-info.service';
import { EventService } from '../../../../jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-large-menu-item',
  templateUrl: './large-menu-item.component.html',
  styleUrls: ['./large-menu-item.component.scss']
})
export class LargeMenuItemComponent implements OnInit, OnDestroy {
  navigationInitializedEventSubscription: Subscription = new Subscription();

  @Input() menuitem: any = null;

  constructor(
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    public userService: UserService
  ) { }

  ngOnDestroy() {
    if (this.navigationInitializedEventSubscription) { this.navigationInitializedEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.navigationInitializedEventSubscription = this.eventService.navigationInitializedEvent.subscribe(event => {
      if (event.target === this.menuitem.value) {
        this.applicationInfoService.selectedMenuItem = this.menuitem;
      } else {
        this.menuitem.childs.forEach(child => {
          if (event.target === child.value) {
            this.applicationInfoService.selectedMenuItem = this.menuitem;
            this.applicationInfoService.highlightedMenuItem = child;
          }
        });
      }
    });
    if (this.applicationInfoService.menuInitId != null) {
      // console.log('updateMenuBarSubscription menuInitId', this.applicationInfoService.menuInitId);
      this.eventService.navigationInitialized(this.applicationInfoService.menuInitId);
      this.applicationInfoService.menuInitId = null;
    }
  }

  getMenuInfo() {
    console.log('getMenuInfo', this.menuitem);
  }

  callMethod(control: any) {
    if (control.methods.length > 0) {
      this.eventService.closeMegaMenu();
      this.applicationInfoService.selectedMenuItem = this.menuitem;
      this.applicationInfoService.highlightedMenuItem = control;
      this.methodService.launchInternalMethods(control);
    }
  }
}
