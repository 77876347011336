<div [innerHTML]="innerHTML" class=""></div>
<!-- 
<div class="row" style="margin-top: 30px;">
    <div class="col-md-12">
        <div class="jjTab" *ngIf="!applicationInfoService?.isMobile()">
            <button [ngClass]="{ 
                    jjActivetab: child.id == selectedTabIndex,
                    jjControlDefaultColor: child.id == selectedTabIndex,
                    jjInactivetab: child.id != selectedTabIndex
                  }" *ngFor="let child of visibleTabs; let currentIndex = index" class="tablinks"
                (click)="setCurrentChild(child.id)">
                {{ child.displayText | texttransform }}
            </button>
        </div>
        <div *ngIf="applicationInfoService?.isMobile()">
            <select style="width: 100%;" [(ngModel)]="selectedTabIndex">
                <option [ngValue]="child.id" *ngFor="let child of visibleTabs">
                    {{ child.displayText | texttransform }}
                </option>
            </select>
        </div>
    </div>
</div>
<div style="padding: 5px;" *ngIf="applicationInfoService?.currentAccount != null">
    <span *ngIf="selectedTabIndex == 1">
        <div [innerHTML]="innerHTMLQuestionaire" class=""></div>
    </span>
    <span *ngIf="selectedTabIndex == 2">
        <div [innerHTML]="innerHTMLTasks" class="account_footer__item"></div>
    </span>
    <span *ngIf="selectedTabIndex == 3">
        <div [innerHTML]="innerHTMLActivities" class=""></div>
    </span>
    <span *ngIf="selectedTabIndex == 4">
        <div [innerHTML]="innerHTMLAllContacts" class="account_footer__item"></div>
    </span>
    
    <span *ngIf="selectedTabIndex == 6">
        <app-followups></app-followups>
    </span>
    <span *ngIf="selectedTabIndex == 7">
        <app-conversion-list></app-conversion-list>
    </span>
    <span *ngIf="selectedTabIndex == 8">
        <div [innerHTML]="innerHTMLOpportunity" class="account_footer__item"></div>
        
    </span>
    <span *ngIf="selectedTabIndex == 9">
        <app-leadscoring-detail></app-leadscoring-detail>
    </span>
</div> -->
