import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { SettingsService } from 'app/shared/service/settings.service';

@Component({
  selector: 'app-custom-contactleadstateoverview',
  templateUrl: './custom-contactleadstateoverview.component.html',
  styleUrls: ['./custom-contactleadstateoverview.component.scss']
})
export class CustomContactleadstateoverviewComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  updateCombinedActivityListSubscription: Subscription = new Subscription();
  updateControlAttributesEventSubscription2: Subscription = new Subscription();
  toggled = false;  
  contactLeadStatusCampaigns = [];
  loadingData = false;
  currentLeadstate = null;
  currentLeadstateName = '';
  currentEditingState = null;
  currentEditingStateName = '';
  leadstateOverviewAutoToggleOnLoad = false;
  targetId = null;
  objections = [];
  showNotesInSecondLine = false;
  customContactLeadStateToggleUserSetting = false;
  target = 'contact';

  constructor(
    private injector: Injector,
    private settingService: SettingsService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;    
  }

  ngOnInit(): void {
    this.updateCombinedActivityListSubscription  = this.eventService.updateCombinedActivityList.subscribe((event) => {
      setTimeout(() => { this.getCampaignLeadstates() }, 800);
    });

    this.updateControlAttributesEventSubscription2 = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getValues();
    });

    this.leadstateOverviewAutoToggleOnLoad = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'leadstateOverviewAutoToggleOnLoad', false);        
    this.showNotesInSecondLine = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'showNotesInSecondLine', false);        
    this.target = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'leadstateControlTarget', 'contact');        
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings['customContactLeadStateToggle'])) {
      this.customContactLeadStateToggleUserSetting = this.applicationInfoService.userSettings['customContactLeadStateToggle'];
    }
    setTimeout(() => {
      this.getValues();
      if (this.leadstateOverviewAutoToggleOnLoad == true || this.customContactLeadStateToggleUserSetting == true) {
        this.changeToggle();
      }            
    }, 700);
  }

  ngOnDestroy(): void {
    if (this.updateCombinedActivityListSubscription) { this.updateCombinedActivityListSubscription.unsubscribe(); }
    if (this.updateControlAttributesEventSubscription2) { this.updateControlAttributesEventSubscription2.unsubscribe(); }    
  }

  getValues() {    
    switch (this.target) {
      case 'account':
        if (this.applicationInfoService.currentAccount) {
          this.currentLeadstate = this.entityService.getEntityValue(1, 'calculatedLeadStateId', 1);
          this.currentEditingState = this.applicationInfoService.currentAccount.calculatedEditingState;
          this.targetId = this.applicationInfoService.currentAccount.id;
        }
        break;
      case 'contact':
        if (this.applicationInfoService.currentContact) {
          this.currentLeadstate = this.entityService.getEntityValue(4, 'leadStateId', 1);          
          this.currentEditingState = this.applicationInfoService.currentContact.calculatedEditingState;
          this.targetId = this.applicationInfoService.currentContact.id;
        }
        break;
    }
    this.getCurrentContactLeadstate(this.currentLeadstate);
    this.getProgressState();
  }

  getCurrentContactLeadstate(leadStateId) {
    this.currentLeadstateName = this.getLeadstateName(leadStateId);    
  }

  getLeadstateName(leadstateId) {
    let returnValue = this.applicationInfoService.applicationSettings['contactLeadstateOverviewDefaultLeadstateText'];
    if (!this.commonService.isNullOrUndefined(leadstateId)) {
      const leadState = this.applicationInfoService.leadstates.toArray().find(leadstate => leadstate.lookupTable.id == Number(leadstateId));
      if (leadState) {
        returnValue = this.commonService.getTranslationValueFromArray(leadState);
      }
    }
    return returnValue;
  }

  getCampaignName(campaignId) {
    let returnValue = '';
    if (!this.commonService.isNullOrUndefined(campaignId)) {
      const campaign = this.applicationInfoService.campaigns.toArray().find(campaign => campaign.id == Number(campaignId));
      if (campaign) {
        returnValue = this.commonService.getTranslationValueFromArray(campaign);
      }
    }
    return returnValue;
  }

  getCampaignLeadstates() {    
    this.loadingData = true;
    let externalDataSourceId = 896;
    let arrayName = 'contactLeadStatusCampaigns';
    let leadStateIdentifier = 'leadStateId';
    if (this.target == 'account') {
      externalDataSourceId = 897;
      arrayName = 'accountLeadStatusCampaigns';
      leadStateIdentifier = 'calculatedLeadStateId';
    }
    this.externalDatasourceService.executeExternalDataSource(externalDataSourceId, [this.targetId])
    .then(getCampaignLeadstatesResult => {
      this.getCurrentContactLeadstate(getCampaignLeadstatesResult[leadStateIdentifier]);
      this.currentEditingState = getCampaignLeadstatesResult.calculatedEditingState;
      this.getProgressState();
      this.contactLeadStatusCampaigns = getCampaignLeadstatesResult[arrayName];
      this.loadingData = false;
      this.getObjections();
    }).catch(() => { this.loadingData = false; });    
  }

  changeToggle() {
    this.toggled = !this.toggled;
    if (this.contactLeadStatusCampaigns.length == 0) {
      this.getCampaignLeadstates();
    }
    this.applicationInfoService.userSettings['customContactLeadStateToggle'] = this.toggled;
    this.settingService.updateSecurityUserSettingsJson();
  }

  getProgressState() {
    let returnValue = '';
    const lookupTable = this.commonService.getProjectLookupTableValues(this.applicationInfoService.applicationSettings['progressStateLookupId'], 'central');
    const progressState = lookupTable.find(lookup => lookup.id == this.currentEditingState);
    if (progressState) {
      returnValue = this.commonService.getTranslationValueFromArray(progressState);
    } 
    this.currentEditingStateName = returnValue;
    return returnValue;   
  }

  showDetails(contactLeadStatusCampaign) {
    contactLeadStatusCampaign['leadStateName'] = this.getLeadstateName(contactLeadStatusCampaign.leadStatusId);
    contactLeadStatusCampaign['campaignName'] = this.getCampaignName(contactLeadStatusCampaign.campaignId);
    contactLeadStatusCampaign['objections'] = this.objections[contactLeadStatusCampaign.campaignId];
    this.applicationInfoService.miscSettings['contactLeadStatusCampaign'] = contactLeadStatusCampaign;
    this.applicationInfoService.miscSettings['contactLeadStatusCampaignTarget'] = this.target;
    this.eventService.showJjPopup('CustomContactLeadstatusDetails.Label.Header', 'customcontactleadstatusdetails', '630px;800px');
  }

  getObjections() {
    let refId = null;
    if (this.target == 'contact') {
      refId = this.targetId;
    }
    this.objections = [];
    this.externalDatasourceService.executeExternalDataSource(893, [2, refId]).then(getObjectionsResult => {      
      if (getObjectionsResult != null) {
        const guid = this.commonService.createGuid();
        const resultJson = JSON.parse(getObjectionsResult);       
        resultJson.tasks.sort((a, b) => a.date > b.date ? -1 : 1);    
        resultJson.tasks.forEach(task => {
          if (this.commonService.isNullOrUndefined(this.objections[task.taskCampaignId])) {
            this.objections[task.taskCampaignId] = '';
          }
          if (this.objections[task.taskCampaignId] == '') {
            if (task.taskAnswers != null) {
              this.objections[task.taskCampaignId] = this.objections[task.taskCampaignId].concat(task.taskAnswers);
              if (task.taskAnswerText != null) {
                  this.objections[task.taskCampaignId] = this.objections[task.taskCampaignId].concat(': ', task.taskAnswerText);
              }                  
            } else {
              this.objections[task.taskCampaignId] = ' ';
            }
          }
        });
      }      
    });
  }

  checkContactRequirement() {
    let returnValue = true;
    if (this.target == 'contact') {
      if (this.applicationInfoService.currentContact == null) {
        returnValue = false;
      }
    }
    return returnValue
  }
}
