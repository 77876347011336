import { Injectable } from '@angular/core';
import { Subscription, timer, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { SettingsService, Setting } from './settings.service';
import { AuthService } from '../../core/authentication/services/auth.service';
import * as moment from 'moment';
import { AxivasTranslateService } from '../translation/axivas-translate.service';

@Injectable()
export class AutoLogoutService  {
  private oldTitle: string;

  jjCounterActive = false;
  public jjInactivityWarningTime = 2 * 60000;
  public jjInactivityStartTime = 120 * 60000;
  public jjInactivityTimeNum: number;
  public jjInactivityTimeObs: Subject<number> = new Subject<number>();

  public timerTick = 1000;
  public timerStart = 1000;

  public jjInactivitySubscription: Subscription;

  public constructor(
      private settingsService: SettingsService
    , private titleService: Title
    , private axivasTranslateService: AxivasTranslateService
    , private authService: AuthService
  ) {
    this.oldTitle = titleService.getTitle();
    this.settingsService.getServerSettings()
      .subscribe(resSettings => {
        this.jjInactivityWarningTime = Number(resSettings[0].trInactivityWarningTime);
        this.jjInactivityStartTime = Number(resSettings[0].trInactivityStartTime);
        this.jjInactivityTimeNum = this.jjInactivityStartTime;
    });
  }

  public activateJollyJupiterTimer() {
    this.jollyJupiterFormInactiveTimer();
  }

  jollyJupiterFormInactiveTimer() {
    if (!this.jjCounterActive) {
      let activityFormCheckIntervall = 0;
      const inactivityTimer = timer(1000, 1000);
      this.jjCounterActive = true;

      this.jjInactivitySubscription = inactivityTimer.subscribe( time => {
        this.jjInactivityTimeNum = this.jjInactivityTimeNum - (1000);
        this.jjInactivityTimeObs.next(this.jjInactivityTimeNum);
        if (activityFormCheckIntervall <= 0) {
          if (this.jjInactivityTimeNum <= (this.jjInactivityWarningTime + 6000)) {
            // Checking if activity form is running
            activityFormCheckIntervall = 60;
            this.settingsService.getUserSetting(Setting.ActivityFormLastTick).subscribe(
              setting => {
                if (setting) {
                  let dateDiff: number;
                  dateDiff = moment(Date.now()).diff(Number(setting.value), 'second');
                  if (dateDiff <= 120) {
                    console.log('Activity form - Suspending inactivity logout');
                    this.jjInactivityTimeNum = this.jjInactivityTimeNum + 300000;
                  }
                } else {
                  this.titleService.setTitle(this.axivasTranslateService.getTranslationTextForToken('inactivity.PageHeader'));
                }
              }
            );
          } else {
            this.titleService.setTitle(this.oldTitle);
          }
        }
        activityFormCheckIntervall = activityFormCheckIntervall - 1;
        if (this.jjInactivityTimeNum <= 1) {
          this.jjCounterActive = false;
          this.titleService.setTitle(this.oldTitle);          
        }
      });
    }
  }

  public resetJollyJupiterTimer() {
    this.jjInactivityTimeNum = this.jjInactivityStartTime;
  }
}
