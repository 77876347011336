import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventbookingBatchType, EventbookingbatchService } from 'app/jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.service';
import { WizardMode, WizardService } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-consentmanagementdashboard-item',
  templateUrl: './consentmanagementdashboard-item.component.html',
  styleUrls: ['./consentmanagementdashboard-item.component.scss']
})
export class ConsentmanagementdashboardItemComponent implements OnInit {
  lastXDays = null;
  timeframetext = '';
  availableMedia = [];
  medialist = [];
  pieId = '';
  pieIdOptOut = '';
  isMediaselectionExpanded = false;
  loadingData = false;
  mode ='1';
  valuesPrepared = false;
  chartData = {
    color: [],
    data: [
      {
            "name": "Email",
            "value": 17,
        },
      {
          "name": "AllMedia",
            "value": 8,

        }
    ],
    title: "",
    type:'pie'
};

  // Container
  innerHTMLContent = `<jj-universal controlid="201179"> </jj-universal>`;
  innerHTML = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private eventService: EventService,
    public externaldatasourceService: ExternaldatasourceService,
    private domSanitizer: DomSanitizer,
    private wizardService: WizardService,
    private eventbookingbatchService: EventbookingbatchService
  ) { }

  ngOnInit(): void {
    this.pieId = 'pie_'.concat(this.commonService.createGuid());
    this.pieIdOptOut = 'pie_'.concat(this.commonService.createGuid());
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.getAvailableMedia();
    this.callEntityTable(true);
  }

  getAvailableMedia() {
    const tableData = this.applicationInfoService.projectLookupTables.find(
      projectLookupTable => projectLookupTable.definitionId == Number(16) && projectLookupTable.source == 'central'
    );
    if (tableData) {
      tableData.lookups.forEach(data => {
        if (data.isActive) {
          const dataItem = JSON.parse(JSON.stringify(data));
          dataItem.isSelected = true;
          dataItem.displayName = dataItem.defaultName;
          this.availableMedia.push(dataItem);
        }
      });
    }
  }

  changeSelection(medialistItem = null) {
    if (medialistItem == null) {
      this.callEntityTable(true);
    } else {
      medialistItem.isSelected = !medialistItem.isSelected;
      this.callEntityTable(true);
    }
  }

  getId(medialistItem) {
    return 'consentManagementDashboardItem'.concat(medialistItem.id.toString());
  }

  toNumber(value) {
    return Number(value);
  }

  getData(poolId, consentTypes, mediumTypes) {
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(774, [
      poolId,
      consentTypes,
      mediumTypes,
      this.lastXDays
    ]).then(getDataResult => {
      this.fillMediaList(getDataResult);
      this.loadingData = false;
    });
  }

  fillMediaList(dataSet) {
    this.chartData = {
      color: [],
      data: [],
      title: '',
      type:'pie'
    };
    const tableData = this.applicationInfoService.projectLookupTables.find(
      projectLookupTable => projectLookupTable.definitionId == Number(16) && projectLookupTable.source == 'central'
    );
    if (tableData) {
      tableData.lookups.forEach(data => {
        if (data.isActive) {
          const dataItem = JSON.parse(JSON.stringify(data));
          dataItem.isSelected = true;
          dataItem.displayName = dataItem.defaultName;
          let mediaData = dataSet.filter(resultItem => resultItem.lookupMediumRefId == data.id );
          dataItem.value = mediaData.length;
          if (mediaData.length > 0) {
            this.chartData.data.push({
              name: dataItem.displayName , 
              value: mediaData.length, 
            });
          }
        }
      });
    }
    this.eventService.customEvent.emit({
      id: 'updatePieChart',
      value: this.pieId,
      chartData: this.chartData
    });
  }


  getSelectedValueString() {
    let returnValue = '';
    this.availableMedia.forEach(mediaListItem => {
      if (mediaListItem.isSelected) {
        if (returnValue != '') {
          returnValue = returnValue.concat(', ');
        }
        returnValue = returnValue.concat(mediaListItem.defaultName);
      }
    });
    return returnValue;
  }

  callEntityTable(getData = false) {
    const consentTypes = [];
    const mediumTypes = []
    this.availableMedia.forEach(mediaListItem => {
      if (mediaListItem.isSelected) {
        mediumTypes.push(mediaListItem.id);
      }
    });
    if (this.mode == '1') {
      consentTypes.push(1815);
    } else {
      consentTypes.push(44);
    }
    if (getData) {
      this.getData(this.applicationInfoService.poolId, consentTypes, mediumTypes);
    }
    let consentDetails = {
      id: 'updateEntityTableViewConsent',
      consentTypes: consentTypes,
      mediumTypes: mediumTypes,
      lastXDays: this.lastXDays
    }
    this.eventService.customEvent.emit(consentDetails);
    if (!this.valuesPrepared) {
      this.applicationInfoService.miscSettings['updateEntityTableViewConsent'] = consentDetails;
      this.valuesPrepared = true;
    }
  }

  startMicroCampaign() {
    const consentTypes = [];
    const mediumTypes = []
    this.availableMedia.forEach(mediaListItem => {
      if (mediaListItem.isSelected) {
        mediumTypes.push(mediaListItem.id);
      }
    });
    if (this.mode == '1') {
      consentTypes.push(1815);
    } else {
      consentTypes.push(44);
    }

    this.eventbookingbatchService.createMicroCampaign('New microcampaign', EventbookingBatchType.MicroCampaign, true, consentTypes, mediumTypes, this.lastXDays);
  }

  startMailshot() {

  }
}
