import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';

import { Location } from '@angular/common';

@Injectable()
export class AuthGuard {
  returnUrl: string;

  constructor(private authService: AuthService, private userService: UserService, private router: Router
  , location: Location) { }

  canActivate() {
    return true;
      // return this.authService.isLoggedInObs().mergeMap( () =>
      //   this.userService.getApplicationUser().mergeMap( () =>
      //   this.userService.getUserPermissions()
      //   ).mergeMap( (res) => {
      //     if (res.length === 0) {
      //       this.router.navigate(['401']);
      //       return Observable.of(false);
      //     } else {
      //       return Observable.of(true);
      //     }
      //   }
      // )
      // );
  }

}
