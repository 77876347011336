import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-admin-uicontrolmethodparameterinformation',
  templateUrl: './admin-uicontrolmethodparameterinformation.component.html',
  styleUrls: ['./admin-uicontrolmethodparameterinformation.component.scss']
})
export class AdminUicontrolmethodparameterinformationComponent implements OnInit {
  dataArray = [];
  displayedColumns = ['id', 'method', 'index', 'informationtext', 'optional', 'delete'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  methodTypes = InternalMethodType;
  methodTypesArray = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private methodService: MethodService
  ) { }

  ngOnInit(): void {
    this.methodTypesArray = this.commonService.enumToArray(this.methodTypes);
    this.methodTypesArray.sort((a, b) => a.id < b.id ? -1 : 1);
    this.getData();
  }

  getData() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(641)
    .then(getDataResult => {
      getDataResult.sort((a, b) => {
        if (a.method === b.method) {
          return a.index < b.index ? -1 : 1
        } else {
          return a.method < b.method ? -1 : 1
        }
      });
      this.dataArray = getDataResult;
      this.dataSource = new MatTableDataSource(getDataResult);
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  updateData(item)  {
    this.externaldatasourceService.executeExternalDataSource(643, [
      this.commonService.getModifyArrayBody(item, [])
    ])
    .then(() => { })
    .catch(() => { });
  }

  deleteData(item) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(644, [item.id])
    .then(() => {
      this.getData();
      this.loaderService.display(false);
    })
    .catch(() => { this.loaderService.display(false); });
  }

  createData() {
    const item = new Object();
    item['method'] = InternalMethodType.AskYesNo;
    item['index'] = 1;
    item['informationText'] = 'text';
    item['optional'] = false;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(642, [
      this.commonService.getModifyArrayBody(item, [])
    ])
    .then(() => {
      this.getData();
      this.loaderService.display(false);
    })
    .catch(() => { this.loaderService.display(false); });
  }
}
