import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-spacer',
  templateUrl: './custom-spacer.component.html',
  styleUrls: ['./custom-spacer.component.scss']
})
export class CustomSpacerComponent extends CustomControlBaseClass implements OnInit {

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
  }
}
