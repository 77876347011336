import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';
import { WizardMode, WizardService } from '../wizard/wizard.service';

@Component({
  selector: 'app-mocoprojectassignment',
  templateUrl: './mocoprojectassignment.component.html',
  styleUrls: ['./mocoprojectassignment.component.scss']
})
export class MocoprojectassignmentComponent implements OnInit, OnDestroy {
  wizardFinishedEventSubscription: Subscription = new Subscription();
  @Input() campaignId = null;

  definitionId = 59;
  mocoProjects = [];
  mocoProjectTasks = [];
  lookupValues = [];
  assignments = [];
  newTask;
  assignmentTasks = [];
  loadingData = true;
  displayedColumns = ['project', 'task', 'lookup', 'delete'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  mocoTasks = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private wizardService: WizardService
  ) { }

  ngOnInit(): void {
    this.getMocoProjectAssignments();
    this.getMocoProjects();
    this.getTasksOfMocoProjects();
    this.getLookupValues();

    this.wizardFinishedEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(event => {
      this.getMocoProjectAssignments();
    });
  }

  ngOnDestroy(): void {
    if (this.wizardFinishedEventSubscription) { this.wizardFinishedEventSubscription.unsubscribe(); }
  }
  resetAssignment() {
    this.newTask = new Object();
    this.newTask.mocoProjectTaskId = null;
    this.newTask.campaignId = this.campaignId;
  }

  getMocoProjects() {
    this.externaldatasourceService.executeExternalDataSource(458, [])
    .then(getMocoProjectsResult => {
      getMocoProjectsResult.sort((a, b) => a.name < b.name ? -1 : 1);
      this.mocoProjects = getMocoProjectsResult.filter(mocoproject => mocoproject.active == true);
    });
  }

  getLookupValues() {
    this.externaldatasourceService.executeExternalDataSource(387, [this.definitionId])
    .then(getLookupValuesResult => {
      getLookupValuesResult.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
      this.lookupValues = getLookupValuesResult;
    });
  }

  changeProject(element) {
    const tasks = this.getTaskstOfSpecificProject(element.mocoProjectId);
    element.mocoProjectTaskId = tasks[0].id;
    this.updateAssignment(element);
  }

  getTaskstOfSpecificProject(projectId = null) {
    const tasks = this.mocoTasks.filter(task => task.projectId == projectId);
    if (tasks) {
      return tasks;
    } else {
      return [];
    }
  }

  getTasksOfMocoProjects() {
    this.externaldatasourceService.executeExternalDataSource(459, [null])
    .then(getTasksOfMocoProjectResult => {
      getTasksOfMocoProjectResult.sort((a, b) => a.name < b.name ? -1 : 1);
      this.mocoTasks = getTasksOfMocoProjectResult;
    });
  }

  getMocoProjectAssignments() {
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(454, [])
    .then(getMocoProjectAssignmentsResult => {
      this.assignments = getMocoProjectAssignmentsResult;
      this.dataSource = new MatTableDataSource(getMocoProjectAssignmentsResult);
      this.loadingData = false;
    });
  }

  addAssignment() {
    this.externaldatasourceService.executeExternalDataSource(455, [
      this.commonService.getModifyArrayBody(this.newTask, [])
    ])
    .then(() => {
      this.getMocoProjectAssignments();
    });
  }

  deleteAssignment(assignment) {
    this.externaldatasourceService.executeExternalDataSource(457, [
      assignment.id
    ])
    .then(() => {
      this.getMocoProjectAssignments();
    });
  }

  updateAssignment(assignment) {
    this.externaldatasourceService.executeExternalDataSource(456, [
      this.commonService.getModifyArrayBody(assignment, ['campaign', 'typeLookup', 'project'])
    ])
    .then(() => {
      // this.getMocoProjectAssignments();
    });
  }

  createNewMocoAssignment() {
    this.wizardService.showWizard(WizardMode.Moco, { mocoProjectTaskId: null, mocoProjectId: null, typeLookupId: null });
  }
}
