<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div *ngIf="wizardService.wizardPage == 1" class="wizardPage autoOverflowY">
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.DisplayName' | texttransform }}</label>
            <input [(ngModel)]="wizardService.wizardArray.displayName" type="text" class="wizardInput">
        </div>

        <label class="everleadSmallHeader">{{ 'SMTPSettings.Label.ReceiveSettings' | texttransform }}</label>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.Server' | texttransform }}</label>
            <input [(ngModel)]="wizardService.wizardArray.serverReader" type="text" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.Port' | texttransform }}</label>
            <input [(ngModel)]="wizardService.wizardArray.portReader" type="number" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.UserName' | texttransform }}</label>
            <input [(ngModel)]="wizardService.wizardArray.userNameReader" type="text" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.Password' | texttransform }}</label>
            <input [(ngModel)]="wizardService.wizardArray.passwordReader" type="password" class="wizardInput">
        </div>

        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.UseSSL' | texttransform }}</label>
            <input [(ngModel)]="wizardService.wizardArray.useSslReader" type="checkbox">
        </div>
        
        <button [disabled]="wizardService.wizardArray.displayName == ''" class="jjButtonColor jjButtonStyle" (click)="checkEMailReader()">{{ 'SMTPSettings.Label.TestAndSave' | texttransform }}</button>
        <br><br>

        <label class="everleadSmallHeader">{{ 'SMTPSettings.Label.SendSettings' | texttransform }}</label>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.Server' | texttransform }}
            </label>
            <input [(ngModel)]="wizardService.wizardArray.serverSender" type="text" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.Port' | texttransform }}
            </label>
            <input [(ngModel)]="wizardService.wizardArray.portSender" type="number" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.UserName' | texttransform }}
            </label>
            <input [(ngModel)]="wizardService.wizardArray.userNameSender" type="text" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.Password' | texttransform }}
            </label>
            <input [(ngModel)]="wizardService.wizardArray.passwordSender" type="password" class="wizardInput">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.UseSSL' | texttransform }}
            </label>
            <input [(ngModel)]="wizardService.wizardArray.useSslSender" type="checkbox">
        </div>
        <div class="wizardDetailItem">
            <label class="wizardLabel">{{ 'SMTPSettings.Label.FromEmailSender' | texttransform }}
            </label>
            <input [(ngModel)]="wizardService.wizardArray.fromEmailSender" type="text" class="wizardInput">
        </div>
        
        <button [disabled]="wizardService.wizardArray.displayName == ''" class="jjButtonColor jjButtonStyle" (click)="checkEMailSender()">{{ 'SMTPSettings.Label.TestAndSave' | texttransform }}</button>
        <br><br>
    </div>
</div>