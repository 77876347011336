<div class="quickSearch__wrapper">
    <div class="quickSearch__interaction">
        <div class="quickSearch__header">
            <input id="quickSearchInput" type="text" class="quickSearch__searchInput" 
                [(ngModel)]="applicationInfoService.quickSearchValue"
                placeholder="{{ 'General._.QuickSearch' | texttransform }}" (keydown)="handleQuickSearchKeyDown($event)">
            <div class="quickSearch__header__allPools" *ngIf="applicationInfoService.applicationSettings['hideAllPoolQuickSearch'] != 'true'">
                <input type="checkbox" [(ngModel)]="applicationInfoService.quickSearchAllPools">
                <div class="ellipsisText">{{ 'QuickSearch.Label.AllPools' | texttransform }}</div>
            </div>            
            <svg-icon src="assets/images/svg/search.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" (click)="searchForValue()" class="fingerCursor"></svg-icon>   
        </div>
        <div class="quickSearch__filterBar" *ngIf="searchResults != null">
            <div class="quickSearch__filterBar__filterInput">
                <input type="text" [(ngModel)]="filterString" placeholder="{{ 'General._.Filter' | texttransform }}">
            </div>
            <div class="quickSearch__filterBar__resultContainer" (click)="changeResultType(0)">
                <div class="ellipsisText" [ngClass]="{ 
                    'quickSearch__filterBar__resultContainer__active': resultType == 0
                }">{{ 'QuickSearch.Label.ResultAll' | texttransform }}</div>
                <div class="quickSearch__filterBar__resultContainer__icon quickSearch__filterBar__resultContainer__icon__all">{{ searchResults.num_all }}</div>
            </div>
            <div class="quickSearch__filterBar__resultContainer" (click)="changeResultType(1)">
                <div class="ellipsisText" [ngClass]="{ 
                    'quickSearch__filterBar__resultContainer__active': resultType == 1
                }">{{ 'QuickSearch.Label.ResultAccounts' | texttransform }}</div>
                <div class="quickSearch__filterBar__resultContainer__icon quickSearch__filterBar__resultContainer__icon__account">{{ searchResults.num_accounts }}</div>
            </div>
            <div class="quickSearch__filterBar__resultContainer quickSearch__filterBar__resultContainer__last" (click)="changeResultType(2)">
                <div class="ellipsisText" [ngClass]="{ 
                    'quickSearch__filterBar__resultContainer__active': resultType == 2
                }">{{ 'QuickSearch.Label.ResultContacts' | texttransform }}</div>
                <div class="quickSearch__filterBar__resultContainer__icon quickSearch__filterBar__resultContainer__icon__contact">{{ searchResults.num_contacts }}</div>
            </div>
        </div>        
    </div>

    <div *ngIf="searchResults != null">
        <div *ngIf="searchResults.num_all == 0 && searching==false" class="quickSearch__infoContainer">
            <i class="fas fa-frown"></i> {{ 'QuickSearch.Label.NoResults' | texttransform }}
        </div>
        <div *ngIf="searchResults.num_accounts == 100 || searchResults.num_contacts == 100" class="quickSearch__infoContainer">
            <i class="fas fa-exclamation-triangle"></i> {{ 'QuickSearch.Label.TooManyResults' | texttransform }}
        </div>
    </div>        

    <div class="quickSearch__results fingerCursor" *ngIf="searchResults != null && searching == false">
        <div *ngIf="(resultType == 1 || resultType == 0) && searchResults.num_accounts > 0">
            <div *ngFor="let searchResult of searchResults.accounts">
                <div class="quickSearch__results__panel" (click)="loadAccount(searchResult)" *ngIf="checkIfItemCorrespondsFilter(searchResult, 1)">
                    <div class="quickSearch__results__panel__icon quickSearch__results__panel__icon__account">
                        <svg-icon src="assets/images/svg/account.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="searchForValue()"></svg-icon>   
                    </div>
                    <div class="quickSearch__results__panel__text">
                        <div class="quickSearch__results__panel__text__inner">
                            <div class="quickSearchId">{{ searchResult.id }} </div> <label [innerHTML]="accountStrings[searchResult.id].accountText">
                                <!-- {{ accountStrings[searchResult.id].accountText }} -->
                            </label>
                        </div>       
                        <div class="everleadCoral ellipsisText  quickSearch__results__panel__text__pool">
                            {{ searchResult.poolname }}
                        </div>                  
                    </div>                
                </div>
            </div>
        </div>

        <div *ngIf="(resultType == 2 || resultType == 0) && searchResults.num_contacts > 0">
            <div *ngFor="let searchResult of searchResults.contacts">
                <div class="quickSearch__results__panel" (click)="loadContact(searchResult)" *ngIf="checkIfItemCorrespondsFilter(searchResult, 2)">
                    <div class="quickSearch__results__panel__icon quickSearch__results__panel__icon__contact">
                        <svg-icon src="assets/images/svg/contact-pool.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="searchForValue()"></svg-icon>                           
                    </div>
                    <div class="quickSearch__results__panel__text">
                        <div class="quickSearch__results__panel__text__inner" [ngClass]="{
                            'quickSearch__results__panel__text__inner__contact': searchResult.accountText != null
                        }">
                            <div class="quickSearchId">{{ searchResult.id }} </div> 
                            <label [innerHTML]="contactStrings[searchResult.id].contactText"></label>
                            <span *ngIf="searchResult['account.is_dummy_account'] == false" class="d-flex">
                                <div class="quickSearch__results__panelsmall__icon quickSearch__results__panel__icon__contactAccount">
                                    <svg-icon src="assets/images/svg/account.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"></svg-icon>   
                                </div>                                
                                <label [innerHTML]="contactStrings[searchResult.id].accountText"></label>
                            </span>                            
                        </div>            
                        <div class="everleadCoral ellipsisText quickSearch__results__panel__text__pool">
                            {{ searchResult.poolname }}
                        </div>    
                    </div>
                    <!-- <div class="quickSearch__results__panel__additionalAccountInformation" *ngIf="searchResult['account.is_dummy_account'] == false">
                        <div class="quickSearch__results__panelsmall" (click)="loadContact(searchResult)" *ngIf="checkIfItemCorrespondsFilter(searchResult)">
                            <div class="quickSearch__results__panelsmall__icon quickSearch__results__panel__icon__contactAccount">
                                <svg-icon src="assets/images/svg/account.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12 }" (click)="searchForValue()"></svg-icon>   
                            </div>
                            <div class="quickSearch__results__panelsmall__text">
                                <div class="quickSearch__results__panelsmall__text__inner">
                                    <label [innerHTML]="contactStrings[searchResult.id].accountText"></label>
                                </div>     
                            </div>                
                        </div>                        
                    </div> -->
                </div>
            </div>
        </div>
    </div>

</div>