import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { CommonService } from './common.service';
import { environment } from 'environments/environment';

declare function aJStartjTracker(trackingDatabase): any;
declare function aJpageView(): any;

@Injectable({
  providedIn: 'root'  
})
export class TrackingService {
  // trackingDatabase = 'dmc_20211221_testweb1';
  trackingEnabled = false;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private shareAPI: SharedAPI
  ) { }

  startTracking() {
    this.trackingEnabled = false;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['trackingDatabase']) && environment.dataStage == 'urlLive') {
      this.trackingEnabled = true;
    } 
  }

  trackAPIPageView(from, to) {
    if (this.trackingEnabled) {
      this.shareAPI.trackPage(this.applicationInfoService.applicationSettings['trackingDatabase'], 
        encodeURIComponent(from), 
        encodeURIComponent(to), 
        encodeURIComponent(this.applicationInfoService.user.email)
      );      
    }    
  }

  trackPageView() {
    
  }
}
