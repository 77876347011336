<div class="debugPanel__wrapper"><i class="fas fa-user-shield debugInfoIcon" [tippy]="'Admin Panel'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}"
        (click)="loadAdminPanel()" style="color: blue; margin-right: 10px;"></i>
    <i class="headerDivider"></i>
    <i class="fas fa-tasks debugInfoIcon" [tippy]="'Taskinfo'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showTaskInfo()"></i>
    <i class="fas fa-chess debugInfoIcon" [tippy]="'Dictionarys anzeigen'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showApplicationInfo()"></i>
    <i class="fas fa-user debugInfoIcon" [tippy]="'Informationen zum angeldeten User'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showUserInformation()"></i>
    <i class="fas fa-project-diagram debugInfoIcon" [tippy]="'Projekt-Management'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="projectManagement()"></i>
    <i class="fas fa-globe debugInfoIcon" [tippy]="'Übersetzungen verwalten'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showTranslationForm()"></i>
    <i class="fas fa-file-upload debugInfoIcon" [tippy]="'Daten importieren'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="importMask()"></i>
    <i class="fas fa-vials debugInfoIcon" [tippy]="'Vorhandenen Task simulieren'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}"
        (click)="createAccountEnvironmentForProjectSetup()"></i>    
    <i class="fas fa-undo-alt debugInfoIcon" [tippy]="'Auditverwaltung öffnen'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="openAudit()"></i>    
</div>