<div  class="timelineWrap " [ngClass]="{ 
    customTimeLineDarkBackground: type != 2 && customLeadStateMode == false,
    customLeadStateMode: customLeadStateMode == true
}">  
    <div class="combinedTimelineFilterPanelTop" [ngClass]="{}" *ngIf="customLeadStateMode == false">
        <div class="combinedTimelineFilterPanelTop__description">
            <button class="everleadCustomButton" (click)="newActivity()" *ngIf="type != 2">{{ 'AccountFooter.Label.NewActivity' | texttransform }}</button>
        </div>
        <div class="combinedTimelineFilterPanelTop__activities" *ngIf="type == 1 && applicationInfoService.currentContact != null">
            <div class="combinedTimelineFilterPanelTop__activities__item"><input type="radio" value="1" [(ngModel)]="activityTarget" (change)="getData()">{{ 'AccountFooter.Label.AllActivities' | texttransform }}</div>
            <div class="combinedTimelineFilterPanelTop__activities__item"><input type="radio" value="2" [(ngModel)]="activityTarget" (change)="getData()">{{ 'AccountFooter.Label.OnlyForContact' | texttransform }}</div>
        </div>
        <div class="combinedTimelineFilterPanelTop__filter">
          <!-- <div class="callSelector">
                <label>items per call</label>
                <select [(ngModel)]="limit">
                    <option [ngValue]="5">5</option>
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="15">15</option>
                    <option [ngValue]="20">20</option>
                </select>
            </div>  -->
            {{ 'Timeline.Label.FilterOptions' | texttransform }} 
            <i class="fas fa-lg fa-cog fingerCursor" [ngClass]="{ 
                everleadCoral: showAdditionalFilterBar(timelineId) == true }" 
                (click)="timelineService.filterPanelOpen[timelineId] = !timelineService.filterPanelOpen[timelineId]" 
                [tippy]="getSelectedFilter()"
                [tippyOptions]="{placement:'bottom'}"
            ></i>
            <i class="fas fa-lg fa-sync fingerCursor" (click)="getData()"></i>
            <i *ngIf="type != 2" class="fas fa-lg fingerCursor " [tippy]="'ActivityList.Label.ExpandAll' | texttransform" [tippyOptions]="{ placement:'bottom' }" (click)="isGlobalResultViewExpanded = !isGlobalResultViewExpanded" [ngClass]="{
                'fa-chevron-down': !isGlobalResultViewExpanded,
                'fa-chevron-up': isGlobalResultViewExpanded
            }"></i>   
        </div> 
    </div>

   
    <div *ngIf="type == 1 && timelineService.filterPanelOpen[timelineId] == true">
        <div class="combinedTimelineFilterPanelItems">
            <div class="combinedTimelineFilterPanelItems__list">
                <div class="combinedTimelineFilterPanelItems__item" *ngFor="let activityFilterOption of timelineService.activityFilterOptions"                     
                    (click)="toggleSearch(activityFilterOption.identifier)">
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 40 }" class="combinedTimeline__header__filter__item__icon" 
                        *ngIf="commonService.checkIfItemIsInArray(timelineService.activityFilterType, activityFilterOption.identifier)"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 40 }" class="combinedTimeline__header__filter__item__icon" 
                        *ngIf="!commonService.checkIfItemIsInArray(timelineService.activityFilterType, activityFilterOption.identifier)"></svg-icon>
                    {{ activityFilterOption.defaultName | texttransform }}
                </div>
            </div>
        </div>
        <div class="combinedTimelineFilterButtons">
            <button class="everleadCustomButton" (click)="timelineService.clearActivityFilter()">{{ 'Timeline.Label.SelectNone' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="timelineService.resetActivityFilter()">{{ 'Timeline.Label.SelectAll' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="setFilter()">{{ 'Timeline.Label.SetFilter' | texttransform }}</button>
        </div>
    </div>

    <div *ngIf="type == 2 && timelineService.filterPanelOpen[timelineId] == true">
        <div class="combinedTimelineFilterPanelItems">
            <div class="combinedTimelineFilterPanelItems__list">
                <div class="combinedTimelineFilterPanelItems__item" *ngFor="let activityFilterOption of timelineService.microcampaignFilterOptions" 
                    (click)="toggleSearch(activityFilterOption.identifier)">
                    <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 40 }" class="combinedTimeline__header__filter__item__icon" 
                        *ngIf="commonService.checkIfItemIsInArray(timelineService.microcampaignFilterType, activityFilterOption.identifier)"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 40 }" class="combinedTimeline__header__filter__item__icon" 
                        *ngIf="!commonService.checkIfItemIsInArray(timelineService.microcampaignFilterType, activityFilterOption.identifier)"></svg-icon>
                    {{ activityFilterOption.defaultName | texttransform }}
                </div>
            </div>   
            <div class="combinedTimelineFilterButtons">
                <button class="everleadCustomButton" (click)="timelineService.clearMicrocampaignFilter()">{{ 'Timeline.Label.SelectNone' | texttransform }}</button>
                <button class="everleadCustomButton" (click)="timelineService.resetMicrocampaignFilter()">{{ 'Timeline.Label.SelectAll' | texttransform }}</button>
                <button class="everleadCustomButton" (click)="setFilter()">{{ 'Timeline.Label.SetFilter' | texttransform }}</button>
            </div>     
        </div>
    </div>

    <div class="combinedTimeline__header__wrapper" *ngIf="(type == 1 || isResultViewExpanded && element.length > 0) && customLeadStateMode == false">
        <div class="combinedTimeline__header__agent">
            <div class="combinedTimeline__header__agent__name">
                {{ 'Timeline.Label.EverleadUser' | texttransform }}
            </div>
        </div>
        <div class="combinedTimeline__header__contact">
            <div class="combinedTimeline__header__contact__name">
                {{ 'Timeline.Label.Contact' | texttransform }}
            </div>
        </div>
    </div>

    <span *ngIf="loadingData==true" class="combinedTimeline__spinner">
        <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData' | texttransform}}<br>
    </span>



    <div class="combinedTimeline__wrapper" *ngIf="loadingData==false && (isResultViewExpanded || type == 1)">
        <div #scrollContainer *ngFor="let item of element; let i = index"  class="combinedTimeline__item" [ngClass]="{ 
            combinedTimeline__item__reverse: checkIfContactActivity(item),
            combinedTimeline__item__cuddle: checkIfContactActivityNeedsToCuddle(item, i)
        }">
            <div class="lastBookingForLeadstate" *ngIf="customLeadStateModeTarget == 'contact' && i == 0"><i class="fas fa-star"></i></div>
            <div class="combinedTimeline__item__data">
                <div class="combinedTimeline__item__data__line">

                </div>
                <div class="combinedTimeline__item__data__top">
                    {{ item.date | date: 'medium'}}
                    {{ item.followUpDate | date: 'medium'}}
                </div>
                <div class="combinedTimeline__item__data__header">
                    <div class="combinedTimeline__item__data__header__icon">
                        <i [class]="getItemIcon(item, 'circle-icon fas ')"></i>
                    </div>
                    <div class="combinedTimeline__item__data__header__step">
                        <div class="ellipsisText"> 
                            {{ item.taskStep | texttransform  }} 
                            {{ item.taskStepName | texttransform  }} 
                            <span *ngIf="customLeadStateMode == false">
                                <i class="fas fa-sticky-note fa-lg combinedTimeLineNote" [tippy]="'Task.Label.Subject' | texttransform" *ngIf="item.taskSubject"></i>
                                {{ item.taskSubject }}
                            </span>
                            {{ item.conversionAssetName | texttransform  }}
                            <ng-container *ngIf="item.eventType == 'pageView'"><a [href]=item.eventUrl
                                    [tippy]=item.eventUrl [tippyOptions]="{maxWidth: '80rem'}" target="_new">{{ getModifiedDemoUrl(item.eventUrl) }}</a></ng-container>
                        </div>
                    </div>
                    <div class="combinedTimeline__item__data__header__result">
                        <div class="ellipsisText" *ngIf="item.taskTempResultId != null">
                            {{ commonService.getTranslationValueFromArray(getQMResultName(item.taskTempResultId)) | texttransform }}
                        </div>
                        <div class="ellipsisText" *ngIf="item.taskTempResultId == null">                            
                            <i class="fas fa-ban fingerCursor" (click)="setItemAsDeleted(item)" 
                            *ngIf="applicationInfoService.isDeveloper  && item.type == 'task'" style="margin: 0 5px;"></i>

                            <span [tippy]="item.taskResult">{{ item.taskResult | texttransform }}</span>
                            {{ item.resultName | texttransform  }}                            
                            <span *ngIf="isValid(item.eventType) && !isValid(item.maileventType)">{{ item.eventType }}</span>
                            <span *ngIf="isValid(item.maileventType)">{{getEventTranslation(item.maileventType)}}</span>                                                        
                            <span *ngIf="item.mailDirection == 'Outgoing'">{{ 'Timeline.Label.OutgoingEMail' | texttransform }}</span>
                            <span *ngIf="item.mailDirection == 'Incoming'">{{ 'Timeline.Label.IncomingEMail' | texttransform }}</span>                            
                            {{ item.conversionAssetType | texttransform  }}
                        </div>
                    </div>
                </div>

                <div class="combinedTimeline__item__data__content combinedTimeline__item__data__border">
                    <i class="fas fa-edit fa-lg combinedTimeline__item__data__content__edit fingerCursor"
                        *ngIf="canEditTask(item) && item.taskTempResultId == null" (click)="editActivity(item)"></i>

                    <i class="fas fa-edit fa-lg combinedTimeline__item__data__content__edit fingerCursor"
                        *ngIf="item.type=='conversion'" (click)="editConversion(item)"></i>
                    <div *ngIf="item.taskTempResultId != null" class="combinedTimeline__item__data__content__infoline combinedTimelineNeedQM">
                        {{ 'Timeline.Label.RequiresQM' | texttransform }}
                    </div>

                    <div *ngIf="customLeadStateModeTarget == 'contact' && i == 0" class="infoLine">
                        <div class="everleadCoral ellipsisText">{{ 'Task.Label.ThisBookingDefinedLeadstate' | texttransform }}</div>                            
                    </div>  

                    <div *ngIf="applicationInfoService.isDeveloper">
                        <div *ngIf="isValid(item.taskChainGuid)" class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">Task-Id</div>
                            <div class="combinedTimeline__item__data__content__infoline__name">{{ item.id }}
                            </div>
                        </div>
                        <div *ngIf="isValid(item.taskChainGuid)" class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">{{ 'ActivityList.Label.TaskChainGuid' | texttransform }}</div>
                            <div class="combinedTimeline__item__data__content__infoline__name">{{ item.taskChainGuid }}
                            </div>
                        </div>
                        <div *ngIf="isValid(item.taskFollowUpTaskId)" class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">{{ 'ActivityList.Label.TaskFollowUpTaskId' | texttransform }}</div>
                            <div class="combinedTimeline__item__data__content__infoline__name">{{ item.taskFollowUpTaskId }}
                            </div>
                        </div>                        
                    </div>

                    <div *ngIf="isValid(item.taskLeadStatus)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{ 'Task.Label.LeadState' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name">{{ item.taskLeadStatus }}</div>                            
                    </div>     
                    
                    <div *ngIf="isValid(item.taskContact)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{ 'Timeline.Label.Contact' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name">{{ item.taskContact }}</div>                            
                    </div>
                   
                    <div *ngIf="isValid(item.eventReferringUrl)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{
                            'Timeline.Label.ReferringURL' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name" [tippy]="item.eventReferringUrl" [tippyOptions]="{placement: 'auto', maxWidth:'80rem'}">
                            {{ getModifiedDemoUrl(item.eventReferringUrl) }}
                        </div>
                    </div>
                    <div *ngIf="isValid(item.taskResultUser)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{
                            'Timeline.Label.TaskResultUser' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name">
                            {{ item.taskResultUser }}                            
                        </div>
                    </div>
                    <div *ngIf="isValid(item.mailEventData)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{
                            'Timeline.Label.MailEventData' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name" [tippy]="item.mailEventData | json">
                            {{ item.mailEventData | json }}                            
                        </div>
                    </div>                    
                    <div *ngIf="(isValid(item.maileventMailId) || item.type=='mail' || item.taskMailId != null) && item.taskTempResultId == null" 
                        class="maxWidth" (click)="getMailPreview(item)">
                        <div class="showEMail fingerCursor">
                            <i class="fas fa-envelope fa-lg fingerCursor" [tippy]="'EMailReview.Label.ShowEMail' | texttransform"></i>
                            {{ 'EMailReview.Label.ShowEMail' | texttransform }}
                        </div>
                    </div>
              
                    <div *ngIf="isValid(item.conversionUrl)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{ 'ActivityList.Label.ConversionUrl' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name" [tippy]="item.conversionUrl" [tippyOptions]="{placement: 'auto', maxWidth:'80rem'}">{{  getModifiedDemoUrl(item.conversionUrl) }}</div>
                    </div>                                        

                    <div *ngIf="isValid(item.url)" class="combinedTimeline__item__data__content__infoline">
                        <div class="combinedTimeline__item__data__content__infoline__label">{{
                            'ActivityList.Label.Url' | texttransform }}</div>
                        <div class="combinedTimeline__item__data__content__infoline__name"><a [href]="item.url" target="_new">{{ item.url }}</a>
                        </div>
                    </div>                    
                   
                    <div *ngIf="commonService.checkIfItemIsInArray(expandedItems, item.id) || isGlobalResultViewExpanded">
                            <div *ngIf="isValid(item.data)" class="combinedTimelineData">
                            <div class="combinedTimelineData__header" >{{ 'ActivityList.Label.Data' | texttransform }}</div>
                            <div class="combinedTimelineData__content">
                                <span *ngIf="   item.resultType != 'formsubmission' &&
                                                dataArrayItem(item) != true
                                ">{{ item.data }}</span>
                                <span *ngIf="   item.resultType == 'formsubmission' ||
                                                dataArrayItem(item) == true
                                ">
                                    <div *ngFor="let dataItem of formatArray(item.data, item)" class="combinedTimeline__item__data__content__infoline__name__dataItem">
                                        <div class="combinedTimelineData__dataHeader">
                                            {{ dataItem.name }}
                                        </div>
                                        <div class="combinedTimelineData__dataValue">
                                            <div *ngIf="commonService.isDate(dataItem.value)">{{ dataItem.value | date:'medium' }}</div>
                                            <div *ngIf="!commonService.isDate(dataItem.value)">{{ dataItem.value }}</div>
                                        </div>                                    
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div *ngIf="isValid(item.taskWorkflow) || isValid(item.workflowName)" class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">{{ 'ActivityList.Label.Process' | texttransform }}</div>
                            <div class="combinedTimeline__item__data__content__infoline__name">{{ item.taskWorkflow }} {{ item.workflowName }}</div>
                        </div>
                        <div *ngIf="isValid(item.taskCampaign) || isValid(item.campaignName)" class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">{{ 'ActivityList.Label.Campaign' | texttransform }}</div>
                            <div class="combinedTimeline__item__data__content__infoline__name">{{ item.taskCampaign }}{{ item.campaignName }}</div>
                        </div>
                        
                        <div *ngIf="isValid(item.taskAnswers)"
                            class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">{{ 'General.Label.Objections' | texttransform }}</div>
                            <div class="combinedTimeline__item__data__content__infoline__notes">{{ cleanTaskAnswers(item.taskAnswers) }}</div>
                        </div>

                        <div *ngIf="isValid(item.taskAnswerText)"
                            class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">{{ 'ActivityList.Label.Reason' | texttransform }}</div>
                            <div class="combinedTimeline__item__data__content__infoline__notes">{{ item.taskAnswerText }}</div>
                        </div>


                        <!-- !! from ivo : added tooltip on branch tippy-timeline-->
                        <!-- <app-conversiondetail *ngIf="isValid(item.eventId)" [eventId]="item.eventId" [darkMode]=true></app-conversiondetail> -->
                        

                        <div *ngIf="isValid(item.taskNotes) || isValid(item.taskSubject)"
                            class="combinedTimeline__item__data__content__infoline">
                            <div class="combinedTimeline__item__data__content__infoline__label">
                                {{ 'Task.Label.Notes' | texttransform }}                                
                                <i class="fas fa-copy fingerCursor" (click)="copyToClipBoard(item.taskNotes)"></i>
                            </div>
                            <div class="combinedTimeline__item__data__content__infoline__notes">
                                <div class="bold">{{ item.taskSubject }}</div>
                                <div [innerHTML]="getCleanNotes(item.taskNotes)"></div><br>
                                <br>
                            </div>
                        </div>            
                    </div>
                               
                </div>

                <div class="combinedTimeline__item__data__footer">
                    <div class="combinedTimeline__item__data__footer__timer">
                        <span *ngIf="type == 1">{{getTimeDifferenceString(item.date)}}</span>
                        <span *ngIf="type == 2">{{getTimeDifferenceString(item.followUpDate)}}</span>
                    </div>
                    <div class="combinedTimeline__item__data__footer__toggler fingerCursor"
                        *ngIf=" item.type=='task' || 
                                item.type=='conversion' || 
                                item.resultSource == 'agent' || 
                                dataArrayItem(item) == true"
                        (click)="commonService.toggleArrayItem(expandedItems, item.id)">
                        <div *ngIf="commonService.checkIfItemIsInArray(expandedItems, item.id) || isGlobalResultViewExpanded">{{ 'Timeline.Label.Less'
                            | texttransform }}</div>
                        <div *ngIf="!commonService.checkIfItemIsInArray(expandedItems, item.id) && !isGlobalResultViewExpanded">{{
                            'Timeline.Label.More' | texttransform }}</div>
                    </div>
                </div>


            </div>
            <div class="combinedTimeline__item__empty">

            </div>
        </div>
        <div class="lazyLoadingDataSpinner">
            <span *ngIf="lazyLoadingData==true" class="combinedTimeline__spinner loading-lazy-results">
                <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData' | texttransform}}<br>
            </span>    
        </div>
    </div>

    <div class="combinedTimeline__dropdown" *ngIf="type == 2 && element?.length > 0">
        <div class="combinedTimeline__dropdown__wrapper" (click)="toggleResultView()">
            <i class="fas fa-chevron-up fingerCursor" *ngIf="isResultViewExpanded && type == 2" ></i>
            <i class="fas fa-chevron-down fingerCursor" *ngIf="!isResultViewExpanded && type == 2 && element?.length > 0"></i>
            {{ 'Timeline.Label.Activities' | texttransform }}
            <span class="combinedTimeline__dropdown__elementCount" *ngIf="element.length > 0">{{element?.length}}</span>
        </div>
    </div>

    <hr *ngIf="element?.length === 0">
</div>
