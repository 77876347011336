import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-emaildistributor',
  templateUrl: './emaildistributor.component.html',
  styleUrls: ['./emaildistributor.component.scss']
})
export class EmaildistributorComponent implements OnInit {
  distributionLists = [];
  filterString = '';
  createNewMailMode = false;
  createNewMailModeDistribution = null;
  selectedDistributionList = null;
  distributionEmails = [
    { id: 1, email: 'sascha.winheim@alex-gross.com', isActive: true }
  ];
  newEmailAdress = '';

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private uiService: UiService
  ) { }

  ngOnInit(): void {
    this.getDistributionLists();
  }

  getDistributionEmails() {
    this.externaldatasourceService.executeExternalDataSource(700).then(getDistributionEmailsResult => {
      this.distributionEmails = getDistributionEmailsResult;
    });
  }

  getDistributionLists() {
    this.externaldatasourceService.executeExternalDataSource(700).then(getDistributionListsResult => {
      this.distributionLists = getDistributionListsResult;
      if (getDistributionListsResult.length > 0) {
        this.selectedDistributionList = getDistributionListsResult[0];
      }
    });
  }

  createNewDistibution(distributionList) {
    const object = {
      distributionListId: distributionList.id,
      distributionEmailId: null,
      isMailTo: false,
      isMailCc: false,
      isActive: true
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(701, [
      this.commonService.getModifyArrayBody(object, [])
    ]).then(createNewDistibutionResult => {
      this.loaderService.display(false);
      object['id']= createNewDistibutionResult.id;
      distributionList.distributions.push(object);
    }).catch(() => { 
      this.loaderService.display(false); 
    });
  }

  saveDistribution(distribution) {
    this.externaldatasourceService.executeExternalDataSource(702, [this.commonService.getModifyArrayBody(distribution, ['createdByUserId'])]);
  }

  createNewDistributionList() {
    this.externaldatasourceService.executeExternalDataSource(708).then(() => { this.getDistributionLists() });
  }

  updateDistributionList(distributionList) {
    this.externaldatasourceService.executeExternalDataSource(709, [this.commonService.getModifyArrayBody(distributionList, [])]);
  }

  deleteDistribution(distribution, distributionList) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(711, [distribution.id])
    .then(() => {
      this.loaderService.display(false);
      this.commonService.removeItemFromArray(distributionList.distributions, distribution);
    }).catch(() => { this.loaderService.display(false); });
  }

  getDivHeight() {
    let divSize = Number(this.uiService.getDesignSpanPosition('emailDistibutorDesignSpan', 60));
    return divSize + 'px'; 
  }

  checkIfValidEmail() {
    return this.commonService.validateEMail(this.newEmailAdress);
  }

  setNewMailMode(distribution) {
    this.createNewMailModeDistribution = distribution;
    this.createNewMailMode = true;
  }

  createNewDistributionEmail() {
    this.externaldatasourceService.executeExternalDataSource(710, [
      this.commonService.getModifyArrayBody({ email: this.newEmailAdress }, [])
    ])
    .then(createNewDistributionEmailResult => {
      this.getDistributionEmails();
      this.createNewMailModeDistribution.distributionEmailId = createNewDistributionEmailResult.id;
      this.saveDistribution(this.createNewMailModeDistribution);
      this.createNewMailMode = false;
    });
  }
}
