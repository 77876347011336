<span id="navigationTopLevelSpan"></span>
<div *ngIf="applicationInfoService.projectSelected && !commonService.isNullOrUndefined(this.axivasTranslateService.translationTextArray)"
    class="navigationWrapper navigationMain navigationSizing" [ngClass]="{ 
        hideInDom: !applicationInfoService.navigationExpandedMobile &&  applicationInfoService.isMobile() && axivasTranslateService,
        navigationSizingUnpinned: applicationInfoService.navigationPinned == false,
        navigationExpandedFixed: applicationInfoService.navigationExpandedFixed
    }">

    <div id="navigationDesignSpan"></div>
    <!-- project related navigation -->
    <div class="topLevelAlexGross" *ngIf="!applicationInfoService.isMobile()">
        <div class="topLevelAlexGross__inner">
            <svg-icon src="assets/images/everleadtheme/EVERLEAD_logo.svg" class="alexgrossLogo"
                [ngClass]="{ topLevelAlexGross__navigationExpandedFixedShow: applicationInfoService.navigationExpandedFixed }"
                [svgStyle]="{ 'width.px': 270, 'height.px': 50 }"></svg-icon>
            <svg-icon src="assets/images/svg/EVERLEAD-unendlich-symbol.svg" class="alexgrossLogoMinimized"
                [ngClass]="{ topLevelAlexGross__navigationExpandedFixedNoShow: applicationInfoService.navigationExpandedFixed }"
                [svgStyle]="{ 'width.px': 36, 'height.px': 50 }"></svg-icon>
        </div>
    </div>
    
    <div class="navigationItem__subLevel navigationItem__edit" (click)="designLayout()"
        *ngIf="!applicationInfoService.isMobile() && applicationInfoService.designerMode == true">
        <div class="navigationItem__subLevel__inner">
            <div class="navigationItem__iconContainer">
                <i class="fas fa-edit fa-lg"></i>
            </div>
        </div>
    </div>


    <div *ngIf="!applicationInfoService.isMobile()" class="navigationMenu">
        <div class="navigationMenu__inner navigationMenu__fixBar" [ngClass]="{
            'navigationMenu__inner__minimizedPinned': applicationInfoService.navigationPinned,
            'navigationMenu__inner__maximizedPinned': applicationInfoService.navigationExpandedFixed
        }">
            <div class="navigationMenu__fixBar__item fingerCursor" (click)="togglePin()">
                <svg-icon src="assets/images/svg/navigation/navigation_fix_inactive.svg" 
                *ngIf="!applicationInfoService.navigationPinned"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"  [title]="'Navigation.Label.PinFixActive' | texttransform"
                    class="navigationItem__redesignIcon fingerCursor" 
                ></svg-icon>
                <label *ngIf="!applicationInfoService.navigationPinned" class="navigationMenu__inner__hide fingerCursor">{{ 'Navigation.Label.FixLeft' | texttransform }}</label>
                <svg-icon src="assets/images/svg/navigation/navigation_fix_active.svg" *ngIf="applicationInfoService.navigationPinned"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [title]="'Navigation.Label.PinFixInactive' | texttransform"
                    class="navigationItem__redesignIcon fingerCursor"
                ></svg-icon>
            </div>
            <div class="navigationMenu__fixBar__item navigationMenu__fixBar__item__right navigationMenu__inner__hide fingerCursor fingerCursor" 
                *ngIf="!applicationInfoService.navigationPinned" (click)="toggleExtendedPin()">
                <label class=" fingerCursor">{{ 'Navigation.Label.Pin' | texttransform }}</label>
                <svg-icon src="assets/images/svg/navigation/navigation_pin_inactive.svg" *ngIf="!applicationInfoService.navigationExpandedFixed"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [title]="'Navigation.Label.PinActive' | texttransform"
                    class="navigationItem__redesignIcon fingerCursor" 
                ></svg-icon>                
                <svg-icon src="assets/images/svg/navigation/navigation_pin_active.svg" *ngIf="applicationInfoService.navigationExpandedFixed"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [title]="'Navigation.Label.PinFixInactive' | texttransform"
                    class="navigationItem__redesignIcon fingerCursor"
                ></svg-icon>
            </div>
            <!-- <svg-icon src="assets/images/svg/iconset/16icon-pin.svg" 
                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="toggleExtendedPin()" [title]="'Navigation.Label.PinRight' | texttransform"
                class="navigationItem__redesignIcon navigationItem__maximizePin fingerCursor" [ngClass]="{ 
                    everleadCoral: applicationInfoService.navigationExpandedFixed,
                    hideInDom: applicationInfoService.navigationPinned
                }"></svg-icon>
            <i class="fas fa-chevron-down fingerCursor everleadCoral" (click)="expandAll()" [title]="'Navigation.Label.ExpandAllItems' | texttransform"
                [ngClass]="{ hideInDom: applicationInfoService.navigationPinned }"></i>             -->
        </div>
    </div>
    <!-- <div class="navigationItem__subLevel navigationItem__pin"
        *ngIf="!applicationInfoService.isMobile()">
        <div class="navigationItem__subLevel__inner">
            <div class="navigationItem__iconContainer" (click)="togglePin()">
                <svg-icon src="assets/images/svg/iconset/16icon-pin.svg"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="navigationItem__redesignIcon" [ngClass]="{
                            everleadCoral: applicationInfoService.navigationPinned
                        }"></svg-icon>
            </div>
            <div class="navigationItem__iconContainer" (click)="toggleExtendedPin()" class="navigationExpandedFixedPin">
                <svg-icon src="assets/images/svg/iconset/16icon-pin.svg"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" class="navigationItem__redesignIcon" [ngClass]="{
                            everleadCoral: applicationInfoService.navigationExpandedFixed
                        }"></svg-icon>
            </div>
        </div>
    </div> -->



    <ng-container *ngIf="agentMode == false">
        <div class="topLevelDiv">
            <div class="topLevelDiv__inner navSectionHeaderLabel fingerCursor" (click)="setNavigationMode(true)">
                <label class="navigationItem__sectionLabel everleadCoral fingerCursor">
                    {{'General.Menu.Back' | texttransform }}
                </label>
                <div class="playIcon everleadCoral">
                    <i class="fas fa-chevron-circle-left"></i>
                </div>

            </div>
        </div>
    </ng-container>



    <div class="scrollableInner" id="jsonMenuList">
        <div class="mobile-visible">
            <div class="navigationItem__topLevel" (click)="showProjectSettings()">
                <div class="navigationItem__topLevel">
                    <label [ngClass]="{ hideInDom: !checkIfHovered() }">
                        <span [id]=applicationInfoService.projectID class="ajandoHeaderProjectNameSpan"
                            [title]="applicationInfoService.projectName">Test{{ applicationInfoService.projectName
                            }}</span>

                    </label>
                    <div class="navigationItem__subLevel" *ngIf="getUserFollowupGroup() != ''">
                        <div class="navigationItem__subLevel__inner">
                            <div class="navigationItem__iconContainer">
                                <svg-icon *ngIf="getUserFollowupGroup() != ''"
                                    src="assets/images/svg/contact-groups.svg"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    class="navigationItem__redesignIcon"></svg-icon>
                            </div>
                            <label class="navigationItem__subLevel__label" *ngIf="checkIfLabelShouldBeShown()">
                                {{ getUserFollowupGroup() | texttransform }}</label>
                        </div>
                    </div>

                    <div class="navigationItem__subLevel">
                        <div class="navigationItem__subLevel__inner">
                            <div class="navigationItem__iconContainer">
                                <svg-icon src="assets/images/svg/database-import.svg"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    class="navigationItem__redesignIcon"></svg-icon>
                            </div>
                            <label class="navigationItem__subLevel__label">{{
                                applicationInfoService.projectInfo?.pools[0]?.defaultName
                                }}</label>
                        </div>
                    </div>
                    <div class="navigationItem__subLevel" *ngIf="getUserRole() != ''">
                        <div class="navigationItem__subLevel__inner">
                            <div class="navigationItem__iconContainer">
                                <svg-icon src="assets/images/svg/rolle.svg"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    class="navigationItem__redesignIcon"></svg-icon>
                            </div>
                            <label class="navigationItem__subLevel__label">{{ getUserRole() | texttransform }}
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- JSON Menü -->
        <div class="navigationJson">
            <div *ngFor="let menuItem of navigationLevel0" [id]="menuItem.guid">
                <div [ngClass]="{ subMenuWrapper: menuItem.isExtendable }">
                    <app-navigationitem [menuItem]="menuItem"></app-navigationitem>                    
                    <div *ngFor="let child of navigationLevel1[menuItem.guid]" [id]="child.guid" [ngClass]="{
                        hideInDom: menuItem.isExtendable && applicationInfoService.userSettings['extendedNavigation' + menuItem.guid] != true
                    }">
                        <app-navigationitem [menuItem]="child" [parent]="menuItem.guid" [parents]="navigationLevel0" *ngIf="showJsonMenuItem(child)"></app-navigationitem>
                    </div>
                </div>
            </div>    
        </div>
        
        <!-- Database Menü -->
        <div *ngFor="let menuItem of menuItems" class="navigationItem__topLevel" id="projectRelatedNavigation"
            [ngClass]="{ 
                hideInDom: (!checkIfLabelShouldBeShown(menuItem) && (menuItem.additionalSetting1 == '' || menuItem.additionalSetting1 == null)) ||
                            (userService.getControlPermissionByType(menuItem.uiControlSecurityPermissions, true, 'visible')==false && applicationInfoService.designerMode == false)
            }">
            <div class="topLevelDiv"
                [ngClass]="{
                    subMenuWrapper: (menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null) || menuItem.isFieldActivatable,
                    'hideInDom': menuItem.displayText == '' || menuItem.displayText == null 
                }">
                <div class="topLevelDiv__inner navSectionHeaderLabel" [ngClass]="{
                    subMenu: (menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null),
                    dropDownMenu:  menuItem.isFieldActivatable,
                    fingerCursor: (menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null) || menuItem.isFieldActivatable                    
                }" (click)="loadSubmenu(menuItem)">                
                    <div class="navigationItem__iconContainer" *ngIf="menuItem.additionalSetting2 != null && menuItem.additionalSetting2 != ''">
                        <svg-icon [src]="getImage(menuItem)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [title]="menuItem.displayText | texttransform" class="navigationItem__redesignIcon"></svg-icon>
                    </div>
                    <label *ngIf="checkIfLabelShouldBeShown(menuItem) || (menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null)"
                        class="navigationItem__sectionLabel" [title]="commonService.getTranslationValueFromArray(menuItem, 'displayText') | texttransform"
                        [ngClass]="{
                            everleadCoral: (menuItem.additionalSetting1 == '' || menuItem.additionalSetting1 == null) && !menuItem.isFieldActivatable,
                            fingerCursor: (menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null) || menuItem.isFieldActivatable
                    }">
                        <i class="fas fa-edit designIconMod fingerCursor" style="margin-right: 5px;" *ngIf="applicationInfoService.designerMode == true" (click)="designerService.editUiControl(menuItem);$event.stopPropagation()" title="Element bearbeiten"></i>
                        {{ commonService.getTranslationValueFromArray(menuItem, 'displayText') | texttransform }}
                    </label>    
                    <div *ngIf="menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null" class="playIcon">
                        <i class="fas fa-chevron-circle-right"></i>
                    </div>
                    <div *ngIf="menuItem.isFieldActivatable" class="playIcon">
                        <i class="fas fa-chevron-circle-down " *ngIf="applicationInfoService.userSettings['extendedNavigation' + menuItem.logicalControlId] != true"></i>
                        <i class="fas fa-chevron-circle-up " *ngIf="applicationInfoService.userSettings['extendedNavigation' + menuItem.logicalControlId] == true"></i>
                    </div>
                </div>
            </div>

            <div *ngFor="let child of menuItem.childs" class="navigationItem__subLevel" (click)="navigateTo(child)"
                [ngClass]="{ 
                    navigationItem__isHighlighted: getSelectedItem(child, menuItem),
                    hideInDom: (userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')==false || child.isActive == false) && applicationInfoService.designerMode == false  ||
                    (menuItem.isFieldActivatable && applicationInfoService.userSettings['extendedNavigation' + menuItem.logicalControlId] != true)
                }" [id]="getNavigationId(child)">
                <div *ngIf="showSubControlSpan(child)" class="navigationItem__subLevel__inner">
                    <div class="navigationItem__iconContainer">
                        <svg-icon [src]="getImage(child)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [title]="child.displayText | texttransform" *ngIf="child.additionalSetting2 != null && child.additionalSetting2 != ''"class="navigationItem__redesignIcon"></svg-icon>
                    </div>

                    <label class="navigationItem__subLevel__label fingerCursor" *ngIf="checkIfLabelShouldBeShown()"
                        [ngClass]="{ navigationItem__isInactive: applicationInfoService.designerMode && !child.isActive }" [title]="commonService.getTranslationValueFromArray(child, 'displayText') | texttransform">
                        <i class="fas fa-edit designIconMod fingerCursor" style="margin-right: 5px;" *ngIf="applicationInfoService.designerMode == true" (click)="designerService.editUiControl(child);$event.stopPropagation()" title="Element bearbeiten"></i>
                        {{ commonService.getTranslationValueFromArray(child, 'displayText') | texttransform }}
                    </label>
                </div>
            </div>
        </div>

        <div class="mobile-visible">
            <div class="topLevelDiv">
                <div class="topLevelDiv__inner">
                    <label class="navigationItem__sectionLabel">
                        LOGIN
                    </label>
                </div>
            </div>

            <div id="generalNavigation">
                <div class="navigationItem__topLevel">
                    <label *ngIf="checkIfLabelShouldBeShown()" [ngClass]="{ hideInDom: !checkIfHovered() }">{{
                        'General.Menu.Administration' | texttransform }} Test</label>
                    <div class="navigationItem__subLevel" (click)="showUserSettings()">
                        <div class="navigationItem__subLevel__inner">
                            <div class="navigationItem__iconContainer">
                                <svg-icon src="assets/images/svg/settings.svg"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    class="navigationItem__redesignIcon"></svg-icon>
                            </div>
                            <label class="navigationItem__subLevel__label" *ngIf="checkIfLabelShouldBeShown()">{{
                                'General.Menu.Settings' | texttransform }}</label>
                        </div>
                    </div>
                    <div class="navigationItem__subLevel" (click)="logout()">
                        <div class="navigationItem__subLevel__inner">
                            <div class="navigationItem__iconContainer">
                                <svg-icon src="assets/images/svg/logout.svg"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    class="navigationItem__redesignIcon"></svg-icon>
                            </div>
                            <label class="navigationItem__subLevel__label" *ngIf="checkIfLabelShouldBeShown()">{{
                                'General.Menu.Logout' | texttransform }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>