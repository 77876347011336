import { Injectable } from '@angular/core';
import { UiService } from './ui.service';
import { VariableType, ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ControlService {

  constructor(
    private uiService: UiService,
    private applicationInfoService: ApplicationInfoService,
    private userService: UserService,
    private commonService: CommonService
  ) { }

  checkIfControlIsVisible(controlDefinition: any): boolean {
    let isVisible = this.uiService.checkAttributeChanges('visible', controlDefinition, true, VariableType.Boolean);
    isVisible = this.uiService.checkAttributeModifierGroups('visible', controlDefinition, isVisible);
    if (controlDefinition.uiControlSecurityPermissions.length > 0) {
      isVisible = this.userService.getControlPermission('', controlDefinition.uiControlSecurityPermissions, isVisible, 'visible');
    }
    if (controlDefinition.isActive === false) {
      isVisible = false;
    }

    // Mobile or desktop disabling
    if (!this.uiService.checkViewModeVisibility(controlDefinition)) {
      isVisible = false;
    }

    if (!this.commonService.isNullOrUndefined(controlDefinition.minXResolution)) {
      if (this.applicationInfoService.innerWidth < controlDefinition.minXResolution) {
        isVisible = false;
      }
    }
    if (!this.commonService.isNullOrUndefined(controlDefinition.maxXResolution)) {
      if (this.applicationInfoService.innerWidth > controlDefinition.maxXResolution) {
        isVisible = false;
      }
    }

    if (!this.commonService.isNullOrUndefined(controlDefinition.minYResolution)) {
      if (this.applicationInfoService.innerHeight < controlDefinition.minYResolution) {
        isVisible = false;
      }
    }
    if (!this.commonService.isNullOrUndefined(controlDefinition.maxYResolution)) {
      if (this.applicationInfoService.innerHeight > controlDefinition.maxYResolution) {
        isVisible = false;
      }
    }

    if (controlDefinition.isDeveloperOnly) {
      if (this.applicationInfoService.isDeveloper) {
        isVisible = true;
      } else {
        isVisible = false;
      }      
    }
    
    // if (this.applicationInfoService.designerMode) { isVisible = true; }

    // ControlSettings
    if (this.uiService.getControlSettingOrDefault(controlDefinition, 'invisible', false) == true) {
      isVisible = false;
    }
    if (this.uiService.getControlSettingOrDefault(controlDefinition, 'onlyVisibleIfContactIsAvailable', false) == true) {
      if (this.applicationInfoService.currentContact == null) {
        isVisible = false;
      }
    }
    if (this.uiService.getControlSettingOrDefault(controlDefinition, 'onlyVisibleIfAccountIsAvailable', false) == true) {
      if (this.applicationInfoService.currentAccount == null) {
        isVisible = false;
      }
    }
    
    return isVisible;
  }

  getSubControlInteractionControlWidth(controlMainId, substractValue = 0) {
    let returnValue = this.uiService.getDomControlWidth(controlMainId,
      Number(this.uiService.getControlDimensionWithoutAttribute(this.applicationInfoService.controlInteractionIconWidth)));
    const borderWidth = this.uiService.getBorderWidthOfElement(controlMainId);
    returnValue = returnValue - (2 * borderWidth) - substractValue;
    return returnValue + 'px';        
  }
}
