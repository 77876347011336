import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-control-design-externaldatasource',
  templateUrl: './control-design-externaldatasource.component.html',
  styleUrls: ['./control-design-externaldatasource.component.scss']
})
export class ControlDesignExternaldatasourceComponent implements OnInit {
  @Input() externalDataSource = null;
  @Input() parent = null;

  constructor(
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  deleteUiControlExternalDataSource(externalDataSource) {
    this.externaldatasourceService.executeExternalDataSource(334, [externalDataSource.id])
    .then(() => {
      this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UiControl/0').subscribe(() => {
        this.eventService.refreshControlInfo.emit(this.parent);
      });
    });
  }
}
