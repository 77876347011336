import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SettingsService } from 'app/shared/service/settings.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  updateMenuBarSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  searchActive = false;
  menuItems = [];
  superVisorItems = [];
  agentItems = [];
  agentMode = true;
  openTabs = [];
  applicationSettings = null;
  lastJSONMenu = null;

  // New navigation
  navigationTable = null;
  navigationLevel0 = [];
  navigationLevel1 = [];
  navigationLevel2 = [];

  constructor(
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    public designerService: DesignerService,
    private uiService: UiService,
    private methodService: MethodService,
    public axivasTranslateService: AxivasTranslateService,
    public userService: UserService,
    private router: Router,
    public commonService: CommonService,
    private awsCognitoService: AwsCognitoService,
    public settingService: SettingsService,
    private projectService: ProjectService,
    public packagefeatureService: PackagefeatureService
  ) { }

  ngOnDestroy(): void {
    if (this.updateMenuBarSubscription) { this.updateMenuBarSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.updateMenuBarSubscription = this.eventService.updateManuBarEvent.subscribe(event => {
      if (event.target === 'mainmenu') {
        this.getControls(event.arguments[0]);
      }
    });
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'editNavigationJson') {
        this.consumeNewLayout(event.value);
      }
      if (event.id == 'loadJsonNavigation') {
        this.loadJsonLayout(event.value);
      }
      if (event.id == 'loadJsonNavigationAfterRoleChange') {
        this.getNavigationLayout('navigationLayout' + this.applicationInfoService.currentUserRoleId);
      }
    })
    this.getNavigationLayout('navigationLayout' + this.applicationInfoService.currentUserRoleId);
    // this.getSubmenuControls(159936);
  }

  getNavigationLayout(jsonNavigationItem) {
    this.projectService.getApplicationSettings().then(applicationSettings => {
      this.applicationSettings = applicationSettings;
      if (this.applicationSettings != null) {
        const applicationSetting = this.applicationSettings.find(setting => setting.setting == jsonNavigationItem)
        if (applicationSetting) {
          this.loadJsonLayout(jsonNavigationItem);
        }
        if (this.navigationTable == null)  {
          this.getControls(1000);
        }
      }
    });
  }

  loadJsonLayout(navigationName = null) {
    if (navigationName == 'navigationLayout') {
      navigationName = navigationName.concat(this.applicationInfoService.currentUserRoleId);
    }
    if (this.commonService.isNullOrUndefined(navigationName)) {
      navigationName = this.lastJSONMenu;
    } else {
      this.lastJSONMenu = navigationName;
    }
    const applicationSetting = this.applicationSettings.find(setting => setting.setting == navigationName)
    //console.log('application setting', applicationSetting);
    this.navigationTable = JSON.parse(applicationSetting?.value);
    this.consumeNewLayout(this.navigationTable);
  }

  consumeNewLayout(layout) {
    this.menuItems = [];
    let lastLevel0 = null;
    this.navigationLevel0 = [];
    this.navigationLevel1 = [];
    layout.fields.forEach(field => {
      if (field.isChild == true) {
        if (this.commonService.isNullOrUndefined(this.navigationLevel1[lastLevel0])) {
          this.navigationLevel1[lastLevel0] = [];
        }
        this.navigationLevel1[lastLevel0].push(field);
      } else {
        lastLevel0 = field.guid;
        this.navigationLevel0.push(field);
      }
    });    
    const listDiv = document.getElementById('jsonMenuList');
    if (listDiv) { listDiv.scrollTop = 0; }
  }

  loadSubmenu(menuItem) {
    if (menuItem.additionalSetting1 != '' && menuItem.additionalSetting1 != null) {
      this.getSubmenuControls(menuItem.additionalSetting1);
    }
    if (menuItem.isFieldActivatable) {
      if (this.applicationInfoService.userSettings['extendedNavigation' + menuItem.logicalControlId] == true) {
        this.applicationInfoService.userSettings['extendedNavigation' + menuItem.logicalControlId] = false;
      } else {
        this.applicationInfoService.userSettings['extendedNavigation' + menuItem.logicalControlId] = true;
      }
      this.settingService.updateSecurityUserSettings();
    }
  }

  getSubmenuControls(argument) {
    this.uiService.getControls(argument)
    .then(result => {
      result.forEach(child => {
        child.childs.sort((a, b) => a.order < b.order ? -1 : 1);
      });
      this.superVisorItems = result;
      this.setNavigationMode(false)
    }).catch(error => {
      if (this.applicationInfoService.isDeveloper) {
        console.log(error)
      }
    });
  }

  getControls(argument) {
    this.uiService.getControls(argument).then(result => {
      result.forEach(child => {
        child.childs.sort((a, b) => a.order < b.order ? -1 : 1);
      });
      this.agentItems = result;
      this.menuItems = result;
    }).catch(error => {
      if (this.applicationInfoService.isDeveloper) {
        console.log(error)
      }
    });
  }

  setNavigationMode(agentMode = true) {
    this.agentMode = agentMode;
    if (agentMode) {
      this.menuItems = this.agentItems;
    } else {
      this.menuItems = this.superVisorItems;
    }
  }

  showProjectSelection() {
    this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80');
    this.hideNavigation();
  }

  hideNavigation(){
    let navToggleButton = document.querySelector("#navToggleButton");
    navToggleButton.classList.remove("show");
    if (this.applicationInfoService.isMobile()) {
      this.applicationInfoService.navigationExpandedMobile = false;
    }
  }

  navigateTo(child) {
    this.methodService.callMethod('onclick', child, null);
    this.hideNavigation();
    this.commonService.hideBoldBiDropdowns();
  }

  getNavigationHeight() {
    return this.uiService.getDesignSpanPosition('navigationDesignSpan', 0);
  }


  getSelectedItem(child, menuItem) {
    let returnValue = false;
    if (child.value !== null && child.value !== '') {
      let currentLocation = window.location.href.toString();
      const currentLocationDetails = currentLocation.split('?');
      currentLocation = currentLocationDetails[0];

      if (this.commonService.checkIfStringContainsString(window.location.href.toString(), child.value.concat('/')) ||
        currentLocation.endsWith(child.value) ||
        localStorage.getItem('containerViewId') == child.value
      ) {
        returnValue = true;
        this.applicationInfoService.miscSettings['navigatedSection'] = child.value;
        if (menuItem.nameTranslationTokenId) {
          this.applicationInfoService.breadCrumbTextSection = this.axivasTranslateService.getTranslationTextForId(menuItem.nameTranslationTokenId);
        } else {
          this.applicationInfoService.breadCrumbTextSection = this.axivasTranslateService.getTranslationTextForToken(menuItem.displayText);
        }
        if (child.nameTranslationTokenId) {
          this.applicationInfoService.breadCrumbText = this.axivasTranslateService.getTranslationTextForId(child.nameTranslationTokenId);
        } else {
          this.applicationInfoService.breadCrumbText = this.axivasTranslateService.getTranslationTextForToken(child.displayText);
        }
      }
    }
    return returnValue;
  }

  showProjectSettings() {
    if (this.applicationInfoService.blockSettingChange == true) {
      return;
    }
    this.applicationInfoService.miscSettings['showProjectSettings'] = true;
    this.eventService.showJjPopup('StaticForm.ProjectSelection.ProjectSettings', 'projectselection', '50');
  }

  showProspects() {
    this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/prospects']);
    this.hideNavigation();
  }

  showConversions() {
    this.router.navigate(['../' + this.applicationInfoService.mainFrameName + '/convertions']);
    this.hideNavigation();
  }

  getImage(child) {
    return 'assets/images/svg/'.concat(child.additionalSetting2);
  }

  checkIfLabelShouldBeShown(menuItem = null) {
    let returnValue = false;
    if (this.applicationInfoService.isMobile()) {
      returnValue = true;
    } else {
      if (this.applicationInfoService.navigationExpanded) {
        returnValue = true;
      } else {
        returnValue = false;
      }
    }

    if (menuItem) {
      let counter = 0;
      menuItem.childs.forEach(child => {
        if (child.isActive && this.userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')) {
          counter ++;
        }
      });
      if (counter === 0) {
        returnValue = false;
      }
    }
    if (this.applicationInfoService.designerMode) {
      returnValue = true;
    }
    return returnValue;
  }

  checkIfHovered() {
    let returnValue = false;
    const box = document.getElementById('navigationDesignSpan');
    if (box) {
      const width = box.offsetWidth;
      if (width > 50) {
        returnValue = true;
      }
    }
    return false;
  }

  changeView() {
    if (this.applicationInfoService.isDeveloper) {
      this.applicationInfoService.useNewDesign = !this.applicationInfoService.useNewDesign;
    }
  }


  getUserFollowupGroup(): string {
    let returnvalue = '';
    this.applicationInfoService?.availableUserGroups.forEach(group => {
      if (group.id === this.applicationInfoService.currentUserGroup) {
        if (group.nameTranslationToken) { returnvalue = group.nameTranslationToken.tokenFullName; }
      }
    })
    return returnvalue;
  }

  getUserRole(): string {
    let returnvalue = '';
    this.userService?.userRoles.forEach(role => {
      if (role.id === this.applicationInfoService.currentUserRoleId) {
        returnvalue = role.nameTranslationToken.tokenName
      }
    })
    return returnvalue;
  }

  showUserSettings() {
    this.applicationInfoService.routerHeaderLabelText = 'General.Menu.Settings';
    this.router.navigate(['../usersettings']);
    this.hideNavigation();
  }

  showSubControlSpan(child) {
    let returnValue = false;
    if (this.userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')==true && child.isActive) {
      returnValue = true
    }
    if (this.applicationInfoService.designerMode) {
      returnValue = true
    }
    return returnValue;
    // (userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')==true && child.isActive) || applicationInfoService.isDesignMode
  }

  logout() {
    this.awsCognitoService.logoutUserFromCognito();
  }

  togglePin() {
    this.applicationInfoService.navigationExpandedFixed = false;
    this.applicationInfoService.navigationPinned = !this.applicationInfoService.navigationPinned;
  }

  toggleExtendedPin() {
    this.applicationInfoService.navigationPinned = false;
    this.applicationInfoService.navigationExpandedFixed = !this.applicationInfoService.navigationExpandedFixed;
  }

  handleQuickSearchKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.searchForValue();
    }
  }

  searchForValue() {
    if (this.applicationInfoService.quickSearchValue === '' || this.searchActive) {
      return;
    }
    // console.warn('searchForValue', this.applicationInfoService.quickSearchValue);
    this.searchActive = true;
    this.eventService.showJjPopup(this.axivasTranslateService
      .getTranslationTextForToken('General._.QuickSearch'), 'quicksearch', '80', true);
    setTimeout(() => this.searchActive = false, 1000);
  }

  getNavigationId(child) {
    return 'nav' + child.logicalControlId;
  }

  // JSON Menu
  designLayout() {
    this.eventService.showJjPopup('Navigation', 'navigationdesign', '90');
  }

  showJsonMenuItem(menuItem) {
    let returnValue = true;
    // Blocked
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedUserIds, this.applicationInfoService.userID) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedPoolIds, this.applicationInfoService.poolId) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedProjectIds, this.applicationInfoService.projectID) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedCampaignIds, this.applicationInfoService.campaingnModeId) == false) { returnValue = false; }

    // Required
    if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredProjectIds, this.applicationInfoService.projectID) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredPoolIds, this.applicationInfoService.poolId) == false) { returnValue = false; }
    if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredCampaignIds, this.applicationInfoService.campaingnModeId) == false) { returnValue = false; }

    // Rights
    if (!this.commonService.isNullOrUndefined(menuItem.requiredRights) && menuItem.requiredRights != '') {
      const details = menuItem.requiredRights.split(';');
      details.forEach(detail => {
        if (!this.userService.hasPermission(detail)) {
          returnValue = false;
        }
      });
    }
    return returnValue;
  }

  expandAll() {
    this.eventService.customEvent.emit({ id: 'toggleAllNavigations' });
    setTimeout(() => {
      this.settingService.updateSecurityUserSettings();
    }, 1000);
  }
}
