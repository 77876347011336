import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { Location } from '@angular/common';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-supervisor-main',
  templateUrl: './supervisor-main.component.html',
  styleUrls: ['./supervisor-main.component.scss']
})
export class SupervisorMainComponent implements OnInit, OnDestroy {
  showSettingPageEventSubscription: Subscription = new Subscription();
  visibleTabs: any[] = [];
  selectedTabIndex = null;
  selectedSubTabIndex = 0;
  tabControlId = '6746';
  visibleSubTabs = [];
  pageSelected = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private userService: UserService,
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private commonService: CommonService,
    private location: Location,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.showSettingPageEventSubscription = this.eventService.showSettingPageEvent.subscribe(event => {
      this.showSettingPage(event);
    });

    if (this.applicationInfoService.projectSettingPage == null) {
      if (this.commonService.getSessionStorageItem('settingpage') != null) {
        this.showSettingPage(this.commonService.getSessionStorageItem('settingpage'));  
      }
    } else {
      this.showSettingPage(this.applicationInfoService.projectSettingPage);
    }
  }

  ngOnDestroy(): void {
    if (this.showSettingPageEventSubscription) { this.showSettingPageEventSubscription.unsubscribe(); }
  }
  
  showSettingPage(pageNumber) {
    this.commonService.setSessionStorageItem('settingpage', pageNumber);
    this.selectedTabIndex = Number(pageNumber);
    this.applicationInfoService.miscSettings['settingpage'] = null;
    this.pageSelected = true;
    this.getSubTabs(this.selectedTabIndex);
    // this.location.replaceState(''.concat('/supervisor-main', '?settingpage=', pageNumber.toString()));
  }

  getTabs() {
    this.visibleTabs = [];
    this.addProjectSettingMenuItem('CanUpdateProject', { id: 0, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.GeneralSettings') });  
    this.addProjectSettingMenuItem('CanUpdateEmailBlacklist', { id: 1, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Blacklist') });   
    this.addProjectSettingMenuItem('CanReadDataSourceDeliveredRecord', { id: 2, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.DataDelivery') }); 

    //TODO: ADD CONTAINER ID USER
    this.addProjectSettingMenuItem('CanUpdateUserGroupUser', { id: 3, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.User') }); 

    //TODO: ADD CONTAINER ID PROJECT
    this.addProjectSettingMenuItem('CanReadWorkflow', { id: 4, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Project') });

     //TODO: ADD CONTAINER ID ◦Supervisor (Alex & Gross intern)
    this.addProjectSettingMenuItem('CanReadDataSourceDeliveredRecord', { id: 5, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Supervisor') });


    //TODO: ADD CONTAINER ID FollowUp
    // this.addProjectSettingMenuItem('CanReadDataSourceDeliveredRecord', { id: 6, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.FollowUp') });

    //TODO: ADD CONTAINER ID Email
    this.addProjectSettingMenuItem('CanReadEmailTemplate', { id: 7, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Email') });

     //TODO: ADD CONTAINER ID Admin
    if (this.applicationInfoService.isDeveloper) {
      this.addProjectSettingMenuItem('CanReadDataSourceDeliveredRecord', { id: 8, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Admin') });
    }

    this.getSubTabs(this.selectedTabIndex);
  }
  
  getContent() {
    let returnValue = false;
    if (this.visibleTabs.length == 0) {
      this.getTabs();
    } else {
      returnValue = true;
    }
    return returnValue;
  }

  getSubTabs(id) {
    this.visibleSubTabs = [];
    this.selectedSubTabIndex = null;
    switch(id) { 
      case 3:
        this.addProjectSettingMenuItem('CanUpdateUser', { id: 300, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.UserManagement')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanUpdateUserGroupUser', { id: 301, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.UserRoles')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanUpdateFollowUpUserGroup', { id: 302, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.UserGroups')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanUpdateUiAutomaticRecordAssignment', { id: 303, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.AutomaticRecordAssignment')}, this.visibleSubTabs);    
        break;

      case 4:                
        this.addProjectSettingMenuItem('CanUpdateCampaign', { id: 401, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.ManageCampaigns')}, this.visibleSubTabs);    
        this.addProjectSettingMenuItem('CanReadWorkflow', { id: 402, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.ManageWorkflow')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanReadLookupLeadStatus', { id: 403, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.ManageLeadstates')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanReadLookupLeadStatus', { id: 404, displayText: this.axivasTranslateService.getTranslationTextForToken('Audit')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanReadPool', { id: 405, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Pools')}, this.visibleSubTabs);
        // this.addProjectSettingMenuItem('CanCreateImportTemplate', { id: 406, displayText: this.axivasTranslateService.getTranslationTextForToken('Import.Label.ImportData')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanReadPool', { id: 407, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.AJTracker')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanReadEvent', { id: 408, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Events')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanCreateEntityMember', { id: 409, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.EntityMember')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanReadSettingPageCustomerAPI', { id: 410, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.CustomerAPI')}, this.visibleSubTabs);        
        this.addProjectSettingMenuItem('CanReadSettingPageSMTPSettings', { id: 411, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.SMTPSettings')}, this.visibleSubTabs);        
        this.addProjectSettingMenuItem('CanReadSecurity.Module.SettingPageConsent', { id: 412, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Consent')}, this.visibleSubTabs);        
        this.addProjectSettingMenuItem('CanReadDataRightsGroup', { id: 801, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.DataRights')}, this.visibleSubTabs);    
        break;

      case 5:
        this.addProjectSettingMenuItem('CanUpdateLeadreportTemplate', { id: 501, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.LeadReportSettings')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanUpdateProjectMocoProject', { id: 502, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Moco')}, this.visibleSubTabs);    
        break;

      case 7: 
        this.addProjectSettingMenuItem('CanReadEmailTemplate', { id: 701, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.EMailTemplates')}, this.visibleSubTabs);    
        this.addProjectSettingMenuItem('CanReadEmailTemplateContent', { id: 702, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.EMailContent')}, this.visibleSubTabs);        
        break;
      case 8:
        this.addProjectSettingMenuItem('CanUpdateUiStyle', { id: 801, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.DataRights')}, this.visibleSubTabs);    
        this.addProjectSettingMenuItem('CanCreateReport', { id: 806, displayText: this.axivasTranslateService.getTranslationTextForToken('SesConfig')}, this.visibleSubTabs);      
        //this.addProjectSettingMenuItem('CanCreateReport', { id: 802, displayText: this.axivasTranslateService.getTranslationTextForToken('SendMailItems')}, this.visibleSubTabs);      
        this.addProjectSettingMenuItem('CanReadEvent', { id: 408, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.Events')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanCreateReport', { id: 803, displayText: this.axivasTranslateService.getTranslationTextForToken('Projektübersicht')}, this.visibleSubTabs);      
        this.addProjectSettingMenuItem('CanCreateReport', { id: 804, displayText: this.axivasTranslateService.getTranslationTextForToken('SyncSettings')}, this.visibleSubTabs);      
        this.addProjectSettingMenuItem('CanUpdateUiStyle', { id: 805, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.UiStyle')}, this.visibleSubTabs);            
        this.addProjectSettingMenuItem('CanUpdateUiStyle', { id: 807, displayText: this.axivasTranslateService.getTranslationTextForToken('LogicalEntities')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanCreateReport', { id: 802, displayText: this.axivasTranslateService.getTranslationTextForToken('SendMailItems')}, this.visibleSubTabs);
        this.addProjectSettingMenuItem('CanCreateAiPrompt', { id: 820, displayText: this.axivasTranslateService.getTranslationTextForToken('Supervisor.Label.AIPrompts')}, this.visibleSubTabs);
        break;
    }
  }

  setCurrentChild(id) {
    this.selectedTabIndex = Number(id);
    this.selectedSubTabIndex = 0;
    this.getSubTabs(id);
  }

  setCurrentSubChild(id) {
    this.selectedSubTabIndex = Number(id);
  }

  addProjectSettingMenuItem(requiredRight: any, item: any, tabs = this.visibleTabs) {
    if (!isNullOrUndefined(requiredRight)) {
      if (this.userService.hasPermission(requiredRight)) {
        tabs.push(item);
        if (this.selectedTabIndex == null) { this.selectedTabIndex = item.id; }
        if (this.selectedSubTabIndex == null) { this.selectedSubTabIndex = item.id; }
      }
    } else {
      tabs.push(item);
    }
  }

  getContentHeight(container, value) {
    return Number(this.uiService.getDesignSpanPosition(container, value));
  }
}
