import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ControlType, UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-control-design-admin',
  templateUrl: './control-design-admin.component.html',
  styleUrls: ['./control-design-admin.component.scss']
})
export class ControlDesignAdminComponent implements OnInit {
  private controlTypes = ControlType;
  public controlTypeOptions = [];
  externalDatasources = [];
  externalDatasourcesList = [];
  selectedExternalDataSource = null;
  entityList = [];
  visibilityModes = [];
  @Input() controlToModify = null;

  constructor(
    private commonService: CommonService,
    public designerService: DesignerService,
    private uiService: UiService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.controlTypeOptions = this.commonService.enumToArray(this.controlTypes);
    const cleanTypeOptions = JSON.parse(JSON.stringify(this.controlTypeOptions));
    cleanTypeOptions.forEach(option => {
      if (!isNaN(Number(option))) {
        this.commonService.removeItemFromArray(this.controlTypeOptions, option);
      }
    });
    this.controlTypeOptions.sort((a, b) => a.name < b.name ? -1 : 1)
    this.getVisibilityModes();
    this.applicationInfoService.entities.GetItemArray().forEach(entityItem => {
      if (!this.commonService.isNullOrUndefined(entityItem)) {
        this.entityList.push(this.applicationInfoService.entities.Item(entityItem.toString()));
      }
    });
    this.entityList.sort((a, b) => a.uiEntityInstance < b.uiEntityInstance ? -1 : 1)
  }

  getVisibilityModes() {
    this.externaldatasourceService.executeExternalDataSource(387, [67])
    .then(result => {
      this.visibilityModes = result;
    });
  }

  getExternalDataSources() {
    if (!this.commonService.isNullOrUndefined(this.controlToModify.id)) {
      this.externaldatasourceService.executeExternalDataSource(356, [this.controlToModify.id])
      .then(result => {
        this.externalDatasources = result;
      });
    }
  }

  deleteControl(child) {
    this.designerService.deleteControl(child).then(() => {
      this.commonService.removeItemFromArray(this.controlToModify.childs, child);
    });
  }

  toNumber(value) {
    return Number(value);
  }



  callbackEvent(event) {
    this.controlToModify[event.member] = event.value;
    this.designerService.saveControl(this.controlToModify);
  }

  removeTranslation() {
    this.controlToModify.nameTranslationToken = null;
    this.controlToModify.nameTranslationTokenId = null;
    this.designerService.saveControl(this.controlToModify);
  }
}
