import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss']
})
export class WizardStepComponent implements OnInit {
  stepTypes = [];
  results = [];
  newResultBatch = '';
  newResultName = '';

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService    
  ) { }

  ngOnInit(): void {
    this.getWorkflowStepTypes();
  }

  checkStep() {
    this.wizardService.nextDisabled = false;
    if (this.wizardService.wizardPage == 1 && this.wizardService.wizardArray.defaultName == '') {
      this.wizardService.nextDisabled = true;
    }
    if (this.wizardService.wizardPage == 2 && this.wizardService.wizardArray.typeLookupId == null) {
      this.wizardService.nextDisabled = true;
    }    
    return false;
  }

  getWorkflowStepTypes() {
    this.stepTypes = [];
    this.externaldatasourceService.executeExternalDataSource(387, [55])
    .then(getWorkflowStepResultTypesResult => {
      this.stepTypes = getWorkflowStepResultTypesResult;
    })
    .catch(error => {
      console.error('getWorkflowStepTypes', error);
    });
  }

  createResult(newResultName) {
    this.wizardService.createResult(this.wizardService.wizardArray, newResultName)
    .then(createResultResult => {
      this.newResultName = '';
      this.wizardService.wizardArray.results.push(createResultResult)
    });
  }

  createResults(newResultBatch) {
    const batchArray = newResultBatch.split(';');
    batchArray.forEach(batchArrayItem => {
      this.createResult(batchArrayItem);
    });
  }
}
