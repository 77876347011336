import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-datafilter-groupitem',
  templateUrl: './datafilter-groupitem.component.html',
  styleUrls: ['./datafilter-groupitem.component.scss']
})
export class DatafilterGroupitemComponent implements OnInit {
  @Input() filterSegment = null;
  @Input() filter = null;
  @Input() memberEntities: [];
  @Input() filterSegmentOperators = [];
  @Input() operators = [];
  @Input() disabled = false;
  @Input() emailBatches = [];
  
  constructor(
    private eventService: EventService,
    private datafilterService: DatafilterService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit() {
    
  }


  deleteFilterSegment() {
    this.datafilterService.deleteFilterSegment(this.filter.id, this.filterSegment.id)
    .then(() => {
      this.eventService.updateDataFilterSegmentsEvent.emit(new JJEvent(this.filter.id, []));
    })
    .catch(error => { console.error(error); });
  }

  createSegmentMember() {
    let lookupOperatorId = 18;
    if (
      this.applicationInfoService.entities.Item(this.filter.mainEntityId).entityMembers[0].memberType == 'NUMERIC' ||
      this.applicationInfoService.entities.Item(this.filter.mainEntityId).entityMembers[0].memberType == 'BOOLEAN'
    ) {
      lookupOperatorId = 32;
    }
    this.datafilterService.createFilterSegmentMember({
      entityId: this.filter.mainEntityId, 
      memberId: this.applicationInfoService.entities.Item(this.filter.mainEntityId).entityMembers[0].id, 
      isNegation: false, 
      filterSegmentId: this.filterSegment.id,
      filterParameter: null, 
      filterParameter2: null, 
      isRelevantForAllEntries: false, 
      lookupFilterOperatorId: lookupOperatorId
    });
  }

  changeOperator() {
    this.datafilterService.updateDataFilterSegment(this.filterSegment.id, this.filterSegment.lookupSegmentOperatorId, this.filter.id, this.filterSegment.segmentNumber);
  }
}
