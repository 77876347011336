import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { isNullOrUndefined } from 'util';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';

@Component({
  selector: 'app-uicontrol-design-attribute-groups',
  templateUrl: './uicontrol-design-attribute-groups.component.html',
  styleUrls: ['./uicontrol-design-attribute-groups.component.scss']
})
export class UicontrolDesignAttributeGroupsComponent implements OnInit {
  @Input() currentControlToModify = null;
  attributeModifierSources = [];
  attributeModifierSourceMember = [];
  attributeModifierSourceDropdownItems = [];

  constructor(
    public commonService: CommonService,
    private designerService: DesignerService,
    public dragdropService: DragdropService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit() {
    this.attributeModifierSources = this.designerService.getAttributeModifierSources(this.currentControlToModify);
  }

  addAttributeModifierGroup() {
    const attributeModifierGroup = new Object();
    attributeModifierGroup['attribute'] = 'visible';
    attributeModifierGroup['newValue'] = 'false';
    attributeModifierGroup['controlId'] =  this.currentControlToModify.id;
    attributeModifierGroup['connection'] = 'and';
    attributeModifierGroup['controlAttributeModifiers'] = [];
    this.currentControlToModify.controlAttributeModifierGroups.push(attributeModifierGroup);
    this.addAttributeModifier(attributeModifierGroup);
  }

  addAttributeModifier(group) {
    const attributeModifier = new Object();
    attributeModifier['compareType'] = '=';
    group.controlAttributeModifiers.push(attributeModifier);
  }

  removeAttributeModifier(array, item) {
    this.commonService.removeItemFromArray(array, item);
    if (isNullOrUndefined(this.currentControlToModify.removedAttributeModifier)) {
      this.currentControlToModify.removedAttributeModifier = [];
    }
    if (!isNullOrUndefined(item.id)) {
      this.currentControlToModify.removedAttributeModifier.push(item.id);
    }
  }

  removeAttributeModifierGroup(attributeModifierGroups, attributeModifierGroup) {
    this.commonService.removeItemFromArray(attributeModifierGroups, attributeModifierGroup);
    if (isNullOrUndefined(this.currentControlToModify.removedAttributeModifierGroups)) {
      this.currentControlToModify.removedAttributeModifierGroups = [];
    }
    if (!isNullOrUndefined(attributeModifierGroup.id)) {
      this.currentControlToModify.removedAttributeModifierGroups.push(attributeModifierGroup.id);
    }
  }

  getSelectedAttributeModifierSource(attributeModifier) {
    let returnValue = null;
    this.attributeModifierSources.forEach(source => {
      if (source.id === attributeModifier.compareValue) {
        this.attributeModifierSourceMember[attributeModifier.compareValue] = source.member;
        returnValue = source.member;
        this.getLookupTableData(attributeModifier, source.member);
      }
    });
    return returnValue;
  }

  compareValueChanged(attributeModifier) {
    this.getSelectedAttributeModifierSource(attributeModifier);
    const member = this.attributeModifierSourceMember[attributeModifier.compareValue];
    this.getLookupTableData(attributeModifier, member);
    attributeModifier.refValue = null;
  }

  getLookupTableData(attributeModifier, member) {
    if (!isNullOrUndefined(this.attributeModifierSourceMember[attributeModifier.compareValue].lookupTableId)) {
      if (isNullOrUndefined(this.attributeModifierSourceDropdownItems[attributeModifier.compareValue])) {
        this.externaldatasourceService.executeLookUpTable({
          lookupTableSource: member.lookupTableSource,
          lookupTableId: member.lookupTableId
        })
        .then(getDataLookupTableDefinitionsResult => {
          getDataLookupTableDefinitionsResult.forEach(item => {
            item.id = item.id.toString();
          });
          this.attributeModifierSourceDropdownItems[attributeModifier.compareValue] = getDataLookupTableDefinitionsResult;
        });
      }
    }
  }
}
