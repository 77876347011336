import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { SessionStorageService } from 'ngx-webstorage';
import { timer } from 'rxjs';

@Component({
  selector: 'app-mailaccept',
  templateUrl: './mailaccept.component.html',
  styleUrls: ['./mailaccept.component.scss']
})
export class MailacceptComponent implements OnInit {
  action = 0;
  rid = null;
  aid = null;
  pid = 0;
  date = null;
  reportingDeclineText = '';
  requestAnswerText = '';
  jobStopped = false;
  jobAccepted = false;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private sessionStorageService: SessionStorageService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
    this.action =  this.activatedRoute.snapshot.queryParams['action'];
    this.aid =  this.activatedRoute.snapshot.queryParams['aid'];
    this.rid =  this.activatedRoute.snapshot.queryParams['rid'];
    this.pid =  this.activatedRoute.snapshot.queryParams['pid'];
    this.date =  this.activatedRoute.snapshot.queryParams['d'];

    console.warn('redirect values',
      this.activatedRoute.snapshot.queryParams['action'],
      this.activatedRoute.snapshot.queryParams['aid'],
      this.activatedRoute.snapshot.queryParams['rid'],
      this.activatedRoute.snapshot.queryParams['pid'],
      this.activatedRoute.snapshot.queryParams['d'],
    );

    // ?action=1&aid=12985&rid=8&d=2021-10-29
    if (this.sessionStorageService.retrieve('mailAcceptValuesCreated') !== 'true') {
      this.commonService.setSessionStorageItem('mailAcceptValuesCreated', 'true');
      this.commonService.setSessionStorageItem('action', this.action);
      this.commonService.setSessionStorageItem('aid', this.aid);
      this.commonService.setSessionStorageItem('rid', this.rid);
      this.commonService.setSessionStorageItem('pid', this.pid);
      this.commonService.setSessionStorageItem('date', this.date);
    } else {
      this.action =  this.sessionStorageService.retrieve('action');
      this.aid =  this.sessionStorageService.retrieve('aid');
      this.rid =  this.sessionStorageService.retrieve('rid');
      this.pid =  this.sessionStorageService.retrieve('pid');
      this.date =  this.sessionStorageService.retrieve('date');
      this.sessionStorageService.clear('mailAcceptValuesCreated');
    }
    if (this.action == 1) {
      this.applicationInfoService.breadCrumbText = 'Reporting bestätigen / Accept reporting';
      setTimeout(() => { this.acceptJob(); }, 5000);      
    } else {
      this.applicationInfoService.breadCrumbText = 'Reporting stoppen / Stop reporting';
    }    
    this.applicationInfoService.noProject = true;
  }


  stopJob() {
    this.applicationInfoService.projectID = this.pid;
    this.externaldatasourceService.executeExternalDataSource(440, [
      this.rid,
      this.aid,
      this.reportingDeclineText
    ])
    .then(() => {
      this.jobStopped = true;
      this.requestAnswerText = 'Job stopped';
    })
    .catch(stopJobError => {
      this.jobStopped = true;
      this.requestAnswerText = 'Job stopp failed';
      console.error('StopJob', stopJobError);
    })
    .finally(() => { this.clearStorage(); });
  }

  acceptJob() {
    console.warn('acceptJob');
    this.applicationInfoService.showLoadProjectSpinner = false;
    this.applicationInfoService.projectID = this.pid;
    this.applicationInfoService.applicationInitialized = true;
    this.externaldatasourceService.executeExternalDataSource(441, [this.rid, this.aid, this.reportingDeclineText])
    .then(() => { 
      this.jobAccepted = true; 
      this.requestAnswerText = 'Job approved';
    })
    .catch(stopJobError => {
      this.jobAccepted = true; 
      this.requestAnswerText = 'Job approve failed';
      console.error('accecptJob', stopJobError) 
    })
    .finally(() => { this.clearStorage(); });
  }

  clearStorage() {
    this.sessionStorageService.clear('mailAcceptValuesCreated');
    this.sessionStorageService.clear('rid');
    this.sessionStorageService.clear('action');
    this.sessionStorageService.clear('aid');
    this.sessionStorageService.clear('date');
  }
}
