<div class="websiteDashboard__wrapper websiteDashboardNonMobile">
    <div class="websiteDashboard__leads">
        <div class="websiteDashboard__general websiteDashboard__kpis">
            <app-dashbdashboardcomponent-kpis class="maxHeight"></app-dashbdashboardcomponent-kpis>
        </div>
        <div class="websiteDashboard__general websiteDashboard__myvisitors">
            <app-dashbdashboardcomponent-myvisitors class="maxHeight"></app-dashbdashboardcomponent-myvisitors>
        </div>
    </div>



    <div class="websiteDashboard__myday">
        <app-dashbdashboardcomponent-myday class="maxHeight"></app-dashbdashboardcomponent-myday>
    </div>
</div>

<div class="websiteDashboard__wrapper websiteDashboardMobile">    
    <app-dashbdashboardcomponent-kpis></app-dashbdashboardcomponent-kpis>
    <div class="websiteDashboardMobile__myDay">
        <app-dashbdashboardcomponent-myday></app-dashbdashboardcomponent-myday>
    </div>    
    <div class="websiteDashboardMobile__myVisitors">
        <app-dashbdashboardcomponent-myvisitors></app-dashbdashboardcomponent-myvisitors>
    </div>    
</div>