import { Injectable } from '@angular/core';
import { SettingsService } from 'app/shared/service/settings.service';
import { EventService } from './event.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { MessagingService } from './messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from './common.service';

export enum VipLookup {
  Account = 8060,
  AccountUrl = 4107,
  Contact = 6640,
  Prospect = 4107
}

@Injectable({
  providedIn: 'root'
})
export class VipService {

  constructor(
    private externalDataSourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    private settingsService: SettingsService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private commonService: CommonService
  ) { }

  addItemToVipList(displayName, value, typeLookupId): Promise<any> {
    return new Promise((addItemToVipListResolve, addItemToVipListReject) => {
      this.loaderService.display(true);
      if (this.commonService.isNullOrUndefined(displayName)) {
        displayName = '';
      }
      this.externalDataSourceService.executeExternalDataSource(564, [value, typeLookupId, displayName])
      .then(addProspectToVipListResult => {
        this.messagingService.showDefaultSuccess('Vip.VIPAddedMessage.Header', 'Vip.VIPAddedMessage.Message')
        if (this.settingsService.userSettings['VipAlertFrequency'].value == 'none') {
          this.eventService.showBannerEvent.emit('showVipNotActivated');
        }
        this.loaderService.display(false);
        addItemToVipListResolve(addProspectToVipListResult);
      })
      .catch(error => {
        this.loaderService.display(false);
        addItemToVipListReject(error);
      });
    });    
  }

  removeItemFromVipList(vipId): Promise<any> {
    return new Promise((addItemToVipListResolve, addItemToVipListReject) => {
      this.loaderService.display(true);
      this.externalDataSourceService.executeExternalDataSource(540, [vipId])
      .then(() => { addItemToVipListResolve(null); })
      .catch(error => {
        this.loaderService.display(false);
        addItemToVipListReject(error);
      });
    });    
  }

  // addProspectToVipList(prospect): Promise<any> {
  //   return new Promise((addProspectToVipListResolve, addProspectToVipListReject) => {
  //     this.loaderService.display(true);
  //     this.externalDataSourceService.executeExternalDataSource(564, [prospect.url, 4107, prospect.companyName])
  //     .then(addProspectToVipListResult => {
  //       this.messagingService.showDefaultSuccess('Vip.VIPAddedMessage.Header', 'Vip.VIPAddedMessage.Message')
  //       if (this.settingsService.userSettings['VipAlertFrequency'].value == 'none') {
  //         this.eventService.showBannerEvent.emit('showVipNotActivated');
  //       }
  //       this.loaderService.display(false);
  //       addProspectToVipListResolve(addProspectToVipListResult);
  //     })
  //     .catch(error => {
  //       this.loaderService.display(false);
  //       addProspectToVipListReject(error);
  //     });
  //   });    
  // }

  addContactToVipList(contactId): Promise<any> {
    return new Promise((addContactToVipListResolve, addContactToVipListReject) => {
      this.loaderService.display(true);
      this.externalDataSourceService.executeExternalDataSource(564, [contactId, 6640, ''])
      .then(addProspectToVipListResult => {
        this.messagingService.showDefaultSuccess('Vip.VIPAddedMessage.Header', 'Vip.VIPAddedMessage.Message')
        if (this.settingsService.userSettings['VipAlertFrequency'].value == 'none') {
          this.eventService.showBannerEvent.emit('showVipNotActivated');
        }
        this.loaderService.display(false);
        addContactToVipListResolve(addProspectToVipListResult);
      })
      .catch(error => {
        this.loaderService.display(false);
        addContactToVipListReject(error);
      });
    });    
  }

  checkIfProspectIsVip(prospect): Promise<any> {    
    return new Promise((checkIfProspectIsVipResolve, checkIfProspectIsVipReject) => {
      this.externalDataSourceService.executeExternalDataSource(537)
      .then(checkIfProspectIsVipResult => {        
        let returnValue = false;
        const vip = checkIfProspectIsVipResult.find(e => e.value === prospect.url);
        if (vip) {
          returnValue = true;
        }
        checkIfProspectIsVipResolve(returnValue);
      })
      .catch(error => {
        console.error(error);
        checkIfProspectIsVipReject(error);
      });    
    });
  }

  checkIfContactIsVip(contactId): Promise<any> {    
    return new Promise((checkIfContactIsVipResolve, checkIfContactIsVipReject) => {
      this.externalDataSourceService.executeExternalDataSource(537)
      .then(checkIfContactIsVipResult => {        
        let returnValue = false;        
        const vip = checkIfContactIsVipResult.find(e => e.value == contactId.toString());
        if (vip) {
          returnValue = true;
        }
        checkIfContactIsVipResolve(returnValue);
      })
      .catch(error => {
        console.error(error);
        checkIfContactIsVipReject(error);
      });    
    });
  }


  deleteContactFromList(contactId): Promise<any> {
    return new Promise((deleteContactFromListResolve, deleteContactFromListeject) => {
      this.loaderService.display(true);
      this.externalDataSourceService.executeExternalDataSource(537)
      .then(checkIfProspectIsVipResult => {   
        console.warn(checkIfProspectIsVipResult, contactId);

        const vip = checkIfProspectIsVipResult.find(e => e.value == contactId.toString());
        if (vip) {
          this.externalDataSourceService.executeExternalDataSource(540, [vip.id])
          .then(() => {
            this.messagingService.showDefaultSuccess('Vip.VIPRemovedMessage.Header', 'Vip.VIPRemovedMessage.Message')
            deleteContactFromListResolve(vip);
          })
          .catch(error => {
            console.error(error);
            deleteContactFromListeject(error);
          });
        } else {
          deleteContactFromListResolve(vip);
        }        
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
        deleteContactFromListeject(error);
      });   
    });
  }

  deleteProspectFromList(prospect): Promise<any> {
    return new Promise((deleteProspectFromListResolve, deleteProspectFromListReject) => {
      this.loaderService.display(true);
      this.externalDataSourceService.executeExternalDataSource(537)
      .then(checkIfProspectIsVipResult => {        
        const vip = checkIfProspectIsVipResult.find(e => e.value === prospect.url);
        if (vip) {
          this.externalDataSourceService.executeExternalDataSource(540, [vip.id])
          .then(() => {
            this.messagingService.showDefaultSuccess('Vip.VIPRemovedMessage.Header', 'Vip.VIPRemovedMessage.Message')
            deleteProspectFromListResolve(vip);
          })
          .catch(error => {
            console.error(error);
            deleteProspectFromListReject(error);
          });
        } else {
          deleteProspectFromListResolve(vip);
        }        
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
        deleteProspectFromListReject(error);
      });   
    });
  }
}
