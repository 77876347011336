<div><b>{{ 'MyLeads.Label.NewLogicalEntityHeader' | texttransform }}</b></div>
<br>
<label class="newLogicalEntityLabel">{{ 'MyLeads.Label.NewLogicalEntityName' | texttransform }}</label>
<input [(ngModel)]="newLogicalEntityName" class="newLogicalEntityInput">
<br>
<br>
<button [disabled]="newLogicalEntityName.length < 4" class="jjButtonColor jjButtonStyle"
(click)="createNewLogicalEntity()"
>
    {{ 'MyLeads.Label.NewLogicalEntityCreate' | texttransform }}
</button>
