<div *ngIf="userCanSeeDesigner()" class="containerDesigner">
    <div class="containerDesigner__name">
        <label class="ellipsisText">{{ 'General.Label.EditContainer' | texttransform }}</label>
    </div>
    <div class="containerDesigner__edit">
        <i class="fas fa-edit fingerCursor" (click)="editContainer()"></i>
    </div>
</div>

<ng-container
    *ngIf="getUiContent === false && userService.getControlPermissionByType(controlDefinition?.uiControlSecurityPermissions, true, 'visible')==true">
    <div id="jjContainer" [ngClass]="{
                'hideInDom': !checkVisibility() && !applicationInfoService.containerDesignerMode,
                'inline': controlDefinition?.inline > 0, 
                'containerDesigner__wrapper': userCanSeeDesigner()
                }" [class]="GetContainerCSS()" [ngStyle]="containerStyle()" (scroll)="onScroll($event)"
>
        <div [innerHTML]="innerHTML" *ngIf="minimizedView == false && !controlDefinition" [ngClass]="{
                    'containerDesigner__content':  applicationInfoService.containerDesignerMode }">
        </div>

        <div *ngIf="controlDefinition" class="containerContent__wrapper">
            <ng-container *ngFor="let containerControl of containerControls" class="containerContent">
                <app-custom-universal [controlid]=containerControl?.uiControlLogicalId
                    *ngIf=" applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType != null &&
                                        applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType != 0"></app-custom-universal>
                <app-custom-container [controluiid]=containerControl?.uiControlLogicalId
                    *ngIf=" applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType == null ||
                                        applicationInfoService.controls.Item(containerControl.uiControlLogicalId)?.controlType == 0">
                </app-custom-container>
            </ng-container>
        </div>
    </div>
</ng-container>