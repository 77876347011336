import { Component, OnInit, Input } from '@angular/core';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-datafilter-groupitem-member',
  templateUrl: './datafilter-groupitem-member.component.html',
  styleUrls: ['./datafilter-groupitem-member.component.scss']
})
export class DatafilterGroupitemMemberComponent implements OnInit {
  @Input() filter = null;
  @Input() filterSegmentMember = null;
  @Input() filterSegment = null;
  @Input() memberEntities = [];
  @Input() disabled = false;
  @Input() operators = [];
  @Input() emailBatches = [];
  lookupTableValues = [];
  mailshotFakeMember = { id: 5173, defaultName: 'Mailshot - Status', lookupTableId: null, memberType: 'MAILSHOT' };
  mailShotStates = [
    { id: '0', displayName: 'Sent' },
    { id: '1', displayName: 'Delivered' },
    { id: '2', displayName: 'Bounced' },
    { id: '3', displayName: 'Deleted' },
  ];
  members = [];

  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private datafilterService: DatafilterService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    let member = this.getMember(this.filterSegmentMember.memberId);
    if (member.lookupTableId != null) {
      this.getLookupValues(member);
    }
  }

  saveSegmemtMember() {
    this.datafilterService.saveFilterSegmentMember(this.filterSegmentMember)
  }

  getMember(memberId) {
    let member = this.applicationInfoService.entityMember.Item(memberId);
    if (this.commonService.isNullOrUndefined(member)) {
      if (memberId == this.mailshotFakeMember.id) {
        member = this.mailshotFakeMember;
      }
    } 
    return member;
  }

  changeEntity() {
    this.filterSegmentMember.memberId = this.applicationInfoService.entities.Item(this.filterSegmentMember.entityId).entityMembers[0].id;
    this.saveSegmemtMember();
  }

  deleteMember() {
    this.datafilterService.deleteFilterSegmentMember(this.filterSegment, this.filterSegmentMember);
  }

  getMembers() {
    if (this.filterSegmentMember.entityId == null || this.filterSegmentMember.entityId == undefined) {
      return [];
    } else {
      if (!this.commonService.isNullOrUndefined(this.members[this.filterSegmentMember.entityId])) {
        return this.members[this.filterSegmentMember.entityId];
      }
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.filterSegmentMember.entityId))) {
        let memberList = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.filterSegmentMember.entityId).entityMembers));
        if (this.filterSegmentMember.entityId == 4) {
          memberList.push(this.mailshotFakeMember);
        }
        this.commonService.sortArrayWithTranslationToken(memberList);
        this.members[this.filterSegmentMember.entityId] = memberList;
        return memberList;
      } else {
        return [];
      }      
    }
  }

  changeOperator() {
    let member = this.getMember(this.filterSegmentMember.memberId);
    this.filterSegmentMember.filterParameter = null;
    this.filterSegmentMember.filterParameter2 = null;
    if (member) {
      if (member.memberType == 'BOOL') {
        this.filterSegmentMember.filterParameter = 'true';
      }
    }
    this.saveSegmemtMember();
  }
  
  getLookupTableContent(filterSegmentMember) {
    let member = this.getMember(this.filterSegmentMember.memberId);
    return this.lookupTableValues[member.lookupTableSource + member.lookupTableId];
  }

  getLookupValues(member) {
    let lookupTableSource = 1;
    if (member.lookupTableSource == 'main') {
      lookupTableSource = 2;
    }
    if (this.commonService.isNullOrUndefined(this.lookupTableValues[member.lookupTableSource + member.lookupTableId])) {
      this.externaldatasourceService.getLookupTableValues(member.lookupTableId, lookupTableSource)
      .then(getLookupValuesResult => {
        this.commonService.sortArrayWithTranslationToken(getLookupValuesResult);
        this.lookupTableValues[member.lookupTableSource + member.lookupTableId] = getLookupValuesResult;
      });  
    }
  }

  invalidOperatorForMember(filterSegmentMember, operator) {
    let returnValue = false;
    let member = this.getMember(filterSegmentMember.memberId);
    switch(operator.lookupTable.id) {
      case 18: // Text like
        if (member.lookupTableId != null || member.memberType == 'NUMERIC' || member.memberType == 'BOOL' || member.memberType == 'DATETIME') {
          returnValue = true;
        }
        if (member.id == this.mailshotFakeMember.id) { returnValue = true; }
        break;
      case 21: // date between
        if (member.lookupTableId != null || member.memberType != 'DATETIME') {
          returnValue = true;
        }
        if (member.id == this.mailshotFakeMember.id) { returnValue = true; }
        break;  
      case 22: // number csv
        returnValue = true;
        break;
      case 23: // number between
        if (member.lookupTableId != null || member.memberType == 'BOOL' || member.memberType == 'TEXT' || member.memberType == 'DATETIME') {
          returnValue = true;
        }
        if (member.id == this.mailshotFakeMember.id) { returnValue = true; }
        break;
      case 32: // equal
        if (member.id == this.mailshotFakeMember.id) { returnValue = true; }
        break;
      case 105: // not equal
        if (member.id == this.mailshotFakeMember.id) { returnValue = true; }
        break;
      case 6013: // mailshotstate
        if (member.id != this.mailshotFakeMember.id) { returnValue = true; }
        break;
    }
    // console.warn(operator.lookupTable.id, member.memberType, member.defaultName, returnValue)
    return returnValue;
  }

  memberChanged(filterSegmentMember) {
    const member = this.applicationInfoService.entityMember.Item(filterSegmentMember.memberId);
    if (member.lookupTableId != null) {
      this.getLookupValues(member);
    }
    filterSegmentMember.lookupFilterOperatorId = null;
    filterSegmentMember.filterParameter = '';
    filterSegmentMember.filterParameter2 = '';
    // this.saveSegmemtMember();
  }

  toString(value) {
    return value.toString();
  }

  changeBoolValue(filterSegmentMember, filterParameter) {
    if (filterSegmentMember[filterParameter] == 'true') {
      filterSegmentMember[filterParameter] = 'false';
    } else {
      filterSegmentMember[filterParameter] = 'true';
    }
    this.saveSegmemtMember();
  }

  callClickElement(controlId) {
    const control = document.getElementById(controlId.concat(this.filterSegmentMember.id));
    if (control) {
      control.click();
    }
  }

  getMyId(baseText) {
    return baseText.concat(this.filterSegmentMember.id);
  }
}

