<div class="adminJobsWrapper">
    <div class="jjTab adminJobsTabs" *ngIf="!applicationInfoService?.isMobile()">
        <button [ngClass]="{ 
                jjActivetab: child.id == selectedTabIndex,
                jjControlDefaultColor: child.id == selectedTabIndex,
                jjInactivetab: child.id != selectedTabIndex
            }" *ngFor="let child of visibleTabs; let currentIndex = index" class="tablinks"
            (click)="setCurrentChild(child.id)">
            {{ child.displayText | texttransform }}
        </button>
    </div>

    <div *ngIf="selectedTabIndex == 1" class="adminJobsIndex1">
        <app-admin-jobs-schedule></app-admin-jobs-schedule>
    </div>

    <div *ngIf="selectedTabIndex == 0" class="adminJobsIndex0">
        <div *ngIf="mode == 1" class="adminJobsJobs">
            <div class="projectSettings__header adminJobsHeader">
                <div class="projectSettings__header__newItem">                    
                    <button (click)="createJob()" class="jjControlSubmenuButton" style="white-space: normal;"><i
                        class="fas fa-plus-square"></i></button>
                </div>   
                <div class="projectSettings__header__interaction">             
                    <div class="projectSettings__header__showOnlyActive">
                        <input [(ngModel)]="showOnlyActive" type="checkbox">
                        {{ 'CampaignDesign.Label.ShowOnlyActive' | texttransform }}            
                    </div>
                    <div class="projectSettings__header__filter">
                        <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                        <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
                    </div>
                </div>
            </div>

            <div class="jobList">
                <div *ngFor="let job of adminJobsService.jobs" class="everleadShadowBox jobBox" [ngClass]="{ 
                    hideInDom : itemIsFiltered(job.defaultName) || (job.isActive == false && showOnlyActive == true),
                    adminJobPabelDisabled: !job.isActive
                }" (click)="commonService.toggleItemExpanded(jobsExpanded, job.id);$event.stopPropagation()">
                    <div class="jobBox__header">
                        <input type="checkbox" class="adminJobCheckBox" [(ngModel)]=job.isActive (click)="$event.stopPropagation()" (change)="adminJobsService.updateJob(job)">
                        <label class="adminJobIdLabel everleadCoral">{{ job.id }}</label>
                        <input type="text" [(ngModel)]=job.defaultName class="jjDesignBorder"
                            (click)="$event.stopPropagation()" (change)="adminJobsService.updateJob(job)">
                        <i class="fingerCursor everleadGreenColor fas fa-stethoscope" title="Job testen" (click)="adminJobsService.checkJob(job);$event.stopPropagation()" [ngClass]="{ hideInDom: !job.isActive}"></i>
                        <i class="fingerCursor everleadCoral fas fa-play-circle" title="Job starten" (click)="adminJobsService.launchJob(job.id);$event.stopPropagation()" [ngClass]="{ hideInDom: !job.isActive}"></i>   
                        <i class="fingerCursor fas" [ngClass]="{                
                            'fa-chevron-down': commonService.isItemExpanded(jobsExpanded, job.id) != true,
                            'fa-chevron-up': commonService.isItemExpanded(jobsExpanded, job.id) == true
                        }"></i>            
                    </div>
                    <div *ngIf="commonService.isItemExpanded(jobsExpanded, job.id) && job.isActive == true"
                        class="jobStepItem" cdkDropList [cdkDropListData]="job.jobSteps"
                        (cdkDropListDropped)="dragdropService.moveInList($event, '0')" (click)="$event.stopPropagation()">

                        <!-- JobSteps -->
                        <div class="jobStepsHeaderDiv">
                            <div class="jobStepsHeaderDiv__steps">
                                Steps
                            </div>   
                            <div class="jobStepsHeaderDiv__newStep">
                                <i class="fas fa-plus" style="margin-left: 20px; margin-right: 5px" (click)="addNewStep(job);$event.stopPropagation()"></i>
                                <select [(ngModel)]="newStepType" class="adminJobNewStepCombo" (click)="$event.stopPropagation()">
                                    <option *ngFor="let type of adminJobsService.jobStepTypes" [ngValue]="type">{{ type.defaultName }}</option>
                                </select>
                            </div>                                                     
                        </div>
                        <div [cdkDragData]="step" cdkDrag data-html="true" *ngFor="let step of job.jobSteps"
                            class="jobStepBox"
                            (click)="commonService.toggleItemExpanded(jobStepsExpanded, step.id);$event.stopPropagation()">
                            <div class="jobStepBox__header">
                                <input type="checkbox" [(ngModel)]="step.isActive" class="jjDesignBorder"
                                    (click)="$event.stopPropagation()" (change)="adminJobsService.updateJobStep(step)">                        
                                <label *ngIf="applicationInfoService.isDeveloper" class="everleadCoral jobStepParameterId">{{ step.id }}</label>
                                <input type="text" [(ngModel)]="step.defaultName" class="jjDesignBorder" (click)="$event.stopPropagation()" (change)="adminJobsService.updateJobStep(step)">
                                <select [(ngModel)]="step.stepTypeId" (change)="changeStepType(step)" class="adminJobCombo"
                                    (click)="$event.stopPropagation()" disabled=true>
                                    <option *ngFor="let type of adminJobsService.jobStepTypes" [ngValue]="type.id">{{ type.defaultName }}</option>
                                </select>
                                <!-- <i (click)="removeItem(job, step)" class="everleadCoral fingerCursor fas fa-trash"></i> -->
                                <i class="fas fa-grip-lines " cdkDragHandle></i>
                                <i class=" fingerCursor everleadCoral fas fa-play-circle" title="Job starten"
                                    (click)="adminJobsService.launchJobStep(step.id);$event.stopPropagation()" [ngClass]="{
                                        hideInDom: !job.isActive
                                    }"
                                ></i>
                                <i class=" fingerCursor fas" [ngClass]="{
                                'fa-chevron-down': commonService.isItemExpanded(jobStepsExpanded, step.id) != true,
                                'fa-chevron-up': commonService.isItemExpanded(jobStepsExpanded, step.id) == true
                            }"></i>
                            </div>   
                            <div [ngClass]="{'hideInDom': commonService.isItemExpanded(jobStepsExpanded, step.id) != true }"
                                class="adminJobStep everleadShadowBox" (click)="$event.stopPropagation()">
                                <div class="everleadSmallHeader">
                                    Parameter
                                </div>
                                <!-- Parameter -->
                                <div *ngFor="let stepParameter of step.parameterValues" class="jobStepParameter">
                                    <label *ngIf="applicationInfoService.isDeveloper" class="everleadCoral jobStepParameterId">{{ stepParameter.id }}</label>
                                    <label class="adminJobParameterLabel alignTop">
                                        <i class="far fa-lightbulb" [tippy]="Pflichtfeld" (mousedown)="$event.stopPropagation()"
                                            *ngIf="stepParameter.stepTypeParameter.isMandatory" class="everleadCoral fingerCursor"></i>
                                        {{ stepParameter.stepTypeParameter.parameterName }}
                                    </label>
                                    <div *ngIf="stepParameter.stepTypeParameter.parameterType.defaultName == 'string'">
                                        <label class="adminJobParameterTypeLabel alignTop">string</label>
                                        <textarea [(ngModel)]=stepParameter.value (change)="adminJobsService.updateJobStepParameter(stepParameter)"
                                            class="adminJobParameterTextBox jjDesignBorder"
                                            (mousedown)="$event.stopPropagation()"></textarea>
                                    </div>
                                    <div *ngIf="stepParameter.stepTypeParameter.parameterType.defaultName == 'integer'">
                                        <label class="adminJobParameterTypeLabel alignTop">integer</label>
                                        <input type="text" [(ngModel)]=stepParameter.value (change)="adminJobsService.updateJobStepParameter(stepParameter)"
                                            class="jjDesignBorder">
                                    </div>
                                    <div *ngIf="stepParameter.stepTypeParameter.parameterType.defaultName == 'boolean'">
                                        <label class="adminJobParameterTypeLabel alignTop">boolean</label>
                                        <select class="adminJobBoolDropdown" [(ngModel)]=stepParameter.value (change)="adminJobsService.updateJobStepParameter(stepParameter)">
                                            <option ngValue=false>Nein</option>
                                            <option ngValue=true>Ja</option>
                                        </select>
                                        <!-- <input type="checkbox" [(ngModel)]=stepParameter.value class="jjDesignBorder"> -->
                                    </div>
                                    <div *ngIf="stepParameter.stepTypeParameter.parameterType.defaultName == 'date'">
                                        <label class="adminJobParameterTypeLabel">date</label>                                         
                                        <input [(ngModel)]=stepParameter.value [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly class="adminJobTextBox"
                                            appendTo="body" aria-modal="true" (dateTimeChange)="adminJobsService.updateJobStepParameter(stepParameter)">
                                        <owl-date-time #dt7></owl-date-time>                            
                                    </div>
                                </div>
                                <div class="everleadSmallHeader missingParameter fingerCursor" (click)="addMissingParameter(step)">
                                    Add missing parameter
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
<!-- 
        <div *ngIf="mode == 2">
            <div style="padding-bottom: 10px;" class="controlInlineBlock">
                <div class="jjControlDefaultColor leadStateHeaderDiv">
                    <label style="padding-left: 3px;" class="normalWhiteSpace">Neuer Step</label>
                </div>
                <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
                    <button (click)="addStepToJob(newStepId)" class="jjControlSubmenuButton" style="white-space: normal;"><i
                            class="fas fa-save"></i></button>
                    <button (click)="setMode(1)" class="jjControlSubmenuButton" style="white-space: normal;"><i
                            class="fas fa-window-close"></i></button>
                    <input [(ngModel)]="filterString" style="height: 21px; margin-top: 2px; margin-right: 3px;"
                        class="floatRight">
                    <i class="fas fa-filter floatRight" style="margin-top: 5px; margin-right: 3px;"></i>
                </div>
            </div>
            <label class="adminJobParameterLabel">JobType</label>
            <select [(ngModel)]="newStepId" class="adminJobNewStepCombo" (click)="$event.stopPropagation()">
                <option *ngFor="let type of adminJobsService.jobStepTypes" [ngValue]="type">{{ type.defaultName }}</option>
            </select>

        </div> -->
    </div>
</div>