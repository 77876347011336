import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-qm-questionanswers',
  templateUrl: './qm-questionanswers.component.html',
  styleUrls: ['./qm-questionanswers.component.scss']
})
export class QmQuestionanswersComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  @Input() questions = null;
  @Input() selecteditems = [];
  @Input() disable = false;
  selectedAnswers = [];
  answerTexts = [];
  answers = [];
  checkedAnswers = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'qmResultChanged') {
        this.getSelectedAnswers();
        this.getAnswers();
      }
    });
    console.warn(this.questions, this.selecteditems);
    this.getSelectedAnswers();
    this.getAnswers();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getAnswers() {
    this.answers = [];
    this.questions.forEach(question => {
      question.answers.forEach(answer => {
        this.answers.push(answer);
      }); 
    });
  }

  getSelectedAnswers() {
    this.selectedAnswers = [];
    this.checkedAnswers = []; 
    this.applicationInfoService.miscSettings['qmCheckedAnswers'] = [];   
    this.questions.forEach(question => {
      if (question.isMultiselect) {
        question.answers.forEach(answer => {
          const foundItem = this.selecteditems.find(item => item.answerId == answer.id);
          if (foundItem) {
            this.checkedAnswers.push(answer.id);
            this.applicationInfoService.miscSettings['qmCheckedAnswers'].push(answer.id);
          }            
        });
      } else {
        this.selectedAnswers[question.id] = null;
        question.answers.forEach(answer => {
          const foundItem = this.selecteditems.find(item => item.answerId == answer.id);
          if (foundItem) {
            this.selectedAnswers[question.id] = answer.id;
            if (answer.typeLookupId == 1907) {
              this.answerTexts[answer.id] = foundItem.answerText;
            }
          }
        });
      }      
    });
    this.updateAnswerList();
  }
  
  updateAnswerList() {
    const answers = [];
    Object.keys(this.selectedAnswers).forEach(key => {
      answers.push({ answerId: this.selectedAnswers[key] });
    });
    this.applicationInfoService.miscSettings['qmAnswers'] = answers;
  }

  updateAnswerTextList() {
    this.applicationInfoService.miscSettings['qmAnswersText'] = this.answerTexts;
  }

  checkIfSelectedAnswerIsType(dropDownAnswer, typeLookupId) {
    let returnValue = false;
    const selectedAnswer = this.answers.find(answer => answer.id == dropDownAnswer );
    if (selectedAnswer) {
      if (selectedAnswer.typeLookupId == typeLookupId) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  checkedItemsContainId(answerId) {
    return this.commonService.checkIfItemIsInArray(this.checkedAnswers, answerId);
  }

  toggleCheck(answerId) {
    this.applicationInfoService.miscSettings['qmCheckedAnswers'] = [];    
    if (this.commonService.checkIfItemIsInArray(this.checkedAnswers, answerId)) {
      this.commonService.removeItemFromArray(this.checkedAnswers, answerId);
    } else {
      this.checkedAnswers.push(answerId);
    }
    this.checkedAnswers.forEach(answer => {
      this.applicationInfoService.miscSettings['qmCheckedAnswers'].push(answer);
    });  
    console.warn(this.applicationInfoService.miscSettings['qmCheckedAnswers']);
  }
}
