import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-consentmanagementdashboard',
  templateUrl: './consentmanagementdashboard.component.html',
  styleUrls: ['./consentmanagementdashboard.component.scss']
})
export class ConsentmanagementdashboardComponent implements OnInit {
  featureId = null;
  featureNotFound = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private packagefeatureService: PackagefeatureService
  ) { }

  ngOnInit(): void {
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_consentmanagement']);    
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.featureNotFound = false;
    }    
  }
}
