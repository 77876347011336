<div class="projectSettings__header">
    <div class="projectSettings__header__newItem" (click)="createNewMailshot()" [ngClass]="{ fingerCursor: userService.hasPermission('CanCreateMailshotList') }">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            [tippy]="'General.Label.CreateNewWithDefault' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></svg-icon>               
        {{ 'Mailshots.Label.NewMailshot' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction">
        <!-- <div class="projectSettings__header__showOnlyActive" >
            <i class="fas fa-refresh fingerCursor" (click)="checkEmailBatchQueue()"></i>
        </div> -->
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>

<ng-container *ngIf="loadingData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
</ng-container>

<div *ngIf="loadingData == false" class="maxHeight">
    <div class="mailshotItem mailshotItem__header everleadSmallHeader">
        <div class="mailshotItem__name">
            {{ 'Mailshots.Label.Name' | texttransform }}
        </div>        
        <div class="mailshotItem__info">{{ 'Mailshots.Label.Info' | texttransform }}</div>        
        <div class="mailshotItem__pool">{{ 'Mailshots.Label.Pool' | texttransform }}</div>        
        <div class="mailshotItem__date mailshotItem__date__header">{{ 'Mailshots.Label.Date' | texttransform }}</div>        
        <!-- <div class="mailshotItem__details mailshotItem__details__header">{{ 'Mailshots.Label.Details' | texttransform }}</div>         -->
        <div class="mailshotItem__buttons mailshotItem__buttons__header">{{ 'Mailshots.Label.Options' | texttransform }}</div>
    </div>
    <div class="mailshotListWrapper">
        <div *ngFor="let mailshot of mailshots" class="everleadShadowBox fingerCursor mailshotItemWrapper" [ngClass]="{ hideInDom: 
            itemIsFiltered(mailshot) || mailshotPoolIsCurrentPool(mailshot) == false
        }" (click)="editMailshot(mailshot)">
            <div class="mailshotItem">
                <div class="mailshotItem__name">
                    <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Name' | texttransform }}</span>{{ mailshot.batchName }}                   
                </div> 
                <div class="mailshotItem__info">
                    <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Info' | texttransform }}</span>
                    <div class="mailshotItem__details__item">
                        <i class="fas fa-clock" [tippy]="'Mailshots.Label.CountOpen' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
                        {{ mailshot.countOpen }}
                    </div>
                    <div class="mailshotItem__details__item">
                        <i class="fas fa-paper-plane" [tippy]="'Mailshots.Label.CountSend' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
                        {{ mailshot.countSent }}
                    </div>
                    <div class="mailshotItem__details__item">
                        <svg-icon src="assets/images/svg/NichtZustellbar.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"[tippy]="'Mailshots.Label.CountBounced' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></svg-icon>
                        <!-- <i class="fas fa-times-circle"></i> -->
                        {{ mailshot.countBounced }}
                    </div>
                    <div class="mailshotItem__details__item">
                        <svg-icon src="assets/images/svg/GeloeschtePost.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12 }" [tippy]="'Mailshots.Label.CountCanceled' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></svg-icon>
                        {{ mailshot.countCanceled }}
                    </div>
                </div>        
                <div class="mailshotItem__pool">
                    <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Pool' | texttransform }}</span>
                    {{ getMailShotPool(mailshot) }}
                </div>        
               
                <div class="mailshotItem__date">
                    <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Date' | texttransform }}</span>
                    {{ mailshot.createdAt | date: 'medium' }}
                </div>        
                
                <div class="mailshotItem__buttons" (click)="$event.stopPropagation()">                
                    <button [tippy]="'Mailshots.Label.CloneItem' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" [disabled]="!userService.hasPermission('CanCreateMailshotList')"  class="mailShotButtonClone"
                        (click)="cloneMailshot(mailshot)">
                        <i class="fas fa-clone fingerCursor"></i>
                    </button>
                    <button (click)="showMailshotDetails(mailshot)" class="mailShotButtonDetails">
                        <i class="fas fa-eye fingerCursor"></i>
                    </button>
                    <button (click)="deleteMailshot(mailshot)" [disabled]="!userService.hasPermission('CanDeleteMailshotList') || mailshot.startedAt != null"
                        [ngClass]="{ everleadCoral: userService.hasPermission('CanDeleteMailshotList') && mailshot.startedAt == null}">
                        <i class="fas fa-trash fingerCursor"></i>
                    </button>

                    <div class="mailshotItem__buttons__spacer"></div>

                    <button (click)="stopMailshot(mailshot, 0)" [tippy]="'Mailshots.Label.StopBatch' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" class="mailShotButtonStop"
                        [disabled]="!userService.hasPermission('CanApproveSecurity.Module.MailshotList') || mailshot.startedAt == null">
                        <i class="fas fa-stop fingerCursor"></i>            
                    </button>
                    <button (click)="startMailshot(mailshot)" [tippy]="'Mailshots.Label.StartBatch' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"
                        [disabled]="!userService.hasPermission('CanApproveSecurity.Module.MailshotList') || mailshot.startedAt != null">
                        <i class="fas fa-play fingerCursor" ></i>
                    </button>                
                    <i class="fas fa-ban" [tippy]="'Mailshots.Label.MailshotCanceled' | texttransform" 
                        *ngIf="mailshot.startedAt != null && mailshot.stoppedAt != null"
                        (click)="startMailshot(mailshot)"></i>
                    <i class="fas fa-check everleadGreenColor" *ngIf="mailshot.completed == true"></i>
                </div>
            </div>           
        </div>
    </div>
</div>