import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-moco',
  templateUrl: './wizard-moco.component.html',
  styleUrls: ['./wizard-moco.component.scss']
})
export class WizardMocoComponent implements OnInit {
  mocoProjects = [];
  lookupValues = [];
  assignmentTasks = [];
  mocoProjectTasks = [];

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
    this.getLookupValues();
    this.getMocoProjects();    
  }

  getMocoProjects() {
    this.externaldatasourceService.executeExternalDataSource(458, [])
    .then(getMocoProjectsResult => {
      getMocoProjectsResult.sort((a, b) => a.name < b.name ? -1 : 1);
      this.mocoProjects = getMocoProjectsResult.filter(mocoproject => mocoproject.active == true);
    });
  }

  getLookupValues() {
    this.externaldatasourceService.executeExternalDataSource(387, [59])
    .then(getLookupValuesResult => {
      getLookupValuesResult.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
      this.lookupValues = getLookupValuesResult;
      this.wizardService.wizardArray.typeLookupId = getLookupValuesResult[0].id;
    });
  }

  getTasksOfMocoProject(projectId, assignment = null) {
    this.wizardService.wizardArray.mocoProjectTaskId = null;
    this.externaldatasourceService.executeExternalDataSource(459, [projectId])    
    .then(getTasksOfMocoProjectResult => {
      getTasksOfMocoProjectResult.sort((a, b) => a.name < b.name ? -1 : 1);
      if (assignment == null) {
        this.mocoProjectTasks = getTasksOfMocoProjectResult;        
      } else {
        this.assignmentTasks[projectId] = getTasksOfMocoProjectResult;
      }      
    });    
  }
}
