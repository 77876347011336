// import { ThrowStmt } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-container-view',
  templateUrl: './container-view.component.html',
  styleUrls: ['./container-view.component.scss']
})
export class ContainerViewComponent implements OnInit, OnDestroy {
  changeContainerViewContentSubscription: Subscription = new Subscription();
  containerId = null;
  innerHTMLContent = ` <jj-container controluiid="<0>"> </jj-container>`;
  innerHTML = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private eventService: EventService,
    private location: Location,
    private trackingService: TrackingService
  ) { }

  ngOnDestroy() {
    if (this.changeContainerViewContentSubscription) { this.changeContainerViewContentSubscription.unsubscribe(); }
  }

  ngOnInit(): void {
    this.trackingService.trackPageView();
    this.containerId = localStorage.getItem('containerViewId');
    // this.containerId = this.applicationInfoService.miscSettings['pagecontentcontainerid'];
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent.replace('<0>', this.containerId));
    this.updateUrl();

    this.changeContainerViewContentSubscription = this.eventService.changeContainerViewContent.subscribe(() => {
      this.containerId = localStorage.getItem('containerViewId');
      // this.containerId = this.applicationInfoService.miscSettings['pagecontentcontainerid'];
      this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent.replace('<0>', this.containerId));
      this.updateUrl();
    });
  }

  updateUrl() {
    if (this.containerId) {
      // this.location.replaceState(''.concat('/containerview', '?id=', this.containerId.toString()));
    }
  }
}
