<div id="headerSpan"
  class="jjTopHeaderBackground topLevelHeaderFlex-container topLevelHeaderItemHeight topLevelHeaderHideStuff">
  <div class="topLevelHeaderFlex-container-start">
    <div>
      <img *ngIf="applicationInfoService.projectInfo?.projectLogo == null" src="assets/images/aug_white.png"
        alt="Axivas" title="Axivas" class="topLevelHeaderItemHeight fingerCursor" (click)="onClickAxivasLogo()">
      <img *ngIf="applicationInfoService.projectInfo?.projectLogo != null"
        [src]="applicationInfoService.projectInfo?.projectLogo" class="topLevelHeaderItemHeight fingerCursor"
        (click)="onClickAxivasLogo()">
    </div>

    <div *ngIf="applicationInfoService.isMobile() == false"
      class="topLevelHeaderApplicationNameLabel jjTopHeaderFont centerMeVertically" (click)="showDebug()">
      <label class="jjToplevelHeaderLabel topLevelHeaderLabel" [title]="applicationInfoService.projectName + ' ' + applicationInfoService.versionNumber"
        *ngIf="('general.PortalName' | texttransform) != 'JollyJupiter'">&nbsp;{{ 'Everlead' | texttransform }}</label>
      <label class="jjToplevelHeaderLabel topLevelHeaderLabel" [title]="applicationInfoService.projectName + ' ' + applicationInfoService.versionNumber"
        *ngIf="('general.PortalName' | texttransform) == 'JollyJupiter'">&nbsp;{{ Everlead }}</label>
    </div>
  </div>

  <div *ngIf="applicationInfoService.isMobile()==false && applicationInfoService.projectSelected"
    class="topLevelHeaderApplicationNameLabel jjTopHeaderFont topLevelHeaderCenterContent">
    <label (click)="showProjectSelection()"
      class="fingerCursor topLevelHeaderLabel">{{ applicationInfoService.projectID }} -
      {{ applicationInfoService.projectName | texttransform }}</label>
    <i class="fas fa-external-link-alt seperaterIcon fingerCursor fa-sm" *ngIf="applicationInfoService.isDeveloper"
      (click)="showProjectSelection()" style="padding-left: 5px;"></i>
    <span *ngIf="applicationInfoService.projectInfo?.pools?.length > 0">
      / {{ 'TopLevelHeader.Label.Pool' | texttransform }}
      <select [(ngModel)]="applicationInfoService.poolId" class="topLevelHeaderPoolSelectionControl jjTopHeaderFont">
        <option [ngValue]="pool.id" *ngFor="let pool of applicationInfoService.projectInfo?.pools"
          class="topLevelHeaderDropDownOption">
          {{ pool.defaultName }}
        </option>
      </select>
    </span>
    <span *ngIf="applicationInfoService.projectInfo?.categories?.length > 0">
      / {{ 'TopLevelHeader.Label.Category' | texttransform }}
      <select [(ngModel)]="applicationInfoService.selectedCategory" class="topLevelHeaderPoolSelectionControl jjTopHeaderFont" style="max-width: 100px;"
        [title]="applicationInfoService.selectedCategory">
        <option value='' class="topLevelHeaderDropDownOption"></option>
        <option [ngValue]="category" *ngFor="let category of applicationInfoService.projectInfo?.categories"
          class="topLevelHeaderDropDownOption topLevelHeaderCategoryDropDownOption" [title]=category>
          {{ category }}
        </option>
      </select>
    </span>
    / {{ 'TopLevelHeader.Label.Role' | texttransform }}
    <select [(ngModel)]="applicationInfoService.currentUserRoleId" class="topLevelHeaderPoolSelectionControl jjTopHeaderFont"
      (change)="userService.getUserPermissions()">
      <option [ngValue]="role.roleId" *ngFor="let role of userService?.userRoles"
        class="topLevelHeaderDropDownOption">
        {{ role.role.nameTranslationToken.tokenFullName }}
      </option>
    </select>

  </div>
  <p-menu #menu [popup]="true" [model]="userDropdownMenuItems" class="timeRecordingInfoLabel"></p-menu>
  <div id="currentUserSpan" class="currentUserControl topLevelHeaderItemHeight fingerCursor">
    <label style="margin: 0px" (click)="menu.toggle($event)" *ngIf="applicationInfoService.user.fakeUser == null"
      class="fingerCursor jjTopHeaderFont topLevelHeaderBold floatRight">{{ applicationInfoService.user.userName }}</label>
    <label style="margin: 0px" (click)="menu.toggle($event)" *ngIf="applicationInfoService.user.fakeUser != null"
      class="fingerCursor jjTopHeaderFont topLevelHeaderBold floatRight">{{ applicationInfoService.user?.fakeUser?.userName }}</label>
    <br>

    <select (change)="changeUserGroup()" [(ngModel)]="applicationInfoService.currentUserGroup "
      class="topLevelHeaderPoolSelectionControl floatRight jjTopHeaderFont topLevelHeaderUserGroupDropdown">
      <option *ngFor="let userGroup of applicationInfoService.availableUserGroups"
        class="topLevelHeaderAlignRight topLevelHeaderDropDownOption" [ngValue]="userGroup.id"
      >
        <span *ngIf="!userGroup.nameTranslationToken">{{ userGroup.defaultName }}</span>
        <span *ngIf="userGroup.nameTranslationToken">{{ userGroup.nameTranslationToken.tokenFullName | texttransform }}</span>
      </option>
    </select>
    <label style="margin: 0px" class="topLevelHeaderPoolSelectionControl floatRight jjTopHeaderFont">
      {{ 'AutomaticRecordAssignment.Label.UserGroup' | texttransform }}</label>
  </div>
  <div id="userPicture" class="topLevelHeaderPictureInline" (click)="changeView()">
    <!-- <i class="fas fa-user pictureBox topLevelHeaderItemHeight fa-2x jjTopHeaderFont" style="margin-top: 5px;"></i> -->
    <i class="topLevelHeaderUserIcon topLevelHeaderItemHeight fas fa-user fa-2x fingerCursor" (click)="menu.toggle($event)"></i>
    <!-- <img *ngIf="applicationInfoService.user.fakeUserId == null" class="topLevelHeaderPictureBox topLevelHeaderItemHeight fas fa-user fingerCursor" (click)="menu.toggle($event)"> -->
  </div>
</div>

<!-- Mobile -->
<div *ngIf="applicationInfoService.isMobile() && applicationInfoService.projectSelected"
  class="jjTopHeaderBackground topLevelHeaderItemHeightMobile" style="margin-top: 1px;">
  <div class="topLevelHeaderApplicationNameLabel jjTopHeaderFont fingerCursor" (click)="showProjectSelection()">
    <label class="topLevelHeaderTextOverflow">{{ applicationInfoService.projectID }} -
      {{ applicationInfoService.projectName | texttransform }}
    </label>
    <i class="fas fa-external-link-alt seperaterIcon fa-sm" style="vertical-align: top; padding-top: 5px"
      *ngIf="applicationInfoService.isDeveloper"></i>
  </div>
</div>

<div
  *ngIf="applicationInfoService.isMobile() && applicationInfoService.projectSelected && applicationInfoService.projectInfo?.pools?.length > 0"
  class="jjTopHeaderBackground topLevelHeaderItemHeightMobile" style="margin-top: 1px;">
  <label class="jjTopHeaderFont topLevelHeaderApplicationNameLabel topLevelHeaderCenterContent topLevelHeaderSmallFont"
    style="padding-left: 5px">Pool</label>
  <select [(ngModel)]="applicationInfoService.poolId" class="topLevelHeaderPoolSelectionControl jjTopHeaderFont"
    (change)="setPool(poolId)">
    <option [ngValue]="pool.id" *ngFor="let pool of applicationInfoService.projectInfo?.pools"
      class="topLevelHeaderDropDownOption">
      {{ pool.defaultName }}
    </option>
  </select>
</div>
