<div class="vipUsers">
    <div class="vipUsers__header">
        <label>{{ 'VipUsers.Label.Header' | texttransform }}</label>  
        <button class="everleadCustomButton" (click)="openVipSettings()">{{ 'UserSettings.Label.OpenVipSettings' | texttransform }}</button>          
    </div>
    <div class="vipUsers__description">
        {{ 'VipUsers.Label.Description' | texttransform }}
    </div>    
    <div class="everleadContentContainer" class="vipUsers__content">
        <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
    </div>
</div>
