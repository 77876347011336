<div class="wizardSubheader__wrapper dataImportAssignment__container">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
            texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
            texttransform }}</label>
    </div>
    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1">
            <div *ngIf="uploadingFile==false">
                <input type="file" #file accept=".xlsx" placeholder="Choose file" (change)="uploadFile($event)"
                    style="display:none;">
                <button type="button" class="jjButtonColor jjButtonStyle fingerCursor"
                    (click)="file.click()">{{'Import.Label.UploadFile' | texttransform}}</button>
            </div>

            <div *ngIf="uploadingFile">
                {{'Import.Label.UploadingFilePleaseWait' | texttransform}}
            </div>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="dataImportAssignment__uploadFileFirst" *ngIf="!wizardService.wizardArray.selectedSheet">
                {{'Import.Label.PleadUploadFileFirst' | texttransform}}
            </div>
            <div *ngIf="wizardService.wizardArray.selectedSheet" class="maxHeight">
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{'Import.Label.DataSheet' | texttransform}}</label>
                    <select [(ngModel)]="wizardService.wizardArray.selectedSheet" class="wizardDropDown">
                        <option *ngFor="let sheet of sheets" [ngValue]="sheet">
                            {{ sheet.name }}
                        </option>
                    </select>
                </div>
                <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.selectedSheet">
                    <label class="noPadding wizardLabel">{{'Blacklist.Label.BlacklistInfoField' | texttransform}}</label>
                    <select [(ngModel)]="wizardService.wizardArray.selectedHeader" class="wizardDropDown">
                        <option [ngValue]=null></option>
                        <option *ngFor="let header of wizardService.wizardArray.selectedSheet.data.headers" [ngValue]="header">
                            {{ header.name }}
                        </option>
                    </select>
                </div>
                <div *ngIf="wizardService.wizardArray.selectedHeader">
                    <label class="dataImportAssignment__header__checkList">{{'Import.Label.ExampleData' | texttransform}}</label>
                    <div *ngFor="let dataItem of this.wizardService.wizardArray.selectedSheet.data.data; let i = index" [ngClass]="{ hideInDom: i > 5 }">
                        {{ dataItem[wizardService.wizardArray.selectedHeader.dataOrder] }}
                      </div>
                </div>
                <div class="wizardDetailItem">
                    <label class="noPadding wizardLabel">{{'Blacklist.Label.Type' | texttransform}}</label>
                    <select [(ngModel)]="wizardService.wizardArray.blacklistTypeLookupId" class="wizardDropDown">
                        <option *ngFor="let type of wizardService.wizardArray.types" [ngValue]="type.id">{{ type.defaultName }}</option>
                    </select>
                </div>
                <br>
                <br>
                <button class="jjButtonColor jjButtonStyle fingerCursor" [disabled]="wizardService.wizardArray.blacklistTypeLookupId == null || 
                    wizardService.wizardArray.selectedHeader == null" (click)="importSheet()">
                    {{'DataImport.Label.Page4' | texttransform}}
                  </button>          
            </div>
        </div>
    </div>
</div>