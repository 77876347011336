<div class="dashboardComponentLeadstateWrapper">
    <div class="websiteDashboardHeaderLabel" >{{ 'DashboardComponent.Label.Leadstate' | texttransform }}</div>
    <span *ngIf="loadingData==true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
    <div class="everleadShadowBox dashboardComponentLeadstates" [ngClass]="{ hideInDom: loadingData==true }">
        <div class="dashboardComponentLeadstatesHeader">
            <div>
                {{ 'DashboardComponent.Label.LeadstateFor' | texttransform }}
            </div>
            <select [(ngModel)]="mode" (change)="getDashboardData()">
                <option ngValue="1">{{ 'Contact.Label.Header' | texttransform }}</option>
                <option ngValue="2">{{ 'Account.Label.Header' | texttransform }}</option>
            </select>
        </div>
        <div class="dashboardComponentLeadstatesInner">
         <!--    <span *ngIf="loadingData==true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </span> -->
            <app-echarts
                *ngIf="funnelData.data.length > 0"
                [ngClass]="{ hideInDom: loadingData==true }"
                [chart]="'funnel'"
                [chartData]="funnelData"
                [controlid]="funnelChartId"
                [legend]="false"
                [header]="Test"
                [colors]="colors"
                [legendPosition]="{'bottom': 0}"
                [height]="maxHeight"
                [sort]="'none'"
                [max]="maxValue"
                [orient]="'vertical'"
                [gap]="1"
                [funnelAlign]="'center'"
                [funnelLabelPosition]="{'position': 'center', 'show': true }"  
            ></app-echarts>
        </div>
    </div>
</div>