<div class="dashboardcomponentMyVisitors__wrapper">
    <div class="dashboardcomponentMyVisitors__vertical"></div>
    <div class="dashboardcomponentMyVisitors__header">
        <div class="dashboardcomponentMyVisitors__header__label fingerCursor" (click)="showConversions()">
            {{ 'WebsiteDashboard.Label.MyWebleads' | texttransform }}
        </div>
        <div class="dashboardcomponentMyVisitors__header__select">
            <div class="dashboardcomponentMyVisitors__sources__select__description">{{ 'WebsiteDashboard.Label.IntervallLast' | texttransform }}</div>
            <div class="dashboardcomponentMyVisitors__sources__select__select">
                <select [(ngModel)]="lastXDays" (change)="getKPIs()">
                    <option [ngValue]=3>3</option>
                    <option [ngValue]=15>15</option>
                    <option [ngValue]=30>30</option>
                    <option [ngValue]=60>60</option>
                </select>
            </div>
            <div class="dashboardcomponentMyVisitors__sources__select__description">{{ 'WebsiteDashboard.Label.IntervallDays' | texttransform }}</div>
        </div>
    </div>
    <div class="dashboardcomponentMyVisitors__stats">
        <span *ngIf="loading" class="dashboardcomponentMyVisitors__spinner">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>
        <div *ngIf="kpis != null" class="dashboardcomponentMyVisitors__visitors">


            <div class="dashboardcomponentMyVisitors__visitors__intervall">                
                <div class="dashboardcomponentMyVisitors__visitors__overallitem">
                    <div class="dashboardcomponentMyVisitors__visitors__overallitem__bluetext">
                        {{ 'WebsiteDashboard.Label.Web' | texttransform }}                        
                    </div>
                    <div class="dashboardcomponentMyVisitors__visitors__overallitem__value">
                        {{ kpis.trend }}%
                        <!-- {{ kpis.activeVisitorCountInterval }} -->
                    </div>
                </div>
                <div class="dashboardcomponentMyVisitors__visitors__overallitem">
                    <div class="dashboardcomponentMyVisitors__visitors__overallitem__detailtext">
                        <div class="ellipsisText">{{ 'WebsiteDashboard.Label.IntervallConversions' | texttransform }}</div>
                        <div class="dashboardcomponentMyVisitors__visitors__overallitem__detailtext__count">{{ kpis.conversionCountInterval }}</div>
                    </div>
                    <div class="dashboardcomponentMyVisitors__visitors__overallitem__trendicon">
                        <svg-icon src="assets/images/svg/dashboard_arrow_up.svg" *ngIf="kpis.trend > 0" [svgStyle]="{ 'width.px': 60, 'height.px': 60 }"></svg-icon>
                        <svg-icon src="assets/images/svg/dashboard_arrow_down.svg" *ngIf="kpis.trend < 0" [svgStyle]="{ 'width.px': 60, 'height.px': 60 }"></svg-icon>
                    </div>
                </div>
            </div>


            <div class="dashboardcomponentMyVisitors__visitors__overall">
                <div class="dashboardcomponentMyVisitors__visitors__overallitem">
                    <div class="dashboardcomponentMyVisitors__visitors__overallitem__text">
                        {{ 'WebsiteDashboard.Label.AllConversions' | texttransform }}
                    </div>
                    <div class="dashboardcomponentMyVisitors__visitors__overallitem__value">
                        {{ kpis.conversionCountAllTime }}
                        <div class="dashboardcomponentMyVisitors__visitors__overallitem__value__icon">
                            <!-- <i class="fas fa-info-circle"></i> -->
                        </div>                        
                    </div>
                </div>
                <div class="dashboardcomponentMyVisitors__visitors__overallitem">
                </div>                
            </div>
        </div>
        <div *ngIf="kpis != null" class="dashboardcomponentMyVisitors__sources">
            <div class="dashboardcomponentMyVisitors__sources__select">
                <div class="dashboardcomponentMyVisitors__sources__select__header">{{ 'WebsiteDashboard.Label.Sources' | texttransform }}</div>
            </div>
            <div class="dashboardcomponentMyVisitors__sources__inner">
                <div *ngFor="let source of kpis.eventSources" class="dashboardcomponentMyVisitors__sources__inner__item">
                    <div class="dashboardcomponentMyVisitors__sources__inner__item__text">
                        {{ source.source | texttransform }}
                    </div>
                    <div class="dashboardcomponentMyVisitors__sources__inner__item__value">
                        <div class="dashboardcomponentMyVisitors__sources__inner__item__value__count" >{{ source.count }}</div>
                        <div class="dashboardcomponentMyVisitors__sources__inner__item__value__percentage"
                            [style.height]="getPercentHeight(source)">                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>