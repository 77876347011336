<div class="toolbar">
    <!-- <div class="toolbar__item fingerCursor" id="toolbarDemoUser" (click)="createDemoUser()"  [tippy]="'Toolbar.Label.CreateDemoUser' | texttransform"
        >  
        <svg-icon src="assets/images/svg/iconset/06icon-kontakte.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div> --> 
    <div class="toolbar__item fingerCursor" id="toolbarAccount" (click)="showAccountData()" [tippy]="getReturnToText() | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRouteIsNotParamValue('account') == true">
        <svg-icon [src]="getReturnToSVG()" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div>    

    <!-- Yammer -->
    <div class="toolbar__item fingerCursor" id="toolbarYammer" (click)="showEVERLEADYammer()" 
        [ngClass]="{ everleadCoral: checkIfThereAreNewsForUser() }"
        [tippy]="'Toolbar.Label.ShowWhatsNew' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.user?.isExternalUser == false">
        <i class="fas faYammer fa-info-circle"></i>
        <div class="yammerNew" *ngIf="checkIfThereAreNewsForUser()">New!</div>
    </div>    

    <!-- Demowebseite -->
    <div class="toolbar__item fingerCursor" id="toolbarDemoWebsiteLink" (click)="showDemoWebsite()" [tippy]="'DemoBanner.Label.Button1Text' | axivastranslate" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
        <svg-icon src="assets/images/svg/toolbar/toolbar_trial_website.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div>    

    <div class="toolbar__item fingerCursor" id="toolbarExport" (click)="accountExport()" [tippy]="'Toolbar.Label.Leadreport' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement:'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
        <svg-icon src="assets/images/svg/toolbar/toolbar_export.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div>    

    <div class="toolbar__item fingerCursor" id="toolbarResearch" (click)="callSection('research', 'toolbarResearch')"  [tippy]="'Toolbar.Label.Research' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
        <svg-icon src="assets/images/svg/toolbar/toolbar_world.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div>

    <!-- <div class="toolbar__item fingerCursor" id="toolbarMail" (click)="callSection('mail', 'toolbarMail')">
        <svg-icon src="assets/images/svg/toolbar/toolbar_email.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div> -->
    
    <div class="toolbar__item fingerCursor" id="toolbarPhone" (click)="callSection('phone', 'toolbarPhone')" [tippy]="'Toolbar.Label.Phonemethods' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}">
        <svg-icon src="assets/images/svg/toolbar/toolbar_telefon.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div>

    <div class="toolbar__item fingerCursor" id="toolbarUnlock" (click)="unlockAccount()" [tippy]="'Toolbar.Label.TooltipUnlock' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
        <svg-icon src="assets/images/svg/toolbar/toolbar_lock.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div>

    <div class="toolbar__item fingerCursor" id="toolbarPlus" (click)="callSection('plus', 'toolbarPlus')" [tippy]="'Toolbar.Label.New' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}">
        <svg-icon src="assets/images/svg/toolbar/toolbar_plus.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div> 




    <div *ngIf="checkIfTippIsAvailable()!=null" class="helpActivator fingerCursor" (click)="showHelpContent()" [tippy]="'Toolbar.Label.ToggleHelp' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement:'bottom'}">
        <i class="fas fa-lg fa-question"></i>
    </div>
    
    <div class="toolbar__item fingerCursor" id="toolbarUserLogo" (click)="callSection('userMenu', 'toolbarUserLogo')">
        <div class="userLogo userLogoNoAvater" *ngIf="applicationInfoService.userAvatar == 'none'">
            <div class="userLogo__newMessages" *ngIf="getNewUserMessages() != 0 && userService.hasPermission('CanReadUserMessage')">
                {{ getNewUserMessages() }}</div>
            {{ getUserNameShortCut(applicationInfoService?.user) }}
        </div>

        <div class="userLogo" *ngIf="applicationInfoService.userAvatar != 'none'">
            <img [src]="applicationInfoService.userAvatar" class="userAvatar">
            <div class="userLogo__newMessages"
                *ngIf="getNewUserMessages() != 0 && userService.hasPermission('CanReadUserMessage')">
                {{ getNewUserMessages() }}</div>
        </div>
    </div>
</div>