<div>
    <button        
        class="everleadCustomButton"
        [disabled]="!checkIfControlIsEnabled()" 
        [ngStyle]="setControlStyle('control')" 
        (click)="buttonClick()"   
        (focus)="callMethod('onfocus')"                         
    >
        <span *ngIf="controlDefinition.icon!=null">
            <i [class]="controlDefinition.icon"></i>
        </span>
        {{ getDisplayText() | texttransform  }}
    </button>
</div>
