import { Component, OnInit, OnDestroy } from '@angular/core';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { ExternalDataSource_QueryType } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessageService } from 'primeng/api';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-optinoptout',
  templateUrl: './optinoptout.component.html',
  styleUrls: ['./optinoptout.component.scss']
})
export class OptinoptoutComponent implements OnInit, OnDestroy {
  optInOptOutWorkflow = null;
  optInOptOutBookingStayInAccount = false;

  optInOptOutInnerHtml = null;
  optInOptOutInnerHtmlBase = '<jj-container controluiid=<0>></jj-container>';
  optInOptOutModifyInnerHtml = null;
  optInOptOutModifyInnerHtmlBase = '<jj-container controluiid=<0>></jj-container>';
  editMode = false;
  optInModifyControl = '6675'; // TODO Diese Werte in settings
  optInListControl = '6652';
  optInEntity = 20;
  optInLookupTypeRefId = 43;
  optOutLookupTypeRefId = 44;
  bookingEnabled = false;

  // Subscriptions
  callInternalMethodEventSubscription: Subscription;

  constructor(
    private graphqlqueryService: GraphqlqueryService,
    private graphQLService: GraphQLService,
    private domSanitizer: DomSanitizer,
    private entityService: EntityService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private methodService: MethodService,
    private applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private commonService: CommonService
  ) {
    this.callInternalMethodEventSubscription = this.eventService.callInternalMethodEvent.subscribe(event => {
      if (event[0] === 'deleteoptinoptout') {
        this.deleteOptInOptOutItem(event[1]);
      }
      if (event[0] === 'modifyoptinoptout') {
        this.modifyOptInOptOutItem(event[1]);
      }
    });
  }

  ngOnDestroy() {
    if (this.callInternalMethodEventSubscription) { this.callInternalMethodEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
      this.loadOptInOptOutContainer();
      this.optInOptOutWorkflow = this.applicationInfoService.optInOptOutWorkflow;
      this.optInOptOutBookingStayInAccount = this.applicationInfoService.optInOptOutBookingStayInAccount;
      this.applicationInfoService.optInOptOutWorkflow = null;
      this.applicationInfoService.optInOptOutBookingStayInAccount = null;
      if (this.optInOptOutWorkflow) {
        this.checkIfRequiredOptInOptOutIsInArray();
      }
  }

  bookTask() {
    this.methodService.finallyBookTask(this.optInOptOutBookingStayInAccount).then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.ClosePopup, []);
    });
  }

  checkIfRequiredOptInOptOutIsInArray() {
    let returnValue = false;
    let optInFound = false;
    let optOutFound = false;
    this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Query,
      this.graphqlqueryService.getQuery('optInOptOutOptInOptOuts'), [])
    .then(optInOptOutOptInOptOutsResult => {
      optInOptOutOptInOptOutsResult.data.optInOptOuts.forEach(optInOptOut => {
        if (Number(optInOptOut.lookupTypeRefId) === Number(this.optInLookupTypeRefId)) {
          optInFound = true;
        }
        if (Number(optInOptOut.lookupTypeRefId) === Number(this.optOutLookupTypeRefId)) {
          optOutFound = true;
        }
        if (this.optInOptOutWorkflow.isConsentRequired && optOutFound) {
          returnValue = true;
        }
      });
      this.bookingEnabled = returnValue;
    })
    .catch(() => { });
  }

  loadOptInOptOutContainer() {
    this.optInOptOutInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(
      this.optInOptOutInnerHtmlBase.replace('<0>', this.optInListControl) // TODO: In Applicationsettings
    );
    this.optInOptOutModifyInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(
      this.optInOptOutModifyInnerHtmlBase.replace('<0>', this.optInModifyControl) // TODO: In Applicationsettings
    );
  }

  updateListView() {
    this.eventService.updateControlContentByControlType(16);
  }

  modifyOptInOptOutItem(optInOptOutItem: any) {
    this.entityService.overwriteEntityData(this.optInEntity.toString(), optInOptOutItem);
    this.editMode = true;
  }

  deleteOptInOptOutItem(optInOptOutItem: any) {
    if (optInOptOutItem) {
      this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, [
        this.axivasTranslateService.getTranslationTextForToken('OptInOptOut.Message.DeleteHeader'),
        this.axivasTranslateService.getTranslationTextForToken('OptInOptOut.Message.DeleteMessage')
      ]).then(() => {
        this.bookingEnabled = false;
        this.loaderService.display(true);
        this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Mutation,
          this.entityService.getExternalDataSourceByType(this.optInEntity, ExternalDataSource_QueryType.Delete).dataQuery,
          [optInOptOutItem.id])
        .then(() => {
          this.checkIfRequiredOptInOptOutIsInArray();
          this.updateListView();
          this.loaderService.display(false);
        })
        .catch(() => { this.loaderService.display(false); });
      })
      .catch(() => {});
    }
  }

  modifyOpt() {
    this.entityService.checkValuesForAllRequiredControls(this.optInEntity, true)
    .then(() => {
      this.bookingEnabled = false;
      this.loaderService.display(true);
      let externaldatasource = null;
      const id = this.entityService.getEntityValue(this.optInEntity, 'id', 1);
      if (id) {
        externaldatasource = this.entityService.getExternalDataSourceByType(this.optInEntity, ExternalDataSource_QueryType.Update);
      } else {
        externaldatasource = this.entityService.getExternalDataSourceByType(this.optInEntity, ExternalDataSource_QueryType.Insert);
      }
      let query = externaldatasource.dataQuery;
      query = query.replace('<memberString>', this.commonService.getModifiedEntityValuesString(this.optInEntity, []));
      this.graphQLService.apolloGQLpromiseWithParameter(externaldatasource.source, ApolloMethod.Mutation, query, [id])
      .then(() => {
        this.updateListView();
        if (this.optInOptOutWorkflow) {
          this.checkIfRequiredOptInOptOutIsInArray();
        }
        this.editMode = false;
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error('modifyOptIn error', error);
        this.loaderService.display(false);
      });
    })
    .catch(() => {});
  }

  createNew() {
    this.editMode = true;
    this.entityService.clearEntityData(this.optInEntity);
  }

  cancelCreation() {
    this.editMode = false;
  }
}
