<div class="leadscoringChart">
<!--     <div *ngIf="!dataAvailable" class="leadscoringChart__noData" [ngClass]="{ 'leadscoring-lineGraph__darkMode': darkmode }">
        {{ 'LeadScoringDetail.Label.NoEventsDataAvailable' | texttransform }}
    </div> -->

    <div class="leadscoringChart__echart" 
        echarts
        [options]="options"
        [merge]="updateOptions"
        [id]="controlid"
        [style.height]="getHeight()"
        [style.width]="getWidth()"
        (chartDataZoom)="onDataZoom($event)"
        (chartInit)="onChartInit($event)">
    </div>

</div>
