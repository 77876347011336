import { Injectable } from '@angular/core';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { FilterDto } from '../dto/filter-dto';
import { ExternaldatasourceService } from './externaldatasource.service';
import { CommonService } from './common.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class DatafilterService {

  constructor(
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private applicationInfoService: ApplicationInfoService,
    private externalDataSourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService
  ) { }

  getDataFilterOperators(): Promise<any> {
    return new Promise<any>((getDataFilterOperatorsResolve, getDataFilterOperatorsReject) => {
      const filterSegmentOperators = [];
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterFilterSegmentOperators, [])
      .then(result => {
        result.data.filterSegmentOperators.forEach(filterSegmentOperator => {
          filterSegmentOperators.push({ defaultName: filterSegmentOperator.defaultName, id: filterSegmentOperator.id });
        });
        getDataFilterOperatorsResolve(filterSegmentOperators);
      })
      .catch(error => getDataFilterOperatorsReject(error));
    });
  }

  getDataFilters() {
    return new Promise<any>((getDataFiltersResolve, getDataFiltersReject) => {
      let dataFilters = [];
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterMainPanelFilterDefinitions,
        [this.applicationInfoService.dataFilterLookupFilterTypeId])
      .then(result => {
        dataFilters = result.data.filterDefinitions;    
        getDataFiltersResolve(dataFilters);
      })
      .catch(error => getDataFiltersReject(error));
    });
  }

  getValidEntities(filterTypeId): any[] {
    const validEntities = [];
    switch(filterTypeId) {
      case 4523:
        validEntities.push(this.applicationInfoService.entities.Item('1'));
        validEntities.push(this.applicationInfoService.entities.Item('4'));
        break;
      default:
        validEntities.push(this.applicationInfoService.entities.Item('1'));
        validEntities.push(this.applicationInfoService.entities.Item('2'));
        validEntities.push(this.applicationInfoService.entities.Item('4'));
        validEntities.push(this.applicationInfoService.entities.Item('462'));
        validEntities.push(this.applicationInfoService.entities.Item('780'));
        break;
    }
    return validEntities;
  }


  getDataFilterInformation(dataFilterId: any): Promise<any> {
    return new Promise<any>((getDataFilterInformationResolve, getDataFilterInformationReject) => {
      this.externalDataSourceService.executeExternalDataSource(596, [dataFilterId])
      .then(result => {
        getDataFilterInformationResolve(result);
      })
      .catch(error => getDataFilterInformationReject(error));
    });
  }

  copyDataFilter(sourceFilterId: any, newFilterName) {
    return new Promise<any>((copyDataFilterResolve, copyDataFilterReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterCreateFilterDuplicateMutation,
        [sourceFilterId, newFilterName])
      .then(copyDataFilterResult => {
        copyDataFilterResolve(copyDataFilterResult);
      })
      .catch(error => copyDataFilterReject(error));
    });
  }

  createNewFilter(newDataFilterEntity, newDataFilterName, newDataFilterOperator, lookupFilterTypeId: any = 11): Promise<any> {
    return new Promise<any>((createNewFilterResolve, createNewFilterReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterCreateFilterMutation,
        [newDataFilterName, newDataFilterOperator, newDataFilterEntity, lookupFilterTypeId])
      .then(createNewFilterResult => {
        createNewFilterResolve(createNewFilterResult);
      })
      .catch(error => createNewFilterReject(error));
    });
  }

  updateDataFilterSegment(segmentId, operatorId, filterId, segmentNumber): Promise<any> {
    return new Promise<void>((updateDataFilterSegmentResolve, updateDataFilterSegmentReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterUpdateFilterSegmentMutation, [segmentId, operatorId, filterId, segmentNumber])
      .then(dataFilterUpdateFilterSegmentMutationResult => {
        updateDataFilterSegmentResolve(dataFilterUpdateFilterSegmentMutationResult);
      })
      .catch(error => updateDataFilterSegmentReject(error));
    });
  }

  getSegmentInformation(segmentId: any): Promise<any> {
    return new Promise<void>((getSegmentInformationResolve, getSegmentInformationReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterFilterSegment, [segmentId])
      .then(result => {
        getSegmentInformationResolve(result);
      })
      .catch(error => getSegmentInformationReject(error));
    });
  }

  getProjectEntities(): Promise<any> {
    return new Promise<void>((getProjectEntitiesResolve, getProjectEntitiesReject) => {
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterSegmentMemberEntities)
      .then(result => {
        getProjectEntitiesResolve(result);
      })
      .catch(error => getProjectEntitiesReject(error));
    });
  }

  getRelatedEntites(mainEntity): Promise<any> {
    return new Promise<void>((getRelatedEntitesResolve, getRelatedEntitesReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.miscEntitySubRelationships, [mainEntity])
      .then(result => {
        getRelatedEntitesResolve(result);
      })
      .catch(error => getRelatedEntitesReject(error));
    });
  }

  getSegmentMemberOperators(): Promise<any> {
    return new Promise<void>((getProjectEntitiesResolve, getProjectEntitiesReject) => {
      this.externalDataSourceService.executeExternalDataSource(601)
      .then(dataFilterFilterSegmentMemberOperatorsResult => {
        getProjectEntitiesResolve(dataFilterFilterSegmentMemberOperatorsResult);
      })
      .catch(error => getProjectEntitiesReject(error));
    });
  }

  deleteFilterSegment(filterId: any, filterSegmentId: any): Promise<any> {
    return new Promise<void>((deleteFilterSegmentResolve, deleteFilterSegmentReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterDeleteFilterSegmentMutation, [filterId, filterSegmentId])
      .then(dataFilterFilterSegmentMemberOperatorsResult => {
        deleteFilterSegmentResolve(dataFilterFilterSegmentMemberOperatorsResult);
      })
      .catch(error => deleteFilterSegmentReject(error));
    });
  }

  deleteFilter(dataFilter: any): Promise<any> {
    return new Promise<void>((deleteFilterResolve, deleteFilterReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterDeleteFilterMutation, [dataFilter.id])
      .then(dataFilterUpdateFilterMutationresult => {
        deleteFilterResolve(dataFilterUpdateFilterMutationresult);
      })
      .catch(error => deleteFilterReject(error));
    });
  }

  addNewFilterSegment(dataFilter: any, newFilter: boolean = false): Promise<any> {
    return new Promise<void>((addNewFilterSegmentResolve, addNewFilterSegmentReject) => {
      let highestSegmentNumber = 0;
      if (!newFilter) {
        dataFilter.filterSegments.forEach(filterSegment => {
          if (filterSegment.segmentNumber > highestSegmentNumber) { highestSegmentNumber = filterSegment.segmentNumber; }
        });
      }

      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterCreateFilterSegmentMutation, [dataFilter.id, highestSegmentNumber + 1])
      .then(dataFilterCreateFilterSegmentMutationResult => {
        addNewFilterSegmentResolve(dataFilterCreateFilterSegmentMutationResult);
      })
      .catch(error => addNewFilterSegmentReject(error));
    });
  }

  getFilterMainEntityAndRelatedEntities(mainEntityId: any): Promise<any> {
    return new Promise<void>((getFilterMainEntityAndRelatedEntitiesResolve, getFilterMainEntityAndRelatedEntitiesReject) => {
    });
  }

  getProjectEntitiyMember(): Promise<any> {
    return new Promise<void>((getProjectEntitiyMemberResolve, getProjectEntitiyMemberReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.dataFilterSegmentMemberEntities, [])
      .then(getProjectEntitiyMemberResult => {
        getProjectEntitiyMemberResolve(getProjectEntitiyMemberResult);
      })
      .catch(error => getProjectEntitiyMemberReject(error));
    });
  }

  setFilterEnvironment(dataFilter: any, baseLogicalEntityIdForFilter: any, dataFilterCaller: any, filterTemplate: any,
      lookupFilterTypeId: any) {
    this.applicationInfoService.baseLogicalEntityIdForFilter = baseLogicalEntityIdForFilter;
    this.applicationInfoService.dataFilterBaseEntity = baseLogicalEntityIdForFilter;
    this.applicationInfoService.dataFilterCaller = dataFilterCaller;
    this.applicationInfoService.dataFilterFilterTemplate = filterTemplate;
    this.applicationInfoService.dataFilterLookupFilterTypeId = lookupFilterTypeId;
  }

  buildJsonDataFilter(filters: FilterDto[]): string {
    let graphqlFilter: string;

    graphqlFilter = '[';

    filters.forEach(filter => {

      if (filter.filterParameter != null && filter.filterParameter.length > 0) {
        graphqlFilter += '{';

        graphqlFilter += 'filterId: ' + filter.filterId;
        graphqlFilter += ', filterSegmentMembers: [ ';

        filter.filterParameter.forEach(param => {
              graphqlFilter += '{';

              graphqlFilter += ' memberId: ' + param.filterSegmentMemberId;

              if (param.filterParameter1 != null ) {
                graphqlFilter += ', filterParameter: "' + param.filterParameter1 + '"';
              }

              if (param.filterParameter2 != null ) {
                graphqlFilter += ', filterParameter2: "' + param.filterParameter2 + '"';
              }

              graphqlFilter += '} ,';
          });
          graphqlFilter = graphqlFilter.substring(0, graphqlFilter.length - 1);
          graphqlFilter += ']}';
        }

        graphqlFilter += ',';
      }
     );

      graphqlFilter = graphqlFilter.substring(0, graphqlFilter.length - 1);
      graphqlFilter += ']';

      return graphqlFilter;
  }

  saveFilterSegmentMember(filterSegmentMember) {
    this.externalDataSourceService.executeExternalDataSource(597, [
      this.commonService.getModifyArrayBody(filterSegmentMember, ['lookupFilterOperator', 'entityMember', 'entity', 'lookupFilterOperator'])
    ]).then(() => { this.eventService.customEvent.emit('myLeadsFilterChanged'); });
  }

  createFilterSegmentMember(filterSegmentMember) {
    this.externalDataSourceService.executeExternalDataSource(598, [
      this.commonService.getModifyArrayBody(filterSegmentMember, [])
    ]).then(() => this.eventService.updateDataFilterSegmentsEvent.emit(null));
  }

  deleteFilterSegmentMember(filterSegment, filterSegmentMember) {
    this.externalDataSourceService.executeExternalDataSource(600, [
      filterSegment.id,
      filterSegmentMember.id
    ]).then(() => this.eventService.updateDataFilterSegmentsEvent.emit(null));
  }
}
