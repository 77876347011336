<div class="adminReportsWrapper">
    <div style="padding-bottom: 10px;" class="adminReportHeader">
        <div class="adminReportHeader__report">
            <label>Report</label>
            <select [(ngModel)]="selectedReport">
                <option *ngFor="let report of reports" [ngValue]=report> ({{ report.id }}) {{ report.defaultName }}</option>
            </select>
        </div>    
        <div class="adminReportHeader__buttons">
            <i class="fas fa-save fingerCursor" *ngIf="selectedReport?.projectId != null" (click)="saveReport()"></i>
            <i class="fas fa-plus-square fingerCursor" (click)="newReport()"></i>
        </div>
        <div class="adminReportHeader__filter">
            <i class="fas fa-filter"></i><input [(ngModel)]="filterString">            
        </div>
    </div>


    <!-- Report -->
    <div *ngIf="selectedReport != null" class="adminReportReportDetails">
        <div class="everleadShadowBox everleadShadowBox2">
            Allgemein
        </div>
        <div class="adminReportGeneral">
            <div class="adminReportGeneral__line">
                <label>Reportname</label>
                <input type="text" [(ngModel)]="selectedReport.defaultName">
                <app-translation-button type='nameTranslationToken' source='selectedReport' [target]=selectedReport></app-translation-button>
                <app-translation-button type='descriptionTranslationToken' source='selectedReport' [target]=selectedReport></app-translation-button>

            </div>
            <div class="adminReportGeneral__line">
                <label>Output-Name Template</label>
                <input type="text" [(ngModel)]="selectedReport.outputNameTemplate">
            </div>
            <div class="adminReportGeneral__line">
                <label>Template-Pfad</label>
                <input type="text" [(ngModel)]="selectedReport.templatePath">
            </div>
            <div class="adminReportGeneral__line">
                <label>fileExtension</label>
                <input type="text" [(ngModel)]="selectedReport.fileExtension">
            </div>
            <div class="adminReportGeneral__line">
                <label>canGeneratePdf</label>
                <input type="checkbox" [(ngModel)]="selectedReport.canGeneratePdf">
            </div>
        </div>
        <div>
            <!-- Queries -->
            <div class="everleadShadowBox everleadShadowBox2" (click)="commonService.toggleItemExpanded(expandedQuery, 1);$event.stopPropagation()">
                <i class="fas fa-plus-square" style="margin-right: 5px; margin-right: 3px"
                    (click)="addNewReporQuery();$event.stopPropagation()"></i>
                <label class="adminReportLabel">Queries</label>
                <i class="floatRight fingerCursor adminReportExpanderIcon fas" [ngClass]="{
                'fa-chevron-down': commonService.isItemExpanded(expandedQuery, 1) != true,
                'fa-chevron-up': commonService.isItemExpanded(expandedQuery, 1) == true
                }"></i>
            </div>

            <div class="adminReportQueryDiv" [ngClass]="{'hideInDom': (commonService.isItemExpanded(expandedQuery, 1) != true)}">
                <div *ngFor="let reportQuery of selectedReport.reportQueries" class="adminReportQueryDetailDiv">
                    <div (click)="commonService.toggleItemExpanded(expandedQueryDetails, reportQuery.id);$event.stopPropagation()" class="adminReportQueryDiv__line">                    
                        <i class="fas fa-edit fingerCursor" title="Query-Editor" (click)="launchQueryBuilder(reportQuery, selectedReport)"></i>
                        <label>{{ reportQuery.id }}</label>
                        <input type="text" [(ngModel)]="reportQuery.query.defaultName" (click)="$event.stopPropagation()">
                        <i class="everleadCoral fingerCursor fas fa-trash" (click)="removeReportQuery(reportQuery);$event.stopPropagation()"></i>
                        <i class="fingerCursor fas" [ngClass]="{
                            'fa-chevron-down': commonService.isItemExpanded(expandedQueryDetails, reportQuery.id) != true,
                            'fa-chevron-up': commonService.isItemExpanded(expandedQueryDetails, reportQuery.id) == true
                        }"></i>                        
                    </div>

                    <div [ngClass]="{'hideInDom': (commonService.isItemExpanded(expandedQueryDetails, reportQuery.id) != true)}"
                        (click)="$event.stopPropagation()" class="everleadShadowBox adminReportQueryDetails">
                        <div style="vertical-align: top;" class="adminReportGeneral__line">
                            <label  style="vertical-align: top;" class="adminReportQueryLabel">Query-Template</label>
                            <textarea [(ngModel)]="reportQuery.query.queryTemplate"
                                class="adminReportQueryData adminReportQueryDataTextArea"></textarea>                            
                        </div>
                        <div class="adminReportGeneral__line">
                            <label class="adminReportQueryLabel">minimumRowsRequired</label>
                            <input type="number" [(ngModel)]="reportQuery.query.minimumRowsRequired"
                                class="adminReportQueryData">
                        </div>
                        <div class="adminReportGeneral__line">
                            <label class="adminReportQueryLabel">externalDataSourceId</label>
                            <input type="number" [(ngModel)]="reportQuery.query.externalDataSourceId"
                                class="adminReportQueryData">
                        </div>
                        <div class="adminReportGeneral__line">
                            <label class="adminReportQueryLabel">resultRowTypeName</label>
                            <input type="text" [(ngModel)]="reportQuery.query.resultRowTypeName"
                                class="adminReportQueryData">
                        </div>
                        <div class="adminReportGeneral__line">
                            <label class="adminReportQueryLabel">dynamicalParamName</label>
                            <input type="text" [(ngModel)]="reportQuery.query.dynamicalParamName"
                                class="adminReportQueryData">
                        </div>
                        <div class="adminReportGeneral__line">
                            <label class="adminReportQueryLabel">Connection</label>
                            <select [(ngModel)]="reportQuery.query.queryConnectionGroupId">
                                <option *ngFor="let connectionGroup of connectionGroups" [ngValue]=connectionGroup.id>{{ connectionGroup.defaultName }}</option>
                            </select>
                        </div>

                        <!-- Transformations -->
                        <hr>
                        <i class="fas fa-plus-square" style="margin-right: 5px; margin-right: 3px; padding-left: 20px;"
                            (click)="addTransformation(reportQuery.query, selectedReport);$event.stopPropagation()"></i>        
                        <label class="adminReportQueryLabel">Query Transformations</label>
                        <div *ngFor="let reportQueryTransformation of reportQuery.query.reportQueryTransformations">
                            <div (click)="commonService.toggleItemExpanded(expandedQueryTransformations, reportQueryTransformation.id);$event.stopPropagation()" 
                                class="adminReportQueryDiv__line">
                                <input type="text" [(ngModel)]="reportQueryTransformation.dataFieldName"
                                (click)="$event.stopPropagation()" (mousedown)="$event.stopPropagation()">
                                <i class="everleadCoral fingerCursor fas fa-trash" 
                                    (click)="removeTransformation(reportQuery.query.reportQueryTransformations, reportQueryTransformation);$event.stopPropagation()" ></i>
                                <i class="fas fa-grip-lines"></i>
                                <i class="fingerCursor fas" [ngClass]="{
                                    'fa-chevron-down': commonService.isItemExpanded(expandedQueryTransformations, reportQueryTransformation.id) != true,
                                    'fa-chevron-up': commonService.isItemExpanded(expandedQueryTransformations, reportQueryTransformation.id) == true
                                }"></i>                
                            </div>
                            <span [ngClass]="{'hideInDom': (commonService.isItemExpanded(expandedQueryTransformations, reportQueryTransformation.id) != true)}">
                                <div>
                                    <label class="adminReportQueryLabel">isStringArrayFromFirstColumn</label>
                                    <input type="checkbox" [(ngModel)]="reportQueryTransformation.isStringArrayFromFirstColumn">
                                </div>
                                <div>
                                    <label class="adminReportQueryLabel">isTopOneResult</label>
                                    <input type="checkbox" [(ngModel)]="reportQueryTransformation.isTopOneResult">
                                </div>
                                <div>
                                    <label class="adminReportQueryLabel">linqQuery</label>
                                    <textarea [(ngModel)]="reportQueryTransformation.linqQuery"
                                    class="adminReportQueryData adminReportQueryDataTextArea"></textarea>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ReportParameters -->
            <div class="adminReportParameterBox">
                <app-admin-report-parameter [selectedReport]="selectedReport"></app-admin-report-parameter>
            </div>            
        </div>
    </div>

    <div *ngIf="mode==2">
        <div>
            <label class="adminReportQueryLabel">Project</label>
            <select [(ngModel)]="newReportProject" class="adminReportQueryDataHeader">
                <option *ngFor="let project of projects" [ngValue]=project.id>
                    {{ project.id }} {{ project.projectName }}
                </option>
            </select>
        </div>
        <div>
            <label class="adminReportQueryLabel">Reportname</label>
            <input type="text" [(ngModel)]="newReportName" class="adminReportQueryData">
        </div>
        <br>
        <br>
        <button class="jjButtonColor jjButtonStyle fingerCursor" [disabled]="newReportName.length < 3 || newReportProject == null"
        (click)="createNewReport()">
            Neuen Report erzeugen
        </button>    
    </div>
</div>