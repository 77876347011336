<div class="leadStateDetailWrapper">
    <div class="leadStateDetailInfo">
        <div class="leadStateDetailHeader">
            {{ contactLeadStatusCampaign.campaignName | texttransform }}
        </div>
        <div class="leadStateDetailBody everleadShadowBox everleadShadowBox2">
            <div class="leadStateDetailBodyLine">
                <div class="leadStateDetailBodyLine__label ">{{ 'General.Label.CurrentLeadstate' | texttransform }}</div>
                <div class="leadStateDetailBodyLine__value ellipsisText">{{ contactLeadStatusCampaign.leadStateName | texttransform }}</div>
            </div>            
            <div class="leadStateDetailBodyLine" *ngIf="target == 'contact'">
                <div class="leadStateDetailBodyLine__label">{{ 'General.Label.LastBooking' | texttransform }}</div>
                <div class="leadStateDetailBodyLine__value ellipsisText">{{ contactLeadStatusCampaign.changedDate | date: 'medium' }}</div>
            </div>            
            <div class="leadStateDetailBodyLine" *ngIf="target == 'contact'">
                <div class="leadStateDetailBodyLine__label">{{ 'General.Label.Objections' | texttransform }}</div>
                <div class="leadStateDetailBodyLine__objections">{{ contactLeadStatusCampaign.objections }}</div>
            </div>  
        </div>    
    </div>
    <div class="leadStateDetailBodyActivityHeader">
        {{ 'General.Label.CampaignActivitiesWithLeadStateChanges' | texttransform }}
    </div>
    <div class="leadStateDetailActivitites">
        <app-custom-timeline [customLeadStateMode]=true [customLeadStateModeTarget]="target" [newMode]=true></app-custom-timeline>
    </div>
</div>
