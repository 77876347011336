import { Component, OnInit } from '@angular/core';
import { DragDropelements, UiService } from 'app/jollyjupiter/service/ui.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { isNullOrUndefined } from 'util';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { Dictionary } from 'app/core/dictionary';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.scss']
})
export class UsergroupsComponent implements OnInit {
  public dragdropElements: DragDropelements[] = [];
  public orginalOffsetForButton: number = null;
  userGroupTypes = [];
  userGroups: any;
  selectedUserGroup = null;
  selectedUserGroupType = null;
  availableUsers = [];
  assignedUsers = [];
  filterString = '';
  filterStringAssigned = '';
  assignmentType = 'user';
  mailConfigSesRefIds = [];
  mailConfigSmtpRefIds = [];
  assignedSesConfigs = [];
  assignedSMTPSettings = [];
  userGroupMailType = 5962;
  newGroupName = '';
  createNewUserGroupMode = false;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public dragdropService: DragdropService,
    private loaderService: LoaderService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private commonService: CommonService,
    public uiService: UiService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.getAvailableUsers();
    this.getSesRefIds();
    this.getSMTPRefIds();
    this.getUserGroupTypes().then(getUserGroupTypesResult => {
      this.selectedUserGroupType = getUserGroupTypesResult[0].id;
      this.getAvailableUserGroups();
    });
  }

  getSesRefIds() {
    this.externaldatasourceService.executeExternalDataSource(135)
    .then(getCampaignsResult => {
      getCampaignsResult.forEach(campaign => {
        if (campaign.isActive == true && campaign.sesConfigSetId != null) {
          this.mailConfigSesRefIds.push(campaign);
        }        
      });
    });
  }

  getAssignedSesConfigs() {
    this.externaldatasourceService.executeExternalDataSource(713, [this.selectedUserGroup.id])
    .then(getAssignedSesConfigsResult => {
      this.assignedSesConfigs = getAssignedSesConfigsResult;
    });
  }

  getAssignedSMTPSettings() {
    this.externaldatasourceService.executeExternalDataSource(712, [this.selectedUserGroup.id])
    .then(getAssignedSMTPSettingsResult => {
      this.assignedSMTPSettings = getAssignedSMTPSettingsResult;
    });
  }

  getSMTPRefIds() {
    this.externaldatasourceService.executeExternalDataSource(659, [])
    .then(getEMailSettingsResult => { 
      this.mailConfigSmtpRefIds = getEMailSettingsResult;
    });
  }

  getUserGroupMember() {
    this.getAssignedUsers();
  }

  
  checkIfAlreadyAssigned(user) {
    return this.assignedUsers.some(userToCheck => userToCheck.user.id === user.id); 
  }

  getUserGroupTypes(): Promise<any> {
    return new Promise((getUserGroupTypesResolve, getUserGroupTypesReject) => {
      this.externaldatasourceService.executeExternalDataSource(151)
      .then(externalDataSourceResult => {
        this.userGroupTypes = externalDataSourceResult;
        getUserGroupTypesResolve(externalDataSourceResult);
      })
      .catch(error => {
        console.error('getUserGroups error', error);
        getUserGroupTypesReject();
      });
    });
  }

  getAvailableUserGroups(): Promise<any> {
    return new Promise((getAvailableUserGroupsResolve, getAvailableUserGroupsReject) => {
      this.externaldatasourceService.executeExternalDataSource(95, [this.selectedUserGroupType])
      .then(externalDataSourceResult => {
        this.assignmentType = 'user';
        if (externalDataSourceResult != null) {
          this.userGroups = externalDataSourceResult.filter(usergroup => usergroup.isVisible == true);
        } else {
          this.userGroups = null;
        }       
        this.selectedUserGroup = this.commonService.getFirstItemFromArrayIfAvailable(this.userGroups);
        if (this.selectedUserGroupType == this.userGroupMailType) {
          this.getAssignedSMTPSettings();
          this.getAssignedSesConfigs();    
        }
        this.getUserGroupMember();
        getAvailableUserGroupsResolve(externalDataSourceResult);
      })
      .catch(error => {
        console.error('getUserGroups error', error);
        getAvailableUserGroupsReject();
      });
    });
  }

  isFilterActive(checkString, filterString) {
    return !this.commonService.checkIfStringContainsString(checkString, filterString);
  }

  getAssignedUsers() {    
    this.assignedUsers= [];
    if (this.selectedUserGroup) {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(96, [this.selectedUserGroup.id])
      .then(externalDataSourceResult => {
        this.assignedUsers = externalDataSourceResult.userGroupUsers;
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
      });  
    }
  }

  getAvailableUsers() {
    this.externaldatasourceService.executeExternalDataSource(14)
    .then(result => {      
      result.sort((a, b) => a.userName < b.userName ? -1 : 1);
      this.availableUsers = result;
    })
    .catch(error => {
      console.error(error);
    });
  }

  addUser(user) {
    this.loaderService.display(true);
    const itemToAdd = ''.concat('{ userId:' , user.id, ' userGroupId: ', this.selectedUserGroup.id, '}');
    this.externaldatasourceService.executeExternalDataSource(101, [itemToAdd])
    .then(() => {
      this.getAssignedUsers();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  removeUser(user) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(102, [user.id])
    .then(() => {
      this.getAssignedUsers();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  checkIfSmptSettingIsActive(smtpSetting) {
    const foundItem = this.assignedSMTPSettings.find(setting => setting.emailSettingId == smtpSetting.id);
    if (foundItem) {
      return foundItem;
    } else {
      return null;
    }
  }

  checkIfSesConfigIsActive(mailConfigSesRefId) {
    const foundItem = this.assignedSesConfigs.find(setting => setting.sesConfigSetId == mailConfigSesRefId.sesConfigSetId);
    if (foundItem) {
      return foundItem;
    } else {
      return null;
    }
  }

  toggleMailConfigSes(mailConfigSesRefId) {
    const config = this.checkIfSesConfigIsActive(mailConfigSesRefId);
    this.loaderService.display(true);
    if (config) {
      this.externaldatasourceService.executeExternalDataSource(718, [config.id])
      .then(() => {
        this.getAssignedSesConfigs();
        this.loaderService.display(false);
      }).catch(() => { this.loaderService.display(false); });
    } else {
      this.externaldatasourceService.executeExternalDataSource(717, [
        this.selectedUserGroup.id,
        mailConfigSesRefId.sesConfigSetId
      ])
      .then(() => {
        this.getAssignedSesConfigs();
        this.loaderService.display(false);
      }).catch(() => { this.loaderService.display(false); });
    }
  }

  toggleMailConfigSmtp(mailConfigSmtpRefId) {
    this.loaderService.display(true);
    const config = this.checkIfSmptSettingIsActive(mailConfigSmtpRefId);
    if (config) {
      this.externaldatasourceService.executeExternalDataSource(716, [config.id])
      .then(() => {
        this.getAssignedSMTPSettings();
        this.loaderService.display(false);
      }).catch(() => { this.loaderService.display(false); });
    } else {
      this.externaldatasourceService.executeExternalDataSource(715, [
        this.selectedUserGroup.id,
        mailConfigSmtpRefId.id
      ])
      .then(() => {
        this.getAssignedSMTPSettings();
        this.loaderService.display(false);
      }).catch(() => { this.loaderService.display(false); });
    }
  }

  createNewUserGroup() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(714, [
      this.commonService.getModifyArrayBody({
        defaultName: this.newGroupName,
        projectId: this.applicationInfoService.projectID,
        typeLookupId: this.selectedUserGroupType  
      }, [])
    ])
    .then(() => {
      this.getAvailableUserGroups();
      this.loaderService.display(false);
      this.createNewUserGroupMode = false;
    }).catch(() => { this.loaderService.display(false); });

  }
}
