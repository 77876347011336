import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { MailService } from 'app/jollyjupiter/service/mail.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';

@Component({
  selector: 'app-custom-email',
  templateUrl: './custom-email.component.html',
  styleUrls: ['./custom-email.component.scss']
})
export class CustomEmailComponent extends CustomControlBaseClass implements OnInit, AfterViewInit {
  emailValid = true;
  dataPopupOpen = false;
  tempEmail = '';
  emailCheck = [];
  canUpdateEMail = false;
  checkCount = 0;
  loadingData = true;
  emailChanged = false;
  isDisabled = true;
  apiValidationCheckResult = null;
  hideValidationCheckButton = false;
  validateEmailOnChange = false;
  verifyEMailAfterControlLoad = false;
  hideChangeEMailButton = false;

  constructor(
    private injector: Injector,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private mailService: MailService,
    private domSanitizer: DomSanitizer,
    private emailtemplateService: EmailtemplateService
  ) {
    super(injector);
    this.valueType = ValueType.String;
    this.controlGuidPredefine = 'email';
  }

  ngAfterViewInit(): void {
    this.appendPopupToBody();
  }

  ngOnInit(): void {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.isDisabled = !this.uiService.getControlSettingOrDefault(this.controlDefinition, 'canEditEmailViaInput', false);
    this.hideValidationCheckButton = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'hideValidationCheckButton', false);
    this.validateEmailOnChange = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'validateEmailOnChange', false);
    this.verifyEMailAfterControlLoad = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'verifyEMailAfterControlLoad', false);
    this.hideChangeEMailButton = this.uiService.getControlSettingOrDefault(this.controlDefinition, 'hideChangeEMailButton', false);
    if (this.verifyEMailAfterControlLoad == true) {
      this.validateEmailViaAPI();
    }
  }

  callLocalMethod(type) {
    if (this.validateEmailOnChange) {
      this.validateEmailViaAPI();
    }
    this.emailValid = true;
    const entityMember = this.entityService.getEntityMemberByName(this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value);
    if (!this.commonService.isNullOrUndefined(entityMember)) {
      this.externalDatasourceService.executeExternalDataSource(509, [entityMember.id, this.controlValue])
      .then(() => {
        this.callMethod(type);
        this.dataPopupOpen = false;
      })
      .catch(()=> {
        this.confirmationService.confirm({
          message: this.axivasTranslateService.getTranslationTextForToken('VerificationError.EMail.Message'),
          header: this.axivasTranslateService.getTranslationTextForToken('VerificationError.EMail.Header'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.callMethod(type);
          },
          reject: () => {
            this.emailValid = false;
          }
        });
      })
    } else {
      this.callMethod(type);
    }
  }

  keyDown() {
    this.emailChanged = true;
    console.warn(this.emailChanged)
    this.eventService.entityKeyDownEvent.emit(this.controlDefinition.uiEntityInstanceId)
  }

  sendMail() {
    this.mailService.mailTo(this.controlValue, '', '');
  }

  editEMail() {
    this.tempEmail = this.controlValue;
    this.checkCount = 0;
    this.dataPopupOpen = true;
    this.getEMailInformation();
  }

  getEMailInformation() {
    this.loadingData = true;
    this.canUpdateEMail = false;
    if (!this.commonService.isNullOrUndefined(this.tempEmail)) {
      this.tempEmail = this.tempEmail.trim();
    }
    this.externalDatasourceService.executeExternalDataSource(647, [this.tempEmail])
    .then(getEMailInformationResult => {
      this.loadingData = false;
      this.emailCheck = getEMailInformationResult;
      this.canUpdateEMail = true;
      this.checkCount ++;
      this.emailChanged = false;
    });
  }

  checkIfContactsAreConnected() {
    let returnValue = true;
    if (this.emailCheck) {
      if (this.emailCheck.length == 0) {
        returnValue = false;
      } else {
        if (this.emailCheck.length >= 2) {
          returnValue = true;
        } else {
          if (this.emailCheck[0].id == this.applicationInfoService.currentContact.id) {
            returnValue = false;
          }
        }
      }
    }
    return returnValue;
  }

  updateEMail() {
    this.tempEmail = this.tempEmail.trim();
    this.controlValue = this.tempEmail;
    this.callLocalMethod('onchange');
  }

  hasConversion() {
    if (this.emailCheck.find(contact => contact.hasConversion == true )) {
      return true;
    } else {
      return false;
    }
  }

  validateEmailViaAPI() {
    if (this.commonService.isNullOrUndefined(this.controlValue) || this.controlValue == '') {
      return;
    }
    this.externalDatasourceService.executeExternalDataSource(898, [this.controlValue]).then(validationResult => {
      this.apiValidationCheckResult = validationResult;
    });
  }

  validateEMail() {
    return this.commonService.validateEMail(this.tempEmail);
  }

  createEMail() {
    if (this.commonService.validateEMail(this.controlValue)) {
      this.applicationInfoService.miscSettings['syncMailTo'] = this.controlValue;
      this.eventService.showJjPopup('CustomEMail.Label.MailToContact', 'emaileditorsync', '90');
    }
  }

  generateTippy() {
    return this.emailtemplateService.generateTippyForValidation(this.apiValidationCheckResult);
  }

  getPopupId(layer = '') {
    return 'emailPopupId'.concat(this.controlDefinition.id, layer);
  }

  appendPopupToBody() {
    const background = document.getElementById(this.getPopupId('background'));    
    document.body.append(background);
    const popup = document.getElementById(this.getPopupId());    
    document.body.append(popup);
  }
}
