<div class="processvisualpop">
    <div class="processvisualpop__content">
            <div class="processvisualpop__item" *ngIf="type !== 'bookingDays'" >
                <label class="label">
                    {{ 'ProcessDesign.Label.IsActive' | texttransform }}
                </label>
                <input type="checkbox" [(ngModel)]="data.isActive" >
            </div>
            <div class="processvisualpop__item" *ngIf="type !== 'bookingDays'">
                <label class="label">
                    {{ 'ProcessDesign.Label.DefaultName' | texttransform }}
                </label>
                <input type="text" [(ngModel)]="data.defaultName" class="input">
            </div>

            <div class="processvisualpop__item" *ngIf="type === 'steps'" >
                <label class="label">
                   {{'ProcessDesign.Label.Description' | texttransform }}
                </label>
                <textarea [(ngModel)]="data.description" style="height: 90px; width:100%"></textarea>
            </div>
            
            <!-- HERE FOR WIZARD ASSETS -->

            <div class="processvisualpop__item" *ngIf="type  === 'bookingDays'">
                <div class="">
                    <label class="label">
                        {{'ProcessVisual.Label.AutomaticbookingafterxDays' | texttransform }}
                    </label>
                    <input  type="number"[(ngModel)]="data.bookTaskAfterXDays" class="input">
                </div>
            </div>

            <div class="processvisualpop__nodeswrapper" *ngIf="type === 'bookingDays'">
                <label class="label">
                    {{'ProcessVisual.Label.Result' | texttransform}}
                   <!--  {{this.axivasTranslationService.getTranslationTextForToken("ProcessVisual.Label.Result")}} -->
                </label>
                <div class="nodesInner">
                    <span [ngClass]="result.nextStepId ? 'resultsChildren' : 'results' ">{{result.defaultName}}</span>
                </div>
            </div>
        
    </div>

    <div class="processvisualpop__footer">
        <button class="everleadCustomButton"(click)="saveData()">{{'ProcessDesign.Label.Save' | texttransform }}</button>
    </div> 
</div>

<!-- <div class="processvisualpop" *ngIf="type == 'duplicateTemplate'">
   <!-- <div class="processvisualpop__header">
        <span>{{'ProcessDesign.Label.CreateNewTemplateName'}}</span>
    </div> -->

 <!--    <div class="processvisualpop__item">
       <label class="label">
            {{ 'ProcessDesign.Label.DefaultName' | texttransform }}
        </label>
        <input type="text" [(ngModel)]="data.defaultName" class="input">
    </div>
    <div class="processvisualpop__footer">
        <button class="everleadCustomButton"(click)="createWorkflow()">{{'ProcessVisual.Label.Duplicate' | texttransform }}</button>
    </div> 
</div> --> 
