import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { WizardMode, WizardService } from '../../static/wizard/wizard.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campaigndashboard',
  templateUrl: './campaigndashboard.component.html',
  styleUrls: ['./campaigndashboard.component.scss']
})
export class CampaigndashboardComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  mailCampaignOverview = null;
  microCampaignOverview = null;
  microCampaignDashboardInfo = {
    microCampaignItemsPerPage: 5,
    microCampaignPage: 1,
    microCampaignMaxPage: 1
  }
  mailCampaignDashboardInfo = {
    microCampaignItemsPerPage: 5,
    microCampaignPage: 1,
    microCampaignMaxPage: 1
  }
  selectedMicroCampaign = null;
  selectedMailCampaign = null;
  loadingMicroCampaignData = false;
  loadingDataEMailBatches = false;
  
  // Pie
  completePieId = '';
  sentInfoPieId = '';
  barChartId = '';
  barChartMailId = '';
  funnelChartMailId='';

  mailCampaignDetails = [];
  mailCampaignSentInfo = [];

  mailCampaignSearchString = '';
  microCampaignSearchString = '';
  
  funnelMaxHeight = '350px';

  chartDataMicroCampaign = {
    data: [],
    categories: [],
    type:''

  }

  chartDataMailDetailsCampaign = {
    data: [],
    type:'bar'
  }

  chartDataMailCampaignSentInfo = {
    data: [],
    type:''
    //color: ['#ED5C73','#F07E26','#80B800','#59AA8E','#AEA7B0', '#3EA4C3', '#1D1645', '#57518E', '#346188', '#1B3A55']
  }


  funnelFakeData ={
    data:[],
    type:'',
  }

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,
    private userService: UserService,
    private loaderService: LoaderService,
    private wizardService: WizardService,
    private methodService: MethodService
  ) { }

  ngOnInit(): void {
    this.getMicroCampaignOverview();
    this.getEMailBatches();
    this.completePieId = 'mailShotDashboardComplete';
    this.sentInfoPieId = 'mailShotDashboardOpened';
    this.barChartId = 'microCampaignStepInfoBar';

    // ! ivo
    this.barChartMailId= 'mailCampaignDetailsInfoBar';
    this.funnelChartMailId = 'mailCampaignDetailsInfoBar';

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'selectedPoolChanged') { 
        this.getMicroCampaignOverview();
        this.getEMailBatches();
       }
    })
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getEMailBatches() {
    this.loadingDataEMailBatches = true;
    this.mailCampaignOverview = [];
    let externalDataSourceId = 656;
    if (this.applicationInfoService.applicationSettings['useEventCampaign'] == 'true') {
      externalDataSourceId = 853;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId)
    .then(getEMailBatchesResult => {
      getEMailBatchesResult.forEach(emailBatch => {
        if (this.applicationInfoService.applicationSettings['sortEMailCampaignsByPoolId']) {
          if (this.mailshotPoolIsCurrentPool(emailBatch) && emailBatch.isDeleted == false) {
            this.mailCampaignOverview.push(emailBatch);
          }          
        } else {
          this.mailCampaignOverview.push(emailBatch);
        }
      });
      this.loadingDataEMailBatches = false;
    }).catch(() => { this.loadingDataEMailBatches = false; });
  }

  mailshotPoolIsCurrentPool(mailshot) {
    const array = JSON.parse(mailshot.wizardValues);
    if (array) {
      return array.poolId == this.applicationInfoService.poolId;
    } else {
      return false;
    }
  }

  getMicroCampaignOverview() {
    this.loadingMicroCampaignData = true;
    this.microCampaignOverview = [];
    this.externaldatasourceService.executeExternalDataSource(759, [
      this.microCampaignDashboardInfo.microCampaignPage,
      this.microCampaignDashboardInfo.microCampaignItemsPerPage
    ])
    .then(getCampaignOverviewResult => {
      this.loadingMicroCampaignData = false;
      this.microCampaignOverview = getCampaignOverviewResult;
    }).catch (() => { this.loadingMicroCampaignData = false; });
  }

  showCampaignVisual(campaignOverviewItem) {
    this.applicationInfoService.miscSettings['processvisual'] = campaignOverviewItem;
    this.eventService.showJjPopup(campaignOverviewItem.resultName, 'processvisual', '90');
  }

  showMicroCampaignDetails(campaignOverviewItem) {
    this.selectedMailCampaign = null;
    this.selectedMicroCampaign = campaignOverviewItem;
    this.chartDataMicroCampaign.data = campaignOverviewItem.details
    this.chartDataMicroCampaign.categories = ['CampaignDashboard.Label.CampaignDetailBooked', 'CampaignDashboard.Label.CampaignDetailOpen']
    this.chartDataMicroCampaign.type='bar'
    this.eventService.customEvent.emit({
      id: 'updateBarChart',
      value: this.barChartId,
      chartData: this.chartDataMicroCampaign
    });
  }

  getCampaignProgressPercentage(campaignOverviewItem) {
    const sumMax = campaignOverviewItem.countOpen + campaignOverviewItem.countSent + campaignOverviewItem.countCanceled + campaignOverviewItem.countBounced;
    let percentage = 0;
    if (sumMax != 0) {
      percentage = Math.floor(((sumMax - campaignOverviewItem.countOpen) * 100) / sumMax);
    }
    if (percentage > 100) { percentage = 100; }
    if (percentage < 0) { percentage = 0; }
    return percentage + '%';
  }


  getStepName(stepId) {
    const step = this.applicationInfoService.steps.toArray().find(step => step.id == stepId);
    if (step) {
      return this.commonService.getTranslationValueFromArray(step);
    } else {
      return '';
    }
  }

  getCampaignIconId(campaignOverviewItem) {
    return 'campaignIcon' + campaignOverviewItem.id;
  }

  showMailCampaignDetails(campaignOverviewItem) {
    this.mailCampaignDetails = [];
    this.selectedMicroCampaign = null;
    // Overall details
    if (!this.commonService.isNullOrUndefined(campaignOverviewItem.wizardValues)) {

    }
    const details = JSON.parse(campaignOverviewItem.wizardValues);
    const campaignId = details.campaignId;

      if ( campaignOverviewItem.countOpen > 0 ) { this.mailCampaignDetails.push({ name: 'Mailshots.Label.CountOpen', value: campaignOverviewItem.countOpen, isSelected: true }) };
      if ( campaignOverviewItem.countBounced > 0 ) { this.mailCampaignDetails.push({ name: 'Mailshots.Label.CountBounced', value: campaignOverviewItem.countBounced, isSelected: true }) };
      if ( campaignOverviewItem.countSent > 0 ) { this.mailCampaignDetails.push({ name: 'Mailshots.Label.CountSend', value: campaignOverviewItem.countSent, isSelected: true }) };
      if ( campaignOverviewItem.countCanceled > 0 ) { this.mailCampaignDetails.push({ name: 'Mailshots.Label.CountCanceled', value: campaignOverviewItem.countCanceled, isSelected: true }) };
      if ( campaignOverviewItem.countFailure > 0 ) { this.mailCampaignDetails.push({ name: 'Mailshots.Label.CountFailure', value: campaignOverviewItem.countFailure, isSelected: true }) };
      if ( campaignOverviewItem.countPlanned > 0 ) { this.mailCampaignDetails.push({ name: 'Mailshots.Label.CountPlanned', value: campaignOverviewItem.countPlanned, isSelected: true }) };
      this.selectedMailCampaign = campaignOverviewItem;
      this.getSentMailsInfo(campaignId, this.selectedMailCampaign).then(mailInfo => {
        mailInfo.forEach(info => {
          info.name = info.displayName;
        });
        this.mailCampaignSentInfo = mailInfo;
        this.chartDataMailCampaignSentInfo.data = this.mailCampaignSentInfo;
        this.chartDataMailCampaignSentInfo.data.sort((a, b) => a.value > b.value ? -1 : 1);
        this.eventService.customEvent.emit({
          id: 'updatePieChart',
          value: this.sentInfoPieId,
          chartData: this.chartDataMailCampaignSentInfo
        });
      });

      // * campaign Mail Details
      let checkValues = this.mailCampaignDetails.some((item) => item.value > 0)
      if (!checkValues) {
        this.chartDataMailDetailsCampaign.data=[]

        //! funnel 
        this.funnelFakeData.data=[]

      } else {
        //console.log('this mail campaign details', this.mailCampaignDetails);
        this.chartDataMailDetailsCampaign.data = this.mailCampaignDetails
        this.chartDataMailDetailsCampaign.type='bar'
      }

      // * campaign sent Info
      this.chartDataMailCampaignSentInfo.data = this.mailCampaignSentInfo
      this.chartDataMailCampaignSentInfo.type='funnel'

      //console.log('this chartDataMail Bar', this.chartDataMailDetailsCampaign);

      this.eventService.customEvent.emit({
        id: 'updateBarChart',
        value: this.barChartMailId,
        chartData: this.chartDataMailDetailsCampaign
      });
      this.eventService.customEvent.emit({
        id: 'updatePieChart',
        value: this.sentInfoPieId,
        chartData: this.chartDataMailCampaignSentInfo
      });

    // * campaign Mail Details
    let checkValues2 = this.mailCampaignDetails.some((item) => item.value > 0)
    if (!checkValues2) {
      this.chartDataMailDetailsCampaign.data=[]
    } else {
      this.chartDataMailDetailsCampaign.data = this.mailCampaignDetails
      this.chartDataMailDetailsCampaign.type = "bar"
    }

    this.eventService.customEvent.emit({
      id: 'updateBarChart',
      value: this.barChartMailId,
      chartData: this.chartDataMailDetailsCampaign
    });      
  }

  getSentMailsInfo(campaignId, campaignOverviewItem): Promise<any> {
    return new Promise((getSentMailsInfoResolve, getSentMailsInfoReject) => {
      this.externaldatasourceService.executeExternalDataSource(842, [
          campaignId, 
          campaignOverviewItem.id
      ]).then(mailDetails => {
        const returnValue = [];
        const clickedMails = mailDetails.filter(detail => detail.eventType == 'Click');
        const deliveredMails = mailDetails.filter(detail => detail.eventType == 'Delivery');
        const failedMails = mailDetails.filter(detail => detail.eventType == 'failure');
        const openMails = mailDetails.filter(detail => detail.eventType == 'Open');
    
        const openMailsFinal = openMails.length - clickedMails.length;
        const deliveredMailsFinal = deliveredMails.length - openMails.length;
    
        if (clickedMails.length > 0) {
          returnValue.push({ displayName: 'Mailshots.Label.Clicked', value: clickedMails.length, isSelected: true });
        }
        if (openMailsFinal > 0) {
          returnValue.push({ displayName: 'Mailshots.Label.Open', value: openMailsFinal, isSelected: true });
        }
        if (deliveredMailsFinal > 0) {
          returnValue.push({ displayName: 'Mailshots.Label.Delivered', value: deliveredMailsFinal, isSelected: true });
        }
        if (failedMails.length > 0) {
          returnValue.push({ displayName: 'Mailshots.Label.Failed', value: failedMails.length, isSelected: true });
        }
        getSentMailsInfoResolve(returnValue);  
      });
    });
  }

  addMailDetails(base, eventType, text1, text2, maxValue) {
    const array = [];
    let amount = 0;
    let rest = 0;
    if (!this.commonService.isNullOrUndefined(base)) {
      const items = base.filter(baseItem => baseItem.eventType == eventType);
      if (items) {
        amount = items.length;
      }
      rest = maxValue - amount;
    }
    array.push({ name: text1, value: amount, isSelected: true });
    array.push({ name: text2, value: rest, isSelected: true });
    return array;
  }

  isItemFiltered(baseString, containedString) {
    return this.commonService.checkIfStringContainsString(baseString, containedString);
  }

  createMailshot() {
    if (!this.userService.hasPermission('CanCreateMailshotList')) { return; }
    const array = {
      projectId: this.applicationInfoService.projectID,
      stepId: null,
      batchName: 'New batch',
      campaignId: null,
      campaignName: '',
      receivers: null,
      followUpUserId: this.applicationInfoService.userID,
      followUpDate: new Date(Date.now()),
      sender: '',
      subject: '',
      templateName: '',
      templateHTML: '',
      templateSource: 1
    }
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(693, [
      this.commonService.getModifyArrayBody({
        batchName: array.batchName,
        projectId: array.projectId,
        countSent: 0,
        countOpen: 0,
        countCanceled: 0,
        countBounced: 0,
        wizardValues: JSON.stringify(array)
      }, [])
    ]).then(createNewMailshotResult => {
      array['id'] = createNewMailshotResult.id;
      array['status'] = 0;
      array['countSent'] = 0;
      array['countOpen'] = 0;
      array['countCanceled'] = 0;
      array['countBounced'] = 0;
      array['receivers'] = null;
      this.loaderService.display(false);
      this.wizardService.showWizard(WizardMode.Mailshot, array, '90', '90');
    }).catch(()=> { this.loaderService.display(false); });
  }

  isFiltered(baseString, searchString) {
    return !this.commonService.checkIfStringContainsString(baseString, searchString);
  }

  showMailCampaigns() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'mailshots']);
  }

  editMailCampaign(campaignOverviewItem) {
    this.wizardService.showWizard(WizardMode.Mailshot, campaignOverviewItem, '90', '90');
  }

  showMailshotDetails(mailshot) {
    this.applicationInfoService.miscSettings['emailbatch'] = mailshot;
    this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
  }

  getSendDate(selectedMailCampaign) {
    if (!this.commonService.isNullOrUndefined(selectedMailCampaign.wizardValues)) {
      const mailShotInfo = JSON.parse(selectedMailCampaign.wizardValues);
      return mailShotInfo.followUpDate;  
    } else {
      return null;
    }
  }

  showCampaignUser(selectedMicroCampaign) {
    this.applicationInfoService.miscSettings['presetMicroCampaignId'] = selectedMicroCampaign.resultId;
    this.eventService.showJjPopup('', this.applicationInfoService.applicationSettings['microCampaignParticipantControlId'], '90');
  }
}
