import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { environment } from 'environments/environment';
import { CommonService } from './common.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { MessagingService } from './messaging.service';

@Injectable({
  providedIn: 'root'
})
export class EmailtemplateService {
  demoContact = {
    firstName: 'Max',
    lastName: 'Mustermann',
    extension: '01234 213321',
    mobile: '01123 mobile',
    titleId: 'Mr.'    
  }

  demoAccount = {
    name1: 'Musteraccount',
    name2: '& Co KG',
    street: 'Musterstraße',
    zipcode: '99099',
    city: 'Musterstadt',
    mail: 'test@mustermann-ag.de',
    url: 'http://mustermann-ag.de',
    phone: 'Mr.'    
  }

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  getEMailContents() {
    return new Promise((getEMailContentsResolve, getEMailContentsReject) => {
    this.externaldatasourceService.executeExternalDataSource(435, [])
      .then(getEMailContentsResult => {
        const result = [];
        getEMailContentsResult.forEach(element => {
            if (element.isPublished == false) {
              result.push(element);
            }
        });
        getEMailContentsResolve(result);
      })
      .catch(error => {
        getEMailContentsReject(error);
      });
    });
  }

  replaceGeneralTokens(html) {
    html = html.replace(/{{ConsentDomain}}/, environment.consentPageUrl);
    html = html.replace(/{{ProjectGuid}}/, this.applicationInfoService.projectInfo.projectGuid);
    html = html.replace(/{{ContactGuid}}/, this.applicationInfoService.currentContact.guid);
    return html;
  }

  checkSenderDomain(sender) {
    if (this.commonService.isNullOrUndefined(sender)) {
      return false;
    }
    let returnValue = false;
    if (this.applicationInfoService.applicationSettings['activeAWSIdentities'] == undefined) { return returnValue; }

    let validDomains = this.applicationInfoService.applicationSettings['activeAWSIdentities'].split(';');
    let domain = sender.split('@');
    if (domain.length > 1) {
      domain = domain[1].split('>');
      validDomains.forEach(validDomainItem => {
        if (domain[0].toLowerCase() == validDomainItem.toLowerCase()) {
          returnValue = true;
        }
      });
    }
    return returnValue;
  }
  getTempalateLanguage(templateId, languageId): Promise<any> {
    return new Promise((getTempalateLanguageResolve, getTempalateLanguageReject) => {
      this.externaldatasourceService.executeExternalDataSource(461, [templateId, languageId])
      .then(getTempalateLanguageResult => {
        getTempalateLanguageResolve(getTempalateLanguageResult);
      })
      .catch(error => { 
        getTempalateLanguageReject(error); 
      });
    });
  }

  getTemplateLanguageSubject(selectedTemplate, languageId): Promise<any> {
    return new Promise((getTemplateLanguageSubjectResolve, getTemplateLanguageSubjectReject) => {
      this.getTempalateLanguage(selectedTemplate.id, languageId).then(getTempalateLanguageResult => {
        getTemplateLanguageSubjectResolve(getTempalateLanguageResult.subject);
      }).catch(() => { getTemplateLanguageSubjectReject(null); });
    });
  }

  getTaskMailPreview(taskId): Promise<any> {
    return new Promise((getTaskMailPreviewResolve, getTaskMailPreviewReject) => {
      this.externaldatasourceService.executeExternalDataSource(500, [taskId])
      .then(getTaskMailPreviewresult => {
        console.warn('result', getTaskMailPreviewresult)
        this.getEMailTemplate(getTaskMailPreviewresult.emailTemplateLanguageId, 2, true)
        .then(getEMailTemplateResult => {
          getTaskMailPreviewResolve(getEMailTemplateResult);
        })
        .catch(error => {
          getTaskMailPreviewReject(error);
        });  
      })
      .catch(error => {
        getTaskMailPreviewReject(error);
      });
    });    
  }

  isValidSMTPContent(emailContent) {
    if (
      emailContent.emailTemplateContentTypeLookupId == 5780 &&
      (emailContent.projectId == null || emailContent.projectId == this.applicationInfoService.projectID) &&
      (emailContent.userId == null || emailContent.userId ==  this.applicationInfoService.userID)
    ) {
      return true;
    } else {
      return false;
    }
  }
  
  getEMailTemplate(templateId, languageId = 2, isPublished = false): Promise<any> {
    return new Promise((getEMailTemplateResolve, getEMailTemplateReject) => {
    this.externaldatasourceService.executeExternalDataSource(461, [templateId, languageId, isPublished])
      .then(getEMailTemplateResult => {
        getEMailTemplateResolve(getEMailTemplateResult);
      })
      .catch(error => {
        getEMailTemplateReject(error);
      });
    });
  }

  addDemoData(content) {
    content = this.replaceWithArrayValues('account', this.demoAccount, content);
    content = this.replaceWithArrayValues('contact', this.demoContact, content);
    return content;
  }

  addCustomerData(content, contactData = null) {
    content = this.replaceWithArrayValues('account', this.applicationInfoService.currentAccount, content);
    if (contactData == null) {
      content = this.replaceWithArrayValues('contact', this.applicationInfoService.currentContact, content);
    } else {
      content = this.replaceWithArrayValues('contact', contactData, content);
    }
    return content;    
  }

  replaceWithArrayValues(entityName, array, content) {
    if (this.commonService.isNullOrUndefined(array)) {
      return;
    }
    const contactArray = Object.getOwnPropertyNames(array);
    contactArray.forEach(arrayItem => {      
      content = content.replace(
        ''.concat('{{', entityName, '.', arrayItem, '}}'), 
        array[arrayItem]
      )
    })
    return content;
  }

  renderEMail(content, emailContents, useCurrentCustomer = false, contactData = null, dontReplaceTokens = false) {
    emailContents.forEach(contentItem => {
      if (!this.commonService.isNullOrUndefined(contentItem.content)) {
        const itemVariable = '{{' + contentItem.defaultName + '}}';
        let tempItemContent = contentItem.content;
        tempItemContent = tempItemContent.replace(/\r\n/g, '<br>');
        tempItemContent = tempItemContent.replace(/\n/g, '<br>');
        content = content.replace(itemVariable, tempItemContent);  
      }
    });
    if (dontReplaceTokens == false) {
      if (useCurrentCustomer == false) {
        content = this.addDemoData(content);
      } else {
        content = this.addCustomerData(content, contactData);
      }            
    }
    return content;      
  }
  
  createEMailTemplateLanguage(templateId, emailLanguage) {
    return new Promise((createEMailTemplateLanguageResolve, createEMailTemplateLanguageReject) => {
      this.externaldatasourceService.executeExternalDataSource(436, [templateId, emailLanguage])
        .then(getEMailTemplateResult => {
          createEMailTemplateLanguageResolve(getEMailTemplateResult);
        })
        .catch(error => {
          createEMailTemplateLanguageReject(error);
        });
      });
  }

  sendTestEmail(emailTemplateId, campaignId, contactId, emailAdress, templateCheck = false): Promise<any> {
    return new Promise((sendTestEmailResolve, sendTestEmailReject) => {
      const mail = new Object();
      if (this.commonService.isNullOrUndefined(contactId)) {
        console.error('sendTestEmail', 'contactId required')
      }
      if (this.commonService.isNullOrUndefined(campaignId)) {
        console.error('sendTestEmail', 'campaignId required')
      }
      mail['projectId'] = this.applicationInfoService.projectID;
      mail['contactId'] = contactId;
      mail['campaignId'] = campaignId;
      mail['templateId'] = emailTemplateId;
      mail['recipientOverride'] = emailAdress;
      if (templateCheck) {
        mail['previewOnly'] = true;
      }
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(463, [
        this.commonService.getModifyArrayBody(mail, [])
      ]).then(testEmailResult => {
        this.loaderService.display(false);
        if (templateCheck == false) {
          this.messagingService.showDefaultSuccess('EMailService.TestMail.Header', 'EMailService.TestMail.Message');
          console.log('sendTestEmail', testEmailResult)  
        }
        sendTestEmailResolve(testEmailResult);
      }).catch(error => {
        this.loaderService.display(false);
        if (templateCheck == false) {
          this.messagingService.showDefaultError('EMailService.TestMail.Header', error);
          console.error('sendTestEmail', error)  
        }
        sendTestEmailReject(error);
      });
    });
  }

  createProcessForEmailTemplate(template): Promise<any> {
    return new Promise((createProcessForEmailTemplateResolve, createProcessForEmailTemplateReject) => {
      this.externaldatasourceService.executeExternalDataSource(105, [])
      .then(workflows => {
        let mailWorkflow = workflows.find(workflow => workflow.typeLookupId == 4958 );
        if (this.commonService.isNullOrUndefined(mailWorkflow)) {
          this.externaldatasourceService.executeExternalDataSource(530, [
            this.commonService.getModifyArrayBody(
              { typeLookupId: 4958, defaultName: 'MailShot', isActive: true, projectId: this.applicationInfoService.projectID }, []
            )
          ]).then(newWorkflowResult => {
            this.createStepForEmailTemplate(template, newWorkflowResult.id).then(createStepForEmailTemplateResult => { 
              createProcessForEmailTemplateResolve({ workflowId: newWorkflowResult.id, stepId: createStepForEmailTemplateResult.id, resultId: createStepForEmailTemplateResult.bookTaskAfterXDaysResultId }); 
            }); 
          });
        } else {
          this.createStepForEmailTemplate(template, mailWorkflow.id)
          .then(createStepForEmailTemplateResult => { 
            createProcessForEmailTemplateResolve({ workflowId: mailWorkflow.id, stepId: createStepForEmailTemplateResult.id, resultId: createStepForEmailTemplateResult.bookTaskAfterXDaysResultId }); 
          });          
        }
      });  
    });
  }

  createStepForEmailTemplate(template, workflowId): Promise<any> {
    return new Promise((createStepForEmailTemplateTemplateResolve, createStepForEmailTemplateReject) => {
      this.loaderService.display(true);
      console.warn('createStepForEmailTemplate', template, workflowId)
      // Step erstellen
      this.externaldatasourceService.executeExternalDataSource(567, [
        this.commonService.getModifyArrayBody({ 
          defaultName: 'MailShot: ' + template.defaultName, isActive: true, workflowId: workflowId, isProcessStarter: true }, [])
      ])
      .then(createStepForEmailTemplateResult => {
        // Template anreichern
        this.externaldatasourceService.executeExternalDataSource(431, [
          this.commonService.getModifyArrayBody({ 
            id: template.id, stepId: createStepForEmailTemplateResult.id
          }, [])
        ])  
        // Ergebnis erstellen
        this.externaldatasourceService.executeExternalDataSource(569, [
          this.commonService.getModifyArrayBody(
            { 
              isActive: true, stepId: createStepForEmailTemplateResult.id, defaultName: template.defaultName + ' verschicken', emailTemplateId: template.id,
              resultOrder: 0, isPersonalFollowup: false, isNetResult: false, isCallAttempt: false, nextStepId: null 
            }
          , [])
        ]).then(createStepResultResult => {
          // Ergebnis dem Step anreichern
          this.applicationInfoService.miscSettings['templateStepResultId'] = createStepResultResult.id;
          this.externaldatasourceService.executeExternalDataSource(568, [
            this.commonService.getModifyArrayBody(
              { 
                id: createStepForEmailTemplateResult.id, bookTaskAfterXDays: 0, bookTaskAfterXDaysResultId: createStepResultResult.id,
                defaultName: 'MailShot: ' + template.defaultName, isActive: true, workflowId: workflowId
              }
            , [])
          ])
          .then(() => {
            createStepForEmailTemplateTemplateResolve(createStepForEmailTemplateResult);
            this.messagingService.showDefaultSuccess('', 'EMailService.Message.CreateTaskSuccessfull')
            this.loaderService.display(false);
          }).catch(() => { this.loaderService.display(false); createStepForEmailTemplateReject(null);  });
        }).catch(() => { this.loaderService.display(false); createStepForEmailTemplateReject(null); });
      }).catch(() => { this.loaderService.display(false); createStepForEmailTemplateReject(null);  });
    });
  }

  getPlaceHolderInfo(placeholder) {
    let returnString = '';
    let entityId = null;
    placeholder = placeholder.replace(/{/g, '');
    placeholder = placeholder.replace(/}/g, '');
    const placeHolderDetails = placeholder.split('.');
    switch (placeHolderDetails[0]) {
      case 'account':
        entityId = 1;
        break;

      case 'contact':
        entityId = 4;
        break;

      case 'lastActivity':
        entityId = 2;
        break;  
    }
    if (entityId == null) {
      returnString = placeholder;
    } else {
      const member = this.applicationInfoService.entities.Item(entityId).entityMembers
        .find(member => member.defaultName == placeHolderDetails[1]);
      if (member) {
        returnString = ''.concat(
          this.axivasTranslateService.getTranslationTextForObject(this.applicationInfoService.entities.Item(entityId), 'nameTranslationToken'),
          ' - ',
          this.axivasTranslateService.getTranslationTextForObject(member, 'nameTranslationToken')
        );
      } else {
        returnString = placeholder;
      }
    }
    return returnString;
  }

  getAttachments(emailTemplateId): Promise<any> {
    return new Promise((getAttachmentsResolve, getAttachmentsReject) => {
      this.externaldatasourceService.executeExternalDataSource(882, [emailTemplateId])
      .then(getAttachmentsResult => {
        getAttachmentsResolve(getAttachmentsResult);        
      }).catch(() => getAttachmentsReject(null) );
    });
  }

  generateTippyForValidation(validationResult) {
    let returnString = '';
    returnString = returnString.concat('<b>', this.axivasTranslateService.getTranslationTextForToken('CustomEmail.Label.Header'), '</b>', '<br><br>');
    returnString = returnString.concat(
      this.getIconForTooltip(validationResult.canReceiveMail), ' ',
      this.axivasTranslateService.getTranslationTextForToken('CustomEmail.Label.CanReceiveMail'),      
      '<br>'
    );
    returnString = returnString.concat(
      this.getIconForTooltip(validationResult.isFreeMail), ' ',
      this.axivasTranslateService.getTranslationTextForToken('CustomEmail.Label.IsFreeMail'),      
      '<br>'
    );
    returnString = returnString.concat(
      this.getIconForTooltip(validationResult.isTrashMail), ' ',
      this.axivasTranslateService.getTranslationTextForToken('CustomEmail.Label.IsTrashMail'),      
      '<br>'
    );
    returnString = returnString.concat(
      this.getIconForTooltip(validationResult.mailserver), ' ',
      this.axivasTranslateService.getTranslationTextForToken('CustomEmail.Label.Mailserver'),      
      '<br>'
    );
    if (validationResult.message != '') {
      returnString = returnString.concat(
        '<div class="iconForTooltip__message">',
        validationResult.message,
        '</div>'
      );  
    }
    return returnString;
  }

  getIconForTooltip(boolValue): string {    
    if (boolValue) {
      return '<span><i class="fas fa-check-square everleadGreen iconForTooltip"></i></span>'
    } else {
      return '<span><i class="fas fa-times everleadCoral iconForTooltip"></i></span>';
    }
  }
}
