import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LanguageService } from 'app/shared/translation/language.service';

@Component({
  selector: 'app-custom-palette-editor',
  templateUrl: './custom-palette-editor.component.html',
  styleUrls: ['./custom-palette-editor.component.scss']
})
export class CustomPaletteEditorComponent implements OnInit {
  paletteObject = null;
  newObject = false;
  array = {
    id: null,
    controls: null
  };
  languages = [];
  locations = [];
  targets = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private languageService: LanguageService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.paletteObject = this.applicationInfoService.applicationSettings['customPalette'];    
    const locationsFromApplicationSettings = this.applicationInfoService.applicationSettings['paletteLocations'].split(';')
    locationsFromApplicationSettings.forEach(location => {
      const locationDetails = location.split('|');
      this.locations.push({
        defaultName: locationDetails[0],
        value: locationDetails[1]
      });
    });
    const targetsFromApplicationSettings = this.applicationInfoService.applicationSettings['paletteTargets'].split(';')
    targetsFromApplicationSettings.forEach(target => {
      const targetDetails = target.split('|');
      this.targets.push({
        defaultName: targetDetails[0],
        value: targetDetails[1]
      });
    });

    this.getLanguages();

    if (this.paletteObject == '') {
      this.array.controls = [];
      this.newObject = true;
    } else {
      this.array = JSON.parse(this.paletteObject)
    }
  }

  getLanguages() {
    this.languageService.getLanguages().then(getLanguagesResult => {this.languages = getLanguagesResult });    
  }

  savePalette() {
    let externalDatasourceId = 673;
    if (this.commonService.isNullOrUndefined(this.array.id)) {
      externalDatasourceId = 674;
    }

    let tableConfig = { 
      projectId: this.applicationInfoService.projectID, 
      setting: 'customPalette',  
      value: ''     
    }
    tableConfig.value = JSON.stringify(this.array)    
    if (this.array.id != null) {
      tableConfig['id'] = this.array.id;
    }
   
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [
      this.commonService.getModifyArrayBody(tableConfig, [])
    ]).then(savePaletteResult => {
      this.array.id = savePaletteResult.id;
      this.applicationInfoService.applicationSettings['customPalette'] = tableConfig.value;
      this.eventService.customEvent.emit({ id: 'paletteChanged' });
      if (externalDatasourceId == 674) {
        this.savePalette();
      }
    });
  }

  clonePalette(palette) {
    this.array.controls.push(JSON.parse(JSON.stringify(palette)));
    this.savePalette();
  }
  
  addPalette() {
    this.array.controls.push({
      isActive: true,
      controlSettingPaletteLocation: '',
      controlSettingPaletteLocationCheckPart: false,
      controlSettingPaletteLocationMinX: '',      
      controlSettingPaletteLocationMaxX: '',
      languageTexten: 'Title',
      languageTextde: 'Titel'
    });
    this.savePalette();
  }

  removePalette(control) {
    this.commonService.removeItemFromArray(this.array.controls, control);
    this.savePalette();
  }
}
