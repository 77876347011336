<div class="pieGraphHeaderLabel" [id]="getHeaderLabelId()" *ngIf="header != ''" [style.color]="headercolor">{{ header | texttransform }}</div>
<div *ngIf="!dataAvailable" class="pieGraph__noData" [ngClass]="{ 'pieGraph__darkMode': darkmode }">
    {{ 'Pie.Label.NoDataAvailable' | texttransform }}
</div>
<div class="pieGraph" [ngClass]="{ '
    pieGraph__flexStart': alignLeft,
    'hideInDom': !dataAvailable
}">
    <div [style.min-width]="getMaxValue()" class="d-flex">
        <canvas [id]="controlid" *ngIf="controlid != '' && showCanvas && size==400" width="400px" height="400px" [style.max-height]="getMaxValue()"></canvas>
        <canvas [id]="controlid" *ngIf="controlid != '' && showCanvas && size==300" width="300px" height="300px" [style.max-height]="getMaxValue()"></canvas>
        <canvas [id]="controlid" *ngIf="controlid != '' && showCanvas && size==200" width="200px" height="200px" [style.max-height]="getMaxValue()"></canvas>
        <canvas [id]="controlid" *ngIf="controlid != '' && showCanvas && size==150" width="150px" height="150px" [style.max-height]="getMaxValue()"></canvas>
        <canvas [id]="controlid" *ngIf="controlid != '' && showCanvas && size==100" width="100px" height="100px" [style.max-height]="getMaxValue()"></canvas>
    </div>
    <div class="pieGraph__legend" *ngIf="legend == true"  [style.max-width]="getMaxLegendWidth()" [ngClass]="{ 'pieGraph__legend__flexStart': alignLeft }">
        <div *ngFor="let dataItem of data; let i = index"
            [style.color]="colors[i].font"
            class="pieGraph__legend__item"
            [ngClass]="{ hideInDom: dataItem?.value == 0 || dataItem?.isSelected == false }"
        >
            <div class="pieGraphLegendBall" [style.background-color]="colors[i].background" ></div>
            <div class="pieGraphLegendValue" [style.color]="getLegendColor()">
                {{ dataItem.displayName | texttransform }}: {{ dataItem.value }} ({{ getPercentage(dataItem) }})
            </div>   
        </div>
    </div>
</div>
