import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-dashoardcontrol-microcampaigns',
  templateUrl: './custom-dashoardcontrol-microcampaigns.component.html',
  styleUrls: ['./custom-dashoardcontrol-microcampaigns.component.scss']
})
export class CustomDashoardcontrolMicrocampaignsComponent implements OnInit {
  campaignOverview = null;
  dashboardInfo = {
    microCampaignItemsPerPage: 5,
    microCampaignPage: 1,
    microCampaignMaxPage: 1
  }

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getCampaignOverview();
  }

  getCampaignOverview() {
    this.externaldatasourceService.executeExternalDataSource(759, [this.dashboardInfo.microCampaignPage, this.dashboardInfo.microCampaignItemsPerPage])
    .then(getCampaignOverviewResult => {
      this.campaignOverview = getCampaignOverviewResult;
    });
  }

  showCampaignVisual(campaignOverviewItem) {
    this.applicationInfoService.miscSettings['processvisual'] = campaignOverviewItem;
    console.warn(campaignOverviewItem)
    this.eventService.showJjPopup(campaignOverviewItem.resultName, 'processvisual', '90');
  }
}
