<div class="bookingPreviewControl__nextStepHeader__bookingResult" *ngIf="resultId == null && result != null">
    {{ 'BookingPreview.Label.BookingPreview' | texttransform }}
</div>

<div class="bookingPreviewControl__spacer" *ngIf="resultId != null">
    <div class="bookingPreviewControl__spacer__arrow"></div>
</div>

<div class="bookingPreviewControl__nextStepHeader__leadState" *ngIf="result?.leadStatus != null">
    <div class="bookingPreviewControl__nextStepHeader__leadState__text">
        {{ 'BookingPreview.Label.BookedLeadState' | texttransform }}
    </div>
    <div class="bookingPreviewControl__nextStepHeader__leadState__name">
        {{ result?.leadStatus?.lookupTable?.defaultName | texttransform }}
    </div>     
</div>
<div class="bookingPreviewControl__nextStepHeader" *ngIf="resultId == null">
    {{ getStepHeader('BookingPreview.Label.NextStepHeader') | texttransform }}
</div>
<div class="bookingPreviewControl__nextStepHeader" *ngIf="step == null && result != null">
    {{ 'BookingPreview.Label.ResultClosesWorkflow' | texttransform }}
</div>

<div class="bookingPreviewControl__wrapper" *ngIf="step != null">   
    <div class="bookingPreviewControl__nextStepHeader__layer" *ngIf="layerNumber > 0">
        {{ 'BookingPreview.Label.LayerNumber' | texttransform }}: {{ layerNumber }} - {{ result.defaultName | texttransform }}
    </div>

    <div class="bookingPreviewControl__nextStepHeader__step">
        {{ 'Task.Label.Step' | texttransform }}: {{ step.defaultName  | texttransform }}
    </div>
    <div class="bookingPreviewControl__nextStepResults">
        <div class="bookingPreviewControl__nextStepResults__header">
            {{ 'BookingPreview.Label.PossibleResults' | texttransform }}
        </div>        
        <div class="bookingPreviewControl__nextStepResults__item fingerCursor" *ngFor="let result of step.results; let indexOfelement=index;"
            [ngClass]="{ hideInDom: (indexOfelement > 2 && !showAll) || result.isActive == false }" (click)="showFollowUpStep(result)">
            <div class="bookingPreviewControl__nextStepResults__item__resultName" [title]="result.defaultName | texttransform">                
                <i class="fas fa-envelope fingerCursor" (click)="showPreview(result);$event.stopPropagation()" 
                    [title]="'BookingPreview.Label.ShowPreview' | texttransform"
                    *ngIf="showEmailPreview && result.emailTemplateId != null"></i>
                {{ result.defaultName | texttransform }}
            </div>   
            <div class="bookingPreviewControl__nextStepResults__item__arrow">

            </div>         
            <div class="bookingPreviewControl__nextStepResults__item__resultFollowUp" [ngClass]="{ 
                bookingPreviewControl__nextStepResults__item__resultFollowUp__active: result.nextStepId != null,
                bookingPreviewControl__nextStepResults__item__resultFollowUp__active__selected: result.id == followUpResultId
             }" [title]="getFollowUpStepName(result)">
                <div class="bookingPreviewControl__nextStepResults__item__resultFollowUp__inner">
                    {{ getFollowUpStepName(result) | texttransform }}
                </div>                
            </div>
        </div>

        <div class="bookingPreviewControl__nextStepResults__item" *ngIf="!showAll && step.results.length > 2">
            <div class="bookingPreviewControl__nextStepResults__item__resultName">...</div>   
            <div class="bookingPreviewControl__nextStepResults__item__arrow"></div>     
            <div class="bookingPreviewControl__nextStepResults__item__resultFollowUp"></div>    
        </div>

        <div class="bookingPreviewControl__nextStepResults__more" *ngIf="step.results.length > 2" (click)="showAll = !showAll">
            <span *ngIf="showAll">{{ 'Timeline.Label.Less' | texttransform }}</span>
            <span *ngIf="!showAll">{{ 'Timeline.Label.More' | texttransform }}</span>
        </div>
    </div>
</div>

<app-booking-preview 
    [resultId]="followUpResultId" 
    [showEmailPreview]="showEmailPreview" 
    [layerNumber]="layerNumber + 1" 
    *ngIf="followUpResultId != null"
></app-booking-preview>