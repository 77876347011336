<div class="cloudConnectionWrapper" *ngIf="!userService.hasPermission('CanReadCloudConnection')">
    {{ 'general.Label.NoPermission' | texttransform }}
</div>

<div class="cloudConnectionWrapper">
    <!-- Header -->
    <div class="projectSettings__header" *ngIf="selectedConnection == null">
        <div class="projectSettings__header__newItem fingerCursor"
            *ngIf="userService.hasPermission('CanCreateCloudConnection')" (click)="createNewCloudConnection()">
            <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
             [tippy]="'CloudConnection.Label.New' | texttransform"
             [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"
                class="fingerCursor"></svg-icon>
            {{ 'CloudConnection.Label.New' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg"
                    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="cloudConnectionDataItem__line" *ngIf="selectedConnection != null">
        <button class="everleadCustomButton" (click)="selectedConnection = null">{{ 'General.Label.Back' | texttransform }}</button>
        <button class="everleadCustomButton" [disabled]="checkIfDisabled()" (click)="updateCloudConnection()">{{ 'CloudConnection.Label.UpdateConnection' | texttransform }}</button>        
    </div>

    <!-- Liste -->
    <div *ngIf="userService.hasPermission('CanReadCloudConnection')">
        <div class="cloudConnectionData" *ngIf="selectedConnection == null && createNewConnection == false" id="cloudConnectionList">
            <div class="cloudConnectionDataItem everleadShadowBox" *ngFor="let cloudConnection of cloudConnections"
                [ngClass]="{
                    hideInDom: checkIfItemIsFiltered(cloudConnection) || cloudConnection.connectionTypeLookupId == 8185,
                    fingerCursor: userService.hasPermission('CanUpdateCloudConnection'),
                    isInactive: cloudConnection.isActive == false
                }" (click)="selectConnection(cloudConnection)"
            >
                <div class="cloudConnectionDataItem__line">
                    <div class="cloudConnectionDataItem__line__id everleadCoral">{{ cloudConnection.id }}</div>
                    <div class="cloudConnectionDataItem__line__name">{{ cloudConnection.defaultName }}</div>
                    <div class="cloudConnectionDataItem__line__buttons" (click)="$event.stopPropagation()"
                        *ngIf="cloudConnection.connectionTypeLookupId == 7942">
                        <i class="fas fa-vial" (click)="testConnection(cloudConnection)" [tippy]="'CloudConnection.Label.TestConnection' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
                        <i class="fas fa-sign-in-alt" (click)="getSMTPMsoCode(cloudConnection.id)" [tippy]="'CloudConnection.Label.SMTPMsoCODE' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Edit -->
    <div class="cloudConnectionData" *ngIf="userService.hasPermission('CanUpdateCloudConnection') &&
                                        selectedConnection != null 
                                        && createNewConnection == false" id="cloudConnectionEdit">
        <div class="cloudConnectionDataItem__dataline">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionName' | texttransform }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <input [(ngModel)]="selectedConnection.defaultName">
            </div>
        </div>

        <div class="cloudConnectionDataItem__dataline">
            <div class="cloudConnectionDataItem__dataline__label">
                <input [(ngModel)]="selectedConnection.isActive" type="checkbox"> {{ 'CloudConnection.Label.IsActive' | texttransform }}
            </div>
            <div class="cloudConnectionDataItem__dataline__label">
                <input [(ngModel)]="connectionSettingsJson.AutoContactAssignment" type="checkbox"> {{ 'CloudConnection.Label.AutoContactAssignment' | texttransform }}
            </div>
            <div class="cloudConnectionDataItem__dataline__label">
                <input [(ngModel)]="connectionSettingsJson.MoveSynchronizedMails" type="checkbox"> {{ 'CloudConnection.Label.MoveSynchronizedMails' | texttransform }}
            </div>
            <div class="cloudConnectionDataItem__dataline__label">
                <input [(ngModel)]="connectionSettingsJson.DeleteSynchronizedMails" type="checkbox"> {{ 'CloudConnection.Label.DeleteSynchronizedMails' | texttransform }}
            </div>            
            <div class="cloudConnectionDataItem__dataline__label isDeveloper" *ngIf="isCustomerMode == false">
                <input [(ngModel)]="connectionSettingsJson.AutoContactCreation" type="checkbox"> {{ 'CloudConnection.Label.AutoContactCreation' | texttransform }}
            </div>      
        </div>
        
        <!-- Connectiondata -->
        <div class="cloudConnectionDataItem__dataline__value everleadShadowBox everleadShadowBox2" *ngIf="connectionDataJson != null">
            <div class="cloudConnectionDataItemAuthHeader">{{ 'CloudConnection.Label.AuthorityHeader' | texttransform }}</div>
            <div class="cloudConnectionDataItemAuthDescription">{{ 'CloudConnection.Label.AuthorityDesc' | texttransform }}</div>
            <div class="cloudConnectionDataItemAuthDescription__line">
                <label>{{ 'CloudConnection.Label.Authority' | texttransform }}</label> 
                <input [(ngModel)]="connectionDataJson.Authority">
            </div>
            <div class="cloudConnectionDataItemAuthDescription__line">
                <label>{{ 'CloudConnection.Label.ClientId' | texttransform }}</label>
                <input [(ngModel)]="connectionDataJson.ClientId">
            </div>
            <div class="cloudConnectionDataItemAuthDescription__line">
                <label>{{ 'CloudConnection.Label.ClientSecret' | texttransform }}</label>
                <input [(ngModel)]="connectionDataJson.ClientSecret" type="password">
            </div>
        </div>

        <!-- Settings -->
        <div class="cloudConnectionDataItem__dataline everleadShadowBox everleadShadowBox2" *ngIf="connectionSettingsJson != null">
            <div class="cloudConnectionDataItemAuthHeader">{{ 'CloudConnection.Label.Settings' | texttransform }}</div>
            <div class="cloudConnectionDataItemAuthDescription__line">
                <label>{{ 'CloudConnection.Label.Email' | texttransform }}</label> 
                <input [(ngModel)]="connectionSettingsJson.Email">
            </div>
            <div class="cloudConnectionDataItemAuthDescription__line">
                <label>{{ 'CloudConnection.Label.DisplayName' | texttransform }}</label>
                <input [(ngModel)]="connectionSettingsJson.DisplayName">
            </div>                  
        </div>
        
        <div class="cloudConnectionDataItem__dataline isDeveloper" *ngIf="applicationInfoService.isDeveloper">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionProject' | texttransform }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <select class="cloudConnectionType__select" [(ngModel)]="selectedConnection.projectId"
                    (change)="updateCloudConnection(selectedConnection)">
                    <option [ngValue]=null></option>
                    <option *ngFor="let project of projects" [ngValue]="project.project.id">{{ project.project.projectName |texttransform }}</option>
                </select>
            </div>
        </div>

        <div class="userManagement__wrapper">
            <div class="userManagement__left">
                <div class="userManagement__sectionHeader">
                    <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}
                    </div>
                </div>
                <div id="userManagementUserList" class="userManagement__itemContainer">
                    <div id="userWrapper" *ngFor="let user of availableUsers">
                        <div class="userManagement__item__container fingerCursor">
                            <div class="userManagement__item__name ellipsisText">{{ user.user?.userName }}</div>
                            <div class="userManagement__item__move">
                                <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [tippy]="'General.User.AddUser' | texttransform" (click)="addUser(user)"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="userManagement__right">
                <div class="userManagement__sectionHeader">
                    <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' | texttransform }}
                    </div>
                </div>
                <div id="userManagementUserList" class="userManagement__itemContainer">
                    <div id="userWrapper" *ngFor="let user of assignedUsers">
                        <div class="userManagement__item__container fingerCursor">
                            <div class="userManagement__item__name ellipsisText">{{ user.user?.userName }}</div>
                            <div class="userManagement__item__move">
                                <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                                    [tippy]="'General.User.RemoveUser' | texttransform"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    (click)="removeUser(user)"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="cloudConnectionWrapper" *ngIf="!userService.hasPermission('CanReadCloudConnection')">
    {{ 'general.Label.NoPermission' | texttransform }}
</div>

<div class="cloudConnectionWrapper">
    <div class="projectSettings__header">
        <div class="projectSettings__header__newItem fingerCursor"
            *ngIf="userService.hasPermission('CanCreateCloudConnection')" (click)="createConnection()">
            <svg-icon src="assets/images/svg/icon-newitem-wizard.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                class="fingerCursor"></svg-icon>
            {{ 'CloudConnection.Label.New' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg"
                    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div *ngIf="userService.hasPermission('CanReadCloudConnection') && isCustomerMode == false">
        <div class="cloudConnectionType" *ngIf="selectedConnection == null && createNewConnection == false">
            <div class="cloudConnectionType__label">{{ 'CloudConnection.Label.ConnectionType' | texttransform }}</div>
            <select class="cloudConnectionType__select" [(ngModel)]="selectedConnectionType"
                (click)="$event.stopPropagation()">
                <option [ngValue]=null>{{ 'CloudConnection.Label.ConnectionAll' | texttransform }}</option>
                <option *ngFor="let connectionType of connectionTypes" [ngValue]="connectionType.id">{{
                    connectionType.defaultName | texttransform }}</option>
            </select>
        </div>

        <div class="cloudConnectionType" *ngIf="selectedConnection != null && createNewConnection == false">
            <button class="everleadCustomButton" (click)="selectedConnection = null">{{
                'CloudConnection.Label.BacktoList' |
                texttransform }}</button>
        </div>

        <div class="cloudConnectionData" *ngIf="selectedConnection == null && createNewConnection == false"
            id="cloudConnectionList">
            <div class="cloudConnectionDataItem everleadShadowBox" *ngFor="let cloudConnection of cloudConnections"
                [ngClass]="{
            hideInDom: checkIfItemIsFiltered(cloudConnection),
            fingerCursor: userService.hasPermission('CanUpdateCloudConnection')
        }" (click)="selectConnection(cloudConnection)">
                <div class="cloudConnectionDataItem__line">
                    <div class="cloudConnectionDataItem__line__id everleadCoral">{{ cloudConnection.id }}</div>
                    <div class="cloudConnectionDataItem__line__name">{{ cloudConnection.defaultName }}</div>
                    <div class="cloudConnectionDataItem__line__buttons" (click)="$event.stopPropagation()"
                        *ngIf="cloudConnection.connectionTypeLookupId == 7942">
                        <i class="fas fa-vial" (click)="testConnection(cloudConnection)"></i>
                        <i class="fas fa-sign-in-alt" (click)="getSMTPMsoCode(cloudConnection.id)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>





    <div *ngIf="userService.hasPermission('CanReadCloudConnection') && 
                                           isCustomerMode == true &&
                                           selectedConnection == null && 
                                           createNewConnection == false
">
        <div class="cloudConnectionDataItem everleadShadowBox" *ngFor="let cloudConnection of cloudConnections"
            [ngClass]="{
        hideInDom: checkIfItemIsFiltered(cloudConnection),
        fingerCursor: userService.hasPermission('CanUpdateCloudConnection')
    }" (click)="selectConnection(cloudConnection)">
            <div class="cloudConnectionDataItem__line">
                <div class="cloudConnectionDataItem__line__id everleadCoral">{{ cloudConnection.id }}</div>
                <div class="cloudConnectionDataItem__line__name">{{ cloudConnection.defaultName }}</div>
                <div class="cloudConnectionDataItem__line__buttons" (click)="$event.stopPropagation()"
                    *ngIf="cloudConnection.connectionTypeLookupId == 7942">
                    <button class="everleadCustomButton" (click)="testConnection(cloudConnection)">{{
                        'CloudConnection.Label.TestConnection' | texttransform }}</button>
                    <button class="everleadCustomButton" (click)="getSMTPMsoCode(cloudConnection.id)">{{
                        'CloudConnection.Label.Login' | texttransform }}</button>
                </div>
            </div>
        </div>
    </div>


    <div class="cloudConnectionData" *ngIf="createNewConnection == true" id="cloudConnectionEdit">
        <div class="cloudConnectionDataItem__dataline">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionName' |
                texttransform
                }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <input [(ngModel)]="createNewConnectionName">
            </div>
        </div>
        <div class="cloudConnectionDataItem__dataline" *ngIf="isCustomerMode == false">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionType' |
                texttransform
                }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <select class="cloudConnectionType__select" [(ngModel)]="createNewConnectionType"
                    (click)="$event.stopPropagation()">
                    <option [ngValue]=null></option>
                    <option *ngFor="let connectionType of connectionTypes" [ngValue]="connectionType.id">{{
                        connectionType.defaultName | texttransform }}</option>
                </select>
            </div>
        </div>
        <div class="cloudConnectionDataItem__dataline" *ngIf="createNewConnectionType == 7942">
            <div *ngIf="isCustomerMode == false">
                <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.Provider' | texttransform }}
                </div>
                <div class="cloudConnectionDataItem__dataline__value">
                    <select class="cloudConnectionType__select" [(ngModel)]="createNewConnectionProvider">
                        <option *ngFor="let provider of providers" [ngValue]="provider.id">{{ provider.defaultName |
                            texttransform }}</option>
                    </select>
                </div>
            </div>
            <div class="cloudConnectionDataItem__dataline__value " [ngClass]="{
                    everleadShadowBox: isCustomerMode== false,
                    everleadShadowBox2: isCustomerMode==false
                }"
                *ngIf="createNewConnectionProvider == 7944">
                <div class="cloudConnectionDataItemAuthHeader">{{ 'CloudConnection.Label.AuthorityHeader' |
                    texttransform }}
                </div>
                <div class="cloudConnectionDataItemAuthDescription">{{ 'CloudConnection.Label.AuthorityDesc' |
                    texttransform
                    }}</div>
                <div>{{ 'CloudConnection.Label.Authority' | texttransform }} <input
                        [(ngModel)]="createNewConnectionOAuthObject.Authority"></div>
                <div>{{ 'CloudConnection.Label.ClientId' | texttransform }} <input
                        [(ngModel)]="createNewConnectionOAuthObject.ClientId"></div>
                <div>{{ 'CloudConnection.Label.ClientSecret' | texttransform }} <input
                        [(ngModel)]="createNewConnectionOAuthObject.ClientSecret" type="password"></div>
            </div>
            <div class="cloudConnectionDataItem__dataline__value">
                <div class="cloudConnectionDataItemAuthHeader">{{ 'CloudConnection.Label.Settings' | texttransform }}
                </div>
                <div>{{ 'CloudConnection.Label.Email' | texttransform }} <input
                        [(ngModel)]="createNewConnectionSettings.Email"></div>
                <div>{{ 'CloudConnection.Label.DisplayName' | texttransform }} <input
                        [(ngModel)]="createNewConnectionSettings.DisplayName"></div>
                <div class="cloudConnectionDataItem__dataline__value__checkbox">
                    <input [(ngModel)]="createNewConnectionSettings.AutoContactCreation" type="checkbox">
                    {{ 'CloudConnection.Label.AutoContactCreation' | texttransform }}
                </div>
                <div class="cloudConnectionDataItem__dataline__value__checkbox" *ngIf="isCustomerMode == false">
                    <input [(ngModel)]="createNewConnectionSettings.AutoContactAssignment" type="checkbox">
                    {{ 'CloudConnection.Label.AutoContactAssignment' | texttransform }}
                </div>
            </div>
        </div>

        <div class="cloudConnectionDataItem__line">
            <button class="everleadCustomButton" [disabled]="checkIfDisabled()" (click)="createNewCloudConnection()">
                {{ 'CloudConnection.Label.CreateConnection' | texttransform }}
            </button>
            <button class="everleadCustomButton" (click)="createNewConnection = false">
                {{ 'CloudConnection.Label.CancelCreation' | texttransform }}
            </button>
        </div>


    </div>

    <div class="cloudConnectionData" *ngIf="userService.hasPermission('CanUpdateCloudConnection') &&
                                        selectedConnection != null 
                                        && createNewConnection == false" id="cloudConnectionEdit">
        <div class="cloudConnectionDataItem__dataline">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionName' |
                texttransform
                }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <input [(ngModel)]="selectedConnection.defaultName"
                    (change)="updateCloudConnection(selectedConnection)">
            </div>
        </div>

        <div class="cloudConnectionDataItem__dataline">
            <div class="cloudConnectionDataItem__dataline__label">
                <input [(ngModel)]="selectedConnection.isActive" type="checkbox"
                    (change)="updateCloudConnection(selectedConnection)">
                {{ 'CloudConnection.Label.IsActive' | texttransform }}
            </div>
        </div>
        <div class="cloudConnectionDataItem__dataline">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionSettings' |
                texttransform }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <textarea [(ngModel)]="selectedConnection.connectionSettings"
                    (change)="updateCloudConnection(selectedConnection)"></textarea>
            </div>
        </div>

        <div class="cloudConnectionDataItem__dataline isDeveloper" *ngIf="applicationInfoService.isDeveloper">
            <div class="cloudConnectionDataItem__dataline__label">{{ 'CloudConnection.Label.ConnectionProject' |
                texttransform }}</div>
            <div class="cloudConnectionDataItem__dataline__value">
                <select class="cloudConnectionType__select" [(ngModel)]="selectedConnection.projectId"
                    (change)="updateCloudConnection(selectedConnection)">
                    <option [ngValue]=null></option>
                    <option *ngFor="let project of projects" [ngValue]="project.project.id">{{
                        project.project.projectName |
                        texttransform }}</option>
                </select>
            </div>
        </div>

        <div class="userManagement__wrapper">
            <div class="userManagement__left">
                <div class="userManagement__sectionHeader">
                    <div class="userManagement__sectionHeader__label">{{ 'General.User.AvailableUser' | texttransform }}
                    </div>
                </div>
                <div id="userManagementUserList" class="userManagement__itemContainer">
                    <div id="userWrapper" *ngFor="let user of availableUsers">
                        <div class="userManagement__item__container fingerCursor">
                            <div class="userManagement__item__name ellipsisText">{{ user.user?.userName }}</div>
                            <div class="userManagement__item__move">
                                <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" (click)="addUser(user)"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="userManagement__right">
                <div class="userManagement__sectionHeader">
                    <div class="userManagement__sectionHeader__label">{{ 'General.User.AssignedUsers' | texttransform }}
                    </div>
                </div>
                <div id="userManagementUserList" class="userManagement__itemContainer">
                    <div id="userWrapper" *ngFor="let user of assignedUsers">
                        <div class="userManagement__item__container fingerCursor">
                            <div class="userManagement__item__name ellipsisText">{{ user.user?.userName }}</div>
                            <div class="userManagement__item__move">
                                <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                    (click)="removeUser(user)"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->
