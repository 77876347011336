<label *ngIf="applicationInfoService.projectID == 164">
    {{'SMTPSettings.Label.DemoversionValues'| texttransform}}
</label>

<div class="smtpSettingsCloudConnection everleadShadowBox everleadShadowBox2">
    <div class="smtpSettingsCloudConnection__label">
        {{ 'SMTPSettings.Label.CloudConnections'| texttransform }}
    </div>
    <div class="smtpSettingsCloudConnection__value"></div>
    <select [(ngModel)]="selectedCloudConnection" *ngIf="syncingMailbox==false">
        <option *ngFor="let cloudConnection of cloudConnections" [ngValue]="cloudConnection.id">{{
            cloudConnection.defaultName }}</option>
    </select>
    <div *ngIf="syncingMailbox==true" class="smtpSettingsCloudConnectionSync">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'SMTPSettings.Label.SyncingMailbox'|
        texttransform }}
    </div>
    <div class="mailboxButtons">
        <i class="fas fa-sync fingerCursor" (click)="syncMailbox()" *ngIf="syncingMailbox==false"></i>
        <svg-icon src="assets/images/svg/Posteingang.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
            class="fingerCursor" 
            [tippy]="'SMTPSettings.Label.IncomingMails' | texttransform"
            [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"
            [ngClass]="{ everleadCoral: mode == 2 }" (click)="changeMode(2)">
        </svg-icon>
        <svg-icon src="assets/images/svg/Postausgang.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
            class="fingerCursor" [tippy]="'SMTPSettings.Label.OutgoingMails' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"
            [ngClass]="{ everleadCoral: mode == 3 }" (click)="changeMode(3)">
        </svg-icon>
    </div>
</div>

<div [innerHTML]="innerHTMLEMailContent"></div>

<div *ngIf="selectedCloudConnection != null">
    <div *ngIf="(mode == 2 || mode == 3) && emails != null">
        <div *ngIf="(mode == 2 || mode == 3)" class="smtpSettingsFilter">
            <!-- Filter & Co -->
            <div class="smtpSettingsFilter__item">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg"
                    [svgStyle]="{ 'width.px': 16, 'height.px': 15 }"></svg-icon>
                <input [(ngModel)]="filterString">
            </div>
            <div class=" maxWidth"></div>
            <div class="smtpSettingsFilter__item">
                <label>{{ 'SMTPSettings.Label.OrderBy' | texttransform }}</label>
                <select [(ngModel)]="orderId" (change)="newEMailSearch()">
                    <option [ngValue]=1>{{ 'SMTPSettings.Label.OrderByDate' | texttransform }} <i
                            class="fas fa-sort-up"></i></option>
                    <option [ngValue]=2 *ngIf="mode == 2">{{ 'SMTPSettings.Label.OrderBySender' | texttransform }} <i
                            class="fas fa-sort-up"></i></option>
                    <option [ngValue]=3 *ngIf="mode == 3">{{ 'SMTPSettings.Label.OrderByReceiver' | texttransform }} <i
                            class="fas fa-sort-up"></i></option>
                </select>
            </div>
            <div class="smtpSettingsFilter__item">
                <i class="fas fa.filter"></i>
                <label>{{ 'SMTPSettings.Label.Filter' | texttransform }}</label>
                <select [(ngModel)]="filterId" (change)="newEMailSearch()">
                    <option [ngValue]=null></option>
                    <option [ngValue]=3>{{ 'SMTPSettings.Label.OnlyWithContact' | texttransform }}</option>
                    <option [ngValue]=4>{{ 'SMTPSettings.Label.OnlyWithAttachments' | texttransform }}</option>
                </select>
            </div>
        </div>
        <span id="projectSettingsDesignSpan"></span>
        <div class="userMessages__wrapper autoOverflowY" [style.height]="getControlHeight()">
            <div *ngIf="emails.length == 0" class="maxWidth">
                {{ 'UserMailBox.Label.NoMessagesAvailable' | texttransform }}
            </div>
            <div class="userMessages__navigation" *ngIf="emails.length > 0" [style.width.px]="emailListWidth"
                [style.min-width.px]="emailListWidth">
                <div class="mailItem__header" (scroll)="onScroll($event)">
                    <div class="everleadShadowBox fingerCursor mailItem" *ngFor="let mail of emails"
                        (click)="renderContent(mail)"
                        [ngClass]="{ selected: mail.id == selectedMail?.id, hideInDom: itemIsFiltered(mail) }">

                        <div>
                            <div class="everleadSmallHeader maxWidth userMessages__header">
                                <div class="d-flex maxWidth ">
                                    <div class="userMessageContact">
                                        <div class="userMessages__header__from ellipsisText" 
                                        [tippy]="mail._1130_from"
                                        [tippyOptions]="{placement:'top'}"
                                        [ngClass]="{ fromReducedLength: mail._1130_hasattachements == true }" *ngIf="mode == 2">{{
                                        mail._1130_from }}</div>

                                    <div class="userMessages__header__from ellipsisText" 
                                        [tippy]="mail._1130_to"
                                        [tippyOptions]="{placement:'top'}"
                                        [ngClass]="{ fromReducedLength: mail._1130_hasattachements == true }" *ngIf="mode == 3">{{
                                        mail._1130_to }}</div>
                                    </div>
                               
                                    <div class="userMessageButtonBar">
                                        <div class="mailItem__user" *ngIf="mail._1130_contactid != null">
                                            <svg-icon src="assets/images/svg/icon_campaign_parent.svg" class="fingerCursor"
                                                [tippy]="'MicroCampaign.Label.StartMicroCampaign' | texttransform"
                                                [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"
                                                [svgStyle]="{ 'width.px': 16, 'height.px': 15 }"
                                                (click)="createContact(mail)"></svg-icon>
                                        </div>
                                        <div class="mailItem__user mailItem__user__attachment"
                                            *ngIf="mail._1130_hasattachements == true">
                                            <i class="fa-sm fas fa-paperclip"></i>
                                        </div>
        
                                        <div class="mailItem__user mailItem__user__interaction">
                                            <i class="fas fa-eye fingerCursor" *ngIf="mail._1130_contactid != null && mail._4_isdeleted == false" [ngClass]="{
                                                everleadCoral: mail._1130_isvisible==true 
                                            }"
                                            (click)="toggleVisibility(mail)" 
                                            [tippy]="'SMTPSettings.Label.ShowInActivity' | texttransform" 
                                            [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"></i>
                            
                                            <svg-icon src="assets/images/svg/account-plus.svg"
                                                [tippy]="'SMTPSettings.Label.ConvertToContact' | texttransform"
                                                [tippyOptions]="{theme: 'everleadBlue', placement:'bottom'}"
                                                (click)="createContact(mail)"
                                                *ngIf="mail._1130_contactid == null"
                                                [svgStyle]="{ 'width.px': 16, 'height.px': 15 }"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="userMessages__navigation__time everleadCoral" [tippy]="mail?._1130_time | date: 'medium'" [tippyOptions]="{theme:'everleadRed'}">{{ mail?._1130_time | date: 'medium' }}
                            </div>
                        </div>
                        <div class="jjBold everleadBlueColor ellipsisText" [tippy]="mail?._1130_subject" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}">{{ mail?._1130_subject }}
                        </div>
                    </div>
                </div>
                <div class="grabber" id="grabber"></div>
            </div>
            <div class="userMessages__content everleadShadowBox" *ngIf="emails.length > 0"
                [ngClass]="{ userConnected: selectedMail._1130_contactid != null }">
                <div class="userMessages__content__subject d-flex" *ngIf="selectedMail">
                    <div class="mailHeaderWrapper__left">
                        <div class="maxWidth">
                            <div>
                                <div class="d-flex">
                                    <div class="jjBold maxWidth">{{ selectedMail?._1130_subject }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row mailHeader">
                            <div class="maxWidth smallText" *ngIf="mode == 2">
                                {{ selectedMail?._1130_from }}
                            </div>
                            <div class="maxWidth smallText" *ngIf="mode == 3">
                                <i class="fas fa-arrow-alt-circle-right"></i> {{ selectedMail?._1130_to }}
                            </div>
                            <div class="maxWidth smallText flex-justify-flexend d-flex">
                                {{ selectedMail?._1130_time | date: 'medium' }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mailItem__user2"
                            *ngIf="selectedMail._1130_contactid != null && selectedMail._1130_conversionid == null">
                            <svg-icon src="assets/images/svg/iconset/03icon-conversion.svg" class="fingerCursor"
                                [tippy]="'SMTPSettings.Label.CreateConversion' | texttransform"
                                [tippyOptions]="{ theme:'everleadBlack', placement:'bottom'}"
                                [svgStyle]="{ 'width.px': 22, 'height.px': 18 }"
                                (click)="createConversion(selectedMail);$event.stopPropagation()"></svg-icon>
                        </div>
                        <div class="mailItem__user2 mailItem__user__attachment"
                            *ngIf="selectedMail._1130_hasattachements == true">
                            <i class="fa-sm fas fa-paperclip"></i>
                        </div>
                        <div class="mailItem__user2 mailItem__user__interaction">
                            <svg-icon src="assets/images/svg/iconset/06icon-kontakte.svg"
                                class="everleadGreenColor fingerCursor" 
                                [tippy]="getContactTitle(selectedMail)"
                                [tippyOptions]="{ theme:'everleadBlack', placement:'bottom'}"
                                *ngIf="selectedMail._1130_contactid != null && selectedMail._4_isdeleted == false"
                                [svgStyle]="{ 'width.px': 22, 'height.px': 18 }"
                                (click)="loadContact(selectedMail._1130_contactid);$event.stopPropagation()"></svg-icon>
                            <svg-icon src="assets/images/svg/iconset/06icon-kontakte.svg" class="everleadCoral"
                                [tippy]="'SMTPSettings.Label.DeletedContact' | texttransform"
                                [tippyOptions]="{ theme:'everleadBlack', placement:'bottom'}"
                                *ngIf="selectedMail._1130_contactid != null && selectedMail._4_isdeleted == true"
                                [svgStyle]="{ 'width.px': 22, 'height.px': 18 }"></svg-icon>

                            <svg-icon src="assets/images/svg/account-plus.svg" class="fingerCursor"
                                [tippy]="'SMTPSettings.Label.ConvertToContact' | texttransform"
                                [tippyOptions]="{ theme:'everleadBlack', placement:'bottom'}"
                                (click)="createContact(selectedMail);$event.stopPropagation()"
                                *ngIf="selectedMail._1130_contactid == null"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 15 }"></svg-icon>
                        </div>
                    </div>


                </div>
                <div *ngIf="selectedMail._1130_contactid != null && selectedMail._4_isdeleted == false"
                    class="userDetails everleadCoralBackground">
                    <div class="userDetails__icon everleadCoralBackground">
                        <svg-icon src="assets/images/svg/iconset/06icon-kontakte.svg"
                            [svgStyle]="{ 'width.px': 16, 'height.px': 15 }"></svg-icon>
                    </div>
                    <label>{{ 'SMTPSettings.Label.ConnectedContact' | texttransform }}: {{
                        selectedMail?._4_firstname }} {{ selectedMail?._4_lastname }}</label>
                    <div (click)="loadContact(selectedMail._1130_contactid);$event.stopPropagation()"><i
                            class="fas fa-external-link-alt fingerCursor"></i></div>
                </div>
                <div *ngIf="selectedMail._1130_hasattachements != null" class="attachments">
                    <div *ngFor="let attachment of attachments" class="attachments__item fingerCursor"
                        (click)="downloadAttachment(attachment);$event.stopPropagation()">
                        <i class="fas fa-file"></i>
                            {{ getAttachmentFileName(attachment.filename) }} 
                            ({{ commonService.getAttachmentSizeStringFromAttachmantSize(attachment.size) }})
                    </div>
                </div>
                <div class="userMessages__content__body" *ngIf="selectedMail">
                    <div [innerHTML]="innerHTML"></div>
                </div>
            </div>
        </div>
    </div>
</div>