import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-admin-scoringcontenttype',
  templateUrl: './admin-scoringcontenttype.component.html',
  styleUrls: ['./admin-scoringcontenttype.component.scss']
})
export class AdminScoringcontenttypeComponent implements OnInit {
  dataArray = [];
  displayedColumns = ['id', 'type', 'points', 'subUrl', 'eventTypeId', 'additionalCriteria', 'delete'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(633)
    .then(getDataResult => {
      this.dataArray = getDataResult;
      this.dataSource = new MatTableDataSource(getDataResult);
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  updateData(item)  {
    this.externaldatasourceService.executeExternalDataSource(635, [
      this.commonService.getModifyArrayBody(item, [])
    ])
    .then(() => { })
    .catch(() => { });
  }

  deleteData(item) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(636, [item.id])
    .then(() => {
      this.getData();
      this.loaderService.display(false);
    })
    .catch(() => { this.loaderService.display(false); });
  }

  createData() {
    const item = new Object();
    item['type'] = 'type';
    item['points'] = 10;
    item['subUrl'] = null;
    item['eventTypeId'] = null;
    item['additionalCriteria'] = null;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(634, [
      this.commonService.getModifyArrayBody(item, [])
    ])
    .then(() => {
      this.getData();
      this.loaderService.display(false);
    })
    .catch(() => { this.loaderService.display(false); });
  }
}
