import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from './service/event.service';
import { ApplicationInfoService } from '../core/application/application-info.service';

@Component({
  selector: 'app-jollyjupiter',
  templateUrl: './jollyjupiter.component.html',
  styleUrls: ['./jollyjupiter.component.scss']
})
export class JollyjupiterComponent implements OnInit {
  controlID = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    public applicationInfoService: ApplicationInfoService,
  ) {
    this.activatedRoute.params.subscribe( (params) => {
      if (!this.applicationInfoService.applicationInitialized) {
        if (this.applicationInfoService.redirectTo === '') {
          this.applicationInfoService.redirectTo = this.applicationInfoService.mainFrameName + '/' + params['id'];
        }
        // console.log('redirectTo (jjc):', applicationInfoService.redirectTo);
        this.router.navigate(['../init']);
      } else {
        setTimeout(() => { this.eventService.loadNewContainerContent('jjsection', params['id']); }, 100);
      }
     });
   }

  ngOnInit() {
  }

}
