<div class="newSesItem">
    <input [(ngModel)]="newSesName">
    <div class="d-flex">
        <button class="everleadCustomButton" (click)="createSesEntry()" [disabled]="newSesName.length < 4">SES Config anlegen</button>
        <button class="everleadCustomButton" (click)="createSesEntry(true)" [disabled]="newSesName.length < 4">SES Batch Config anlegen</button>
    </div>
</div>
<div>
    Bestehende Einträge
</div>
<div class="everleadShadowBox" *ngFor="let sesListItem of sesList">
    {{ sesListItem.defaultName }}
</div>