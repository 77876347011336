<div class="largeMenuDesign">
    <div *ngIf="applicationInfoService.designerMode == true" class="designPanel" style="padding:0px; margin: 0px; width: 100%" (click)="getMenuInfo()">        
        controlId: {{ menuitem.id }} 
    </div>
  <span (click)="callMethod(menuitem)" [ngClass]="{ fingerCursor: menuitem.methods.length > 0 }"
      *ngIf="userService.getControlPermissionByType(menuitem.uiControlSecurityPermissions, true, 'visible')==true  && menuitem.isActive"
    >
    <i class="{{ menuitem.icon }} largeMenuPicture"></i>
    <label class="largeMenuHeaderLabel">{{ menuitem?.displayText | texttransform }}</label>
    <br>
    <div *ngFor="let child of menuitem?.childs">
      <span *ngIf="userService.getControlPermissionByType(child.uiControlSecurityPermissions, true, 'visible')==true && child.isActive"
        (click)="callMethod(child)" [ngClass]="{ fingerCursor: child.methods.length > 0,
          highlightMenuItem: child?.logicalControlId == applicationInfoService.highlightedMenuItem?.logicalControlId
      }">
        <i class="{{ child.icon }} largeMenuPicture"></i>
        {{ child?.displayText | texttransform }}<br>


        <div *ngFor="let subchild1 of child?.childs">
          <span
          *ngIf="userService.getControlPermissionByType(subchild1.uiControlSecurityPermissions, true, 'visible')==true && subchild1.isActive"
            (click)="callMethod(subchild1)" class="largeMenuSubChildLabel" [ngClass]="{ fingerCursor: subchild1.methods.length > 0,
            highlightMenuItem: subchild1?.logicalControlId == applicationInfoService.highlightedMenuItem?.logicalControlId
          }">
            <i class="{{ subchild1.icon }} largeMenuPicture"></i>
            {{subchild1.uiControlSecurityPermissions?.securityPermission?.name?.tokenName}}
            {{ subchild1?.displayText | texttransform }}<br>
          </span>
        </div>
      </span>
    </div>
  </span>

</div>