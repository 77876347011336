<div class="maxHeight htmlEditor__wrapper">
    <div class="everleadHorizontalFlex htmlEditor__header">
        <div class="maxWidth everleadHorizontalFlex">
            <div class="htmlEditor__header__item fingerCursor" (click)="wrapTextInTag('b')"><i class="fas fa-bold"></i></div>    
            <div class="htmlEditor__header__item fingerCursor" (click)="wrapTextInTag('i')"><i class="fas fa-italic"></i></div>    
            <div class="htmlEditor__header__item fingerCursor" (click)="wrapTextInTag('u')"><i class="fas fa-underline fa-sm"></i></div>    
            <div class="htmlEditor__header__item fingerCursor" onclick="document.getElementById('pictureFile').click();"><i class="fas fa-image fa-sm"></i></div>            
        </div>
        <div class="htmlEditor__header__isTemplate">
            <input type="checkbox" [(ngModel)]="isTemplate" (change)="changeValueEmitter()">{{ 'HTMLEditor.Label.IsTemplate' | texttransform }}
            <i class="fas fa-info-circle" [tippy]="'HTMLEditor.Label.IsTemplateDesc' | texttransform"></i>
        </div>
        <div class="htmlEditor__header__item fingerCursor" *ngIf="smtpSetting != null" (click)="sendTestMail()" 
            [title]="'HTMLEditor.Label.SendTestMail' | texttransform">
            <i class="fas fa-edit"></i>
        </div>        

        <div class="htmlEditor__header__item fingerCursor" *ngIf="mode==2" (click)="mode = 1" 
            [title]="'HTMLEditor.Label.ShowCode' | texttransform">
            <i class="fas fa-eye"></i>
        </div>        
        <div class="htmlEditor__header__item fingerCursor" *ngIf="mode==1" (click)="renderContent()" 
            [title]="'HTMLEditor.Label.ShowPreview' | texttransform">
            <i class="fas fa-code"></i>
        </div>
    </div>
    <div class="maxHeight" *ngIf="mode == 1">
        <textarea 
            id="templateContentEditor"
            #templateContentEditor
            [(ngModel)]="htmlText" 
            class="maxWidth htmlEditor__text" 
            (change)="changeValueEmitter()"
            (click)="getCaretPos(templateContentEditor)"
            (keyup)="getCaretPos(templateContentEditor)"
        ></textarea>
    </div>
    <div class="maxHeight htmlEditor__preview" *ngIf="mode == 2">
        <div [innerHTML]="innerHTML"></div>
    </div>
</div>
<input id="pictureFile" type="file" (change)="createBase64String($event)" style="display: none;">