import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LoaderService } from 'app/shared/service/loader-service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { isNullOrUndefined } from 'util';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-questionanswers-item',
  templateUrl: './questionanswers-item.component.html',
  styleUrls: ['./questionanswers-item.component.scss']
})
export class QuestionanswersItemComponent implements OnInit, OnDestroy {
  @Input() question = null;
  @Input() level = 0;
  @Input() dragDropIndex = 0;
  @Input() parentId = null;
  @Input() filterString = '';

  excludeItemsQuestion = ['answers'];
  questionBackup = null;
  designPaddingSize = 10;
  tempItemId = 0;
  translationSourceQuestion = 'question';
  translationSourceAnswer = 'answer';
  excludes = [];
  expandedQuestion = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private commonService: CommonService,
    private eventService: EventService,
    public jjtranslationService: JjtranslationService,
    public methodService: MethodService
  ) { }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.getQuestionInfo()
    .then(getQuestionInfoResult => {
      this.setQuestionArray(getQuestionInfoResult);
    });
  }

  getQuestionInfo(): Promise<any> {
    return new Promise((getResultQuestionsResolve, getResultQuestionsReject) => {
      if (isNullOrUndefined(this.question.id)) {
        getResultQuestionsResolve(this.question);
        return;
      }
      this.loaderService.display(true, false, 'getResultQuestions');
      this.externaldatasourceService.executeExternalDataSource(153, [this.question.id])
      .then(getQuestionInfoResult => {
        this.loaderService.display(false, false, 'getResultQuestions');
        this.question.answers = getQuestionInfoResult;
        getResultQuestionsResolve(this.question);
      })
      .catch(error => {
        this.loaderService.display(false, false, 'getResultQuestions');
        getResultQuestionsReject(error);
      });
    });
  }

  setQuestionArray(array: any) {
    this.question = array;
    this.questionBackup = JSON.parse(JSON.stringify(this.question));
  }

  getLeftPadding(additionalPadding = 0): number {
    return (this.level + additionalPadding) * this.designPaddingSize;
  }

  addAnswer() {
    const answer = new Object();
    answer['questionId'] = this.question.id;
    answer['order'] = this.question.answers.length + 1;
    answer['defaultName'] = this.axivasTranslateService.getTranslationTextForToken('QuestsionAnswers.Label.NewAnswerDefaultName');
    this.externaldatasourceService.executeExternalDataSource(157, [this.commonService.getModifyArrayBody(answer, [])])    
    .then(addAnswerResult => {
      answer['id'] = addAnswerResult.id;
      this.question.answers.push(answer);
    })
  }

  saveQuestion()  {
    this.externaldatasourceService.executeExternalDataSource(155, [this.commonService.getModifyArrayBody(this.question, this.excludeItemsQuestion)]);
  }

  getRequiredTranslationMutations(): Promise<any>[] {
    const translationAray: Promise<any>[] = [];
    this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationAray, this.question,
      'nameTranslationToken', null, 'nameTranslationTokenId', this.question.nameTranslationToken, 'QuestionAnswers');
    this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationAray, this.question,
      'descriptionTranslationToken', null, 'descriptionTranslationTokenId', this.question.descriptionTranslationToken, 'QuestionAnswers');
    this.question.answers.forEach(answer => {
      this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationAray, answer,
        'descriptionTranslationToken', null, 'descriptionTranslationTokenId', answer.descriptionTranslationToken, 'QuestionAnswers');
      this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationAray,
        answer, 'nameTranslationToken', null, 'nameTranslationTokenId', answer.nameTranslationToken, 'QuestionAnswers');
    });
    return translationAray;
  }

  // saveQuestionAnswers(question: any): Promise<any> {
  //   return new Promise((saveQuestionAnswersResolve, saveQuestionAnswersReject) => {
  //     const promiseArray: Promise<any>[] = [];
  //     question.answers.forEach((answer, index) => {
  //       if (isNullOrUndefined(answer.id)) {
  //         promiseArray.push(this.externaldatasourceService.executeExternalDataSource(157, [
  //           answer.defaultName,
  //           this.commonService.nullOrUndefinedToDefaultValue(answer.nameTranslationTokenId),
  //           this.commonService.nullOrUndefinedToDefaultValue(answer.descriptionTranslationTokenId),
  //           index,
  //           this.question.id
  //         ]).then(result => {
  //           answer.id = result.id;
  //         }));
  //       } else {
  //         promiseArray.push(this.externaldatasourceService.executeExternalDataSource(158, [
  //           answer.defaultName,
  //           this.commonService.nullOrUndefinedToDefaultValue(answer.nameTranslationTokenId),
  //           this.commonService.nullOrUndefinedToDefaultValue(answer.descriptionTranslationTokenId),
  //           index,
  //           this.question.id,
  //           answer.id
  //         ]));
  //       }
  //     });
  //     Promise.all(promiseArray)
  //     .then(() => saveQuestionAnswersResolve(null))
  //     .catch(error => saveQuestionAnswersReject(error));
  //   });
  // }

  saveAnswer(answer) {
    this.externaldatasourceService.executeExternalDataSource(158, [this.commonService.getModifyArrayBody(answer, [])])
    .then(saveAnswerResult => {

    });
  }

  addSubQuestion(answer: any) {
    const question = new Object();
    question['refId'] = answer.id;    
    question['isMultiselect'] = false;    
    question['isMandatory'] = false;    
    question['order'] = answer.childQuestions.length + 1;    
    question['lookupRefTypeId'] = 41;
    question['defaultName'] = this.axivasTranslateService.getTranslationTextForToken('QuestsionAnswers.Label.NewQuestionDefaultName');
    this.externaldatasourceService.executeExternalDataSource(156, [this.commonService.getModifyArrayBody(question, [])])
    .then(addSubQuestionResult => {
      question['answers'] = [];
      question['id'] = addSubQuestionResult.id;
      answer.childQuestions.push(question);
    });    
  }

  getChildLevel() {
    return this.level + 1;
  }

  removeItem(question, answer) {
    if (!isNullOrUndefined(answer.id)) {
      this.externaldatasourceService.executeExternalDataSource(263, [answer.id])
      .then(() => {
        this.commonService.removeItemFromArray(question.answers, answer);
      })
      .catch(error => {
        console.error(error);
      });
    } else {
      this.commonService.removeItemFromArray(question.answers, answer);
    }
  }

  removeQuestion(question) {
    if (!isNullOrUndefined(question.id)) {
      this.externaldatasourceService.executeExternalDataSource(264, [question.id])
      .then(() => {
        this.question = null;
      })
      .catch(error => {
        console.error(error);
      });
    } else {
      this.question = null;
    }
  }

  itemIsFiltered() {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(this.question.defaultName, this.filterString)) {
      return false;
    }
    return returnValue;
  }

  updateQuestion() {
    this.externaldatasourceService.executeExternalDataSource(155, [
      this.commonService.getModifyArrayBody(this.question, this.excludeItemsQuestion)
    ]);
  }
}
