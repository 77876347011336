<div  class="accountGroup__wrapper">
    <div class="accountGroup__groupsHeader">
        <div>
            {{ 'AccountGroup.Label.GroupHeader' | texttransform }}
        </div>        
        <div>
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            <input [(ngModel)]="filterString">    
        </div>
    </div>
    <span *ngIf="loading" class="accountGroup__spinner">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>
    <div *ngIf="accountGroup.length == 0 && !loading" class="accountGroup__infoLabel">
        {{ 'AccountGroup.Label.NoItems' | texttransform }}
    </div>
    <div class="accountGroup__groups" *ngIf="accountGroup.length > 0">
        <div *ngFor="let accountGroupItem of accountGroup" class="accountGroup__item" [ngClass]="{ hideInDom: isItemFiltered(accountGroupItem) }">            
            <!-- Parent -->
            <div *ngIf="showParents" class="accountGroup__item__name ellipsisText">
                <div class="accountGroup__item__name__name1 everleadCoral ellipsisText">
                    {{ accountGroupItem.parent.name3 }}
                </div>
                <div class="accountGroup__item__name__nameEdit">
                    <label>Vorname</label><input [(ngModel)]="accountGroupItem.parent.name2" (change)="updateAccount(accountGroupItem.parent)">
                </div>
                <div class="accountGroup__item__name__nameEdit">
                    <label>Nachname</label><input [(ngModel)]="accountGroupItem.parent.name1" (change)="updateAccount(accountGroupItem.parent)">
                </div>
                <div class="accountGroup__item__name__infoLine ellipsisText">{{ accountGroupItem.parent.street }} {{ accountGroupItem.parent.hausnummer }} {{ accountGroupItem.parent.hausnrzusatz }}</div>
                <div class="accountGroup__item__name__infoLine ellipsisText">{{ accountGroupItem.parent.zipcode }} {{ accountGroupItem.parent.city }}</div>
                <div *ngIf="targetHasOptout(accountGroupItem.parent)" class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText everleadCoral jjBold">
                    {{ 'AccountGroup.Label.OptOutFound' | texttransform }}
                </div>               
                <div class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText">
                    <i class="fas fa-envelope accountGroup__item__name__infoLine__mail"></i>
                    <input [(ngModel)]="accountGroupItem.parent.email" (change)="updateMail(accountGroupItem.parent)">
                </div>
                <div class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText" *ngIf="!targetHasOptout(accountGroupItem.parent)">
                    <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber(accountGroupItem.parent)"></svg-icon>
                    <input [(ngModel)]="accountGroupItem.parent.phone" (change)="updatePhone(accountGroupItem.parent)">
                    <i class="fas fa-globe fa-lg fingerCursor" title="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.parent)"></i>
                    <i class="fas fa-search fa-lg fingerCursor" title="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.parent)"></i>
                </div>
                <!-- <div class="accountGroup__item__name__infoLine accountGroup__item__name__infoLine__search" *ngIf="!targetHasOptout(accountGroupItem.parent)">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" title="Telefonnummer recherchieren" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" 
                        (click)="searchPhoneNumber(accountGroupItem.parent)"></svg-icon>
                    <select [(ngModel)]="searchEngine">
                        <option ngValue="1">Telefonbuch.de</option>
                        <option ngValue="2">Das Örtliche</option>
                        <option ngValue="3">Opendi</option>
                    </select>                    
                </div> -->
            </div>

            <!-- Subsidary -->
            <div *ngIf="!showParents" class="accountGroup__item__name ellipsisText">
                <div class="accountGroup__item__name__name1 everleadCoral ellipsisText">{{ accountGroupItem.subsidary.name1 }}</div>
                <div class="accountGroup__item__name__nameEdit">
                    <label class="everleadCoralBackground accountGroup__item__name__leadState">{{ accountGroupItem.subsidary.id3w }}</label>
                </div>    
                <div class="accountGroup__item__name__infoLine ellipsisText">{{ accountGroupItem.subsidary.street }} {{ accountGroupItem.subsidary.hausnummer }} {{ accountGroupItem.subsidary.hausnrzusatz }}</div>
                <div class="accountGroup__item__name__infoLine ellipsisText">{{ accountGroupItem.subsidary.zipcode }} {{ accountGroupItem.subsidary.city }}</div>
                <div *ngIf="targetHasOptout(accountGroupItem.subsidary)" class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText everleadCoral jjBold">
                    {{ 'AccountGroup.Label.OptOutFound' | texttransform }}
                </div>
                <div class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText" *ngIf="!targetHasOptout(accountGroupItem.subsidary)">
                    <svg-icon src="assets/images/svg/iconset/icon-phone.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="callNumber(accountGroupItem.subsidary)"></svg-icon>
                    <input [(ngModel)]="accountGroupItem.subsidary.phone" (change)="updatePhone(accountGroupItem.subsidary)">
                    <i class="fas fa-globe fa-lg fingerCursor" title="Telefonnummer recherchieren" (click)="searchPhoneNumber(accountGroupItem.subsidary)"></i>
                    <i class="fas fa-search fa-lg fingerCursor" title="Telefonnummer recherchieren (das Örtliche)" (click)="searchPhoneNumberOertlich(accountGroupItem.subsidary)"></i>
                </div>            
                <div class="accountGroup__item__name__infoLine__dial accountGroup__item__name__infoLine ellipsisText">
                    <i class="fas fa-envelope accountGroup__item__name__infoLine__mail"></i>
                    <input [(ngModel)]="accountGroupItem.subsidary.email" (change)="updateMail(accountGroupItem.subsidary)">
                </div>
            </div>
            <div class="accountGroup__item__commentacc" *ngIf="!showParents">
                <label>Kommentar (Objekt)</label>
                <textarea [(ngModel)]="accountGroupItem.subsidary.comments" type="text" (change)="updateAccount(accountGroupItem.subsidary)"></textarea>
            </div>
            <div class="accountGroup__item__commentacc" *ngIf="showParents">
                <label>Kommentar (Eigentümer)</label>
                <textarea [(ngModel)]="accountGroupItem.parent.comments" type="text" (change)="updateAccount(accountGroupItem.parent)"></textarea>
            </div>

            <div class="accountGroup__item__comment">
                <label>Kommentar (Verbindung)</label>
                <textarea [(ngModel)]="accountGroupItem.comment" type="text" (change)="updateAccountGroup(accountGroupItem)"></textarea>
            </div>
            <div class="accountGroup__item__showAccount">
                <button (click)="loadAccount(accountGroupItem)" class="maxWidth everleadCustomButton">
                    {{ 'CompactTaskList.Label.LoadTask' | texttransform }} <i class="far fa-caret-square-right"></i>
                </button>
                <button  (click)="deleteAccountGroup(accountGroupItem)" class="maxWidth everleadCustomButton" [title]="'CompactTaskList.Label.Delete' | texttransform">
                    {{ 'CompactTaskList.Label.Delete' | texttransform }} <i class="fas fa-trash"></i>
                </button>
            </div> 
        </div>
    </div>
    <div class="accountGroup__available">
        <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
    </div>
</div>