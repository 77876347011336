export class FilterParameterDto {

  filterSegmentMemberId: number;
  filterParameter1: string;
  filterParameter2: string;

  constructor(filterSegmentMemberId: number,
    filterParameter1: string,
    filterParameter2: string) {
    this.filterSegmentMemberId = filterSegmentMemberId;
    this.filterParameter1 = filterParameter1;
    this.filterParameter2 = filterParameter2;
  }
}
