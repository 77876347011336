import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-softphone-ringcentralwebrtc-extension',
  templateUrl: './softphone-ringcentralwebrtc-extension.component.html',
  styleUrls: ['./softphone-ringcentralwebrtc-extension.component.scss']
})
export class SoftphoneRingcentralwebrtcExtensionComponent implements OnInit, OnDestroy {
  collapseExtensionEventSubscription: Subscription = new Subscription();
  mode = 1;
  selectedRecordingTypeId = null;

  constructor(
    private ringcentralwebphoneService: RingcentralwebphoneService,
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.collapseExtensionEventSubscription = this.ringcentralwebphoneService.showRecordOptionsEvent.subscribe(() => {
      this.mode = 4;
    });
  }

  ngOnDestroy(): void {
    if (this.collapseExtensionEventSubscription) { this.collapseExtensionEventSubscription.unsubscribe(); }
  }

  modeChange() {
    if (this.mode == 2) {
      this.ringcentralwebphoneService.getRecentCalls();
    }        
  }

  makeCall(recentCall) {
    console.log('X1')
    let array = null;
    if (recentCall.direction == 'Inbound') {
      array = recentCall.from;
    } else {
      array = recentCall.to;
    }

    let number = '';
    if (array.phoneNumber) {
      number = array.phoneNumber;
    } else {
      number = array.extensionNumber;
    }
    this.ringcentralwebphoneService.makeCall(number);
  }

  startRecord(recordingtype) {
    this.ringcentralwebphoneService.selectedRecordingTypeId = recordingtype.id;
    this.ringcentralwebphoneService.toggleRecord();
    this.ringcentralwebphoneService.collapseExtensionEvent.emit();
  }
}
