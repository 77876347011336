import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { isNullOrUndefined } from 'util';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';

@Component({
  selector: 'app-attribute-modifier-overview',
  templateUrl: './attribute-modifier-overview.component.html',
  styleUrls: ['./attribute-modifier-overview.component.scss']
})
export class AttributeModifierOverviewComponent implements OnInit {
  controls = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public designerService: DesignerService
  ) { }

  ngOnInit() {
    this.applicationInfoService.controls.impArray.items.forEach(item => {
      if (!isNullOrUndefined(this.applicationInfoService.controls.Item(item).controlAttributeModifierGroups)) {
        if (this.applicationInfoService.controls.Item(item).controlAttributeModifierGroups.length > 0) {
          this.controls.push(this.applicationInfoService.controls.Item(item));
        }
      }
    });
  }

}
