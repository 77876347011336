import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-jj-popup-item',
  templateUrl: './jj-popup-item.component.html',
  styleUrls: ['./jj-popup-item.component.scss']
})
export class JjPopupItemComponent implements OnInit, OnDestroy, AfterViewInit {
  customEventSubscription: Subscription = new Subscription();
  @Input() popupSize = '80';
  @Input() popupWidth = null;
  @Input() popupHeight = null;
  isExpanded = false;
  showContainer = false;
  @Input() headerText = '';
  @Input() showExpandButton = false;
  innerHTML = null;
  @Input() popupIsClosable = true;
  @Input() id = null;
  @Input() controlId;
  isInitialyMaximized = false;
  isFullSize = false;

  @ViewChild('everleadPopup') everleadPopup: ElementRef;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private domSanitizer: DomSanitizer,
    private uiService: UiService,
    private commonService: CommonService,
    public axivasTranslateService: AxivasTranslateService,
    public wizardService : WizardService
  ) { }

  ngOnInit() {    
    if (isNaN(this.controlId)) {
      this.uiService.getUIfromID(this.controlId, this.controlId)
      .then(getUIfromIDResult => { this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(getUIfromIDResult); });        
    } else {
      this.showContainer = true; 
    }
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'resizeJJPopup') {
        this.popupSize = event.value;
        this.setPopupSize(true);
      }
    });
    this.setPopupSize();
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.closeWindowOnKey(event);
  }

  setPopupSize(popupResized = false) {
    if (this.commonService.checkIfStringContainsString(this.popupSize, ';')) {
      const popupSizeSplit = this.popupSize.split(';');
      this.popupWidth = popupSizeSplit[0];
      this.popupHeight = popupSizeSplit[1];    
    } else {
      this.popupWidth = this.popupSize;
      this.popupHeight = this.popupSize;    
    }
    if (this.applicationInfoService.miscSettings['popupFullSize'] == true) {
      this.isFullSize = true;
      this.applicationInfoService.miscSettings['popupFullSize'] = false;
    }
    if (this.popupWidth == 100 && this.popupHeight == 100 && popupResized == false) {
      this.isInitialyMaximized = true;
    }
  }

  ngAfterViewInit(): void {
    this.dragElement(document.getElementById('everleadPopup'));
  }

  ngOnDestroy() {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    this.closePopup();
  }

  toggleExpand() {
    if (!this.isExpanded) {
      document.getElementById('everleadPopup').style.left = '50%';
      document.getElementById('everleadPopup').style.top = '50%';
      if (this.applicationInfoService.isMobile()) {
        document.getElementById('everleadPopup').style.top = 'calc(50% + 30px)';
      }
    }
    this.isExpanded = !this.isExpanded;
  }

  closePopup() {
    this.eventService.closeJjPopup(this.id);
  }

  getZIndex(bonus = 0) {
    return ((Number(this.id) * 100) + 20000 + bonus);
  }

  getPopupDimension(axis) {
    switch (axis) {
      case 'x':
        if (this.applicationInfoService.isMobile() || this.isExpanded) {
          return '100vw';
        } else {
          if (
            this.commonService.checkIfStringContainsString(this.popupWidth, 'px') || 
            this.commonService.checkIfStringContainsString(this.popupWidth, '%')
          ) {
            return this.popupWidth;
          } else {
            return this.popupWidth + 'vw'
          }          
        }
        break;

      case 'y':
        if (this.applicationInfoService.isMobile() || this.isExpanded) {
          return '100%';
        } else {
          if (
            this.commonService.checkIfStringContainsString(this.popupHeight, 'px') ||
            this.commonService.checkIfStringContainsString(this.popupHeight, '%') 
          ) {
            return this.popupHeight;
          } else {
            return this.popupHeight + 'vh';
          }          
        }
        break;
    }
  }

  dragElement(element) {
    if (this.isInitialyMaximized) { return; }
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(element.id + "__header")) {
      document.getElementById(element.id + "__header").onmousedown = dragMouseDown;
    } else {
      element.onmousedown = dragMouseDown;
    }
  
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      element.style.top = (element.offsetTop - pos2) + "px";
      element.style.left = (element.offsetLeft - pos1) + "px";
    }
  
    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  getPopupId(additionalText) {
    return 'everleadPopup'.concat(this.id, additionalText);
  }

  closeWindowOnKey(event:KeyboardEvent){
    if (this.applicationInfoService.showJJPopup && (event.key === 'Escape' || event.key === 'Esc')) {
      this.closePopup();
    }
  }

}
