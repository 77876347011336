<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="addNewMethod()" class="fingerCursor" [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
        {{ 'Designer.Label.CreateMethod' | texttransform }} <app-documentation-info section="Method"></app-documentation-info>
    </div>
    <div class="projectSettings__header__interaction"></div>
</div>

<div *ngFor="let method of currentControlToModify.methods" class="everleadShadowBox uiControlMethods__methodBox">
    <div class="uiControlMethods__method">
        <div class="uiControlMethods__method__line">
            <label>Id</label>
            <div>
                {{ method.id}}            
                <i class="fas fa-trash fingerCursor" (click)="deleteMethod(method)"></i>    
            </div>
        </div>
        <div class="uiControlMethods__method__line">
            <label>{{'General.Label.Order' | texttransform}}</label>
            <input type="number" [(ngModel)]="method.order" (change)="saveMethod(method)">
        </div>
        <div class="uiControlMethods__method__line">
            <label>{{'ProcessDesign.Label.Type' | texttransform}}</label>
            <select [(ngModel)]="method.type" (change)="saveMethod(method)">
                <option ngValue="onClick">Click</option>
                <option ngValue="onDblClick">DblClick</option>
                <option ngValue="onChange">Change</option>
                <option ngValue="onBlur">Blur</option>
            </select>
        </div>
        <div class="uiControlMethods__method__line">
            <label>{{'General.Label.Method' | texttransform}}</label>
            <select [(ngModel)]="method.method" (change)="saveMethodType(method)">
                <option *ngFor="let methodType of methodTypesArray" [ngValue]="methodType.name">{{ methodType.id }}</option>
            </select>
        </div>
        <div class="uiControlMethods__method__line">
            <label>{{'General.Label.Predecessor' | texttransform}}</label>
            <select [(ngModel)]="method.parentControlMethodId" (change)="saveMethod(method)">
                <option [ngValue]=null></option>
                <option *ngFor="let method of currentControlToModify.methods" [ngValue]="method.id">{{ method.id }}</option>
            </select>
        </div>
        <div>
            <label class="uiControlMethods__params__header">{{'General.Label.Parameter' | texttransform}}</label>
        </div>
    </div>
    <div class="uiControlMethods__params">
        <div *ngIf="method.parameters?.length == 0" class="everleadCoral uiControlMethods__params__info">
            {{'Designer.Label.CurrentlyNoParameters' | texttransform}}
        </div>


        <div *ngFor="let param of method.parameters; let currentIndex = index" class="uiControlMethods__paramBox">
            <div class="uiControlMethods__paramBox__line">
                <label>Id</label>
                <div>{{ param.id}}<i class="fas fa-trash fingerCursor" (click)="deleteParameter(method, param)"></i></div>
            </div>
            <!-- <div class="uiControlMethods__method__line">
                <label>{{'General.Label.Order' | texttransform}}</label>
                <input type="number" [(ngModel)]="param.order" (change)="saveParameter(param)">
            </div> -->
            <div class="uiControlMethods__paramBox__line">
                <label>{{'ProcessDesign.Label.Type' | texttransform}}</label>
                <select [(ngModel)]="param.type" (change)="saveParameter(param)">
                    <option [ngValue]=toNumber(1)>no conversion of value</option>
                    <option [ngValue]=toNumber(2)>selected line (value can be empty)</option>
                    <option [ngValue]=toNumber(3)>value from selected line (value)</option>
                    <option [ngValue]=toNumber(4)>value from controlValue (logicalControlId;value)</option>
                    <option [ngValue]=toNumber(5)>value from entity (entityId;value)</option>
                    <option [ngValue]=toNumber(6)>null</option>
                    <option [ngValue]=toNumber(7)>selected line from controlId (logicalControlId)</option>
                    <option [ngValue]=toNumber(8)>misc-Setting</option>
                    <option [ngValue]=toNumber(9)>user-Informationen</option>
                    <option [ngValue]=toNumber(10)>DateNow</option>
                    <option [ngValue]=toNumber(11)>CampaignModeCampaignId (value can be empty)</option>
                    <option [ngValue]=toNumber(12)>Current AccountId (value can be empty)</option>
                    <option [ngValue]=toNumber(13)>Current ContactId (value can be empty)</option>
                </select>
            </div>
            <div class="uiControlMethods__paramBox__line">
                <label>{{'General.Label.Value' | texttransform}}</label>
                <input [(ngModel)]="param.value" (change)="saveParameter(param)">
            </div>
            <div class="everleadCoral uiControlMethods__params__info">
                <i class="fas fa-info-circle"></i>{{ getParameterInformation(method, currentIndex) }}
            </div>
        </div>


    </div>
    <div class="nonExpertView__newSubGroup fingerCursor" (click)="addNewParameter(method)">
        <i class="fas fa-plus"></i>{{ 'Designer.Label.CreateParameter' | texttransform}}
    </div>
</div>


        <!-- <div class="row paramHeader">
            <div class="col-md-12">
                <div *ngFor="let param of method.parameters; let currentIndex = index" style="margin-left: 40px;">
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-xs-12">
                            <b>Order</b>
                        </div>
                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <b>Type</b>
                        </div>
                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <b>Value</b>
                        </div>
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <b>Information</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-xs-12">
                            <i class="fas fa-minus" (click)="deleteParameter(method, param)"></i>{{ param.id }}
                        </div>
                        <div class="col-lg-3 col-md-3 col-xs-12">
                            <input type="number" [(ngModel)]="param.order" (change)="saveParameter(param)">
                        </div>
                        <div class="col-3">
                            <select [(ngModel)]="param.type" (change)="saveParameter(param)">
                                <option [ngValue]=toNumber(1)>no conversion of value</option>
                                <option [ngValue]=toNumber(2)>selected line (value can be empty)</option>
                                <option [ngValue]=toNumber(3)>value from selected line (value)</option>
                                <option [ngValue]=toNumber(4)>value from controlValue (logicalControlId;value)</option>
                                <option [ngValue]=toNumber(5)>value from entity (entityId;value)</option>
                                <option [ngValue]=toNumber(6)>null</option>
                                <option [ngValue]=toNumber(7)>selected line from controlId (logicalControlId)</option>
                                <option [ngValue]=toNumber(8)>misc-Setting</option>
                                <option [ngValue]=toNumber(9)>user-Informationen</option>
                                <option [ngValue]=toNumber(10)>DateNow</option>
                                <option [ngValue]=toNumber(11)>CampaignModeCampaignId (value can be empty)</option>
                                <option [ngValue]=toNumber(12)>Current AccountId (value can be empty)</option>
                                <option [ngValue]=toNumber(13)>Current ContactId (value can be empty)</option>
                            </select>
                        </div>
                        <div class="col-lg-3 col-xs-12">
                            <input [(ngModel)]="param.value" (change)="saveParameter(param)">
                        </div>
                        <div class="col-lg-4 col-xs-12">
                            {{ getParameterInformation(method, currentIndex) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->