<div>
  <div [ngClass]="{ hideInDom: showPopup==false }">
    <p-dialog [header]="popupTitle | texttransform" id="popUpmodule" [(visible)]="showPopup" [modal]="true"
      resizable="true" focusTrap="true" maximizable="true" [autoZIndex]="false" [closable]="popupIsClosable"
      appendTo="body" [closeOnEscape]="true" [responsive]="true" [style]="style()" [contentStyle]="contentstyle()">

      <input id="phoneNumberInput" [(ngModel)]="phoneNumber" class="webphonePopupPhoneInputControl"
        (keydown)="handleKeyDown($event)">

      <span class="fas fa-phone webphonePopupPhoneIconControl jjButtonColor fingerCursor floatRight"
        (click)="startCall()">
      </span>
      <br>
      <br>
      <div [innerHTML]="phoneBookInnerHTML" class="zeroIndex"></div>
      <!-- <app-custom-universal [controlid]="phonebookControlId"></app-custom-universal> -->
      <br>
      <div [innerHTML]="phoneHistoryInnerHTML" class="zeroIndex"></div>
      <!-- <app-custom-universal [controlid]="phonehistoryControlId"></app-custom-universal> -->
    </p-dialog>
  </div>
</div>