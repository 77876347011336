import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-eventtrigger',
  templateUrl: './wizard-eventtrigger.component.html',
  styleUrls: ['./wizard-eventtrigger.component.scss']
})
export class WizardEventtriggerComponent implements OnInit {
  eventTriggers = [];

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getEventTriggers();
  }

  getEventTriggers() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(478)    
    .then(getEventTriggersResult => { 
      this.eventTriggers = getEventTriggersResult;
      let eventTrigger = this.eventTriggers.find(trigger => 
        trigger.eventType == this.wizardService.wizardArray.type &&
        trigger.subUrl == this.wizardService.wizardArray.url
      );
      console.warn(this.eventTriggers, this.wizardService.wizardArray, eventTrigger);
      if (!eventTrigger) {
        eventTrigger = new Object();
        this.wizardService.wizardArray.id = null;
        this.wizardService.wizardArray.subUrl = this.wizardService.wizardArray.url;
        this.wizardService.wizardArray.eventType = this.wizardService.wizardArray.type;
        this.wizardService.wizardArray.regexData = '';
        this.wizardService.wizardArray.regexUrl = '';
        this.wizardService.wizardArray.projectId = this.applicationInfoService.projectID;
        this.wizardService.wizardArray.isCreateNewTask = false;
      } else {
        this.wizardService.wizardArray = eventTrigger;
      }
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  createEventTrigger() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(646, [
      this.commonService.getModifyArrayBody(this.wizardService.wizardArray, ['count', 'url', 'type', 'id'])
    ]).then(() => { 
      this.eventService.closeJjPopup(); 
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  updateEventTrigger() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(476, [
      this.commonService.getModifyArrayBody(this.wizardService.wizardArray, ['count', 'url', 'type'])
    ])
    .then(() => { this.loaderService.display(false); })
    .catch(() => { this.loaderService.display(false); });
  }

  deleteEventTrigger(eventTrigger) {
    this.loaderService.display(true);
    console.warn('deleteEventTrigger');
    this.externaldatasourceService.executeExternalDataSource(477, [eventTrigger.id])
    .then(() => { 
      this.loaderService.display(false); 
      this.getEventTriggers();
    })
    .catch(() => { this.loaderService.display(false); });
  }

  editEventTrigger(eventTrigger) {
    this.wizardService.wizardArray = eventTrigger;
    this.wizardService.wizardPage = 1;
  }
}
