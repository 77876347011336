import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoplayer: any;
  videoStarted = false;
  video = null;
  dontShowAgain = false;
  dontShowAgainString = '';
  videoLoaded = false;
  videoUrlEn = '';
  videoUrl = '';

  // videoUrlEn = 'https://www.youtube.com/embed/CvAJplINScg?autoplay=1';
  // videoUrl = 'https://www.youtube.com/embed/UpXqkjKcf1k?autoplay=1';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private domSanitizer: DomSanitizer,
    private axivasTranslateService: AxivasTranslateService
  ) { 

  }

  ngOnDestroy(): void {
    if (this.applicationInfoService.projectID == 164) {
      this.applicationInfoService.showBanner = true;
    }      
  }

  ngOnInit(): void {
    this.eventService.showStartupVideoEvent.subscribe(() => {
      this.showDemoVideo();
    });   

    this.eventService.showCustomVideoEvent.subscribe(videoSource => {
      if (this.axivasTranslateService.currentLanguage == 'de') {        
        this.showVideo(this.applicationInfoService.applicationSettings[videoSource + 'De']);
      } else {
        this.showVideo(this.applicationInfoService.applicationSettings[videoSource + 'En']);
      }      
    });    

    this.eventService.showCustomVideoWithCheckEvent.subscribe(videoSource => {
      if (this.axivasTranslateService.currentLanguage == 'de') {        
        this.showVideo(this.applicationInfoService.applicationSettings[videoSource + 'De'], true);
      } else {
        this.showVideo(this.applicationInfoService.applicationSettings[videoSource + 'En'], true);
      }      
    });    
  }

  toggleVideo(event: any) {
      this.videoplayer.play();
  }

  closeVideo() {
    this.applicationInfoService.showVideo = false;
    if (this.applicationInfoService.projectID == 164) {
      this.applicationInfoService.showBanner = true;
    }      
  }

  showDemoVideo() {
    this.videoUrl = this.applicationInfoService.applicationSettings['startupVideo']
    if (this.axivasTranslateService.currentLanguage == 'en') {
      this.videoUrl = this.applicationInfoService.applicationSettings['startupVideoEn'];
    }    
    this.dontShowAgainString = 'dontShow'.concat(this.videoUrl.toString());
    this.video = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    this.settingsService.getUserSettingByStringPromise(this.dontShowAgainString, 'false')    
    .then((result:any) => {
      if (result.value != 'true') {
        this.videoLoaded = true;
        this.toggleDontShowAgain(true);
        // this.videoplayer.play();        
      } else {
        if (this.applicationInfoService.applicationSettings['showBanner'] == 'true') {
          this.applicationInfoService.showBanner = true;
        }          
      }
    })  
  }

  showVideo(videoUrl, checkIfSupressed = false) { 
    this.videoUrl = videoUrl;
    this.video = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);

    if (checkIfSupressed) {
      this.dontShowAgainString = 'dontShow'.concat(this.videoUrl.toString());
      this.settingsService.getUserSettingByStringPromise(this.dontShowAgainString, 'false')    
      .then((result:any) => {
        if (result.value != 'true') {
          this.applicationInfoService.showVideo = true;
          this.videoLoaded = true;
          this.toggleDontShowAgain(true);
        }
      })    
    } else {
      this.applicationInfoService.showVideo = true;
      this.videoLoaded = true;
    }
  }

  startVideo() {
    if (this.videoStarted == false) {
      this.videoStarted = true;
      this.videoplayer.play();
    }    
  }

  toggleDontShowAgain(toggleValue = false) {
    if (toggleValue) {
      this.dontShowAgain = !this.dontShowAgain;
    }
    if (this.dontShowAgain) {
      this.settingsService.setUserSetting('dontShow'.concat(this.videoUrl.toString()), 'true');
    } else {
      this.settingsService.setUserSetting('dontShow'.concat(this.videoUrl.toString()), 'false');
    }
  }
}
