<div *ngIf="mode==1" class="wizardItemWrapper__translations" (click)="$event.stopPropagation();getTranslations()"
 [tippy]="'Wizard.Label.Translations' | texttransform" 
 [tippyOptions]="{theme: (array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null) ? 'everleadRed': 'everleadBlue'}"
 [ngClass]="{
    wizardTranslations__editor__header__active: array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null
}">
    <i class="fas fa-globe fa-lg wizardItemWrapper__translations__icon" ></i>
</div>

<div *ngIf="mode==3" class="wizardTranslations__editor__mode3">
    <i class="fas fa-globe fa-lg wizardItemWrapper__translations__icon fingerCursor" [ngClass]="{
        wizardTranslations__editor__mode3__active: array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null
    }" (click)="$event.stopPropagation();showPopup()" 
    [tippy]="'Wizard.Translation.Header' | texttransform" 
    [tippyOptions]="{theme: (array?.nameTranslationTokenId != null || array?.descriptionTranslationTokenId != null) ? 'everleadRed': 'everleadBlue'}"></i>
</div>