import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { WizardService } from '../wizard.service';
import { SmtpService } from 'app/jollyjupiter/service/smtp.service';

@Component({
  selector: 'app-wizard-mailtocontact',
  templateUrl: './wizard-mailtocontact.component.html',
  styleUrls: ['./wizard-mailtocontact.component.scss']
})
export class WizardMailtocontactComponent implements OnInit {
  foundItems = [];
  searchStringQuickSearch = '';
  searchStringQuickAccount = '';
  dummyAccountId = null;
  mailSource = '_1130_from';
  campaignSelected = false;
  microcampaigns = [];
  selectedMicroCampaign = null;
  contactInfo = null;
  optinMissing = false;
  optinCheckPending = true;
  searching = false;

  constructor(
    public wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    public loaderService: LoaderService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private confirmationService: ConfirmationService,
    private methodService: MethodService,
    private smtpService: SmtpService
  ) { }

  ngOnInit(): void {
    this.wizardService.wizardArray.firstName = '';
    this.wizardService.wizardArray.lastName = '';
    let searchString = this.wizardService.wizardArray[this.mailSource].split('@')[0];
    this.searchStringQuickSearch = searchString;
    this.quickSearchContacts();
    this.getDummyAccounts();
    this.getMicroCampaignStarter();
    if (this.wizardService.wizardArray._1130_type != 'Incoming') {
      this.mailSource = '_1130_to';
    }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray._1130_contactid)) {
      this.wizardService.wizardArray._1130_contactid = null;
    }

    let page = 1;
    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray._1130_contactid)) {
      page = 3;
    };
    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray._1130_conversionid)) {
      page = 4;
    }
    this.wizardService.wizardPage = page;

    this.wizardService.wizardArray.conversionId = this.wizardService.wizardArray._1130_conversionid;
    this.wizardService.wizardArray.from = this.wizardService.wizardArray._1130_from;
    this.wizardService.wizardArray.to = this.wizardService.wizardArray._1130_to;
    this.wizardService.wizardArray.type = this.wizardService.wizardArray._1130_type;
    this.wizardService.wizardArray.time = this.wizardService.wizardArray._1130_time;
  }

  quickSearchContacts() {
    this.foundItems = [];
    if (this.searching == true) { return; }
    this.searching = true;
    if (this.searchStringQuickSearch == '') { return; }
    this.externaldatasourceService.executeExternalDataSource(880, [
      this.searchStringQuickSearch,
      this.applicationInfoService.poolId,
      this.applicationInfoService.applicationSettings['quickSearchAccountValue'],
      this.applicationInfoService.applicationSettings['quickSearchContactValue']
    ]).then(quickSearchResult => {
      const quickSearchJson = JSON.parse(quickSearchResult.resultJson);
      if (quickSearchJson.contacts != null) {
        quickSearchJson.contacts.forEach(contact => {
          this.foundItems.push(contact);
        });
      }
      this.searching = false;
      this.loaderService.display(false);
    })
  }

  getDummyAccounts() {
    this.externaldatasourceService.executeExternalDataSource(684)
    .then(getDummyAccountsResult => {
      const poolDummyAccount = getDummyAccountsResult.filter(dummy => dummy.poolId == this.applicationInfoService.poolId);
      if (poolDummyAccount) {
        this.dummyAccountId = poolDummyAccount[0].id;
      }
    });
  }

  createContact(loadContact = false) {
    if (!this.commonService.isNullOrUndefined(this.wizardService.wizardArray._1130_contactid)) {
      return;
    }

    this.smtpService.createEvent(this.wizardService.wizardArray).then(createEventResult => {
      const promiseArray: Promise<any>[] = [];
      this.loaderService.display(true);
      if (this.dummyAccountId == null) {
        promiseArray.push(this.createDummyAccount());
      }
      Promise.all(promiseArray)
      .then(()=> {
        this.externaldatasourceService.executeExternalDataSource(685, [
          this.commonService.getModifyArrayBody({
            firstName: this.wizardService.wizardArray.firstName,
            lastName: this.wizardService.wizardArray.lastName,
            accountId: this.dummyAccountId,
            email: this.wizardService.wizardArray[this.mailSource]
          }, [])
        ])
        .then(createContactResult => {
          if (this.wizardService.wizardArray.createConversion == true) {
            this.smtpService.createConversion(createContactResult.id, createEventResult, this.wizardService.wizardArray, this.applicationInfoService.campaingnModeId)
            .then(createConversionResult => {
              this.wizardService.wizardArray._1130_conversionid = createContactResult.id;
              this.wizardService.wizardArray.conversionId = createContactResult.id;
            });
          }
          // if (loadContact) {
          //   this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [createContactResult.id]);
          // } else {
          //   this.eventService.customEvent.emit({ source: 'mailtoContact', id: 'refreshSMTPMails' });
          // }
          this.eventService.closeJjPopup();
          this.loaderService.display(false);
          this.assignMailToContact(createContactResult);
        }).catch(() => { this.loaderService.display(false); });
      }).catch(() => { this.loaderService.display(false); });
    });
  }

  assignMailToContact(contact) {
    contact.isDeleted = false;
    this.wizardService.wizardArray._1130_contactid = contact.id;
    if (!this.commonService.isNullOrUndefined(contact.text)) {
      contact.lastName = contact.text;
    }
    this.eventService.customEvent.emit({
      id: 'updateContactOfEmailByEMailAdress',
      contact: contact,
      email: this.wizardService.wizardArray[this.mailSource]
    });
    this.wizardService.wizardArray._1130_contactid = contact.id;
    this.externaldatasourceService.executeExternalDataSource(705, [
      this.commonService.getModifyArrayBody({
        id: this.wizardService.wizardArray.id,
        contactId: contact.id
      }, [])
    ]);
  }

  createDummyAccount(): Promise <any> {
    return new Promise((createDummyAccountResolve, createDummyAccountReject) => {
      this.externaldatasourceService.executeExternalDataSource(204, [
        this.commonService.getModifyArrayBody({
          name1: '   ',
          isDummyAccount: true,
          poolId: this.applicationInfoService.poolId
        }, [])
      ])
      .then(createDummyAccountResult => {
        this.dummyAccountId = createDummyAccountResult.id;
        createDummyAccountResolve(createDummyAccountResult);
      })
      .catch(error => { createDummyAccountReject(error); });
    });
  }


  selectExistingContact(foundItem) {
    console.warn('foundItem', foundItem);
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('MailToContact.Message.AttachEMail2Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('MailToContact.Message.AttachEMail2Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.externaldatasourceService.executeExternalDataSource(682, [
          this.commonService.getModifyArrayBody({
            id: foundItem.id,
            email2: this.wizardService.wizardArray[this.mailSource]
          }, [])
        ])
        .then(() => {
          // this.eventService.customEvent.emit({ source: 'mailtoContact', id: 'refreshSMTPMails' });
          this.assignMailToContact(foundItem);
          // this.smtpService.createEvent(this.wizardService.wizardArray).then(event => {
          //   this.smtpService.createConversion(foundItem.id, event, this.wizardService.wizardArray, this.applicationInfoService.campaingnModeId);
          //   this.eventService.closeJjPopup();
          // });
        });
      }
    });
  }

  handleQuickSearchKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.quickSearchContacts();
    }
  }

  noContactFound() {
    this.wizardService.wizardPage ++;
  }

  checkCampaign(): Promise<any> {
    this.optinMissing = true;
    this.optinCheckPending = true;
    return new Promise((checkCampaignResolve, checkCampaignReject) => {
      this.getContactInfo().then(() => {
        if (this.selectedMicroCampaign.emailTemplateId == null) {
          this.optinMissing = false;
          checkCampaignResolve(this.optinMissing);
        } else {
          // Checking E-Mail Optin
          this.externaldatasourceService.executeExternalDataSource(878, [this.selectedMicroCampaign.emailTemplateId]).then(templateInfo => {
            if (templateInfo.isRequireDoubleOptIn == true) {
              this.externaldatasourceService.executeExternalDataSource(879, [
                this.contactInfo.account.id,
                this.contactInfo.id
              ]).then(getOptStatesResult => {
                if (this.commonService.getOptinStateForMedia({ id: 47 }, getOptStatesResult, this.contactInfo).lookupTypeRefId == 1815) { this.optinMissing = false; }
                if (this.commonService.getOptinStateForMedia({ id: 51 }, getOptStatesResult, this.contactInfo).lookupTypeRefId == 1815) { this.optinMissing = false; }
                checkCampaignResolve(this.optinMissing);
              });
            } else {
              this.optinMissing = false;
              checkCampaignResolve(this.optinMissing);
            }
          }).catch(() => checkCampaignReject(null));
        }
      });
    });
  }

  startCampaign() {
    this.checkCampaign().then(() => {
      this.optinCheckPending = false;
      if (this.optinMissing) { return; }
      this.campaignSelected = false;
      this.applicationInfoService.miscSettings['isStartingMicroCampaign'] = this.selectedMicroCampaign;
      this.applicationInfoService.miscSettings['bookingWorkflowTypeId'] = 2851;
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1'])
      .then(() => {
        this.applicationInfoService.miscSettings['bookingMode'] = 4;
        if (this.contactInfo) {
          this.applicationInfoService.miscSettings['microCampaignContactInfo'] =  this.contactInfo;
          setTimeout(() => { this.campaignSelected = true; }, 300);
        }
      });
    });
  }

  getContactInfo(): Promise<any> {
    return new Promise((getContactInfoResolve, getContactInfoReject) => {
      if (this.contactInfo == null) {
        this.externaldatasourceService.executeExternalDataSource(876, [
          this.wizardService.wizardArray._1130_contactid
        ]).then(contactInfo => {
          this.contactInfo = contactInfo;
          this.campaignSelected = true;
          console.warn('getContactInfo', this.contactInfo);
          getContactInfoResolve(null);
        }).catch(() => { getContactInfoReject(null); });
      } else {
        getContactInfoResolve(this.contactInfo);
      }
    });
  }

  createConversion() {
    if (this.wizardService.wizardArray._1130_conversionid != null) {
      return;
    }

    this.smtpService.createEvent(this.wizardService.wizardArray).then(event => {
      this.smtpService.createConversion(this.wizardService.wizardArray._1130_contactid, event,
          this.wizardService.wizardArray, this.applicationInfoService.campaingnModeId)
      .then(createConversionResult => {
        this.wizardService.wizardArray._1130_conversionid = createConversionResult.id;
      });
    });
  }

  getMicroCampaignStarter() {
    this.microcampaigns = [];
    this.applicationInfoService.results.toArray().forEach(result => {

      if (result.typeLookupId == 6216) {
        this.microcampaigns.push(result);
      }
    });
  }
}
