import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-header-information',
  templateUrl: './header-information.component.html',
  styleUrls: ['./header-information.component.scss']
})
export class HeaderInformationComponent implements OnInit {

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private entityService: EntityService
  ) { }

  ngOnInit(): void {
  }

  getAdressLine(fullInfo = false) {
    if (fullInfo) {
      return ''.concat(
        this.getEntityInfo(1, 'name1'), '\n',
        this.getEntityInfo(1, 'street'), '\n',
        this.getEntityInfo(1, 'zipcode'), ' ',
        this.getEntityInfo(1, 'city')
      );
    } else {
      return this.getEntityInfo(1, 'name1');
    }
  }

  getContactLine() {
    return ''.concat(
      this.getEntityInfo(4, 'firstName'), ' ',
      this.getEntityInfo(4, 'lastName'), ' ',
      // this.entityService.getEntityValue(4, 'zipcode', 1), ' ',
      // this.entityService.getEntityValue(4, 'city', 1)
    );
  }

  getEntityInfo(entityId, memberName) {
    let returnValue = this.entityService.getEntityValue(entityId, memberName, 1);
    if (returnValue == null || returnValue == undefined) {
      returnValue = '';
    }
    return returnValue;
  }
}
