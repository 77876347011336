<div class="controlEditLight__wrapper">
    <!-- Default control -->
    <div class="containerView" *ngIf="mode == 2">
        <!-- Container -->
        <div class="containerView__select everleadShadowBox">
            <div class="containerView__selectLabel">{{ 'UIControlSettings.Label.Section' | texttransform }}</div>
            <select [(ngModel)]="containerView" class="maxWidth">
                <option [ngValue]=1>{{ 'UIControlSettings.Label.ContainerView' | texttransform }}</option>
                <option [ngValue]=2>{{ 'UIControlSettings.Label.ControlSettings' | texttransform }}</option>
            </select>
        </div>
        <div *ngIf="containerView == 1">
            <div class="explanationText">{{ 'UIControlSettings.Label.ContainerDesignDescription' | texttransform }}</div>
        </div>
        <div class="containerView__content">            
            <app-uicontainer-design [currentControlToModify]="controlDefinition" class="maxHeight" *ngIf="containerView == 1"></app-uicontainer-design>
            <app-contols-design-settings [fixedTypeId]="fixedTypeId" [control]="controlDefinition" *ngIf="containerView == 2"></app-contols-design-settings>    
            <div class="isDeveloper" *ngIf="containerView == 2">
                <div>Developer only</div><i class="fas fa-edit" (click)="editDeveloper()" *ngIf="applicationInfoService.isDeveloper"></i>
            </div>
        </div>
    </div>
    <div class="controlView" *ngIf="mode == 1">
        <app-contols-design-settings [fixedTypeId]="fixedTypeId" [control]="controlDefinition"></app-contols-design-settings>
        <div class="isDeveloper">
            <div>Developer only</div><i class="fas fa-edit" (click)="editDeveloper()" *ngIf="applicationInfoService.isDeveloper"></i>
        </div>
    </div>
</div>