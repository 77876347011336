<div class="dashboardFilterWrapper">
    <input type="text" [(ngModel)]="filterValue" class="dashboardFilter" [placeholder]="'General.Label.Search' | texttransform">
</div>
<div class="dashboardItemWrapper">
    <div *ngFor="let dashboard of dashboardsList"
        [ngClass]="{ 
            currentDashboard: dashboard.Id == applicationInfoService.currentDashboardId, 
            hideInDom: isItemFiltered(dashboard) || isItemBlocked(dashboard)
        }">   
        <div class="dashboardItem fingerCursor" (click)="loadDashboard(dashboard)" *ngIf="!isItemFiltered(dashboard) && !isItemBlocked(dashboard)"[ngClass]="{ 
            currentDashboard: dashboard.Id == applicationInfoService.currentDashboardId
        }">
            {{ dashboard.Name }}
        </div>
       
    </div>    
</div>
