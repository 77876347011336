import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { isNullOrUndefined } from 'util';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit {
  reportParameterExpanded = [];
  expandedQuery = [];
  removedQueries = [];
  // removedParameters = [];
  removeReportQueryTransformations = [];
  expandedQueryDetails = [];
  expandedReportParameters = [];
  expandedQueryTransformations = [];
  mode = 1;
  filterString = '';
  selectedProject = null;
  projects = [];
  selectedReport = null;
  reports = [];

  // new report
  newReportName = '';
  newReportProject = null;

  connectionGroups = [
    { id: 1, defaultName: 'JJData'},
    { id: 2, defaultName: 'JJCentral'},
    { id: 3, defaultName: 'MS SQL'}
  ];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    public dragdropService: DragdropService,
    private applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    private uiService: UiService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private jjtranslationService: JjtranslationService
  ) { }

  ngOnInit() {
    // this.getConnectionGroups();
    this.getProjects()
    .then(() => {
      this.selectedProject = this.applicationInfoService.projectID;
      this.getProjectReports(this.applicationInfoService.projectID);
    });
  }

  getConnectionGroups(): Promise<any> {
    return new Promise((getConnectionGroupsResolve, getConnectionGroupsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(1)
      .then(executeExternalDataSourceResult => {
        this.connectionGroups = executeExternalDataSourceResult;
        getConnectionGroupsResolve(executeExternalDataSourceResult);
      })
      .catch(error => {
        getConnectionGroupsReject(error);
      })
      .finally(() => {
        this.loaderService.display(false);
      });
    });
  }

  getProjects(): Promise<any> {
    return new Promise((getProjectsResolve, getProjectsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(1)
      .then(executeExternalDataSourceResult => {
        this.projects = executeExternalDataSourceResult;
        getProjectsResolve(executeExternalDataSourceResult);
      })
      .catch(error => {
        getProjectsReject(error);
      })
      .finally(() => {
        this.loaderService.display(false);
      });
    });
  }

  checkIfProjectReporting() {
    let returnValue = false;
    if (this.selectedReport) {
      if (this.selectedReport.id === 1) { 
        returnValue = true;
      }
    }
    return returnValue;
  }

  getProjectReports(projectId): Promise<any> {
    return new Promise((getProjectReportsResolve, getProjectReportsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(199, [projectId])
      .then(executeExternalDataSourceResult => {
        executeExternalDataSourceResult.forEach(report => {
          report.reportParameters.sort((a, b) => a.order < b.order ? -1 : 1);
        });
        this.reports = executeExternalDataSourceResult;
        if (this.reports.length > 0) {
          this.selectedReport = this.reports[0];
        } else {
          this.selectedReport = null;
        }
        getProjectReportsResolve(executeExternalDataSourceResult);
      })
      .catch(error => {
        getProjectReportsReject(error);
      })
      .finally(() => {
        this.loaderService.display(false);
      });
    });
  }

  getMaximumHeightOfSubContainer() {
    return { height: this.uiService.getDesignSpanPosition('adminReportDesignSpan', 20) + 'px' };
  }

  // newReport() {
  //   this.mode = 2;
  // }

  // createNewReport() {
  //   this.mode = 1;
  // }

  saveReport() {
    const translationPromiseArray: Promise<any>[] = [];
    this.selectedReport.reportParameters.forEach(reportParameter => {
      this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationPromiseArray, reportParameter,
        'descriptionTranslationToken', null, 'descriptionTranslationTokenId', reportParameter.descriptionTranslationToken, 'reportParameter', null);
      this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationPromiseArray, reportParameter, 
        'nameTranslationToken', null, 'nameTranslationTokenId', reportParameter.nameTranslationToken, 'reportParameter', null);
    });
    this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationPromiseArray, this.selectedReport,
      'descriptionTranslationToken', null, 'descriptionTranslationTokenId', this.selectedReport.descriptionTranslationToken, 'report', null);
    this.jjtranslationService.addRequiredTranslationPromisesToPromiseArray(translationPromiseArray, this.selectedReport, 
      'nameTranslationToken', null, 'nameTranslationTokenId', this.selectedReport.nameTranslationToken, 'report', null);
    
    Promise.all(translationPromiseArray).then(() => {
      const promiseArray: Promise<any>[] = [];
      this.saveBaseReport()
      .then(() => {
        this.loaderService.display(true);
        this.selectedReport.reportParameters.forEach((parameter, index) => {
          parameter.order = index;
          parameter.reportId = this.selectedReport.id;
          promiseArray.push(this.saveReportParameter(parameter));
        });
        if (!isNullOrUndefined(this.selectedReport.removedParameters)) {
          this.selectedReport.removedParameters.forEach(parameter => {
            promiseArray.push(this.deleteReportParameter(parameter));
          });
          this.selectedReport.removedParameters = [];
        }
        this.selectedReport.reportQueries.forEach(query => {
          query.reportId = this.selectedReport.id;
          promiseArray.push(this.saveReportQuery(query.query));
        });
        this.removedQueries.forEach(query => {
          promiseArray.push(this.deleteReportQuery(query));
        });
        this.removeReportQueryTransformations.forEach(transformation => {
          promiseArray.push(this.deleteReportQueryTransformation(transformation));
        });
        this.removeReportQueryTransformations = [];
        this.removedQueries = [];
        Promise.all(promiseArray).then(() => {
          this.loaderService.display(false);
        }).catch(() => {
          this.loaderService.display(false);
        });
      });      
    })
  }

  saveBaseReport(): Promise<any> {
    let externalDataSourceId = 244;
    let param = this.commonService.getModifyArrayBody(this.selectedReport, ['reportParameters', 'reportQueries',
      'removedParameters', 'removedQueries', 'removeReportQueryTransformations']);
    if (isNullOrUndefined(this.selectedReport.id)) {
      externalDataSourceId = 243;
      param = this.commonService.getModifyArrayBody(this.selectedReport, ['reportParameters', 'reportQueries',
        'removedParameters', 'removedQueries', 'removeReportQueryTransformations', 'id']);
    }
    return new Promise((saveBaseReportResolve, saveBaseReportReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveBaseReportResult => {
        this.selectedReport.id = saveBaseReportResult.id;
        saveBaseReportResolve(saveBaseReportResult);
      })
      .catch(error => {
        saveBaseReportReject(error);
      });
    });
  }

  saveReportParameter(parameter): Promise<any> {
    let externalDataSourceId = 235;
    let param = this.commonService.getModifyArrayBody(parameter, ['queryEditorDebugValue']);
    if (isNullOrUndefined(parameter.id)) {
      externalDataSourceId = 234;
      param = this.commonService.getModifyArrayBody(parameter, ['queryEditorDebugValue', 'id']);
    }
    return new Promise((saveReportParameterResolve, saveReportParameterReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveReportParameterResult => {
        parameter.id = saveReportParameterResult.id;
        saveReportParameterResolve(saveReportParameterResult);
      })
      .catch(error => {
        // this.messagingService.showDefaultError('', '' + error);
        saveReportParameterReject(error);
      });
    });
  }

  deleteReportParameter(parameter): Promise<any> {
    return new Promise((deleteReportParameterResolve, deleteReportParameterReject) => {
      this.externaldatasourceService.executeExternalDataSource(236, [parameter])
      .then(deleteReportParameterResult => {
        deleteReportParameterResolve(deleteReportParameterResult);
      })
      .catch(error => {
        this.messagingService.showDefaultError('', '' + error);
        deleteReportParameterReject(error);
      });
    });
  }

  saveReportQuery(query): Promise<any> {
    const promiseArray: Promise<any>[] = [];
    let externalDataSourceId = 241;
    let newQuery = false;
    let param = this.commonService.getModifyArrayBody(query, ['reportQueryTransformations']);
    if (isNullOrUndefined(query.id)) {
      externalDataSourceId = 240;
      newQuery = true;
      param = this.commonService.getModifyArrayBody(query, ['reportQueryTransformations', 'id']);
    }
    return new Promise((saveReportQueryResolve, saveReportQueryReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveReportQueryResult => {
        query.id = saveReportQueryResult.id;
        if (newQuery) {
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(237, [this.selectedReport.id, query.id]));
        }
        query.reportQueryTransformations.forEach((transformation, index) => {
          transformation.order = index;
          promiseArray.push(this.saveReportQueryTransformation(transformation));
        });
        Promise.all(promiseArray)
        .then(() => { saveReportQueryResolve(saveReportQueryResult); })
        .catch(error => { saveReportQueryReject(error); });
      })
      .catch(error => {
        saveReportQueryReject(error);
      });
    });
  }

  deleteReportQuery(query): Promise<any> {
    return new Promise((deleteReportQueryResolve, deleteReportQueryReject) => {
      this.externaldatasourceService.executeExternalDataSource(239, [query.id])
      .then(deleteReportQueryResult => {
        this.externaldatasourceService.executeExternalDataSource(242, [query.query.id])
        .then(() => {
          deleteReportQueryResolve(deleteReportQueryResult);
        })
        .catch(error => {
          this.messagingService.showDefaultError('', '' + error);
          deleteReportQueryReject(error);
        });
      })
      .catch(error => {
        this.messagingService.showDefaultError('', '' + error);
        deleteReportQueryReject(error);
      });
    });
  }

  removeReportQuery(query) {
    this.commonService.removeItemFromArray(this.selectedReport.reportQueries, query);
    if (!isNullOrUndefined(query.id)) {
      this.removedQueries.push(query);
    }
  }

  addNewReporQuery() {
    const reportQuery = new Object();
    const query = new Object();
    query['defaultName'] = 'Neues Query';
    reportQuery['query'] = query;
    this.selectedReport.reportQueries.push(reportQuery);
  }

  newReport() {
    const newReport = new Object();
    newReport['defaultName'] = 'Neuer Report';
    newReport['reportParameters'] = [];
    newReport['reportQueries'] = [];
    newReport['templatePath'] = '';
    newReport['canGeneratePdf'] = false;
    newReport['outputNameTemplate'] = '';
    this.reports.push(newReport);
    this.selectedReport = newReport;
  }

  addTransformation(query, report) {
    const newTransformation = new Object();
    newTransformation['queryId'] = query.id;
    newTransformation['reportId'] = report.id;
    newTransformation['isStringArrayFromFirstColumn'] = false;
    newTransformation['isTopOneResult'] = false;
    query.reportQueryTransformations.push(newTransformation);
  }

  removeTransformation(array, item) {
    this.commonService.removeItemFromArray(array, item);
    if (!isNullOrUndefined(item.id)) {
      this.removeReportQueryTransformations.push(item);
    }
  }

  saveReportQueryTransformation(transformation): Promise<any> {
    let externalDataSourceId = 247;
    let param = this.commonService.getModifyArrayBody(transformation, []);
    if (isNullOrUndefined(transformation.id)) {
      externalDataSourceId = 246;
      param = this.commonService.getModifyArrayBody(transformation, ['id']);
    }
    return new Promise((saveReportQueryTransformationResolve, saveReportQueryTransformationReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [param])
      .then(saveReportQueryTransformationResult => {
        transformation.id = saveReportQueryTransformationResult.id;
        saveReportQueryTransformationResolve(saveReportQueryTransformationResult);
      })
      .catch(error => {
        // this.messagingService.showDefaultError('', '' + error);
        saveReportQueryTransformationReject(error);
      });
    });
  }

  deleteReportQueryTransformation(transformation): Promise<any> {
    return new Promise((deleteReportQueryTransformationResolve, deleteReportQueryTransformationReject) => {
      this.externaldatasourceService.executeExternalDataSource(248, [transformation.id])
      .then(() => {
        deleteReportQueryTransformationResolve(null);
      })
      .catch(error => {
        this.messagingService.showDefaultError('', '' + error);
        deleteReportQueryTransformationReject(error);
      });
    });
  }

  launchQueryBuilder(reportQuery, report) {
    this.applicationInfoService.miscSettings['queryEditorReportQuery'] = reportQuery;
    this.applicationInfoService.miscSettings['queryEditorReport'] = report;
    this.eventService.showJjPopup('Query-Builder', 'admin-query-builder', 90, true);
  }
}
