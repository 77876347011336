<div *ngIf="loadingData == true" >
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</div>

<div class="" *ngIf="noMailData == true">
    {{ 'EMailReview.Label.NoMailData' | texttransform }} 
</div>

<div class="emailreview__wrapper" *ngIf="template != null && noMailData==false">
    <div class="emailreview__details">
        <div class="emailreview__details__icon">
            <i class="fas fa-envelope fa-3x"></i>
        </div>
        <div class="emailreview__details__text">
            <div class="emailreview__details__subject">
                {{ mailSubject  }}
            </div>
    
            <div class="emailreview__details__sub">
                <div class="emailreview__details__to">
                    {{ 'EMailReview.Label.To' | texttransform }} 
                    <a [href]=getMailRef(mailTo)>{{ mailTo }}</a>
                </div>
                <div class="emailreview__details__date">
                    {{ mailDate | date: 'medium' }}
                </div>    
            </div>    
        </div>
    </div>
    <div class="emailreview__content">
        <div [innerHTML]="innerHTML"></div>
    </div>    
</div>

