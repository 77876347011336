<div class="dashboardControlGeneralWrapper" [ngClass]="{ 
    dashboardThemeWhite: dashboard.theme == 0,
    dashboardThemeBlue: dashboard.theme == 1,
    dashboardThemeCoral: dashboard.theme == 2
}">
    <div *ngIf="dashboard.dasbboardTitle" class="dashboardControlDataItemHeader">
        {{ dashboard.dasbboardTitle | texttransform }}
    </div>

    <!-- Keine Daten vorhanden -->
    <div *ngIf="dashboarddata[dataid] == null || dashboarddata[dataid] == undefined">
        {{ 'DashboardControl.Label.NoDataAvailable' | texttransform }}
    </div>

    <!-- Tabellenansicht -->
    <div *ngIf="dashboard.dashboardType == 0" class="dashboardControlGeneralWrapper__inner">
        <div class="dashboardTypeTableWrapper" *ngIf="headers != null">
            <div class="dashboardTypeTableWrapper__header">
                <div *ngFor="let header of headers" class="dashboardTypeTableWrapper__header__item">{{ header | texttransform }}</div>
            </div>
            <div class="dashboardTypeTableWrapper__data">
                <div *ngFor="let dataItem of dashboarddata[dataid]" class="dashboardTypeTableWrapper__data__line">
                    <div *ngFor="let header of headers" class="dashboardTypeTableWrapper__data__line__item">
                        {{ dataItem[header] }}
                    </div>
                </div>
            </div>
        </div>
    </div>    

    <div *ngIf="dashboard.dashboardType == 1" class="dashboardControlGeneralWrapper__inner">
        <div class="dashboardControlGeneralBar1">
            <div class="dashboardControlGeneralBar1__main">
                <!-- <div class="dashboardControlGeneralBar1__main__name">
                    {{ headers[0] }}
                </div> -->
                <div class="dashboardControlGeneralBar1__main__data">
                    <div *ngFor="let dataItem of dashboarddata[dataid]" class="dashboardControlGeneralBar1__main__dataWrapper">
                        <div class="d-flex maxWidth">
                            <div class="dashboardControlGeneralBar1__main__data__name">
                                {{ dataItem[headers[0]] }}
                            </div>
                            <!-- <div class="dashboardControlGeneralBar1__main__data__valueNumber everleadCoral">
                                {{ dataItem[headers[1]] }}
                            </div> -->
                            <div class="dashboardControlGeneralBar1__main__data__value">                                
                                <div class="dashboardControlGeneralBar1__main__data__value__inner maxWidth">
                                    <div class="dashboardControlGeneralBar1__main__data__value__inner__color" [style.width]="getBar1Width(dataItem[headers[1]])"></div>
                                    <div class="dashboardControlGeneralBar1__main__data__value__inner__value everleadCoral">{{ dataItem[headers[1]] }}</div>
                                </div>                                
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="dashboardControlGeneralBar1__footer">
                <div class="dashboardControlGeneralBar1__footer__name">{{ headers[1] }}</div>                
            </div> -->
        </div>
    </div>

    <div *ngIf="dashboard.dashboardType == 50">
        <app-custom-dashoardcontrol-microcampaigns></app-custom-dashoardcontrol-microcampaigns>
    </div>
    <div *ngIf="dashboard.dashboardType == 51">
        <app-custom-dashboardcontrol-userviplist></app-custom-dashboardcontrol-userviplist>
    </div>
    <div *ngIf="dashboard.dashboardType == 52">
        <app-custom-dashboardcontrol-bestnextcontact></app-custom-dashboardcontrol-bestnextcontact>
    </div>    
    <div *ngIf="dashboard.dashboardType == 53">
        <app-custom-dashboardcontrol-optinoptout></app-custom-dashboardcontrol-optinoptout>
    </div>    
</div>