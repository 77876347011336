<div class="nonExpertView__wrapper" [ngClass]=" {
    hideInDom: checkIfItemNeedsToBeHidden()
}" (click)="datarightsService.selectedGroup = dataRightGroup">

    <div class="nonExpertView__main " [style.margin-left.px]="30 * level" [ngClass]="{
        dataRightsItem__isSelected: datarightsService.selectedGroup == dataRightGroup
    }">
        <div class="nonExpertView__chevron" *ngIf="level > 0">
            <i class="fas fa-chevron-circle-right"></i>
        </div>        
        <div class="nonExpertView__name">            
            <label>{{ dataRightGroup.defaultName }}</label>
        </div>
        <div class="nonExpertView__aditionalInformation">
            <!-- <div class="nonExpertView__bonus" [title]="getAssignedFilterToolTip()"> -->
            <div class="nonExpertView__bonus" >
                <i class="fas fa-eye" *ngIf="dataRightGroup.isReadOnly" [tippy]="'DataRights.Label.AccessLevelOnlyRead' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>
                <i class="fas fa-feather" *ngIf="!dataRightGroup.isReadOnly" [tippy]="'DataRights.Label.AccessLevelFullAccess' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>
            </div>

            <div class="nonExpertView__bonus">
                <i class="fas fa-user" [tippy]="getAssignedUserToolTip()" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>{{ dataRightGroup.users?.length }}
            </div>
            <div class="nonExpertView__bonus">
                <i class="fas fa-filter" [tippy]="getAssignedFilterToolTip()"[tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>{{ dataRightGroup.filters?.length }}
            </div>
            <div class="nonExpertView__bonus"  
                (click)="editDataRightGroup(dataRightGroup)">
                <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" [tippy]="'DataRights.Label.EditGroup' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>
            </div>
            <div class="nonExpertView__bonus" (click)="deleteDataRightsGroup(dataRightGroup)" >
                <i [tippy]="'DataRights.Label.DeleteGroup' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}" class="fas fa-trash fingerCursor dataRightsItem__buttons__button" [ngClass]="{ 
                    hideInDom: dataRightGroup.children?.length > 0 ||
                        dataRightGroup.filters?.length > 0 ||
                        dataRightGroup.users?.length > 0
                }"></i>
            </div>
    
            <div class="nonExpertView__buttons__container dataRightsItem__buttons__container__chevron">
                <i class="fingerCursor fas " [tippy]="'DataRights.Label.ToggleView' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"
                (click)="toggleExpansion()" *ngIf="expandAll == false && dataRightGroup.children.length > 0" [ngClass]="{
                'fa-chevron-down': datarightsService.isExpanded[dataRightGroup.id] != true,
                'fa-chevron-up': datarightsService.isExpanded[dataRightGroup.id] == true
                }"></i>
            </div>
        </div>
    </div>
    <div [style.margin-left.px]="30 * level" class="nonExpertView__newSubGroup fingerCursor" (click)="createDataRightsGroup(dataRightGroup)">
        <i class="fas fa-plus"></i>
        {{'DataRights.Label.CreateNewSubGroup' | texttransform }}
    </div>
    <div class="nonExpertView__expandedArea" *ngIf="datarightsService.isExpanded[dataRightGroup.id] == true || expandAll">
        <app-datarights-item *ngFor="let dataRightGroupChild of dataRightGroup.children"
            [dataRightGroup]="dataRightGroupChild" 
            [level]="newLevel()" 
            [availableUsers]="availableUsers"
            [availableFilters]="availableFilters"
            [expandAll]="expandAll"
            [generalFilterString]="generalFilterString"
            [generalFilterUser]="generalFilterUser"
            [generalFilterAccessRights]="generalFilterAccessRights"
        ></app-datarights-item>
    </div>
</div>