import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-multi-select',
  templateUrl: './custom-multi-select.component.html',
  styleUrls: ['./custom-multi-select.component.scss']
})
export class CustomMultiSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
