<div class="leadmanagementDashboard__wrapper">
    <div class="leadmanagementDashboard__content">
        <div class="leadmanagementDashboard__content__left">
            <div class="leadmanagementDashboard__content__left__leads">
                <app-dashbdashboardcomponent-kpis [showKPIs]="false" class="maxHeight"></app-dashbdashboardcomponent-kpis>
            </div>
            <div class="leadmanagementDashboard__content__left__contacts">
                <app-dashboardcomponent-leadstates></app-dashboardcomponent-leadstates>
            </div>
        </div>
        <div class="leadmanagementDashboard__content__right">
            <app-dashbdashboardcomponent-mytopcontacts class="maxHeight maxWidth"></app-dashbdashboardcomponent-mytopcontacts>
            <!-- <app-dashbdashboardcomponent-myday></app-dashbdashboardcomponent-myday> -->
        </div>
    </div>
</div>