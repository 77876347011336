<div>
  <div *ngIf="showPopup">

    <h1>hi</h1>
    <p-dialog [header]="popupTitle | texttransform" 
        id="popUpmodule"
        [(visible)]="showPopup"
        [modal]="true"
        resizable="true"
        focusTrap="true"
        blockScroll="true"
        maximizable="true"
        [autoZIndex]="false"
        [closable]="popupIsClosable"  
        [closeOnEscape]="popupIsClosable" 
        [style]="style()"
        [contentStyle]="contentstyle()"
        >
        <div [innerHTML]="innerHTML" style="z-index: 200"></div>
    </p-dialog>
  </div>  
</div>
  