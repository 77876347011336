import { Injectable } from '@angular/core';
import { ExternaldatasourceService, ExternalDataSource_QueryType } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { Dictionary } from 'app/core/dictionary';
import { isNullOrUndefined } from 'util';
import { ObjectDeserializeService } from 'app/jollyjupiter/pipe/object-deserialize.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Injectable({
  providedIn: 'root'
})
export class CompactListDataService {

  constructor(
    private externalDatasourceService: ExternaldatasourceService,
    private uiService: UiService,
    private objectDeserializeService: ObjectDeserializeService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private commonService: CommonService,
    private sharedApi: SharedAPI,
    private applicationInfoService: ApplicationInfoService
  ) { }

  fillExternalDataSourceDictionary(controlDefinition: any): Promise<any> {
    return new Promise((fillExternalDataSourceDictionaryResolve, fillExternalDataSourceDictionaryReject) => {
      const promiseArray: Promise<any>[] = [];
      const externalDataSourceResults: Dictionary<any> = new Dictionary();
      if (controlDefinition.childs) {
        controlDefinition.childs.forEach(child => {
          child.childs.forEach(subChild => {
            if (subChild.uiControlExternalDataSources.length > 0) {
              subChild.uiControlExternalDataSources.forEach(uiControlExternalDataSource => {
                if (uiControlExternalDataSource.externalDataSource.queryType.toLowerCase() === ExternalDataSource_QueryType.Query) {
                  const extDataSource = uiControlExternalDataSource.externalDataSource;
                  promiseArray.push(this.externalDatasourceService.executeQuery(extDataSource).then(result => {
                    externalDataSourceResults.Add(extDataSource.id, result);
                  }));
                }
              });
            } else {
              const externalDataSource = this.uiService.getExternalDatasourceFromControlEntityMember(subChild);
              if (externalDataSource) {
                promiseArray.push(this.externalDatasourceService.executeQuery(externalDataSource).then(memberResult => {
                  externalDataSourceResults.Add(externalDataSource.id, memberResult);
                }));
              } else {
                const lookUp = this.uiService.getEntityMemberLookupTable(subChild);
                if (!isNullOrUndefined(lookUp)) {
                  promiseArray.push(this.externalDatasourceService.executeLookUpTable(lookUp)
                    .then(lookupTableResult => {
                      externalDataSourceResults.Add(lookUp.lookupTableSource + lookUp.lookupTableId, lookupTableResult);
                    })
                  );
                }
              }
            }
          });
        });
        Promise.all(promiseArray).then(() => {
          fillExternalDataSourceDictionaryResolve(externalDataSourceResults);
        })
        .catch(error => {
          fillExternalDataSourceDictionaryReject(error);
        });
      }
    });
  }

  getAdditionalColumnString(controlDefinition, sortingArray, filterValueArray): string {
    let returnValue = '';
    controlDefinition.childs.forEach((child, index) => {
      if (!isNullOrUndefined(filterValueArray[index]) || !isNullOrUndefined(sortingArray[index])) {
        let sortDirection = '0';
        const filterParameter = isNullOrUndefined(filterValueArray[index]) ? '' : filterValueArray[index];
        if (sortingArray[index] === true) { sortDirection = '1'; }
        if (sortingArray[index] === false) { sortDirection = '2'; }
        if (returnValue !== '') {
          returnValue = returnValue.concat(', ');
        }
        returnValue = returnValue.concat(
          '{ columnName: "', child.childs[0].value, '", filter: "', filterParameter, '"', ' sortDirection: ',
          sortDirection , ' }'
        );
      }
    });
    return returnValue;
  }

  getSubChildValue(externalDataSourceResults, subChild: any, dataItem: any, fullText = true, childs = []) {
    let labelDetailName = '';
    if (subChild.displayText) {
      labelDetailName = this.axivasTranslateService.getTranslationTextForToken(subChild.displayText);
    }

    let labelContent = '';
    if (!subChild.value) {
      return labelContent;
    }
    let extDataSource = null;
    let lookUpTable = null;
    const splittedValue: any[] = subChild.value.split(';');
    if (subChild.controlType === 99) {
      const dataArray = this.objectDeserializeService.transform(dataItem, splittedValue[0]);
      dataArray.forEach(dataArrayItem => {
        if (labelContent !== '') {
          labelContent = labelContent.concat(' - ');
        }
        labelContent = labelContent.concat(this.objectDeserializeService.transform(dataArrayItem, splittedValue[1]));
      });
      return labelContent;
    }
    splittedValue.forEach((item, index) => {
      let itemValue = '';
      if (subChild.uiControlExternalDataSources.length > 0) {
        subChild.uiControlExternalDataSources.forEach(uiControlExternalDataSource => {
          if (uiControlExternalDataSource.externalDataSource.queryType.toLowerCase() === ExternalDataSource_QueryType.Query) {
            extDataSource = uiControlExternalDataSource.externalDataSource;
          }
        });
      } else {
        extDataSource = this.uiService.getExternalDatasourceFromControlEntityMember(subChild);
      }
      if (extDataSource) {
        if (externalDataSourceResults.ContainsKey(extDataSource.id)) {
          itemValue = this.GetLabelContentFromExternalDataSource(externalDataSourceResults, extDataSource,
            this.objectDeserializeService.transform(dataItem, item));
        }
      } else {
        lookUpTable = this.uiService.getEntityMemberLookupTable(subChild);
        if (!isNullOrUndefined(lookUpTable)) {
          extDataSource = new Object();
          extDataSource['id'] = lookUpTable.lookupTableSource + lookUpTable.lookupTableId;
          extDataSource['value'] = 'defaultName';
          extDataSource['dataKey'] = 'id';
          extDataSource['dataValue'] = 'defaultName';
          itemValue = this.GetLabelContentFromExternalDataSource(externalDataSourceResults, extDataSource,
            this.objectDeserializeService.transform(dataItem, item));
        } else {
          itemValue = this.objectDeserializeService.transform(dataItem, item);
        }
      }

      if (childs.length > 1) {
        itemValue = this.formatText(subChild.controlType, itemValue); // TODO CS
      }
      if (index > 0) {
        itemValue = itemValue.concat(' ');
      }
      labelContent = labelContent.concat(itemValue, ' ');
    });

    if (fullText && labelDetailName !== '') {
      if (labelContent.trim() !== '') {
        labelContent = '<b>' + labelDetailName + '</b> ' + labelContent;
      } else {
        return '';
      }
    }
    return labelContent;
  }

  formatText(controlType: any, labelContent: string): string {
    if ((labelContent === null) || (labelContent === '')) { return ''; }
    switch (controlType) {
      case 1:
        if (labelContent.trim() !== '') {
          labelContent = new Date(labelContent.trim()).toLocaleString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          });
        }
        break;
      default:
        return labelContent;
    }
    return labelContent;
  }

  GetLabelContentFromExternalDataSource(externalDataSourceResults, extDataSource: any, value: string): string {
    let result = '';
    if (externalDataSourceResults.Item(extDataSource.id) !== null) {
      externalDataSourceResults.Item(extDataSource.id).forEach(getLabelContentFromExternalDataSourceElement => {
        if (getLabelContentFromExternalDataSourceElement[extDataSource.dataKey] === value) {
          const valueArray = extDataSource.dataValue.split(';');
          valueArray.forEach(valueArrayItem => {
            if (valueArrayItem === 'defaultName') {
              result = result.concat(this.axivasTranslateService.getTranslationTextForToken(
                this.commonService.getTranslationValueFromArray(getLabelContentFromExternalDataSourceElement)), ' ');
            } else {
              result = result.concat(this.objectDeserializeService.transform(
                getLabelContentFromExternalDataSourceElement, valueArrayItem), ' ');
            }
            // result = result.concat(getLabelContentFromExternalDataSourceElement[valueArrayItem], ' ');
          });
        }
      });
    }
    return result;
  }

  exportData(controlDefinition, filteredItemsDisplay, fileNameAddition = '') {
    let counter = 0;
    const itemArray = [];
    const valueArray = [];
    const inactiveList = [];
    let body = `{ "headers": [<0>], "data": [<1>] }`;
    const headerArray = [];

    if (isNullOrUndefined(fileNameAddition)) {
      fileNameAddition = '';
    }

    const headerTitleArray = [];
    controlDefinition.childs.forEach(child => {
      if (child.isActive) {
        let itemName = this.axivasTranslateService.getTranslationTextForToken(child.displayText);
        let itemNameCounter = 1;
        while (this.commonService.checkIfItemIsInArray(headerTitleArray, itemName)) {
          itemName = this.axivasTranslateService.getTranslationTextForToken(child.displayText).concat(itemNameCounter.toString());
          itemNameCounter ++;
        }
        let itemType = 'string';
        if (child.childs[0].controlType === 1) {
          itemType = 'date';
        }
        if (child.childs[0].controlType === 2) {
          itemType = 'integer';
        }
        itemArray.push(itemType);
        valueArray.push(child.childs[0].value);
        // Header
        headerTitleArray.push(itemName);
        headerArray.push('{ "name": "' + itemName + '", "type": "' + itemType + '" }');
      } else {
        inactiveList.push(counter);
        itemArray.push(null);
        valueArray.push(null);
      }
      counter ++;
    });

    // Data
    const dataArray = [];
    filteredItemsDisplay.forEach(filterItem => {
      let item = '';
      for (let i = 0; i < counter; i++) {
        if (!this.commonService.checkIfItemIsInArray(inactiveList, i)) {
          if (item !== '') {
            item = item.concat(', ');
          }
          if (itemArray[i] !== 'date') {
            let dataItem = this.commonService.getValueByType(filterItem[i]);
            // dataItem = this.commonService.cleanStringValue(dataItem);
            dataItem = dataItem.replace(/\"/g, '');
            dataItem = dataItem.replace(/"\\/g, '');
            dataItem = dataItem.replace(/\\/g, '');
            dataItem = dataItem.replace(/"/g, '');
            item = item.concat('"', dataItem, '"');
          } else {
            item = item.concat(this.commonService.getValueByType(filterItem[valueArray[i]]));
          }
        }
      }
      dataArray.push('[ ' + item + ' ]');
    });
    body = body.replace('<0>', headerArray.toString());
    body = body.replace('<1>', dataArray.toString());
    this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
      this.axivasTranslateService.getTranslationTextForToken('CompactList.ExportData.Message'), false);
    this.sharedApi.createFromPassedData(body).subscribe(createFromPassedDataResult => {
      this.commonService.downloadFile(createFromPassedDataResult,
        'application/' + 'xlsx',
        ''.concat(this.commonService.removeIllegalCharactersFromFileName(this.applicationInfoService.projectName), '_',
          'export', '_', this.commonService.getDateTimeString(new Date(Date.now())),
          fileNameAddition === '' ? '' : '_' , fileNameAddition, '.xlsx'));
    });
  }
}

