import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-datadelivery',
  templateUrl: './datadelivery.component.html',
  styleUrls: ['./datadelivery.component.scss']
})
export class DatadeliveryComponent implements OnInit {
  getDataDeliveryExternalDataSourceId = 77;
  updateDataDeliveryExternalDataSourceId = 80;
  dataDeliveries = [];
  currentDataDelivery = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.getDataDeliveries();
  }

  getDataDeliveries() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(this.getDataDeliveryExternalDataSourceId)
    .then(getDataDeliveriesResult => {
      this.dataDeliveries =  getDataDeliveriesResult;
      this.loaderService.display(false);
    })
    .catch(error => {
      this.messagingService.showDefaultError('getDataDeliveries', '' + error, true);
      this.loaderService.display(false);
    });
  }

  updateDataDelivery(dataDelivery) {
    const promiseArray: Promise<any>[] = [];

    this.loaderService.display(true);
    dataDelivery.deliveredRecords.forEach(delivery => {
      promiseArray.push(this.externaldatasourceService.executeExternalDataSource(this.updateDataDeliveryExternalDataSourceId,
        [delivery.id, delivery.recordsDelivered]));
    });
    Promise.all(promiseArray).then(() => {
      this.getDataDeliveries();
      this.loaderService.display(false);
    })
    .catch(error => {
      this.messagingService.showDefaultError('getDataDeliveries', '' + error, true);
      this.loaderService.display(false);
    });
  }
}
