<div class="audit__container">

    <div class="audit__header" *ngIf="onlySettings == false">
        <div class="audit__header__label">
            <ng-container *ngIf="mode == 1 && userService.hasPermission('CanCreateAuditDefinition')">{{
                'Audit.Label.HeaderSettings' | texttransform }}</ng-container>
            <ng-container *ngIf="mode == 2">{{ 'Audit.Label.HeaderRevert' | texttransform }}</ng-container>
        </div>
        <div class="audit__header__switch fingerCursor" (click)="mode=2" *ngIf="mode == 1 && onlySettings == false">
            <i class="fas fa-undo" [title]="'Audit.Label.HeaderRevert' | texttransform"></i>
        </div>
        <div class="audit__header__switch fingerCursor" (click)="mode=1" *ngIf="mode == 2">
            <i class="fas fa-edit" [title]="'Audit.Label.HeaderSettings' | texttransform"></i>
        </div>
    </div>
    
    <div *ngIf="mode==2">
        <div class="audit__entitySelection__header">
            {{ 'Audit.Label.Section' | texttransform}}
        </div>
        <div class="audit__entitySelection">
            <div class="audit__entitySelection__type">
                <div class="audit__entitySelection__type__button"
                    [ngClass]="{ 'audit__entitySelection__type__button__active': auditSource == 1 }"
                    (click)="setAuditSource(1)" *ngIf="applicationInfoService.currentAccount != null && applicationInfoService.currentAccount?.isDummyAccount == false">{{
                    'Audit.Label.CurrentAccount' | texttransform }}</div>
                <div class="audit__entitySelection__type__button"
                    [ngClass]="{ 'audit__entitySelection__type__button__active': auditSource == 2 }"
                    (click)="setAuditSource(2)" *ngIf="applicationInfoService.currentContact != null">{{
                    'Audit.Label.CurrentContact' | texttransform }}</div>
                <div class="audit__entitySelection__type__button" *ngIf="applicationInfoService.isDeveloper"
                    [ngClass]="{ 'audit__entitySelection__type__button__active': auditSource == 3 }"
                    (click)="setAuditSource(3)">{{ 'Audit.Label.ExpertMode' | texttransform }}</div>
            </div>
    
            <div class="audit__entitySelection__details">
                <div *ngIf="auditSource == 1">
                    <div>{{ applicationInfoService?.currentAccount?.name1 }}</div>
                    <!-- <div>{{ applicationInfoService?.currentAccount?.zipCode }} {{ applicationInfoService?.currentAccount?.city
                    }}</div>
                    <div>{{ applicationInfoService?.currentAccount?.street }}</div>
                    <div>{{ applicationInfoService?.currentAccount?.phone }}</div> -->
                </div>
                <div *ngIf="auditSource == 2">
                    {{ applicationInfoService?.currentContact?.firstName }} {{
                    applicationInfoService?.currentContact?.lastName }}
                </div>
                <div *ngIf="auditSource == 3">
    
                </div>
            </div>            
        </div>
    </div>
 

    <div class="audit__changedData" *ngIf="mode==2">
        <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
    
            <ng-container matColumnDef="field">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.ChangedValue' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    {{ getEntityMemberName(element.entityId, element.entityMemberId) | texttransform }}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="oldValue">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.OldValue' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    {{ element.oldValueText }}
                </td>
            </ng-container>
            <ng-container matColumnDef="newValue">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.NewValue' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    {{ element.newValueText }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.User' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    {{ getUserName(element.userId) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Source' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <ng-container *ngIf="element.source != null">{{ element.source }}</ng-container>
                    <ng-container *ngIf="element.source == null">{{ 'Audit.Label.SourceEverlead' | texttransform }}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Date' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    {{ element.timestamp | date: 'medium' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="button">
                <th mat-header-cell *matHeaderCellDef class="audit__button"></th>
                <td mat-cell *matCellDef="let element">            
                    <div class="audit__changedData__item__value audit__changedData__item__revert fingerCursor"
                    (click)="revertItem(element)">
                    <i class="fas fa-undo fa-lg"></i>
                </div>
    
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
                hideInDom: (row.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
                projectSettings__isInactive: row.isActive == false
            }"></tr>
        </table>
    </div>






    <div class="projectSettings__header" *ngIf="mode==1">
        <div class="projectSettings__header__newItem fingerCursor" (click)="createAuditDefinition()">
            <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>                   
            {{ 'Audit.Label.CreateNewDefinition' | texttransform }}
        </div>
        <div class="projectSettings__header__interaction">            
            <div class="projectSettings__header__filter">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                <input [(ngModel)]="filterStringDefinition" class="projectSettings__header__showOnlyActive__filter">
            </div>
        </div>
    </div>

    <div class="audit__changedData" *ngIf="mode==1">
        <table mat-table #table [dataSource]="dataSourceDefinitions" style="width: 100%" matSort>       
    
            <ng-container matColumnDef="entity">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Entity' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <select [(ngModel)]="element.entityId">
                        <option [ngValue]=null></option>
                        <option [ngValue]="entity.id" *ngFor="let entity of entities">
                            <span *ngIf="!entity.nameTranslationToken">{{ entity.entityName }}</span>
                            <span *ngIf="entity.nameTranslationToken">{{ entity.nameTranslationToken.tokenFullName | texttransform }}</span>
                        </option>
                    </select>
                </td>
            </ng-container>

            <ng-container matColumnDef="member">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.ChangedValue' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <select [(ngModel)]="element.entityMemberId"
                        (change)="saveAuditDefinition(element)">
                        <option [ngValue]=null></option>
                        <option [ngValue]="member.id" *ngFor="let member of getEntityMember(element.entityId)">
                            <span *ngIf="!member.nameTranslationToken">{{ member.defaultName | texttransform }}</span>
                            <span *ngIf="member.nameTranslationToken">{{ member.nameTranslationToken.tokenFullName | texttransform }}</span>
                        </option>
                    </select>
                </td>
            </ng-container>

            <ng-container matColumnDef="start">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.Start' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <input [(ngModel)]="element.auditStart"
                        (dateTimeChange)="saveAuditDefinition(element)" [owlDateTimeTrigger]="dt7"
                        [owlDateTime]="dt7" readonly class="audit__dateTime" appendTo="body" aria-modal="true">
                    <owl-date-time #dt7></owl-date-time>
                </td>
            </ng-container>

            <ng-container matColumnDef="end">
                <th mat-header-cell *matHeaderCellDef>{{ 'Audit.Label.End' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">            
                    <input [(ngModel)]="element.auditEnd"
                        (dateTimeChange)="saveAuditDefinition(element)" [owlDateTimeTrigger]="dt8"
                        [owlDateTime]="dt8" readonly class="audit__dateTime" appendTo="body" aria-modal="true">
                    <owl-date-time #dt8></owl-date-time>
                </td>
            </ng-container>

            <ng-container matColumnDef="button">
                <th mat-header-cell *matHeaderCellDef class="audit__button"></th>
                <td mat-cell *matCellDef="let element">            
                    <div class="audit__changedData__item__value audit__changedData__item__revert fingerCursor"
                        (click)="deleteAuditDefinition(element)">
                        <i class="fas fa-trash fa-lg"></i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDefinitions; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDefinitions;" [ngClass]="{             
                hideInDom: (row.isActive == false && showOnlyActive)  || definitionItemIsFiltered(row) == true,
                projectSettings__isInactive: row.isActive == false
            }"></tr>
        </table>
    </div>
</div>