import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';
import { WizardMode, WizardService } from '../wizard/wizard.service';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  types = [];
  projects = [];
  filterString = '';
  validCustomers = [];
  selectedCustomer = null;
  customers = [];
  blacklists = []

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private wizardService: WizardService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.getProjects();
    this.selectedCustomer = this.applicationInfoService.customerID;
    this.getBlacklistsForCustomer();
    this.getTypes();

    this.customEventSubscription = this.eventService.customEvent.subscribe(result => {
      if (result == 'blacklistimported') {
        this.getBlacklistsForCustomer();
      }      
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getTypes() {
    this.externaldatasourceService.getLookupTableValues(74, 2).then(getTypesResult => { this.types = getTypesResult; });
  }

  getProjects() {
    this.validCustomers = [];
    this.projects = [];
    this.externaldatasourceService.executeExternalDataSource(2)
    .then(getProjectsResult => {
      this.externaldatasourceService.executeExternalDataSource(8)
      .then(getCustomerResults => {
        this.projects = getProjectsResult;
        this.projects.forEach(project => {
          if (!this.commonService.checkIfItemIsInArray(this.validCustomers, project.customerId)) {
            this.validCustomers.push(project.customerId);
            this.customers.push(getCustomerResults.find(customerResultItem => customerResultItem.id == project.customerId));
          }
        }); 
        this.customers.sort((a, b) => a.customerName < b.customerName ? -1 : 1);
      });
    });  
  }

  getBlacklistsForCustomer() {
    this.externaldatasourceService.executeExternalDataSource(686, [this.selectedCustomer])
    .then(getBlacklistsForCustomerResult => {
      this.blacklists = getBlacklistsForCustomerResult;
    });
  }

  createBlacklistItem() {
    this.externaldatasourceService.executeExternalDataSource(687, [
      this.commonService.getModifyArrayBody({
        customerId: this.applicationInfoService.customerID,
        projectId: null,
        value: 'value',
        blacklistTypeLookupId: 5717
      }, [])
    ]).then(() => { this.getBlacklistsForCustomer(); });    
  }

  saveItem(blacklist) {
    this.externaldatasourceService.executeExternalDataSource(688, [
      this.commonService.getModifyArrayBody(blacklist, [])
    ]);
  }

  deleteBlacklistItem(blacklist) {
    this.externaldatasourceService.executeExternalDataSource(689, [blacklist.id])
    .then(() => {
      this.commonService.removeItemFromArray(this.blacklists, blacklist);
    });
  }

  getValidProjects(customerId) {
    return this.projects.filter(project => project.customerId == customerId);
  }

  changeCustomer(blacklist) {
    if (blacklist.customerId == null) { blacklist.projectId = null; }
    this.saveItem(blacklist);
  }

  itemIsFiltered(blacklist) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(blacklist.value, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(blacklist.regex, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  importBlacklist() {
    this.wizardService.showWizard(WizardMode.BlackListImport, { types: this.types }, '80vh', '50vh')
  }
}
