import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contentlibrary',
  templateUrl: './contentlibrary.component.html',
  styleUrls: ['./contentlibrary.component.scss']
})
export class ContentlibraryComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  contentLibraries = [];
  filterString = '';
  editMode = false;
  selectedContentLibrary = null;
  libraryItems = null;
  selectedTopFolder = null;
  startIId = null;

  libraryObject = null;
  createLibrary = false;
  editLibrary = false;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    public userService: UserService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private sharedAPI: SharedAPI,
    private eventService: EventService,
    public packagefeatureService: PackagefeatureService
  ) { }

  ngOnInit(): void {
    const featureId = Number(this.applicationInfoService.applicationSettings['featureId_contentLibrary']);    
    if (this.packagefeatureService.selectedPackageHasFeature(featureId) == false) {
      this.packagefeatureService.showFeatureInfoPage(featureId);
      return;
    }

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'addFilteToContentLibrary') {
        this.libraryItems.push(event.value);
      }
      if (event.id == 'createFolderInFolder') {
        this.newFolder(this.selectedContentLibrary, event.value);
      }
      if (event.id == 'resetLibrary') {
        this.libraryItems = null;
        this.getContentLibraries();
      }

      if (event.id == 'loadContentLibrary') {
        this.startIId = null;
        this.loadContentLibrary(event.value);
      }
    });

    const startPath = localStorage.getItem('locationStartInfo');

    if (!this.commonService.isNullOrUndefined(startPath)) {
      const startData = JSON.parse(startPath);
      if (startData.cliid != null) {
        this.startIId = startData.cliid;
      }
      if (startData.clid != null) {
        this.getContentLibraries(startData.clid).then(() => {});
      } else {
        this.getContentLibraries();
      }
    } else {
      this.getContentLibraries();
    }
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    this.applicationInfoService.miscSettings['currentContentLibraryFolder'] = null;
    this.applicationInfoService.miscSettings['currentContentLibary'] = null;
  }

  getContentLibraries(contentLibraryId = null): Promise<any> {
    return new Promise((getContentLibrariesResolve, getContentLibrariesReject) => {
      this.selectedContentLibrary = null;
      this.externaldatasourceService.executeExternalDataSource(809).then(getContentLibrariesResult => {
        this.contentLibraries = getContentLibrariesResult;
        if (contentLibraryId != null) {
          const library = getContentLibrariesResult.find(resultItem => resultItem.id == Number(contentLibraryId) );
          if (library) {
            this.loadContentLibrary(library);
          }
        }
        getContentLibrariesResolve(getContentLibrariesResult);
      }).catch(error => { getContentLibrariesReject(error); });
    });
  }

  updateContentLibrary(contentLibrary) {
    this.externaldatasourceService.executeExternalDataSource(807, [
      this.commonService.getModifyArrayBody(contentLibrary, ['isExpanded'])
    ]).then(() => { this.editLibrary = false; });
  }

  deleteContentLibrary(contentLibrary) {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('ContentLibrary.DeleteContentLibrary.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('ContentLibrary.DeleteContentLibrary.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        this.externaldatasourceService.executeExternalDataSource(808, [
          contentLibrary.id
        ]).then(() => {
          this.loaderService.display(false);
          this.commonService.removeItemFromArray(this.contentLibraries, contentLibrary);
        }).catch(() => {
          this.loaderService.display(false);
        });
      }
    });
  }

  prepareCreation() {
    this.libraryObject = {
      projectId: this.applicationInfoService.projectID,
      defaultName: 'New Content',
      isActive: true,
      cloudConnectionId: 9,
      maxContentLengthInMb: 50
    };
    this.createLibrary = true;
  }

  closeEditWindow() {
    this.editLibrary = false;
    this.createLibrary = false;
  }

  createContentLibrary() {
    this.externaldatasourceService.executeExternalDataSource(806, [
      this.commonService.getModifyArrayBody(this.libraryObject, [])
    ]).then(getContentLibrariesResult => {
      this.libraryObject['id'] = getContentLibrariesResult.id;
      this.contentLibraries.push(this.libraryObject);
      this.createLibrary = false;
    });
  }

  editContentLibrary(contentLibrary) {
    this.libraryObject = contentLibrary;
    this.editLibrary = true;
  }

  newFolder(contentLibrary, parentFolderId = null) {
    const contentLibraryItem = {
      projectId: this.applicationInfoService.projectID,
      libraryId: contentLibrary.id,
      folderName: 'Documents/Everlead/Libraries/'.concat(
        this.applicationInfoService.projectID.toString(),
        '/',
        contentLibrary.id,
        '/',
        this.commonService.createGuid()
      ),
      displayName: 'Neuer Ordner',
      parentFolderId: parentFolderId,
    }
    if (parentFolderId != null) {
      contentLibraryItem.folderName = this.commonService.createGuid();
    }

    this.loaderService.display(true);
    this.sharedAPI.contentLibraryCreatFolder(contentLibraryItem).subscribe(
      newFolderResult => {
      contentLibraryItem['id'] = newFolderResult.id;
      this.getLibraryItems(contentLibrary);
      this.loaderService.display(false);
    }, error => {
      this.loaderService.display(false);
      console.error('newFolder', error);
    });
  }

  loadContentLibrary(contentLibrary) {
    this.libraryItems = null;
    setTimeout(() => {
      this.selectedContentLibrary = contentLibrary;
      this.getLibraryItems(this.selectedContentLibrary);
      this.selectedTopFolder = null;
      this.applicationInfoService.miscSettings['currentContentLibary'] = contentLibrary;
    }, 200);
  }

  getLibraryItems(library) {
    this.externaldatasourceService.executeExternalDataSource(811, [library.id])
    .then(getLibraryItemsResult => {
      this.libraryItems = getLibraryItemsResult;
    });
  }

  saveContentLibraryItem(contentLibraryItem) {
    this.externaldatasourceService.executeExternalDataSource(812, [
      this.commonService.getModifyArrayBody(contentLibraryItem, ['editMode', 'createdAt', 'createdBy'])
    ]);
  }

  isItemFiltered(contentLibrary) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(contentLibrary.defaultName, this.filterString)) {
      returnValue = false;
    }

    if (contentLibrary.isVisible == false && this.applicationInfoService.isDeveloper == false) {
      returnValue = true;
    }
    return returnValue;
  }
}
