import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-logical-entities',
  templateUrl: './logical-entities.component.html',
  styleUrls: ['./logical-entities.component.scss']
})
export class LogicalEntitiesComponent implements OnInit {
  logicalEntities = [];
  projects = [];
  entities = [];
  uiEntityInstanceIds = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
    this.getLogicalEntities();
    this.getProjects();
    this.getEntities()
  }

  getEntities() {
    this.entities = this.applicationInfoService.getEntities();
  }

  getUiEntityInstanceIds() {
    this.entities = this.applicationInfoService.getUiEntityInstanceIds();
  }

  getLogicalEntities() {
    this.externaldatasourceService.executeExternalDataSource(595)
    .then(getLogicalEntitiesResult => {
      this.logicalEntities = getLogicalEntitiesResult;
    });
  }

  getProjects() {
    this.externaldatasourceService.executeExternalDataSource(2)
    .then(getProjectsResult => {
      this.projects = getProjectsResult;
    });
  }
    
  createNewLogicalEntity() {
    this.externaldatasourceService.executeExternalDataSource(605, [
      this.commonService.getModifyArrayBody({ 
        projectId: null, 
        defaultName: 'Neue logicalEntity',
        entityId: 1,
        uiEntityInstanceId: 1
      }, [])
    ])
    .then(() => { this.getLogicalEntities(); });
  }

  saveLogicalEntity(logicalEntity) {
    this.externaldatasourceService.executeExternalDataSource(606, [
      this.commonService.getModifyArrayBody(logicalEntity, [])
    ])
    .then(getLogicalEntitiesResult => {
      this.logicalEntities = getLogicalEntitiesResult;
    });
  }
}
