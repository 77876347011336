import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardMode, WizardService } from '../wizard/wizard.service';
import { LoaderService } from 'app/shared/service/loader-service';

export enum EventbookingBatchType {
  MicroCampaign = 7422,
  Mailshot = 7421
}

@Injectable({
  providedIn: 'root'
})

export class EventbookingbatchService {
  prepareBookingBatchExternalDataSourceId = 850;
  startBookingBatchExternalDataSourceId = 851;
  stopBookingBatchExternalDataSourceId = 852;
  getBookingBatchExternalDataSourceId = 853;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private wizardService: WizardService,
    private loaderService: LoaderService
  ) { }

  
  createMicroCampaign(batchName, batchLookupId, showWizard = false, consentTypes = null, mediumTypes = null, lastXDays = 0, newsletter = false): Promise<any> {
    return new Promise((createMicroCampaignResolve, createMicroCampaignReject) => {
      const wizardArray = {
        batchName: batchName,
        consentTypes: consentTypes,
        mediumTypes: mediumTypes,
        lastXDays: lastXDays,
        batchLookupId: batchLookupId,
        projectId: this.applicationInfoService.projectID,
        poolId: this.applicationInfoService.poolId,
        queryEntityId: 4,
        bookingVersion: 2,
        receivers: 0,
        newsletterMicroCampaignResultId: null,
        newsletter: newsletter,
        status: 7642,
        campaignId: this.applicationInfoService.campaingnModeId
      }
      if (newsletter) {
        wizardArray.status = 9139;
      }
      let wizardValuesString = JSON.stringify(wizardArray);
      wizardValuesString = wizardValuesString.replace(/"/g, '\\"');      
      this.externaldatasourceService.executeExternalDataSource(933, [
        batchName,
        batchLookupId,
        wizardValuesString,
        wizardArray.status
      ]).then(createCampaignResult => {       
        wizardArray['id'] = createCampaignResult.id;

        if (showWizard) {
          switch (batchLookupId) {
            case EventbookingBatchType.MicroCampaign:
              this.wizardService.showWizard(WizardMode.MicroCampaignBatch, wizardArray)
              break;
            case EventbookingBatchType.Mailshot:
              this.wizardService.showWizard(WizardMode.Mailshot, wizardArray)
              break;                
          }        
        } 
        createMicroCampaignResolve(createCampaignResult)     
      }).catch(error => { createMicroCampaignReject(error); });  
    });
  }

  finalizeMailshot(eventBookingBatch) {
    const wizardArray = JSON.parse(eventBookingBatch.wizardValues);
    this.wizardService.showWizard(WizardMode.MailshotFinal, wizardArray);
  }

  startEventBookingBatch(eventBookingBatch) {
    switch (eventBookingBatch.lookupId) {
      case EventbookingBatchType.MicroCampaign:
        this.loaderService.display(true);
        this.externaldatasourceService.executeExternalDataSource(this.startBookingBatchExternalDataSourceId, [eventBookingBatch.id])
        .then(startEventBookingBatchResult => { 
          eventBookingBatch.startedAt = startEventBookingBatchResult.startedAt;
          this.loaderService.display(false);
        }).catch(error => {
          this.loaderService.display(false);
        });    
        break;
      
      case EventbookingBatchType.Mailshot:
        this.wizardService.showWizard(WizardMode.MailshotFinal, eventBookingBatch);
        break;
    }
  }

  stopEventBookingBatch(eventBookingBatch) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(this.stopBookingBatchExternalDataSourceId, [eventBookingBatch.id])
    .then(stopEventBookingBatchResult => { 
      eventBookingBatch.startedAt = stopEventBookingBatchResult.startedAt;
      eventBookingBatch.stoppedAt = stopEventBookingBatchResult.stoppedAt;      
      this.loaderService.display(false);
    }).catch(error => {
      this.loaderService.display(false);
    });
  }

  updateBatch(eventBatch) {
    eventBatch.wizardValues = JSON.stringify(eventBatch.wizardValueArray);
    this.externaldatasourceService.executeExternalDataSource(855, [
      this.commonService.getModifyArrayBody(eventBatch, ['dataSql', 'wizardValueArray'])])
    .then(() => {
      this.externaldatasourceService.executeExternalDataSource(932, [
        eventBatch.wizardValueArray.id,
        eventBatch.wizardValueArray.newsletterMicroCampaignResultId,
        new Date(eventBatch.wizardValueArray.followUpDate).toUTCString()
      ]).then(() => {
      });
    });
  }
}
