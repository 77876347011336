<div class="matTableSupervisor__wrapper maxHeight">
    <div id="matTableSupervisorMember" class="matTableSupervisorMember maxHeight">
        <!-- <div class=" uiContainerDesign__member__header everleadShadowBox">
            <label>{{ 'UiControlDesign.Label.AvailiableDataFields' | texttransform}}</label>
        </div>       
        <select style="width: 100%; margin-bottom: 4px;" [(ngModel)]="selectedEntity">
            <option *ngFor="let availableField of specialArray[0]" [ngValue]=availableField>
                <span *ngIf="!availableField.entity.nameTranslationToken">{{ availableField.entity?.entityName }}</span>
                <span *ngIf="availableField.entity.nameTranslationToken">{{ availableField.entity?.nameTranslationToken.tokenFullName | texttransform }}</span>
            </option>
        </select> -->
        <div class="matTableSupervisorMember__wrapper">
            <div  class="matTableSupervisorMember__header">
                <label class="matTableSupervisorMember__infoLabel">{{ 'UiControlDesign.Label.AvailiableDataFields' | texttransform}}</label>
            </div>
            <div class="matTableSupervisorMember">
                <div *ngIf="selectedEntity != null">
                    <div class="everleadShadowBox matTableSupervisorMember__item"
                        *ngFor="let item of selectedEntity.entity.entityMembers" [ngClass]="{ hideInDom: checkIfMemberShouldBeHidden() }">
                        <label class="uiContainerDesignItemAvailableLabel ellipsisText" *ngIf="item.nameTranslationToken==null"
                            [tippy]="item.defaultName">
                            {{ item.defaultName }}
                        </label>
                        <label class="uiContainerDesignItemAvailableLabel ellipsisText" *ngIf="item.nameTranslationToken!=null"
                            [tippy]="item.nameTranslationToken.tokenFullName | texttransform">
                            {{ item.nameTranslationToken.tokenFullName | texttransform }}
                        </label>
                        <i class="fas fa-arrow-alt-circle-right floatRight fingerCursor" style="margin-right: 10px;"
                            (click)="addToContainedControls(selectedEntity.entity, item)"></i>
                    </div>
                </div>    
            </div>            
        </div>
    </div>

    <div id="matTableSupervisorContent" class=" maxHeight maxWidth">
        <div class="matTableSupervisorContent__wrapper">
            <div  class="matTableSupervisorContent__header">
                <label class="matTableSupervisorMember__infoLabel">{{ 'MatTable.Label.AvailableTableItems' | texttransform}}</label>
            </div>
            <div class="matTableSupervisorContent">
                <div *ngFor="let child of controlDefinition.childs" class="everleadShadowBox everleadBlueColor everleadHorizontalFlex matTableSupervisorContent__child" 
                    [ngClass]="{ hideInDom: !child.isActive }">
                    <label *ngIf="checkSpecialText(child) == ''">{{ child.displayText | texttransform }}</label>
                    <label *ngIf="checkSpecialText(child) != ''" [tippy]="checkSpecialText(child) | texttransform" class="matTableSupervisorMember__infoLabel">
                        {{ checkSpecialText(child) | texttransform }}
                    </label>
                    <i class="fas fa-trash-alt everleadCoral fingerCursor" *ngIf="child.projectId != null && isControlDeleteable(child)" (click)="deleteControl(child)"></i>
                </div>    
            </div>
        </div>
    </div>
</div>

