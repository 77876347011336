import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/authentication/guards/auth.guard';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { DashboardComponent } from '../shared/dashboard/dashboard.component';
import { JollyjupiterComponent } from './jollyjupiter.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ProspectComponent } from './controls/static/prospect/prospect.component';
import { ProspectDetailComponent } from './controls/static/prospect/prospect-detail.component';
import { AccountComponent } from './controls/static/account/account.component';
import { MyFollowupsComponent } from './controls/static/my-followups/my-followups.component';
import { AccountsComponent } from './controls/static/accounts/accounts.component';
import { ContactsComponent } from './controls/static/contacts/contacts.component';
import { MailacceptComponent } from './controls/noproject/mailaccept/mailaccept.component';
import { ContainerViewComponent } from './controls/static/container-view/container-view.component';
import { MyleadsComponent } from './controls/static/myleads/myleads.component';
import { TrialextensionComponent } from './controls/noproject/trialextension/trialextension.component';
import { ReportingComponent } from './controls/static/reporting/reporting.component';
import { SupervisorMainComponent } from './controls/static/supervisor/supervisor-main/supervisor-main.component';
import { ImportMainComponent } from './controls/static/supervisor/import/import-main/import-main.component';
import { AdminMainComponent } from './controls/admin/admin-main/admin-main.component';
import { ConversionComponent } from './controls/static/conversion/conversion.component';
import { MailshotsComponent } from './controls/static/mailshots/mailshots.component';
import { ProjectstartpageComponent } from './controls/static/projectstartpage/projectstartpage.component';
import { SmtpsettingsComponent } from './controls/static/supervisor/smtpsettings/smtpsettings.component';
import { NewaccountComponent } from './controls/static/newaccount/newaccount.component';
import { GeneralsettingsComponent } from './controls/static/supervisor/generalsettings/generalsettings.component';
import { CampaignComponent } from './controls/static/supervisor/campaign/campaign.component';
import { LeadstateComponent } from './controls/static/supervisor/leadstate/leadstate.component';
import { ProcessComponent } from './controls/static/supervisor/process/process.component';
import { PoolsComponent } from './controls/static/supervisor/pools/pools.component';
import { EmailtemplatesComponent } from './controls/static/supervisor/emailtemplates/emailtemplates.component';
import { EmailcontentComponent } from './controls/static/supervisor/emailcontent/emailcontent.component';
import { DatarightsComponent } from './controls/static/datarights/datarights.component';
import { AdminEntityComponent } from './controls/admin/admin-entity/admin-entity.component';
import { UsermanagementComponent } from './controls/static/usermanagement/usermanagement.component';
import { ProjectusersComponent } from './controls/static/projectusers/projectusers.component';
import { UsergroupsComponent } from './controls/static/supervisor/usergroups/usergroups.component';
import { AutomaticRecordAssignmentComponent } from './controls/static/automatic-record-assignment/automatic-record-assignment.component';
import { LeadreportsettingsComponent } from './controls/static/supervisor/leadreportsettings/leadreportsettings.component';
import { ConsentManagementComponent } from './controls/static/supervisor/consent-management/consent-management.component';
import { AjtrackerComponent } from './controls/static/supervisor/ajtracker/ajtracker.component';
import { CustomerapiComponent } from './controls/static/supervisor/customerapi/customerapi.component';
import { BlacklistComponent } from './controls/static/blacklist/blacklist.component';
import { MocoprojectassignmentComponent } from './controls/static/mocoprojectassignment/mocoprojectassignment.component';
import { AuditComponent } from './controls/static/supervisor/audit/audit.component';
import { UserdashboardComponent } from './controls/static/userdashboard/userdashboard.component';
import { ProjectManagementComponent } from './controls/static/developer/project-management/project-management.component';
import { EventlistComponent } from './controls/static/supervisor/eventlist/eventlist.component';
import { VoicescriptcontrolComponent } from './controls/static/voicescriptcontrol/voicescriptcontrol.component';
import { CampaigndashboardComponent } from './controls/dashboards/campaigndashboard/campaigndashboard.component';
import { WebsitedashboardComponent } from './controls/dashboards/websitedashboard/websitedashboard.component';
import { LeadmanagementdashboardComponent } from './controls/dashboards/leadmanagementdashboard/leadmanagementdashboard.component';
import { ConsentmanagementdashboardComponent } from './controls/dashboards/consentmanagementdashboard/consentmanagementdashboard.component';
import { ContentlibraryComponent } from './controls/static/contentlibrary/contentlibrary.component';
import { VipactivityComponent } from './controls/static/vipactivity/vipactivity.component';
import { AileadscoringComponent } from './controls/static/aileadscoring/aileadscoring.component';
import { StartpagedashboardComponent } from './controls/dashboards/startpagedashboard/startpagedashboard.component';
import { AdminAipromptsComponent } from './controls/admin/admin-aiprompts/admin-aiprompts.component';
import { ProcessvisualComponent } from './controls/static/processvisual/processvisual.component';
import { CloudconnectionsComponent } from './controls/static/cloudconnections/cloudconnections.component';
import { CacheresetComponent } from './controls/static/cachereset/cachereset.component';
import { EventbookingbatchComponent } from './controls/static/eventbookingbatch/eventbookingbatch.component';
import { QuestionaireDesignerControlComponent } from './controls/static/questionaire-control/questionaire-designer-control/questionaire-designer-control.component';
import { LookuptablesComponent } from './controls/static/lookuptables/lookuptables.component';
import { CustomPaletteEditorComponent } from './controls/custom/custom-palette/custom-palette-editor/custom-palette-editor.component';
import { FeaturesComponent } from './controls/static/features/features.component';
import { FeaturessettingsComponent } from './controls/static/featuressettings/featuressettings.component';
import { FollowupsComponent } from './controls/static/followups/followups.component';
import { FeaturespackagessettingsComponent } from './controls/static/featurespackagessettings/featurespackagessettings.component';
import { ProjectpackagesComponent } from './controls/static/projectpackages/projectpackages.component';
import { OpportunitylistComponent } from './controls/static/opportunitylist/opportunitylist.component';
import { ContactProtectionComponent } from './controls/static/contact-protection/contact-protection.component';


const routes: Routes = [

  {
      path: 'content',
      pathMatch: 'prefix',
      canActivate: [AuthGuard],
      children: [
        { path: ':id', component: JollyjupiterComponent  },
      ]
  },
  { path: 'dashboard', component: DashboardComponent,   canActivate: [AuthGuard] ,
     // resolve : { applicationUser: JollyJupiterResolveService  },
    children: [
      { path: '', component: DashboardComponent  },
      { path: '**', redirectTo: '404' }
    ]
  },
  {
    path: 'UserSettings',
    component: UsersettingsComponent,
    pathMatch: 'prefix',
    canActivate: [AuthGuard] ,
    children: [
      { path: ':id', component: UsersettingsComponent  },
    ]
  },
  {
    path: 'prospects',
    component: ProspectComponent,
    canActivate: [AuthGuard] ,
    children: [
      { path: ':id', component: ProspectDetailComponent, pathMatch: 'full'  },
    ]
  },
  {
    path: 'redirect',
    component: RedirectComponent,
    pathMatch: 'prefix',
    canActivate: [AuthGuard] ,
    children: [
      { path: ':id', component: UsersettingsComponent  },
    ]
  },
  { path: 'account', component: AccountComponent},
  { path: 'myfollowups', component: MyFollowupsComponent },
  { path: 'followups', component: FollowupsComponent},
  { path: 'accounts', component: AccountsComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'mailaccept', component: MailacceptComponent },
  { path: 'containerview', component: ContainerViewComponent },
  { path: 'myleads', component: MyleadsComponent },
  { path: 'trialextension', component: TrialextensionComponent },
  { path: 'reporting', component: ReportingComponent },
  { path: 'supervisor-main', component: SupervisorMainComponent },
  { path: 'import-main', component: ImportMainComponent },
  { path: 'admin-main', component: AdminMainComponent },
  { path: 'conversions', component: ConversionComponent },
  { path: 'mailshots', component: MailshotsComponent },
  { path: 'dashboard', component: DashboardComponent,  },
  { path: 'smtpsettings', component: SmtpsettingsComponent,  },
  { path: '1200', component: DashboardComponent },
  { path: 'usersettings', component: UsersettingsComponent },
  { path: 'newaccount', component: NewaccountComponent   },
  { path: 'developerprojectmanagement', component: ProjectManagementComponent },
  { path: 'generalsettings', component: GeneralsettingsComponent },
  { path: 'process', component: ProcessComponent },
  { path: 'leadstate', component: LeadstateComponent },
  { path: 'campaigns', component: CampaignComponent },
  { path: 'pools', component: PoolsComponent },
  { path: 'emailcontent', component: EmailcontentComponent },
  { path: 'emailtemplate', component: EmailtemplatesComponent },
  { path: 'admin-entity', component: AdminEntityComponent },
  { path: 'datarights', component: DatarightsComponent },
  { path: 'usermanagement', component: UsermanagementComponent },
  { path: 'projectusers', component: ProjectusersComponent },
  { path: 'usergroups', component: UsergroupsComponent },
  { path: 'leadreportsettings', component: LeadreportsettingsComponent },
  { path: 'automaticrecordassignment', component: AutomaticRecordAssignmentComponent },
  { path: 'consent', component: ConsentManagementComponent },
  { path: 'ajtracker', component: AjtrackerComponent },
  { path: 'customerapi', component: CustomerapiComponent },
  { path: 'blacklist', component: BlacklistComponent },
  { path: 'mocoprojectassignment', component: MocoprojectassignmentComponent },
  { path: 'audit', component: AuditComponent },
  { path: 'eventlist', component: EventlistComponent },
  { path: 'voicescript', component: VoicescriptcontrolComponent },
  { path: 'campaigndashboard', component: CampaigndashboardComponent },
  { path: 'websitedashboard', component: WebsitedashboardComponent },
  { path: 'leadmanagementdashboard', component: LeadmanagementdashboardComponent },
  { path: 'consentmanagementdashboard', component: ConsentmanagementdashboardComponent },
  { path: 'contentlibrary', component: ContentlibraryComponent }  ,
  { path: 'vipalert', component: VipactivityComponent },
  { path: 'aileadscore', component: AileadscoringComponent },
  { path: 'startpagedashboard', component: StartpagedashboardComponent }  ,
  { path: 'admin-aiprompt', component: AdminAipromptsComponent } ,
  { path: 'processvisual', component: ProcessvisualComponent },
  { path: 'cloudconnection', component: CloudconnectionsComponent },
  { path: 'cachereset', component: CacheresetComponent },
  { path: 'bookingbatch', component: EventbookingbatchComponent },
  { path: 'bookingbatchmailshot', component: EventbookingbatchComponent },
  { path: 'questionaires', component: QuestionaireDesignerControlComponent },
  { path: 'lookuptables', component: LookuptablesComponent },
  { path: 'paletteeditor', component: CustomPaletteEditorComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'featuressettings', component: FeaturessettingsComponent },
  { path: 'featurespackagessettings', component: FeaturespackagessettingsComponent },
  { path: 'eventlist', component: EventlistComponent },
  { path: 'datafields', component: AdminEntityComponent },
  { path: 'projectpackages', component: ProjectpackagesComponent },
  { path: 'opportunities', component: OpportunitylistComponent },
  { path: 'contactprotection', component: ContactProtectionComponent },
];

export const jollyJupiterRoutes = RouterModule.forChild(routes );
