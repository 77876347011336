import { Injectable, PipeTransform, Pipe, Injector, ChangeDetectorRef } from '@angular/core';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { CommonService } from '../service/common.service';
import { isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { UserService } from 'app/core/authentication/services/user.service';

@Pipe({
  name: 'texttransform',
  pure: false
})

@Injectable({
  providedIn: 'root'
})
export class TextTransformService implements PipeTransform {
  constructor(
    private applicationInfoService: ApplicationInfoService,
    public _cdhref: ChangeDetectorRef,
    public axivasTranslateService: AxivasTranslateService,
    private userService: UserService
  ) {
  }

  transform(originalText: any): any {
    if (originalText == null || originalText == undefined) {
      return '';
    }
    
    if (this.axivasTranslateService.translatedValues[originalText] != undefined && 
        this.axivasTranslateService.translatedValues[originalText] != null) {
      return this.axivasTranslateService.translatedValues[originalText];
    }
    
    if ((originalText === '') || (isNullOrUndefined(originalText))) {
      return '';
    }

    let translatedText =  this.axivasTranslateService.getTranslationTextForToken(originalText);
    if (isNullOrUndefined(translatedText)) {
      translatedText = '';
    }

    if (!isNullOrUndefined(this.applicationInfoService.user)) {
      translatedText = translatedText.replace('{UserName}', this.applicationInfoService.user.userName + ' ');
      // originalText = originalText.replace('{UserFirstName}', this.applicationInfoService.user.employee.firstName);
      // originalText = originalText.replace('{UserLastName}', this.applicationInfoService.user.employee.lastName);
    }

    translatedText = translatedText.replace('{projectID}', this.applicationInfoService.projectID.toString());
    translatedText = translatedText.replace('{projectName}', this.applicationInfoService.projectName);
    if (!isNullOrUndefined(this.userService)) {
      translatedText = translatedText.replace('{UserID}', this.userService.getUserId().toString());
    } else {
      translatedText = translatedText.replace('{UserID}', this.applicationInfoService.userID.toString());
    }
    if (this.applicationInfoService.currentTask) {
      translatedText = translatedText.replace('{taskId}', this.applicationInfoService.currentTask.id);
    }
    // console.warn('this.applicationInfoService.currentAccount', this.applicationInfoService.currentAccount)
    if (this.applicationInfoService.currentAccount) {
      translatedText = translatedText.replace('{accountId}', this.applicationInfoService.currentAccount.id);
    }
    if (this.applicationInfoService.currentContact) {
      translatedText = translatedText.replace('{contactId}', this.applicationInfoService.currentContact.id);
    } else {
      translatedText = translatedText.replace('(Id: {contactId})', '');
      translatedText = translatedText.replace('({contactId})', '');
      translatedText = translatedText.replace('{contactId}', '');
    }
    
    if (
      this.axivasTranslateService.translationTextArrayGlobal != null &&
      this.axivasTranslateService.translationTextArrayProject != null
    ) {
      if (
        this.axivasTranslateService.translationTextArrayGlobal.length > 0 && 
        this.axivasTranslateService.translationTextArrayProject.length > 0
      ) {
        this.axivasTranslateService.translatedValues[originalText] = translatedText;
      }      
    }
    return translatedText;
  }
}
