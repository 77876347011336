<div *ngIf="
            applicationInfoService.isTimeRecordingUser && 
            applicationInfoService.timeRecordingConnected &&
            activityItems.length > 0
            " 
    class="timeRecordingOverflowHidden"
>
    <!-- <span *ngIf="!applicationInfoService.isMobile() && useNewSoftphone === 'false'">
        <span id="inboundOutboundSpan"         
            title="{{ 'Activity.General.PaidHours' | texttransform }}">
            <i class="fas fa-play timeRecordingInfoIcon"></i>
            <label class="timeRecordingInfoLabel" *ngIf="applicationInfoService.isMobile() == false">
                {{ 'Activity.General.PaidHours' | texttransform }}:
            </label> 
            <label class="timeRecordingInfoLabel timeRecordingBold">{{ paidHours | decimalToTime:'1' }}</label>    
        </span>

        <span id="shortBreakSpan" 
            (click)="setCurrentActivity(7)" 
            title="{{ 'Activity.General.PaidBreak' | texttransform | texttransform }}"
            class="timeRecordingButton"
            >
            <i class="timeRecordingHeaderDivider"></i>
            <i class="fas fa-pause-circle timeRecordingInfoIcon fingerCursor"></i>
            <label class="timeRecordingInfoLabel fingerCursor" *ngIf="applicationInfoService.isMobile() == false">
                {{ 'Activity.General.PaidBreak' | texttransform }}:
            </label>
            <label class="timeRecordingInfoLabel fingerCursor timeRecordingBold">{{ shortBreakTime | decimalToTime:'1' }}</label>
        </span>

        <span id="longBreakSpan" 
            (click)="setCurrentActivity(8)" 
            title="{{ 'Activity.General.UnpaidBreak' | texttransform }}"
            class="timeRecordingButton"
            >
            <i class="timeRecordingHeaderDivider"></i>
            <i class="fas fa-utensils timeRecordingInfoIcon fingerCursor"></i>
            <label class="timeRecordingInfoLabel fingerCursor" *ngIf="applicationInfoService.isMobile() == false">
                {{ 'Activity.General.UnpaidBreak' | texttransform }}:
            </label>
            <label class="timeRecordingInfoLabel fingerCursor timeRecordingBold">{{ longBreakTime | decimalToTime:'1' }}</label>
        </span>

        <i class="timeRecordingHeaderDivider"></i>
    </span> 
   -->
    <span (mouseleave)="menu.hide()" (click)="menu.show($event)">
        <p-menu #menu [popup]="true" [model]="items" class="timeRecordingInfoLabel globalFontSize" (click)="menu.hide()"></p-menu>  
        <label class="timeRecordingInfoLabel">{{ 'Activity.General.CurrentActivity' | texttransform }}:</label>

        <i *ngIf="applicationInfoService.isMobile()" class="fingerCursor timeRecordingInfoIcon"></i>
        <label *ngIf="applicationInfoService?.currentTimeRecordingStatus != null && useNewSoftphone == 'true'" class="timeRecordingInfoLabel timeRecordingBold">
            {{ applicationInfoService?.currentTimeRecordingStatus.translationTokenName | texttransform  }}
            {{ applicationInfoService?.currentTimeRecordingStatus.translationToken | texttransform  }}
        </label>

        <label *ngIf="applicationInfoService?.currentTimeRecordingStatus != null && useNewSoftphone == 'false'" class="timeRecordingInfoLabel timeRecordingBold">
            {{ applicationInfoService?.currentTimeRecordingStatus?.currentActivity?.translationTokenFullName | texttransform  }}
        </label>

        <label *ngIf="applicationInfoService?.currentTimeRecordingStatus == null" class="timeRecordingInfoLabel fingerCursor timeRecordingBold">
                {{ 'Activity.General.SelectActivity' | texttransform  }}
        </label>
        <i class="fas fa-caret-down fingerCursor" style="padding-right: 5px;"></i>
    </span>   

    <span *ngIf="middlewareSignalrService?.availStatus?.status == 3">
        {{ 'Activity.Name.OutOfOffice' | texttransform }}
    </span>

    <span *ngIf="applicationInfoService.isMobile() == false && useNewSoftphone === 'false'">
        <i class="timeRecordingHeaderDivider"></i>
        <span *ngFor="let item of applicationInfoService.availableFavoritActivities" 
              (click)="setCurrentActivity(item.activityId, false)"
              class="timeRecordingButton"
               >
            <span *ngIf="item.activityId != applicationInfoService.currentTimeRecordingStatus?.currentActivity?.activityId">
                <i class="far fa-star timeRecordingInfoFavIcon fingerCursor"></i>
                <label class="timeRecordingInfoLabel fingerCursor"
                    title="{{ item.translationTokenFullName | texttransform }}"
                >
                    {{ item.translationTokenFullName | texttransform }}
                </label>
            </span>
        </span>     
    </span>   
</div>

<div *ngIf="activityItems.length == 0" class="timeRecordingInfoLabel">
    {{ 'Activity.General.NoActivitiesAvailable' | texttransform  }}
</div>