import { Injectable } from '@angular/core';
import { GraphQLService, ApolloMethod, JJApolloClient } from '../../shared/service/graphql.service';
import { EventService, JJEvent } from './event.service';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { ExternaldatasourceService, ExternalDataSource_QueryType } from './externaldatasource.service';
import { MessagingService, MessagePosition, MessageSeverity } from './messaging.service';
import { LoggerService } from 'app/core/logger.service';
import { CommonService } from './common.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { isArray, isNullOrUndefined } from 'util';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

export enum ValueType {
  Nothing = 0,
  Date = 1,
  String = 2,
  Boolean = 3,
  List = 4,
  BootstrapDate = 5,
  Number = 6,
  DateTime = 7
}

export enum ValidityCheckType {
  RegEx = 1
}

@Injectable({
  providedIn: 'root'
})

export class EntityService {
  constructor(
    private graphQLService: GraphQLService,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private loggerService: LoggerService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private graphqlqueryService: GraphqlqueryService
    ) { }


  getEntityMemberByName(entityId, memberName) {
    let returnValue = null;
    if (this.applicationInfoService.entities.ContainsKey(entityId)) {
      const entity = this.applicationInfoService.entities.Item(entityId);
      const entityMember = entity.entityMembers.find(e => e.defaultName === memberName);
      if (!this.commonService.isNullOrUndefined(entityMember)) {
        returnValue = entityMember;
      }
    }
    return returnValue;
  }

  executeExternalDataSource(entityId: any, externalDataSourceType: any, additionalParameter = []): Promise<any> {
    return new Promise((executeExternalDataSourceResolve, executeExternalDataSourceReject) => {
      const entity = this.applicationInfoService.entities.Item(entityId);

      // console.warn('executeExternalDataSource', entityId, this.applicationInfoService.entities);
      // console.warn('executeExternalDataSource', entityId, this.applicationInfoService.entities.Item('1'));

      if (!entity) {
        executeExternalDataSourceReject('Entity not found');
      }
      const entityExternalDataSource = this.getExternalDataSourceByType(entity, externalDataSourceType);
      if (entityExternalDataSource.type === 2) {
        let query = entityExternalDataSource.dataQuery;
        query = query.replace('<memberValues>', this.commonService.getModifiedEntityValuesString(entityId, []));
        query = query.replace('<entityControlMembers>', this.getEntityControlMembers(entityId));
        let queryType = ApolloMethod.Query;
        if (query.toUpperCase().includes('MUTATION')) {
          queryType = ApolloMethod.Mutation;
        }
        this.graphQLService.apolloGQLpromiseWithParameter(entityExternalDataSource.source,
          queryType, query, additionalParameter)
        .then(executeExternalDataSourceResult => {
            executeExternalDataSourceResolve(executeExternalDataSourceResult.data[entityExternalDataSource.identifier]);
        })
        .catch(error => { executeExternalDataSourceReject(error); });
      }
    });
  }

  getEntityControlMembers(entityId: any): string {
    let resultValue = '';
    this.applicationInfoService.controls.impArray.items.forEach(controlId => {
      if (Number(this.applicationInfoService.controls.Item(controlId).uiEntityInstanceId) === Number(entityId)) {
        if (this.applicationInfoService.controls.Item(controlId).value) {
          if (!this.applicationInfoService.controls.Item(controlId).value.includes(';')
            && !this.applicationInfoService.controls.Item(controlId).value.startsWith('ui')
            && !this.applicationInfoService.controls.Item(controlId).value.includes('.')
            && !this.applicationInfoService.controls.Item(controlId).value.startsWith('subResults')) {
            resultValue = resultValue.concat(this.applicationInfoService.controls.Item(controlId).value, ' ');
          }
        }
      }
    });
    return resultValue;
  }

  getExternalDataSourceOfEntityMember(entityId: any, memberId: any): any {
    let resultValue = null;
    if (this.applicationInfoService.entities.Item(entityId)) {
      this.applicationInfoService.entities.Item(entityId)
      .entityMembers.forEach(entityMember => {
        if (memberId === entityMember.id) {
          if (entityMember.externalDataSource) {
            resultValue = entityMember.externalDataSource;
          }
        }
      });
    }
    return resultValue;
  }

  getMemberDefaultName(entityId, memberId): string {
    let returnValue = '';
    if (this.applicationInfoService.entities.ContainsKey(entityId)) {
      this.applicationInfoService.entities.Item(entityId).entityMembers.forEach(member => {
        if (member.id === memberId) {
          returnValue = member.defaultName;
        }
      });
    }
    return returnValue;
  }

  getEntityMemberType(entityMembers: any, member: any): any {
    let returnValue = null;
    if ( (!entityMembers) || (!member)) {
      return null;
    }
    entityMembers.forEach(entityMember => {
      if (entityMember.defaultName === member) {
        returnValue = entityMember.memberType;
      }
    });
    return returnValue;
  }

  checkIfFieldIsInSchema(schema: any, field: any): boolean {
    let result = false;
      schema.fields.forEach(element => {
        if (element.name === field) {
          result = true;
        }
      });
    return result;
  }

  getEntityValueSchemaType(schema: any, entityValue: any): string {
    let result = null;
      schema.fields.forEach(element => {
        if (element.name === entityValue) {
          if (element.type.kind === 'NON_NULL') {
            result = element.type.ofType.name;
          } else {
            result = element.type.name;
          }
          this.messagingService.showDebugMessageInConsole('getEntityValueSchemaType found: ', [element, result]);
        }
      });
    return result;
  }

  memberValidityRegEx(valueToTest: string, regExValue: string): boolean {
    if (valueToTest == null || valueToTest === undefined) {
      return false;
    }
    const regEx = new RegExp(regExValue);
    return regEx.test(valueToTest);
  }

  getEntityMember(entity: any, memberName: string): any {
    let result: any = null;
    if (entity) {
      if (entity.entityMembers) {
        entity.entityMembers.forEach(member => {
          if (member.defaultName === memberName) {
            result = member;
          }
        });
      }
    }
    return result;
  }

  getMemberValidityErrorString(memberValidity: any, memberName): string {
    if (!this.commonService.isNullOrUndefined(memberValidity.nameTranslationToken)) {
      let translationText = this.axivasTranslateService.getTranslationTextForToken(memberValidity.nameTranslationToken.tokenFullName);
      let translationTextDesc = this.axivasTranslateService.getTranslationTextForToken(memberValidity.descriptionTranslationToken.tokenFullName);
      translationText = translationText.replace('{0}', memberName);
      translationText = translationText.replace('{1}', translationTextDesc);      
      return translationText
    } else {
      return memberValidity.defaultName;
    }
  }

  getMemberTranslation(memberId: any): string {
    if (this.applicationInfoService.entityMember.ContainsKey(memberId)) {
      const member = this.applicationInfoService.entityMember.Item(memberId);
      if (!this.commonService.isNullOrUndefined(member.nameTranslationToken)) {
        return this.axivasTranslateService.getTranslationTextForToken(member.nameTranslationToken.tokenFullName);
      } else {
        return member.defaultName;
      }
    } else {
      return '';
    }
  }

  checkMemberValidity(entity: any, modifiedValues: any): boolean {
    let result = true;
    const id = this.getEntityValue(entity, 'id', 1);
    this.eventService.raiseResetRequiredField(entity.uiEntityInstance);
    if (id < 0) {
    entity.entityMembers.forEach(entityMember => {
      if (!isNullOrUndefined(entityMember.entityMemberValidities)) {
        entityMember.entityMemberValidities.forEach(memberValidity => {
          if (this.validityCheck(memberValidity, entity, entityMember.defaultName) === false) {
            result = false;
          }
        });
      }
    });
    } else {
      modifiedValues.forEach(modifiedValue => {
        const member = this.getEntityMember(entity, modifiedValue);
        if (member) {
          member.entityMemberValidities.forEach(memberValidity => {
            if (this.validityCheck(memberValidity, entity, modifiedValue) === false) {
              result = false;
            }
          });
        }
      });
    }
    return result;
  }

  validityCheck(memberValidity: any, entity: any, modifiedValue: any) {
    let result = true;
    if (memberValidity.regExJs) {
      if (this.memberValidityRegEx(entity.data[modifiedValue], memberValidity.regExJs) === false)  {
        const memberInfo = this.getEntityMember(entity, modifiedValue);
        this.eventService.raiseShowRequiredField(entity.uiEntityInstance, modifiedValue);
        this.messagingService.showDefaultWarning(
          this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(memberInfo)),
          this.getMemberValidityErrorString(memberValidity, this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(memberInfo))), 
          false
        );
        result = false;
      }
    }
    return result;
  }

  public cleanEntityValues(entityId: any) {
    // console.warn('cleanEntityValues', entityId);
    const modifiedValues = this.getModifiedEntityValues(entityId);
    modifiedValues.forEach(modifiedValue => {
      const member = this.getEntityMember(this.getEntities(entityId), modifiedValue);
      // console.warn('cleanEntityValues member', member);
      if (!isNullOrUndefined(member)) {
        let value = this.getEntityValue(this.getEntities(entityId), modifiedValue, 0);
        // console.warn('cleanEntityValues value', value);
        if (member.memberType === 'TEXT') {
          value = this.commonService.cleanStringValue(value);
          this.insertDataValue(entityId, modifiedValue, value);
        }
      }
    });
  }

  public saveEntityToServer(entityId: string): Promise<any> {
    return new Promise((saveEntityToServerResolve, saveEntityToServerReject) => {
        let dataSource: any = null;
        let finalData = '';
        let modifiedValues = [];
        const entityInformation = this.applicationInfoService.entities.Item(entityId);

        const relatedId = Number(this.applicationInfoService.entities.Item(entityId).data['id']);
        // console.log('saveEntityToServer relatedId', entityId, relatedId);
        if (relatedId < 0) {
          if (this.checkProjectRelatedRequiredEntityMembers(entityId) == false) {
            saveEntityToServerReject('Required fields');
            return;
          }  
          dataSource = this.getExternalDataSourceByType(this.applicationInfoService.entities.Item(entityId),
          ExternalDataSource_QueryType.Insert);
          modifiedValues = this.commonService.getModifiedEntityValues(entityId, true);
        } else {
          dataSource = this.getExternalDataSourceByType(this.applicationInfoService.entities.Item(entityId),
          ExternalDataSource_QueryType.Update);
          finalData = finalData.concat('id:' + this.applicationInfoService.entities.Item(entityId).data['id'] + ', ');
          modifiedValues = this.commonService.getModifiedEntityValues(entityId);
        }

        if (dataSource == null) {           
          saveEntityToServerResolve(null);
          return; 
        }

        let query = dataSource.dataQuery;
        if (this.checkMemberValidity(this.applicationInfoService.entities.Item(entityId), modifiedValues) === false) {
          saveEntityToServerReject('Validation failed');
          return;
        }

        if (modifiedValues.length === 0) { saveEntityToServerResolve(null); return; }
        // console.warn('saveEntityToServer modifiedValues', modifiedValues, query, dataSource);
        this.getSchema(dataSource)
        .then(result => {
          if (this.checkIfFieldIsInSchema(result.data.__type, 'accountId')) {
            if (this.applicationInfoService.currentAccount) {
              finalData = finalData.concat('accountId' + ':' + this.applicationInfoService.currentAccount.id + ', '); 
            }           
          }
          this.messagingService.showDebugMessageInConsole('saveEntityToServer schema ', [result]);
          finalData = this.modifiyFinalQueryWithSchemaTypes(modifiedValues, finalData, entityId, result);

          finalData = finalData.substring(0 , finalData.length - 2);
          query = query.replace(/<entityName>/, this.applicationInfoService.entities.Item(entityId).entityName);
          query = query.replace(/<entitydata>/, finalData);
          query = query.replace(/<projectId>/, this.applicationInfoService.projectID);
          query = query.replace(/<entityId>/, this.applicationInfoService.entities.Item(entityId).data['id']);
          if (this.applicationInfoService.entitiesDynamicFields.ContainsKey(entityId)) {
            query = query.replace('<dynamicFields>', this.applicationInfoService.entitiesDynamicFields.Item(entityId));
          }
          this.graphQLService.apolloGQLpromise(dataSource.source, ApolloMethod.Mutation, query)
          .then(data => {
            // this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, entityId,
            // this.axivasTranslateService.getTranslationTextForToken('Entity._.EntitySaved'), false);
            this.applicationInfoService.entitiesorig.Item(entityId).data = JSON.parse(
              JSON.stringify(this.applicationInfoService.entities.Item(entityId).data));
            this.eventService.entitySavedEvent.emit(this.applicationInfoService.entities.Item(entityId).data);
              saveEntityToServerResolve(data);
          })
          .catch(error => { saveEntityToServerReject(error); });
        }).catch(error => {
          console.error(error);
        });
        return;
    });
  }

  checkProjectRelatedRequiredEntityMembers(entityId) {
    let allRequirementsFullfilled = true;
    this.eventService.raiseResetRequiredField(entityId);
    const entityData = this.applicationInfoService.entities.Item(entityId).data;

    this.applicationInfoService.controls.impArray.items.forEach(control => {
      const controlIttem = this.applicationInfoService.controls.Item(control);
      if (controlIttem.uiEntityInstanceId == Number(entityId)) {
        if (controlIttem.isRequired == true) {
          if (this.commonService.isNullOrUndefined(entityData[controlIttem.value])) {
            allRequirementsFullfilled = false;            
            this.eventService.raiseShowRequiredField(entityId, controlIttem.value);
          }
        }
      }
    });
    if (!allRequirementsFullfilled) {
      this.messagingService.showDefaultWarning('', 'Entity._.PleaseInsertRequiredFields');
    }
    return allRequirementsFullfilled;
  }

  modifiyFinalQueryWithSchemaTypes(modifiedValues: any[], finalData: string, entityId: any, result: any): string {
    modifiedValues.forEach(element => {
      const entityValueSchemaType = this.getEntityValueSchemaType(result.data.__type, element);
      this.messagingService.showDebugMessageInConsole('saveEntityToServer modifiedValues element',
      [element, this.applicationInfoService.entities.Item(entityId).data, entityValueSchemaType]);
      if (entityValueSchemaType != null) {
        if (this.applicationInfoService.entities.Item(entityId).data[element] == null) {
          finalData = finalData.concat(element + ':' + this.applicationInfoService.entities.Item(entityId).data[element] + ', ');
        } else {
          // console.warn('entityValueSchemaType.toUpperCase()', entityValueSchemaType.toUpperCase());
          switch (entityValueSchemaType.toUpperCase()) {
            case 'DATE':
              finalData = finalData.concat(element + ':"' +
              new Date(this.applicationInfoService.entities.Item(entityId).data[element]) + '", ');
              break;
            case 'DATETIME':
              finalData = finalData.concat(element + ':"' +
              new Date(this.applicationInfoService.entities.Item(entityId).data[element]).toLocaleString('en-US') + '", ');
              break;
            case 'STRING':
              // console.warn('STRING', this.applicationInfoService.entities.Item(entityId).data[element]);
              let stringValue = this.applicationInfoService.entities.Item(entityId).data[element];
              stringValue = this.commonService.cleanStringValue(stringValue);
              stringValue = stringValue.replace(/(?:\r\n|\r|\n)/g, '\\n');
              finalData = finalData.concat(element + ':"' +
              stringValue + '", ');
              break;
            case 'INT':
              finalData = finalData.concat(element + ':' +
              this.applicationInfoService.entities.Item(entityId).data[element] + ', ');
              break;
            case 'BOOLEAN':
              finalData = finalData.concat(element + ':' +
              this.applicationInfoService.entities.Item(entityId).data[element] + ', ');
              break;
          }
        }
      }
    });
    return finalData;
  }

  getEntityDataArray(entityId: any) {
    if (this.applicationInfoService.entities.ContainsKey(entityId)) {
      if (this.applicationInfoService.entities.Item(entityId)) {
        return this.applicationInfoService.entities.Item(entityId).data;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getEntityValue(entity: any, member: any, type: number): any {
    if (!isNaN(Number(entity))) {
      entity = this.getEntities(entity);
    }
    if (!entity) { return null; }
    if (entity.data) {
      const baseValue = entity.data[member];
      const memberDefinition = this.getEntityMember(entity, member);
      if (type === 2) {
        return new Date(baseValue);
      }
      if (memberDefinition) {
        switch (memberDefinition.memberType) {
          case 'TEXT':
            return baseValue;
          case 'NUMERIC':
            return baseValue;
          case 'DATETIME':
            return new Date(baseValue).toLocaleString('de-DE');
          case 'BOOL':
            return baseValue;
        }
      } else {
        return baseValue;
      }
    } else {
      console.error('getEntityValue:', 'No data for entity', entity);
      return null;
    }
  }

  getEntities(entityID: string): any {
    if (this.applicationInfoService.entities.ContainsKey(entityID)) {
      return JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(entityID)));
    } else {
      return null;
    }
  }

  insertDataValue(entityID: string, setting: string, value: any) {
    // console.warn('insertDataValue', entityID, setting, value);
    if (this.applicationInfoService.entities.ContainsKey(entityID)) {
      this.applicationInfoService.entities.Item(entityID).data[setting] = value;
      this.eventService.updateEntity(entityID, setting);
    }
  }

  createEmptyObject() {
    return new Object();
  }

  updateValuebyType(entity: string, member: string, value: any, type: ValueType) {
    // console.log('updateValuebyType', entity, member, value, type);
    let finalValue: any = null;
    switch (type) {
      case ValueType.Date:
      finalValue = new Date(value);
      break;
      case ValueType.DateTime:
      finalValue = new Date(value).toLocaleString('en-US');
      break;
      case ValueType.Number:
      finalValue = value;
      break;
      case ValueType.String:
      finalValue = value;
      break;
      case ValueType.Boolean:
      finalValue = value;
      break;
      case ValueType.BootstrapDate:
      finalValue = new Date(value.year, value.month - 1, value.day).toDateString();
      break;
    }
    this.insertDataValue(entity, member, finalValue);
    if (this.applicationInfoService.useNewDesign) {
      if (
        entity.toString() === this.applicationInfoService.accountEntityId ||
        entity.toString() === '99' ||
        entity.toString() === this.applicationInfoService.contactEntityId) {
        // console.log('updateValuebyType - autosave', entity, member, value, type);
        this.saveEntityToServer(entity);
      }
    }
  }

  objectToEntityArray(baseObject: any): any[] {
    return Object.keys(baseObject).map((key) => [key, baseObject[key]]);
  }

  getEntityArrayValue(array: any[], key: any) {
    let result = null;
    array.forEach(element => {
      if (element[0] === key) {
        result = element[1];
      }
    });
    return JSON.stringify(result);
  }

  getSchema(dataSource: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.graphQLService.apolloGQLpromise(dataSource.source, ApolloMethod.Query, this.externaldatasourceService.schemaQuery.replace('<0>', dataSource.schema))
      .then(result => { resolve(result); })
      .catch(error => { reject(error); });
    });
  }

  getModifiedEntityValues(entityId: any, insertQuery: boolean = false): any[] {
    const modifiedValues: any[] = [];
    const baseValues = this.objectToEntityArray(this.applicationInfoService.entities.Item(entityId).data);
    const origValues = this.objectToEntityArray(this.applicationInfoService.entitiesorig.Item(entityId).data);
    baseValues.forEach(element => {
      let addElement = false;
      if (insertQuery) {
        if ('id' !== element[0]) {
          addElement = true;
        }
      } else {
        if (this.getEntityArrayValue(baseValues, element[0]) !== this.getEntityArrayValue(origValues, element[0])) {
          addElement = true;
        }
      }
      if (this.commonService.startsWidth(element[0], 'ui')) {
        this.messagingService.showDebugMessageInConsole('getModifiedEntityValues not used because uiValue: ', [element]);
        addElement = false;
      }
      if (addElement) {
        modifiedValues.push(element[0]);
      }
    });
    return modifiedValues;
  }

  // checkSpecialModifiedEntityValueCases(baseValue: boolean, entityId: any, element: any): boolean {
  //   console.warn('YYY', baseValue, entityId, element);
  //   if (entityId === this.applicationInfoService.taskEntityId && element[0] !== 'taskId') {
  //     return false;
  //   }
  //   return baseValue;
  // }

  getExternalDataSourceByType(entity: any, type: string): any {
    if (! isNaN(Number(entity))) {
      entity = this.applicationInfoService.entities.Item(entity);
    }
    let result: any = null;
    if (entity.entityExternalDataSources != null) {
      entity.entityExternalDataSources.forEach(element => {
        if (element.externalDataSource.queryType === type) {
          result = element.externalDataSource;
        }
      });  
    }
    return result;
  }

  overwriteEntityData(entityId: string, data: any) {
    return new Promise(resolve => {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(entityId))) {
        this.applicationInfoService.entities.Item(entityId).data = data;
        this.eventService.updateEntityDataProperty(entityId);
        this.applicationInfoService.entitiesorig.Item(entityId).data = JSON.parse(JSON.stringify(data));  
      }
    });
  }

  loadEntity(params: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const externalDataSource = this.getExternalDataSourceByType(
        this.applicationInfoService.entities.Item(params[0]), ExternalDataSource_QueryType.Query);
      const cleanedParams = [];
      params.forEach((param, index) => {
        if (index > 0) { cleanedParams.push(param); }
      });
      externalDataSource.dataQuery = externalDataSource.dataQuery.replace('<entityControlMembers>',
        this.getEntityControlMembers(params[0]));
      this.externaldatasourceService.executeQuery(externalDataSource, cleanedParams)
      .then(result => {
        if (Number(params[0]) == 4) {
          this.applicationInfoService.currentContact = result;
        }
        this.applicationInfoService.entities.Item(params[0]).data = result;
        this.applicationInfoService.entitiesorig.Item(params[0]).data = JSON.parse(JSON.stringify(result));
        this.eventService.updateEntityEvent.emit(new JJEvent(params[0], []));
        resolve(result);
      })
      .catch(error => { reject(error); });
    });
  }

  clearEntityData(entityId: any) {
    this.applicationInfoService.entities.Item(entityId).data = new Object();
    this.applicationInfoService.entitiesorig.Item(entityId).data = new Object();
  }

  checkValuesForAllRequiredControls(entityId, showMessage = false): Promise<any> {
    return new Promise((checkValuesForAllRequiredControlsResolve, checkValuesForAllRequiredControlsReject) => {
      let checkSuccessfull = true;
      this.eventService.raiseResetRequiredField(entityId);
      this.applicationInfoService.controls.impArray.items.forEach(control => {        
        control = this.applicationInfoService.controls.Item(control);        
        if (control.uiEntityInstanceId) {
          if (Number(control.uiEntityInstanceId) === entityId) {
            if (control.isRequired) {
              if (!this.getEntityValue(entityId, control.value, 1)) {
                this.eventService.raiseShowRequiredField(entityId, control.value);
                checkSuccessfull = false;
              }
            }
          }
        }
      });
      if (checkSuccessfull) {
        checkValuesForAllRequiredControlsResolve(null);
      } else {
        if (showMessage) {
          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info,
            '', this.axivasTranslateService.getTranslationTextForToken('Entity._.PleaseInsertRequiredFields'), false);
        }
        checkValuesForAllRequiredControlsReject();
      }
    });
  }

  updateEntity(entityId: string, dataId: string): Promise<any> {
    return new Promise((updateEntityResolve, updateEntityReject) => {
      if (dataId === '-1') {
        this.applicationInfoService.entities.Item(entityId).data = this.createEmptyObject();
        this.applicationInfoService.entities.Item(entityId).data['id'] = -1;
        this.eventService.updateEntity(entityId, dataId);
        updateEntityResolve(null);
      } else {
        this.loaderService.display(true);
        this.executeExternalDataSource(entityId, 'query', [dataId])
        .then(executeExternalDataSourceResult => {
          this.loaderService.display(false);
          this.applicationInfoService.entities.Item(entityId).data = executeExternalDataSourceResult;
          this.applicationInfoService.entitiesorig.Item(entityId).data = JSON.parse(JSON.stringify(executeExternalDataSourceResult));
          this.eventService.updateEntity(entityId, dataId);
          // console.warn('updateEntityDone', this.applicationInfoService.entities.Item(entityId));
          updateEntityResolve(null);
        })
        .catch(error => {
          this.loaderService.display(false);
          updateEntityReject(error);
        });
      }
    });
  }

  copyValuesFromEntityToEntity(param) {
    const copyList = param.split(';');
    copyList.forEach(listItem => {
      const parts = listItem.split('-');
      const source = parts[0].split('.');
      const dest = parts[1].split('.');
      this.applicationInfoService.entities.Item(dest[0]).data[dest[1]] =
       this.applicationInfoService.entities.Item(source[0]).data[source[1]];
      this.eventService.updateEntity(dest[0], '');
    });
  }
}
