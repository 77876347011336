
<div class="telekomCreateTake__wrapper">
    <div class="telekomCreateTake__body">
        <div>{{ 'TelekomTake.Label.TakeId' | texttransform }}</div>
        <input [(ngModel)]="account.name1" class="telekomCreateTake__input">
        <div>{{ 'TelekomTake.Label.TakeIdDescription' | texttransform }}</div>
    </div>
    <div class="telekomCreateTake__row">
        <div>{{ 'TelekomTake.Label.Campaign' | texttransform }}</div>
        <select [(ngModel)]="account.systeminfo">
            <option *ngFor="let item of getLookupList(29)" [ngValue]="item.id">{{item.defaultName}}</option>
        </select>
    </div>
    <div class="telekomCreateTake__row">
        <div>{{ 'TelekomTake.Label.Tarif' | texttransform }}</div>
        <select [(ngModel)]="account.product">
            <option *ngFor="let item of getTarifSublist()" [ngValue]="item.id">{{item.defaultName}}</option>
        </select>
    </div>
    
    <div>   
        <button class="jjButtonStyle jjButtonColor" [disabled]="!checkIfTakeIdIsValid() || account.systeminfo == null || account.product == null" (click)="createAccount()">
            {{ 'TelekomTake.Label.CreateTakeId' | texttransform }}
        </button>
    </div>
</div>