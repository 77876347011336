<div class="videoBackgroundLayer" *ngIf="applicationInfoService.showVideo && videoLoaded"></div>

<div class="videoPlayerWrapper" *ngIf="applicationInfoService.showVideo && videoLoaded && !this.applicationInfoService.isMobile()">

    <iframe [src]='video' class="videoPlayer" frameborder="0" allowfullscreen></iframe>
  
    <div class="videoplayerToolbar" *ngIf="applicationInfoService.showVideo && videoLoaded">
        <input type="checkBox" (change)="toggleDontShowAgain()" [(ngModel)]="dontShowAgain">
        <label (click)="toggleDontShowAgain(true)" class="fingerCursor  videoplayerToolbar__label">{{ 'Videoplayer.Demovideo.DontShowAgain' | texttransform }} </label>
        <label (click)="closeVideo()" class="fingerCursor videoplayerToolbar__label videoplayerToolbar__closeLabel">{{ 'Videoplayer.Demovideo.Close' | texttransform }}</label>
    </div>    
</div>
 
