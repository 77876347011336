import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-entitymember',
  templateUrl: './wizard-entitymember.component.html',
  styleUrls: ['./wizard-entitymember.component.scss']
})
export class WizardEntitymemberComponent implements OnInit {
  lookupTableDefinitions = [];
  mode = 1; // 1 = general 2 = datasource
  sampleData = [];

  constructor(
    public wizardService: WizardService,
    public commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit(): void {
    this.getLookupTables(205, 'main');
    this.getLookupTables(193, 'data');
  }

  getLookupTables(externalDataSourceId, source) {
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [])
    .then(getLookupTablesResult => {
      this.lookupTableDefinitions[source] = getLookupTablesResult;
    })
    .catch(error => {
      console.error('getLookupTables', error);
    });
  }

  sourceChanged(source) {
    if (source != null) {
      this.wizardService.wizardArray.memberType = 'NUMERIC';
      this.wizardService.wizardArray.lookupTableId = null;
    }
  }

  checkMemberName() {
    this.wizardService.wizardArray.forbiddenValue = false;
    this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.replace(/ü/g, "ue");
    this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.replace(/ä/g, "ae");
    this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.replace(/ö/g, "oe");
    this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.replace(/ /g, "");
    this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.replace(/[^a-zA-Z0-9 ]/g, "");
    this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.toLowerCase();
    if (this.wizardService.wizardArray.entity.entityMembers.find(member => member.defaultName == this.wizardService.wizardArray.defaultName)) {
      this.wizardService.wizardArray.forbiddenValue = true;
    }
    while(!isNaN(this.wizardService.wizardArray.defaultName[0]) && this.wizardService.wizardArray.defaultName.length > 0) {
      this.wizardService.wizardArray.defaultName = this.wizardService.wizardArray.defaultName.substring(1, this.wizardService.wizardArray.defaultName.length);
    }
    
    const invalidMemberNames = this.applicationInfoService.applicationSettings['invalidMemberNames'].split(';');
    invalidMemberNames.forEach(membername => {
      if (this.wizardService.wizardArray.defaultName == membername) {
        this.wizardService.wizardArray.forbiddenValue = true;
      }
    });
  }

  getSampleDataFromLookupTable() {
    if (this.wizardService.wizardArray.lookupTableId == null) {
      return;
    }

    let source = 1;
    if (this.wizardService.wizardArray.lookupTableSource == 'main') {
      source = 2;
    }
    this.externaldatasourceService.getLookupTableValues(
      this.wizardService.wizardArray.lookupTableId, source)
    .then(getSampleDataFromLookupTableResult => {
      this.sampleData = getSampleDataFromLookupTableResult;
    })
    .catch(error => {
      console.error('getSampleDataFromLookupTableResult', error);
    });
  } 
}
