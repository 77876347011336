import { Injectable } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Dictionary } from 'app/core/dictionary';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Injectable({
  providedIn: 'root'
})
export class DragdropService {

  constructor(
    private applicationInfoService: ApplicationInfoService
  ) { }

  clearItems(dragdropElements: any) {
    dragdropElements.forEach(element => {
      element.items = [];
      element.itemsUI = [];
    });
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  filterArray(item: any, dragdropElements: any): any {
    dragdropElements[item].itemsUI = this.filterArrayEx(
      dragdropElements[item].items,
      dragdropElements[item].filterString.toLowerCase()
    );
    return dragdropElements;
  }

  moveAllItems(source: number, destination: number, dragdropElements: any) {
    dragdropElements[source].itemsUI.forEach(element => {
      dragdropElements[destination].items.push(element);
      this.removeMovedItemFromArray(element, destination, dragdropElements);
      if (!dragdropElements[destination].itemDictInitial.ContainsKey(element.id)) {
        dragdropElements[destination].itemDictAdded.Add(element.id, element);
      }
      dragdropElements = this.checkIfItemNeedsToBeRemoved(element, destination, dragdropElements);
      dragdropElements = this.checkIfItemIsInToDeleteList(element, destination, dragdropElements);
    });
    dragdropElements = this.filterArray(0, dragdropElements);
    dragdropElements = this.filterArray(1, dragdropElements);
  }

  filterArrayEx(arraySource: any[], filterValue: string): any[] {
    const arrayTarget = [];
    arraySource.forEach((element) => {
      const stringValue: string = element.userName.toLowerCase();
      if (stringValue.indexOf(filterValue) >= 0) {
        arrayTarget.push(element);
      }
    });
    return arrayTarget;
  }

  getIDList(source: Dictionary<any>): string {
    let result = '';
    for (const item in source.items) {
      result = result.concat(',', source.Item(item).workID);
    }
    if (result.length > 0) { result = result.substring(1); }
    return result;
  }

  sortArray(array: any, sortBy: string) {
    array.sort((a, b) => a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? -1 : 1);
  }

  sortItems(control: number, sortBy: string, dragdropElements: any) {
    dragdropElements[control].items.sort((a, b) => a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? -1 : 1);
    this.filterArray(control, dragdropElements);
  }

  checkIfItemExistsInOtherLists(sourceID: number, item: any, dragdropElements: any): boolean {
    let returnValue = false;
    dragdropElements.forEach(element => {
      if (element !== dragdropElements[sourceID]) {
        element.items.forEach(itemToCheck => {
          if (item.id === itemToCheck.id) {
            returnValue = true;
          }
        });
      }
    });
    return returnValue;
  }

  pushItemToDragDropDicts(dict, item) {
    dict.items.push(item);
    dict.itemsUI.push(item);
    dict.itemDictInitial.Add(item['id'], item);
  }

  drop(event: CdkDragDrop<string[]>, item: any, dragdropElements: any): any {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex);
        if (dragdropElements[item].filterString === '') {
          dragdropElements[item].items = event.container.data;
        }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
        dragdropElements[item].items = this.addMovedItemToList(dragdropElements[item].items, event.item.data);
        dragdropElements = this.removeMovedItemFromArray(event.item.data, item, dragdropElements);

        if (!dragdropElements[item].itemDictInitial.ContainsKey(event.item.data.id)) {
          dragdropElements[item].itemDictAdded.Add(event.item.data.id, event.item.data);
        }

        dragdropElements = this.checkIfItemNeedsToBeRemoved(event.item.data, item, dragdropElements);
        dragdropElements = this.checkIfItemIsInToDeleteList(event.item.data, item, dragdropElements);
    }
    dragdropElements = this.filterArray(0, dragdropElements);
    dragdropElements = this.filterArray(1, dragdropElements);
    return dragdropElements;
  }

  checkIfItemIsInToDeleteList(itemInfo: any, item: any, dragdropElements: any) {
    if (dragdropElements[item].itemDictRemoved.ContainsKey(itemInfo.id)) {
      dragdropElements[item].itemDictRemoved.Remove(itemInfo.id);
    }
    return dragdropElements;
  }

  checkIfItemNeedsToBeRemoved(itemInfo: any, item: any, dragdropElements: any) {
    dragdropElements.forEach(element => {
      if (element === dragdropElements[item]) {
      } else {
        if (element.itemDictInitial.ContainsKey(itemInfo.id)) {
          element.itemDictRemoved.Add(itemInfo.id, itemInfo);
        }
        if (element.itemDictAdded.ContainsKey(itemInfo.id)) {
          element.itemDictAdded.Remove(itemInfo.id);
        }
      }
    });
    return dragdropElements;
  }

  addMovedItemToList(array: any[], item: any): any[] {
    array.push(item);
    return array;
  }

  removeMovedItemFromArray(item: any, ignoreItem: any, dragdropElements: any): any {
    dragdropElements.forEach(element => {
      if (element === dragdropElements[ignoreItem]) {
      } else {
        const result: any[] = [];
        element.items.forEach(subelement => {
          if (subelement === item) {
          } else {
            result.push(subelement);
          }
        });
        element.items = result;
      }
    });
    return dragdropElements;
  }
}
