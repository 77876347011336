<div class="headerInformation__wrapper">
    <div id="headerInformation__taskview" *ngIf="applicationInfoService.routedSection == 'task'">
        <div *ngIf="applicationInfoService.currentAccount != null && applicationInfoService.currentAccount?.isDummyAccount != true" class="headerInformation__task__accountLine1">            
            <label class="ellipsisText noPadding headerInformation__adressLabel bold" [title]="getAdressLine(true)">
                {{ getAdressLine() }}
            </label>            
            <label class="ellipsisText noPadding headerInformation__contactLabel" [title]="getContactLine()">
                {{ getContactLine() }}
            </label>            
        </div>
        
</div>