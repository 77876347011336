import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-mydayappointment',
  templateUrl: './mydayappointment.component.html',
  styleUrls: ['./mydayappointment.component.scss']
})
export class MydayappointmentComponent implements OnInit {
  @Input() followUp = null;
  isAi = false;
  isToday = false;
  isInPast = false;
  isSoon = false;
  isTomorrow = false;

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (this.followUp.contact.isAiContact == true) { this.isAi = true; }
    if (this.followUp.contact.isAiSuggestion == true) { this.isAi = true; }

    this.isToday = this.commonService.checkIfDateIsToday(this.followUp.followUpDate);
    this.isInPast = this.commonService.checkIfDateIsInPast(this.followUp.followUpDate)
    this.isSoon = this.commonService.checkIfDateIsSoon(this.followUp.followUpDate, 2)
    this.isTomorrow = this.commonService.checkIfDateIsTomorrow(this.followUp.followUpDate)
  }

  getAccountName(followUp) {
    return followUp.account.name1;
  }

  getContactName(followUp) {
    if (followUp.contactId != null) {
      return followUp.contact.firstName + ' ' + followUp.contact.lastName;
    } else {
      return '';
    }
  }
  
}
