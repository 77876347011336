<div id="myLeadsFilter" [ngClass]="{
        'jjControlMenuDivSpacer': isViewExtended==true
    }" [class]="getMainClasses()" (click)="toggleView()">
    <label class="communicationGuidelHeaderLabel fingerCursor" style="width: 60%;">{{ 'Task.Label.InterviewGuide' | texttransform }}</label>
    <button (click)="toggleView();$event.stopPropagation()" class="floatRight jjControlMenuButton"
        style="white-space: normal;">
        <i class="fas fa-expand fingerCursor" *ngIf="isViewExtended==false"></i>
        <i class="fas fa-chevron-up fingerCursor" *ngIf="isViewExtended==true"></i>
    </button>
</div>

<div [ngStyle]="setControlStyle('control')" style="overflow-y: auto; padding: 3px"
    [style.height.px]="labelHeight">
    <span *ngIf="loadingControlData==true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>

    <label *ngIf="loadingControlData==false" [innerHTML]="calculateText()"
        [class]="getClasses()" (click)="callMethod('onclick')">
    </label>    
</div>