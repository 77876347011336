
<!-- <div *ngIf="loader" class="globalFontSize apiResponseContainer">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px; color:white"></i>{{
        'General.Label.LoadingData' | texttransform }}
</div>
 -->

<div class="contactProtectionContainer">
    <div class="row">
        <button (click)="addContactProtection()" class="everleadCustomButton" *ngIf="viewMode == 0">
            {{'ContactProtection.Label.AddContactEntry' | texttransform }}
        </button>

        <button (click)="createProjectProtection()" class="everleadCustomButton" *ngIf="viewMode == 1">
             {{'ContactProtection.Label.AddProjectEntry' | texttransform }}
        </button>
    </div>

    <div *ngIf="loader" class="globalFontSize apiResponseContainer">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px; color:white"></i>{{
            'General.Label.LoadingData' | texttransform }}
    </div>

    <div *ngIf="apiError" class="globalFontSize apiResponseContainer">
        {{ 'ContactProtection.Label.NoDataAvailable' | texttransform }}
    </div>
    
    <div class="row filterPaginatorContainer" *ngIf="!loader && listData && !apiError && viewMode == 0">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div class="filter">
                <div class="filter__svg">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg"
                        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
                </div>
                <input [(ngModel)]="searchValue" [placeholder]="'ContactProtection.Label.SearchValue' | texttransform"
                    (ngModelChange)="filterContactList($event, searchValue)">
            </div>
        </div>
    
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div class="paginatorContainer">
                <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Seite auswählen"></mat-paginator>
                <i class="fas fa-sync fingerCursor" (click)="getApiData()"></i>
            </div>
        </div>
    </div>


    <div class="contactProtectionTable" *ngIf="!loader && listData && !apiError && viewMode == 0">
        <table mat-table #table [dataSource]="dataSource" [@.disabled]="true">

            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <svg-icon *ngIf="element?.contact" src="assets/images/svg/dashboard_contact.svg" [svgStyle]="{ 'width.px': 32, 'height.px': 32}"></svg-icon>
                    <svg-icon *ngIf="!element?.contact" src="assets/images/svg/account_factory.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20}"></svg-icon>            
                </td>
            </ng-container>

            <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Contact' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <div class="text-container">
                       <div class="ellipsisCell" *ngIf="element?.contact" [tippy]="element?.contact?.firstName + ' ' + element?.contact?.lastName" [tippyOptions]="{placement: 'bottom'}">{{ element?.contact?.firstName }} {{ element?.contact?.lastName }}</div>
                       <div class="ellipsisCell" *ngIf="!element?.contact" [tippy]="element?.account?.name1" [tippyOptions]="{placement: 'bottom'}">{{element?.account?.name1}}</div>
                    </div>
                </td> 
            </ng-container>

            <ng-container matColumnDef="medium">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Medium' | texttransform}}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mediumForTable }}
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Frequency' | texttransform}}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.freqForTable }}
                </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Quantity' | texttransform }}</th>
                <td mat-cell *matCellDef="let element" >
                    {{element.quantity}}
                </td>
            </ng-container>
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Comments' | texttransform }}</th>
                <td mat-cell *matCellDef="let element" >
                    <span *ngIf="element.comment" class="text-container">
                        <div class="ellipsisCell" [tippy]="element.comment" [tippyOptions]="{placement: 'bottom'}">
                            {{element.comment}}
                        </div>
                    </span>
                    <span *ngIf="!element.comment" [ngStyle]="{'fontWeight': '700px'}">-</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Options' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <div class="optionsContainer">
                        <i class="fas fa-edit fingerCursor" (click)="editContact(element)" [tippy]="'ContactProtection.Label.UpdateContact' | texttransform" [tippyOptions]="{placement:'bottom'}"></i>
                        <i class="fas fa-trash fingerCursor" (click)="deleteContact(element)" [tippy]="'ContactProtection.Label.DeleteContact' | texttransform" [tippyOptions]="{placement:'bottom'}"></i>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (mouseenter)="highlightRow(row)"
            (mouseleave)="deselectRow()"
            [ngClass]="{'highlightedRow' : highlightRowId === row.id}"></tr>

        </table>
        <div *ngIf="dataSource.data.length === 0"> <span>{{ 'ContactProtection.Label.ResultNotFound' | texttransform }}</span></div>

    </div>

    <div class="projectProtectionTable" *ngIf="!loader && listData && !apiError && viewMode == 1">
        <table mat-table #table [dataSource]="dataSource" [@.disabled]="true">
            <!-- <ng-container matColumnDef="current">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.CurrentProject' | texttransform}}</th>
                <td mat-cell *matCellDef="let element">
                   <svg class="check-icon" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 256 256"><path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>
                </td>
            </ng-container> -->
            <ng-container matColumnDef="medium">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Medium' | texttransform}}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mediumType.defaultName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Frequency' | texttransform}}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.frequencyType.defaultName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Quantity' | texttransform }}</th>
                <td mat-cell *matCellDef="let element" >
                    {{element.quantity}}
                </td>
            </ng-container>
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Options' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <div class="optionsContainer">
                        <i class="fas fa-edit fingerCursor" (click)="editProjectProtection(element)" [tippy]="'ContactProtection.Label.UpdateProjectEntry' | texttransform" [tippyOptions]="{placement:'left'}"></i>
                        <i class="fas fa-trash fingerCursor" (click)="deleteProjectProtection(element)" [tippy]="'ContactProtection.Label.DeleteContact' | texttransform" [tippyOptions]="{placement:'bottom'}"></i>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (mouseenter)="highlightRow(row)"
            (mouseleave)="deselectRow()"
            [ngClass]="{'highlightedRow' : highlightRowId === row.id}"></tr>

        </table>
    </div>
</div>










