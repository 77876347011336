import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { GraphQLService, JJApolloClient, ApolloMethod } from 'app/shared/service/graphql.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-create-task-finalize',
  templateUrl: './create-task-finalize.component.html',
  styleUrls: ['./create-task-finalize.component.scss']
})
export class CreateTaskFinalizeComponent implements OnInit {
  taskCreationHTMLBase = '<jj-container controluiid=<0>></jj-container>';
  taskCreationInnerHTML = null;
  newActivity = false;
  bookTask = false;
  page = 1;
  emailTemplateId = 2;
  bookTaskEntityId = '15';
  public deleteTaskUserRightName;

  @Input() accountListForTaskCreation = null;
  @Input() accountAndContactForTaskCreation = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private graphqlqueryService: GraphqlqueryService,
    private graphQLService: GraphQLService,
    private entityService: EntityService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private projectService: ProjectService,
    private loaderService: LoaderService,
    private methodService: MethodService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public userService: UserService
  ) {
  }

  ngOnInit() {
    this.deleteTaskUserRightName = this.applicationInfoService.applicationSettings['deleteTaskUserRigthName'];
    this.taskCreationInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.taskCreationHTMLBase.replace('<0>', '6355') // TODO In Application-Settings Tabelle auslagern
    );
    if (this.applicationInfoService.miscSettings['createTaskMode'] == 'createactivity') {
      this.applicationInfoService.miscSettings['createTaskMode'] = null;
      this.newActivity = true;
    }
    if (this.applicationInfoService.miscSettings['createTaskMode'] == 'booktask') {
      this.applicationInfoService.miscSettings['createTaskMode'] = null;
      this.bookTask = true;
    }

  }

  changePage(page) {
    if (page == 2) {
      // setTemplate
      this.emailTemplateId = this.applicationInfoService.results.Item(this.applicationInfoService.entities.Item(this.bookTaskEntityId).data.resultId).emailTemplateId;
    }
    this.page = page;
  }

  checkIfEMailTemplateIsAvailable() {
    let returnValue = false;

    if (this.applicationInfoService.entities.Item(this.bookTaskEntityId).data.resultId) {
      if (this.applicationInfoService.results.Item(this.applicationInfoService.entities.Item(this.bookTaskEntityId).data.resultId).isEditableEmail == true) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  resetValues() {
    this.applicationInfoService.entities.Item(this.bookTaskEntityId).data =
    JSON.parse(JSON.stringify(this.applicationInfoService.entitiesorig.Item(this.bookTaskEntityId).data));
    this.eventService.updateEntityDataProperty(15);
  }

  createNewTask() {
    let query = this.graphqlqueryService.getQuery('createTaskCreateTask');
    if (this.projectService.checkIfQuestionsHaveRequiredAnswers(
      this.entityService.getEntityValue(15, 'resultId', 1),
      '15') === false) {
      this.messagingService.showDefaultWarning(
        this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.Header'),
        this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.QuestionAnswerRequired'),
      );
      return;
    }

    this.entityService.checkValuesForAllRequiredControls(15, true)
    .then(() => {
      const modifiedValues = this.commonService.getModifiedEntityValues(15); // TODO In Application-Settings Tabelle auslagern
      let modiviedValuesQueryString = '';
      modiviedValuesQueryString = this.commonService.getModifiedEntityValuesString(15, ['followUpTaskIsAvailable']);
      modiviedValuesQueryString = modiviedValuesQueryString.concat('accountId: ', this.applicationInfoService.currentAccount.id);
      query = query.replace('<subResults>',
        this.projectService.getSubResultQueryString(15)); // TODO In Application-Settings Tabelle auslagern
      // console.warn('modiviedValuesQueryString', query, modiviedValuesQueryString);
      this.loaderService.display(true);
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Mutation, query, [modiviedValuesQueryString])
      .then(() => {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success,
          this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.CreateTaskSuccessfullHeader'),
          this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.CreateTaskSuccessfullMessage'), false);
        this.loaderService.display(false);
        this.resetValues();
        this.methodService.updateTaskFormControls();
        this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
      })
      .catch(() => { this.loaderService.display(false); });
      // console.warn('createNewTask', query, modifiedValues);
    })
    .catch(error => {});
  }

  bookCampaignTask() {
    this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId).data =
      JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.bookTaskEntityId).data));
    this.applicationInfoService.currentTask =
      JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item(this.bookTaskEntityId).data));

    if (this.projectService.checkIfQuestionsHaveRequiredAnswers(
      this.entityService.getEntityValue(15, 'resultId', 1),
      '15') === false) {
      this.messagingService.showDefaultWarning(
        this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.Header'),
        this.axivasTranslateService.getTranslationTextForToken('BookTask.Message.QuestionAnswerRequired'),
      );
      return;
    }

    this.loaderService.display(true);
    this.projectService.saveTaskInformation(15)
    .then(() => {
      this.projectService.bookTask(15).then(() => {
        this.eventService.closeJjPopup();
        this.eventService.updateControlContent(24915);
        this.loaderService.display(false);
      })
      .catch(() => {
        this.loaderService.display(false);
      });
    })
    .catch(() => {
      this.loaderService.display(false);
    });
  }

  deleteTask() {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.DeleteTaskMessage'),
      header: this.axivasTranslateService.getTranslationTextForToken('CreateTask.Message.DeleteTaskHeader'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        const deletedTask = new Object();
        deletedTask['id'] = this.applicationInfoService.entities.Item(this.bookTaskEntityId).data.id;
        deletedTask['isDeleted'] = true;
        this.externaldatasourceService.executeExternalDataSource(418, [
          this.commonService.getModifyArrayBody(deletedTask, [])
        ]).then(() => {
          this.loaderService.display(false);
          this.eventService.updateMicroCampaignViewEvent.emit();
          this.eventService.updateControlContentEvent.emit(new JJEvent('24915', []))
          this.eventService.closeJjPopup();
        }).catch(() => {
          this.loaderService.display(false);
        });
      },
    })
  }
}
