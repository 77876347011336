import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent extends CustomControlBaseClass implements OnInit {

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.Date;
  }

  ngOnInit() {
    if (this.entity != null) {
      const value = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId)
        .data[this.controlDefinition.value];
      if (!isNullOrUndefined(value)) {
        this.controlValue = new Date(value);
      }
      // this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.checkAttributes();
    this.controlGuidPredefine = 'datepicker';
  }

  clearControlValue() {
    if (!this.isEnabled) {
      return;
    }
    this.time = null;
    this.controlValue = null;
    this.entityService.insertDataValue(this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value, null);
  }
}
