import { Component, OnInit } from '@angular/core';
import { GraphQLService, ApolloMethod } from 'app/shared/service/graphql.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ConfirmationService } from 'primeng/api';
import { MessagingService, MessagePosition, MessageSeverity } from 'app/jollyjupiter/service/messaging.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { environment } from 'environments/environment';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss']
})
export class ProjectManagementComponent implements OnInit {
  projectManagmentNewProjectCollapsed = false;
  projectManagmentChangeSchemaCollapsed = false;
  dataInstanceId = 1;

  projects = [];

  /* source */
  sourceCustomers = [];
  selectedSourceCustomer: any;

  selectedSourceProject: any;
  sourceSchemas = [];
  selectedDataSourceSchema: any;

  /* destination */
  selectedDestinationCustomer: any;
  destinationSchemas = [];
  selectedDataDestinationSchema: any;

  isNewCustomer: Boolean = false;
  newCustomerName = '';
  newProjectName = '';
  isNewSchema: Boolean = false;
  newCustomerSchema = '';

  isCustomerAlreadyExists: Boolean = false;
  isProjectAlreadyExists: Boolean = false;
  isSchemaAlreadyExists: Boolean = false;

  /* schema change */
  selectedSchemaCustomer: any;
  schemaProjects = [];
  selectedSchemaProject: any;
  schemas = [];
  selectedSchema: any;
  datainstances = [];
  selectedDataInstance = 1;

  constructor(
    private graphQLService: GraphQLService,
    public applicationInfoService: ApplicationInfoService,
    private graphqlqueryService: GraphqlqueryService,
    private sharedAPI: SharedAPI,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnInit() {
    this.reload();
    console.warn(this.applicationInfoService.projectInfo)
  }

  reload () {
    this.getCustomers();
    this.getProjects();
    this.getSchematas(this.dataInstanceId);
    this.getSchemaProjects();
    this.getChangeSchematas(this.dataInstanceId);
    this.getDataInstances();
  }

  changeProjectSource() {
    this.loadProjectData(this.selectedSourceProject);
  }

  changeCustomerSource() {
    this.getProjects();
    this.getSchematas(this.dataInstanceId);
  }

  filterProjectsSourceByCustomerId() {
    if (this.selectedSourceCustomer != null) {
      return this.projects.filter(x => x.customerId === this.selectedSourceCustomer.id);
    }
  }

  changeCustomerDestination() {
    this.isNewCustomer = (this.selectedDestinationCustomer.id === -1 );
    if (!this.isNewCustomer) {
      this.newCustomerName = this.selectedDestinationCustomer.customerName;
      this.isCustomerAlreadyExists = false;
    }
  }

  changeSchemaDestination() {
    this.isNewSchema = (this.selectedDataDestinationSchema.id === -1 );
    if (!this.isNewSchema) {
      this.newCustomerSchema = this.selectedDataDestinationSchema.schema;
      this.isSchemaAlreadyExists = false;
    }
  }

  loadProjectData(selectedProject: any) {
    // console.log('loadProjectData selectedProject', selectedProject);
    this.selectedDataSourceSchema = null;
    this.selectedDataSourceSchema = this.sourceSchemas.filter(
      x => (  x.id === selectedProject.dataInstanceDatabaseDetailId ))[0];

  }

  getCustomers() {
    this.sourceCustomers = [];
    this.externaldatasourceService.executeExternalDataSource(8, [])
    .then(result => {
      this.sourceCustomers = result;
      this.sourceCustomers.unshift({customerName: '<new>', id: -1});
    })
    .catch(error => {
      console.error(error);
    });
  }

  getDataInstances() {
    this.externaldatasourceService.executeExternalDataSource(146).then(getDataInstancesResult => {
      this.datainstances = getDataInstancesResult;
    });    
  }

  getProjects() {
    this.projects = [];
    if (this.selectedSourceCustomer != null) {
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.developerProjectAdministratonGetProjects)
      .then(result => {
        result.data.projects.forEach(project => {
          if (project.customerId === this.selectedSourceCustomer.id) {
            this.projects.push(project);
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  getSchematas(dataInstanceId: number) {
    this.sourceSchemas = [];
    this.destinationSchemas = [];
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.developerProjectAdministratonGetSchemas, [dataInstanceId])
    .then(result => {
      this.destinationSchemas.push({schema: '<new>', id: -1});
      result.data.dataInstanceDatabaseDetails.forEach(schema => {
        this.destinationSchemas.push(schema);
        this.sourceSchemas.push(schema);
      });
    })
    .catch(error => {
      console.error(error);
    });
  }

  preCheckExecution() {
    if (this.isCustomerAlreadyExists || this.isProjectAlreadyExists || this.isSchemaAlreadyExists ) {
      this.checkInput();
    } else {
      this.cloneProject();
    }
  }


  checkInput() {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('DeveloperProjectManagement.Question.WarningSettingsHeader'),
      header: this.axivasTranslateService.getTranslationTextForToken('DeveloperProjectManagement.Question.WarningSettingsHeader'),
      icon: 'pi pi-exclamation-triangle',
     accept: () => {
        this.cloneProject();
      }
    });

    return false;
  }

  cloneProject(): Promise<any> {
    this.loaderService.display(true);
    return new Promise<any>((resolve, reject) => {
      this.sharedAPI.cloneProject(
        this.selectedSourceProject.id, 
        this.newCustomerName, 
        this.newProjectName, 
        this.selectedDataSourceSchema.schema, 
        this.newCustomerSchema
      ).subscribe(result => {
        this.loaderService.display(false);
        resolve(result);
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
          this.axivasTranslateService.getTranslationTextForToken('DeveloperProjectManagement.Message.ProjectCreateSuccess'), false);
      }, (error) => {
        this.loaderService.display(false);
        reject(error);
      });
    });
  }

  checkIfCustomerAlreadyExists() {
    for (let i = 0; i < this.sourceCustomers.length; i++) {
      if ( this.sourceCustomers[i].customerName === this.newCustomerName ) {
        this.isCustomerAlreadyExists = true;
        break;
      }
      this.isCustomerAlreadyExists = false;
    }
  }

  checkIfProjectAlreadyExists() {
    for (let i = 0; i < this.projects.length; i++) {
      if ( this.projects[i].projectName === this.newProjectName ) {
        this.isProjectAlreadyExists = true;
        break;
      }
      this.isProjectAlreadyExists = false;
    }
  }
  checkIfSchemaAlreadyExists() {
    for (let i = 0; i < this.projects.length; i++) {
      if ( this.destinationSchemas[i].schema === this.newCustomerSchema ) {
        this.isSchemaAlreadyExists = true;
        break;
      }
      this.isSchemaAlreadyExists = false;
    }
  }

  toggleClosedData(e: { target: { checked: boolean; }; }) {
    // this.isExportOnlyClosedData = e.target.checked;
  }


  toggleItem(toggleName: string) {
    console.warn(toggleName, this[toggleName], this[toggleName]);
    this[toggleName] = !this[toggleName];
  }

  changeSchemaCustomer() {
    this.selectedSchemaProject = null;
    this.selectedSchema = null;
  }

  loadSchemaProjectData(selectedProject: any) {
    // console.log('loadProjectData selectedProject', selectedProject);
    this.selectedSchema = null;
    this.selectedSchema = this.schemas.filter(
      x => (  x.id === selectedProject.dataInstanceDatabaseDetailId ))[0];

  }

  getSchemaProjects() {
    this.schemaProjects = [];
    this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.developerProjectAdministratonGetProjects)
    .then(result => {
      console.warn('schemaProjects', result);
      result.data.projects.forEach(project => {
        this.schemaProjects.push(project);
      });
    })
    .catch(error => {
      console.error(error);
    });
  }

  filterSchemaProjectsSourceByCustomerId() {
    if (this.selectedSchemaCustomer != null) {
      return this.schemaProjects.filter(x => x.customerId === this.selectedSchemaCustomer.id);
    }
  }

  changeProject() {
    this.loadSchemaProjectData(this.selectedSchemaProject);
  }

  changeSchema() {
    this.loadSchemaData(this.selectedSchema);
  }


  getChangeSchematas(dataInstanceId: number) {
    this.schemas = [];
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.developerProjectAdministratonGetSchemas, [dataInstanceId])
    .then(result => {
      result.data.dataInstanceDatabaseDetails.forEach(schema => {
        this.schemas.push(schema);
      });
    })
    .catch(error => {
      console.error(error);
    });
  }

  loadSchemaData(selectedSchema: any) {
    console.log('loadSchemaData selectedSchema', selectedSchema);
  }

  updateSchema(project: any, dataInstance: any) {
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
      this.graphqlqueryService.updateProjectAdministratonSchemaMutation, [project.id , dataInstance.id  ])
    .then(result => {
      console.warn('updateSchema', result);
        this.selectedSchemaProject.dataInstanceDatabaseDetailId = dataInstance.id;
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
            this.axivasTranslateService.getTranslationTextForToken('DeveloperProjectManagement.Message.UpdateSuccess'), false);
    })
    .catch(error => {
      console.error(error);
    });
  }
}
