import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UiService } from '../../../service/ui.service';
import { EventService } from '../../../service/event.service';
import { Subscription, timer } from 'rxjs';
import { ApplicationInfoService } from '../../../../core/application/application-info.service';
import { Dialog } from 'primeng/dialog';

export enum DesignerType {
  Container = 1,
  Control = 2
}

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})

export class CustomPopupComponent implements OnInit, OnDestroy {
  showPopupSubscription: Subscription = new Subscription;
  closePopupSubscription: Subscription = new Subscription;
  loadNewContainerContentEventSubscription: Subscription = new Subscription;
  showDesignerPopupEventSubscription: Subscription = new Subscription;
  showNewContentEventSubscription: Subscription = new Subscription;

  public innerHTML = null;
  @Input() controluiid = '';
  showPopup = false;
  popupId = '';
  popupTitle = '';
  popupIsClosable = true;

  @ViewChild(Dialog) primeNGDialog: Dialog;

  constructor(
    private domSanitizer: DomSanitizer
    , private uiService: UiService
    , private applicationInfoService: ApplicationInfoService
    , private eventService: EventService
  ) {
    this.loadNewContainerContentEventSubscription = this.eventService.loadNewContainerContentEvent.subscribe(event => {
      if (this.controluiid === event.target) {
        this.uiService.getUIfromID(event.arguments[0], this.controluiid);
      }
    });

    this.showDesignerPopupEventSubscription = this.eventService.designerPopupEvent.subscribe(event => {
      if (this.controluiid !== 'designer') { return; }
      this.showPopup = event.arguments[0];
      this.popupTitle = '';
      this.popupTitle = this.popupTitle.concat('Editor für: ', event.arguments[2], ' ', event.arguments[1]);
    });

    this.showNewContentEventSubscription = this.eventService.showNewContentEvent.subscribe(event => {
      if (this.controluiid === event.arguments[1]) {
        this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(event.arguments[0]);
        this.showPopup = true;
      }
    });

    this.showPopupSubscription = this.eventService.showPopupEvent.subscribe(event => {
      this.popupTitle = event.target;
      this.popupId = event.arguments[1];
      this.popupIsClosable = event.arguments[2];
    });

    this.closePopupSubscription = this.eventService.closePopupEvent.subscribe(() => {
      this.showPopup = false;
    });
  }

  ngOnInit() {
    const responseTimer = timer(1000, 1000);
    responseTimer.subscribe(restime => {
    });
  }

  style() {
    if (this.applicationInfoService.isMobile()) {
      return {
        'width': '100vw',
        'height': '100vh',
        'z-index': '500'
      };
    } else {
      return {
        'width': '90vw',
        'height': '80vh',
        'z-index': '500'
      };
    }
  }

  contentstyle() {
    if (this.applicationInfoService.isMobile()) {
      return {
        'min-height': '95vh',
        'max-height': '95vh',
        'z-index': '500'
      };
    } else {
      return {
        'min-height': '80vh',
        'max-height': '80vh',
        'z-index': '500'
      };
    }
  }


  ngOnDestroy() {
    if (this.showNewContentEventSubscription) { this.showNewContentEventSubscription.unsubscribe(); }
    if (this.loadNewContainerContentEventSubscription) { this.loadNewContainerContentEventSubscription.unsubscribe(); }
    if (this.showPopupSubscription) { this.showPopupSubscription.unsubscribe(); }
    if (this.closePopupSubscription) { this.closePopupSubscription.unsubscribe(); }
    if (this.showDesignerPopupEventSubscription) { this.showDesignerPopupEventSubscription.unsubscribe(); }    
  }
}
