<div class="row">
    <div class="col-md-12">
        <div *ngFor="let i of dashboardsList" class="radioButtonWrapper">
            <input type="radio" (change)="renderDashboard(i)" [(ngModel)]="selectedDashboard" [value]="i"> {{ i.Name }}
        </div>
        
        <!-- <div (click)="renderDashboard(i)" *ngFor="let i of dashboardsList" class="dashboardItem">{{i.Name}}</div> -->
    </div>
</div>
<div [style.height]="getHeightBoldBiDashoardHeight()" style="position: relative;" [ngClass]="{
    hideInDom: applicationInfoService.isMobile() && applicationInfoService.paletteCollapsed == false
}">
    <!-- <span *ngIf="replaceTranslationTokens()"></span> -->
    <div class="boldBiRefresh" *ngIf="applicationInfoService.isDeveloper">
        <i class="fas fa-sync fingerCursor" (click)="refreshBoldBiData()"></i>
        {{ 'BoldBi.Label.LastSyncTime' | texttransform }}
        {{ applicationInfoService.lastBoldBiSync | date: 'medium' }}
    </div>
    <span id="designSpanBoldBI"></span>
    <div id="viewer-section">
        <div id="dashboard"></div>
    </div>
</div>