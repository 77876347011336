import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LanguageService } from 'app/shared/translation/language.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { WizardMode, WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-leadreportsettings',
  templateUrl: './leadreportsettings.component.html',
  styleUrls: ['./leadreportsettings.component.scss']
})
export class LeadreportsettingsComponent implements OnInit {
  languages = [];
  lookuptables = [];
  levels = [];
  ftpConnections = [];
  templateDefinitions = [];
  leadreportExportSettings = [];
  newMode = true;
  settings = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private languageService: LanguageService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private wizardService: WizardService
  ) { }

  ngOnInit() {
    this.settings.push(JSON.parse(JSON.stringify(this.applicationInfoService.projectInfo)));
    this.languageService.getLanguages()
    .then(getAvailableLanguagesResult => {
      this.languages = getAvailableLanguagesResult;
    })
    .catch(error => {
      console.error('LeadreportComponent getAvailableLanguages', error);
    });
    this.getFtpConnections();
    this.getLevels();
    this.getExportSettings();
    this.getTemplateDefinitions();
  }

  showWizard(array) {
    this.wizardService.showWizard(WizardMode.Leadreport, array);
  }

  getFtpConnections() {
    this.externaldatasourceService.executeExternalDataSource(259, []).then(result => { this.ftpConnections = result; });
  }

  getLevels() {
    this.externaldatasourceService.getLookupTableValues(24, 2).then(result => {this.levels = result; });
  }

  getTemplateDefinitions() {
    this.externaldatasourceService.executeExternalDataSource(261, []).then(result => { this.templateDefinitions = result; });
  }

  getExportSettings() {
    this.externaldatasourceService.getLookupTableValues(36, 2).then(result => {this.leadreportExportSettings = result; });
  }

  saveSettings() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(260, [
      this.commonService.getModifyArrayBody(this.applicationInfoService.projectInfo, ['pools', 'uiStyle', 'categories'])
    ]).then(result => {
      this.loaderService.display(false);
    })
    .catch(error => {
      console.error('LeadreportsettingsComponent', error);
      this.loaderService.display(false);
    });
  }
}
