import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { LanguageService } from 'app/shared/translation/language.service';
import { WizardService } from '../wizard.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

interface EmailtemplateLanguage{
  bodyHtml?: string,
  isPublished?: boolean;
}

@Component({
  selector: 'app-wizard-emailtemplate',
  templateUrl: './wizard-emailtemplate.component.html',
  styleUrls: ['./wizard-emailtemplate.component.scss']
})
export class WizardEmailtemplateComponent implements OnInit {
  caretPos = 0;
  viewModeToolbar = 1;
  viewMode = 1;  
  innerHTML = null;
  placeholders = [];
  emailLanguage = 2;
  selectedTemplate = null;
  emailContents = null;
  emailTemplateLanguage: EmailtemplateLanguage= {};
  emailTemplateLanguageBackup;
  blockWorkflowCreation = false;
  resultsWithThisEMailTemplate = [];
  testMailUsers = [];
  testMailContact = null;
  mailConfigSesRefIds = [];
  mailConfigSmtpRefIds = [];
  mailConfigTypes = [];
  senderIsValid = false;
  emailAttachments = [];
  languages = [];
  showAttachmentWarning = false;

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private languageService: LanguageService,
    public emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    public commonService: CommonService,
    private loaderService: LoaderService,
    private refreshValueService: RefreshValueService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.getEMailContents();
    this.getResultsWithThisEMailTemplate();
    this.getUsers();
    this.getTestContact();
    this.getSesRefIds();
    this.getSMTPRefIds();
    this.getMailConfigTypes();
    this.getLanguages();
    this.emailtemplateService.getAttachments(this.wizardService.wizardArray.id).then(getAttachmentResult => {
      this.emailAttachments = getAttachmentResult.emailTemplateAttachments;
      this.checkAttachmentWarning();
    });
    this.senderIsValid = this.emailtemplateService.checkSenderDomain(this.wizardService.wizardArray.sender);   
  }
  
  checkAttachmentWarning() {
    this.showAttachmentWarning = false;
    if (
      this.emailAttachments.filter(attachment => attachment.languageId == 2).length !=
      this.emailAttachments.filter(attachment => attachment.languageId == 1).length
    ) {
      this.showAttachmentWarning = true;
    }
  }

  getSesRefIds() {
    this.externaldatasourceService.executeExternalDataSource(135)
    .then(getCampaignsResult => {
      getCampaignsResult.forEach(campaign => {
        if (campaign.isActive == true && campaign.sesConfigSetId != null) {
          this.mailConfigSesRefIds.push(campaign);
        }        
      });
    });
  }

  getLanguages() {
    this.languageService.getLanguages().then(languages => this.languages = languages);
  }

  checkDomain() {
    this.senderIsValid = this.emailtemplateService.checkSenderDomain(this.wizardService.wizardArray.sender)
  }

  getSMTPRefIds() {
    this.externaldatasourceService.executeExternalDataSource(659, [])
    .then(getEMailSettingsResult => { 
      this.mailConfigSmtpRefIds = getEMailSettingsResult;
    });
  }

  getTestContact() {
    this.externaldatasourceService.executeExternalDataSource(707, [])
    .then(result => {
      if (result != null) {
        this.testMailContact = result.id;
      }      
    });
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(14, [])
    .then(result => {
      this.testMailUsers = result;
    });
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
       this.caretPos = oField.selectionStart;
    }
  }

  getEMailContents() {
    this.emailtemplateService.getEMailContents()
    .then(getEMailContentsResult => {
      this.emailContents = getEMailContentsResult;
      this.getEMailTemplateLanguage();
    })
  }

  setViewModeToolbar(value) {
    this.viewModeToolbar = Number(value);
  }

  renderContent() {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.emailtemplateService.renderEMail(
      this.emailTemplateLanguage.bodyHtml, 
      this.emailContents
    ));
  }

  setViewMode(value) {
    if (value == 3) {
      this.setViewModeToolbar(1);
    }
    this.renderContent();
    this.viewMode = Number(value);
  }

  getEMailTemplateLanguage() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(461, [this.wizardService.wizardArray.id, this.emailLanguage])
    .then(getEMailTemplateLanguageResult => {  
      this.loaderService.display(false);
      if (getEMailTemplateLanguageResult == null) {
        this.createEMailTemplateLanguage(this.selectedTemplate.id, this.emailLanguage);
      } else {
        this.emailTemplateLanguage = getEMailTemplateLanguageResult;        
        this.emailTemplateLanguageBackup = JSON.parse(JSON.stringify(getEMailTemplateLanguageResult));
        // console.log('getEmail Template Language', this.emailTemplateLanguage)

        this.renderContent();
      }      
    })
    .catch(error => {
      this.loaderService.display(false);
      console.error('getEMailTemplateLanguage', error);
    })
  }

  createEMailTemplateLanguage(templateId, language) {
    this.loaderService.display(true);
    this.emailtemplateService.createEMailTemplateLanguage(templateId, language)
    .then(() => {
      this.getEMailTemplateLanguage();
      this.loaderService.display(false);
    })
    .catch(error => {
      console.error('createEMailTemplateLanguage', error);
      this.loaderService.display(false);
    });
  }

  addContentToTemplate(content) {
    const memberName = ''.concat('{{', content.defaultName, '}}');
    this.emailTemplateLanguage.bodyHtml = ''.concat(
      this.emailTemplateLanguage.bodyHtml.substring(0, this.caretPos),
      memberName,
      this.emailTemplateLanguage.bodyHtml.substring(this.caretPos, this.emailTemplateLanguage.bodyHtml.length),
    );
    this.caretPos = this.caretPos + memberName.length;
  }

  saveTemplateLanguage(publish = false) { 
    this.emailTemplateLanguage.isPublished = publish;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(434, [      
      this.commonService.getModifyArrayBody(this.emailTemplateLanguage, [])
    ])
    .then(() => {
      this.loaderService.display(false);
      this.getEMailTemplateLanguage();
    }).catch(() => { this.loaderService.display(false); });
  }

  checkIfTemplateIsChanged() {
    if (JSON.stringify(this.emailTemplateLanguageBackup) == JSON.stringify(this.emailTemplateLanguage)) {
      return false;
    } else {
      return true;
    }
  }

  createWorkflow() {
    this.blockWorkflowCreation = true;
    this.emailtemplateService.createProcessForEmailTemplate(this.wizardService.wizardArray)
    .then(() => { 
      this.refreshValueService.getCompleteProjectInformaton()
      .then(() => {
        this.getResultsWithThisEMailTemplate(); 
      });
    });
  }
  
  getResultsWithThisEMailTemplate() {    
    this.resultsWithThisEMailTemplate = this.applicationInfoService.results.toArray().filter(
      result => result.emailTemplateId == this.wizardService.wizardArray.id
    );
  }

  testTemplate() {
    if (this.testMailContact != null) {
      this.emailtemplateService.sendTestEmail(this.wizardService.wizardArray.id, this.wizardService.wizardArray.testMailCampaign, this.testMailContact, this.wizardService.wizardArray.testMailUser.email);
    }    
  }

  getValidCampaignsForSesConfig(config) {
    const campaigns = [];
    const campaignsComplete = this.applicationInfoService.campaigns.toArray();
    campaignsComplete.forEach(campaign => {
      if (campaign.sesConfigSetId == config.configRefId) {
        campaigns.push(campaign);
      }
    });
    return campaigns;
  }

  updateTemplateConfig(config, updateCampaignSesConfigSetId = false) {
    if (updateCampaignSesConfigSetId) {
      const campaign = this.mailConfigSesRefIds.find(sesConfig => sesConfig.id == config.campaignId);      
      config.configRefId = campaign.sesConfigSetId;
    }
    this.externaldatasourceService.executeExternalDataSource(722, [
      this.commonService.getModifyArrayBody(config , ['testMailCampaign', 'testMailUser'])
    ]);
  }

  createNewConfig() {
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.emailTemplateConfigs)) {
      this.wizardService.wizardArray.emailTemplateConfigs = [];
    }
    let configRefId = null;
    let typeLookupId = null;
    let priority = 0;
    if (this.mailConfigSesRefIds.length != 0) {
      configRefId = this.mailConfigSesRefIds[0].sesConfigSetId;  
      typeLookupId = 5935;
    }
    if (this.mailConfigSmtpRefIds.length != 0) {
      configRefId = this.mailConfigSmtpRefIds[0].id;  
      typeLookupId = 5936;
      priority = 1;
    }    
    this.externaldatasourceService.executeExternalDataSource(720, [
      this.commonService.getModifyArrayBody({
        configRefId: configRefId,
        emailTemplateId: this.wizardService.wizardArray.id,
        typeLookupId: typeLookupId,
        priority: priority
      }, [])
    ])
    .then(createNewConfigResult => {      
      this.wizardService.wizardArray.emailTemplateConfigs.push(createNewConfigResult);
    });
  }

  getMailConfigTypes() {
    this.externaldatasourceService.getLookupTableValues(78, 2).then(getMailConfigTypesResult => {
      this.mailConfigTypes = getMailConfigTypesResult;
    });
  }

  deleteConfig(config) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(721, [config.id])
    .then(() => {
      this.commonService.removeItemFromArray(this.wizardService.wizardArray.emailTemplateConfigs, config);
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  addAttachment(files) {    
    // const files: FileList = event.target.files;
    // const formData = new FormData();
    for ( let i = 0; i < files.length; i++) {
      this.loaderService.display(true);    
      this.sharedAPI.uploadEmailTemplateAttachment(files[i], this.wizardService.wizardArray.id, this.emailLanguage).subscribe(data => {
        this.loaderService.display(false); 
        this.emailtemplateService.getAttachments(this.wizardService.wizardArray.id).then(getAttachmentResult => {
          this.emailAttachments = getAttachmentResult.emailTemplateAttachments;
          this.checkAttachmentWarning();
        });   
      });          
    }
  }

  onFilesSelected(files) {
    this.addAttachment(files);
  }

  deleteAttachment(emailTemplateAttachment) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(797, [emailTemplateAttachment.id])
    .then(() => {
      this.emailtemplateService.getAttachments(this.wizardService.wizardArray.id).then(getAttachmentResult => {
        this.emailAttachments = getAttachmentResult.emailTemplateAttachments;
        this.checkAttachmentWarning();
      });
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }
}
