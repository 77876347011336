
import { NotificationsService } from '../core/notifications.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Injectable, Injector } from '@angular/core';

export let isDebugMode = environment.isDebug;
// export let applicationName = environment.applicationName;
export let applicationName = '';
export let userMessage = 'An error occurs. Please contact ITHELP!';

const noop = (): any => undefined;

@Injectable()
export class LoggerService {

  private router: Router;

  Debug(message: any[]) {
    if (isDebugMode) {
      console.log(message);
    } else {
      return noop;
    }
  }

  Info(message: string, parameters: any | any[] = null) {
    if (isDebugMode) {
      this.notificationsService.info(applicationName, message, false, 3000);
    } else {
      this.notificationsService.info(applicationName, message, false, 3000);
      return noop;
    }
  }

  Warning(message: string, parameters: any | any[] = null) {
    if (isDebugMode) {
      this.notificationsService.warning(applicationName, message, false, 5000);
    } else {
      this.notificationsService.warning(applicationName, message, false, 5000);
      return noop;
    }
  }

  Error(message: string, parameters: any | any[] = null) {
    if (environment.isDebug) {
       console.error(message, parameters);
       this.notificationsService.error(applicationName, message, true, 10000);
    } else {
      this.notificationsService.error(applicationName, userMessage, true, 10000);
      return noop;
    }
  }

  constructor(injector: Injector, private notificationsService: NotificationsService) {
    isDebugMode = environment.isDebug;
   }
}
