import { AfterViewInit, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { RingcentralService } from 'app/jollyjupiter/service/ringcentral.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-ringcentral-popup',
  templateUrl: './ringcentral-popup.component.html',
  styleUrls: ['./ringcentral-popup.component.scss']
})
export class RingcentralPopupComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sort1', { static: false }) sort: MatSort;
  @ViewChild('sort2', { static: false }) sortAdressBook: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  dataSourceAdressBook: MatTableDataSource<any> = new MatTableDataSource<any>();
  selectedCacheArea = null;
  public displayedColumnsCallHistory = ['direction', 'fromNumber', 'toNumber', 'start', 'duration'];
  public displayedColumnsAdressBook = ['firstName', 'lastName', 'email', 'department', 'extension', 'phoneNumber'];

  callingNumber = '';
  filterString  = '';
  selectedTab = 2;
  popupMode = null;
  showFoundContacts = false;
  loadingData = false;
  potentialCallers = [];
  lastSearched = '';
  ringCentralMode = 0;

  visibleTabs = [
    { name: 'RingCentral.Label.Direct', id: 2 },
    { name: 'RingCentral.Label.CallHistory', id: 0 },
    { name: 'RingCentral.Label.PhoneBook', id: 1 }
  ];

  constructor(
    public ringcentralService: RingcentralService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private externalDataSourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private methodService: MethodService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private commonService: CommonService,
    private paginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit(): void {
    this.paginatorIntl.itemsPerPageLabel = this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator');
    if (this.applicationInfoService.phoneConnection == this.applicationInfoService.phoneRingCentralWebRTC) {
      this.ringCentralMode = 2;
    } else {
      this.ringCentralMode = 1;
    }
    this.getCallHistory();
    this.getAdressbook();
    this.popupMode = this.applicationInfoService.miscSettings['ringCentralPopupMode'];
    this.applicationInfoService.miscSettings['ringCentralPopupMode'] = null;
    if (this.applicationInfoService.isDeveloper && this.ringCentralMode == 1) {
      this.visibleTabs.push({ name: 'Cache', id: 999 })
    }
  }

  logout() {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('AWS.Logout.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('AWS.Logout.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.eventService.closeJjPopup();
        localStorage.removeItem('ringCentralAuthCode');
        this.ringcentralService.logoutUser();
      },
    });
  }

  ngOnChanges() {
    this.dataSource.sort = this.sort;
    this.dataSourceAdressBook.sort = this.sortAdressBook;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSourceAdressBook.sort = this.sortAdressBook;
  }

  getCallHistory() {
    if (this.ringCentralMode == 1) {
      this.ringcentralService.hubConnection.invoke('GetCallLog', [this.ringcentralService.userInfo.extensionId])
      .then(result => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.sort = this.sort;
      });
    } else {
      this.ringcentralwebphoneService.getRecentCalls().then(getRecentCallsResult => {
        console.warn('getCallHistory', getRecentCallsResult);
        getRecentCallsResult.records.forEach(getRecentCallsResultItem => {
          getRecentCallsResultItem['fromNumber'] = getRecentCallsResultItem.from.phoneNumber;
          getRecentCallsResultItem['toNumber'] = getRecentCallsResultItem.to.phoneNumber;
          getRecentCallsResultItem['start'] = getRecentCallsResultItem.startTime;
          getRecentCallsResultItem['durationFormatted'] = this.commonService.getDurationString(getRecentCallsResultItem.duration);
        });
        this.dataSource = new MatTableDataSource(getRecentCallsResult.records);
        this.dataSource.sort = this.sort;
      });
    }
  }

  getAdressbook() {
    if (this.ringCentralMode == 1) {
      this.ringcentralService.hubConnection.invoke('GetAddressBook', [this.ringcentralService.userInfo.extensionId])
      .then(result => {
        result.sort((a, b) => a.lastName < b.lastName ? -1 : 1);
        this.dataSourceAdressBook = new MatTableDataSource(result);
        this.dataSourceAdressBook.sort = this.sortAdressBook;
      });
    } else {
      this.ringcentralwebphoneService.getAdressBook().then(getAdressbookResult => {
        getAdressbookResult.records.forEach(getAdressbookResultItem => {
          getAdressbookResultItem['extension'] = getAdressbookResultItem.extensionNumber;
          if (getAdressbookResultItem.phoneNumbers) {
            if (getAdressbookResultItem.phoneNumbers.length > 0) {
              getAdressbookResultItem['phoneNumber'] = getAdressbookResultItem.phoneNumbers[0].formattedPhoneNumber;
              this.dataSourceAdressBook = new MatTableDataSource(getAdressbookResult.records);
              this.dataSourceAdressBook.sort = this.sortAdressBook;
            }
          }
        })
      });
    }
  }

  setCurrentChild(tab) {
    this.selectedTab = tab.id;
    this.filterString = '';
    this.applyFilter(null);
  }

  callTarget(row) {
    if (row.direction == 'Outbound') {
      this.ringcentralService.makeCall(row.phoneNumber);
    } else {
      this.ringcentralService.makeCall(row.extension);
    }

  }

  callHistoryTarget(row) {
    if (row.direction == 'Outbound') {
      this.callNumber(row.toNumber);
    } else {
      this.callNumber(row.fromNumber);
    }
  }

  callNumber(number) {
    console.log('callnumber');
    switch (this.popupMode) {
      case 'consult':
        this.ringcentralService.consult(number);
        this.eventService.closeJjPopup();
        break;
      case 'transfer':
        this.ringcentralService.transferCall(number);
        this.eventService.closeJjPopup();
        break;
      case 'conference':
        this.ringcentralService.makeCall(number);
        this.eventService.closeJjPopup();
        break;

      default:
        if (this.ringCentralMode == 1) {
          this.ringcentralService.makeCall(number);
          this.eventService.closeJjPopup();
        } else {
          this.ringcentralwebphoneService.makeCall(number);
          this.eventService.closeJjPopup();
        }
        break;
    }
  }

  applyFilter(event: Event) {
    this.dataSource.filter = this.filterString;
    this.dataSourceAdressBook.filter = this.filterString;
  }

  updateCache() {
    this.ringcentralService.refreshCache(this.selectedCacheArea);
  }

  getPotentialCaller(callerNumber) {
    this.eventService.openIncomingCallEvent.emit(callerNumber);
  }

  loadContact(contact) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [contact.id]);
  }
}
