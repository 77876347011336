import { Injectable } from '@angular/core';
import { ObjectDeserializeService } from '../pipe/object-deserialize.service';
import { isNullOrUndefined } from 'util';
import { LoaderService } from 'app/shared/service/loader-service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { environment } from 'environments/environment';
import { CommonService } from './common.service';
import { MessagingService } from './messaging.service';
// import { tokenName } from '@angular/compiler';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Injectable({
  providedIn: 'root'
})
export class JjtranslationService {
  tempTranslationName = 'tempTranslations';
  constructor(
    private objectDeserializeService: ObjectDeserializeService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private authService: AuthService,
    private commonService: CommonService,
    private messagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService
  ) { }

  addTranslationArrayToItemFromArray(itemArray: any, item: any, translations: any, target: any, callType = 'original') {
    console.warn('JjtranslationService addTranslationArrayToItemFromArray', itemArray, item, translations, target, callType);
    if (Array.isArray(itemArray)) {
      itemArray.forEach(arrayItem => {
        this.addTranslationArrayToItem(arrayItem, item, translations, target, callType);
      });
    } else {
      this.addTranslationArrayToItem(itemArray, item, translations, target, callType);
    }
    return itemArray;
  }

  addTranslationArrayToItem(arrayItem, item: any, translations: any, target: any, callType) {
    if ((callType === 'original' && arrayItem.id === item.id) ||
    (callType === 'temp' && arrayItem.tempItemId === item.tempItemId && arrayItem.tempItemId !== null)) {
      if (this.commonService.isNullOrUndefined(this.objectDeserializeService.transform(arrayItem, target, null))) {
        // creating target on array
        this.commonService.addToObject(arrayItem, target);
      }
      if (target !== null) {
        this.objectDeserializeService.transform(arrayItem, target)[this.tempTranslationName] = translations;
      } else {
        arrayItem['tempTranslations'] = translations;
      }
  }

  }
  saveTranslations(translationLanguages: any, translationValues: any, selectedTranslationToken: any,
    existingTranslationsForToken): Promise<any> {
    return new Promise((saveTranslationsResolve, saveTranslationsReject) => {
      if (translationValues.length === 0) {
        saveTranslationsResolve(null);
        return;
      }
      this.loaderService.display(true);
      const promiseArray: Promise<any>[] = [];
      translationLanguages.forEach(language => {
        let idOfTranslation = null;
        existingTranslationsForToken.forEach(existingTranslationForToken => {
          if (language.id === existingTranslationForToken.language.id) {
            idOfTranslation = existingTranslationForToken.id;
          }
        });
        let translationString = translationValues[language.id];
        if (this.commonService.isNullOrUndefined(translationString)) {
          translationString = '';
        }
        translationString = this.commonService.cleanStringValue(translationString);
        if (this.commonService.isNullOrUndefined(idOfTranslation)) {
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(113,
            [language.id, selectedTranslationToken.id, translationString]));
        } else {
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(112,
            [translationString, idOfTranslation]));
        }
      });
      Promise.all(promiseArray)
      .then(result => {
        this.loaderService.display(false);
        saveTranslationsResolve(result);
      })
      .catch(error => {
        this.loaderService.display(false);
        saveTranslationsReject();
      });
    });
  }

  saveTempTranslation(translationToken: any): Promise<any> {
    return new Promise((saveTempTranslationResolve, saveTempTranslationReject) => {
    });
  }

  getTranslationLanguages(): Promise<any> {
    return new Promise((getTranslationLanguagesResolve, getTranslationLanguagesReject) => {
      this.externaldatasourceService.executeExternalDataSource(89)
      .then(executeExternalDataSourceResult => {
        getTranslationLanguagesResolve(executeExternalDataSourceResult);
      })
      .catch(error => {
        getTranslationLanguagesReject(error);
      });
    });
  }

  getTranslationGroups():  Promise<any> {
    return new Promise((getTranslationGroupsResolve, getTranslationGroupsReject) => {
      const groupNames = [];
      this.externaldatasourceService.executeExternalDataSource(111)
      .then(executeExternalDataSourceResult => {
        executeExternalDataSourceResult.forEach(executeExternalDataSourceResultItem => {
          if (!this.commonService.checkIfItemIsInArray(groupNames, executeExternalDataSourceResultItem.group)) {
            groupNames.push(executeExternalDataSourceResultItem.group);
          }
        });
        getTranslationGroupsResolve(groupNames);
      })
      .catch(error => {
        getTranslationGroupsReject(error);
      });
    });
  }

  upsertTranslation(): Promise<any> {
    return new Promise((upsertTranslationResolve, upsertTranslationReject) => {

    });
  }

  modifiyLeadStateTranslation(translationToken: any): Promise<any> {
    return new Promise((modifiyLeadStateTranslationResolve, modifiyLeadStateTranslationReject) => {
      const promiseArray: Promise<any>[] = [];
      if (this.commonService.isNullOrUndefined(translationToken.tempTranslations)) {
        modifiyLeadStateTranslationResolve(null);
        return;
      }
      if (this.commonService.isNullOrUndefined(translationToken.id)) {
        this.upsertTranslationToken(translationToken, promiseArray);
      }
      modifiyLeadStateTranslationReject();
    });
  }

  updateTranslationCache() {
    this.authService.AuthGet(environment.apiUrl +
      '/AdminRefreshRepositoryCache/refresh/entity/Translation/0').subscribe();
  }

  checkTranslationTokenRequirements(translationToken: any): boolean {
    if (this.commonService.isNullOrUndefined(translationToken.tokenName) || translationToken.tokenName ===  '') {
      this.messagingService.showDefaultInfo('',
        this.axivasTranslateService.getTranslationTextForToken('DesignTranslation.Message.TokenNameRequired'));
      return false;
    }
    if (this.commonService.isNullOrUndefined(translationToken.group) || translationToken.group ===  '') {
      this.messagingService.showDefaultInfo('',
        this.axivasTranslateService.getTranslationTextForToken('DesignTranslation.Message.GroupRequired'));
      return false;
    }
    if (this.commonService.isNullOrUndefined(translationToken.subgroup) || translationToken.subgroup ===  '') {
      translationToken.subgroup = '_';
    }
    if (this.commonService.isNullOrUndefined(translationToken.field) || translationToken.field ===  '') {
      translationToken.field = null;
    }
    return true;
  }

  upsertTranslationToken(translationToken: any, promiseArray: any) {
    if (translationToken.id === null) {
      promiseArray.push(this.externaldatasourceService.executeExternalDataSource(109, [
        this.commonService.getModifyArrayBody(translationToken, ['id'])
      ]));
    } else {
      promiseArray.push(this.externaldatasourceService.executeExternalDataSource(110, [
        '"<0>"'.replace('<0>', translationToken.tokenName),
        '"<0>"'.replace('<0>', translationToken.group),
        this.commonService.isNullOrUndefined(translationToken.subgroup) ? null : '"<0>"'.replace('<0>', translationToken.subgroup),
        this.commonService.isNullOrUndefined(translationToken.field) ? null : '"<0>"'.replace('<0>', translationToken.field),
        translationToken.id,
        this.commonService.isNullOrUndefined(translationToken.projectId) ? null : '<0>'.replace('<0>', translationToken.projectId)
      ]));
    }
  }

  upsertTempTranslationToken(object: any, idTarget: any, idObjectTarget: any, idObjectTargetElement: any,
    translationToken: any, source: any, projectId = this.applicationInfoService.projectID): Promise<any> {
    return new Promise((usertTempTranslationTokenResolve, usertTempTranslationTokenReject) => {
      if (this.commonService.isNullOrUndefined(translationToken)) {
        usertTempTranslationTokenResolve('no token');
        return;
      }
      if (this.commonService.isNullOrUndefined(translationToken.tempTranslations)) {
        usertTempTranslationTokenResolve('no translations to update');
        return;
      }
      console.warn('usertTempTranslationToken', object, idTarget, translationToken);
      let translationTokenDataSourceId = 130;
      if (this.commonService.isNullOrUndefined(translationToken.id)) {
        translationTokenDataSourceId = 129;
      }

      let translationArrayString = '';
      translationToken.tempTranslations.forEach((tempTranslation, index) => {
        if (!this.commonService.isNullOrUndefined(tempTranslation)) {
          translationArrayString = translationArrayString.concat('{ languageId:', index, ', translation: "', tempTranslation,  '" }');
        }
      });
      if (this.commonService.isNullOrUndefined(translationToken.group)) {
        translationToken.group = source;
        translationToken.subgroup = 'CreatedAutomatically';
        translationToken.field = this.commonService.createGuid();
        translationToken.tokenName = this.commonService.createGuid();
      }
      this.loaderService.display(true, false, 'upsertTempTranslationToken');
      this.externaldatasourceService.executeExternalDataSource(translationTokenDataSourceId,
        [
          translationToken.tokenName,
          translationToken.group,
          translationToken.subgroup,
          translationToken.field,
          translationArrayString,
          translationToken.id,
          projectId
      ])
      .then(result => {
        this.axivasTranslateService.updateTranslationCache();
        this.loaderService.display(false, false, 'upsertTempTranslationToken');
        this.commonService.addToObject(object, idTarget);
        this.objectDeserializeService.transform(object, idTarget).id = result.id;
        if (this.commonService.isNullOrUndefined(idObjectTarget)) {
          object[idObjectTargetElement] = result.id;
        } else {
          this.objectDeserializeService.transform(object, idObjectTarget)[idObjectTargetElement] = result.id;
        }
        usertTempTranslationTokenResolve(result);
      })
      .catch(error => {
        this.loaderService.display(false, false, 'upsertTempTranslationToken');
        usertTempTranslationTokenReject(error);
      });
    });
  }

  addRequiredTranslationPromisesToPromiseArray(promiseArray: any, object: any, idTarget: any,
      idObjectTarget: any, idObjectTargetElement: any, translationToken, source: any, projectId = this.applicationInfoService.projectID) {
    promiseArray.push(this.upsertTempTranslationToken(object, idTarget, idObjectTarget, idObjectTargetElement, translationToken, source, projectId));
  }

  createDynamicTranslationToken(translationToken: any = null, group = '', subgroup = '') {
    if (this.commonService.isNullOrUndefined(translationToken)) {
      translationToken = [];
    }
    translationToken.group = (group === '' ? 'Dynamic' : group);
    translationToken.subgroup = (subgroup === '' ? this.commonService.createGuid() : subgroup);
    translationToken.tokenName = this.commonService.createGuid();
    return translationToken;
  }

  checkIfTranslationIsAvailableInToken(translationToken: any): boolean {
    let resultValue = false;
    if (this.commonService.isNullOrUndefined(translationToken)) {
      return false;
    }
    if (!this.commonService.isNullOrUndefined(translationToken.id) || !this.commonService.isNullOrUndefined(translationToken.tempTranslations)) {
      resultValue = true;
    }
    return resultValue;
  }
}
