<span id="designSpan"></span>
<div [style.height.px]="uiService.getDesignSpanPosition('designSpan', 40)">
    <div class="awsIdentities__wrapper">
        <div class="projectSettings__header">
            <div class="projectSettings__header__interaction">
                <div class="projectSettings__header__filter">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                    <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
                </div>
            </div>
        </div>
        <div class="autoOverflowY">
            <div *ngFor="let awsIdentity of awsIdentities" class="everleadShadowBox fingerCursor" [ngClass]="{ 
                everleadCoral: commonService.checkIfItemIsInArray(this.activeAWSIdentities, awsIdentity.identity),
                hideInDom: isFiltered(awsIdentity)
            }" (click)="toggleAWSIdentity(awsIdentity)">
                {{awsIdentity.identity}}
            </div>        
        </div>
    </div>
</div>
