import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-microcampaignbatchstarter',
  templateUrl: './microcampaignbatchstarter.component.html',
  styleUrls: ['./microcampaignbatchstarter.component.scss']
})
export class MicrocampaignbatchstarterComponent implements OnInit {
  microCampaignBatch = null;
  bookingResult = null;
  campaigns = [];
  selectedCampaign = null;
  users = [];
  mode = 1;
  selectedUser = null;  
  dontReplaceTokens = true;
  bookingDate = null;
  campaignName = '';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public axivasTranslateService: AxivasTranslateService,
    private methodService: MethodService,
    public commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private messagingService: MessagingService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.microCampaignBatch = this.applicationInfoService.miscSettings['microCampaignBatch'];
    this.applicationInfoService.miscSettings['microCampaignBatch'] = null;
    this.bookingResult = this.applicationInfoService.results.toArray().find(result => result.id == this.microCampaignBatch.microCampaign.id);
    this.bookingDate = new Date(Date.now());
    
    // Users
    this.users = this.applicationInfoService.projectUsers.toArray();
    this.selectedUser = this.applicationInfoService.user.id;

    // Campaigns 
    this.campaigns = this.applicationInfoService.campaigns.toArray().filter(campaign => campaign.isActive == true);
    if (this.applicationInfoService.campaingnModeId != null) {
      this.selectedCampaign = this.applicationInfoService.campaingnModeId;
    } else {
      if (this.campaigns.length > 0) {
        this.selectedCampaign = this.campaigns[0].id;
      }      
    }
    this.changeMode(1);
    this.campaignName = 'MicroCampaign: '.concat(
      this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(this.bookingResult))
    );    
  }

  startMicroCampaignBatch() {
    if (this.microCampaignBatch != null) {
      // MicroCampaign batch creation
      
      let microCampaignBatchText = this.axivasTranslateService.getTranslationTextForToken('Task.Message.MailCampaignBatch');
      microCampaignBatchText = microCampaignBatchText.replace('<0>', 
        this.axivasTranslateService.getTranslationTextForObject(this.bookingResult, 'nameTranslationToken')
      );
      microCampaignBatchText = microCampaignBatchText.replace('<1>', this.microCampaignBatch.controlData.totalCount);
      this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, ['MicroCampaign', microCampaignBatchText]).then(() => {
        const bookingObject = {
          resultId: this.bookingResult.id,
          campaignId: this.selectedCampaign,
          stepId: this.bookingResult.stepId,
          followUpUserId: this.selectedUser,
          followUpDate: this.bookingDate,
          poolId: this.applicationInfoService.poolId
        }
        this.loaderService.display(true);
        this.createEventBookingBatch(bookingObject).then(createEventBookingBatchResult => {
          this.prepareEntityTableDataForBookingBatch(createEventBookingBatchResult).then(prepareEntityTableDataForBookingBatchResult => {
            this.externaldatasourceService.executeExternalDataSource(851, [createEventBookingBatchResult.id]).then(() => {
              this.messagingService.showDefaultSuccess(
                'MicroCampaignBatchStarter.Message.CampaignStartedHeader', 
                'MicroCampaignBatchStarter.Message.CampaignStarted'
              )
              this.eventService.closeJjPopup();
              this.loaderService.display(false);
            }).catch(() => { this.loaderService.display(false); });
          }).catch(() => { this.loaderService.display(false); });
        }).catch(() => { this.loaderService.display(false); });
      });
      return;        
    }
  }

  changeMode(mode) {
    switch(mode) {
      case 1:
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: '1300px;750px' });
        break;
      case 2:
        this.eventService.customEvent.emit({ id: 'resizeJJPopup', value: '80%;80%' });
        break;
    }
    this.mode = mode;
  }

  createEventBookingBatch(bookingObject): Promise<any> {
    return new Promise((createEventBookingBatchResolve, createEventBookingBatchReject) => {
      let wizardValues = JSON.stringify(bookingObject);
      wizardValues = wizardValues.replace(/"/g, '\\"');
      this.externaldatasourceService.executeExternalDataSource(849, [
        'MicroCampaign: '.concat(
          this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(this.bookingResult))),
        7422,  
        wizardValues      
      ]).then(createEventBookingBatchResult => {        
        createEventBookingBatchResolve(createEventBookingBatchResult);
      }).catch(error => {
        createEventBookingBatchReject(error);
      });      
    });
  }

  prepareEntityTableDataForBookingBatch(createEventBookingBatchResult): Promise<any> {
    return new Promise((prepareEntityTableDataForBookingBatchResolve, prepareEntityTableDataForBookingBatchReject) => {
      this.externaldatasourceService.executeExternalDataSource(850, [
        this.microCampaignBatch.dataTableItem.id,
        this.microCampaignBatch.dataTableItem.filterId,
        this.microCampaignBatch.columnFilter,
        createEventBookingBatchResult.id,
        this.microCampaignBatch.consentData.consentTypes,
        this.microCampaignBatch.consentData.mediumTypes,
        this.microCampaignBatch.consentData.lastXDays
      ]).then(prepareEntityTableDataForBookingBatchResult => {
        prepareEntityTableDataForBookingBatchResolve(prepareEntityTableDataForBookingBatchResult);
      }).catch(error => {
        prepareEntityTableDataForBookingBatchReject(error);
      });      
    });
  }
}
