import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DomSanitizer } from "@angular/platform-browser";
import { DashboardcoreService } from "../../dashboards/dashboardcore.service";
import { ApplicationInfoService } from "app/core/application/application-info.service";
import { EventService } from "app/jollyjupiter/service/event.service";
import { Subscription } from "rxjs";
import { FollowuplistComponent } from "./followuplist/followuplist.component";
import { TrustedAdvisor } from "aws-sdk";
import { followUpdata } from "./fake data/followUpdata";
import { AxivasTranslateService } from "app/shared/translation/axivas-translate.service";

@Component({
  selector: "app-followups",
  templateUrl: "./followups.component.html",
  styleUrls: ["./followups.component.scss"],
})
export class FollowupsComponent implements OnInit , OnDestroy{

  innerHTMLContent = ` <jj-container controluiid="24914"> </jj-container>`;
  innerHTML = null;
  searchValue: string = "";
  searchResult: string = "";
  customEventSubscription: Subscription = new Subscription();


  currentCampaign;
  searchAlert = false;


  constructor(
    private domSanitizer: DomSanitizer,
    private dashBoardCoreService: DashboardcoreService,
    private applicationInfoService: ApplicationInfoService,
    private eventService : EventService,
    private axivasTranslateService: AxivasTranslateService
  ) {}


  // * API ARRAYS
  getfollowupData;
  general=[];
  personal=[];
  overall=[];


  loading: boolean = false
  noDataAvailable = false;
  noOverallDataAvailable = false;
  noPersonalDataAvailable = false;
  

  // * CHART GLOBAL
  colors

  // * CHART PERSONAL
  personalPieChartData = {series: [], color: []}
  personalTodayTaskChart:[]=[];
  personalTaskChart=[];

  // * CHART OVERALL
  overAllPieChartData = { series: [], color: [] };
  overAllTodayTaskChart:[]=[]
  overAllTaskChart=[];

  // * tabs
  initialDashboardTabs =[{ id:0, name: 'FollowUp.Label.General'},{ id:1, name: 'FollowUp.Label.Personal'}];
  dashboardTabs = this.initialDashboardTabs
  selectedTab: number = 0;

 // * SELECT INPUTS
 campaigns: any[] = [];
 campaignsPool:any[]=[];
  selectedCampaign;
  selectedPool;

  //* DUMMY DATA
  fakeData = followUpdata
  userId= 2011

  ngOnInit() {
    // this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      
       if(event.id === "selectedPoolChanged"){
        this.getCampaigns();
        this.getCampaignPool();
       }

       if(event == 'getCompleteProjectInformaton'){
        //console.log('CALLED',event.id)
        this.getCampaigns();
        this.getCampaignPool();
        this.getData();

       }

       if(event.id == 'pageContentChanged'){
        //console.log('CALLED', event.id)
        this.getCampaigns();
        this.getCampaignPool();
        this.getData();
       }
    })

    this.getCampaigns();
    this.getCampaignPool();
    this.getData();

  
  }




  getCampaignPool(){
    this.campaignsPool = this.applicationInfoService.pools.toArray()
    if(this.applicationInfoService.poolId){
      this.selectedPool = this.applicationInfoService.poolId
    } else {
      this.selectedPool = this.campaignsPool[0]?.id
    }


  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray();
    if(this.applicationInfoService.campaingnModeId){
      this.selectedCampaign = this.applicationInfoService.campaingnModeId
    } else {
      this.selectedCampaign = this.campaigns[0]?.id
    }
  }



  getData(){
    this.loading = true;
    this.noDataAvailable = true
    const params = []
    params.push({key:'_campaign_id', value: this.selectedCampaign })
    params.push({key: '_pool_id', value: this.selectedPool })

    this.dashBoardCoreService.getDashboardData(8, params).then((getDashboard) => {
      this.formattApiData(getDashboard[0][0]);
      this.loading = false

    }).catch(() => {
     this.loading =  false
    });
  }

  formattApiData(data) {
    this.noDataAvailable = true;
    this.getfollowupData = JSON.parse(
      JSON.stringify(data?.get_aggregated_follow_ups)
    );

    if(this.getfollowupData){
      this.noDataAvailable = false
      this.loading = false;
      this.dashboardTabs = this.initialDashboardTabs
    }

    this.overall = this.getfollowupData?.overall ? this.getfollowupData?.overall : [];
    this.general = this.getfollowupData?.general ?  this.getfollowupData?.general : [];
    this.personal = this.getfollowupData?.personal ? this.getfollowupData?.personal : [];
    
    // ! testin purposes
    //this.general = [...this.general, ...this.fakeData]
    //this.personal = this.personal.filter((item) => item.follow_up_user_id === this.userId);
    
    this.personal = this.personal.filter((item) => item.follow_up_user_id === this.applicationInfoService.userID);
              
      if(this.general === null && this.personal.length === 1){
        this.dashboardTabs = [...this.dashboardTabs].filter((item) => item.id != 0)
        this.createDataChartsForPersonal();

      } else if(this.general.length > 0 && this.personal.length !== 1){
        this.dashboardTabs = [...this.dashboardTabs].filter((item) => item.id == 0)
        this.createDataChartsForOverall();

      } else if(this.general.length > 0 && this.personal.length === 1 ){
        this.dashboardTabs = this.initialDashboardTabs
        this.createDataChartsForOverall();
        this.createDataChartsForPersonal();
      } else {
        this.noDataAvailable = true
      }
  
}

  createDataChartsForPersonal(){
    this.personalPieChartData={
      series:this.createDataForPieChart(this.personal),
      color:["#ed5c73", "#ffc300", "#2955f"],
    }

    this.personalTaskChart = this.createDataForTasksChart(this.personal);
    this.personalTodayTaskChart =  this.createDataForTodayTaskChart(this.personal)
    this.colors=["#ed5c73", "#ffc300"];
  }


  createDataChartsForOverall() {
    this.overAllPieChartData={
      series:this.createDataForPieChart(this.overall),
      color:["#ed5c73", "#ffc300", "#2955f"],
    }

    this.overAllTaskChart = this.createDataForTasksChart(this.overall);
    this.overAllTodayTaskChart= this.createDataForTodayTaskChart(this.overall);
    this.colors=["#ed5c73", "#ffc300"];
  }

  setSelectedCampaignValue( $event, campaignId){
   this.getData()
   this.eventService.customEvent.emit({
    id: 'followUpSelectedCampaignValueChanged',
    value: campaignId
   })
  }

  setSelectedPoolValue(poolId){
    this.getData()
    this.eventService.customEvent.emit({
      id:'followUpSelectedPoolValueChanged',
      value: poolId
    })
  }

  setCurrentTab(tab){
   this.selectedTab = tab.id
  }

  createDataForPieChart(data) {
    const extractedKeys = data.map((item: any) => {
      const { overdue, current, total_today, ...rest } = item;
      return { overdue, total_today, current };
    });

    const result = extractedKeys
      .map((item) => {
        return Object.entries(item).map(([key, value]) => ({
          displayName: this.getChartLabelForTranslation(key),
          value: +value,
        }));
      })
      .flat();

    return result;
  }

  createDataForTasksChart(data) {
    const extractedKeys = data.map((item: any) => {
      const {
        follow_up_user_id,
        user_name,
        campaign_name,
        pool_name,
        overdue,
        overdue_today,
        current,
        total_today,
        ...rest
      } = item;
      return { ...rest };
    });

    const result = extractedKeys
      .map((item) => {
        return Object.entries(item).map(([key, value]) => ({
          name: this.getChartLabelForTranslation(key),
          value: +value,
        }));
      })
      .flat();

    return result;

  }

  createDataForTodayTaskChart(data){
    const extractedKeys = data.map((item: any) => {
      const {
        overdue_today,
        total_today,
        ...rest
      } = item;
      return { overdue_today, total_today };
    });

    const result = extractedKeys
    .map((item) => {
      return Object.entries(item).map(([key, value]) => ({
        name: this.getChartLabelForTranslation(key),
        value: +value,
      }));
    })
    .flat();

  return result;
  }

  getChartLabelForTranslation(key, map?:string){
    if(key.includes('ft')){
      let cutName = key.split('ft')
      return `${this.axivasTranslateService.getTranslationTextForToken('FollowUp.Label.Day')} ${cutName[1]}`
    } else {
      switch (key) {
        case "overdue":
          return this.axivasTranslateService.getTranslationTextForToken("FollowUp.Label.Overdue");
        case "total_today":
          return this.axivasTranslateService.getTranslationTextForToken("FollowUp.Label.TotalToday");
        case "current":
          return this.axivasTranslateService.getTranslationTextForToken("FollowUp.Label.Current");
        case "future":
          return this.axivasTranslateService.getTranslationTextForToken("FollowUp.Label.Future");
        case "overdue_today":
          return this.axivasTranslateService.getTranslationTextForToken("FollowUp.Label.OverdueToday");
        default:
          return key;
      }
  }

  }

  ngOnDestroy(): void {
    if(this.customEventSubscription){
      this.customEventSubscription.unsubscribe()
    }
  }


}
