<div class="opportunityWrapper">    
    <div class="opportunityContent" *ngIf="createNew">        
        <div class="opportunityContentHead">
            {{ 'Task.Label.OpportunityDescription' | texttransform }}
        </div>
        <div class="opportunityContentBody" [innerHTML]="innerHTML"></div>
        <div class="opportunityContentFooter">
            <button class="everleadCustomButton" [disabled]="!canUseOpportunity()" (click)="applyOpportunity()">
                {{ 'Task.Label.AssignOpportunity' | texttransform }}
            </button>
        </div>
    </div>

    <div class="opportunityContent" *ngIf="!createNew">        
        <div class="opportunityContentHead">
            {{ 'Task.Label.OpportunityDescriptionEdit' | texttransform }}
        </div>
        <div class="opportunityContentBody" [innerHTML]="innerHTMLEdit"></div>
        <div class="opportunityContentFooter">
            <button class="everleadCustomButton" [disabled]="!canUseOpportunity()" (click)="saveExistingOpportunity()">
                {{ 'Task.Label.SaveOpportunity' | texttransform }}
            </button>
        </div>
    </div>

</div>
