<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="createData()" class="fingerCursor" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon>         
        Neuer Wert
    </div>
    <div class="projectSettings__header__interaction">
    </div>
</div>

<table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
    <ng-container matColumnDef="id" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>id</th>
        <td mat-cell *matCellDef="let element">  
            <label>{{ element.id }}</label>
        </td>
    </ng-container>
    <ng-container matColumnDef="type" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>type</th>
        <td mat-cell *matCellDef="let element">  
            <input [(ngModel)]="element.type" (change)="updateData(element)">
        </td>
    </ng-container>
    <ng-container matColumnDef="subUrl" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>subUrl</th>
        <td mat-cell *matCellDef="let element">  
            <input [(ngModel)]="element.subUrl" (change)="updateData(element)">
        </td>
    </ng-container>
    <ng-container matColumnDef="additionalCriteria" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>additionalCriteria</th>
        <td mat-cell *matCellDef="let element">  
            <input [(ngModel)]="element.additionalCriteria" (change)="updateData(element)">
        </td>
    </ng-container>

    <ng-container matColumnDef="eventTypeId" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>eventTypeId</th>
        <td mat-cell *matCellDef="let element" class="scoringParameter__floatValue">  
            <input [(ngModel)]="element.eventTypeId" (change)="updateData(element)" type="number">
        </td>
    </ng-container>
    <ng-container matColumnDef="points" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef>points</th>
        <td mat-cell *matCellDef="let element" class="scoringParameter__floatValue">  
            <input [(ngModel)]="element.points" (change)="updateData(element)" type="number">
        </td>
    </ng-container>

    <ng-container matColumnDef="delete" [sticky]="!applicationInfoService.isMobile()">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="scoringParameter__delete">  
            <i class="fas fa-trash fingerCursor" (click)="deleteData(element)"></i>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
