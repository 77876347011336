import { Component, OnInit , Input } from '@angular/core';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-custom-diagram',
  templateUrl: './custom-diagram.component.html',
  styleUrls: ['./custom-diagram.component.scss']
})
export class CustomDiagramComponent implements OnInit {

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData = [];
  @Input() title = "";
  @Input() width = "100%";
  @Input() height = "100%";
  @Input() chartId = "";
  @Input() labels = [""];
  @Input() datasets = {};
  @Input() options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 100,
              stepSize: 10
            }
      }]
    },
    animation: {
      duration: 0
    }
   }

   stylesObj = {
     width: "100px", height: "100px"
   };

  ngOnInit(): void {
    this.stylesObj = {
      width: this.width + "px" , height: this.height + "px"
    }
  }

  ngAfterViewInit (){

   this.barChartData = [
     this.datasets
   ]
  }

}
