<span *ngIf="checkIfControlIsSmall()"></span>
<div [ngClass]="{ matTableDark: isDarkmode }">
    <div class="row" *ngIf="(controlDefinition.displayText != null && controlDefinition.displayText != '') || controlDefinition.nameTranslationTokenId != null">
        <div class="col-12">
            <label class="matTableHeaderLabel">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
        </div>
        <div *ngIf="descriptionText != null">
            {{ descriptionText | texttransform }}
        </div>
    </div>
    <div [id]="getMatTableId()" class="d-flex customMatTableWrapper">    
        <div class="paginationWrapper" *ngIf="showPagination" style="display: none;">
            <mat-paginator [length]="resultsLength" [pageSize]=pageSize [pageSizeOptions]="[5, 10, 20, 50, 100, 200, 500]"
                [pageIndex]=currentPage [showFirstLastButtons]="true" (page)="handlePageEvent($event)"></mat-paginator>
        </div>

        <div class="filterWrapper">
            <div>
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor filterWrapper__searchIcon"  *ngIf="showFilter" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon> 
                <input (change)="applyFilter($event)" [placeholder]="'General.Label.Search' | texttransform" #input [(ngModel)]="globalFilter"
                    class="matTableglobalFilter" *ngIf="showFilter">                 
            </div>
        </div>
        <div class="filterWrapper__paging__center" *ngIf="smallVersion==false && resultsLength > 20">        
            <select [(ngModel)]="pageSize" (change)="pageSizeChanged()" class="filterWrapper__dropdown">
                <option [ngValue]=toNumber(5)>5</option>
                <option [ngValue]=toNumber(10)>10</option>
                <option [ngValue]=toNumber(20)>20</option>
                <option [ngValue]=toNumber(50)>50</option>
                <option [ngValue]=toNumber(100)>100</option>
                <option [ngValue]=toNumber(150)>150</option>
                <option [ngValue]=toNumber(200)>200</option>
            </select>
            <span class="filterWrapper__itemsPerPage">{{ 'MatTable.Label.ItemsPerPage' | texttransform }}</span>
        </div> 
        <div class="filterWrapper__paging"  *ngIf="smallVersion==false">{{ getResultString() }}</div>
        <div class="filterWrapper__paging" *ngIf="maxPages > 1 && smallVersion==false">
            <div class="filterWrapper__paging__box" [ngClass]="{ 'filterWrapper__paging__box__active': currentPage > 1 }" 
                (click)="firstPage()">
                <i class="fas fa-step-backward"></i>
            </div>
            <div class="filterWrapper__paging__box" [ngClass]="{ 'filterWrapper__paging__box__active': currentPage > 1 }"
                (click)="previousPage()">
                <i class="fas fa-angle-left"></i>
            </div>
            <div class="filterWrapper__paging__center">
                <input #matTablePageSelector [(ngModel)]="currentPage" (click)="matTablePageSelector.select()"
                    (change)="pageSizeChangedManualy()" type="number"
                    class="filterWrapper__paging__center__pageSelect">&nbsp;/ {{ maxPages }}
            </div>
            <div class="filterWrapper__paging__box" (click)="nextPage()" 
                [ngClass]="{ 'filterWrapper__paging__box__active': currentPage != maxPages  }">
                <i class="fas fa-angle-right"></i>
            </div>
            <div class="filterWrapper__paging__box" (click)="lastPage()"
                [ngClass]="{ 'filterWrapper__paging__box__active': currentPage != maxPages }">
                <i class="fas fa-step-forward"></i>
            </div>
        </div>
        <div class="filterWrapper__paging__center">
            <i *ngIf="createDataSource !== null" (click)="createRow()" class="fas fa-plus fa-lg fingerCursor matTableButtonColor"
                style="margin: 0 10px 0 0;"></i>
            <i class="fas fa-sync-alt fa-lg fingerCursor matTableButtonColor" style="width: 25px; margin-left: 10px" (click)="getData()"></i>
            <i class="fas fa-file-excel fa-lg fingerCursor matTableButtonColor" (click)="exportData()" *ngIf="canExportData && lastResult.length > 0"></i>
            <i class="fas fa-edit fa-lg fingerCursor matTableButtonColor" (click)="editContent()" *ngIf="canEditContent"></i>
        </div>
    </div>


    <span [id]=controlDefinition.id></span>
    <span id="getMaxHeightSpan"></span>
    <div class="matTableWrapper" [style.max-height.px]="getMaxHeight()"
        [ngClass]="{ hideInDom: loadingControlData == true }">
        <mat-table #table [dataSource]="dataSource" style="width: 100%" [style.min-width.px]="controlDefinition.minWidth">
            <span [matColumnDef]="displayedColumn"
                *ngFor="let displayedColumn of displayedColumns; let colIndex = index">            
                <mat-header-cell *matHeaderCellDef 
                    [ngStyle]="{'width.px': controlDefinition.childs[colIndex].width}"
                    [ngClass]="{ hideInDom: checkIfItemIsVisible(element, controlDefinition.childs[colIndex], controlDefinition.childs[colIndex]) == false }"
                >
                <div *ngIf="controlDefinition.childs[colIndex]?.value == 'selectall'" (click)="$event.stopPropagation()" class="selectAll">
                    <input type="checkbox" [(ngModel)]="allFilesSelected" (change)="selectAll()" (click)="$event.stopPropagation()">
                </div>

                <div class="matTableWrapper__sortDiv">
                    <div class="matTableWrapper__sortDiv__icon fingerCursor" *ngIf="controlDefinition.childs[colIndex]?.isFieldFilterable" (click)="sortDataByColIndex(colIndex)">
                        <i class="fas fa-arrow-down matTableWrapper__sortDiv__hover" *ngIf="selectedSortCol != colIndex + 'ASC' && selectedSortCol != colIndex + 'DESC'"></i>
                        <i class="fas fingerCursor" [ngClass]="{
                            'fa-arrow-up': selectedSortCol == colIndex + 'ASC',
                            'fa-arrow-down': selectedSortCol == colIndex + 'DESC'
                        }"></i>
                    </div>                
                    <ng-container *ngIf="controlDefinition.childs[colIndex]?.isFieldFilterable">
                        <input [type]="inputType" matInput [placeholder]="getHeader(colIndex)" [(ngModel)]="filterArray[colIndex]" (change)="filterColumn(colIndex)" class="matTable__filterInput">                    
                    </ng-container>
                    <ng-container *ngIf="!controlDefinition.childs[colIndex]?.isFieldFilterable">
                        <label class="matTable__label">{{ getHeader(colIndex) }}</label>
                    </ng-container>
                </div>

                </mat-header-cell>
                <mat-cell [ngStyle]="getMobileStyle(controlDefinition.childs[colIndex]?.mobileRow)" [class]="controlDefinition.childs[colIndex]?.additionalCssClasses"
                [attr.data-column-name]="getHeader(colIndex, true)" *matCellDef="let element;" [ngClass]="{ 
                    'fingerCursor': controlDefinition.methods.length > 0,
                    hideInDom: checkIfItemIsVisible(element, controlDefinition.childs[colIndex], controlDefinition.childs[colIndex]) == false 
                }">
                    <div *ngFor="let subChild of controlDefinition.childs[colIndex]?.childs" class="matTableCellItem" [ngClass]="{ 
                        'fingerCursor': controlDefinition.methods.length > 0,
                        hideInDom: checkIfItemIsVisible(element, subChild, controlDefinition.childs[colIndex]) == false
                    }">
                        <div *ngIf="subChild.controlType == 0 || subChild.controlType == null" class="ellipsisText">
                            <mat-label><b>{{ subChild.displayText | texttransform }}</b>
                            </mat-label>
                            <span [tippy]="getSubChildValue(element, subChild)">
                                {{ getSubChildValue(element, subChild, false) }}
                            </span>
                        </div>
                        <div *ngIf="subChild.controlType == 1" style="margin: 5px 0;" class="matTableImage">
                            <img [src]="getImage(element, subChild)" class="matTable__image">
                        </div>
                        <div *ngIf="subChild.controlType == 2">
                            <app-custom-thermometer [value]="element[subChild.value]"></app-custom-thermometer>
                        </div>
                        <div *ngIf="subChild.controlType == 3">
                            <ul *ngIf="element[subChild.value] != ''">
                                <li *ngFor="let item of commonService.splitRows(element[subChild.value])" [tippy]="item">{{
                                    getShortText(item, subChild, false) }}</li>
                            </ul>
                        </div>
                        <div *ngIf="subChild.controlType == 4">
                            <svg-icon src="assets/images/svg/google.svg" class="matTableButton__icon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                (click)="commonService.openGoogle(element[subChild.value]);$event.stopPropagation()">
                            </svg-icon>
                            <svg-icon src="assets/images/svg/xing.svg" class="matTableButton__icon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                (click)="commonService.openXing(element[subChild.value]);$event.stopPropagation()">
                            </svg-icon>
                        </div>
                        <div *ngIf="subChild.controlType == 5">
                            <mat-label><b>{{ subChild.displayText | texttransform }}</b></mat-label>
                            <span> {{ getSubChildValue(element, subChild) }}</span>
                        </div>
                        <div *ngIf="subChild.controlType == 6">
                            <app-custom-leadscore [controlValue]="getSubChildValue(element, subChild)">
                            </app-custom-leadscore>
                        </div>
                        <div *ngIf="subChild.controlType == 7">
                            <input type="checkbox" [(ngModel)]="element[subChild.value]" (change)="saveRow(element)"
                                [disabled]="checkIfIsEnabled(subChild)" (click)="$event.stopPropagation()">
                        </div>
                        <div *ngIf="subChild.controlType == 8">
                            <input matInput type="text" [(ngModel)]="element[subChild.value]" (change)="saveRow(element)"
                                [tippy]=element[subChild.value] [disabled]="checkIfIsEnabled(subChild)" (click)="$event.stopPropagation()"
                                class="matTableInput" style="width: 95%">
                        </div>
                        <div *ngIf="subChild.controlType == 9">
                            <select type="text" [(ngModel)]="element[subChild.value]" (change)="saveRow(element)"
                                style="width: 95%" [disabled]="checkIfIsEnabled(subChild)"
                                (click)="$event.stopPropagation()">
                                <option [ngValue]="null"></option>
                                <option [ngValue]="item.id" *ngFor="let item of additionalDataSourcesArray[subChild.id]">
                                    <span
                                        *ngFor="let visibleItem of getVisibleItems(additionalDataSourcesDisplayValues[subChild.id])">
                                        {{ item[visibleItem] }}
                                    </span>
                                </option>
                            </select>
                        </div>
                        <div *ngIf="subChild.controlType == 10">
                            <input (dateTimeChange)="saveRow(element)" [(ngModel)]="element[subChild.value]"
                                [owlDateTimeTrigger]="dt7" [disabled]="checkIfIsEnabled(subChild)" [owlDateTime]="dt7"
                                readonly (click)="$event.stopPropagation()" appendTo="body" aria-modal="true">
                            <owl-date-time #dt7></owl-date-time>
                            <button class="btn" (click)="resetDate(element, subChild.value, null)"><i class="fas fa-times"></i></button>
                        </div>
                        <div *ngIf="subChild.controlType == 11">
                            <input matInput type="number" [(ngModel)]="element[subChild.value]"
                                (click)="$event.stopPropagation()" (change)="saveRow(element)"
                                [disabled]="checkIfIsEnabled(subChild)">
                        </div>
                        <div *ngIf="subChild.controlType == 12">
                            <i [class]="getIconClasses(subChild)"
                                (click)="controlClicked(subChild, element);$event.stopPropagation()"></i>
                        </div>
                        <div *ngIf="subChild.controlType == 13">
                            <i class="fas fa-trash fa-lg everleadCoral matTable__deleteIcon fingerCursor matTableButtonColor"
                                (click)="deleteRow(element);$event.stopPropagation()"></i>
                        </div>
                        <div *ngIf="subChild.controlType == 14" class="fingerCursor">
                            <mat-label style="padding-right: 5px;"><b>{{ subChild.displayText | texttransform }}</b>
                            </mat-label>
                            <span style="text-decoration: underline;"
                                (click)="commonService.openUrl(element[subChild.value]);$event.stopPropagation()">{{
                                getSubChildValue(element, subChild) }}</span>
                        </div>
                        <div *ngIf="subChild.controlType == 15" (click)="$event.stopPropagation()">
                            <input type="checkbox" [(ngModel)]="selectedRows[element.id]" (change)="selectRow(element)" [disabled]="checkIfIsEnabled(subChild)">
                        </div>

                        <div *ngIf="subChild.controlType == 16" [style.color]="getDateWarning(element, subChild)">
                            <mat-label><b>{{ subChild.displayText | texttransform }}</b></mat-label>
                            <span [tippy]="getSubChildValue(element, subChild)"> {{ getSubChildValue(element, subChild) }}</span>
                        </div>
                        <div *ngIf="subChild.controlType == 17">
                            <mat-label><b>{{ subChild.displayText | texttransform }}</b></mat-label>
                            <span [tippy]="getSubChildValue(element, subChild)"> {{ getSubChildValue(element, subChild,
                                false) }}</span>
                            <svg-icon src="assets/images/svg/google.svg" class="matTableButton__icon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                (click)="commonService.openGoogle(element[subChild.value]);$event.stopPropagation()">
                            </svg-icon>
                            <svg-icon src="assets/images/svg/xing.svg" class="matTableButton__icon"
                                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                                (click)="commonService.openXing(element[subChild.value]);$event.stopPropagation()">
                            </svg-icon>
                        </div>
                        <div *ngIf="subChild.controlType == 18">
                            <div *ngIf="getSubChildValue(element, subChild, false) == 2">
                                <svg-icon src="assets/images/svg/icon-ai_thumbs_up.svg" [tippy]="'MatTable.Label.AIHigh' | texttransform" class="matTableButton__icon" [svgStyle]="{ 'width.px': 56, 'height.px': 56 }"></svg-icon>
                            </div>
                            <div *ngIf="getSubChildValue(element, subChild, false) == 0">
                                <svg-icon src="assets/images/svg/icon-ai_thumbs_down.svg" [tippy]="'MatTable.Label.AILow' | texttransform" class="matTableButton__icon" [svgStyle]="{ 'width.px': 56, 'height.px': 56 }"></svg-icon>
                            </div>
                        </div>
                    </div>
                </mat-cell>
            </span>

            <mat-header-row  [ngStyle]="getGridTemplateStyle()" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row  [ngStyle]="getGridTemplateStyle()" *matRowDef="let row; columns: displayedColumns;" (click)="cellClickedEvent(row)"
                (dblclick)="cellDblClickedEvent(row)" [ngClass]="{ 
                    everleadShadowBox: applicationInfoService.isMobile(),
                    mobileRow: applicationInfoService.isMobile()
                }"></mat-row>
        </mat-table>    
    </div>
    <div class="matTableWrapper__bottom" *ngIf="smallVersion==true">
        <div class="matTableWrapper__bottom__paging">
            <div class="filterWrapper__paging__center" *ngIf="smallVersion==true">
                <select [(ngModel)]="pageSize" (change)="pageSizeChanged()" class="filterWrapper__dropdown">
                    <option [ngValue]=toNumber(5)>5</option>
                    <option [ngValue]=toNumber(10)>10</option>
                    <option [ngValue]=toNumber(20)>20</option>
                    <option [ngValue]=toNumber(50)>50</option>
                    <option [ngValue]=toNumber(100)>100</option>
                    <option [ngValue]=toNumber(150)>150</option>
                    <option [ngValue]=toNumber(200)>200</option>
                </select>
                <span class="filterWrapper__itemsPerPage">{{ 'MatTable.Label.ItemsPerPage' | texttransform }}</span>
            </div>
        </div>    

        <div class="filterWrapper__paging" >
            <div class="filterWrapper__paging__box" 
                [ngClass]="{ 'filterWrapper__paging__box__active': currentPage > 0 }" (click)="firstPage()">
                <i class="fas fa-step-backward"></i>
            </div>
            <div class="filterWrapper__paging__box"
                [ngClass]="{ 'filterWrapper__paging__box__active': currentPage > 0 }" (click)="previousPage()">
                <i class="fas fa-angle-left"></i>
            </div>
            <div class="filterWrapper__paging__center">
                <input #matTablePageSelector [(ngModel)]="currentPage" (click)="matTablePageSelector.select()"
                    (change)="pageSizeChangedManualy()" type="number"
                    class="filterWrapper__paging__center__pageSelect">&nbsp;/ {{ maxPages }} 
            </div>
            <div class="filterWrapper__paging__box" (click)="nextPage()"
                [ngClass]="{ 'filterWrapper__paging__box__active': currentPage != maxPages  }">
                <i class="fas fa-angle-right"></i>
            </div>
            <div class="filterWrapper__paging__box" (click)="lastPage()"
                [ngClass]="{ 'filterWrapper__paging__box__active': currentPage != maxPages  }" >
                <i class="fas fa-step-forward"></i>
            </div>
        </div>
        <div>{{ getResultString() }}</div>
        
    </div>
    <div [ngClass]="{ hideInDom: loadingControlData == false }" class="matTableLoadingData">
        <i class="fas fa-spinner fa-spin " style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
</div>