<div *ngIf="middlewareSignalrService?.connectionStatus == null && applicationInfoService.showReconnectOption == false">
    <label class="webPhoneCallLabel">{{ 'Webphone.Connection.Connecting' | texttransform }}</label>
</div>


<div *ngIf="middlewareSignalrService?.connectionStatus != null && applicationInfoService.showReconnectOption == false">
    <!-- Make call, answer call & hangup -->
    <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneStatusConnected" 
      *ngIf="middlewareSignalrService?.connectionStatus.canDial"
      title="{{ 'Webphone.Tooltip.Dial' | texttransform }}"
      (click)="methodService.launchInternalMethod('showsoftphonepopup', [1])"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-phone fa-rotate-90"></i></button>
    
    <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneStatusConnected" 
    *ngIf="middlewareSignalrService?.connectionStatus.canAnswer"
    title="{{ 'Webphone.Tooltip.Answer' | texttransform }}"
    (click)="softphoneEvent('answer', 'canAnswer')"
    [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
      webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-phone-volume"></i></button>


    <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneDisconnectColor" 
      *ngIf="middlewareSignalrService?.connectionStatus.canDisconnect"
      title="{{ 'Webphone.Tooltip.Hangup' | texttransform }}"
      (click)="softphoneEvent('hangup', 'canDisconnect')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
      ><i class="fas fa-phone-slash"></i></button>
      
  

    <!-- Hold/Unhold -->
    <button class="webPhoneStatusIcon webPhoneDialerButton" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canHold"
      title="{{ 'Webphone.Tooltip.Hold' | texttransform }}"
      (click)="softphoneEvent('hold', 'canHold')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-pause"></i></button>
   
  
 
    <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneShowActive" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canResume"
      title="{{ 'Webphone.Tooltip.Resume' | texttransform }}"
      (click)="softphoneEvent('resume', 'canResume')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-pause"></i></button>


    <!-- Recording -->
    <button class="webPhoneStatusIcon webPhoneDialerButton" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canRecord"
      title="{{ 'Webphone.Tooltip.Record' | texttransform }}"
      (click)="softphoneEvent('startrecord', 'canRecord')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-circle"></i></button>

    <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneShowActive" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canStopRecord"
      title="{{ 'Webphone.Tooltip.StopRecord' | texttransform }}"
      (click)="softphoneEvent('stoprecord', 'canStopRecord')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-circle"></i></button>


    <button class="webPhoneStatusIcon webPhoneDialerButton" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canStopRecord"
      title="{{ 'Webphone.Tooltip.DiscardRecord' | texttransform }}"
      (click)="softphoneEvent('discardrecord', 'canStopRecord')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-trash"></i></button>

    <!-- Consult -->
    <button class="webPhoneStatusIcon webPhoneDialerButton" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canInitConsult"
      title="{{ 'Webphone.Tooltip.Consult' | texttransform }}"
      (click)="softphoneEvent('consult', 'canInitConsult')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-user-plus"></i></button>    


    <!-- Transfer -->
    <button class="webPhoneStatusIcon webPhoneDialerButton" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canTransferCall"
      title="{{ 'Webphone.Tooltip.Transfer' | texttransform }}"
      (click)="middlewareSignalrService.transfer()"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-user-plus"></i></button>        


    <!-- Conference -->
    <button class="webPhoneStatusIcon webPhoneDialerButton" 
      *ngIf="middlewareSignalrService?.connectionStatus?.canInitConference"
      title="{{ 'Webphone.Tooltip.Conference' | texttransform }}"
      (click)="softphoneEvent('conference', 'canInitConference')"
      [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
        webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
    ><i class="fas fa-users"></i></button>         

    <span *ngIf="middlewareSignalrService.isActiveCall()" class="floatRight">
      <!-- Misc buttons -->
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('1')">1</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('2')">2</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('3')">3</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('4')">4</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('5')">5</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('6')">6</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('7')">7</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('8')">8</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('9')">9</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('0')">0</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('#')">#</button>
      <button class="webPhoneStatusIcon webPhoneDialerButton webPhoneActiveCallButtonDimension" (click)="middlewareSignalrService.sendKey('*')">*</button>
    </span>

    <span>

      <!-- Volume, headphone & stuff -->
      <i class="webPhoneHeaderDivider"></i>
      <!-- Mute -->
      <button class="webPhoneStatusIcon webPhoneDialerButton" 
        *ngIf="middlewareSignalrService?.connectionStatus?.canMute || middlewareSignalrService?.connectionStatus?.isMute"
        title="{{ 'Webphone.Tooltip.Mute' | texttransform }}"
        (click)="middlewareSignalrService.mute()"
        [ngClass]="{ 
          webPhoneShowActive: middlewareSignalrService?.connectionStatus?.isMute,
          webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
          webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()
        }"        
      ><i class="fas fa-microphone-slash"></i></button>


      <button class="webPhoneStatusIcon webPhoneDialerButton" 
        *ngIf="middlewareSignalrService?.connectionStatus?.canVolumeUp"
        title="{{ 'Webphone.Tooltip.VolumeUp' | texttransform }}"
        (click)="middlewareSignalrService.volumeUp()"
        [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
          webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
      ><i class="fas fa-volume-up"></i></button>


      <button class="webPhoneStatusIcon webPhoneDialerButton" 
        *ngIf="middlewareSignalrService?.connectionStatus?.canVolumeDown"
        title="{{ 'Webphone.Tooltip.VolumeDown' | texttransform }}"
        (click)="middlewareSignalrService.volumeDown()"
        [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
          webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
      ><i class="fas fa-volume-down"></i></button>

      <button class="webPhoneStatusIcon webPhoneDialerButton" 
        *ngIf="middlewareSignalrService?.connectionStatus?.canVolumeDown"
        title="{{ 'Webphone.Tooltip.Headphone' | texttransform }}"
        (click)="softphoneEvent('toggleheadset', 'canToggleHeadset')"
        [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
          webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
      ><i class="fas fa-headphones"></i></button>

      <button class="webPhoneStatusIcon webPhoneDialerButton" 
        *ngIf="middlewareSignalrService?.connectionStatus?.canToggleSpeaker"
        title="{{ 'Webphone.Tooltip.Speaker' | texttransform }}"
        (click)="softphoneEvent('togglespeaker', 'canToggleSpeaker')"
        [ngClass]="{ webPhoneActiveCallButtonDimension: middlewareSignalrService.isActiveCall(),
          webPhoneInactiveCallButtonDimension: !middlewareSignalrService.isActiveCall()}"        
      ><i class="fas fa-bullhorn"></i></button>

    </span>

  

</div>

<div style="height: 20px;" *ngIf="middlewareSignalrService?.connectionStatus?.connectionStatus != 'Disconnected' && middlewareSignalrService?.connectionStatus?.connectionStatus != null">
  
  <!-- The current call -->
  <label class="webPhoneCallLabel floatRight">
    <i class="webPhoneHeaderDivider webPhoneStatusIcon"></i>
    <i class="fas fa-phone fa-rotate-90 webPhoneStatusIcon" 
    [ngClass]="{ 
    'webPhoneStatusRinging': middlewareSignalrService?.connectionStatus?.connectionStatus == 'Ringing'
    ,'webPhoneStatusConnected': middlewareSignalrService?.connectionStatus?.connectionStatus == 'Connected'
    ,'webPhoneStatusDisconnected': middlewareSignalrService?.connectionStatus?.connectionStatus == 'Disconnected'
    }"
    ></i>
    {{ middlewareSignalrService?.connectionStatus?.ownDisplayedNumber }} 
    <!-- <i class="fas fa-link webPhoneCallIcon"></i>  -->
    <i class="fas fa-arrow-alt-circle-left webPhoneCallIcon" *ngIf="middlewareSignalrService?.connectionStatus?.isInbound"></i>
    <i class="fas fa-arrow-alt-circle-right webPhoneCallIcon" *ngIf="middlewareSignalrService?.connectionStatus?.isOutbound"></i>
    <span *ngIf="middlewareSignalrService?.connectionStatus?.otherPartyName != ''">
    {{ middlewareSignalrService?.connectionStatus?.otherPartyName }}</span>
    <span *ngIf="middlewareSignalrService?.connectionStatus?.otherPartyName == ''">
    {{ middlewareSignalrService?.connectionStatus?.otherParty }}
    </span>
  </label>
  <label class="webPhoneCallLabel floatRight" *ngIf="
      middlewareSignalrService?.connectionStatus?.connectionStatus == 'Dialing'
      || middlewareSignalrService?.connectionStatus?.connectionStatus == 'RingingOutbound'
      || middlewareSignalrService?.connectionStatus?.connectionStatus == 'Connected'">
    <i class="fas fa-clock webPhoneStatusIcon"></i> {{ connectionTime }}
  </label>  
</div>

<span *ngIf="applicationInfoService.showReconnectOption && currentlyReconnecting == false">
  <label class="webPhoneCallLabel" (click)="reinitializeConnection()">{{'Webphone.Connection.Reconnect' | texttransform}}</label>
</span>
<span *ngIf="currentlyReconnecting == true">
  <label class="webPhoneCallLabel">Reconnecting...</label>
</span>