import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { WizardService } from '../wizard.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { Subscription } from 'rxjs';
import { DatarightsService } from '../../datarights/datarights.service';

@Component({
  selector: 'app-wizard-datarightsgroup',
  templateUrl: './wizard-datarightsgroup.component.html',
  styleUrls: ['./wizard-datarightsgroup.component.scss']
})
export class WizardDatarightsgroupComponent implements OnInit, OnDestroy {
  refreshDataRightFiltersEventSubscription: Subscription = new Subscription();
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  users = [];
  filters = [];
  filterString = '';
  filterStringAssigned = '';
  filterStringFilter = '';
  filterStringAssignedFilter = '';

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    public uiService: UiService,
    private datafilterService: DatafilterService,
    private eventService: EventService,
    private datarightsService: DatarightsService
  ) { }

  ngOnInit(): void {
    this.getUsers();
    this.getAvailableFilters();

    this.refreshDataRightFiltersEventSubscription = this.datarightsService.refreshDataRightFiltersEvent.subscribe(() => {
      this.getAvailableFilters();
    });

    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(() => {
      this.getAvailableFilters();
    });
    
  }

  ngOnDestroy(): void {
    if (this.refreshDataRightFiltersEventSubscription) { this.refreshDataRightFiltersEventSubscription.unsubscribe(); }
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
  }

  getAvailableFilters() {
    this.externaldatasourceService.executeExternalDataSource(581, [4523])
    .then(getAvailableFiltersResult => { 
      this.filters = getAvailableFiltersResult;
    });
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(139, []).then(result => { this.users = result; })
  }

  isFilterAssigned(filter) {
    let returnValue = false;
    const foundFilter = this.wizardService.wizardArray.filters.find(assignedFilter => assignedFilter.filter.id == filter.id);
    if (foundFilter) {
      returnValue = true;
    }
    return returnValue;
  }

  isAssigned(user) {
    let returnValue = false;
    const foundUser = this.wizardService.wizardArray.users.find(assignedUser => assignedUser.userId == user.id);
    if (foundUser) {
      returnValue = true;
    }
    return returnValue;
  }

  toggleUser(user) {    
    const foundUser = this.wizardService.wizardArray.users.find(assignedUser => assignedUser.userId == user.id);
    if (foundUser) {
      this.removeUser(foundUser);
    } else {
      this.addUser(user);
    }
  }

  toggleFilter(filter) {
    const foundFilter = this.wizardService.wizardArray.filters.find(assignedFilter => assignedFilter.filter.id == filter.id);
    if (foundFilter) {
      this.removeFilter(foundFilter);
    } else {
      this.addFilter(filter);
    }
  }

  updateGroupInfo() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(580, [this.wizardService.wizardArray.id])
    .then(updateGroupInfoResult => {
      this.wizardService.wizardArray.users = updateGroupInfoResult.users;
      this.wizardService.wizardArray.filters = updateGroupInfoResult.filters;      
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  removeUser(assignedUser) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(577, [assignedUser.id])
    .then(() => {
      this.updateGroupInfo();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  removeFilter(assignedFilter) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(579, [assignedFilter.id])
    .then(() => {
      this.updateGroupInfo();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  addUser(user) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(576, [user.id, this.wizardService.wizardArray.id])
    .then(() => {
      this.updateGroupInfo();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  addFilter(filter) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(578, [filter.id, this.wizardService.wizardArray.id])
    .then(() => {
      this.updateGroupInfo();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  isFilterActive(checkString, filterString) {
    return !this.commonService.checkIfStringContainsString(checkString, filterString);
  }

  checkIfAlreadyAssigned(user) {    
    return this.wizardService.wizardArray.users.some(userToCheck => userToCheck.user.id == user.id); 
  }

  checkIfFilterAlreadyAssigned (filter) {
    return  this.wizardService.wizardArray.filters.some(filterToCheck => filterToCheck.filter.id == filter.id); 
  }

  editFilterPopup() {
    this.datafilterService.setFilterEnvironment(
      null,
      null,
      '',
      null,
      4523
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80', true);
  }

  calculateDataRightsForFilter(filter) {
    this.externaldatasourceService.executeExternalDataSource(604, [filter.filter.id]);
  }
}
