<div class="customTextControlWrapper">
  <label [class]="uiService.getLabelClasses(controlDefinition)"
    [ngClass]="{ customTextControlWrapper__coralColor : isRequired }"
  >
    {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
    <span *ngIf="controlDefinition.isRequired"> *</span>
  </label>
  <div class="customControlValueWrapper">
    <input *ngIf="inputType == 'text'"
      class="customTextControlWrapper__textbox"
      type="text"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="checkInputValue()"
      [tippy]="controlValue"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
    >
    <input *ngIf="inputType == 'number'"
      class="customTextControlWrapper__textbox"
      type="number"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="checkInputValue()"
      [tippy]="controlValue"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
      onlynumber
    >
  </div>
<!--   <div class="customControlValueWrapper">
    <input
      class="customTextControlWrapper__textbox"
      [type]="inputType"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="callMethod('onchange')"
      [tippy]="controlValue"
      [showTooltipTemplate]=true
      tooltipHeader="<b>Im a header!</b>"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
    >
  </div>   -->

</div>
