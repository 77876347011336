<div class="chartGraphHeaderLabel" [id]="getHeaderLabelId()" *ngIf="header != ''" [style.color]="headercolor">{{ header | texttransform }}</div>

<div *ngIf="!dataAvailable" class="chart__noData" [ngClass]="{ 'chartGraph__darkMode': darkmode }">
    {{ 'Chart.Label.NoDataAvailable' | texttransform }}
</div>

<div *ngIf="dataAvailable"
    class="echart"
    echarts
    [options]="options"
    [merge]="merge"
    [id]="controlid"
    [style.height]="getHeight()"
>
</div>
