import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-dashbdashboardcomponent-myday',
  templateUrl: './dashbdashboardcomponent-myday.component.html',
  styleUrls: ['./dashbdashboardcomponent-myday.component.scss']
})
export class DashbdashboardcomponentMydayComponent implements OnInit {
  myFollowUpsPast = [];
  myFollowUpsToday = [];
  myFollowUpsFuture = [];
  myDayContentFilter = 1;

  constructor(
    private methodService: MethodService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getMyFollowUps();
  }

  loadFollowUp(followUp) {
    this.methodService.launchInternalMethod(InternalMethodType.ShowTask, [followUp]);
  }

  getMyFollowUps() {
    this.externaldatasourceService.executeExternalDataSource(835)
    .then(getMyFollowUpsResult => {
      this.myFollowUpsToday = getMyFollowUpsResult.presentTasks.slice(0, 50);
      this.myFollowUpsPast = getMyFollowUpsResult.pastTasks.slice(0, 50);
      this.myFollowUpsFuture = getMyFollowUpsResult.futureTasks.slice(0, 50);
    });
  }

  startWorking() {
    this.methodService.launchInternalMethod(InternalMethodType.NextTask, []);
  }

  myDayContentFilterChanged() {

  }

  showMyFollowUps() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['myfollowups', 'myfollowups']);
  }
}
