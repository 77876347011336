<div class="finalizeTaskContent">

    <div [innerHTML]="taskCreationInnerHTML" [ngClass]="{ hideInDom: page != 1 }"></div>
    <br>

    <div *ngIf="page == 2">
        <app-custom-emaileditor [templateId]=emailTemplateId [taskEntityId]=bookTaskEntityId></app-custom-emaileditor>
    </div>

</div>

<div class=finalizeTaskButtons>
    <button class="jjButtonColor jjButtonStyle" (click)="bookCampaignTask()" style="margin-right: 10px"
        *ngIf="bookTask == true && page == 1 && checkIfEMailTemplateIsAvailable() == false">
        {{ 'CreateTask.Label.BookTask' | texttransform }}
    </button>

    <button class="jjButtonColor jjButtonStyle" (click)="changePage(2)" style="margin-right: 10px"
        *ngIf="bookTask == true && page == 1 && checkIfEMailTemplateIsAvailable() == true">
        {{ 'CreateTask.Label.EditEMail' | texttransform }}
    </button>

    <button class="jjButtonColor jjButtonStyle" (click)="changePage(1)" style="margin-right: 10px"
        *ngIf="bookTask == true && page == 2">
        {{ 'CreateTask.Label.Back' | texttransform }}
    </button>

    <button class="jjButtonColor jjButtonStyle" (click)="bookCampaignTask()" style="margin-right: 10px"
        *ngIf="bookTask == true && page == 2">
        {{ 'CreateTask.Label.BookTask' | texttransform }}
    </button>

    <button class="jjButtonColor jjButtonStyle" (click)="deleteTask()" style="margin-right: 10px"
        *ngIf="bookTask == true && userService.hasPermission(deleteTaskUserRightName)">
        {{ 'CreateTask.Label.DeleteTask' | texttransform }}
    </button>


    <button class="jjButtonColor jjButtonStyle" (click)="createNewTask()" style="margin-right: 10px"
        *ngIf="bookTask == false">
        <span *ngIf="newActivity == false">{{ 'CreateTask.Label.CreateTask' | texttransform }}</span>
        <span *ngIf="newActivity == true">{{ 'CreateTask.Label.CreateActivity' | texttransform }}</span>
    </button>
</div>
<!-- <button class="jjButtonColor jjButtonStyle" (click)="resetValues()" *ngIf="newActivity == false && page == 1">
    {{ 'CreateTask.Label.ResetValues' | texttransform }}
</button> -->