import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-dashboardcontrol-bestnextcontact',
  templateUrl: './custom-dashboardcontrol-bestnextcontact.component.html',
  styleUrls: ['./custom-dashboardcontrol-bestnextcontact.component.scss']
})
export class CustomDashboardcontrolBestnextcontactComponent implements OnInit {
  bestNextContacts = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private methodService: MethodService
  ) { }

  ngOnInit(): void {
    this.getBestNextContacts();
  }

  getBestNextContacts() {
    this.externaldatasourceService.executeExternalDataSource(779, [])
    .then(getBestNextContactsResult => {
      this.bestNextContacts = getBestNextContactsResult;
    });
  }

  loadContact(contact) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [contact.contactId]);
  }
}