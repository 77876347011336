<div class="createDemoAccount">
    <div class="createDemoAccountLine">
        <label class="ellipsisText">{{ 'Contact.Label.FirstName' | texttransform }}</label>
        <input [(ngModel)]="firstName">
    </div>
    <div class="createDemoAccountLine">
        <label class="ellipsisText">{{ 'Contact.Label.LastName' | texttransform }}</label>
        <input [(ngModel)]="lastName">
    </div>
    <div class="createDemoAccountLine">
        <label class="ellipsisText">{{ 'Contact.Label.EMail' | texttransform }}</label>
        <input [(ngModel)]="email" (change)="cleanEMail(email)">
    </div>
    <div class="createDemoAccountLine">
        <label class="ellipsisText">{{ 'General._.Language' | texttransform }}</label>
        <select [(ngModel)]="languageId">
            <option ngValue="1">English</option>
            <option ngValue="2">Deutsch</option>
        </select>
    </div>
    <div class="createDemoAccountButton">
        <button (click)="createDemoAccount()" class="everleadCustomButton" [disabled]="lastName.length < 3 || emailValid() == false">
            {{ 'CreateDemoAccount.Label.CreateDemo' | texttransform }}
        </button>
    </div>
</div>
