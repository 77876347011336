import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-documentation-link',
  templateUrl: './documentation-link.component.html',
  styleUrls: ['./documentation-link.component.scss']
})
export class DocumentationLinkComponent implements OnInit {
  @Input() infoLink = '';

  constructor(
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
  }

  showDocumentation() {
    this.commonService.openUrl(this.infoLink, '_new');
  }
}
