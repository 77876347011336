import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-borderitem',
  templateUrl: './banner-borderitem.component.html',
  styleUrls: ['./banner-borderitem.component.scss']
})
export class BannerBorderitemComponent implements OnInit {
  @Input() itemNumber = 0;
  constructor(
  ) { }

  ngOnInit(): void {
    
  }

}
