import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardMode, WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-eventlist',
  templateUrl: './eventlist.component.html',
  styleUrls: ['./eventlist.component.scss']
})
export class EventlistComponent implements OnInit {
  events = [];
  triggers = [];
  counter = 30;
  constructor(
    public commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public userService: UserService,
    private wizardService: WizardService
  ) { }

  ngOnInit(): void {
    this.getEvents();
    this.getTriggers();
  }

  getEvents() {
    if (!this.userService.hasPermission( 'CanReadEvent')) {
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(645).then(getEventsResult => {
      this.events = getEventsResult;
    });
  }

  getTriggers() {
    if (!this.userService.hasPermission( 'CanReadEvent')) {
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(478).then(getTriggersResult => {
      this.triggers = getTriggersResult;
    });
  }

  showTriggerWizard(event) {
    this.wizardService.showWizard(WizardMode.EventTrigger, event, '90');
  }

  eventTriggerAvailable(event) {    
    const foundTrigger = this.triggers.find(trigger => 
      trigger.eventType == event.type &&
      trigger.subUrl    == event.url
    );
    
    if (this.counter > 0) {
      this.counter --;
      console.warn(event, this.triggers, foundTrigger);
    }
    if(foundTrigger) {
      return true;
    } else {
      return false;
    }
  }
}
