import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { ProcessvisualService } from 'app/jollyjupiter/controls/static/processvisual/processvisual.service';
import { ConsentManagementComponent } from 'app/jollyjupiter/controls/static/supervisor/consent-management/consent-management.component';
import { WizardMode, WizardService } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';


@Component({
  selector: "app-flowchart",
  templateUrl: "./flowchart.component.html",
  styleUrls: ["./flowchart.component.scss"],
})
export class FlowchartComponent implements OnInit, OnDestroy {
  refreshBlocker = false;


  constructor(
    public applicationInfoService: ApplicationInfoService,
    public processvisualService: ProcessvisualService,
    private axivasTranslationService: AxivasTranslateService,
    private commonService: CommonService,
    private eventService: EventService,
    private wizardService: WizardService,
    private userService: UserService,
    private packagefeatureService: PackagefeatureService,

  ) {}

  customEventSubscription: Subscription = new Subscription();
  options = {};
  updateOptions: EChartsOption;
  chartInstance;
  openModalConfig;
  editButton = "";
  buttonType;
  modalData;
  featureId=null;
  emailTemplateFeatureIsActive:boolean;
  //campaignName;

  isSuperVisor: boolean = true;
  isEditor: boolean = true;
  starterStepId:number = 0;
  isStarterStep:boolean = false;

  @Input() chartData;
  @Input() controlid: string = "";
  @Input() treeWidth: string = "";
  @Input() treeHeight: string = "";
  @Input() initialTreeDepth: number;
  @Input() height;
  @Input() width;
  @Input() backgroundColor;
  @Input() top;


  // * ToolBox 
  @Input() toolboxInfo : boolean = false;
  @Input() toolboxExpand : boolean = false;

  @Input() dataToolbox;
  showInfo: boolean = true;


  
  loaderService
  workflowStepResultEventTriggers
  findNestedStep

  // ! ANIMATION AND REBUILD CHART AFTER EDIT
  lastClickedNode
  currentDataBeforeEdit
  rootParents

  countCall=0



  ngOnInit(): void {
    this.checkFeatures();

    this.customEventSubscription = this.eventService.customEvent.subscribe(
      event => {
    //console.log('event', event)
        if (this.controlid == "") {
          this.controlid = "flowChart_".concat(this.commonService.createGuid());
        }

        if (event.id == "updateFlowChart" && event.value == this.controlid) {

  /*         console.log('this.controlid', this.controlid)
          console.log('API EVENT ', event.id, '---->' , event.chartData) */
          this.starterStepId = event?.charData?.starterStepId;
          this.isEditor = event.editor
          this.isSuperVisor = this.userService.hasPermission("CanUseProcessVisualExpertMode");

          this.findNestedStep = this.getNestedSteps(event.chartData?.workflowSteps)
          this.chartData = this.recursiveData(event.chartData);
          //console.log('this', this.chartData)
          this.createFlowChart();
          //console.log('this options', this.options)
        }

        if(event.id == 'updateTemplateFlowChart'){
          this.isEditor = event.editor;
          this.chartData = this.recursiveData(event.chartData);
          this.createFlowChart();
        }

        if(event.id == 'updateFlowChart-WIZARD'){
          //console.log('flowchart event', event.id)
          this.starterStepId = event?.charData?.starterStepId;
          this.isEditor = event.editor
          this.isSuperVisor = this.userService.hasPermission("CanUseProcessVisualExpertMode");

          this.findNestedStep = this.getNestedSteps(event.chartData?.workflowSteps)
          this.chartData = this.recursiveData(event.chartData);
          this.updateChart();
          this.getPreviousChartPath();
        }
      }
    );


    if (this.controlid == 'microCampaignStarted') {
      this.isStarterStep = true;

      this.chartData = this.recursiveData(this.chartData);
      this.isEditor = false;
      this.createFlowChart();
    }

    if(this.controlid == 'campaignOverview'){
      //console.log('campaignOverview data before', this.chartData)
      // console.log('campaignOverview data before', this.chartData)
      this.chartData = this.campaignOverviewData(this.chartData);
      this.isEditor = false;
      this.createFlowChart();
    }

    this.createFlowChart();
    this.checkFeatures()

  }

  onChartInit(e) {
    this.chartInstance = e;
    this.chartInstance.resize()
  
  }

  checkFeatures(){
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_emailtemplate']);
    this.emailTemplateFeatureIsActive = this.packagefeatureService.selectedPackageHasFeature(this.featureId);
    //console.log('this.emailtemplateIsActive', this.emailTemplateFeatureIsActive);
    //this.emailTemplateFeatureIsActive = false 
  
  }

 getNestedSteps(data){ 
    //const steps = data
    //console.log('steps', steps)
    const array =[];

     data.map((item, index)=>{
      const stepId = {id:item.id , name:this.getTranslationValue("name", item)};
      //console.log('id', parentId)
      const findSteps = data.map((item, index)=>{
        return this.findStepId(item.results, stepId, item.id)
      })

      if(findSteps.includes(true)){
        array.push({
          id:item.id, 
          defaultName: item.defaultName,
          name: this.getTranslationValue("name", item)
        })
      }
    })

     return array
    
}

  findStepId(array, stepId, currentRowId){
    //console.log('other id ',currentId)
    let found: any = false;
    array.map((item) =>{
      // console.log('StepId', stepId.id, 'StepName', stepId.name,  'currentRowId', currentRowId, 'nextStepId', item.nextStepId, 'isfound-> ', (item.nextStepId === stepId.id) , 'name',this.getTranslationValue("name", item), 'defaultName', item.defaultName)
      if(item.nextStepId === stepId?.id && item.nextStepId !== currentRowId){
        found = true
      }
      return
    })
    return found
  }



  isAnimation(params){
    if(params.event.target.style.image === "/assets/images/svg/charts/note-pencil.svg"){
      return false
    } else if(params.event.target.style.image === "/assets/images/svg/charts/envelope-open.svg"){
      return false
    } else {
      return true
    }

  }

  onChartClick(params) {

    this.lastClickedNode= undefined;
    this.currentDataBeforeEdit = this.chartInstance.getOption().series[0].data[0]
    const toggleNodeData = this.toggleNodeState(this.currentDataBeforeEdit, params.data);
    //console.log('params', params)
    //console.log('toggleNodeData',this.chartInstance.getOption().series[0].data)

      this.updateOptions={
        series:[{
          data:[toggleNodeData],
          animation:this.isAnimation(params)
        }],
      }

    if(params.event.target.style.image === "/assets/images/svg/charts/note-pencil.svg"){
      this.lastClickedNode = params
      params.event.event.preventDefault();

      this.onModalOpen(params);
      const collapsedNodeData = this.preventCollapse(this.currentDataBeforeEdit, params.data)
      this.updateOptions={
        series:[{
          data:[collapsedNodeData],
        }]
      }

    }

    if(params.event.target.style.image === "/assets/images/svg/charts/envelope-open.svg"){
      let result = this.applicationInfoService.results.toArray().find((arrayItem) => arrayItem.id == params.data.id);
        this.lastClickedNode = params;
        params.event.event.preventDefault();
        const nodes = this.preventCollapse(this.currentDataBeforeEdit, params.data);
        this.wizardService.showWizard(WizardMode.EMailTemplate, result);

        this.updateOptions={
          series:[{
            data:[nodes]
          }]
        }
    }


    this.refreshBlocker = true;
    setTimeout(() => {
      this.refreshBlocker = false;
    }, 1000);

  }

  toggleNodeState(data, currentNode){
    // console.log('toggleNodeState', data, currentNode)
    if(data.value === currentNode.value){
      return {...data, collapsed: !data.collapsed };
    } else if(data.children){
      return {...data, children:data.children.map((child)=> this.toggleNodeState(child, currentNode))}
    } else {
      return {...data, collapsed:true };
    }
  }


  preventCollapse(data, currentNode){
    //console.log('preventCollapse', data, currentNode)
    if(data.value === currentNode.value){
      // ! commented return makes the node children to collapse
      //return {...data, collapsed: true };
      return {...data, collapsed: data.collapsed === false ? false : true };
    } else if(data.children){
      return{...data, children: data.children.map((child)=> this.preventCollapse(child, currentNode))}
    } else {
      return {...data };
    }
  }  

  updateChart(){
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    this.options = {
      responsive: true,
      backgroundColor: this.backgroundColor ? this.backgroundColor : 'transparent',
      tooltip: { 
        show: this.controlid === 'campaignOverview' ? false : true,
        trigger: "item",
        triggerOn: "mousemove",
        enterable: true,
        confine: true,
        formatter: ({ data }) => {
          return this.tooltipHTMLFormater(data.type, data);
        },
      },
      textStyle:{
        fontSize: 12,
      },
      grid: {
        left: 10,
        containLabel: true,
        bottom: 10,
        top: 10,
        right: 30
      },
      series: [
        {
          name:'flowchart',
          value:0,
          id:'flowchart_0',
          type: "tree",
          data: [this.chartData],
          layout: "orthogonal",
          orient: "LR",
          width: this.treeWidth ? this.treeWidth : undefined,
          height: this.treeHeight ? this.treeHeight : undefined,
          top: this.top ? this.top : '1%',
          zoom:0,
          roam: true,
          //expandAndCollapse: true,
          //animation:true,
          silent: false,
          label:{
            formatter:(v)=>{
              if(this.controlid == 'campaignOverview'){
                return this.getCampaignOverViewLabelFormatter(v)
              }
              if(this.controlid == 'microCampaignStarted'){
                return this.getMicroCampaignStartedLabelFormatter(v)
              }

              if(this.controlid != 'campaignOverview' && this.controlid != 'microCampaignStarted'){
                return this.getRegularLabelFormatter(v)
              }
            },
            rich:{
             ...this.getRichIcons(),
              stepsBg:{
                backgroundColor: "#1c3a56" ,
                align: 'center',
                color:  'white',
                padding:10,
                borderRadius: 0,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                position: 'inside',
                fontSize: 12,
                
              },
              resultsBg:{
                backgroundColor: "#dbd7d4",
                align: 'center',
                verticalAlign: 'middle',
                padding: 10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "black",
                position:'inside'
              },
              resultsChildrenBg:{
                backgroundColor: "#ED5C73",
                //align:'center',
                verticalAlign: 'middle',
                padding:10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "white",
                //position: 'inside'
              },
              finalResultsChildrenBg:{
                backgroundColor: "#ED5C73",
                align:'center',
                verticalAlign: 'middle',
                padding:10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "white",
                position: 'inside'

              },
              previousResultsBg:{
                backgroundColor: "#ED5C73",
                align:'center',
                //verticalAlign: 'middle',
                padding:10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "white",
                position: 'inside'

              },
              inactiveBg:{
                align: 'left',
                padding: 5,
                backgroundColor:'transparent',
                position: 'right',
                verticalAlign:'middle',
                distance: 0,
                color:'red',
              },
              reactionBg:{
                backgroundColor: "white",
                align: "center",
                padding: 10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "black",
                borderColor: "black",
                borderWidth: 1,
                position: 'inside',
                borderType: 'dotted',
              },
              text:{
                backgroundColor: 'transparent',
                color:  'black',
                padding:10,
                position: 'inside',
                //distance:10
              },
              hr:{
                padding:10,
                fontSize:12,
                height: 12,
              }
            },
          },
           leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
            },
          },
         emphasis:{
          focus:'relative',
          disable:false,
         },
        },
      ],
    };
  }

  createFlowChart() {
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    this.options = {
      responsive: true,
      backgroundColor: this.backgroundColor ? this.backgroundColor : 'transparent',
      tooltip: { 
        show: this.controlid === 'campaignOverview' ? false : true,
        trigger: "item",
        triggerOn: "mousemove",
        enterable: true,
        confine: true,
        formatter: ({ data }) => {
          return this.tooltipHTMLFormater(data.type, data);
        },
      },
      textStyle:{
        fontSize: 12,
      },
      grid: {
        left: 10,
        containLabel: true,
        bottom: 10,
        top: 10,
        right: 30
      },
      series: [
        {
          name:'flowchart',
          value:0,
          id:'flowchart_0',
          type: "tree",
          data: [this.chartData],
          layout: "orthogonal",
          orient: "LR",
          width: this.treeWidth ? this.treeWidth : undefined,
          height: this.treeHeight ? this.treeHeight : undefined,
          top: this.top ? this.top : '1%',
          zoom:0,
          initialTreeDepth:this.initialTreeDepth ? this.initialTreeDepth :  3,
          roam: true,
          //expandAndCollapse: true,
          //animation:true,
          silent: false,
          label:{
            formatter:(v)=>{
              if(this.controlid == 'campaignOverview'){
                return this.getCampaignOverViewLabelFormatter(v)
              }
              if(this.controlid == 'microCampaignStarted'){
                return this.getMicroCampaignStartedLabelFormatter(v)
              }

              if(this.controlid != 'campaignOverview' && this.controlid != 'microCampaignStarted'){
                return this.getRegularLabelFormatter(v)
              }
            },
            rich:{
             ...this.getRichIcons(),
              stepsBg:{
                backgroundColor: "#1c3a56" ,
                align: 'center',
                color:  'white',
                padding:10,
                borderRadius: 0,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                position: 'inside',
                fontSize: 12,
                
              },
              resultsBg:{
                backgroundColor: "#dbd7d4",
                align: 'center',
                verticalAlign: 'middle',
                padding: 10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "black",
                position:'inside'
              },
              resultsChildrenBg:{
                backgroundColor: "#ED5C73",
                //align:'center',
                verticalAlign: 'middle',
                padding:10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "white",
                //position: 'inside'
              },
              finalResultsChildrenBg:{
                backgroundColor: "#ED5C73",
                align:'center',
                verticalAlign: 'middle',
                padding:10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "white",
                position: 'inside'

              },
              previousResultsBg:{
                backgroundColor: "#ED5C73",
                align:'center',
                //verticalAlign: 'middle',
                padding:10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "white",
                position: 'inside'

              },
              inactiveBg:{
                align: 'left',
                padding: 5,
                backgroundColor:'transparent',
                position: 'right',
                verticalAlign:'middle',
                distance: 0,
                color:'red',
              },
              reactionBg:{
                backgroundColor: "white",
                align: "center",
                padding: 10,
                borderRadius: 12,
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
                color: "black",
                borderColor: "black",
                borderWidth: 1,
                position: 'inside',
                borderType: 'dotted',
              },
              text:{
                backgroundColor: 'transparent',
                color:  'black',
                padding:10,
                position: 'inside',
                //distance:10
              },
              hr:{
                padding:10,
                fontSize:12,
                height: 12,
              }
            },
          },
           leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
            },
          },
         emphasis:{
          focus:'relative',
          disable:false,
         },
        },
      ],
    };

  }

  getPreviousChartPath(){ 
    if(this.lastClickedNode && this.chartInstance){
      let updatedData = this.matchCollapseData(this.chartData, this.currentDataBeforeEdit);

      this.updateOptions={
        series:[{
          data:[updatedData]
        }]
      }

      // ! not sure if this is effective enough it presents an effect that sometimes is broken
   /*    this.chartInstance.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.lastClickedNode.dataIndex,
      
      }); */
    }
  }

  matchCollapseData(newData, oldData){
    return {
      ...newData,
      collapsed: oldData.collapsed,
      children: newData?.children?.map(child1 =>{
        const child2 = oldData?.children?.find(chil2 =>chil2.value === child1.value);
        return child2 ? this.matchCollapseData(child1, child2) : child1;
      })
    }
  }
  

  // ! I leave this comented in case there is a request to just show the highlighted path until the node that was edit
/*   checkVisitedPath(data:any){

  
    const flatParentsArray = this.updatedRoot.flat();
    const stringifyParents = flatParentsArray.map((item) => JSON.stringify(item));
    const removeDuplicates = [...new Set(stringifyParents)];
    const parseParents = removeDuplicates.map((item: any): any => JSON.parse(item));

    console.log('flatParentsArray', parseParents)
    const found = parseParents.find((item) => item.name === data.name && item.value === data.value);

    if(found){
      const treeLength = parseParents.length -1
      const foundIndex = parseParents.findIndex((item) => item.name === found?.name);

      if(data.children && foundIndex <= treeLength){
        return{
          ...data,
          collapsed: false,
          children: data.children.map((child)=> this.checkVisitedPath(child))
        }
      } else {
        return{
          ...data,
          collapsed: true,
        }
      }
    } else {
      return {
        ...data,
        collapsed:true,
      }
    }
  } */


  getToolBox(string){
    switch(string){
      case 'info':
        this.showInfo = this.showInfo === true ? false : true;
        break;
        case 'expand':
            this.applicationInfoService.miscSettings["flowChartOverviewExpanded"] = this.dataToolbox;
            this.applicationInfoService.miscSettings["flowChartOverviewExpandedType"] = this.controlid;
            this.eventService.showJjPopup('',
              "expandflowchartoverview", 100, true);
        break;
        case 'edit':
        default:
          return
    }
  }
  // * API tools 
  recursiveData(data) {
    if (data.workflowSteps) {

      //console.log('data workflow', data)
      return {
        ...this.workflows(0, data),
        children: data.isActive ?  data.workflowSteps.map((item) => this.recursiveData(item)) : null,
  
      };
    } else if (data.results) {
      //console.log('data steps', data)
      if (data.bookTaskAfterXDays) {
        const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
        const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
        // ! to implement later when requested
        //const findStepIfNested = this.findNestedStep?.find(item => item.id === data.id)
      /*   console.log('findTask', findTask, 'data ->', data);
        console.log('isSame', isSame, 'data->', data); */

        if(data.results.length === 1 && isSame){
          //console.log('booking with no reaction', data)
          //const eventTriggers = data.results.filter((item) => item.workflowStepResultEventTriggers.length > 0)
          return {
            ...this.steps(0, data, this.starterStepId, data.isProcessStarter),
            children:[{
              ...this.bookingDays(0, data),
              children:data?.results.map((item)=> this.recursiveData(item))
            }]
          }
        } else {

          //console.log('booking with reaction', 'data ->', data)
          return {
            ...this.steps(0, data, this.starterStepId, data.isProcessStarter),
            children: [
              {
                ...this.bookingDays(0, data),
                children:[
                  {
                  ...this.reaction(0, 'noReaction', data),
                  children:[this.recursiveData(findTask)]
                },
                {
                  ...this.reaction(0, 'reaction', data),
                  children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveData(item)) : null,
                },]
                
              }]
          };
        }

      } else {
        //console.log('no booking', data)
        return {
          ...this.steps(0,data, this.starterStepId, data.isProcessStarter),
          children: data.isActive ? data.results.map((item) => this.recursiveData(item)): null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps.toArray().find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep) {
        return {
          ...this.resultsChildren(0,data, this.isStarterStep),
          children: data.isActive ? [this.recursiveSecond(nextStep)] : null,
        };
      } else {
        return {
          ...this.results(0,data),
        };
      }
    } else {
      return {
        ...this.results(0,data),
      };
    }
  }

  recursiveSecond(data) {
    if (data.workflowSteps) {
      return {
        ...this.workflows(1,data),
        children: data.isActive ? data.workflowSteps.map((item) => this.recursiveSecond(item)) : null,
      };
    } else if (data.results) {
      if (data.bookTaskAfterXDays) {
        const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
        const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
        if(data.results.length === 1 && isSame){
          return {
            ...this.steps(1, data),
            children:[{
              ...this.bookingDays(1,data),
              children:data?.results.map((item)=> this.recursiveSecond(item))
            }]
          }
        } else {
          return {
            ...this.steps(1, data),
            children: [
              {
                ...this.bookingDays(1,data),
                children:[
                  {
                  ...this.reaction(1,'noReaction', data),
                  children:[this.recursiveSecond(findTask)]
                },
                {
                  ...this.reaction(1,'reaction', data),
                  children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveSecond(item)) : null,
                },]
                
              }]
          };
        }
      } else {
        return {
          ...this.steps(1,data),
          children: data.isActive ? data.results.map((item) => this.recursiveSecond(item)) : null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps.toArray().find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep) {
        return {
          ...this.resultsChildren(1,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [this.recursiveThird(nextStep)] : null,
        };
      } else {
          return {
          ...this.results(1,data),
        };
      }
    } else {
      return {
        ...this.results(1,data),
      };
    }
  }

  recursiveThird(data) {
    if (data.workflowSteps) {
      return {
        ...this.workflows(2,data),
        children: data.isActive ? data.workflowSteps.map((item) => this.recursiveThird(item)) : null,
      };
    } else if (data.results) {
      if (data.bookTaskAfterXDays) {
          const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
          const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
          if(data.results.length === 1 && isSame){
            return {
              ...this.steps(2,data),
              children:[{
                ...this.bookingDays(2,data),
                children:data?.results.map((item)=> this.recursiveThird(item))
              }]
            }
          } else {
            return {
              ...this.steps(2,data),
              children: [
                {
                  ...this.bookingDays(2,data),
                  children:[
                    {
                    ...this.reaction(2,'noReaction', data),
                    children:[this.recursiveThird(findTask)]
                  },
                  {
                    ...this.reaction(2,'reaction', data),
                    children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveThird(item)) : null,
                  },]
                  
                }]
            };
          }
      } else {
        return {
          ...this.steps(2,data),
          children: data.isActive ? data.results.map((item) => this.recursiveThird(item)) : null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps.toArray().find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep && this.controlid !== 'microCampaignStarted') {
        return {
          ...this.resultsChildren(2,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [] : null,
        };
      } else if (nextStep && this.controlid === 'microCampaignStarted') {
        return {
          ...this.resultsChildren(2,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [this.recursiveThirdMCStarter(nextStep)] : null,
        };
      } else {
        return {
          ...this.results(2,data),
        };
      }

    } else {
      return {
        ...this.results(2,data),
      };
    }
  }

  recursiveThirdMCStarter(data) {
    if (data.workflowSteps) {
      return {
        ...this.workflows(3,data),
        children: data.isActive ? data.workflowSteps.map((item) => this.recursiveThirdMCStarter(item)) : null,
      };
    } else if (data.results) {
      if (data.bookTaskAfterXDays) {
        const findTask = this.applicationInfoService.results.toArray().find((resultItem) => resultItem.id === data.bookTaskAfterXDaysResultId);
        const isSame = data.results.find((result) => result.id === data.bookTaskAfterXDaysResultId)
        if(data.results.length === 1 && isSame){
          return {
            ...this.steps(3,data),
            children:[{
              ...this.bookingDays(3,data),
              children:data?.results.map((item)=> this.recursiveThirdMCStarter(item))
            }]
          }
        } else {
          return {
            ...this.steps(3,data),
            children: [
              {
                ...this.bookingDays(3,data),
                children:[
                  {
                  ...this.reaction(3,'noReaction', data),
                  children:[this.recursiveThirdMCStarter(findTask)]
                },
                {
                  ...this.reaction(3,'reaction', data),
                  children: data.isActive ? data.results.filter((item)=> item.id !== findTask.id).map((item) => this.recursiveThirdMCStarter(item)) : null,
                },]
                
              }]
          };
        }
        
      } else {
        return {
          ...this.steps(3,data),
          children: data.isActive ? data.results.map((item) => this.recursiveThirdMCStarter(item)) : null,
        };
      }
    } else if (data.nextStepId) {
      const nextStep = this.applicationInfoService.steps
        .toArray()
        .find((stepItem) => stepItem.id === data.nextStepId);
      if (nextStep) {
        return {
          ...this.resultsChildren(3,data),
          nextStepName: nextStep.defaultName,
          children: data.isActive ? [] : null,
        };
      } else {
        return {
          ...this.results(3,data),
        };
      }
    } else {
      return {
        ...this.results(3,data),
      };
    }
  }

  workflows(treeId, data){
    return{
      id: data.id,
      name: data.defaultName,
      value: `W__${treeId}__${data.id}`,
      type: 'workflows',
      status: data.isActive,
      starterStep: data.starterStep,
      starterStepId: data.starterStepId,
      description: this.getTranslationValue('description', data),
      typeLookupId:data.typeLookupId,
      svgLabelName: this.hasSVGs(data),
      ...this.styleConfig('workflows', data),
      collapsed: false,
    }

  }

  steps(treeId, data, starterStepId?:number, isProcessStarter?:boolean, findNestedStep?: number){
    this.countCall =  this.countCall + 1
    return {
      id: data.id,
      name: this.getTranslationValue("name", data),
      value: `S__${treeId}-${this.countCall}__${data.id}`,
      bookTaskAfterXDays: data.bookTaskAfterXDays,
      type: 'steps',
      status: data.isActive,
      svgLabelName: this.hasSVGs(data),
      description: this.getTranslationValue('description',data),
      //nodeHeader: ((starterStepId === data.id) || isProcessStarter ) ? this.getKeyToText('Flowchart.Label.StartProcess') : '',
      typeLookupId: data.typeLookupId,
      isProcessStarter: ((starterStepId === data.id) || isProcessStarter /* || findNestedStep > 0 */) ? true : false,
      ...this.styleConfig('steps', data),
      collapsed: treeId === 0 ? false : true,
    };
  }

  results(treeId, data){
    this.countCall = this.countCall +1
    return {
      id: data.id,
      name: this.getTranslationValue("name", data),
      value: `R__${treeId}-${this.countCall}__${data.id}`,
      nextStepId: data.nextStepId,
      type: 'results',
      status: data.isActive,
      dueInDaysMax: data.dueInDaysMax,
      bookTaskAfterXDays: data.bookTaskAfterXDays,
      leadStatusId: data.leadStatusId,
      emailTemplateId:data.emailTemplateId,          
      description: this.getTranslationValue('description',data),
      svgLabelName: this.hasSVGs(data),
      typeLookupId: data.typeLookupId,
      ...this.styleConfig('results',data),
      collapsed: true,
    }
  }

  resultsChildren(treeId, data, isStarterStep?:boolean){
    this.countCall = this.countCall + 1
    return {
      type: 'resultsChildren',
      id: data.id,
      name: this.getTranslationValue("name", data),
      value: `RCH__${treeId}-${this.countCall}__${data.id}`,
      nextStep: data.nextStepId,
      status: data.isActive,
      dueInDaysMax: data.dueInDaysMax ? `max. ${data.dueInDaysMax} `+ this.getKeyToText('Flowchart.Label.Days') :  '',//data.dueInDaysMax,
      bookTaskAfterXDays: data.bookTaskAfterXDays,
      leadStatusId: data.leadStatusId,
      emailTemplateId: data.emailTemplateId,
      description: this.getTranslationValue('description', data),
      isCampaignStarter: isStarterStep,
      nodeHeader: isStarterStep ? this.getKeyToText('Flowchart.Label.StartCampaign') : '',
      typeLookupId:data.typeLookupId,
      svgLabelName: this.hasSVGs(data),
      eventTriggers:  data?.workflowStepResultEventTriggers?.length > 0 ? `${data?.workflowStepResultEventTriggers?.length} ${this.getKeyToText('Flowchart.Label.EventTriggers')}` : '',
      ...this.styleConfig('results-children', data),
      collapsed: true,
    };
  }


  bookingDays(treeId, data){
    this.countCall = this.countCall + 1
    return {
      id: data.id,
      name: `max. ${data.bookTaskAfterXDays} `+ this.getKeyToText('Flowchart.Label.Days'),
      value: `BD__${treeId}-${this.countCall}__${data.id}`,
      type: 'bookingDays',
      ...this.styleConfig('bookingDays',data),
      collapsed: true,
    };
  }


  reaction(treeId, string, data){
    this.countCall = this.countCall + 1
    switch(string){
      case "noReaction":
        return {
          id:  data.id,
          name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.NoMoreAction'),
          value: `NRCT__${treeId}-${this.countCall}__${data.id}`,
          type: 'noReaction',
          ...this.styleConfig('reaction'),
          collapsed: true,
        }
      case "reaction":
        return {
          id:  data.id,
          name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.Reaction'),
          value: `RCT__${treeId}-${this.countCall}__${data.id}`,
          type: 'reaction',
          ...this.styleConfig('reaction'),
          collapsed: true,
        }
    }
  }


  styleConfig(string, data?: any,) {
    switch (string) {
      case "workflows":
        return {
          itemStyle: {
            color: "#80B800",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: "circle",
          symbolSize: 90,
          label: {
            align: "center",
            position: 'inside',
            color: data.isActive ? "white" : "black",
            borderRadius: 12,
            overflow: "truncate",
            ellipsis: "...",
            width: 75,
            padding: 30,
            lineHeight: 17,
          },
          
     /*      animation:true,
          animationDelay: 0 */
        };
        break;
      case "steps":
            return {
              itemStyle: {
                color: data.isActive ? "#1c3a56" : "red",
                borderColor: data.isActive ? "none"  : 'red',
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
              },
              symbol: data.isActive ? 'none' : this.nodesSVGs('inactive'),
              symbolSize: data.isActive ? 0 : 15,
              label: {
                align: data.isActive ? 'center' : 'left',
                padding: data.isActive ? 10 : 5,
                backgroundColor:'transparent',
                position: data.isActive ? 'inside' : 'right',
                distance: data.isActive ? 1 : 0,
                color: data.isActive ? "white" : 'red',
              },
            /*   animation:true,
              animationDelay: 0 */

            };
          break;
      case "bookingDays":
        return {
          itemStyle: {
            color: "#253342",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: this.nodesSVGs('bookingDays'),
          symbolSize: 20,
          label: {
            position: "bottom",
            align: "center",
            distance: 10,
          },
        /*   animation:true,
          animationDelay: 0 */
        };
        break;
      case "reaction":
        return {
          itemstyle: { color: "transparent" },
          symbol:  'none',
          symbolSize: 35,
           label: {
            backgroundColor: "transparent",
            align: "center",
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: "black",
            borderColor: "transparent",
            borderWidth: 0.5,
            borderType: 'dashed'
          },
        /*   animation:true,
          animationDelay: 0 */
        };
        break;
      case "results":
        return {
          itemStyle: {
            color: data.isActive ? "#dbd7d4" : "red",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: data.isActive ? 'none' : this.nodesSVGs('inactive'),
          symbolSize: data.isActive ? 0 : 15,
          label: {
            backgroundColor: "transparent",
            align: data.isActive ? 'center' : 'left',
            padding: data.isActive ? 10 : 2,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: data.isActive ? "black" : "red",
          },
        };
        break;
      case "results-children":
        return {
          itemStyle: {
            color: data.isActive ? "#dbd7d4" : "red",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: data.isActive ? 'none' :  this.nodesSVGs('inactive'),
          symbolSize: data.isActive ? 0 : 15,
          label: {
            backgroundColor: "transparent",
            align: data.isActive ? 'center' : 'left',
            padding: data.isActive ? 10 : 2,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: data.isActive ? "white" : "red",
          },
        };
        break;
    }
  }

  onModalOpen({ data }) {
    this.refreshBlocker = true;
    let type = data.type

    if(type === 'resultsChildren'){
      type = 'results'
    }else if ( type === 'reaction' || type === 'noReaction') {
      type = 'steps'
    } else {
      type = data.type
    }

    if(!this.isSuperVisor){
      this.openDialogue(data)
    } else {
      let object
      if(type !== 'bookingDays'){
        object = this.applicationInfoService[type].toArray().find((arrayItem) => arrayItem.id == data.id);
        //console.log('OBJECT MODAL', object)
      }
    switch (type) {
      case "workflows":
        if (!this.userService.hasPermission("CanUpdateWorkflowStepResult")) {
          this.openDialogue(data, 'workflows');
        }
        object.showDuplicateOption = false;
        this.wizardService.showWizard(WizardMode.Process, object);
        break;
      case "steps":
        if (!this.userService.hasPermission("CanUpdateWorkflowStep")) {
          //console.log('no credentials')
           this.openDialogue(data, 'steps');
        }
        this.wizardService.showWizard(WizardMode.Step, object);
        break;
      case "results":
        if (!this.userService.hasPermission("CanUpdateWorkflow")) {
           this.openDialogue(data, 'results');
        }
        this.wizardService.showWizard(WizardMode.Result, object);
        break;
      case 'bookingDays':
        this.openDialogue(data, 'bookingDays')
        break;
    }

  }
    setTimeout(() => {
      this.refreshBlocker = false;
    }, 2000);
  }

  openDialogue(data, stringType?: string){
    let type = stringType ? stringType : data.type
    let name = data.name;

    if(type === 'resultsChildren'){
      type = 'results'
    }
    if(type === 'bookingDays'){
      type = 'steps'
      name = 'ProcessVisual.Label.Automaticbooking'
    }
    if ( type === 'reaction' || type === 'noReaction' ) {
      type = 'steps'
    }
      let object = this.applicationInfoService[type].toArray().find((arrayItem) => arrayItem.id == data.id);
      this.applicationInfoService.miscSettings["processVisualObject"] = object;
      this.applicationInfoService.miscSettings["processVisualObjectType"] = data.type;
      this.eventService.showJjPopup(name,
        "processvisualpopup",
        "500px; 550px"
        );
  }

  getTranslationValue(type, data) {
    if (type === "name") {
      if (
        data?.nameTranslationToken != null &&
        data?.nameTranslationToken != undefined
      ) {
        return this.axivasTranslationService.getTranslationTextForToken(
          data?.nameTranslationToken?.tokenFullName
        );
      } else {
        return data.defaultName;
      }
    }
    if (type === "description") {
      if (data?.descriptionTranslationToken != null && data?.descriptionTranslationToken != undefined) {
        return this.axivasTranslationService.getTranslationTextForToken(
          data?.descriptionTranslationToken?.tokenFullName
        );
      } else {
        return data.descriptionTranslationToken;
      }
    }
  }

  getKeyToText(key) {
    return this.axivasTranslationService.getTranslationTextForToken(key);
   }

  getLeadstate(data) {
    const leadstate = this.applicationInfoService.leadstates
      .toArray()
      .find((lead) => lead.lookupTable.id == data.leadStatusId);
    if (leadstate) {
      return this.axivasTranslationService.getTranslationTextForObject(
        leadstate.lookupTable,
        "nameTranslationToken"
      );
    } else {
      return this.axivasTranslationService.getTranslationTextForToken(
        "ProcessVisual.Label.NoChange"
      );
    }
  }

  getDifferenceInDays(date1, date2, stringContent, multiplicator) {
    if (date1 == null) {
      return '';
    } else {
      return this.commonService.getDifferenceInDays(date1, date2, stringContent, multiplicator);
    }
  }

  isValidStep(step) {
    let returnValue = true;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.steps.Item(step))) {
      if (this.applicationInfoService.steps.Item(step).typeLookupId == 3842) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  nodesSVGs(string){
    //console.log('NODES SVGS',string)
    switch(string){
      case 'inactive':
        return "path://M19.82,18.07a1.59,1.59,0,0,1,.3.46,1.45,1.45,0,0,1,.1.55,1.44,1.44,0,0,1-.1.54,1.59,1.59,0,0,1-.3.46,1.39,1.39,0,0,1-.45.31,1.31,1.31,0,0,1-1.05,0,1.39,1.39,0,0,1-.45-.31L12,14,6.13,20.08a1.39,1.39,0,0,1-.45.31,1.31,1.31,0,0,1-1,0,1.41,1.41,0,0,1-.75-.77,1.44,1.44,0,0,1-.1-.54,1.45,1.45,0,0,1,.1-.55,1.41,1.41,0,0,1,.3-.46L10.07,12,4.18,5.93a1.49,1.49,0,0,1-.4-1,1.45,1.45,0,0,1,.4-1,1.34,1.34,0,0,1,2,0L12,10l5.87-6.08a1.34,1.34,0,0,1,1.95,0,1.45,1.45,0,0,1,.4,1,1.49,1.49,0,0,1-.4,1L13.93,12Z"
      case 'bookingDays':
        return "path://M208,96a12,12,0,1,1,12,12A12,12,0,0,1,208,96ZM196,72a12,12,0,1,0-12-12A12,12,0,0,0,196,72Zm28.66,56a8,8,0,0,0-8.63,7.31A88.12,88.12,0,1,1,120.66,40,8,8,0,0,0,119.34,24,104.12,104.12,0,1,0,232,136.66,8,8,0,0,0,224.66,128ZM128,56a72,72,0,1,1-72,72A72.08,72.08,0,0,1,128,56Zm-8,72a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H136V80a8,8,0,0,0-16,0Zm40-80a12,12,0,1,0-12-12A12,12,0,0,0,160,48Z"
      case 'mail':
        return "path://M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.43,64,128,133.15,52.57,64ZM216,192H40V74.19l82.59,75.71a8,8,0,0,0,10.82,0L216,74.19V192Z"
      case 'call':
        return "path://M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"
      case 'pending':
        return "path://M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm56,112H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48a8,8,0,0,1,0,16Z"
      case 'event-trigger':
        return "path://M48,76a60,60,0,0,1,120,0,8,8,0,0,1-16,0,44,44,0,0,0-88,0,8,8,0,0,1-16,0Zm140,44a27.9,27.9,0,0,0-13.36,3.39A28,28,0,0,0,136,106.7V76a28,28,0,0,0-56,0v80l-3.82-6.13a28,28,0,0,0-48.41,28.17l29.32,50A8,8,0,1,0,70.89,220L41.6,170a12,12,0,1,1,20.78-12l.14.23,18.68,30A8,8,0,0,0,96,184V76a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V132a12,12,0,0,1,24,0v20a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0v36c0,21.61-7.1,36.3-7.16,36.42a8,8,0,0,0,3.58,10.73A7.9,7.9,0,0,0,200,232a8,8,0,0,0,7.16-4.42c.37-.73,8.85-18,8.85-43.58V148A28,28,0,0,0,188,120Z"
        default:
          return
     }
  }

  hasSVGs(data){
    switch(data.typeLookupId){
      case 2435:
        return 'mail'
      case 2446:
        return 'conversion'
      case 3842:
        return 'pending'
      case 4190:
        return 'followup'
      case 2434:
        return 'call'
      case 4905:
        return 'meeting'
      case 4906:
        return 'miscellaneous'
    }

  }


  // ! -> CAMPAIGN OVERVIEW

  campaignOverviewData(data){
    if(data.previousTaskStep){
      return {
        name:this.getKeyToText(data.previousTaskStep),
        nodeHeader: this.getKeyToText('MicroCampaign.Label.Previous'),
        svgLabelName: data.previousTaskType,
        description: this.getKeyToText(data.previousTaskResult),
        type: 'steps',
        ...this.styleConfigCampaignOverview('steps'),
        children:[{
          name:this.getKeyToText(data.currentTaskStep),
          nodeHeader:this.getKeyToText('MicroCampaign.Label.Current'),
          svgLabelName:data.currentTaskType,
          type: 'steps',
          ...this.getcampaignOverviewTask(data),
          children:this.campaignOverviewCurrentChildren(data),
          ...this.styleConfigCampaignOverview('steps')
        }]
      }
    } else {
      return{
          name:this.getKeyToText(data.currentTaskStep),
          nodeHeader:this.getKeyToText('MicroCampaign.Label.Current'),
          svgLabelName:data.currentTaskType,
        type: 'steps',
          ...this.getcampaignOverviewTask(data),
          children:this.campaignOverviewCurrentChildren(data),
          ...this.styleConfigCampaignOverview('steps')
        }
      }
  }

  campaignOverviewCurrentChildren(data){
    //console.log('campaignOOverview', data)
    let results =[]
    let noReactionResult =[]
    let reactionResults = []

    // ! manufacture results 
    if(data.resultTypes){
      data?.resultTypes?.map((item) =>{

        // console.log('item', item)
        if(data.nextAutomaticBookingResultId){
           const foundResult = item.results.find(result => result.id === data.nextAutomaticBookingResultId)
           if(foundResult){
            noReactionResult.push({
              resultType: item.resultType,
              name: this.getKeyToText(foundResult.name),
              id: foundResult.id,
              svgLabelName:item.resultType,
              apiBooking:data,
              type:item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'resultsChildren',
              ...this.styleConfigCampaignOverview(item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'results-children')
            })
           }

          item.results.filter((item)=> item.id !== foundResult?.id).map(result =>{
            reactionResults.push({
              name: this.getKeyToText(result.name),
              id: result.id,
              svgLabelName:item.resultType,
              apiBooking:data,
              type:item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'resultsChildren',
              ...this.styleConfigCampaignOverview(item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'results-children')
          })
          }) 
        } 
          item.results.map(result =>{
             results.push({
               name: this.getKeyToText(result.name),
               id: result.id,
               svgLabelName:item.resultType,
               apiBooking:data,
               type:item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'resultsChildren',
               ...this.styleConfigCampaignOverview(item.resultType === 'close' ? 'results' : item.resultType.isAuto === true ?  'bookingDays' : 'results-children')
           })
           })
        
      })
    }

    // ! conditions for results
    if(data.currentTaskResultId && data.nextAutomaticBooking == null && data.currentTaskResultDate != null){
      return [{
        name:this.getKeyToText(data.currentTaskResult),
        nodeHeader: this.getKeyToText('MicroCampaign.Label.EndedWith'),
        type: 'results',
        ...this.styleConfigCampaignOverview('results')
      }]
    } 

    if( data.nextAutomaticBooking != null &&  data.currentTaskResultDate == null && data.nextAutomaticBookingResultId){
      return [
        {
          name: this.commonService.getTimeDifferenceString(data.nextAutomaticBooking, null, "max. <0>", true),
            ...this.styleConfigCampaignOverview("bookingDays"),
            type: 'bookingDays',
            children:[
              {
                type:'noReaction',
                name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.NoMoreAction'),
                id: data.id,
                ...this.styleConfigCampaignOverview("reaction"),
                children:noReactionResult,
              },
              {
                type:'reaction',
                name: this.axivasTranslationService.getTranslationTextForToken('MicroCampaign.Label.Reaction'),
                id:data.id,
                ...this.styleConfigCampaignOverview("reaction"),
                children: reactionResults,
              }

            ]
        }]
    } else {
      return results
    }

  }

  getcampaignOverviewTask(data: any) {
    if (this.isValidStep(data.currentTaskStepId) && data.currentTaskResultDate == null) {
      return {
        currentTaskFollowUpDateLabel: this.getKeyToText('MicroCampaign.Label.TaskFollowUpDate'),
        currentTaskFollowUpDate: data.currentTaskFollowUpdate
      }
    } else {
      return
    }
  }

  styleConfigCampaignOverview(string, svgType?: string){
    switch (string) {
      case "workflows":
        return {
          itemStyle: {
            color: "#80B800",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: "circle",
          symbolSize: 90,
          label: {
            align: "center",
            position: 'inside',
            color: "white",
            borderRadius: 12,
            overflow: "truncate",
            ellipsis: "...",
            width: 75,
            padding: 30,
            lineHeight: 17,
          },

        };
        break;
      case "steps":
            return {
              itemStyle: {
                color: "#1c3a56",
                borderColor:"none",
                shadowBlur: 10,
                shadowOffsetX: -10,
                shadowColor: "rgba(0,0,0, 0.5)",
              },
              symbol:'none',
              symbolSize: 0,
              label: {
                align: 'center',
                padding: 10,
                backgroundColor:'transparent',
                position: 'inside',
                distance: 1,
                color: "white",
              },

            };
          break;
      case "bookingDays":
        return {
          itemStyle: {
            color: "#253342",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: this.nodesSVGs('bookingDays'),
          symbolSize: 25,
          label: {
            position: "bottom",
            align: "center",
            distance: 10,
          },
        };
        break;
      case "reaction":
        return {
          itemstyle: { color: "transparent" },
          symbol:  'none',
          symbolSize: 35,
           label: {
            backgroundColor: "transparent",
            align: "center",
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: "black",
            borderColor: "transparent",
            borderWidth: 0.5,
            borderType: 'dashed'
          },
        };
        break;
      case "results":
        return {
          itemStyle: {
            color: "transparent",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
          },
          symbol: 'none',
          symbolSize: 0 ,
          label: {
            backgroundColor: "transparent",
            align: 'center',
            position:'inside',
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color: "black",
          },
        };
        break;
      case "results-children":
        return {
          itemStyle: {
            color: "#ED5C73",
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            
          },
          symbol: 'none',
          symbolSize: 0,
          label: {
            backgroundColor: "#ED5C73",
            align: 'center',
            position: 'inside',
            padding: 10,
            borderRadius: 12,
            shadowBlur: 10,
            shadowOffsetX: -10,
            shadowColor: "rgba(0,0,0, 0.5)",
            color:"white",
          },
        };
        break;
        default:
          return;
    }
  }


  // * CampaignOverviewLabelFormater
  getCampaignOverViewLabelFormatter(v){
    let nodeType = v.data.type
    let icon = v.data.svgLabelName
    if(v.data.type === 'noReaction'){
      nodeType = 'reaction'
    }
    switch(nodeType){
      case 'steps':
        return [
        `{text| ${v.data.nodeHeader}}`,
        `{${icon}Bg| }`,
        `{hp|}`,
        `{stepsBg| ${v.data.name}}`,
        `{text| ${v.data.description ? v.data.description : ''}}`,
        `{hr|}`,
       ].join('\n');
      case 'results':
        return [
        `{hr|}`,
        `{text| ${v.data.nodeHeader ? v.data.nodeHeader : ''}}`,
        `{hr|}`,
        `{resultsBg| ${v.data.name}}`,
        `{text| ${v.data.description ? v.data.description : ''}}`,
        `{hr|}`,
        `{hr|}`,
      ].join('\n');
      case 'resultsChildren':
          // return [`{${icon}WhiteBg|} ${v.data.name}`,].join('\n');
          return [`${v.data.name}`,].join('\n');
      case 'reaction':
        return [`{reactionBg| ${v.data.name}}`].join('\n');
      case 'bookingDays':
        return [`${v.data.name}`].join('\n');
    }
  }

   // * RegularLabelFormatter
  getRegularLabelFormatter(v){
    let nodeType = v.data.type
    let icon = v.data.svgLabelName

    if(v.data.type === 'noReaction'){
      nodeType = 'reaction'
    }

    if(this.isEditor){
      if(!v.data.status && nodeType !== 'reaction' && nodeType !== 'bookingDays' && nodeType !== 'eventTrigger'){
        return [`{inactiveBg| ${v.data.name}}{editorBg|}`,].join('\n');
      }
      switch(nodeType){
        case 'workflows':
          return [
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
            `${v.data.name}`,
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
            `{editorBg|}`,
         ].join('\n');
        case 'steps':
            if(v.data.isProcessStarter){
              return[
                `{starterProcess|}`,
                `{sp|}`,
                `{${icon}Bg|} {stepsBg| ${v.data.name}}{editorBg|}`,
                `{hr|}`,
              ].join('\n');
            } else {
             return[
               `{hr|}`,
               `{${icon}Bg|} {stepsBg| ${v.data.name}}{editorBg|}`,
               `{hr|}`,
            ].join('\n');
          }
        case 'results':
        if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
          return [`{resultsBg| ${v.data.name}}{editorBg|}{space|}{emailTemplateBg|}`].join('\n');
        } else 
          return [`{resultsBg| ${v.data.name}}{editorBg|}`].join('\n');
        case 'resultsChildren':
          if(v.data.emailTemplateId && this.emailTemplateFeatureIsActive){
            return[`{resultsChildrenBg| ${v.data.name}}{editorBg|}{space|}{emailTemplateBg|}`].join('\n');
          } else {
            return[`{resultsChildrenBg| ${v.data.name}}{editorBg|}`].join('\n');
          }
        case 'reaction':
          if(this.isSuperVisor){
            return [`{reactionBg| ${v.data.name}} {editorBg|}`].join('\n');
          }
          return [`{reactionBg| ${v.data.name}}`].join('\n');
        case 'bookingDays':
          return [`${v.data.name}{editorBg|}`].join('\n');
        case 'eventTrigger':
          return [`${v.data.name}`].join('\n');
      }
    } else {
      if(!v.data.status && nodeType !== 'reaction' && nodeType !== 'bookingDays' && nodeType !== 'eventTrigger'){
        return [`{inactiveBg| ${v.data.name}}`,].join('\n');
      }
      switch(nodeType){
        case 'workflows':
          return [
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
            `${v.data.name}`,
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
            `{hr|}`,
         ].join('\n');
        case 'steps':
          if(v.data.isProcessStarter){
            return [
              `{starterProcess|}`,
              `{${icon}Bg|} {stepsBg| ${v.data.name}}`,
              `{hr|}`,
           ].join('\n');
          } else {
            return [
              `{hr|}`,
              `{${icon}Bg|} {stepsBg| ${v.data.name}}`,
              `{hr|}`,
           ].join('\n');
          }
        case 'results':
        if(v.data.emailTemplateId){
          return [`{resultsBg| ${v.data.name}}`].join('\n');
        } else 
          return [`{resultsBg| ${v.data.name}}`].join('\n');
        case 'resultsChildren':
          if(v.data.emailTemplateId){
            return[`{resultsChildrenBg| ${v.data.name}}`].join('\n');
          } else {
            return[`{resultsChildrenBg| ${v.data.name}}`].join('\n');
          }
        case 'reaction':
          return [`{reactionBg| ${v.data.name}}`].join('\n');
        case 'bookingDays':
          return [`${v.data.name}`].join('\n');
        case 'eventTrigger':
          return [`${v.data.name}`].join('\n');
    }
    }

  }

  // * -> MICROCAMPAIGN STARTER
  getMicroCampaignStartedLabelFormatter(v){
    let nodeType = v.data.type
    let icon = v.data.svgLabelName
    // console.log('icon', icon)
    if(v.data.type === 'noReaction'){
      nodeType = 'reaction'
    }

    if(!v.data.status && nodeType !== 'reaction' && nodeType !== 'bookingDays'){
      return [`{inactiveBg| ${v.data.name}}`,].join('\n');
    }
    switch(nodeType){
      case 'steps':
        return [
        `{hr|}`,
        `{${icon}Bg| } {stepsBg| ${v.data.name}}`,
        `{text| ${v.data.description ? v.data.description : ''}}`,
       ].join('\n');
      case 'results':
        return [`{resultsBg| ${v.data.name}}`].join('\n');
      case 'resultsChildren':
        if(v.data.isCampaignStarter){
          return [
            `{text|${v.data.nodeHeader} }`,
            `{resultsChildrenBg| ${v.data.name}}`,
            `{text| ${v.data.description ? v.data.description : ''}}`
         ].join('\n');
        } else {
            return  [`{resultsChildrenBg| ${v.data.name}}`].join('\n');
        }
      case 'reaction':
        return [`{reactionBg| ${v.data.name}}`].join('\n');
      case 'bookingDays':
        return [`${v.data.name}`].join('\n');
    }

  }

  // * UI FUNCTION 

  getRichIcons(){

    return {
      starterProcess:{
        backgroundColor:{
          image:"/assets/images/svg/charts/flag-checkered.svg",
        },
        height: 20,
        distance:10,
      },
      space:{
        height:5,
        width:5
      },
      mailBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/envelope-simple.svg",
        },
        height: 20,
      },
      mailWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/campaignOverview/envelope-simple.svg",
        },
        height: 20,
      },
      conversionBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/factory.svg",
        },
        height: 20,
      },
      emailTemplateBg:{
        backgroundColor: {
          image:"/assets/images/svg/charts/envelope-open.svg",
        },
        height: 20,
      },
      conversionWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/campaignOverview/factory.svg",
        },
        height: 20,
      },
      pendingBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/calendar-plus.svg",
        },
        height: 20,
      },
      pendingWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/campaignOverview/clock-fill.svg",
        },
        height: 20,
      },
      followupBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/signpost.svg",
        },
        height: 20,
      },
      followupWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/campaignOverview/signpost.svg",
        },
        height: 20,
      },
      callBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/phone.svg",
        },
        height: 20,
        //align: 'center',
      },
      callWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/campaignOverview/phone.svg",
        },
        height: 20,
      },
      meetingBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/users-four.svg",
        },
        height: 20,
      },
      meetingWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/campaignOverview/users-four.svg",
        },
        height: 20,
      },
      miscellaneousBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/shuffle-angular.svg",
        },
        height: 20,
      },
      miscellaneousWhiteBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/shuffle-angular.svg",
        },
        height: 20,
      },
      editorBg:{
        backgroundColor: {
          image:"/assets/images/svg/charts/note-pencil.svg"
        },
        height:20,
        distance: 10
      },
      bookBg:{
        backgroundColor:{
          image:"/assets/images/svg/charts/calendar-plus.svg",
        },
        height: 20,
      },
    }
  }

  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  }

  getWidth() {
    if (isNaN(this.width)) {
      return this.width;
    } else {
      return this.width.toString().concat('px');
    }
  }

  tooltipHTMLFormater(type, data) {
    //console.log('FORMATTER', data)
    switch (type) {
      case "workflows":
        return `
        <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
            ${
              data.status === true
                ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Active"
                  )}</strong></p>`
                : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Inactive"
                  )}</strong></p>`
            }
              <p>${data.name}</p>
              <span></span>
            </div>
              ${
                data.description
                  ? `<div class="tooltipFlowChart__section">
              <h4>${this.axivasTranslationService.getTranslationTextForToken(
                "ProcessVisual.Label.Description"
              )}</h4>
              <p>${this.axivasTranslationService.getTranslationTextForToken(
                data.description
              )}</p>
              <span></span>
            </div>`
                  : ""
              }
        </div>
        `;
      case "steps":
        return `
        ${this.getSvgTooltipFormater(data)}
        <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
            ${
              data.status === true
                ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Active"
                  )}</strong></p>`
                : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                    "ProcessDesign.Label.Inactive"
                  )}</strong></p>`
            }
              <p>${data.name}</p>
              <span></span>
            </div>
      
           ${
             data.description
               ? `<div class="tooltipFlowChart__section">
              <h4>${this.axivasTranslationService.getTranslationTextForToken(
                "ProcessVisual.Label.Description"
              )}</h4>
              <p>${data.description}</p>
              <span></span>
            </div>`
               : ""
           }
            <div class="tooltipFlowChart__section">
            </div>
        </div>
        `;
      case "results":
        return `
        ${this.getSvgTooltipFormater(data)}
          <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
                ${
                  data.status === true
                    ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Active"
                      )}</strong></p>`
                    : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Inactive"
                      )}</strong></p>`
                }
                <p>${data.name}</p>
                <span></span>
              </div>
              <div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "Account.Label.Leadstate"
                )}</h4>
                <p>${this.getLeadstate(data)}</p>
                <span></span>
              </div>
              ${
                data.dueInDaysMax
                  ? `<div class="tooltipFlowChart__section">
                <h4>Followup in days</h4>
                <p>${data.dueInDaysMax}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                data.description
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.Description"
                )}</h4>
                <p>${data.description}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                (data.emailTemplateId && this.emailTemplateFeatureIsActive)
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.EmailTemplateId"
                )}</h4>
                <p>${data.emailTemplateId}</p>
                <span></span>
              </div>`
                  : ""
              }
          </div>
          `;
      case "resultsChildren":
            return `
            ${this.getSvgTooltipFormater(data)}
          <div class="tooltipFlowChart">
            <div class="tooltipFlowChart__section">
                ${
                  data.status === true
                    ? `<p><strong style="color:black" >Status: </strong><strong style="color: green"> ${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Active"
                      )}</strong></p>`
                    : `<p><strong style="color: black">Status: </strong><strong style="color: red">${this.axivasTranslationService.getTranslationTextForToken(
                        "ProcessDesign.Label.Inactive"
                      )}</strong></p>`
                }
                <p>${data.name}</p>
                <span></span>
              </div>
              <div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "Account.Label.Leadstate"
                )}</h4>
                <p>${this.getLeadstate(data)}</p>
                <span></span>
              </div>
              ${
                data.dueInDaysMax
                  ? `<div class="tooltipFlowChart__section">
                <h4>Followup in days</h4>
                <p>${data.dueInDaysMax}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                data.description
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.Description"
                )}</h4>
                <p>${data.description}</p>
                <span></span>
              </div>`
                  : ""
              }
              ${
                (data.emailTemplateId && this.emailTemplateFeatureIsActive) 
                  ? `<div class="tooltipFlowChart__section">
                <h4>${this.axivasTranslationService.getTranslationTextForToken(
                  "ProcessVisual.Label.EmailTemplateId"
                )}</h4>
                <p>${data.emailTemplateId}</p>
                <span></span>
              </div>`
                  : ""
              }
          </div>
          `;
    }
  }

  getSvgTooltipFormater(data){
    //console.log('data', data)
    switch(data.svgLabelName){
      case 2435:
        return `
        <div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.43,64,128,133.15,52.57,64ZM216,192H40V74.19l82.59,75.71a8,8,0,0,0,10.82,0L216,74.19V192Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 2446:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M116,176a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h28A8,8,0,0,1,116,176Zm60-8H148a8,8,0,0,0,0,16h28a8,8,0,0,0,0-16Zm72,48a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H32V88a8,8,0,0,1,12.8-6.4L96,120V88a8,8,0,0,1,12.8-6.4l38.74,29.05L159.1,29.74A16.08,16.08,0,0,1,174.94,16h18.12A16.08,16.08,0,0,1,208.9,29.74l15,105.13s.08.78.08,1.13v72h16A8,8,0,0,1,248,216Zm-85.86-94.4,8.53,6.4h36.11L193.06,32H174.94ZM48,208H208V144H168a8,8,0,0,1-4.8-1.6l-14.4-10.8,0,0L112,104v32a8,8,0,0,1-12.8,6.4L48,104Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 3842:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-48-56a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,152Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 4190:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M246,106.65l-36-40A8,8,0,0,0,204,64H136V32a8,8,0,0,0-16,0V64H40A16,16,0,0,0,24,80v64a16,16,0,0,0,16,16h80v64a8,8,0,0,0,16,0V160h68a8,8,0,0,0,5.95-2.65l36-40A8,8,0,0,0,246,106.65ZM200.44,144H40V80H200.44l28.8,32Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 2434:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 4905:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M27.2,126.4a8,8,0,0,0,11.2-1.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,93.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,115.2,8,8,0,0,0,27.2,126.4ZM176,40a24,24,0,1,1-24,24A24,24,0,0,1,176,40ZM80,40A24,24,0,1,1,56,64,24,24,0,0,1,80,40ZM203,197.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,219.2a8,8,0,1,0,12.8,9.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,197.51ZM80,144a24,24,0,1,1-24,24A24,24,0,0,1,80,144Zm96,0a24,24,0,1,1-24,24A24,24,0,0,1,176,144Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
      case 4906:
        return `<div class="tooltipFlowChart">
        <div class="tooltipFlowChart__Svgwrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" viewBox="0 0 256 256"><path d="M237.66,178.34a8,8,0,0,1,0,11.32l-24,24a8,8,0,0,1-11.32-11.32L212.69,192H200.94a72.12,72.12,0,0,1-58.59-30.15l-41.72-58.4A56.1,56.1,0,0,0,55.06,80H32a8,8,0,0,1,0-16H55.06a72.12,72.12,0,0,1,58.59,30.15l41.72,58.4A56.1,56.1,0,0,0,200.94,176h11.75l-10.35-10.34a8,8,0,0,1,11.32-11.32ZM143,107a8,8,0,0,0,11.16-1.86l1.2-1.67A56.1,56.1,0,0,1,200.94,80h11.75L202.34,90.34a8,8,0,0,0,11.32,11.32l24-24a8,8,0,0,0,0-11.32l-24-24a8,8,0,0,0-11.32,11.32L212.69,64H200.94a72.12,72.12,0,0,0-58.59,30.15l-1.2,1.67A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.16,1.86l-1.2,1.67A56.1,56.1,0,0,1,55.06,176H32a8,8,0,0,0,0,16H55.06a72.12,72.12,0,0,0,58.59-30.15l1.2-1.67A8,8,0,0,0,113,149Z"></path></svg>
        <p>${data.svgLabelName}</p>
        </div>
        </div>`
        default:
          return `<span></span>`
    }
    
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) {
      this.customEventSubscription.unsubscribe();
    }
  }


}


