<div class="ringCentralPopupWrapper">
    <div class="infoLine" *ngIf="ringCentralMode == 1">
        <div class="infoLine__item">
            {{ ringcentralService?.userInfo.firstName }} {{ ringcentralService?.userInfo.lastName }}
        </div>
        <div class="infoLine__item">
            <div class="infoLine__item__section">Extension</div> {{ ringcentralService?.userInfo.extensionNumber }}
        </div>
        <div class="infoLine__item">
            <div class="infoLine__item__section">Phone</div> {{ ringcentralService?.userInfo.directNumber }}
        </div>
        <div class="infoLine__item">
            <div class="infoLine__item__section">Id</div> {{ ringcentralService?.userInfo.extensionId }}
        </div>
        <div class="infoLine__item__logout">
            <div class="infoLine__item__logout__button" (click)="logout()">Logout</div>
        </div>
    </div>

    <div class="infoLine" *ngIf="ringCentralMode == 2">
        <div class="infoLine__item">
            {{ ringcentralwebphoneService?.extension.name }}
        </div>
        <div class="infoLine__item">
            <div class="infoLine__item__section">Extension</div> {{ ringcentralwebphoneService?.extension.extensionNumber }}
        </div>
        <div class="infoLine__item">
            <div class="infoLine__item__section">Id</div> {{ ringcentralwebphoneService?.extension.extensionNumber }}
        </div>
        <div class="infoLine__item__logout">
            <div class="infoLine__item__logout__button" (click)="ringcentralwebphoneService.logout()">Logout</div>
        </div>
    </div>

    <div class="jjTab desktop-visible ringCentralTabs">
        <button [ngClass]="{ jjActivetab: selectedTab == tab.id }"
            *ngFor="let tab of visibleTabs; let currentIndex = index" class="tablinks" (click)="setCurrentChild(tab)">
            {{ tab.name | texttransform }}
        </button>
        <div class="ringCentalFilter" *ngIf="selectedTab != 2">
            <div class="ringCentalFilter__icon">
                <i class="fas fa-filter"></i>
            </div>
            <div class="ringCentalFilter__input">
                <input [(ngModel)]="filterString" (keyup)="applyFilter($event)">
            </div>
        </div>
    </div>

    <div class="tabContentSection">
        <table mat-table #table [dataSource]="dataSource" multiTemplateDataRows matTableResponsive matSort
            #sort1="matSort" class="matTable ringCentralTable" *ngIf="selectedTab == 0" matSort>
            <ng-container matColumnDef="start">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.Date' | texttransform }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.start | date: 'medium' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="fromNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.Caller' | texttransform }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.fromNumber }} <i class="fas fa-info-circle fingerCursor" 
                        (click)="getPotentialCaller(element.fromNumber)" [title]="'RingCentral.Label.GetCallerInfo' | texttransform"></i>
                </td>
            </ng-container>

            <ng-container matColumnDef="toNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.Partner' | texttransform
                    }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.toNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.Duration' | texttransform
                    }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.durationFormatted }}
                </td>
            </ng-container>

            <ng-container matColumnDef="direction">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element">
                    <svg-icon src="assets/images/svg/telefon.svg" class="fingerCursor"
                        [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" (click)="callHistoryTarget(element)">
                    </svg-icon>
                    {{ element.direction }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCallHistory; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCallHistory;"></tr>

        </table>



        <table mat-table #table [dataSource]="dataSourceAdressBook" multiTemplateDataRows matTableResponsive matSort
            #sort2="matSort" class="matTable ringCentralTable" *ngIf="selectedTab == 1"  matSort>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.LastName' | texttransform
                    }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.lastName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.FirstName' | texttransform
                    }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.firstName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.EMail' | texttransform }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                </td>
            </ng-container>

            <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.Department' |
                    axivastranslate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.department }}
                </td>
            </ng-container>

            <ng-container matColumnDef="extension">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.Extension' | texttransform
                    }}</th>
                <td mat-cell *matCellDef="let element">
                    <svg-icon src="assets/images/svg/telefon.svg" class="fingerCursor"
                        [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" (click)="callNumber(element.extension)">
                    </svg-icon>
                    {{ element.extension }}
                </td>
            </ng-container>

            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RingCentral.Label.PhoneNumber' |
                    axivastranslate }}</th>
                <td mat-cell *matCellDef="let element">
                    <svg-icon src="assets/images/svg/telefon.svg" class="fingerCursor"
                        [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" (click)="callNumber(element.phoneNumber)">
                    </svg-icon>
                    {{ element.phoneNumber }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsAdressBook; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAdressBook;"></tr>

        </table>

        <div class="ringCentralCallDiv" [ngClass]="{ hideInDom: selectedTab != 2 }">
            <div class="callSection">
                <input [(ngModel)]="callingNumber" class="ringCentralCallInput">
                <button (click)="callNumber(callingNumber)" class="ringCentralCallButton">Call</button>
            </div>

        </div>
        <div class="callSection" *ngIf="selectedTab == 999">
            <select [(ngModel)]="selectedCacheArea" class="ringCentralCallInput">
                <option *ngFor="let cacheArea of ringcentralService.cacheAreas" [ngValue]="cacheArea">
                    {{ cacheArea }}
                </option>
            </select>
            <br>
            <br>
            <button class="ringCentralCallButton" (click)="updateCache()">Go!</button>
        </div>
    </div>

    <!-- <div class="ringCentralFoundContacts" *ngIf="showFoundContacts">   
        <div class="ringCentralPopup__callerInfoHeader">
            <div class="ringCentralPopup__callerInfoHeader__label">
                {{ 'IncomingCall.Label.FoundContacts' | texttransform  }}: {{ lastSearched }}
            </div>
            <div (click)="showFoundContacts = false" class="fingerCursor ringCentralPopup__callerInfoHeader__icon">
                <i class="fas fa-close"></i>
            </div>
        </div>     
        <div class="incomingCall__inner__caller__wrapper" *ngIf="loadingData == false ">
            <div class="incomingCall__inner__caller" *ngFor="let caller of potentialCallers" (click)="loadContact(caller)" [title]="'IncomingCall.Label.Enter' | texttransform">
                <label class="incomingCall__inner__caller__contactlabel">{{ caller?.firstName }} {{ caller?.lastName }}</label>
                <label class="incomingCall__inner__caller__accountlabel" *ngIf="caller?.account?.name1">{{ caller?.account?.name1 }}</label>
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.account?.phone">
                    {{ 'IncomingCall.Label.Phone' | texttransform }}: {{ caller?.account?.phone }}
                </label>
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.mobile">
                    {{ 'IncomingCall.Label.Mobile' | texttransform }}: {{ caller?.mobile }}
                </label>                
                <label class="incomingCall__inner__caller__phonelabel" *ngIf="caller?.extension">
                    {{ 'IncomingCall.Label.Extension' | texttransform }}: {{ caller?.extension }}
                </label>                     
            </div>
        </div>
    </div> -->
</div>