<div *ngIf="applicationInfoService.showBanner" class="everleadDemoBannerBackground"></div>
<div *ngIf="applicationInfoService.showBanner" class="everleadDemoBanner" [ngClass]="{
    largeHeight: demoPage == 0 || demoPage == 1,
    smallHeight: demoPage == 2
}">
    <div class="everleadDemoBanner__inner">
        <!-- <div *ngIf="demoPage==0" class="visitTheWebsite fingerCursor" (click)="visitWebsite()">Webseite besuchen</div> -->
        <svg-icon src="assets/images/banner/banner_left.svg" class="everleadDemoBanner__inner__previous fingerCursor"  (click)="demoPage=demoPage-1"
            [svgStyle]="{ 'width.px': 32, 'height.px': 38 }" *ngIf="demoPage>0"></svg-icon>
        <svg-icon src="assets/images/banner/banner_right.svg" class="everleadDemoBanner__inner__next fingerCursor" (click)="demoPage=demoPage+1"
            [svgStyle]="{ 'width.px': 32, 'height.px': 38 }" *ngIf="demoPage<2"></svg-icon>
        <div class="everleadDemoBanner__header">
            <label>{{ demoPages[demoPage].header | texttransform }}</label>
            <i class="fas fa-times bannerClose fa-lg fingerCursor" (click)="closeBanner('showBanner')"></i>
        </div>

        <div class="everleadDemoBanner__top">
            <div class="everleadDemoBanner__top__left">
                <img src="../../../../../assets/images/banner/everlead_page1_left.png"
                    class="everleadDemoBanner__top__left__p1" *ngIf="demoPage==0">
                <img src="../../../../../assets/images/banner/banner_page2_left.png" class="everleadDemoBanner__top__left__p2" *ngIf="demoPage==1">
                <img src="../../../../../assets/images/banner/banner_page3_left.png" class="everleadDemoBanner__top__left__p3" *ngIf="demoPage==2">
                <div *ngIf="demoPage==0" class="everleadDemoBannerVisitWebsite fingerCursor" (click)="visitWebsite()">
                    <i class="fas fa-external-link-alt"></i>
                    {{ 'DemoBanner.Label.OpenInBrowser' | axivastranslate }}
                </div>
            </div>
            <div class="everleadDemoBanner__top__right">
                <div class="everleadDemoBanner__top__right__text" [ngClass]="{
                    'everleadDemoBanner__top__right__text__p1': demoPage==0,
                    'everleadDemoBanner__top__right__text__p2': demoPage==1,
                    'everleadDemoBanner__top__right__text__p3': demoPage==2
                }">{{ demoPages[demoPage].smallText | texttransform }}</div>

                <svg-icon src="assets/images/banner/banner_page1_right.svg" [svgStyle]="{ 'width.px': 170, 'height.px': 240 }" *ngIf="demoPage==0"></svg-icon>
                <svg-icon src="assets/images/banner/banner_page2_right.svg" [svgStyle]="{ 'width.px': 170, 'height.px': 240 }" *ngIf="demoPage==1"></svg-icon>
                <svg-icon src="assets/images/banner/banner_page3_right.svg" [svgStyle]="{ 'width.px': 170, 'height.px': 240 }" *ngIf="demoPage==2"></svg-icon>
            </div>
        </div>

        <div class="everleadDemoBanner__dots">
            <i class="far fa-circle" *ngIf="demoPage!=0" (click)="demoPage=0"></i>
            <i class="fas fa-circle everleadCoral" *ngIf="demoPage==0"></i>
            <i class="far fa-circle" *ngIf="demoPage!=1" (click)="demoPage=1"></i>
            <i class="fas fa-circle everleadCoral" *ngIf="demoPage==1"></i>
            <i class="far fa-circle" *ngIf="demoPage!=2" (click)="demoPage=2"></i>
            <i class="fas fa-circle everleadCoral" *ngIf="demoPage==2"></i>
        </div>

        <div class="everleadDemoBanner__bottom">
            <div>
                <label [innerHTML]="demoPages[demoPage].bottom | texttransform"></label>
            </div>
            <div *ngIf="demoPages[demoPage].bottom2 != ''">
                <label [innerHTML]="demoPages[demoPage].bottom2 | texttransform"></label>
            </div>
            <div *ngIf="demoPage==0" class="everleadDemoBanner__bottom__buttons ">
                <button class="everleadCustomButton everleadDemoBannerButton1 visitWebsite" (click)="visitWebsite()">{{ 'DemoBanner.Label.Button1Text' | axivastranslate }}</button>
            </div>
            <div *ngIf="demoPage==2" class="everleadDemoBanner__bottom__buttons">
                <button class="everleadCustomButton " (click)="visitContact()">{{ 'DemoBanner.Label.Button2Text' | axivastranslate }}</button>
                <button class="everleadCustomButton " (click)="visitPlanner()">{{ 'DemoBanner.Label.Button3Text' | axivastranslate }}</button>
            </div>
        </div>
    </div>
</div>














<div *ngIf="applicationInfoService.awsSilentRenewError" class="awsErrorBanner everleadMagentaBackgroundColor">
    <div class="bannerInner">
        <div class="row maxWidth">
            <div class="col-md-10">
                <label class="bannerLabel bannerHeaderLabel">{{ 'AWSRefreshError.Label.Header' | texttransform }}</label>
            </div>
            <div class="col-md-2">
                <i class="fas fa-times bannerClose fingerCursor" (click)="closeBanner('awsSilentRenewError')"></i>
            </div>
            <div class="col-md-10">
                <!-- <label class="bannerLabel bannerHeaderLabel">Erleben Sie EVERLEAD in Aktion:</label> -->
                <label class="bannerLabel">{{ 'AWSRefreshError.Label.LoginMessage' | texttransform }}</label>
            </div>
            <div class="col-md-2 bannerInner__navigation">
                <button class="bannerInner__button" (click)="login()">{{ 'AWSRefreshError.Label.Login' | texttransform }}</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showVipNotActivated" class="showVipNotActivatedBanner everleadMagentaBackgroundColor">
    <div class="showVipNotActivatedBanner__inner">
        <div class="showVipNotActivated__inner__header">
            <div>
                {{ 'Supervisor.Label.ManageVip' | texttransform }}
            </div>
            <div class="everleadDemoBanner__item__close">
                <i class="fas fa-times bannerClose fa-2x fingerCursor" (click)="showVipNotActivated = false"></i>
            </div>
        </div>
        <div class="showVipNotActivatedBanner__inner__description">
            {{ 'VipNotActivatedBanner.Label.Description' | texttransform }}
        </div>
        <div class="showVipNotActivatedBanner__inner__select">
            <div class="showVipNotActivatedBanner__inner__select__label">
                {{ 'UserSettings.Label.VipAlertFrequency' | texttransform }}
            </div>
            <div class="showVipNotActivatedBanner__inner__select__interaction" *ngIf="settingsService.userSettings['VipAlertFrequency']">
                <select [(ngModel)]="settingsService.userSettings['VipAlertFrequency'].value"
                    (change)="settingsService.setUserSettingEx(settingsService.userSettings['VipAlertFrequency'])">
                    <option value="none">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.None' | texttransform }}</option>
                    <option value="direct">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Direct' | texttransform }}</option>
                    <option value="daily">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Daily' | texttransform }}</option>
                    <option value="weekly">{{ 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly' | texttransform }}</option>
                </select>
            </div>
        </div>
    </div>
</div>
