<span id="uiControlDesignForm"></span>
<span id="uiDesignSpan"></span>
<div>
    <!-- For Supervisors / user -->
    <!-- <h6>Label</h6>
    <div class="uiControlAdminSection">
        <label class="uiControlAdminLabel">Label-Position, Breite, Höhe</label>
        <select [(ngModel)]="currentControlToModify.labelPos" class="uiControlAdminSelect">
            <option [ngValue]=position.id *ngFor="let position of positions">{{ position.name }}</option>
        </select>
        <input type="text" [(ngModel)]="currentControlToModify.displayTextWidth" class="uiControlAdminTextboxShort">
        <input type="text" [(ngModel)]="currentControlToModify.displayTextHeight" class="uiControlAdminTextboxShort">
        <br>
        <label class="uiControlAdminLabel">Beschriftung</label>
        <input type="text" [(ngModel)]="currentControlToModify.displayText" class="uiControlAdminTextboxLong">
    </div>
    <br>

    <h6>{{ 'UiControlDesign.Label.Tooltip' | texttransform}}</h6>
    <textarea [(ngModel)]="currentControlToModify.tooltip" class="uiControlDesignTextArea inline"
        style="margin-bottom: 5px;"></textarea> -->

    <!-- LookupTable -->
    <div *ngIf="lookupTable != null && lookupTable?.lookupTableSource != 'main'">
        <app-controls-design-lookup-table [controlDefinition]=currentControlToModify></app-controls-design-lookup-table>
    </div>    

    <!-- Subcontrols -->
    <h6>Listendefinition</h6>
    <div *ngIf="currentControlToModify.controlType == 16 && currentControlToModify.uiEntityInstanceId != null">
        <div style=" width: 30%; display: inline-block; vertical-align: top; padding-right: 15px;">
            <!-- Available Fields -->

            <label
                class="uiControlSubControlHeaderLabel">{{ 'UiControlDesign.Label.AvailableEntities' | texttransform }}</label>
            <select style="width: 100%; margin-bottom: 4px;" [(ngModel)]="selectedEntity">
                <option *ngFor="let availableField of specialArray[0]" [ngValue]=availableField>
                    {{ availableField.entity.entityName }}</option>
            </select>
            <div *ngIf="selectedEntity != null">
                <div class="uiControlItemAvailableField" *ngFor="let item of selectedEntity.entity.entityMembers"
                    [ngClass]="{ 
                hideInDom: checkIfMemberAlreadyInList(item) }">
                    <label class="uiControlItemAvailableLabel ellipsisText" *ngIf="item.nameTranslationToken==null"
                        [tippy]="item.defaultName">{{ item.defaultName }}</label>
                    <label class="uiControlItemAvailableLabel ellipsisText" *ngIf="item.nameTranslationToken!=null"
                        [tippy]="item.nameTranslationToken.tokenFullName | texttransform">{{ item.nameTranslationToken.tokenFullName | texttransform }}</label>
                    <i class="fas fa-arrow-alt-circle-right floatRight fingerCursor"
                        style="margin-right: 5px; margin-top: 7px;" (click)="addToCompactListSubControls(item)"></i>
                </div>
            </div>
            <label
                class="uiControlSubControlHeaderLabel">{{ 'UiControlDesign.Label.Misc' | texttransform }}</label>
            <div class="uiControlItemAvailableField">
                <label class="uiControlItemAvailableLabel ellipsisText" 
                    [tippy]="'UiControlDesign.Label.EmptyColumn' | texttransform">{{ 'UiControlDesign.Label.EmptyColumn' | texttransform }}</label>
                <i class="fas fa-arrow-alt-circle-right floatRight fingerCursor"
                    style="margin-right: 5px; margin-top: 7px;" (click)="addEmptyItemToCompactListSubControls()"></i>
            </div>
        </div>        

        <div style=" width: 70%; display: inline-block; vertical-align: top;">
            <!-- Already in list -->
            <label
                class="uiControlSubControlHeaderLabel">{{ 'UiControlDesign.Label.AlreadyInSubCrontrolList' | texttransform }}</label>
            <div class="inline dragColumnDiv jjDragDropItemMaxWidth" cdkDropList
                [cdkDropListData]="currentControlToModify.childs"
                (cdkDropListDropped)="dragdropService.moveInList($event, '0')">
                <div class="jjDragDropItemList jjDragDropItemMaxWidth">
                    <div class="jjDragDropItemBox jjDragDropItemLeftBorderNormal maxHeight"
                        *ngFor="let item of currentControlToModify.childs; let i = index" cdkDrag [cdkDragData]="item"
                        data-html="true" [ngClass]="{ 
                        jjDragDropDisabledColor: item.isActive==false ,
                        jjDragDropBorderLeftNewItem: item.id==null,
                        jjDragDropNoPaddingAndMargin: item.isActive==false && showOnlyActive
                    }">
                        <div>
                            <input type="checkbox" [(ngModel)]="item.isActive" style="margin: 3px 5px 0px 3px; float:left">
                            <i class="fas fa-trash fingerCursor"
                                style="margin-right: 5px; margin-top: 3px; color: red; float: left"
                                (click)="removeFromCompactListSubControls(item)"></i>
                            <label style="margin: 0px; padding: 0px; width: 50%;"
                                *ngIf="item.nameTranslationToken==null" class="ellipsisText"
                                [tippy]="item.displayText | texttransform">{{ item.displayText | texttransform }}</label>
                            <i class="fas fa-grip-lines floatRight" style="margin-right: 5px;"></i>
                        </div>
                        <div>
                            <label style="margin: 0px; padding: 0px; width: 40%; display: inline-block;"
                                class="ellipsisText"
                                [tippy]="'UiControlDesign.Label.ColumnWidthDescription' | texttransform">Anzeigename /
                                Breite</label>
                            <input type="text" [(ngModel)]="item.displayText" float
                                class="uiDesignSubControlDescriptionTextbox">
                            <app-translation-button type='nameTranslationToken' [source]=translationSourceSubControl
                                [target]=item></app-translation-button>
                            <app-translation-button type='descriptionTranslationToken'
                                [source]=translationSourceSubControl [target]=item></app-translation-button>

                            <input type="text" [(ngModel)]="item.width"
                                [tippy]="'UiControlDesign.Label.ColumnWidthDescription' | texttransform"
                                style="margin-left: 5px; width: 10%; display: inline-block;">
                        </div>
                        <div>
                            <label style="margin: 0px; padding: 0px; width: 40%; display: inline-block;"
                                class="ellipsisText">Filterbar</label>
                            <input type="checkbox" [(ngModel)]="item.isFieldFilterable"
                                style="margin-right: 5px; display: inline-block;">
                        </div>
                        <div>
                            <label style="margin: 0px; padding: 0px; width: 40%; display: inline-block;"
                                class="ellipsisText">Type</label>
                            <select [(ngModel)]="item.childs[0].controlType">
                                <option [ngValue]=compactListSubControlType.id
                                    *ngFor="let compactListSubControlType of compactListSubControlTypes">
                                    {{ compactListSubControlType.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    <app-uicontrol-design-attribute-groups [currentControlToModify]=currentControlToModify></app-uicontrol-design-attribute-groups>
</div>