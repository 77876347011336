<div>


<span *ngIf="optInOptOutWorkflow!= null">
    {{ 'OptInOptOut.Label.BookOptInOptOutHeader' | texttransform }}<br>
    <br>
</span>
<div [innerHTML]="optInOptOutInnerHtml" class="zeroIndex optinOptOutList"></div>
<br>
<button *ngIf="!editMode" class="jjButtonColor jjButtonStyle fingerCursor createNewOptinButton" (click)="createNew()">
    {{ 'OptInOptOut.Label.CreateNew' | texttransform }}
</button>
<span *ngIf="editMode">
    <div [innerHTML]="optInOptOutModifyInnerHtml" class="zeroIndex optinOptOutList"></div>
    <br>
    <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="modifyOpt()" style="margin-right: 10px;">
        {{ 'General._.Save' | texttransform }}
    </button>
    <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="cancelCreation()"
        style="margin-right: 10px;">
        {{ 'General._.Cancel' | texttransform }}
    </button>
</span>

<span *ngIf="optInOptOutWorkflow!= null">
    <hr>
    <span *ngIf="!bookingEnabled">
        {{ 'OptInOptOut.Label.BookOptInOptOutDescription' | texttransform }}<br>
        <br>
        <div class="optInOptOutRequiredOptLabel" *ngIf="optInOptOutWorkflow.isConsentRequired">
            {{ 'LookupTable.OptInType.OptOut' | texttransform }}
        </div>
        <div class="optInOptOutRequiredOptLabel" *ngIf="optInOptOutWorkflow.isOptOut">
            {{ 'LookupTable.OptInType.OptOut' | texttransform }}
        </div>
        <div class="optInOptOutRequiredOptLabel" *ngIf="optInOptOutWorkflow.isOptIn">
            {{ 'LookupTable.OptInType.OptIn' | texttransform }}
        </div>
        <br>
    </span>
    <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="bookTask()" [disabled]=!bookingEnabled>
        {{ 'OptInOptOut.Label.BookTask' | texttransform }}
    </button>
</span>

</div>