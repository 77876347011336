<div cdkDropListGroup id="dropListGroup" class="controlInlineBlock">
    <p-panel [ngStyle]="setControlStyle('dragpanel')" header="{{ 'Moco.Label.AvailableProjects' | texttransform }}"
      class="inline dragColumnDiv" cdkDropList [cdkDropListData]="dragdropElements[0].itemsUI"
      (cdkDropListDropped)="drop($event, '0', dragdropElements)">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon dragDropfilterInfo"><i class="fas fa-filter"></i></span>
        <input type="text" pInputText class="dragDropfilterInput" [(ngModel)]="dragdropElements[0].filterString"
          (input)="dragdropService.filterArray('0', dragdropElements)" />
        <span id="sort2" class="ui-inputgroup-addon dragDropfilterInfo fingerCursor"
          (click)="dragdropService.sortItems(0, 'userName', dragdropElements)"><i
            class="fas fa-sort-alpha-down"></i></span>
      </div>
      <div class="dragDropitem-list">
        <div class="dragDropitem-box maxHeight" *ngFor="let item of dragdropElements[0].itemsUI" cdkDrag
          [cdkDragData]="item">
          <div>{{item.userName}}</div>
          <i class="fas fa-grip-lines floatRight"></i>
        </div>

      </div>
    </p-panel>

    <p-panel [ngStyle]="setControlStyle('dragpanel')" header="{{ 'Moco.Label.AssignedProjects' | texttransform }}"
      class="inline dragColumnDiv" cdkDropList [cdkDropListData]="dragdropElements[1].itemsUI"
      (cdkDropListDropped)="drop($event, '1', dragdropElements)">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon dragDropfilterInfo"><i class="fas fa-filter"></i></span>
        <input type="text" pInputText class="dragDropfilterInput" [(ngModel)]="dragdropElements[1].filterString"
          (input)="dragdropService.filterArray('1', dragdropElements)" />
        <span id="sort1" class="ui-inputgroup-addon dragDropfilterInfo fingerCursor"
          (click)="dragdropService.sortItems(1, 'userName', dragdropElements)">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
      </div>
      <div class="dragDropitem-list">
        <div class="dragDropitem-box maxHeight overflowy" *ngFor="let item of dragdropElements[1].itemsUI" cdkDrag
         
          [cdkDragData]="item">
          <div>{{item.userName}}</div>
          <i class="fas fa-grip-lines floatRight"></i>
        </div>
      </div>
    </p-panel>
  </div>

  {{ campaignId }}