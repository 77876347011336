import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-uicontrol-design-attributemodifier',
  templateUrl: './uicontrol-design-attributemodifier.component.html',
  styleUrls: ['./uicontrol-design-attributemodifier.component.scss']
})
export class UicontrolDesignAttributemodifierComponent implements OnInit {
  @Input() currentControlToModify = null;
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
  }

  deleteAttributeModifier(modifier) {
    this.externaldatasourceService.executeExternalDataSource(371, [modifier.id])
    .then(deleteAttributeModifierResult => {
      this.commonService.removeItemFromArray(this.currentControlToModify.attributeModifiers, modifier);
    });
  }

  updateAttributeModifier(modifier) {
    this.externaldatasourceService.executeExternalDataSource(370, [
      this.commonService.getModifyArrayBody(modifier, [])
    ])
  }  
  
  createAttributeModifier() {
    const modifier = new Object();
    modifier['attribute'] = 'visible';
    modifier['controlId'] = this.currentControlToModify.id;
    modifier['order'] = 0;
    modifier['compareValue'] = '1';
    modifier['refValue'] = '1';
    modifier['compareValueType'] = 2;
    modifier['compareType'] = '=';
    modifier['newValue'] = 'false';
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(369, [
      this.commonService.getModifyArrayBody(modifier, [])
    ]) 
    .then(createAttributeModifierResult => {
      modifier['id'] = createAttributeModifierResult.id;
      this.currentControlToModify.attributeModifiers.push(modifier);
      this.loaderService.display(false);
    }).catch(error => { this.loaderService.display(false); });
  }

  toNumber(value) {
    return Number(value);
  }
}
