<div class="flowchart"
[style.height]="getHeight()"
[style.width]="getWidth()">
    <div class="flowChartNav">
        <div class="tooltip__info" *ngIf="showInfo == true && toolboxInfo == true" (click)="getToolBox('info')">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#262626" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
            <p><strong>{{ 'ProcessVisual.Label.Datazoom' | texttransform}} </strong> {{ 'ProcessVisual.Label.DatazoomDesc' | texttransform}}</p> 
            <p><strong>{{ 'ProcessVisual.Label.Grab' | texttransform }}</strong> {{ 'ProcessVisual.Label.GrabDesc' | texttransform}}</p>
        </div>
        <div class="flowChartNav__toolbox">
            <button *ngIf="toolboxInfo" (click)="getToolBox('info')">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M144,16H112A64.07,64.07,0,0,0,48,80v96a64.07,64.07,0,0,0,64,64h32a64.07,64.07,0,0,0,64-64V80A64.07,64.07,0,0,0,144,16Zm48,160a48.05,48.05,0,0,1-48,48H112a48.05,48.05,0,0,1-48-48V80a48.05,48.05,0,0,1,48-48h32a48.05,48.05,0,0,1,48,48ZM136,64v48a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Z"></path></svg>
            </button>
<!--             <button *ngIf="toolboxEdit" (click)="getToolBox('edit')">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,58.34l-32-32a8,8,0,0,0-11.32,0l-96,96A8,8,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8,8,0,0,0,5.66-2.34l96-96A8,8,0,0,0,229.66,58.34ZM124.69,152H104V131.31l64-64L188.69,88ZM200,76.69,179.31,56,192,43.31,212.69,64ZM224,120v88a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h88a8,8,0,0,1,0,16H48V208H208V120a8,8,0,0,1,16,0Z"></path></svg>
            </button> -->
            <button *ngIf="toolboxExpand" (click)="getToolBox('expand')">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M216,48V96a8,8,0,0,1-16,0V67.31l-42.34,42.35a8,8,0,0,1-11.32-11.32L188.69,56H160a8,8,0,0,1,0-16h48A8,8,0,0,1,216,48ZM98.34,146.34,56,188.69V160a8,8,0,0,0-16,0v48a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16H67.31l42.35-42.34a8,8,0,0,0-11.32-11.32ZM208,152a8,8,0,0,0-8,8v28.69l-42.34-42.35a8,8,0,0,0-11.32,11.32L188.69,200H160a8,8,0,0,0,0,16h48a8,8,0,0,0,8-8V160A8,8,0,0,0,208,152ZM67.31,56H96a8,8,0,0,0,0-16H48a8,8,0,0,0-8,8V96a8,8,0,0,0,16,0V67.31l42.34,42.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
            </button>
        </div>
    </div>
<div echarts 
    class="flowChart"
    [id]="controlid"
    [options]="options"
    [merge]="updateOptions"
    (chartClick)="onChartClick($event)"
    (chartInit)="onChartInit($event)"
    [style.height]="getHeight()"
    [style.width]="getWidth()"
></div>
</div>
