import { EventEmitter, Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

export enum WizardMode {
  Campaign = 1,
  Process = 2,
  Step = 3,
  Result = 4,
  Leadreport = 5,
  LeadState = 6,
  DataRightGroup = 7,
  Translation = 8,
  EMailContent = 9,
  EMailTemplate = 10,
  EntityMember = 11,
  Entity = 12,
  DataImport = 13,
  Moco = 14,
  EventAssignment = 15,
  EMailSettings = 16,
  Mailshot = 17,
  MailToContact = 18,
  EventTrigger = 19,
  MailshotFinal = 20,
  SMTPContent = 21,
  BlackListImport = 22,
  VoiceTemplate = 23,
  MicroCampaignBatch = 24
}

export enum WizardType {
  Create = 1,
  Update = 2
}
@Injectable({
  providedIn: 'root'
})

export class WizardService {
  public wizardFinishedEvent: EventEmitter<any> = new EventEmitter<any>();
  expertMode = false;
  processExpertMode = false;
  translationProjectSpecific = false;
  exclude = [];
  helperId = null;
  wizardWidth;
  wizardHeight;
  wizardMode: WizardMode = null;
  wizardArray = null;
  wizardPage = 1;
  nextDisabled = false;
  canSave = false;
  pages = [];
  showTranslationEditor = false;
  additionalInformation = '';
  visitedPages = [];
  wizardActive = false;
  wizardPageList = [];
  pageInfo = [];
  modeInfo = [];
  toolbarDisabled = false;

  // variablle to hide template
  fromComponent = "";

  // Translation
  translationExclude = null;
  translationArray = null;
  translationArrayBase = null;
  translationArrayBaseUpdateExternalDataSourceId = 0;
  translationIds = [];
  headerText = 'Header';
  wizardZAxis = 0;
  showOptionalPages = false;
  translationDefaultValue = 'defaultName';

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private userService: UserService,
    private eventService: EventService,
    private sharedAPI: SharedAPI,
    private refreshValueService: RefreshValueService,
    private messagingService: MessagingService,
    private packagefeatureService: PackagefeatureService,
  ) {     
    // this.fillPagesAndModes();
  }

  showWizardData(wizardMode: WizardMode, arrayToShow, width = '80vw', height = '70vh') {
    if (this.wizardActive == true) {
      return;
    }
    this.toolbarDisabled = false;
    this.wizardActive = true;
    this.wizardMode = wizardMode;
    this.helperId = null;
    this.wizardArray = arrayToShow;
    this.wizardPage = 1;    
    this.wizardWidth = width;
    this.wizardHeight = height;
    if (this.applicationInfoService.isMobile()) {
      this.wizardWidth = '100vw';
      this.wizardHeight = '100vh';
    }
    this.wizardZAxis = 100;
    this.nextDisabled = false;
    this.initPages();
    this.applicationInfoService.miscSettings['popupFullSize'] = true;
    let cancelEnabled = true;   
    this.eventService.showJjPopup('WizardHeader.Label.Section'.concat(wizardMode.toString()), 'wizard', '80', cancelEnabled);
    this.canSave = this.checkIfArrayCanBeSaved();
    this.getVisitedPages(wizardMode);
    
  }

  fillPagesAndModes(): Promise<any> {
    return new Promise((fillPagesAndModesResolve, fillPagesAndModesReject) => {
      if (this.modeInfo.length > 0) {
        fillPagesAndModesResolve(null);
        return;
      }
      this.externaldatasourceService.executeExternalDataSource(622)
      .then(modes => {
        this.modeInfo = modes;
        this.externaldatasourceService.executeExternalDataSource(623).then(pages => {
          this.pageInfo == pages;
          modes.forEach(mode => {            
            const modePages = pages.filter(page => page.wizardMode == mode.modeId );
            modePages.sort((a, b) => a.order < b.order ? -1 : 1);
            this.wizardPageList[mode.modeId] = modePages;
          });
          fillPagesAndModesResolve(null);
        })
      }).catch(error => { fillPagesAndModesReject(error) });
    });
  }

  showWizard(wizardMode: WizardMode, arrayToShow, width = '80vw', height = '70vh',) {
    if (this.pageInfo.length == 0) {
      this.fillPagesAndModes().then(() => { this.showWizardData(wizardMode, arrayToShow, width, height); });
    } else {
      this.showWizardData(wizardMode, arrayToShow, width, height);
    }  
  }

  getVisitedPages(wizardMode) {
    if (!this.commonService.isNullOrUndefined(this.wizardArray.id)) {
      this.externaldatasourceService.executeExternalDataSource(611, [wizardMode, this.wizardArray.id])
      .then(getVisitedPagesResult => {
        this.visitedPages = getVisitedPagesResult;
        this.addCurrentPageToVisitedPages();
      });
    }
  }

  checkIfArrayCanBeSaved() {
    let returnValue = false;    
    switch (this.wizardMode) {
      case WizardMode.Campaign:
        if (
          this.wizardArray.defaultName != '' &&
          this.userHasPermission('Campaign')
        ) {
          returnValue = true;
        }
        break;

      case WizardMode.Leadreport:
        returnValue = true;  
        break;

      case WizardMode.EventAssignment:
        if (
          this.wizardArray.subUrl != null &&
          this.wizardArray.eventType != null
        ) { returnValue = true; }
        break;

      case WizardMode.Moco:
        if (
          this.wizardArray.mocoProjectTaskId != null &&
          this.wizardArray.mocoProjectId != null &&
          this.wizardArray.typeLookupId != null 
        ) { returnValue = true; }
        
        break;

      case WizardMode.DataRightGroup:
        if (this.wizardArray.defaultName != '') { returnValue = true; }
        break;

      case WizardMode.VoiceTemplate:
        if (this.wizardArray.defaultName != '') { returnValue = true; }
        break;
  
      case WizardMode.EMailContent:
        if (this.wizardArray.defaultName != '') { returnValue = true; }
        break;

      case WizardMode.EMailTemplate:
        if (this.wizardArray.defaultName != '') {
          returnValue = true;
        }
        break;
  
      case WizardMode.LeadState:
        if (
          this.wizardArray.lookupTable.defaultName != '' &&
          this.userHasPermission('LookupLeadStatus')
        ) {
          returnValue = true;
        }         
        break;
  
      case WizardMode.Process:
        if (
          this.wizardArray.defaultName != '' &&
          this.userHasPermission('Workflow')
        ) {
          returnValue = true;
        }         
        break;    
      
      case WizardMode.EntityMember:
        if (this.wizardArray.defaultName != '' && this.wizardArray.defaultName.length > 2 && this.wizardArray.forbiddenValue == false) { returnValue = true; }   
        break;

      case WizardMode.Step:
        if (
          this.wizardArray.defaultName != '' &&
          !this.commonService.isNullOrUndefined(this.wizardArray.typeLookupId)
        ) {
          returnValue = true;
        }   
        break;

      case WizardMode.Result:
        if (
          this.wizardArray.defaultName != ''
        ) {
          returnValue = true;
        }   
        break;
      
      case WizardMode.SMTPContent:
        if (
          this.wizardArray.defaultName != '' &&
          this.wizardArray.content != '' &&
          this.wizardPage == 2
        ) {
          returnValue = true;
        }  
        break;
      
      case WizardMode.Mailshot:
        returnValue = true;
        break;
    }    
    return returnValue;
  }

  userHasPermission(requiredPermission) {
    if (this.commonService.isNullOrUndefined(this.wizardArray.id)) {
      return this.userService.hasPermission('CanCreate' + requiredPermission);
    } else {
      return this.userService.hasPermission('CanUpdate' + requiredPermission);
    }
  }

  checkIfFeatureIsActive(featureName:string){
    const featureId = Number(this.applicationInfoService.applicationSettings[featureName]);
    return this.packagefeatureService.selectedPackageHasFeature(featureId)
  }

  initPages() {
    this.pages = [];
    this.additionalInformation = '';    
    this.pages = JSON.parse(JSON.stringify(this.wizardPageList[this.wizardMode]));

    switch (this.wizardMode) {
      case WizardMode.Result:
        if (this.applicationInfoService.isDeveloper) {
          this.pages.push({ name: 'StepResultDesign.Label.Page8', optional: true, pageId: 8 });   
        } 
        if(!this.checkIfFeatureIsActive('featureId_emailtemplate')){
          this.pages = this.pages.filter((item) => item.pageId !== 2)
        }
        break;
      case WizardMode.EventAssignment:
        this.toolbarDisabled = true;
    }    
  }

  setAdditionalInfo(additionalInformation) {
    this.additionalInformation = additionalInformation;
  }

  saveWizardItem() {
    const promiseArray: Promise<any>[] = [];
    let addEntityMemberToDefinition = false;
    let externalDataSourceId = 0;
    let eventItem = null;
    let enumList = [];
    let filterList = [];

    const selectedMode = this.modeInfo.find(item => item.modeId == this.wizardMode);
    if (this.wizardArray.id == null || this.wizardArray.id == undefined) {
      if (selectedMode.createFilter) { filterList = selectedMode.createFilter.split(';'); }
      externalDataSourceId = selectedMode.createId;
      eventItem = { wizard: this.wizardMode, type: WizardType.Create, item: this.wizardArray }
    } else {
      if (selectedMode.updateFilter) { filterList = selectedMode.updateFilter.split(';'); }
      externalDataSourceId = selectedMode.updateId;
      eventItem = { wizard: this.wizardMode, type: WizardType.Update, item: this.wizardArray }
    }

    switch (this.wizardMode) {
      case WizardMode.Process:
        console.warn('saveWizardItem', this.wizardArray);
        if (!this.commonService.isNullOrUndefined(this.wizardArray.selectedTemplateWorkflow)) {
          this.externaldatasourceService.executeExternalDataSource(890, [this.wizardArray.selectedTemplateWorkflow, this.wizardArray.defaultName])
          .then(() => {
            this.messagingService.showDefaultSuccess('CloneWorkflow.Message.Header', 'CloneWorkflow.Message.MessageSuccess');
            this.eventService.customEvent.emit({ id: 'refreshProcessVisual' });
            this.eventService.closeJjPopup();
          }).catch(() => {
            this.messagingService.showDefaultError('CloneWorkflow.Message.Header', 'CloneWorkflow.Message.MessageError');
            this.eventService.closeJjPopup();
          });
          this.externaldatasourceService.executeExternalDataSource(891, [this.wizardArray.id]);
          return;
        }              
        break;

      case WizardMode.Result:
        if (!this.applicationInfoService.isDeveloper) {
          filterList.push('preExecuteSql');
          filterList.push('postExecuteSql');
        }
        break;
      
      case WizardMode.VoiceTemplate:
        if (this.wizardArray.content == '') {
          filterList.push('content');
        }
        break;

      case WizardMode.EntityMember:
        enumList = ['memberType'];
        if (this.wizardArray.id == null || this.wizardArray.id == undefined) {           
          addEntityMemberToDefinition = true;
        }
        break;
      
      case WizardMode.LeadState:
        if (this.wizardArray.id == null || this.wizardArray.id == undefined) { 
          this.wizardArray.lookupTable['order'] = 0;
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(214, [            
            this.commonService.getModifyArrayBody(this.wizardArray.lookupTable, ['id', 'length', 'isActive'])])
            .then(result => {
              this.wizardArray.lookupTableId = result.id;
            })
          );
        } else {          
          promiseArray.push(this.externaldatasourceService.executeExternalDataSource(215, [this.commonService.getModifyArrayBody(this.wizardArray.lookupTable, ['length'])])
          .then(() => { }));
        }
        break;
      
      case WizardMode.SMTPContent:
        if (this.commonService.isNullOrUndefined(this.wizardArray.subject)) {
          this.wizardArray.subject = '';
        }
        break;
      
      case WizardMode.Mailshot:
        this.eventService.closeJjPopup();
        return;
    }    

    Promise.all(promiseArray)
    .then(() => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
        this.commonService.getModifyArrayBody(this.wizardArray, filterList, enumList)
      ])
      .then(wizardSaveItemResult => {
        switch (this.wizardMode) {
          case WizardMode.EMailContent:
            if (this.wizardArray.isPublished == false) {
              this.messagingService.showDefaultInfo('', 'EMailContent.Message.ContentNotPublished');
            }            
            break;
          case WizardMode.Process:
            this.refreshValueService.getWorkflows().then(() => { this.eventService.customEvent.emit({ id: 'updateProcessVisual' }); });            
            break;
          case WizardMode.Step:
            this.refreshValueService.getWorkflows().then(() => { this.eventService.customEvent.emit({ id: 'updateProcessVisual' }); });            
            break;
          case WizardMode.Result:
            this.refreshValueService.getWorkflows().then(() => { this.eventService.customEvent.emit({ id: 'updateProcessVisual' }); });            
            break;  
        }
        this.insertVisitedPages(wizardSaveItemResult.id);        
        this.loaderService.display(false);
        this.eventService.closeJjPopup();
        if (addEntityMemberToDefinition) {
          this.addEntityMemberToDefinition(wizardSaveItemResult.id, eventItem);
        } else {
          this.wizardFinishedEvent.emit(eventItem);
        }
      })
      .catch(error => {
        console.error(error);
        this.loaderService.display(false);
      });  
    })
  }

  showTranslations() {
    this.showTranslationEditor = true;
  }  

  createWorkflow(): Promise<any> {
    return new Promise((resolve, reject) => {
      let workflowObject = {
        projectId: this.applicationInfoService.projectID,
        defaultName: 'new workflow',
        isActive: true
      }
      this.externaldatasourceService.executeExternalDataSource(530, [
        this.commonService.getModifyArrayBody(workflowObject, [])
      ]).then(result => {
        workflowObject['id'] = result.id;
        workflowObject['workflowSteps'] = [];
        resolve(workflowObject);
      })
      .catch(error => { reject(error); });  
    });
  }

  createWorkflowStep(workflow, stepName): Promise<any> {
    return new Promise((resolve, reject) => {
      let workflowObject = {
        workflowId: workflow.id,
        defaultName: stepName,
        isActive: true
      }
      this.externaldatasourceService.executeExternalDataSource(567, [
        this.commonService.getModifyArrayBody(workflowObject, [])
      ]).then(result => {
        workflowObject['id'] = result.id;
        workflowObject['results'] = [];
        resolve(workflowObject);
      })
      .catch(error => { reject(error); });  
    });
  }

  createResult(step, resultName): Promise<any> {
    return new Promise((resolve, reject) => {
      let resultObject = {
        stepId: step.id,
        defaultName: resultName,
        isActive: true,
        isPersonalFollowup: false
      }
      this.externaldatasourceService.executeExternalDataSource(569, [
        this.commonService.getModifyArrayBody(resultObject, [])
      ]).then(result => {
        resultObject['id'] = result.id;
        resolve(resultObject);
      })
      .catch(error => { reject(error); });  
    });
  }

  addCurrentPageToVisitedPages() {
    const page = this.visitedPages.find(visitedPage => visitedPage.pageId == this.pages[this.wizardPage - 1].pageId);
    if (!page) {
      this.visitedPages.push({ 
        pageId: this.pages[this.wizardPage - 1].pageId,
        isNewEntry: true
      });
    }
  }

  insertVisitedPages(refId) {
    this.visitedPages.forEach(page => {
      if (page.isNewEntry == true) {
        this.externaldatasourceService.executeExternalDataSource(613, [
          page.pageId,
          refId,
          this.wizardMode
        ]);
      }
    });
  }

  wizardHasOptionalContent() {
    let returnValue = false;
    this.pages.forEach(page => {
      if (page.optional == true) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  getProgressOfWizardType(array, wizardType, visitedPages) {
    let returnValue = { visited: 0, max: 0 }
    if (this.wizardPageList[wizardType]) {
      this.wizardPageList[wizardType].forEach(pageListItem => {
        if (pageListItem.optional == false) {
          returnValue.max ++;
          const page = visitedPages.find(visitedPage => 
            visitedPage.refId == array.id && 
            visitedPage.wizardTypeId == wizardType &&
            visitedPage.pageId == pageListItem.pageId
          );
          if (page) {
            returnValue.visited ++;
          }
        }
      });
    }
    return returnValue;
  }

  addEntityMemberToDefinition(resultId, eventItem) {
    const promiseArray: Promise<any>[] = [];
    if (this.applicationInfoService.projectSpecificDefinitionId == null) {
      promiseArray.push(this.externaldatasourceService.executeExternalDataSource(200, ['automaticGenerated_'.concat(
        eventItem.item.entity.entityName, 
        eventItem.item.entity.id
      )])
      .then(executeExternalDataSource200Result => {
        this.applicationInfoService.projectSpecificDefinitionId = executeExternalDataSource200Result.id;
        this.externaldatasourceService.executeExternalDataSource(201, [eventItem.item.entity.id, executeExternalDataSource200Result.id]);
      }));
    }    
    console.warn(eventItem, eventItem.item);
    Promise.all(promiseArray).then(() => {      
      this.externaldatasourceService.executeExternalDataSource(172, [
        this.applicationInfoService.projectSpecificDefinitionId,
        resultId      
      ]).then(() => { 
        this.loaderService.display(true);
        this.refreshValueService.getProjectEntities().then(() => { 
          this.sharedAPI.updateDataSchema(this.applicationInfoService.projectID).subscribe(event => {
            this.loaderService.display(false);
            this.wizardFinishedEvent.emit(eventItem); 
          });                    
        });        
      });  
    });
  }
}
