<div class="projectSettings__header">
    <div class="projectSettings__header__newItem">
        <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
            (click)="addUiControlSecurityPermissions()" class="fingerCursor" [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
        {{ 'Designer.Label.NewUiSecurityPermission' | texttransform }}
    </div>
    <div class="projectSettings__header__interaction"></div>
</div>
<div class="uiSecurityPermisson__info">
    Der Name des benötigten Rechts setzt sich aus Can + das Zugriffsrecht + der Modulname zusammen, also z.B. CanReadPools
</div>
<div *ngFor="let permission of control.uiControlSecurityPermissions" class="everleadShadowBox uiSecurityPermisson">    
    <input [(ngModel)]="permission.securityPermission" placeholder="Benötigtes Recht" (change)="updatePermission(permission)">
    <select [(ngModel)]="permission.permissionType">
        <option ngValue="visible">visible</option>
        <option ngValue="enabled">enabled</option>
    </select>
    <i class="fas fa-trash everleadCoral fingerCursor" (click)="removeUiControlSecurityPermissions(permission)"></i>
</div>