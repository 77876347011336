import { Component, OnInit, Input } from '@angular/core';
import { ImportHelperService } from '../import-helper.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { isNullOrUndefined } from 'util';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-import-details',
  templateUrl: './import-details.component.html',
  styleUrls: ['./import-details.component.scss']
})
export class ImportDetailsComponent implements OnInit {
  @Input() importGuid = '';
  @Input() isSkipped = null;
  result = null;
  resultImported = [];
  resultImportedPagination = [];
  resultImportedPage = 1;
  resultImportedMaxPage = 1;
  resultNotImported = [];
  resultNotImportedPagination = [];
  resultNotImportedPage = 1;
  resultNotImportedMaxPage = 1;
  resultImportedCount = 0;
  resultNotImportedCount = 0;
  paginationItemsPerPage = 400;

  constructor(
    private importHelperService: ImportHelperService,
    private applicationInfoService: ApplicationInfoService,
    private sharedAPI: SharedAPI,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    if (!isNullOrUndefined(this.applicationInfoService.miscSettings['importDetailsGUID'])) {
      this.importGuid = this.applicationInfoService.miscSettings['importDetailsGUID'];
      this.applicationInfoService.miscSettings['importDetailsGUID'] = null;
    }
    this.getImportDetailsFromGuid(this.importGuid);
  }

  getImportDetailsFromGuid(guid: any) {
    let importIdentifierRow = 0;
    this.sharedAPI.getImportDetails(guid, this.applicationInfoService.projectID, this.isSkipped).subscribe(result => {
      this.result = result;
      result.headers.forEach((header, index) => {
        if (header.name === 'is_skipped') {
          importIdentifierRow = index;
        }
      });
      result.data.forEach(resultItem => {
        resultItem.forEach((subItem, index) => {
          if (subItem === 'NULL') {
            resultItem[index] = '';
          }
        });
        if (resultItem[importIdentifierRow] === 'f') {
          this.resultImported.push(resultItem);
        } else {
          this.resultNotImported.push(resultItem);
        }
      });
      this.resultImportedCount = this.resultImported.length;
      this.resultNotImportedCount = this.resultNotImported.length;
      this.getPaginationItems(this.resultImported, 1, 1);
      this.getPaginationItems(this.resultNotImported, 2, 1);
    });
  }

  getPaginationItems(source, targetArray, page) {
    const target = [];
    const start = (page - 1) * this.paginationItemsPerPage;
    const end = (page) * this.paginationItemsPerPage;
    for (let i = start; i < end; i++ ) {
      if (!isNullOrUndefined(source[i])) {
        target.push(source[i]);
      }
    }
    switch (targetArray) {
      case 1:
        this.resultImportedPagination = target;
        this.resultImportedMaxPage = Math.ceil(source.lentgh / this.paginationItemsPerPage);
        break;
      case 2:
        this.resultNotImportedPagination = target;
        this.resultNotImportedMaxPage = Math.ceil(source.lentgh / this.paginationItemsPerPage);
        break;
    }
  }

  checkPage(section, buttonType) {
    let returnValue = false;
    let page = this.resultImportedPage;
    let min = (this.resultImportedPage - 1) * this.paginationItemsPerPage;
    let max = (this.resultImportedPage) * this.paginationItemsPerPage;
    let items = this.resultImported.length;
    if (section === 2) {
      page = this.resultNotImportedPage;
      min = (this.resultNotImportedPage - 1) * this.paginationItemsPerPage;
      max = (this.resultNotImportedPage) * this.paginationItemsPerPage;
      items = this.resultNotImported.length;
    }

    switch (buttonType) {
      case 'up':
        if (max > items) {
          return true;
        }
        break;
      case 'down':
        if (page < 2) {
          returnValue = true;
        }
        break;
    }
    return returnValue;
  }

  pageDown(section) {
    switch (section) {
      case 1:
        this.resultImportedPage --;
        this.getPaginationItems(this.resultImported, 1, this.resultImportedPage);
        break;
      case 2:
        this.resultNotImportedPage --;
        this.getPaginationItems(this.resultNotImported, 2, this.resultNotImportedPage);
        break;
    }
  }

  pageUp(section) {
    console.warn('Page Up', section);
    switch (section) {
      case 1:
        this.resultImportedPage ++;
        this.getPaginationItems(this.resultImported, 1, this.resultImportedPage);
        break;
      case 2:
        this.resultNotImportedPage ++;
        this.getPaginationItems(this.resultNotImported, 2, this.resultNotImportedPage);
        break;
    }
  }

  getTableElementWidth() {
    return 100 / this.result.headers.length;
  }

  exportData(recordArray) {
    let body = `{ "headers": [<0>], "data": [<1>] }`;
    const headerArray = [];
    const valueArray = [];
    this.result.headers.forEach(header => {
        headerArray.push('{ "name": "' + header.name + '", "type": "string" }');
    });

    // Data
    const dataArray = [];
    recordArray.forEach(record => {
      let item = '';
      record.forEach(recordItem => {
        if (item !== '') {
          item = item.concat(', ');
        }
        item = item.concat('"', recordItem, '"');
      });
      dataArray.push('[ ' + item + ' ]');
    });

    body = body.replace('<0>', headerArray.toString());
    body = body.replace('<1>', dataArray.toString());

    this.sharedAPI.createFromPassedData(body).subscribe(createFromPassedDataResult => {
      this.commonService.downloadFile(createFromPassedDataResult,
        'application/' + 'xlsx',
        ''.concat(this.commonService.removeIllegalCharactersFromFileName(this.applicationInfoService.projectName), '_',
          'export', '_', this.commonService.getDateTimeString(new Date(Date.now())),
          '', '.xlsx'));
    });
  }
}
