export class improvedArray  {
    items: any[] = [];

    add(item: any) {
        this.items.push(item);
    }

    remove(item: any) {
        const result: any[] = [];
        this.items.forEach(element => {
            if (element !== item) {
                result.push(item);
            }
        });
        this.items = result;
    }

    list(): any[] {
        return this.items;
    }
}
