<p-table [value]="data"                 
scrollable="true" 
scrollHeight="600px"
class="listOverflow"
[columns]="headerList" 
[paginator]="true" 
paginatorPosition="both"
[rows]=getRowCount()
selectionMode="single"
[(selection)]="controlSelection">
    <ng-template pTemplate="caption">
        <div style="text-align: right">        
        <i class="fas fa-search" style="margin:4px 4px 0 0"></i>
        <input 
            type="text" 
            pInputText size="50" 
            placeholder="Filter" 
            (input)="dt.filterGlobal($event.target.value, 'contains')" style="width: 50%">
        </div>
    </ng-template>         

    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of headerList" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'height': col.height }">
                <label class="ellipsisText">{{ col.header | texttransform }}<p-sortIcon [field]="col.field" ariaLabel="" ariaLabelDesc="" ariaLabelAsc=""></p-sortIcon></label>                
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData"                             
            (click)="callMethod('onclick')">
            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'height': col.height }"> 
                <label class="ellipsisText">
                    <div *ngIf="scheme?.Item(col.field)=='Date'">{{ rowData | objectdeserialize:col.field | date: applicationInfoService.dateFormat }}</div>                                
                    <div *ngIf="scheme?.Item(col.field)=='DateTime'">{{ rowData | objectdeserialize:col.field | date: 'medium' : translateService.currentLang }}</div>                                
                    <div *ngIf="scheme?.Item(col.field)!='Date' && scheme?.Item(col.field) !='DateTime'">{{ rowData | objectdeserialize:col.field | texttransform }}</div>
                </label>
            </td>
        </tr> 
    </ng-template>    
</p-table>