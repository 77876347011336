<div [id]="radioGuid" style="width:100%; max-width: 100%" [ngClass]="{
    heightMax: !applicationInfoService.useNewDesign,
    heightNew: applicationInfoService.useNewDesign
}">
    <ul>
        <li  *ngFor="let selectItem of data; let i = index"
        
            [ngClass]="{extendedRadioMain__horizontal: controlDefinition.additionalSetting1 ==='horizontal',
            hideInDom: selectItem.isActive == false }">
            <input name="custom-radio" type="radio" [value]="selectItem['id']"  id="custom-radio-{{i}}" (change)="onControlValueChanged($event) " 
            [checked]="selectItem['id']==controlValue"
             [ngClass]="{
                extendedRadioMain__withLookupTable: entityMemberlookupTable !== null && userService.hasPermission('CanUpdateUIControl'),
                extendedRadioMain__borderNew: applicationInfoService.useNewDesign,
                extendedRadioMain__withLookupTableNew: entityMemberlookupTable !== null && userService.hasPermission('CanUpdateUIControl') && applicationInfoService.useNewDesign
            }" >
            <label for="custom-radio-{{i}}">{{selectItem['defaultName']}}</label>
           
            <div *ngFor="let arrayItem of valueArray">
                <span *ngIf="commonService.isDate(selectItem[arrayItem])">{{ selectItem[arrayItem] | date: medium }}</span>
            </div>   
        </li>
    </ul>

</div>