<div class="myLeads__wrapper">
  <label class="myLeadsHeaderLabel" *ngIf="!mainLevel?.nameTranslationToken">{{ mainLevel?.defaultName | texttransform }}</label>
  <label class="myLeadsHeaderLabel" *ngIf="mainLevel?.nameTranslationToken">{{ mainLevel?.nameTranslationToken.tokenFullName | texttransform }}</label>

  <div [ngClass]="{ hideInDom: selectedTabIndex != 1 }">

    <div class="jjControlMenuDivSpacer" [ngClass]="{ hideInDom: dataCollectionCollapsed==true }">
      <div class="jjControlSubmenuColor contentDivSpacer">
      </div>

      <div class="row maxWidth" *ngIf="controlSubMode != 3">
        <div class="col-md-2">
          <label class="myLeadsLayerLabel everleadCoral">{{ 'MyLeads.Label.MyLeadLevel' | texttransform
            }}</label>
        </div>
        <div class="col-md-10">
          <select [(ngModel)]="mainLevel" class="myLeadsLayerSelect" (change)="changeMyLeadsLevel()">
            <option [ngValue]="myLeadLevel" *ngFor="let myLeadLevel of myLeadLevels" [ngClass]="{
              hideInDom: myLeadLevel.id == 15
            }">
              {{ commonService.getNameTranslationOrDefaultName(myLeadLevel) | texttransform }}
            </option>
          </select>
          <i class="fas fa-copy customTextControlWrapper__button fingerCursor" (click)="newEntityLevel()"></i>
        </div>
      </div>

      <div class="jjControlMenuDivSpacer" [ngClass]="{ hideInDom: additionalFilterCollapsed==true }">
        <span *ngIf="mainLevel?.id == 1">
          <label style="width: 30%;">{{ 'MyLeads.Label.Campaign' | texttransform }}</label>
          <select [(ngModel)]="selectedCampaign" style="width: 60%">
            <option [ngValue]="0">{{ 'MyLeads.Label.AllCampaigns' | texttransform }}</option>
            <option [ngValue]="campaign.id" *ngFor="let campaign of campaigns">
              {{ campaign.defaultName | texttransform }}
            </option>
          </select>
          <br>
        </span>
      </div>
    </div>
  </div>

  <div>
    <div class="everleadShadowBox">  
      <div class="myLeadsFilterHeader__wrapper">
        <!-- <div *ngIf="applicationInfoService.myLeadsSelectedFilter != null" class="myLeadsFilterHeader">
          <i class="fas fa-filter"></i>
          <label>{{ applicationInfoService.myLeadsSelectedFilter.defaultName }}</label>
          <i class="fas fa-edit customTextControlWrapper__button fingerCursor" (click)="editDataFilter();$event.stopPropagation()"></i>
          <i class="fas fa-trash customTextControlWrapper__button fingerCursor"(click)="clearDataFilter();$event.stopPropagation()"></i>
          <i class="fas fingerCursor" (click)="filterExpanded = !filterExpanded" [ngClass]="{
            'fa-chevron-down': !filterExpanded,
            'fa-chevron-up': filterExpanded
          }"></i>  
        </div>    -->

        <div class="myLeadsFilterHeader">
          <i class="fas fa-filter"></i>
          <!-- <label class="fingerCursor">{{ 'MyLeads.Label.NoFilterSelected' | texttransform }}</label> -->
          <select [(ngModel)]="selectedFilterId" (change)="showDataFilter(selectedFilterId)" class="maxWidth">
            <option [ngValue]=null>{{ 'MyLeads.Label.NoFilterSelected' | texttransform }}</option>
            <option *ngFor="let filter of availableFilters" [ngValue]="filter.id">{{ filter.defaultName }}</option>
          </select>
          <i class="fas fa-edit customTextControlWrapper__button fingerCursor" (click)="editDataFilter();$event.stopPropagation()"></i>
          <i class="fas fa-trash customTextControlWrapper__button fingerCursor" (click)="clearDataFilter();$event.stopPropagation()"  *ngIf="applicationInfoService.myLeadsSelectedFilter != null"> </i>
          <i class="fas fingerCursor" (click)="filterExpanded = !filterExpanded" *ngIf="applicationInfoService.myLeadsSelectedFilter != null" [ngClass]="{
            'fa-chevron-down': !filterExpanded,
            'fa-chevron-up': filterExpanded
          }"></i>  
        </div>
      </div>
      <div>
        <div *ngIf="filterExpanded">
          <div *ngFor="let filterSegment of applicationInfoService?.myLeadsSelectedFilter?.filterSegments; let i = index">        
            <div *ngIf="i > 0" class="everleadShadowBox dataFilter__mainPanel__criteriaGroupSpacer">
                
            </div>
            <div >
              <app-datafilter-groupitem 
                [filterSegment]="filterSegment" 
                [filter]="selectedFilter"
                [filterSegmentOperators]="filterSegmentOperators"
                [memberEntities]="validEntities"
                disabled=true
                [operators]="filterSegmentMemberOperators"
              ></app-datafilter-groupitem>
            </div>
          </div>      
        </div>
      </div>    
    </div>
  </div>

  <div *ngIf="mainLevel?.descriptionTranslationToken" class="myLeadsSectionDescription">
    <div [innerHTML]="mainLevel?.descriptionTranslationToken?.tokenFullName | texttransform"></div>
  </div>

  <!-- Results -->
  <div [ngClass]="{ 'hideInDom': selectedTabIndex == 2 }">
    <div id="myLeadsResults" [ngClass]="{
  'jjControlDefaultColor': resultsCollapsed==false,
  'jjControlNotSelectedColor': resultsCollapsed==true,
  'jjControlMenuDivSpacer': resultsCollapsed==true
  }" class="jjControlMenuDiv fingerCursor" (click)="toggleItem('resultsCollapsed')" *ngIf="useMyLeadsV2 == false">
      <label class="fingerCursor" style="width: 60%;">{{ 'MyLeads.Label.FilterResults' | texttransform }}</label>
      <button class="fingerCursor" (click)="toggleItem('resultsCollapsed');$event.stopPropagation()"
        class="floatRight jjControlMenuButton" style="white-space: normal;">
        <i class="fas fa-chevron-down fingerCursor" *ngIf="resultsCollapsed==false"></i>
        <i class="fas fa-chevron-up fingerCursor" *ngIf="resultsCollapsed==true"></i>
      </button>
    </div>
    <div class="jjControlMenuDivSpacer" [ngClass]="{ 'hideInDom': resultsCollapsed == true }"
      *ngIf="useMyLeadsV2 == false">
      <div class="jjControlSubmenuColor contentDivSpacer" *ngIf="mainLevel!=null">
        <button (click)="refreshResults();$event.stopPropagation()" class="jjControlSubmenuButton"
          title="{{ 'MyLeads.Label.RefreshMyLeadResults' | texttransform }}"><i class="fas fa-sync"></i></button>

        <button (click)="modifySelectedResults();$event.stopPropagation()" class="jjControlSubmenuButton"
          [disabled]="lastSelectedRowsCount==0"
          title="{{ 'MyLeads.Label.ModifySelectedRecordsets' | texttransform }}"><i class="fas fa-forward"></i></button>
        <button (click)="exportData();$event.stopPropagation()" class="jjControlSubmenuButton"
          *ngIf="useMyLeadsV2 == false" title="{{ 'MyLeads.Label.ExportData' | texttransform }}"><i
            class="fas fa-file-excel"></i></button>
      </div>
      <div [innerHTML]="taskResultInnerHTML" class="zeroIndex" *ngIf="useMyLeadsV2 == false"></div>
      <label *ngIf="mainLevel!=null && useMyLeadsV2 == false" class="" style="width: 100%;">{{ resultBottomText
        }}</label>
    </div>
    <!-- <app-extended-button buttonText="Test" iconUrl="https://www.w3schools.com/tags/logo_w3s.gif" iconCss="fas fa-user"></app-extended-button> -->
  </div>

  <!-- Step 2 -->
  <div [ngClass]="{ hideInDom: selectedTabIndex != 2 }">
    <div id="myLeadsResults" [ngClass]="{
      'jjButtonColor': modifyPanelCollapsed==false,
      'jjControlNotSelectedColor': modifyPanelCollapsed==true,
      'jjControlMenuDivSpacer': modifyPanelCollapsed==true
      }" class="jjControlMenuDiv fingerCursor" (click)="toggleItem('modifyPanelCollapsed')">
      <label class="fingerCursor" style="width: 60%;">{{ 'MyLeads.Label.ModifySelectedRecordsets' | texttransform
        }}</label>
      <button (click)="toggleItem('modifyPanelCollapsed');$event.stopPropagation()"
        class="floatRight jjControlMenuButton" style="white-space: normal;">
        <i class="fas fa-chevron-down fingerCursor" *ngIf="modifyPanelCollapsed==false"></i>
        <i class="fas fa-chevron-up fingerCursor" *ngIf="modifyPanelCollapsed==true"></i>
      </button>
    </div>
    <div class="jjControlMenuDivSpacer" *ngIf="modifyPanelCollapsed==false">
      <div class="jjControlSubmenuColor contentDivSpacer" *ngIf="mainLevel!=null">
        <button (click)="backToSelection();$event.stopPropagation()" class="jjControlSubmenuButton"
          title="{{ 'MyLeads.Label.Back' | texttransform }}"><i class="fas fa-backward"></i></button>
      </div>
      <label>{{ 'MyLeads.Label.RecordsetsToBeModified' | texttransform }}</label>
      <div [innerHTML]="taskResultSummaryInnerHTML" class="zeroIndex" *ngIf="useMyLeadsV2 == false"></div>
      <label>{{ 'MyLeads.Label.ValuesThatCanBeModified' | texttransform }}</label>
      <span *ngIf="useMyLeadsV2 == false">
        <div [innerHTML]="taskModifyInnerHTML" class="zeroIndex" *ngIf="useMyLeadsV2 == false"></div>
        <br>
        <button (click)="launchMultiUpdate();$event.stopPropagation()" class="jjButtonColor jjButtonStyle"
          title="{{ 'MyLeads.Label.RefreshMyLeadResults' | texttransform }}">{{ 'MyLeads.Label.RefreshMyLeadResults' |
          texttransform }}</button>
      </span>

      <span *ngIf="useMyLeadsV2 == false">
        <span *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4">
          <br>
          <button (click)="createFollowUps();$event.stopPropagation()" class="jjButtonColor jjButtonStyle"
            title="{{ 'MyLeads.Label.CreateFollowUps' | texttransform }}">{{ 'MyLeads.Label.CreateFollowUps' |
            texttransform }}</button>
        </span>

        <span *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4">
          <br>
          <button (click)="createTasks();$event.stopPropagation()" class="jjButtonColor jjButtonStyle"
            title="{{ 'MyLeads.Label.CreateTasks' | texttransform }}">{{ 'MyLeads.Label.CreateTasks' | texttransform
            }}</button>
        </span>
      </span>
    </div>
  </div>










  <div *ngIf="useMyLeadsV2" class="maxHeight">
    <div [innerHTML]="taskResultInnerHTML" class="zeroIndex taskResultInnerHTML"></div>

    <div *ngIf="selectedTabIndex == 1" style="margin-top: 10px;">
      <label *ngIf="lastSelectedRowsCount > 0 && controlSubMode == 1">{{ 'MyLeads.Label.ValuesThatCanBeModified' | texttransform }}</label>
      <div [innerHTML]="taskModifyInnerHTML" class="zeroIndex " *ngIf="lastSelectedRowsCount > 0 && controlSubMode == 1"></div>
      <br>
      <div class="myLeadsButtonBar">
        <button (click)="launchMultiUpdate();$event.stopPropagation()" class="jjButtonColor jjButtonStyle" *ngIf="controlSubMode == 1"
          [disabled]="lastSelectedRowsCount == 0" title="{{ 'MyLeads.Label.RefreshMyLeadResults' | texttransform }}">{{
          'MyLeads.Label.RefreshMyLeadResults' | texttransform }}</button>
        <div *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4">
          <button (click)="createTasks(1);$event.stopPropagation()" *ngIf="controlSubMode == 2"
            class="jjButtonColor jjButtonStyle myLeadsBottomButton" [disabled]="lastSelectedRowsCount == 0"
            [title]="'MyLeads.Label.CreateTasks' | texttransform">{{ 'MyLeads.Label.CreateTasks' | texttransform
            }}</button>
        </div>

        <div *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4" class="maxWidth">          
          <button (click)="createEMailShot();$event.stopPropagation()" *ngIf="controlSubMode == 3"
            class="jjButtonColor jjButtonStyle myLeadsBottomButton"
            [disabled]="applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters']?.length < 1 || 
              applicationInfoService.matTableCustomFiltersUsed == true" [title]="'MyLeads.Label.CreateEmails' | texttransform"
            >
              {{ 'MyLeads.Label.CreateEMails' | texttransform}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>