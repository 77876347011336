<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="everleadSmallHeader" *ngIf="wizardService.wizardArray.id == null">{{'EventTrigger.Label.TriggerNotCreatedYet' | texttransform}} </div>
            <div class="wizardDetailItem noWizardBorder">
                <label class="noPadding wizardLabel">{{ wizardService.wizardArray.eventType }}</label>
                <label class="wizardInput">{{ wizardService.wizardArray.subUrl }}</label>
            </div>    

            <!-- <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.EventType' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.eventType" class="wizardInput" [disabled]="wizardService.wizardArray.id != null">
            </div>    
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.SubUrl' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.subUrl" class="wizardInput" [disabled]="wizardService.wizardArray.id != null">
            </div>  -->
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.RegExUrl' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.regexUrl" class="wizardInput">
            </div> 
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.RegExData' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.regexData" class="wizardInput">
            </div> 
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.HandlebarsjsPreExecute' | texttransform }}</label>
                <textarea [(ngModel)]="wizardService.wizardArray.handlebarsjsPreExecute" class="wizardInput"></textarea>
            </div> 
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.HandlebarsjsPostExecute' | texttransform }}</label>
                <textarea [(ngModel)]="wizardService.wizardArray.handlebarsjsPostExecute" class="wizardInput"></textarea>
            </div> 
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EventTrigger.Label.IsCreateNewTask' | texttransform }}</label>
                <div class="maxWidth">
                    <input [(ngModel)]="wizardService.wizardArray.isCreateNewTask" type="checkbox">
                </div>                
            </div> 

            <div>
                <br>
                <button class="jjButtonStyle jjButtonColor" (click)="createEventTrigger()" *ngIf="wizardService.wizardArray.id == null">
                    {{'EventTrigger.Label.CreateEventTrigger' | texttransform}}
                </button>
                <button class="jjButtonStyle jjButtonColor" (click)="updateEventTrigger()" *ngIf="wizardService.wizardArray.id != null">
                    {{'EventTrigger.Label.UpdateEventTrigger' | texttransform}}
                </button>    
            </div>    
        </div>            
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="maxHeight autoOverflowY">
                <div *ngFor="let eventTrigger of eventTriggers" class="everleadShadowBox">
                    <div class="d-flex maxWidth">
                        <div class="maxWidth everleadCoral">{{eventTrigger.eventType}}</div>
                        <div class="eventTriggerButtons">
                            <i class="fas fa-edit fingerCursor" (click)="editEventTrigger(eventTrigger)"></i>
                            <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteEventTrigger(eventTrigger)"></i>
                        </div>                        
                    </div>
                    <div class="maxWidth eventTriggerSubUrlLabel">{{eventTrigger.subUrl}}</div>
                    <div class="everleadSmallHeader">{{eventTrigger.regexUrl}}</div>
                    <div class="everleadSmallHeader">{{eventTrigger.regexData}}</div>
                </div>
            </div>
        </div>
    </div>
</div>