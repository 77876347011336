import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  filterPanelOpen = [];
  
  activityFilterOptions = [
    { defaultName: 'ActivityList.Label.SalesActivities', identifier: 'task', numRecords: 'numRecordsTask' },
    { defaultName: 'ActivityList.Label.Mail', identifier: 'mail', numRecords: 'numRecordsMail' },
    { defaultName: 'ActivityList.Label.Conversions', identifier: 'conversion', numRecords: 'numRecordsConversion' },
    { defaultName: 'ActivityList.Label.WebActivities', identifier: 'event', numRecords: 'numRecordsEvent' },
    { defaultName: 'ActivityList.Label.MailEvents', identifier: 'mailevent', numRecords: 'numRecordsMailevent' }
  ];
  activityFilterTypeBase = ['conversion','task','event', 'mailevent', 'mail'];
  activityFilterType = [];

  microcampaignFilterOptions = [
    { defaultName: 'MicroCampaign.Label.Agent', identifier: 'agent', numRecords: 'numRecordsAgent' },
    { defaultName: 'MicroCampaign.Label.System', identifier: 'system', numRecords: 'numRecordsSystem' },
    { defaultName: 'MicroCampaign.Label.Contact', identifier: 'contact', numRecords: 'numRecordsContact' }
  ];
  microcampaignFilterTypeBase = ['system', 'contact', 'agent'];
  microcampaignFilterType = [];

  constructor() {
    this.resetActivityFilter();
    this.resetMicrocampaignFilter();
  }

  resetActivityFilter() {
    this.activityFilterType = JSON.parse(JSON.stringify(this.activityFilterTypeBase));
  }

  resetMicrocampaignFilter() {
    this.microcampaignFilterType = JSON.parse(JSON.stringify(this.microcampaignFilterTypeBase));
  }

  clearMicrocampaignFilter() {
    this.microcampaignFilterType = [];
  }

  clearActivityFilter() {
    this.activityFilterType = [];
  }
}
