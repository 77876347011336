<!-- <img [src]="image" class="microCampaignPicture"> -->
<app-flowchart
[chartData]="result"
[controlid]="'microCampaignStarted'"
[initialTreeDepth]="3"
[treeWidth]="'80%'"
[treeHeight]="'70%'"
[height]="'100%'"
[width]="'100%'"
[backgroundColor]="'white'"
[top]="'20%'"
[toolboxInfo]="true"

></app-flowchart>
