import { Component, Input, OnInit } from '@angular/core';
import { WizardService } from '../../wizard.service';

@Component({
  selector: 'app-wizard-dataimport-datalist',
  templateUrl: './wizard-dataimport-datalist.component.html',
  styleUrls: ['./wizard-dataimport-datalist.component.scss']
})
export class WizardDataimportDatalistComponent implements OnInit {
  constructor(
    public wizardService: WizardService
  ) { }

  ngOnInit(): void {
  }

  getValidHeaders(headers) {
    return headers.filter(headerItem => headerItem.copy == null);
  }
}
