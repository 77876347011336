<label 
    *ngIf="controlDefinition?.uiEntityInstanceId == null"
    [innerHTML]="controlDefinition.displayText | texttransform" 
    [ngStyle]="setControlStyle('control')"
    [class]="getClasses()"
    (click)="callMethod('onclick')"
></label>
<span *ngIf="applicationInfoService.useNewDesign && controlDefinition.displayText != null && controlDefinition.displayText != '' && controlDefinition?.uiEntityInstanceId != null">
    <label class="labelHeader">
        {{ controlDefinition.displayText | texttransform }}
    </label>    
    <br>
</span>
<label 
    (click)="callMethod('onclick')"
    [id]="getControlMainId('label')"
    *ngIf="controlDefinition?.uiEntityInstanceId != null && this.controlDefinition.uiControlExternalDataSources != null"    
    style="padding: 0px; margin: 0px;"
    [ngStyle]="setControlStyle('control')"
    [class]="getClasses()"
    [style.backgroundColor]="checkBackgroundColorAttribute('label')"
    [style.color]="checkColorAttribute('label')"
    [tippy]="getEntityLabelText() | texttransform"
>
    {{ getEntityLabelText() | texttransform }}
</label>

<label 
    (click)="callMethod('onclick')"
    [id]="getControlMainId('label')"
    *ngIf="controlDefinition?.uiEntityInstanceId != null && this.controlDefinition.uiControlExternalDataSources == null"    
    style="padding: 0px; margin: 0px;"
    [ngStyle]="setControlStyle('control')"
    [class]="getClasses()"
    [style.backgroundColor]="checkBackgroundColorAttribute('label')"
    [style.color]="checkColorAttribute('label')"
    [tippy]="getControlValueAsTitle(controlValue) | texttransform"
>
    {{ controlValue | texttransform }}
</label>
