<div class="newVersionWrapper everleadCoralBackground" *ngIf="applicationInfoService.newVersionAvailable && showBanner">
    <div class="newVersionHeader">
        <div class="newVersionHeader__label">
            <div class="newVersionHeaderlabel">
                {{ 'NewVersionAvailable.Label.Header' | texttransform }}
            </div>            
            <div class="newVersionDescription">
                {{ 'NewVersionAvailable.Label.Description' | texttransform }}                
            </div>        
        </div>
        <div class="newVersionHeader__buttons">
            <button class="everleadCustomButton" (click)="reloadPage()">{{ 'NewVersionAvailable.Label.ReloadPage' | texttransform }}</button>
        </div>
    </div>    
</div>