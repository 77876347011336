import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import * as CryptoJS from 'crypto-js';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { environment } from 'environments/environment';
import { MessagingService } from './messaging.service';
import { InternalMethodType, MethodService } from './method.service';
import { Subscription, timer } from 'rxjs';
import { CommonService } from './common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})

export class RingcentralService {
  cacheAreas = [
    'All', // PhoneBlacklist + Projects
    'PhoneBlacklist',
    'Projects',
    'CompanyPhoneNumbers',
    // the following will effectively logout a user! RingCentral authCode should be deleted from localStorage aforehand
    'UserLogin', // Delete login information for user
    'UserToken', // Delete RingCentral tokens for user
    'User', // userLogin + userLogin
    // use this to force renewal of all subscriptions
    'Subscription'
  ];
  
  responseTimerSubscription: Subscription = new Subscription();
  eventCheckTimerSubscription: Subscription =Subscription.EMPTY;
  public hubConnection: HubConnection | undefined;
  public clientConnectionId: string;
  public activities = [];
  ringCentralAuthWindow = null;
  currentlyReconnecting = false;
  recordingId = '';
  checkForEvents = false;
  callRecording: any;
  callInitialized = false;

  public customFields = [];

  public agentTelephonySessionId = '';
  public agentExtensionId = '';
  public supervisedPartyId = '';
  public maskedNumber = false;
  public recordingPartyId = '';
  public resumeRecording = false;
  public telephonyPartyStatus = '';

  ringCentralGuid = null;
  public userInfo: any;
  public currentCallSession: any;
  public consulteeSession: any;
  public phoneConnectionStatus = 'Disconnected';
  public telephonySessionId: string;
  public superviseTelephonySessionId: string;
  public partyID: string;
  public callDirection: string;
  public numberFrom: string;
  public numberTo: string;
  public direction = '';
  presenceInfo: any;
  public userStatus: any;
  public dndStatus: any;
  public presenceStatus: any;
  public eventTime: any;
  public lastEventDate;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private messagingService: MessagingService,
    private methodService: MethodService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private axivasTranslateService: AxivasTranslateService,
    private eventService: EventService
  ) { }

  public initConnection(force = false): Promise<any> {

    return new Promise((initConnectionResolve, initConnectionReject) => {
      if (localStorage.getItem('ringCentralAuthCode') == null) {
        initConnectionReject('No auth code');
        return;
      }
      if (this.hubConnection != undefined) {
        if (this.hubConnection.state == 1 && force == false) {
          console.warn('RingCentral initConnection', " - already connected") 
          return;
        }
      }

      const i = Date.now();
      this.currentlyReconnecting = true;
      this.applicationInfoService.webPhoneConnected = false;
      let url = environment.ringCentralApiRul;
      url = url + '/?l=' + this.encryptSignalRText(localStorage.getItem('awstoken'), i.toString()) + '&i=' + i ;

      this.hubConnection = new HubConnectionBuilder()
      .withUrl(url)
      .configureLogging(signalR.LogLevel.Error)
      .build();

      this.hubConnection.start().then(result => {
        this.addHubConnectionMethods();
        console.warn('RingCentral initConnection', "success");
        this.callSignalRMethodPromise('LoginWithAuthCode', localStorage.getItem('ringCentralAuthCode'), 
          environment.ringCentralAuthRedirectClean.replace('<0>', window.location.origin))
        .then( result => {          
          this.userInfo = result;
          this.currentlyReconnecting = false;
          this.getActivitites();
          this.applicationInfoService.webPhoneConnected = true;
          if (this.responseTimerSubscription) { this.responseTimerSubscription.unsubscribe(); }
          if (this.eventCheckTimerSubscription) { this.eventCheckTimerSubscription.unsubscribe(); }
          this.startPingTimer();
          this.startEventCheckTimer();
          initConnectionResolve('OK');          
        })
        .catch(error => {
          this.currentlyReconnecting = false;
          initConnectionReject();
          localStorage.removeItem('ringCentralAuthCode');
          console.error('RingCentral HubConnection init error (login): ', error)
        });
      })
      .catch(error => {
        this.currentlyReconnecting = false;
        console.error('RingCentral HubConnection init error', error);
        initConnectionReject(error);
      });
    });
  }

  getActivitites() {
    this.hubConnection.invoke('GetActivities')
    .then(result => { 
      this.activities = result; 
      this.getCurrentUserActivity();
    })
    .catch(error => console.error('RingCentral getActivitites', error));    
  }

  bookActivity() {
    this.callSignalRMethodPromise('SetUserActivity', this.userInfo.extensionId, this.applicationInfoService.currentTimeRecordingStatus)
    .then(() => { console.log('geht') })
    .catch(() => { console.error('geht nicht') });
  }

  addHubConnectionMethods() {
    this.hubConnection.on('Test', (data: any) => {
      console.warn('RingCentralTest', data);
    });

    this.hubConnection.on('OnUserActivityChanged', (data: any) => {
      console.warn('OnUserActivityChanged', data);
      this.activities.forEach(item => {
        if (JSON.stringify(item) == JSON.stringify(data)) {
          this.applicationInfoService.currentTimeRecordingStatus = item;
        }
      });      
    });    
    
    this.hubConnection.on('OnCallToBlacklistedNumber', (data: any) => {
      this.setLastEventDate();
      this.callInitialized = false; 
      console.log('OnCallToBlacklistedNumber', data);
      this.messagingService.showDefaultInfo(
        'RingCentral.MakeCall.OnCallToBlacklistedNumberHeader', 
        'RingCentral.MakeCall.OnCallToBlacklistedNumberMessage');
    });

    this.hubConnection.on('OnClientAuthorized', (data: any) => {
      this.setLastEventDate();
      console.log('RingCentralOnClientAuthorized', data);
      this.clientConnectionId = data;
    });

    this.hubConnection.on('OnServiceError', (data: any) => {
      this.callInitialized = false;
      let message = '';
      console.log('OnServiceError', data.errors[0]);
      message = this.axivasTranslateService.getTranslationTextForToken(message);
      this.messagingService.showDefaultWarning('RingCentral'.concat(' ', data.errors[0].errorCode), data.errors[0].message);
      this.handleApiError(data.errors[0].errorCode);
    });

    this.hubConnection.on('OnClientTokenInvalid', (data: any) => {
      this.setLastEventDate();
      console.log('RingCentralOnClientTokenInvalid', data);
      this.deleteUserInformation();
      // this.clientConnectionId = data;
    });

    this.hubConnection.on('OnPhoneSessionEvent', (data: any) => {
      this.setLastEventDate();
      console.log('RingCentralOnPhoneSessionEvent', data);
      this.eventService.ringCentralOnPhoneSessionEvent.emit(data);
      this.updateCurrentPhoneSession(data);
    });

    this.hubConnection.on('OutgoingCallerId', (data: any) => {
      this.setLastEventDate();
      console.log('OutgoingCallerId', data);
      this.userInfo.directNumber = data;
    });

    this.hubConnection.on('OnInvalidCallerId', (data: any) => {
      this.setLastEventDate();
      console.log('OnInvalidCallerId', data);
      this.messagingService.showDefaultWarning('RingCentral', data);
    });
    
    this.hubConnection.on('OnSelectProjectFailed', (data: any) => {
      this.setLastEventDate();
      console.log('OnSelectProjectFailed', data);
      this.messagingService.showDefaultError('RingCentral', data);
    });
    
    this.hubConnection.on('OnPresenceEvent', (data: any) => {
      this.setLastEventDate();
      console.log('RingCentralOnPresenceEvent', data);
      this.setPresenceInfo(data);
    });

    this.hubConnection.on('OnDetailedPresenceEvent', (data: any) => {
      this.setLastEventDate();
      console.log('RingCentralOnDetailedPresenceEvent', data);
      this.setPresenceInfo(data);
    });
  }

  handleApiError(error) {
    switch (error) {
      case 'API-003':
        this.deleteUserInformation();
        break;
      case 'API-007':
        this.deleteUserInformation();        
        break;
    }
  }

  deleteUserInformation() {
    localStorage.removeItem('ringCentralAuthCode');
    this.applicationInfoService.webPhoneConnected = false;
    this.userInfo = null;
  }

  setLastEventDate() {
    this.lastEventDate = new Date(Date.now());
  }

  getCurrentUserActivity() {
    if(this.userInfo != null) {
      this.callSignalRMethodPromise('GetCurrentUserActivity', this.userInfo.extensionId)
      .then(getCurrentUserActivityResult => { 
        this.activities.forEach(item => {
          if (JSON.stringify(item) == JSON.stringify(getCurrentUserActivityResult)) {
            this.applicationInfoService.currentTimeRecordingStatus = item;
          }
        });      
      })
      .catch(error => { console.error('getCurrentUserActivity', error); });    
    }
  }

  setCallSession(data: any)
  {
    this.currentCallSession = data.session;
    this.recordingPartyId = data.session.session.parties[0].id;
  }

  updateCurrentPhoneSession(data: any) {
    console.log('updateCurrentPhoneSession: ', data);
    this.eventTime = data.body.eventTime;
    this.telephonySessionId = data.body.telephonySessionId;      
    if (data.body.parties.length == 0) {
      this.phoneConnectionStatus = 'Disconnected';      
    }
    this.recordingId = null;
    if (data.body != null) {
      if (data.body.parties[0] != null && data.body.parties[0] != undefined) {
        this.phoneConnectionStatus = data.body.parties[0].status.code;
        if (this.phoneConnectionStatus == 'Disconnected') {
          this.callInitialized = false;
        }
        if (data.body.parties[0].recordings != null) {
          this.recordingId = data.body.parties[0].recordings[0].id;
          this.resumeRecording = !data.body.parties[0].recordings[0].active;
        }
        this.callDirection = data.body.parties[0].direction;
        this.telephonyPartyStatus = data.body.parties[0].status.code;
        this.partyID = data.body.parties[0].id;        
        this.numberFrom = data.body.parties[0].from.phoneNumber;
        this.recordingPartyId = data.body.parties[0].id;
        this.numberTo = data.body.parties[0].to.phoneNumber; 
        if (data.body.parties[0].from.extensionId == this.userInfo.extensionId) {
          this.direction = 'Outbound';
        } else {
          this.direction = 'Inbound';
        }
      }      
      this.autoAcceptStatus(data.body);
    }
  }

  autoAcceptStatus(data) {
    if (data.parties.length > 0) {
      if (data.parties[0].from.extensionId == data.parties[0].to.extensionId) {
        this.answerCall();
      }
    }
  }

  resetCallSession() {
    if (this.consulteeSession == null) {
      this.currentCallSession = undefined;
      this.phoneConnectionStatus = undefined;
      this.telephonySessionId = undefined;
      this.partyID = undefined;
      this.callDirection = undefined;
      this.numberFrom = undefined;
      this.numberTo = undefined;
    } else {
      this.consulteeSession = undefined;
    }
  }

  setPresenceInfo(data: any) {
    if (data.body != null) {
      console.log('Received presence info: ', data);
      this.presenceInfo = data.body;
      this.userStatus = this.presenceInfo.userStatus;
      this.dndStatus = this.presenceInfo.dndStatus;
      this.presenceStatus = this.presenceInfo.presenceStatus;
    }
  }

  updateUserStatus(result: any): any {
    console.log(result);
  }

  private encryptSignalRText(text: string, iv: string): string {​​​​​​​​
    const dataIv = '000' + iv;
    const dataKey = 'efoije4rpk40rk4!';
    return this.encryptData(dataKey, dataIv, text);
  }​​​​​​​​

  private encryptData(dataKey: string, dataIv: string, txtText: string): string {
    if (txtText.length > 0 ) {
      const key = CryptoJS.enc.Utf8.parse(dataKey);
      const iv = CryptoJS.enc.Utf8.parse(dataIv);

      const encryptedData = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(txtText),
        key,
        {
          keySize: 128 / 8,
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );

      const encodeData = encodeURIComponent(encryptedData.toString());
      return encodeData;
    }

    return '';
  }

  startRecording() {
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('CreateRecording', userExtensionId,
      this.telephonySessionId, this.recordingPartyId)
    .then(result => this.recordingId = result)
    .catch(error => console.error(error));
  }

  toggleRecording() {
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('PauseOrResumeRecording', userExtensionId,
      this.telephonySessionId, this.recordingPartyId, this.recordingId, this.resumeRecording)
    .then(result => this.callRecording = result)
    .catch(error => console.error(error));
  }

  logoutUser(){
    console.log('Logout user');
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('Logout', userExtensionId)
    .then(result => {
      console.log ('Logged out: ', result);
      localStorage.removeItem("ringCentralAuthCode");
      this.userInfo = null;
    })
    .catch(error => console.error(error));
  }

  updateCallLog(logObject) {
    this.externaldatasourceService.executeExternalDataSource(470, [
      this.commonService.getModifyArrayBody(logObject, [])
    ])
    .then(logCallResult => {});
  }

  logCall(extensionId, phoneNumber, projectId, accountId): Promise<any> {
    return new Promise((logCallResolve, logCallReject) => {
      let campaignId = this.applicationInfoService.projectInfo.defaultCampaignId;
      if (this.applicationInfoService.currentTask != null) {
        campaignId = this.applicationInfoService.currentTask.campaignId;
      }
      if (this.commonService.isNullOrUndefined(campaignId)) {
        campaignId = null;
      }
      const call = new Object();
      call['extensionId'] = extensionId;
      call['projectId'] = projectId;
      call['accountId'] = accountId;
      call['entityId'] = 1;
      call['userId'] = this.applicationInfoService.userID;
      call['campaignId'] = campaignId;
      call['entityMemberId'] = 1;
      call['calledNumber'] = phoneNumber;
      call['callStartedAt'] = new Date(Date.now());
      this.externaldatasourceService.executeExternalDataSource(462, [
        this.commonService.getModifyArrayBody(call, [])
      ])
      .then(logCallResult => {
        call['id'] = logCallResult.id;
        logCallResolve(logCallResult);
      })
      .catch(error => { logCallReject(error); });  
    });
  }

  // --- Call Control
  makeCall(phoneNumber) {
    if (this.callInitialized) { 
      return;
    }
    phoneNumber = phoneNumber.replace(/ /g, '');
    let accountId = null;
    if (this.applicationInfoService.currentAccount != null) { 
      accountId = this.applicationInfoService.currentAccount.id; 
    }

    let contactId = null;
    if (this.applicationInfoService.currentContact != null) { 
      contactId = this.applicationInfoService.currentContact.id; 
    }

    let campaignId = null;
    if (this.applicationInfoService.currentTask != null) { 
      campaignId = this.applicationInfoService.currentTask.campaignId; 
    }
    if (this.applicationInfoService.campaingnModeId != null) {
      campaignId = this.applicationInfoService.campaingnModeId; 
    }

    let taskId = null;
    if (this.applicationInfoService.currentTask != null) { 
      taskId = this.applicationInfoService.currentTask.id; 
    }

    if (this.applicationInfoService.isDeveloper) {
      console.log('MakeCall', this.userInfo.extensionId, phoneNumber, this.maskedNumber, 
        this.applicationInfoService.projectID, 1, this.customFields, 
        accountId, contactId, taskId, campaignId
      );
    }
    
    if (this.phoneConnectionStatus == 'Disconnected') {      
      this.callInitialized = true;
      setTimeout(() => { 
        this.callInitialized = false; 
      }, 20000);

      this.logCall(this.userInfo.extensionId, phoneNumber, this.applicationInfoService.projectID, accountId)
      .then(logCallResult => {

        const makeCallJson = {
          userExtensionId:  this.userInfo.extensionId,
          phoneNumber: phoneNumber,
          projectId: this.applicationInfoService.projectID, 
          projectSourceId: 1,
          accountId: accountId,
          contactId: contactId,
          taskId: taskId,
          campaignId: campaignId,
          agentMakeCalltime: new Date(Date.now()),
          customFields: this.customFields,
          callLogId: logCallResult.id
        }
        this.callSignalRMethodPromise('MakeCall', 
          JSON.stringify(makeCallJson)
          // this.userInfo.extensionId, 
          // phoneNumber, 
          // this.maskedNumber, 
          // this.applicationInfoService.projectID, 
          // 1, 
          // accountId,
          // contactId,
          // taskId,
          // campaignId,
          // this.customFields
        )
        .then(makeCallResult => {
          this.setCallSession(makeCallResult);
          const callLogObject = new Object();
          callLogObject['id'] = logCallResult.id;
          callLogObject['telephonySessionId'] = makeCallResult.session.session.id;
          if (makeCallResult.session.session.parties.length > 0) {
            callLogObject['outgoingCallerNumber'] = makeCallResult.session.session.parties[0].from.phoneNumber;
          } else {
            callLogObject['outgoingCallerNumber'] = null;
          }          
          callLogObject['sessionCreateStatusLookupId'] = makeCallResult.createSessionStateLookupId;
          callLogObject['apiReactionTimeMsec'] = makeCallResult.reactionTime;
          callLogObject['apiProcessingTimeMsec'] = makeCallResult.processingTime;
          this.updateCallLog(callLogObject);  

        })
        .catch(error => console.error(error));
      });
    } else {
      this.messagingService.showDefaultWarning('RingCentral.MakeCall.CallAlreadyStartedHeader', 'RingCentral.MakeCall.CallAlreadyStartedMessage')
    }
  }

  initEventCheck() {
    this.lastEventDate = null;
    this.checkForEvents = true;         
  }

  dropCall() {
    console.log('Drop call ', this.telephonySessionId);    
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('DropCall', userExtensionId, this.telephonySessionId)
    .then(result => {  
      this.resetCallSession();      
      this.phoneConnectionStatus = 'Disconnected';
    })
    .catch(error => console.error(error));
  }

  answerCall() {
    console.log('Answer call ', this.telephonySessionId, this.partyID);
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('AnswerCall', userExtensionId,
      this.telephonySessionId,
      this.partyID)
    .then(result => console.log(result))
    .catch(error => console.error(error));
  }

  holdCall() {
    console.log('Hold call ', this.telephonySessionId, this.partyID);
    const userExtensionId = this.userInfo.extensionId;
    if (this.telephonySessionId != null){
      this.callSignalRMethodPromise('HoldCall', userExtensionId,
        this.telephonySessionId)
      .then(result => console.log(result))
      .catch(error => console.error(error));
    } else {
      console.error('No session to hold');
    }
  }

  unholdCall() {
    console.log('Unhold call ', this.telephonySessionId, this.partyID);
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('UnholdCall', userExtensionId,
      this.telephonySessionId)
    .then(result => console.log(result))
    .catch(error => console.error(error));
  }

  muteCall() {
    console.log('Mute call ', this.telephonySessionId, this.partyID);
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('MuteCall', userExtensionId,
      this.telephonySessionId)
    .then(result => console.log(result))
    .catch(error => console.error(error));
  }

  unmuteCall() {
    console.log('Unmute call ', this.telephonySessionId, this.partyID);
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('UnmuteCall', userExtensionId,
      this.telephonySessionId)
    .then(result => console.log(result))
    .catch(error => console.error(error));
  }

  transferCall(transferNumber) {
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('TransferCall', userExtensionId, this.telephonySessionId, transferNumber)
    .then(result => {
      
    })
    .catch(error => console.error(error));
  }

  consult(consultNumber) {
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('Consult', userExtensionId,
      this.telephonySessionId, consultNumber)
    .then(result => {
      this.consulteeSession = result;
    })
    .catch(error => console.error(error));
  }

  conferenceCall(conferenceNumber) {
    console.log('Start conference ', this.telephonySessionId, this.partyID);
    const userExtensionId = this.userInfo.extensionId;
    this.callSignalRMethodPromise('Conference', userExtensionId,
      this.telephonySessionId, conferenceNumber)
    .then(result => {
      console.log(result);
      this.consulteeSession = result;
    })
    .catch(error => console.error(error));
  }

  startEventCheckTimer() {        
    let checkSeconds = 0;
    this.eventCheckTimerSubscription = timer(1000, 2000).subscribe(restime => {                
      if (this.checkForEvents == false) {
        checkSeconds = 0;
        return;
      }
  
      console.log('startEventCheckTimer', 'check required', checkSeconds, this.lastEventDate);
      if (checkSeconds > 7 && this.lastEventDate == null) {        
        console.log('startEventCheckTimer', 'event not found during wait time, refreshing cache...');
        this.refreshCache('Subscription');
        this.checkForEvents = false;
        checkSeconds = 0;
      }
      if (this.lastEventDate != null) {
        console.log('startEventCheckTimer', 'event found', this.eventCheckTimerSubscription);
        this.checkForEvents = false;
        checkSeconds = 0;
      }
      checkSeconds = checkSeconds + 1;
    });
  }

  startPingTimer() {
    const responseTimer = timer(5000, 30000);
      this.responseTimerSubscription = responseTimer.subscribe(restime => {
        this.callSignalRMethodPromise('Ping', this.userInfo.extensionId)
        .then(pingRes => {
          // console.log('Ping result: ', pingRes);
        })
        .catch(error => {
          // console.error('Ping error: ', error)
        });
      });
  }


  stopPingTimer() {
    if (this.responseTimerSubscription) { this.responseTimerSubscription.unsubscribe(); }
  }

  callSignalRMethod(method, ...params: any[]) {
    if (this.hubConnection) {
      this.hubConnection.invoke(method, params).then(result => { })
      .catch(error => {
        console.error('callSignalRMethod result: ', error);
      });
    } else {
      console.warn('Signal-R connection not initialized!');
    }
  }

  callSignalRMethodPromise(method, ...params: any[]): Promise<any> {
    return new Promise((res, err) => {
      if (this.hubConnection) {
        this.hubConnection.invoke(method, params)
        .then(result => {
          res(result);
        })
        .catch(error => {
          err(error);
        });
      } else {
        err('Signal-R connection not initialized!')
      }
    });
  }

  initTransfer() {
    this.applicationInfoService.miscSettings['ringCentralPopupMode'] = 'transfer';
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['RingCentral', 'ringcentralpopup', 80]);
  }

  initConsult() {
    this.applicationInfoService.miscSettings['ringCentralPopupMode'] = 'consult';
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['RingCentral', 'ringcentralpopup', 80]);
  }

  initConference() {
    this.applicationInfoService.miscSettings['ringCentralPopupMode'] = 'conference';
    this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['RingCentral', 'ringcentralpopup', 80]);
  }

  callRingCentralAhtentification() {
    let url = environment.ringCentralAuthRedirectClean.replace('<0>', window.location.origin);
    url = encodeURIComponent(url);
    url = environment.ringCentralAuthRedirect.replace('<0>', url);
    this.ringCentralAuthWindow = window.open(url, '_blank');
  }

  closeRingCentralAhtentification() {
    this.ringCentralAuthWindow.close();
  }

  refreshCache(cache){
    console.log('Refresh cache', cache);
    const userExtensionId = +this.userInfo.extensionId;
    // userExtensionId: logged in user
    this.callSignalRMethodPromise('RefreshCache', userExtensionId, cache)
    .then(result => {
    console.log('Cache reloaded: ', result);
    }).
    catch(error => console.error(error));
    }
}
