<div *ngFor="let dataItem of data" [ngStyle]="getLevelPadding()">
    <div *ngIf="dataItem.parentAnswerId == parentId"
        [ngClass]="{ hideInDom: parentId != null && itemInList(parentId) == false }">
        <label *ngIf="dataItem.descriptionTranslationToken?.tokenFullName!=null" class="subResultQuestionLabel">
            <span *ngIf="dataItem.isMandatory">* </span>{{ dataItem.descriptionTranslationToken?.tokenFullName | texttransform }}
        </label>
        <label *ngIf="dataItem.descriptionTranslationToken?.tokenFullName==null" class="subResultQuestionLabel">
            <span *ngIf="dataItem.isMandatory">* </span>{{ dataItem.defaultName }}
        </label>
        <span *ngIf="dataItem.isMultiselect==true">
            <div *ngFor="let answer of dataItem.answers" [ngStyle]="getLevelPadding(subResultLevel + 1)"
                (click)="toggleSubResultInArray(answer.id);$event.stopPropagation()" [ngClass]="{ hideInDom: answer.isDeleted == true }">
                <input [ngModel]="itemInList(answer.id)" class="subResultAnswerCheckbox" type="checkbox" style="width: 5%">
                <label *ngIf="answer.descriptionTranslationToken?.tokenFullName!=null" class="subResultLabel"
                    style="width: 95%">
                    {{ answer.descriptionTranslationToken.tokenFullName | texttransform }}
                </label>
                <label *ngIf="answer.descriptionTranslationToken?.tokenFullName==null" class="subResultLabel">
                    {{ answer.defaultName }}
                </label>
                <textarea [ngModel]="answerText[answer.id]" (change)="addAnswerTextArrayToEntity()"  *ngIf="answer.typeLookupId == 15"></textarea>
                <div (click)="$event.stopPropagation()">
                    <app-custom-subresult-item [data]="data" [subResultLevel]="subResultLevel+1" [parentId]="answer.id"
                        [mainControl]="mainControl">
                    </app-custom-subresult-item>
                </div>
            </div>
        </span>
       
        <div *ngIf="dataItem.isMultiselect==false" class="subResultItem__answer">
            <select (change)="selectDropDownItem(dropdownSelection[dataItem.id], dataItem)" class="maxWidth"
                [ngStyle]="getLevelPadding(subResultLevel + 1)" [(ngModel)]="dropdownSelection[dataItem.id]">
                <option *ngFor="let answer of dataItem.answers" [value]=answer.id [ngClass]="{ hideInDom: answer.isDeleted == true }">
                    <label *ngIf="answer.descriptionTranslationToken?.tokenFullName!=null" class="subResultLabel">
                        {{ answer.descriptionTranslationToken.tokenFullName | texttransform }}
                    </label>
                    <label *ngIf="answer.descriptionTranslationToken?.tokenFullName==null" class="subResultLabel">
                        {{ answer.defaultName }}
                    </label>                    
                </option>                
            </select>
            <input [(ngModel)]="answerText[dropdownSelection[dataItem.id]]" (change)="addAnswerTextArrayToEntity()" 
                *ngIf="checkIfSubquestionAvailable(dataItem, dropdownSelection[dataItem.id], 1907) == true" type="text" style="margin-left: 10px;">
            <div *ngFor="let answer of dataItem.answers">
                <app-custom-subresult-item [data]="data" [subResultLevel]="subResultLevel+1" [parentId]="answer.id"
                    [mainControl]="mainControl">
                </app-custom-subresult-item>
            </div>
        </div>
    </div>
</div>