import { Injectable } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { Observable } from 'rxjs';
import { SharedAPI } from '../service/sharedAPI';
import { AxivasTranslateService } from './axivas-translate.service';



@Injectable()
export class CustomTranslateLoader {
    constructor(
      private sharedAPI: SharedAPI,
      private axivasTranslateService: AxivasTranslateService,
      private commonService: CommonService
    ) { }

    getTranslation(lang: string): Observable<any> {
        return Observable.create(observer => {
          let itemFound = false;
          // const storageItem = this.commonService.getLocalStorageCache('lang' + lang);
          // if (storageItem != null) {
          //   this.axivasTranslateService.translationTextArray = storageItem;
          //   observer.next(storageItem);
          //   observer.complete();
          //   itemFound = true;
          // };  
          if (!itemFound) {
            this.sharedAPI.getTranslationIdsForLanguage(lang)
            .subscribe((res: Response) => {
                  this.axivasTranslateService.translationTextArray = res;
                  // this.commonService.setLocalStorageCache('lang' + lang, res);
                  observer.next(res);
                  observer.complete();
              });
          }
      });
    }
}
