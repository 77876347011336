import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-mainscreen',
  templateUrl: './mainscreen.component.html',
  styleUrl: './mainscreen.component.scss'
})

export class MainscreenComponent implements OnInit {
  constructor(
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    
  }
}
