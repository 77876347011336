import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-research',
  templateUrl: './custom-research.component.html',
  styleUrls: ['./custom-research.component.scss']
})
export class CustomResearchComponent extends CustomControlBaseClass implements OnInit {
  //// ADDITIONAL SETTING 1: Dynamic entites and members for search

  constructor(
    private injector: Injector,
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.checkAttributes();
    this.controlGuidPredefine = 'research';
  }

  launchGoogleSearch() {
    let url =  'https://www.google.de/search?source=everlead&q=<0>';
    let replaceString = '';
    const entityData = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data;
    if (this.commonService.isNullOrUndefined(this.controlDefinition.additionalSetting1) ||
      this.controlDefinition.additionalSetting1 === '') {
      replaceString = replaceString.concat(
        this.modifySearchString(entityData.name1), '+',
        this.modifySearchString(entityData.name2), '+',
        this.modifySearchString(entityData.street), '+',
        this.modifySearchString(entityData.zipcode), '+',
        this.modifySearchString(entityData.city)
      );
    } else {
      const searchValues = this.controlDefinition.additionalSetting1.split(';');
      searchValues.forEach(value => {
        const valueDetails = value.split('.');
        const valueSearchString = this.applicationInfoService.entities.Item(valueDetails[0])
          .data[valueDetails[1]];
        if (!this.commonService.isNullOrUndefined(valueSearchString)) {
          replaceString = replaceString.concat(this.modifySearchString(valueSearchString), ' ');
        }
      });
    }
    replaceString = replaceString.replace(/&/, '');
    url = url.replace(/<0>/, replaceString);
    window.open(url);
  }

  modifySearchString(baseString: string): string {
    let resultString = '';
    if (baseString == null) {
      return resultString;
    }
    resultString = baseString.trim();
    resultString = resultString.replace(/ /, '+');
    return resultString;
  }

  openLinkedIn() {
    let isAccount = false;
    const entityData = this.applicationInfoService.entities.Item(this.controlDefinition.uiEntityInstanceId).data;
    if (Number(this.controlDefinition.uiEntityInstanceId) == 1) {
      isAccount = true;
    }
    let value1 = entityData.firstName;
    let value2 = entityData.lastName;
    if (isAccount) {
      value1 = entityData.name1;
    }
    if (this.commonService.isNullOrUndefined(value1)) { value1 = ''; }
    if (this.commonService.isNullOrUndefined(value2)) { value2 = ''; }
    this.commonService.openLinkedIn(value1, value2, isAccount);
  }
}
