import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Injectable()
export class PermissionGuard {

  public userService: UserService;
  public router: Router;

  public constructor(userService: UserService, router: Router) {
    this.userService = userService;
    this.router = router;
  }

  public redirectUnauthorized() {
    // this.router.navigate(['401']);
  }
}
