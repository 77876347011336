<div class="barsGraphHeaderLabel" [id]="getHeaderLabelId()" *ngIf="header != ''" [style.color]="headercolor">{{ header | texttransform }}</div>
<!-- <div *ngIf="!dataAvailable" class="pieChart__noData" [ngClass]="{ 'pieGraph__darkMode': darkmode }">
    {{ 'Bars.Label.NoDataAvailable' | texttransform }}
</div> -->

<div
  echarts
  [options]="options"
  [id]="controlid"
  [style.height.px]="height"
  >
</div>
