import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-horizontal-rule',
  templateUrl: './custom-horizontal-rule.component.html',
  styleUrls: ['./custom-horizontal-rule.component.scss']
})
export class CustomHorizontalRuleComponent extends CustomControlBaseClass implements OnInit, OnDestroy {

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;
  }

  ngOnInit() {
  }

  ngOnDestroy() {}
}
