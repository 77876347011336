import { Component, Injector, OnInit } from '@angular/core';
import { Dictionary } from 'app/core/dictionary';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

@Component({
  selector: 'app-custom-copytoclipboard-button',
  templateUrl: './custom-copytoclipboard-button.component.html',
  styleUrls: ['./custom-copytoclipboard-button.component.scss']
})
export class CustomCopytoclipboardButtonComponent extends CustomControlBaseClass implements OnInit {
  // additionalSetting1 = Comes AFTER the value
  // additionalSetting2 = if 1, then the translation of the member is added to the value
  // additionalSetting3 = will replace addtionalSetting2 with a custom Text (can be a translation token)
  results: Dictionary<any> = new Dictionary<any>();

  constructor(
    private injector: Injector,
    private axivasTranslateService: AxivasTranslateService
  ) {
    super(injector);
    this.valueType = ValueType.Boolean;
  }

  ngOnInit() {
  
  }

  copyRelatedItemsToClipboard() {
    const promiseArray: Promise<any>[] = [];

    let clipboardstring = '';
    this.controlDefinition.childs.forEach(child =>  {
      const member = this.entityService.getEntityMember(this.applicationInfoService.entities
        .Item(child.uiEntityInstanceId), child.value);
        if (!this.commonService.isNullOrUndefined(member.externalDataSource)) {
          promiseArray.push(this.getLookupValue(member.externalDataSource.id));
        }
    });
    Promise.all(promiseArray).then(() => {
      this.controlDefinition.childs.forEach(child =>  {
        const member = this.entityService.getEntityMember(this.applicationInfoService.entities
          .Item(child.uiEntityInstanceId), child.value);
        if (this.applicationInfoService.entities.ContainsKey(child.uiEntityInstanceId)) {
          let addString = this.applicationInfoService.entities.Item(child.uiEntityInstanceId).data[child.value];
          if (this.commonService.isNullOrUndefined(addString)) {
            addString = '';
          }
          if (!this.commonService.isNullOrUndefined(member.externalDataSource)) {
            const toTranslate = this.results.Item(member.externalDataSource.id).filter(x => x.id === Number(addString))[0];
            if (!this.commonService.isNullOrUndefined(toTranslate)) {
              addString = this.axivasTranslateService.getTranslationTextForToken(
                toTranslate.defaultName
              );
            }
          }
          let afterString = child.additionalSetting1;
          if (this.commonService.isNullOrUndefined(afterString)) {
            afterString = ' ';
          }

          if ((child.additionalSetting2 === '1') && (this.commonService.isNullOrUndefined(child.additionalSetting3))) {
            const memberPrefix = this.entityService.getMemberTranslation(member.id);
            addString = memberPrefix.concat(': ', addString);
          } else if (!this.commonService.isNullOrUndefined(child.additionalSetting3)) {
            const memberPrefix = this.axivasTranslateService.getTranslationTextForToken(child.additionalSetting3);
            addString = memberPrefix.concat(': ', addString);
          }
          if (addString !== '') {
            if (this.commonService.checkIfStringContainsString(afterString, '\\n')) {
              clipboardstring = clipboardstring.concat(addString, '\n');
            } else {
              clipboardstring = clipboardstring.concat(addString, afterString);
            }
          }
        }
      });
      this.commonService.copyTextToClipboard(clipboardstring);
      this.messagingService.showDefaultSuccess(
        'General.CopyToClipBoard.Header',
        'General.CopyToClipBoard.Message',
        false
      );
    });
  }

  getLookupValue(externalDataSourceId): Promise<any> {
    return new Promise((getLookupValueResolve, getLookupValueReject) => {
      this.externalDatasourceService.executeExternalDataSource(externalDataSourceId).then(result => {
        this.results.Add(externalDataSourceId, result);
        getLookupValueResolve(null);
      });
    });
  }
}
