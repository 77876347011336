<div class="toolbarPopup" *ngIf="showPopup" (click)="closePopup()">
    <div class="toolbarArrow" [style.right]="arrowRight"></div>
    <div class="toolbarPopup__content">
        <app-toolbar-phone *ngIf="section == 'phone'"></app-toolbar-phone>

        <div *ngIf="section == 'showDemoLandingPageNotVisitedInfo'" class="showDemoLandingPageNotVisitedInfo">
            {{ 'Toolbar.Label.ShowDemoLandingPageNotVisitedInfo' | texttransform }}
        </div>

        <div *ngIf="section == 'plus'" class="plus-section">
            <div class="plus">
                <div class="plus__item fingerCursor" (click)="newItem(2)"
                    *ngIf="applicationInfoService.applicationSettings['blockNewContact']!='true'">
                    <div class="plus__item__svg">
                        <svg-icon src="assets/images/svg/toolbar/toolbar_newcontact.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="plus__item__text">{{ 'Contact.Label.NewContact' | texttransform }}</div>
                </div>
                <div class="plus__item fingerCursor" (click)="newItem(1)"
                    *ngIf="applicationInfoService.applicationSettings['blockNewAccount']!='true' || applicationInfoService.isDeveloper">
                    <div class="plus__item__svg">
                        <svg-icon src="assets/images/svg/toolbar/toolbar_newaccount.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="plus__item__text">{{ 'Supervisor.Menu.NewAccount' | texttransform }}</div>
                </div>
                <div class="plus__item" (click)="newItem(4)" [ngClass]="{
                    fingerCursor: applicationInfoService.currentAccount != null,
                    'plus__item__inactive': applicationInfoService.currentAccount == null
                }" *ngIf="commonService.checkApplicationRoute('account') == true && applicationInfoService.applicationSettings['blockNewActivity']!='true'">
                    <div class="plus__item__svg">
                        <svg-icon src="assets/images/svg/toolbar/toolbar_newactivity.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="plus__item__text">{{ 'AccountFooter.Label.NewActivity' | texttransform }}</div>
                </div>
            </div>

            <!-- Processvisual -->
            <div class="plus" *ngIf="commonService.checkApplicationRoute('processvisual') == true">
                <div class="plus__item" (click)="createWorkflow()">
                    <div class="plus__item__svg">
                        <svg-icon src="assets/images/svg/toolbar/toolbar_newactivity.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="plus__item__text">{{ 'ProcessDesign.Label.NewWorkflow' | texttransform }}</div>
                </div>
                <div *ngIf="commonService.checkApplicationRoute('processvisual') == true &&
                        applicationInfoService.selectedProcessVisualWorkflow != 0 &&
                        userService.hasPermission('canCreateWorkflow')" class="plus__item" (click)="createStarterStep()">
                    <div class="plus__item__svg">
                        <svg-icon src="assets/images/svg/toolbar/toolbar_newactivity.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
                    </div>
                    <div class="plus__item__text">{{ 'ProcessDesign.Label.NewStarterStep' | texttransform }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="section == 'research'" class="research">
            <div class="research__item" id="researchAccount" *ngIf="
                applicationInfoService?.currentAccount != null &&
                applicationInfoService?.currentAccount?.isDummyAccount != true
            ">
                <div class="research__item__icon">
                    <div class="research__item__icon__wrapper"><svg-icon src="assets/images/svg/iconset/05icon-adressen.svg" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }" style="margin-top: -4px"></svg-icon></div>
                </div>
                <div class="research__item__content">
                    <div>{{ applicationInfoService.currentAccount.name1 | texttransform }}</div>
                    <div class="research__item__content__inner">
                        <div class="maxWidth">
                            <div class="research__item__content__search">{{ 'Toolbar.Label.SearchIn' | texttransform }}</div>
                            <div class="research__item__content__buttons">
                                    <div class="research__item__content__buttons__button">
                                        <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                        (click)="launchGoogleSearch(1);$event.stopPropagation()" [tippy]="'Research.Label.Google' | texttransform"></svg-icon>
                                    </div>
                                    <div class="research__item__content__buttons__button">
                                        <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                        (click)="openXing(1);$event.stopPropagation()" [tippy]="'Research.Label.Xing' | texttransform"></svg-icon>
                                    </div>
                                    <div class="research__item__content__buttons__button">
                                        <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                                        *ngIf="applicationInfoService.applicationSettings['showLinkedIn'] == 'true'" [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                        (click)="openLinkedIn(1);$event.stopPropagation()" [tippy]="'Research.Label.LinkedIn' | texttransform"></svg-icon>
                                    </div>
                            </div>
                        </div>
                        <div *ngIf="getAccountValue('url') != ''" class="research__url">
                            <div class="research__item__content__search">{{ 'Toolbar.Label.URL' | texttransform }}</div>
                            <div class="research__item__content__url">
                                <label class="ellipsisText noMargin">{{ getAccountValue('url') }} </label>
                                <svg-icon src="assets/images/svg/toolbar/toolbar_openurl.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"
                                    (click)="openURL(getAccountValue('url'));$event.stopPropagation()"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="research__item" id="researchAccount" *ngIf="applicationInfoService.currentContact != null">
                <div class="research__item__icon">
                    <div class="research__item__icon__wrapper"><i class="fas fa-user"></i></div>
                </div>
                <div class="research__item__content">
                    <div>{{ applicationInfoService.currentContact.firstName | texttransform }} {{ applicationInfoService.currentContact.lastName | texttransform }}</div>
                    <div class="research__item__content__search">{{ 'Toolbar.Label.SearchIn' | texttransform }}</div>
                    <div class="research__item__content__buttons">
                        <div class="research__item__content__buttons__button">
                            <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                            (click)="launchGoogleSearch(4);$event.stopPropagation()" [tippy]="'Research.Label.Google' | texttransform"></svg-icon>
                        </div>
                        <div class="research__item__content__buttons__button">
                            <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                            (click)="openXing(4);$event.stopPropagation()" [tippy]="'Research.Label.Xing' | texttransform"></svg-icon>
                        </div>
                        <div class="research__item__content__buttons__button">
                            <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                            *ngIf="applicationInfoService.applicationSettings['showLinkedIn'] == 'true'" [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                            (click)="openLinkedIn(4);$event.stopPropagation()" [tippy]="'Research.Label.LinkedIn' | texttransform"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="section == 'userMenu'" class="userMenu">
            <div class="userMenu__item" *ngIf="userService.hasPermission('CanUpdateExpertMode')" (click)="changeExpertMode()">
                <i class="fas " [ngClass]="{  'fa-toggle-on': applicationInfoService.expertMode == true, 'fa-toggle-off': applicationInfoService.expertMode == false }"></i>
                <div class="userMenu__item__label ellipsisText">{{ 'UserSettings.Label.ExpertMode' | texttransform }}</div>
            </div>
            <div class="userMenu__item" *ngIf="userService.hasPermission('CanReadUiDesigner') && canUseDesigner" (click)="changeDesignerMode()">
                <i class="fas fa-pencil-ruler"></i>
                <div class="userMenu__item__label ellipsisText">{{ getModeText() | texttransform }}</div>
            </div>
            <div class="userMenu__item" *ngIf="userService.hasPermission('CanReadUserMessage')" (click)="showMailbox()">
                <i class="fas fa-envelope" style="position: relative;">
                    <div class="userLogo__newMessages" *ngIf="getNewUserMessages() != 0">
                    </div>
                </i>
                <div class="userMenu__item__label ellipsisText">{{ 'UserSettings.Label.Mailbox' | texttransform }}</div>
            </div>
            <div class="userMenu__item" (click)="showUserSettings()">
                <svg-icon src="assets/images/svg/settings.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                <div class="userMenu__item__label ellipsisText">{{ 'General.Menu.Settings' | texttransform }}</div>
            </div>
            <div class="userMenu__item"  (click)="logout()">
                <svg-icon src="assets/images/svg/logout.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                <div class="userMenu__item__label ellipsisText">{{ 'General.Menu.Logout' | texttransform }}</div>
            </div>
        </div>
    </div>
</div>
