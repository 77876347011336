<div class="projectSettings__header">
    <div class="projectSettings__header__interaction">
        
        <div class="projectSettings__header__showOnlyActive" *ngIf="applicationInfoService.expertMode">
            <input type="checkbox" [(ngModel)]="showIds">
            <label class="userPermissionPermissionLabel floatRight">Ids anzeigen</label>
        </div>
        <div class="projectSettings__header__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
            </svg-icon>
            <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
        </div>
    </div>
</div>

<div class="everleadShadowBox everleadHorizontalFlex">
    <label class="userPermissionInfoLabel">Rechtebereich:</label>
    <select [(ngModel)]="permissionSection" class="userPermissionDropdown" (change)="clearSelections()">
        <option ngValue=1>Rechte-Sets</option>
        <option ngValue=2>Benutzerrollen-Rechte</option>
        <option ngValue=3>Benutzer-Rechte</option>
    </select>
</div>

<div *ngIf="permissionSection == 1">
    <div *ngIf="createNewPermissionSet == true">
        <b>Ein neues Permission-Set anlegen</b><br>
        Bitte geben Sie einen Namen für das neue Permission-Set an. Sobald das entsprechende Token verfügbar ist kann
        das Set erstellt werden.
        <br>
        <br>
        <div>
            <label style="margin-right: 10px;">Projekt</label>
            <select [(ngModel)]="newTokenProjectId">
                <option [ngValue]=null></option>
                <option [ngValue]=project.id *ngFor="let project of projects">{{ project.projectName }}</option>
            </select>
        </div>
        <div class="userPermissionTranslation" (click)="methodService.callTranslationPopup(permissionSetToCreate, permissionSetToCreate.nameTranslationToken, 
            'nameTranslationToken', translationSource);$event.stopPropagation()">
            <i class="fingerCursor fas fa-edit"
                [ngClass]="{ jjColorPositive: jjtranslationService.checkIfTranslationIsAvailableInToken(permissionSetToCreate.nameTranslationToken) }"></i>
            <label class="cleanFromMarginAndPadding campaignLongLabel fingerCursor">
                Übersetzung für das neue PermissionSet</label>
        </div>
        <br>
        <button
            [disabled]="!jjtranslationService.checkIfTranslationIsAvailableInToken(permissionSetToCreate.nameTranslationToken)"
            (click)="saveNewPermissionSet()">Erstellen</button>
    </div>

    <div *ngIf="createNewPermissionSet == false" class="everleadShadowBox everleadHorizontalFlex">
        <label class="userPermissionInfoLabel">Rechteset:</label>
        <select [(ngModel)]="permissionSet" class="userPermissionDropdown"
            (change)="setSelectedPermissions(permissionSet)">
            <option [ngValue]=permissionSet *ngFor="let permissionSet of permissionSets">
                {{ permissionSet.defaultName | texttransform }}</option>
        </select>
    </div>
    <div *ngIf="permissionSet != null && createNewPermissionSet == false"
        class="userPermissionOverflowY">

        <div>
            <div class="manageRights__permissions__header">
                <label>
                    <i class="fas fa-search"></i>
                    <input [(ngModel)]="filterString" style="width: 100%" class="filterInput">
                </label>
                <div>
                    <div *ngFor="let permissionRight of permissionRights">
                        <div>
                            {{ permissionRight.defaultName | texttransform }}
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <div id="userPermissionDesign"></div>
        <div class="manageRights__permissions userPermissionOverflowY"  [style.max-height.px]="getMaxHeight()">
            <div *ngFor="let module of modules" class="manageRights__permissions__wrapper" [ngClass]="{ hideInDom: itemIsFiltered(module) }">
                <label [tippy]="commonService.getNameTranslationOrDefaultName(module) | texttransform">
                    {{ module.defaultName | texttransform }}
                </label>      
                <div class="manageRights__permissions" style="flex-direction: row; border-width: 0;">
                    <div *ngFor="let permissionRight of permissionRights">      
                        <div *ngIf="getModulePermission(module, permissionRight) == null">
                            
                        </div>          
                        <div (click)="changeActivatedPermission(getModulePermission(module, permissionRight).id)" *ngIf="getModulePermission(module, permissionRight) != null"
                            [ngClass]="{ everleadCoral: permissionIdIsActivated(getModulePermission(module, permissionRight).id) }">
                            <i class="far fa-check-square" *ngIf="permissionIdIsActivated(getModulePermission(module, permissionRight).id)"></i>                
                            <i class="far fa-square" *ngIf="!permissionIdIsActivated(getModulePermission(module, permissionRight).id)"></i>    
                        </div>
                    </div>    
                </div>  
            </div>
        </div>
    </div>
</div>

<div *ngIf="permissionSection == 2">
    <div class="everleadShadowBox everleadHorizontalFlex">
        <label class="userPermissionInfoLabel">Benutzerrolle:</label>
        <select [(ngModel)]="userRole" class="userPermissionDropdown" (change)="loadUserRolePermissionSets(userRole)">
            <option [ngValue]=userRole *ngFor="let userRole of userRoles">
                {{ userRole.id }} - {{ userRole?.nameTranslationToken?.tokenFullName | texttransform }}
            </option>
        </select>
    </div>
    <div id="userPermissionDesign"></div>
    <div class="userPermissionOverflowY" [style.max-height.px]="getMaxHeight()" *ngIf="userRole != null">
        <div *ngFor="let permissionSet of permissionSets" class="everleadShadowBox"
            (click)="changeActivatedPermission(permissionSet.id)" [ngClass]="{ 
            hideInDom : itemIsFiltered(permissionSet.nameTranslationToken.tokenFullName)
        }">
            <label class="userPermissionPermissionLabel" [ngClass]="{ 
                everleadCoral : permissionIdIsActivated(permissionSet.id) 
            }">
                <span *ngIf="showIds">{{ permissionSet.id }} -
                </span>{{ permissionSet.defaultName | texttransform }}
            </label>
        </div>
    </div>
</div>

<div *ngIf="permissionSection == 3">
    <div class="everleadShadowBox everleadHorizontalFlex">
        <label class="userPermissionInfoLabel">Benutzerrolle:</label>
        <select [(ngModel)]="user" class="userPermissionDropdown" (change)="loadUserPermissionSets(user)">
            <option [ngValue]=user *ngFor="let user of users">
                {{ user?.userName | texttransform }} ({{ user?.id }})</option>
        </select>
    </div>
    <div id="userPermissionDesign"></div>
    <div class="userPermissionOverflowY" [style.max-height.px]="getMaxHeight()" *ngIf="user != null">
        <div *ngFor="let permissionSet of permissionSets" class="everleadShadowBox"
            (click)="changeActivatedPermission(permissionSet.id)" [ngClass]="{ 
                hideInDom : itemIsFiltered(permissionSet.nameTranslationToken.tokenFullName),
                userPermissionActivatedColor : permissionIdIsActivated(permissionSet.id) 
            }">
            <div class="maxWidth maxHeight">
            <label class="userPermissionPermissionLabel">
                <span *ngIf="showIds">{{ permissionSet.id }} -
                </span>{{ permissionSet.defaultName | texttransform }}
            </label>
        </div>
    </div>
</div>
