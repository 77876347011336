import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-admin-external-datasources',
  templateUrl: './admin-external-datasources.component.html',
  styleUrls: ['./admin-external-datasources.component.scss']
})
export class AdminExternalDatasourcesComponent implements OnInit {
  externalDataSources = [];
  filterString = '';
  queryTest = '';
  queryResult = '';
  param = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private messagingService: MessagingService,
    private commonService: CommonService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.getExternalDataSources();
  }

  getExternalDataSources() {
    this.loaderService.display(true, false, 'getExternalDataSources');
    this.externaldatasourceService.executeExternalDataSource(327, [])
    .then(getExternalDataSourcesResult => {
      getExternalDataSourcesResult.sort((a, b) => a.id > b.id ? -1 : 1);
      this.externalDataSources = getExternalDataSourcesResult;
      this.loaderService.display(false, false, 'getExternalDataSources');
    }).catch(() => { this.loaderService.display(false, false, 'getExternalDataSources'); });
  }

  saveExternalDataSource(dataSource) {
    this.loaderService.display(true, false, 'getExternalDataSources');
    const dataSourceObject = this.commonService.getModifyArrayBody(dataSource, []);
    this.externaldatasourceService.executeExternalDataSource(329, [dataSourceObject])
    .finally(() => {
      this.loaderService.display(false, false, 'getExternalDataSources');
    });
  }

  createExternalDataSource() {
    const dataSource = new Object();
    this.loaderService.display(true, false, 'getExternalDataSources');
    this.externaldatasourceService.executeExternalDataSource(328, [dataSource])
    .then(createExternalDataSourceResult => {
      dataSource['id'] = createExternalDataSourceResult.id;
      this.externalDataSources.unshift(dataSource);
    })
    .finally(() => {
      this.loaderService.display(false, false, 'getExternalDataSources');
    });

  }

  itemIsFiltered(externalDataSource) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(externalDataSource.dataQuery, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(externalDataSource.id.toString(), this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  testExternalDataSource(externalDataSource) {
    console.warn('externalDataSource', externalDataSource);
    this.externaldatasourceService.executeExternalDataSource(externalDataSource.id, [this.param])
    .then(result => {
      console.log('testExternalDataSource', result);
      this.queryTest = externalDataSource.id + ': ' + externalDataSource.dataQuery;
      this.queryResult = result;
    })
    .catch(error => {
      this.queryResult = error;
      this.messagingService.showDefaultError('testExternalDataSource', error);
    });
  }

  queryResultString() {
    if (!this.commonService.isNullOrUndefined(this.queryResult)) {
      return this.queryResult.toString();
    }
  }
}
