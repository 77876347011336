<div class="microCampaignCancel">
    <div class="microCampaignCancel__description">
        {{ 'MicroCampaign.Label.CancelMicroCampaignDescription' | texttransform }}
    </div>
    <div class="microCampaignCancel__canceltext">
        <textarea [(ngModel)]="cancelText" class="microCampaignCancel__canceltext__area"></textarea>
    </div>
    <div class="microCampaignCancel__button">
        <button class="everleadCustomButton" (click)="cancelMicroCampaign()" [disabled]="cancelText.length < 5">
            {{ 'MicroCampaign.Label.CancelMicroCampaignButtonDescription' | texttransform }}
        </button>
    </div>
</div>