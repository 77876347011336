import { Component, Input, OnInit } from '@angular/core';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ControlType } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-uicontrol-subcontrol',
  templateUrl: './uicontrol-subcontrol.component.html',
  styleUrls: ['./uicontrol-subcontrol.component.scss']
})
export class UicontrolSubcontrolComponent implements OnInit {
  @Input() parentControl = null;
  @Input() childControl = null;
  @Input() entityList = null;

  constructor(
    private commonService: CommonService,
    public designerService: DesignerService
  ) { }

  ngOnInit(): void {
  }

  deleteControl() {
    this.designerService.deleteControl(this.childControl).then(() => {
      this.commonService.removeItemFromArray(this.parentControl.childs, this.childControl);
    });
  }

  toNumber(value) {
    return Number(value);
  }

  getAdditionalInfoAboutControl() {
    let returnValue = '';
    switch(this.parentControl.controlType) {
      case ControlType.MatTable:
        returnValue = 'Dieses SubControl stellt die Überschrift einer Spalte dar. Der Wert in Anzeigetext wird in der Überschrift hinterlegt, es kann auch ein Translation-Token hinterlegt werden';
      break;
      case ControlType.CompactTaskList:
        returnValue = 'SubControls mit dem ControlType \'AdditionalTaskListControl\' werden unter den Basisinformationen angezeigt. Jedes dieser Controls benöigt eine UiEntityInstance und den Namen des Members in \'value\'';
      break;
      case ControlType.Button:
        returnValue = ''
      break;
      case ControlType.Button:
        returnValue = ''
      break;
      case ControlType.Button:
        returnValue = ''
      break;

    }
    return returnValue;
  }

  getControlTypeList() {
    let returnValue = null;

    return returnValue;
  }
}
